import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

class HistoryPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  renderHistoryRow(entry, index) {
    const lastUpdateAt = _.get(entry, 'row_last_updated');
    let userContnet;
    if(entry['user_email']){
      userContnet = (
        <span>
          <em> Created by: </em> {entry['user_email']} -
        </span>
      )
    }
    return(
      <div className="history-entry-row" key={index}>
        <h6>{entry['message']}</h6>
        <div className="update-info">
          {userContnet}
          <span className="mt-1">
            {moment(lastUpdateAt).format('MMM D, YYYY h:mm')}
          </span>
        </div>
        <div className="clearfix"></div>
      </div>
    )
  }

  renderHistoryDetails() {
    const { notesHistory } = this.props;
    if(_.isEmpty(notesHistory)) {
      return null
    }
    return notesHistory.map((entry, index) => {
      return this.renderHistoryRow(entry, index)
    })
  }

  renderNotesDetails() {
    const { notesHistory, notesEntry } = this.props;

    if(!_.isEmpty(notesHistory) || _.isEmpty(notesEntry)) {
      return null
    }
    return this.renderHistoryRow(notesEntry)
  }

  render() {
    return (
      <>
        <label className="custom-label text-muted"> History </label>
        <div className="history-page">
          {this.renderHistoryDetails()}
          {this.renderNotesDetails()}
        </div>
      </>
    );
  }
}


HistoryPage.propTypes = {
  notesHistory: PropTypes.array,
  notesEntry: PropTypes.object
}

const mapDispatchToProps = {
};

function mapStateToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage);
