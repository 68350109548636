import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { getThousandSeparatedValueWithFraction } from 'helpers/numberHelper';
import OutsideClickHandler from 'common/components/OutsideClickHandler';
import { REG_EXP_NUMERIC } from 'appConstants';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const WAIT_BEFORE_FOCUS_TIME = 100;

const MonitorConditionValueSelector = (props) => {
  const { className, value, handleMonitorValueChange, index, placeholder, isExpanded } = props;

  const [showInput, setShowInput] = useState(isExpanded);
  const inputRef = useRef(null);

  const onMonitorValueClick = () => {
    setShowInput(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, WAIT_BEFORE_FOCUS_TIME);
  }

  const onKeyPressMonitorValue = (e) => {
    if (isEnterButtonPressed(e)) {
      setShowInput(true);
      setTimeout(() => {
        inputRef.current.focus();
      }, WAIT_BEFORE_FOCUS_TIME);
    }
  }

  const handleValueChange = (event, index) => {
    const inputValue = event.target.value;
    if (inputValue === '' || inputValue === '-' || REG_EXP_NUMERIC.test(Number(inputValue))) {
      handleMonitorValueChange(event, index, 'value')
    }
  }

  const onOutSideClickElement = () => {
    if ( !isExpanded ){
      setShowInput(false);
    }
  }

  const onKeyPressElement = (e) => {
    e.stopPropagation();
    setShowInput(true);
  }

  const inputClassNames = `form-control monitor-condition-input ${className}`;
  return (
    <OutsideClickHandler onClickOutSide={onOutSideClickElement} shouldClickOutSide={() => true}>
      <div className="position-relative monitor-condition">
        {(showInput || _.isEmpty(value))  ?
          <input
            ref={inputRef}
            type="text"
            placeholder={placeholder}
            value={value}
            className={inputClassNames}
            onKeyDown={(e) => onKeyPressElement(e)}
            onChange={(e) => handleValueChange(e, index)} /> :
          <div className="monitor-text-value align-items-center"
            tabIndex={0}
            onClick={onMonitorValueClick}
            onKeyDown={(e) => onKeyPressMonitorValue(e)}>
            {getThousandSeparatedValueWithFraction(value)}
          </div>
        }
      </div>
    </OutsideClickHandler>
  );
}

MonitorConditionValueSelector.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleMonitorValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  isExpanded: PropTypes.bool
}

MonitorConditionValueSelector.defaultProps = {
  placeholder: 'Number',
  isExpanded: false,
}

export default MonitorConditionValueSelector;
