import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingSpinner from 'common/components/LoadingSpinner';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import { templateIdPropTypes } from 'common/propTypes';

class NestedResullt extends Component {
  state = {
    varianceData: {},
    isLoading: false,
    isAnovaLoading: false,
    anovaData: []
  }

  componentDidMount() {
    const { templateId, parentVarianceItem = {} } = this.props;
    const { entities } = parentVarianceItem;

    const baseQuickFilters = _.map(entities, (value, field) => {
      // This is adhoc bad. doing it for spiking.
      return {
        // column: "land_use_type", 
        column: getSelectedDimensionEntry(templateId, field).column,
        type: "text", 
        field, 
        conditions: [ 
          {
            operator: "=", 
            value: [
              value === '(No value)' ? undefined : value,
            ]} 
        ]
      };
    });
    const discardDimensions = _.keys(entities);

    this.fetchChildVariances({ baseQuickFilters, discardDimensions });
    this.fetchAnova({ baseQuickFilters, discardDimensions });
  }

  fetchChildVariances({ baseQuickFilters, discardDimensions }) {
    const { fetchVarianceData } = this.props;
    this.setState({ isLoading: true });
    fetchVarianceData({ baseQuickFilters, discardDimensions })
      .then((varianceData) => this.setState({ varianceData }))
      .finally(() => this.setState({ isLoading: false }));
  }

  fetchAnova({ baseQuickFilters, discardDimensions }) {
    const { fetchAnovaData } = this.props;
    this.setState({ isAnovaLoading: true });

    fetchAnovaData({ baseQuickFilters, discardDimensions })
      .then((anovaData) => this.setState({ anovaData }))
      .finally(() => this.setState({ isAnovaLoading: false }));
  }

  renderChildVariance(title, varianceEntities) {
    const { render } = this.props;

    return (
      <div>
        <h6>{title}</h6>
        {render(varianceEntities)}
      </div>
    );
  }

  renderAnovaDatum = ({ dimensionEntry, result }) => {
    const { dimensionField, name } = dimensionEntry;
    return (
      <div className="mt-2" key={dimensionField}>
        <b className="mr-2">{name}</b>
        <em>{JSON.stringify(result, null, 2)}</em>
      </div>
    );
  };
  
  // [
  //    {
  //      "dimensionEntry": {"column":"class_cleaned_","name":"Class","renderType":"text","field":"..."},
  //      "result": [{"degrees_freedom_numerator":"0","degrees_freedom_denominator":"3"}]
  //    },
  //    {
  //      "dimensionEntry": {"column":"style","name":"Style","renderType":"text","field":"..."},
  //      "result":[{"degrees_freedom_numerator":"-1","degrees_freedom_denominator":"4","F_ratio":"0E-32"}]
  //    },
  //    ...
  // ]

  renderAnovaData() {
    const { isAnovaLoading, anovaData } = this.state;

    if (isAnovaLoading || _.isEmpty(anovaData)) {
      return null;
    }
    
    return (
      <div className="anova-results">
        <h5>Anova</h5>
        {_.map(anovaData, this.renderAnovaDatum)}
      </div>
    );
  }

  render() {
    const { isLoading, varianceData = {} } = this.state;
    const { increased_entities: increasedEntities, decreased_entities: decreasedEntities } = varianceData;
    return (
      <div className="variance-nested-result">
        <LoadingSpinner isLoading={ isLoading } />
        {this.renderAnovaData()}
        {_.isEmpty(decreasedEntities) ? null : this.renderChildVariance('Decreases', decreasedEntities)}
        {_.isEmpty(increasedEntities) ? null : this.renderChildVariance('Increases', increasedEntities)}
      </div>
    );
  }
}

NestedResullt.propTypes = {
  templateId: templateIdPropTypes,
  parentVarianceItem: PropTypes.object,
  fetchVarianceData: PropTypes.func,
  fetchAnovaData: PropTypes.func,
  render: PropTypes.func
}

function mapDispatchToProps() {
  return {
  }
}

function mapStateToProps(state) {
  return {
    templateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NestedResullt);