import React, { useEffect} from 'react'
import PropTypes from 'prop-types'

import LoadingSpinner from 'common/components/LoadingSpinner';
import TimeOfDayTableColumn from './TimeOfDayTableColumn';

import { useFetchTimeOfDayData } from 'modules/hooks/useFetchTimeOfDayData';
import { getTimeOfDayColorBuckets } from 'modules/utils/timeOfDayOrDayOfWeekHelper';
import { TIME_OF_DAYS, TIME_OF_WEEKS } from 'appConstants';
import { enableDragAndSelect } from 'common/components/TimeOfDayFilter/DragSelectorHelper';
import { DEFAULT_COLOR } from 'modules/Map/helpers/mapHelper';

const TimeOfDayOrDayOfWeek = (props) => {
  const {
    url, dayField, weekField, onUpdateLegend, selectedCells, defaultColor,
    selectedColor, onSelectedCellsUpdate, viewEntry
  } = props;
  const { loading, data } = selectedColor ? {loading: false, data: []} : useFetchTimeOfDayData(url);
  const timeOfDayColorBuckets = getTimeOfDayColorBuckets(data, viewEntry);

  const handleSelectEvent = (selectedElements) => {
    let selectedCellsArray = [];
    _.each(selectedElements, (selectedCell) => {
      const entry = JSON.parse(selectedCell.getAttribute('dragselect'));
      if (_.isUndefined(_.find(selectedCellsArray, entry))){
        selectedCellsArray.push(entry);
      }
    });
    onSelectedCellsUpdate(selectedCellsArray);
  }

  useEffect(() => {
    if(selectedColor) {
      const option = {
        selectables: '.drag-selection-heat-chart .cell',
        boundaries: '.drag-selection-heat-chart',
        selectedClass: 'selected'
      };
      enableDragAndSelect(option, handleSelectEvent);
    }
  }, []);

  useEffect(() => {
    onUpdateLegend(timeOfDayColorBuckets);
  }, [timeOfDayColorBuckets])

  const onColumnClick = (selectedCell) => {
    onSelectedCellsUpdate([selectedCell], true);
  }

  const renderTableColumn = (weekOption, dayOption, index) => {
    const tableColumnAttributes = {
      weekOption: {...weekOption, name: _.get(weekOption, weekField )},
      key: `${weekOption.short}-${index}`,
      dayOption,
      index,
      colorBuckets: timeOfDayColorBuckets,
      weekWiseData: data,
      defaultColor,
      selectedColor,
      selectedCells,
      viewEntry,
      onColumnClick: onColumnClick
    };

    return <TimeOfDayTableColumn {...tableColumnAttributes} />
  }

  const renderTableRow = (weekOption, rowIndex) => {
    const renderDayOfWeekContent = _.map(TIME_OF_DAYS, (dayOption, index) => {
      return renderTableColumn(weekOption, dayOption, index);
    });

    return (<tr key={rowIndex}>{renderDayOfWeekContent}</tr>);
  }

  const renderTableHeader = (timeOfDayItem, index) => {
    return (<th scope="col" key={index}>{_.get(timeOfDayItem, [dayField], '')}</th>);
  }
  const className = selectedColor ? 'drag-selection-heat-chart' : '';

  return (
    <div className="time-of-day-day-of-week svg-container">
      <LoadingSpinner isLoading={loading} />
      <table className={`time-of-day-table ${className}`}>
        <thead>
          <tr>{_.map(TIME_OF_DAYS, renderTableHeader)}</tr>
        </thead>
        <tbody>
          {_.map(TIME_OF_WEEKS, renderTableRow)}
        </tbody>
      </table>
    </div>
  );
}

TimeOfDayOrDayOfWeek.propTypes = {
  url: PropTypes.string,
  dayField: PropTypes.string,
  weekField: PropTypes.string,
  onUpdateLegend: PropTypes.func,
  selectedCells: PropTypes.array,
  defaultColor: PropTypes.string,
  selectedColor: PropTypes.string,
  onSelectedCellsUpdate: PropTypes.func,
  viewEntry: PropTypes.object
}

TimeOfDayOrDayOfWeek.defaultProps = {
  dayField: 'short',
  weekField: 'short',
  selectedCells: [],
  defaultColor: DEFAULT_COLOR,
  selectedColor: '',
  onUpdateLegend: _.noop,
  onSelectedCellsUpdate: _.noop,
  viewEntry: {}
}

export default TimeOfDayOrDayOfWeek;
