import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import DistributionChart from 'modules/visualization/DistributionChart';
import DistributionOptions from 'pages/drilldown/visualizations/DistributionOptions';
import VisualizationDescription from 'pages/drilldown/visualizations/VisualizationDescription';
import { getCurrentDrilldownDimensionColumn } from 'helpers/chartDataHelper';
import { getApiParams } from 'helpers/apiParamsHelper';
import { getDimensionName } from 'helpers/displayNameHelper';
import {
  getDistributionBenchmarkEntries,
  getDefaultDistributionOption
} from 'helpers/distributionHelper';
import { isCurrencyType } from 'helpers/visualizationHelper';
import {
  updateDistributionBenchMarkNames,
  updateCurrentDistributionOption
} from 'actions/distributionActions';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import DistributionLegends from './DistributionLegends';
import { isMobileOrTablet, scrollLegendElementToTarget } from 'helpers/DomPageHelper';

class DistributionVisualization extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
      legends: [],
      isOpenLegend: true
    };
  }

  componentDidMount() {
    const {
      isCumulativeDistribution,
      isDiscrete,
      currentDrilldownViewEntry,
      dispatchUpdateCurrentDistributionOption
    } = this.props;
    if(_.isUndefined(isCumulativeDistribution) && _.isUndefined(isDiscrete)) {
      const defaultDistributionOption = getDefaultDistributionOption(currentDrilldownViewEntry);
      dispatchUpdateCurrentDistributionOption(
        defaultDistributionOption.isCumulativeDistribution,
        defaultDistributionOption.isDiscrete
      )
    }
  }

  onChangeCumulativeDistribution = (isCumulative) => {
    const { isDiscrete, dispatchUpdateCurrentDistributionOption } = this.props;
    dispatchUpdateCurrentDistributionOption(isCumulative, isDiscrete);
  }

  onChangeDiscrete = (isDiscrete) => {
    const { isCumulativeDistribution, dispatchUpdateCurrentDistributionOption } = this.props;
    dispatchUpdateCurrentDistributionOption(isCumulativeDistribution, isDiscrete);
  }

  handleChartRenderingComplete = (legends) => {
    this.setState({ legends });
  }

  toggleClickLegendButton = (isOpenLegend) => {
    this.setState({ isOpenLegend: isOpenLegend });
    scrollLegendElementToTarget(isOpenLegend);
  }

  onBenchmarkOptionChange = (currentBenchMarkEntries) => {
    const benchmarkNames =_.map(currentBenchMarkEntries, 'name');
    this.props.dispatchUpdateBenchMarkName(benchmarkNames);
  }

  checkLegendOption = () => {
    const { legends } = this.state;
    const { isCumulativeDistribution, isDiscrete } = this.props;
    const isNotLegend = _.isEmpty(legends) || (isDiscrete && isCumulativeDistribution); 
    const isNotDiscrete = !(isDiscrete && isCumulativeDistribution); 

    return !(isNotDiscrete && isNotLegend);
  }

  renderChartView(isCurrencyDimensionField) {
    const {
      apiParams,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      currentDrilldownDimensionField,
      currentBenchMarkMetricNames,
      quickFilters,
      isCumulativeDistribution,
      isDiscrete,
      onBarClick
    } = this.props;
    const dimensionColumn = getCurrentDrilldownDimensionColumn(
      currentDrilldownTemplateId, currentDrilldownDimensionField);
    const distributionOption = {
      isCumulative : isCumulativeDistribution,
      isDiscrete
    }
    return (
      <DistributionChart
        isCurrencyDimensionField={isCurrencyDimensionField}
        apiParams={apiParams}
        onBarClick={onBarClick}
        viewEntry={currentDrilldownViewEntry}
        quickFilters={quickFilters}
        templateId={currentDrilldownTemplateId}
        dimensionColumn={dimensionColumn}
        distributionOption = {distributionOption}
        benchmarkMetricNames={currentBenchMarkMetricNames}
        afterRender={this.handleChartRenderingComplete}
       />
    );
  }

  renderLegendForMobileView = (legendOptions) => {
    const { isOpenLegend } = this.state;

    if (isOpenLegend && !isMobileOrTablet()) {
      return null;
    }

    return (
      <DistributionLegends legendOptions={legendOptions} />
    )
  }

  render() {
    const { legends, isOpenLegend } = this.state;
    const { isCumulativeDistribution, isDiscrete,
      currentDrilldownViewEntry,
      currentBenchMarkMetricNames,
      currentDrilldownDimensionField,
      currentDrilldownTemplateId
    } = this.props;

    const showLegend = (!_.isEmpty(legends) || (isCumulativeDistribution && isDiscrete) ||
    !_.isEmpty(getDistributionBenchmarkEntries(currentDrilldownViewEntry)));
    const vizClassNames = classNames('chartContainer viz-wrapper', {
      'legend-container': showLegend && !isMobileOrTablet() 
    });
    const isCurrencyDimensionField = isCurrencyType({
      currentDrilldownTemplateId,
      currentDrilldownDimensionField
    });
    const dimensionName = getDimensionName(currentDrilldownTemplateId, currentDrilldownDimensionField);

    const isAddLegendClass = this.checkLegendOption();
    const vizWrapperClass = classNames('visualization-wrapper', {
      'visualization-width': isOpenLegend && isAddLegendClass && !isMobileOrTablet()
    });

    const legendOptions =  { 
      legends, 
      isDiscrete, 
      isCumulativeDistribution, 
      dimensionName,
      isCurrencyDimensionField, 
      currentDrilldownTemplateId, 
      toggleClickLegendButton : this.toggleClickLegendButton,
      fromMobileView: true,
      isOpenLegend
    }

    return (
      <div className={vizClassNames}>
        <DistributionOptions
          isCurrencyDimensionField={isCurrencyDimensionField}
          isCumulativeDistribution={isCumulativeDistribution}
          isDiscrete = {isDiscrete}
          onChangeCumulative = {this.onChangeCumulativeDistribution}
          onChangeView = {this.onChangeViewOptions}
          onChangeDiscrete = {this.onChangeDiscrete}
          onBenchmarkOptionChange={this.onBenchmarkOptionChange}
          legends = {legends}
          benchmarkMetricNames={currentBenchMarkMetricNames}
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          dimensionName ={dimensionName}
          toggleClickLegendButton={this.toggleClickLegendButton}
          isOpenLegend= {isOpenLegend}
        />

        <div className={vizWrapperClass}>
          <div className="visualization-lside">
            <div className="chart-view">
              {this.renderChartView(isCurrencyDimensionField)}
              {isMobileOrTablet() && this.renderLegendForMobileView(legendOptions)}
            </div>
            <div className="visualization-footer">
              <VisualizationDescription />
            </div>
          </div>

          
        </div>
      </div>
    );
  }
}

DistributionVisualization.propTypes = {
  drilldown: commonPropTypes.drilldownPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentVisualizationType: PropTypes.string,
  currentDrilldownDimensionField: PropTypes.string,
  isDiscrete: PropTypes.bool,
  isCumulativeDistribution: PropTypes.bool,
  currentBenchMarkMetricNames: PropTypes.array,
  dispatchUpdateCurrentDistributionOption: PropTypes.func,
  dispatchUpdateBenchMarkName: PropTypes.func,
  apiParams: PropTypes.object,
  onBarClick: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateCurrentDistributionOption: (isCumulativeDistribution, isDiscrete) => {
      dispatch(updateCurrentDistributionOption(isCumulativeDistribution, isDiscrete));
    },
    dispatchUpdateBenchMarkName: (names) => {
      dispatch(updateDistributionBenchMarkNames(names));
    }
  }
}

function mapStateToProps(state) {
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);

  return {
    drilldown: _.get(state, 'drilldown', {}),
    currentDrilldownViewEntry: _.get(state.drilldown, 'currentDrilldownViewEntry', {}),
    quickFilters: _.get(state.drilldown, 'quickFilters', []),
    // Is below required. It was used to check if fetchData has to be called or not.
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentBenchMarkMetricNames: _.get(state, 'visualization.distribution.currentBenchMarkMetricNames', []),
    apiParams: getApiParams(apiParamsOptions, {}),
    isDiscrete: _.get(state, 'visualization.distribution.isDiscrete'),
    isCumulativeDistribution: _.get(state, 'visualization.distribution.isCumulativeDistribution')
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributionVisualization);
