import React from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';

import { getPlotlyData, getPlotlyConfigs } from './dataFormatter';
const Plot = createPlotlyComponent(window.Plotly);
const AccuracyChart = (props) => {
  const plotlyData = getPlotlyData(props);
  const plotlyParams = getPlotlyConfigs(props, plotlyData);
  return (
    <div>
      <Plot {...plotlyParams} />
    </div>
  );
}

export default AccuracyChart;
