import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NoDataWrapper } from './NoDataWrapper';
import QueryFailed from './QueryFailed';
import NoDataFound from './NoDataFound';
class ErrorHandler extends Component {

  componentDidMount(){
    const { onUpdateLoadingStatus } = this.props;
    if(!_.isUndefined(onUpdateLoadingStatus)){
      onUpdateLoadingStatus(false);
    }
  }

  render(){
    const { viewMode, isNodataFound } = this.props;

    return (
      <>
        {!isNodataFound && <QueryFailed viewMode={viewMode}/>}
        {isNodataFound && <NoDataFound viewMode={viewMode}/> }
      </>
    )
  }
}

ErrorHandler.propTypes = {
  viewMode: PropTypes.string,
  statusCode: PropTypes.bool,
  isNodataFound: PropTypes.bool,
  onUpdateLoadingStatus:PropTypes.func
};

export default NoDataWrapper(ErrorHandler);