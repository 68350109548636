import React from 'react';
import PropTypes from 'prop-types';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import {
  SUBSCRIPTION_FREQUENCY_OPTIONS,
  TIMEZONE_OPTIONS,
  TIME_PICKER_OPTIONS,
  REG_EXP_NUMERIC
} from 'appConstants';

const FrequencySettings = (props) => {
  const {
    frequency, timeZone, frequencyInterval, emailSendingTime, onSettingValueChange, isDisabled
  } = props;
  const selectedFrequency = _.find(SUBSCRIPTION_FREQUENCY_OPTIONS, { 'value': frequency });

  const handleValueChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === '' || inputValue === '-' || REG_EXP_NUMERIC.test(Number(inputValue))) {
      onSettingValueChange(inputValue, 'frequencyInterval')
    }
  }

  let errorMessage = ''
  if (frequencyInterval && frequencyInterval <= 0) {
    errorMessage = 'Please enter a number greater than 0.'
  }

  return (
    <div className="frequency-settings-filters">
      <input type="text"
        className="form-control frequency-value-input"
        value={frequencyInterval} placeholder='Number'
        disabled={isDisabled}
        onChange={(e) => handleValueChange(e)}
      />
      { (!_.isEmpty(errorMessage)) &&
        <span className="invalid-feedback">{errorMessage}</span>
      }
      <SolutionDropDown
        size="sm"
        id="frequency"
        className="border-0"
        title={_.get(selectedFrequency, 'name')}
        disabled={isDisabled}
        options={SUBSCRIPTION_FREQUENCY_OPTIONS}
        onSelect={(e) => onSettingValueChange(e['value'], 'frequency')} />

      at
      <div className="sending-time">
        <SolutionDropDown
          size="sm"
          id="time"
          className="subscription-dropdown dropdown-caret-none"
          title={emailSendingTime}
          options={TIME_PICKER_OPTIONS}
          disabled={isDisabled}
          onSelect={(e) => onSettingValueChange(e, 'emailSendingTime')} />
      </div>
      <SolutionDropDown
        size="sm"
        id="timezone"
        className="subscription-dropdown"
        title={timeZone}
        options={TIMEZONE_OPTIONS}
        disabled={isDisabled}
        onSelect={(e) => onSettingValueChange(e, 'timeZone')} />
    </div>
  );
}

FrequencySettings.propTypes = {
  emailSendingTime: PropTypes.string,
  frequency: PropTypes.string,
  frequencyInterval: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  timeZone: PropTypes.string,
  onSettingValueChange: PropTypes.func,
  isDisabled: PropTypes.bool,
}

export default FrequencySettings;
