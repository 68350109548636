import _ from 'lodash';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import {
  getBenchMarkEntries,
  getBenchMarkMetricEntries,
  getBenchmarkNames,
  getDefaultGroupBySortOption,
  getDefaultSortOption,
  getSecondaryMetricEntries,
  getSecondaryMetricEntry,
  isCurrencyType,
  isGroupByCurrencyType
} from 'helpers/visualizationHelper';
import { SNAPSHOT_VISUALIZATION_TYPES } from 'appConstants';
import { isNumericDimensionField } from 'helpers/snapshotRendererHelper';
import { getDimensionName } from 'helpers/displayNameHelper';
import { PIE_CHART_DATA_LIMIT } from 'modules/visualization/constants';
import { isBarchart } from 'modules/visualization/SnapshotVisualization/barChartHelper';
import { isScatterChart } from 'modules/visualization/SnapshotVisualization/plotlyScatterChartHelper';

export const getSnapshotAttributes = (options) => {
  const { currentSnapshotView, chartType } = options;
  const chartOptions = getCommonChartOptions(options);
  const newOptions = { ...chartOptions, ...options };

  let visualizationAttributes = {};
  if(currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type) {
    visualizationAttributes = getPieChartAttributes(newOptions)
  } else {
    if(chartType == 'groupChart') {
      if(currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type){
        visualizationAttributes = getGroupedBarChartAttributes(newOptions);
      } else if (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type) {
        visualizationAttributes = getGroupedScatterChartAttributes(newOptions);
      }
    } else {
      if(currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type) {
        visualizationAttributes = getBarChartAttributes(newOptions);
      } else {
        visualizationAttributes = getScatterChartAttributes(newOptions)
      }
    }
  }
  return {
    ...getCommonAttributes(newOptions),
    ...visualizationAttributes
  }
}

const getCommonAttributes = (options) => {
  return _.pick(options, [
    'afterRender',
    'apiParams',
    'onAfterPlot',
    'currentSnapshotView',
    'onApiDataLoad',
    'onNewApiData',
    'onFormattedDataLoad',
    'templateName',
    'templateId',
    'viewEntry',
    'drillDownTotal',
    'showChartValues',
    'compareYearRanges',
    'comparisonType',
    'isComparisonEnabled',
    'commonFilters',
    'onDataLoading'
  ]);
}

const getPieChartAttributes = (options) => {
  const {
    apiParams,
    onPieSliceClick,
    skipToNextLevel,
    isCurrencyDimensionField,
    isCurrencyGroupByField,
    dimensionName,
    pieChartAnnotationOptions,
    metricTotalLoading
  } = options;

  return {
    apiParams: _.merge({}, apiParams, { limit: PIE_CHART_DATA_LIMIT, ignoreGroupBy: true }),
    isCurrencyDimensionField,
    onPieSliceClick,
    onApiDataLoad: skipToNextLevel,
    isCurrencyGroupByField,
    dimensionName,
    pieChartAnnotationOptions,
    isChartAndTotalLoading: metricTotalLoading
  };
}

const getGroupedBarChartAttributes = (options) => {
  return _.merge({}, _.pick(options, [
      'benchMarkEntries',
      'groupByEntry',
      'groupType',
      'isBarClickable',
      'secondaryMetricEntry',
      'isCurrencyDimensionField',
      'isCurrencyGroupByField',
      'groupByViewType',
      'dimensionName']),
    { onBarClick: options.handleBarClick }
  );
}

const getGroupedScatterChartAttributes = (options) => {
  const {
    onBarClick,
    groupByEntry,
    secondaryMetricEntry,
    benchMarkEntries,
    showScatterPlotRange,
    currentGroupByViewOption,
    isCurrencyDimensionField,
    isCurrencyGroupByField,
    dimensionSortOption
  } = options;
  return {
    onBarClick,
    groupByEntry,
    secondaryMetricOption: secondaryMetricEntry,
    benchMarkEntries,
    showRange: showScatterPlotRange,
    groupByViewType: currentGroupByViewOption,
    isCurrencyDimensionField,
    isCurrencyGroupByField,
    dimensionSortOption
  };
}

const getBarChartAttributes = (options) => {
  return _.merge({}, _.pick(options, [
    'secondaryMetricEntry',
    'benchMarkEntries',
    'dimensionName',
    'isBarClickable',
    'isCurrencyDimensionField',
    'isCurrencyGroupByField',
    'isNumericDimensionField']),
  { onBarClick: options.handleBarClick });
}

const getScatterChartAttributes = (options) => {
  const {
    showScatterPlotRange,
    isNumericDimensionField,
    benchMarkEntries,
    isCurrencyDimensionField,
    isCurrencyGroupByField,
    secondaryMetricEntry,
    onBarClick
  } = options;
  return {
    onBarClick,
    secondaryMetricOption: secondaryMetricEntry,
    benchMarkEntries,
    showRange: showScatterPlotRange,
    isCurrencyGroupByField,
    isNumericDimensionField,
    isCurrencyDimensionField
  };
}

const getCommonChartOptions = (options) => {
  const {
    currentDrilldownViewEntry,
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  } = options;

  const currentDrilldownDimension = getSelectedDimensionEntry(
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  );

  return {
    viewEntry: currentDrilldownViewEntry,
    templateId: currentDrilldownTemplateId,
    dimensionName: _.get(currentDrilldownDimension, 'name')
  };
}

export const getSnapshotOptionsAttributes = (stateOptions, propOptions) => {
  const { legends } = stateOptions;
  const {
    showScatterPlotRange,
    isLeafPage,
    currentSnapshotView,
    currentDrilldownDimensionField,
    currentDrilldownGroupByEntry,
    currentDrilldownViewEntry,
    currentVisualizationType,
    currentSecondaryMetricField,
    currentBarChartSortType,
    currentDimensionSortType,
    currentGroupBySortType,
    currentBenchMarkMetricNames,
    currentDrilldownTemplateId,
    showSnapshotChartValues,
    commonFilters
  } = propOptions;
  const benchMarkEntries = getBenchMarkEntries(
    currentDrilldownViewEntry, currentVisualizationType, currentSnapshotView);
  const benchMarkNames = getBenchmarkNames(benchMarkEntries,
      currentBenchMarkMetricNames,
      currentDrilldownTemplateId,
      currentDrilldownDimensionField);
  const currentSecondaryMetricEntry = getSecondaryMetricEntry(
    currentDrilldownViewEntry, currentVisualizationType, currentSnapshotView, currentSecondaryMetricField);

  const comparisonField = _.get(currentSecondaryMetricEntry, 'field');
  const isComparisonModeOn = (_.get(commonFilters, 'comparisonModeOn')) && _.isEmpty(comparisonField);
  const renderType = isComparisonModeOn ? 'bullet' : _.get(currentSecondaryMetricEntry, 'render_type');
  const isStackBarChart = (renderType === 'stack' &&
    currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
  const isBulletBarChart = (_.includes(['bullet','parallel'], renderType) &&
    currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
  const isPieChart = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type);
  const isRangeChart = isScatterChart(currentSnapshotView);

  const isBarChart = isBarchart(currentSnapshotView);
  const legendItems = (isStackBarChart || isBulletBarChart || isPieChart || isBarChart || isRangeChart) ?
    legends : [];
  const secondaryMetrics = getSecondaryMetricEntries(
    currentDrilldownViewEntry, 'snapshot', currentSnapshotView);
  const currentBenchMarkEntries = getBenchMarkMetricEntries(
    currentDrilldownViewEntry, currentVisualizationType, currentSnapshotView, benchMarkNames);

  const currentBarChartSortOption = getDefaultSortOption(
    currentDrilldownDimensionField,
    currentDrilldownViewEntry,
    currentBarChartSortType,
    currentDrilldownTemplateId);
  const currentDimensionSortOption = getDefaultSortOption(
    currentDrilldownDimensionField,
    currentDrilldownViewEntry,
    currentDimensionSortType,
    currentDrilldownTemplateId);
  const currentGroupBySortOption = getDefaultGroupBySortOption(
    currentDrilldownGroupByEntry,
    currentDrilldownViewEntry,
    currentGroupBySortType,
    currentDrilldownTemplateId);
  const isNumericDimension = isNumericDimensionField({
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  });
  const isCurrencyDimensionField = isCurrencyType({
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  });
  const dimensionName = getDimensionName(currentDrilldownTemplateId, currentDrilldownDimensionField);
  const isNumericGroup = (_.get(currentDrilldownGroupByEntry, 'renderType') === 'number'
                                          && _.get(currentDrilldownGroupByEntry, 'renderAxis') === 'range' );
  const isCurrencyGroupByField = isGroupByCurrencyType(currentDrilldownGroupByEntry);
  const showLegend = (isStackBarChart || isBulletBarChart || isPieChart ||
    !_.isEmpty(secondaryMetrics) || !_.isEmpty(benchMarkEntries))

  return {
    isScatterPlotRangeSelected: showScatterPlotRange,
    isLeafPage,
    currentSecondaryMetricEntry,
    currentSnapshotView,
    currentDrilldownViewEntry,
    currentBarChartSortOption,
    currentGroupBySortOption,
    currentDimensionSortOption,
    legends: legendItems,
    showLegend,
    benchMarkEntries : currentBenchMarkEntries,
    isNumericGroup,
    isNumericDimension,
    isCurrencyDimensionField,
    isCurrencyGroupByField,
    benchMarkNames,
    showChartValues: showSnapshotChartValues,
    dimensionName,
    isBulletBarChart,
    isStackBarChart,
    isPieChart,
    isBarChart,
    isRangeChart,
    isOpenLegend : _.get(stateOptions,'isOpenLegend', false)
  };
}
