import _ from 'lodash';
import React, { Component } from 'react';
import { getRadarTotalRowData } from 'common/api/commonApi';
import { getMetricWiseData } from '../radarHelper';
import { getCensusEntry } from 'common/config/customerConfiguration';
/* eslint react/prop-types: 0 */
export default function RadarTableWrapper(HocComponent){
  return class extends Component {
    constructor(props) {
      super(props);
      this.abortFetchControllers = {
        selectedTracts: new AbortController(),
        unSelectedTracts: new AbortController(),
        comparisonTracts: new AbortController(),
        total: new AbortController()
      };
      this.state = {
         metricWiseData: {
          selectedTracts: {},
          unSelectedTracts: {},
          comparisonTracts: {},
          total: {}
        },
        loadingStates: {
          selectedTracts: false,
          unSelectedTracts: false,
          comparisonTracts: false,
          total: false,
        },
        barChartMetricWiseData: {
          selectedTracts: {},
          unSelectedTracts: {},
          comparisonTracts: {},
          total: {}
        },
      }
    }
    componentDidMount(){
      const {
        selectedTracts, comparisonTracts, isCheckedUnSelectedArea
      } = this.props;
      const allSelectedTracts = [...selectedTracts, ...comparisonTracts];
      const tracts  = isCheckedUnSelectedArea ? [] : allSelectedTracts
      let rowKeyEntryToFetch= [
        {tracts, rowKey: 'total'},
        {tracts: selectedTracts, rowKey: 'selectedTracts'},
        {tracts: allSelectedTracts, rowKey: 'unSelectedTracts'}
      ];
      if(!_.isEmpty(comparisonTracts)){
        rowKeyEntryToFetch.push({tracts: comparisonTracts, rowKey: 'comparisonTracts'});
      }
      this.fetchRadarRowTotal(rowKeyEntryToFetch);
    }

    componentDidUpdate(prevProps) {
      const {
        selectedTracts, apiParams, totalAggregateType,
        comparisonTracts, isCheckedUnSelectedArea, radarData
      } = this.props;
      let shouldFetch = false;
      const isParamsChanged = !_.isEqual(prevProps.apiParams, apiParams);
      const isTotalAggregateTypeChanged = !_.isEqual(prevProps.totalAggregateType, totalAggregateType);
      const isSelectedTractsChanged = !_.isEqual(prevProps.selectedTracts, selectedTracts);
      const isComparisonTractChanged = !_.isEqual(prevProps.comparisonTracts, comparisonTracts);
      const isDataUpdated = !_.isEqual(prevProps.radarData, radarData);
      const isCommonPropsChanged = isParamsChanged || isTotalAggregateTypeChanged || isDataUpdated;
      const includeUnSelectedAreaChanged = !_.isEqual(
        prevProps.isCheckedUnSelectedArea, isCheckedUnSelectedArea);
      const allSelectedTracts = [...selectedTracts, ...comparisonTracts];
      let rowKeyEntryToFetch= [];
      if (isCommonPropsChanged || isSelectedTractsChanged) {
        rowKeyEntryToFetch.push({tracts: selectedTracts, rowKey: 'selectedTracts'});
        rowKeyEntryToFetch.push({tracts: allSelectedTracts, rowKey: 'unSelectedTracts'});
        shouldFetch = true;
      }

      if (isCommonPropsChanged || isComparisonTractChanged) {
        rowKeyEntryToFetch.push({tracts: comparisonTracts, rowKey: 'comparisonTracts'});
        rowKeyEntryToFetch.push({tracts: allSelectedTracts, rowKey: 'unSelectedTracts'});
        shouldFetch = true;
      }
      if (isCommonPropsChanged || includeUnSelectedAreaChanged ||
        (isSelectedTractsChanged && !isCheckedUnSelectedArea) ) {
        const tracts  = isCheckedUnSelectedArea ? [] : allSelectedTracts
        rowKeyEntryToFetch.push({tracts, rowKey: 'total'});
        shouldFetch = true;
      }
      if(shouldFetch){
        this.fetchRadarRowTotal(_.uniqBy(rowKeyEntryToFetch, 'rowKey'));
      }
    }

    componentWillUnmount() {
      this.abortFetchControllers['selectedTracts'].abort();
      this.abortFetchControllers['unSelectedTracts'].abort();
      this.abortFetchControllers['comparisonTracts'].abort();
      this.abortFetchControllers['total'].abort();
    }

    getRadarTotalPromise = (tracts, rowKey , abortController) => {
      const { apiParams, totalAggregateType } = this.props;
      const isTractSelected = (_.includes(['unSelectedTracts', 'total'], rowKey)) ?
      true : !_.isEmpty(tracts);
      const params = {
        ...apiParams,
        selectedTracts: JSON.stringify(_.uniq(tracts)),
        excludeTracts: rowKey == 'unSelectedTracts',
        totalAggregateType
      };
      return isTractSelected ?
        getRadarTotalRowData(params, abortController) : Promise.resolve([]);
    }


    getMetricWiseDataByRadarData = (rowEntry) => {
      const { selectedMetrics, radarData, isCheckedUnSelectedArea } = this.props;
      const { tracts, rowKey } = rowEntry;

      let selectedTractData = _.filter(radarData, (datum) => {
        if(rowKey == 'unSelectedTracts'){
          return !_.includes(tracts, datum['tract_id']);
        }else{
          return _.includes(tracts, datum['tract_id']);
        }
      });
      if(rowKey == 'total' && isCheckedUnSelectedArea){
        selectedTractData = radarData
      }

      return getMetricWiseData(selectedTractData, selectedMetrics);
    }

    fetchRadarRowTotal = (rowKeyEntryToFetch) => {
      const censusEntry = getCensusEntry();
      const { selectedMetrics, totalAggregateType, isCurrentMetricsAltered, radarData } = this.props;
      let {
        metricWiseData: newMetricWiseData,
        loadingStates: newLoadingStates,
        barChartMetricWiseData: newBarChartMetricWiseData
      } = this.state;
      const isTotalAggregate = totalAggregateType == 'total';

      if(_.isEmpty(censusEntry)) {
        const promises = _.map(rowKeyEntryToFetch, (rowEntry) => {
          const { tracts, rowKey } = rowEntry;
          newLoadingStates[rowKey] = true;
          this.abortFetchControllers[rowKey].abort();
          this.abortFetchControllers[rowKey] = new AbortController();
          return this.getRadarTotalPromise(tracts, rowKey, this.abortFetchControllers[rowKey]);
        });
        this.setState({ loadingStates: newLoadingStates });
        Promise.all(promises).then((results) => {
          _.each(results, (response, index) => {
            const rowEntry = rowKeyEntryToFetch[index];
            const { rowKey } = rowKeyEntryToFetch[index];
            const metricData = getMetricWiseData(response, selectedMetrics, true);
            newMetricWiseData[rowKey] = metricData;
            newLoadingStates[rowKey] = false;
            if(isTotalAggregate){
              newBarChartMetricWiseData[rowKey] = getMetricWiseData(response, selectedMetrics);
            }else{
              if((_.isEmpty(newBarChartMetricWiseData[rowKey]) || isCurrentMetricsAltered) &&
                !_.isEmpty(radarData)){
                newBarChartMetricWiseData[rowKey] = this.getMetricWiseDataByRadarData(rowEntry);
              }
            }
          });
          this.setState({
            metricWiseData: newMetricWiseData,
            loadingStates: newLoadingStates,
            barChartMetricWiseData: newBarChartMetricWiseData
          });
        }).catch((e) => {
          console.error(e);
          _.each(rowKeyEntryToFetch, (rowEntry) => {
            const { rowKey } = rowEntry;
            newLoadingStates[rowKey] = false;
          });
          this.setState({ loadingStates: newLoadingStates });
        });
      }else{
        _.each(rowKeyEntryToFetch, (rowEntry) => {
          const { rowKey } = rowEntry;
          const metricData = this.getMetricWiseDataByRadarData(rowEntry);
          newMetricWiseData[rowKey] = metricData;
        });

        this.setState({
          metricWiseData: newMetricWiseData,
          barChartMetricWiseData: newMetricWiseData
        });
      }
    }

    render() {
      return(
        <HocComponent
          {...this.props}
          metricWiseData={this.state.metricWiseData}
          loadingStates={this.state.loadingStates}
          barChartMetricWiseData={this.state.barChartMetricWiseData}
        ></HocComponent>
      )
    }
  }
}
