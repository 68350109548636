import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SummaryTable from 'modules/visualization/SnapshotVisualization/SummaryTable';
import {
  getForecastChartSummaryFormatter
} from 'modules/visualization/SnapshotVisualization/SummaryTable/forecastChartSummaryFormatter';
import * as timeline from 'modules/visualization/LineChart/plotlyParamsGenerator/timeline';
import dataFormatter from 'modules/visualization/LineChart/dataFormatter';
import UseSummaryTableDataFetch from './useSummaryTableDataFetch';

const ForecastSummaryTable = (props) => {
  const { currentDrilldownTemplateId, onUpdateSummaryData,  onSummaryTableLoading,
    lineChartAttributes, summaryTableOptions } = props;

  const { dateRange, renderType, futureForecastDateRange,
    axisGranularity, projectionAdjustedValues, projectionPercent } = lineChartAttributes;

  const { forecastModelOptions } = summaryTableOptions

  const [summaryTableData, setSummaryTableData] = useState({});
  const [prevLineChartAttributes, setPrevLineChartAttributes] = useState(null);
  const {
    loading, rawData: apiData
  } = UseSummaryTableDataFetch(lineChartAttributes, dateRange, axisGranularity, renderType,
    futureForecastDateRange, prevLineChartAttributes, forecastModelOptions, projectionPercent);

  useEffect(() => {
    setPrevLineChartAttributes(lineChartAttributes);
  },[lineChartAttributes])

  useEffect(() => {
    if (!loading && !_.isEmpty(apiData)) {
      processData(apiData);
      onSummaryTableLoading(false);
    }else{
      onSummaryTableLoading(true);
    }
  }, [apiData,loading])

  useEffect(() => {
    if (!_.isEmpty(apiData)) {
      processData(apiData);
    }
  }, [projectionAdjustedValues])

  const processData = (apiData) => {
    const chartData = dataFormatter.formatData(lineChartAttributes, apiData);
    const lineTraces = timeline.toPlotlyTraces(lineChartAttributes, chartData);
    const summaryData = { formatData: lineTraces };
    const tableData = getForecastChartSummaryFormatter(summaryData, summaryTableOptions);
    setSummaryTableData(tableData);
    onUpdateSummaryData(summaryData);
  }

  return (
    <div className="forecast-summary-table">
      <SummaryTable
        subHeader={[]}
        tableHeaders={summaryTableData.tableHeaders}
        tableData={summaryTableData.tableData}
        summaryTableOptions={summaryTableOptions}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        isForecastingView={true} />
    </div>
  );
}

ForecastSummaryTable.propTypes = {
  currentDrilldownTemplateId: PropTypes.any,
  onUpdateSummaryData: PropTypes.func,
  onSummaryTableLoading: PropTypes.func,
  lineChartAttributes: PropTypes.object,
  summaryTableOptions: PropTypes.object,
};

export default ForecastSummaryTable;
