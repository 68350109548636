import React from 'react'
import PropTypes from 'prop-types'
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown'

function SearchUserList({ options, title , label, onSelect, onInputChange, inputValue, inputRef }) {
  return (
    <div className="search-user-list search-rs d-flex align-items-center border rounded">
      <div className="d-flex align-items-center">
        <i className="icons-search mr-5 ml-2"></i>
        <label className="mb-0 text-nowrap">{label}</label>
        <SolutionDropDown
          className="mx-2"
          id="search-in"
          size="sm"
          title={title}
          options={options}
          onSelect={onSelect}
        />
      </div>
      <input ref={inputRef}
        type="text"
        className="form-control flex-grow-1 border-0 bg-transparent"
        placeholder="Search"
        value={inputValue}
        onChange={onInputChange}
      />
    </div>
  )
}

SearchUserList.propTypes = {
  inputValue: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  inputRef:PropTypes.any
}

SearchUserList.defaultProps = {
  inputValue: '',
  label: 'Search in',
  options: [],
  title: '',
  onSelect: _.noop,
  onInputChange: _.noop,
}

export default SearchUserList
