import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { ForgeIcon } from '@tylertech/forge-react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { DEFAULT_DROPDOWN_OPTION } from 'appConstants';
import { getBenchMarkName } from 'pages/SubscriptionsManager/components/Bookmark/helper';

import ConfirmationDialogue from 'modules/Administration/ConfirmationDialogue';

class BookmarkBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showConfirmModal: false
    };
  }

  handleCollectionClick = () => {
    const {
      enableConfirmModal,
      onBookmarkClick,
      bookmark
    } = this.props

    if(enableConfirmModal) {
      this.setState({ showConfirmModal: true });
    }else {
      onBookmarkClick(bookmark)
    }
  }

  handlePressCollection = () => {
    const { bookmark } = this.props;

    if (isEnterButtonPressed(event)) {
      this.handleCollectionClick(bookmark);
    }
  }

  handleBookmarkChangeConfirm = (isConfirm) => {
    const { bookmark, onBookmarkClick } = this.props;
    this.setState({showConfirmModal: false});

    if(isConfirm){
      onBookmarkClick(bookmark)
    }
  }

  isBookmarkMonitorIcon = () => {
    const { bookmark } = this.props;

    const drilldown = _.get(bookmark, 'drilldown', {});
    let monitorConditions = _.get(bookmark, 'bookmarkOptions.monitorConditions', []);
    const visualization = _.get(bookmark, 'visualization', {});
    const bookmarkNames = getBenchMarkName(drilldown, visualization);
    const emailOptions = _.get(bookmark, 'email_options', {});
    const emailStrategy = _.get(bookmark, 'email_strategy', '')
    const isEmailStrategy = emailStrategy !== 'never';
    const isSchedule = _.includes(["on_schedule"], emailStrategy);
    const isMatchRecord = _.includes(["records_match_on_selected_filters"], emailStrategy);

    if (
     _.isEmpty(bookmarkNames) ||
     _.isEqual(DEFAULT_DROPDOWN_OPTION.name, bookmarkNames)
     ) {
       monitorConditions = _.filter(monitorConditions, (condition) => {
         return condition.type != 'benchmark';
       });
     }

    const isMonitor = _.some(monitorConditions, (condition) => {
      return condition.enable;
    });

    const showAlertIcon = ((isSchedule &&
      !_.isEmpty(emailOptions.emailSendingTime)) || isMatchRecord);
    return (isEmailStrategy && (isMonitor || showAlertIcon ))
  }

  renderIcon() {
    const { bookmark } = this.props;
    const isRadar= JSON.parse(_.get(bookmark, 'is_radar', false));
    const isManageView = _.get(bookmark, 'is_manage_view', false);
    const isCollaborateAlert = _.get(bookmark, 'is_collaborate_alert', false);

    let iconHtml = null;
    if(isRadar) {
      iconHtml = (
        <i className="icons icons-change-over-time tx-23 mr-2"></i>
      );
    } else if(isManageView || isCollaborateAlert) {
      iconHtml = (<ForgeIcon name="supervisor_account" className="forge-icon" />);
    } else if (this.isBookmarkMonitorIcon()) {
      iconHtml = (
        <i className="icons icons-alert-set mr-2"></i>
      );
    } else {
      iconHtml = (
        <i className="icons icons-bookmark-manager mr-2"></i>
      );
    }
    return iconHtml;
  }

  renderBookmarkConfirmModal () {
    const { showConfirmModal } = this.state;

    if(!showConfirmModal){
      return null
    }
    const message =`Are you sure you want to leave this page without saving the changes
      made to this bookmark?`;
    return (<ConfirmationDialogue
      message={message}
      messageTitle={'Confirm changes'}
      onConfirmationCallBack = {this.handleBookmarkChangeConfirm} />)
  }

  renderBookmark() {
    const {
      bookmark,
      isSelected,
      index,
    } = this.props;
    const linkClassNames = classNames('link-items w-100 d-flex align-items-center', {
        'active': isSelected
      });
    return(
      <div key={index}>
        <div
          className="collection-link d-flex align-items-center">
          {this.renderIcon()}
          <div className={linkClassNames} tabIndex="0" onKeyDown={(e) => this.handlePressCollection(e)}>
            <div className="w-100" onClick={() => this.handleCollectionClick(bookmark)}>
              <span class="tx-14">
                {_.get(bookmark, 'name')}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderBookmark()}
        {this.renderBookmarkConfirmModal()}
      </>
    );
  }
}

BookmarkBar.propTypes = {
  index: PropTypes.number,
  bookmark: PropTypes.object,
  isSelected: PropTypes.bool,
  enableConfirmModal: PropTypes.bool,
  onBookmarkClick: PropTypes.func,
  monitorReached: PropTypes.bool,
  isMonitorEnabled: PropTypes.bool
}

export default (BookmarkBar);
