import { getDefaultVisualizationView } from 'common/config/visualizationConfiguration';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import {
  updateDrilldownViewEntry,
  updateQuickFilters,
  setDrilldownDimensions,
  setCurrentVisualizationType
} from 'actions/drilldownActions';
import { updateSliderRange, updateOvertimeChartView } from 'actions/overtimeActions';
import { setDefaultDrilldownMapOptions, toggleShapeIdsFilter } from 'actions/mapOptionsActions';
import { updateCurrentSnapshotView } from 'actions/snapshotActions';
import { getDefaultVisualizationChartView } from 'helpers/visualizationHelper';
import { VISUALIZATION_TYPES } from 'appConstants';

export const setDefaultViewActions = ({
  currentVisualizationType,
  dispatch,
  quickFilters = [],
  templateId,
  viewEntry,
  currentChartVizType
}) => {
  const defaultView = currentVisualizationType || getDefaultVisualizationView(viewEntry);
  const defaultChartView = currentChartVizType || getDefaultVisualizationChartView(viewEntry, defaultView);

  dispatch(updateDrilldownViewEntry(viewEntry));
  dispatch(updateCurrentBookmark());
  dispatch(updateSliderRange([]));
  dispatch(updateQuickFilters(quickFilters));
  dispatch(setDrilldownDimensions([]));
  dispatch(toggleShapeIdsFilter([], null));
  dispatch(setDefaultDrilldownMapOptions(templateId, viewEntry, true));
  dispatch(setCurrentVisualizationType(defaultView));

  if (defaultView == VISUALIZATION_TYPES.SNAPSHOT.type) {
    dispatch(updateCurrentSnapshotView(defaultChartView));
  } else if (defaultView == VISUALIZATION_TYPES.OVERTIME.type) {
    dispatch(updateOvertimeChartView(defaultChartView));
  }
}
