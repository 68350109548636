export const ENTER_BUTTON = "Enter";
export const ESCAPE_BUTTON = "Escape";
export const SPACE_BUTTON = "Space";

export const isEnterButtonPressed = (e) => {
  return _.isEqual(e.key, ENTER_BUTTON);
}

export const isSpaceButtonPressed = (e) => {
  return _.isEqual(e.code, SPACE_BUTTON);
}

export const isEscapeButtonPressed = (e) => {
  return _.isEqual(e.key, ESCAPE_BUTTON);
}

export const isEllipsisActive = (e) => {
  return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
}
