export const UPDATE_ON_TOGGLE_MODAL = 'UPDATE_ON_TOGGLE_MODAL';
export const UPDATE_IS_LOADING = 'UPDATE_IS_LOADING';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_REFETCH = 'UPDATE_REFETCH';
export const UPDATE_IS_USER_DATA_LOADING = 'UPDATE_IS_USER_DATA_LOADING';

export const updateToggleModal = (isOpenModal) => ({
  type: UPDATE_ON_TOGGLE_MODAL,
  isOpenModal
});

export const updateIsLoading = (isLoading) => ({
  type: UPDATE_IS_LOADING,
  isLoading
});

export const updateCollaborateCollection = (collection) => ({
  type: UPDATE_COLLECTION,
  collection
});

export const updateUsers = (users, isUserDataLoading, reFetch = false) => ({
  type: UPDATE_USERS,
  users,
  isUserDataLoading,
  reFetch
});

export const updateIsUserDataLoading = (isUserDataLoading) => ({
  type: UPDATE_IS_USER_DATA_LOADING,
  isUserDataLoading
});

export const updateRefetch = (reFetch) => ({
  type: UPDATE_REFETCH,
  reFetch
});
