import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Map from 'modules/Map/Map';

import { getReportDataBoundingPoints } from 'modules/AdvanceSearch/advanceSearchHelper';
import { getMapViews } from 'modules/Map/helpers/MapOptionsHelper';
import { DEFAULT_MAP_VIEW, DEFAULT_MAP_CONTAINER_HEIGHT } from 'appConstants';
import { getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
import { updateDrilldownTemplateNameAndId } from 'actions/drilldownActions';
import classNames from 'classnames';
import ExpandPopupHandler from 'modules/Map/handlers/ExpandPopupHandler';

const MapView = ({ onMapLoaded,
  showOnlySelectedRows = false,
  isSubjectProperty = false,
  onApiDataLoading = _.noop
}) => {
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));
  const subjectRowIds = useSelector(store => _.get(store, 'advanceSearch.subjectRowIds', []));
  const searchField = useSelector(store => store.advanceSearch.searchField);
  const filters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
  const reportPageData = useSelector(store => _.get(store, 'advanceSearch.reportPageData', []));
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));
  const shapeDatasetEntry = useSelector(store => _.get(store, 'advanceSearch.shapeDatasetEntry', {}));
  const selectedReportRowIds = useSelector(store => _.get(store, 'advanceSearch.selectedReportRowIds', []));
  const polygonGeoJson = useSelector(store => _.get(store, 'advanceSearch.polygonGeoJson', {}));
  const isComparisonListOpen = useSelector(state => state.advanceSearch.isComparisonListOpen);
  const searchProperty = useSelector(store => store.advanceSearch.searchProperty);
  const subjectData = useSelector(state => state.advanceSearch.subjectData);
  const isDrawingEnabled = useSelector(store =>
    _.get(store, 'visualization.mapOptions.isDrawingEnabled', false));
  const templateEntry = getCurrentTemplateEntryById(templateId);
  const centerLng = _.get(templateEntry, 'map.centerLng');
  const centerLat = _.get(templateEntry, 'map.centerLat');
  const mapZoom = _.get(templateEntry, 'map.zoom');
  const center = [parseFloat(centerLng || 0), parseFloat(centerLat || 0)];
  const defaultMapView = _.get(getMapViews(), DEFAULT_MAP_VIEW);
  const currentMapStyleEntry = _.first(
    _.get(templateEntry, 'map.style_entries', [{}])
  );

  const mapBounds = useMemo(() => {
    if(showOnlySelectedRows) {
      return getReportDataBoundingPoints(reportPageData, subjectData)
    } else if(isSubjectProperty && !showOnlySelectedRows ){
      return getReportDataBoundingPoints([], subjectData);
    } else { return [] }

  }, [JSON.stringify(reportPageData), showOnlySelectedRows, JSON.stringify(subjectData)]);

  const dispatch = useDispatch();
  const mapAttributes = {
    apiParams: {
      commonFilters: JSON.stringify({}),
      currentDrilldownTemplateId: templateId,
      drilldownEntry: JSON.stringify({
        currentDrilldownTemplateId: templateId,
        quickFilters: filters
      }),
      polygonGeoJson: JSON.stringify(polygonGeoJson),
      advanceSearchSubjectField: JSON.stringify(searchField),
      shapeIds: selectedShapeIds.join(','),
      shapeGroupId: _.get(shapeDatasetEntry, 'shape_dataset_id'),
      selectedReportRowIds: showOnlySelectedRows ? JSON.stringify(selectedReportRowIds) : '[]',
      subjectRowIds: JSON.stringify(subjectRowIds),
      showOnlySelectedRows
    },
    cardImageId: `templateId--${templateId}`,
    center: center,
    currentMapStyleEntry: currentMapStyleEntry,
    currentMapView: defaultMapView,
    drilldown: { currentDrilldownTemplateId: templateId },
    viewEntry: {},
    selectedShapesExtent: {},
    selectedReportRowIds,
    showOnlySelectedRows,
    zoom: Number(mapZoom),
    isAdvancedSearch: true,
    showSearchPoints: !_.isEmpty(filters) || !_.isEmpty(selectedShapeIds) || showOnlySelectedRows,
    searchProperty: searchProperty,
    containerHeight: DEFAULT_MAP_CONTAINER_HEIGHT,
    isSubjectProperty,
    polygonGeoJson,
    isSideBar: isComparisonListOpen
  };

  const onDataLoading = (loading) => {
    onApiDataLoading(loading);
  }

  const onCurrentPeriodMapCreated = (map) => {
    onMapLoaded(map);
    dispatch(updateDrilldownTemplateNameAndId('', templateId));
  }


  const searchMapIconClass = classNames(
    'search-map-visualization-wrapper mt-2',
    { 'draw-icon-filter': isDrawingEnabled }
  );

  return (
    <div className={searchMapIconClass}>
    <Map {...mapAttributes} onDataLoad={onDataLoading}
      onMapCreated={onCurrentPeriodMapCreated} mapBounds={mapBounds} />
      <ExpandPopupHandler/>
    </div>
  );
}

MapView.propTypes = {
  map: PropTypes.object,
  onMapLoaded: PropTypes.func,
  onApiDataLoading: PropTypes.func,
  showOnlySelectedRows: PropTypes.bool,
  isSubjectProperty: PropTypes.bool
};

export default MapView;
