export const handleAfterPlot = (chartContainer) => {
    handleRadarBarChartAfterPlot(chartContainer);
  }

  const getCurrentRotation = (el) => {
    var st = window.getComputedStyle(el, null);
    var tm = st.getPropertyValue("transform") ||
             "none";
    if (tm != "none") {
      var values = tm.split('(')[1].split(')')[0].split(',');
      var angle = Math.round(Math.atan2(values[1],values[0]) * (180/Math.PI));
      return (angle < 0 ? angle + 360 : angle);
    }
    return 0;
  }

  const handleRadarBarChartAfterPlot = (chartContainer) => {

    let xAxisElement = chartContainer.querySelector('g.xtick text')
    let xAxisTickAngle = getCurrentRotation(xAxisElement);
    chartContainer.querySelectorAll('svg').forEach(el => el.style.overflow = 'visible');
    let minHeight = document.getElementsByClassName('radar-barchart')[0].style.minHeight;
    minHeight = minHeight ? parseInt(minHeight.replace('px','')) : 0 ;
    let barWidth = chartContainer.querySelector('svg').getAttribute("width");
    if (xAxisTickAngle == 30){
      chartContainer.querySelector('g.legend').setAttribute('transform', `translate(0,370)`);
      if (minHeight < 470)
        document.getElementsByClassName('radar-barchart')[0].style.minHeight = "480px"
    }else if(xAxisTickAngle == 90){
      chartContainer.querySelector('g.legend').setAttribute('transform', `translate(0,400)`);
      if (minHeight < 520)
        document.getElementsByClassName('radar-barchart')[0].style.minHeight = "530px"
    }else{
      if(barWidth > 250)
        document.getElementsByClassName('radar-barchart')[0].style.minHeight = ""
    }
  };