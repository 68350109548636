import {
    VISUALIZATION_TYPES,
    CURRENCY_TYPE,
    OVERTIME_VISUALIZATION_TYPES
} from 'appConstants';
import {
    getDimensionEntries,
    getNextDrilldownDimensionField,
} from 'common/config/templateConfiguration';
import { disableTableVisualization } from 'modules/DetailsTable/TableHelper';
import {
    getNewQuickFilters,
    getGroupDrilldownDimensionField
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { getDimensionEntry } from 'helpers/templateHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { getDistributionBucketEntry, isComboChartEnable } from 'common/config/visualizationConfiguration';
import {
    getAvailableVisualizationType,
    getSecondaryMetricEntry,
    getVisualizationTitle
} from 'helpers/visualizationHelper';
import { isStackTypeChart } from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { isHeatMap } from 'modules/Map/helpers/MapOptionsHelper';
import {
    canShowProjectionMetric,
    disableShowProjection
} from 'modules/visualization/LineChart/Helpers/projectionHelper';
import { getOvertimeVisualizationTitle } from './visualizations/visualizationRenders/helper';

export const getDrillDownEntries = (propsOption, stateOption) => {
    const {
        currentDrilldownTemplateId,
        drilledDownDimensions,
        currentDrilldownViewEntry,
        isLeafPage,
        currentVisualizationType,
        quickFilters
    } = propsOption;
    const { isLoading } = stateOption;
    const viewEntryName = _.get(currentDrilldownViewEntry, 'name', '');
    const dimensionEntriesSize = _.size(getDimensionEntries(currentDrilldownTemplateId)) - 1;
    const disableTableView = disableTableVisualization(currentDrilldownTemplateId);
    let breadCrumbQuickFilters = _.cloneDeep(quickFilters);
    _.map(drilledDownDimensions, function (dimensionEntry) {
        const drillDownGenericFields = getGroupDrilldownDimensionField(
            currentDrilldownTemplateId, dimensionEntry['field']);
        breadCrumbQuickFilters = _.reject(breadCrumbQuickFilters, { 'field': drillDownGenericFields });
    });

    let drillDownEntries = [{
        'title': viewEntryName,
        'isHomeLink': true,
        'isLoading': isLoading,
        'queryParams': getCurrentDrilldownTotalQueryParams(propsOption, {}, breadCrumbQuickFilters)
    }];
    _.each(drilledDownDimensions, (dimension) => {
        const drillDownGenericFields = getGroupDrilldownDimensionField(
            currentDrilldownTemplateId, dimension['field']);
        const dimensionQuickFilters = _.find(quickFilters, { 'field': drillDownGenericFields });
        if (!_.isEmpty(dimensionQuickFilters)) {
            breadCrumbQuickFilters.push(dimensionQuickFilters);
        }
        const { type, operator, field, values, value, to, index, isDistributionFilter } = dimension;
        const dimensionEntry = getDimensionEntry(currentDrilldownTemplateId, dimension.field);
        let title = values;
        if (isDistributionFilter) {
            title = (operator !== "between") ? `${operator} ${value}` : `${value} - ${to}`;
        } else if (_.get(dimensionEntry, 'renderType') === CURRENCY_TYPE) {
            title = formatValueToCurrency(values[0], true);
        } else if (type == 'number') {
            title = `${value} - ${to}`;
        }

        drillDownEntries.push({
            'title': title,
            'subhead': `${dimensionEntry['name']}`,
            'field': field,
            'fieldIndex': index,
            'value': type == 'number' ? `${value} - ${to}` : values,
            'isLoading': isLoading,
            // If Table Visualization is hidden then in drill-down also hidden.
            'isDisable': index === dimensionEntriesSize && disableTableView,
            'isOtherBucket': _.get(dimension, 'isOthersBucket', false),
            'queryParams': getCurrentDrilldownTotalQueryParams(
                propsOption, dimensionEntry, breadCrumbQuickFilters)
        });
    });

    if (isLeafPage && VISUALIZATION_TYPES.TABLE.type === currentVisualizationType) {
        drillDownEntries.push({
            'title': 'Details',
            'isLeafPageLink': true
        });
    }

    return drillDownEntries;
};

export const dispatchUpdateDrilldownFilters = (propsOption, filters) => {
    const {
        currentDrilldownTemplateId,
        dispatchSetDrilldownDimensions,
        currentDrilldownDimensionField,
        dispatchUpdateDrilldownDimensionField,
        dispatchUpdateQuickFilters,
        drilledDownDimensions,
        currentDrilldownViewEntry
    } = propsOption;
    const newDrilledDownDimensions = _.chain(filters).
        map((filter) => {
            const dimensionField = filter.field;
            const drilledDownDimensionEntry = _.find(drilledDownDimensions, { field: dimensionField });
            if (drilledDownDimensionEntry) {
                const filterValues = _.chain(filter.conditions).
                  find({ operator: '=' }).
                  get('value', []).
                  value();
                return !_.isEmpty(filterValues) ?
                  { ...drilledDownDimensionEntry, values: filterValues } : null;
            } else {
                return null;
            }
        }).
        without(null).
        value();
    const nextDrilldownDimensionField = getNextDrilldownDimensionField(
        currentDrilldownTemplateId,
        newDrilledDownDimensions,
        currentDrilldownViewEntry
    );

    if (_.isEmpty(currentDrilldownDimensionField)) {
        dispatchUpdateDrilldownDimensionField(nextDrilldownDimensionField);
    }

    dispatchSetDrilldownDimensions(newDrilledDownDimensions);
    dispatchUpdateQuickFilters(filters);
}

export const updateBreadcrumbsSelect = (propsOption, breadcrumbEntry) => {
    const {
        currentDrilldownTemplateId,
        currentDrilldownGroupByEntry,
        currentDrilldownDimensionField,
        dispatchUpdateDrilldownDimensionField,
        drilledDownDimensions,
        quickFilters,
        dispatchSetDrilldownDimensions,
        dispatchUpdateQuickFilters,
        dispatchUpdateShowLeafPage,
        currentDrilldownViewEntry,
        currentVisualizationType,
        dispatchSetCurrentVisualizationType,
        dispatchUpdateShowTableViewOnDrilldown
    } = propsOption;
    const groupByFiled = _.get(currentDrilldownGroupByEntry, 'field');
    let dimensionsForCurrentBreadcrumb = [];
    let removedDimensions = drilledDownDimensions;
    let alreadydDrilledDownDimensions = [{ field: groupByFiled }];
    const isDistributionChartype = _.isEqual(
      VISUALIZATION_TYPES.DISTRIBUTION.type, currentVisualizationType);

    if (!breadcrumbEntry.isHomeLink && !breadcrumbEntry.isLeafPageLink) {
        dimensionsForCurrentBreadcrumb = _.filter(drilledDownDimensions, (dimension) => {
            return (dimension['index'] <= breadcrumbEntry['fieldIndex']);
        });
        removedDimensions = _.filter(drilledDownDimensions, (dimension) => {
            return (dimension['index'] > breadcrumbEntry['fieldIndex']);
        });
        alreadydDrilledDownDimensions = alreadydDrilledDownDimensions.concat(dimensionsForCurrentBreadcrumb);
    } else if (breadcrumbEntry.isLeafPageLink) {
        dimensionsForCurrentBreadcrumb = drilledDownDimensions,
            removedDimensions = []
        alreadydDrilledDownDimensions = alreadydDrilledDownDimensions.concat(dimensionsForCurrentBreadcrumb);
    }

    const nextDrilldownDimensionField = getNextDrilldownDimensionField(
        currentDrilldownTemplateId,
        alreadydDrilledDownDimensions,
        currentDrilldownViewEntry
    );

    if (_.isEmpty(nextDrilldownDimensionField) && breadcrumbEntry.isLeafPageLink) {
        dispatchUpdateShowTableViewOnDrilldown(false);
        dispatchUpdateShowLeafPage(true);
    } else {
        dispatchUpdateShowTableViewOnDrilldown(_.isEmpty(nextDrilldownDimensionField));
        dispatchUpdateShowLeafPage(false);
    }

    const newQuickFilters = getNewQuickFilters(
        removedDimensions,
        currentDrilldownTemplateId,
        quickFilters
    );

    if (_.isEmpty(currentDrilldownDimensionField)) {
        dispatchUpdateDrilldownDimensionField(nextDrilldownDimensionField);
    }
    dispatchSetDrilldownDimensions(dimensionsForCurrentBreadcrumb);
    dispatchUpdateQuickFilters(newQuickFilters);
    const bucketEntry = getDistributionBucketEntry(currentDrilldownViewEntry);
    if (isDistributionChartype && _.isEmpty(bucketEntry)) {
        dispatchSetCurrentVisualizationType(VISUALIZATION_TYPES.SNAPSHOT.type);
    }
}

const getCurrentDrilldownTotalQueryParams = (propsOption, dimensionEntry, quickFilters) => {
    const { apiParams, drilledDownDimensions } = propsOption;
    const queryParams = _.cloneDeep(apiParams);
    let drilldownEntry = _.cloneDeep(JSON.parse(queryParams['drilldownEntry']));

    drilldownEntry['currentDrilldownDimensionField'] = _.get(dimensionEntry, 'field', '');
    drilldownEntry['quickFilters'] = quickFilters;

    const dimensionsForCurrentBreadcrumb = _.filter(drilledDownDimensions, (dimension) => {
        return (dimension['index'] <= dimensionEntry['index']);
    });

    drilldownEntry['drilledDownDimensions'] = dimensionsForCurrentBreadcrumb;
    queryParams['drilldownEntry'] = JSON.stringify(drilldownEntry);

    return queryParams;
}

export const renderVisualizationTitle = (boundaryMapName, propsOption, chartFormattedData = []) => {
    const {
      currentDrilldownViewEntry,
      currentVisualizationType,
      currentSnapshotView,
      currentSecondaryMetricField,
      currentDrilldownDimensionField,
      currentDrilldownTemplateId,
      currentDrilldownGroupByEntry,
      currentMapView,
      dimensionConfigsByRenderType,
      currentChartView
    } = propsOption;

    let visualizationTitle = getVisualizationTitle({
      currentVisualizationType,
      currentDrilldownViewEntry,
      currentSnapshotView,
      currentSecondaryMetricField,
      currentDrilldownDimensionField,
      currentDrilldownTemplateId,
      currentDrilldownGroupByEntry
    });

    const availableVizType = getAvailableVisualizationType(
      currentDrilldownTemplateId,
      currentVisualizationType,
      currentDrilldownViewEntry
    );
    // const isTableVisualization = VISUALIZATION_TYPES.TABLE.type === availableVizType;
    const isOvertimeVisualization = VISUALIZATION_TYPES.OVERTIME.type === availableVizType;
    const isMapVisualization = VISUALIZATION_TYPES.MAP.type === availableVizType;
    const isDistributionVisualization = VISUALIZATION_TYPES.DISTRIBUTION.type === availableVizType;

    const currentSecondaryMetricEntry = getCurrentSecondaryMetricEntry(propsOption);
    const isStackedChart = isStackTypeChart({ secondaryMetricEntry: currentSecondaryMetricEntry });

    const primaryMetricName = getPrimaryMetricName(currentDrilldownViewEntry);
    let showProjectionButton = false;
    if(isMapVisualization){
      const startTitle = isHeatMap(currentMapView) ?
        `Concentration of ${primaryMetricName}` : `${primaryMetricName}`;
      visualizationTitle = `${startTitle}${boundaryMapName}`
    } else if(isDistributionVisualization){
      visualizationTitle = primaryMetricName
    } else if(isOvertimeVisualization){
      showProjectionButton = (
        !_.isEqual(OVERTIME_VISUALIZATION_TYPES.AREA.type, currentChartView) &&
        canShowProjectionMetric(currentDrilldownViewEntry, currentChartView) &&
        !disableShowProjectionOption(propsOption, chartFormattedData)
      );
      const isComboChart = isComboChartEnable(currentDrilldownViewEntry, currentChartView)
      visualizationTitle = getOvertimeVisualizationTitle(dimensionConfigsByRenderType,
        propsOption, isComboChart);
    }

    return { visualizationTitle, isStackedChart, showProjectionButton  }
  }

  const getCurrentSecondaryMetricEntry = (propsOption) => {
    const {
      currentDrilldownViewEntry,
      currentVisualizationType,
      currentSnapshotView,
      currentSecondaryMetricField
    } = propsOption;

    return getSecondaryMetricEntry(
      currentDrilldownViewEntry,
      currentVisualizationType,
      currentSnapshotView,
      currentSecondaryMetricField
    );
  }

  const disableShowProjectionOption = (propsOption, chartFormattedData) => {
    const {
      commonFilters,
      currentDrilldownViewEntry ,
      currentSelectedTimeFrame,
      axisGranularity
    } = propsOption;
    const dateRange = _.get(commonFilters, 'dateRange', {});
    return disableShowProjection(
      dateRange,
      chartFormattedData,
      currentSelectedTimeFrame,
      currentDrilldownViewEntry,
      axisGranularity
    );
  }
