import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './Header';

import SearchLeftSide from './SearchResultPage/SearchLeftSide';
import RightSideContainer from './RightSideContainer';
import classNames from 'classnames';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getTableColumnEntries } from 'common/config/templateConfiguration';

const SearchResultPage = (props) => {
  const { searchField } = props;
  const [map, setMap] = useState({});
  const [isSideBar, setIsSideBar] = useState(false);
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));
  const detailsEntries = getTableColumnEntries(templateId);
  const isComparisonListOpen = useSelector(state => state.advanceSearch.isComparisonListOpen);
  const toggleClassName = isComparisonListOpen ? 'collapsed' : '';

  const onMapLoaded = (newMap) => {
    setMap(newMap);
  }

  const onToggleButtonClick = () => {
    setIsSideBar(!isSideBar);
  }

  const onToggleButtonKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      onToggleButtonClick();
    }
  }

  const leftSideClassNames = classNames(
    { "toggle-sidebar-hide": isSideBar }
  );

  return (
    <div className={leftSideClassNames}>
      <div className="drilldown-page custom-search-page">
        <Header
          detailsEntries={detailsEntries}
          searchField={searchField}>
        </Header>
        <div className="analysis-page forge-inline-message">
          <div className="filters-section drawer-panel">
            <div className="toggle-btn"
              tabIndex={0}
              onClick={() => onToggleButtonClick()}
              onKeyDown={(e) => onToggleButtonKeyDown(e)}>
              <span className="icons-chevron-left"></span>
            </div>
            <SearchLeftSide map={map} />
          </div>

          <div className={`content-section ${toggleClassName}`}>
            <RightSideContainer
            onMapLoaded={onMapLoaded} />
          </div>

        </div>
      </div>
    </div>
  );
}

SearchResultPage.propTypes = {
  searchField: PropTypes.object
};

export default SearchResultPage;
