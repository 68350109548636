import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  enableCollectionLevelDateFilters,
} from 'common/config/customerConfiguration';

class ResetFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFilters: _.cloneDeep(_.get(props, 'dateFilters')),
      collectionFilters: _.cloneDeep(_.get(props, 'collectionFilters')),
      quickFilters: _.cloneDeep(_.get(props, 'quickFilters')),
      filteredGeojson: _.cloneDeep(_.get(props, 'filteredGeojson'))
    };
  }

  handleResetBtn = () => {
    this.props.onResetFilter(_.cloneDeep(this.state));
  }

  handleKeyDown = (e) => {
    if(isEnterButtonPressed(e)) {
      this.handleResetBtn();
    }
  }

  render() {
    const { dateFilters, collectionFilters, quickFilters, filteredGeojson } = this.props;
    const isDateFiltersChanged = enableCollectionLevelDateFilters() &&
      !_.isEqual(dateFilters, this.state.dateFilters);
    const isCollectionFiltersChanged = !_.isEqual(collectionFilters, this.state.collectionFilters);
    const isQuickFiltersChanged = !_.isEqual(quickFilters, this.state.quickFilters);
    const filteredGeojsonChanged = !_.isEqual(filteredGeojson, this.state.filteredGeojson);
    const anyoneFiltersUpdated = (isDateFiltersChanged || isCollectionFiltersChanged ||
      isQuickFiltersChanged || filteredGeojsonChanged);

    return anyoneFiltersUpdated && (
      <span
        className="float-right reset-link forge-typography--caption"
        tabIndex={0}
        role="button"
        aria-label="Reset all filters"
        onClick={this.handleResetBtn}
        onKeyDown={this.handleKeyDown}
      >Reset</span>
    );
  }
}

ResetFilters.propTypes = {
  dateFilters: PropTypes.object,
  collectionFilters: PropTypes.array,
  quickFilters: PropTypes.array,
  onResetFilter: PropTypes.func,
  filteredGeojson: PropTypes.object
}

export default ResetFilters;
