import _ from 'lodash';
import {
  UPDATE_INSIGHTS_ID,
  UPDATE_HISTORY_TAB_HASH
} from 'actions/insightActions';

export const insightReducerDefaultState = () => {
  return {
    insightId: '',
    historyTabName: ''
  };
};

export default function(state = insightReducerDefaultState(), action) {
  switch (action.type) {
    case UPDATE_INSIGHTS_ID:
      return {
        ...state,
        insightId: _.get(action, 'insightId', '')
      }

    case UPDATE_HISTORY_TAB_HASH:
      return {
        ...state,
        historyTabHash: _.get(action, 'hash', '')
      }
    default:
      return state;
  }
}
