import {
  USER_PAGE_CONFIGS,
  SECURITYLIST_PAGE,
  BRANDING_PAGE
} from 'appConstants';
import { getSecurityRoles, securityUsers } from 'common/config/customerConfiguration';

export const getUserListPageBasedOnPermission = (userFromBellerophon) => {
  const userDetails = _.get(userFromBellerophon, 'bellerophon_user_detail', {});
  const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
  if(isLaunchpadAdmin){
    return USER_PAGE_CONFIGS
  }

  return _.filter(USER_PAGE_CONFIGS, (userPageConfig) => {
    const { page } = userPageConfig;

    if (page === BRANDING_PAGE) {
      return _.get(userDetails, 'isManageBranding', 'false') === 'true';
    } else if(page === SECURITYLIST_PAGE) {
      return _.get(userDetails, 'isManageUser', 'false') === 'true';
    }else {
      const currentUserEmail = _.get(userDetails, 'email', '');
      const isUserInSecurityList = !_.isEmpty(_.find(securityUsers(), { 'email': currentUserEmail }));
      return isUserInSecurityList;
    }
  });
}

export const getUserRoleOption = (userFromBellerophon) => {
  const userRole = _.get(userFromBellerophon, 'bellerophon_user_detail.role');
  const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
  if(isLaunchpadAdmin){
    return {
      roleName: 'Launchpad Admin',
      roleDescription: ''
    }
  }

  const matchedUserRole = _.find(getSecurityRoles(), (securityRole) => {
    const { role_id } = securityRole;
    return (role_id == userRole);
  });

  return {
    roleName: _.get(matchedUserRole, 'name', 'Viewer'),
    roleDescription: _.get(matchedUserRole, 'description', ''),
    matchedUserRole
  };
}

export const isUserIsInSecurityList = (userEmail) => {
  return  !_.isEmpty(_.find(securityUsers(), (user) => {
    return _.toLower(user['email']) === _.toLower(userEmail);
  }));
}
