import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import {
  WEEK_NUMBER_OPTIONS,
  WEEKDAYS_OPTIONS,
  MONTHLY_REGULARITY_TYPES,
  DATE_FORMAT,
  MONTH_DAY_OPTIONS
} from 'appConstants';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import RadioButton from '../RadioButton';
import ForgeDateInputPicker from '../ForgeDatePicker/ForgeDateInputPicker';
import { dateWithTimeZone } from 'helpers/dateHelper';

const FrequencySettingOption = (props) => {
  const {
    emailSendingDays,
    frequency,
    monthlyWeekNumber,
    handleWeekdaysOptionsChange,
    onSettingValueChange,
    startDate,
    regularityType = MONTHLY_REGULARITY_TYPES.WEEK,
    timePeriodDay = 1,
    isDisabled
  } = props;
  const selectedWeekNumber = _.find(WEEK_NUMBER_OPTIONS, { 'value': monthlyWeekNumber});
  const selectedTimePeriodDay = _.find(MONTH_DAY_OPTIONS, { 'value': Number(timePeriodDay)});
  const isMonthFrequency= frequency === 'month';

  const handlePressWeekdaysOption = (e, option, isChecked) => {
    if(isEnterButtonPressed(e)) {
      handleWeekdaysOptionsChange(!isChecked, option);
    }
  }

  const weekdaysOptions = _.map(WEEKDAYS_OPTIONS, (option, index) =>{
    const isChecked = _.includes(emailSendingDays, option['day'])
    return (
      <label
        className="options-checkbox"
        tabIndex="0"
        onKeyDown={(e) => handlePressWeekdaysOption(e, option['day'], isChecked)}
        key={option['day']+index}>
        <input type="checkbox"
          className="checkbox-input"
          checked={isChecked}
          disabled={isDisabled}
          onChange={(e) => handleWeekdaysOptionsChange(e.target.checked, option['day'])}/>
          <span>{option['abbrName']}</span>
      </label>
    );
  });

  const onChangeStartDate = (date) => {
    const newStartDate = moment(date.toString()).format(DATE_FORMAT);

    onSettingValueChange(newStartDate, 'startDate');
  }

  const renderDays = () => {
    return(
      <SolutionDropDown
        size="sm ml-2"
        className="subscription-dropdown dropdown-caret-none"
        title= {_.get(selectedTimePeriodDay, 'name')}
        options={MONTH_DAY_OPTIONS}
        disabled={isDisabled}
        onSelect={(e) => onSettingValueChange(e['value'], 'timePeriodDay')}
      />
    );
  }

  const startDateOption = () => {
    const newStartDate = startDate ? dateWithTimeZone(startDate).format(DATE_FORMAT) :
      dateWithTimeZone().format(DATE_FORMAT);
    const maxDate = moment(newStartDate).add(10, 'year').format(DATE_FORMAT);
    var minDate = dateWithTimeZone().startOf('day').format(DATE_FORMAT);

    if(moment(newStartDate) < moment(minDate)){
      minDate = dateWithTimeZone(newStartDate).startOf('day').format(DATE_FORMAT);
    }

    return(
      <div className='frequency-date-picket d-flex align-items-center gap-10'>
        <label className="m-0">Starting</label>
        <ForgeDateInputPicker
            value={newStartDate}
            minDate={minDate}
            maxDate={maxDate}
            isDisabled={isDisabled}
            label=""
            handleChangeDate={onChangeStartDate} />
      </div>
    );
  }

  const renderWeekDropdown = () => {
    return(
      <SolutionDropDown
        size="sm ml-2"
        className="subscription-dropdown dropdown-caret-none"
        title= {_.get(selectedWeekNumber, 'name')}
        options={WEEK_NUMBER_OPTIONS}
        disabled={isDisabled}
        onSelect={(e) => onSettingValueChange(e['value'], 'monthlyWeekNumber')}
      />
    );
  }

  const renderWeekDays = (showDays = false) => {
    return(
      <div className='d-flex'>
        {!showDays ? <span>on</span> : <span>on the</span>}
        {(isMonthFrequency && !showDays) && <span>{renderWeekDropdown()}</span>}
        {isMonthFrequency && showDays && renderDays()}
        {!showDays && <span className="week-days-options ml-2">{weekdaysOptions}</span>}
        {showDays && <span className='ml-1'>day of the month</span> }
      </div>
    )
  }

  const renderRadioButton = (type) => {
    const radioButtonAttributes = {
      id: `monthly-${type}`,
      isChecked: type === regularityType,
      value: type,
      onChange: (value) => onSettingValueChange(value, 'regularityType'),
    };

    return (<RadioButton {...radioButtonAttributes} disabled={isDisabled} />);
  }

  const renderWeekDaysAndRadioButton = (type, showDays = false) => {
    return (
      <div className="frequency-setting-option" role="radiogroup">
        {isMonthFrequency && renderRadioButton(type)}
        {renderWeekDays(showDays)}
      </div>
    );
  }

  if(frequency === 'day'){
    return startDateOption();
  }

  return(
    <>
      {renderWeekDaysAndRadioButton(MONTHLY_REGULARITY_TYPES.WEEK)}
      {isMonthFrequency && renderWeekDaysAndRadioButton(MONTHLY_REGULARITY_TYPES.DAY,true)}
      {startDateOption()}
    </>
  );
}

FrequencySettingOption.propTypes = {
  emailSendingDays: PropTypes.array,
  frequency: PropTypes.string,
  handleWeekdaysOptionsChange: PropTypes.func,
  onSettingValueChange: PropTypes.func,
  monthlyWeekNumber: PropTypes.number,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  regularityType: PropTypes.string,
  timePeriodDay: PropTypes.number,
  isDisabled: PropTypes.bool,
}

export default FrequencySettingOption;
