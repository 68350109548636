import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { toast } from 'react-toastify';

import LoadingSpinner from 'common/components/LoadingSpinner';
import CollectionPage from './CollectionPage';
import SubscriptionPage from './SubscriptionPage';
import CollectionBar from './CollectionBar';
import { deleteSubscription, deleteManagedSubscription } from 'common/api/subscriptionApi';

import {
  getUserAllCollections,
  deleteCollection,
  resetCollection,
  copyCollection
} from 'common/api/collectionApi';
import { isMyViews } from 'pages/dashboard/components/CardList/cardHelper';
import { getPermittedCollection } from 'pages/dashboard/components/Collections/collectionHelper';
import {
  collectionDeleteMessage,
  collectionDeleteErrorMessage,
  subscriptionDeleteMessage,
  subscriptionDeleteErrorMessage,
  collectionCopiedMessage,
  collectionCopiedErrorMessage
} from 'helpers/toastMessages';
import {
  updateMangeViewCollectionId,
  updateMangeViewSubscriptionId
} from 'actions/managePageActions';
import { isCollaborateCollection } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';

class CollectionsManager extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      collections: [],
      oldCollections: [],
      isLoading: false,
      currentCollection: {},
      currentSubscription: {},
      reLoadCollection: true,
      isErrorInRecipients: [],
    };
  }

  componentDidMount() {
    this.fetchCollections();
  }

  componentDidUpdate() {
    const { reLoadCollection } = this.state;
    const { isCollectionSaved } = this.props;

    if (reLoadCollection && isCollectionSaved) {
      this.fetchCollections();
    }
  }

  fetchCollections = () => {
    this.setState({ isLoading: true, reLoadCollection: false });
    getUserAllCollections()
      .then((response) => response.json())
      .then((response) => {
        const collections = _.sortBy(getPermittedCollection(response), 'sort_order');
        const defaultCollection = this.getDefaultCollection(collections);
        const defaultSubscription = this.getDefaultSubscription(collections);
        const defaultErrorInRecipients = this.setDefaultErrorInRecipients(collections);
        this.setState({
          collections,
          oldCollections: _.cloneDeep(collections),
          currentCollection: defaultCollection,
          currentSubscription: defaultSubscription,
          isLoading: false,
          isErrorInRecipients: defaultErrorInRecipients
        });
      });
  };

  setDefaultErrorInRecipients = (collections) => {
    const defaultErrorInRecipients = [];
    _.each(collections, (collection) => {
      _.each(collection['collection_subscriptions'], (subscription) => {
        if (subscription['is_collaborate_subscription']) {
          defaultErrorInRecipients.push({ id: subscription['id'], isError: false })
        }
      })
    });
    return defaultErrorInRecipients;
  }

  getDefaultCollection = (collections) => {
    const { currentCollectionId } = this.props;
    const collectionFromUrl = _.find(collections, { id: Number(currentCollectionId) })
    return _.isEmpty(collectionFromUrl) ? _.get(collections, '[0]', {}) : collectionFromUrl;
  }

  getDefaultSubscription = (collections) => {
    const { currentCollectionId, currentSubscriptionId } = this.props;
    const collection = _.find(collections, { id: Number(currentCollectionId) })
    const collectionSubscriptions = _.get(collection, 'collection_subscriptions', []);
    const subscription = _.find(collectionSubscriptions, { id: Number(currentSubscriptionId) });
    return _.isEmpty(subscription) ? {} : subscription;
  }

  updateCollectionEntry = (collections, newEntry) => {
    const newCollections = _.map(collections, (collectionEntry) => {
      if (newEntry.id == collectionEntry.id) {
        return newEntry;
      } else {
        return collectionEntry;
      }
    });
    return newCollections;
  }

  handleUpdateCollection = (collection, isErrorInRecipientsSub) => {
    const { currentSubscription } = this.state;
    const { currentCollection, collections, oldCollections, isErrorInRecipients } = this.state;
    const { onCollectionsUpdate } = this.props;
    let newCollection = currentCollection;

    if (currentCollection.id == collection.id) {
      newCollection = collection;
    }

    const newCollections = this.updateCollectionEntry(collections, collection);
    let subscriptionsRecipientErrorCheck = isErrorInRecipients;
    _.each(subscriptionsRecipientErrorCheck, (subscriptionRecipient) => {
      if (subscriptionRecipient['id'] == currentSubscription.id) {
        subscriptionRecipient['isError'] = isErrorInRecipientsSub;
      }
    });

    const isErrorFound = _.find(subscriptionsRecipientErrorCheck, (subscriptionRecipient) => {
      return subscriptionRecipient.isError
    });

    this.setState({
      collections: newCollections,
      currentCollection: newCollection,
      reLoadCollection: true,
      isErrorInRecipients: subscriptionsRecipientErrorCheck
    });

    const isCollectionChanges = !_.isEqual(newCollections, oldCollections) && _.isEmpty(isErrorFound);
    onCollectionsUpdate(newCollections, isCollectionChanges);
  }

  handleResetCollectionClick = (currentCollection) => {
    const { currentUser, onCollectionsUpdate } = this.props;
    const { collections } = this.state;
    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      resetCollection(_.get(currentCollection, 'id'))
        .then((response) => response.json())
        .then((response) => {
          const newCollections = this.updateCollectionEntry(collections, response)
          this.setState({
            isLoading: false,
            collections: newCollections,
            currentCollection: response
          })
          onCollectionsUpdate(newCollections);
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log('Error on reset collection', error);
        });


    }
  }
  handleCollectionClick = (collection) => {
    this.setState({
      currentCollection: collection,
      currentSubscription: {}
    });
    this.props.dispatchUpdateCollectionId(collection.id);
  }

  handleSubscriptionClick = (subscription, collection) => {
    this.setState({
      currentCollection: collection,
      currentSubscription: subscription
    });
    this.props.dispatchUpdateSubscriptionId(collection.id, subscription.id);
  }

  handleCollectionDelete = (collection) => {
    const { currentUser, onCollectionsUpdate } = this.props;
    const { collections, currentCollection } = this.state;

    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      deleteCollection(_.get(currentCollection, 'id'))
        .then((response) => response.json())
        .then((response) => {
          if (response.ok || response.message) {
            const newCollections = _.filter(collections, (collectionEntry) => {
              return collection.id != collectionEntry.id && collectionEntry.name != 'My Views'
            })
            const sortedCollections = _.sortBy(newCollections, 'sort_order');
            const firstCollection = _.get(sortedCollections, '0', {});
            this.setState({
              isLoading: false,
              collections: newCollections,
              currentCollection: firstCollection
            });
            onCollectionsUpdate(newCollections);
            this.props.dispatchUpdateCollectionId(_.get(firstCollection, 'id', ''));
            toast.success(collectionDeleteMessage);
          } else {
            this.setState({ isLoading: false });
            toast.error(collectionDeleteErrorMessage);
          }
        });
    }
  };

  handleCopyCollection = (copyCollectionName) => {
    const { currentCollection, collections } = this.state;
    const { currentUser } = this.props;
    const lastSortOrder = _.get(_.maxBy(collections, 'sort_order'), 'sort_order', 0);
    if (!_.isEmpty(copyCollectionName) && !_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      let collection = {
        name: _.trim(copyCollectionName),
        sort_order: lastSortOrder + 1,
        is_hidden: _.get(currentCollection, 'is_hidden', false),
        dateFilters: _.get(currentCollection, 'dateFilters', {}),
        collectionFilters: _.get(currentCollection, 'collectionFilters', []),
        copied_collection_id: _.get(currentCollection, 'id'),
      };

      copyCollection(collection)
        .then((response) => {
          if (!response.ok) {
            this.setState({ isLoading: false });
            console.error('error on collection copy', response);
            toast.error(collectionCopiedErrorMessage);
            return null;
          }
          return response.json();
        })
        .then((response) => {
          this.setState({
            isLoading: false
          });
          if (!_.isEmpty(response)) {
            toast.success(collectionCopiedMessage);
            this.fetchCollections();
          }
        });
    }
  };

  handleSubscriptionDelete = (collection, subscription) => {
    this.setState({ isLoading: true });
    const isCollaborateSubscription = subscription['is_collaborate_subscription'];
    if (isCollaborateSubscription) {
      this.deleteManagedSubscription(collection, subscription);
    } else {
      this.deleteSubscription(collection, subscription);
    }
  }

  deleteSubscription = (collection, subscription) => {
    const { onCollectionsUpdate } = this.props;
    const { collections } = this.state;
    let newSubscription = {};
    deleteSubscription(_.get(collection, 'id'), _.get(subscription, 'id'))
      .then((response) => response.json())
      .then((response) => {
        if (response.ok || response.message) {
          const newCollections = _.map(collections, (collectionEntry) => {
            if (collection.id == collectionEntry.id) {
              const collectionSubscriptions = _.get(collectionEntry, 'collection_subscriptions', [])
              const newSubscriptions = _.filter(collectionSubscriptions, (subscriptionEntry) => {
                return subscription.id != subscriptionEntry.id
              });
              collectionEntry['collection_subscriptions'] = newSubscriptions;
              newSubscription = _.get(newSubscriptions, '[0]', {});
            }
            return collectionEntry;
          })
          this.setState({
            isLoading: false,
            collections: newCollections,
            currentSubscription: newSubscription
          });
          this.props.dispatchUpdateSubscriptionId(collection.id, newSubscription.id);
          onCollectionsUpdate(newCollections);
          toast.success(subscriptionDeleteMessage);
        } else {
          this.setState({ isLoading: false });
          toast.error(subscriptionDeleteErrorMessage);
        }
      });
  }

  deleteManagedSubscription = (collection, subscription) => {
    const { onCollectionsUpdate } = this.props;
    const { collections } = this.state;
    let newSubscription = {};
    deleteManagedSubscription(_.get(subscription, 'collection_id'), _.get(subscription, 'id'))
        .then((response) => {
          if (response.ok || response.message) {
            const newCollections = _.map(collections, (collectionEntry) => {
              if (collection.id == collectionEntry.id) {
                const collectionSubscriptions = _.get(collectionEntry, 'collection_subscriptions', [])
                const newSubscriptions = _.filter(collectionSubscriptions, (subscriptionEntry) => {
                  return subscription.id != subscriptionEntry.id
                });
                collectionEntry['collection_subscriptions'] = newSubscriptions;
                newSubscription = _.get(newSubscriptions, '[0]', {});
              }
              return collectionEntry;
            })
            this.setState({
              isLoading: false,
              collections: newCollections,
              currentSubscription: newSubscription
            });
            this.props.dispatchUpdateSubscriptionId(collection.id, newSubscription.id);
            onCollectionsUpdate(newCollections);
            toast.success(subscriptionDeleteMessage);
          } else {
            this.setState({ isLoading: false });
            toast.error(subscriptionDeleteErrorMessage);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(`Error on deleting Manged Subscription`, error);
        });
  };

  handleUpdateSubscription = (collection, subscription, isErrorInRecipientsSub = false) => {
    const { currentSubscription } = this.state;
    let newSubscription = currentSubscription;

    if (subscription.id == currentSubscription.id) {
      newSubscription = subscription;
    }

    this.setState({ currentSubscription: newSubscription });
    this.handleUpdateCollection(collection, isErrorInRecipientsSub);
  }

  renderSubscriptionDetails() {
    const { currentUser } = this.props;
    const { currentCollection, currentSubscription } = this.state;
    if (_.isEmpty(currentSubscription)) {
      return null;
    }

    return (
      <div className="right-side subscriptions-inner">
        <SubscriptionPage
          isManageCollection={isCollaborateCollection(currentCollection)}
          currentCollection={currentCollection}
          currentSubscription={currentSubscription}
          onUpdateSubscription={this.handleUpdateSubscription}
          onDelete={this.handleSubscriptionDelete}
          currentUser={currentUser}
        />
      </div>
    )
  }

  renderCollectionDetails() {
    const { currentUser } = this.props;
    const { currentCollection, currentSubscription } = this.state;
    if (_.isEmpty(currentCollection) || !_.isEmpty(currentSubscription)) {
      return null;
    }

    return (
      <div className="right-side collections-inner">
        <CollectionPage
          isManageCollection={isCollaborateCollection(currentCollection)}
          onCollectionDelete={this.handleCollectionDelete}
          onCollectionUpdate={this.handleUpdateCollection}
          onCollectionCopy={this.handleCopyCollection}
          currentUser={currentUser}
          currentCollection={currentCollection}
          onResetCollectionClick={this.handleResetCollectionClick}
        />
      </div>
    )
  }

  renderCollection(collection, index) {
    const {
      currentCollection,
      currentSubscription
    } = this.state;
    const isSelected = (_.isEqual(collection.id, currentCollection.id) &&
      _.isEmpty(currentSubscription));

    return (
      <CollectionBar
        key={index}
        index={index}
        isManageCollection={isCollaborateCollection(collection)}
        collection={collection}
        isSelected={isSelected}
        currentSubscription={currentSubscription}
        onCollectionClick={this.handleCollectionClick}
        onSubscriptionClick={this.handleSubscriptionClick}
        onCollectionUpdate={this.handleUpdateCollection} />
    )
  }

  renderCollections() {
    const { collections } = this.state;
    const collectionContents =  _.sortBy(collections, 'sort_order').map((collection, index) => {
      return isMyViews(collection) ?
        null :
        this.renderCollection(collection, index);
    });

    return (
      <Accordion defaultActiveKey="0">
        {collectionContents}
      </Accordion>
    )
  }

  renderCollectionList() {
    return (
      <div className="collection-filter sidebar">
        <div className="sidebar-nav">
          {this.renderCollections()}
        </div>
      </div>
    )
  }

  render() {
    const { collections, isLoading } = this.state;

    const collectionData = _.compact(_.map(collections, (collection) => {
      return isMyViews(collection) ? null : collection;
    }));

    if (_.isEmpty(collectionData) && !isLoading) {
      return (
        <div className="content-wrapper">
          <div className="no-collection-msg">
            <div className="msg">
              <h6>You have no collections. <br />
                You can make a new collection on the overview screen.
              </h6>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="content-wrapper">
        <LoadingSpinner isLoading={isLoading} />
        {this.renderCollectionList()}
        {this.renderCollectionDetails()}
        {this.renderSubscriptionDetails()}
      </div>
    )
  }
}

CollectionsManager.propTypes = {
  onCollectionsUpdate: PropTypes.func,
  dispatchUpdateCollectionId: PropTypes.func,
  dispatchUpdateSubscriptionId: PropTypes.func,
  currentUser: PropTypes.object,
  isCollectionSaved: PropTypes.bool,
  currentCollectionId: PropTypes.string,
  currentSubscriptionId: PropTypes.string
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateCollectionId: (collectionId) => {
      dispatch(updateMangeViewCollectionId(collectionId));
      dispatch(updateMangeViewSubscriptionId(collectionId, ''));
    },
    dispatchUpdateSubscriptionId: (collectionId, subscriptionId) => {
      dispatch(updateMangeViewSubscriptionId(collectionId, subscriptionId));
    }
  }
}

function mapStateToProps(state) {
  return {
    currentCollectionId: _.get(state, 'manage.collectionId', ''),
    currentSubscriptionId: _.get(state, 'manage.subscriptionId', '')
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsManager);
