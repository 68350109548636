import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { getNotesData, onSaveNotes, getSearchAssigneeName } from 'common/api/commonApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import HistoryPage from './HistoryPage' ;
import abortableFetch from 'common/abortableFetch';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

const moment = extendMoment(Moment);

class NotesPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      notesEntry: {},
      loading: false,
      isSavingNotes: false,
      assignee: [],
      assigneeNames: [],
      message: '',
      editMode: false,
      lastCalled: null,
      user_email: '',
      loadingAssigneeNames: false,
      notesHistory: []
    };
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.fetchNotesData();
  }

  componentDidUpdate(prevProps) {
    if(!_.isEqual(prevProps.noteId, this.props.noteId) && !_.isEmpty(this.props.noteId)) {
      this.fetchNotesData();
    }
  }

  fetchNotesData() {
    this.setState({ loading: true });
    const { currentDrilldownTemplateId, noteId } = this.props;
    const params = {
      drilldownEntry: JSON.stringify({ currentDrilldownTemplateId }),
      id: noteId
    };
    const getNotesDataApiUrl = getNotesData(params);
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    abortableFetch(getNotesDataApiUrl, { signal: this.abortFetchController.signal })
      .then(response => response.json()).then((response) => {
        const notesEntry = _.get(response, 'results[0]', {});
        const notesHistory = _.get(response, 'history', []);
        let lastCalled = _.get(notesEntry, 'last_called', '');
        if(lastCalled) {
         lastCalled = moment(lastCalled).format('YYYY-MM-DD h:mm a');
        }
        this.setState({
          notesHistory: notesHistory,
          notesEntry,
          loading: false,
          assignee: [_.get(notesEntry, 'assignee', '')],
          message: _.get(notesEntry, 'message', ''),
          lastCalled
        });
    }).catch((error) => {
      console.error('Error on fetch notes', error)
      this.setState({ loading: false, notesEntry: {} })
    });
  }

  onSaveClick = (notesEntry) => {
    this.setState({ isSavingNotes: true });
    const { currentDrilldownTemplateId } = this.props;
    const params = {
      drilldownEntry: JSON.stringify({ currentDrilldownTemplateId }),
      ...notesEntry
    };

    onSaveNotes(params).then(() => {
      this.setState ({ isSavingNotes: false });
      this.fetchNotesData();
    }).catch((err) => {
      this.setState({isSavingNotes: false});
      console.error('Error on saving notes', err)
    });
  }

  onEditClick = () => {
    this.setState({ editMode: true });
  }

  onCancelClick = () => {
    const { notesEntry } = this.state;
    this.setState({
      editMode: false,
      assignee: [_.get(notesEntry, 'assignee', '')],
      message: _.get(notesEntry, 'message', '')
    });
  }

  handleLastCalledDateChange = (date) => {
    if(date){
      this.setState({lastCalled: moment(date).format('YYYY-MM-DD h:mm a')});
    } else {
      this.setState({lastCalled: null});
    }
  }

  handleTypeHeadSelection = (selectedItems) => {
    this.setState({ assignee: selectedItems });
  }

  fetchAssigneeNames = (searchInput) => {
    const { currentDrilldownTemplateId, } = this.props;
    this.setState({ loadingAssigneeNames: true, assignee: [searchInput] });
    const params = {
      drilldownEntry: JSON.stringify({ currentDrilldownTemplateId }),
      field: 'assignee',
      searchText: searchInput
    };

    getSearchAssigneeName(params).
      then(response => response.json()).then((response) => {
        this.setState({
          assigneeNames: response,
          loadingAssigneeNames: false
        });
      }).catch((err) => {
        console.log('Error on column Values ', err); // eslint-disable-line no-console
        this.setState({ loadingAssigneeNames: false, assigneeNames: [] });
      });
  }

  handleNowButtonClick = () => {
    // updating current Date time
    const now = moment().format('YYYY-MM-DD h:mm a');
    this.setState({lastCalled: now});
  }

  onNotesValueChange = (e) => {
    this.setState({ message: e.target.value });
  }

  onAddNotes = () => {
    const { assignee, message, lastCalled } = this.state;
    const { noteId, currentUserEmail } = this.props;
    let last_called;
    if(_.isEmpty(lastCalled)) {
      last_called = moment().format('YYYY-MM-DDTHH:mm:ss');
    } else {
      last_called = moment(lastCalled).format('YYYY-MM-DDTHH:mm:ss');
    }

    const notesEntry =  {
      assignee: assignee[0],
      message,
      last_called: last_called,
      user_email: currentUserEmail,
      id: noteId
    };
    this.setState({ editMode: false });
    this.onSaveClick(notesEntry);
  }

  renderAssigneeField() {
    const { assignee, assigneeNames, editMode, loadingAssigneeNames } = this.state;
    return (
      <div className="notify-text">
        <label className="custom-label text-muted"> Assignee</label>
        <AsyncTypeahead
          multiple={false}
          disabled={!editMode}
          id="'assign-map-id"
          minLength={1}
          onChange={this.handleTypeHeadSelection}
          isLoading={loadingAssigneeNames}
          onSearch={this.fetchAssigneeNames}
          options={assigneeNames}
          selected={assignee}
        />
      </div>
    );
  }

  renderLastCalled() {
    const { lastCalled, editMode } = this.state;
    let lastCalledDate;
    if(lastCalled) {
      lastCalledDate = moment(lastCalled).toDate();
    }
    let endDate = moment().toDate();
    return(
      <div className="last-call">
        <label className="custom-label text-muted"> Last called</label>
        <DatePicker
          className="datepicker-input form-control"
          selected={lastCalledDate}
          startDate={lastCalledDate}
          disabled={!editMode}
          endDate={endDate}
          dateFormat="MMM d, yyyy h:mm aa"
          showTimeInput={true}
          timeInputLabel="Time:"
          onChange={this.handleLastCalledDateChange}/>
      </div>
    )
  }

  renderNowButton() {
    const { editMode } = this.state;
    return (
      <div className="current-date-time-button pt-5">
        <button disabled={!editMode} className="btn btn-link p-0"
              onClick={this.handleNowButtonClick}> <u>Now</u>
        </button>
      </div>
    );
  }

  renderMessageField() {
    const { message , editMode } = this.state;
    return(
      <div>
        <label className="custom-label text-muted"> Message</label>
        <textarea
          disabled={!editMode}
          rows="5"
          placeholder="Enter notes..."
          value={message}
          className="form-control mb-2"
          onChange={this.onNotesValueChange} />
      </div>
    )
  }

  renderFormButtons() {
    const { editMode } = this.state;
    const { isModelView } = this.props;
    if(isModelView) {
      return null;
    }
    return (
      <div className="d-fle align-items-center">
        <button
          className="btn btn-outline-primary btn-sm mr-2"
          onClick={editMode ? this.onCancelClick : this.onEditClick}>
          {editMode ? 'Cancel' : 'Edit'}
        </button>
        <button
          disabled={!editMode}
          className="btn btn-primary btn-sm"
          onClick={this.onAddNotes}>
          Save
        </button>
      </div>
    )
  }

  renderNotesForm() {
    const { notesEntry, notesHistory } = this.state;
    const { noteId, isModelView } = this.props;
    return (
      <div>
        <div className="notes-form">
        <div className="info-side">
          <div className="notes-form-head d-flex align-items-center justify-content-between">
            {isModelView ? '' : <h4>Note</h4> }
            {this.renderFormButtons()}
          </div>

          <div>
            <div className='mb-3'>
              <label className="custom-label text-muted">ID</label>
              <h5>{noteId}</h5>
            </div>

            <div>{this.renderLastCalled()}</div>
            <div>{this.renderNowButton()}</div>
            {this.renderAssigneeField()}
            {this.renderMessageField()}
          </div>
        </div>

        <div className="history-side">
          <HistoryPage notesHistory={notesHistory} notesEntry={notesEntry} />
        </div>
      </div>
    </div>
    );
  }



  render() {
    const { isTableDataLoading } = this.props;
    const { loading, isSavingNotes } = this.state;

    return (
      <div className="notes-page">
        {isTableDataLoading ? null : <LoadingSpinner isLoading={loading || isSavingNotes} />}

        {this.renderNotesForm()}
      </div>
    )
  }
}

NotesPage.propTypes = {
  noteId: commonPropTypes.stringOrNumberProps,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentUserEmail: PropTypes.string,
  isModelView: PropTypes.bool,
  isTableDataLoading: PropTypes.bool
}

const mapDispatchToProps = {
};

function mapStateToProps(state) {
  return {
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    currentUserEmail: _.get(state, 'currentUser.user.email')
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesPage);
