import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { ForgeIconButton, ForgePopup, ForgeIcon, ForgeTextField, ForgeButton } from '@tylertech/forge-react';
import { PopupAnimationType } from '@tylertech/forge';
import { withRouter } from 'react-router-dom';
import { createBookmark, updateBookmark } from 'common/api/bookmarksApi';
import { getBookmarkDefaultParams } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { viewSavedMessage } from 'helpers/toastMessages';

class SaveContainer extends Component {
  constructor(props, context) {
    super(props, context);
    const { currentBookmarkName } = props;

    this.state = {
      name: currentBookmarkName,
      description: "",
      isLoading: false,
      isEditMode: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { currentBookmark, currentBookmarkName } = this.props;

    if(!_.isEqual(prevProps.currentBookmark, currentBookmark)) {
      if(!_.isEmpty(currentBookmark)){
        this.setState({
          name: _.get(currentBookmark, 'name'),
          description: _.get(currentBookmark, 'bookmarkOptions.description'),
          isEditMode: true
        });
      } else {
        this.setState({
          name: currentBookmarkName,
          description: '',
          isEditMode: false
        });
      }
    }
  }

  navigateToMyView = (myViewCollectionId) => {
    this.props.onUpdateCurrentCollectionId(myViewCollectionId)
    this.props.history.push({ pathname: "/overview" });
  }

  handleSaveAsClick = () => {
    const { setOpenSaveContainer } = this.props;
    const { isEditMode } = this.state;
    setOpenSaveContainer(false);
    if (isEditMode) {
      this.renameBookmark();
    } else {
      this.createNewBookmark();
    }
  };

  renameBookmark = () => {
    const {
      currentBookmark,
      currentBookmarkName,
      onCreateBookmark,
      onBookmarkChange
    } = this.props;
    const { name, description } = this.state;
    const bookmarkId = _.get(currentBookmark, 'id');

    const params = {
      ...currentBookmark,
      name: name,
      bookmarkOptions: {
        ..._.get(currentBookmark, 'bookmarkOptions'),
        description: description,
      }
    }
    this.setState({ isLoading: true, hideSaveAsDropDown: false });
    updateBookmark(bookmarkId, params)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          isLoading: false,
          currentBookmark,
          name: currentBookmarkName,
          description: ""
        });
        onCreateBookmark(false);
        onBookmarkChange(response);
        toast.success(viewSavedMessage);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toast.error(`Error while editing view. ${err}`);
        console.error(err); // eslint-disable-line no-console
      });
  };

  createNewBookmark = () => {
    const {
      onBookmarkChange,
      advanceSearchOptions,
      currentBookmarkName,
      onCreateBookmark
    } = this.props;
    const { name, description } = this.state;
    let updateAdvanceSearchOptions ={...advanceSearchOptions };
    updateAdvanceSearchOptions['selectedReportRowIds'] = [];
    updateAdvanceSearchOptions['reportPageData'] = [];
    const bookmarkParams = {
      ...getBookmarkDefaultParams(this.props, { name, description }),
      is_custom_advance_search: true,
      advanceSearchOptions: updateAdvanceSearchOptions,
    }
    const params = {
      shareEmails: [],
      bookmark: bookmarkParams,
      shareFieldInputs: {},
      include_current_user: true,
      ignore_view_entry: true,
      includeMyViewCollectionId: true
    };

    this.setState({ isLoading: true, hideSaveAsDropDown: false });
    onCreateBookmark(true);
    createBookmark(params).
      then(response => response.json()).
      then((response) => {
        const currentBookmark = _.get(_.values(response), '[0]', {})
        const myViewCollectionId = _.get(_.values(response), '[1]', 0);
        this.setState({
          isLoading: false,
          currentBookmark,
          name: currentBookmarkName,
          description: ""
        });
        onCreateBookmark(false);
        onBookmarkChange(currentBookmark);
        if (response.error) {
          toast.error("error on saving advance search view");
        } else {
          toast.success(this.CustomToastWithLink(myViewCollectionId));
        }
      }).
      catch((err) => {
        this.setState({ isLoading: false });
        toast.error(`Error while creating view. ${err}`);
        console.error(err);   // eslint-disable-line no-console
      }).
      then(() => {
      });
  }

  CustomToastWithLink = (myViewCollectionId) => (
    <div className="saved-view-toast">Success! Your Advance search has been saved. Go to&nbsp;
      <a className="toast-nav-link" onClick={() => this.navigateToMyView(myViewCollectionId)} >My Views</a>.
    </div>
  );

  renderSpinner() {
    return (
      <LoadingSpinner isLoading={this.state.isLoading} />
    )
  }

  handleInputFieldChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  }

  handleCancelClick = () => {
    const { setOpenSaveContainer } = this.props;
    this.setState({ description: "" });
    setOpenSaveContainer(false)
  };

  renderPopup() {
    const { name, description, isEditMode } = this.state;
    const animationType = PopupAnimationType.Menu;
    const { openSaveContainer, setOpenSaveContainer, filterPopupRef, bookmarkMenuType } = this.props;
    const displayText = _.startCase(bookmarkMenuType);
    return (
      <>
        <ForgePopup
          className="sample-popup"
          style={{ maxHeight: "100px" }}
          targetElementRef={filterPopupRef}
          open={openSaveContainer}
          Placement={'right-start'}
          onDismiss={() => setOpenSaveContainer(false)}
          options={{ animationType }}>
          <div className='bookmark-save-container pb-0'>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className='forge-typography--headline5'>
                {isEditMode ? displayText + ' Saved Filters' : 'Save filters'}
              </div>
              <ForgeIconButton>
                <button
                  type="button"
                  aria-label="View user profile"
                  onClick={() => setOpenSaveContainer(false)}
                >
                  <ForgeIcon name="close" />
                </button>
              </ForgeIconButton>
            </div>
            {!isEditMode &&
              <p className='forge-typography--body2 d-block black-65 mb-5'>
                This will save your current filters so that you can quickly return to this
                search in the future.
              </p>
            }
            <div className="mb-5">
              <ForgeTextField >
                <input
                  type="text"
                  value={name}
                  onChange={(e) => this.handleInputFieldChange(e, 'name')} />
                  <label className="font-weight-bold">Saved filters name</label>
              </ForgeTextField>
            </div>
            <div className="mb-5">
              <ForgeTextField>
                <textarea
                  style={{height: '144px'}}
                  placeholder='Description'
                  row="10"
                  value={description}
                  onChange={(e) => this.handleInputFieldChange(e, 'description')} />
              </ForgeTextField>
            </div>
            <div className="dropdown-footer d-flex justify-content-end align-items-center py-3">
              <ForgeButton type="outlined">
                <button
                  className="mr-2"
                  tabIndex={0}
                  aria-label="cancel"
                  onClick={() => this.handleCancelClick()}>
                  Cancel
                </button>
              </ForgeButton>
              <ForgeButton type="raised">
                <button
                  disabled={_.isEmpty(name)}
                  tabIndex={0}
                  aria-label="Save"
                  onClick={() => this.handleSaveAsClick()}>
                  Save
                </button>
              </ForgeButton>
            </div>
          </div>
        </ForgePopup>
      </>
    )
  }

  render() {
    return (
      <div className="d-flex bookmark-save-options" ref={(ref) => this.saveButtonRef = ref}>
        {this.renderSpinner()}
        {this.renderPopup()}
      </div>
    )
  }
}

SaveContainer.propTypes = {
  onBookmarkChange: PropTypes.func,
  onDataLoading: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  history: PropTypes.array,
  advanceSearchOptions: PropTypes.object,
  currentBookmarkName: PropTypes.string,
  onCreateBookmark: PropTypes.func,
  onUpdateCurrentCollectionId: PropTypes.func,
  openSaveContainer: PropTypes.bool,
  setOpenSaveContainer: PropTypes.func,
  filterPopupRef: PropTypes.func,
  currentBookmark: PropTypes.object,
  bookmarkMenuType: PropTypes.string
};

SaveContainer.defaultProps = {
  onCreateBookmark: _.noop
};

export default withRouter(SaveContainer);
