import React from 'react';
import PropTypes from 'prop-types';
import { ForgeButton } from '@tylertech/forge-react';

function CustomizedForgeButton(props) {
  const { type, text, disabled, onClick } = props;
  return (
    <ForgeButton type={type}>
      <button type="button" disabled={disabled} onClick={onClick}>
        <span>{text}</span>
      </button>
    </ForgeButton>
  );
}

CustomizedForgeButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CustomizedForgeButton.defaultProps = {
  type: 'raised',
  text: 'Button',
  disabled: false,
  onClick: _.noop,
};

export default CustomizedForgeButton;
