import _ from 'lodash';
import * as actionTypes from '../actionTypes/bookmarkActionTypes';

export const getInitialState = () => {
  return {
    currentBookmarkId: '',
    currentBookmarkName: '',
    currentBookmarkType: '',
    canUpdateBookmarkViewCount: false,
  };
};

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_BOOKMARK:
      return {
        ...state,
        currentBookmarkId: (_.get(action, 'bookmarkId') || '') + '',
        currentBookmarkName: _.get(action, 'name', ''),
        currentBookmarkType: _.get(action, 'bookmarkType', '')
      }
    case actionTypes.UPDATE_BOOKMARK_VIEW_COUNT:
      return {
        ...state,
        canUpdateBookmarkViewCount: _.get(action, 'canUpdateBookmarkViewCount', false)
      }
    default:
      return state;
  }
}
