import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeButton } from '@tylertech/forge-react';

import Card from "pages/dashboard/components/CardList/Card";
import LoadingSpinner from 'common/components/LoadingSpinner';
import { CARD_TYPES } from 'appConstants';
class ForecastCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBack: false,
      isLoading: false,
      isCardsLoading: false
    }
  }

  renderCreateForecast() {
    const { handleCreateNewForecast } = this.props;
    return (
      <ForgeButton type="outlined">
        <button onClick={handleCreateNewForecast} type="button">
          <span className="text-primary">
            <ForgeIcon name="plus_thick" className="tx-12 mr-1" />Create new forecast
          </span>
        </button>
      </ForgeButton>
    );
  }

  renderCardList() {
    const {
      commonFilters,
      currentUser,
      savedForecasts,
      templateEntries,
      onCardClick,
      onDeleteReloadSavedForecasts
    } = this.props;

    const {
      isLoading,
      isCardsLoading,
    } = this.state;

    return _.map(savedForecasts, (cardEntry, key)  => {
      return (
          <Card
            newLabelCards={[]}
            bookmarks={savedForecasts}
            collectionEntry={{}}
            currentUser={currentUser}
            cardEntry={cardEntry}
            isLoading={isLoading || isCardsLoading}
            onCardClick={onCardClick}
            key={`${key}`}
            index={key}
            cardType={CARD_TYPES.BOOKMARK}
            commonFilters={commonFilters}
            globalFilters={{}}
            userCards={[]}
            cardEntries={savedForecasts}
            templateEntries={templateEntries}
            reloadBookmarks={onDeleteReloadSavedForecasts}
          />
      );
    });
  }

  backToPreviosPage () {
    const forecastLocation = _.get(window, 'forecastFromPage', '/');
    if (_.includes(['/analysis', '/overview'], forecastLocation)) {
      this.props.handleBackButtonClick();
    } else {
      this.props.handleBackToIndexPage();
      window.history.back();
    }
  }

  render() {
    const {
      isLoading,
      isCardsLoading
    } = this.state;
    const {
      selectedForecastMetric
    } = this.props;
    return (
      <main role="main" className="forecast-card-wrapper">
        <div className='page-main-head pl-2 py-1 h-auto'>
          <button
            aria-label="Back To Overview"
            className="btn back-arrow-icon back-btn-light"
            onClick={() => this.backToPreviosPage()}>
            <i className="icons-arrow-left2" />
          </button>
          <div className='forge-typography--headline4'>Advanced Forecasting</div>
        </div>
        <div className='row m-0 forecast-inner'>
          <div className='col-md-10 offset-md-1 d-flex flex-column h-100'>
            <div className='mt-10 mb-4 d-flex flex-column pl-4'>
              <div className="forge-typography--title	mb-3">
                {_.get(selectedForecastMetric, 'name', '')}
              </div>
              <div className="forge-typography--subtitle1	mb-3">
                Select an existing forecast, or create a new one
              </div>
              {this.renderCreateForecast()}
            </div>
            <div className='forecasting-card'>
              <LoadingSpinner isLoading={isLoading || isCardsLoading} />
              <div className='template-cards' role="list">
                {this.renderCardList()}
              </div>
            </div>
            <div className='forecasting-footer pl-4 py-2'>
              <ForgeButton type="outlined">
                <button
                  type='button'
                  onClick={() => {this.backToPreviosPage()}}
                >Back</button>
              </ForgeButton>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

ForecastCards.propTypes = {
  savedForecasts: PropTypes.array,
  commonFilters: PropTypes.object,
  currentUser: PropTypes.object,
  templateEntries: PropTypes.array,
  handleBackButtonClick: PropTypes.func,
  handleCreateNewForecast: PropTypes.func,
  onCardClick: PropTypes.func,
  onDeleteReloadSavedForecasts: PropTypes.func,
  handleBackToIndexPage: PropTypes.func,
  selectedForecastMetric: PropTypes.object
};

export default ForecastCards;