import React from "react";
import { useSelector } from "react-redux";

import CollaborateCollectionCreatedAt from "./CollaborateCollectionCreatedAt";
import { useFetchCollectionActivities } from "./hooks/useFetchCollectionActivities";
import { getTimeStampForDate } from "./collaboratorHelper";

const CollaborateActivity = () => {
  const collection = useSelector(state => state.collection);
  const { data } = useFetchCollectionActivities(collection['id']);
  const renderActivityContent = ({ user, id, updated_at, is_save_view, activity_type }) => {
    const email = _.get(user, 'email');
    const time = getTimeStampForDate(updated_at);

    return is_save_view ?
      <div key={id}> {email} has updated the saved view <b>{activity_type}</b> on {time}</div> :
      <div key={id}>Updated by {email} on {time}</div>;
  }

  return (
    <div className="active-tab mt-5">
      <CollaborateCollectionCreatedAt collection={collection} />
      {_.map(data, renderActivityContent)}
    </div>
  );
}

export default CollaborateActivity;
