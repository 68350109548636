import React from 'react'
import PropTypes from 'prop-types'
import { ForgeLinearProgress } from '@tylertech/forge-react'

function ForgeProgressLinear(props) {
  const { isLoading} = props;

  if(!isLoading){
    return
  }

  return (
    <ForgeLinearProgress determinate = {false} progress={0.5} />
  )
}
  
ForgeProgressLinear.propTypes = {
  isLoading: PropTypes.bool
}

ForgeProgressLinear.defaultPropTypes = {
  isLoading: false
}

export default ForgeProgressLinear
