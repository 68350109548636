// Vendor Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Project Imports
import FilterBadges from 'common/components/FilterBadges';
import TypeHeadAndTopValues from './TypeHeadAndTopValues';

import { getNullValueLabel } from 'common/config/templateConfiguration';
import {
  getFormatConditionValues,
  getFilterItem,
  getNewFilterConditions,
  isTextSearchOperator,
  removeValueAndGetConditions,
  getTextFilterTopValueTitle
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { templateIdPropTypes } from 'common/propTypes';
const SEPARATE_UP_TO_LIMIT = 5;
const SPLITTED_OPERATOR = ',';

class SearchAndTopValues extends Component {
  constructor(props) {
    super(props);
    const { allFilters, filter } = props;
    const filterConditions = _.chain(allFilters).
      filter((filterItem) => (filterItem.field === _.get(filter, 'field'))).
      map('conditions').
      flatten().
      value();

    this.state = { conditions: _.cloneDeep(filterConditions) || [] };
  }

  componentDidUpdate(prevProps) {
    const { allFilters, filter } = this.props;
    const isAllFiltersChanged = !_.isEqual(prevProps.allFilters, allFilters);

    if (isAllFiltersChanged) {
      const filterConditions = _.chain(allFilters).
        filter((filterItem) => (filterItem.field === _.get(filter, 'field'))).
        map('conditions').
        flatten().
        value();
      this.setState({ conditions: _.cloneDeep(filterConditions) || [] });
    }
  }

  getConditionValues = () => {
    const { logicalOperatorEntry } = this.props;
    const { conditions } = this.state;

    return _.chain(conditions).
      filter((condition) => { return (_.get(condition, 'operator') === logicalOperatorEntry.value); }).
      map('value', []).
      flatten().
      map(valueItem => _.isNil(valueItem) ? this.getEmptyValue() : valueItem).
      value();
  }

  getEmptyValue = () => {
    const { templateId } = this.props;
    return getNullValueLabel(templateId);
  }

  updateConditions = (conditions) => {
    const { filter, onSearchChange, logicalOperatorEntry } = this.props;
    const operator = _.get(logicalOperatorEntry, 'value', '');
    const isLikeOperator = _.includes(['like', 'not_like'], operator);

    this.setState({ conditions }, () => {
      let filterItem = getFilterItem(filter, conditions);
      if (isLikeOperator) {
        if (_.size(_.get(filterItem, 'conditions[0].value', [])) > SEPARATE_UP_TO_LIMIT) {
          filterItem.conditions[0].value = _.take(filterItem.conditions[0].value, SEPARATE_UP_TO_LIMIT);
        }
      }

      onSearchChange(filterItem);
    });
  }

  setFilterConditions = (selectedItems) => {
    const { logicalOperatorEntry, isEditMode } = this.props;
    const newSelectedItems = (_.get(selectedItems, '0') === this.getEmptyValue()) ?
      [null] :
      selectedItems;
    const { conditions } = this.state;
    let newConditions = getNewFilterConditions(conditions, logicalOperatorEntry, newSelectedItems);
    if (isEditMode) {
      newConditions = [_.find(newConditions, { operator: logicalOperatorEntry.value })];
    }
    this.updateConditions(newConditions);
  }

  inputKeyDown = (e) => {
    const val = e.target.value;
    e.stopPropagation();
    if (isEnterButtonPressed(e) && val) {
      this.onInputChange(e);
      this.props.onApply();
    }
  }

  handleClickRemoveFilter = (removedValue) => {
    const { logicalOperatorEntry, isEditMode } = this.props;
    const { conditions } = this.state;
    const options = { conditions, logicalOperatorEntry, removedValue, emptyValue: this.getEmptyValue() };
    const newConditions = removeValueAndGetConditions(options);
    const emptyValue = this.getEmptyValue();

    this.updateConditions(getFormatConditionValues({ conditions: newConditions }, emptyValue), isEditMode);
  }

  onForgeSelect = (selectedItems) => {
    const { logicalOperatorEntry } = this.props;
    const { conditions } = this.state;

    let newConditions = _.cloneDeep(conditions);
    const selectedFilterCondition = _.find(newConditions, { operator: logicalOperatorEntry.value });

    if (selectedFilterCondition) {
      newConditions = _.map(newConditions, (condition) => {
        if (condition['operator'] === logicalOperatorEntry['value']) {
          condition['value'] = selectedItems;
        }
        return condition;
      });
    } else {
      newConditions.push({ operator: logicalOperatorEntry.value, value: selectedItems });
    }
    this.updateConditions(newConditions);

  }

  onInputChange = (event) => {
    let values = [];
    if (event.target.value) {
      values = _.chain(event.target.value)
        .split(SPLITTED_OPERATOR)
        .map(value => _.trimStart(value))
        .value();
    }
    this.setFilterConditions(values);
  }

  renderFilterBadge = () => {
    const { logicalOperatorEntry } = this.props;
    const operator = _.get(logicalOperatorEntry, 'value', '');
    const isLikeOperator = _.includes(['like', 'not_like'], operator);

    let conditionValues = this.getConditionValues();
    if (isLikeOperator) {
      conditionValues = _.take(_.compact(this.getConditionValues()), SEPARATE_UP_TO_LIMIT);
    }

    return !_.isEmpty(conditionValues) && (
      <FilterBadges
        isCurrencyRenderType={this.props.isCurrencyRenderType}
        values={conditionValues}
        onRemoveItem={this.handleClickRemoveFilter} />
    );
  }

  renderTextSearchContent = () => {
    const { logicalOperatorEntry } = this.props;
    const value = _.join(this.getConditionValues(), SPLITTED_OPERATOR);

    return isTextSearchOperator(logicalOperatorEntry.value) && (
      <>
        <input type="text" className="form-control"
          onKeyDown={this.inputKeyDown}
          onChange={this.onInputChange} value={value}
        />
        <label className="info-label-color">Use commas to separate up to five values</label>
      </>
    );
  }

  renderTypeHeadAndTopValues = () => {
    const {
      logicalOperatorEntry, filter, searchApiParams, fetchTopValues,
      fetchSearchInputValues, isCurrencyRenderType, onFilterItemClick,
      isExpanded
    } = this.props;

    return !isTextSearchOperator(logicalOperatorEntry.value) && (
      <TypeHeadAndTopValues
        onFilterItemClick={onFilterItemClick}
        isCurrencyRenderType={isCurrencyRenderType}
        apiParams={searchApiParams}
        fetchTopValues={fetchTopValues}
        fetchSearchInputValues={fetchSearchInputValues}
        field={_.get(filter, 'field')}
        nullValueLabel={this.getEmptyValue()}
        topValueLabel={getTextFilterTopValueTitle(filter)}
        values={this.getConditionValues()}
        selectedValues={this.getConditionValues()}
        onAdd={this.setFilterConditions}
        onRemove={this.handleClickRemoveFilter}
        isExpanded={isExpanded}
        onForgeSelect={this.onForgeSelect}
        filterName={_.get(filter, 'name')}
      />
    );
  }

  render() {
    return (
      <div className='top-filter-container'>
        <label>Value</label>
        {this.renderTextSearchContent()}
        {this.renderTypeHeadAndTopValues()}
        <div className='search-top-filter'>
          <div className="d-flex align-items-center search-top-values gap-5">
            <div className="flex-grow-1 filter-items">
            </div>
          </div>
          <div className="filter-items-badge">
            {this.renderFilterBadge()}
          </div>
        </div>
      </div>
    );
  }
}

SearchAndTopValues.propTypes = {
  filter: PropTypes.shape({
    column: PropTypes.any,
    mapped_field: PropTypes.any
  }),
  isEditMode: PropTypes.bool,
  allFilters: PropTypes.array,
  logicalOperatorEntry: PropTypes.object,
  templateId: templateIdPropTypes,
  onSearchChange: PropTypes.func,
  onApply: PropTypes.func,
  searchApiParams: PropTypes.object,
  fetchTopValues: PropTypes.func,
  fetchSearchInputValues: PropTypes.func,
  isCurrencyRenderType: PropTypes.bool,
  onFilterItemClick: PropTypes.func,
  isExpanded: PropTypes.bool,
};

SearchAndTopValues.defaultProps = {
  isEditMode: false
};

export default SearchAndTopValues;
