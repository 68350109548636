import { RADAR_SELECTED_MINI_MAP_SESSION_KEY,
  RADAR_COMPARISON_MINI_MAP_SESSION_KEY } from 'appConstants';

export const setRadarMiniMapSelectSession = (type,value = true) => {
    if (type == 'selectedTracts')
      sessionStorage.setItem(RADAR_SELECTED_MINI_MAP_SESSION_KEY,value);
    if (type == 'comparisonTracts')
      sessionStorage.setItem(RADAR_COMPARISON_MINI_MAP_SESSION_KEY,value);
}

export const getRadarMiniMapSelectSession = (type) => {
    if (type == 'selectedTracts')
      return sessionStorage.getItem(RADAR_SELECTED_MINI_MAP_SESSION_KEY);
    if (type == 'comparisonTracts')
      return sessionStorage.getItem(RADAR_COMPARISON_MINI_MAP_SESSION_KEY);
}

export const removeRadarSessionStorage = () => {
    sessionStorage.removeItem(RADAR_SELECTED_MINI_MAP_SESSION_KEY);
    sessionStorage.removeItem(RADAR_COMPARISON_MINI_MAP_SESSION_KEY);
}