import * as actionTypes from '../actionTypes/distributionActionTypes';
import { UPDATE_VISUALIZATION_REDUCER } from 'actions/visualizationActions';
import { getDistributionState } from './helper';

export const getInitialState = () => {
  return {
    isDiscrete: true,
    isCumulativeDistribution: false
  };
};

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.UPDATE_DISTRIBUTION_BENCH_MARK_NAMES:
      return {
        ...state,
        currentBenchMarkMetricNames: _.get(action, 'names')
      }

    case actionTypes.UPDATE_DISTRIBUTION_OPTION:
      return {
        ...state,
        isCumulativeDistribution: _.get(action, 'isCumulativeDistribution'),
        isDiscrete: _.get(action, 'isDiscrete')
      }

    case actionTypes.UPDATE_VISUALIZATION_DEFAULTS:
      return {
        ...state,
        ...getDistributionState(_.get(action, 'options'))
      }

    case UPDATE_VISUALIZATION_REDUCER:
      return {
        ...state,
        ...getInitialState(),
        ..._.get(action, 'visualization.distribution', {})
      }

    default:
      return state;
  }
}
