import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import { DRAGGABLE } from 'appConstants';

function DropTargetCard(props) {
    const { onDrop, children, isDroppable } = props;
    const [{ isHoveredOver, canDrop }, drop] = useDrop({
      accept: [DRAGGABLE.TYPES.CARD],
      drop: (item) => {
        onDrop(item);
      },
      canDrop: () => {
        return isDroppable;
      },
      collect: (monitor) => ({
        isHoveredOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });
  
    const dropClassNames = classNames(
      { 'is-hovered-over': isHoveredOver },
      { 'can-drop': canDrop  }
    );
  
    return (
      <div ref={drop} className={dropClassNames}>
        {children}
      </div>
    );
  }
  
  DropTargetCard.propTypes = {
    onDrop: PropTypes.func,
    children: PropTypes.element.isRequired,
    isDroppable: PropTypes.bool,
    collection: PropTypes.object
  };
  
  export default DropTargetCard;