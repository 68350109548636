import _ from 'lodash';
import LineChartDataHandler from './DataHandler/LineChartDataHandler';
import { apiDataHelper } from './DataHandler/Helper/apiDataHelper';

export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    const isProjectOptionChanged = !_.isEqual(
      _.get(previousVizOptions, 'projectionType'), _.get(vizOptions, 'projectionType')
    ) ||
      !_.isEqual(previousVizOptions['projectionPercent'], vizOptions['projectionPercent']) ||
      !_.isEqual(previousVizOptions['projectionEnabled'], vizOptions['projectionEnabled']) ||
      !_.isEqual(previousVizOptions['forecastingOption'], vizOptions['forecastingOption']) ||
      !_.isEqual(_.get(vizOptions, 'forecastPrepareDataTime'),
        _.get(previousVizOptions, 'forecastPrepareDataTime'));

    if (isProjectOptionChanged) {
      return true;
    }
    return (
      false
      // _.get(vizOptions, '') !== _.get(previousVizOptions, '')
    );
  },
  formatData: (vizOptions, rawApiData) => {
    if (_.isEmpty(rawApiData)) {
      return {}
    }
    const apiDataHandler = new apiDataHelper(rawApiData, vizOptions);
    rawApiData = apiDataHandler.updateAdjustedValue();

    const dataFormatter = new LineChartDataHandler(rawApiData, vizOptions);

    let formattedData = {
      "current": dataFormatter.getCurrentPeriodData(),
      "comparison": dataFormatter.getComparisonPeriodData(),
      "benchMarks": dataFormatter.getBenchmarkData(),
      "tailingDrop": dataFormatter.getTailingDrops(),
      "tailingDropComparison": dataFormatter.getTailingDropComparisonData(),
      "tailingDropComboMetrics": dataFormatter.getTailingDropComboMetrics(),
      "actualDropItems": dataFormatter.getActualDropData(),
      "projection": dataFormatter.getProjectionData(),
      "dimensionConfigs": dataFormatter.getDimensionConfigs(),
      "comboMetricConfigs": dataFormatter.getComboMetricConfigs(),
    };

    formattedData = dataFormatter.addTotalLegend(formattedData);
    return formattedData;
  }
};

export const getEveryNthElementFromFirst = (arr, n) => {
  return arr.filter((_, index) => index % n === 0);
};