import React from "react";
const WAIT_BEFORE_SHOW_TIME = 150;

export const NoDataWrapper = (SourceComponent) => {
  return class extends React.Component {

    constructor(props) {
      super(props);
      this.state = { hidden: true };
    }

    componentDidMount() {
      this.timeout = setTimeout(() => {
        this.setState({ hidden: false });
      }, WAIT_BEFORE_SHOW_TIME);
    }

    componentWillUnmount() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    }

    render() {
      return ( <SourceComponent {...this.props} {...this.state} />);
    }
  }
}
