import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';

export const getScatterChartSummaryFormatter = (formattedData,
  summaryTableOptions, summaryTotalData) => {

  const summaryTotals = _.get(summaryTotalData, 'group_totals', []);
  const tableHeaders = getScatterChartHeaders(summaryTableOptions);
  const tableData = getScatterSummaryDataFormatter(
      formattedData,
      summaryTableOptions,
      summaryTotals
    );

  return {
    tableHeaders,
    tableData
  }
}

const getScatterChartHeaders = (summaryTableOptions) => {
  const {
    showScatterPlotRange,
    primaryMetricName,
    secondaryMericName,
    dimensionName,
    isGroupByNone,
    isGroupChart,
    isComparisonEnabled
  } = summaryTableOptions;
  const title = (!isGroupByNone && isGroupChart) ? 'Category': dimensionName;

  let tableHeaders = [
    { name:title, columnField: "dimension" },
    { name:secondaryMericName, columnField: "secondaryCount" },
    { name:"Lower Range", columnField: "minValue" },
    { name:primaryMetricName, columnField: "dimensionValue" },
    { name:"Upper Range", columnField: "maxValue" }];

  if(_.isEmpty(secondaryMericName) && !showScatterPlotRange ){
    tableHeaders = [
      { name:title, columnField: "dimension" },
      { name:primaryMetricName, columnField: "dimensionValue" }];
  } else if(_.isEmpty(secondaryMericName) && showScatterPlotRange ){
    tableHeaders = [
      { name:title, columnField: "dimension" },
      { name:"Lower Range", columnField: "minValue" },
      { name:primaryMetricName, columnField: "dimensionValue" },
      { name:"Upper Range", columnField: "maxValue" }];
  } else if(!_.isEmpty(secondaryMericName) && !showScatterPlotRange){
    tableHeaders = [
      { name:title, columnField: "dimension" },
      { name:secondaryMericName, columnField: "secondaryCount" },
      { name:primaryMetricName, columnField: "dimensionValue" }];
  } else if(isComparisonEnabled){ // for disabling comaprison mode in scatterchart summaryTable
    tableHeaders = [             // It beahaved like a normal mode.
      { name:title, columnField: "dimension" },
      { name:"Lower Range", columnField: "minValue" },
      { name:primaryMetricName, columnField: "dimensionValue" },
      { name:"Upper Range", columnField: "maxValue" }];
  }

  return tableHeaders;
}

const getScatterSummaryDataFormatter = (formattedData, summaryTableOptions, summaryTotalData) => {
  const {
    secondaryMetricEntry,
    currentDrilldownViewEntry,
    templateId,
    isCurrencyDimensionField,
    isCurrencyGroupByField
  } = summaryTableOptions;

  let formattedReverseData = _.cloneDeep(formattedData)
  const nullValueLabel = getNullValueLabel(templateId);
  formattedReverseData = _.filter(formattedReverseData, function (datum) {
    return _.get(datum, 'isSeeMoreDimension', false) === false;
  });

  const summaryData = _.map(formattedReverseData, (datum) => {
    const minValue = _.get(datum, 'scatterEntry.min_count', 0);
    const maxValue = _.get(datum, 'scatterEntry.max_count', 0);
    let formattedMinValue = getFormattedNumberValue(minValue, currentDrilldownViewEntry);
    let formattedMaxValue = getFormattedNumberValue(maxValue, currentDrilldownViewEntry);
    let secondaryCount = _.get(datum, 'scatterEntry.secondary_count', 0);
    const isGroup = _.get(datum, 'isGroup', false) === true;
    let dimensionColumnName = isGroup ? _.get(datum, 'label') :
      _.get(datum, 'scatterEntry.dimension', nullValueLabel);

    secondaryCount = getFormattedNumberValue(secondaryCount, secondaryMetricEntry);
    let groupPrimaryTotal;
    let groupSecondaryTotal;
    let isCurrencyType = isCurrencyDimensionField;

    if(isGroup) {
      const groupTotal = getBarAndScatterSummaryGroupTotal(summaryTotalData,
        dimensionColumnName, isCurrencyGroupByField);
      groupPrimaryTotal = getFormattedNumberValue(_.get(groupTotal,'count'), currentDrilldownViewEntry);
      groupSecondaryTotal = getFormattedNumberValue(_.get(groupTotal,'secondary_count'),
        secondaryMetricEntry);

      formattedMinValue = "";
      formattedMaxValue = "";
      isCurrencyType = isCurrencyGroupByField;
    }

    dimensionColumnName = _.isEmpty(dimensionColumnName) ?
      nullValueLabel : dimensionColumnName;
    dimensionColumnName = formatValueToCurrency(dimensionColumnName, isCurrencyType);

    return {
      isGroup,
      dimension : dimensionColumnName,
      maxValue : formattedMaxValue,
      minValue : formattedMinValue,
      secondaryCount: isGroup ? groupSecondaryTotal : secondaryCount,
      dimensionValue : isGroup ? groupPrimaryTotal : _.get(datum, 'plotlyDescription', '')
    }
  });

  return summaryData;
}

export const getBarAndScatterSummaryGroupTotal = (summaryTotalData, groupName, isCurrencyType = false) => {
  const summaryTotal = _.find(summaryTotalData, function(summaryTotalDatum) {
    const groupByField = _.get(summaryTotalDatum, "group_by_field", '');
    return (
      (groupByField === groupName) ||
      (formatValueToCurrency(groupByField, isCurrencyType) === groupName)
    );
  });
  return summaryTotal;
}
