import abortableFetch from 'common/abortableFetch';
import { updateSubscriptionParamsforApi } from 'pages/dashboard/components/Collections/collectionApiHelper';

export const createCollectionSubscription = (collectionId, subscriptionSettings) => {
  const params = {
    method: 'POST',
    body: JSON.stringify(updateSubscriptionParamsforApi(collectionId, subscriptionSettings)),
    headers:{ 'content-type': 'application/json' }
  };
  const apiPath = collectionSubscriptionPath(collectionId);
  const apiUrl = `${apiPath}.json`;
  return abortableFetch(apiUrl, params);
}

export const deleteSubscription  = (collectionId, subscriptionId) => {
  const params =  {
      method: 'DELETE',
      headers:{ 'content-type': 'application/json' }
  };
  const apiPath = collectionSubscriptionPath(collectionId);
  const apiUrl = `${apiPath}/${subscriptionId}.json`;
  return abortableFetch(apiUrl, params);
}

export const deleteManagedSubscription = (collectionId, subscriptionId) => {
  const apiUrl = `/api/collections/manage_subscription_delete.json?`+
    `collection_id=${collectionId}&subscription_id=${subscriptionId}`
  return abortableFetch(apiUrl);
}

export const updateSubscription = (collectionId, subscriptionSettings) => {
  const subscriptionId = subscriptionSettings.id;
  const options =  {
      method: 'PUT',
      body: JSON.stringify(updateSubscriptionParamsforApi(collectionId, subscriptionSettings)),
      headers:{ 'content-type': 'application/json' },
  };
  const apiPath = collectionSubscriptionPath(collectionId);
  const apiUrl = `${apiPath}/${subscriptionId}.json`;
  return abortableFetch(apiUrl, options);
};

export const shareSubscription = (collectionId, params) => {
  const subscriptionParams = _.get(params, 'subscriptionParams', {});
  const subscriptionId = subscriptionParams.id;
  const options =  {
    method: 'POST',
    body: JSON.stringify(params),
    headers:{ 'content-type': 'application/json' },
  };
  const apiPath = collectionSubscriptionPath(collectionId);
  const apiUrl = `${apiPath}/${subscriptionId}/share_subscription.json`;
  return abortableFetch(apiUrl, options);
};

const collectionSubscriptionPath = (collectionId) => {
  return `/api/collections/${collectionId}/collection_subscriptions`
}
