import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SearchDetailsTable from './SearchResultPage/SearchDetailsTable';
import LoadingSpinner from 'common/components/LoadingSpinner';

import { DEFAULT_PAGINATION_SIZE } from 'appConstants';
import { getAdvanceSearchResult } from 'common/api/advanceSearchApi';
import {
  updateSelectedReportIds,
  updateReportPageData,
  updateSortColumns,
  updateSearchResultCount
} from 'actions/advanceSearchActions';
import { getTableColumnEntries } from 'common/config/templateConfiguration';
import { buildQueryString } from 'helpers/HttpHelper';
import GlobalEvent from 'common/components/GlobalEvents';

const SearchFilterResultTable = (props) => {
  const { isCustomSearch, hideContent = false } = props;
  const dispatch = useDispatch();
  const searchField = useSelector(state => state.advanceSearch.searchField);
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));
  const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));
  const subjectRowIds = useSelector(store => _.get(store, 'advanceSearch.subjectRowIds', []));
  const polygonGeoJson = useSelector(store => _.get(store, 'visualization.mapOptions.filteredGeojson', {}));

  const shapeDatasetEntry = useSelector(store => _.get(store, 'advanceSearch.shapeDatasetEntry', {}));
  const selectedReportRowIds = useSelector(state => state.advanceSearch.selectedReportRowIds);
  const reportPageData = useSelector(state => state.advanceSearch.reportPageData);
  const sortColumns = useSelector(store => _.get(store, 'advanceSearch.sortColumns', []));
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPagination, setShowPagination] =  useState(true);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [queryOptions, setQueryOptions] = useState({ limit: DEFAULT_PAGINATION_SIZE, offset: 0 });
  const paramsRef = useRef({});

  const filters = JSON.stringify({ quickFilters: searchFilters, selectedShapeIds });
  const detailsEntries = getTableColumnEntries(templateId);
  const shapeGroupId = _.get(shapeDatasetEntry, 'shape_dataset_id', '');
  const newTemplateId = templateId;
  const previousFilterRef = useRef(filters);

  const newQueryOptions = useMemo(() => {
    const filterChanged = !_.isEqual(previousFilterRef.current, filters);
    return filterChanged ? { limit: DEFAULT_PAGINATION_SIZE, offset: 0 } : queryOptions;
  }, [filters, queryOptions]);

  paramsRef.current = {
    advanceSearchSubjectField: JSON.stringify(searchField),
    include_quick_filters: true,
    currentDrilldownTemplateId: newTemplateId,
    drilldownEntry: filters,
    shapeIds: selectedShapeIds.join(','),
    shapeGroupId: shapeGroupId,
    subjectRowIds: JSON.stringify(subjectRowIds),
    sortColumns: JSON.stringify(sortColumns || []),
    isCustom: isCustomSearch,
    polygonGeoJson: JSON.stringify(polygonGeoJson),
  };

  const getSearchResultQueryParamsWithUrl = (overrideOptions) => {
    const newParams = paramsRef.current;
    const params = {
      ...newParams,
      ...overrideOptions
    }

    return getAdvanceSearchResult(params);
  }

  useEffect(() => {
    GlobalEvent.on('EXPORT_SEARCH_PAGE', handleCsvDownload);

    return () => {
      GlobalEvent.off('EXPORT_SEARCH_PAGE', handleCsvDownload);
    }
  }, [])

  const fetchApiData = (queryOptions) => {
    setIsLoading(true);
    const promises = [
      getSearchResultQueryParamsWithUrl({ onlyCsvRowCount: true }),
      getSearchResultQueryParamsWithUrl(queryOptions),
    ];
    Promise.all(promises).then((results) => {
      const rowsCount = Number(_.get(results, '0.0.row_count', 0));
      const tableRowsData = _.get(results, '1', []);
      setTotalRowsCount(rowsCount);
      setShowPagination(rowsCount >= queryOptions.limit);
      dispatch(updateSearchResultCount(rowsCount));
      setTableData(tableRowsData);
      setIsLoading(false);
    }).catch((error) => {
      console.log("Error on fetching data ", error);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    fetchApiData(newQueryOptions);
  }, [
    templateId,
    newQueryOptions,
    shapeGroupId,
    sortColumns,
    polygonGeoJson
  ]);

  useEffect(() => {
    if (!_.isEmpty(selectedReportRowIds) && _.isEmpty(reportPageData)) {
      const selectedResults = _.compact(_.map(tableData, (data) => {
        if (_.indexOf(selectedReportRowIds, _.get(data, 'row_id_field')) >= 0) {
          return data;
        }
      }));
      dispatch(updateReportPageData(selectedResults));
    }
  }, [tableData]);

  useEffect(() => {
    previousFilterRef.current = filters;
  }, [filters]);

  const setTableOptions = useCallback((e) => {
    const { pageSize, pageIndex } = _.get(e, 'detail', {});
    setQueryOptions({ limit: pageSize, offset: pageIndex });
  }, []);

  const onSelectFilterResult = (isChecked, data) => {
    let selectedResults = _.isEmpty(reportPageData) ? [] : [...reportPageData]
    if (isChecked) {
      selectedResults = [...selectedResults, data];
    } else {
      selectedResults = _.filter(selectedResults,
        (selectedItem) => selectedItem['row_id_field'] !== data['row_id_field']
      );
    }
    dispatch(updateReportPageData(selectedResults));
    dispatch(updateSelectedReportIds(_.map(selectedResults, 'row_id_field')));
  }

  const updateFilterTableSortColumns = (columns) => {
    dispatch(updateSortColumns(columns));
  };

  const handleCsvDownload = (selectedTab) => {
    if(selectedTab != 'table'){
      return;
    }
    const queryStr = buildQueryString(paramsRef.current);
    window.location.href= `/api/advanced_search/custom_search_download.csv?${queryStr}`;
  }

  if(hideContent) {
    return null;
  }

  if (!_.isEmpty(tableData) || isLoading) {
    return (
      <>
        <LoadingSpinner isLoading={isLoading} />
        <SearchDetailsTable
          onlyShowTableRow={false}
          tableHeaders={detailsEntries}
          paginationOptions={queryOptions}
          setTableOptions={setTableOptions}
          tableData={tableData}
          totalRowsCount={totalRowsCount}
          sortColumns={sortColumns}
          onClickTableSortedHeader={(sortColumns) => { updateFilterTableSortColumns(sortColumns) }}
          onSelectTableCheckbox={onSelectFilterResult}
          selectedReportRowIds={selectedReportRowIds}
          isCustomSearch={isCustomSearch}
          tableId="filter-table"
          showPagination={showPagination}
        />
      </>
    );
  } else {
    return (
      <div className='mx-10 my-10 text-center'>
        <img src='/images/property.png' alt='property' />
        <div className='ml-10'>No results found. Change your filters and try again.</div>
      </div>
    );
  }
}

SearchFilterResultTable.propTypes = {
  isCustomSearch: PropTypes.bool,
  hideContent: PropTypes.bool
};

SearchFilterResultTable.defaultPropTypes = {
  isCustomSearch: false
};

export default SearchFilterResultTable;
