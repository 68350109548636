// eslint-disable-next-line
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import RadarMap from './RadarMap';

class RadarChoroplethContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  renderMap(){
    const { apiParams, selectedMapMetric, metricEntry } = this.props;
    return(
      <RadarMap
        {...this.props}
        apiParams={
          {...apiParams,
          selectedMetric: _.get(selectedMapMetric, 'id')}
        }
        metricEntry={metricEntry}
      />
    )
  }

  renderMetricsDropDown(){
    const {
      selectedMetrics, selectedMapMetric, onSelectedMapMetricChange
    } = this.props
    return(
      <div>
        <SolutionDropDown
          className='p-0 compare-mode'
          selectedOption={selectedMapMetric}
          options={selectedMetrics}
          onSelect={onSelectedMapMetricChange}
        />
      </div>
    )
  }

  renderMetricButton = (metricEntry) => {
    const { selectedMapMetric, onSelectedMapMetricChange } = this.props;
    const activeClass = selectedMapMetric.id == metricEntry.id ? 'active' : '';
    return(
      <button
        type="button"
        className={`btn btn-outline-primary ${activeClass}`}
        onClick={() => onSelectedMapMetricChange(metricEntry)}>
          {metricEntry.name}</button>
    );
  }

  renderMetrics = () => {
    const { selectedMetrics } = this.props
    return (
      <div className="btn-group btn-active-check" role="group" aria-label="Render Metric Button">
        {_.map(selectedMetrics, this.renderMetricButton)}
      </div>
    )
  }

  renderTitleInfo(){
    const { metricEntry } = this.props;

    return(
      <div className='map-head text-center'>
        <p className='map-title'>
          {metricEntry['name']}
        </p>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderTitleInfo()}
        {this.renderMap()}
      </>
    );
  }
}

RadarChoroplethContainer.defaultProps = {
  selectedMapMetric: {}
}

RadarChoroplethContainer.propTypes = {
  apiParams: PropTypes.object,
  currentDateRangeOptions: PropTypes.object,
  previousDateRangeOptions: PropTypes.object,
  selectedMapMetric: PropTypes.object,
  selectedShapeAreaEntry: PropTypes.object,
  onSelectedMapMetricChange: PropTypes.func,
  selectedMetrics: PropTypes.array,
  metricEntry: PropTypes.object,
  onMapDataLoading: PropTypes.func
}


export default RadarChoroplethContainer;
