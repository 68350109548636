import { UPDATE_VISUALIZATION_REDUCER } from 'actions/visualizationActions';

export const getInitialState = () => {
  return {
    currentRecordIndex: 0,
    currentNoteId: ''
  };
};

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case UPDATE_VISUALIZATION_REDUCER:
      return {
        ...state,
        ..._.get(action, 'visualization.leafPage', {})
      }

    default:
      return state;
  }
}
