import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import combine from '@turf/combine';

import { drawControlCustomStyle } from './drawControlStyles';
import { updateDrawingStateChange, updatePolygonFeature } from 'actions/mapOptionsActions';
import drawLineStringMode from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

const GEOJSON_POLYGON = 'Polygon';

class DrawControl extends Component {
  constructor(props) {
    super(props);

    const { map, onUpdateDraw, choroplethMapDraw, pointMapDraw } = this.props;
    if(choroplethMapDraw) {
      map.removeControl(choroplethMapDraw);
      onUpdateDraw(null, true);
    }

    if(pointMapDraw) {
      this._draw = pointMapDraw;
    } else {
      this._draw = new MapboxDraw({  // eslint-disable-line no-undef
        displayControlsDefault: false,
        controls: {
          polygon: false,
          trash: false
        },
        modes: Object.assign(MapboxDraw.modes, {
          draw_line_string: drawLineStringMode
        }),
        styles: drawControlCustomStyle
      });

      map.addControl(this._draw, 'top-left');
      onUpdateDraw(this._draw, false);
    }
    map.on('draw.create', this.onDrawCreate);
  }

  componentWillUnmount() {
    const { map } = this.props;
    if (map && this._draw) {
      // map.removeControl(this._draw);
      map.off('draw.create', this.onDrawCreate);
    }
  }

  componentDidUpdate(prevProps) {
    const { isDrawingEnabled } = this.props;
    if ((prevProps !== this.props) && (isDrawingEnabled)) {
      this.enablePolygonDrawing();
    }
    if ((prevProps !== this.props) && (!isDrawingEnabled)) {
      this.disablePolygonDrawing();
    }
  }

  enablePolygonDrawing = () => {
    this._draw.changeMode('draw_polygon');
  }

  disablePolygonDrawing = () => {
    this.props.dispatchUpdateDrawingStateChange(false);
    this._draw.deleteAll();
    this._draw.changeMode('simple_select');
  }

  onDrawCreate = () => {
    const featureCollection = this._draw.getAll();
    const features = _.get(featureCollection, 'features', []);
    const { updatePolygonFeatures } = this.props;

    let polygonFeatures = _.filter(features, (feature) => {
      return _.get(feature, 'geometry.type', '') === GEOJSON_POLYGON;
    });

    const multipolygonFeature = combine({
      type: 'FeatureCollection',
      features: polygonFeatures
    });

    if(!_.isEmpty(polygonFeatures)){
      updatePolygonFeatures(multipolygonFeature)
    }

    setTimeout(() => {
      this.enablePolygonDrawing();
    })
  }

  render() {
    return null;
  }
}

DrawControl.propTypes = {
  map: PropTypes.object,
  isDrawingEnabled: PropTypes.bool,
  dispatchUpdateDrawingStateChange: PropTypes.func,
  dispatchUpdatePolygonFeature: PropTypes.func,
  updatePolygonFeatures: PropTypes.func,
  onUpdateDraw: PropTypes.func,
  pointMapDraw: PropTypes.object,
  choroplethMapDraw: PropTypes.object,
};

const mapDispatchToProps = {
  dispatchUpdateDrawingStateChange: updateDrawingStateChange,
  dispatchUpdatePolygonFeature: updatePolygonFeature
};

const mapStateToProps = (state) => {
  return {
    isDrawingEnabled: _.get(state, 'visualization.mapOptions.isDrawingEnabled', false)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawControl);
