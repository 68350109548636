import $ from 'jquery';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import OutsideClickHandler from 'common/components/OutsideClickHandler';
import { updateHistoryTabHash, updateInsightsId } from 'actions/insightActions';

function InsightsInfo(props) {
  const { topMovers, slaWatch } = props;
  const [shouldShowInsightsInfo, setShouldShowInsightsInfo] = useState(false);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch()
  const insightsInfoRef = useRef();

  useEffect(() => {
    if (count == 0) {
      setShouldShowInsightsInfo(true);
      // Initially let the user know the expand interaction.
      setTimeout(() => setShouldShowInsightsInfo(false), 1500);
      setCount(count + 1);
    }
  });

  const shouldClickOutSide = (event) => {
    return !($(event.target).closest('.info-variance-btn').length > 0);
  }

  const onClickInsight = () => {
    const tabName = location.hash.split('?')[0];
    if (tabName !== '#!/insights' && tabName) {
      const pageName = _.get(location.hash.split('#!'), '1', '/');
      const insightId = _.get(topMovers, 'insight_id', null);
      dispatch(updateHistoryTabHash(pageName))
      if(!_.isNull(insightId) && _.isEmpty(slaWatch)) {
        dispatch(updateInsightsId(insightId))
      }
    }
  }

  const renderInfoText = () => {
    return (
      <>
        Insights available! Open&nbsp;
        <a href="/#!/insights" className="btn-link" onClick={onClickInsight}>the report</a>
        &nbsp;to view.
      </>
    )
  }

  const insightsInfoClassNames = classNames('info-variance-btn', {
    'expanded': shouldShowInsightsInfo
  })

  return (
    <OutsideClickHandler
      onClickOutSide={setShouldShowInsightsInfo}
      shouldClickOutSide={shouldClickOutSide}>
      <span
        className={insightsInfoClassNames}
        ref={insightsInfoRef}
        onClick={() => setShouldShowInsightsInfo(true)}>
        <div className="variance-icon">
          <i className="icons-light-bulb"></i>
        </div>

        <div className="info-text">
          {renderInfoText()}
        </div>
      </span>
    </OutsideClickHandler>
  )
}

InsightsInfo.propTypes = {
  topMovers: PropTypes.object,
  slaWatch: PropTypes.array,
  currentCollectionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default React.memo(InsightsInfo);
