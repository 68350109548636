import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { removePolygonFilter } from 'actions/mapOptionsActions';

class PolygonFilterCard extends Component {
  handleRemovePolygonFilter = () => {
    this.props.dispatchRemovePolygonFilter();
  }

  render(){
    const { isDrawingEnabled, filteredGeojson } = this.props;
    if(isDrawingEnabled || _.isEmpty(filteredGeojson) || _.isEmpty(_.get(filteredGeojson, 'features'))){
      return null;
    }
    return (
        <div className="filter-chip" key="polygon-filter" tabIndex="0">
          <div className='filter-chip-values'>
            <i className="icons-tactic"/>
            <div className='chip-values-text'>Custom boundary</div>
            <span
              onClick={this.handleRemovePolygonFilter}
              tabIndex="0"
              className="tag-close rounded-circle icons-times"
            />
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isDrawingEnabled: _.get(state, 'visualization.mapOptions.isDrawingEnabled'),
    filteredGeojson: _.get(state, 'visualization.mapOptions.filteredGeojson')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRemovePolygonFilter: () => {
      dispatch(removePolygonFilter());
    }
  };
}

PolygonFilterCard.propTypes = {
  dispatchRemovePolygonFilter: PropTypes.func,
  isDrawingEnabled: PropTypes.bool,
  filteredGeojson: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(PolygonFilterCard);
