import _ from 'lodash';
import * as barChart from './barChart';
import * as groupByScatterChart from './groupByScatterChart';
import * as scatterChart from './scatterChart';
import * as pieChart from './pieChart';
import { isScatterChart } from '../plotlyScatterChartHelper';
import { isBarchart } from '../barChartHelper';

export default {
  shouldUpdate: () => {  // (vizOptions, previousVizOptions) use this params when needed
    return false;
  },
  toPlotlyParams: (vizOptions, apiData, formattedData) => {
    const plotVariant = getPlotVariant(vizOptions);
    return plotVariant.toPlotlyTraces(vizOptions, apiData, formattedData);
  }
}

const getPlotVariant = (vizOptions) => {
  const currentSnapshotView = _.get(vizOptions, 'currentSnapshotView');
  if(isBarchart(currentSnapshotView)) {
    return barChart;
  } else if (isScatterChart(currentSnapshotView)) {
    if(_.isEmpty(_.get(vizOptions,'groupByViewType'))) {
      return scatterChart;
    } else {
      return groupByScatterChart;
    }
  } else {
    return pieChart;
  }
}
