import _ from 'lodash';

import {
  CUMULATIVE_DISTRIBUTION_TYPE,
  DISCRETE_DISTRIBUTION_TYPE,
  VISUALIZATION_TYPES
} from 'appConstants';

export const getDefaultDistributionOption = (currentDrilldownViewEntry) => {
  const defaultDistributionType = getDefaultDistributionType(currentDrilldownViewEntry);
  const isDiscreteAndCumulative = _.isEqual(defaultDistributionType, "discrete_and_cumulative");
  const isCumulativeDistribution =  isDiscreteAndCumulative ||  
    _.isEqual(defaultDistributionType, CUMULATIVE_DISTRIBUTION_TYPE);
  const isDiscrete =  isDiscreteAndCumulative || 
    _.isEqual(defaultDistributionType, DISCRETE_DISTRIBUTION_TYPE);
   return { isCumulativeDistribution, isDiscrete };
}

export const getInitialDistributionOptions = (viewEntry, visualizationEntry) => {
  const { isCumulativeDistribution, isDiscrete } = _.get(visualizationEntry, 'distribution', {});
  if(_.isUndefined(isCumulativeDistribution) && _.isUndefined(isDiscrete)) {
    const defaultDistributionType = getDefaultDistributionType(viewEntry);
    const isDiscreteAndCumulative = _.isEqual(defaultDistributionType, "discrete_and_cumulative", false);
    return {
      isCumulative: (isDiscreteAndCumulative || _.isEqual(defaultDistributionType, "cumulative", false)),
      isDiscrete: isDiscreteAndCumulative || _.isEqual(defaultDistributionType, "discrete")
    };
  }

  return { isCumulative: isCumulativeDistribution, isDiscrete };
}

export const getDistributionBenchmarkEntries = (viewEntry) => {
  return _.get(viewEntry, 'visualization.distribution.bench_mark_entries', []);
}

export const getDefaultDistributionType = (viewEntry) => {
  return _.get(viewEntry, 'visualization.distribution.default_distribution_type', 'discrete');
}

export const isDistributionType = (visualizationType) => {
  return (VISUALIZATION_TYPES.DISTRIBUTION.type === visualizationType);
}
