import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FilterItem from './FilterItem';
const ExpandedFilters = (props) => {
  const { filters, filterFieldEntries, templateId, apiParams, onFiltersChange } = props;
  const [newFilters, setNewFilters] = useState(filters);

  useEffect(() => {
    setNewFilters(filters)
  }, [filters]);

  const onSingleFilterChange = (filter, isRemove = false) => {
    let filterItems = _.reject(newFilters, {field: _.get(filter, 'field')});
    if (!isRemove) {
      filterItems.push(filter);
    }

    setNewFilters(filterItems);
    onFiltersChange(filterItems);
  };

  const renderFilterItems = () => {
    if (_.isEmpty(filterFieldEntries)) {
      return null;
    }

    return _.map(filterFieldEntries, (filter, index) => {
      if (_.get(filter, 'type') === 'text') {
        return _.map(filter.conditions, (filterCondition, conditionIndex) => {
          const newFilter = { ...filter, conditions: [filterCondition] };
          return renderFilterItem(newFilter, `${index}-${conditionIndex}`);
        });
      } else {
        return renderFilterItem(filter, index);
      }
    });
  }

  const renderFilterItem = (filter, filterKey) => {
    const key = `${filter.field}-${filterKey}`;

    return (
      <div className='filter-badge' key={key}>
        <div className="collection-filter-badge">
          <FilterItem
            key={key}
            filter={filter}
            filterKey={filterKey}
            isEditMode={true}
            filterFieldEntries={filterFieldEntries}
            templateId={templateId}
            apiParams={apiParams}
            allFilters={filters}
            onFilterChange={onSingleFilterChange}
            />
        </div>
      </div>
    );
  }

  return (
    <>
    {!_.isEmpty(filterFieldEntries) &&
      <div className='extented-form-wrapper'>
        {renderFilterItems()}
      </div>
    }
  </>
  );
};


ExpandedFilters.propTypes = {
  filters: PropTypes.array,
  filterFieldEntries: PropTypes.array,
  templateId: PropTypes.string,
  apiParams: PropTypes.object,
  onFiltersChange: PropTypes.func,
};


export default ExpandedFilters;
