import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  CARD_TYPES,
  VISUALIZATION_TYPES,
  SHAPE_AREA_TABS,
  FORECAST_PAGE_TYPES,
  SEARCH_BAR_VIEW,
  BOOKMARK_COLLECTION
} from 'appConstants';

import { updateBookmarkDetails, updateCurrentBookmark } from 'actions/bookmarkActions';
import { updateRadarOptions } from 'actions/radarActions';
import { updateForecastOptions } from 'actions/forecastingActions';
import { updateAdvanceSearchOptions, updatePolygonGeojson } from 'actions/advanceSearchActions';

import {
  updateCenterAndZoom,
} from 'actions/mapOptionsActions';
import { updateCurrentDistributionOption } from 'actions/distributionActions';

import {
  getDefaultVisualizationView,
} from 'common/config/visualizationConfiguration';
import {
  getAvailableVisualizationType,
} from 'helpers/visualizationHelper';
import { getMapCenter, getMapzoom } from 'common/config/templateConfiguration';
import { getDefaultDistributionOption } from 'helpers/distributionHelper';

import { getPolygonGeoJson } from 'reducers/advanceSearchReducer';
import { dispatchMetricSelection } from 'helpers/templateSelectionDispatchHelper';
import { updateOvertimeIsEmptyLegend, updateOvertimeShowLegendTotal } from 'actions/overtimeActions';
import { setSelectedView } from 'actions/dashboardActions';

const getCardClickHandler = (Component) => {
  const wrappedComponent = (props) => {
    const {
      dispatchRadarOptions,
      dispatchBookmarkSelection,
      dispatchMapCoordinates,
      dispatchTemplateSelection,
      dispatchUpdateCurrentDistributionOption,
      dispatchForecastOptions,
      dispatchAdvanceSearchOptions,
      dispatchUpdateShowLegendTotal,
      dispatchUpdateOvertimeIsEmptyLegend,
      // Only send the original props (+onCardClick) to the component. Do not send other
      // props received from redux to the component. Thats why de-constructing commonFilters
      // seperately
      ...componentProps
    } = props;
    const { globalFilters, userCollectionsWithCards, currentCollectionId, currentCollectionTagId} = props;

    const onCardClick = (cardType, cardConfig, defaultCommonFilters = {}, isSearchBar = false) => {
      if (cardType == CARD_TYPES.BOOKMARK) {
        if (_.get(cardConfig, 'is_radar', false)) {
          dispatchRadarOptions(cardConfig, isSearchBar);
        } else if (_.get(cardConfig, 'is_forecast', false)) {
          dispatchForecastOptions(cardConfig, isSearchBar);
        } else if (_.get(cardConfig, 'is_custom_advance_search', false)) {
          dispatchAdvanceSearchOptions(cardConfig, isSearchBar);
        } else {
          dispatchBookmarkSelection(cardConfig, defaultCommonFilters, isSearchBar);
        }
      } else {
        const { templateEntry, viewEntry, drilldownEntry, commonFilters, isNewCard } = cardConfig;
        dispatchTemplateSelection(
          templateEntry,
          viewEntry,
          drilldownEntry,
          commonFilters,
          globalFilters,
          isNewCard,
          userCollectionsWithCards,
          currentCollectionId || cardConfig.currentCollectionId,
          currentCollectionTagId || cardConfig.currentCollectionTagId,
          isSearchBar
        );
        dispatchMapCoordinates(templateEntry);
        dispatchUpdateCurrentDistributionOption(templateEntry, viewEntry);
        dispatchUpdateShowLegendTotal(true);
        dispatchUpdateOvertimeIsEmptyLegend(false);
      }
    };

    return <Component {...componentProps} onCardClick={onCardClick} />;
  };

  wrappedComponent.propTypes = {
    dispatchBookmarkSelection: PropTypes.func,
    dispatchMapCoordinates: PropTypes.func,
    dispatchTemplateSelection: PropTypes.func,
    dispatchUpdateCurrentDistributionOption: PropTypes.func,
    commonFilters: PropTypes.object,
    globalFilters: PropTypes.array
  };

  return wrappedComponent;
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchTemplateSelection: (
      templateEntry,
      viewEntry,
      drilldownEntry,
      commonFilters,
      globalFilters,
      isNewCard = false,
      userCollectionsWithCards,
      currentCollectionId,
      currentCollectionTagId,
      isSearchBar = false
    ) => {
      const isMetricCardClick = true;
      dispatch(setSelectedView(isSearchBar ? SEARCH_BAR_VIEW : currentCollectionId));
      dispatchMetricSelection(
        dispatch,
        templateEntry,
        viewEntry,
        drilldownEntry,
        commonFilters,
        globalFilters,
        isNewCard,
        userCollectionsWithCards,
        currentCollectionId,
        currentCollectionTagId,
        isMetricCardClick)
    },
    dispatchMapCoordinates: (entry) => {
      const { template_id } = entry;
      const center = getMapCenter(template_id);
      const zoom = getMapzoom(template_id);

      dispatch(updateCenterAndZoom({ center, zoom }));
    },
    dispatchUpdateCurrentDistributionOption: (templateEntry, viewEntry) => {
      const {
        isCumulativeDistribution,
        isDiscrete,
      } = getDefaultDistributionOption(viewEntry);
      const defaultVizView = getDefaultVisualizationView(viewEntry);
      const templateId = templateEntry.template_id;
      const availableVizType = getAvailableVisualizationType(
        templateId,
        defaultVizView,
        viewEntry
      );
      if (availableVizType === VISUALIZATION_TYPES.DISTRIBUTION.type) {
        dispatch(
          updateCurrentDistributionOption(isCumulativeDistribution, isDiscrete)
        );
      }
    },
    dispatchBookmarkSelection: (bookmarkConfig, defaultCommonFilters, isSearchBar = false) => {
      const isMyViewPageCard = _.get(bookmarkConfig, 'isMyViewPageCard', false);
      const currentCollectionId = _.get(bookmarkConfig, 'currentCollectionId', false);
      const currentView = isMyViewPageCard ? BOOKMARK_COLLECTION.name : currentCollectionId;

      dispatch(setSelectedView(isSearchBar ? SEARCH_BAR_VIEW : currentView));
      updateBookmarkDetails({
        bookmarkConfig,
        dispatch,
        isUpdateBookmarkId : true,
        defaultCommonFilters,
        canUpdateBookmarkViewCount: true});
    },
    dispatchRadarOptions: (bookmarkConfig, isSearchBar = false) => {
      const { id, radarOptions } = bookmarkConfig;
      const selectAreaName = _.get(radarOptions, 'selectAreaName', SHAPE_AREA_TABS[0].name);
      const comparisonAreaName = _.get(radarOptions, 'comparisonAreaName', SHAPE_AREA_TABS[1].name);
      dispatch(setSelectedView(isSearchBar ? SEARCH_BAR_VIEW : ''));

      dispatch(updateRadarOptions({
        ...radarOptions,
        bookmarkId: id,
        selectAreaName,
        comparisonAreaName
      }));
    },
    dispatchForecastOptions: (bookmarkConfig, isSearchBar = false) => {
      const { id, forecastOptions } = bookmarkConfig;
      dispatch(setSelectedView(isSearchBar ? SEARCH_BAR_VIEW : ''));
      dispatch(updateForecastOptions({
        ...forecastOptions,
        bookmarkId: id,
        currentForecastPage: FORECAST_PAGE_TYPES.CREATE_FORECASTS_PAGE,
      }));
    },
    dispatchAdvanceSearchOptions: (bookmarkConfig, isSearchBar = false) => {
      const { id, advanceSearchOptions } = bookmarkConfig;
      dispatch(setSelectedView(isSearchBar ? SEARCH_BAR_VIEW : ''));
      dispatch(updateCurrentBookmark(id));
      dispatch(updateAdvanceSearchOptions({
        ...advanceSearchOptions,
        bookmarkId: id,
      }));
      dispatch(updatePolygonGeojson(getPolygonGeoJson(advanceSearchOptions)));
    },
    dispatchUpdateShowLegendTotal: (enable) => {
      dispatch(updateOvertimeShowLegendTotal(enable));
    },
    dispatchUpdateOvertimeIsEmptyLegend: (isEmpty) => {
      dispatch(updateOvertimeIsEmptyLegend(isEmpty));
    }
  };
}

function mapStateToProps() {
  return {};
}

export const withCardClickHandler = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getCardClickHandler
);
