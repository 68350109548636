import * as actionTypes from '../actionTypes/tableActionTypes';

export const updateTableSortColumns = (sortColumns) => ({
  type: actionTypes.UPDATE_TABLE_SORT_COLUMNS,
  sortColumns
});

export const updateShowLeafPage = (isLeafPage) => ({
  type: actionTypes.UPDATE_SHOW_LEAF_PAGE,
  isLeafPage
});

export const updateLeafPageRecordIndex = (currentRecordIndex) => ({
  type: actionTypes.UPDATE_LEAF_PAGE_RECORD_INDEX,
  currentRecordIndex
});

export const updateLeafPageNoteId = (currentNoteId) => ({
  type: actionTypes.UPDATE_LEAF_PAGE_NOTE_ID,
  currentNoteId
});

export const updateShowTableViewOnDrilldown = (isTableView) => ({
  type: actionTypes.UPDATE_SHOW_TABLE_ON_DRILLDOWN,
  isTableView
});

export const updateTableLeafData = (currentLeafData) => ({
  type: actionTypes.UPDATE_TABLE_LEAF_DATA,
  currentLeafData
});