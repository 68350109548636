import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ForgeDrawer,
  ForgeButton
} from '@tylertech/forge-react';
import ExpandedFilters from 'common/components/Filters/ExpandedFilters';
import { isClickOutSideFilterPopup } from 'common/components/Filters/FilterDomHelper';
import CanvasBody from './CanvasBody';
import { removeEmptyFilterConditions } from 'pages/drilldown/components/QuickFilterBar/helper';

const OffCanvasFilter = (props) => {
  const { isOpen, setIsOpen, filterFieldEntries, filters, searchFilters,
    templateId, apiParams, onFiltersChange, resetSelection, onFiltersSave,
    isRightSideContainer = false
  } = props;
  const offCanvasRef = useRef();
  const [isDisableApply, setIsDisableApply] = useState(true);

  const handleClickOutside = (e) => {
    if (isClickOutSideFilterPopup(e, offCanvasRef.current, ['forge-popup'])) {
        setIsOpen(false);
    }
  }

  useEffect(() => {
    setIsDisableApply(_.isEqual(searchFilters, filters));
  }, [filters, searchFilters]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  },[]);



  const onClickCancelFilter = () => {
    setIsOpen(false);
  }

  const onApplyFilter = () => {
    setIsOpen(false);
    onFiltersSave();
  }

  const isDisabledButton = () => {
    return _.isEmpty(removeEmptyFilterConditions(filters));
  }

  const renderActionButtons = () => {
    return(
      <div className="section-titles forge-typography--overline d-flex align-items-center">
        Filters
        <div className='ml-auto'>
          <ForgeButton>
            <button disabled={isDisabledButton()} type="button" onClick={resetSelection}>
              <span>Reset</span>
            </button>
          </ForgeButton>
          <ForgeButton type="outlined" className="mx-2">
            <button type="button" onClick={onClickCancelFilter}>
              <span>Cancel</span>
            </button>
          </ForgeButton>
          <ForgeButton type="raised">
            <button type="button" onClick={onApplyFilter} disabled={isDisableApply}>
              <span>Apply</span>
            </button>
          </ForgeButton>
        </div>
      </div>
    );
  }


  return isOpen && (
    <>
      <CanvasBody isRightSideContainer={isRightSideContainer} />
      <div className="off-canvas-filter" ref={offCanvasRef}>
        <ForgeDrawer direction="left" open={isOpen}>
          <div className="filter-content">
            {renderActionButtons()}
            <ExpandedFilters
              disabledFilters={false}
              isDisabledAddFilterButton={false}
              filterFieldEntries={filterFieldEntries}
              filters={filters}
              templateId={templateId}
              apiParams={apiParams}
              onFiltersChange={onFiltersChange}
            />
          </div>
        </ForgeDrawer>
      </div>
    </>
  );
};

OffCanvasFilter.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    filterFieldEntries: PropTypes.array,
    filters: PropTypes.array,
    searchFilters: PropTypes.array,
    templateId: PropTypes.string,
    apiParams: PropTypes.object,
    onFiltersChange: PropTypes.func,
    resetSelection: PropTypes.func,
    onFiltersSave: PropTypes.func,
    isRightSideContainer: PropTypes.bool

};

export default OffCanvasFilter;