import moment from 'moment';
import { trackEvent } from "helpers/eventTracking";
import { 
  OVERTIME_VISUALIZATION_TYPES, 
  SNAPSHOT_VISUALIZATION_TYPES, 
  VISUALIZATION_TYPES 
} from "appConstants";
import {
  getChartWithTitleImageURL,
  getLegendContainer,
  getMapWithTitleURL,
  getMetricTotalContainer,
  getPlotlyChartCanvas,
  getSummaryTableContainer,
  getTimeOfDayChartContainer
} from "helpers/exportImageHelper";

export default class ExportImage {
  async exportImageBasedOnOptions(propsOption, stateOption) {
    const {
      currentVisualizationType,
      plotlyChart,
      currentMaps,
      currentSnapshotView,
      shouldHideCsvRawDataDownload = true,
      csvDownloadCallBack = _.noop,
      csvSummaryTableDownloadCallBack = _.noop,
      onClose = _.noop
    } = propsOption;
    const chart = plotlyChart();

    const {
      titleText = chart.querySelector('.viz-header').innerText,
      withTitle = true,
      withFooterNote = true,
      withSummaryCsv = false,
      withSummaryTable = false,
      withTableRawDataCsv = false,
      withChartImage = false,
      withFullPageImage = false
    } = stateOption;
    const optionsList = { withSummaryTable, withChartImage, withSummaryCsv, withFullPageImage };

    const legendCanvas = await getLegendContainer(chart);
    const metricTotalImage = await getMetricTotalContainer(chart);
    const summaryTableImage = await getSummaryTableContainer(chart);
    let chartImageCanvas = null;

    if(_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.TIME_OF_DAY.type)) {
      chartImageCanvas = await getTimeOfDayChartContainer(chart);
    } else if(!_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.TIME_OF_DAY.type) &&
      !_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.MAP.type) ) {
      chartImageCanvas = await this._getChartImageCanvas(chart, propsOption)
    }

    if (withSummaryCsv) {
      csvSummaryTableDownloadCallBack(true);
    }

    if (withTableRawDataCsv && !shouldHideCsvRawDataDownload) {
      csvDownloadCallBack(true);
    }

    if (!this._isEnableExport(stateOption)) {
      onClose(false);
      return null;
    }

    trackEvent('export-visualization', {
      visualizationType: currentVisualizationType
    });

    _.each(optionsList, async (value, optionKey) => {
      const options = {
        withChartImage: false,
        withSummaryTable: false,
        withSummaryCsv: false,
        withFullPageImage: false,
        titleText,
        withTitle,
        withFooterNote,
      };
      options[optionKey] = value;

      let chartOption = {
        chartContainer: chart,
        snapshotView: currentSnapshotView,
        legendCanvas,
        options,
        metricTotalImage,
        summaryTableImage
      };

      if (_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.MAP.type)) {
        _.each(currentMaps, async (currentMap) => {
          if (!_.isEmpty(currentMap)) {
            const mapImageCanvas = currentMap.map.getCanvas()

            chartOption['chartImageCanvas'] = mapImageCanvas;
            chartOption['dateRange'] = currentMap.dateRange;
            if (value && !options.withSummaryCsv) {
              await this._exportIndividual(chartOption, propsOption);
            }
          }
        })

      } else {
        chartOption['chartImageCanvas'] = chartImageCanvas;

        if (value && !options.withSummaryCsv) {
          await this._exportIndividual(chartOption, propsOption);
        }
      }
    })
  }

  async _exportIndividual(chartOption, propsOption){
    const { currentVisualizationType } = propsOption;

    let exportImageUrl;
    if (_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.MAP.type)) {
      exportImageUrl = await getMapWithTitleURL(chartOption);
    } else {
      exportImageUrl = await getChartWithTitleImageURL(chartOption);
    }

    this._onExportImage(exportImageUrl, chartOption.options, propsOption);
  }

  async _onExportImage(exportImageUrl, options, propsOption) {
    const invisibleLink = document.createElement('A');
    invisibleLink.setAttribute('href', exportImageUrl);
    invisibleLink.setAttribute('download', this._getExportFileName(options, propsOption));
    invisibleLink.click();
    propsOption.onCompleteExportImage(false);
  }

  async _getChartImageCanvas(chartContainer, propsOption){
    const { plotlyChartData, currentSnapshotView } = propsOption;
    if (!_.isEmpty(chartContainer)) {
      return await getPlotlyChartCanvas(plotlyChartData, chartContainer, currentSnapshotView)
    }
  }

  _isEnableExport(stateOption) {
    const { withFullPageImage, withChartImage, withSummaryTable } = stateOption;
    return (withFullPageImage || withChartImage || withSummaryTable);
  }

  _getExportFileName = (options, propsOption) => {
    const { currentVisualizationType, visualization } = propsOption;
    const { titleText } = options;

    let fileName = "";
    if (options.withSummaryTable) {
      fileName = "summary_table";
    } else if (options.withChartImage) {
      fileName = "chart";
    }

    let chartType, chartIndex, chartName = VISUALIZATION_TYPES.DISTRIBUTION.name;
    if (currentVisualizationType == VISUALIZATION_TYPES.SNAPSHOT.type) {
      chartType = _.get(visualization, `${currentVisualizationType}.currentSnapshotView`, 'barchart');
      chartIndex = _.indexOf(_.map(SNAPSHOT_VISUALIZATION_TYPES, 'type'), chartType);
      chartName = _.map(SNAPSHOT_VISUALIZATION_TYPES, 'name')[chartIndex];
    } else if (currentVisualizationType == VISUALIZATION_TYPES.OVERTIME.type) {
      chartType = _.get(visualization, `${currentVisualizationType}.currentChartView`, 'timeline');
      chartIndex = _.indexOf(_.map(OVERTIME_VISUALIZATION_TYPES, 'type'), chartType);
      chartName = _.map(OVERTIME_VISUALIZATION_TYPES, 'name')[chartIndex];
    } else if (currentVisualizationType == VISUALIZATION_TYPES.MAP.type) {
      chartType = _.get(visualization, 'mapOptions.currentMapView.name', 'Street Map');
      chartName = _.replace(chartType, ' Map', '')
    } else if (currentVisualizationType == VISUALIZATION_TYPES.MAP.type) {
      chartName = 'Time of day';
    }
    return `${titleText}_${chartName}_${fileName}_${moment().format("YYYYMMDD")}.jpeg`
  }
}
