import { COPY_CARD_IN_COLLECTION, REMOVE_CARD_FROM_COLLECTION } from 'actions/userCollectionsActions';
import { addCardInCollection, removeCardByCardId } from './helper';


export const userCollectionsReducerDefaultState = (userCollections) => {
  return {
    userCollectionsWithCards: userCollections
  };
};

export default function (state = userCollectionsReducerDefaultState(), action) {
  switch (action.type) {
    case REMOVE_CARD_FROM_COLLECTION:
      const userCollections = _.get(state,'userCollectionsWithCards',[])
      const { collectionId, cardId } = action;
      const updatedCollections = removeCardByCardId(userCollections, collectionId, cardId);
      return {
        ...state,
        userCollectionsWithCards: updatedCollections
      }

    case COPY_CARD_IN_COLLECTION:
      const userCollectionsWithCards = _.get(state,'userCollectionsWithCards',[])
      const { copyToCollectionId, copyCardId, templateEntry, viewEntry } = action;
      const updatedCollectionsWithCards = addCardInCollection(userCollectionsWithCards,
        templateEntry, viewEntry, copyToCollectionId, copyCardId);
      return {
        ...state,
        userCollectionsWithCards: updatedCollectionsWithCards
      }


    default:
      return state;
  }
}
