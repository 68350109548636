import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CompareEntityResult from './CompareEntityResult';
import PriorPeriodResult from './PriorPeriodResult';
import CurrentPeriodResult from './CurrentPeriodResult';

import { VARIANCE_COMPARISON_OPTIONS } from 'appConstants';
import { ErrorBoundaryWrapper } from 'pages/errorboundary/ErrorBoundaryWrapper';
import { getAbbreviatedStringValue } from 'helpers/numberHelper';
const EXTRA_DETAIL_DISPLAY_NAME = {
  total_sample_size_1: 'Current period sample size',
  total_sample_size_2: 'Previous period sample size',
  avg_1: 'Current period value',
  avg_2: 'Previous period value',
};

class VarianceResult extends Component {
  renderVarianceData(varianceData) {
    const { 
      comparisonOption, 
      currentDrilldownTemplateId, 
      selectedDimensionEntity, 
      primaryMetricField,
      secondaryMetricField,
      fetchVarianceData,
      fetchAnovaData,
    } = this.props;

    if (_.isEmpty(varianceData)) {
      return "None..."
    }

    switch(comparisonOption) {
      case _.get(VARIANCE_COMPARISON_OPTIONS, [0, 'type']):
        return <PriorPeriodResult 
          varianceData={varianceData}
          primaryMetricField={primaryMetricField}
          currentDrilldownTemplateId={currentDrilldownTemplateId} 
          fetchVarianceData={fetchVarianceData}
          fetchAnovaData={fetchAnovaData} />
      case _.get(VARIANCE_COMPARISON_OPTIONS, [1, 'type']):
        return <CurrentPeriodResult 
          varianceData={varianceData} 
          secondaryMetricField={secondaryMetricField}
          currentDrilldownTemplateId={currentDrilldownTemplateId} 
          fetchVarianceData={fetchVarianceData} 
          fetchAnovaData={fetchAnovaData} />
      default:
        return <CompareEntityResult 
          varianceData={varianceData} 
          selectedDimensionEntity={selectedDimensionEntity}
          currentDrilldownTemplateId={currentDrilldownTemplateId} 
          fetchVarianceData={fetchVarianceData} />
    }
  }
  
  renderExtraDetailItem = (value, key) => {
    if (!value) {
      return;
    }

    return (
      <span className="badge badge-info p-1 mr-2" key={key}>
        <b className="mr-1">{EXTRA_DETAIL_DISPLAY_NAME[key] || key}:</b>
        <i>{getAbbreviatedStringValue(value, 2, true, true)}</i>
      </span>
    );
  }

  render() {
    const { varianceData, comparisonOption } = this.props;

    if (_.isEmpty(comparisonOption)) {
      return null;
    }

    const { increased_entities, decreased_entities, extras } = varianceData;
    return (
      <div className="new-variance mt-5">
        <div>{_.map(extras, this.renderExtraDetailItem)}</div>
        <h5>Decreased Entities</h5>
        {this.renderVarianceData(decreased_entities)}
        <h5>Increased Entities</h5>
        {this.renderVarianceData(increased_entities)}
      </div>
    );
  }
}

VarianceResult.propTypes = {
  comparisonOption: PropTypes.oneOf(_.map(VARIANCE_COMPARISON_OPTIONS, 'type')),
  currentDrilldownTemplateId: PropTypes.string.isRequired,
  selectedDimensionEntity: PropTypes.object,
  primaryMetricField: PropTypes.string,
  secondaryMetricField: PropTypes.string,
  varianceData: PropTypes.object,
  fetchVarianceData: PropTypes.func,
  fetchAnovaData: PropTypes.func
}

function errorContent() {
  return (
    <div>
      Something went wrong in rendering the results.
    </div>
  );
}

export default ErrorBoundaryWrapper((VarianceResult), errorContent());
