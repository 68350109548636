import * as actionTypes from '../actionTypes/bookmarkActionTypes';

import {
  getCurrentTemplateEntry,
  getCurrentTemplateEntryById,
  getCurrentViewEntry,
} from 'common/config/templateConfiguration';
import {
  getConfiguredDataEndDate,
  getGlobalFiltersFromTemplate,
  getRadarOptions
} from 'common/config/customerConfiguration';

import {
  getRelativeFilterDateRange,
  getDefaultDateMode,
  getComparisonPeriodDateRanges
} from 'helpers/dateHelper';
import {
  getDefaultAxisGranularity,
  showAllYears,
  getOvertimeDimensionSortOrder
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { getMapViews } from 'modules/Map/helpers/MapOptionsHelper';
import {
  DATE_FORMAT,
  DEFAULT_MAP_VIEW,
  VISUALIZATION_TYPES,
  DATE_COMPARISON_TYPE
} from 'appConstants';
import { getBookmarkType } from 'pages/drilldown/components/Bookmark/bookmarkHelper'
import {
  setDefaultDrilldownMapOptions,
  updateCenterAndZoom,
  updateMapView,
  updatePolygonFeature,
  toggleShapeIdsFilter,
} from 'actions/mapOptionsActions';
import {
  updateDrilldownTemplateNameAndId,
  updateDrilldownDimensionField,
  updateDrilldownGroupByEntry,
  setDrilldownDimensions,
  updateQuickFilters,
  updateDrilldownViewEntry,
  setCurrentVisualizationType,
} from 'actions/drilldownActions';
import {
  updateGlobalFilter,
  updateDateFilters,
  updateComparisonModeOn,
  updateComparisonType,
  updateComparisonDateRanges
} from 'actions/commonFiltersActions';
import {
  updateTableSortColumns,
  updateShowLeafPage
} from 'actions/tableActions';
import { updateVisualizationReducer } from 'actions/visualizationActions';
import { updateCurrentSnapshotView } from 'actions/snapshotActions';
import {
  updateOvertimeChartView,
  updateAxisGranularity,
  updateOvertimeDimensionSortOrder
} from 'actions/overtimeActions';
import { updateSelectedAreaName, updateRadarOptions } from 'actions/radarActions';
import {
  getQuickFilterEntriesFromTemplate
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { getNewQuickFiltersAndGlobalFilters } from 'helpers/FilterHelper';
import { getCollectionId } from 'helpers/collectionIdHelper';
import { getCardId } from 'pages/embed/helper';
import { setAnalysisPageCollectionId, setCurrentCardId } from './dashboardActions';

export const updateCurrentBookmark = (bookmarkId, name, bookmarkType) => ({
  type: actionTypes.UPDATE_CURRENT_BOOKMARK,
  bookmarkId,
  name,
  bookmarkType
});

export const updateCanUpdateBookmarkViewCount = (canUpdateBookmarkViewCount) => ({
  type: actionTypes.UPDATE_BOOKMARK_VIEW_COUNT,
  canUpdateBookmarkViewCount
});

export const updateBookmarkDetails = (updateOptions) => {
  const {
    bookmarkConfig,
    dispatch,
    isUpdateBookmarkId,
    defaultCommonFilters,
    canUpdateBookmarkViewCount,
    userCollectionsWithCards
  } = updateOptions;

  const {
    drilldown,
    commonFilters,
    mapOptions,
    is_radar = false,
    bookmarkOptions,
    visualization,
    id,
    name
  } = bookmarkConfig;
  const bookmarkType = getBookmarkType(bookmarkConfig);
  let newCommonFilters = _.cloneDeep(defaultCommonFilters || commonFilters);
  newCommonFilters['comparisonModeOn'] = _.get(commonFilters, 'comparisonModeOn', false);
  let { relativeDateFilterEntry, dateRange, dateType, additionalDateFilters } = newCommonFilters;
  if (is_radar) {
    const radarOptions = getRadarOptions();
    const { selectAreaName, comparisonAreaName } = radarOptions;
    dispatch(updateSelectedAreaName('selectAreaName', selectAreaName));
    dispatch(updateSelectedAreaName('comparisonAreaName', comparisonAreaName));
    dispatch(updateRadarOptions({ bookmarkId: '' }));
    return;
  }

  const { currentDrilldownTemplateId, currentDrilldownViewEntry } = drilldown;
  const cardId = getCardId({ template_id: currentDrilldownTemplateId }, currentDrilldownViewEntry);
  dispatch(setCurrentCardId(cardId));

  if (!_.isEmpty(userCollectionsWithCards)) {
    const collectionId = getCollectionId(userCollectionsWithCards, cardId);
    dispatch(setAnalysisPageCollectionId(collectionId));
  } 

  if (canUpdateBookmarkViewCount) {
    dispatch(updateCanUpdateBookmarkViewCount(canUpdateBookmarkViewCount));
  }

  const viewId = _.get(currentDrilldownViewEntry, 'view_id');
  const globalFilters = _.get(commonFilters, 'globalFilters', []);

  const isRelativeDateTypeSelected =
    _.get(relativeDateFilterEntry, 'isRelativeFilter', 'false') == 'true';
  if (_.isEmpty(dateType)) {
    dateType = isRelativeDateTypeSelected
      ? 'relative'
      : getDefaultDateMode();
  }
  // Updating date range to current year end date if monitor is enabled
  if (dateType == 'relative') {
    dateRange = getRelativeFilterDateRange(relativeDateFilterEntry);
  } else if (_.get(bookmarkOptions, 'isRelativeDateSelected', false)) {
    (dateRange = {
      ...dateRange,
      endDate: getConfiguredDataEndDate().format(DATE_FORMAT),
    }),
      (dateType = 'custom_range');
  }

  let templateId = _.get(drilldown, 'currentDrilldownTemplateId');
  let templateName = _.get(drilldown, 'currentDrilldownTemplateName');
  const templateEntry = getCurrentTemplateEntryById(templateId);

  if (_.isNull(templateId)) {
    templateId = _.get(
      getCurrentTemplateEntry(templateName),
      'template_id'
    );
  } else {
    templateName = _.get(templateEntry, 'name');
  }
  const viewEntry = getCurrentViewEntry(templateId, viewId);
  const defaultMapView = _.get(getMapViews(), DEFAULT_MAP_VIEW);
  const dimensionField = _.get(drilldown, 'currentDrilldownDimensionField');
  const groupEntry = _.get(drilldown, 'currentDrilldownGroupByEntry');
  const dimensionFilters = _.get(drilldown, 'drilledDownDimensions');
  const quickFilters = _.get(drilldown, 'quickFilters');
  const visualizationType = _.get(drilldown, 'currentVisualizationType');
  const currentMapView = _.get(mapOptions, 'currentMapView', defaultMapView);
  const centerAndZoom = _.get(mapOptions, 'centerAndZoom');
  const filteredGeojson = _.get(mapOptions, 'filteredGeojson');
  const selectedShapeIds = _.get(mapOptions, 'shape.selectedShapeIds');
  const selectedShapesExtent = _.get(mapOptions, 'shape.selectedShapesExtent');
  const currentChartVizType = _.get(bookmarkOptions, 'currentVizChartType');
  const sortColumns = _.get(visualization, 'table.sortColumns', []);
  let axisGranularity = _.get(visualization, 'overtime.axisGranularity');
  const dimensionSortOrder = getOvertimeDimensionSortOrder(_.get(drilldown, 'currentDrilldownViewEntry'),
    currentChartVizType);
  const defaultAxisGranularity = getDefaultAxisGranularity(templateId);

  if (showAllYears()) {
    axisGranularity = _.includes(['month', 'year'], axisGranularity) ?
      axisGranularity :
      defaultAxisGranularity;
  }

  if (isUpdateBookmarkId) {
    dispatch(updateCurrentBookmark(id + '', name, bookmarkType));
  } else {
    dispatch(updateCurrentBookmark());
  }

  const globalFilterConfigEntries = getGlobalFiltersFromTemplate(templateEntry);
  const quickFilterEntries = getQuickFilterEntriesFromTemplate(templateId, viewEntry);
  const { newQuickFilters, newCollectionFilters } = getNewQuickFiltersAndGlobalFilters({
    collectionFilters: _.cloneDeep(globalFilters),
    globalFilterConfigEntries,
    quickFilters: _.cloneDeep(quickFilters),
    quickFilterEntries,
    isCardClick: true
  });

  dispatch(setDefaultDrilldownMapOptions(templateId)); //Default Map Options
  dispatch(updateDrilldownTemplateNameAndId(templateName, templateId)); //Updating template selection

  // Updating sentence builder values
  dispatch(updateDrilldownViewEntry(viewEntry));
  dispatch(updateDrilldownDimensionField(dimensionField));
  dispatch(updateDrilldownGroupByEntry(groupEntry));
  dispatch(updateQuickFilters(newQuickFilters));
  dispatch(updateGlobalFilter(newCollectionFilters));
  dispatch(updateMapView(currentMapView));
  dispatch(updateCenterAndZoom(centerAndZoom));
  dispatch(updatePolygonFeature({ featuresGeojson: filteredGeojson }));
  dispatch(toggleShapeIdsFilter(selectedShapeIds, selectedShapesExtent));

  dispatch(setDrilldownDimensions(dimensionFilters));
  dispatch(setCurrentVisualizationType(visualizationType));
  dispatch(updateTableSortColumns(sortColumns));
  dispatch(updateDateFilters({
    dateRange,
    dateType,
    relativeDateFilterEntry,
    additionalDateFilters
  }));

  // currently won't create bookmark from leaf page.
  dispatch(updateShowLeafPage(false));
  if (!_.isEmpty(visualization)) {
    dispatch(updateVisualizationReducer(visualization));
  } else if (
    currentChartVizType &&
    visualizationType == VISUALIZATION_TYPES.SNAPSHOT.type
  ) {
    dispatch(updateCurrentSnapshotView(currentChartVizType));
  } else if (
    currentChartVizType &&
    visualizationType == VISUALIZATION_TYPES.OVERTIME.type
  ) {
    dispatch(updateOvertimeChartView(currentChartVizType));
  }

  dispatch(updateAxisGranularity(axisGranularity));
  dispatch(updateOvertimeDimensionSortOrder(dimensionSortOrder));

  const comparisonType = _.get(newCommonFilters, 'comparisonType', DATE_COMPARISON_TYPE.PRIOR);
  const comparisonDateRanges = _.get(newCommonFilters, 'comparisonDateRanges', []);
  const comparisonModeOn = _.get(newCommonFilters, 'comparisonModeOn', false);
  const newComparisonDateRanges = getComparisonPeriodDateRanges(
    { comparisonType, dateRange, dateType, comparisonDateRanges });
  dispatch(updateComparisonType(comparisonType));
  dispatch(updateComparisonDateRanges(newComparisonDateRanges));
  dispatch(updateComparisonModeOn(comparisonModeOn));
}
