import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ResetButton from 'common/components/TimeOfDayFilter/ResetButton';
import MiniMapShapeFilter from 'pages/drilldown/MiniMapShapeFilter';
import Filters from 'common/components/Filters';
import BookmarkList from '../BookmarkSave/BookmarkList';
import {
  updateSearchFilters,
  updateSelectedShapeIds,
  updateShapeDatasetEntry,
  updateSelectedReportIds,
  updateReportPageData,
  updateSortColumns,
  updateSearchResultCount,
  updatePolygonGeojson
} from 'actions/advanceSearchActions';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import {
  getCurrentTemplateEntryById,
  getDefaultShapeDatasetEntry,
  getQuickFilterEntries
} from 'common/config/templateConfiguration';
import QuickFilter from '../QuickFilter/QuickFilter';
import LoadingSpinner from 'common/components/LoadingSpinner';
import PolygonFilterCard from 'modules/Map/PolygonFilterCard';

const SearchLeftSide = ({ map }) => {
  const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));
  const shapeDatasetEntry = useSelector(store => _.get(store, 'advanceSearch.shapeDatasetEntry'));
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));
  const filteredGeojson = useSelector(store =>
    _.get(store, 'advanceSearch.polygonsGeojson', {}));
  const isDrawingEnabled = useSelector(store =>
    _.get(store, 'advanceSearch.isDrawingEnabled', false));
  const [isLoading, setIsLoading] = useState(false);

  const onCreateBookmark = (isLoading) => {
    setIsLoading(isLoading);
  }

  const previousFilters = useMemo(() => searchFilters, []);
  const dispatch = useDispatch();
  const filterFieldEntries = getQuickFilterEntries(templateId);
  const apiParams = {
    ignore_view_entry: true,
    is_advanced_search: true,
    currentDrilldownTemplateId: templateId,
    availableTemplateIds: [templateId],
    drilldownEntry: JSON.stringify({ quickFilters: [] })
  };

  const resetSelection = () => {
    dispatch(updateReportPageData([]));
    dispatch(updateSelectedReportIds([]));
    dispatch(updateSortColumns([]));
    dispatch(updateSearchFilters([]));
    dispatch(updateCurrentBookmark(''));
    dispatch(updatePolygonGeojson({ featuresGeojson: {} }));
    dispatch(updateSearchResultCount(0));
  }

  const handleCollectionFiltersChange = (filters) => {
    dispatch(updateSearchFilters(filters));
    if (_.isEmpty(selectedShapeIds) && _.isEmpty(filters)) {
      resetSelection();
    }
  }

  const toggleShapeIdsFilter = (newShapeIds = [], shapeExtent = null) => {
    if (_.isEmpty(shapeDatasetEntry)) {
      const newShapeDatasetEntry = getDefaultShapeDatasetEntry(templateId)
      dispatch(updateShapeDatasetEntry(newShapeDatasetEntry));
    }

    dispatch(updateSelectedShapeIds(newShapeIds, shapeExtent));
    if (_.isEmpty(newShapeIds) && _.isEmpty(searchFilters)) {
      resetSelection();
    }
  }

  const onUpdateShapeDatasetEntry = (shapeDatasetEntry) => {
    dispatch(updateShapeDatasetEntry(shapeDatasetEntry));
  }

  const onResetButton = () => {
    dispatch(updateSearchFilters(previousFilters));
    if (_.isEmpty(selectedShapeIds)) {
      resetSelection();
    }
  }

  const isFiltersChanged = !_.isEmpty(searchFilters);
  const templateEntry = getCurrentTemplateEntryById(templateId);
  const currentMapStyleEntry = _.first(
    _.get(templateEntry, 'map.style_entries', [{}])
  );
  const defaultShapeDatasetEntry = _.isEmpty(shapeDatasetEntry) ?
    getDefaultShapeDatasetEntry(templateId) : shapeDatasetEntry;

  return (
    <div className='left-side-section drawer-panel-wrapper'>
      <LoadingSpinner isLoading={isLoading} />
      {/* <LeftSideModal /> */}
      <div className="section-titles forge-typography--overline d-flex align-items-center">
        Filters
        <div className="ml-auto">
          {isFiltersChanged && <ResetButton onUpdate={onResetButton} />}
        </div>
      </div>
      <BookmarkList onCreateBookmark={onCreateBookmark}></BookmarkList>
      {(!_.isEmpty(_.get(filteredGeojson, 'features')) && !isDrawingEnabled) &&
        <div className="filters-container">
          <PolygonFilterCard />
        </div>
      }
      <>
        <Filters
          disabledFilters={false}
          isCustomSearch={false}
          isDisabledAddFilterButton={true}
          type="quickFilters"
          filterFieldEntries={filterFieldEntries}
          filters={searchFilters}
          templateId={templateId}
          apiParams={apiParams}
          onFiltersChange={handleCollectionFiltersChange}
        />
      </>
      <QuickFilter />
      <div className="mapshape-filters-section">
        <MiniMapShapeFilter
          map={map}
          currentDrilldownTemplateId={templateId}
          currentMapStyleEntry={currentMapStyleEntry}
          selectedShapeEntry={defaultShapeDatasetEntry}
          selectedShapeIds={selectedShapeIds}
          dispatchToggleShapeIdsFilter={toggleShapeIdsFilter}
          updateShapeDatasetEntry={onUpdateShapeDatasetEntry}
        />
      </div>
    </div>
  );
}

SearchLeftSide.propTypes = {
  map: PropTypes.object
};

export default SearchLeftSide;
