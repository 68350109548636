import _ from 'lodash';
import { getSecondaryMetricEntry } from './visualizationHelper';
import { getDimensionEntries, getQuickFilterEntries } from 'common/config/templateConfiguration';

import {
  DATE_OPTIONS_TYPE,
  SNAPSHOT_VISUALIZATION_TYPES,
  STRING_TYPES_FIELD,
  BETWEEN,
  NOT_BETWEEN,
  STACK_CHART_ADD_TEXT
} from 'appConstants';
import moment from 'moment';
import { getLogicalOperatorEntries } from 'pages/drilldown/components/QuickFilterBar/helper';
import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { getCurrentFilterDateRange, getComparisonPeriodDateRanges } from 'helpers/dateHelper';

export const getSecondaryMetricNameForSnapshot = (
  viewEntry,
  visualizationType,
  snapshotView,
  secondaryMetricField
) => {
  const currentSecondaryMetricEntry = getSecondaryMetricEntry(
    viewEntry,
    visualizationType,
    snapshotView,
    secondaryMetricField
  );
  const currentSecondaryMetricName = _.get(currentSecondaryMetricEntry, 'name');
  return (!_.isEmpty(_.get(currentSecondaryMetricEntry, 'field')) &&
            (snapshotView !== SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type)) ?
            ` and ${currentSecondaryMetricName}` : '';
}

export const getSecondaryMetricNameForOvertime = (
  isComboChart,
  comboChartMetricList,
  currentSecondaryMetricEntry
) => {
  const secondaryMetricWithoutComboChart = !_.isEmpty(_.get(currentSecondaryMetricEntry, 'field')) ?
                                              ` and ${_.get(currentSecondaryMetricEntry, 'name')}` : '';
  return isComboChart ? comboChartMetricList : secondaryMetricWithoutComboChart;
}

export const getSecondaryMetricName = ({
  currentDrilldownViewEntry,
  currentVisualizationType,
  currentSnapshotView,
  currentSecondaryMetricField,
  isComboChart,
  comboChartMetricList,
  currentSecondaryMetricEntry
}) => {
  const secondaryMetricForSnapshot = getSecondaryMetricNameForSnapshot(
    currentDrilldownViewEntry,
    currentVisualizationType,
    currentSnapshotView,
    currentSecondaryMetricField
  );
  const secondaryMetricForOvertime = getSecondaryMetricNameForOvertime(
    isComboChart, comboChartMetricList, currentSecondaryMetricEntry);
  const secondaryMetricName = (currentVisualizationType === 'snapshot') ?
    secondaryMetricForSnapshot : secondaryMetricForOvertime;
  return (currentVisualizationType !== 'distribution') ? secondaryMetricName : '';
}

export const getDimensionName = (templateId, dimensionField) => {
  const dimensions_entries = getDimensionEntries(templateId);
  const dimension_entry = _.find(dimensions_entries, { 'field': dimensionField });
  return _.get(dimension_entry,'name', '');
}

export const getGroupName = (groupByEntry, snapshotView, currentVisualizationType) => {
  const groupByName = _.get(groupByEntry, 'name');
  const groupByField = _.get(groupByEntry, 'field');
  return (!_.isEmpty(groupByField) &&
          (snapshotView !== SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type) &&
          (currentVisualizationType === 'snapshot')) ? ` grouped by ${groupByName}` : '';
}

export const getDateRangeText = (dateRange, dateType) => {
  const startDate = moment(_.get(dateRange, 'startDate'));
  const endDate = moment(_.get(dateRange, 'endDate'));
  const startYear = Number(startDate.format('YYYY')) + 1;
  const endYear = endDate.format('YYYY');
  const diffDays = endDate.diff(startDate,'days');
  const customRange = `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`;
  const fiscalYear = (startYear !== endYear && diffDays > 365) ? `${startYear} - ${endYear}` : `${endYear}`;
  return _.isEqual(dateType, DATE_OPTIONS_TYPE.YEARLY) ? `${fiscalYear}` : `${customRange}`;
}

export const getQuickFilterForDescription = (quickFilters, templateId) => {
  const quickFilterEntries = getQuickFilterEntries(templateId);
  return getFiltersDescription(quickFilters, quickFilterEntries, templateId);
}

export const getFiltersDescription = (quickFilters, quickFilterEntries, templateId) => {
  const nullValueLabel = getNullValueLabel(templateId);

  if(!_.isEmpty(quickFilters)){
    return _.map(quickFilterEntries, (filter) => {
      const selectedFilter = _.find(quickFilters, { 'field':  filter.field });
      if (!_.isEmpty(selectedFilter)) {
        let filteredValue = []
        const filterRenderType = _.get(selectedFilter, 'type', '');

        if(_.isEmpty(filterRenderType) || filterRenderType === STRING_TYPES_FIELD) {
          return getTextFilterDescription(filter, selectedFilter, nullValueLabel);
        } else if(filterRenderType === "date") {
          const dateRange = _.get(selectedFilter, 'dateRange');
          const startDateDescription = moment(dateRange.start_date).format('LL');
          const endDateDescription = moment(dateRange.end_date).format('LL');
          filteredValue.push(`<b>${startDateDescription} - ${endDateDescription}</b>`);
        } else {
          const value = getNumberTypeFilterValue(selectedFilter, filterRenderType);
          filteredValue.push(value);
        }
        return `${_.get(filter, 'name')}: ${_.join(filteredValue, ', ')}`;
      } else {
        return '';
      }
    });
  }else{
    return ''
  }
}

const getTextFilterDescription = (filter, selectedFilter, nullValueLabel) => {
  const descriptionForConditions = _.map(_.compact(selectedFilter.conditions), (valueItem) => {
    const { value, operator } = valueItem;
    return getDescriptionForValue({ values: value, operator, filter, nullValueLabel });
  });

  return _.join(descriptionForConditions, '. ');
}

const getDescriptionForValue = ({ values, operator, filter, nullValueLabel }) => {
  const filterValueFormatter = (filteredValues) => {
    return _.map(filteredValues, (filterValue) => {
      return (_.isNil(filterValue) || filterValue === '') ? nullValueLabel : filterValue;
    });
  };
  const logicalOperatorEntries = getLogicalOperatorEntries(STRING_TYPES_FIELD);
  const formattedValues = filterValueFormatter(values);
  const logicalOperatorEntry = _.find(logicalOperatorEntries, { value: operator });
  const operatorName = _.toLower(logicalOperatorEntry.name);

  return `${_.get(filter, 'name')} ${operatorName}: <b>${_.join(formattedValues, ', ')}</b>`;
}

const getNumberTypeFilterValue = (selectedFilter, renderType) => {
  const filterOperator = _.get(selectedFilter, 'operator');
  const logicalOperator = getOperatorName(filterOperator, renderType);
  const operatorPlaceHolder = _.get(logicalOperator, 'placeHolder');
  const operator = (filterOperator !== BETWEEN &&
    filterOperator !== NOT_BETWEEN) ? `${operatorPlaceHolder} -` : _.capitalize(filterOperator)
  const filterValue = _.get(selectedFilter, 'value');
  const toValue = _.get(selectedFilter, 'to');
  const actualToValue = (filterOperator !== BETWEEN && filterOperator !== NOT_BETWEEN) ? '' : ` to ${toValue}`
  return `${operator} <b>${filterValue}${actualToValue}</b>`
}

const getOperatorName = (operator, renderType) => {
  const logicalOperatorList = getLogicalOperatorEntries(renderType);
  return _.find(logicalOperatorList, {'value' : operator});
}

export const getPrimaryMetricName = (currentViewEntry) => {
  return (!_.isEmpty(_.get(currentViewEntry, 'primary_metric_name')) ?
    _.get(currentViewEntry, 'primary_metric_name') : _.get(currentViewEntry, 'name'));
}

export const getFirstLineOfDescription = (viewEntry) => {
  return _.get(viewEntry, 'visualization_description', '');
}

export const getCompareToYearForMapDescription = (commonFilters) => {
  const { dateRange } = commonFilters;
  const currentPeriodDateRange =  getCurrentFilterDateRange(dateRange, 'll');
  const previousPeriodDateRange = getCurrentFilterDateRange(
    getComparisonPeriodDateRanges(commonFilters)[0],
    'll'
  );

  const currentPeriodText = `${currentPeriodDateRange.startDate} - ${currentPeriodDateRange.endDate}`;
  const previousPeriodText = `${previousPeriodDateRange.startDate} - ${previousPeriodDateRange.endDate}`;
  return `${currentPeriodText} and ${previousPeriodText}`;
}

export const  getTickText = (actualLabelValues, letterLimitCount, withoutTruncate = false) => {
  return _.map(actualLabelValues, (dimensionLabel) => {
    const dimensionSplitLabel = _.first(_.split(dimensionLabel, STACK_CHART_ADD_TEXT));
    let labelText = dimensionSplitLabel;
    if (_.isEmpty(dimensionSplitLabel)) {
      return  dimensionSplitLabel;
    }
    if(labelText.length > letterLimitCount){
      labelText = _.split(dimensionSplitLabel,' ');
      if(labelText.length > 1 && labelText[0].length <= 15){
        return getFormatTickLabel(labelText, letterLimitCount, withoutTruncate);
      } else {
        return  _.truncate(labelText, {'length': letterLimitCount})
      }
    } else {
     return _.truncate(dimensionSplitLabel, {'length': letterLimitCount})
    }
  });
}

function getFormatTickLabel(labelText, letterLimitCount, withoutTruncate = false) {
  let firstTextCount = 0;
  let canMoveToSecondLine = false;
  let firstLineString="";
  let secondLineString="";

  _.map(labelText, (word) => {
     if(word.length <= letterLimitCount && firstTextCount == 0) {
         firstTextCount = word.length;
         firstLineString = word
     } else {
       // continue to same line
       if((letterLimitCount - firstTextCount) > word.length && canMoveToSecondLine === false){
         firstLineString = `${firstLineString} ${word}`;
         firstTextCount = firstLineString.length;
       } else {
         if(!canMoveToSecondLine){
          if(word.length > letterLimitCount && _.isEmpty(firstLineString)) {
             firstLineString =  `${word}<br>`;
             firstTextCount = word.length;
             word = '';
          } else {
            firstLineString = `${firstLineString}<br>`;
          }
        }

         secondLineString = `${secondLineString} ${word}`;
         canMoveToSecondLine = true;
       }
     }
   });

   //final result
   if(!_.isEmpty(secondLineString)){
     return  firstLineString += withoutTruncate ?
      secondLineString : _.truncate(secondLineString, {'length': letterLimitCount});
   }
}

export const getShapeFilterDescription = (shapeData, datasetEntry, shapeIds) => {
  const currentShapeDataName = _.get(datasetEntry, 'shape_name');
  const newShapeData = _.cloneDeep(_.map(shapeData, (data) => { return _.first(data)}))
  let selectedShapeName = []
  _.each(shapeIds, (shapeId) => {
    selectedShapeName.push(_.get(_.find(newShapeData, {shape_id: shapeId}), 'shape_name'))
  })
  if(!_.isEmpty(shapeIds)){
    const shapeFilterList = `${_.join(selectedShapeName, ', ')}`
    const shapeFilters = ` ${currentShapeDataName}: <b>${shapeFilterList}.</b> `
    return !_.isEmpty(shapeData) ? shapeFilters : '' ;
  }else{
    return '';
  }
}

// This function for to check user display name is a emailid
const isEmailId = (email) => {
  let regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regExp.test(email);
}

export const getInitialOfUserName = (userName, isOmniBar = false) => {
  const splitUserName = _.split(userName, /[ .]+/);
  if(isOmniBar) {
    return _.map(userName.split('.'), _.upperFirst).join(' ');
  }
  if(isEmailId(userName)) {
    return `${_.toUpper(userName.substring(0,2))}`
  } else {
    const multipleName = _.map(splitUserName, (name) => {
      if(splitUserName.length == 1) {
        return `${_.toUpper(name.substring(0,2))}`;
      } else {
        return `${_.toUpper(name.charAt(0))}`;
      }
    })
    return _.join(multipleName, '');
  }
}

export const getDimensionNameByParams = (props) => {
  const drilldownEntry = JSON.parse(_.get(props, 'apiParams.drilldownEntry', "{}"));
  const { currentDrilldownDimensionField, currentDrilldownTemplateId } = drilldownEntry;
  return getDimensionName(currentDrilldownTemplateId, currentDrilldownDimensionField);
}

export const getPermissionFilterDescription = (permissionFilers, templateId) => {
  if(_.isEmpty(permissionFilers)){
    return '';
  }
  const quickFilterEntries = getQuickFilterEntries(templateId);
  const nullValueLabel = getNullValueLabel(templateId);
  const descriptionForFilters = _.map(permissionFilers, (values, filter_column) => {
    const operator = '='
    const filterEntry = _.find(quickFilterEntries, {column: filter_column})
    return getDescriptionForValue({ values, operator, filter: filterEntry, nullValueLabel });
  });

  return _.join(descriptionForFilters, '. ');

}
