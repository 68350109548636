import _ from 'lodash';
import React, { Component } from "react";
import { connect } from 'react-redux';
import DrillDownSentenceBuilder from 'common/components/DrillDownSentenceBuilder/DrillDownSentenceBuilder';
import { updateTableSortColumns } from 'actions/tableActions';
import { getDefaultSortOptionForTable } from 'modules/DetailsTable/TableHelper';
import {
  SNAPSHOT_VISUALIZATION_TYPES,
  OVERTIME_VISUALIZATION_TYPES,
  VISUALIZATION_TYPES,
  DRILLDOWN_VIEW_TYPE
} from 'appConstants';

import {
  updateDrilldownDimensionField,
  updateDrilldownGroupByEntry,
  updateDrilldownViewEntry,
  updateTemplateEntryChange,
  setCurrentVisualizationType,
  updateQuickFilters
} from 'actions/drilldownActions';
import {
  updateCenterAndZoom,
  setDefaultDrilldownMapOptions,
  removePolygonFilter,
  toggleShapeIdsFilter,
  updateShapeDatasetEntry
} from 'actions/mapOptionsActions'
import { updateGroupBychartApproach, updateSnapshotBenchMarkName } from 'actions/snapshotActions';
import { getDefaultVisualizationChartView,
  getAvailableVisualizationTypes,
  getOvertimeChartOptions,
  getDefaultBenchMarkEntries,
  getDefaultDimensionField,
  isGeoLocationOrUrlColumn
} from 'helpers/visualizationHelper';
import {
  updateOvertimeBenchMarkName,
  updateVisualizationDefaults,
  updateAxisGranularity,
  updateSliderRange,
  updateOvertimeDimensionSortOrder
} from 'actions/overtimeActions';
import { updateComparisonModeOn } from 'actions/commonFiltersActions';
import {
  getDimensionEntries,
  getGroupByEntries,
  getDefaultShapeDatasetEntry
} from 'common/config/templateConfiguration';
import {
  shouldShowRangeChart,
  shouldShowPieChart,
  getDefaultGroupByColumn
} from 'common/config/visualizationConfiguration';
import {
  getDefaultVisualizationView,
  getDistributionBucketEntry } from 'common/config/visualizationConfiguration';
import { updateShowTableViewOnDrilldown, updateShowLeafPage } from 'actions/tableActions';
import {
  getDefaultAxisGranularity,
  getOvertimeDimensionSortOrder
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { updateCurrentDistributionOption } from 'actions/distributionActions';
import { getDefaultDistributionOption } from 'helpers/distributionHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';


class VisSentenceBuilder extends Component {

  onDimensionSelect = (selectedEntry) => {
    const { dispatchUpdateDrilldownDimensionField } = this.props;
    dispatchUpdateDrilldownDimensionField(selectedEntry['field']);
    this.updateSnapshotBenchMark(selectedEntry['field']);
    this.updateOvertimeBenchMark(selectedEntry['field']);
    if (_.isEmpty(selectedEntry['field'])) {
      this.props.dispatchUpdateDrilldownGroupEntry(selectedEntry);
    }
  }

  onViewSelect = (entry) => {
    const {
      dispatchUpdateDrilldownViewEntry,
      quickFilters,
      currentVisualizationType
    } = this.props;
    const defaultSort = getDefaultSortOptionForTable(entry, entry.template_id);
      // Removed isDistributionFilter in quick Filters when view is changed.
    const newQuickFilters = _.reject(quickFilters, { 'isDistributionFilter': true });
    const defaultVizView = getDefaultVisualizationView(entry);
    const isUpdateDrilldownField = !(defaultVizView === currentVisualizationType);

    window.lastUrls = []; // reset undo button when switching visualization type
    window.isUndoEnabled = false; // reset undo button when switching visualization type
    this.updateVisualizationReducer(entry, entry.template_id);
    dispatchUpdateDrilldownViewEntry(entry, defaultSort, newQuickFilters, isUpdateDrilldownField);
  }

  onGroupBySelect = (entry) => {
    this.props.dispatchUpdateDrilldownGroupEntry(entry);
  }

  onTemplateChange = (entry, newViewEntry) => {
    const viewEntry = newViewEntry || _.first(_.get(entry, 'view_entries', []));
    const defaultSort = getDefaultSortOptionForTable(viewEntry, entry.template_id);
    this.props.dispatchTemplateEntry(entry, defaultSort);

    this.updateVisualizationReducer(viewEntry, entry.template_id);
  }

  updateVisualizationReducer = (viewEntry, templateId) => {
    const {
      currentVisualizationType,
      currentSnapshotView,
      currentChartView,
      dispatchSetCurrentVisualizationType,
      dispatchUpdateCurrentDistributionOption,
      commonFilters
    } = this.props;
    let snapshotView = currentSnapshotView;
    let overtimeView = currentChartView;
    const overtimeChartOptions = getOvertimeChartOptions(viewEntry);
    const dimensionEntries = getDimensionEntries(templateId);
    const drilldownDimensionField = getDefaultDimensionField(dimensionEntries, viewEntry);
    const defaultVizView = getDefaultVisualizationView(viewEntry);
    const availableVisualizationTypes = getAvailableVisualizationTypes(templateId, viewEntry);

    const groupEntries = getGroupByEntries(templateId);
    const defaultGroupByColumn = getDefaultGroupByColumn(viewEntry);
    const groupbyColumn = isGeoLocationOrUrlColumn(defaultGroupByColumn) ?
      _.get(groupEntries, '0.column') :
      defaultGroupByColumn;
    const currentDrilldownGroupByEntry = _.find(groupEntries, { 'column':  groupbyColumn });
    const bucketEntry = getDistributionBucketEntry(viewEntry);

    if (snapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type && !shouldShowPieChart(viewEntry)) {
      snapshotView = SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type;
    }else if(snapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type &&
      !shouldShowRangeChart(viewEntry)){
      snapshotView = SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type;
    }

    if (!_.includes(_.map(overtimeChartOptions, 'type'), overtimeView)) {
      overtimeView = OVERTIME_VISUALIZATION_TYPES.TIMELINE.type;
    }

    if (!_.includes(_.map(availableVisualizationTypes,'type'), currentVisualizationType)) {
      dispatchSetCurrentVisualizationType(defaultVizView);
      snapshotView = getDefaultVisualizationChartView(viewEntry, VISUALIZATION_TYPES.SNAPSHOT.type);
    } else {
      if (_.isEqual(VISUALIZATION_TYPES.DISTRIBUTION.type, currentVisualizationType) &&
          _.isEmpty(bucketEntry)){
        dispatchSetCurrentVisualizationType(VISUALIZATION_TYPES.SNAPSHOT.type);
      }
    }

    if(_.isEqual(VISUALIZATION_TYPES.MAP.type, currentVisualizationType)
        || _.isEqual(VISUALIZATION_TYPES.MAP.type, defaultVizView)
      ){
      this.props.dispatchUpdateMapDefaults(templateId, viewEntry)
    }

    const defaultDistributionOption = getDefaultDistributionOption(viewEntry);
    dispatchUpdateCurrentDistributionOption(
      defaultDistributionOption.isCumulativeDistribution,
      defaultDistributionOption.isDiscrete
    )

    const defaultOptions = {
      currentSnapshotView: snapshotView,
      currentOvertimeView: overtimeView,
      currentDrilldownViewEntry: viewEntry,
      currentDrilldownTemplateId: templateId,
      currentDrilldownDimensionField: drilldownDimensionField,
      currentDrilldownGroupByEntry,
      commonFilters
    };
    this.props.dispatchUpdateVisualizationDefaults(defaultOptions);
  }

  updateSnapshotBenchMark = (dimensionField) => {
    const {
      currentSnapshotView,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId
    } = this.props;

    const snapshotView = currentSnapshotView ||
      getDefaultVisualizationChartView(currentDrilldownViewEntry, VISUALIZATION_TYPES.SNAPSHOT.type);
    
    const defaultBenchMarkEntries = getDefaultBenchMarkEntries(
      currentDrilldownViewEntry, 'snapshot', snapshotView, currentDrilldownTemplateId, dimensionField);
    this.props.dispatchUpdateSnapshotBenchMarkName(_.compact(_.map(defaultBenchMarkEntries, 'name', '')));
  }

  updateOvertimeBenchMark = (dimensionField) => {
    const {
      currentChartView,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId
    } = this.props;

    const overtimeView = currentChartView ||
      getDefaultVisualizationChartView(currentDrilldownViewEntry, VISUALIZATION_TYPES.OVERTIME.type);
    const defaultBenchMarkEntries = getDefaultBenchMarkEntries(
      currentDrilldownViewEntry, 'overtime', overtimeView, currentDrilldownTemplateId, dimensionField);
    this.props.dispatchUpdateOvertimeBenchMarkName(_.compact(_.map(defaultBenchMarkEntries, 'name', '')));
  }

  render() {
    const {
      currentDrilldownGroupByEntry,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      currentDrilldownTemplateName,
      currentDrilldownDimensionField,
      drilledDownDimensions
    } = this.props;

    return (
      <>
        <DrillDownSentenceBuilder {... {
          currentDrilldownGroupByEntry,
          currentDrilldownViewEntry,
          currentDrilldownTemplateId,
          currentDrilldownTemplateName,
          currentDrilldownDimensionField,
          drilledDownDimensions }}
          showTemplateDropDown={true}
          onTemplateChange={this.onTemplateChange}
          onViewSelect={this.onViewSelect}
          onDimensionSelect={this.onDimensionSelect}
          onGroupBySelect={this.onGroupBySelect} />
      </>
    );
  }
}

VisSentenceBuilder.propTypes = {
  currentDrilldownGroupByEntry: commonPropTypes.groupByEntryPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentDrilldownTemplateName: PropTypes.string,
  currentDrilldownDimensionField: PropTypes.string,
  drilledDownDimensions: PropTypes.array,
  currentVisualizationType: PropTypes.string,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  currentChartView: PropTypes.string,
  currentSnapshotView: PropTypes.string,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  dispatchUpdateDrilldownDimensionField: PropTypes.func,
  dispatchUpdateDrilldownViewEntry: PropTypes.func,
  dispatchUpdateDrilldownGroupEntry: PropTypes.func,
  dispatchTemplateEntry: PropTypes.func,
  dispatchSetCurrentVisualizationType: PropTypes.func,
  dispatchUpdateCurrentDistributionOption: PropTypes.func,
  dispatchUpdateMapDefaults: PropTypes.func,
  dispatchUpdateVisualizationDefaults: PropTypes.func,
  dispatchUpdateSnapshotBenchMarkName: PropTypes.func,
  dispatchUpdateOvertimeBenchMarkName: PropTypes.func
}

function mapStateToProps(state) {
  return {
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry', {}),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    currentDrilldownTemplateName: _.get(state, 'drilldown.currentDrilldownTemplateName', ''),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField', ''),
    drilledDownDimensions: _.cloneDeep(_.get(state, 'drilldown.drilledDownDimensions', [])),
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    quickFilters:  _.cloneDeep(_.get(state, 'drilldown.quickFilters', [])),
    currentChartView:  _.get(state, 'visualization.overtime.currentChartView', ''),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView'),
    commonFilters:  _.get(state, 'commonFilters', {})
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateDrilldownDimensionField: (field) => {
      dispatch(updateDrilldownDimensionField(field));
    },
    dispatchUpdateDrilldownGroupEntry: (entry) => {
      dispatch(updateDrilldownGroupByEntry(entry));
      if(entry.name === 'None'){
        dispatch(updateGroupBychartApproach(_.get(_.first(DRILLDOWN_VIEW_TYPE),'type')));
      }
    },
    dispatchUpdateDrilldownViewEntry: (entry, defaultSort, quickFilters, isUpdateDrilldownField) => {
      dispatch(updateSliderRange([]));
      dispatch(updateDrilldownViewEntry(entry, isUpdateDrilldownField));
      dispatch(updateTableSortColumns([defaultSort]));
      dispatch(updateQuickFilters(quickFilters));
      dispatch(updateGroupBychartApproach(_.get(_.first(DRILLDOWN_VIEW_TYPE),'type')));
      dispatch(updateComparisonModeOn(false));
    },
    dispatchTemplateEntry: (entry, defaultSort) => {
      const templateId = entry.template_id;
      const viewEntry = _.first(_.get(entry, 'view_entries', []))
      const center = [
        parseFloat(_.get(entry, 'map.centerLng', 0)),
        parseFloat(_.get(entry, 'map.centerLat', 0))
      ];
      const centerAndZoom = {
        center,
        zoom: _.get(entry, 'map.zoom', 1)
      };
      const chartView = getDefaultVisualizationChartView(viewEntry, VISUALIZATION_TYPES.OVERTIME.type);
      const defaultAxisGranularity = getDefaultAxisGranularity(templateId);
      const shapeDatasetEntry = getDefaultShapeDatasetEntry(templateId);
      const dimensionSortOrder = getOvertimeDimensionSortOrder(viewEntry, chartView);
      dispatch(updateShapeDatasetEntry(shapeDatasetEntry));
      dispatch(toggleShapeIdsFilter([], null));
      dispatch(updateAxisGranularity(defaultAxisGranularity));
      dispatch(updateOvertimeDimensionSortOrder(dimensionSortOrder));
      dispatch(updateSliderRange([]));
      dispatch(updateTableSortColumns([defaultSort]));
      dispatch(updateCenterAndZoom(centerAndZoom));
      dispatch(updateTemplateEntryChange(entry));
      dispatch(updateGroupBychartApproach(_.get(_.first(DRILLDOWN_VIEW_TYPE),'type')));
      dispatch(removePolygonFilter());//Resetting Map Polygon Filter
      dispatch(updateShowTableViewOnDrilldown(false));
      dispatch(updateShowLeafPage(false));
      dispatch(updateComparisonModeOn(false));
    },
    dispatchUpdateMapDefaults: (templateId, viewEntry) => {
      dispatch(setDefaultDrilldownMapOptions(templateId, viewEntry)) //Default Map Options
    },
    dispatchUpdateVisualizationDefaults: (options) => {
      dispatch(updateVisualizationDefaults(options));
    },
    dispatchUpdateSnapshotBenchMarkName: (name) => {
      dispatch(updateSnapshotBenchMarkName(name));
      dispatch(updateComparisonModeOn(false));
    },
    dispatchUpdateOvertimeBenchMarkName: (name) => {
       dispatch(updateOvertimeBenchMarkName(name));
    },
    dispatchSetCurrentVisualizationType: (visualizationType) => {
      dispatch(setCurrentVisualizationType(visualizationType))
    },
    dispatchUpdateCurrentDistributionOption: (isCumulativeDistribution, isDiscrete) => {
      dispatch(updateCurrentDistributionOption(isCumulativeDistribution, isDiscrete));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisSentenceBuilder);
