import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDrop, useDrag } from 'react-dnd';

import { childrenPropTypes } from 'common/propTypes';
import { DRAGGABLE, COLLECTION_ID } from 'appConstants';
import { isMyViews } from 'pages/dashboard/components/CardList/cardHelper';
import MyPreview from './MyPreview';
import { getEmptyImage } from 'react-dnd-html5-backend'
import { canUserRestrictCollections } from '../ManageCollection/collaboratorHelper';

function CollectionDrag({ collection, children, collectionTabRef }) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DRAGGABLE.TYPES.COLLECTION,
      collection
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const dragClassNames = classNames('collection-drag h-100', {
    'is-dragging': isDragging,
  });

  return (
    <div
      ref={drag} className={dragClassNames}
      style={{ position: 'relative', zIndex: isDragging ? 10000: 0 }}
    >
      <MyPreview ref={preview} collectionTabRef={collectionTabRef} />
      {children}
    </div>
  );
}

CollectionDrag.propTypes = {
  collection: PropTypes.object,
  children: childrenPropTypes,
  collectionTabRef: PropTypes.object
};

function CollectionDrop({
  collection,
  onCollectionMove,
  insertAfter,
  children,
}) {
  // return (<div className="collection-drop insert-before"></div>);
  const [{ isHoveredOver, canDrop }, drop] = useDrop({
    accept: [DRAGGABLE.TYPES.COLLECTION],
    drop: (props, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      onCollectionMove({
        targetCollection: collection,
        draggedCollection: _.get(monitor.getItem(), 'collection'),
        insertAfter,
      });
    },
    collect: (monitor) => ({
      isHoveredOver: monitor.isOver(),
      canDrop: (
        monitor.canDrop() &&
        _.get(monitor.getItem(), 'collection.id') !== collection.id
      )
    }),
  });

  const dropClassNames = classNames(
    'collection-drop',
    insertAfter ? 'insert-after' : 'insert-before',
    {
      'is-hovered-over': isHoveredOver,
      'can-drop': canDrop,
    }
  );

  return (
    <div ref={drop} className={dropClassNames}>
      {children}
    </div>
  );
}

CollectionDrop.propTypes = {
  collection: PropTypes.object,
  onCollectionMove: PropTypes.func,
  insertAfter: PropTypes.bool,
  children: childrenPropTypes,
};

const DragSortableCollectionItem = (props) => {
  const {
    collection,
    onCollectionMove,
    collectionTabRef,
    children,
    isUserCollection
  } = props;
  const isAllMetricsCollection = _.isEqual(collection.id, COLLECTION_ID.ALL_METRICS);
  const isRestricted = canUserRestrictCollections();
  if(isMyViews(collection) || !isUserCollection || isAllMetricsCollection || isRestricted) {
    return children;
  }
  return (
    <div className="drag-sortable-collection-item h-100">
      <CollectionDrag collection={collection} key={collection.id} collectionTabRef={collectionTabRef}>
        <CollectionDrop collection={collection} onCollectionMove={onCollectionMove}>
        </CollectionDrop>
        <CollectionDrop collection={collection} onCollectionMove={onCollectionMove} insertAfter>
        </CollectionDrop>
        <div className="collection-item-content h-100">
          {children}
        </div>
      </CollectionDrag>
    </div>
  );
};

DragSortableCollectionItem.propTypes = {
  collection: PropTypes.object,
  onCollectionMove: PropTypes.func,
  children: childrenPropTypes,
  isUserCollection: PropTypes.bool,
  collectionTabRef: PropTypes.object
};

export default DragSortableCollectionItem;
