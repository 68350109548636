import { useEffect, useState, useRef } from 'react';

import { buildQueryString } from 'helpers/HttpHelper';
import { fetchApiDataWithStatus } from 'helpers/apiResponseHelper';
import dataFormatter from 'modules/visualization/LineChart/dataFormatter';

const UseLineChartDataFetch = (
  lineChartAttributes, currentDateRange,
  axisGranularity, currentChartView,
  futureForecastDateRange
  ) => {

  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);
  const { apiParams } = lineChartAttributes;
  useEffect(() => {
    isMounted.current = true;
    if(abortController) {
      abortController.abort();
    }
    const abortController = new AbortController();

    setLoading(true);
    const newParams = {...apiParams, is_forecast_view: true, axisGranularity: axisGranularity }
    const apiUrl = `/api/visualization/line_chart.json?${buildQueryString(newParams)}`;
    fetchApiDataWithStatus(apiUrl, abortController)
      .then((response) => {
        if (isMounted.current) {
          const rawData = { ...response['api_data'], ...response }
          setChartData(dataFormatter.formatData(lineChartAttributes, rawData));
          setLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    return () => {
      isMounted.current = false;
      abortController.abort();
    };
  }, [
    JSON.stringify(currentDateRange), axisGranularity, currentChartView,
    JSON.stringify(futureForecastDateRange)
  ])

  return { loading, data: chartData };
}

export default UseLineChartDataFetch;
