
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ForgeOpenIcon,
  ForgeExpansionPanel,
  ForgeCard,
  ForgeBadge,
  ForgeIcon,
  ForgeTooltip,
  ForgeButton
} from '@tylertech/forge-react';

import { getTableColumnEntries } from 'common/config/templateConfiguration';
import CompFinderIcon from '../CompFinderIcon';
import { formattedValueByDataType } from 'modules/DetailsTable/TableHelper';
import {
  checkImageExists,
  getReportData,
  getReportDistinctData
} from 'common/api/advanceSearchApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { getPropertyTooltipText, getSecondaryValuesList, isValidPdfColumn } from '../advanceSearchHelper';
import PropertyPhoto from '../PropertyPhoto';
import PropertyPdf from '../PropertyPdf';
import { SEARCH_METHOD } from 'appConstants';
import ExpandPhotoModal from '../ExpandPhotoModal';

const CompareListChip = (props) => {
  const {
    compareData,
    customSearchField,
    isSubjectData,
    templateId,
    onRemoveCompare,
    searchMethod
  } = props;
  const detailsEntries = getTableColumnEntries(templateId);
  const rowId = _.get(compareData, 'row_id_field', '');
  const quickRefId = _.get(compareData, 'comp_finder_quick_ref_id', null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentListData, setCurrentListData] = useState([]);
  const [secondaryData, setSecondaryData] = useState([]);
  const [isPhotoAvailable, setIsPhotoAvailable] = useState(false);
  const [isPopupExpand, setIsPopupExpand] = useState(false);
  const forgeCardRef = useRef();

  const handleArrowButtonClick = (event) => {
    event.stopPropagation();

    const isExpandButtonClicked = event.target.closest('.property-content-expand');
    if (isExpandButtonClicked) {
      setIsPopupExpand(true);
    }
  }

  useEffect (() => {
    fetchReportData();
  }, [rowId])

  useEffect(() => {
    forgeCardRef.current.addEventListener('mousedown', handleArrowButtonClick);
    return () => {
      forgeCardRef.current?.removeEventListener('mousedown', handleArrowButtonClick);
    }
  }, [])

  const renderField = (data) => {
    return (
      <div className='forge-typography--body1'>
        {_.get(data, 'comp_finder_quick_ref_id', customSearchField)}
      </div>
    )
  };

  const fetchReportData = () => {
    setIsLoading(true);
    const params = {
      row_id_field: rowId,
      currentDrilldownTemplateId: templateId
    }
    const promises = [
      getReportData(params),
      getReportDistinctData(params),
      !_.isNull(quickRefId) ? checkImageExists(compareData) : ''
    ];
    Promise.all(promises).
      then((results) => {
        setCurrentListData(_.first(results[0]));
        setSecondaryData(results[1]);
        setIsPhotoAvailable(results[2]?.result?.IsPhotoAvailable);
        setIsLoading(false);
      }).catch((error) => {
        console.log("Error on fetching user config ", error);
        setIsLoading(false);
      });
  };

  const onPanelExpand = () => {
    fetchReportData();
  }

  const getPropertyNumber = () => {
    const detailEntry = _.find(detailsEntries, (entry) => {
      return entry['column'] === 'property_number'
    });

    if(!_.isEmpty(detailEntry)){
      return _.get(compareData, detailEntry?.field, '')
    }
  }

  const renderValue = (datum, entry, isPdfValue) => {
    const value = _.get(datum, entry.field, '');
    const secondaryValues = getSecondaryValues(datum, entry);
    const formattedValue = formattedValueByDataType(value, entry, true);
    const renderValue = _.isEmpty(secondaryValues) ? formattedValue : secondaryValues;
    const isPropertyColumn = entry?.column === 'property_number';
    return _.isObject(value) ?
      <div className="text-break">{_.get(value, 'url')} </div> :
      <>
        <div className="text-break">{renderValue}</div>
        {!isPdfValue && <ForgeTooltip
          text={getPropertyTooltipText(isPhotoAvailable, isPropertyColumn, '')}
          position="top" />}
      </>
  };

  const getSecondaryValues = (datum, entry) => {
    let secondaryValuesList = [];
    if (!_.isEmpty(secondaryData)) {
      const rowId = _.get(datum, 'row_id_field', '');
      secondaryValuesList = getSecondaryValuesList(secondaryData[rowId], entry);
    }

    let secondaryValues;
    if (!_.isEmpty(secondaryValuesList)) {
      secondaryValues = _.map(secondaryValuesList, (datumValue) => {
        return (<> {datumValue} <br />  </>)
      })
    }
    return secondaryValues
  }

  const renderDetails = () => {
    const isSubjectSearch = SEARCH_METHOD.SUBJECT_PROPERTY == searchMethod;

    return _.map(detailsEntries, (entry, index) => {
      return (
        <div className="panel-list d-flex" key={entry.field}>
          <span className="forge-typography--subtitle2-secondary label-name">{entry.name}</span>
          <span className="forge-typography--body2">
            {
              isValidPdfColumn(isSubjectSearch, templateId, index, entry, currentListData) ?
                <PropertyPdf
                  value={renderValue(currentListData, entry, true)}
                  propertyDetails={currentListData}
                />
                :
                <>
                  {renderValue(currentListData, entry, false)}
                </>
            }
          </span>
        </div>
      )
    });
  };

  const className = isSubjectData ? 'subject-data-chip' : '';

  const renderDialogModal = () => {
    const title = renderField(compareData);

    return (
      <ExpandPhotoModal
        renderPhotoContent={renderPhotoContent}
        searchTemplateId={templateId}
        searchMethod={searchMethod}
        isPopupExpand={isPopupExpand}
        setIsPopupExpand={setIsPopupExpand}
        title={title}
        subTitle={getPropertyNumber()}
        handleClose={handleClose}
        showExpandIcon={false}
      />
    )
  }


  const showPhotoContent = (!isLoading
    && _.get(compareData, 'is_property_details_available', false)
    && isPhotoAvailable)

  const renderPhotoContent = () => {
    return showPhotoContent ? <PropertyPhoto photoDetails={compareData}></PropertyPhoto> :
      <LoadingSpinner isLoading={true} />
  }
  const handleOpenCallback = () => {
    onPanelExpand();
  }

  const handleClose = () => {
    setIsPopupExpand(false);
  }

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <ForgeCard
        className={`compare-list-chip position-relative ${className}`} outlined="true"
        ref={forgeCardRef}
      >
        {isSubjectData ?
          <ForgeBadge className="forge-badge" theme="warning">Subject Property</ForgeBadge> : null}
        {!isSubjectData && <ForgeIcon
          name="close"
          className="compare-close"
          onClick={() => onRemoveCompare(compareData)}>
        </ForgeIcon>}
        <ForgeExpansionPanel on-forge-expansion-panel-toggle={handleOpenCallback}>
          <div slot="header" id="expansion-panel-button"
            class="expansion-panel-button compare-expansion-panel d-flex align-items-center gap-10"
            aria-controls="expansion-panel-content">
            <div className='flex-grow-1 text-truncate compare-data'>
              <div className='text-truncate d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center align-items-center gap-3 text-truncate'>
                  <CompFinderIcon templateId={templateId} searchMethod={searchMethod} />
                  <div className='forge-typography--body1'>
                      {_.get(compareData, customSearchField, '')}
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-center gap-3'>
                  {showPhotoContent && <ForgeButton className='property-content-expand'>
                    <button type="button" onClick={handleArrowButtonClick}>
                      <ForgeIcon name='arrow_expand' />
                    </button>
                    <ForgeTooltip
                      text='Expand photo'
                      position={'top'} />
                  </ForgeButton>}
                  <ForgeOpenIcon />
                </div>
              </div>
            </div>
            {renderDialogModal()}
          </div>
          <div id="expansion-panel-content" className='expansion-panel' role="group">
            <div className='property-photo'>
              {isPhotoAvailable && renderPhotoContent()}
            </div>
            {renderDetails()}
          </div>
        </ForgeExpansionPanel>
      </ForgeCard>
    </>
  );
}

CompareListChip.propTypes = {
  compareData: PropTypes.object,
  isSubjectData: PropTypes.bool,
  isCustomSearch: PropTypes.bool,
  customSearchField: PropTypes.string,
  templateId: PropTypes.string,
  onRemoveCompare: PropTypes.func,
  searchMethod: PropTypes.string,
};
export default CompareListChip;
