import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { DISTRIBUTION_CHART_TYPES } from 'appConstants';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isMobileOrTablet } from 'helpers/DomPageHelper';
import MenuDropDownSelector from 'common/components/MenuDropDown/MenuDropDownSelector';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';

function DistributionLegends(props) {

  const { legends, isDiscrete, isCumulativeDistribution, 
    dimensionName, fromMobileView, isCurrencyDimensionField, 
    currentDrilldownTemplateId, toggleClickLegendButton, isOpenLegend } = props.legendOptions

  const renderLegendItem = (legendOption, index) => {
    const { color, label } = legendOption;

    return (
      <div className="snap-shot-legend-item d-flex" key={index}>
        <span className="legend-color" style={{ 'backgroundColor': color }}></span>
        <span className="legend-label">{formatValueToCurrency(label, isCurrencyDimensionField)}</span>
      </div>
    );
  }

  const renderCategories = () => {
    const renderLegendItems = _.map(legends, renderLegendItem);
    if (_.isEmpty(legends) || (isDiscrete && isCumulativeDistribution)) {
      return null;
    }
    return (
      <div className="filter-wrapper">
        <div className="options-label mb-2">{dimensionName}</div>
        {renderLegendItems}
      </div>
    );
  }

  const renderLegend = () => {
    let chartTypes = [];
    if (isDiscrete) {
      chartTypes.push({ type: DISTRIBUTION_CHART_TYPES.DISCRETE });
    }
    if (isCumulativeDistribution) {
      chartTypes.push({ type: DISTRIBUTION_CHART_TYPES.CUMULATIVE });
    }

    const legendContent = _.map(chartTypes, (chartOption) => {
      const color = (DISTRIBUTION_CHART_TYPES.DISCRETE === chartOption.type) ?
        getConfiguredMetricColor(currentDrilldownTemplateId, 'primary') :
        getConfiguredMetricColor(currentDrilldownTemplateId, 'secondary');
      const label = _.capitalize(chartOption.type);

      return renderLegendItem({ label, color }, chartOption.type);
    });

    return (isDiscrete || isCumulativeDistribution) ? (
      <div className="legend-details filter-wrapper">
        {legendContent}
      </div>
    ) : null;
  }

  const onButtonClick = () => {
    toggleClickLegendButton(!isOpenLegend);
  }

  const renderButtonMobileView = () => {
    if (fromMobileView) {
      return null
    }

    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          className=""
          onKeyDown={() => onButtonClick()}
          onClick={() => onButtonClick()}>
          <ForgeIcon name="category" />
        </button>
      </ForgeButton>
    );
  }

  const renderMobileView = (legendRender, categoryRender) => {

    if (!isMobileOrTablet() || !isOpenLegend || !fromMobileView) {
      return null
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
        toggleClickLegendButton={toggleClickLegendButton}
        className="" leadingIconName="category">
        <div className="legend-wrapper snap-shot-legend-container">
          {legendRender}
          {categoryRender}
        </div>
      </MenuDropDownSelector>
    );
  }

  const renderDesktopView = (legendRender, categoryRender) => {
    if (isMobileOrTablet()) {
      return renderButtonMobileView()
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
        toggleClickLegendButton={toggleClickLegendButton}
        className="" leadingIconName="category">
        <div className="legend-wrapper snap-shot-legend-container">
          {legendRender}
          {categoryRender}
        </div>
      </MenuDropDownSelector>
    );
  }

  const renderDistributionLegends = () => {
    const legendRender = renderLegend();
    const categoriesContent = renderCategories();

    if (_.isEmpty(legendRender) && _.isEmpty(categoriesContent)) {
      return null;
    }

    let categoryRender = null;
    if (!_.isEmpty(categoriesContent)) {
      categoryRender = (<div className="categories">
        {categoriesContent}
      </div>)
    }

    return (
      <Fragment>
        {isMobileOrTablet() && renderMobileView(legendRender, categoryRender)}
        {renderDesktopView(legendRender, categoryRender)}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {renderDistributionLegends()}
    </Fragment>
  )
}

DistributionLegends.propTypes = {
  legendOptions: PropTypes.object
}

export default DistributionLegends
