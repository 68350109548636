import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import BookmarkCard from '../dashboard/components/BookmarkCards/BookmarkCard';
import { getBookmarkAnalysisLink } from './helper';
import {
  getCurrentViewEntry,
  getCurrentTemplateEntryById
} from 'common/config/templateConfiguration';

class BookmarkEmbedView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false
    };
  }

   renderBookmarkCard = () => {
    const { currentUser, bookmarkEntry, state } = this.props;

    if(_.isEmpty(bookmarkEntry)){
      return
    }
    const cardEntry = bookmarkEntry;
    const { currentDrilldownViewEntry, currentDrilldownTemplateId } = _.get(
      cardEntry,
      'drilldown',
      {}
    );
    const viewId = _.get(currentDrilldownViewEntry, 'view_id');
    const viewEntry = getCurrentViewEntry(currentDrilldownTemplateId, viewId);
    const templateEntry = getCurrentTemplateEntryById(currentDrilldownTemplateId);
    const { commonFilters } = bookmarkEntry;
    return(
      <BookmarkCard
        dateFilters={commonFilters}
        templateEntry={templateEntry}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        currentUser={currentUser}
        cardEntry={cardEntry}
        onCardClick={_.noop}
        analysisLink={getBookmarkAnalysisLink(bookmarkEntry, state)}
        currentDrilldownViewEntry={viewEntry}
        onBookmarkLoading={_.noop}
        reloadBookmarks={_.noop}
        isEmbed={true}
      />);
  }

  render(){
    if (this.state.redirectToAnalysis) {
      return <Redirect push to="/analysis" />;
    }

    return (
      <div >
        {this.renderBookmarkCard()}
      </div>
    );
  }
}

BookmarkEmbedView.propTypes = {
  currentUser: PropTypes.object,
  state: PropTypes.object,
  bookmarkEntry: PropTypes.object
}

BookmarkEmbedView.defaultProps = {
  bookmark: {}
}

export default BookmarkEmbedView;
