import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BookmarkManageDetails from './BookmarkManageDetails';
import ShareBookmarkFromManage from './ShareBookmarkFromManage';
import BookmarkBaseViewSelector from './BookmarkBaseViewSelector';
import LoadingSpinner from 'common/components/LoadingSpinner';
import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';
import { getApiParamsForMap } from 'helpers/apiParamsHelper';
import { trackEvent } from 'helpers/eventTracking';
import {
  EMAIL_STRATEGY_TYPES,
  VIEW_DELETE_MESSAGE,
  ALERT_DELETE_MESSAGE,
  BOOKMARK_TYPES
} from 'appConstants';
import { VIEWER_ROLE } from 'pages/dashboard/components/ManageCollection/constants';
import { isManageCollections } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';

class BookmarkManagePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: this.props.isLoading,
      bookmarkEntry: {},
      isShareModalOpened: false
    };
  }

  isNeverEmailStrategy = () => {
    const { currentBookmarkEntry } = this.props;
    return (_.get(currentBookmarkEntry, 'email_strategy') === EMAIL_STRATEGY_TYPES.NEVER);
  }

  onClickDeleteButton = () => {
    if (!this.isNeverEmailStrategy()) {
      trackEvent('manage_initiate_alert_delete');
    } else {
      trackEvent('manage_initiate_view_delete');
    }
  }

  onDeleteConfirm = () => {
    const { onBookmarkDelete, currentBookmarkEntry } = this.props;
    if (!this.isNeverEmailStrategy()) {
      trackEvent('manage_confirm_alert_delete');
    } else {
      trackEvent('manage_confirm_view_delete');
    }
    onBookmarkDelete(currentBookmarkEntry);
  }

  handleBookmarkSave = (bookmarkParams) => {
    const { onBookmarkUpdate } = this.props;
    onBookmarkUpdate(bookmarkParams)
  };

  renderManageBookmark() {
    const { currentBookmarkEntry, viewEntry } = this.props;
    const { isLoading } = this.state;
    let drilldown = _.get(currentBookmarkEntry, 'drilldown', {});
    const visualization = _.get(currentBookmarkEntry, 'visualization', {});
    const visualizationMapOptions = _.get(
      currentBookmarkEntry,
      'visualization.mapOptions',
      {}
    );
    const mapOptions = _.get(
      currentBookmarkEntry,
      'mapOptions',
      visualizationMapOptions
    );
    const commonFilters = _.get(currentBookmarkEntry, 'commonFilters', {});
    const currentDrilldownTemplateId = _.get(
      currentBookmarkEntry,
      'drilldown.currentDrilldownTemplateId',
      ''
    );

    drilldown = {
      ...drilldown,
      currentDrilldownViewEntry: viewEntry,
    };
    const apiParams = getApiParamsForMap({
      currentDrilldownTemplateId,
      drilldown: drilldown,
      shapeGroupId: '',
      mapOptions,
      commonFilters,
    });
    const recipients = _.get(currentBookmarkEntry, 'recipients', []);

    const bookmark = {
      ..._.get(currentBookmarkEntry, 'bookmarkOptions'),
      id: currentBookmarkEntry.id,
      name: currentBookmarkEntry.name,
      sortOrder: _.get(currentBookmarkEntry, 'sort_order'),
      sendEmailUpdates: currentBookmarkEntry.send_alerts,
      emailOptions: currentBookmarkEntry.email_options,
      emailStrategy: currentBookmarkEntry.email_strategy,
      includeImage: currentBookmarkEntry.include_image,
      isManageView: _.get(currentBookmarkEntry, 'is_manage_view', false),
      isCollaborateAlert: _.get(currentBookmarkEntry, 'is_collaborate_alert', false)
    };

    const isDisableManagedAlert = (
      _.get(currentBookmarkEntry, 'is_collaborate_alert', false) && !isManageCollections()
    )
    const isDisabled =  _.get(currentBookmarkEntry, 'is_manage_view', false) || isDisableManagedAlert

    //TODO: Check form saved bookmark once deep linking is completed
    const distributionChartOptions = { isCumulative: false, isDiscrete: true };

    return (
      <BookmarkManageDetails
        key={currentBookmarkEntry.id}
        editMode={true}
        isDisabled={isDisabled}
        isRadar={_.get(currentBookmarkEntry, 'is_radar', false)}
        isCustomAdvanceSearch={_.get(currentBookmarkEntry, 'is_custom_advance_search', false)}
        onSave={this.handleBookmarkSave}
        commonFilters={commonFilters}
        bookmarkFieldOptions={bookmark}
        name={bookmark.name}
        sendEmailUpdates={bookmark.sendEmailUpdates}
        emailStrategy={bookmark.emailStrategy}
        drilldown={drilldown}
        mapOptions={mapOptions}
        radarOptions={_.get(currentBookmarkEntry, 'radarOptions')}
        advanceSearchOptions={_.get(currentBookmarkEntry, 'advanceSearchOptions')}
        visualization={visualization}
        distributionChartOptions={distributionChartOptions}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        apiParams={apiParams}
        isLoading={isLoading}
        recipients={recipients}
        currentBookmarkEntry={currentBookmarkEntry}
      />
    );
  }

  renderBookmarkDelete(){
    const {  bookmarkType, currentBookmarkEntry } = this.props;
    const { isShareModalOpened } = this.state;
    const collectionUserRole =_.get(currentBookmarkEntry, 'collection_user.role', '');
    const isViewer = collectionUserRole === VIEWER_ROLE;
    const deleteTitle = `Delete ${bookmarkType}`;
    const deleteMessage = _.includes([BOOKMARK_TYPES.SAVED_VIEW, BOOKMARK_TYPES.RADAR], bookmarkType) ?
      VIEW_DELETE_MESSAGE :
      ALERT_DELETE_MESSAGE;

    if(isShareModalOpened || isViewer){
      return null;
    }
    return(
      <>
        <DeleteConfirmModal
          title={deleteTitle}
          classNames="mr-1"
          message={deleteMessage}
          onClickDeleteButton={this.onClickDeleteButton}
          onDeleteConfirm={this.onDeleteConfirm}
        />
       </>
    )
  }

  onShareButtonClick =(isOpen) => {
    this.setState({ isShareModalOpened: isOpen });
  }

  renderBookmarkShare(){
    const { currentBookmarkEntry, currentUser } = this.props;
    const isDisabled =_.get(currentBookmarkEntry, 'is_manage_view', false) ||
      _.get(currentBookmarkEntry, 'is_collaborate_alert', false);

    return !isDisabled && (
      <ShareBookmarkFromManage
        currentUser={currentUser}
        currentBookmarkEntry={currentBookmarkEntry}
        onShareClick={this.onShareButtonClick}
      />
    );
  }

  render() {
    const { isLoading } = this.state;
    const { bookmarkType, currentBookmarkEntry, userCollectionsWithCards } = this.props;
    const title = bookmarkType === BOOKMARK_TYPES.RADAR ?
      'Saved multi-metric change overtime view' :
      bookmarkType;
    const isCustomAdvanceSearch = _.get(currentBookmarkEntry, 'is_custom_advance_search', false)
    return (
      <>
        <div className="bookmark-manage-page-head rightside-page-head">
          <div>
            <h1>{_.capitalize(title)}</h1>
            {!isCustomAdvanceSearch && <BookmarkBaseViewSelector bookmark={currentBookmarkEntry} 
              userCollectionsWithCards={userCollectionsWithCards} />}
          </div>
          <LoadingSpinner isLoading={isLoading} />
          <div className="actions-btn">
            {this.renderBookmarkShare()}
            {this.renderBookmarkDelete()}
          </div>
        </div>
        <div className="mt-2">
          {this.renderManageBookmark()}
        </div>
      </>
    );
  }
}

BookmarkManagePage.propTypes = {
  currentBookmarkEntry: PropTypes.object,
  viewEntry: PropTypes.object,
  currentUser: PropTypes.object,
  isLoading: PropTypes.bool,
  onBookmarkUpdate: PropTypes.func,
  onBookmarkDelete: PropTypes.func,
  bookmarkType: PropTypes.string,
  userCollectionsWithCards: PropTypes.array 
};

export default BookmarkManagePage;
