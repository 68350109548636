import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ForgeIcon,
  ForgeList,
  ForgeListItem,
  ForgeExpansionPanel,
  ForgeDrawer,
  ForgeOpenIcon,
  ForgeDivider,
  ForgeBadge,
  ForgeTooltip
} from '@tylertech/forge-react';
import $ from "jquery";

import UserFromBellerophonContext from "context/UserFromBellerophonContext";
import AdvanceSearchItem from './AdvanceSearchItem';

import { getUserListPageBasedOnPermission } from 'helpers/pageHelper';
import { MANAGE_PAGE_CONFIG, MANAGE_FORECASTING_PAGE_CONFIG } from 'appConstants';
import {
  getFirstTemplateViewEntryForForecasting,
  getHeaderLinks,
  hamburgerMenuLabel,
  hamburgerMenuTagHoverText,
  isEligibleAdvancedForecasting,
  showAdvanceSearch,
  getRadarPageTitle,
  showRadar,
  showForecastingBetaTag,
  showRadarBetaTag,
  useAppLevelShapeConfigs
} from 'common/config/customerConfiguration';
import { addhttp } from 'helpers/HttpHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const MenuDrawer = ({ showDrawer, onListItemClick, onDrawerClose, disableForecastLink }) => {
  const { userFromBellerophon } = useContext(UserFromBellerophonContext);
  const menuDrawerRef = useRef();
  const TOOLTIP_MAX_LENGTH = 25;
  const headerLinks = getHeaderLinks();
  let userListPages = getUserListPageBasedOnPermission(userFromBellerophon);
  userListPages = [...userListPages, MANAGE_PAGE_CONFIG];

  const handleClickOutside = (e) => {
    const isClickWithinMenuButton = $(e.target).closest("forge-app-bar-menu-button").length > 0;
    if (showDrawer && !isClickWithinMenuButton &&
        menuDrawerRef && menuDrawerRef.current && !menuDrawerRef.current.contains(e.target)) {
      onDrawerClose();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  })

  const onUrlClick = (_page, _to, url) => {
    window.open(addhttp(url), '_blank');
  }

  const onKeyDownMenuButton = (e, page, to, url) => {
    const onClick = url ? onUrlClick : onListItemClick;
    if (isEnterButtonPressed(e)) {
      onClick(page, to, url)
    }
  }
  const showToolTip = (textValue) => {
    if(_.size(textValue) > TOOLTIP_MAX_LENGTH){
      return true;
    }
  }
  const renderForgeListItem = (listItem, index) => {
    const { name, url = '', page, to } = listItem;
    const onClick = url ? onUrlClick : onListItemClick;

    return (
      <ForgeListItem indented key={index}
        onkeydown={(e) => onKeyDownMenuButton(e, page, to, url)}
        onClick={() => onClick(page, to, url)}>
        {name}
      </ForgeListItem>
    );
  }

  const renderParentListItem = (label, iconName) => {
    return (
      <ForgeListItem slot="header" role="none">
        <ForgeIcon slot="leading" name={iconName}></ForgeIcon>
        {label}
        <ForgeOpenIcon slot="trailing"></ForgeOpenIcon>
      </ForgeListItem>
    )
  }

  const renderForgeChildListItem = (listOptions) => {
    const listItemContent = _.map(listOptions, renderForgeListItem);

    return (<ForgeList>{listItemContent}</ForgeList>);
  }

  const renderForecastBetaTag = () => {
    if(!showForecastingBetaTag()){
      return null;
    }

    return (<OverlayTrigger
      key="forecasting-tag"
      placement="right"
      overlay={
        <Tooltip id="tooltip-top">
          <div className="text-left">{hamburgerMenuTagHoverText}</div>
        </Tooltip>
      }>
        <div slot="trailing" className='d-inline-block ml-2'>
          <ForgeBadge  strong={false} theme={'default'}>Beta</ForgeBadge>
        </div>
    </OverlayTrigger>
    )
  }

  const renderRadarBetaTag = () => {
    if(!showRadarBetaTag()){
      return null;
    }

    const message = `Introducing our new multi-metric analysis experience!
    If you have any suggestions for how we can improve it,
    please let us know via the orange Feedback button in the lower right corner.`;

    return (<OverlayTrigger
      key="radar-tag"
      placement="right"
      overlay={
        <Tooltip id="tooltip-top">
          <div className="text-left">{message}</div>
        </Tooltip>
      }>
      <div slot="trailing" className='d-inline-block ml-2'>
        <ForgeBadge  strong={false} theme={'default'}>Beta</ForgeBadge>
      </div>
    </OverlayTrigger>
    )
  }

  const renderRadarListItem = () => {

    const page = "radar", to = "/radar", url = "";

    return (
      showRadar() && useAppLevelShapeConfigs && <ForgeListItem key={'radar' + 1}
        // className="radar-list-item"
        onkeydown={(e) => onKeyDownMenuButton(e, page, to, url)}
        onClick={() => onListItemClick(page, to, url)}>
        <ForgeIcon slot="leading" name="view_week" />
        <span slot="title"> {getRadarPageTitle()}
          {showToolTip(getRadarPageTitle()) &&
          <ForgeTooltip text={getRadarPageTitle()}  position="right" /> }
        </span>
        {renderRadarBetaTag()}
      </ForgeListItem>
    );

  }

  const renderForecastingListItem = () => {
    const { url = '', page, to } = MANAGE_FORECASTING_PAGE_CONFIG;
    const onClick = url ? onUrlClick : onListItemClick;

    if (!isEligibleAdvancedForecasting()) {
      return
    }

    const entryOption = getFirstTemplateViewEntryForForecasting();
    return (
      <ForgeListItem key={'forecasting' + 1}
        disabled={disableForecastLink}
        onkeydown={(e) => !disableForecastLink && onKeyDownMenuButton(e, page, to, url)}
        onClick={() => !disableForecastLink && onClick(page, to, url, entryOption)}>
        <ForgeIcon slot="leading" name="chart_timeline_variant" />
        <span slot="title"> {hamburgerMenuLabel}
          {showToolTip(hamburgerMenuLabel) &&
          <ForgeTooltip text={hamburgerMenuLabel}  position="right" /> }
        </span>
        {renderForecastBetaTag()}
      </ForgeListItem>
    );
  }

  const className = showDrawer ? '' : 'close-drawer';

  return (
    <div ref={menuDrawerRef}
      className={`forge-omnibar-drawer ${className}`}>
      <ForgeDrawer open={showDrawer} direction="left">
        <ForgeList>
          {!_.isEmpty(headerLinks) &&
            <>
              <ForgeExpansionPanel role="listitem" key={1}>
                {renderParentListItem('Related sites', 'link')}
                {renderForgeChildListItem(headerLinks)}
              </ForgeExpansionPanel>
              <ForgeDivider role="none" ></ForgeDivider>
            </>}
          {renderRadarListItem()}
          {renderForecastingListItem()}
          {showAdvanceSearch() && <AdvanceSearchItem onListItemClick={onListItemClick} />}
          <ForgeExpansionPanel role="listitem" key={2}>
            {renderParentListItem('Administration', 'settings')}
            {renderForgeChildListItem(userListPages)}
          </ForgeExpansionPanel>
        </ForgeList>
      </ForgeDrawer>
    </div>
  );
}

MenuDrawer.propTypes = {
  showDrawer: PropTypes.bool,
  onListItemClick: PropTypes.func,
  onDrawerClose: PropTypes.func,
  disableForecastLink: PropTypes.bool
}

export default MenuDrawer;
