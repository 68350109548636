import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeButton, ForgePopup } from '@tylertech/forge-react';
import { PopupAnimationType } from '@tylertech/forge';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { isClickOutSideFilterPopup } from 'common/components/Filters/FilterDomHelper';
import ForgeCloseButton from '../ForgeCloseButton/ForgeCloseButton';

class MenuDropDownPopupSelector extends Component {
  constructor(props) {
    super(props)
    this.toggleButtonRef = React.createRef();

    this.state = {
      showDropDown: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (isClickOutSideFilterPopup(e, this.toggleButtonRef.current, ['forge-popup'])) {
      this.setState({ showDropDown: false });
    }
  }

  handleKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.onDropDownButtonClick();
    }
  }

  onDropDownButtonClick = () => {
    this.setState({ showDropDown: !this.state.showDropDown });
  }

  onDropDownButtonKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.onDropDownButtonClick();
    }
  }

  onCloseOption = () => {
    this.setState({ showDropDown: !this.state.showDropDown });
  }

  renderDropDownButton() {
    const { leadingIconName, trailingIconName, toggleButtonLabel, customIconName } = this.props;

    return (
      <ForgeButton
        id={"popup_overtime_options"}
        type="outlined"
        className="map-forge-btn"
        ref={this.toggleButtonRef}>
        <button
          aria-label="Visualization Options"
          className=""
          onKeyDown={this.onDropDownButtonKeyDown}
          onClick={this.onDropDownButtonClick}>
          {leadingIconName && <ForgeIcon name={leadingIconName} />}
          {customIconName && <i className={customIconName}></i>}
          <span className="button-label">{toggleButtonLabel}</span>
          {trailingIconName && <ForgeIcon name={trailingIconName} className="mr-0 arrow-down" />}
        </button>
      </ForgeButton>
    );
  }

  renderBodyContent() {
    const { children } = this.props;

    return (
      <div className="custom-options-popup">
        <div className='d-flex option-head'>
          <div className="ml-auto">
            <ForgeCloseButton onClose={this.onCloseOption} />
          </div>
        </div>
        <div className="">
          {children}
        </div>
      </div>
    )
  }

  render() {
    const { showDropDown } = this.state;

    const placement = "bottom-end", animationType = PopupAnimationType.Menu, manageFocus = true;

    return (
      <div className="forge-popup-host">
        {this.renderDropDownButton()}

        <div>
          <ForgePopup
            style={{ maxHeight: "100px" }}
            open={showDropDown}
            onDismiss={() => this.onCloseOption()}
            targetElementRef={this.toggleButtonRef}
            options={{ placement, manageFocus, animationType }}>

            {this.renderBodyContent()}
          </ForgePopup>
        </div>
      </div>
    )
  }

}

MenuDropDownPopupSelector.defaultProps = {
  children: null,
  toggleButtonLabel: 'Options',
  leadingIconName: 'settings',
  customIconName: '',
  trailingIconName: '',
  className: '',
  isDropDownOpen: false,
  toggleClickLegendButton: _.noop
};

MenuDropDownPopupSelector.propTypes = {
  children: PropTypes.any,
  toggleButtonLabel: PropTypes.string,
  leadingIconName: PropTypes.string,
  trailingIconName: PropTypes.string,
  customIconName: PropTypes.string,
  className: PropTypes.string,
  toggleClickLegendButton: PropTypes.func
};

export default MenuDropDownPopupSelector;
