import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SlaLeftContainer from './slaLeftContainer';
import SlaRightContainer from './slaRightContainer'

class SlaContainer extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedItem: _.first(this.props.allItems)
    };
  }

  componentDidUpdate(prevProps) {
    const { dateRange, globalFilters, allItems } = this.props;

    const isDateChanged = !_.isEqual(dateRange, prevProps.dateRange);
    const isGlobalFiltersChanged = !_.isEqual(globalFilters, _.get(prevProps, 'globalFilters', []));
    if ((isDateChanged ||
        isGlobalFiltersChanged) && !_.isEmpty(allItems)) {
      this.setState({
        selectedItem: _.first(allItems)
      });
    }
  }

  onCallbackSelectedItem = (item) => {
    this.setState({
      selectedItem : item
    })
  }

  render() {
    const { selectedItem } = this.state;
    const { allItems, dateRange, globalFilters } = this.props;

    return (
      <div defaultActiveKey="0">
        <div className="sla-watch-wrapper row">
          <div className="col-3 offset-lg-1">
            <SlaLeftContainer
              allItems={allItems}
              selectedItem={selectedItem}
              onCallbackSelectedItem={this.onCallbackSelectedItem} />
          </div>

          <div className="col-9 col-lg-7 sla-right-container">
            <SlaRightContainer
              dateRange={dateRange}
              globalFilters={globalFilters}
              selectedItem={selectedItem}/>
          </div>
        </div>
      </div>
    )
  }
}

SlaContainer.propTypes = {
  commonFilters: PropTypes.object,
  allItems: PropTypes.array,
  globalFilters: PropTypes.array,
  dateRange: PropTypes.object
};

function mapDispatchToProps() {
  return {}
}

function mapStateToProps(state) {
  return {
    commonFilters: _.get(state, 'commonFilters', {}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlaContainer);
