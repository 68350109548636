// Vendor Imports
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components Define
import CollectionFilters from 'pages/dashboard/components/Collections/CollectionFilters/CollectionFilters';
import QuickFilterBarHandler from 'pages/drilldown/components/QuickFilterBar/QuickFilterBarHandler';
import PermissionFilter from 'common/components/Filters/PermissionFilter';
import ResetFilters from './ResetFilters';
import PolygonFilterCard from './visualizations/PolygonFilterCard';
import MiniMapShapeFilter from './MiniMapShapeFilter';
import TimeFrames from './components/TimeFrames';
import GlobalEvent from 'common/components/GlobalEvents';

// Helper File Import
import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import { getCurrentTemplateGlobalFilters } from 'pages/dashboard/components/Collections/collectionHelper';
import { getFilterEntriesWithoutCollectionFilters } from './components/QuickFilterBar/helper';
import {
  getDateFilterParams,
  // getLastPeriodDateRange
} from 'helpers/dateHelper';
import { getApiParams } from 'helpers/apiParamsHelper';
import {
  getCollectionDateFiltersProps,
  getCollectionFiltersProps,
  getTimeFrameProps
} from 'helpers/drilldownLeftSideSectionHelper';
import CollectionDateFilters
  from 'pages/dashboard/components/Collections/CollectionDateFilters/CollectionDateFilters';
import { enableCollectionLevelDateFilters } from 'common/config/customerConfiguration';
import { templateIdPropTypes } from 'common/propTypes';
import VisSentenceBuilder from './components/VisSentenceBuilder';
import ComparisonModeDateRangeSelector from 'pages/drilldown/components/ComparisonModeDateRangeSelector';
import {
  updateDateFilters,
  updateComparisonType,
  updateComparisonModeOn,
  updateComparisonDateRanges
} from 'actions/commonFiltersActions';
import { toggleShapeIdsFilter } from 'actions/mapOptionsActions';
import { DATE_COMPARISON_TYPE_OPTIONS } from 'appConstants';
import { showDateFilter } from 'common/config/viewConfiguration';
import { getSnapshotChartType } from 'common/config/visualizationConfiguration';

class LeftSideSection extends PureComponent {
  renderPermissionFilters = () => {
    const { currentDrilldownTemplateId } = this.props;

    return (
      <PermissionFilter
        templateIds={[currentDrilldownTemplateId]}
      />
    );
  }

  renderCollectionDateFilters() {
    const { dispatchDateFiltersChange } = this.props;
    const options = getCollectionDateFiltersProps(this.props);
    if (_.isEmpty(options)) {
      return null;
    }

    return (
      <>
        <label className='section-titles forge-typography--overline'>Time frame</label>
        <div className='mb-6'>
          <CollectionDateFilters {...options} onDateFiltersChange={dispatchDateFiltersChange} />
        </div>
        <div className='mb-6'>
          <ComparisonModeDateRangeSelector />
        </div>
      </>
    );
  }

  renderFilters = () => {
    const {
      currentDrilldownTemplateId, commonFilters, dateFilters, dispatchGlobalFiltersChange, onResetFilter,
      apiParams, quickFilters, currentDrilldownViewEntry, filteredGeojson, onFilterChange, isDrawingEnabled,
      currentDrilldownGroupByEntry
    } = this.props;
    const globalFilters = _.get(commonFilters, 'globalFilters', []);
    const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
    const filters = _.cloneDeep(getCurrentTemplateGlobalFilters(templateEntry, globalFilters));
    const isGroupChart = (getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart');
    const isGroupByNone = _.get(currentDrilldownGroupByEntry, 'name') === 'None'
    const isGroupByEntry = (isGroupChart && !isGroupByNone);

    const quickFilterEntries = getFilterEntriesWithoutCollectionFilters(
      currentDrilldownTemplateId,
      currentDrilldownViewEntry,
      filters,
      isGroupByEntry
    );

    return (
      <div className="collection-filters-section">
        <div className="section-titles forge-typography--overline d-flex align-items-center">
          Filters
          <div className="ml-auto">
            <ResetFilters
              dateFilters={dateFilters}
              collectionFilters={filters}
              quickFilters={quickFilters}
              filteredGeojson={filteredGeojson}
              onResetFilter={onResetFilter}
            />
          </div>
        </div>

        {this.renderPermissionFilters()}

        {!isDrawingEnabled && !_.isEmpty(_.get(filteredGeojson, 'features')) &&
          <div className="filters-container">
            <PolygonFilterCard />
          </div>
        }

        {!_.isEmpty(filters) &&
          <div className="filters-container">
            <CollectionFilters
              {...getCollectionFiltersProps(this.props)}
              onCollectionFiltersChange={dispatchGlobalFiltersChange}
              collectionFilters={filters}/>
          </div>
        }

        <div className="filters-container">
          <QuickFilterBarHandler
            filterEntries={quickFilterEntries}
            showOnlyFiltersContent={true}
            apiParams={apiParams}
            currentDrilldownTemplateId={currentDrilldownTemplateId}
            quickFilters={quickFilters}
            onFilterChange={onFilterChange}
            viewEntry={currentDrilldownViewEntry}
            isAnalysisPageFilter
          />
        </div>
      </div>
    );
  }

  handleScroll = (e) => {
    GlobalEvent.emit('LEFT_SIDE_SCROLL_TOP', {
      scrollTop: e.target.scrollTop
    });
  };

  render() {
    const {
      map, currentDrilldownTemplateId, currentDrilldownViewEntry, currentMapStyleEntry,
      selectedShapeEntry, selectedShapeIds, dispatchToggleShapeIdsFilter
    } = this.props;
    const timeFramesAttributes = getTimeFrameProps(this.props, currentDrilldownTemplateId);
    const showCollectionFilters = enableCollectionLevelDateFilters();
    const showTimeFrames = !showCollectionFilters &&
      showDateFilter(currentDrilldownTemplateId,currentDrilldownViewEntry)
    return (
      <div className="left-side-section drawer-panel-wrapper" onScroll={this.handleScroll}>
        { showTimeFrames &&
          <TimeFrames {...timeFramesAttributes} />
        }
        { showCollectionFilters && this.renderCollectionDateFilters()}
        { (showCollectionFilters || showTimeFrames) && <hr className="dividers" />  }

        <div className="sentence-builder">
          <div className="section-titles forge-typography--overline">Data pivot</div>
          <div className="sentence-builder-filters">
            <VisSentenceBuilder />
          </div>
        </div>

        <hr className="dividers" />

        {this.renderFilters()}

        <div className="mapshape-filters-section">
          <MiniMapShapeFilter
            map={map}
            currentDrilldownTemplateId={currentDrilldownTemplateId}
            currentMapStyleEntry={currentMapStyleEntry}
            selectedShapeEntry={selectedShapeEntry}
            selectedShapeIds={selectedShapeIds}
            dispatchToggleShapeIdsFilter={dispatchToggleShapeIdsFilter}
          />
        </div>
      </div>
    );
  }
}

LeftSideSection.propTypes = {
  apiParams: PropTypes.object,
  commonFilters: PropTypes.object,
  currentDrilldownTemplateId: templateIdPropTypes,
  currentDrilldownViewEntry: PropTypes.object,
  currentUser: PropTypes.object,
  dateFilters: PropTypes.object,
  filteredGeojson: PropTypes.object,
  quickFilters: PropTypes.array,
  dispatchDateFiltersChange: PropTypes.func,
  dispatchGlobalFiltersChange: PropTypes.func,
  onResetFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  isDrawingEnabled: PropTypes.bool,
  map: PropTypes.object,
  currentDrilldownGroupByEntry: PropTypes.object,
  dispatchToggleShapeIdsFilter: PropTypes.func,
  selectedShapeIds: PropTypes.array,
  selectedShapeEntry: PropTypes.object,
  currentMapStyleEntry: PropTypes.object
};

LeftSideSection.defaultProps = {
  dispatchDateFiltersChange: _.noop
};

function mapStateToProps(state) {
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);

  return {
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    commonFilters: _.get(state, 'commonFilters', {}),
    currentUser: _.get(state, 'currentUser.user', {}),
    dateFilters: getDateFilterParams(_.get(state, 'commonFilters', {})),
    apiParams: getApiParams(apiParamsOptions, {}),
    quickFilters: _.get(state, 'drilldown.quickFilters', []),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    filteredGeojson: _.get(state, 'visualization.mapOptions.filteredGeojson'),
    isDrawingEnabled: _.get(state, 'visualization.mapOptions.isDrawingEnabled'),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry'),
    currentMapStyleEntry: _.get(state, 'visualization.mapOptions.currentMapStyleEntry'),
    selectedShapeEntry: _.get(state, 'visualization.mapOptions.shape.datasetEntry'),
    selectedShapeIds: _.cloneDeep(_.get(state, 'visualization.mapOptions.shape.selectedShapeIds', [])),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDateFiltersChange: (filters) => {
      // const dateRange = _.get(filters,'dateRange');
      // const dateType = _.get(filters,'dateType');
      // const compareRange = getLastPeriodDateRange(
      //   _.cloneDeep(dateRange),
      //   dateType
      // );
      dispatch(updateComparisonType(DATE_COMPARISON_TYPE_OPTIONS[1].value));
      // dispatch(updateComparisonDateRanges([compareRange]));
      dispatch(updateComparisonModeOn(false));
      dispatch(updateComparisonDateRanges([]));
      dispatch(updateDateFilters(filters));
    },
    dispatchToggleShapeIdsFilter: (newShapeIds = [], shapeExtent = null) => {
      dispatch(toggleShapeIdsFilter(newShapeIds, shapeExtent));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideSection);
