import React, { useState, useEffect } from 'react';
import ExpandPhotoModal from 'modules/AdvanceSearch/ExpandPhotoModal';
import GlobalEvents from 'common/components/GlobalEvents';

const ExpandPopupHandler = () => {
  const [isPopupExpand, setIsPopupExpand] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const {
    renderPhotoContent,
    searchTemplateId,
    searchMethod,
    title,
    subTitle,
    showExpandIcon,
    handleMapPopupClose,
    handleExpandClose
  } = modalContent;

  useEffect(() => {
    const handleExpandPhotoModal = (props) => {
      setModalContent(props);
      setIsLoading(_.get(props, 'isLoading', false));
      setIsPopupExpand(true);
    };

    GlobalEvents.on('EXPAND_POPUP_MODAL_MAP_VIEW', handleExpandPhotoModal);

    return () => {
      GlobalEvents.off('EXPAND_POPUP_MODAL_MAP_VIEW', handleExpandPhotoModal);
    };
  }, []);

  const handleClose = (event) => {
    setIsPopupExpand(false);
    event.stopPropagation();
    handleMapPopupClose()
  };

  const handleExpand = (event) => {
    setIsPopupExpand(false);
    event.stopPropagation();
    handleExpandClose()
  }

  if (!isPopupExpand) {
    return null
  }

  return (
    <>
      <ExpandPhotoModal
        renderPhotoContent={() => (
          <div dangerouslySetInnerHTML={{ __html: renderPhotoContent() }} />)}
        searchTemplateId={searchTemplateId}
        searchMethod={searchMethod}
        title={title}
        subTitle={subTitle}
        showExpandIcon={showExpandIcon}
        handleClose={handleClose}
        isPopupExpand={isPopupExpand}
        setIsPopupExpand={setIsPopupExpand}
        handleExpand={handleExpand}
        isLoading={isLoading}
      />
    </>
  );
};

export default ExpandPopupHandler;
