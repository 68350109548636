import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { ForgePopup, ForgeIconButton, ForgeIcon, ForgeButton } from '@tylertech/forge-react';

import { deleteBookmark } from 'common/api/bookmarksApi';
import {
  viewDeleteMessage,
  viewDeleteErrorMessage
} from 'helpers/toastMessages';

const DeleteBookmarkDialog = (props) => {
  const { 
    openPopUp, 
    setDeletePopUp, 
    currentBookmarkId, 
    onDeleteBookmark, 
    setLoading, 
    targetElementRef 
  } = props;

  const handleDelete = () => {
    setDeletePopUp(false);
    setLoading(true);
    deleteBookmark(currentBookmarkId)
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          onDeleteBookmark();
          toast.success(viewDeleteMessage);
        } else {
          toast.error(viewDeleteErrorMessage);
        }
      })
      .catch((error) => {
        console.log('Error on deleting saved view', error);
        toast.error(viewDeleteErrorMessage);
      });
  };

  return (
    <div>
      <ForgePopup
        open={openPopUp}
        targetElementRef={targetElementRef}
        onDismiss={() => setDeletePopUp(false)}
      >
        <div className='bookmark-save-container'>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className='forge-typography--headline5'>Are you sure?</div>
            <ForgeIconButton>
              <button
                type="button" aria-label="View user profile"
                onClick={() => {setDeletePopUp(false)}}
              >
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          </div>
          <div className='forge-typography--body2 black-65 mb-5'>Deleting saved filters cannot be undone</div>

          <div className="dropdown-footer d-flex justify-content-end align-items-center">
              <ForgeButton type="outlined">
                <button
                  className="mr-2"
                  tabIndex={0}
                  aria-label="cancel"
                  onClick={() => setDeletePopUp(false)}>
                  Cancel
                </button>
              </ForgeButton>
              <ForgeButton type="raised">
                <button
                  className="btn-danger bg-danger"
                  tabIndex={0}
                  aria-label="Save"
                  onClick={() => handleDelete()}>
                  Yes, Delete
                </button>
              </ForgeButton>
            </div>

        </div>
      </ForgePopup>
    </div>
  );
}

DeleteBookmarkDialog.propTypes = {
  openPopUp: PropTypes.bool,
  setDeletePopUp: PropTypes.func,
  currentBookmarkId: PropTypes.string,
  onDeleteBookmark: PropTypes.func,
  setLoading: PropTypes.func,
  targetElementRef: PropTypes.any
}

export default DeleteBookmarkDialog;
