import _ from 'lodash';

import { getIconCode } from '../helpers/mapHelper';

const MAPBOX_GL_ICONS_FONT_NAME = 'socrata-charms-v007 Regular';
const LAYERS = Object.freeze({ DROP_PIN: 'drop-pin-layer' });
const SOURCES = Object.freeze({ DROP_PIN: 'drop-pin-source' });
const DROP_PIN_SYMBOL = Object.freeze({
  TEXT_FIELD: 'map-marker-alt',
  TEXT_SIZE: 20,
  TEXT_COLOR: '#ff0000'
});

export default class DropPin {
  constructor(map) {
    this._map = map;
  }

  alreadySetup() {
    return !_.isNil(this._map.getSource(SOURCES.DROP_PIN));
  }

  initialize = () => {
    if (this.alreadySetup()) {
      return;
    }

    this._map.addSource(SOURCES.DROP_PIN, {
      type: 'geojson',
      data: getGeojson()
    });

    this._map.addLayer({
      'id': LAYERS.DROP_PIN,
      'type': 'symbol',
      'source': SOURCES.DROP_PIN,
      'layout': {
        'text-font': [MAPBOX_GL_ICONS_FONT_NAME],
        'text-field': getIconCode(DROP_PIN_SYMBOL.TEXT_FIELD),
        'text-ignore-placement': true,
        'text-size': DROP_PIN_SYMBOL.TEXT_SIZE
      },
      'paint': {
        'text-color': DROP_PIN_SYMBOL.TEXT_COLOR
      }
    });
  }

  update = (feature = null) => {
    if(this._map.getSource(SOURCES.DROP_PIN)) {
      this._map.getSource(SOURCES.DROP_PIN).setData(getGeojson(feature));
    }
  }
}

function getGeojson(feature = null) {
  let geojsonFeatures = [];
  if (!_.isNil(feature)) {
    geojsonFeatures = [{
      type: 'Feature',
      geometry: feature.geometry
    }];
  }

  return {
    type: 'FeatureCollection',
    features: geojsonFeatures
  };
}
