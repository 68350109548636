import React, { Component } from 'react';
import { getAppExpiredMessage } from 'common/config/customerConfiguration';

class AppExpiry extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div className="col-md-6 offset-md-3 mt-10 text-center helpful-text">
        {getAppExpiredMessage()}
      </div>
      )
  }
}

AppExpiry.propTypes = {
  // foo: PropTypes.string
}

export default AppExpiry;
