import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';

import { getCurrentViewEntry } from 'common/config/templateConfiguration';
import { formatVarianceValue, getEntitiesValue } from './InsightsHelper';
const ITEMS_PER_PAGE = 5;
class InsightsTabs extends Component{
  constructor(props, context) {
    super(props, context);

    const { allInsights } = props;
    this.state = {
      currentStartIndex: 0,
      total: allInsights.length
    };
  }

  handlePreviousClick = () => {
    this.slider && this.slider.slickPrev();
  }

  handleNextClick = () => {
    this.slider && this.slider.slickNext();
  }

  renderTabs() {
    const { allInsights } = this.props;

    if (_.isEmpty(allInsights)) {
      return (
        <div> There are currently no Insights.</div>
      );
    } 

    const sliderSettings = {
      slidesToShow: ITEMS_PER_PAGE,
      slidesToScroll: ITEMS_PER_PAGE,
      dots: false,
      infinite: false,
      afterChange: (index) => this.setState({ currentStartIndex: index })
    };

    return (
      <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
        {_.map(allInsights, this.renderTab)}
      </Slider>
    );
  }

  renderTab = (insight, index) => {
    const { onTabSelection, selectedInsightId } = this.props;
    const { 
      insight_id: insightId, 
      template_id, 
      view_id,
      calculation
    } = insight;

    if(_.get(insight, 'isLoading', true)){
      return (
        <div className="insights-tabs-item" key={insightId}>
          <Spinner variant="primary" className="loading-spinner" size="sm" animation="border" />
        </div>
      );
    }

    const firstResult = _.get(insight, 'apiData.results.0');
    const navClassNames = classNames('insights-tabs-item', {
      'active': selectedInsightId == insightId
    });
    const variance = Number(_.get(firstResult, 'variance', 0));
    const viewEntry = getCurrentViewEntry(template_id, Number(view_id));
    const formattedVariance = formatVarianceValue(variance, calculation, viewEntry);
    const arrowClassNames = classNames('icons', {
      'icons-big-up-arrow': variance > 0,
      'icons-big-down-arrow': variance < 0
    });
    const entities = getEntitiesValue(firstResult);
    const title = _.get(insight, 'name');
  
    return(
      <div 
        className={navClassNames}
        onClick={() => onTabSelection(insightId)}
        tabIndex={index}
        key={insightId}>
          <div className="insights-tabs-details">
            <div className="insight-name">{title}:</div>
            <div className="insight-dimension-values">{entities}&nbsp;</div>
          </div>
          <h1 className="insights-variance">
            <i className={arrowClassNames}></i> {formattedVariance}
          </h1>
      </div>
    );
  }

  renderPreviousButton() {
    const { currentStartIndex } = this.state;
    if(currentStartIndex <= 0){
      return null;
    }
    const remainingCount = currentStartIndex;
    return(
      <div className='pre-btn' onClick={this.handlePreviousClick}>
        <i className='icons-arrow-left-drop-circle' alt="Previous" />
        {remainingCount} Insights
      </div>
    );
  }

  renderNextButton(){
    const { allInsights } = this.props;
    const { currentStartIndex } = this.state;
    const remainingCount = allInsights.length - (currentStartIndex + ITEMS_PER_PAGE);
    if (remainingCount <= 0) {
      return null;
    }
    return(
      <div className='next-btn' onClick={this.handleNextClick}>
        <i className='icons-arrow-right-drop-circle-1' alt="Next" />
        {remainingCount} Insights
      </div>
    );
  }

  render(){  
    return (
      <div className="insights-tabs-wrapper">
        <div className="insights-tabs">
          {this.renderTabs()}
        </div>
        {this.renderPreviousButton()}
        {this.renderNextButton()}
      </div>
    );
  }
}

InsightsTabs.propTypes = {
  allInsights: PropTypes.arrayOf(PropTypes.shape({})),
  selectedInsightId: PropTypes.string,
  onTabSelection: PropTypes.func
}

export default InsightsTabs;
