import { DATE_TYPES_FIELD, FILTER_SELECT_ALL, NUMBER_TYPES_FIELD, STRING_TYPES_FIELD } from 'appConstants';

export const getNewQuickFiltersAndGlobalFilters = ({
  collectionFilters,
  globalFilterConfigEntries,
  quickFilters,
  quickFilterEntries,
  isCardClick = false
}) => {
  if (isCardClick && _.isEmpty(collectionFilters)) {
    return {
      newQuickFilters: quickFilters,
      newCollectionFilters: collectionFilters
    };
  }
  const quickFiltersWithEntries = mergeFilterWithEntries(quickFilters, quickFilterEntries);
  const newCollectionFilters = mergeFilterWithEntries(collectionFilters, globalFilterConfigEntries);
  return moveMatchedQuickFiltersToCollectionFilters(
    newCollectionFilters, quickFiltersWithEntries
  );
}

export const mergeFilterWithEntries = (filters, filterEntries) => {
  let filterWithEntries = {};

  _.each(filters, (filter) => {
    const { field } = filter;
    const matchedFilterEntry = _.find(filterEntries, { field }) || {};
    const fieldName = `${matchedFilterEntry.column}-${matchedFilterEntry.name}`;

    filterWithEntries[fieldName] = { ...filter, ...matchedFilterEntry, filter };
  });

  return filterWithEntries;
}

export const filterFormatValues = (params) => {
  let queryParams = _.cloneDeep(params);
  if(!_.isEmpty(queryParams['drilldownEntry'])){
    queryParams['drilldownEntry'] = _.replace(queryParams['drilldownEntry'], FILTER_SELECT_ALL, "0");
    return queryParams
  } else {
    return queryParams
  }
}

const moveMatchedQuickFiltersToCollectionFilters = (
  collectionFilters,
  quickFilters
) => {
  let newQuickFilters = [];
  let filters = _.values(collectionFilters);

  _.each(quickFilters, (quickFilter) => {
    const { column, name, conditions, filter } = quickFilter;
    const matchedFilterEntry = _.get(collectionFilters, `${column}-${name}`);
    if (!_.isEmpty(matchedFilterEntry)) {
      filters.push({ ...matchedFilterEntry, conditions });
    } else {
      newQuickFilters.push(filter);
    }
  });
  const newCollectionFilters = _.map(filters, (filter) => {
    const { type } = filter;
    if(type === STRING_TYPES_FIELD) {
      return _.pick(filter, 'column', 'conditions', 'type', 'field');
    }else if(type === NUMBER_TYPES_FIELD) {
      return _.pick(filter, 'column', 'type', 'field', 'operator', 'value', 'to');
    }else if(type === DATE_TYPES_FIELD) {
      return _.pick(filter, 'column', 'dateRange', 'type', 'field');
    } else {
      return filter;
    }
  });

  return {
    newQuickFilters,
    newCollectionFilters: groupByFiltersBasedOnField(newCollectionFilters)
  }
}

const groupByFiltersBasedOnField = (filters) => {
  return _.chain(filters).
    groupBy('field').
    map((groupedFilters, field) => {
      const { column, type } = _.head(groupedFilters);
      if(type === STRING_TYPES_FIELD) {
        const newConditions = _.chain(groupedFilters).
          map('conditions').
          flatten().
          groupBy('operator').
          map((conditions, operator) => {
            const newValue = _.chain(conditions).map('value').flatten().uniq().value();
            return { operator, value: newValue };
          }).
          value();

        return (column && !_.isEmpty(newConditions)) ?
          { column, type, conditions: newConditions, field } :
          null;
      }else{
        return _.head(groupedFilters);
      }
    }).
    without(null).
    value();
}
