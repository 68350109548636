import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types'

function PermissionFilterLabel(props) {
  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const permissionLabels = props.permissionLabels;
  const labelField = props.labelField;
  const uniqueKey = `${labelField}-permission-label-key`;

  useEffect(() => {
    var lineElement = document.getElementById(`${uniqueKey}`);
    if (!_.isEmpty(lineElement)) {
      var divHeight = lineElement.offsetHeight;
      var lineHeight = 19;
      var lines = divHeight / lineHeight;

      if (lines > 4 && !show) {
        setShow(true);
        lineElement.classList.add("permission-ellipsis");
      }
    }
  });

  const onIconClick = () => {
    setShowMore(!showMore);
    var lineElement = document.getElementById(`${uniqueKey}`);

    if (!showMore) {
      lineElement.classList.add("permission-ellipsis");
      lineElement.classList.remove("permission-expand");
    } else {
      lineElement.classList.add("permission-expand");
      lineElement.classList.remove("permission-ellipsis");
    }
  }

  return (
    <div className='permission-filters' id={uniqueKey}>
      <div className="filter-chip">
        <div className="field-title filter-chip-values pr-2">
          <i className="icons-np-key" />
          <div className='permission-labels'>
            <span className="text-capitalize font-weight-normal">{labelField}</span>
            <span className="font-weight-normal mx-1">is</span>
            {permissionLabels.join(' or ')}
          </div>
        </div>
        {show && <div className="expanded-control">
          <i className='icons-arrow-down d-block' onClick={() => onIconClick()} /></div>}
      </div>
    </div>
  )
}

PermissionFilterLabel.propTypes = {
  permissionLabels: PropTypes.array,
  labelField: PropTypes.string
}

export default PermissionFilterLabel
