export const SET_CURRENT_COLLECTION_ID = 'SET_CURRENT_COLLECTION_ID';
export const SET_CURRENT_CARD_ID = 'SET_CURRENT_CARD_ID';
export const SET_DEFAULT_TEMPLATE_ENTRY = "SET_DEFAULT_TEMPLATE_ENTRY";
export const SET_BELLEROPHON_COLLECTION_TAG_ID = "SET_BELLEROPHON_COLLECTION_TAG_ID";
export const SET_OVERWRITE_COLLECTION_FILTER = "SET_OVERWRITE_COLLECTION_FILTER";
export const SET_MIN_DATES_FOR_TEMPLATES = "SET_MIN_DATES_FOR_TEMPLATES";
export const SET_IS_MANAGE_COLLECTION = "SET_IS_MANAGE_COLLECTION";
export const SET_MANAGE_COLLECTION_DETAILS = "SET_MANAGE_COLLECTION_DETAILS";
export const SET_ANALYSIS_PAGE_COLLECTION_ID = "SET_ANALYSIS_PAGE_COLLECTION_ID";
export const SET_ANALYSIS_PAGE_COLLECTION_TAG_ID = "SET_ANALYSIS_PAGE_COLLECTION_TAG_ID";
export const SET_IS_MANAGE_METRIC_LIBRARY = "SET_IS_MANAGE_METRIC_LIBRARY";
export const SET_MIN_DATES_LOADING_STATUS = "SET_MIN_DATES_LOADING_STATUS";
export const SET_SELECTED_VIEW = "SET_SELECTED_VIEW";

export const setCurrentCollectionId = (collectionId) => ({
  type: SET_CURRENT_COLLECTION_ID,
  collectionId
});

export const setSelectedView = (selectedView) => ({
  type: SET_SELECTED_VIEW,
  selectedView
});

export const setAnalysisPageCollectionId = (collectionId) => ({
  type: SET_ANALYSIS_PAGE_COLLECTION_ID,
  collectionId
});

export const setCurrentCardId = (cardId) => ({
  type: SET_CURRENT_CARD_ID,
  cardId
});

export const setMinDatesForTemplates = (minDates) => ({
  type: SET_MIN_DATES_FOR_TEMPLATES,
  minDates
});

export const setMinDatesLoadingStatus = (isMinDateResultsLoading) => ({
  type: SET_MIN_DATES_LOADING_STATUS,
  isMinDateResultsLoading
});

export const setDefaultTemplateEntry = (templateEntry) => ({
  type: SET_DEFAULT_TEMPLATE_ENTRY,
  templateEntry
});

export const setBellerophonCollectionTagId = (collectionTagId) => ({
  type: SET_BELLEROPHON_COLLECTION_TAG_ID,
  collectionTagId
});

export const setAnalysisPageCollectionTagId = (collectionTagId) => ({
  type: SET_ANALYSIS_PAGE_COLLECTION_TAG_ID,
  collectionTagId
});

export const setOverwriteCollectionFilter = (isOverwriteFilter) => ({
  type: SET_OVERWRITE_COLLECTION_FILTER,
  isOverwriteFilter
});

export const setIsManageCollection = (isManageCollection) => ({
  type: SET_IS_MANAGE_COLLECTION,
  isManageCollection
});

export const setManageCollectionDetails = (collection) => ({
  type: SET_MANAGE_COLLECTION_DETAILS,
  collection
});

export const setIsManageMetricLibrary = (isManageMetricLibrary) => ({
  type: SET_IS_MANAGE_METRIC_LIBRARY,
  isManageMetricLibrary
});
