// vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

// Project Imports
import Filters from 'common/components/Filters';
import {
  getCurrentTemplateGlobalFilters,
} from 'pages/dashboard/components/Collections/collectionHelper';
import { isClickOutSideFilter } from 'common/components/Filters/FilterDomHelper';
import {
  getFiltersCount,
  getDateRangeFiltersText,
  polygonFilterCount,
  geographicFilterCount
} from 'helpers/FilterIconHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getNewQuickFiltersAndGlobalFilters } from 'helpers/FilterHelper';
import { getGlobalFiltersFromTemplate } from 'common/config/customerConfiguration';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { buildQueryString } from 'helpers/HttpHelper';
class FilterIcon extends Component {
  state = {
    showFilterContent: false,
    shapeEntries: []
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.getShapeEntries();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { showFilterContent } = this.state;
    if (isClickOutSideFilter(e, this.filterRef) && showFilterContent) {
      // hiding field filter drop down
      this.setState({ showFilterContent: false });
    }
  }

  handleEnterButtonPressed = (event) => {
    if (isEnterButtonPressed(event)) {
      this.toggleAddFilterButton(event);
    }
  }

  getShapeEntries = async () => {
    const { templateEntry, shapeGeoJson, shapeData } = this.props;
    const isGeoFilterEnabled = geographicFilterCount(shapeGeoJson) > 0;
    if(isGeoFilterEnabled){
      if(_.isEmpty(shapeData)){
        const params = {
          currentDrilldownTemplateId: _.get(templateEntry,'template_id'),
          shapeGroupId: _.get(shapeGeoJson,'datasetEntry.shape_dataset_id')
        };
        const apiUrl = `api/shapes/details_without_geom.json?${buildQueryString(params)}`;
        const shapeEntries = await fetchApiData(apiUrl);
        this.setState({shapeEntries: shapeEntries});
      }else{
        const newShapeData = _.cloneDeep(_.map(shapeData, (data) => { return _.first(data)}));
        this.setState({shapeEntries: newShapeData});
      }
    }
  }

  toggleAddFilterButton = (e) => {
    const { showFilterContent } = this.state;
    e.stopPropagation();

    this.setState({ showFilterContent: !showFilterContent });
  }

  renderFilterChipLabel = () => {
    return (
      <div
        className="filter-chip-values filter-count-values filter-chip-active"
        tabIndex={0}
        onClick={this.toggleAddFilterButton}
        onKeyDown={this.handleEnterButtonPressed}>
        <forge-icon name="filter_variant_check" class="filter-icon tx-18" />
        <div className="chip-values-text">
          <span className="font-weight-bolds">
            {getFiltersCount(this.props)}
          </span>
        </div>
      </div>
    );
  }

  renderCollectionLevelDateFilters = () => {
    const {
      bookmarkFitlersText, isUnSelectFilters, isRelativeDateSelected
    } = this.props;
    const filtersText = getDateRangeFiltersText(this.props, isUnSelectFilters, isRelativeDateSelected);
    const bookmarkDateFiltersNames = _.map(bookmarkFitlersText, 'dateFilterKey');

    return _.map(filtersText, (filterText, index) => {
      const { dateFilterLabel, dateFilterKey } = filterText;
      if (!_.includes(bookmarkDateFiltersNames, dateFilterKey) && isUnSelectFilters) {
        return null;
      }
      const matchedDateFilter = _.find(bookmarkFitlersText, { dateFilterLabel });
      if (isUnSelectFilters && !_.isEmpty(matchedDateFilter)) {
        return null;
      }
      return this.renderCollectionDateFilter(filterText, index, _.isEmpty(matchedDateFilter));
    });
  }

  onKeydownFilterRemoveBadgeItem = (e, filterName) => {
    const { handleRemoveBookmarkDate } = this.props;
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      handleRemoveBookmarkDate(e, filterName);
    }
  }

  renderCollectionDateFilter = (filterConfig, index, showRemoveDate = true) => {
    const { name, rangeText } = filterConfig;
    const { isUnSelectFilters, isRemoveSaveDate, handleRemoveBookmarkDate } = this.props;
    const dateFilterClassNames = classNames('date-range-filter', {
      'unselect': isUnSelectFilters
    });

    const shouldShowRemoveDateIcon = isRemoveSaveDate && showRemoveDate;
    const filterChipClassNames = classNames('filter-chip-values', {
      'pr-0': shouldShowRemoveDateIcon
    });

    return (
      <div className={dateFilterClassNames} key={index}>
        <div className="filter-chip" tabIndex="0">
          <div className={filterChipClassNames}>
            <i className="icons-date-filter-icon align-middle"></i>
            <div className="chip-values-text">
              <span className="mr-1">{name}</span>
              <span className="font-weight-bold">
                {rangeText}
              </span>
            </div>
            {shouldShowRemoveDateIcon &&
              <span
                tabIndex={0}
                aria-label="remove filtered value"
                className="tag-close rounded-circle icons-times d-flex"
                onKeyDown={(e) => this.onKeydownFilterRemoveBadgeItem(e, name)}
                onClick={(e) => handleRemoveBookmarkDate(e, name)}>
              </span>
            }
          </div>
        </div>
      </div>
    );
  }

  renderPolygonFilter = () => {
    const { filteredGeojson } = this.props;
    const count = polygonFilterCount(filteredGeojson);
    if(count == 0){
      return null;
    }

    return (
      <div className="filter-chip" key="polygon-filter" tabIndex="0">
        <div className='filter-chip-values'>
          <i className="icons-tactic"/>
          <div className='chip-values-text'>Custom boundary</div>
        </div>
      </div>
    );
  }

  renderGeographicFilter = () => {
    const { shapeGeoJson } = this.props;
    const { shapeEntries } = this.state;
    const count = geographicFilterCount(shapeGeoJson);
    if(count == 0){
      return null;
    }
    const shapeName = _.get(shapeGeoJson,'datasetEntry.shape_name');
    const selectedShapeIds = _.get(shapeGeoJson, 'selectedShapeIds', []);
    const selectedShapes = _.filter(shapeEntries, (shape) => _.includes(selectedShapeIds,shape.shape_id));
    const selectedShapeNames = _.map(selectedShapes, 'shape_name');

    return !_.isEmpty(selectedShapeNames) && (
      <div className="filter-chip" key="geographic-filter" tabIndex="0">
        <div className='filter-chip-values'>
          <forge-icon name="filter_list" class="tx-18" />
          <div className='chip-values-text'>{`${shapeName} is `}
            <span className="font-weight-bold">
              {_.join(selectedShapeNames, ' or ')}
            </span>
        </div>
        </div>
      </div>
    );
  }

  renderFilterBody = (isUnSelectFilters = false) => {
    const {
      templateEntry, globalFilters, quickFilterEntries, quickFilters, isBookmarkCard, isHideDateFilters,
      currentDrilldownViewEntry
    } = this.props;
    const collectionFilters = getCurrentTemplateGlobalFilters(templateEntry, globalFilters);

    const globalFilterConfigEntries = getGlobalFiltersFromTemplate(templateEntry);
    const filterBodyClassNames = classNames('filters-body filters-container', {
      'mb-5': !isBookmarkCard
    });
    const filterConfig = getNewQuickFiltersAndGlobalFilters({
      collectionFilters: _.cloneDeep(collectionFilters),
      globalFilterConfigEntries,
      quickFilters: _.cloneDeep(quickFilters),
      quickFilterEntries
    });
    const newQuickFilters = isUnSelectFilters ? quickFilters : _.get(filterConfig, 'newQuickFilters');
    const newCollectionFilters = isUnSelectFilters ?
      collectionFilters :
      _.get(filterConfig, 'newCollectionFilters');

    return (
      <div className={filterBodyClassNames}>
        {this.renderPolygonFilter()}
        {this.renderGeographicFilter()}
        {!isHideDateFilters && this.renderCollectionLevelDateFilters()}
        <Filters
          canStopPropagation={true}
          viewEntry={currentDrilldownViewEntry}
          isUnSelectFilters={isUnSelectFilters}
          isDisabledAddFilterButton={true}
          isFilterBadge={true}
          type="quickFilters"
          apiParams={{}}
          filterFieldEntries={globalFilterConfigEntries}
          filters={newCollectionFilters}
          templateId={0}
        />
        <Filters
          canStopPropagation={true}
          viewEntry={currentDrilldownViewEntry}
          isUnSelectFilters={isUnSelectFilters}
          isDisabledAddFilterButton={true}
          type="quickFilters"
          isFilterBadge={true}
          apiParams={{}}
          filterFieldEntries={quickFilterEntries}
          filters={newQuickFilters}
          templateId={0}
        />
      </div>
    );
  }

  renderOverrideFilterText = () => {
    const { isBookmarkCard, hideOverrideLabel, isRemoveSaveDate } = this.props;
    return (isBookmarkCard && !hideOverrideLabel && isRemoveSaveDate) && (
      <em className="d-block text-muted tx-14 mb-2">
        This view has conflicting date filters. Press the X to remove the saved date filter.
      </em>
    );
  }

  render() {
    const { showFilterContent } = this.state;
    const { onlyRenderFilters, isBookmarkCard } = this.props;
    if (getFiltersCount(this.props) < 1) {
      return null;
    }
    const filterClassNames = classNames('global-filter filter-count',
      { 'show-filter-chip': showFilterContent }
    );

    if (onlyRenderFilters && isBookmarkCard) {
      return (
        <div className="metric-card-collection-filters">
          {this.renderFilterBody(true)}
        </div>
      );
    }

    return (
      <div className="metric-card-filter-count" ref={(ref) => this.filterRef = ref}>
        <div className={filterClassNames}>
          <div className="filter-chip">
            {this.renderFilterChipLabel()}
            <div className="filter-chip-show">
              <div className="common-filters-wrapper pt-0">
                {this.renderOverrideFilterText()}
                {this.renderFilterBody()}
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FilterIcon.propTypes = {
  bookmarkFitlersText: PropTypes.array,
  cardEntries: PropTypes.array,
  children: PropTypes.any,
  currentDrilldownViewEntry: PropTypes.object,
  dateFilters: PropTypes.object,
  globalFilters: PropTypes.array,
  hideCommonFilters: PropTypes.bool,
  hideOverrideLabel: PropTypes.bool,
  isBookmarkCard: PropTypes.bool,
  isHideDateFilters: PropTypes.bool,
  isUnSelectFilters: PropTypes.bool,
  onlyRenderFilters: PropTypes.bool,
  quickFilterEntries: PropTypes.array,
  quickFilters: PropTypes.array,
  templateEntry: PropTypes.object,
  type: PropTypes.string,
  isEmbed: PropTypes.bool,
  isRemoveSaveDate: PropTypes.bool,
  handleRemoveBookmarkDate: PropTypes.func,
  filteredGeojson: PropTypes.object,
  isRelativeDateSelected: PropTypes.bool,
  isRadarFilter: PropTypes.bool,
  shapeGeoJson: PropTypes.object,
  shapeData: PropTypes.array,
};

FilterIcon.defaultProps = {
  bookmarkFitlersText: [],
  cardEntries: [],
  children: null,
  dateFilters: {},
  globalFilters: [],
  hideCommonFilters: false,
  hideOverrideLabel: true,
  isBookmarkCard: false,
  isUnSelectFilters: false,
  onlyRenderFilters: false,
  isHideDateFilters: false,
  quickFilterEntries: [],
  quickFilters: [],
  templateEntry: {},
  type: '',
  isEmbed: false,
  isRemoveSaveDate: false,
  isRelativeDateSelected: false,
  isRadarFilter: false,
  shapeGeoJson: {}
};

export default FilterIcon;
