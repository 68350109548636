import React from 'react'
import PropTypes from 'prop-types'

import SelectedCellDescriptions from './SelectedCellDescriptions';

const TimeOfDayFilterLabel = ({ filters , showFilterContent = false }) => {
  let filterLabel = 'Time of Day, Day of Week';
  if(_.isEmpty(filters) && !showFilterContent) {
    filterLabel = 'All hours';
  } else if(!_.isEmpty(filters) && !showFilterContent) {
    filterLabel = (
      <div>
        <SelectedCellDescriptions filters={filters} />
      </div>
    );
  }

  return filterLabel;
}

TimeOfDayFilterLabel.propTypes = {
  filters: PropTypes.array,
  showFilterContent: PropTypes.bool
}

export default TimeOfDayFilterLabel
