import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// We show the dummy card because we load the actual metrics card based on screen size instead of 
// setting the default card count. So we need at least one card to calculate the width of a card.
const DummyCard = ({ isLoading }) => {
  const visibleClassNames = classNames("dummy-cards", {
    "show-card": isLoading,
    "hide-card": !isLoading
  })
  
  return (
    <div className={visibleClassNames}></div>
  );
}

export default DummyCard

DummyCard.propTypes = {
  isLoading: PropTypes.bool
}