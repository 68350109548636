import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CustomButton from 'common/components/CustomButton';

const ModalFooter = (props) => {
  const isCollaborateCollection = useSelector((state) => state.isCollaborateCollection);
  const { toggleModalOpen } = props;

  return (
    <footer className="forge-dialog__footer">
      {!isCollaborateCollection &&
        <CustomButton onClick={() => toggleModalOpen(false)} label="Cancel" style={{ marginRight: 16 }} />
      }
    </footer>
  );
}

ModalFooter.propTypes = {
  toggleModalOpen: PropTypes.func
}

export default ModalFooter;
