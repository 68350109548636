import moment from 'moment';
import {
  OVERTIME_VISUALIZATION_TYPES,
  FORECASTING_TYPE,
  PROPHET_FORECAST_COLOR,
  HISTORICAL_FORECAST_COLOR,
  PROPHET_FORECAST
} from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import { toLineDummyTrace, toLineTrace } from './timeline';
import { getPeriodType, isTraceVisible, getComboChartPrimaryMetricRenderType } from '../vizOptionsHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
import { getDateRangeDifference, MONTH_DAYS_COUNT } from 'helpers/dateHelper';
import { isDiscreteModeData } from 'common/config/viewConfiguration';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import { getCompareTraceColor } from '../Helpers/dimensionHelper';
import {
  getQuarterMonths,
  updateAdjustedValueForData,
  getAdjustedDataAndNegativeData
} from '../Helpers/overtimeHelper';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';

export const toPlotlyTraces = (vizOptions, formattedData) => {
  if (_.isEmpty(formattedData)) {
    return []
  }
  const { renderType, viewEntry, viewMode, dateRange, templateId, axisGranularity } = vizOptions;
  const isSmallView = viewMode === VIEW_MODE.SMALL;
  let isBurnup = renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const periodType = getPeriodType(vizOptions);
  const primaryMetricName = getPrimaryMetricName(viewEntry);
  const primaryMetricRenderType = getComboChartPrimaryMetricRenderType(vizOptions);
  const comboMetricConfigs = _.get(formattedData, 'comboMetricConfigs', []);
  const isProjectionEnabled = vizOptions.projectionEnabled;
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);

  const traces = [];
  const totalMeta = {
    segmentType: 'current',
    isTotal: true,
    value: 'primary',
    dimension: primaryMetricName,
    metricEntry: viewEntry,
    isForecastingView,
    isProjection: false
  };

  let formattedDataWithDummyBar = formattedData;
  // plotly isn't draw bar if it has single bar with other line markers
  // so we add dummy bar for bar chart,
  // For monthly if there is one bar.plotly doesn't plot. so added for that only
  if (periodType == 'month' || periodType == 'day') {
    const singleBarDaysCount = (periodType == 'month') ? MONTH_DAYS_COUNT : '2';
    const dateRangeDifference = getDateRangeDifference(dateRange, 'days');
    // Added dummy month so for burn up it will plot for that month also so it was changed to false.
    if ((dateRangeDifference < singleBarDaysCount)) {
      isBurnup = false;
    }
    formattedDataWithDummyBar = addDummuyBar(formattedData, vizOptions);
  }

  const commonTraceOptions = {
    isBurnup, isDiscreteMode, isSmallView, dateRange, isProjectionEnabled, axisGranularity, isForecastingView
  }

  traces.push(toLineTrace(_.get(formattedDataWithDummyBar, 'current.total', []), 'value', {
    meta: totalMeta,
    lineColor: getConfiguredMetricColor(templateId, 'primary'),
    borderLineColor: getConfiguredMetricColor(templateId, 'primary'),
    lineWidth: 2,
    visible: isTraceVisible(vizOptions, { traceId: primaryMetricName }),
    isSecondsFormat,
    dataUnit,
    renderType: primaryMetricRenderType,
    periodType,
    ...commonTraceOptions
  }));

  _.each(formattedDataWithDummyBar.current.combo_metrics, (comboMetrics) => {
    _.each(comboMetrics, (dataItems, metricField) => {
      const comboMetricConfig = _.find(comboMetricConfigs, { metricField }) || {};
      const dimension = _.get(comboMetricConfig, 'traceId');
      const metricEntry = _.get(comboMetricConfig, 'metricEntry');
      traces.push(toLineTrace(dataItems, 'value', {
        meta: { segmentType: 'current', metricConfig: comboMetricConfig, dimension, metricEntry,
          isForecastingView, isProjection: false},
        lineColor: _.get(comboMetricConfig, 'color'),
        borderLineColor: _.get(comboMetricConfig, 'color'),
        lineWidth: 2,
        visible: isTraceVisible(vizOptions, { traceId: dimension }),
        isSecondsFormat,
        dataUnit,
        renderType: _.get(metricEntry, 'render_type', 'line'),
        periodType,
        ...commonTraceOptions
      }));
    });
  });

  let trailingDropLineColor = getConfiguredMetricColor(templateId, 'primary');
  let trailingDropBorderLineColor = '';
  let trailingDropRenderType = 'markers';

  if (primaryMetricRenderType == 'bar') {
    trailingDropBorderLineColor = getConfiguredMetricColor(templateId, 'primary');
    trailingDropLineColor = getCompareTraceColor(trailingDropBorderLineColor);
    trailingDropRenderType = primaryMetricRenderType;
  }

  traces.push(toLineTrace(formattedDataWithDummyBar.tailingDrop.total, 'value', {
    meta: totalMeta,
    lineColor: trailingDropLineColor,
    borderLineColor: trailingDropBorderLineColor,
    lineWidth: 2,
    borderWidth: 2,
    visible: isTraceVisible(vizOptions, { traceId: primaryMetricName }),
    isSecondsFormat,
    dataUnit,
    renderType: trailingDropRenderType,
    periodType,
    ...commonTraceOptions
  }));

  _.each(formattedDataWithDummyBar.tailingDropComboMetrics, (comboMetrics) => {
    _.each(comboMetrics, (dataItems, metricField) => {
      const comboMetricConfig = _.find(comboMetricConfigs, { metricField }) || {};
      const dimension = _.get(comboMetricConfig, 'traceId');
      const metricEntry = _.get(comboMetricConfig, 'metricEntry');
      let trailingDropLineColor = _.get(comboMetricConfig, 'color');
      let trailingDropBorderLineColor = '';
      let trailingDropRenderType = 'markers';

      if (_.get(metricEntry, 'render_type') == 'bar') {
        trailingDropLineColor = _.get(comboMetricConfig, 'liteColor');
        trailingDropBorderLineColor = _.get(comboMetricConfig, 'color');
        trailingDropRenderType = _.get(metricEntry, 'render_type');
      }
      traces.push(toLineTrace(dataItems, 'value', {
        meta: {
          segmentType: 'current',
          metricConfig: comboMetricConfig, dimension,
          metricEntry, isProjection: false
        },
        lineColor: trailingDropLineColor,
        borderLineColor: trailingDropBorderLineColor,
        lineWidth: 2,
        borderWidth: 2,
        visible: isTraceVisible(vizOptions, { traceId: dimension }),
        isSecondsFormat,
        dataUnit,
        renderType: trailingDropRenderType,
        periodType,
        ...commonTraceOptions
      }));
    });
  });

  if (isForecastingView) {
    const markerOptions = {
      vizOptions,
      totalMeta,
      dataUnit,
      isSecondsFormat,
      primaryMetricName,
      templateId,
      comboMetricConfigs,
      commonTraceOptions,
      periodType,
      primaryMetricRenderType
    }

    getNegativeAndAdjustMarker(traces, formattedDataWithDummyBar, markerOptions, vizOptions);

    if(!isProjectionEnabled && _.get(vizOptions, 'axisGranularity') == 'week'){
      getDummyXAxisForecast(traces, formattedDataWithDummyBar, markerOptions);
    }
  }

  if (isProjectionEnabled) {
    _.each(formattedDataWithDummyBar.projection.combo_projection_total, (comboMetrics) => {
      _.each(comboMetrics, (dataItems, metricField) => {
        const comboMetricConfig = _.find(comboMetricConfigs, { metricField }) || {};
        const dimension = _.get(comboMetricConfig, 'traceId');
        const metricEntry = _.get(comboMetricConfig, 'metricEntry');
        const meta = {
          segmentType: 'current',
          metricConfig: comboMetricConfig,
          dimension,
          metricEntry,
          isProjection: true
        };
        if (_.get(metricEntry, 'render_type') !== 'bar') {
          if (isForecastingView) {
            _.each(dataItems, (forecastProjection) => {
              const dataItems = _.get(forecastProjection, 'data', []);
              const projectionType = _.get(forecastProjection, 'type', '');
              const traceColor = projectionType == PROPHET_FORECAST ?
                PROPHET_FORECAST_COLOR : HISTORICAL_FORECAST_COLOR;

              traces.push(toLineTrace(dataItems, 'value', {
                meta: { ...meta, projectionType },
                lineColor: traceColor,
                lineWidth: 2,
                lineDash: 'dot',
                visible: isTraceVisible(vizOptions, { traceId: dimension }),
                renderType: _.get(metricEntry, 'render_type', 'line'),
                isSecondsFormat,
                dataUnit,
                periodType,
                ...commonTraceOptions
              }));
            });

          } else {
            traces.push(toLineTrace(dataItems, 'value', {
              meta,
              lineColor: _.get(comboMetricConfig, 'color'),
              lineWidth: 2,
              lineDash: 'dot',
              visible: isTraceVisible(vizOptions, { traceId: dimension }),
              renderType: _.get(metricEntry, 'render_type', 'line'),
              isSecondsFormat,
              dataUnit,
              periodType,
              ...commonTraceOptions
            }));
          }
        }
      });
    });

    if (primaryMetricRenderType == 'line') {
      if (isForecastingView) {
        _.each(formattedData.projection.forecastingProjections, (forecastProjection) => {
          const projectionData = _.get(forecastProjection, 'data', []);
          const projectionType = _.get(forecastProjection, 'type', '');
          const traceColor = projectionType == FORECASTING_TYPE.PROPHET ?
            PROPHET_FORECAST_COLOR : HISTORICAL_FORECAST_COLOR;
          totalMeta['color'] = traceColor;

          traces.push(toLineTrace(projectionData, 'value', {
            meta: _.merge({}, totalMeta, { isProjection: true, projectionType }),
            lineColor: traceColor,
            lineWidth: 2,
            lineDash: 'dot',
            visible: isTraceVisible(vizOptions, { traceId: primaryMetricName }),
            isSecondsFormat,
            dataUnit,
            renderType: 'line',
            periodType,
            ...commonTraceOptions
          }));
        });
      } else {
        traces.push(toLineTrace(formattedDataWithDummyBar.projection.total, 'value', {
          meta: _.merge({}, totalMeta, { isProjection: true }),
          lineColor: getConfiguredMetricColor(templateId, 'primary'),
          lineWidth: 2,
          lineDash: 'dot',
          visible: isTraceVisible(vizOptions, { traceId: primaryMetricName }),
          isSecondsFormat,
          dataUnit,
          renderType: 'line',
          periodType,
          ...commonTraceOptions
        }));
      }
    }
  }

  return traces;
}

const addDummuyBar = (formattedData, vizOptions) => {
  const comboMetricConfigs = _.get(formattedData, 'comboMetricConfigs', []);
  const primaryMetricRenderType = getComboChartPrimaryMetricRenderType(vizOptions);
  const totalItems = _.get(formattedData, 'current.total', []);
  const trailDropTotalItems = _.get(formattedData, 'tailingDrop.total', []);
  if (primaryMetricRenderType == 'bar') {
    if (totalItems.length == 1 && _.isEmpty(trailDropTotalItems)) {
      formattedData.current.total.push(getdummyEntry(_.first(totalItems), vizOptions));
    }

    if (trailDropTotalItems.length == 1) {
      formattedData.tailingDrop.total.push(getdummyEntry(_.first(trailDropTotalItems), vizOptions));
    }
  }

  _.each(formattedData.current.combo_metrics, (comboMetrics, index) => {
    _.each(comboMetrics, (dataItems, metricField) => {
      const comboMetricConfig = _.find(comboMetricConfigs, { metricField }) || {};
      const metricEntry = _.get(comboMetricConfig, 'metricEntry');
      const trailingDropMetric = formattedData.tailingDropComboMetrics[index][metricField];
      if (_.get(metricEntry, 'render_type') == 'bar'
        && dataItems.length == 1 && _.isEmpty(trailingDropMetric)) {
        const dummyEntry = getdummyEntry(_.first(dataItems), vizOptions)
        formattedData.current.combo_metrics[index][metricField].push(dummyEntry);
      }
    });
  });

  _.each(formattedData.tailingDropComboMetrics, (comboMetrics, index) => {
    _.each(comboMetrics, (dataItems, metricField) => {
      const comboMetricConfig = _.find(comboMetricConfigs, { metricField }) || {};
      const metricEntry = _.get(comboMetricConfig, 'metricEntry');
      if (_.get(metricEntry, 'render_type') == 'bar' && dataItems.length == 1) {
        const dummyEntry = getdummyEntry(_.first(dataItems), vizOptions)
        formattedData.tailingDropComboMetrics[index][metricField].push(dummyEntry);
      }
    });
  });

  return formattedData;
}

const getdummyEntry = (item, vizOptions) => {
  const { axisGranularity } = vizOptions;
  const isQuarterType = axisGranularity == 'quarter';
  const periodType = getPeriodType(vizOptions);
  const addValue = isQuarterType ? 3 : 1;
  const periodDate = item.periodMmt.add(addValue, periodType).format(DATE_FORMAT);
  const quarterMonths = getQuarterMonths();
  let periodMonth = moment(periodDate).month();
  let periodYear = moment(periodDate).year();
  const quarterText = `${quarterMonths[periodMonth]} ${periodYear}`;
  return {
    ...item,
    value: null,
    quarterText,
    period: item.periodMmt.add(addValue, periodType).format(DATE_FORMAT),
    periodMmt: item.periodMmt.add(addValue, periodType)
  }
}

const getNegativeAndAdjustMarker = (traces, formattedData, markerOptions) => {
  const {
    vizOptions,
    totalMeta,
    dataUnit,
    isSecondsFormat,
    templateId,
    periodType,
    comboMetricConfigs,
    commonTraceOptions,
    primaryMetricRenderType
  } = markerOptions;

  const formattedDataTotal = _.cloneDeep(_.get(formattedData, 'current.total', []));
  updateAdjustedValueForData(formattedDataTotal, vizOptions, 'total');
  const { 
    adjustFormatted, 
    negativeAndGapFormatted 
  } = getAdjustedDataAndNegativeData(formattedDataTotal, vizOptions);
  const optionConfig = _.merge({}, commonTraceOptions,
    { renderType: 'markers', markerSize: 15, isDiscreteMode: false }
  )

  if (!_.isEmpty(adjustFormatted) && primaryMetricRenderType != 'bar') {
    traces.push(toLineTrace(adjustFormatted, 'value', {
      meta: { ...totalMeta, prePareData: true},
      lineColor: '#3F51B5',
      lineDash: 'dot',
      visible: true,
      isSecondsFormat,
      dataUnit,
      isTransparent: true,
      periodType,
      ...optionConfig
    }))
  }

  if (!_.isEmpty(negativeAndGapFormatted) && primaryMetricRenderType != 'bar') {
    traces.push(toLineTrace(negativeAndGapFormatted, 'value', {
      meta: { ...totalMeta },
      lineColor: '#b50404',
      borderLineColor: getConfiguredMetricColor(templateId, 'primary'),
      lineDash: 'dot',
      visible: true,
      isSecondsFormat,
      dataUnit,
      isTransparent: true,
      periodType,
      ...optionConfig
    }))
  }

  _.each(formattedData.current.combo_metrics, (comboMetrics) => {
    _.each(comboMetrics, (dataItems, metricField) => {
      updateAdjustedValueForData(dataItems, vizOptions, metricField )
      const comboMetricConfig = _.find(comboMetricConfigs, { metricField }) || {};
      const dimension = _.get(comboMetricConfig, 'traceId');
      const metricEntry = _.get(comboMetricConfig, 'metricEntry');
      const renderType = _.get(metricEntry, 'render_type', 'line');
      if (renderType === 'bar') {
        return;
      }
      const {
        adjustFormatted: adjustFormattedCombo,
        negativeAndGapFormatted: negativeAndGapFormattedCombo
      } = getAdjustedDataAndNegativeData(dataItems, vizOptions);

      if (!_.isEmpty(adjustFormattedCombo)) {
        traces.push(toLineTrace(adjustFormattedCombo, 'value', {
          meta: { segmentType: 'current',
            metricConfig: comboMetricConfig, dimension,
            metricEntry, prePareData: true
          },
          lineColor: '#3F51B5',
          borderLineColor: _.get(comboMetricConfig, 'color'),
          lineDash: 'dot',
          visible: isTraceVisible(vizOptions, { traceId: dimension }),
          isSecondsFormat,
          dataUnit,
          isTransparent: true,
          customData: adjustFormattedCombo,
          periodType,
          ...optionConfig
        }))
      }

      if (!_.isEmpty(negativeAndGapFormattedCombo)) {
        traces.push(toLineTrace(negativeAndGapFormattedCombo, 'value', {
          meta: { segmentType: 'current', metricConfig: comboMetricConfig, dimension, metricEntry },
          lineColor: '#b50404',
          borderLineColor: _.get(comboMetricConfig, 'color'),
          lineDash: 'dot',
          visible: isTraceVisible(vizOptions, { traceId: dimension }),
          isSecondsFormat,
          dataUnit,
          isTransparent: true,
          periodType,
          ...optionConfig
        }))
      }
    });
  });
}

const getDummyXAxisForecast = (traces, formattedData, markerOptions) => {
  const { totalMeta, dataUnit, periodType,
    isSecondsFormat, commonTraceOptions } = markerOptions;
  _.each(formattedData.projection.forecastingProjections, (forecastProjection) => {
    const projection = _.get(forecastProjection, 'data', []);
    traces.push(toLineDummyTrace(projection, 'value', {
      meta: _.merge({}, totalMeta, { value: 'primary', dimension: null }),
      lineColor: '#3F51B5',
      lineDash: 'dot',
      visible: true,
      isSecondsFormat,
      dataUnit,
      periodType,
      ...commonTraceOptions
    }));
  });
}
