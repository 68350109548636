// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import { RADAR_MAP } from 'appConstants';
import { isChoroplethMap, getMapViews } from '../helpers/MapOptionsHelper';
import { getShapeIdsAndExtent } from '../helpers/mapHelper';
import { getCustomColorPalette,
  getChoroplethDataClasses,
  getChoroplethClassificationMethod,
  getChoroplethMidpoint,
  shouldSimplifyChoroplethLegends
} from 'common/config/templateConfiguration';

import { getColorByBucketsAndStops, getStops } from '../helpers/choroplethMapHelper';
import LoadingSpinner from 'common/components/LoadingSpinner';

export const SOURCES = {
  SHAPES: 'shapes-source'
};
export const LAYERS = {
  SHAPES_FILL: 'shapes-fill-layer',
  SHAPES_FILL_HIGHLIGHT: 'shapes-fill-highlight-layer',
  SHAPE_OUTLINE_HIGHLIGHT: 'shape-filter-outlines-highlight-choropleth-map'
};
const OPACITY = 0.5;

class ChoroplethMapPartial extends Component {
  state = {
    isLoading: false,
    colorByBuckets: [],
    stops: getStops([])
  };

  componentDidMount() {
    this.initSources();
    this.initLayers();
    this.initHandlers();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const {
      currentMapView, tileParams, selectedShapeIds, currentDrilldownTemplateId, currentDrilldownViewEntry
    } = this.props;
    const classificationMethod = getChoroplethClassificationMethod(
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    );
    const isShapeIdsChanged = !_.isEqual(prevProps.selectedShapeIds, selectedShapeIds) &&
      classificationMethod === 'linear';
    const isApiChanged = !_.isEqual(prevProps.tileParams, tileParams) && classificationMethod === 'linear';

    if (!_.isEqual(prevProps.shapeGroupId, this.props.shapeGroupId) || (isShapeIdsChanged || isApiChanged)){
      this.removeSourcesAndLayers();
      this.initSources();
      this.initLayers();
    }

    if (!_.isEqual(prevProps.tileParams, tileParams)){
      this.fetchData();
    }
    if (!_.isEqual(prevProps.currentMapView, currentMapView)){
      this.toggleVisibility(isChoroplethMap(currentMapView));
    }
  }

  componentWillUnmount() {
    const { map } = this.props;
    if(map){
      map.off('click', this.handleMouseClick);
    }
  }

  fetchData() {
    const {
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      onLegendsChange,
      map,
      selectedShapeIds,
      mouseInteractionHandler,
      tileParams,
      compareApiParams,
      shapeWiseDataApi,
      paletteName,
      numberOfDataClasses,
      legendMidPoint,
      mapClassificationMethod,
      mapDrawType,
      onUpdateMapLoadingStatus
    } = this.props;
    const customColorPalette = paletteName ||
      getCustomColorPalette(currentDrilldownTemplateId, currentDrilldownViewEntry);
    const classificationMethod = mapClassificationMethod ||
      getChoroplethClassificationMethod(currentDrilldownTemplateId, currentDrilldownViewEntry);
    const midpoint = legendMidPoint ||
      getChoroplethMidpoint(currentDrilldownTemplateId, currentDrilldownViewEntry);
    const dataClasses = numberOfDataClasses ||
      getChoroplethDataClasses(currentDrilldownTemplateId, currentDrilldownViewEntry);
    const newTileParams = _.merge({}, tileParams, { classificationMethod });
    const newCompareApiParams = _.merge({}, compareApiParams, { classificationMethod });

    try {
      const currentPeriodPromise = shapeWiseDataApi(newTileParams);
      let comparePeriodPromise;
      if(!_.isEmpty(compareApiParams)) {
        comparePeriodPromise = shapeWiseDataApi(newCompareApiParams);
      } else {
        comparePeriodPromise = Promise.resolve([]);
      }
      this.setState({ isLoading: true });
      onUpdateMapLoadingStatus(true);
      Promise.all([currentPeriodPromise, comparePeriodPromise]).then((results) => {
        const { shapeConfig, stops, colorByBuckets } = getColorByBucketsAndStops({
          data: results,
          viewEntry: currentDrilldownViewEntry,
          paletteName: customColorPalette,
          classificationMethod,
          dataClasses,
          midpoint,
          mapDrawType,
          shouldSimplifyLegend: shouldSimplifyChoroplethLegends(currentDrilldownTemplateId)
        });

        map.setPaintProperty(LAYERS.SHAPES_FILL, 'fill-color', stops);
        this.setState({ colorByBuckets, stops, isLoading: false });
        onUpdateMapLoadingStatus(false);
        onLegendsChange(colorByBuckets, getMapViews().choropleth.type);

        map.setPaintProperty(LAYERS.SHAPES_FILL, 'fill-color', stops);
        map.setPaintProperty(LAYERS.SHAPES_FILL_HIGHLIGHT, 'fill-color', stops);

        if (!_.isEmpty(selectedShapeIds) && mapDrawType != RADAR_MAP) {
          map.setFilter(LAYERS.SHAPES_FILL, ['all', ['in', 'shape_id'].concat(selectedShapeIds)]);
        } else {
          map.setFilter(LAYERS.SHAPES_FILL, ['all', ['!in', 'shape_id', '']]);
        }

        if (mouseInteractionHandler) {
          mouseInteractionHandler.updateChoroplethData(shapeConfig, currentDrilldownViewEntry);
        }
      }).catch((err) => {
        this.setState({ isLoading: false });
        onUpdateMapLoadingStatus(false);
        console.log('ChoroplethMap err', err); // eslint-disable-line no-console
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  toggleVisibility(shouldShow){
    const { map } = this.props;

    map.setLayoutProperty(LAYERS.SHAPES_FILL, 'visibility', shouldShow ? 'visible' : 'none');
    map.setLayoutProperty(LAYERS.SHAPES_FILL_HIGHLIGHT, 'visibility', shouldShow ? 'visible' : 'none');
    map.setLayoutProperty(LAYERS.SHAPE_OUTLINE_HIGHLIGHT, 'visibility', shouldShow ? 'visible' : 'none');
  }

  initSources() {
    const {
      map, tileParams, currentDrilldownTemplateId,
      currentDrilldownViewEntry, shapeTileUrl
    } = this.props;
    const classificationMethod = getChoroplethClassificationMethod(
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    );
    const newTileParams = _.merge({}, tileParams, { classificationMethod });

    map.addSource(SOURCES.SHAPES, {
      'type': 'vector',
      'geojsonTile': true,
      'tiles': [shapeTileUrl(newTileParams)]
    });
  }

  initLayers() {
    const { currentMapView, map } = this.props;

    map.addLayer({
      'id': LAYERS.SHAPES_FILL,
      'type': 'fill',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': isChoroplethMap(currentMapView) ? 'visible' : 'none'
      },
      'paint': {
        'fill-color': this.state.stops,
        'fill-opacity': OPACITY
      }
    });

    map.addLayer({
      'id': LAYERS.SHAPES_FILL_HIGHLIGHT,
      'type': 'fill',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': isChoroplethMap(currentMapView) ? 'visible' : 'none'
      },
      'paint': {
        'fill-color': this.state.stops,
        'fill-opacity': OPACITY
      }
    });

    map.addLayer({
      'id': LAYERS.SHAPE_OUTLINE_HIGHLIGHT,
      'type': 'line',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': isChoroplethMap(currentMapView) ? 'visible' : 'none'
      },
      'paint': {
        'line-color': {
          'type': 'identity',
          'property': 'outline_highlight_color'
        },
        'line-width': 2
      },
      'filter': ['==', 'shape_id', '__custom_shape_id']
    });
  }

  initHandlers() {
    const { map } = this.props;
    if(map){
      map.on('click', this.handleMouseClick);
    }
  }

  handleMouseClick = (event) => {
    const { map, isDrawingEnabled } = this.props;

    const features = map.queryRenderedFeatures(event.point, {
      layers: [LAYERS.SHAPES_FILL]
    });

    if (_.isEmpty(features) || isDrawingEnabled) {
      return;
    }

    const shapeId = _.get(features, '[0].properties.shape_id');
    this.handleShapeIdSelect(shapeId);
  }

  handleShapeIdSelect(shapeId) {
    const { map, selectedShapeIds } = this.props;
    const { shapeIds, shapesExtent } = getShapeIdsAndExtent(map, selectedShapeIds, shapeId, SOURCES.SHAPES);

    this.props.toggleShapeIdsFilter(shapeIds, shapesExtent);
  }

  removeSourcesAndLayers() {
    const { map } = this.props;

    _.each(LAYERS, (layerId) => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });

    if (map.getSource(SOURCES.SHAPES)) {
      map.removeSource(SOURCES.SHAPES);
    }
  }

  render() {
    const { map, selectedShapeIds, mapDrawType, isComparisonMap } = this.props;
    if(mapDrawType == RADAR_MAP && map.getLayer(LAYERS.SHAPES_FILL)) {
      const opacity = !_.isEmpty(selectedShapeIds) ? (OPACITY / 3) : OPACITY;
      map.setPaintProperty(LAYERS.SHAPES_FILL, 'fill-opacity', opacity);
      if(!_.isEmpty(selectedShapeIds)) {
        map.setFilter(LAYERS.SHAPES_FILL_HIGHLIGHT, ['all', ['in', 'shape_id'].concat(selectedShapeIds)]);
        map.setPaintProperty(LAYERS.SHAPES_FILL_HIGHLIGHT, 'fill-color', this.state.stops);
      }
    }
    return !isComparisonMap && (<LoadingSpinner isLoading={this.state.isLoading} />);
  }
}

ChoroplethMapPartial.defaultProps = {
  isComparisonMap: false,
  onUpdateMapLoadingStatus: _.noop
};

ChoroplethMapPartial.propTypes = {
  tileParams: PropTypes.object,
  onLegendsChange: PropTypes.func,
  currentDrilldownViewEntry: PropTypes.object,
  map: PropTypes.object,
  currentMapView: PropTypes.object,
  shapeGroupId: PropTypes.string,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mouseInteractionHandler: PropTypes.object,
  compareApiParams: PropTypes.object,
  selectedShapeIds: PropTypes.array,
  isDrawingEnabled: PropTypes.bool,
  toggleShapeIdsFilter: PropTypes.func,
  shapeTileUrl: PropTypes.func,
  shapeWiseDataApi: PropTypes.func,
  paletteName: PropTypes.string,
  legendMidPoint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mapClassificationMethod: PropTypes.string,
  mapDrawType: PropTypes.string,
  numberOfDataClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isComparisonMap: PropTypes.bool,
  onUpdateMapLoadingStatus: PropTypes.func
};

export default ChoroplethMapPartial;
