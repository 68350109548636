import React, { useEffect } from 'react';
import {
  getReadMoreContent,
  customReadMoreLabel,
  getSupportLink
} from 'common/config/customerConfiguration';
import { handleBackButtonClick } from 'helpers/visualizationHelper';
import { useHistory, useLocation } from 'react-router-dom';

const ReadMoreContent = () => {

  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line
  let readMoreContent = DOMPurify.sanitize(getReadMoreContent, {ADD_TAGS: ['IFRAME']})
  const supportLink = getSupportLink() ? getSupportLink() : 'javascript:void(0)';
  readMoreContent = readMoreContent.replace('{LINK_TO_SUPPORT}', supportLink);

  useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const id = queryParams.get('scroll');
      if (_.isEmpty(id)){
        return;
      }
      var element = document.querySelectorAll(`[data-uid=${id}]`)[0];
      if (!_.isNull(element)) {
        element.scrollIntoView({ behavior: "smooth", block: "center"  });
      }
  }, [location.search]);

  return (
    <main className="read-more-page">
      <div className="page-main-head">
        <button
          aria-label="Back To Overview"
          className="btn back-arrow-icon back-btn-light"
          onClick={() => handleBackButtonClick(history)}>
            <i className="icons-arrow-left2" />
        </button>
        <div className="tx-15">{customReadMoreLabel}</div>
      </div>
      <div className="container about-page">
        <h2>{customReadMoreLabel}</h2>
        <div className="col-md-12">
          <div dangerouslySetInnerHTML={{__html : readMoreContent}} />
        </div>
      </div>
    </main>
  );
}

export default ReadMoreContent;
