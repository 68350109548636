import { useState } from 'react';
import { buildQueryString } from 'helpers/HttpHelper';

export const useCheckExternalImage = () => {
  const [photoState, setPhotoState] = useState({});

  const checkExternalImage = async (datum, detailIndex) => {
    setPhotoState(prevState => ({
      ...prevState,
      [detailIndex]: { isLoading: true, isPhotoAvailable: false }
    }));
    const queryParams = {
      country: _.get(datum, 'comp_finder_country', '').replace(/County/i, "").trim(),
      quickRefId: _.get(datum, 'comp_finder_quick_ref_id', ''),
      taxYear: _.get(datum, 'comp_finder_tax_year', '')
    };
    const url = `/api/advanced_search/check_external_image.json?${buildQueryString(queryParams)}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        setPhotoState(prevState => ({
          ...prevState,
          [detailIndex]: { isLoading: false, isPhotoAvailable: false }
        }));
        return;
      }
      const data = await response.json();

      setPhotoState(prevState => ({
        ...prevState,
        [detailIndex]: { isLoading: false, isPhotoAvailable: data.result?.IsPhotoAvailable }
      }));

    } catch (error) {
      setPhotoState(prevState => ({
        ...prevState,
        [detailIndex]: { isLoading: false, isPhotoAvailable: false }
      }));
    }
  };
  return { photoState, checkExternalImage };
};
