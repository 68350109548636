// Vendor imports.
import _ from 'lodash';

// Project Imports
import { formatBarChartData } from './barChartDataFormatter';
import { formatScatterChartData } from './scatterChartDataFormatter';
import { formatGroupByScatterChartData } from './groupByScatterChartDataFormatter';
import { formatPieChartData } from './pieChartDataFormatter';
import { isScatterChart } from '../plotlyScatterChartHelper';
import { isBarchart } from '../barChartHelper';
import { getGroupByEntries } from 'common/config/templateConfiguration';

export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    return (
      !_.isEqual(_.get(vizOptions,'viewEntry'), _.get(previousVizOptions, 'viewEntry')) ||
      !_.isEqual(_.get(vizOptions,'benchMarkEntries'), _.get(previousVizOptions, 'benchMarkEntries')) ||
      !_.isEqual(_.get(vizOptions,'groupByViewType'), _.get(previousVizOptions, 'groupByViewType')) ||
      !_.isEqual(
        _.get(vizOptions,'secondaryMetricEntry'),
        _.get(previousVizOptions, 'secondaryMetricEntry')
      ) ||
      !_.isEqual(_.get(vizOptions,'showRange'), _.get(previousVizOptions, 'showRange')) ||
      !_.isEqual(_.get(vizOptions,'dimensionSortType'), _.get(previousVizOptions, 'dimensionSortType')) ||
      !_.isEqual(_.get(vizOptions,'drillDownTotal'), _.get(previousVizOptions, 'drillDownTotal')) ||
      !_.isEqual(_.get(vizOptions,'showChartValues'), _.get(previousVizOptions, 'showChartValues')) ||
      !_.isEqual(
        _.get(vizOptions,'pieChartAnnotationOptions'),
        _.get(previousVizOptions,'pieChartAnnotationOptions')
      )
    );
  },
  formatData: (vizOptions, apiData, isUpdateApiCall) => {
    const currentSnapshotView = _.get(vizOptions, 'currentSnapshotView');
    const templateId = _.get(vizOptions, 'templateId');

    if(isBarchart(currentSnapshotView)) {
      return formatBarChartData(vizOptions, apiData, isUpdateApiCall);
    } else if (isScatterChart(currentSnapshotView)) {
      const groupByEntries = getGroupByEntries(templateId);
      if(_.isEmpty(_.get(vizOptions,'groupByViewType')) || _.isEmpty(groupByEntries)) {
        return formatScatterChartData(vizOptions, apiData, isUpdateApiCall);
      } else {
        return formatGroupByScatterChartData(vizOptions, apiData, isUpdateApiCall);
      }
    } else {
      return formatPieChartData(vizOptions, apiData, isUpdateApiCall);
    }
  }
};
