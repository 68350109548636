import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

const SearchDetailsSecondaryTable = (props) => {
  const  {
    tableHeaders,
    tableData,
    onClickSecondaryTableSortedHeader,
    secondarySortColumns
  } = props

  const setWidth = (index) => {
    // Both tables Need to be aligned exactly.
    const thead = $('#subject-table thead th');
    if( _.isEmpty(thead) ||  _.isEmpty(thead[index])){
      return false;
    }
    const boundingRect =  thead[index].getBoundingClientRect();
    return boundingRect.width;
  };

  const onClickTableHeader = (entry) => {
    let newSortColumns = [];
    const sortBy = _.find(secondarySortColumns, (column) => { return entry.column === column.columnName });

    if (_.isEmpty(sortBy)) {
      // On first click Added
      newSortColumns.push({
        columnName: entry.column,
        ascending: true
      });
    } else if (!_.isEmpty(sortBy) && sortBy.ascending ) {
      // On second click Set to descending
      newSortColumns.push({
        columnName: entry.column,
        ascending: false
      });
    }
    onClickSecondaryTableSortedHeader(newSortColumns);
  };

  const renderHeaderSortIcon = (entry) => {
    const sortBy = _.find(secondarySortColumns, (column) => { return entry.column === column.columnName });
    if (_.isEmpty(sortBy)) {
      return null;
    }
    const iconName = _.get(sortBy, 'ascending', true) ? "icons-arrow-up2" : "icons-arrow-down2";

    return (
      <span className="column-icon align-self-center"> <i className={iconName}></i></span>
    );
  };

  const renderTableHeaderWithoutSort = () => {
    return(
      <thead>
        <tr>
           {
           tableHeaders.map((entry, index) =>
            <th style={{ Width: setWidth(index + 1) }} key={index} 
              className='forge-typography--subtitle2'>
              <div className='entry-name'>{entry.name}</div>
            </th>
          )}
        </tr>
      </thead>
    );
  };

  const renderTableHeaderWithSort = () => {
    return(
      <thead>
        <tr>
          {
            tableHeaders.map((entry, index) => {
              return (
                // Added '1' to index , There is one empty column added before the Headers.
                <th style={{ Width: setWidth(index + 1) }}
                  onClick={() => onClickTableHeader(entry)}
                  key={index} className='forge-typography--subtitle2'>
                  <div className='d-flex'>
                    {entry.name}
                    {renderHeaderSortIcon(entry)}
                  </div>
                </th>
              )
            })
          }
        </tr>
      </thead>
    );
  };

  const renderTableHeader = () => {
    if (_.isNil(secondarySortColumns)) {
      return renderTableHeaderWithoutSort();
    } else {
      return renderTableHeaderWithSort();
    }
  };

  const renderTableRow = (tableDatum) => {
    return(
      tableHeaders.map((entry) => {
        const field = entry.field;
        return (
          <td key={field}><div className='tabledatum-field'> {tableDatum[field]} </div></td>
        );
      })
    )
  }

  const renderTable = () => {
    return(
      <table className='table secondary-table mb-0'>
        {renderTableHeader()}
        <tbody>
          { tableData.map((tableDatum, index) => {
            return (
              <tr key={index}>{renderTableRow(tableDatum)}</tr>
            );
          })
          }
        </tbody>
      </table>
    )
  }

  return renderTable();
}

SearchDetailsSecondaryTable.propTypes = {
  tableHeaders: PropTypes.array,
  tableData: PropTypes.array,
  secondarySortColumns: PropTypes.array,
  onClickSecondaryTableSortedHeader: PropTypes.func 
};

SearchDetailsSecondaryTable.defaultPropTypes = {
  tableHeaders: [],
  tableData: [],
  secondarySortColumns: []
};

export default SearchDetailsSecondaryTable;
