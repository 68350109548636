import _ from 'lodash';
import { UPDATE_METRIC_TOTALS } from 'actions/metricTotalActions';

export const initialState = () => {
  return {
    isLoading: false,
    totals: {
      currentPeriodMetricTotals: {},
      comparisonDatesMetricTotals: {}
    }
  };
};

export default function(state = initialState(), action) {
  switch (action.type) {
    case UPDATE_METRIC_TOTALS:
      return {
        ...state,
        isLoading: _.get(action, 'isLoading'),
        totals: _.get(action, 'totals')
      }

    default:
      return state;
  }
}
