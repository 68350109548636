import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { datePropTypes } from 'common/propTypes';
import { ForgeDatePicker, ForgeTextField } from '@tylertech/forge-react';

const ForgeDateInputPicker = (props) => {
  var _isOpenPicker = false;

  const {
    minDate,
    maxDate,
    value,
    isDisabled,
    label,
    handleChangeDate,
    maskFormat,
    showLabel
  } = props;
  const datePickerRef = useRef();

  const handleChangeSelectDate = (date) => {
    if (!_isOpenPicker) {
      return
    }

    const selectedDate = _.get(date, 'detail');
    if (!_.isNull(selectedDate) && _isOpenPicker) {
      handleChangeDate(selectedDate);
    }
  }

  const handleOpenDatePicker = () => {
    _isOpenPicker = true;
  }
  

  const validDate =(stringDate) =>{
    var pattern =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
    return pattern.test(stringDate);
  }

  const handleInputPicker = (e) => {
    _isOpenPicker = false;
    const targetId = _.get(e, 'target.id')
    if (targetId == 'input-date-picker') {
      const selectedDate = _.get(e, 'target.value')
      if(validDate(selectedDate)){
        handleChangeDate(selectedDate)
      }
    }
  }

  const open = false,
    masked = false,
    showMaskFormat = false,
    allowInvalidDate = false,
    showToday = true,
    showClear = false,
    disabledDaysOfWeek = [];

  return (
    <div ref={datePickerRef}>
      <ForgeDatePicker
        disabled={isDisabled}
        value={value}
        min={minDate}
        max={maxDate ? maxDate : null}
        open={open}
        masked={masked}
        maskFormat={maskFormat}
        showMaskFormat={showMaskFormat}
        allowInvalidDate={allowInvalidDate}
        showToday={showToday}
        showClear={showClear}
        disabledDaysOfWeek={disabledDaysOfWeek}
        on-forge-date-picker-open={handleOpenDatePicker}
        on-forge-date-picker-change={handleChangeSelectDate}
        // on-forge-date-picker-close={handleCloseDatePicker} 
        >
        <ForgeTextField>
          <input type="text" aria-label="Starting Date" id="input-date-picker" onChange={handleInputPicker} />
          {showLabel && <label htmlFor="input-date-picker">{label}</label>}
        </ForgeTextField>
      </ForgeDatePicker>
    </div>
  );
}

ForgeDateInputPicker.propTypes = {
  minDate: datePropTypes,
  maxDate: datePropTypes,
  value: datePropTypes,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  handleChangeDate: PropTypes.func,
  maskFormat: PropTypes.string,
  showLabel: PropTypes.bool,
}

ForgeDateInputPicker.defaultProps = {
  isDisabled: false,
  showLabel: false,
  maskFormat: 'MM/DD/YYYY',
  handleChangeDate: _.noop,
}

export default (ForgeDateInputPicker);
