// Vendor Imports
import _ from 'lodash';
import * as ss from 'simple-statistics';
import chroma from "chroma-js";

// Project Imports
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import {
  CUSTOM_COLOR_PALETTE,
  CUSTOM_COLOR_PALETTE_FOR_MAP,
  RADAR_MAP,
  TYLERBLUE_COLOR_PALETTE,
  ORANGE900_COLOR_PALETTE
} from 'appConstants';
import { DEFAULT_CHOROPLETH_CLASSIFICATION_METHOD } from 'modules/visualization/constants';

export const DEFAULT_COLOR = '#FAF0E6';
const EMPTY_BUCKET_VALUE = '__empty_value__';
const EMPTY_TRANSPARENT_COLOR = 'transparent';
export const DEFAULT_LEGEND_COLORS = [
  '#FFD4BF', '#FFB69D', '#FF9880', '#FF7469', '#F64F5C',
  '#E23051', '#C81640', '#AA0427', '#8B0000', '#5F0202'
];
const PRECISION = { MIN: 2, MAX: 7 };

export const getColorByBucketsAndStops = (options) => {
  const { data, classificationMethod, mapDrawType } = options;
  if (classificationMethod === DEFAULT_CHOROPLETH_CLASSIFICATION_METHOD ||
      mapDrawType == RADAR_MAP) {
    const currentMeasures = getMeasures(data[0]);
    const compareMeasures = getMeasures(data[1]);
    const measures = [...currentMeasures, ...compareMeasures];
    const colorByBuckets = measuresToColorByBuckets({ measures, ...options });
    const shapeColorConfigs = getShapeColorConfigs(colorByBuckets, currentMeasures);
    const stops = getStops(shapeColorConfigs);

    return { shapeConfig: { valueConfigs: currentMeasures, isJenks: true }, colorByBuckets, stops };
  } else {
    const measures = _.chain(data).
      map((datum) => {
        const minValue = _.get(datum, '0.min_value');
        const maxValue = _.get(datum, '0.max_value');
        return [{ value: Number(maxValue), maxValue },{ value: Number(minValue), minValue }];
      }).
      flatten().
      value();
    const colorByBuckets = measuresToColorByBuckets({ measures, ...options });
    const stops = getStopsForLinear(colorByBuckets);
    return { shapeConfig: { valueConfigs: colorByBuckets, isJenks: false }, colorByBuckets, stops };
  }
}

const getMeasures = (choroplethMapData) => {
  const measures = _.chain(choroplethMapData).
    map((choroplethMapDatum) => {
      const shapeId = _.get(choroplethMapDatum, 'shape_id');
      const measureValue = _.get(choroplethMapDatum, 'count');

      if (_.isUndefined(shapeId)) {
        return shapeId;
      }
      return _.merge(
        {
          shapeId,
          value: (_.isNil(measureValue) || measureValue === '') ? null : Number(measureValue)
        },
        choroplethMapDatum
      );
    }).
    compact().value();

  if (_.isEmpty(measures)) {
    return [];
  }

  return measures;
};

const measuresToColorByBuckets = ({
  measures,
  viewEntry,
  paletteName = null,
  classificationMethod,
  dataClasses,
  midpoint,
  shouldSimplifyLegend
}) => {
  const values = _.chain(measures).
      map('value').
      uniq().
      without(undefined, null).
      value();

  if (_.isEmpty(values)) {
    return [];
  }

  // If there is only one value, we cannot create buckets,
  // so adding 0, 1 to existing value, so that we can get
  // one or more buckets.
  const sanitizedValues = (values.length === 1) ? values.concat([0, 1]) : values;
  const insufficientValuesToUseJenks = values.length < dataClasses;
  const palette = _.isEmpty(paletteName) || paletteName === 'default' ?
    DEFAULT_LEGEND_COLORS : getColorPalette(paletteName, dataClasses);
  let bucketRanges;
  const isJenksRange = (
    classificationMethod === DEFAULT_CHOROPLETH_CLASSIFICATION_METHOD &&
    !insufficientValuesToUseJenks
  );
  if (isJenksRange) {
    const intervalGroups = ss.ckmeans(values, dataClasses);
    bucketRanges = _.map(intervalGroups, (intervalGroup) => {
      return { start: _.head(intervalGroup), end: _.last(intervalGroup) };
    });
  } else {
    // if required number of buckets is 7(meaning, we want 8 stops) and we have only 8 unique values
    // then jenks will have undefineds in the stops. So using jenks only when there are more than
    // (bucket_count + 1) uniq values.
    const intervals = getLinearBuckets(
      _.min(sanitizedValues),
      _.max(sanitizedValues),
      dataClasses,
      midpoint
    );
    bucketRanges = _.chain(0).
      range(intervals.length - 1).
      map((index) => {
        return { start: intervals[index], end: intervals[index + 1] };
      }).
      value();
  }

  return _.chain(bucketRanges).
    map((bucketRange, index) => {
      let start = bucketRange.start;
      let end = bucketRange.end;
      if(shouldSimplifyLegend) {
        // round of logic is implement based on https://socrata.atlassian.net/browse/STB-5176
        const nextStart = _.get(bucketRanges, `[${index + 1}].start`, 0);
        const prevEnd = _.get(bucketRanges, `[${index - 1}].end`, 0)
        if(!_.isEqual(start, end)){
          let endRange = roundOfNumber(end, 'ceil', nextStart);
          const endMagnitude = _.get(endRange, 'magnitude', 0);
          let startRange = roundOfNumber(
            start, 'round',
            isJenksRange ? 0 : prevEnd,
            index == 0 ? endMagnitude : null);
          start = _.get(startRange, 'roundedNumber', start);
          end = _.get(endRange, 'roundedNumber', end);
        }
      }
      const label = getRangeLabel(start, end, viewEntry);

      return {
        start,
        end,
        label,
        color: palette[index] || DEFAULT_LEGEND_COLORS[index]
      };
    }).
    value();
};

const getShapeColorConfigs = (buckets, measures) => {
  const defaultBucket = _.last(buckets);
  const shapeColorConfigs = _.map(measures, (measure) => {
    if (_.isNil(measure.value)) {
      return { shapeId: measure.shapeId, color: EMPTY_TRANSPARENT_COLOR };
    }
    const matchingBucket = _.find(buckets, (bucket) => {
      return measure.value >= bucket.start && measure.value <= bucket.end;
    });
    const colorForShape = _.get((matchingBucket || defaultBucket), 'color');

    return { shapeId: measure.shapeId, color: colorForShape };
  });

  return shapeColorConfigs;
};

export const getStops = (shapeColorConfigs) => {
  let paintProperty = ['match', ['to-string', ['get', 'shape_id']]];

  if(_.isEmpty(shapeColorConfigs)) {
    paintProperty.push(EMPTY_BUCKET_VALUE, EMPTY_TRANSPARENT_COLOR);
  } else {
    _.each(shapeColorConfigs, (shapeColorConfig) => {
      paintProperty.push(shapeColorConfig.shapeId, shapeColorConfig.color);
    });
  }
  paintProperty.push(EMPTY_TRANSPARENT_COLOR);

  return paintProperty;
};

const getStopsForLinear = (colorByBuckets) => {
  let paintSteps = ['step', ['to-number', ['get', 'count'], -1, -1]];

  paintSteps.push(EMPTY_TRANSPARENT_COLOR);

  _.each(colorByBuckets, (colorByBucket) => {
    paintSteps.push(colorByBucket.start, colorByBucket.color);
  })

  return paintSteps;
};

export const getLinearBuckets = (min, max, numberOfBuckets, midpoint) => {
  if (midpoint && !isNaN(midpoint)) {
    return getMidpointRangeBuckets({ min, max, dataClasses: numberOfBuckets, midpoint: Number(midpoint) });
  }

  [min, max] = getMinAndMaxValue([min, max]);

  let rangeDiff = Math.abs(max - min);
  if (rangeDiff < numberOfBuckets) {
    min = 0;
    max = Math.max(numberOfBuckets, max);
    rangeDiff = max;
  }
  let bucketSize = rangeDiff / numberOfBuckets;
  const roundOffValue = roundOffDecimalDigits(bucketSize, PRECISION.MIN);
  bucketSize = roundOffValue === 0 ? bucketSize : roundOffValue;

  const intermediateValues = _.map(_.range(1, numberOfBuckets), (bucketIndex) => {
    return Math.floor(min + (bucketIndex * bucketSize));
  });

  return [min, ...intermediateValues, max];
};

function getMinAndMaxValue(values) {
  let min = _.min(values);
  let max = _.max(values);

  if (min === max) {
    if (min === 0 && max === 0) {
      return [0, 1];
    }

    return max > 0 ? [0, max] : [min, 0];
  }

  return [min, max];
}

function getMidpointRangeBuckets({ min,max, midpoint, dataClasses }){
  const newRange = Math.max((max - midpoint), (midpoint - min));
  const bucketSize = (newRange * 2) / dataClasses;
  const start = midpoint - newRange;
  const end = midpoint + newRange;

  const intermediateValues = _.map(_.range(1, dataClasses), (bucketIndex) => {
    return start + (bucketIndex * bucketSize);
  });

  return [start, ...intermediateValues, end];
}

function roundOffDecimalDigits(value, precision) {
  return parseFloat(value.toFixed(precision));
}

function getRangeLabel(start, end, viewEntry) {
  const startLabel = getFormattedNumberValue(start, viewEntry);

  return (start !== end) ?
    `${startLabel} to ` + `${getFormattedNumberValue(end, viewEntry)}` :
    `${startLabel}`;
}

function getColorPalette(paletteName, dataClasses) {
  if(paletteName == 'radar'){
    return getRadarColorPalette(dataClasses);
  }else if(_.includes(['sequential5', 'sequential6'], paletteName)) {
    const colorPalettes = _.get(CUSTOM_COLOR_PALETTE_FOR_MAP, paletteName);
    return _.get(colorPalettes, dataClasses);
  }else if(paletteName == 'sequential7') {
    return chroma.scale([ORANGE900_COLOR_PALETTE,'white',TYLERBLUE_COLOR_PALETTE]).colors(dataClasses);
  }else if(paletteName == 'sequential8') {
    return chroma.scale([TYLERBLUE_COLOR_PALETTE,'white',ORANGE900_COLOR_PALETTE]).colors(dataClasses);
  } else {
    return _.get(CUSTOM_COLOR_PALETTE, paletteName);
  }
}

export const getRadarColorPalette = (dataClasses) => {
  return chroma.scale([TYLERBLUE_COLOR_PALETTE,'white', ORANGE900_COLOR_PALETTE]).colors(dataClasses);
}

// Covert 1234 => 1200
// 56789 => 50000
const roundOfNumber = (number, roundType, nextStart, defaultMagnitude = null) => {

    if(number == nextStart){
      roundType = 'ceil';
    }
    if (typeof number === 'string') {
      number = parseFloat(number.replace(/,/g, ''));
    }

    if (number >= 0 && number < 100){
      return number;
    }

    // Determine the magnitude of the number (order of magnitude)
    let magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(number))))
    if(magnitude > 10){
      magnitude = defaultMagnitude ? defaultMagnitude : magnitude/10;
    }

    // Round the number to the nearest lower magnitude value
    let roundedNumber;
    if(roundType == 'ceil'){
      roundedNumber = Math.ceil(number / (magnitude)) * (magnitude);
      var nextStartFloor = Math.ceil(parseFloat(nextStart) / magnitude) * magnitude;
      if(roundedNumber > nextStartFloor && nextStart != 0){
        if (magnitude >= 100){
          magnitude = magnitude/10;
          roundedNumber = Math.ceil(number / magnitude) * magnitude;
        }else {
          roundedNumber = number;
        }
      }
    } else {
      roundedNumber = Math.floor(number / magnitude) * magnitude;
    }

    return {roundedNumber, magnitude};
}