import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeatChart from './HeatChart';
import SlaLoadingSpinner from '../SlaLoadingSpinner';
class HeatChartContainer extends Component {
  renderChart() {
    const { isLoading, selectedListItem, selectedDimension, dimensionData } = this.props;

    if (isLoading) {
      return null;
    }

    return (
      <HeatChart
        selectedDimension={selectedDimension}
        selectedListItem={selectedListItem}
        dimensionData={dimensionData}>
      </HeatChart>
    );
  }

  renderLoadingInfo = () => {
    if (!this.props.isLoading) {
      return null;
    }

    return (
      <div className='ml-10'>
        <SlaLoadingSpinner isLoading={this.props.isLoading} />
      </div>
    )
  }

  render() {
    return (
      <div className="hear-chart-container">
        {this.renderLoadingInfo()}
        {this.renderChart()}
      </div>
    )
  }
}

HeatChartContainer.propTypes = {
  dimensionData: PropTypes.array,
  selectedDimension: PropTypes.object,
  selectedListItem: PropTypes.object,
  isLoading: PropTypes.bool
};

export default HeatChartContainer;
