// Vendor Imports
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';

// Project Imports
import { isPrivate  } from 'common/config/customerConfiguration';
import { isEnterButtonPressed, isSpaceButtonPressed } from 'helpers/mouseEventsHelper';
import InputTag from 'common/components/InputTag/InputTag';

const PLACEHOLDER_TEXT = "Enter an email address. You can include multiple email addresses.";

const EmailInputField = (props) => {
  const {
    emails, onInputChange, placeholder, isMultiple, eventName, userEmails, openOnFocus, autoFocus
  } = props;
  const splittedEmails = _.isArray(emails) ? emails : emails.split(';');
  const selectedEmails = _.isEmpty(emails) ? [] : splittedEmails;
  const newPlaceholder = _.isEmpty(selectedEmails) ? placeholder || PLACEHOLDER_TEXT : '';
  const typeaheadRef = useRef();
  const [openInputList, setOpenInputList] = useState(false);

  const handleInputChange = (newEmails) => {
    let e = { target: { value: newEmails.join(';') } };
    if (isMultiple) {
      onInputChange(e, eventName);
    } else {
      onInputChange(eventName, { target: { value: newEmails } });
    }
  }

  const dispatchInputChange = (event) => {
    const value = event.target.value;
    const splitValues = splitEventValues(value);
    const firstValue = _.first(splitValues);
    if(firstValue) {
      const newEmails = selectedEmails.concat(firstValue);
      const e = { target: { value: newEmails.join(';') } };

      if (isMultiple) {
        onInputChange(e, eventName);
      } else {
        onInputChange(eventName, { target: { value: newEmails } });
      }
      typeaheadRef.current.clear();
    }
  }

  const splitEventValues = (eventValue) => {
    const value = _.trimStart(eventValue);
    return value.split(/[ ,]+/);
  }

  const onTypeaheadInputChange = (event) => {
    const value = event.target.value;
    const splitValues = splitEventValues(value);
    if (isEnterButtonPressed(event) || isSpaceButtonPressed(event) || _.size(splitValues) > 1) {
      dispatchInputChange(event);
      setOpenInputList(false);
    }else{
      if (!openOnFocus){ setOpenInputList(true);}
    }
  }

  const renderPublicEmailField = () => {
    return (
      <>
        <InputTag onChange={handleInputChange} tags={selectedEmails} placeholder={newPlaceholder} />
        <i className="icons icons-exclamation-triangle" />
      </>
    );
  }

  const renderPrivateEmailField = () => {
    let autoFocusAttributes = {};
    if (autoFocus) {
      autoFocusAttributes ={
        autoFocus: true,
        open: openOnFocus || openInputList
      }
    }
    return (
      <Typeahead
        {...autoFocusAttributes}
        id="private-email-field"
        multiple={true}
        onChange={handleInputChange}
        options={userEmails}
        placeholder={newPlaceholder}
        selected={selectedEmails}
        ref={typeaheadRef}
        onKeyDown={onTypeaheadInputChange}
        onBlur={dispatchInputChange}
      />
    );
  }

  return isPrivate() ? renderPrivateEmailField() : renderPublicEmailField();
}

EmailInputField.propTypes = {
  emailInputs: PropTypes.string,
  placeholder: PropTypes.string,
  isMultiple: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  eventName: PropTypes.string,
  userEmails: PropTypes.array,
  onInputChange: PropTypes.func,
  autoFocus: PropTypes.bool,
}

EmailInputField.defaultProps = {
  emailInputs: '',
  placeholder: '',
  isMultiple: true,
  openOnFocus: true,
  eventName: '',
  userEmails: [],
  onInputChange: _.noop,
  autoFocus: true
}

export default EmailInputField;
