import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { formatValueToCurrency } from 'helpers/numberHelper';
import MenuDropDownSelector from 'common/components/MenuDropDown/MenuDropDownSelector';
import { DEFAULT_BENCHMARK_COLOR } from 'appConstants';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { isMobileOrTablet } from 'helpers/DomPageHelper';
import ColorBox from 'common/contentFormatter/ColorBox';

function SnapshotLegends(props) {
  const { legendOption } = props;
  const {
    toggleClickLegendButton, legends, dimensionName,
    isPieChart, isBulletBarChart, isBarChart, isStackBarChart,
    isRangeChart, isCurrencyDimensionField, benchMarkEntries,
    fromMobileView, isOpenLegend
  } = legendOption;

  const renderLegendItem = (legendOption, index) => {
    const { color, label } = legendOption;
    return (
      <div className="snap-shot-legend-item d-flex gap-3" key={index}>
        <ColorBox boxColor={color} />
        <span className="legend-label">{formatValueToCurrency(label, isCurrencyDimensionField)}</span>
      </div>
    );
  }

  const renderLegends = () => {
    const isNormalBarChart = isBarChart && !isStackBarChart;
    if (_.isEmpty(legends) || isBulletBarChart || isNormalBarChart || isRangeChart) {
      return null;
    }
    const renderLegendItems = _.map(legends, renderLegendItem);
    const legendTitle = isPieChart ?
      <div className="legend-inner-title forge-typography--body2">{dimensionName}</div> : null;
    return (
      <div>
        {legendTitle}
        {renderLegendItems}
      </div>
    );
  }

  const renderBulletOrParallelLegendItem = () => {
    const isNormalBarChart = isBarChart && !isStackBarChart;

    if (_.isEmpty(legends) || (!isBulletBarChart && !isNormalBarChart && !isRangeChart)) {
      return null;
    }
    return _.map(legends, renderLegendItem);
  }

  const renderBenchmarkLegendItems = () => {
    return _.map(benchMarkEntries, (benchmark, index) => {
      return renderBenchmarkLegendItem(benchmark, index)
    });
  }

  const renderBenchmarkLegendItem = (benchmark, index) => {
    const benchmarkName = _.get(benchmark, 'name', '');
    const colorCode = _.get(benchmark, 'color', DEFAULT_BENCHMARK_COLOR) || DEFAULT_BENCHMARK_COLOR;
    const lineType = _.get(benchmark, 'lineType', 'dot');
    // TODO: change the renderLogic(repeated codes)

    const benchmarkLegendLines = [
      { lineType: 'dot', lineCode: '&#8943;&#8943;' },
      { lineType: 'dashdot', lineCode: '&#8722; &#8729; &#8722;' },
      { lineType: 'longdash', lineCode: '&#9472; &#9472;' },
      { lineType: '', lineCode: '&#9472;&#9472;', className: 'longline' },
      { lineType: 'dash', lineCode: '&#8722; &#8722; &#8722;' },
    ]

    const legendCode = _.find(benchmarkLegendLines, { lineType: lineType })
    const legendClass = _.isEmpty(legendCode) ? 'longline' : legendCode?.lineType || legendCode?.className;
    const lineClassName = `line-style ${legendClass}`;

    return (
      <div key={index}>

        <div className="legend-info-details previous-year secondary-text">
          <span className={lineClassName} style={{ color: `${colorCode}` }}
            dangerouslySetInnerHTML={{ __html: legendCode.lineCode }}></span>
          <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
        </div>
      </div>
    );
  }

  const renderBenchmarkLegends = () => {
    const isNormalBarChart = isBarChart && !isStackBarChart;
    if (_.isEmpty(benchMarkEntries) && !isBulletBarChart && !isNormalBarChart && !isRangeChart) {
      return;
    }

    return (
      <div className="legend-details">
        <div className="legend-info-inner filter-wrapper">
          <div className='filter-wrapper'>
            {renderBenchmarkLegendItems()}
          </div>
          {renderBulletOrParallelLegendItem()}
        </div>
      </div>
    );
  }

  const onButtonClick = () => {
    toggleClickLegendButton(!isOpenLegend);
  }

  const renderButtonMobileView = () => {
    if (fromMobileView) {
      return null
    }

    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          className=""
          onKeyDown={() => onButtonClick()}
          onClick={() => onButtonClick()}>
          <ForgeIcon name="category" />
        </button>
      </ForgeButton>
    );
  }

  const renderMobileView = (renderBenchmarkLegend, categoryRender) => {

    if (!isMobileOrTablet() || !isOpenLegend || !fromMobileView) {
      return null
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
        className="" leadingIconName="category" toggleClickLegendButton={toggleClickLegendButton}>
        <div className="legend-wrapper snap-shot-legend-container">
          {renderBenchmarkLegend}
          {categoryRender}
        </div>
      </MenuDropDownSelector>
    );
  }

  const renderDesktopView = (renderBenchmarkLegend, categoryRender) => {
    if (isMobileOrTablet()) {
      return renderButtonMobileView()
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
        className="" leadingIconName="category" toggleClickLegendButton={toggleClickLegendButton}>
        <div className="legend-wrapper snap-shot-legend-container">
          {renderBenchmarkLegend}
          {categoryRender}
        </div>
      </MenuDropDownSelector>
    );
  }

  const renderSnapshotLegends = () => {
    const renderLegend = renderLegends();
    const renderBenchmarkLegend = renderBenchmarkLegends();

    if (_.isEmpty(renderLegend) && _.isEmpty(renderBenchmarkLegend)) {
      return null;
    }

    let categoryRender = null;
    if (!_.isEmpty(renderLegend)) {
      categoryRender = (<div className="categories"> {renderLegend} </div>)
    }

    return (
      <>
        {isMobileOrTablet() && renderMobileView(renderBenchmarkLegend, categoryRender)}
        {renderDesktopView(renderBenchmarkLegend, categoryRender)}
      </>
    );
  }

  return (
    <Fragment>
      {renderSnapshotLegends()}
    </Fragment>
  )
}

SnapshotLegends.propTypes = {
  legendOption: PropTypes.object
}

export default SnapshotLegends

