import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import reducer from 'reducers';
import { updateStoreToUrl } from 'helpers/UrlParamsHelper';
import { childrenPropTypes,  shapeProps } from 'common/propTypes';
import PropTypes from 'prop-types';

const propTypes = {
  fullStoreState: shapeProps.isRequired,
  buildLinkState: PropTypes.func.isRequired,
  children: childrenPropTypes,
  viewEntry: PropTypes.object,
  templateEntry: PropTypes.object,
  dispatchDrillDownEntry: PropTypes.func,
  segmentName: PropTypes.string
};
const defaultProps = {};
const ReduxDispatchAsLink = ({
  fullStoreState, buildLinkState, children, dispatchDrillDownEntry, viewEntry, templateEntry,
  segmentName, ...otherProps
}) => {
  let updatedStoreState = _.cloneDeep(fullStoreState);
  buildLinkState((dispatchAction) => {
    updatedStoreState = reducer(updatedStoreState, dispatchAction);
  });
  const href = updateStoreToUrl('/#!/analysis', updatedStoreState);
  const onClick = (e) => {
    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      // Used for opening in new tab, so do nothing.
      window.open(href, '_blank');
    } else {
      dispatchDrillDownEntry(viewEntry, templateEntry)
      window.location = href;
      window.location.reload();
    }
  };
  return (
    <a aria-label={segmentName} href={href} {...otherProps} onClick={onClick}>
      {children}
    </a>
  );
};

ReduxDispatchAsLink.propTypes = propTypes;
ReduxDispatchAsLink.defaultProps = defaultProps;

function mapDispatchToProps() {
  return {}
}

function mapStateToProps(state) {
  return {
    fullStoreState: state,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReduxDispatchAsLink);
