// Vendor imports
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// Project Imports
import * as commonPropTypes from 'common/propTypes';
import TooltipContent from 'common/components/ComparisonTooltip/TooltipContent';

const ComparisonTooltip = (props) => {
  const {
    currentPeriodTotal, previousPeriodTotal, percentage, actualDifference, viewEntry, tooltipId,
    comparisonDateRange, dateRange, dateRangeMode
  } = props;
  const tooltipContent = (
    <Tooltip tooltipId={tooltipId} key={tooltipId}>
      <TooltipContent
        currentPeriodTotal={currentPeriodTotal}
        previousPeriodTotal={previousPeriodTotal}
        comparisonDateRange={comparisonDateRange}
        primaryDateRange={dateRange}
        dateRangeMode={dateRangeMode}
        percentage={percentage}
        actualDifference = {actualDifference}
        viewEntry={viewEntry} />
    </Tooltip>
  );

  return(
    <OverlayTrigger tooltipId={tooltipId} placement="top" overlay={tooltipContent}>
      {props.children}
    </OverlayTrigger>
  );
};

ComparisonTooltip.propTypes = {
  currentPeriodTotal: commonPropTypes.stringOrNumberProps,
  previousPeriodTotal: commonPropTypes.stringOrNumberProps,
  percentage: commonPropTypes.stringOrNumberProps,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  tooltipId: commonPropTypes.stringOrNumberProps,
  dateRangeMode: commonPropTypes.stringOrNumberProps,
  children: commonPropTypes.childrenPropTypes,
  comparisonDateRange: commonPropTypes.dateRangePropTypes,
  dateRange: commonPropTypes.dateRangePropTypes,
  actualDifference: commonPropTypes.stringOrNumberProps
};

export default ComparisonTooltip;
