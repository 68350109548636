import React, { useState, useEffect } from 'react';
import {
  ForgeBadge,
  ForgeButton,
  ForgeInlineMessage,
} from '@tylertech/forge-react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  OVERTIME_VISUALIZATION_TYPES,
  VISUALIZATION_TYPES,
  FORECAST_PAGE_TYPES,
} from 'appConstants';
import { isEligibleMetricAdvancedForecasting } from 'common/config/viewConfiguration';
import {
  analysisModeBetaBannerText,
  showForecastingBetaTag,
} from 'common/config/customerConfiguration';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { setCurrentVisualizationType } from 'actions/drilldownActions';
import {
  getDefaultFutureForecastRange,
  getForecastDateRange,
  storeFromPath,
} from 'pages/Forecasting/ForecastHelper';
import {
  updateForecastDateRange,
  updateForecastOptions,
} from 'actions/forecastingActions';
import { defaultPrepareDataAxisGranularityOption } from 'pages/Forecasting/ForecastHelper';
import {
  updateAxisGranularity,
  updateOvertimeChartView,
} from 'actions/overtimeActions';
import { updateDateRange } from 'actions/commonFiltersActions';

const ProjectionBanner = () => {
  const [isRedirectToForecasting, setIsRedirectToForecasting] = useState(false);

  useEffect(() => {
    if(isRedirectToForecasting){
      updateStoreForForecastingPage();
    }
  }, [isRedirectToForecasting]);

  const dispatch = useDispatch();
  const drilldown = useSelector((state) => _.get(state, 'drilldown', {}));
  const currentDrilldownViewEntry = useSelector((state) =>
    _.get(state, 'drilldown.currentDrilldownViewEntry', {})
  );
  const currentUser = useSelector((state) =>
    _.get(state, 'currentUser.user', {})
  );
  const minDatesTemplateForForecast = useSelector((state) =>
    _.get(state, 'forecasting.minDatesTemplateForForecast', {})
  );
  const currentSecondaryMetricField = useSelector((state) =>
    _.get(state, 'visualization.snapshot.currentSecondaryMetricField', {})
  );

  if (
    !isEligibleMetricAdvancedForecasting(currentDrilldownViewEntry) ||
    _.isEmpty(currentUser)
  ) {
    return null;
  }

  const updateStoreForForecastingPage = () => {
    const {
      currentDrilldownTemplateId,
      currentDrilldownViewEntry,
      currentDrilldownDimensionField,
    } = drilldown;

    let newDateRanges = getForecastDateRange({
      templateId: currentDrilldownTemplateId,
      minDatesTemplateForForecast,
    });
    const defaultFutureForecastRange = getDefaultFutureForecastRange();
    if (!_.isEmpty(newDateRanges)) {
      const forecastDateRange = {
        dateRange: _.cloneDeep(newDateRanges),
      };
      dispatch(updateDateRange(newDateRanges));
      dispatch(updateForecastDateRange(forecastDateRange));
    }

    const axisGranularity = defaultPrepareDataAxisGranularityOption(
      currentDrilldownTemplateId
    );

    const newOptions = {
      bookmarkId: '',
      futureForecastDateRange: defaultFutureForecastRange,
      selectedForecastMetric: currentDrilldownViewEntry,
      selectedForecastTemplateId: `${currentDrilldownTemplateId}`,
      currentChartView: OVERTIME_VISUALIZATION_TYPES.TIMELINE.type,
      axisGranularity,
      currentDrilldownDimensionField: currentDrilldownDimensionField,
      currentSecondaryMetricField: currentSecondaryMetricField,
      projectionAdjustedValues: [],
      currentForecastPage: FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE,
    };

    dispatch(updateForecastOptions(newOptions));
    dispatch(
      updateOvertimeChartView(OVERTIME_VISUALIZATION_TYPES.TIMELINE.type)
    );
    dispatch(setCurrentVisualizationType(VISUALIZATION_TYPES.OVERTIME.type));
    dispatch(updateAxisGranularity(axisGranularity));

    storeFromPath('/analysis');
  };

  const onForecastButtonClick = () => {
    setIsRedirectToForecasting(true);
  };

  const onForecastButtonKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      onForecastButtonClick();
    }
  };

  const renderForecastBetaTag = () => {
    if (!showForecastingBetaTag()) {
      return null;
    }

    return <ForgeBadge strong={false} className="projection-banner-forge-badge">Beta</ForgeBadge>;
  };

  const renderForecastInlineMessage = () => {
    return (
      <ForgeInlineMessage>
        <div className="projection-show-badge">
          <div className='d-flex align-items-flex-start'>
            {renderForecastBetaTag()}
            <div className="forge-typography--body1 pl-3">
              {analysisModeBetaBannerText}
            </div>
          </div>
          <ForgeButton type="outlined">
            <button
              type="button"
              onClick={onForecastButtonClick}
              onKeyDown={onForecastButtonKeyDown}
            >
              <span>Try it now</span>
            </button>
          </ForgeButton>
        </div>
      </ForgeInlineMessage>
    );
  };

  if (isRedirectToForecasting) {
    return <Redirect push to="/forecasting" />;
  }

  return (
    <div>
      {renderForecastInlineMessage()}
    </div>
  );
};
export default ProjectionBanner;
