import moment from 'moment';
import {
  OVERTIME_VISUALIZATION_TYPES,
  DEFAULT_TOTAL_NAME
} from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import { toLineTrace } from './timeline';
import {
  isTraceVisible,
  getPeriodType
} from '../vizOptionsHelper';
import { isDimensionsChange } from '../legendHelper';
import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { isDiscreteModeData } from 'common/config/viewConfiguration';
import {
  isTimeDurationEnabled,
  timeDurationDataUnit
} from 'common/config/customerConfiguration';

import { disableShowProjection } from '../Helpers/projectionHelper';
import { getTraceId } from '../Helpers/dimensionHelper';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';

export const toPlotlyTraces = (vizOptions, formattedData) => {
  let data = [
    ...getTracesForSegment(vizOptions, formattedData, 'current'),
    ...getTracesForSegment(vizOptions, formattedData, 'comparison'),
    ...getTracesForSegment(vizOptions, formattedData, 'tailingDrop'),
    ...getTracesForSegment(vizOptions, formattedData, 'tailingDropComparison'),
    ...getProjectionTraces(vizOptions, formattedData, 'projection')
  ];

  return data;
};

// DummyTrace with all months to set the all months in xAxis
// const getAllMonthsInvisibleTrace = (dateRangeMode) => {
//   let x;
//   let y = _.times(12, () => null);

//   if (isBienniumFiscalYear({ dateRangeMode })) {
//     const reArrangedMonths = reArrangeMonthBasedOnStartMonthIndex();
//     x = reArrangedMonths.concat(_.map(reArrangedMonths, (month) => `${month} `));
//     y = _.times(24, () => null);
//   } else if (getConfiguredDateType() !== DATE_OPTIONS_TYPE.YEARLY) {
//     x = moment.monthsShort();
//   } else {
//     x = reArrangeMonthBasedOnStartMonthIndex();
//   }

//   return {
//     x,
//     y,
//     // visible: 'legendonly'
//   }
// }

// rearranging months by fiscal year month
// const reArrangeMonthBasedOnStartMonthIndex = () => {
//   const allMonths = moment.monthsShort();
//   const startingMonthIndex = getDateRangeStartMonth();
//   const selectedMonthToEndMonth = _.takeRight(allMonths, 12 - (startingMonthIndex));
//   const startMonthToSelectedMonth = _.take(allMonths, startingMonthIndex);
//   return selectedMonthToEndMonth.concat(startMonthToSelectedMonth);
// }

const getTracesForSegment = (vizOptions, formattedData, segmentType) => {
  const { dateRange, renderTimeFrame, templateId, compareYearRanges,
    dateRangeMode , viewEntry, secondaryMetricEntry, axisGranularity,
    showLegendTotalLine, isBookMark } = vizOptions;
  const nullValueLabel = getNullValueLabel(templateId);
  const isTailingDrop =  (
    segmentType == 'tailingDrop' || segmentType == 'tailingDropComparison'
  );
  const chartSegamentType = isTailingDrop ? 'current' : segmentType;
  const isSmallView = vizOptions.viewMode === VIEW_MODE.SMALL;
  const isBurnup = vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const periodType = getPeriodType(vizOptions);
  const dataSegment = _.get(formattedData, segmentType, {});
  const isComparisonEnabled = _.size(_.get(vizOptions, 'compareYearRanges', [])) == 1;
  const disableDimension = shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges);
  // const defaultLineWidth = isComparisonEnabled ? 4 : 2;
  const defaultLineWidth = (isComparisonEnabled && !disableDimension) ? 4 : 2;
  const totalTraceColor = getConfiguredMetricColor(templateId, 'primary');
  const traces = [];
  const renderType = isTailingDrop ? "markers" : '';
  const isComparisonTrace = (segmentType == 'comparison' || segmentType == 'tailingDropComparison');
  let currentDateRange;
  if(isComparisonTrace) {
    currentDateRange = _.first(_.get(vizOptions, 'compareYearRanges', [])) || {};
  }else {
    currentDateRange = _.get(vizOptions, 'dateRange', {});
  }
  //if we change color in legend it will reflect in vizOptions
  // so we give first preference for vizOptions
  // on Data load vizOptions will be empty so use formattedDate dimensionConfigs
  const year = Number(dataSegment.year);
  let dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', []);
  if(_.isEmpty(dimensionConfigs)) {
    dimensionConfigs = _.get(formattedData, 'dimensionConfigs', []);
  }

  let totalMeta = {
    segmentType: chartSegamentType,
    isTotal: true,
    dimension: 'Total',
    year,
    color: totalTraceColor
  };

  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry);
  const isDimensionsChanged = isDimensionsChange(formattedData, vizOptions);
  const traceType = isComparisonTrace ? 'compare': 'current';
  const yearRange = `${moment(currentDateRange.startDate).year()} -
    ${moment(currentDateRange.endDate).year()}`;
  const commonTraceOptions = {
    isYearOnYear: true, isBurnup, isDiscreteMode, isSmallView,
    renderType, dateRangeMode, dateRange: currentDateRange, isTailingDrop, periodType,
    axisGranularity, segmentType };
  if(!_.isEmpty(dataSegment.total) && (showLegendTotalLine || isBookMark)){
    const traceId = getTraceId(DEFAULT_TOTAL_NAME, vizOptions, traceType);
    const color = _.get(_.find(dimensionConfigs, {traceId}), 'color');
    totalMeta['color'] = color || totalTraceColor;
    traces.push(toLineTrace(dataSegment.total, 'value', {
      meta: _.merge({}, totalMeta, { value: 'primary', traceId: traceId }),
      lineColor: color,
      lineWidth: (segmentType == 'current') ? defaultLineWidth : 1,
      visible: isTraceVisible(vizOptions, { traceId: traceId , isDimensionsChanged, traceType }),
      isSecondsFormat,
      dataUnit,
      yearRange: yearRange,
      ...commonTraceOptions
    }));
  }
  if(!_.isEmpty(dataSegment.entries)) {
    _.map(dataSegment.entries, (dataItems, dimension) => {
      const dimensionName = _.isEmpty(dimension)? nullValueLabel: dimension;
      const traceId = getTraceId(dimensionName, vizOptions, traceType);
      const color = _.get(_.find(dimensionConfigs, {traceId}), 'color');
      traces.push(toLineTrace(dataItems, 'value', {
        meta: {
          segmentType: chartSegamentType,
          dimension: dimensionName,
          value: 'primary',
          year: (isComparisonEnabled && disableDimension) ? year : Number(dimensionName),
          color,
          traceId: traceId
        },
        lineColor: color,
        lineWidth: (segmentType == 'current') ? defaultLineWidth : 1,
        visible: isTraceVisible(vizOptions, { traceId }),
        isSecondsFormat,
        dataUnit,
        yearRange: disableDimension ? dimensionName : yearRange,
        ...commonTraceOptions
      }));
    });
  }

  if (!_.isEmpty(vizOptions.secondaryMetricEntry)) {
    const secondaryTraceId = getTraceId(DEFAULT_TOTAL_NAME, vizOptions, traceType);
    const secondaryColor = _.get(_.find(dimensionConfigs, {traceId: secondaryTraceId}), 'color');
    totalMeta['color'] = secondaryColor || totalTraceColor;
    traces.push(toLineTrace(dataSegment.total, 'secondary_total', {
      meta: _.merge({}, totalMeta, { value: 'secondary', traceId: secondaryTraceId }),
      lineColor: secondaryColor,
      lineWidth: defaultLineWidth,
      lineDash: 'dashdot',
      visible: isTraceVisible(vizOptions, { traceId: secondaryTraceId, isDimensionsChanged, traceType }),
      isSecondsFormat : isSecondsFormatSecondary,
      dataUnit: secondaryDataUnit,
      yearRange: yearRange,
      ...commonTraceOptions
    }));

    _.each(dataSegment.entries, (dataItems, dimension) => {
      const dimensionName = _.isEmpty(dimension)? nullValueLabel: dimension;
      const traceId = getTraceId(dimensionName, vizOptions, traceType);
      const color = _.get(_.find(dimensionConfigs, {traceId}), 'color');
      traces.push(toLineTrace(dataItems, 'secondary_total', {
        meta: {
          segmentType: chartSegamentType,
          dimension: dimensionName,
          value: 'secondary',
          year,
          color,
          traceId: traceId
        },
        lineColor: color,
        lineWidth: (segmentType == 'current') ? defaultLineWidth : 1,
        lineDash: 'dashdot',
        visible: isTraceVisible(vizOptions, { traceId }),
        isSecondsFormat : isSecondsFormatSecondary,
        dataUnit: secondaryDataUnit,
        yearRange: disableDimension ? dimensionName : yearRange,
        ...commonTraceOptions
      }));
    });
  }
  return _.flatten(traces);
};

const getProjectionTraces = (vizOptions, formattedData, segmentType) => {
  const { renderTimeFrame, templateId, dateRangeMode,
    dateRange, viewEntry, secondaryMetricEntry, axisGranularity, compareYearRanges } = vizOptions;
  const nullValueLabel = getNullValueLabel(templateId);
  let traces = [];
  const isDimensionsChanged = isDimensionsChange(formattedData, vizOptions);
  let dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', []);
  if(_.isEmpty(dimensionConfigs)) {
    dimensionConfigs = _.get(formattedData, 'dimensionConfigs', []);
  }
  const disableDimension = shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges);
  const periodType = getPeriodType(vizOptions);
  const isSmallView = vizOptions.viewMode === VIEW_MODE.SMALL;
  const isBurnup = vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const dataSegment = _.get(formattedData, segmentType, {});
  const isComparisonEnabled = _.size(_.get(vizOptions, 'compareYearRanges', [])) == 1;
  const defaultLineWidth = isComparisonEnabled ? 4 : 2;
  const chartSegamentType = 'current';
  const year = dataSegment.year;
  const totalTraceColor = getConfiguredMetricColor(templateId, 'primary');
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry);

  const shouldShowProjections = !disableShowProjection(
    vizOptions.dateRange,
    formattedData,
    renderTimeFrame,
    vizOptions.viewEntry,
    vizOptions.axisGranularity
  );
  const totalMeta = {
    segmentType: chartSegamentType,
    isTotal: true,
    dimension: 'Total',
    year,
    color: totalTraceColor
  };

  const commonTraceOptions = {
    isYearOnYear: true, isBurnup, isDiscreteMode,
    isSmallView, lineDash: 'dot', dateRangeMode, dateRange,
    isProjection: true, periodType, axisGranularity, segmentType
  }
  const isComparisonTrace = (segmentType == 'comparison' || segmentType == 'tailingDropComparison');
  const traceType = isComparisonTrace ? 'compare': 'current';

  const yearRange = `${moment(dateRange.startDate).year()} - ${moment(dateRange.endDate).year()}`;
  // Projection Lines
  // Here we use the same function to draw compare lines, comparison data
  // only available when we enable comparison. So we check `projection_total` is
  // empty or not before draw the line.
  if (vizOptions.projectionEnabled && shouldShowProjections) {
    if(!_.isEmpty(dataSegment.total)){
      const totalId = getTraceId(DEFAULT_TOTAL_NAME, vizOptions, 'current');
      const color = _.get(_.find(dimensionConfigs, {traceId: totalId}), 'color');
      traces.push(toLineTrace(dataSegment.total, 'value', {
        meta: _.merge({}, totalMeta, { value: 'primary', isProjection: true, traceId: totalId }),
        lineColor: color,
        lineWidth: 2,
        visible: isTraceVisible(vizOptions, { traceId: totalId, isDimensionsChanged, traceType}),
        isSecondsFormat,
        dataUnit,
        yearRange: yearRange,
        ...commonTraceOptions
      }));

      if (!_.isEmpty(vizOptions.secondaryMetricEntry)) {
        traces.push(toLineTrace(dataSegment.total, 'secondary_total', {
          meta: _.merge({}, totalMeta, { value: 'secondary', isProjection: true, traceId: totalId }),
          lineColor: color,
          lineWidth: (segmentType == 'current') ? defaultLineWidth : 1,
          visible: isTraceVisible(vizOptions, { traceId: totalId, isDimensionsChanged, traceType}),
          isSecondsFormat : isSecondsFormatSecondary,
          dataUnit: secondaryDataUnit,
          yearRange: yearRange,
          ...commonTraceOptions
        }));
      }
    }

    _.each(dataSegment.entries, (dataItems, dimension) => {
      const dimensionName = _.isEmpty(dimension)? nullValueLabel: dimension;
      const traceId = getTraceId(dimensionName, vizOptions, 'current');
      const color = _.get(_.find(dimensionConfigs, {traceId}), 'color');

      const traceOption = {
        meta: {
          segmentType: 'current',
          dimension: dimensionName,
          value: 'primary',
          year,
          color,
          isProjection: true,
          traceId: traceId
        },
        lineColor: color,
        lineWidth: 2,
        visible: isTraceVisible(vizOptions, { traceId }),
        isSecondsFormat,
        dataUnit,
        yearRange: disableDimension ? dimensionName: yearRange,
        ...commonTraceOptions
      };
      traces.push(toLineTrace(dataItems, 'value', traceOption));

      if (!_.isEmpty(vizOptions.secondaryMetricEntry)) {
        const secondaryTraceOption = {
          meta: {
            segmentType,
            dimension: dimensionName,
            value: 'secondary',
            year,
            color,
            isProjection: true,
            traceId: traceId
          },
          lineColor: color,
          lineWidth: (segmentType == 'current') ? defaultLineWidth : 1,
          visible: isTraceVisible(vizOptions, { traceId }),
          isSecondsFormat : isSecondsFormatSecondary,
          dataUnit: secondaryDataUnit,
          yearRange: disableDimension ? dimensionName : yearRange,
          ...commonTraceOptions
        };
        traces.push(toLineTrace(dataItems, 'secondary_total', secondaryTraceOption));
      }
    });
  }
  return _.flatten(traces);
}

// We need to show the separate trace for each year when select more than one year,
// So we formatted like this.
// const getYearWiseTraceResult = (resultEntries, valueField, options, dateRange) => {
  // return toLineTrace(resultEntries, valueField, options);
  // let yearWiseResultEntries = [];
  // const { startDate, endDate } = dateRange;
  // let startYear = Number(getFiscalYearByStartMonth(moment(startDate).format('YYYY')));
  // let endYear = Number(getFiscalYearByStartMonth(moment(endDate).format('YYYY')));

  // if(getConfiguredDateType() === DATE_OPTIONS_TYPE.YEARLY  && getDateRangeStartMonth() > 1) {
  //   const rangeStart = getDateRangeStartMonth() < moment(startDate).format('MM') ? startYear+1: startYear;
  //   const rangeEnd = Number(moment(endDate).format('MM')) > getDateRangeStartMonth() ?
  //      endYear+2:
  //      endYear+1;

  //   _.each(_.range(rangeStart, rangeEnd), (year) => {
  //     let yearRange = getDateRangeForYearly(year);
  //     yearWiseResultEntries.push(_.filter(resultEntries, (entry) =>{
  //       return entry.periodMmt.isBetween(yearRange.startDate, yearRange.endDate, 'month', '[]');
  //     }));
  //   });
  //   console.log(yearWiseResultEntries)
  //   return yearWiseResultEntries.map((entries) => {
  //     return toLineTrace(entries, valueField, options);
  //   });
  // } else {
  //   // In custom range we are not select one complete year every time, and also
  //   // Year start and end with (Jan - Dec) always.
  //   yearWiseResultEntries = _.chain(resultEntries).
  //                             groupBy((entry) => moment(entry.period).format('YYYY')).
  //                             values().
  //                            value();
  //   if (isBienniumFiscalYear(options)) {
  //     return toLineTrace(_.flatten(yearWiseResultEntries), valueField, options);
  //   }
  //   console.log(yearWiseResultEntries)
  //   return yearWiseResultEntries.map((entries) => {
  //     return toLineTrace(entries, valueField, options);
  //   });
  // }
// };
