import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import { getCollectionFiltersAndEntries } from 'pages/dashboard/components/Collections/collectionHelper';
import Filters from 'common/components/Filters';
import { getSelectedTemplateForSLAWatch } from 'common/config/customerConfiguration';

class GlobalFilter extends Component {
  renderGlobalFilters(){
    const { slaGlobalFilters, handleFiltersChange } = this.props;
    const currentDrilldownTemplateId = getSelectedTemplateForSLAWatch();
    const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
    const collectionOptions = {
      collectionFilters: slaGlobalFilters,
      currentDrilldownTemplateId,
      isDisabledAddFilterButton: true,
      cardEntries: [{templateEntry}]
    };
    const collectionFiltersAndEntries = getCollectionFiltersAndEntries(collectionOptions);
    if(_.isEmpty(collectionFiltersAndEntries) || _.isEmpty(slaGlobalFilters)) {
      return null
    }

    return (
      <Filters
        isDisabledAddFilterButton={true}
        type="quickFilters"
        filterFieldEntries={_.get(collectionFiltersAndEntries, 'globalFilterFieldConfigs')}
        filters={_.get(collectionFiltersAndEntries, 'collectionFilters')}
        templateId={currentDrilldownTemplateId}
        onFiltersChange={handleFiltersChange}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderGlobalFilters()}
      </>
    );
  }
}

GlobalFilter.propTypes = {
  slaGlobalFilters: PropTypes.object,
  handleFiltersChange: PropTypes.func
};

export default GlobalFilter;