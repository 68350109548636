// vendor Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Project Imports
import LoadingSpinner from 'common/components/LoadingSpinner';
import { getGlobalFilterColumnValues } from 'common/api/commonApi';
import { getCollectionFilterApiParams } from './helper';
import { STRING_TYPES_FIELD } from 'appConstants';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import PropTypes from 'prop-types';

const DEBOUNCE_WAIT_TIME = 200;

class SingleSelectFilter extends Component {
  constructor(props) {
    super(props);
    const { filter, filterFieldEntries } = props;
    const matchedFilterEntry = _.find(filterFieldEntries, { field: _.get(filter, 'field') }) || filter;
    const values = _.get(filter, 'conditions[0].value', [matchedFilterEntry.single_select_value]);

    this.state = {
      isLoading: false,
      offset: 0,
      values,
      columnValues: [],
      isLoadMore: true
    };
    this.debounceOnScrollSingleSelect = _.debounce(this.onScrollSingleSelect, DEBOUNCE_WAIT_TIME);
  }

  componentDidMount() {
    this.fetchColumnValues();
  }

  fetchColumnValues = async () => {
    const { filter } = this.props;
    const { offset, columnValues, isLoadMore } = this.state;
    const apiParams = getCollectionFilterApiParams(this.props);
    const params = { field : _.get(filter, 'field'), offset, ...apiParams };

    if(!isLoadMore) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      const response = await getGlobalFilterColumnValues(params);
      const newColumnValues = [...columnValues, ...response];

      this.setState({
        columnValues: _.without(_.uniq(newColumnValues), null),
        isLoading: false,
        offset: offset + 1,
        isLoadMore: !_.isEmpty(response)
      });
    } catch(err) {
      console.log('single select filter column values error', err); // eslint-disable-line no-console
      this.setState({ isLoading: false });
    }
  }

  handleFilterValueSelection = (selectedItems) => {
    const { filter, onApply } = this.props;
    const filterdItem = {
      field: _.get(filter, 'field'),
      type: STRING_TYPES_FIELD,
      conditions: [{ operator: '=', value: selectedItems }]
    };

    this.setState({ values: selectedItems }, () => {
      onApply(filterdItem);
    });
  }

  onKeydownListItem = (e, listItem) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      this.handleFilterValueSelection([listItem]);
    }
  }

  onScrollSingleSelect = () => {
    this.fetchColumnValues();
  }

  renderListItem = (listItem, index) => {
    const listClassNames = classNames('list-group-item', {
      'active': (_.get(this.state.values, '0') === listItem)
    });

    return (
      <li
        key={index}
        className={listClassNames}
        onClick={() => this.handleFilterValueSelection([listItem])}
        onKeyDown={(e) => this.onKeydownListItem(e, listItem)}
        tabIndex="0"
        aria-label={listItem}>
        {listItem}
      </li>
    );
  }

  render() {
    const { isLoading, columnValues } = this.state;
    const renderListItems = _.map(columnValues, this.renderListItem);

    return (
      <div className="value-list current-tax-year" onScroll={this.debounceOnScrollSingleSelect}>
        <ul className="list-group">
          {renderListItems}
        </ul>
        {isLoading && <div className="spinner-container">
          <LoadingSpinner size="sm" isLoading={isLoading}/></div>
        }
      </div>
    );
  }
}

SingleSelectFilter.propTypes = {
  apiParams: PropTypes.object,
  onSelection: PropTypes.func,
  filterFieldEntries: PropTypes.array,
  onApply: PropTypes.func,
  filter: PropTypes.object
}

function mapStateToProps(state) {
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);

  return {
    apiParamsOptions: apiParamsOptions
  };
}

export default connect(mapStateToProps, null)(SingleSelectFilter);
