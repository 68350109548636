import React from 'react';
import PropTypes from 'prop-types';
import { getRadarDateRangeText } from 'pages/Radar/radarHelper';

const DateRangeInfoText = ({radarOptions}) => {
  const currentDateRangeOptions = _.get(radarOptions, 'currentDateRangeOptions');
  const previousDateRangeOptions = _.get(radarOptions, 'previousDateRangeOptions');
  const {
    currentDateRangeText, compareDateRangeText
  } = getRadarDateRangeText(currentDateRangeOptions, previousDateRangeOptions);

  return (
    <div className='about-text'>
      Between {compareDateRangeText} and {currentDateRangeText}, these
      metrics changed:
    </div>
  );
}

DateRangeInfoText.defaultProps = {
}

DateRangeInfoText.propTypes = {
  radarOptions: PropTypes.object
}

export default DateRangeInfoText;
