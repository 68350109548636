import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import {
  getMapStackClusterRadius,
  getMapStackCircleRadius,
  getMapStackCircleHighlightRadius
} from '../helpers/mapHelper';
import { getAdvancedSearchPointsTileUrl } from 'common/api/map';

export const SOURCES = {
  INCIDENTS: 'search-incidents'
};
export const LAYERS = {
  INCIDENT_CIRCLE: 'search-point-circle',
  SUBJECT_CIRCLE: 'search-subject-point-circle',
  INCIDENT_ICON: 'search-subject-point-icon',
  INCIDENT_SELECT_ICON: 'search-select-point-icon',
  INCIDENT_UNSELECT_ICON: 'search-un-select-point-icon',
  STACKS_CIRCLE: 'search-stack-circle',
  STACKS_LABEL: 'search-stack-label',
  POINT_HIGHLIGHT: 'search-hover-point',
  HIGHLIGHT_INCIDENT_CIRCLE: 'search-highlight-incident'
};
export const POINT_AND_STACK_STYLES = {
  SELECTED_PIN_BORDER_SIZE: 2,
  HIGHLIGHT_BORDER_SIZE: 4,
  STACK_BORDER_SIZE: 3,
  STACK_COLOR: '#fff',
  STACK_OUTLINE_COLOR: '#196aa1',
  HOVER_STACK_OUTLINE_COLOR: '#7E7E7E',
  STACK_TEXT_COLOR: '#000000',
  ACTIVE_PIN_HIGHLIGHT_COLOR: 'blue',
  SUBJECT_STACK_OUTLINE_COLOR: '#ce7004'
};

const MAPBOX_GL_ICONS_FONT_NAME = 'executive-insights-charms-v001 Regular';

export const INCIDENT_COUNT_KEY = 'count';
export const ROW_ID_KEY = 'row_id_key';

class SearchPointsAndStackPartial extends Component {
  constructor(props) {
    super(props);
    this.removeSourcesAndLayers();
  }

  componentDidMount() {
    this.initSources();
    this.initLayers();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.tileParams, this.props.tileParams)
      || !_.isEqual(prevProps.selectedReportRowIds, this.props.selectedReportRowIds)){
      this.removeSourcesAndLayers();
      this.initSources();
      this.initLayers();
    } else if (!_.isEqual(prevProps.showSearchPoints, this.props.showSearchPoints)){
      this.updateLayoutProperty();
    }
  }

  updateLayoutProperty = () => {
    const { map, showSearchPoints } = this.props;
    const visibility = showSearchPoints ? 'visible' : 'none';

    map.setLayoutProperty(LAYERS.POINT_HIGHLIGHT, 'visibility', visibility);
    map.setLayoutProperty(LAYERS.STACKS_CIRCLE, 'visibility', visibility);
    map.setLayoutProperty(LAYERS.STACKS_LABEL, 'visibility', visibility);
    map.setLayoutProperty(LAYERS.INCIDENT_CIRCLE, 'visibility', visibility);
    map.setLayoutProperty(LAYERS.INCIDENT_ICON, 'visibility', visibility);
  }

  initSources() {
    const { map, tileParams } = this.props;
    map.addSource(SOURCES.INCIDENTS, {
      'type': 'vector',
      'geojsonTile': true,
      'cluster': true,
      'clusterRadius': getMapStackClusterRadius(),
      'aggregateBy': [ INCIDENT_COUNT_KEY ],
      'tiles': [getAdvancedSearchPointsTileUrl(tileParams)]
    });
  }

  initLayers() {
    const {
      map, showSearchPoints, selectedReportRowIds, showOnlySelectedRows
    } = this.props;

    const visibility = showSearchPoints ? 'visible' : 'none';

    map.addLayer({
      'id': LAYERS.POINT_HIGHLIGHT,
      'type': 'circle',
      'source': SOURCES.INCIDENTS,
      'source-layer': '_geojsonTileLayer',
      'filter': ['any', ['has', 'point_count'], ['!in', INCIDENT_COUNT_KEY, 1, '1']],
      'layout': {
        'visibility': visibility
      },
      'paint': {
        'circle-radius': getMapStackCircleHighlightRadius(),
        'circle-color': POINT_AND_STACK_STYLES.STACK_COLOR,
        'circle-stroke-width': POINT_AND_STACK_STYLES.HIGHLIGHT_BORDER_SIZE,
        'circle-stroke-color': '#fff',
        'circle-opacity': 0.65
      }
    });

    map.addLayer({
      'id': LAYERS.STACKS_CIRCLE,
      'type': 'circle',
      'source': SOURCES.INCIDENTS,
      'source-layer': '_geojsonTileLayer',
      'filter': ['any', ['has', 'point_count'], ['!in', INCIDENT_COUNT_KEY, 1, '1']],
      'layout': {
        'visibility': visibility
      },
      'paint': {
        'circle-radius': getMapStackCircleRadius(),
        'circle-color': POINT_AND_STACK_STYLES.STACK_COLOR,
        'circle-stroke-width': POINT_AND_STACK_STYLES.STACK_BORDER_SIZE,
        'circle-stroke-color': POINT_AND_STACK_STYLES.STACK_OUTLINE_COLOR
      }
    });

    map.addLayer({
      id: LAYERS.STACKS_LABEL,
      type: 'symbol',
      'source': SOURCES.INCIDENTS,
      'source-layer': '_geojsonTileLayer',
      'filter': ['any', ['has', 'point_count'], ['!in', INCIDENT_COUNT_KEY, 1, '1']],
      layout: {
        'text-field': `{${INCIDENT_COUNT_KEY}_abbrev}`,
        'text-size': getMapStackCircleRadius(),
        'text-allow-overlap': true,
        'visibility': visibility
      },
      paint: {
        'text-color': POINT_AND_STACK_STYLES.STACK_TEXT_COLOR
      }
    });

    if(!showOnlySelectedRows) {
      map.addLayer({
        id: LAYERS.INCIDENT_SELECT_ICON,
        type: 'symbol',
        'source': SOURCES.INCIDENTS,
        'source-layer': '_geojsonTileLayer',
        'filter': ['all', ['!has', 'point_count'],
          ['in', ROW_ID_KEY, ...selectedReportRowIds],
          ['in', INCIDENT_COUNT_KEY, 1, '1']
        ],
        'layout': {
          'text-font': [ MAPBOX_GL_ICONS_FONT_NAME ],
          'text-field': String.fromCharCode(60072),
          'text-size': 35,
          'text-offset': [0, 0.2],
          'text-allow-overlap': true,
          'visibility': visibility
        },
        'paint': {
          'text-color': '#3F51B5'
        }
      });

      map.addLayer({
        id: LAYERS.INCIDENT_UNSELECT_ICON,
        type: 'symbol',
        'source': SOURCES.INCIDENTS,
        'source-layer': '_geojsonTileLayer',
        'filter': ['all', ['!has', 'point_count'],
          ['!in', ROW_ID_KEY, ...selectedReportRowIds],
          ['in', INCIDENT_COUNT_KEY, 1, '1']
        ],
        'layout': {
          'text-font': [ MAPBOX_GL_ICONS_FONT_NAME ],
          'text-field': String.fromCharCode(60073),
          'text-size': 35,
          'text-offset': [0, 0.2],
          'text-allow-overlap': true,
          'visibility': visibility
        },
        'paint': {
          'text-color': '#3F51B5'
        }
      });
    }

    if(showOnlySelectedRows){
      map.addLayer({
        id: LAYERS.INCIDENT_CIRCLE,
        type: 'circle',
        'source': SOURCES.INCIDENTS,
        'source-layer': '_geojsonTileLayer',
        'filter': ['all', ['in', 'sort_text', 'A', 'B', 'C','D','E', 'F'],
          ['!in', 'is_subject_pin', 'true', true]],
        'layout': {
          'visibility': visibility
        },
        'paint': {
          'circle-radius': getMapStackCircleHighlightRadius(),
          'circle-color': POINT_AND_STACK_STYLES.STACK_COLOR,
          'circle-stroke-width': POINT_AND_STACK_STYLES.SELECTED_PIN_BORDER_SIZE,
          'circle-stroke-color': POINT_AND_STACK_STYLES.STACK_OUTLINE_COLOR,
          'circle-opacity': 0.65
        }
      });

      map.addLayer({
        id: LAYERS.SUBJECT_CIRCLE,
        type: 'circle',
        'source': SOURCES.INCIDENTS,
        'source-layer': '_geojsonTileLayer',
        'filter': ['all', ['in', 'is_subject_pin', 'true', true]],
        'layout': {
          'visibility': visibility
        },
        'paint': {
          'circle-radius': getMapStackCircleHighlightRadius(),
          'circle-color': POINT_AND_STACK_STYLES.STACK_COLOR,
          'circle-stroke-width': POINT_AND_STACK_STYLES.SELECTED_PIN_BORDER_SIZE,
          'circle-stroke-color': POINT_AND_STACK_STYLES.SUBJECT_STACK_OUTLINE_COLOR,
          'circle-opacity': 0.65
        }
      });

      map.addLayer({
        id: LAYERS.INCIDENT_SELECT_ICON,
        type: 'symbol',
        'source': SOURCES.INCIDENTS,
        'source-layer': '_geojsonTileLayer',
        'filter': ['all', ['in', 'sort_text', 'A', 'B', 'C','D','E', 'F']],
        layout: {
          'text-field': `{sort_text}`,
          'text-size': getMapStackCircleRadius(),
          'text-allow-overlap': true,
          'visibility': visibility
        },
        paint: {
          'text-color': POINT_AND_STACK_STYLES.STACK_TEXT_COLOR
        }
      });
    }
  }

  removeSourcesAndLayers() {
    const { map } = this.props;

    _.each(LAYERS, (layerId) => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });
    _.each(SOURCES, (sourceId) => {
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    });
  }

  render() {
    return null;
  }
}

SearchPointsAndStackPartial.propTypes = {
  tileParams: PropTypes.object,
  map: PropTypes.object,
  currentMapView: PropTypes.object,
  showSearchPoints: PropTypes.any,
  selectedReportRowIds: PropTypes.array,
  showOnlySelectedRows: PropTypes.bool
};

SearchPointsAndStackPartial.defaultProps = {
  selectedReportRowIds: []
}

export default SearchPointsAndStackPartial;
