import { getStackedChartSummaryFormatter } from './stackedChartSummaryFormatter';
import { getScatterChartSummaryFormatter } from './scatterChartSummaryFormatter';
import { getBarChartSummaryFormatter } from './barChartSummaryFormatter';

export const getChartSummaryTableFormat = (summaryTableOptions,
  formattedData, summaryTotalData, drillDownTotal) => {
  const {
    isStackedChart,
    isScatterPlot,
    isBarChart
  } = summaryTableOptions;

  if(isScatterPlot){
    return getScatterChartSummaryFormatter(
      formattedData,
      summaryTableOptions,
      summaryTotalData
    );
  } else if(isBarChart && !isStackedChart){
    return getBarChartSummaryFormatter(
      formattedData,
      summaryTableOptions,
      summaryTotalData
   );

  } else if(isBarChart && isStackedChart){
    return getStackedChartSummaryFormatter(
      formattedData,
      summaryTableOptions,
      summaryTotalData,
      drillDownTotal);
  }
}
