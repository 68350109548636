import React, { Component } from 'react';
import PropTypes from 'prop-types';
import createPlotlyComponent from 'react-plotly.js/factory';

import { SLA_CALCULATION_TYPES } from 'appConstants';
import { getSLAWatchCalculationType } from 'common/config/customerConfiguration';
const Plot = createPlotlyComponent(window.Plotly);
class PlotlyView extends Component {
  constructor(props) {
    super(props);
  }

  getPlotlyData = (formattedData) => {
    const { showPercentagePoint } = this.props;
    let annotations = [], plotlyData = [], tickLabel = [], label = [], value = [];
    const { maxXValue } = this.getTextAndMaxData(formattedData);
    _.each(formattedData, (data) => {
      const isGroup = _.get(data, 'isGroup', false);
      // Backgroud Gray Bar
      if (!isGroup) {
        plotlyData.push(
          {
            x: [maxXValue],
            y: [data['label']],
            meta: [data],
            customdata: data['customData'],
            name: [data['tickLabel']],
            // text: textList,
            type: 'bar',
            orientation: 'h',
            hoverinfo: 'none',
            visible: true,
            showlegend: false,
            cliponaxis: true,
            textposition: "outside",
            width: 0.7,
            offset: "",
            xaxis: "",
            marker: {
              color: "#E0E0E066"
            }
          }
        )
      }
      //

      plotlyData.push(
        {
          x: [data['value']],
          y: [data['label']],
          meta: [data],
          customdata: data['customData'],
          name: [data['tickLabel']],
          text: [data['value']],
          type: 'bar',
          orientation: 'h',
          hoverinfo: 'none',
          visible: true,
          showlegend: false,
          cliponaxis: false,
          width: 0.7,
          offset: "",
          marker: { "color": "#3F51B5" }
        }
      )

      tickLabel.push(data.tickLabel);
      label.push(data.label);
      value.push(data.value);

      if (getSLAWatchCalculationType() == SLA_CALCULATION_TYPES.CUMULATIVE_PERCENTAGE) {
        plotlyData.push(
          {
            x: [Number(data['target'])],
            y: [data['label']],
            meta: [data],
            customdata: data['customData'],
            type: 'scatter',
            mode: 'lines+markers',
            hoverinfo: 'none',
            marker: {
              "symbol": [
                142,
                142
              ],
              "color": "#EB7300",
              "size": 33,
              "line": {
                "width": 3
              }
            }
          }
        )
        if (!_.isUndefined(data['percentagePointDistance']) && showPercentagePoint) {
          let color = data['percentagePointDistance'] >= 0 ? "green" : "red"; 
          let annotationText = `${data['percentagePointDistance']} pp`;
          if(!Number(data['value']) > 0){
            annotationText ='No data';
            color='rgba(0, 0, 0, .38)';
          } 
          annotations.push({
            x: maxXValue, 
            y: data['label'],
            text: annotationText,
            font: {
              color: color,
              size: 14
            },
            showarrow: false,
            xanchor: "left",
            // borderpad: 5
          })
        }
      }
    });
    return { plotlyData, annotations, tickLabel, label, value, maxXValue };
  }

  getTextAndMaxData = (formattedData) => {
    let valueList = [];

    _.forEach(formattedData, (datum) => {
      valueList.push(Number(datum['value']));
      valueList.push(Number(datum['target']));
    });

    const maxXValue = _.max(valueList) + 30;
    return {
      maxXValue
    };
  }

  getLayout = ({ annotations, tickLabel, label, value, maxXValue }) => {
    const tickTextLabels = tickLabel;
    const tickValueLabels = label;
    const rangeLastValue = _.size(tickValueLabels);
    const minValue = _.min(value);
    const { showPercentagePoint } = this.props;
    const paddingRight = showPercentagePoint ? 55 : 10;
    const layout = {
      "showlegend": false,
      "autosize": true,
      "shapes": [],
      "margin": { "l": 10, "t": 10, "b": 20, "r": paddingRight, "pad": 5 },
      "bargap": 1,
      "dragmode": false,
      "showspikes": false,
      "bargroupgap": "",
      "hovermode": 'y',
      "height": (tickValueLabels.length * 45) + 60,
      "xaxis": {
        "rangemode": 'tozero',
        "showcrossline": true,
        "side": "top",
        "tickprefix": '',
        "ticksuffix": "",
        "showticklabels": false,
        "showgrid": false,
        "zeroline": false,
        "automargin": true,
        "tickfont": {
          "family": '',
          "size": "auto"
        },
        "type": "linear",
        "range": [minValue, maxXValue],
      },
      "yaxis": {
        "showdividers": true,
        "dividercolor": "block",
        "dividerwidth": 1,
        "tickfont": {
          "family": "",
          "size": "auto",
          "color": "#3F51B5"
        },
        "type": "category",
        "dtick": 1,
        // "autorange": "reversed",
        "range": [-0.5, rangeLastValue],
        "showticklabels": true,
        "automargin": true,
        "ticktext": tickTextLabels,
        "tickvals": tickValueLabels
      },
      "annotations": annotations
    };
    return layout;
  }

  getPlotlyConfigs = () => {
    const { data, extraPlotlyParams } = this.props;
    const config = {
      "displayModeBar": false,
      "scrollZoom": false,
      "editable": false,
      "showLink": false,
      "responsive": true
    };
    const style = { width: "98%", height: "100%" };
    const { plotlyData, annotations, tickLabel, label, value, maxXValue } = this.getPlotlyData(data);
    const layout = this.getLayout({ annotations, tickLabel, label, value, maxXValue });

    return {
      ...extraPlotlyParams,
      data: plotlyData,
      useResizeHandler: true,
      layout,
      config,
      style
    };
  }

  render() {
    const plotlyParams = this.getPlotlyConfigs();
    return (
      <div>
        <Plot {...plotlyParams} />
      </div>
    );
  }
}

PlotlyView.propTypes = {
  data: PropTypes.array,
  extraPlotlyParams: PropTypes.object,
  showPercentagePoint: PropTypes.bool
};

export default PlotlyView;