import moment from 'moment';
import _ from 'lodash';
import { useJsonToCsv } from 'react-json-csv';

import { getLinearBuckets, getRadarColorPalette } from 'modules/Map/helpers/choroplethMapHelper';
import { convertAbbreviatedStringValue } from 'helpers/numberHelper';
import {
  configuredDefaultDateType,
  getGlobalFiltersFromTemplate,
  defaultRadarEntry,
  getRadarPageTitle
} from 'common/config/customerConfiguration';
import {
  COMPARISON_PERIOD,
  COMPARISON_PERIOD_TYPES,
  DATE_COMPARISON_TYPE_OPTIONS,
  DATE_OPTIONS_TYPE,
  RADAR_TRACK_OTHER,
  SHAPE_AREA_TABS
} from 'appConstants';
import { getComparisonPeriodDateRanges, getDateRangeTextEntry } from 'helpers/dateHelper';
import { SOURCES } from 'modules/Map/partials/ShapeFilterPartial';
import { getCurrentTemplateEntryById, getCurrentViewEntry } from 'common/config/templateConfiguration';
import { defaultRadarOptions } from 'reducers/radarReducer';
import { getCurrentTemplateGlobalFilters } from 'pages/dashboard/components/Collections/collectionHelper';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import { getCompareSamePeriodLastYearOptions } from 'helpers/fiscalPeriodUtils';
import {
  getViewEntryQuickFilters
} from 'pages/drilldown/components/QuickFilterBar/helper';

export const getFormattedRadarTableData = (radarData, metricEntries) => {
  if (_.isEmpty(radarData) || _.isEmpty(metricEntries)) {
    return [];
  }

  return getCensusTrackData(radarData, metricEntries);
}

export const getCensusTrackData = (radarData, metricEntries) => {
  const censusTrackData = _.map(_.groupBy(radarData, 'tract_id'), (trackData, trackId) => {
    let metricHash = getMetricWiseData(trackData, metricEntries);
    const trackLabel = trackId == "undefined" ? RADAR_TRACK_OTHER : trackId;
    return { trackId: trackLabel, metricData: metricHash, shapeName: _.get(trackData, '0.shape_name') };
  });
  return censusTrackData;
}

export const tractMapBuckets = (censusTrackData, metricEntries) => {
  const metricValues = {};
  _.each(censusTrackData, (censusEntry) => {
    _.each(metricEntries, (metricEntry, index) => {
      const metricId = metricEntry['id'];
      if (_.isEmpty(metricValues[metricId])) {
        metricValues[metricId] = [];
      }
      metricValues[metricId].push(_.get(censusEntry, `metricData[${index}].${metricId}.change`));
    });
  });
  const metricBuckets = {};
  _.each(metricEntries, (metricEntry) => {
    const metricId = metricEntry['id'];
    const values = _.get(metricValues, metricId);
    const min = _.min(values);
    const max = _.max(values);
    const intervals = getLinearBuckets(
      (min == max) ? min - 1 : min,
      (min == max) ? max + 1 : max,
      10,
      "0"
    );

    const radarColorPalette = getRadarColorPalette(_.clamp(intervals.length, 0, 10));

    const bucketRanges = _.chain(0).
      range(intervals.length - 1).
      map((index) => {
        return { start: intervals[index], end: intervals[index + 1] };
      }).
      value();
    metricBuckets[metricId] = _.map(bucketRanges, (bucketRange, index) => {
      return {
        ...bucketRange,
        color: radarColorPalette[index] || _.last(radarColorPalette)
      }
    });
  });
  return metricBuckets;
}
export const getShapeWiseChoroplethData = (radarData, metricEntry) => {
  return _.map(_.groupBy(radarData, 'tract_id'), (trackData, trackId) => {
    const filteredData = _.filter(trackData, (datum) => {
      return (datum['radar_metric_id'] == metricEntry['id'] &&
        datum['metric_index'] == metricEntry['metricIndex']);
    });
    let metricHash = calculateMetricData(filteredData);
    return {
      shape_id: trackId,
      count: metricHash['change'],
      ...metricHash
    };
  });
}

export const getMetricWiseData = (allMetricsData, metricEntries, showNA = false, isSmallPage = false) => {
  let metricWiseData = [];
  _.each(metricEntries, (metricEntry) => {
    let metricHash = {}
    const metricData = _.filter(allMetricsData, (datum) => {
      return (datum['radar_metric_id'] == metricEntry['id'] &&
        datum['metric_index'] == metricEntry['metricIndex']);
    });
    if (_.isEmpty(metricData) && showNA && metricEntry['aggregate_type'] == 'avg') {
      metricHash[metricEntry['id']] = getNaValueObject()
    } else if (showNA && metricEntry['aggregate_type'] == 'avg' && isSmallPage) {
      metricHash[metricEntry['id']] = getNaValueObject();
    } else {
      metricHash[metricEntry['id']] = calculateMetricData(metricData);
    }
    metricWiseData.push(metricHash);
  });
  return metricWiseData;
}

const getNaValueObject = () => {
  return {
    currentValue: 'N/A',
    compareValue: 'N/A',
    change: 'N/A'
  }
}

const calculateMetricData = (metricData) => {
  let currentSumValue = 0;
  let compareSumValue = 0;
  _.each(metricData, (datum) => {
    currentSumValue += Number(datum['current_value'] || 0);
    compareSumValue += Number(datum['compare_value'] || 0);
  });

  let change = getPercentage(currentSumValue, compareSumValue);
  return {
    currentValue: _.round(currentSumValue, 2),
    compareValue: _.round(compareSumValue, 2),
    change: _.round(change, 2)
  };
}

export const getPercentage = (currentValue, compareValue) => {
  if (compareValue == 0) {
    return currentValue == 0 ? 0 : 100;
  }
  const difference = currentValue - compareValue;
  const percentage = ((100.00 * difference) / compareValue);
  return _.isNaN(percentage) ? 0 : percentage;
}

export const getBucketColor = (metricValueEntry, buckets) => {
  const defaultBucket = _.last(buckets);
  const matchingBucket = _.find(buckets, (bucket) => {
    return metricValueEntry.change >= bucket.start && metricValueEntry.change <= bucket.end;
  });
  return _.get((matchingBucket || defaultBucket), 'color');
}

export const getRadarFormattedBarChartData = (propsOption) => {
  const {
    barChartMetricWiseData,
    selectedTracts,
    selectedMetrics,
    selectAreaName,
    comparisonAreaName,
    isCheckedComparisonArea,
    isCheckedUnSelectedArea
  } = propsOption;
  const metricSelectData = _.get(barChartMetricWiseData, 'selectedTracts', {});
  const metricComparisonData = _.get(barChartMetricWiseData, 'comparisonTracts', {});
  const metricUnSelectData = _.get(barChartMetricWiseData, 'unSelectedTracts', {});
  const isSelectedTracts = _.isEmpty(selectedTracts) ? false : true;

  let radarBarChartData = [];
  _.forEach(selectedMetrics, (metric) => {

    let barChartConfig = {};
    barChartConfig['viewId'] = _.get(metric, 'id');
    barChartConfig['name'] = _.get(metric, 'name');
    barChartConfig['categoryData'] = [];

    const metricIndex = _.get(metric, 'metricIndex');
    const viewEntry = getCurrentViewEntry(_.get(metric, 'template_id'), _.get(metric, 'view_id'));

    const templateMetricSettings = {
      prefix: _.get(viewEntry, 'prefix', ''),
      showSuffixInFlyout: _.get(viewEntry, 'show_suffix_in_flyout', 'false'),
      plural_suffix: _.get(viewEntry, 'plural_suffix', ''),
      singular_suffix: _.get(viewEntry, 'singular_suffix', ''),
      precision: _.get(viewEntry, 'precision', '0')
    }

    if (isSelectedTracts) {
      const selectValue = _.get(metricSelectData, `${metricIndex}.${metric.id}`, {})
      const selectData = {
        compareValue: _.get(selectValue, 'compareValue'),
        currentValue: _.get(selectValue, 'currentValue'),
        percentage: _.get(selectValue, 'change'),
        category: selectAreaName,
        templateMetricSettings: templateMetricSettings
      }
      barChartConfig['categoryData'].push(selectData);
    }

    if (isCheckedComparisonArea) {
      const comparisonValue = _.get(metricComparisonData, `${metricIndex}.${metric.id}`, {})
      const comparisonData = {
        compareValue: _.get(comparisonValue, 'compareValue'),
        currentValue: _.get(comparisonValue, 'currentValue'),
        percentage: _.get(comparisonValue, 'change'),
        category: comparisonAreaName,
        templateMetricSettings: templateMetricSettings
      };
      barChartConfig['categoryData'].push(comparisonData);
    }

    if (isCheckedUnSelectedArea) {
      const unSelectValue = _.get(metricUnSelectData, `${metricIndex}.${metric.id}`, {})
      const commonUnSelectedData = {
        compareValue: _.get(unSelectValue, 'compareValue'),
        currentValue: _.get(unSelectValue, 'currentValue'),
        percentage: _.get(unSelectValue, 'change'),
        category: 'Unselected area',
        templateMetricSettings: templateMetricSettings
      }
      barChartConfig['categoryData'].push(commonUnSelectedData);
    }

    radarBarChartData.push(barChartConfig);

  });

  return radarBarChartData;
}

export const getCsvHeaderFields = (selectedShapeAreaEntry) => {
  return {
    "metric": "Metric",
    "group": "Group",
    "geography": `${selectedShapeAreaEntry['name']}`,
    "compare": 'Pre',
    "current": 'Post',
    "change": "Change"
  }
}

export const getCsvData = (radarData, selectedMetrics, selectedTracts, comparisonTracts, options) => {
  const { selectAreaName, comparisonAreaName } = options;
  let data = [];
  _.each(selectedMetrics, (metric) => {
    _.each(radarData, (metricDatum) => {
      let datum = _.get(metricDatum['metricData'], `${metric.metricIndex}.${metric.id}`, {})
      let trackId = metricDatum['trackId'];
      let hasSelected = _.indexOf(selectedTracts, trackId) >= 0;
      let hasComparison = _.indexOf(comparisonTracts, trackId) >= 0;
      let row = {
        metric: getMetricName(metric.id, selectedMetrics),
        group: hasSelected ? selectAreaName : hasComparison ? comparisonAreaName : 'Unselected area',
        geography: trackId,
        current: convertAbbreviatedStringValue(datum['currentValue']),
        compare: convertAbbreviatedStringValue(datum['compareValue']),
        change: `${datum['change']}%`
      };
      data.push(row);
    });
  });
  return data;
}

export const radarDownloadCsvFile = (csvOptions) => {
  const {
    radarData,
    selectedTracts,
    comparisonTracts,
    selectedMetrics,
    selectedShapeAreaEntry
  } = csvOptions;
  const { saveAsCsv } = useJsonToCsv();
  const data = getCsvData(radarData, selectedMetrics, selectedTracts, comparisonTracts, csvOptions);
  const filename = `${getRadarPageTitle()}_${moment().format("YYYYMMDD")}.csv`;
  const fields = getCsvHeaderFields(selectedShapeAreaEntry);

  saveAsCsv({ data, fields, filename });
}

const getMetricName = (metricId, selectedMetrics) => {
  return _.chain(selectedMetrics)
    .find({ id: _.toString(metricId) })
    .get('name')
    .value();
}

export const isRadarViewChanged = (currentView, bookmark) => {
  const { radarOptions } = bookmark;
  const selectAreaName = _.get(radarOptions, 'selectAreaName', SHAPE_AREA_TABS[0].name);
  const comparisonAreaName = _.get(radarOptions, 'comparisonAreaName', SHAPE_AREA_TABS[1].name);

  const selectedTracts = _.get(radarOptions, 'selectedTracts', {});
  const comparisonTracts = _.get(radarOptions, 'comparisonTracts', {});
  const isCheckedComparisonArea = _.get(radarOptions, 'isCheckedComparisonArea', {});
  const isCheckedUnSelectedArea = _.get(radarOptions, 'isCheckedUnSelectedArea', {});

  const radarMetricFilters = _.omitBy(_.get(bookmark, 'radarOptions.metricFilters', {}), _.isEmpty);
  const viewMetricFilters = _.omitBy(_.get(currentView, 'metricFilters', {}), _.isEmpty);
  const radarMetricGlobalFilters = _.omitBy(_.get(bookmark, 'radarOptions.metricGlobalFilters', {}),
    _.isEmpty);
  const viewMetricGlobalFilters = _.omitBy(_.get(currentView, 'metricGlobalFilters', {}), _.isEmpty);
  const newRadarOptions = {
    ...radarOptions, comparisonTracts,
    selectAreaName, comparisonAreaName, selectedTracts, isCheckedComparisonArea, isCheckedUnSelectedArea
  };
  const radarViewOptions = getRadarViewOptions(newRadarOptions);
  const currentViewOptions = getRadarViewOptions(currentView);

  return (!_.isEqual(radarViewOptions, currentViewOptions) ||
    !_.isEqual(radarMetricFilters, viewMetricFilters) ||
    !_.isEqual(radarMetricGlobalFilters, viewMetricGlobalFilters));
}

export const getPreviousYearDateRange = (previousDateRangeOptions, currentDateRange) => {
  const { dateRange, dateType, comparisonPeriod } = previousDateRangeOptions;

  if (dateType == COMPARISON_PERIOD) {
    return getComparisonPeriodDateRanges({
      comparisonType: comparisonPeriod,
      dateRange: currentDateRange, dateType
    })[0];
  } else {
    return dateRange;
  }
}

export const getComparisonPeriodName = (comparisonPeriod) => {
  const matchedOption = _.find(COMPARISON_PERIOD_TYPES, { value: comparisonPeriod });
  return _.get(matchedOption, 'name', comparisonPeriod);
}

export const getCurrentDateRangeText = (currentDateRangeOptions, showStartAndEndText = false) => {
  const { dateRange } = currentDateRangeOptions;
  const {
    text, startDateText, endDateText
  } = getDateRangeTextEntry(dateRange, DATE_OPTIONS_TYPE.CUSTOM_RANGE);
  return showStartAndEndText ? { startDateText, endDateText } : text;
}

export const getCompareDateRangeText = (
  previousDateRangeOptions, currentDateRangeOptions, showStartAndEndText = false
) => {
  const { dateRange } = currentDateRangeOptions;
  const previousYearDateRange = getPreviousYearDateRange(previousDateRangeOptions, dateRange);
  const { text, startDateText, endDateText } = getDateRangeTextEntry(
    previousYearDateRange, DATE_OPTIONS_TYPE.CUSTOM_RANGE
  );
  return showStartAndEndText ? { startDateText, endDateText } : text;
}

export const getRadarDateRangeText = (
  currentDateRangeOptions, previousDateRangeOptions, showStartAndEndText = false
) => {
  const currentDateRangeText = getCurrentDateRangeText(currentDateRangeOptions, showStartAndEndText);
  const compareDateRangeText = getCompareDateRangeText(
    previousDateRangeOptions, currentDateRangeOptions, showStartAndEndText
  );
  return { currentDateRangeText, compareDateRangeText };
}

const getRadarViewOptions = (currentView) => {
  return _.pick(
    currentView,
    'selectedTracts',
    'currentDateRangeOptions',
    'previousDateRangeOptions',
    'selectedMetricEntries',
    'selectedShapeAreaEntry',
    'selectedMapMetric',
    'selectAreaName',
    'comparisonAreaName',
    'totalAggregateType',
    'isCheckedComparisonArea',
    'isCheckedUnSelectedArea',
    'comparisonTracts'
  );
}

export const getShapeFeaturesFromMap = (map) => {
  const features = map.querySourceFeatures(SOURCES.SHAPES);
  const shapeDetails = _.map(features, (feature) => {
    const properties = _.get(feature, 'properties');

    return { ...properties, name: _.get(properties, 'shape_name') };
  });

  return _.uniqBy(shapeDetails, 'shape_id');
}

export const getRadarShareParams = (currentBookmarkEntry, options) => {
  const shareEmails = _.get(options, 'shareEmails', []);
  const name = _.get(currentBookmarkEntry, 'name', '');
  const radarOptions = _.get(currentBookmarkEntry, 'radarOptions', {});
  const bookmarkId = _.get(currentBookmarkEntry, 'id');
  const {
    selectedMetricEntries = [], selectedMapMetric = {}, metricFilters = {}
  } = radarOptions;

  const radarEmailFilters = {
    selectedShapeAreaEntryId: _.get(radarOptions, 'selectedShapeAreaEntry.id'),
    selectedRadarTracts: _.get(radarOptions, 'selectedTracts', []),
    comparisonTracts: _.get(radarOptions, 'comparisonTracts', []),
    isCheckedComparisonArea: false,
    isCheckedUnSelectedArea: false,
    selectedRadarMetricIds: _.map(selectedMetricEntries, 'id'),
    selectedRadarMapMetricId: _.get(selectedMapMetric, 'id'),
    radarMetricFilters: metricFilters,
    originBookmarkId: bookmarkId
  }

  return {
    shareEmails: shareEmails,
    bookmark: getBookmarkWithExcludeKeys(_.cloneDeep(currentBookmarkEntry)),
    metricName: name,
    radarFilters: radarEmailFilters,
    applicationUrl: window.location.href
  };
}

const getBookmarkWithExcludeKeys = (bookmark) => {
  return _.omit(bookmark, ['id', 'user_id', 'created_at', 'updated_at', 'shared_user']);
}

export const getMetricPermissionUserRoles = (templateEntries, selectedMetricEntries) => {

  const allViewEntries = _.flatten(_.map(templateEntries, 'view_entries', []));
  let allowedUserRoles = [];

  let viewEntries = [];
  _.forEach(selectedMetricEntries, (entry) => {
    const viewEntry = _.find(allViewEntries, { 'view_id': entry.view_id });
    if (!_.isEmpty(viewEntry)) {
      viewEntries.push(viewEntry);
    }
  });

  if (_.isEmpty(viewEntries)) {
    return allowedUserRoles;
  }

  const allUsers = _.flatten(_.map(viewEntries, 'user_list'));
  _.forEach(allUsers, (user) => {

    let isUserAvailable = true;
    _.forEach(viewEntries, (viewEntry) => {
      const users = _.get(viewEntry, 'user_list', []);
      const findUser = _.find(users, { 'role_id': user['role_id'] });
      if (_.isEmpty(findUser)) {
        isUserAvailable = false;
      }
    })
    if (isUserAvailable) {
      allowedUserRoles.push(user)
    }
  });

  return !_.isEmpty(allowedUserRoles) ? _.map(allowedUserRoles, 'role_id') : ['none'];
}

export const getMetricDefaultFilters = (metricEntry) => {

  const viewId = _.get(metricEntry, 'view_id', '');
  const templateId = _.get(metricEntry, 'template_id', '');
  const viewEntry = getCurrentViewEntry(templateId, viewId);
  return getViewEntryQuickFilters(templateId, viewEntry);
}

export const getRadarGlobalFilterByMetric = (cardEntry, globalFilters) => {
  const radarOptions = _.get(cardEntry, 'radarOptions', defaultRadarOptions());
  const selectedMetricEntries = _.get(radarOptions, 'selectedMetricEntries', []);

  let radarGlobalFilter = [];
  _.forEach(selectedMetricEntries, (metricEntry, index) => {
    const viewId = _.get(metricEntry, 'view_id', '');
    const templateId = _.get(metricEntry, 'template_id', '');
    const templateEntry = getCurrentTemplateEntryById(templateId);
    const globalFilterConfigEntries = getGlobalFiltersFromTemplate(templateEntry);
    let collectionFilters = getCurrentTemplateGlobalFilters(templateEntry, globalFilters);

    _.forEach(collectionFilters, (collectionFilter) => {
      const globalFilterConfig = _.find(globalFilterConfigEntries, { field: collectionFilter.field });
      if (!_.isEmpty(globalFilterConfig)) {
        collectionFilter['column'] = _.get(globalFilterConfig, 'column');
      }
    });

    radarGlobalFilter.push({
      metricIndex: index,
      viewId: viewId,
      templateId: templateId,
      id: _.get(metricEntry, 'id'),
      filters: collectionFilters
    });

  })

  return radarGlobalFilter;
}

export const getFilterWithCompareBookmarkFilter = (radarGlobalFilters, radarBookmarkGlobalFilters) => {
  let bookmarkGlobalFilters = _.cloneDeep(radarBookmarkGlobalFilters);
  _.forEach(bookmarkGlobalFilters, (bookmarkDatum) => {
    const findFilter = _.find(radarGlobalFilters, (radarFilter) => {
      const templateId = _.get(radarFilter, 'id');
      const bookmarkTemplateId = _.get(bookmarkDatum, 'id');
      const id = _.get(radarFilter, 'templateId');
      const bookmarkCardId = _.get(bookmarkDatum, 'templateId');
      if (_.isEqual(templateId, bookmarkTemplateId) && _.isEqual(id, bookmarkCardId)) {
        return radarFilter
      }
    });

    let addNewGlobalFilters = [];
    if (!_.isEmpty(_.get(findFilter, 'filters'))) {
      const addFilters = _.get(findFilter, 'filters', []);
      const oldFilters = _.get(bookmarkDatum, 'filters', []);


      _.forEach(addFilters, (addFilter) => {
        let addNewFilter = _.find(oldFilters, (oldFilter) => {
          if (_.isEqual(_.get(addFilter, 'field'), _.get(oldFilter, 'field'))) {
            return addFilter
          }
        });

        if (_.isEmpty(addNewFilter) && !_.isEmpty(addFilter)) {
          addNewGlobalFilters.push(addFilter);
        }
      });

      if (!_.isEmpty(addNewGlobalFilters)) {
        bookmarkDatum.filters = [...bookmarkDatum.filters, ...addNewGlobalFilters];
      }
    }

  });

  return bookmarkGlobalFilters;
}

export const getRadarMetricFilterWithGlobalFilter = (metricFilters, metricGlobalFilter) => {
  let metricWithGlobalFilters = _.cloneDeep(metricFilters);
  _.forEach(metricWithGlobalFilters, (metricFilter) => {
    const metric = _.find(metricGlobalFilter, (filterDatum) => {
      if (filterDatum.id == metricFilter.id && filterDatum.metricIndex == metricFilter.metricIndex) {
        return filterDatum
      }
    });

    if (!_.isEmpty(metric) && !_.isUndefined(metric.filters)) {
      metricFilter.filters = [...metricFilter.filters, ...metric.filters]
    }
  })

  return metricWithGlobalFilters;
}

export const getRadarCustomizeDateRange = (options) => {
  const { commonFilters } = options;
  // const collectionDateFilter = _.get(commonFilters, 'collectionDateFilters.dateRange');
  const dateRangeFilter = _.get(commonFilters, 'dateRange');
  const independentDateFilters = _.get(commonFilters, 'independentDateFilters');

  let dateRangeOption = {};
  if (!_.isEmpty(independentDateFilters)) {
    return dateRangeOption;
  }

  const primaryDateType = _.get(commonFilters, "dateType") || "custom_range";
  const templateId = _.get(defaultRadarEntry(), 'template_id');

  if (!_.isEmpty(dateRangeFilter)) {
    const currentOptions = {
      dateRange: dateRangeFilter,
      dateType: primaryDateType,
      relativeDateFilterEntry: relativeDateFilterEntryDefaultState
    };

    const prevDateRanges = getCompareSamePeriodLastYearOptions(
      { primaryDateRange: dateRangeFilter, primaryDateType, templateId });

    if (!_.isEmpty(prevDateRanges)) {
      const startDate = _.get(dateRangeFilter, 'startDate')
      
      const prevNewDateRange =  _.filter(prevDateRanges, (prevDateRange) => {
        const fromDate = _.get(prevDateRange, 'range.startDate')
        if (moment(fromDate) < moment(startDate)) {
          return prevDateRange
        }
      })

      const { range } = _.first(prevNewDateRange);
      const previousOptions = {
        dateRange: range,
        dateType: configuredDefaultDateType || primaryDateType,
        comparisonPeriod: DATE_COMPARISON_TYPE_OPTIONS[1].value
      };

      if (!_.isEmpty(range)) {
        dateRangeOption = {
          currentOptions,
          previousOptions
        };
      }
    }
  }

  return dateRangeOption;
}
