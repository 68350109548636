export const updateUserMessageReadStatusInLocalStorage = (key) => {
  if (getLocalStorageItem(key)) {
    localStorage.setItem(key, false);
  }
}

export const setLocalStorageItem = (key, value, isOverride = true) => {
  if (isOverride) {
    localStorage.setItem(key, value)
  } else if (!localStorage.getItem(key)) {
    localStorage.setItem(key, value);
  }
}

export const getLocalStorageItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
}

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
}

export const getSessionStorageItem = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
}

export const removeLocalAndSessionStorage = (key) => {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
}

export const removeSessionStorageItem = (key) => {
  sessionStorage.removeItem(key);
}

export const setSessionStorageItem = (key, value) => {
  sessionStorage.setItem(key, value);
}
