export const UPDATE_FORECAST_DATE_RANGE = 'UPDATE_FORECAST_DATE_RANGE';
export const UPDATE_FORECAST_TEMPLATE_ID = 'UPDATE_FORECAST_TEMPLATE_ID';
export const UPDATE_FORECAST_SELECTED_METRIC = 'UPDATE_FORECAST_SELECTED_METRIC';
export const UPDATE_FUTURE_FORECAST_DATE_RANGE = 'UPDATE_FUTURE_FORECAST_DATE_RANGE';
export const UPDATE_FORECAST_MODEL_OPTIONS = 'UPDATE_FORECAST_MODEL_OPTIONS';
export const UPDATE_FORECAST_PREPARE_DATA_TIME = 'UPDATE_FORECAST_PREPARE_DATA_TIME';
export const SET_MIN_DATES_TEMPLATES_FOR_FORECAST = 'SET_MIN_DATES_TEMPLATES_FOR_FORECAST';
export const UPDATE_FORECAST_OPTIONS = 'UPDATE_FORECAST_OPTIONS';
export const UPDATE_FORECAST_OVERTIME_CHART_VIEW = 'UPDATE_FORECAST_OVERTIME_CHART_VIEW';
export const UPDATE_FORECAST_AXIS_GRANULARITY = 'UPDATE_FORECAST_AXIS_GRANULARITY';
export const UPDATE_PROJECTION_ADJUSTED_VALUES = 'UPDATE_PROJECTION_ADJUSTED_VALUES';
export const UPDATE_FORECAST_PAGE_VIEW = 'UPDATE_FORECAST_PAGE_VIEW'
export const UPDATE_TO_DEFAULT_SETTING = 'UPDATE_TO_DEFAULT_SETTING';
export const UPDATE_FORECAST_BOOKMARK_ID = 'UPDATE_FORECAST_BOOKMARK_ID';

export const updateForecastDateRange = (forecastDateRange) => ({
  type: UPDATE_FORECAST_DATE_RANGE,
  forecastDateRange
});

export const updateFutureForecastDateRange = (futureForecastDateRange) => ({
  type: UPDATE_FUTURE_FORECAST_DATE_RANGE,
  futureForecastDateRange
});

export const updateForecastTemplateId = (selectedForecastTemplateId) => ({
  type: UPDATE_FORECAST_TEMPLATE_ID,
  selectedForecastTemplateId
});

export const updateForecastSelectedMetric = (selectedForecastMetric) => ({
  type: UPDATE_FORECAST_SELECTED_METRIC,
  selectedForecastMetric
});

export const updateForecastModelOptions = (forecastModelOptions) => ({
  type: UPDATE_FORECAST_MODEL_OPTIONS,
  forecastModelOptions
});

export const updateForecastPrepareDataTime = (forecastPrepareDataTime) => ({
  type: UPDATE_FORECAST_PREPARE_DATA_TIME,
  forecastPrepareDataTime
});

export const setMinDatesTemplatesForForecast = (minDates) => ({
  type: SET_MIN_DATES_TEMPLATES_FOR_FORECAST,
  minDates
});

export const updateForecastOptions = (forecastOptions) => ({
  type: UPDATE_FORECAST_OPTIONS,
  forecastOptions
});

export const updateForecastOvertimeChartView = (currentChartView) => ({
  type: UPDATE_FORECAST_OVERTIME_CHART_VIEW,
  currentChartView
});

export const updateForecastAxisGranularity = (axisGranularity) => ({
  type: UPDATE_FORECAST_AXIS_GRANULARITY,
  axisGranularity
});

export const updateProjectionAdjustedValues = (projectionAdjustedValues, forecastPrepareDataTime) => ({
  type: UPDATE_PROJECTION_ADJUSTED_VALUES,
  projectionAdjustedValues,
  forecastPrepareDataTime
});

export const updateForecastPageView = (currentForecastPage) => ({
  type: UPDATE_FORECAST_PAGE_VIEW,
  currentForecastPage
})

export const updateToDefaultSetting = () => ({
  type: UPDATE_TO_DEFAULT_SETTING
})

export const updateForecastBookmarkId = (bookmarkId) => ({
  type: UPDATE_FORECAST_BOOKMARK_ID,
  bookmarkId
})