import _ from 'lodash';

import { POINT_AND_STACK_STYLES } from '../PointsAndStackPartial';
import { getGeojson } from '../../helpers/mapHelper';

const SOURCES = {
  POINT_HIGHLIGHT: 'point-highlight-source'
};
export const LAYERS = {
  POINT_HIGHLIGHT: 'point-highlight-layer'
};

export default class PointHighlight {
  constructor(map) {
    this._map = map;
  }

  initialize = () => {
    if(!this._map) {
      return;
    }

    this._map.addSource(SOURCES.POINT_HIGHLIGHT, {
      type: 'geojson',
      data: getGeojson()
    });

    this._map.addLayer({
      'id': LAYERS.POINT_HIGHLIGHT,
      'type': 'circle',
      'source': SOURCES.POINT_HIGHLIGHT,
      'paint': {
        'circle-radius': 22,
        'circle-color': 'red',
        'circle-opacity': 0.35,
        'circle-radius-transition': { duration: 0, delay: 0 },
        'circle-color-transition': { duration: 0, delay: 0 }
      }
    });
  }

  update = (feature = null, options) => {
    if (this._map && this._map.getSource(SOURCES.POINT_HIGHLIGHT)) {
      this._map.getSource(SOURCES.POINT_HIGHLIGHT).setData(getGeojson(feature));
      if (!_.isEmpty(options)) {
        this._updateHighlightPaintProperty({
          source: LAYERS.POINT_HIGHLIGHT,
          opacity: 0.35,
          radius: 18,
          color: POINT_AND_STACK_STYLES.ACTIVE_PIN_HIGHLIGHT_COLOR
        });
      } else {
        this._updateHighlightPaintProperty({
          source: LAYERS.POINT_HIGHLIGHT,
          opacity: 0.75,
          radius: 22,
          color: POINT_AND_STACK_STYLES.STACK_COLOR
        });
      }
    }
  }

  _updateHighlightPaintProperty = (options = {}) => {
    const { color, opacity, radius, source } = options;
    this._map.setPaintProperty(source, 'circle-opacity', opacity);
    this._map.setPaintProperty(source, 'circle-radius', radius);
    this._map.setPaintProperty(source, 'circle-color', color);
  }

  destroy() {
    _.each(SOURCES, (sourceId) => {
      const source = this._map.getSource(sourceId);
      if (!_.isUndefined(source)) {
        source.setData(getGeojson());
      }
    });
  }
}
