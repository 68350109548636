import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';

import reducer from './collaboratorReducer';

const Store = ({ currentCollection, currentUser, allowedUserRoles, isOpenModel }) => {
  const initialState = {
    collection: currentCollection,
    isOpenModal: isOpenModel || false,
    currentUser,
    allowedUserRoles,
    isCollaborateCollection: _.get(currentCollection, 'is_collaborate_collection', false)
  };

  return createStore(reducer, initialState, applyMiddleware(logger));
}

export default Store;
