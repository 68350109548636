import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  HISTORICAL_FORECAST,
  LINEAR_FORECAST,
  PROPHET_FORECAST,
  EXPONENTIAL_FORECAST
} from 'appConstants';
import  PopoverStickOnHover from '../PopoverStickOnHover';
const HISTORICAL_FORECAST_INFO=`A historical projection takes your data from the same time in the prior
  year and inflates it by the percentage you specify. When viewing a month-by-month chart, a historical
  projection with a 3% inflation rate will show you the same pattern as the prior year, inflated by 3%.
  Since these projections are applied at a monthly level, the inflation rate will differ when viewing a
  cumulative chart.`
const LINEAR_FORECAST_INFO = `A linear projection uses the data in your selected timeframe to calculate
  a straight line that continues the current trajectory.`
const EXPONENTIAL_FORECAST_INFO = `Exponential forecasts use weighted averages of past observations.
  The weights are lessened exponentially for older data. The more recent the data, the higher the impact
  it will have on the forecast.`
const PROPHET_FORECAST_INFO = `Prophet is an open-source machine learning based forecasting service
  initially developed by Facebook. Prophet requires 25 months of historical data to create a projection
  and will account for seasonality and other changes in your data. For more information, click `;
class ProjectionInfo extends Component {

  constructor(props) {
    super(props);
   }

  renderInfoPopup() {
    const { currentForecastOption } = this.props;
    let toolTipOption = {};

    if(currentForecastOption === LINEAR_FORECAST) {
      toolTipOption.description = LINEAR_FORECAST_INFO;
    } else if(currentForecastOption === HISTORICAL_FORECAST){
      toolTipOption.description = HISTORICAL_FORECAST_INFO;
    } else if(currentForecastOption === EXPONENTIAL_FORECAST) {
      toolTipOption.description = EXPONENTIAL_FORECAST_INFO;
    } else if(currentForecastOption === PROPHET_FORECAST) {
      toolTipOption.description = PROPHET_FORECAST_INFO;
      toolTipOption.link = "https://facebook.github.io/prophet/";
      toolTipOption.linkText = "here.";
    }

    return toolTipOption;
  }

  render() {
    const { isShowProjection } = this.props;

    if(!isShowProjection) {
      return null
    }

    const tootipOption = this.renderInfoPopup();
    const popover = (
      <div className="p-2 forge-typography--body2">
        {tootipOption.description}
        <a
          className="pointer"
          href={tootipOption.link}
          target="_blank"
          rel="noopener noreferrer">{tootipOption.linkText}</a>
      </div>
    );

    return (
          <PopoverStickOnHover
            component={popover}
            placement="top"
            onMouseEnter={() => { }}
            delay={500}>
            <span className="ml-1 projection-info-icon">
              <i className="icons-info-circle-icon"></i>
            </span>
          </PopoverStickOnHover>
    );
  }
}

ProjectionInfo.propTypes = {
  currentForecastOption: PropTypes.string,
  isShowProjection: PropTypes.bool
}

export default ProjectionInfo;
