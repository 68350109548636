import React from 'react';
import PropTypes from 'prop-types';

import { getInitialOfUserName } from 'helpers/displayNameHelper';
import { getUserRoleOption } from 'helpers/pageHelper';
import PermissonValueSelector from './PermissonValueSelector';
import { getPermissionDescription } from 'common/config/customerConfiguration';
import UserSupportLink from './UserSupportLink';

const UserProfile = ({ currentUser, userFromBellerophon, appConfigUsers, isLoading = false }) => {
  const currentUserName = _.get(currentUser, 'user.nickname', '');
  const email = _.get(currentUser, 'user.email', '');
  const userName = getInitialOfUserName(currentUserName);
  const { roleName, roleDescription } = getUserRoleOption(userFromBellerophon);
  const permissionMapping = _.get(_.find(appConfigUsers, { email }), 'permission_mapping', {});
  const permissionDescription = getPermissionDescription();

  const renderPermissionFieldValue = (value, category) => {
    const key = `${category}-${value}`;
    return(<PermissonValueSelector category={category} value={value} key={key}/>);
  }

  const renderLabelAndDescription = (label, description, details, isPermission = false) => {
    const permissionValueContent = isPermission ?
      _.map(permissionMapping, renderPermissionFieldValue) :
      null;

    return (
      <div className='user-info-card' key={label}>
        <div className='user-info-details mb-2'>
          <div className='forge-typography--overline'>{label}</div>
          {details && <div className='user-name-details forge-typography--body1'>{details}</div>}
          {permissionValueContent &&
            <div className="permission-fields-wrapper">{permissionValueContent}</div>}
        </div>
        {description && <div className='info-card forge-typography--caption'>
            <i className='icons-info-circle'></i>
            {description}
          </div>
        }
      </div>
    );
  }

  return (
    <div className="user-profile">
      <div className="user-rs-side">
        <div className='user-name'>
          {userName}
        </div>
      </div>

      <div className='user-ls-side-content'>
        <div className="forge-typography--title mb-4">User profile</div>
        <div className='user-info-details'>
          <div className='label forge-typography--overline'>User Name</div>
          <div className='user-name-details forge-typography--body1'>{email}</div>
        </div>
        {renderLabelAndDescription('Role', roleDescription, roleName)}
        {!_.isEmpty(permissionMapping) &&
          renderLabelAndDescription('Permissions', permissionDescription, '', true)
        }
        {!isLoading && <UserSupportLink currentUser={currentUser} appConfigUsers={appConfigUsers} />}
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  currentUser: PropTypes.object,
  userFromBellerophon: PropTypes.object,
  appConfigUsers: PropTypes.object,
  isLoading: PropTypes.bool
}

export default UserProfile;
