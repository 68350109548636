export const UPDATE_BAR_CHART_SORT_TYPE = 'UPDATE_BAR_CHART_SORT_TYPE';
export const UPDATE_CURRENT_SNAPSHOT_VIEW = 'UPDATE_CURRENT_SNAPSHOT_VIEW';
export const UPDATE_GROUPCHART_APPROACH = 'UPDATE_GROUPCHART_APPROACH';
export const UPDATE_GROUPCHART_VIEW_TYPE = 'UPDATE_GROUPCHART_VIEW_TYPE';
export const UPDATE_GROUP_CHART_SORT_TYPE = 'UPDATE_GROUP_CHART_SORT_TYPE';
export const UPDATE_SNAPSHOT_BENCH_MARK_NAME = 'UPDATE_SNAPSHOT_BENCH_MARK_NAME';
export const UPDATE_SNAPSHOT_DIMENSION_SORT_TYPE = 'UPDATE_SNAPSHOT_DIMENSION_SORT_TYPE';
export const UPDATE_SNAPSHOT_SECONDARY_METRIC_FIELD = 'UPDATE_SNAPSHOT_SECONDARY_METRIC_FIELD';
export const UPDATE_VISUALIZATION_DEFAULTS = 'UPDATE_VISUALIZATION_DEFAULTS';
export const TOGGLE_ADD_SNAPSHOT_CHART_VALUES = 'TOGGLE_ADD_SNAPSHOT_CHART_VALUES';
export const TOGGLE_PIE_CHART_ANNOTATION_OPTIONS = 'TOGGLE_PIE_CHART_ANNOTATION_OPTIONS';
