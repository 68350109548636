// eslint-disable-next-line
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

// Project Imports
import { getMetricTotalData } from 'common/api/metricTotal';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
const DEBOUNCE_WAIT_TIME = 150;

class MetricTotal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total: null,
      templateTotal: null
    };
    this._debouncedFetchData = _.debounce(this.fetchData, DEBOUNCE_WAIT_TIME);
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this._debouncedFetchData();
  }

  componentDidUpdate(prevProps) {
    const { drilledDownDimensions, queryParams, secondaryMetricEntry } = this.props;
    const isDrilledDownDimensionsChanged = !_.isEqual(prevProps.drilledDownDimensions, drilledDownDimensions);
    const isSecondaryMetricChanged = !_.isEqual(prevProps.secondaryMetricEntry, secondaryMetricEntry);
    if(
      !_.isEqual(prevProps.queryParams, queryParams) ||
      isDrilledDownDimensionsChanged ||
      isSecondaryMetricChanged) {
      this._debouncedFetchData();
    }
  }

  componentWillUnmount() {
     this.abortFetchController.abort();
  }

  fetchData = () => {
    const {
      isComparison,
      queryParams,
      secondaryMetricEntry,
      updateCurrentDimensionTotal,
      viewEntry,
      comparisonDateRanges,
      dateRangeMode
    } = this.props;
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    getMetricTotalData({
      queryParams, viewEntry, secondaryMetrics: [{...secondaryMetricEntry }], isComparison,
      comparisonDateRanges, dateRangeMode
    }, this.abortFetchController).
      then((response) => {
        if(!_.isUndefined(response)) {
          const currentPeriodMetricTotals = _.get(response, 'currentPeriodMetricTotals', {});
          const { total, secondaryTotal } = currentPeriodMetricTotals;
          this.setState(currentPeriodMetricTotals, () => {
            updateCurrentDimensionTotal(total, false, secondaryTotal, response);
          });
        }
      }).catch((error) => {
        if (error.name !== 'AbortError') {
          updateCurrentDimensionTotal(0, false, 0, {});
          console.log("Error on fetching Metric Total", error);
        }
      });
  }

  render() {
    const { shouldShowTemplateTotal } = this.props;
    const { formattedTotalValue, total, templateTotal } = this.state;
    if (_.isNil(total)) {
      return null;
    }
    return shouldShowTemplateTotal ? templateTotal : formattedTotalValue;
  }
}

MetricTotal.defaultProps = {
  formatterOptions: {},
  isComparison: false,
  secondaryMetricEntry: {},
  shouldShowTemplateTotal: false,
  comparisonDateRanges:[],
  showSecondaryTotal: false,
  updateCurrentDimensionTotal: _.noop
}

MetricTotal.propTypes = {
  dateRangeMode: PropTypes.string,
  isComparison: PropTypes.bool,
  queryParams: PropTypes.object,
  comparisonDateRanges: PropTypes.array,
  updateCurrentDimensionTotal: PropTypes.func,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  shouldShowTemplateTotal: PropTypes.bool,
  showSecondaryTotal: PropTypes.bool,
  showAsDuration: PropTypes.bool,
  secondaryMetricEntry: commonPropTypes.secondaryMetricEntryPropTypes,
  drilledDownDimensions: PropTypes.array
}

function mapStateToProps(state) {
  return {
    drilledDownDimensions: _.cloneDeep(_.get(state, 'drilldown.drilledDownDimensions', []))
  };
}

export default connect(mapStateToProps, null)(MetricTotal);
