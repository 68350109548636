import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getAbbreviatedStringValue } from 'helpers/numberHelper';
import DrilldownPath from './DrilldownPath';
import NestedResullt from './NestedResult';

class CurrentPeriodResult extends Component {
  state = {
    expandedRowIndex: null
  }

  toggleRowExpansion = (index) => {
    const { isChildTable } = this.props;
    const { expandedRowIndex } = this.state;

    if (isChildTable) { 
      return;
    }
    const newExpandedRowIndex = (expandedRowIndex === index) ? null : index

    this.setState({ expandedRowIndex: newExpandedRowIndex });
  }

  renderVarianceTableRows = (varianceItem, index) => {
    const { expandedRowIndex } = this.state;
    const { secondaryMetricField } = this.props;
    if(_.isEmpty(varianceItem)) {
      return null;
    }

    const { variance, item, entities } = varianceItem;
    const { v1, v2, v1_group_sample_size, total_sample_size, score } = item;
    
    return [
      <tr key={`detail-${index}`} onClick={() => this.toggleRowExpansion(index)}>
        <td>
          <DrilldownPath fieldValues={entities} secondaryMetricField={secondaryMetricField} />
        </td>
        <td className="text-right">{getAbbreviatedStringValue(variance, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(v1, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(v2, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(v1_group_sample_size, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(total_sample_size, 0, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(score, 0, true, true)}</td>
      </tr>,
      (expandedRowIndex === index) ? this.renderExpandedRowDetails(varianceItem, index) : null
    ];
  }

  renderExpandedRowDetails = (varianceItem, index) => {
    const { fetchVarianceData, fetchAnovaData } = this.props;
    return (
      <tr key={`child-${index}`}>
        <td colSpan="7">
          <NestedResullt 
            fetchVarianceData={fetchVarianceData} 
            fetchAnovaData={fetchAnovaData}
            parentVarianceItem={varianceItem} 
            render={ (childVarianceData) => (
              <CurrentPeriodResult {...this.props} isChildTable={true} varianceData={childVarianceData}/> 
            )}
          />
        </td>
      </tr>
    );
  }

  render() {
    const { varianceData } = this.props;
    if(_.isEmpty(varianceData)) {
      return null;
    }

    // TODO: Display name for primary and secondary metric.
    return (
      <table className="table table-bordered table-sm">
        <thead>
          <tr>
            <th className="text-center">Path</th>
            <th className="text-center">Variance</th>
            <th className="text-center">Primary Metric</th>
            <th className="text-center">Secondary Metric</th>
            <th className="text-center">Sample Size</th>
            <th className="text-center">Total SS</th>
            <th className="text-center">Score</th>
          </tr>
        </thead>
        <tbody>
          {_.map(varianceData, this.renderVarianceTableRows)}
        </tbody>
      </table>
    );
  }
}

CurrentPeriodResult.propTypes = {
  isChildTable: PropTypes.bool,
  varianceData: PropTypes.array.isRequired,
  secondaryMetricField: PropTypes.string,
  fetchVarianceData: PropTypes.func,
  fetchAnovaData: PropTypes.func
}

export default CurrentPeriodResult;
