import _ from 'lodash';
import moment from 'moment';

import {
  getFilterButtonPlaceHolder,
  numberOperatorEntries,
  textOperatorEntries
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { getApiParams } from 'helpers/apiParamsHelper';
import { getThousandSeparatedValueWithFraction } from 'helpers/numberHelper';
import {
  DATE_FORMAT,
  CURRENCY_TYPE,
  STRING_TYPES_FIELD,
  NUMBER_TYPES_FIELD,
  DATE_TYPES_FIELD,
  BOOLEAN_TYPES_FIELD,
  AMOUNT_TYPE
} from 'appConstants';


export const getLogicalOperatorEntry = (filter) => {
  const filterOperator = _.get(filter, 'conditions[0].operator');
  return _.find(textOperatorEntries, { value: filterOperator }) || textOperatorEntries[0];
};

export const getTextOperatorEntries = (allFilters, filter) => {
  const matchedFilterOperators = _.chain(allFilters).
    filter((filterItem) => _.get(filterItem, 'field') === _.get(filter, 'field')).
    map('conditions').
    flatten().
    map('operator').
    value();
  let filterdOperators = _.filter(textOperatorEntries, (operatorEntry) => {
    return !_.includes(matchedFilterOperators, operatorEntry.value);
  });
  const operator = _.get(filter, 'conditions[0].operator', '');
  const currentFilterLogicalEntry = _.find(textOperatorEntries, { value: operator });
  filterdOperators = filterdOperators.concat(currentFilterLogicalEntry);

  return _.uniqBy(filterdOperators, 'value');
};

export const getFilterWithDefaultOption = (filter) => {
  const { renderType } = filter;
  let newFilter = filter;

  if (renderType === NUMBER_TYPES_FIELD) {
    newFilter = _.merge({}, filter, { value: 0, toValue: 0, operator: numberOperatorEntries[0].value });
  } else if(renderType === DATE_TYPES_FIELD) {
    const todayDate = moment(moment().toDate()).endOf('day').format(DATE_FORMAT);
    const dateRange = { start_date: todayDate, end_date: todayDate };
    newFilter = _.merge({}, filter, { dateRange });
  }
  newFilter = _.merge({}, newFilter, { type: renderType });

  return newFilter;
}

export const isValidFilter = (filter) => {
  let { renderType, field, conditions } = filter;
  if(_.isEmpty(renderType)){
    renderType = STRING_TYPES_FIELD;
  }
  if(_.isEmpty(field)){
    return false
  }
  if (renderType === STRING_TYPES_FIELD || renderType === CURRENCY_TYPE) {
    return !_.isEmpty(conditions)
  }
  return true

}

export const getCollectionFilterApiParams = ({ filter, filterFieldEntries, filters, apiParamsOptions }) => {
  const matchedFilterEntry = _.find(filterFieldEntries, { field: filter.field }) || filter;

  return getApiParams(apiParamsOptions, {
      withShapeIds: false,
      withPolygonFilter: false,
      withCommonFilters: true,
      globalFilterOptions: {
        replaceGlobalFilters: true,
        filterColumnEntry: matchedFilterEntry,
        globalFilters: filters
      }
    });
}

export const removeFilterAndGetFilters = (filters, filter, type) => {
  let newFilters = [];
  if (type === 'quickFilters') {
    newFilters = _.chain(filters).
      cloneDeep().
      map((filterItem) => {
       if (_.get(filterItem, 'field') === _.get(filter, 'field')) {
          filterItem['conditions'] = _.filter(filterItem.conditions, (condition) => {
            return _.get(condition, 'operator') !== _.get(filter, 'conditions[0].operator');
          });
        return _.isEmpty(filterItem['conditions']) ? null : filterItem;
       }
       return filterItem;
      }).
      without(null).
      value();
  } else {
    newFilters = _.filter(filters, (filterItem) => {
      return _.get(filterItem, 'field') !== _.get(filter, 'field');
    });
  }

  return newFilters;
}

export const getFilterConditions = ({ filter, filterFieldEntries, type, filters }) => {
  const { field, operator, values, conditions, to, value, isOthersBucket } = filter;
  const { renderType } = _.find(filterFieldEntries, { field }) || { renderType: STRING_TYPES_FIELD };
  let filterConditions = [];

  if (type === 'quickFilters') {
    if (renderType === NUMBER_TYPES_FIELD) {
      const fromValue = getThousandSeparatedValueWithFraction(value);
      const label = _.includes(['between', 'not between'], operator) ?
        `${fromValue} to ${getThousandSeparatedValueWithFraction(to)}` :
        fromValue;
      filterConditions = [{ value: [label], operator, isOthersBucket }];
    } else if (renderType === DATE_TYPES_FIELD) {
      filterConditions = [{ value: [getFilterButtonPlaceHolder(filter, filters)], operator }];
    } else if (renderType === BOOLEAN_TYPES_FIELD) {
      filterConditions = conditions;
    } else {
      filterConditions = conditions;
    }
  } else {
    filterConditions = [{ value: values, operator }];
  }

  return filterConditions;
}

export const isCurrencyRenderType = (filterFieldEntries, filter) => {
  const matchedFilterEntry = _.find(filterFieldEntries, { field: filter.field }) || filter;

  return (
    _.get(matchedFilterEntry, 'renderType') === CURRENCY_TYPE ||
    _.get(matchedFilterEntry, 'renderType') === AMOUNT_TYPE
  );
}

export const isNotValidNumericValue = (allFilters) => {
  const filters = _.filter(allFilters, (filterValue) => filterValue.type === NUMBER_TYPES_FIELD)
  let isValid = false;
  _.forEach(filters, (filter) => {
    if(isNumericValue(filter)){
      isValid = true;
      return
    }
  });

  return isValid
}

const isNumericValue = (filter) => {
  const { operator, type } = filter;
  let isValid = false;
  if (_.includes([NUMBER_TYPES_FIELD], type)) {
    const toValue = _.get(filter, 'to');
    const fromValue = _.get(filter, 'value');

    if (_.includes(["between", "not between"], operator)) {
      isValid = (_.isEmpty(_.toString(toValue)) || _.isEmpty(_.toString(fromValue)));
    } else {
      isValid = _.isEmpty(_.toString(fromValue));
    }
  }
  return isValid
}
