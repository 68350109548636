import React from 'react';
import PropTypes from 'prop-types';

import DetailsViewTab from './Details/DetailsViewTab';
import MapView from './Map/MapView';
import SearchFilterResultTable from './SearchFilterResultTable';

const TabViewContent = ({ selectedTab, data, isLoading, onMapLoaded }) => {
  if(selectedTab === 'table') {
    return (<DetailsViewTab detailsData={data} isLoading={isLoading} />);
  } else if(selectedTab === 'map') {
    return (
    <>
      <SearchFilterResultTable isCustomSearch={true} hideContent={true} />
      <MapView onMapLoaded={onMapLoaded}  isSubjectProperty={true} />
    </>
    );
  } else {
    return null;
  }
}

TabViewContent.propTypes = {
  selectedTab: PropTypes.string,
  detailsData: PropTypes.array,
  onMapLoaded: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.array
};

export default TabViewContent;
