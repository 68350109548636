import moment from 'moment';
import _ from 'lodash';
import {
  DATE_FORMAT,
  HISTORICAL_FORECAST,
  PROPHET_FORECAST,
  OVERTIME_TIME_FRAME_OPTIONS,
  FORECASTING_TYPE,
  AXIS_GRANULARITY_TYPES,
  EXPONENTIAL_FORECAST
} from 'appConstants';
import { getFiscalYearDateRangeForDate } from 'helpers/fiscalPeriodUtils';
import { MONTH_DAYS_COUNT } from 'helpers/dateHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { isComboChartEnable } from 'common/config/visualizationConfiguration';
import { getLineChartAttributes } from 'pages/drilldown/visualizations/visualizationRenders/helper';
import {
  configuredDefaultDateType,
  getDateRangeStartMonth,
  getDateDataBeginsFrom
} from 'common/config/customerConfiguration';
import { getCurrentViewEntry} from 'common/config/templateConfiguration';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getComboChartPrimaryMetricRenderType } from 'modules/visualization/LineChart/vizOptionsHelper'
import { getAxisGranularityOptions } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { getPeriodType } from 'modules/visualization/LineChart/vizOptionsHelper';

export const storeFromPath = (routePath) => {
  window.forecastFromPage = routePath;
}

export const getMinimumDateForForecast = (currentDrilldownTemplateId, minDatesTemplateForForecast) => {
  const dataBeginDate = getDateDataBeginsFrom(currentDrilldownTemplateId);
  const minDateTemplate = _.find(minDatesTemplateForForecast, (datum) => {
    if (datum.template_id == currentDrilldownTemplateId) {
      return datum
    }
  })

  let newStartDate = null;
  if (!_.isEmpty(minDateTemplate) || !_.isEmpty(dataBeginDate)) {
    const minStartDate = _.isEmpty(dataBeginDate) ?  _.get(minDateTemplate, 'min_date') : dataBeginDate;
    const startDate = moment(minStartDate).startOf("month");
    newStartDate = moment.max([startDate, moment().subtract(20, 'year').startOf("month")]);
    newStartDate = newStartDate.format(DATE_FORMAT);
  }

  return newStartDate
}

export const getForecastDateRange = (options) => {
  const { templateId, minDatesTemplateForForecast } = options;
  const minForecastDate = getMinimumDateForForecast(
    templateId,
    minDatesTemplateForForecast
  );
  let newDateRanges = {};
  if (!_.isEmpty(minForecastDate)) {
    newDateRanges = {
      startDate: minForecastDate,
      endDate: moment().format(DATE_FORMAT)
    }
  }
  return newDateRanges;
}

export const getForecastMonths = (currentDate) => {

  const fiscalYearDates = getFiscalYearDateRangeForDate(currentDate);
  const { endDate } = fiscalYearDates;
  const diffMonths = moment(endDate).diff(currentDate, 'month');

  return (diffMonths + 12);
}

export const getDefaultFutureForecastRange = () => {
  const currentDate = moment().format(DATE_FORMAT);
  const futureForecastDetails = getDefaultFutureForecastByFiscalYear();

  const newFutureRanges = {
    dateRange: { startDate: currentDate, endDate: futureForecastDetails.forecastDate },
    forecastMonth: `${futureForecastDetails.forecastMonth}`,
    forecastYear: `${futureForecastDetails.forecastYear}`
  }
  return newFutureRanges;
}

export const getDefaultFutureForecastByFiscalYear = () => {
  const current_Date = moment().format(DATE_FORMAT);
  const periodNumber = getForecastMonths(current_Date);
  const forecastDate = moment(current_Date).add(periodNumber, 'M').endOf('month').format(DATE_FORMAT);
  const forecastYear = moment(forecastDate).year();
  const forecastMonth = Number(moment(forecastDate).month()) +1;

  const forecastDetails = {
    forecastDate : forecastDate,
    forecastMonth : forecastMonth,
    forecastYear : forecastYear
  }
  return forecastDetails;
}

export const getAxisGranularity = (rangeOptions) => {
  const { startDate, endDate } = rangeOptions;
  const diffMonths = moment(endDate).diff(startDate, 'month');
  const diffDays = moment(endDate).diff(startDate, 'day') + 1;
  let granularity = 'month';

  if(diffMonths >= 24){
    // For Forecast accuracy granularity is set to 'month'.
    if (diffMonths >= 36) {
      granularity = 'month';
    } else {
      granularity = 'year';
    }
  } else if(diffDays <= MONTH_DAYS_COUNT){
    granularity = 'day';
  } else if(diffDays > (MONTH_DAYS_COUNT * 3) && diffDays < (MONTH_DAYS_COUNT * 2)){
    granularity = 'month';
  } else if(diffDays > MONTH_DAYS_COUNT && diffDays < (MONTH_DAYS_COUNT * 3)){
    granularity = 'week';
  }

  return granularity;
}

export const isForecastEndDateIsBeforeToday = (currentForecastDateRange, axisGranularity) => {
  const periodType = getPeriodType({ dateRange: currentForecastDateRange['dateRange'], axisGranularity });
  const { dateRange } = currentForecastDateRange;
  const endDate = moment(dateRange.endDate, DATE_FORMAT);
  const trailingStartDate = moment().startOf(periodType);

  return trailingStartDate.isAfter(endDate);
};

export const getValidFormattedData = (propsOption) => {
  const { formattedData, currentForecastDateRange,  axisGranularity} = propsOption;

  const { dateRange } = currentForecastDateRange;
  const { startDate } = dateRange;
  const filterStartDate = moment(startDate).startOf(axisGranularity);

  return _.filter(formattedData, (datum) => {
    return !(moment(datum.period) < filterStartDate) && datum.renderType == 'line'
  });
}

export const isValidDateSummaryData = (period, propsOption) => {
  const { dateRange,  axisGranularity } = propsOption;

  const { startDate } = _.get(dateRange, 'dateRange');
  const filterStartDate = moment(startDate).startOf(axisGranularity);
  const selectDate = moment(period);

  return selectDate < filterStartDate
}

export const getDifferenceDaysInWord = (fromDate, toDate, includeStartingMonth = false) => {
  let diffMonths = moment(toDate).diff(fromDate, 'month');

  if(includeStartingMonth){
    diffMonths = diffMonths + 1;
  }

  let year = 0;
  let month = 0;
  if (diffMonths >= 12) {
    const years = diffMonths / 12;
    const decimalValues = (years + "").split(".");

    if (_.size(decimalValues) > 1) {
      year = Number(decimalValues[0]);
      month = Math.round(Number(`0.${decimalValues[1]}`) * 12);
    } else {
      year = Number(decimalValues[0]);
    }
  } else if (diffMonths >= 1 && diffMonths < 12) {
    month = Math.floor(diffMonths);
  }

  return getDayWords(year, month);
}

const getDayWords = (year, month) => {

  let formattedText = "";
  if (year > 0) {
    formattedText = `${convertNumberToWords(year)} ${getPeriodText(year, 'year')}`;
  }

  if (month > 0) {
    let addText = _.isEmpty(formattedText) ? '' : `${formattedText} and`;
    formattedText = `${addText} ${convertNumberToWords(month)} ${getPeriodText(month, 'month')}`;
  }

  return formattedText
}

const getPeriodText = (value, text) => {
  return value > 1 ? `${text}s` : text;
}

// Define the convertNumberToWords function
const convertNumberToWords = (number) => {

  if (number in numbersToWords) return numbersToWords[number];

  let words = "";

  if (number >= 100) {
    words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

    number %= 100;
  }

  // If the number is greater than zero, handle the remaining digits
  if (number > 0) {
    if (words !== "") words += " and ";

    if (number < 20) words += numbersToWords[number];
    else {
      words += numbersToWords[Math.floor(number / 10) * 10];

      // If the ones place is not zero, add the word form of the ones place
      if (number % 10 > 0) {
        words += "-" + numbersToWords[number % 10];
      }
    }
  }

  return words;
}

// Define an object that maps numbers to their word form
const numbersToWords = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen",
  16: "sixteen",
  17: "seventeen",
  18: "eighteen",
  19: "nineteen",
  20: "twenty",
  30: "thirty",
  40: "forty",
  50: "fifty",
  60: "sixty",
  70: "seventy",
  80: "eighty",
  90: "ninety",
};

export const isForecastViewChanged = (currentView, bookmark, defaultView) => {
  const ignoreKeys = [
    'dimensionConfigsByRenderType',
    'forecastPrepareData',
    'bookmarkId',
    'currentDrilldownDimensionField',
    'selectedForecastMetric'
  ];
  const viewForecastOption = getForecastOptions(currentView);
  const newViewOptions = _.omit(viewForecastOption, ignoreKeys);
  if(_.isEmpty(bookmark)){
    const defaultForecastOption = getForecastOptions(defaultView);
    const defaultOptions = _.omit(defaultForecastOption, ignoreKeys);
    return (!_.isEqual(defaultOptions, newViewOptions));
  }else{
    const { forecastOptions } = bookmark;
    const newForecastOptions = _.omit(forecastOptions, ignoreKeys);
    return (!_.isEqual(newForecastOptions, newViewOptions));
  }
}

export const getForecastOptions = (currentView) => {
  return _.pick(
    currentView,
    'selectedForecastTemplateId',
    'selectedForecastMetric',
    'futureForecastDateRange',
    'currentForecastDateRange',
    'currentChartView',
    'axisGranularity',
    'forecastModelOptions',
    'currentDrilldownTemplateId',
    'projectionAdjustedValues',
    'currentDrilldownDimensionField',
    'dimensionConfigsByRenderType',
    'currentSecondaryMetricField'
  )
}

export const getForecastTitle = (currentDrilldownViewEntry) => {
  return `${getPrimaryMetricName(currentDrilldownViewEntry)} forecast`
}


export const getForecastChartAttributes = (options) => {
  const {
    commonFilters,
    forecastOptions,
    viewEntry,
    templateId
  } = options;
  const {
      futureForecastDateRange,
      forecastModelOptions,
      forecastPrepareDataTime,
      projectionAdjustedValues,
      currentForecastDateRange,
      axisGranularity,
      currentDrilldownDimensionField,
      dimensionConfigsByRenderType,
      currentChartView,
      isUpdateChartData
    } = forecastOptions;
  const currentSelectedTimeFrame = OVERTIME_TIME_FRAME_OPTIONS.ROLLING
  const isComboChart =  isComboChartEnable(viewEntry, currentChartView)
  const fiscalYearEnd = moment().year() + 1;
  let projectionPercent = [];
  const currentProjections = _.map(forecastModelOptions, (modelDatum) => {
    if (modelDatum.type == FORECASTING_TYPE.HISTORICAL_AVG) {
      projectionPercent.push(modelDatum.value)
      return HISTORICAL_FORECAST;
    } else if (modelDatum.type ==  FORECASTING_TYPE.SIMPLE_EXPONENTIAL) {
      return EXPONENTIAL_FORECAST;
    }
    else {
      return PROPHET_FORECAST;
    }
  });
  const forecastingOption = {
    projectionFutureMonth: _.get(futureForecastDateRange, 'forecastMonth', getDateRangeStartMonth()),
    projectionFutureYear: _.get(futureForecastDateRange, 'forecastYear', fiscalYearEnd),
    projectionTypes: currentProjections,
    futureForecastDateRange,
    forecastModelOptions: forecastModelOptions
  }
  // For Advance forecast data is always fetched to current date range.
  let dateRange = _.cloneDeep(_.get(currentForecastDateRange, 'dateRange', {}));
  dateRange.endDate =  moment().format(DATE_FORMAT);

  const props = {
    commonFilters: {...commonFilters, dateRange: dateRange },
    drilldown: {
      currentViewEntryField: _.get(viewEntry, 'view_id'),
      currentDrilldownTemplateId: templateId,
      currentDrilldownViewEntry: viewEntry,
      currentDrilldownDimensionField
    },
    currentDrilldownViewEntry: viewEntry,
    axisGranularity,
    currentDrilldownTemplateId: templateId,
    dimensionConfigsByRenderType,
    currentSelectedTimeFrame,
    currentChartView,
    isForecasting: true,
    forecastDateRange: _.get(currentForecastDateRange, 'dateRange', {})
  }

  const lineChartAttributes = {
    ...getLineChartAttributes(props, { isComboChart }, false),
    render: (chart) => chart,
    dateType: _.get(commonFilters, 'dateType', configuredDefaultDateType),
    isForecastingView: true,
    forecastingOption: forecastingOption,
    forecastPrepareDataTime: forecastPrepareDataTime,
  };

  lineChartAttributes['benchMarkEntries'] = [];
  lineChartAttributes['isComparisonEnabled'] = false;
  lineChartAttributes['projectionPercent'] = projectionPercent;
  lineChartAttributes['projectionType'] = _.size(currentProjections) > 1 ?
    'Multiple' : _.first(currentProjections);
  lineChartAttributes['projectionEnabled'] = !_.isEmpty(forecastModelOptions);
  lineChartAttributes['projectionAdjustedValues'] = projectionAdjustedValues;
  lineChartAttributes['sliderRange'] = [];
  lineChartAttributes['viewMode'] = VIEW_MODE.SMALL;
  lineChartAttributes['isUpdateChartData'] = isUpdateChartData;
  return lineChartAttributes;
}

export const getAdjustedPeriodValues = (tablePrepareData) => {
  if (_.isEmpty(tablePrepareData)) {
    return []
  }

  const prepareFormatData = _.cloneDeep(tablePrepareData);
  let adjustedValues = [];
  _.each(prepareFormatData, (datum) => {
    const haveAdjustValue = !_.isEmpty(`${_.get(datum, 'adjustValue', '')}`);
    const adjustValue = haveAdjustValue ? Number(_.get(datum, 'adjustValue', 0)) : '';
    const note = _.get(datum, 'note', '');

    if( haveAdjustValue || !_.isEmpty(note)){
      adjustedValues.push({
        isAccumulated: datum['isAccumulated'],
        accumulateValue: datum['accumulateValue'],
        metricId: datum['type'],
        period: datum.period,
        value: adjustValue,
        uniqueId: datum['uniqueId'],
        note: note
      })
    }
  })

  return adjustedValues;
}


const updateAdjustedValueToData = (data, projectionAdjustedValues, options, uniqueId) => {
  const {
    isDiscreteMode, isBurnup, isComboChart, primaryMetricRenderType, type
  } = options;
  let accumulateValue = 0;
  const formattedData = _.map(_.flatten(data), (datum) => {
    const newDatum = {};
    let availableDataItem = _.find(projectionAdjustedValues, (dataItem) => {
      return (
        moment(dataItem.period).isSame(datum.period)
      );
    });
    const value = Number(_.get(datum, 'value', 0));

    accumulateValue = accumulateValue + (value || 0);
    newDatum['value'] = value;
    newDatum['period'] = datum.period;
    newDatum['adjustValue'] = _.get(availableDataItem, 'adjustValue', _.get(availableDataItem, 'value', ''));
    newDatum['isAccumulated'] = (isDiscreteMode && isBurnup);
    newDatum['accumulateValue'] = accumulateValue;
    newDatum['note'] = _.get(availableDataItem, 'note', '')
    newDatum['type'] = type;
    newDatum['uniqueId'] = uniqueId;
    newDatum['renderType'] = isComboChart ? primaryMetricRenderType : 'line';
    return newDatum;
  })
  return formattedData;
}

export const getForecastFormattedData = (forecastPrepareData, forecastAttributeOptions) => {
  if(_.isEmpty(forecastPrepareData)){
    return [];
  }
  const {
    currentChartView,
    selectedForecastMetric,
    isBurnup,
    isDiscreteMode,
    projectionAdjustedValues
  } = forecastAttributeOptions;
  const vizOptions = {
    renderType: currentChartView,
    viewEntry: selectedForecastMetric
  }
  const primaryMetricRenderType = getComboChartPrimaryMetricRenderType(vizOptions);
  const comboMetricConfigs = _.get(forecastPrepareData, 'comboMetricConfigs', []);
  const isComboChart = isComboChartEnable(selectedForecastMetric, currentChartView);
  let formattedData = [];
  if (!_.isEmpty(forecastPrepareData) && !_.isEmpty(forecastPrepareData.current.total)) {
    formattedData = _.flatten(forecastPrepareData.current.total);
    // formattedData = _.concat(formattedData, _.flatten(forecastPrepareData.projection.total));
    // let accumulateValue = 0;
    const options = {
      isComboChart, isDiscreteMode, isBurnup, primaryMetricRenderType,
      type: 'total'
    };
    formattedData = updateAdjustedValueToData(formattedData, projectionAdjustedValues, options, 'total');
  }
  // let comboAccumulateValue = 0;
  if(!_.isEmpty(forecastPrepareData) && !_.isEmpty(forecastPrepareData.current.combo_metrics)) {
    _.each(forecastPrepareData.current.combo_metrics, (comboMetric) => {
      _.each(comboMetric, (data, metricField) => {
        const comboMetricConfig = _.find( comboMetricConfigs, {metricField}) || {};
        const metricEntry = _.get(comboMetricConfig, 'metricEntry');
        // comboAccumulateValue = 0;
        const options = {
          isDiscreteMode, isBurnup, isComboChart: true,
          type: 'comboSecondary',
          primaryMetricRenderType:  _.get(metricEntry, 'render_type', 'line')
        };
        data = updateAdjustedValueToData(data, projectionAdjustedValues, options, metricField);
        formattedData = formattedData.concat(data);
      });
    });
  }
  return _.sortBy(formattedData, ['uniqueId', 'period']).reverse();
}

export const defaultPrepareDataAxisGranularityOption = (templateId) => {
  const axisGranularityOptions = getAxisGranularityOptions(templateId, true);
  const axisGranularityValues = _.map(axisGranularityOptions, 'value');
  const defaultOptions =_.find(AXIS_GRANULARITY_TYPES, (option) => {
    return (_.includes(axisGranularityValues, option['value']) &&
      option['value'] != AXIS_GRANULARITY_TYPES[0].value)
  });
  return _.get(defaultOptions, 'value') || _.get(axisGranularityOptions, '[0].value');
}

export const isModalAvailable = (templateId, viewId, type) => {
  const viewEntry = getCurrentViewEntry(templateId, viewId);
  return _.get(
    _.find(viewEntry['advanced_forecasting_modals'], {type: type.toLowerCase()}),
  'value', 'false') == 'true';
}

export const getCurrentQuarterStart =(date)=>{
  return new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 1);
}

export const getCurrentQuarterEnd =(date)=>{
  return new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3 + 3, 0);
}

export const updatePreviousForecastUnsavedPreparedData = (formattedData, tableData) => {
  const indexedTableData = _.keyBy(tableData, dataItem => moment(dataItem.period).format());
  const updatedData =  _.each(_.cloneDeep(formattedData), (datum) => {
    const formattedPeriod = moment(datum.period).format();
    const matchedData = indexedTableData[formattedPeriod];
    if (matchedData && datum['adjustValue'] !== matchedData['adjustValue']) {
      datum['adjustValue'] = matchedData.adjustValue || matchedData.value || '';
    }
  });
  return updatedData;
}