import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NoDataWrapper } from './NoDataWrapper';
import { getSupportLink } from 'common/config/customerConfiguration';

class QueryFailed extends Component {
  render(){
    const { viewMode } = this.props;
    const noDataClassName = classNames({'no-data-lg': _.isEqual(viewMode, 'large')})

    return (
      <div className={`no-data ${noDataClassName}`}>
        <img className='no-data-img' alt='Query Failed' src="../images/query_failed.png" />
        <p className="forge-typography--headline4 my-4"> Oops! No data returned.</p>
        <div className="forge-typography--headline5">
          <span>Please</span> <a href={getSupportLink()} target="_blank" rel="noreferrer">contact Support.</a>
        </div>
      </div>
    );
  }
}

QueryFailed.propTypes = {
  viewMode: PropTypes.string,
  statusCode: PropTypes.bool
};

export default NoDataWrapper(QueryFailed);