import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import InlineEdit from './InlineEdit';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getWidthAndHeightForFilterBadge } from 'common/components/Filters/FilterDomHelper';
import { getElementTransformAndWidth } from 'helpers/DomPageHelper';
import { ForgeIcon } from '@tylertech/forge-react';

const InlineEditWithLabel = ({ name, onInputChange, onlyClickIcon, keyIndex }) => {
  const [showEditInput, setShowEditInput] = useState(false);
  const inlineEditRef = useRef(null);

  const onClickLabel = () => {
    if(!onlyClickIcon) {
      setShowEditInput(true);
    }
  }

  const onClickPencilIcon = () => {
    setShowEditInput(true);
  }

  const handlePressEditInputButton = (e) => {
    if (isEnterButtonPressed(e)) {
      setShowEditInput(true);
    }
  }

  const handleInputChange = (inputValue) => {
    onInputChange(inputValue);
    setShowEditInput(false);
  }
  let inlineEditorStyle = {};
  if(keyIndex == 1 && showEditInput) {
    inlineEditorStyle = getElementTransformAndWidth();
  }
  const style = showEditInput ? getWidthAndHeightForFilterBadge(inlineEditRef) : {};
  const showClassName = showEditInput ? 'show' : '';

  return (
    <div style={{ width: style.width }} ref={inlineEditRef} className={showClassName}>
      <InlineEdit
        style={inlineEditorStyle}
        onEditClose={() => setShowEditInput(false)}
        onInputChange={handleInputChange}
        enableInputEdit={showEditInput}
        defaultText={name}
      >
        <span tabIndex={0}
          className="viz-title"
          onClick={onClickLabel}
          onKeyDown={handlePressEditInputButton}
          aria-label={`inline-edit-${name}`}>
          <span className='mr-2'>{name}</span>
          <ForgeIcon name="mode_edit" className="edit-icon" onClick={onClickPencilIcon} />
        </span>
      </InlineEdit>
    </div>
  );
}

InlineEditWithLabel.defaultProps = {
  name: '',
  onlyClickIcon: false,
  keyIndex: 0
};

InlineEditWithLabel.propTypes = {
  name: PropTypes.string,
  onlyClickIcon: PropTypes.bool,
  onInputChange: PropTypes.func,
  keyIndex: PropTypes.number
};

export default InlineEditWithLabel;
