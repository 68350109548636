// Vendor Imports
import _ from 'lodash';
import React from 'react';

// Project Imports
import { getYAxisDatePeriod, getSelectedXAxisData } from './helper';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import { lineChartFlyoutFormatter } from './lineChartFlyoutFormatter';
import FlyoutPopupTableContent from './FlyoutPopupTableContent';

export const getFlyoutContent = (data, options) => {
  const { renderTimeFrame, compareYearRanges, axisGranularity, dateRange } = options;

  options['disabledDimensions'] = shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges);

  const pointData = _.head(data);
  const period = getYAxisDatePeriod(pointData, dateRange, axisGranularity);
  const filteredData = getSelectedXAxisData(data);

  const { tableHeaders, tableData } = lineChartFlyoutFormatter(filteredData, options)

  return (
    <FlyoutPopupTableContent
      tableHeaders={tableHeaders}
      tableData={tableData}
      period={period}
      options={options} />
  )
}