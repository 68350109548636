import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  getBenchMarkEntries,
  getMonitorConditionOptions,
  getConditionDisplayName
} from 'pages/SubscriptionsManager/components/Bookmark/helper';
import { getDimensionEntries } from 'common/config/templateConfiguration';

import {
  DEFAULT_DROPDOWN_OPTION,
  DEFAULT_BOOKMARK_DIMENSION_OPTION,
  NONE_DIMENSION_FIELD
} from 'appConstants';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import MonitorConditionValueSelector from './MonitorConditionValueSelector';
import CheckBox from 'common/components/CheckBox';

class Monitors extends Component {
  constructor(props){
    super(props);
    this.state = {
      isCheckedForDimension: _.get(props, 'dimensionOption') !== DEFAULT_BOOKMARK_DIMENSION_OPTION,
      dimensionOption: _.get(props, 'dimensionOption')
    };
  }

  getDimensionOption = () => {
    const { currentDrilldownTemplateId, defaultDimensionField } = this.props;
    const { dimensionOption } = this.state;
    let dimensionOptions = [];
    const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
    _.each(dimensionsEntries, (entry) => {
      dimensionOptions.push({
        type: entry.field,
        name: entry.name
      })
    });
    const newDimensionOption = dimensionOption === DEFAULT_BOOKMARK_DIMENSION_OPTION ?
      defaultDimensionField :
      dimensionOption;
    const option = _.find(dimensionOptions, { type: newDimensionOption });
    return { dimensionOption: option, dimensionOptions };
  }

  onHandleDimensionTypeChange = (dimensionOption) => {
    const { isCheckedForDimension } = this.state;
    const newDimensionOption = _.get(dimensionOption, 'type', DEFAULT_BOOKMARK_DIMENSION_OPTION)
    this.setState({ dimensionOption: newDimensionOption });

    if(isCheckedForDimension) {
      this.props.onHandleDimensionTypeChange(dimensionOption);
    }
  }

  onCheckBoxChange = (isChecked) => {
    this.setState({ isCheckedForDimension: isChecked }, () => {
      if(isChecked) {
        const { dimensionOption } = this.getDimensionOption();
        this.props.onHandleDimensionTypeChange(dimensionOption);
      } else {
        this.props.onHandleDimensionTypeChange({
          'type': DEFAULT_BOOKMARK_DIMENSION_OPTION, name: 'all selected data'
        });
      }
    })
  }

  addMonitorCondition = (condition) => {
    const {
      onMonitorConditionsChange,
      onEmailStrategyChange,
      currentDimensionTotal
    } = _.cloneDeep(this.props);

    const newCondition = {
      ...condition,
      enable: true,
      value: currentDimensionTotal
    }
    if(condition.type == 'records'){
      onEmailStrategyChange(condition.strategy, condition.newlyCreatedRecordValue);
    }
    onMonitorConditionsChange([newCondition]);
  }

  handleMonitorValueChange = (e, index, valueKey) => {
    const newMonitorConditions = _.cloneDeep(this.props.monitorConditions);
    if (e.target.validity.valid) {
      newMonitorConditions[index][valueKey] = e.target.value;
      this.props.onMonitorConditionsChange(newMonitorConditions);
    }
  }

  renderForDimensionCheckbox = () => {
    const { monitorConditions, defaultDimensionField } = this.props;
    const { isCheckedForDimension } = this.state;
    if(_.get(monitorConditions, '[0].type') == 'records' || defaultDimensionField == NONE_DIMENSION_FIELD ){
      return null;
    }

    return (
      <div className='d-flex mt-2'>
        <CheckBox
          id="for-dimension-checkbox"
          index="0"
          isChecked={isCheckedForDimension}
          name="for any"
          onChange={(isChecked) => this.onCheckBoxChange(isChecked)}
        />
        <div className='pl-1'>
          {this.renderDimensionOptions()}
        </div>
      </div>
    );
  }

  renderDimensionOptions() {
    const { monitorConditions } = this.props;
    if(_.get(monitorConditions, '[0].type') == 'records' ){
      return null;
    }
    const { dimensionOption, dimensionOptions } = this.getDimensionOption();

    return(
      <div className="d-flex mt-2">
        <SolutionDropDown
          className="ml-1 xl-overflow email-strategy-type border-0"
          size="sm"
          title={_.get(dimensionOption, 'name')}
          options={dimensionOptions}
          onSelect={this.onHandleDimensionTypeChange} />
      </div>
    )
  }

  renderMonitorConditionOptionDropDown() {
    const { drilldown, currentVizChartType, visualization } = this.props;
    const { currentDrilldownViewEntry } = drilldown;
    const currentBenchMarkEntries  = getBenchMarkEntries(drilldown, visualization, currentVizChartType);
    const conditionsOptions = getMonitorConditionOptions(currentDrilldownViewEntry, currentBenchMarkEntries);

    return (
      <SolutionDropDown
        className="add-condition border-0"
        size="sm"
        selectedOption=""
        options={conditionsOptions}
        onSelect={this.addMonitorCondition} />
    );
  }

  renderPercentage(monitorCondition, index) {
    if(monitorCondition.operator != '%') {
      return null
    }
    const currentBenchMarkName =  _.get(monitorCondition,'benchmarkName','');
    if(monitorCondition.type == 'benchmark') {
      return (
        <label className="ml-2 font-weight-bold text-nowrap">
          percent of {currentBenchMarkName}
        </label>
      );
    } else {
      return (
        <>
          <label className="text-primary text-nowrap"> percent of </label>
          <input
            type="text"
            pattern="^\-?[0-9]\d*\.?\d*$"
            value={monitorCondition.percentValue}
            className="form-control w-25"
            onChange={(e) => this.handleMonitorValueChange(e, index, 'percentValue')} />
        </>
      );
    }
  }

  renderValueField(monitorCondition, index) {
    const { drilldown } = this.props;
    const { currentDrilldownViewEntry } = drilldown;

    if((monitorCondition.operator != '%' && monitorCondition.type == 'benchmark') ||
        monitorCondition.type == 'records'){
      return null
    }

    const monitorConditionValueAttributes = {
      index,
      value: monitorCondition.value,
      viewEntry: currentDrilldownViewEntry,
      handleMonitorValueChange: this.handleMonitorValueChange,
    };

    return(<MonitorConditionValueSelector {...monitorConditionValueAttributes} />);
  }

  isDisableBenchMarkEntry(monitorCondition) {
    const { drilldown, visualization } = this.props;
    const { currentVisualizationType } = drilldown;
    const selectedBenchmarkNames = _.get(visualization,
      `${currentVisualizationType}.currentBenchMarkMetricNames`);

    const benchMarkName = _.find(selectedBenchmarkNames, (name) => {
       return name ===_.get(monitorCondition,'benchmarkName','')
    });

    const disableBenchMarkEntry = ((_.isEmpty(benchMarkName) ||
        _.isEqual(benchMarkName, DEFAULT_DROPDOWN_OPTION.name)) &&
        monitorCondition.type == 'benchmark');
    return disableBenchMarkEntry;
  }

  renderMonitor(monitorCondition, index) {
    const { drilldown } = this.props;
    const { currentDrilldownViewEntry = {} } = drilldown;
    const benchMarkName =  _.get(monitorCondition,'benchmarkName','');
    const disableBenchMarkEntry = this.isDisableBenchMarkEntry(monitorCondition);
    // const monitorClassNames = classNames(
    //   'd-flex align-items-center mb-3',
    //   { 'disabled':  this.isDisableBenchMarkEntry(monitorCondition)}
    // );

    return (
      <>
        <div
          key={index}
          tabIndex={disableBenchMarkEntry ? -1 : 0}>
          <label className="text-primary text-nowrap">
            {getConditionDisplayName(currentDrilldownViewEntry, benchMarkName, monitorCondition)}
          </label>
        </div>
        {this.renderValueField(monitorCondition, index)}
        {this.renderPercentage(monitorCondition, index)}
      </>
    );
  }

  renderMonitorConditions() {
    const { monitorConditions } = _.cloneDeep(this.props);
    const monitorConditionList = monitorConditions.map((condition, index) => {
      return this.renderMonitor(condition, index);
    });
    return (
      <div>
        <div>Email me when</div>
        <div className="monitor-filter mt-3">
          {monitorConditionList}
          {this.renderMonitorConditionOptionDropDown()}
        </div>
        {this.renderForDimensionCheckbox()}
      </div>
    )
  }

  render() {
    return this.renderMonitorConditions()
  }
}

Monitors.propTypes = {
  monitorConditions: PropTypes.array,
  onMonitorConditionsChange: PropTypes.func,
  onEmailStrategyChange: PropTypes.func,
  currentDrilldownTemplateId: PropTypes.string,
  dimensionOption: PropTypes.string,
  onHandleDimensionTypeChange: PropTypes.func,
  drilldown: PropTypes.object,
  currentVizChartType: PropTypes.string,
  visualization: PropTypes.object,
  defaultDimensionField: PropTypes.string
}

export default (Monitors);
