import React from 'react'
import PropTypes from 'prop-types'
import { getAvailableVisualizationType } from 'helpers/visualizationHelper';
import { ForgeIcon, ForgeTooltip } from '@tylertech/forge-react';
import { METRIC_CHART_ICONS, VISUALIZATION_TYPES } from 'appConstants';

function MetricIcon(props) {
  const { templateEntry, viewEntry, isCardExist } = props;

  const availableVizType = getAvailableVisualizationType(
    _.get(templateEntry, 'template_id'),
    _.get(viewEntry, 'visualization.default_view'),
    viewEntry
  );

  const renderIcons = () => {
    if (isCardExist) {
      return (<>
        <img className='forge-metric-icon' alt='check' src="../images/check-icon.svg" />
        <ForgeTooltip delay="500" position="top">
          This metric is already in the collection.
        </ForgeTooltip>
      </>)
    }
    if (VISUALIZATION_TYPES.TABLE.type === availableVizType) {
      return <ForgeIcon className="forge-metric-icon" name="table" />
    } else if (VISUALIZATION_TYPES.DISTRIBUTION.type === availableVizType) {
      return <ForgeIcon className="forge-metric-icon" name="bar_chart" />
    } else if (VISUALIZATION_TYPES.TIME_OF_DAY.type === availableVizType) {
      return <ForgeIcon className="forge-metric-icon" name="timer" />  // need to change icon
    } else if (_.includes([VISUALIZATION_TYPES.OVERTIME.type,
    VISUALIZATION_TYPES.SNAPSHOT.type, VISUALIZATION_TYPES.MAP.type], availableVizType)) {
      const viewChartType = _.get(viewEntry, `visualization.${availableVizType}.default_view`, '');
      const defaultIconType = _.find(METRIC_CHART_ICONS, { chartType: viewChartType });
      const iconName = _.get(defaultIconType, 'icon', '');

      return <i className={`forge-metric-icon ${iconName}`}></i>
    } else {
      return <ForgeIcon className="forge-metric-icon" name="icons-chart-bar-horizontal" />
    }
  }

  return renderIcons()
}

MetricIcon.propTypes = {
  templateEntry: PropTypes.object,
  viewEntry: PropTypes.object,
  isCardExist: PropTypes.bool
}

export default MetricIcon
