import React from 'react';
import PropTypes from 'prop-types';
import { ForgeTextField } from '@tylertech/forge-react';

const ForgeInputTextField = (props) => {

  const {
    id,
    type,
    label,
    placeholderLabel,
    hasLabel,
    hasPlaceholder,
    isDisabled,
    handleOnChange,
    handleOnBlur,
    value,
    helperText,
    hasHelperText,
    className,
    ariaLabel
  } = props;

  const density = 'default',
    floatLabelType = 'auto',
    shape = 'default',
    invalid = false,
    required = false;

  return (
    <ForgeTextField
      density={density}
      floatLabelType={floatLabelType}
      shape={shape}
      invalid={invalid}
      className={className}
      required={required}>
      <input
        autoComplete="off"
        type={type}
        id={id}
        value={ value}
        disabled={isDisabled}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        aria-label={ariaLabel}
        placeholder={hasPlaceholder ? placeholderLabel : undefined} />
      {hasLabel && <label htmlFor="input-text" slot="label">{label}</label>}
      {hasHelperText && <span slot="helper-text">{helperText}</span>}
    </ForgeTextField>
  );
}

ForgeInputTextField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholderLabel: PropTypes.string,
  hasLabel: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasPlaceholder: PropTypes.bool,
  handleOnChange: PropTypes.func,
  value: PropTypes.any,
  helperText: PropTypes.string,
  className: PropTypes.string,
  hasHelperText: PropTypes.bool,
  ariaLabel: PropTypes.string,
  handleOnBlur: PropTypes.func
}

ForgeInputTextField.defaultProps = {
  hasLabel: true,
  hasPlaceholder: true,
  hasHelperText:false,
  handleOnChange: _.noop,
  handleOnBlur: _.noop
}

export default (ForgeInputTextField);
