import React from 'react';
import PropTypes from 'prop-types';
import { getEmailArrivalDateTime } from 'helpers/frequencyDateTimeHelper';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const EmailArrivalText = (props) => {
  const options = _.pick(props,
    'emailSendingDays',
    'frequency',
    'monthlyWeekNumber',
    'startDate',
    'timePeriodDay',
    'regularityType',
    'timeZone',
    'frequencyInterval',
    'emailSendingTime'
  );

  const isSettingsPage = useRouteMatch("/app/subscriptions");
  // const { regularityType, timePeriodDay, frequency } = props

  const emailArrivalDate = getEmailArrivalDateTime(options);
  // const isLastDay = (Number(timePeriodDay) >= 29);
  let emailText;
  if(isSettingsPage){
    emailText = `Your next email will arrive on ${emailArrivalDate}`;
  }
  else
  {
    emailText = `Your first email will arrive on ${emailArrivalDate}`;
  }
  // if (isLastDay && regularityType === 'time_period' && frequency === 'month'){
  //   emailText = `${emailText}. For months with fewer than ${Number(timePeriodDay)} days, ` +
  //     `the email will arrive on the last day of the month.`;
  // }

  return (
    <div>{emailText}</div>
  );
}

EmailArrivalText.propTypes = {
  emailSendingTime: PropTypes.string,
  frequency: PropTypes.string,
  frequencyInterval: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  timeZone: PropTypes.string,
  regularityType: PropTypes.string,
  timePeriodDay: PropTypes.number,
  onSettingValueChange: PropTypes.func
}

export default EmailArrivalText;
