// Vendor Imports
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
// Project Imports
import configureStore from './Store';
import EmbedVisualization from './pages/embed/EmbedVisualization';
import './MainStyle';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const Embed = (props) => (
  <Provider store={configureStore(props)}>
    <HashRouter hashType="hashbang">
      <EmbedVisualization />
    </HashRouter>
  </Provider>
);

export default Embed;
