// Vendor Imports
import _ from 'lodash';

// Project Imports
import { getVisualizationTotalData } from 'common/api/drilldown';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getTargetBenchMarkEntry } from 'common/config/viewConfiguration';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getYearTextByRange } from 'helpers/dateHelper';
import {
  getFilterSecondaryMetrics,
  metricTotalFormatter
} from 'helpers/metricTotalHelper';
export const getMetricTotalData = (options, abortFetch) => {
  const {
    queryParams, viewEntry, isComparison,
    anyoneHaveComparison, comparisonDateRanges,
    dateRangeMode
  } = options;
  const secondaryMetrics = _.map(getFilterSecondaryMetrics(options), (metric) => {
    return _.pick(metric, 'field', 'isComparePrevious', 'secondaryDateRange');
  });

  const newQueryParams = {
    ...queryParams,
    isComparison,
    comparePrevious: anyoneHaveComparison,
    compareYearRanges: JSON.stringify(comparisonDateRanges),
    secondaryMetricFields: JSON.stringify(secondaryMetrics),
    targetBenchMarkEntry: JSON.stringify(getTargetBenchMarkEntry(viewEntry))
  };

  return fetchApiData(getVisualizationTotalData(newQueryParams), abortFetch).then(response => {
    const currentMetricData = response[0];
    const comparisonDatesMetricTotals = {}
    _.each(comparisonDateRanges, (dateRange, index) => {
      const yearText = getYearTextByRange(dateRange, dateRangeMode);
      comparisonDatesMetricTotals[yearText] = metricTotalFormatter(response[index+1], options);
    });
    return {
      currentPeriodMetricTotals: metricTotalFormatter(currentMetricData, options),
      drilldownDimensionTotals: getDrilldownDimensionTotals(response, options),
      comparisonDatesMetricTotals: comparisonDatesMetricTotals
    };
  }).catch((error) => {
    if (error.name !== 'AbortError') {
      console.log("Error on fetching Totals data", error);
    }
    return {
      currentPeriodMetricTotals: 0,
      drilldownDimensionTotals: [],
      comparisonDatesMetricTotals: {},
      isError: true
    };
  });
}

const getDrilldownDimensionTotals = (data, options) => {
  const { isComparison, viewEntry } = options;
  const filteredData = _.chain(data).
    map((datum, index) => {
      const { total } = datum;
      return (isComparison && index == 1) ? null : {
        ...datum,
        formattedTotalValue: getFormattedNumberValue(total, viewEntry, true, false, false, false)
      };
    }).
    without(null).
    value();
  const firstData = _.first(filteredData);
  const lastData = _.last(filteredData);
  let newDrilldownDimensions = [lastData];
   _.chain(1).
    range(filteredData.length - 1).
    each((range) => {
      if (filteredData[range]) {
        newDrilldownDimensions.push(filteredData[range]);
      }
    }).
    value();
  newDrilldownDimensions.push(firstData);

  return newDrilldownDimensions;
}