import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as commonPropTypes from 'common/propTypes';

import Pagination from 'common/components/Pagination/Pagination';
import Table from 'modules/DetailsTable/Table';
import LeafPageDrawer from '../LeafPage/LeafPageDrawer';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';

import { getInspectViewData } from 'common/api/drilldown';
import { getApiParams } from 'helpers/apiParamsHelper';
import { getRowsLimitOptions } from 'modules/DetailsTable/TableHelper';
import { updateStoreToUrl } from 'helpers/UrlParamsHelper';
import {
  getCurrentTemplateEntry,
  getTableColumnEntries,
  getLeafPageEntries
} from 'common/config/templateConfiguration';
import {
  updateLeafPageRecordIndex,
  updateTableSortColumns,
  updateShowTableViewOnDrilldown,
  updateShowLeafPage,
  updateLeafPageNoteId,
  updateTableLeafData
} from 'actions/tableActions';
import { fetchApiDataWithStatus } from 'helpers/apiResponseHelper';
import ErrorHandler from 'common/components/NoDataFound/ErrorHandler';
import GlobalEvent from 'common/components/GlobalEvents';
import ViewMetricLink from 'pages/embed/ViewMetricLink';
import VisualizationDescription from 'pages/drilldown/visualizations/VisualizationDescription';
import {
  allowLeafPageOnTableClickByTemplate
} from 'helpers/visualizationHelper';

class TableVisualization extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      tableData: [],
      currentPage: 0,
      totalRecordsCount: 0,
      itemsPerPage: 10,
      selectedTableRowData: {},
      showSideBar: false,
      rowEntry: {}
    };
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    const { isLeafPage } = this.props;
    if(!isLeafPage){
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLeafPage } = this.props;
    const isCurrentPageUpdated = !_.isEqual(this.state.currentPage,prevState.currentPage);
    const isApiParamsChanged = !_.isEqual(prevProps.apiParams, this.props.apiParams);
    const isSortByColumnsChanged = !_.isEqual(prevProps.sortColumns, this.props.sortColumns);
    const isLimitChanged = !_.isEqual(prevState.itemsPerPage, this.state.itemsPerPage);
    const isLeafPageUpdated = !_.isEqual(prevProps.isLeafPage, isLeafPage) && !isLeafPage;
    if(isApiParamsChanged) {
      this.setState({ currentPage: 0 });
    }
    if (
      isApiParamsChanged ||
      isCurrentPageUpdated ||
      isSortByColumnsChanged ||
      isLimitChanged ||
      isLeafPageUpdated
      ) {
      if(!isLeafPage){
        this.fetchData();
      }
    }
  }

  componentWillUnmount(){
    this.abortFetchController.abort();
  }

  fetchData = () => {
    const { apiParams, sortColumns } = this.props;
    const { currentPage, itemsPerPage } = this.state;
    const queryParams = _.merge({}, apiParams, {
      offset: currentPage,
      limit: itemsPerPage,
      sortColumns: JSON.stringify(sortColumns)
    });
    const tableDataApi = getInspectViewData(queryParams);
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    this.setState({ isLoading: true });
    fetchApiDataWithStatus(tableDataApi, this.abortFetchController).then( (response) => {
      const tableData = _.get(response, 'api_data.data' , []);
      this.setState({
        tableData,
        isValid: response.isValid,
        statusCode: response.statusCode,
        isLoading: false,
        totalRecordsCount: _.get(response, 'api_data.count' , 0)
      });

      if(_.size(tableData) === 1){
        this.skipToNextLevel(tableData[0]);
      }
    }, (error) => {
      if (error.name !== 'AbortError') {
        console.error("Error on fetching data", error);
        this.setState({ isLoading: false });
      }
    });
  }

  handleSwitchPage = (switchDirection) => {
    let { currentPage } = this.state;
    this.setState({
      currentPage : (switchDirection === 'next') ? (currentPage+1) : (currentPage-1)
    });
  }

  onCloseSideBar = () => {
    this.setState({ showSideBar: false });
  }

  handleTableRowClick = (rowData) => {
    // For Drilldown: If getLeafPageEntries is not empty then leaf page is shown.
    const { dispatchUpdateShowLeafPageDetails } = this.props;
    const { tableData, showSideBar } = this.state;
    const selectedTableRowIndex = _.findIndex(tableData, rowData);
    const currentRecordIndex =  selectedTableRowIndex;
    const leafpageOption = {
      isLeafPage: false,
      noteId: '',
      currentRecordIndex: currentRecordIndex,
      currentLeafData: tableData
    };

    const tableOptions = { rowEntry: rowData };
    if(!showSideBar) {
      tableOptions['showSideBar'] = true;
    }

    this.setState(tableOptions, () => {
      dispatchUpdateShowLeafPageDetails(leafpageOption);
    });
  }

  skipToNextLevel = (rowEntry) => {
    const { onRowClick, canSkipHierarchy } = this.props;
    const { currentPage } = this.state

    if(canSkipHierarchy){
      onRowClick(rowEntry, currentPage);
    }
  }

  getCustomColumnEntries =  () => {
    const { currentDrilldownTemplateId } = this.props;
    return getTableColumnEntries(currentDrilldownTemplateId);
  }

  renderSpinner() {
    const { isLoading } = this.state;
    if(!isLoading){
      return null;
    }
    return (
      <div className="spinner-overlay">
        <Spinner variant="primary" className="loading-spinner" animation="border" />
      </div>
    );
  }

  renderLimitDropDown() {
    const { itemsPerPage, totalRecordsCount } = this.state;

    if (totalRecordsCount <= 10) {
      return null
    }

    return (
      <div className="rows-per-page">
        Rows per page
        <SolutionDropDown
          className="p-0"
          id="rows-per-page"
          size="sm"
          title= {itemsPerPage}
          options={getRowsLimitOptions(totalRecordsCount)}
          onSelect={(option) => {this.setState({ itemsPerPage: option, currentPage: 0 })}} />
      </div>
    )
  }

  renderPagination() {
    const { currentPage, totalRecordsCount, itemsPerPage } = this.state;
    return (
      <Pagination
      currentPage={currentPage + 1}
      itemsPerPage={itemsPerPage}
      totalRecordsCount={totalRecordsCount}
      switchPage={this.handleSwitchPage} />
    );
  }

  isTableRowClickEnabled = () => {
    const { currentDrilldownTemplateId, isEmbed } = this.props;
    if (isEmbed) {
      return false;
    }
    const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
    return allowLeafPageOnTableClickByTemplate(templateEntry) &&
      !_.isEmpty(getLeafPageEntries(currentDrilldownTemplateId));
  }

  renderViewSource() {
    const { state, isEmbed } = this.props;
    const url = updateStoreToUrl('!/analysis', state);
    const embedUrl = `${window.location.origin}/#${url}`;

    return (<ViewMetricLink isEmbed={isEmbed} viewMetricLink={embedUrl} />);
  }

  renderTableVisualization() {
    const { isLoading, tableData, totalRecordsCount, isValid, statusCode, showSideBar } = this.state;
    const {
      sortColumns,
      dispatchUpdateTableSortColumns,
      isLeafPage,
      currentDrilldownTemplateId,
      quickFilters,
      currentRecordIndex,
      apiParams
    } = this.props;

    if (isLeafPage) {
      return null;
    }

    if (_.isEmpty(tableData) && !isLoading) {
      GlobalEvent.emit('IS_EMPTY_DATA', {
        isEmptyData : _.isEmpty(tableData),
        isLoading: isLoading
      })
      const isNodataFound = _.isEmpty(tableData) && !isLoading && isValid;
      return <ErrorHandler isNodataFound={isNodataFound} statusCode={statusCode}/>;
    }
    const leafPageClass = showSideBar ? 'leaf-page-container' : '';

    return (
      <div className={"chartContainer viz-wrapper " + leafPageClass}>
        {this.renderSpinner()}
        {this.renderViewSource()}
        <Table
          currentRecordIndex={showSideBar ? currentRecordIndex : ''}
          templateEntry={getCurrentTemplateEntry(currentDrilldownTemplateId)}
          tableData={tableData}
          onClick={(newSortColumns) => dispatchUpdateTableSortColumns(newSortColumns)}
          sortColumns={sortColumns}
          onRowClick={this.isTableRowClickEnabled() ? this.handleTableRowClick : null}
          customColumnEntries={this.getCustomColumnEntries()}
          totalRecordsCount={totalRecordsCount}
          quickFilters={quickFilters}
          isLoadingData={isLoading}
          apiParams={apiParams}
          showSearchOption
        />
        {showSideBar &&
          <LeafPageDrawer
            onClose={this.onCloseSideBar}
            totalRecordsCount={totalRecordsCount}
            tableData={tableData}
          />
        }
        <div className="d-flex justify-content-end">
          {this.renderLimitDropDown()}
          {this.renderPagination()}
        </div>

        <div className="visualization-footer">
          <VisualizationDescription totalRowCount={totalRecordsCount}/>
        </div>
      </div>
    );
  }

  render() {

    return (
      <>
        {this.renderTableVisualization()}
      </>
    );
  }
}

TableVisualization.propTypes = {
  onClose: commonPropTypes.onClosePropTypes,
  title: PropTypes.string,
  dispatchUpdateTableSortColumns: PropTypes.func,
  apiParams: PropTypes.object,
  canSkipHierarchy: PropTypes.bool,
  isLeafPage: PropTypes.bool,
  sortColumns: PropTypes.array,
  dispatchUpdateShowTableViewOnDrilldown: PropTypes.func,
  dispatchUpdateShowLeafPageDetails: PropTypes.func,
  onRowClick: PropTypes.func,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  isEmbed: PropTypes.bool,
  state: PropTypes.object,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  currentRecordIndex: PropTypes.number
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateTableSortColumns: (newSortColumns) => {
      dispatch(updateTableSortColumns(newSortColumns));
    },
    dispatchUpdateShowLeafPageDetails: (options) => {
      dispatch(updateLeafPageRecordIndex(options.currentRecordIndex));
      dispatch(updateShowLeafPage(options.isLeafPage));
      dispatch(updateLeafPageNoteId(options.noteId));
      dispatch(updateTableLeafData(options.currentLeafData));
    },
    dispatchUpdateShowTableViewOnDrilldown: (isTableView) => {
      dispatch(updateShowTableViewOnDrilldown(isTableView));
    },
  }
}

function mapStateToProps(state) {
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);

  return {
    state,
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    templateEntries: _.get(state, 'configurations.template_entries', []),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    sortColumns: _.get(state, 'visualization.table.sortColumns', []),
    isLeafPage: _.get(state, 'visualization.table.isLeafPage', false),
    currentDrilldownGroupByEntry: _.cloneDeep(_.get(state, 'drilldown.currentDrilldownGroupByEntry', [])),
    currentDrilldownDimensionField: _.cloneDeep(_.get(state, 'drilldown.currentDrilldownDimensionField', [])),
    currentRecordIndex: _.parseInt(_.get(state, 'visualization.table.leafPageCurrentRecordIndex', 0)),
    apiParams: getApiParams(apiParamsOptions, {}),
    quickFilters: _.get(state, 'drilldown.quickFilters', []),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableVisualization);
