import React from 'react';
import PropTypes from 'prop-types';

import StackedBarRowSelector from './StackedBarRowSelector';
import FlyoutTableHeader from './FlyoutTableHeader';
import ColorWithCategorySelector from 'common/components/ColorWithCategorySelector/ColorWithCategorySelector';

const ChartFlyoutTable = (props) => {
  const { tableHeaders, isStackedBar, tableData, height } = props;

  const renderTableColumn = (datum) => {
    const { color, value: chartValue, isBenchMark } = datum;
    if (chartValue == " " || isBenchMark) {
      return null;
    }

    return _.map(tableHeaders, ({ columnField, suffix, toFixed }, index) => {
      let columnValue = _.get(datum, columnField);
      if(!_.isEmpty(toFixed)) {
        columnValue = Number(columnValue).toFixed(toFixed);
      }
      const newColor = (index === 0) ? color : '';

      return (
        <td key={`${columnField}-${index}`}>
          <ColorWithCategorySelector category={columnValue} color={newColor} suffix={suffix} />
        </td>
      );
    });
  }

  const renderTableRow = () => {
    return _.map(tableData, (datum, index) => {
      return (
        <tr key={index}>
          {renderTableColumn(datum)}
        </tr>
      )
    });
  }
  const tableBodyContent = isStackedBar ? <StackedBarRowSelector {...props} /> : renderTableRow();

  return (
    <div className="table-responsive" style={{height: `calc(${height - 45}px)` }}>
      <table className="table table-freeze-two">
        <FlyoutTableHeader tableHeaders={tableHeaders} showEmptyHead={isStackedBar} />
        <tbody>
          {tableBodyContent}
        </tbody>
      </table>
    </div>
  );
}

ChartFlyoutTable.propTypes = {
  tableHeaders: PropTypes.array,
  tableData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  groupByDimensionField: PropTypes.string,
  flyoutValueField: PropTypes.string,
  isStackedBar: PropTypes.bool,
  height: PropTypes.number
}

export default ChartFlyoutTable;
