import * as selection from '@simonwep/selection-js';

export const enableDragAndSelect = (option, onselect) => {
 return selection.create({
    class: 'selection',
    selectables: [option.selectables],
    boundaries: [option.boundaries],
    onMove(event) {
      event.selectedElements.forEach(s => s.classList.add(option.selectedClass));
      event.changedElements.removed.forEach(s => s.classList.remove(option.selectedClass));
    },
    onStop(event) {
      onselect(event.selectedElements);
    }
  });
};
