import React from 'react';
import { useHistory } from 'react-router-dom';

import BackButton from './BackButton';
import { getAdvanceSearchName } from 'common/config/customerConfiguration';
import { handleBackButtonClick } from 'helpers/visualizationHelper';

const AdvanceSearchHeader = () => {
  const history = useHistory();

  const onBackClick = () => {
    handleBackButtonClick(history)
  }

  const renderTitle = () => {
    return (
      <div className="forge-typography--title">
        {getAdvanceSearchName()}
      </div>
    )
  }

  return (
    <div className="advance-search-header">
      <div className='d-flex align-items-center gap-15'>
        <BackButton onClick={() => onBackClick(false)} />
        {renderTitle()}
      </div>
    </div>
  );
}

AdvanceSearchHeader.propTypes = {};

export default AdvanceSearchHeader;
