import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { getConfiguredDateType,
  configuredDefaultDateType,
  getDateRangeStartMonth,
  getConfiguredDefaultYear,
  getConfiguredDefaultDate,
  getConfiguredDataEndDate,
  getDayOfWeekStarts,
  isYearlyTypeDateConfigured,
  isCustomDateBeginType,
  getDateBeginPeriodCount,
  getDateBeginPeriodType,
  getDateDataBeginsFrom
} from 'common/config/customerConfiguration';

import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';

import {
  BIENNIUM_FISCAL_YEAR,
  DATE_OPTIONS_TYPE,
  DATE_FORMAT,
  RELATIVE_FILTER_OPTIONS,
  RELATIVE_FILTER_PERIOD_TYPE_ENTRIES,
  DATE_COMPARISON_TYPE,
  THIS_PERIOD_LAST_YEAR,
  CALENDAR_DATE_TYPE
} from 'appConstants';
import {
  getAllBienniumFiscalYears,
  getDefaultBienniumDateRange,
  getFiscalYearByStartMonth,
  getFiscalYearDateRangeForDate,
  getFiscalYearEnding,
  getCompareSamePeriodLastYearOptions,
  getBienniumFiscalYearLabel
} from 'helpers/fiscalPeriodUtils';
import { isBienniumFiscalYear } from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';

const moment = extendMoment(Moment);
const weekStartDayIndex = () => moment.weekdays().indexOf(_.capitalize(getDayOfWeekStarts()));

export const YEAR_DAYS_COUNT = 365;
export const MONTH_DAYS_COUNT = 31;
export const DEFAULT_FEB_DAY_COUNT = 28;
export const DEFAULT_FEB_MONT_INDEX = 1;

export const isDateColumnConfigured = (currentTemplateId) => {
  const templateEntry = getCurrentTemplateEntry(currentTemplateId);

  return !_.isEmpty(_.get(templateEntry, 'fields.date_column', ''));
};


const isEndDateAfterTodayDate = ({ endDate, startDate }) => {
  const todayDate = moment().format(DATE_FORMAT);
  const currentYear = moment().format('YYYY');
  const endDateYear = moment(endDate).format('YYYY');
  const startDateYear = moment(startDate).format('YYYY');

  return (
    (startDateYear === currentYear) || (endDateYear === currentYear) &&
    moment(endDate).isAfter(todayDate)
  );
}

const getNewDateRange = (dateRange) => {
  const { startDate } = dateRange;
  const todayDate = moment().format(DATE_FORMAT);

  let newDateRange = dateRange;
  if (isEndDateAfterTodayDate(dateRange)) {
    newDateRange = { startDate, endDate: todayDate };
  }
  return newDateRange;
}

// export const isMultipleYearDateRange = (dateRange, defaultDateType) => {
//   let newDateRange = dateRange;
//   if (defaultDateType === 'yearly' && isEndDateAfterTodayDate(dateRange)) {
//     newDateRange = getNewDateRange(dateRange);
//   }

//   return (getDateRangeDifference(newDateRange, 'day') > YEAR_DAYS_COUNT - 1);
// };

export const getConfiguredDateColumn = (currentTemplateId) => {
  const templateEntry = getCurrentTemplateEntry(currentTemplateId);
  return _.get(templateEntry, 'fields.date_column', '');
};

export const getDateRangeDifference = (dateRange, type) => {
  if(_.isEmpty(dateRange)){
    return 0;
  }

  const { startDate, endDate } = dateRange;
  return moment(endDate).diff(moment(startDate), type);
}

export const getDatePeriods = (dateRange, dateStringFormat, periodType, isFormatDate = false) => {
  const { startDate, endDate } = dateRange;
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const range = moment().range(fromDate, toDate).snapTo(periodType);

  const dateArray = Array.from(range.by(periodType))
  if (isFormatDate){
    return dateArray;
  } else {
    return _.map(dateArray, (date) => {
      return date.format(getDateStringFormat(date, dateStringFormat));
    });
  }
}

export const getDefaultDateRange = (relativeEntry, newDateType) => {
  const dateType = newDateType || configuredDefaultDateType;
  if (dateType === DATE_OPTIONS_TYPE.YEARLY){
    let defaultYear = getConfiguredDefaultYear();
    defaultYear = getFiscalYearByStartMonth(defaultYear);
    return getDateRangeForYearly(defaultYear);
  } else if (dateType === DATE_OPTIONS_TYPE.CUSTOM_RANGE) {
    return getRangeForConfiguredDefaultDate();
  } else if (isBienniumFiscalYear({ dateRangeMode: dateType })) {
    return getDefaultBienniumDateRange();
  } else if (dateType === CALENDAR_DATE_TYPE) {
    return getRangeForConfiguredDefaultDate();
  }
  else {
    return getRelativeFilterDateRange(relativeEntry);
  }
}

export const getLastPeriodDateRange = (thisPeriodDateRange) => {
  if(_.isEmpty(thisPeriodDateRange)) {
    return {};
  }

  const { startDate, endDate } = thisPeriodDateRange;
  let preStartDate, prevEndDate;
  let diff = moment(endDate).diff(startDate, 'days') + 1;

  preStartDate = moment(startDate).subtract(diff, 'days').format(DATE_FORMAT);
  prevEndDate = moment(startDate).subtract(1, 'days').format(DATE_FORMAT);
  return { startDate: preStartDate, endDate: prevEndDate };
}

export const isPreviousPeriodRange = (currentDateRange, compareRange) => {
  const range = getLastPeriodDateRange(currentDateRange);
  return _.isEqual(range, compareRange);
}

export const getRangeForConfiguredDefaultDate = () => {
  const defaultDateRange = getConfiguredDefaultDate() || {};

  let startDate;
  if(_.isEmpty(defaultDateRange.startDate)){
    if(_.isEmpty(defaultDateRange.endDate)){
      startDate = moment().startOf('year')
    } else {
      startDate = moment(defaultDateRange.endDate).startOf('year')
    }
  } else {
    startDate = defaultDateRange.startDate;
  }

  const endDate = _.isEmpty(defaultDateRange.endDate) ? moment() : defaultDateRange.endDate;
  return { startDate: moment(startDate).format(DATE_FORMAT) , endDate: moment(endDate).format(DATE_FORMAT) };
}

export const getExactDateRangeForYear = (year) => {
  const month = getDateRangeStartMonth();

  const startDate = moment().set({ 'year': year, 'month': month, 'date': 1 });
  const endDate = _.cloneDeep(startDate).add(11, 'month').endOf("month");
  // Formatting date to get only YYYY-MM-DD
  return {
    startDate: startDate.format(DATE_FORMAT),
    endDate: endDate.format(DATE_FORMAT)
  }
}

export const getDateRangeForYearly = (year, dateRangeMode, dateRange = {}) => {
  const month = getDateRangeStartMonth();
  const isFiscalYear = _.isEqual(getConfiguredDateType(), DATE_OPTIONS_TYPE.YEARLY);
  const date = moment().set({ 'year': year, 'month': (month - 1)}).endOf("month").format("DD");
  const subtractYear = isBienniumFiscalYear({ dateRangeMode }) ? 2 : 1;
  const startMonth = _.isEmpty(dateRange) ? month : Number(moment(dateRange.startDate).format('M')) -1;
  const endMonth = _.isEmpty(dateRange) ? month : Number(moment(dateRange.endDate).format('M'));

  // Formatting date to get only YYYY-MM-DD
  const startDate = moment().set({ 'year': year, 'month': startMonth, 'date': 1 }).
    subtract(subtractYear, 'year').format(DATE_FORMAT);
  let endDate = moment().set({ 'year': year, 'month': (endMonth - 1), 'date': date }).format(DATE_FORMAT);
  if(_.isEmpty(dateRange) && !isFiscalYear){
    endDate = getFiscalYearEnding(endDate).format(DATE_FORMAT)
  }

  return { startDate, endDate }
}

export const getCustomPeriodDateRange = (periodCount, currentRelativePeriodType) => {
  if(periodCount == 0) {
    return getRelativeFilterDateRange({currentRelativeFilterOption : currentRelativePeriodType});
  }
  const endDate = getConfiguredDataEndDate().
    startOf(currentRelativePeriodType).subtract(1, 'day');
  let startDate = getConfiguredDataEndDate().
    subtract(periodCount, `${currentRelativePeriodType}s`).
    startOf(currentRelativePeriodType).format(DATE_FORMAT);
  if(_.isEqual(currentRelativePeriodType, 'quarter')) {
    startDate = getConfiguredDataEndDate().subtract(periodCount, `${currentRelativePeriodType}s`).
      startOf('Q').format(DATE_FORMAT)
  }
  return {startDate, endDate: endDate.format(DATE_FORMAT)};
}

export const getRelativeFilterDateRange = (relativeEntry) => {
  const periodType = _.get(relativeEntry, 'currentRelativeFilterOption');
  if(periodType == 'custom') {
    const periodCount = _.get(relativeEntry, 'customPeriodCount', 0);
    const customPeriodType = _.get(relativeEntry, 'currentRelativePeriodType', 'day');
    return getCustomPeriodDateRange(periodCount, customPeriodType);
  }
    // Removed timZone to prevent time zone issues for data end date override.
  const today = getConfiguredDataEndDate();
  const yesterday = moment(today).subtract(1, 'day').format(DATE_FORMAT);
  let todayDate = moment(today).format(DATE_FORMAT);

  if(periodType == 'yesterday') {
    return {startDate: yesterday, endDate: yesterday};
  } else if(periodType == 'today'){
    return { startDate: todayDate, endDate: todayDate };
  } else{
    return {
      startDate: getStartDateOfGivenPeriod(periodType, today.format(DATE_FORMAT)).format(DATE_FORMAT),
      endDate: todayDate
    };
  }
}

export const getDatePeriodAsWeeks = (dateRangeParams) => {
  const dateRange = _.get(dateRangeParams, 'dateRange', {});
  const momentObj = moment;
  momentObj.locale(moment.locale(), {
    week: {
      dow: weekStartDayIndex()
    }
  });
  const { startDate, endDate } = dateRange;
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const range = moment().range(fromDate, toDate).snapTo('week');
  const dateArray = Array.from(range.by('week'))
  return _.map(dateArray, (date) => {
    const weekStartDate = momentObj(date).startOf('week'),
          weekEndDate = momentObj(date).endOf('week');
    return {
      weekStartDate: weekStartDate.format(DATE_FORMAT), weekEndDate: weekEndDate.format(DATE_FORMAT)
    };
  });
}

export const getStartDateOfGivenPeriod = (periodType, date=null) => {
  const momentObj = moment;
  if(periodType == 'week') {
    momentObj.locale(moment.locale(), {
      week: {
        dow: weekStartDayIndex()
      }
    });
    if(_.isObject(date)) {
      date.locale(moment.locale(), {
        week: {
          dow: weekStartDayIndex()
        }
      });
    }
  }

  if(date) {
    return momentObj(date).startOf(periodType);
  }else {
    return momentObj().startOf(periodType);
  }

};

export const getFormattedWeekPeriod = (entryDate, dateRange, isProjectionEnabled) => {

  const dateMonthFormat = "MMM D";
  const { endDate } = (dateRange || {});
  const dateFormat = 'D';
  const momentObj = moment;
  momentObj.locale(moment.locale(), {
    week: {
      dow: weekStartDayIndex()
    }
  });
  const weekStartDate = momentObj(entryDate).startOf('week');
  let weekEndDate = momentObj(entryDate).endOf('week');
  // if global end_date is greater than week's end date
  // we are fetching date until global end date
  // not for future dates.
  if(moment(weekEndDate).isAfter(endDate) && !isProjectionEnabled){
    weekEndDate = moment(endDate);
  }
  const startDateFormat = getDateStringFormat(weekStartDate, 'MMM D'),
        endDateFormat = getDateStringFormat(weekEndDate, dateMonthFormat);
  if(_.isEqual(moment(weekStartDate).month(), moment(weekEndDate).month())) {
   return `${moment(weekStartDate).format(startDateFormat)} - ` +
    `${moment(weekEndDate).format(dateFormat)}`;
  } else {
    return `${moment(weekStartDate).format(startDateFormat)} - ` +
    `${moment(weekEndDate).format(endDateFormat)}`;
  }
};

// If the month name should be less than or equal to four characters means,
// we need to show the full name of the month(i.e, May, June, July).
// Otherwise show the abbreviated month names.
export const getDateStringFormat = (date, dateStringFormat) => {
  if(_.includes([4, 5, 6], moment(date).month())) {
    return dateStringFormat.replace(/MMM|MM/, 'MMMM');
  } else {
    return dateStringFormat;
  }
}

const getPreviousYearDateRange = (dateRange, dateFormat) => {
  const { startDate, endDate } = dateRange;

  const validFromDate = getFormattedPreviousYearDate(startDate);
  const validToDate = getFormattedPreviousYearDate(endDate);

  // const fromDate = moment(startDate, dateFormat).subtract(1, 'year').format("YYYY-MM-DD");
  // const toDate = moment(endDate, dateFormat).subtract(1, 'year').format("YYYY-MM-DD");

  return {
    startDate: moment(validFromDate).format(dateFormat),
    endDate: moment(validToDate).format(dateFormat)
  };
}

const getFormattedPreviousYearDate = (dateValue) => {
  // if ==> Previous year leap year == return valid date
  const year = moment(dateValue).year() - 1;
  const month = moment(dateValue).month() + 1;
  const day = moment(dateValue).date();
  const dateSting = `${year}-${month}-${day}`
  const validDate = new Date(dateSting)
  return moment(validDate).format("YYYY-MM-DD");
}

export const getCurrentFilterDateRange = (dateRange, dateFormat = DATE_FORMAT) => {
  const { startDate, endDate } = dateRange;
  return {
    startDate : moment(startDate).format(dateFormat),
    endDate : moment(endDate).format(dateFormat)
  }
}

export const getDateRangeTextEntry = (dateRange, dateType) => {
  const { startDate, endDate } = dateRange;
  const isMultipleYearSelected = moment(endDate).diff(startDate, 'years', true) > 1;
  if (dateType === 'yearly' && isYearlyTypeDateConfigured()) {
    const startYear = moment(startDate).format('YYYY');
    const newStartYear = getDateRangeStartMonth() < 1 ? startYear : Number(startYear) + 1;
    const endYear = moment(endDate).format('YYYY');
    const rangeText = isMultipleYearSelected ? `${newStartYear} - ${endYear}` : endYear;
    return {
      range: dateRange, text: rangeText, isYearlyDate: true, startDateText: newStartYear,
      endDateText: endYear
    };
  } else if (dateType == CALENDAR_DATE_TYPE) {
    const endYear = moment(endDate).format('YYYY');
    return {
      range: dateRange, text: endYear, startDateText: endYear,
      endDateText: endYear
    };
  }else if (_.includes(['relative', 'range', 'custom_range'], dateType)) {
    const startDateText = moment(startDate).format('MMM D, YYYY');
    const endDateText = moment(endDate).format('MMM D, YYYY');
    const rangeText = `${startDateText} - ${endDateText}`;
    return {
      range: dateRange, text: rangeText, isYearlyDate: false, startDateText, endDateText
    };
  } else if (isBienniumFiscalYear({ dateRangeMode: dateType })) {
    const allBienniumFiscalYears = getAllBienniumFiscalYears();
    const currentBienniumName = getBienniumFiscalYearLabel(dateRange);
    const matchedBienniumFiscalYear = _.find(allBienniumFiscalYears, { name: currentBienniumName });
    const bienniumFiscalYearData = matchedBienniumFiscalYear ?
      matchedBienniumFiscalYear : _.get(allBienniumFiscalYears, '0');

    const bienniumStartYear = moment(_.get(bienniumFiscalYearData, 'range.startDate')).format('YYYY');
    const bienniumEndYear = moment(_.get(bienniumFiscalYearData, 'range.endDate')).format('YY');
    return {
      range: bienniumFiscalYearData.range, text: bienniumFiscalYearData.name, isBienniumFiscalYear: true,
      startDateText: bienniumStartYear, endDateText: bienniumEndYear
    };
  } else {
    return getDefaultDateRangeTextEntry(dateRange);
  }
}

export const getCalendarYearDateRange = (selectedYear) => {
  const currentYear = moment().format('YYYY');
  const startDate = moment().set({ 'year': selectedYear, 'month': 0, 'date': 1 });
  const endDate = currentYear == selectedYear ?
    moment().endOf('day') :
    _.cloneDeep(startDate).add(11, 'month').endOf("month");

  return {
    startDate: startDate.format(DATE_FORMAT),
    endDate: endDate.format(DATE_FORMAT)
  }
}

export const getDefaultDateRangeTextEntry = (dateRange) => {
  const isYearlyDateEnabled = isYearlyTypeDateConfigured();
  const { startDate, endDate } = dateRange;
  let startYear = moment(startDate).format('YYYY');
  let endYear = moment(endDate).format('YYYY');

  startYear = getFiscalYearByStartMonth(startYear);
  endYear = getFiscalYearByStartMonth(endYear);
  let startYearDateRange;
  let endYearDateRange;
  const isMultipleYearSelected = moment(endDate).diff(startDate, 'years', true) > 1;
  if (startYear === moment(startDate).format('YYYY') &&
      endYear === moment(endDate).format('YYYY') &&
      !isMultipleYearSelected
    ) {
    startYearDateRange = getDateRangeForYearly(endYear);
    endYearDateRange = getDateRangeForYearly(endYear);
  } else {
    startYearDateRange = getDateRangeForYearly(startYear);
    endYearDateRange = getDateRangeForYearly(endYear);
  }
  const newStartDate = _.get(startYearDateRange, 'startDate');
  const newEndDate = _.get(endYearDateRange, 'endDate');
  const newStartYear = moment(newStartDate).format('YYYY');
  const newEndYear = moment(newEndDate).format('YYYY');
  let isYearlyDate = false;
  let rangeText = moment(startDate).format('MMM D, YYYY') + ' - ' + moment(endDate).format('MMM D, YYYY');
  let startDateText = moment(startDate).format('MMM D, YYYY');
  let endDateText = moment(endDate).format('MMM D, YYYY');
  const hasSameYearlyRange = (_.isEqual(newStartDate, startDate) && _.isEqual(endDate, newEndDate));

  // When a fiscal year range is selected, then, if the start and end date/month formed using
  // the start month from config is same as the start and end date/month formed using the selected fiscal
  // year range then the app is in fiscal mode.
  // if not the app is in custom date range mode.
  if (isMultipleYearSelected && isMonthAndDaySame(startDate, endDate) && isYearlyDateEnabled) {
    rangeText = (getDateRangeStartMonth() < 1) ?
      newStartYear + ' - ' + newEndYear :
      `${Number(moment(startDate).format('YYYY')) + 1} - ${endYear}`;
    startDateText = newStartYear;
    endDateText = endYear;

    isYearlyDate = true;
  } else if(_.isEqual(startYearDateRange, endYearDateRange) && hasSameYearlyRange && isYearlyDateEnabled){
    rangeText = newEndYear;
    endDateText = newEndYear;
    isYearlyDate = true;
  }else if(hasSameYearlyRange && isYearlyDateEnabled) {
    rangeText =  newStartYear + ' - ' + newEndYear;
    startDateText = newStartYear;
    endDateText = newEndYear;
    isYearlyDate = true;
  }

  return {
    range: dateRange,
    text: rangeText,
    isYearlyDate,
    startDateText,
    endDateText
  }
}

function isMonthAndDaySame(startDate, endDate) {
  const dateRangeStartMonth = getDateRangeStartMonth();
  const startDateMonth = moment().set({ 'month': (dateRangeStartMonth) }).format("MM");
  const startDateDay = moment().set({ 'month': (dateRangeStartMonth) }).startOf("month").format("DD");
  const endDateMonth = moment().set({ 'month': (dateRangeStartMonth - 1) }).format("MM");
  const endDateDay = moment().set({ 'month': (dateRangeStartMonth - 1) }).endOf("month").format("DD");
  const startDateMonthAndDay = `${startDateMonth}-${startDateDay}`;
  const endDateMonthAndDay = `${endDateMonth}-${endDateDay}`;

  return (
    moment(startDate).format('MM-DD') === startDateMonthAndDay &&
    endDateMonthAndDay === moment(endDate).format('MM-DD')
  );
}

export const getComparisonPeriodDateRanges = ({
  comparisonType, dateRange, dateType, comparisonDateRanges
  }) => {
    const isBienniumYear = isBienniumFiscalYear({ dateRangeMode: dateType });

    if(dateType == THIS_PERIOD_LAST_YEAR){
      return [getPreviousYearDateRange(dateRange, DATE_FORMAT)];
    } else if(comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD && isBienniumYear) {
      const allBienniumFiscalYears = getAllBienniumFiscalYears(true);
      const startDate = _.get(dateRange, 'startDate')
      const prevDateRanges =  _.filter(_.reverse(allBienniumFiscalYears), (fiscalYear) => {
        const fromDate = _.get(fiscalYear, 'range.startDate')
        if (moment(fromDate) < moment(startDate)) {
          return fiscalYear
        }
      })

      const defaultDateRanges = _.get(_.first(prevDateRanges), 'range');
      return (_.isEmpty(comparisonDateRanges) ? [defaultDateRanges] : comparisonDateRanges);

    } else if(comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD) {
      const defaultDateRanges = getPreviousYearDateRange(dateRange, DATE_FORMAT);
      return (_.isEmpty(comparisonDateRanges) ? [defaultDateRanges] : comparisonDateRanges);
    } else {
      if(_.isEmpty(comparisonDateRanges)){
        return [getLastPeriodDateRange(dateRange)];
      }else{
        return _.orderBy(comparisonDateRanges, 'endDate', 'desc');
      }
    }
}

export const getDateDescriptions = (commonFilters, templateId) => {
  const { dateRange, dateType } = commonFilters;
  let newDateRange = dateRange;
  const isYearlyDateType = dateType === 'yearly';
  if (isYearlyDateType && isEndDateAfterTodayDate(dateRange)) {
    newDateRange = getNewDateRange(dateRange);
  }
  const { startDate, endDate } = newDateRange;
  const formattedStartDate = moment(startDate).format('ll');
  const formattedEndDate = moment(endDate).format('ll');
  const currentPeriodDescription = `${formattedStartDate} - ${formattedEndDate}`;
  const comparisonPeriodDescription = getCompareYearsDescriptionText(commonFilters, templateId).join(', ');

  if (isYearlyDateType && !isEndDateAfterTodayDate(dateRange)) {
    const { text } = getDateRangeTextEntry(dateRange, dateType);
    return { currentPeriodDescription: text, comparisonPeriodDescription };
  }

  return { currentPeriodDescription, comparisonPeriodDescription };
}

export const getCompareYearsDescriptionText = (commonFilters, templateId) => {
  const { dateRange, dateType, comparisonType, comparisonDateRanges } = commonFilters;

  if(comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD){
    const yearOptions = getCompareSamePeriodLastYearOptions({
      primaryDateRange: dateRange, primaryDateType: dateType, templateId}
    );
    const selectYears = getSelectedYearsForBienniumYear(yearOptions, comparisonDateRanges, dateType);
 
    return _.chain(selectYears).orderBy('name', 'desc').
      map((entry) => {
        if(dateType == 'custom_range'){
          return _.get(getDateRangeTextEntry(entry.range), 'text');
        }else{
          return entry.name
        }

      }).value()
  }else{
    const comparisonPeriodDateRange = comparisonDateRanges[0];
    const formattedComparisonStartDate = moment(comparisonPeriodDateRange?.startDate).format('ll');
    const formattedComparisonEndDate = moment(comparisonPeriodDateRange?.endDate).format('ll');

    return _.isEqual(formattedComparisonStartDate, formattedComparisonEndDate) ?
      [formattedComparisonStartDate] : [`${formattedComparisonStartDate} - ${formattedComparisonEndDate}`];
  }
}
export const getDateFilterParams = (commonFilters) => {
  if(_.isEmpty(commonFilters)) {
    return {};
  }
  return {
    dateRange: _.get(commonFilters, 'dateRange'),
    dateType: _.get(commonFilters, 'dateType'),
    relativeDateFilterEntry: _.get(commonFilters, 'relativeDateFilterEntry', {}),
    independentDateFilters: _.get(commonFilters, 'independentDateFilters', {}),
    additionalDateFilters: _.get(commonFilters, 'additionalDateFilters', {})
  }
}

export const getCurrentPeriodDateRangeFromState = (locationState, dateType = '') => {
  const relativeDateFilterEntry = _.get(locationState, 'relativeDateFilterEntry', {});
  const isDateRelativeFilterAdded = (_.get(relativeDateFilterEntry, 'isRelativeFilter', 'false') == 'true');
  const dateRange = _.get(locationState, 'dateRange', {});
  dateType = dateType || _.get(locationState, 'dateType');

  if(isDateRelativeFilterAdded && _.get(locationState, 'dateType') === 'relative') {
    return getRelativeFilterDateRange(relativeDateFilterEntry);
  } else if (dateType === 'yearly') {
    const endDateYear = moment(dateRange.endDate).format("YYYY");
    const currentDateYear = moment().format("YYYY");

    if(endDateYear == currentDateYear) {
      const selectedYear = getFiscalYearByStartMonth(endDateYear);
      return getDateRangeForYearly(selectedYear);
    } else {
      return dateRange;
    }
  } else if(dateType === 'biennium_fiscal_year') {
    const allBienniumFiscalYears = getAllBienniumFiscalYears();
    const currentBienniumName = getBienniumFiscalYearLabel(dateRange);
    const matchedBienniumFiscalYear = _.find(allBienniumFiscalYears, { name: currentBienniumName });
    return matchedBienniumFiscalYear ? matchedBienniumFiscalYear.range
      : _.get(allBienniumFiscalYears, [0, 'range'], dateRange);
  } else {
    return dateRange;
  }
}

export const getCurrentDateRangeByDateType = (options) => {
  const {
    dateType,
    relativeDateFilterEntry,
    currentRelativeFilterOption,
    currentRelativePeriodType,
    customPeriodCount
  } = options;
  const defaultDateRange = getDefaultDateRange(relativeDateFilterEntry, dateType);
  if((dateType == 'yearly')) {
    const selectedYear = getFiscalYearByStartMonth(moment(defaultDateRange.endDate).format("YYYY"));
    return getDateRangeForYearly(selectedYear);
  } else if (isBienniumFiscalYear({ dateRangeMode: dateType })) {
    return getDefaultBienniumDateRange();
  } else if (dateType == 'relative') {
    return getRelativeFilterDateRange({
      currentRelativeFilterOption,
      currentRelativePeriodType,
      customPeriodCount
    });
  } else if (dateType == CALENDAR_DATE_TYPE) {
    return defaultDateRange;
  } else {
    return defaultDateRange;
  }
}

export const isLeapYear = (startDate, endDate) => {
  let isLeapYearAndFebruaryInclude = false;
  const numberOfDaysCount = moment(endDate).diff(moment(startDate), 'days');

  if (numberOfDaysCount === YEAR_DAYS_COUNT) {
    if (moment(startDate).isLeapYear()) {
      const startDateMonthIndex = moment(endDate).month();

      if (
          startDateMonthIndex < DEFAULT_FEB_MONT_INDEX ||
          (startDateMonthIndex === DEFAULT_FEB_MONT_INDEX &&
            Number(moment(startDate).format('DD')) < DEFAULT_FEB_DAY_COUNT
          )
        ) {
        isLeapYearAndFebruaryInclude = true;
      }

    }

    if (moment(endDate).isLeapYear()) {
      const endMonthIndex = moment(endDate).month();
      if (
        endMonthIndex > DEFAULT_FEB_MONT_INDEX ||
        (endMonthIndex === DEFAULT_FEB_MONT_INDEX &&
          Number(moment(endDate).format('DD')) > DEFAULT_FEB_DAY_COUNT
        )
      ) {
        isLeapYearAndFebruaryInclude = true;
      }
    }
  }

  return isLeapYearAndFebruaryInclude;
}

export const getDefaultDateMode = () => {
  if (isYearlyTypeDateConfigured()) {
    return 'yearly';
  }else {
    return 'range'
  }
}

export function getUserPreferenceDateType(dateType, dateRange) {
  const configuredDateType = getConfiguredDateType();
  if (dateType === 'yearly' && isBienniumFiscalYear({ dateRangeMode: configuredDateType })) {
    return 'custom_range';
  } else if (isBienniumFiscalYear({ dateRangeMode: dateType}) && configuredDateType === 'yearly') {
    return 'custom_range';
  } else if (isBienniumFiscalYear({ dateRangeMode: dateType}) && configuredDateType === 'custom_range') {
    return configuredDateType;
  } else if (isBienniumFiscalYear({ dateRangeMode: dateType }) &&
    configuredDateType === BIENNIUM_FISCAL_YEAR) {
    const matchedBienniumFiscalYear = _.find(getAllBienniumFiscalYears(), (bienniumFiscalYear) => {
      const { range } = bienniumFiscalYear;
      return _.isEqual(range, dateRange);
    });

    return matchedBienniumFiscalYear ? dateType : 'custom_range';
  } else {
    return dateType;
  }
}

export const getInBetweenYearsForDateRange = (dateRange) => {
  if(_.isEmpty(dateRange)) {
    return [];
  }

  const { startDate, endDate } = dateRange;
  const isFiscalYear = _.isEqual(getConfiguredDateType(), DATE_OPTIONS_TYPE.YEARLY);
  let newStartDate = startDate;
  let newEndDate = endDate;

  if (isFiscalYear) {
    const { endDate: fiscalYearStartDate } = getFiscalYearDateRangeForDate(startDate);
    const { endDate: fiscalYearEndDate } = getFiscalYearDateRangeForDate(endDate);
    newStartDate = fiscalYearStartDate;
    newEndDate = fiscalYearEndDate;
  }

  return getDatePeriods({ startDate: newStartDate, endDate: newEndDate }, 'YYYY', 'year');
}

export const getValidStartAndEndDate = ({ startDate, endDate, isStartDateChanged = false }) => {
  if (moment(startDate).isAfter(endDate)) {
    const diffYears = moment(startDate).diff(endDate, 'years', true);
    const numberOfYears = diffYears > 1 ? (diffYears + 1) : 1;
    if(isStartDateChanged) {
      return {
        startDate,
        endDate: moment(endDate).add(numberOfYears, 'year').format(DATE_FORMAT),
        isEndDateChanged: true,
        isStartDateChanged: false
      };
    } else {
      return {
        startDate: moment(startDate).subtract(numberOfYears, 'year').format(DATE_FORMAT),
        endDate,
        isStartDateChanged: true,
        isEndDateChanged: false
      };
    }
  }

  return {
    startDate,
    endDate,
    isEndDateChanged: false,
    isStartDateChanged: false
  };
}

export const isDateBetweenTwoDates = ({ startDate, endDate }) => {
  const dateBeginPeriodCount = getDateBeginPeriodCount();
  const dateBeginPeriodType = getDateBeginPeriodType();
  const { startDate: customStartDate, endDate: customEndDate } = getCustomPeriodDateRange(
    dateBeginPeriodCount, dateBeginPeriodType
  );
  const startDateMomentObj = moment(startDate);
  const endDateMomentObj = moment(endDate);
  const customStartDateMomentObj = moment(customStartDate);
  const customEndDateMomentObj = moment(customEndDate);

  return isCustomDateBeginType() &&
    !startDateMomentObj.isBefore(customStartDateMomentObj) &&
    (
      startDateMomentObj.isBetween(customStartDateMomentObj, customEndDateMomentObj) ||
      endDateMomentObj.isBetween(customStartDateMomentObj, customEndDateMomentObj)
    );
}

export const isValidInputForRelativeCustom = (inputValue) => {
  const regExp = /^-?[0-9]\d*(\d+)?$/;
  if (inputValue !== '' && !regExp.test(Number(inputValue))) {
    return true
  }
}

export const getDateRangeTextBasedOnType = (commonFilters, type, id) => {
  let { dateType, dateRange, relativeDateFilterEntry } = commonFilters;
  const currentRelativeFilterOption = _.get(relativeDateFilterEntry, 'currentRelativeFilterOption');
  const currentRelativePeriodType = _.get(relativeDateFilterEntry, 'currentRelativePeriodType');
  const customPeriodCount = _.get(relativeDateFilterEntry, 'customPeriodCount');

  if(type === 'includeAdditionalDateRange') {
    const additionalDateFilter = _.get(commonFilters, `additionalDateFilters.${id}`, {});
    dateType = _.get(additionalDateFilter, 'dateType', dateType);
    dateRange = _.get(additionalDateFilter, 'dateRange', dateRange);
  }

  let { text } = getDateRangeTextEntry(dateRange, dateType);
  if(dateType == 'relative' && currentRelativeFilterOption) {
    text = _.get(_.find(RELATIVE_FILTER_OPTIONS, {type: currentRelativeFilterOption}), 'name', '');
    if(text == 'Custom') {
      const matchedLastPeriod = _.find(
        RELATIVE_FILTER_PERIOD_TYPE_ENTRIES, {type: currentRelativePeriodType}
      );
      text = `Last ${customPeriodCount} ${_.get(matchedLastPeriod, 'name')}`;
    }
  }
  return text;
}

export const getYearTextByRange = (dateRange, dateRangeMode) => {
  const { startDate, endDate } = dateRange;
  const  isBienniumYear = isBienniumFiscalYear({ dateRangeMode });
  let startYear = Number(moment(startDate).format('YYYY'));
  let endYear = Number(moment(endDate).format('YYYY'));
  const formattedEndYear = isBienniumYear ?  moment(endYear, 'YYYY').format('YY') : endYear;
  let yearText = startYear == endYear ? endYear : `${startYear} - ${formattedEndYear}`;
  const dateRangeDifference = getDateRangeDifference(dateRange, 'day');
  if(dateRangeMode === DATE_OPTIONS_TYPE.YEARLY && dateRangeDifference <= 365){
    yearText = endYear
  }
  return  yearText;
}

export const getListOfYears = (startYear = 2005) => {
  var currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 1980;
  while ( startYear <= currentYear ) {
    const yearStart = `${startYear++}`;
    years.push( {name: yearStart, value: yearStart});
  }
  return years;
}

export const getListOfFutureYears = (noOfYearsNeeded = 10 ) => {
  var currentYear = new Date().getFullYear(), years = [];
  var endYear = currentYear + noOfYearsNeeded;
  while ( currentYear < endYear ) {
    const yearStart = `${currentYear++}`;
    years.push( {name: yearStart, value: yearStart});
  }
  return years;
}

export const dateWithTimeZone = (date = null) => {
  if (_.isEmpty(date)) {
    return moment()
  } else {
    return moment(date, DATE_FORMAT);
  }
}

export const dateRangeExpireCheck = (startDate, endDate) => {
  const today = new Date();
  startDate = _.isEmpty(startDate) ? today : new Date(`${startDate} 00:00:00`);
  endDate = _.isEmpty(endDate) ? today : new Date(`${endDate} 23:59:59`);

  return startDate <= today && today <= endDate
  ;
};

export const getAllCalendarYears = (currentTemplateId) => {
  const dataEndDateYear = moment(getConfiguredDataEndDate()).format('YYYY');
  const dataBeginDate = moment(getDateDataBeginsFrom(currentTemplateId)).format('YYYY');
  let noOfYears = 11;
  if(!_.isEmpty(getDateDataBeginsFrom(currentTemplateId))){
    noOfYears = _.ceil(moment(dataEndDateYear).diff(dataBeginDate, 'years')) + 1;
  }
  return _.compact(_.map(_.times(noOfYears), (value) => {
    const year = moment(dataEndDateYear).subtract(value, 'year').format('YYYY');
    return year;
  }));
}

export const getSelectedYearsForBienniumYear = (yearOptions, comparisonDateRanges, primaryDateType) => {
  let selectedYears = _.filter(yearOptions, yearEntry => {
      return _.some(comparisonDateRanges, yearEntry['range']);
  });

  if (primaryDateType === BIENNIUM_FISCAL_YEAR && comparisonDateRanges?.length === 1 &&
     _.isEmpty(selectedYears)) {
      const selectYear = _.chain(yearOptions)
          .filter(yearEntry => {
              return _.some(comparisonDateRanges, comparisonRange => {
                  return new Date(yearEntry.range?.startDate) <= new Date(comparisonRange?.startDate);
              });
          })
          .sortBy(yearEntry => new Date(yearEntry.range?.startDate))
          .last()
          .value();
      
      selectedYears = !_.isUndefined(selectYear) ? [selectYear] : [_.get(yearOptions, '[0]', {})];
  }

  return selectedYears;
};
