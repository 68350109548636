import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import ShareSection from 'pages/SubscriptionsManager/components/ShareSection';
import CustomButton from 'common/components/CustomButton';

import { addCollaborateCollectionUser } from 'common/api/collaborateApi';
import { updateIsUserDataLoading, updateRefetch } from './collaboratorActions';

const AddUserSection = ({ showEmailInput, setEmailInput, addedUsers }) => {
  const currentUser = useSelector((store) => store.currentUser);
  const allowedUserRoles = useSelector((store) => store.allowedUserRoles);
  const collection = useSelector((store) => store.collection);
  const ownerEmail = _.get(collection, 'currentCollectionUser.email', '');
  const dispatch = useDispatch();
  const hiddenUsers = _.flatten([ownerEmail, addedUsers]);

  const onShareCollection = ({ shareEmails }) => {
    const params = { collaborateCollectionUsers: shareEmails };
    dispatch(updateIsUserDataLoading(true));

    addCollaborateCollectionUser(collection.id, params).
      then(response => response.json()).
      then((response) => {
        if(response.errors) {
          _.each(response.errors, (error) => {
            toast.error(error[0]);
          });
          dispatch(updateIsUserDataLoading(false));
        } else {
          toast.success('User added to collection.');
          setEmailInput(false);
          dispatch(updateRefetch(true));
        }
      }).catch((error) => {
        console.log('collaborate collection user failed', error);
      });
  }

  return (
    <div className="add-new-collection-user">
      <CustomButton type="" className="mt-2" onClick={() => setEmailInput(true)} label="+ Add a person" />
      {showEmailInput &&
        <ShareSection
          hiddenUsers={hiddenUsers}
          currentUser={currentUser}
          onCancelShare={() => setEmailInput(false)}
          onSendClick={onShareCollection}
          allowedUserRoles={allowedUserRoles}
        />
      }
    </div>
  )
}

AddUserSection.propTypes = {
  showEmailInput: PropTypes.bool,
  setEmailInput: PropTypes.func,
  addedUsers: PropTypes.array
}

AddUserSection.defaultProps = {
  addedUsers: []
}

export default AddUserSection;
