import _ from 'lodash';
import React, {Component} from 'react';
import {
  VISUALIZATION_TYPES,
  SNAPSHOT_VISUALIZATION_TYPES,
  COMPARISON_MODE_OPTIONS
} from 'appConstants';
import {
  getGlobalDateAdditionalInfo,
  showGlobalDateAdditionalInfo
} from 'common/config/customerConfiguration';

import {
  getSecondaryMetricName,
  getDimensionName,
  getGroupName,
  getQuickFilterForDescription,
  getPrimaryMetricName,
  getFirstLineOfDescription,
  getCompareToYearForMapDescription,
  getShapeFilterDescription,
  getPermissionFilterDescription
} from 'helpers/displayNameHelper';
import { connect } from 'react-redux';
import moment from 'moment';
import { isChoroplethMap, isPointMapView } from 'modules/Map/helpers/MapOptionsHelper';
import { getDatasetUpdatedAtUrl } from 'common/api/commonApi';
import {
  getDateRangeTextEntry,
  getDateDescriptions,
  getComparisonPeriodDateRanges
} from 'helpers/dateHelper';
import { showDateFilter } from 'common/config/viewConfiguration';
import { showDimensionsInDistribution } from 'common/config/visualizationConfiguration';
import {
  getGlobalFiltersDescriptionsByTemplate
} from 'common/components/GlobalFilter/helper';
import AttributionDescription from 'common/components/AttributionDescription';

import { fetchApiData } from 'helpers/apiResponseHelper';
import {
  isDateIgnored,
  getAdditionalDateFiltersConfigs
} from 'pages/dashboard/components/Collections/collectionHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import { getTimeOfDayFiltersText } from 'common/components/TimeOfDayFilter/helper';

class VisualizationDescription extends Component {
  constructor(props) {
    super(props);
    this.abortFetchController = new AbortController();
    this.state = {
      viewLastModified: null
    };
  }

  componentDidMount() {
    this.fetchDatasetUpdatedAt();
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.currentDrilldownTemplateId, this.props.currentDrilldownTemplateId) ||
      !_.isEqual(prevProps.dateRange, this.props.dateRange)
    ){
      this.fetchDatasetUpdatedAt();
    }
  }

  fetchDatasetUpdatedAt = async() => {
    const { drilldown, currentDrilldownTemplateId, commonFilters } = this.props;
    let drilldownWithoutViewEntry = _.omit(drilldown, 'currentDrilldownViewEntry');
    drilldownWithoutViewEntry['currentViewEntryField'] = _.get(
      drilldown, 'currentDrilldownViewEntry.field', '');

    const queryParams = {
      drilldown: JSON.stringify(drilldownWithoutViewEntry),
      commonFilters: JSON.stringify(commonFilters),
      currentDrilldownTemplateId
    }

    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    const datasetUpdatedAtUrl = getDatasetUpdatedAtUrl(queryParams);

    const response = await fetchApiData(datasetUpdatedAtUrl, this.abortFetchController);
    const viewLastModified  = _.get(response, 'rowsUpdatedAt');
    this.setState({ viewLastModified });
  }

  canShowDateFilterText = () => {
    const { currentDrilldownTemplateId, currentDrilldownViewEntry } = this.props;
    const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
    const cards = [{ templateEntry, currentDrilldownViewEntry }];
    const additionalDateFilterConfigs = getAdditionalDateFiltersConfigs(cards);
    const isDateFilterEnabled = !isDateIgnored(currentDrilldownViewEntry);

    const canShowDateFilter = showDateFilter(templateEntry, currentDrilldownViewEntry);
    const isAdditionalDateFilterConfigs = (!_.isEmpty(additionalDateFilterConfigs) && isDateFilterEnabled);

    return (canShowDateFilter ||
      isAdditionalDateFilterConfigs);
  }

  getMetricDescription() {
    const { currentDrilldownViewEntry } = this.props;
    let metricDescription = '';
    if(!_.isEmpty(currentDrilldownViewEntry['aggregate_type'])){
      if(!_.isEmpty(currentDrilldownViewEntry['exclude_less_than'])){
        metricDescription += ` Excluding values less than`+
          `: ${currentDrilldownViewEntry['exclude_less_than']}.`;
      }
      if(!_.isEmpty(currentDrilldownViewEntry['exclude_greater_than'])){
        metricDescription += ` Excluding values greater than`+
          `: ${currentDrilldownViewEntry['exclude_greater_than']}.`;
      }
    }
    return metricDescription;
  }

  getStartOfDescription(currentMapView){
    const startOfPointMap = 'Showing';
    const startOfHeatMap = 'Showing the concentration of';
    const heatMapDescription = isPointMapView(currentMapView) ? startOfPointMap : startOfHeatMap;
    const startOfDescription = isChoroplethMap(currentMapView) ? 'Comparing' : heatMapDescription;
    return startOfDescription;
  }

  getFooterDescription(description, viewName) {
    const {
      commonFilters, comparisonModeOn, currentDrilldownTemplateId, currentVisualizationType
    } = this.props;
    const renderGlobalInfo = showGlobalDateAdditionalInfo() ? getGlobalDateAdditionalInfo() : '';

    if (comparisonModeOn) {
      const {
        comparisonPeriodDescription,
        currentPeriodDescription
      } = getDateDescriptions(commonFilters, currentDrilldownTemplateId);

      const isMapVisualization = _.isEqual(currentVisualizationType, VISUALIZATION_TYPES.MAP.type);

      const comparisonModeText = `${viewName}`+
        ` for <b>${currentPeriodDescription}</b> compared to <b>${comparisonPeriodDescription}</b>` +
        (isMapVisualization ? `. ${description}`: `${description}`);

      return (
        <>
          <p dangerouslySetInnerHTML={{__html : comparisonModeText}} ></p>
          <p>{renderGlobalInfo}</p>
          <AttributionDescription isVisualization={true} templateId = {currentDrilldownTemplateId} />
        </>
      );
    }

    return (
      <>
        <p dangerouslySetInnerHTML={{__html : description + ' '}} ></p>
        <p>{renderGlobalInfo}</p>
        <AttributionDescription isVisualization={true} templateId = {currentDrilldownTemplateId} />
      </>
    );
  }

  getViewLastModifiedDescription(){
    const { viewLastModified } = this.state;
    let viewLastModifiedDescription = '';
    if (!_.isNil(viewLastModified)) {
      const date = moment.unix(viewLastModified);
      const viewUpdatedTime = moment(date).format('LT');
      const viewUpdatedDate = moment(date).format('LL');
      viewLastModifiedDescription = `Data last updated <b>${viewUpdatedDate} at ${viewUpdatedTime}.</b>`;
    }
    return viewLastModifiedDescription;
  }

  getTimeOfDayFiltersDescription(){
    const { isTimeOfDayOn, timeOfDayFilters } = this.props;
    if(!isTimeOfDayOn){
      return '.';
    }
    var timeOfDayFiltersText = isTimeOfDayOn ? getTimeOfDayFiltersText(timeOfDayFilters) : '';
    timeOfDayFiltersText = _.compact(timeOfDayFiltersText);
    return !_.isEmpty(timeOfDayFiltersText) ? ` for ${timeOfDayFiltersText}.` : ' for All hours.';
  }

  renderChartDescription(){
    const {
      currentVisualizationType,
      currentDrilldownViewEntry,
      currentDrilldownDimensionField,
      currentSnapshotView,
      currentSecondaryMetricField,
      currentDrilldownTemplateId,
      currentDrilldownGroupByEntry,
      currentSecondaryMetricEntry,
      isComboChart,
      compareYearRanges,
      dateRange,
      dateType,
      comboChartMetricList,
      quickFilters,
      shapeData,
      datasetEntry,
      selectedShapeIds,
      totalRowCount,
      globalFilters,
      permissionFilters,
      comparisonModeOn,
      commonFilters
    } = this.props;
    const primaryMetricName = getPrimaryMetricName(currentDrilldownViewEntry);
    const dimensionName = getDimensionName(currentDrilldownTemplateId, currentDrilldownDimensionField);
    const customDateText = getDateRangeTextEntry(dateRange, dateType);
    const groupName = getGroupName(
      currentDrilldownGroupByEntry, currentSnapshotView, currentVisualizationType);
    const secondaryMetricName = getSecondaryMetricName({
      currentDrilldownViewEntry,
      currentVisualizationType,
      currentSnapshotView,
      currentSecondaryMetricField,
      isComboChart,
      comboChartMetricList,
      currentSecondaryMetricEntry
    });
    let showDimensionName = true;
    let compareToYear = '';
    if ((currentVisualizationType === 'overtime')) {
      if (!_.isEmpty(compareYearRanges)) {
        let compareYearRangesContent = [];
        const compareYearDateRanges = getComparisonPeriodDateRanges(commonFilters);
         _.each(compareYearDateRanges, (range, index) => {
          const isLast = (index === compareYearRanges.length -1);
          const dateRangeTextEntry = getDateRangeTextEntry(range, dateType);
          const dateRangeText = _.get(dateRangeTextEntry, 'text', '');
          compareYearRangesContent.push(
            isLast ? ` and <b>${dateRangeText}</b>` : ` , <b>${dateRangeText}</b>`);
        });
        compareToYear = compareYearRangesContent.join('');
      }
    }

    const isPieChart = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type);
    const quickFilter = getQuickFilterForDescription(quickFilters, currentDrilldownTemplateId);
    const permissionFilterText = getPermissionFilterDescription(
      permissionFilters, currentDrilldownTemplateId
    );
    const filterList = _.join(_.compact(quickFilter), '. ')
    const shapeFilter = getShapeFilterDescription(shapeData, datasetEntry, selectedShapeIds);
    let globalFiltersDescriptions = '';
    if(!_.isEmpty(globalFilters)){
      globalFiltersDescriptions = getGlobalFiltersDescriptionsByTemplate(
        currentDrilldownTemplateId, globalFilters);
      if(!_.isEmpty(globalFiltersDescriptions)){
        globalFiltersDescriptions = _.join(_.compact(globalFiltersDescriptions), '. ');
      }
    }

    let viewName = `${primaryMetricName}`;
    if (_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.DISTRIBUTION.type) &&
      !showDimensionsInDistribution(currentDrilldownViewEntry)) {
      viewName = `${primaryMetricName}`;
      showDimensionName = false;
    }

    let suffixDescription ='';
    if ( groupName ||
        currentVisualizationType === 'overtime' ||
        (!isPieChart && currentVisualizationType !== 'distribution' && totalRowCount > 0) ) {
      suffixDescription = 'Some results are hidden for brevity.';
    }
    const metricExcludeDescription = this.getMetricDescription();

    const quickFiltersText = ((permissionFilterText) ? `${permissionFilterText}.` : '') +
      ((filterList) ? `${filterList}.` : '') +
      ( globalFiltersDescriptions ? `${globalFiltersDescriptions}.` : '');

    viewName = `<b>${viewName}${secondaryMetricName}</b> ` +
      `${showDimensionName ? `<b>by ${dimensionName}</b>` : ''}` +
      `${groupName ? ` <b>${groupName}</b>` : ''}`;
    const globalDateText = _.get(customDateText, 'text', '');

    const visualizationFilters = (shapeFilter || quickFiltersText) ?
      `This visualization contains the following filters.` +
      ` ${shapeFilter}` +
      ` ${quickFiltersText}`: '';

    let chartDescription =  '';
    const dateText = this.canShowDateFilterText() ?
      `for <b>${globalDateText}</b>${compareToYear}` : '';
    if(comparisonModeOn){
      let startDescriptionText = compareToYear ? `Also ${viewName} ${dateText}` : '';

      chartDescription = `${startDescriptionText}${this.getTimeOfDayFiltersDescription()}` +
        ` ${this.getViewLastModifiedDescription()}` +
        ` ${metricExcludeDescription}`+
        ` ${visualizationFilters}` +
        ` ${suffixDescription}`;
    }else{
      chartDescription = `${viewName}` +
        ` ${dateText}${this.getTimeOfDayFiltersDescription()}`+
        ` ${this.getViewLastModifiedDescription()}` +
        ` ${metricExcludeDescription}`+
        ` ${visualizationFilters}` +
        ` ${suffixDescription}`;
    }

    return this.getFooterDescription(chartDescription, viewName);
  }

  renderMapDescription(){
    const {
      boundaryMapName,
      commonFilters,
      currentDrilldownTemplateId,
      currentDrilldownViewEntry,
      dateRange,
      dateType,
      globalFilters,
      quickFilters,
      shapeData,
      datasetEntry,
      selectedShapeIds,
      comparisonModeOn
    } = this.props;
    const primaryName = getPrimaryMetricName(currentDrilldownViewEntry);
    const customDateText = getDateRangeTextEntry(dateRange, dateType);
    const quickFilter = getQuickFilterForDescription(quickFilters, currentDrilldownTemplateId);
    const filterList = _.join(_.compact(quickFilter), '. ');
    const shapeFilter = getShapeFilterDescription(shapeData, datasetEntry, selectedShapeIds);
    let globalFiltersDescriptions = '';
    if(!_.isEmpty(globalFilters)){
      globalFiltersDescriptions = getGlobalFiltersDescriptionsByTemplate(
        currentDrilldownTemplateId, globalFilters);
      if(!_.isEmpty(globalFiltersDescriptions)){
        globalFiltersDescriptions = _.join(_.compact(globalFiltersDescriptions), '. ');
      }
    }
    const metricExcludeDescription = this.getMetricDescription();
    const quickFiltersText = ((filterList) ? `${filterList}.` : '') +
    ( globalFiltersDescriptions ? `${globalFiltersDescriptions}.` : '');
    const mapDateRangeText = comparisonModeOn ?
      getCompareToYearForMapDescription(commonFilters) :
      _.get(customDateText, 'text', '');


    const visualizationFilters = (shapeFilter || quickFiltersText) ?
    `This visualization contains the following filters.` +
    ` ${shapeFilter}` +
    ` ${quickFiltersText}` : '';

    const titleText = `<b>${primaryName}${boundaryMapName}</b> `;

    let mapDescription =  '';
    const dateText = this.canShowDateFilterText() ? `for <b>${mapDateRangeText}</b>` : '';

    if(comparisonModeOn){
      mapDescription = `${this.getViewLastModifiedDescription()}` +
      ` ${metricExcludeDescription}` +
      ` ${visualizationFilters}`;
    }else{
      mapDescription = `${titleText} ${dateText}${this.getTimeOfDayFiltersDescription()}` +
      ` ${this.getViewLastModifiedDescription()}` +
      ` ${metricExcludeDescription}` +
      ` ${visualizationFilters}`;
    }

    return this.getFooterDescription(mapDescription, titleText);
  }

  renderDescription(){
    const { currentVisualizationType } = this.props;
    return (_.isEqual(currentVisualizationType, VISUALIZATION_TYPES.MAP.type) ?
      this.renderMapDescription() :
      this.renderChartDescription());
  }

  renderFirstLineOfDescription(){
    const { currentDrilldownViewEntry } = this.props
    const firstLineOfDescription = DOMPurify.sanitize( // eslint-disable-line no-undef
      getFirstLineOfDescription(currentDrilldownViewEntry))
    if(_.isEmpty(firstLineOfDescription)){
      return null;
    }

    return (<div className="custom_description">
        <p dangerouslySetInnerHTML={{__html : firstLineOfDescription}} />
      </div>);
  }

  render(){
    const descriptionText = this.renderDescription();
    return (
      <div>
        {this.renderFirstLineOfDescription()}
        <div
          role="definition"
          className="description"
          tabIndex={0}
          aria-label={`Visualization Description Text - ${descriptionText}`}>
          {descriptionText}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    comparison: _.get(state, 'visualization.mapOptions.comparison'),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry'),
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry'),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView'),
    currentChartOption: _.get(state, 'visualization.overtime.currentChartOption'),
    currentSecondaryMetricField: _.get(state, 'visualization.snapshot.currentSecondaryMetricField'),
    comparisonModeOn: _.get(state, 'commonFilters.comparisonModeOn', false),
    comparisonType: _.get(state, 'commonFilters.comparisonType', COMPARISON_MODE_OPTIONS[0].name),
    dateRange: _.get(state, 'commonFilters.dateRange', {}),
    commonFilters: _.get(state, 'commonFilters', {}),
    compareYearRanges:  _.get(state, 'visualization.overtime.compareYearRanges', []),
    dateType: _.get(state, 'commonFilters.dateType'),
    quickFilters: _.get(state, 'drilldown.quickFilters', []),
    isTimeOfDayOn: _.get(state, 'drilldown.isTimeOfDayOn', false),
    timeOfDayFilters: _.get(state, 'drilldown.timeOfDayFilters', []),
    permissionFilters: _.get(state, 'drilldown.permissionFilters', {}),
    drilldown: _.get(state, 'drilldown', {}),
    shapeData: _.get(state, 'visualization.mapOptions.shapeData', {}),
    datasetEntry: _.get(state, 'visualization.mapOptions.shape.datasetEntry', {}),
    selectedShapeIds:_.get(state, 'visualization.mapOptions.shape.selectedShapeIds', {}),
    globalFilters: _.get(state, 'commonFilters.globalFilters', [])
  };
}

VisualizationDescription.propTypes = {
  comparison: PropTypes.object,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentVisualizationType: PropTypes.string,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentDrilldownDimensionField: PropTypes.string,
  currentDrilldownGroupByEntry: commonPropTypes.groupByEntryPropTypes,
  currentSnapshotView: PropTypes.string,
  currentSecondaryMetricField: PropTypes.string,
  comparisonModeOn: PropTypes.bool,
  comparisonType: PropTypes.string,
  dateRange: PropTypes.object,
  compareYearRanges: PropTypes.array,
  dateType: PropTypes.string,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  permissionFilters: commonPropTypes.permissionFiltersPropTypes,
  drilldown: commonPropTypes.drilldownPropTypes,
  shapeData: PropTypes.array,
  datasetEntry: PropTypes.object,
  selectedShapeIds: PropTypes.array,
  globalFilters: PropTypes.array,
  currentSecondaryMetricEntry: commonPropTypes.secondaryMetricEntryPropTypes,
  isComboChart: PropTypes.bool,
  comboChartMetricList: PropTypes.array,
  currentMapView: PropTypes.object,
  totalRowCount: PropTypes.number,
  boundaryMapName: PropTypes.string,
  commonFilters: PropTypes.object,
  isTimeOfDayOn: PropTypes.bool,
  timeOfDayFilters: PropTypes.array
}

export default connect(mapStateToProps)(VisualizationDescription);
