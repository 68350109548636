import _ from 'lodash';
import fp from 'lodash/fp';
import CryptoJS from 'crypto-js';

export const buildQueryString = fp.flow(
  fp.omitBy(fp.isNil),
  fp.mapValues(encodeURIComponent),
  fp.toPairs,
  fp.map(fp.join('=')),
  fp.join('&')
);

export const addhttp = (url) => {
  /* eslint-disable-next-line */
    if (!_.isEmpty(url) && !/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
    }
    return url;
}

export const validateEmail = (email)  => {
  /* eslint-disable-next-line */
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const decryptData = (str) => {
  // https://stackoverflow.com/questions/59102906/cryptojs-decryption-malformed-utf-8-data-error
  const cryptoKey = window.serverConfig.cryptoKey;
  const cryptoIv = window.serverConfig.cryptoIV;

  var C = CryptoJS;
  var Key = C.enc.Utf8.parse(cryptoKey);
  var IV = C.enc.Utf8.parse(cryptoIv);

  var decryptedText = C.AES.decrypt(str, Key, {
    iv: IV,
    mode: C.mode.CBC,
    padding: C.pad.Pkcs7
  });
  return JSON.parse(decryptedText.toString(CryptoJS.enc.Utf8));
};
