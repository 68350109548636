import React from 'react';
import PropTypes from 'prop-types';

import { DateRangeHandlerWrapper } from 'common/components/Hoc/DateRangeHandlerWrapper';
import ComparisonModeDateRangeSelector from './ComparisonModeDateRangeSelector';
import DateRangeSelector from './DateRangeSelector';
import { templateIdPropTypes } from 'common/propTypes';
import { showTimeOfDayFilter } from 'common/config/templateConfiguration';
import TimeOfDayFilter from 'common/components/TimeOfDayFilter/Index';

const TimeFrames = (props) => {
  const {
    currentDateRangeOptions,  currentTemplateId,
    onDateFilterChange
  } = props;

  return(
    <div className='timeframe-filters-section'>
      <div className='section-titles forge-typography--overline'>Time frame</div>
      <div className='time-frame-container'>
        <DateRangeSelector
          key="currentDateRange"
          filterName=""
          currentTemplateId={currentTemplateId}
          dateRangeOptions={currentDateRangeOptions}
          onDateFilterChange={(options) => onDateFilterChange(options, 'currentDateRange')}
        />
        <ComparisonModeDateRangeSelector />
        {showTimeOfDayFilter(currentTemplateId) &&
          <TimeOfDayFilter />
        }
      </div>
    </div>
  )
}

TimeFrames.defaultProps = {
  isComparisonOn: false,
  onDateFilterChange: _.noop,
  onChangeComparisonModeOn: _.noop
};

TimeFrames.propTypes = {
  currentDateRangeOptions: PropTypes.object,
  isComparisonOn: PropTypes.bool,
  previousDateRangeOptions: PropTypes.object,
  currentTemplateId: templateIdPropTypes,
  onDateFilterChange: PropTypes.func,
  onChangeComparisonModeOn: PropTypes.func
}

export default DateRangeHandlerWrapper(TimeFrames);
