import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeButton } from '@tylertech/forge-react';

class DownloadConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleClickRemoveCard = (event) => {
    const { onDownloadConfirm } = this.props;
    this.setState({ showModal: false }, () => {
      onDownloadConfirm();
    });
    event.preventDefault();
  };

  handleKeyDownConfirm = (e, isShow) => {
    if (isEnterButtonPressed(e)) {
      this.onShowConfirm(e, isShow);
    }
  };

  handleKeyDownDownload = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleClickRemoveCard(e);
    }
  };

  handleDownload = () => {
    const { onDownloadConfirm } = this.props;
    this.setState({ showModal: false }, () => {
      onDownloadConfirm();
    });
  }

  onDownloadButtonClick = (event) => {
    document.body.click();
    this.onShowConfirm(event, true)
  }

  handleKeyExport = (event) => {
    document.body.click();
    if (isEnterButtonPressed(event)) {
      this.onShowConfirm(event, true)
    }
  }

  onShowConfirm = (event, isShow) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ showModal: isShow });
  }

  renderDownloadModal() {
    const { showModal } = this.state;
    const { title, message } = this.props;

    if (!showModal) {
      return;
    }

    return (
      <Modal
        onHide={(event) => this.onShowConfirm(event, false)}
        show={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-wrapper delete-container">
          <Modal.Header>
            <Modal.Title> {title} </Modal.Title>
            <button
              aria-label="Close"
              tabIndex={0}
              className="close"
              onKeyDown={(event) => this.handleKeyDownConfirm(event, false)}
              onClick={(event) => this.onShowConfirm(event, false)}
            >
              <i className="icons-close"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-3">
                <span>{message}</span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ForgeButton type="flat">
              <button
                aria-label="Cancel"
                tabIndex={0}
                onClick={(event) => this.onShowConfirm(event, false)}
                onKeyDown={(event) => this.handleKeyDownConfirm(event, false)}
              > Cancel
              </button>
            </ForgeButton>
            <ForgeButton type="raised">
              <button
                aria-label="Confirm"
                className="px-7"
                tabIndex={0}
                onClick={() => this.handleDownload()}
                onKeyDown={(event) => this.handleKeyDownDownload(event)}
              >Download
              </button>
            </ForgeButton>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }

  render() {
    const { buttonLabel, isRenderLabel } = this.props
    const buttonClassName = !_.isEmpty(buttonLabel) ? 'rounded-right-0' : ''; 

    let renderButton = (
      <ForgeButton type="outlined">
        <button className={buttonClassName}
          onClick={(event) => this.onDownloadButtonClick(event)}
          onKeyDown={(event) => this.handleKeyExport(event)}>
          {buttonLabel || 'Export'}
        </button>
      </ForgeButton>)

    if (isRenderLabel) {
      renderButton = (<div
        variant="outline-primary"
        onClick={(event) => this.onDownloadButtonClick(event)}
        onKeyDown={(event) => this.handleKeyExport(event)}>{buttonLabel || 'Export'}</div>)
    }

    return (
      <>
        {this.renderDownloadModal()}
        {renderButton}
      </>
    );
  }
}

DownloadConfirmModal.propTypes = {
  classNames: PropTypes.string,
  onDownloadConfirm: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  isRenderLabel: PropTypes.bool,
};

export default DownloadConfirmModal;
