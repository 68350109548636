// Vendor imports
import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

// Project imports
import { getComparisonMode } from 'common/config/viewConfiguration';
import { DEFAULT_ARROW_COLOR } from 'appConstants';
import { ForgeBadge } from '@tylertech/forge-react';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { checkWGAColorContrast } from 'helpers/colorHelper';

class ComparisonValueSelector extends PureComponent {
  renderComparisonContent = () => {
    const { isSmallView, viewEntry, isLoading, percentage } = this.props;
    const comparisonMode = getComparisonMode(viewEntry);

    if ((isLoading && isSmallView) || percentage === '') {
      return null;
    } else if (isLoading) {
      return (<Spinner variant="primary" size="sm" animation="border" />);
    } else {
      return comparisonMode === 'percentage' ?
        this.renderPercentageContent() : this.renderDiscreteContent();
    }
  }

  renderPercentageContent = () => {
    const { percentage, className, color } = this.props;
    const flagContentColor = checkWGAColorContrast(color);
    return (
      <ForgeBadge open={true} style={{ backgroundColor: color }} className="percentage-content">
        <span style={{ color: flagContentColor }}>{percentage > 0 && '+'}{percentage}% </span>
        {className && <span style={{ color: flagContentColor }} className={className}></span>}
      </ForgeBadge>
    );
  }

  renderDiscreteContent = () => {
    const { isSmallView, viewEntry, discreteValue, className, color } = this.props;
    const flagContentColor = checkWGAColorContrast(color);

    let formattedDiscreteValue = getFormattedNumberValue(discreteValue, viewEntry, true, false, true);
    if (isSmallView) {
      formattedDiscreteValue = getFormattedNumberValue(discreteValue, viewEntry, true, true, true);
    }

    return (
      <ForgeBadge open={true} style={{ backgroundColor: color }} className="percentage-content">
        <span style={{ color: flagContentColor }} >{formattedDiscreteValue} </span>
        {className && <span style={{ color: flagContentColor }} className={className}></span>}
      </ForgeBadge>
    );
  }

  render() {
    return (
      <div className="comparison-percentage-selector">
        {this.renderComparisonContent()}
      </div>
    );
  }
}

ComparisonValueSelector.propTypes = {
  isSmallView: PropTypes.bool,
  discreteValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  viewEntry: PropTypes.object,
  isLoading: PropTypes.bool,
  percentage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  color: PropTypes.string
};

ComparisonValueSelector.defaultProps = {
  isSmallView: false,
  percentage: 0,
  discreteValue: 0,
  isLoading: false,
  className: '',
  color: DEFAULT_ARROW_COLOR
};

export default ComparisonValueSelector;
