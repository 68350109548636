import React from 'react';
import PropTypes from 'prop-types';

const ColorWithCategorySelector = ({ category, color, suffix }) => {
  const renderColor = () => {
    return (
      <span>
        <div className="category-color" style={{ 'backgroundColor': color }}></div>
          {category} {suffix}
      </span>
    )
  }
  return (
    <div className="category-item">
      { _.isEmpty(color) ? `${category} ${suffix}` : renderColor()}
    </div>
  );
}

ColorWithCategorySelector.propTypes = {
  category: PropTypes.string,
  color: PropTypes.string,
  suffix: PropTypes.string
}

ColorWithCategorySelector.defaultProps = {
  suffix: ''
}

export default ColorWithCategorySelector;
