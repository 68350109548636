import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';
import { deleteCard } from 'common/api/collectionApi';
import { onClosePropTypes } from 'common/propTypes';
import { isRestrictedCollection } from '../ManageCollection/collaboratorHelper';

class CardMoveConfirmModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  handleCardMove = () => {
    const { dropCard, dropCollection, onCardCopy, onCardDelete } = this.props;

    const { userCardEntry } = dropCard;
    const isBookmarkCard = _.get(userCardEntry, 'isBookmarkCard', false);
    const cardId = isBookmarkCard ? _.get(userCardEntry, 'cardEntry.id') : _.get(userCardEntry, 'id');
    const collectionId = _.get(dropCollection, 'id');

    this.setState({ isLoading: true });
    deleteCard(collectionId, cardId)
      .then((response) => response.json())
      .then(() => {
        this.setState({ isLoading: false });
        onCardDelete(dropCard);
      })
      .catch((error) => {
        console.log('Error on delete card', error);
        this.setState({ isLoading: false });
      });
    onCardCopy(dropCard, dropCollection, true);
  };

  render() {
    const { onClose, onCardCopy, dropCollection, dropCard, currentCollection } = this.props;
    const isRestricted = isRestrictedCollection(currentCollection);
    return (
      <>
        <Modal show={true} onHide={onClose}>
          <div className="modal-wrapper">
            <Modal.Header>
              <Modal.Title>Move or Copy Metric</Modal.Title>
              <button className="close" onClick={onClose}>
                <i className="icons-close"></i>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div>Would you like to copy or move this metric?</div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-link mr-auto"
                onClick={(e) => onClose(e)}
              >
                Cancel
              </button>
              {
                isRestricted ? null :
                <button onClick={this.handleCardMove}
                className="btn btn-primary px-7">Move </button>
              }
              <button
                onClick={() => onCardCopy(dropCard, dropCollection)}
                className="btn btn-primary px-7"
              >
                Copy
              </button>
            </Modal.Footer>
          </div>
        </Modal>
      </>
    );
  }
}

CardMoveConfirmModal.propTypes = {
  dropCard: PropTypes.shape({
    userCardEntry: PropTypes.object,
  }),
  currentCollection: PropTypes.object,
  dropCollection: PropTypes.object,
  onCardCopy: PropTypes.func,
  onCardDelete: PropTypes.func,
  onClose: onClosePropTypes,
};

export default CardMoveConfirmModal;
