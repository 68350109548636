import React, { Component } from 'react';
import moment from 'moment';

import { Spinner } from 'react-bootstrap';
import {
  getRadarSummaryTableContainer,
  getRadarBarChartContainer,
  getRadarMapChartContainer,
  getRadarChartWithImageURL
} from 'helpers/exportImageHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import PropTypes from 'prop-types';
import { getRadarPageTitle } from 'common/config/customerConfiguration';
import { trackEvent } from 'helpers/eventTracking';
class RadarExportModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
      withTitle: true,
      withMapImage: false,
      withSummaryTable: false,
      withSummaryCsv: false,
      withPrePostChartImage: false,
      withFullPageImage: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { showExportModal, onClose } = this.props;
    if (this.exportModalRef && !this.exportModalRef.contains(e.target)) {
      if(showExportModal) {
        onClose(false);
      }
    }
  }

  handleChangeMapImageOption = () => {
    const { withMapImage } = this.state;
    this.setState({ withMapImage: !withMapImage });
  }

  handleChangeSummaryTableOption = () => {
    const { withSummaryTable } = this.state;
    this.setState({ withSummaryTable: !withSummaryTable });
  }

  handleChangeSummaryCsvOption = () => {
    const { withSummaryCsv } = this.state;
    this.setState({ withSummaryCsv: !withSummaryCsv });
  }

  handleChangePrePostChartOption = () => {
    const { withPrePostChartImage } = this.state;
    this.setState({ withPrePostChartImage: !withPrePostChartImage });
  }

  handleChangeFullPageOption = () => {
    const { withFullPageImage } = this.state;
    this.setState({ withFullPageImage: !withFullPageImage });
  }

  handleClickExportButton = async () => {
    const { csvRadarDownloadCallBack, plotlyChart, onClose, radarTitle, radarDescription } = this.props;
    this.setState({ isLoading: true });

    const {
      withMapImage,
      withSummaryTable,
      withSummaryCsv,
      withPrePostChartImage,
      withFullPageImage
    } = this.state
    const optionsList = {
      withMapImage,
      withSummaryTable,
      withSummaryCsv,
      withPrePostChartImage,
      withFullPageImage
    };

    const chartContainer = plotlyChart();
    const summaryTableImage = await getRadarSummaryTableContainer(chartContainer);
    const barChartImage = await getRadarBarChartContainer(chartContainer);
    const mapChartImage = await getRadarMapChartContainer(chartContainer);

    trackEvent('radar_confirm_export_other');

    if (withSummaryCsv) {
      csvRadarDownloadCallBack(true);
    }

    if (!this.isEnableExport()) {
      onClose(false);
      return null;
    }

    _.each(optionsList, async (value, optionKey) => {
      const options = {
        withMapImage: false,
        withSummaryTable: false,
        withSummaryCsv: false,
        withPrePostChartImage: false,
        withFullPageImage: false,
        radarTitle: radarTitle,
        radarDescription: radarDescription
      };

      options[optionKey] = value;
      const chartOption = {
        chartContainer,
        options,
        summaryTableImage,
        barChartImage,
        mapChartImage
      }
      if (value && !options.withSummaryCsv) {
        await this.exportIndividual(chartOption);
      }
    })

    onClose(false);
  }

  exportIndividual = async (chartOption) => {
    let exportImageUrl;
    exportImageUrl = await getRadarChartWithImageURL(chartOption);
    this.onExportImage(exportImageUrl, chartOption.options)
  }

  handleChangeTitleText = (event) => {
    this.setState({ titleText: event.target.value });
  }

  handleKeyDown = (e, triggerEventFunction) => {
    if (isEnterButtonPressed(e)) {
      triggerEventFunction()
    }
  }

  onExportImage = async (exportImageUrl, options) => {
    const invisibleLink = document.createElement('A');
    invisibleLink.setAttribute('href', exportImageUrl);
    invisibleLink.setAttribute('download', this.getExportFileName(options));
    invisibleLink.click();
    this.setState({ isLoading: false });
  }

  getExportFileName = (options) => {
    let fileName = "";
    if (options.withSummaryTable) {
      fileName = "summary_table_";
    } else if (options.withMapImage) {
      fileName = "map_";
    } else if (options.withPrePostChartImage) {
      fileName = "bar_chart_";
    }

    return `${getRadarPageTitle()}_${fileName}${moment().format("YYYYMMDD")}.jpeg`;
  }

  isEnableExport() {
    const {
      withFullPageImage,
      withMapImage,
      withSummaryTable,
      withPrePostChartImage
    } = this.state;
    return (withFullPageImage || withMapImage ||
      withSummaryTable || withPrePostChartImage);
  }

  renderMapImageSection() {
    const { withMapImage } = this.state;

    return (
      <>
        <div
          tabIndex={0}
          className="custom-control custom-checkbox"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeMapImageOption)}>
          <input
            tabIndex={-1}
            type="checkbox"
            className="custom-control-input"
            id="withMapImage"
            checked={withMapImage}
            onChange={this.handleChangeMapImageOption} />
          <label
            className="custom-control-label font-weight-bold"
            htmlFor="withMapImage">
            JPG of map
          </label>
        </div>
      </>
    );
  }

  renderSummaryTableSection() {
    const { withSummaryTable } = this.state;

    return (
      <>
        <div
          tabIndex={0}
          className="custom-control custom-checkbox"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeSummaryTableOption)}>
          <input
            tabIndex={-1}
            type="checkbox"
            className="custom-control-input"
            id="withSummaryTable"
            checked={withSummaryTable}
            onChange={this.handleChangeSummaryTableOption} />
          <label
            className="custom-control-label font-weight-bold"
            htmlFor="withSummaryTable">
            JPG of summary table
          </label>
        </div>
      </>
    );
  }

  renderSummaryTableCsvSection() {
    const { withSummaryCsv } = this.state;

    return (
      <>
        <div
          tabIndex={0}
          className="custom-control custom-checkbox"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeSummaryCsvOption)}>
          <input
            tabIndex={-1}
            type="checkbox"
            className="custom-control-input"
            id="withSummaryCsv"
            checked={withSummaryCsv}
            onChange={this.handleChangeSummaryCsvOption} />
          <label
            className="custom-control-label font-weight-bold"
            htmlFor="withSummaryCsv">
            CSV of summary table
          </label>
        </div>
      </>
    );
  }

  renderPrePostChartSection() {
    const { withPrePostChartImage } = this.state;

    return (
      <>
        <div
          tabIndex={0}
          className="custom-control custom-checkbox"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangePrePostChartOption)}>
          <input
            tabIndex={-1}
            type="checkbox"
            className="custom-control-input"
            id="withPrePostChartImage"
            checked={withPrePostChartImage}
            onChange={this.handleChangePrePostChartOption} />
          <label
            className="custom-control-label font-weight-bold"
            htmlFor="withPrePostChartImage">
            JPG of bar chart
          </label>
        </div>
      </>
    );
  }

  renderFullPageImageSection() {
    const { withFullPageImage } = this.state;

    return (
      <>
        <div
          tabIndex={0}
          className="custom-control custom-checkbox"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeFullPageOption)}>
          <input
            tabIndex={-1}
            type="checkbox"
            className="custom-control-input"
            id="withFullPageImage"
            checked={withFullPageImage}
            onChange={this.handleChangeFullPageOption} />
          <label
            className="custom-control-label font-weight-bold"
            htmlFor="withFullPageImage">
            JPG of entire page
          </label>
        </div>
      </>
    );
  }

  renderExportFooter() {
    const { onClose } = this.props;
    const { withSummaryCsv } = this.state;
    const isDisabled = !(withSummaryCsv || this.isEnableExport());
    return (
      <>
        <button
          tabIndex={0}
          className="btn btn-link mr-auto"
          onClick={() => onClose(true)}>
          Cancel
        </button>
        <button
          disabled={isDisabled}
          tabIndex={0}
          className="btn btn-primary px-7"
          onClick={this.handleClickExportButton}>
          Export
        </button>
      </>
    );
  }

  renderLoadingSpinner() {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="spinner-overlay">
          <Spinner variant="primary" className="loading-spinner" animation="border" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="bookmark-dropdown-wrapper dropdown-menu show h-auto"
        ref={(ref) => this.exportModalRef = ref}>
        {this.renderLoadingSpinner()}
        <h6> Export other</h6>
        <hr />
        <span className="tx-14">Select which option(s) you would like to export.</span>
        <div className="d-flex flex-column gap-10 my-3">
          {this.renderSummaryTableCsvSection()}
          {this.renderSummaryTableSection()}
          {this.renderPrePostChartSection()}
          {this.renderMapImageSection()}
          {this.renderFullPageImageSection()}
        </div>
        <div className="dropdown-footer">
          {this.renderExportFooter()}
        </div>
      </div>
    );
  }
}

RadarExportModal.propTypes = {
  onClose: PropTypes.func,
  plotlyChart: PropTypes.func,
  csvRadarDownloadCallBack: PropTypes.func,
  showExportModal: PropTypes.bool,
  radarTitle: PropTypes.string,
  radarDescription: PropTypes.string
}

export default RadarExportModal;
