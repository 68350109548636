// Vendor Imports
import React from 'react';
import PropTypes from 'prop-types';

// Project Imports
import DropdownGroup from 'common/components/DropdownGroup/DropdownGroup';
import { getAllBienniumFiscalYears, getBienniumFiscalYearLabel } from 'helpers/fiscalPeriodUtils';
import { BIENNIUM_FISCAL_YEAR } from 'appConstants';
import { ForgeRadio } from '@tylertech/forge-react';

const BienniumFiscalYear = (props) => {
  const { defaultDateType, onInputChange, onSelect, dateRange } = props;
  const allBienniumFiscalYears = getAllBienniumFiscalYears();
  const title = getBienniumFiscalYearLabel(dateRange);

  return (
    <div className="d-flex">
      <div className="date-range-forge-radio">
        <ForgeRadio dense={true}>
          <input
            key='1'
            type="radio"
            tabIndex={0}
            id={BIENNIUM_FISCAL_YEAR}
            name="radios"
            value={BIENNIUM_FISCAL_YEAR}
            aria-label={BIENNIUM_FISCAL_YEAR}
            checked={defaultDateType == BIENNIUM_FISCAL_YEAR}
            onChange={() => onInputChange(BIENNIUM_FISCAL_YEAR)}
          />
          <label className="" htmlFor={BIENNIUM_FISCAL_YEAR}></label>
        </ForgeRadio>
      </div>
      <div className="flex-grow-1">
        <DropdownGroup
          disabled={defaultDateType != BIENNIUM_FISCAL_YEAR}
          key="biennium-year-dropown"
          value={title}
          label="Biennium Fiscal Year"
          toggleButtonName={title}
          options={allBienniumFiscalYears}
          optionDisplayField="name"
          onChange={onSelect}
        />
      </div>
      {/* <div className="d-flex align-items-center gap-5">
        <div className="custom-control custom-radio align-self-center pt-3">
          <input
            key='1'
            type="radio"
            tabIndex={0}
            className="custom-control-input"
            id={BIENNIUM_FISCAL_YEAR}
            checked={defaultDateType == BIENNIUM_FISCAL_YEAR}
            onChange={() => onInputChange(BIENNIUM_FISCAL_YEAR)} />
          <label className="custom-control-label" htmlFor={BIENNIUM_FISCAL_YEAR}></label>
        </div>
        <DropdownGroup
          disabled={defaultDateType != BIENNIUM_FISCAL_YEAR}
          key="biennium-year-dropown"
          value={title}
          label="Biennium Fiscal Year"
          toggleButtonName={title}
          options={allBienniumFiscalYears}
          optionDisplayField="name"
          onChange={onSelect}
        />
      </div> */}
      
    </div>
  );
}

BienniumFiscalYear.propTypes = {
  defaultDateType: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelect: PropTypes.func,
  dateRange: PropTypes.object
}

BienniumFiscalYear.defaultProps = {
  onInputChange: _.noop,
  onSelect: _.noop,
  defaultDateType: ''
}

export default (BienniumFiscalYear);
