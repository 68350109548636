// Vendor Imports
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Project Imports
import { CALENDAR_DATE_TYPE } from 'appConstants';
import { ForgeRadio } from '@tylertech/forge-react';
import DropdownGroup from 'common/components/DropdownGroup/DropdownGroup';
import { getAllCalendarYears } from 'helpers/dateHelper';
import * as commonPropTypes from 'common/propTypes';

const CalendarYear = (props) => {
  const { defaultDateType, onInputChange, onSelect, dateRange, currentDrilldownTemplateId } = props;
  let title = 'Select year';
  if(!_.isEmpty(dateRange)) {
    title = moment(dateRange.endDate).format('YYYY');
  }

  const dropdownOptions = () => {
    return getAllCalendarYears(currentDrilldownTemplateId);
  }

  return (
    <div className="d-flex">
      <div className="date-range-forge-radio">
        <ForgeRadio dense={true}>
          <input
            key='1'
            type="radio"
            tabIndex={0}
            id={CALENDAR_DATE_TYPE}
            name="radios"
            value={CALENDAR_DATE_TYPE}
            aria-label={CALENDAR_DATE_TYPE}
            checked={defaultDateType == CALENDAR_DATE_TYPE}
            onChange={() => onInputChange(CALENDAR_DATE_TYPE)}
          />
          <label className="" htmlFor={CALENDAR_DATE_TYPE}></label>
        </ForgeRadio>
      </div>
      <div className="flex-grow-1">
      <DropdownGroup
        disabled={defaultDateType != CALENDAR_DATE_TYPE}
        key={CALENDAR_DATE_TYPE}
        value={title}
        label="Calendar Year"
        toggleButtonName={title}
        options={dropdownOptions()}
        optionDisplayField=""
        onChange={onSelect}
        />
      </div>
    </div>
  );
}

CalendarYear.propTypes = {
  defaultDateType: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelect: PropTypes.func,
  dateRange: PropTypes.object,
  currentDrilldownTemplateId: PropTypes.string,
  minDate: commonPropTypes.datePropTypes
}

CalendarYear.defaultProps = {
  onInputChange: _.noop,
  onSelect: _.noop,
  defaultDateType: '',
  currentDrilldownTemplateId: null,
}

export default (CalendarYear);
