import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { ErrorBoundaryWrapper } from 'pages/errorboundary/ErrorBoundaryWrapper';
import { dateTypeProps } from 'common/propTypes';
import PropTypes from 'prop-types';

class DateRangeInputs extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    const {
      dateRangeInputClassNames,
      startDate,
      endDate,
      minDate,
      onChangeStartDate,
      onChangeEndDate,
      endDateContainerClassNames,
      startDateContainerClassNames,
      isDisabled
    } = this.props;
    return (
      <>
        <div className={startDateContainerClassNames}>
          <label className="d-block">Start Date</label>
          <DatePicker
            key="1"
            className={dateRangeInputClassNames}
            selected={startDate}
            startDate={startDate}
            minDate={minDate}
            endDate={endDate}
            dateFormat="MMM d, yyyy"
            onChange={onChangeStartDate}
            disabled={isDisabled} />
        </div>
        <div className={endDateContainerClassNames}>
          <label className="d-block">End Date</label>
          <DatePicker
            key="2"
            className={dateRangeInputClassNames}
            selected={endDate}
            startDate={startDate}
            minDate={minDate}
            endDate={endDate}
            dateFormat="MMM d, yyyy"
            onChange={onChangeEndDate}
            disabled={isDisabled} />
        </div>
      </>
    );
  }
}

DateRangeInputs.propTypes = {
  dateRangeContainerClassNames: PropTypes.string,
  dateRangeInputClassNames: PropTypes.string,
  startDateContainerClassNames: PropTypes.string,
  endDateContainerClassNames: PropTypes.string,
  startDate: dateTypeProps,
  endDate: dateTypeProps,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  minDate: PropTypes.string,
  isDisabled: PropTypes.bool
}

DateRangeInputs.defaultProps = {
  dateRangeContainerClassNames: '',
  dateRangeInputClassNames: '',
  startDateContainerClassNames: '',
  endDateContainerClassNames: '',
  isDisabled: false
}

const mapDispatchToProps = {
};

function mapStateToProps() {
  return {
  };
}

function errorContent() {
  return (
    <div className="mr-2">
      something went wrong.
    </div>
    );
}

export default ErrorBoundaryWrapper(
  connect(mapStateToProps, mapDispatchToProps)(DateRangeInputs), errorContent());
