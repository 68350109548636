import React from 'react';
import PropTypes from 'prop-types';
import { ForgeButton, ForgeCard, ForgeDivider } from '@tylertech/forge-react';
import ForgeCloseButton from 'common/components/ForgeCloseButton/ForgeCloseButton';
import LoadingSpinner from 'common/components/LoadingSpinner';

function ManageBookmarkUpdate({
  isLoading,
  closeSaveAnywayOrCopyModal,
  onHandleEditBookmark,
  onToggleSaveAsDropdown,
}) {
  const renderCancelButton = () => {
    return (
      <ForgeButton type="flat">
        <button
          tabIndex={0}
          aria-label="Cancel"
          onClick={() => closeSaveAnywayOrCopyModal()}
        >
          Cancel
        </button>
      </ForgeButton>
    );
  };

  const renderSaveAnywayAndCopyButton = () => {
    return (
      <div>
        <ForgeButton type="outlined" className="">
          <button
            tabIndex={0}
            aria-label="Save anyway"
            onClick={() => onHandleEditBookmark()} >
            Save anyway
          </button>
        </ForgeButton>
        <ForgeButton type="raised" className="ml-3">
          <button
            tabIndex={0}
            aria-label="Create copy"
            onClick={() => onToggleSaveAsDropdown(true)}
          >
            Create copy
          </button>
        </ForgeButton>
      </div>
    );
  };

  return (
    <ForgeCard className="bookmark-save-btn-card">
      <div className="d-flex">
        <div className="forge-typography--title mb-3">Save or create copy</div>
        <ForgeCloseButton onClose={closeSaveAnywayOrCopyModal}  />
      </div>
      <ForgeDivider />
      <LoadingSpinner isLoading={isLoading} />
      <div className="mt-3 mb-3">
        This saved view is being used in more than one collection. If you save,
        your changes will apply to all places.
      </div>
      <ForgeDivider />
      <div className="dropdown-footer mt-3">
        {renderCancelButton()}
        {renderSaveAnywayAndCopyButton()}
      </div>
    </ForgeCard>
  );
}

ManageBookmarkUpdate.propTypes = {
  isLoading: PropTypes.bool,
  closeSaveAnywayOrCopyModal: PropTypes.func,
  onHandleEditBookmark: PropTypes.func,
  onToggleSaveAsDropdown: PropTypes.func,
};

export default ManageBookmarkUpdate;
