import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import RightSideCustomSearch from './RightSideCustomSearch';
import LeftSideCustomSearch from './LeftSideCustomSearch';
import CustomSearchHeader from './CustomSearchHeader';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { updateExpandedFilterMode } from 'actions/advanceSearchActions';
import LoadingSpinner from 'common/components/LoadingSpinner';
const CustomSearchPage = (props) => {
  const { selectedShapeIds, searchFilters } = props;
  const isExpandedFilterMode = useSelector(store => _.get(store, 'advanceSearch.isExpandedFilterMode'));
  const [isSideBar, setIsSideBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [map, setMap] = useState({});
  const [isLargeSideBar, setIsLargeSideBar] = useState(isExpandedFilterMode);
  const [selectedTab, setSelectedTab] = useState('table');
  const dispatch = useDispatch();
  const isComparisonListOpen = useSelector(state => state.advanceSearch.isComparisonListOpen);
  const toggleClassName = isComparisonListOpen ? 'collapsed' : '';

  const onMapLoaded = (newMap) => {
    setMap(newMap);
  }

  const onCreateBookmark = (isLoading) => {
    setIsLoading(isLoading);
  }

  useEffect(()=>{
    setIsLargeSideBar(isExpandedFilterMode);
  }, [isExpandedFilterMode])

  const onToggleButtonClick = (type) => {
    if ( type === 'small') {
      if ( isLargeSideBar ){
        setIsSideBar(false);
      } else {
        setIsSideBar(!isSideBar);
      }
      setIsLargeSideBar(false);
      dispatch(updateExpandedFilterMode(false));
    } else {
      dispatch(updateExpandedFilterMode(!isLargeSideBar));
      setIsLargeSideBar(!isLargeSideBar);
      setIsSideBar(false);
    }
  }

  const onToggleButtonKeyDown = (e, type) => {
    if (isEnterButtonPressed(e)) {
      onToggleButtonClick(type);
    }
  }

  const leftSideClassNames = classNames(
    { "toggle-sidebar-hide": isSideBar },
    { "more-extented": isLargeSideBar }
    );

  return (
    <div className={leftSideClassNames}>
      <LoadingSpinner isLoading={isLoading} />
      <div className="drilldown-page custom-search-page">
        <CustomSearchHeader
          selectedTab={selectedTab}
          selectedShapeIds={selectedShapeIds}
          searchFilters={searchFilters}
        />
        <div className="analysis-page forge-inline-message">
          <div className="filters-section drawer-panel">
            <div className="toggle-btn"
              tabIndex={0}
              onClick={() => onToggleButtonClick('small')}
              onKeyDown={(e) => onToggleButtonKeyDown(e, 'small')}>
              <span className="icons-chevron-left"></span>
            </div>
            <LeftSideCustomSearch map={map} onCreateBookmark={onCreateBookmark} />
          </div>
          {/* MiddleSection */}
          <div className={`content-section ${toggleClassName}`}>
            <RightSideCustomSearch
              onMapLoaded={onMapLoaded}
              map={map}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

CustomSearchPage.propTypes = {
  selectedShapeIds: PropTypes.array,
  searchFilters: PropTypes.array
};

export default CustomSearchPage;
