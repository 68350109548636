import { getFormattedNumberValue  } from 'helpers/chartDataHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';

export const getMapTableHeader = (propsOptions) => {
  const { currentDrilldownViewEntry, shapeName } = propsOptions;

  const primaryMetricName = getPrimaryMetricName(currentDrilldownViewEntry);

  let tableHeaders = [
    { name:shapeName, columnField: "dimension" },
    { name:primaryMetricName, columnField: "dimensionValue" }];

  return {
    tableHeaders,
    tableData : []
  }
}

export const getSummaryTableRows = (tableApiData, summaryTableOptions) => {
  const { currentDrilldownViewEntry, currentDrilldownTemplateId } = summaryTableOptions;

  const nullValueLabel = getNullValueLabel(currentDrilldownTemplateId);
  const sortOrderTableData = _.orderBy(tableApiData, ['shape_name'], ['asc']);
  const summaryData = _.map(sortOrderTableData, (datum) => {
    const count = _.get(datum, 'count');
    const shapeValue = getFormattedNumberValue(count, currentDrilldownViewEntry);
    const dimensionColumnName = _.get(datum, 'shape_name', nullValueLabel)


    return _.isNil(count) ? null : {
      dimension : dimensionColumnName,
      dimensionValue : shapeValue
    };
  });

  return _.without(summaryData, null);
}
