import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateIsUserDataLoading, updateUsers } from '../collaboratorActions';
import { getCollaborateCollectionUsers } from 'common/api/collaborateApi';

export const useFetchUsers = (collectionId, reFetch = false) => {
  const url = getCollaborateCollectionUsers(collectionId);
  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    dispatch(updateIsUserDataLoading(true));

    fetch(url, { signal: controller.signal }).
      then(response => response.json()).
      then((response) => {
        dispatch(updateUsers(response, false, false));
      }).
      catch((error) => {
        console.log('Get collaborate collection users failed', error);
        dispatch(updateUsers([], false, false));
      });

      return () => {
        controller.abort();
      }
  }, [collectionId, reFetch])
}
