import React, { useState, forwardRef, useMemo } from "react";
import PropTypes from 'prop-types';

import { getOrderByRadarMetricEntries } from 'common/config/customerConfiguration'
import RadarMetricCard from './RadarMetricCard'

const RadarMetricCardList = forwardRef(({
  selectedMetricEntries,
  onSelectedMetricsChange,
  onMetricFiltersChange,
  onMetricGlobalFiltersChange,
  metricFilters,
  currentDateRangeOptions,
  metricGlobalFilters,
  metricErrorStatusOptions
}, ref) => {
  const radarMetricEntries = useMemo(() => getOrderByRadarMetricEntries(), []);
  const [activeFilterList, setActiveFilterList] = useState({});

  const setActiveFilter = (filterIndex, isFilterActive) => {
    const newActiveFilterList= { ...activeFilterList, [filterIndex]: isFilterActive };
    setActiveFilterList(newActiveFilterList);
  }

  const renderCard = (metricCardEntry, index) => {
    const metricCardId = _.get(metricCardEntry, 'id');
    const metricIndex = _.get(metricCardEntry, 'metricIndex');
    const activeClassName = _.get(activeFilterList, metricCardId, false) ? 'filter-chip-active' : '';
    const currentMetricFilter = _.find(metricFilters, {id: metricCardId , metricIndex: metricIndex});
    const currentFilter = _.get(currentMetricFilter, 'filters', []);
    const hasError = _.get(metricErrorStatusOptions, `${metricCardId}.hasError`, false);
    return (
      <div className={`radar-metric-card metric-card h-100 ${activeClassName}`}>
        <RadarMetricCard
          metricCardEntry={metricCardEntry}
          index={index}
          key={metricCardId}
          isMetricFilterActive={(isFilterActive) => setActiveFilter(metricCardId, isFilterActive)}
          selectedMetricEntries={selectedMetricEntries}
          onSelectedMetricsChange={onSelectedMetricsChange}
          metricFilters={metricFilters}
          currentFilter={currentFilter}
          currentDateRangeOptions={currentDateRangeOptions}
          onMetricFiltersChange={onMetricFiltersChange}
          onMetricGlobalFiltersChange={onMetricGlobalFiltersChange}
          metricGlobalFilters={metricGlobalFilters}
          metricHasError={hasError} />
      </div>
    );
  }
  const addCard = () => {
    const metricsWithoutSelectedEntries = getMetricsWithoutSelectedEntries();
    const newEntryToInclude = _.first(metricsWithoutSelectedEntries);
    const newMetricEntries = _.isEmpty(newEntryToInclude) ?
      selectedMetricEntries :
      [...selectedMetricEntries, newEntryToInclude];
    const defaultFilter = {
      id: newEntryToInclude['id'],
      metricIndex: _.size(selectedMetricEntries),
      filters: []
    };
    const newMetricFilters = [...metricFilters, defaultFilter];
    onSelectedMetricsChange(newMetricEntries, newMetricFilters);
  }

  const getMetricsWithoutSelectedEntries = () => {
    const selectedMetricEntryIds = _.map(selectedMetricEntries, 'id');
    const metricsWithoutSelectedEntries = _.filter(radarMetricEntries, (metric) => {
      return !_.includes(selectedMetricEntryIds, metric['id'])
    });
    return metricsWithoutSelectedEntries;
  }

  const renderMetricTitle = () => {
    return (
      <div className="control-section-title metrics-heading">
        <div className="section-titles forge-typography--overline" >Metrics</div>
        {/* <span className="ml-1 metrics-info-icon">
          <i className="icons-info-circle"></i>
        </span> */}
      </div>
    )
  }

  const renderAddCardButton = () => {
    if (selectedMetricEntries.length == 3) {
      return null;
    }
    return (
      <>
        <button
          onClick={() => addCard()}
          className="add-metric-btn btn text-primary">
          <i className="icons-circle-add-plus mr-2" /> Add a metric
        </button>
      </>
    );
  }
  const renderMetricCards = () => {
    const isMetricAvailable = _.size(getMetricsWithoutSelectedEntries()) > 0;
    const metricCards = _.map(selectedMetricEntries, (metric, index) => {
      return (
        <div key={index}>
          {renderCard(metric, index)}
        </div>
      );
    });

    return (
      <div className="radar-metric-cards-list">
        {metricCards}
        {isMetricAvailable && renderAddCardButton()}
      </div>
    );
  }

  const render = () => {
    return (
      <div className='radar-metrics-section' ref={ref}>
        {renderMetricTitle()}
        {renderMetricCards()}
      </div>
    );
  }

  return render();
});

RadarMetricCardList.propTypes = {
  selectedMetricEntries: PropTypes.array,
  onSelectedMetricsChange: PropTypes.func,
  onMetricFiltersChange: PropTypes.func,
  onMetricGlobalFiltersChange: PropTypes.func,
  currentDateRangeOptions: PropTypes.object,
  metricFilters: PropTypes.object,
  metricErrorStatusOptions: PropTypes.object,
  metricGlobalFilters: PropTypes.array
};

export default RadarMetricCardList;
