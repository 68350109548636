import React, { useEffect, useState } from "react";
import centroid from '@turf/centroid';
import { LAYERS } from "../partials/ChoroplethMapPartial";
import GlobalEvents from "common/components/GlobalEvents";
import { EVENTS } from "appConstants";

function ChorplethAccessibility(props) {
  const { map, features } = props;
  const [ shapeFeatures, setShapeFeatures ] = useState(features);
  const uniqFeatures = [];
  const onFeatureUpdate = (features) => {
    setShapeFeatures(features);
  }
  useEffect(() => {
    GlobalEvents.on(EVENTS.UPDATE_SHAPE_FEATURES, onFeatureUpdate)
    return () => {
      GlobalEvents.off(EVENTS.UPDATE_SHAPE_FEATURES, onFeatureUpdate)
    }
  }, []);

  const getMapCenter = (feature) => {
    const geometry = _.get(feature, 'geometry', {});
    const pointFeature = centroid(geometry);
    return _.get(pointFeature, 'geometry.coordinates', [])
  }

  const onFeatureFocus = (feature) => {
    const shapeId = _.get(feature, 'properties.shape_id', '');
    map.setFilter(LAYERS.SHAPE_OUTLINE_HIGHLIGHT, ['==', 'shape_id', shapeId]);
    // GlobalEvents.emit("accessibilityShapeFocus", {shapeId, mapType})
  }

  const handleClickOrEnter = (e, feature) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      feature['layer'] = { id: LAYERS.SHAPES_FILL}
      const center = getMapCenter(feature)
      const centerLngLat = {lng: center[0], lat: center[1]}
      map.fire('accessibilityEnter', { event: {lngLat: centerLngLat}, feature });
    }
  }

  const onFeatureBlur = () => {
    map.fire('accessibilityBlur');
  }

  const renderFeatureElements = (feature) =>{
    const shapeName = _.get(feature, 'properties.shape_name', 'EMPTY');
    const shapeId =  _.get(feature, 'properties.shape_id', 'EMPTY');
    if(uniqFeatures.includes(shapeId + shapeName)){
      return null;
    }
    uniqFeatures.push(shapeId + shapeName);

    return(
      <div key={shapeId+shapeName} tabIndex={0} aria-label={shapeName}
        onFocus={() => onFeatureFocus(feature)}
        onBlur={() => onFeatureBlur(feature)}
        onKeyDown={(e) => handleClickOrEnter(e, feature)}
        >
      </div>
    )
  }
  return _.map(shapeFeatures, renderFeatureElements);
}

export default ChorplethAccessibility
