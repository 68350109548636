import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DropDownFooter from 'pages/drilldown/components/QuickFilterBar/DropDownFooter';
import ComparisonDateRangeSelector from './ComparisonDateRangeSelector';
import { DATE_COMPARISON_TYPE_OPTIONS, DATE_COMPARISON_TYPE } from 'appConstants';
import { getLastPeriodDateRange } from 'helpers/dateHelper';
import { getPerviousComparisonYearDateRangeForRange } from 'helpers/fiscalPeriodUtils';
import ForgeDropdowns from '../ForgeDropdowns/ForgeDropdowns';
import { useMemo } from 'react';

const ComparisonDateChipDropDown = (props) => {
  const {
    comparisonType, onApplyClick, onCancelClick, className,
    comparisonDateRanges, templateId, primaryDateRange, dateType,
    isDashboardView
  } = props;

  const defaultComparisonTypeOption = DATE_COMPARISON_TYPE.SAME_PERIOD;
  const [ currentComparisonType, setComparisonType ] = useState(defaultComparisonTypeOption['value']);
  const [ currentDateRanges, setDateRanges ] = useState(comparisonDateRanges);

  useEffect(()=>{
    if(!_.isEmpty(comparisonType)){
      setComparisonType(comparisonType);
    }
  }, [comparisonType])

  useEffect(()=>{
    setDateRanges(comparisonDateRanges);
  }, [comparisonDateRanges])

  const handleComparisonTypeChange = (option) => {
    const type = _.get(option, 'value');
    let newDateRanges = [];
    if(type != DATE_COMPARISON_TYPE.SAME_PERIOD){
      newDateRanges = [getLastPeriodDateRange(primaryDateRange, dateType)];
    }else{
      newDateRanges = [
        getPerviousComparisonYearDateRangeForRange(primaryDateRange)];
    }
    setComparisonType(option['value']);
    setDateRanges(newDateRanges);
  }

  const handleApplyClick = () => {
    onApplyClick(currentComparisonType, _.orderBy(currentDateRanges, 'endDate', 'desc'))
  }

  const renderDateRangeOption = () => {
    const newPrimaryDateRange = useMemo(() => _.cloneDeep(primaryDateRange), [primaryDateRange])

    return(
      <div className='filter-items forge-popup-host'>
        <ComparisonDateRangeSelector
          primaryDateType={dateType}
          templateId={templateId}
          primaryDateRange={newPrimaryDateRange}
          comparisonType={currentComparisonType}
          comparisonDateRanges={currentDateRanges}
          onCompareDateRangesChange={(newDateRanges) => {setDateRanges(newDateRanges)}}
          onDateRangeChange={(newDateRange) => {setDateRanges(newDateRange)}}
          isDashboardView={isDashboardView}
        />
      </div>
    )
  }

  const renderComparisonTypeDropdown = () => {
    let dateCompareOptions = DATE_COMPARISON_TYPE_OPTIONS;

    return(
      <div className='filter-items forge-popup-host'>
        <ForgeDropdowns
          key="comparison-type"
          value={currentComparisonType}
          label="Comparison period"
          options={dateCompareOptions}
          handleOnSelect={handleComparisonTypeChange}
        />
      </div>
    )
  }

  return(
    <div className={className}>
      <div className="filter-chip-show">
        <div className="common-filters-wrapper date-range-filters">
          <div className='filters-body'>
            {renderComparisonTypeDropdown()}
            {renderDateRangeOption()}
          </div>
          <DropDownFooter
            isDisabledApplyButton={
              _.isEmpty(currentDateRanges) || currentDateRanges.length > 5}
            message='No data available for the selected date.'
            onApply={handleApplyClick}
            onRemove={onCancelClick}
            hideResetButton={true}
            hideRemoveButton={false} />
        </div>
      </div>
    </div>
  );
}

ComparisonDateChipDropDown.propTypes = {
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comparisonType: PropTypes.string,
  dateType: PropTypes.string,
  className: PropTypes.string,
  comparisonDateRanges: PropTypes.arrayOf(
    PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  })),
  primaryDateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  onApplyClick: PropTypes.func,
  onCompareDateRangesChange: PropTypes.func,
  onCancelClick: PropTypes.func,
  isDashboardView: PropTypes.bool
}

ComparisonDateChipDropDown.defaultProps = {
  className: ''
}

export default ComparisonDateChipDropDown;
