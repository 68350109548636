import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import YearlyCustomRangeFilter from './YearlyCustomRangeFilter';
import {
  DATE_OPTIONS_TYPE,
  YEARLY_CUSTOM_OPTION,
  LAST_PERIOD_COMPARISON_TYPE
} from 'appConstants';
import { getFiscalYearByStartMonth } from 'helpers/fiscalPeriodUtils';
import { getConfiguredDateType } from 'common/config/customerConfiguration';
import { getDefaultDateRange, getDateRangeForYearly } from 'helpers/dateHelper';
import { ErrorBoundaryWrapper } from 'pages/errorboundary/ErrorBoundaryWrapper';
import DropdownGroup from 'common/components/DropdownGroup/DropdownGroup';
import ForgeDropdowns from '../ForgeDropdowns/ForgeDropdowns';

const dateType = getConfiguredDateType();

class YearlyDateRangeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidUpdate(prevProps){
    const { dateRange, isDisabled } = this.props;

    if(!_.isEqual(prevProps.dateRange, dateRange) &&
      dateType == DATE_OPTIONS_TYPE.YEARLY && !isDisabled){
      this.checkDateRangeYearly();
    }
  }

  componentDidMount() {
    if ( dateType == DATE_OPTIONS_TYPE.YEARLY){
     this.checkDateRangeYearly();
    }
  }

  checkDateRangeYearly () {
    const { dateRange } = this.props;
    const startYear = moment(dateRange.startDate).format('YYYY');
    const endYear = moment(dateRange.endDate).format('YYYY');

    const startDate = moment(dateRange.startDate);
    const endDate = moment(dateRange.endDate);
    const diffDays = endDate.diff(startDate,'days');

    // Found year range => above 365 days will display start year - end year
    if(startYear != endYear && diffDays > 365){
      this.setState({showYearlyCustomRangeFilter: true});
    }else {
      this.setState({showYearlyCustomRangeFilter: false});
    }
  }

  removeCustomRangeYearly = () => {
    const { dateRange, onDateFilterChange } = this.props;
    this.setState({ showYearlyCustomRangeFilter: false });
    const selectedYear = getFiscalYearByStartMonth(moment(dateRange.endDate).format("YYYY"));
    const newDateRange = getDateRangeForYearly(selectedYear);
    onDateFilterChange(newDateRange);
  }

  onYearlyDateChange = (selectedYear, isApply = false) => {
    const { ignoreMonthStart } = this.props;
    if (selectedYear !== YEARLY_CUSTOM_OPTION){
      if(_.isEqual(LAST_PERIOD_COMPARISON_TYPE, selectedYear)) {
        this.props.onDateFilterChange({}, selectedYear.name, isApply);
      }else {
        if(!ignoreMonthStart){
          selectedYear = getFiscalYearByStartMonth(selectedYear);
        }
        let newDateRange = {};
        if(!_.isNaN(Number(selectedYear))){
          newDateRange = getDateRangeForYearly(selectedYear);
        }
        this.props.onDateFilterChange(newDateRange, selectedYear, isApply);
      }
    } else {
      this.setState({showYearlyCustomRangeFilter: true});
    }
  }

  onYearlyCustomDateChange = (selectedDateRange) => {
    this.props.onDateFilterChange(selectedDateRange);
  }

  renderDateRangeDropDown() {
    const {
      dateRange,
      isDisabled,
      dropdownOptions,
      isUseForgeControl
    } = this.props;
    let title = 'Select year';
    if(!_.isEmpty(dateRange)) {
      title = moment(dateRange.endDate).format('YYYY');
    }

    return (
      <>{ isUseForgeControl && 
      <ForgeDropdowns
        isDisabled = {isDisabled}
        key="fiscal-year"
        value={title}
        label="Fiscal Year"
        options={dropdownOptions}
        handleOnSelect={this.onYearlyDateChange}
       />
      } 
      { !isUseForgeControl && 
      <DropdownGroup
        disabled={isDisabled}
        key="fiscal-year"
        value={title}
        label="Fiscal Year"
        toggleButtonName={title}
        options={dropdownOptions}
        optionDisplayField=""
        onChange={this.onYearlyDateChange}
      />}
      </>
    );
  }

  renderYearlyCustomRangeFilter() {
    const {
      currentDrilldownTemplateId,
      isDashboardView,
      isDisabled,
      relativeDateFilterEntry
    } = this.props;
    const defaultDateRange = getDefaultDateRange(relativeDateFilterEntry);
    const { showYearlyCustomRangeFilter } = this.state;
    const customDateRange = _.isEmpty(this.props.dateRange) ? defaultDateRange : this.props.dateRange;
    if(!showYearlyCustomRangeFilter){
      return null;
    }

    return(
      <YearlyCustomRangeFilter
        currentDrilldownTemplateId = {currentDrilldownTemplateId}
        includeDataBeginDate = {!isDashboardView}
        dateRange = {customDateRange}
        isDisabled={isDisabled}
        onDateFilterChange={this.onYearlyCustomDateChange}
        removeCustomRangeYearly={this.removeCustomRangeYearly} />
    );
  }

  renderYearlyDateRangeFilter() {
    const { showYearlyCustomRangeFilter } = this.state;
    if(showYearlyCustomRangeFilter) {
      return this.renderYearlyCustomRangeFilter();
    } else {
      return this.renderDateRangeDropDown();
    }
  }

  render() {
    return (
      <div>
        {this.renderYearlyDateRangeFilter()}
      </div>
    )
  }
}

YearlyDateRangeFilter.propTypes = {
  dateRange: PropTypes.object,
  onDateFilterChange: PropTypes.func,
  dropdownOptions: PropTypes.array,
  isDisabled: PropTypes.bool,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  isDashboardView: PropTypes.bool,
  ignoreMonthStart: PropTypes.bool,
  isUseForgeControl: PropTypes.bool,
  relativeDateFilterEntry: PropTypes.object
}

YearlyDateRangeFilter.defaultProps = {
  isUseForgeControl: false
}

function mapDispatchToProps() {
  return {
  }
}

function mapStateToProps(state) {
  return {
    relativeDateFilterEntry: _.get(state, 'commonFilters.relativeDateFilterEntry', {})
  };
}

function errorContent() {
  return (
    <div className="custom-filter position-relative">
      something went wrong.
    </div>
    );
}

export default ErrorBoundaryWrapper(
  connect(mapStateToProps, mapDispatchToProps)(YearlyDateRangeFilter), errorContent());
