import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { getPaintPropertyForPointIcon,
  getMapStackClusterRadius,
  getMapStackCircleRadius,
  getMapStackCircleHighlightRadius} from '../helpers/mapHelper';
import { getPointsTileUrl } from 'common/api/map';
import { isPointMapView } from '../helpers/MapOptionsHelper';
import { isKMeanClusteringEnable } from 'common/config/customerConfiguration';

export const SOURCES = {
  INCIDENTS: 'incidents'
};
export const LAYERS = {
  INCIDENT_CIRCLE: 'point-circle',
  INCIDENT_ICON: 'point-icon',
  STACKS_CIRCLE: 'stack-circle',
  STACKS_LABEL: 'stack-label',
  POINT_HIGHLIGHT: 'hover-point',
  HIGHLIGHT_INCIDENT_CIRCLE: 'highlight-incident'
};
export const POINT_AND_STACK_STYLES = {
  HIGHLIGHT_BORDER_SIZE: 4,
  STACK_BORDER_SIZE: 3,
  STACK_COLOR: '#fff',
  STACK_OUTLINE_COLOR: '#B8B8B8',
  HOVER_STACK_OUTLINE_COLOR: '#7E7E7E',
  STACK_TEXT_COLOR: '#555555',
  ACTIVE_PIN_HIGHLIGHT_COLOR: 'red'
};

const MAPBOX_GL_ICONS_FONT_NAME = 'socrata-charms-v007 Regular';

export const INCIDENT_COUNT_KEY = 'count';

class PointsAndStackPartial extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.initSources();
    this.initLayers();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.tileParams, this.props.tileParams)){
      this.removeSourcesAndLayers();
      this.initSources();
      this.initLayers();
    } else if (!_.isEqual(prevProps.currentMapView, this.props.currentMapView)){
      this.updateLayoutProperty();
    }
  }

  updateLayoutProperty = () => {
    const { map, currentMapView } = this.props;
    const isStreetViewMap = isPointMapView(currentMapView);

    map.setLayoutProperty(LAYERS.POINT_HIGHLIGHT,
      'visibility',
      isStreetViewMap ? 'visible' : 'none'
    );
    map.setLayoutProperty(LAYERS.STACKS_CIRCLE,
      'visibility',
      isStreetViewMap ? 'visible' : 'none'
    );
    map.setLayoutProperty(LAYERS.STACKS_LABEL,
      'visibility',
      isStreetViewMap ? 'visible' : 'none'
    );
    map.setLayoutProperty(LAYERS.INCIDENT_CIRCLE,
      'visibility',
      isStreetViewMap ? 'visible' : 'none'
    );
    map.setLayoutProperty(LAYERS.INCIDENT_ICON,
      'visibility',
      isStreetViewMap ? 'visible' : 'none'
    );
  }

  initSources() {
    const { map, tileParams } = this.props;

    map.addSource(SOURCES.INCIDENTS, {
      'type': 'vector',
      'geojsonTile': true,
      'cluster': isKMeanClusteringEnable(),
      'clusterRadius': getMapStackClusterRadius(),
      'aggregateBy': [ INCIDENT_COUNT_KEY ],
      'tiles': [getPointsTileUrl(tileParams)]
    });
  }

  initLayers() {
    const { map, currentMapView } = this.props;
    const isStreetViewMap = isPointMapView(currentMapView);

    map.addLayer({
      'id': LAYERS.POINT_HIGHLIGHT,
      'type': 'circle',
      'source': SOURCES.INCIDENTS,
      'source-layer': '_geojsonTileLayer',
      'filter': ['any', ['has', 'point_count'], ['!in', INCIDENT_COUNT_KEY, 1, '1']],
      'layout': {
        'visibility': isStreetViewMap ? 'visible' : 'none'
      },
      'paint': {
        'circle-radius': getMapStackCircleHighlightRadius(),
        'circle-color': POINT_AND_STACK_STYLES.STACK_COLOR,
        'circle-stroke-width': POINT_AND_STACK_STYLES.HIGHLIGHT_BORDER_SIZE,
        'circle-stroke-color': '#fff',
        'circle-opacity': 0.65
      }
    });

    map.addLayer({
      'id': LAYERS.STACKS_CIRCLE,
      'type': 'circle',
      'source': SOURCES.INCIDENTS,
      'source-layer': '_geojsonTileLayer',
      'filter': ['any', ['has', 'point_count'], ['!in', INCIDENT_COUNT_KEY, 1, '1']],
      'layout': {
        'visibility': isStreetViewMap ? 'visible' : 'none'
      },
      'paint': {
        'circle-radius': getMapStackCircleRadius(),
        'circle-color': POINT_AND_STACK_STYLES.STACK_COLOR,
        'circle-stroke-width': POINT_AND_STACK_STYLES.STACK_BORDER_SIZE,
        'circle-stroke-color': POINT_AND_STACK_STYLES.STACK_OUTLINE_COLOR
      }
    });

    map.addLayer({
      id: LAYERS.STACKS_LABEL,
      type: 'symbol',
      'source': SOURCES.INCIDENTS,
      'source-layer': '_geojsonTileLayer',
      'filter': ['any', ['has', 'point_count'], ['!in', INCIDENT_COUNT_KEY, 1, '1']],
      layout: {
        'text-field': `{${INCIDENT_COUNT_KEY}_abbrev}`,
        'text-size': getMapStackCircleRadius(),
        'text-allow-overlap': true,
        'visibility': isStreetViewMap ? 'visible' : 'none'
      },
      paint: {
        'text-color': POINT_AND_STACK_STYLES.STACK_TEXT_COLOR
      }
    });

    map.addLayer({
      id: LAYERS.INCIDENT_CIRCLE,
      type: 'circle',
      'source': SOURCES.INCIDENTS,
      'source-layer': '_geojsonTileLayer',
      'filter': ['all', ['!has', 'point_count'], ['in', INCIDENT_COUNT_KEY, 1, '1']],
      'layout': {
        'visibility': isStreetViewMap ? 'visible' : 'none'
      },
      'paint': {
        'circle-radius': 10,
        'circle-color': POINT_AND_STACK_STYLES.ACTIVE_PIN_HIGHLIGHT_COLOR
      }
    });

    map.addLayer({
      id: LAYERS.INCIDENT_ICON,
      type: 'symbol',
      'source': SOURCES.INCIDENTS,
      'source-layer': '_geojsonTileLayer',
      'filter': ['all', ['!has', 'point_count'], ['in', INCIDENT_COUNT_KEY, 1, '1']],
      'layout': {
        'text-font': [ MAPBOX_GL_ICONS_FONT_NAME ],
        'text-field': getPaintPropertyForPointIcon(),
        'text-size': 10,
        'text-offset': [0, 0.2],
        'text-allow-overlap': true,
        'visibility': isStreetViewMap ? 'visible' : 'none'
      },
      'paint': {
        'text-color': '#ffffff'
      }
    });
  }

  removeSourcesAndLayers() {
    const { map } = this.props;

    _.each(LAYERS, (layerId) => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });
    _.each(SOURCES, (sourceId) => {
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    });
  }

  render() {
    return null;
  }
}

PointsAndStackPartial.propTypes = {
  tileParams: PropTypes.object,
  map: PropTypes.object,
  currentMapView: PropTypes.object
};

export default PointsAndStackPartial;
