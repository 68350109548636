import React from 'react';
import PropTypes from 'prop-types';

import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';

const ResetButton = ({onResetClick}) => {
  const message = "This will reset your collection to the tile order configured for "+
    "the collection and remove any tiles you have added." +
    " Do you want to continue?";

  const handleResetClick = () => {
    onResetClick();
  }

  return (

    <>
      <DeleteConfirmModal
        name="Reset to Default"
        confirmButtonName="Reset to Default"
        title="Reset to Default"
        classNames="mr-1"
        message={message}
        onDeleteConfirm={handleResetClick}
      />
    </>
  );
}

ResetButton.propTypes = {
  onResetClick: PropTypes.func
}
export default ResetButton;