import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ForgeAccordion, ForgeExpansionPanel, ForgeOpenIcon } from '@tylertech/forge-react';
import ForecastingDateFilter from './ForecastingDateFilter';
import ForecastingTypes from './ForecastingTypes';
import ForecastingPrepareData from '../ForecastingPrepareData/ForecastingPrepareData';
class ForecastingFilter extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isAdjustDatesOpen: true
    }
  }

  toggleAdjustDatesPanel = (event) => {
    const targetValue = event.target.value;
    if (targetValue === 'adjustDates') {
      this.setState({
        isAdjustDatesOpen: !this.state.isAdjustDatesOpen
      });
    } else {
      this.setState({
        isAdjustDatesOpen: false
      });
    }
  }

  renderForecastingDateFilter = () => {
    const {
      minDatesTemplateForForecast,
      currentDrilldownTemplateId,
      commonFilters,
      dispatchUpdateForecastDateRange,
      dispatchUpdateFutureForecastDateRange,
      forecastAttributeOptions,
      dispatchUpdateAxisGranularity,
      currentBookmark
    } = this.props;

    return (
      <ForecastingDateFilter
        minDatesTemplateForForecast={minDatesTemplateForForecast}
        commonFilters={commonFilters}
        dispatchUpdateForecastDateRange={dispatchUpdateForecastDateRange}
        dispatchUpdateFutureForecastDateRange={dispatchUpdateFutureForecastDateRange}
        dispatchUpdateAxisGranularity={dispatchUpdateAxisGranularity}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        forecastAttributeOptions={forecastAttributeOptions}
        currentBookmark={currentBookmark}
      />
    )
  }

  render() {
    const {
      forecastAttributeOptions,
      lineChartAttributes,
      currentDateRange,
      futureForecastDateRange,
      currentBookmark
    } = this.props;

    return (
      <ForgeAccordion>
        <ForgeExpansionPanel
          open={this.state.isAdjustDatesOpen}
          on-forge-expansion-panel-toggle={this.toggleAdjustDatesPanel}
          value="adjustDates"
        >
          <div tabIndex={0} slot="header" className='d-flex align-items-center gap-20'>
            <ForgeOpenIcon />
            <div className='forge-typography--headline5'>Adjust dates</div>
          </div>

          <div className='m-1'>
            {this.renderForecastingDateFilter()}
          </div>
        </ForgeExpansionPanel>
        <hr className='dividers' />
          <ForecastingPrepareData
            lineChartAttributes={lineChartAttributes}
            onUpdatePrepareData={this.onUpdatePrepareData}
            currentDateRange={currentDateRange}
            futureForecastDateRange={futureForecastDateRange}
            forecastAttributeOptions={forecastAttributeOptions}
            toggleAdjustDatesPanel={this.toggleAdjustDatesPanel}
            currentBookmark={currentBookmark}
          />
        {/* <ForgeExpansionPanel>
          <div role="button" tabIndex={0} slot="header" className='d-flex align-items-center gap-20'>
            <ForgeOpenIcon />
            <div className='forge-typography--headline5'>Prepare data</div>
            {this.renderIssuesDetected()}
          </div>


        </ForgeExpansionPanel> */}
        <hr className='dividers' />

        <ForgeExpansionPanel on-forge-expansion-panel-toggle={this.toggleAdjustDatesPanel}>
          <div tabIndex={0} slot="header" className='d-flex align-items-center gap-20'>
            <ForgeOpenIcon />
            <div className='forge-typography--headline5'>Add models</div>
          </div>

          <div className='m-1'>
            <ForecastingTypes
              lineChartAttributes={lineChartAttributes}
              forecastAttributeOptions={forecastAttributeOptions}
              currentBookmark={currentBookmark}
            />
          </div>
        </ForgeExpansionPanel>
      </ForgeAccordion>
    )
  }
}

ForecastingFilter.propTypes = {
  minDatesTemplateForForecast: PropTypes.array,
  currentDrilldownTemplateId: PropTypes.any,
  commonFilters: PropTypes.shape({}),
  dispatchUpdateForecastDateRange: PropTypes.func,
  dispatchUpdateFutureForecastDateRange: PropTypes.func,
  dispatchUpdateAxisGranularity: PropTypes.func,
  forecastAttributeOptions: PropTypes.object,
  currentDateRange: PropTypes.object,
  futureForecastDateRange: PropTypes.object,
  lineChartAttributes: PropTypes.object,
  currentBookmark: PropTypes.object,
};

export default ForecastingFilter;
