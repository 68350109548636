import _ from 'lodash';
import {
  getCurrentTemplateEntry,
  getDateColumnLabelFromTemplateEntry,
  isDateColumnConfigured
} from './templateConfiguration';
import { DEFAULT_TARGET_TYPE } from 'appConstants';
import {
  enableCollectionLevelDateFilters,
  showAdvancedForecasting
} from './customerConfiguration';

export const isEndDateOverridden = (currentViewEntry) => {
  return _.isEqual(_.get(currentViewEntry, 'end_date_override_and_ignore', "false"), "true");
}

export const disableMetricTotal = (viewEntry) => {
  return _.chain(viewEntry).get('disable_total', 'false').isEqual('true').value();
}

export const getComparisonMode = (viewEntry) => {
  return _.get(viewEntry,'comparison_mode', 'percentage');
}

export const isAnyModalEnabledForAdvanceForecasting = (viewEntry) => {
  return _.compact(_.filter(viewEntry['advanced_forecasting_modals'], (modal) => {
    if (modal['value'] === 'true'){
      return true;
    }
  })).length > 0;
}

export const isEligibleMetricAdvancedForecasting = (viewEntry) => {
  return showAdvancedForecasting() && isAnyModalEnabledForAdvanceForecasting(viewEntry);
}

export const isMetricHeatChartEnabled = (viewEntry) => {
  return (
    _.get(viewEntry,'visualization.map.enable_metric_weight_heat_map', 'false') == 'true' &&
    !_.isEmpty(_.get(viewEntry, 'aggregate_type'))
  );
}

export const isDateIgnored = (viewEntry) => {
  const isDiscreteMode = _.get(viewEntry, 'date_mode') === 'discrete';
  const isStartDateIgnored = _.get(viewEntry, 'start_date_override_and_ignore') === 'true';
  const isEndDateIgnored = _.get(viewEntry, 'end_date_override_and_ignore') === 'true';
  return (isDiscreteMode && isStartDateIgnored && isEndDateIgnored);
}

export const isIndependentDateFiltersEnabled = (entry) => {
  if(!enableCollectionLevelDateFilters()){
    return false;
  }

  return (_.get(entry, 'enable_date_filter_independently') == 'true')
}

export const canUserEditAbsoluteTargetEntry = (viewEntry) => {
  const canEditAbsoluteTargetEntry = _.get(viewEntry, 'edit_absolute_target_entry', 'false');
  return JSON.parse(canEditAbsoluteTargetEntry);
}

export const isDateColumnConfiguredForViewEntry = (viewEntry) => {
  return !_.isEmpty(_.get(viewEntry, 'fields.date_column', ''));
}

// export const getLeafPageEntryColumnFieldMaping = (tableRowEntry, leafPageEntries) => {
//   let leafPageColumnMap = {};
//   _.each(leafPageEntries, (leafPageEntry) => {
//     if(!_.isEmpty(_.get(tableRowEntry, leafPageEntry.column))) {
//       leafPageColumnMap[leafPageEntry.field] = _.get(tableRowEntry, leafPageEntry.column);
//     }
//   });
//   return leafPageColumnMap;
// }

// export const getSelectedDimensionEntry = (currentDrilldownTemplateId, dimensionFiled ) => {
//   let templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
//   let dimensionsEntries = _.get(templateEntry,'dimension_entries', []);
//   return _.find(dimensionsEntries, (entry) =>{
//     return (entry['field'] == dimensionFiled)
//   });
// }

// export const getDimensionEntry = (currentDrilldownTemplateId, dimensionName) => {
//   let templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
//   let dimensionsEntries = _.get(templateEntry,'dimension_entries', []);
//   return _.find(dimensionsEntries, (entry) =>{
//     return (entry['name'] === dimensionName)
//   });
// }

export const getTargetEntryType = (viewEntry) => {
  return _.get(viewEntry, 'target_entry_type', DEFAULT_TARGET_TYPE);
};

export const getTargetBenchMarkEntry = (viewEntry) => {
  const targetBenchMarkEnry =_.get(viewEntry, 'target_benchmark_entry');
  return targetBenchMarkEnry ? JSON.parse(targetBenchMarkEnry) : {};
};

export const getTargetMetricEntry = (viewEntry) => {
  const targetMetricEntry = _.get(viewEntry, 'target_metric_entry');
  return targetMetricEntry ? JSON.parse(targetMetricEntry) : {};
};

export const isAbsoluteTargetType = (viewEntry) => {
  const targetEntryType = getTargetEntryType(viewEntry);

  return targetEntryType === DEFAULT_TARGET_TYPE;
}

export const getDateColumnLabelFromViewEntry = (viewEntry) => {
  return _.get(viewEntry, 'fields.date_column_label');
}

export const getDateColumnLabel = (templateId, viewEntry) => {
  const templateDateColumnLabel = getDateColumnLabelFromTemplateEntry(templateId);
  const viewDateColumnLabel = _.get(viewEntry, 'fields.date_column_label');

  return _.isEmpty(viewDateColumnLabel) ? templateDateColumnLabel : viewDateColumnLabel;
}

export const isDiscreteModeData = (viewEntry) => {
  const isDiscreteMode = _.get(viewEntry, 'date_mode') === 'discrete';
  const isStartDateIgnored = _.get(viewEntry, 'start_date_override_and_ignore') === 'true';
  const isEndDateIgnored = _.get(viewEntry, 'end_date_override_and_ignore') === 'true';
  const isDateIgnored = isStartDateIgnored || isEndDateIgnored;
  const onlyShowDeltasInTimeline = _.get(viewEntry,'only_show_deltas_in_timeline', 'false') === 'true';

  return isDiscreteMode || (isDateIgnored && onlyShowDeltasInTimeline)
}

export const isDateRangeFirstOrLastPeriodCategory = (viewEntry) => {
  const isRangeMode = _.get(viewEntry, 'date_mode', '') === 'date_range';
  const dateRangeCalculationType = _.get(viewEntry, 'date_range_calculation_type', '');

  return isRangeMode && dateRangeCalculationType !== 'any-time';
}

export const isTemplateLevelConfigEnabled = (viewEntry, configKey) => {
  if(_.isEmpty(configKey)) {
    return true;
  }

  const configOption = _.get(viewEntry, `visualization.map.choropleth.${configKey}`, true);
  return (configOption !== 'custom');
}

export const showDateFilter = (templateEntryOrId, viewEntry) => {
  const isDateFilterEnabled = !isDateIgnored(viewEntry);
  const templateEntry = _.isObject(templateEntryOrId) ?
    templateEntryOrId :
    getCurrentTemplateEntry(templateEntryOrId);

  if(enableCollectionLevelDateFilters()) {
    const isIndependentFilters = isIndependentDateFiltersEnabled(viewEntry) ||
      isIndependentDateFiltersEnabled(templateEntry);

    return (
      isDateColumnConfigured(templateEntry) ||
      (isIndependentFilters && isDateColumnConfiguredForViewEntry(viewEntry) && isDateFilterEnabled)
    );
  } else {
    return isDateColumnConfigured(templateEntry) ||
      (isDateColumnConfiguredForViewEntry(viewEntry) && isDateFilterEnabled);
  }
}
