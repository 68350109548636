import React from 'react';
import PropTypes from 'prop-types';

import CustomButton from 'common/components/CustomButton';

const CollaborateButton = (props) => {
  const { toggleModalOpen } = props;

  return (
    <CustomButton onClick={() => toggleModalOpen(true, 6)} label="Collaborate" />
  );
}

CollaborateButton.propTypes = {
  toggleModalOpen: PropTypes.func
}

export default CollaborateButton;
