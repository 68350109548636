import { SCROLL_TYPES } from "modules/visualization/constants";
const MAP_Y_PADDING = 20;
const MAP_X_PADDING = 20;
const DELTA_DISTANCE = 100;
const OVERLAY_HEIGHT = 33;

export const setScrollZoom = (type, map) => {
  if(type === SCROLL_TYPES.START) {
    if(map) {
      map.scrollZoom.disable();
    }
  } else {
    if(map) {
      map.scrollZoom.enable();
    }
  }
}

export const getMapPanConfig = (mapContainer, event) => {
  const boundingRect = mapContainer.getBoundingClientRect();
  const isBottomOfMapReached = (event.y + MAP_Y_PADDING) > (boundingRect.height + boundingRect.top);
  const isTopOfMapReached = (event.y - (MAP_Y_PADDING + OVERLAY_HEIGHT)) < boundingRect.top;
  const isLeftSideReached = (event.x - MAP_X_PADDING) < boundingRect.left;
  const isRightSideReached = (event.x + MAP_X_PADDING) > (boundingRect.left + boundingRect.width);

  if(isBottomOfMapReached) {
    return { offset: [0, DELTA_DISTANCE], options: { easing } };

  } else if (isTopOfMapReached) {
    return { offset: [0, -DELTA_DISTANCE], options: { easing } };

  } else if(isLeftSideReached) {
    return { offset: [-DELTA_DISTANCE, 0], options: { easing } };

  } else if(isRightSideReached) {
    return { offset: [DELTA_DISTANCE, 0], options: { easing } };
  }

  return {};
}

function easing(t) {
  return t * (2 - t);
}
