import React from 'react';
import {  stringOrObjectProps } from 'common/propTypes';
import PropTypes from 'prop-types';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const FilterChip = (props) => {
  const { name, value, onRemove, label } = props;


  const onKeyDownFilterChip = (value,e) => {
    if (isEnterButtonPressed(e)) {
      onRemove(value,e);
    }
  }

  return (
    <div className="filter-chip">
      <div className="filter-chip-values" tabIndex="0">
        <forge-icon name="filter_list" class="tx-18" />
        <div className="chip-values-text">{name}
          <span className="font-weight-bold"> {label}</span>
        </div>
        <span
          onKeyDown={(e) => onKeyDownFilterChip(value, e)}
          tabIndex={0}
          className="tag-close rounded-circle icons-times"
          onClick={(e) => onRemove(value, e)}
        >
        </span>
      </div>
    </div>
  );
}

FilterChip.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: stringOrObjectProps,
  onRemove: PropTypes.func
}

FilterChip.defaultPropTypes = {
  name: ''
}

export default (FilterChip);
