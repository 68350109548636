import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LoadingSpinner from 'common/components/LoadingSpinner';
import {
  COLLECTION_TYPES,
  EMAIL_STRATEGY_TYPES
} from 'appConstants';
import { isMyViews } from 'pages/dashboard/components/CardList/cardHelper';

class ShareInfo extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  renderUserCollectionButton(){
    const { onShareAccept, onShareDelete } = this.props;
    return(
      <span>
        <button
          onClick={onShareAccept}
          className="btn btn-sm btn-outline-primary align-baseline mr-2">
          Accept
        </button>
        <button
          onClick={onShareDelete}
          className="btn btn-sm btn-outline-primary align-baseline mr-2">
          Delete
        </button>
      </span>
    )
  }

  renderShareInfo() {
    const { bookmarks, currentCollection, onShareAccept } = this.props;
    const isUserCollection = _.isEqual(
      _.get(currentCollection, 'type'),
      COLLECTION_TYPES.USER
    );
    let acceptText = 'Accept';
    const sharedBookmarks = _.filter(bookmarks, (entry) => {
      return !_.get(entry, 'is_shared_accepted', true);
    });
    const sharedCount = _.size(sharedBookmarks);
    const isNeverEmailStrategy = _.get(sharedBookmarks, '0.email_strategy') === EMAIL_STRATEGY_TYPES.NEVER;
    let sharedText = (sharedCount === 1 && !isNeverEmailStrategy) ?
      'A new alert has been shared with you.' :
      'A new view has been shared with you.';

    if(isMyViews(currentCollection)) {
      if (sharedCount <= 0) {
        return null;
      }
      if (sharedCount > 1) {
        sharedText = `${sharedCount} new views/alerts have been shared with you.`+
          " You can accept them or delete them.";
        acceptText = 'Accept all';
      }
    }else if(isUserCollection){
      if(_.get(currentCollection, 'is_shared_accepted', true)){
        return null;
      }
      const userName = _.get(currentCollection, 'shared_user.name');
      sharedText = `${userName} shared this collection with you. Select "Accept" to keep it.`;
    } else {
      return null;
    }

    return (
      <div className="bookmarks-info mb-1 p-2">
        {(isUserCollection && !isMyViews(currentCollection)) ? this.renderUserCollectionButton() : null}
        <span>{sharedText}</span>
        {isMyViews(currentCollection) ?
          <button
            onClick={onShareAccept}
            className="btn btn-outline-primary btn-sm align-baseline ml-2">
            {acceptText}
          </button> : null}
      </div>
    );
  }

  render() {
    const { currentUser } = this.props;
    const { isLoading } = this.state;

    if(_.isEmpty(currentUser)) {
      return null;
    }
    return (
      <>
        <LoadingSpinner isLoading={isLoading} />
        {this.renderShareInfo()}
      </>
    );
  }
}

ShareInfo.propTypes = {
  bookmarks: PropTypes.array,
  currentCollection: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  onShareAccept: PropTypes.func,
  onShareDelete: PropTypes.func,
  currentUser: PropTypes.object
}

export default ShareInfo;
