import React from 'react'
import PropTypes from 'prop-types'
import ForgeProgressLinear from 'common/components/ForgeProgress/ForgeProgressLinear';

function SlaLoadingSpinner(props) {
  const { isLoading } = props; 
  return (
    <div className='w-50 m-auto text-center'>
      <div className='p-2'>
        <b>Good news! You have a lot of data!</b> However, that means it’s
        going to take a little while to assemble your request.
        Please stay on this page for your results.
      </div>
      <div className="sla-data-loading">
        <ForgeProgressLinear isLoading={isLoading} />
      </div>
      <img className='sla-load-data-img' alt='No search results' src="../images/recipes-spot-hero.svg" />
    </div>
  )
}

SlaLoadingSpinner.propTypes = {
  isLoading: PropTypes.bool
}

export default SlaLoadingSpinner
