import { ForgeButton, ForgeCheckbox } from '@tylertech/forge-react';
import React from 'react';
import PropTypes from 'prop-types';

function OnboardFooterSection({
  nextStep,
  isShowWizardAgain,
  setIsShowWizardAgain,
  onHandleClickSkip,
  onHandleClickNext,
  isChangeStatus,
  onHandleClickDone }) {

  const renderFooter = () => {
    return (
      <ForgeButton type="raised" className="ml-auto">
        <button type="button" onClick={onHandleClickDone} forge-dialog-focus="true">Done</button>
      </ForgeButton>
    )
  }

  const renderConfirmFooter = () => {

    return (
      <>
        <div>
          <ForgeButton type="outlined">
            <button type="button" onClick={onHandleClickSkip}>Skip</button>
          </ForgeButton>
          <ForgeCheckbox className="ml-5">
            <input type="checkbox" id="wizard-checkbox"
              checked={isShowWizardAgain}
              onClick={() => setIsShowWizardAgain(!isShowWizardAgain)} />
            <label htmlFor="wizard-checkbox" className="mb-0" slot="label">
              <span>Don’t show this again</span>
            </label>
          </ForgeCheckbox>
        </div>
        <div className="ml-auto">
          <ForgeButton type="raised">
            <button type="button" onClick={() => onHandleClickNext(1)} disabled={!isChangeStatus()}
              forge-dialog-focus="true">Next</button>
          </ForgeButton>
        </div>
      </>
    )
  }

  return (
    <footer className="d-flex dialog__footer p-3">
      {nextStep == 0 && renderConfirmFooter()}
      {nextStep == 1 && renderFooter()}
    </footer>
  )
}

OnboardFooterSection.propTypes = {
  nextStep: PropTypes.number,
  isShowWizardAgain: PropTypes.bool,
  setIsShowWizardAgain: PropTypes.func,
  onHandleClickSkip: PropTypes.func,
  onHandleClickNext: PropTypes.func,
  isChangeStatus: PropTypes.func,
  onHandleClickDone: PropTypes.func
}

export default OnboardFooterSection