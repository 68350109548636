import { useEffect, useState } from 'react';
import { fetchApiData } from 'helpers/apiResponseHelper';
export const useFetchTimeOfDayData = (url) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState();

  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);
    fetchApiData(url, { signal: controller.signal }).
      then((response) => {
        const data = _.get(response, 'week_wise_data', {});
        setData(data);
      }).
      catch(setError).
      finally(() => setLoading(false))

      return () => {
        controller.abort();
      }
  }, [url])

  return { loading, error, data };
}
