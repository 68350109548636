import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import ReduxDispatchAsLink from 'common/components/ReduxDispatchAsLink';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import {
  getAvailableDrilldownEntries,
  getQuickFilterEntries,
} from 'common/config/templateConfiguration';
import { VISUALIZATION_TYPES } from 'appConstants';
import {
  getFilterObject,
  getNewFilters
} from 'helpers/visualizationHelper';
import { updateComparisonModeOn } from 'actions/commonFiltersActions';
import {
  setDrilldownDimensions,
  updateDrilldownDimensionField,
  updateQuickFilters,
  setCurrentVisualizationType
} from 'actions/drilldownActions';
import { updateSnapshotSecondaryMetricField } from 'actions/snapshotActions';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

const dispatchVariancePathLinkActions = ({
  templateId,
  quickFilters: existingQuickFilters,
  drilldownPath,
  dimensionField: targetDimensionField,
  secondaryMetricField,
  snapshotComparePriorYear,
  drilledDownDimensions,
  dispatch
}) => {
  const dimensionFields = _.keys(drilldownPath);
  const selectedDrilldownEntries = _.map(dimensionFields, (field) => {
    return getSelectedDimensionEntry(templateId, field);
  });
  const quickFilterEntries = getQuickFilterEntries(templateId);
  const availableDrilldownEntries = getAvailableDrilldownEntries({
    currentDrilldownTemplateId: templateId,
    drilledDownDimensions: _.map(selectedDrilldownEntries, 'field')
  });
  let newDrilledDownDimensions = _.cloneDeep(drilledDownDimensions);

  let newQuickFilters = _.cloneDeep(existingQuickFilters);

  _.each(selectedDrilldownEntries, (dimensionEntry, index) => {
    const field = dimensionEntry['field'];
    const column = dimensionEntry['column'];
    const dimensionByFilterObject = getFilterObject(
      false,
      column,
      field,
      drilldownPath[field] == '(No value)' ? null : drilldownPath[field],
      (newDrilledDownDimensions.length += index)
    );
    newDrilledDownDimensions.push(dimensionByFilterObject)
    const quickFilterObj = {
      ...dimensionByFilterObject,
      field: _.get(_.find(quickFilterEntries, { column: column }), 'field', dimensionEntry['field'])
    };
    newQuickFilters = getNewFilters(newQuickFilters, quickFilterObj);
  });
  const newDimensionField = targetDimensionField || _.get(_.first(availableDrilldownEntries), 'field');
  if(_.isEmpty(newDimensionField)) {
    dispatch(setCurrentVisualizationType(VISUALIZATION_TYPES.TABLE.type));
  } else {
    dispatch(updateDrilldownDimensionField(newDimensionField));
  }
  dispatch(updateComparisonModeOn(snapshotComparePriorYear));
  dispatch(setDrilldownDimensions(newDrilledDownDimensions));
  dispatch(updateSnapshotSecondaryMetricField(secondaryMetricField));
  dispatch(updateQuickFilters(newQuickFilters));
}

const renderPathSegment = (templateId, field, value) => {
  const dimensionEntry = getSelectedDimensionEntry(templateId, field);

  return (
    <span className="badge badge-light" key={field}>
      <b className="mr-1">{_.get(dimensionEntry, 'name', field) }:</b>
      <i>{value}</i>
    </span>
  )
}

// Set Secondary metric field and entity based on params.
const DrilldownPath = ({
  fieldValues,
  templateId,
  quickFilters,
  dimensionField,
  secondaryMetricField,
  currentSecondaryMetricField,
  drilledDownDimensions,
  snapshotComparePriorYear = false
}) => {
  return (
    <ReduxDispatchAsLink
      buildLinkState={(dispatch) => dispatchVariancePathLinkActions({
        templateId,
        quickFilters,
        drilldownPath: fieldValues,
        dispatch,
        dimensionField,
        secondaryMetricField: secondaryMetricField || currentSecondaryMetricField,
        snapshotComparePriorYear,
        drilledDownDimensions
      })}>
      {_.map(fieldValues, (value, field) => renderPathSegment(templateId, field, value))}
    </ReduxDispatchAsLink>
  )
};

DrilldownPath.propTypes = {
  templateId: commonPropTypes.templateIdPropTypes,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  currentSecondaryMetricField: PropTypes.string,
  dimensionField: PropTypes.string,
  secondaryMetricField: PropTypes.string,
  fieldValues: PropTypes.object,
  snapshotComparePriorYear: PropTypes.bool,
  drilledDownDimensions: PropTypes.array
}

function mapDispatchToProps() {
  return {
  }
}

function mapStateToProps(state) {
  return {
    templateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    quickFilters: _.cloneDeep(_.get(state.drilldown, 'quickFilters', [])),
    currentSecondaryMetricField: _.get(state, 'visualization.snapshot.currentSecondaryMetricField'),
    drilledDownDimensions: _.cloneDeep(_.get(state, 'drilldown.drilledDownDimensions', []))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DrilldownPath);
