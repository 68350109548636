import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { updateCurrentBookmark } from 'actions/bookmarkActions';
import { removeRadarSessionStorage } from 'pages/Radar/radarSessionStorageHelper';

const NotificationManageAllButton = (props) => {
  const { label, id, onSetOpen } = props;
  let history = useHistory();
  const dispatch = useDispatch();

  const onClickManageAllButton = () => {
    onSetOpen(false);
    removeRadarSessionStorage();
    history.push("/app/subscriptions");
    dispatch(updateCurrentBookmark(id));
  }

  return (
    <div className="alert-footer text-center mt-auto py-2 border-top">
      <button
        className="btn btn-outline-primary"
        onClick={onClickManageAllButton}
      >{label}</button>
    </div>
  );
}

NotificationManageAllButton.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onSetOpen: PropTypes.func
}

NotificationManageAllButton.defaultProps = {
  label: 'Manage All',
  id: 0,
  onSetOpen: _.noop
}

export default NotificationManageAllButton;
