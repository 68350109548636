import { ForgeButton } from '@tylertech/forge-react'
import { updateIsComparisonListOpen } from 'actions/advanceSearchActions';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const CompareListButton = () => {
    const isComparisonListOpen = useSelector(state => state.advanceSearch.isComparisonListOpen);
    const dispatch = useDispatch();

    const updatedComparisonList = () => {
        dispatch(updateIsComparisonListOpen(!isComparisonListOpen));
    }
  return (
    <ForgeButton type="raised" className="ml-auto">
        <button type="button" onClick={() => {updatedComparisonList()}}>
          <span>View Compare List</span>
        </button>
      </ForgeButton>
  )
}

export default CompareListButton;