import { COMPARE_VIEW_STACK_COLORS } from 'appConstants';
import { getViewLabel } from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
const wcagContrast = require("wcag-contrast"); // eslint-disable-line no-undef


export const getSortedViewValues = (chartData, nullValueLabel) => {
  let chartDataWithTotalCount = {};

  // TODO refactor
  chartDataWithTotalCount = _.chain(chartData)
  .groupBy((data) => {
    return _.get(data, 'view');
  })
  .map((val, key) => {
    const totalCount = _.reduce(val, (sum, hash) => {
      return sum + Number(_.get(hash, 'count', 0))
    }, 0)
    return { 'view' : getViewLabel(key, nullValueLabel), 'totalCount': totalCount};
  })
  .sortBy((view) => { return _.lowerCase(_.get(view, 'view'));})
  .value();

  return _.map(chartDataWithTotalCount, (datum) => {
    return datum['view'];
  });
}

// Input:  [{view: 'a', ...}, {view: 'b', ...}, {view: 'a', ...}]
// OUTPUT: {'a': '#abcdea', 'b': '#12a123', ...}
export const getStackedBarChartColors = (chartData, nullValueLabel, isSmallView = false) => {
  let colorHash = {};
  const viewValues = getSortedViewValues(chartData);

  const othersCategoryColor = _.last(COMPARE_VIEW_STACK_COLORS)

  _.each(_.uniq(viewValues), (view, index) => {
    const newViewLabel = getViewLabel(view, nullValueLabel);
    colorHash[newViewLabel] = COMPARE_VIEW_STACK_COLORS[index] || othersCategoryColor;
  });

  if(isSmallView || _.size(_.keys(colorHash)) > 9){
    colorHash['Others'] = othersCategoryColor;
  }

  return colorHash;
}


export const isValidColorCode = (colorCode) => {
  const hexaColorMatcher = /^[#]+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})?$/
  const rgbColorMatcher = /^rgb\([0-9]{1,3},\s*[0-9]{1,3},\s*[0-9]{1,3}\)$/
  const rgbaColorMatcher = /^rgba\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)$/
  if(hexaColorMatcher.test(colorCode) || rgbColorMatcher.test(colorCode) ||
    rgbaColorMatcher.test(colorCode)) {
    return true
  } else {
    return false;
  }
}

export const checkWGAColorContrast = (targetEntryColor) => {
  if(_.isEmpty(targetEntryColor)){
    return '#ffffff';
  }
  const contrastRatio = wcagContrast.hex('#ffffff', targetEntryColor);
  return contrastRatio < 4.5 ? '#000000' : '#ffffff';
}
