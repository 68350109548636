import React from 'react'
import PropTypes from 'prop-types'
import { ForgeButton } from '@tylertech/forge-react';

const SearchButton = ({ onSearch, searchButtonRef, searchText }) => {
  return (
    <ForgeButton type="raised">
      <button disabled={_.isEmpty(searchText)} type="button" onClick={onSearch} ref={searchButtonRef}>
        <span>Search</span>
      </button>
    </ForgeButton>
  );
}

SearchButton.propTypes = {
  searchButtonRef: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  searchText: PropTypes.object
};

export default SearchButton;
