import React from 'react';
import PropTypes from 'prop-types';
import CustomDropdownList from '../SolutionDropDown/CustomDropdownList';
import CompFinderIcon from 'modules/AdvanceSearch/CompFinderIcon';

function TemplateDropdown(props) {
  const { templateId, onTemplateChange, templateEntries, searchMethod } = props;
  const templateEntry = _.find(templateEntries, (entry) => {
    if (entry.template_id == templateId) {
      return entry
    }
  });
  const title = _.get(templateEntry, 'name');

  return (
    <div className="sentence-builder-filter d-flex align-items-center gap-10">
      <CompFinderIcon templateId={templateId} searchMethod={searchMethod} />
      <CustomDropdownList
        key="template-dropdown-key"
        id="template-dropdown"
        className="p-0  d-flex"
        title={title}
        optionDisplayField="name"
        options={templateEntries}
        onSelect={onTemplateChange}
      />
    </div>
  )

}

TemplateDropdown.propTypes = {
  templateId: PropTypes.any,
  onTemplateChange: PropTypes.func,
  templateEntries: PropTypes.array,
  searchMethod: PropTypes.string,
}

export default TemplateDropdown
