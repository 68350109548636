import _ from 'lodash';
import {
  getComparisonPeriodDateRanges
} from 'helpers/dateHelper';
import { getQuickFilterEntriesFromTemplate } from 'pages/drilldown/components/QuickFilterBar/helper';

export const getApiParams = (state, {
  withShapeIds = true,
  withDrillDown = true,
  withPolygonFilter = true,
  withCommonFilters = true,
  isComparison = false,
  globalFilterOptions = {}
}) => {

  let mapOptions = {};
  // TODO: use common param structure for mapOptions
  if(_.isEmpty(state.mapOptions)) {
    mapOptions = _.get(state, 'visualization.mapOptions', {});
  }else {
    mapOptions = _.get(state, 'mapOptions', {});
  }
  const params = {
    shapeGroupId: _.get(mapOptions, 'shape.datasetEntry.shape_dataset_id'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId')
  };

  if (withShapeIds) {
    params.shapeIds = _.get(mapOptions, 'shape.selectedShapeIds', []).join(',');
  }



  if (withDrillDown) {
    const drilldown = _.get(state, 'drilldown', {});
    let drilldownWithoutViewEntry = _.omit(drilldown, 'currentDrilldownViewEntry');
    drilldownWithoutViewEntry['quickFilters'] = getValidQuickFilter(drilldown);
    drilldownWithoutViewEntry['currentViewEntryField'] = _.get(
      drilldown, 'currentDrilldownViewEntry.field', '');

    params.drilldownEntry = JSON.stringify(drilldownWithoutViewEntry)
  }

  if (withPolygonFilter) {
    params.polygonGeoJson = JSON.stringify(_.get(mapOptions, 'filteredGeojson', {}))
  }
  if(withCommonFilters) {
    let commonFilters = sanitizeCommonFilterParam(_.cloneDeep(_.get(state, 'commonFilters', {})));
    commonFilters['collectionDateFilters'] = {};
    const replaceGlobalFilters = _.get(globalFilterOptions, 'replaceGlobalFilters', false);
    if (replaceGlobalFilters) {
      commonFilters = updateNewGlobalFilters(commonFilters, globalFilterOptions);
    }
    params.commonFilters =  JSON.stringify(commonFilters);
  }

  if(isComparison) {
    const commonFilters = sanitizeCommonFilterParam(_.cloneDeep(_.get(state, 'commonFilters', {})));
    commonFilters['collectionDateFilters'] = {};
    const comparisonPeriodDateRange = getComparisonPeriodDateRanges(commonFilters)[0];
    const newCommonFilters = _.merge({}, commonFilters, { dateRange: comparisonPeriodDateRange });
    params.commonFilters =  JSON.stringify(newCommonFilters);
  }

  return params;
};

export const getApiParamsForMap = (state, isPreviousPeriodMap = false) => {
  const drilldownEntry = _.get(state, 'drilldown', {});
  const commonFilters = _.get(state, 'commonFilters', {});
  let drilldownEntryWithoutViewEntry = _.omit(drilldownEntry, 'currentDrilldownViewEntry');
  const currentDrilldownViewEntry = _.get(drilldownEntry, 'currentDrilldownViewEntry');
  if (_.isEmpty(currentDrilldownViewEntry)){
    drilldownEntryWithoutViewEntry['currentViewEntryField'] = _.get(
      drilldownEntry, 'currentViewEntryField', '');
  }else{
    drilldownEntryWithoutViewEntry['currentViewEntryField'] =  _.get(
      currentDrilldownViewEntry, 'field', '');
  }

  let dateRange = _.get(commonFilters, 'dateRange', {});
  if (isPreviousPeriodMap) {
    dateRange = getComparisonPeriodDateRanges(commonFilters)[0];
  }
  return {
    shapeGroupId: _.get(state, 'shapeGroupId'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    shapeIds: _.get(state.mapOptions, 'shape.selectedShapeIds', []).join(','),
    drilldownEntry: JSON.stringify(drilldownEntryWithoutViewEntry),
    polygonGeoJson: JSON.stringify(_.get(state, 'mapOptions.filteredGeojson', {})),
    commonFilters: JSON.stringify({
      dateRange,
      globalFilters: _.get(state, 'commonFilters.globalFilters', []),
      additionalDateFilters: _.get(state, 'commonFilters.additionalDateFilters', {})
    }),
  };
};

// currentDrilldownDimensionField is added with last drilledDownDimensions,
// if there is no currentDrilldownDimensionField.
export const getDrilldownEntryForSnapshot = (drilldownEntry) => {
  const parsedDrilldownEntry = JSON.parse(drilldownEntry);
  if(_.isEmpty(parsedDrilldownEntry['currentDrilldownDimensionField']) &&
    !_.isEmpty(parsedDrilldownEntry['drilledDownDimensions'])) {
    const drilledDownDimensions = _.chain(parsedDrilldownEntry['drilledDownDimensions'])
                                    .reject({ 'isDistributionFilter': true })
                                    .last().value();
    let newDrilldownEntry =  _.merge({}, parsedDrilldownEntry, {
      currentDrilldownDimensionField: drilledDownDimensions['field']
    });
    return JSON.stringify(newDrilldownEntry);
  } else {
    return drilldownEntry;
  }
}

function updateNewGlobalFilters(commonFilters, globalFilterOptions) {
  const newCommonFilters = { ...commonFilters };
  const filterColumnEntry = _.get(globalFilterOptions, 'filterColumnEntry');
  const globalFilters = _.get(globalFilterOptions, 'globalFilters', []);

  const newCollectionFilters = _.filter(globalFilters, (globalFilter) => {
    return (_.get(globalFilter, 'field') !== _.get(filterColumnEntry, 'field'));
  });

  newCommonFilters['globalFilters'] = newCollectionFilters;
  return newCommonFilters;
}

export const sanitizeCommonFilterParam = (commonFilters) => {
  const keysToOmit = ['allMetricDateFilters',
    'slaDateFilters',
    'slaGlobalFilters',
    'collectionDateFilters'
  ];
  return _.omit(commonFilters, keysToOmit);
}

function getValidQuickFilter(drilldown) {
  const quickFilters = _.get(drilldown, 'quickFilters', []);
  const templateId = _.get(drilldown, 'currentDrilldownTemplateId');
  const viewEntry = _.get(drilldown, 'currentDrilldownViewEntry');

  const filterEntries = getQuickFilterEntriesFromTemplate(templateId, viewEntry)

  let newQuickFilters = [];
  _.forEach(quickFilters, (datum) => {
    const matchedFilterEntry = _.find(filterEntries, { field: datum.field });
    if(!_.isEmpty(matchedFilterEntry)) {
      newQuickFilters.push(datum);
    }
  })

  return newQuickFilters;
}

export const getTextFilterParams = (apiParams, filterEntry) => {
  const drilldownEntry = JSON.parse(_.get(apiParams, 'drilldownEntry', '{}'));
  const availableTemplateIds = _.get(apiParams, 'availableTemplateIds', []);
  const quickFilters = _.get(drilldownEntry, 'quickFilters', []);
  const drilledDownDimensions = _.get(drilldownEntry, 'drilledDownDimensions', [])
  const filterEntryField = _.get(filterEntry, 'field');
  const newDrilledDownDimensions = _.filter(drilledDownDimensions, (drilledDownDimension) => {
    return filterEntryField !== _.get(drilledDownDimension, 'field');
  });
  const newQuickFilters = _.filter(quickFilters, (quickFilter) => {
    return filterEntryField !== _.get(quickFilter, 'field');
  });
  drilldownEntry['quickFilters'] = newQuickFilters;
  drilldownEntry['drilledDownDimensions'] = newDrilledDownDimensions;

  return {
    ...apiParams,
    drilldownEntry: JSON.stringify(drilldownEntry),
    availableTemplateIds: JSON.stringify(availableTemplateIds)
  };
}