// Vendor Imports
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import QuickFilterBar from './QuickFilterBar';

class QuickFilterHandler extends Component {
  render() {
    const {
      quickFilters, currentDrilldownTemplateId, apiParams, className, viewEntry, onFilterChange,
      showOnlyFiltersContent, filterEntries, disabledFilters, isAnalysisPageFilter
    } = this.props;
    let filterProps = {
      apiParams,
      showOnlyFiltersContent,
      filterEntries,
      className,
      onFilterChange,
      onAddFilter: this.handleAddingFilter,
      onRemoveFilter: this.handleRemovingFilter,
      quickFilters,
      templateId: currentDrilldownTemplateId,
      viewEntry,
      disabledFilters,
      isAnalysisPageFilter
    };

    return (<QuickFilterBar {...filterProps} />);
  }
}

QuickFilterHandler.propTypes = {
  onFilterChange: PropTypes.func,
  quickFilters: PropTypes.array,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  apiParams: PropTypes.object,
  className: PropTypes.string,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  showOnlyFiltersContent: PropTypes.bool,
  filterEntries: PropTypes.array,
  disabledFilters: PropTypes.bool,
  isAnalysisPageFilter: PropTypes.bool
};

export default QuickFilterHandler;
