import $ from 'jquery';

// eslint-disable-line no-console
export const toggleTextSelectionInDom = (isEnabled) => {
  const bodyElem = $('body')[0];
  bodyElem.style.webkitTouchCallout = isEnabled ? 'auto': 'none'; /* iOS Safari */
  bodyElem.style.webkitUserSelect = isEnabled ? 'auto': 'none'; /* Safari */
  bodyElem.style.khtmlUserSelect = isEnabled ? 'auto': 'none'; /* Konqueror HTML */
  bodyElem.style.mozUserSelect = isEnabled ? 'auto': 'none'; /* Firefox */
  bodyElem.style.msUserSelect = isEnabled ? 'auto': 'none'; /* Internet Explorer/Edge */
  bodyElem.style.userSelect = isEnabled ? 'auto': 'none'; /* Non-prefixed version, currently] */
};
