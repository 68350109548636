import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { templateEntriesPropTypes } from 'common/propTypes';
import DateRangeFilterWithBadge from
  'pages/dashboard/components/Collections/CollectionDateFilters/DateRangeFilterWithBadge';
import { updateUserPreferences } from 'common/api/commonApi';
import {
  showGlobalDateAdditionalInfo,
  getGlobalDateAdditionalInfo,
  getGenericDatePickerLabel
} from 'common/config/customerConfiguration';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  BIENNIUM_FISCAL_YEAR,
  DATE_OPTIONS_TYPE,
  RELATIVE_DATE_TYPE,
  CALENDAR_DATE_TYPE
} from 'appConstants';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const DateRangeSelector = (props) => {
  const {
    currentTemplateId, dateRangeOptions, filterName, onDateFilterChange, labelText,
    globalFilters
  } = props;
  const isOverviewPage = useRouteMatch({ path: "/overview" });
  const [previousDateRangeConfig, setPreviousDateRangeConfig] = useState({
    [BIENNIUM_FISCAL_YEAR] : {},
    [DATE_OPTIONS_TYPE.YEARLY]: {},
    [DATE_OPTIONS_TYPE.CUSTOM_RANGE]: {},
    [RELATIVE_DATE_TYPE]: {},
    [CALENDAR_DATE_TYPE]: {}
  });

  const handleDateRangeFilterChange = (options) => {
    const { dateType, dateRange } = options;
    setPreviousDateRangeConfig({ ...previousDateRangeConfig, [dateType]: dateRange });
    onDateFilterChange(options, 'currentDateRange');
  }

  const onUpdateUserPreference = (dateFilters) => {
    if(isOverviewPage?.isExact){
      updateUserPreferences(globalFilters, dateFilters).
      then(() => {
      }).catch((err) => {
        console.log('Error on updating global filters ', err); // eslint-disable-line no-console
      });
    }
  }

  const renderTooltipInfo = () => {
    if(!showGlobalDateAdditionalInfo()){
      return
    }

    const tooltip = (<Tooltip id="global-date-tooltip">{getGlobalDateAdditionalInfo()}</Tooltip>);
    const uniqueKey = 'global-date-tooltip-key';

    return (
      <OverlayTrigger key={uniqueKey} placement="right" overlay={tooltip}>
        <span className='p-2 pointer text-primary'>
          <i className="icons-info-circle-icon"></i>
        </span>
      </OverlayTrigger>
    );
  }

  const configLabelText = getGenericDatePickerLabel();
  const filterLabelText = _.isEmpty(configLabelText) ? labelText : configLabelText;     

  return (
    <div className='time-filter'>
      <div className="forge-typography--caption">
        {filterLabelText}
        {renderTooltipInfo()}
      </div>
      <div className="d-flex">
        <DateRangeFilterWithBadge
          previousDateRangeConfig={previousDateRangeConfig}
          filterName={filterName}
          onUpdateUserPreference={onUpdateUserPreference}
          currentDrilldownTemplateId={currentTemplateId}
          key='current-period-date-range'
          {...dateRangeOptions}
          onDateFilterChange={handleDateRangeFilterChange}
        />
      </div>
    </div>
  );
}

DateRangeSelector.propTypes = {
  filterName: PropTypes.string,
  labelText: PropTypes.string,
  currentDrilldownTemplateId: templateEntriesPropTypes,
  globalFilters: PropTypes.array,
  dateRangeOptions: PropTypes.object,
  onDateFilterChange: PropTypes.func,
  currentTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DateRangeSelector.defaultProps = {
  filterName: '',
  labelText: 'Date filter',
  dateRangeOptions: {},
  onDateFilterChange: _.noop
};

export default DateRangeSelector;
