import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import AppHeader from './components/AppHeader/AppHeader';
import TylerForgeOmniBar from './components/AppHeader/TylerForgeOmniBar';
import UserCredentialCheck from './components/AppHeader/UserCredentialCheck';
import AppContent from './components/AppContent/AppContent';
import {
  showInsight,
  getBrandingTitle,
  isApplicationExpired,
  showRadar,
  isEligibleAdvancedForecasting,
  isAvailableConfigMinDate,
  showAdvanceSearch
} from 'common/config/customerConfiguration';
import { initiatePendo } from 'helpers/pendoConfig';
import { initializeReactGA, trackPageview } from 'helpers/googleAnalyticsHelper';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';

import ReadMoreContent from 'common/components/ReadMoreContent/ReadMoreContent';
import InsightsCalculationsPage from 'pages/InsightsCalculations/index';
import Radar from 'pages/Radar/index';
import SubscriptionsManager from 'pages/SubscriptionsManager/SubscriptionsManager';
import Admin from 'pages/Admin/Admin';
import Forecasting from 'pages/Forecasting';
import ForecastingConcepts from 'pages/Forecasting/ForecastingConcepts';
import AdvanceSearch from 'modules/AdvanceSearch/AdvanceSearch';
import AppExpiry from 'pages/Expiry/AppExpiry';

import { userPropTypes } from './propTypes';
import { getMinDatesUrl } from './api/commonApi';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { setMinDatesForTemplates, setMinDatesLoadingStatus } from 'actions/dashboardActions';
import { isUserIsInSecurityList } from 'helpers/pageHelper';
import { setMinDatesTemplatesForForecast } from 'actions/forecastingActions';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.fetchMinDatesForTemplates();
  }

  componentWillMount() {
    initializeReactGA();
    trackPageview();
  }

  fetchMinDatesForTemplates = async () => {
    try{
      const minDatesUrl = getMinDatesUrl();
      if (isAvailableConfigMinDate()) {
        window.minDatesForTemplateEntries = [];
        this.props.dispatchSetMinDatesForTemplates([]);
        if(isEligibleAdvancedForecasting()) {
          this.props.dispatchSetMinDatesTemplatesForForecast([]);
        }
      } else {
        this.props.dispatchSetMinDatesLoadingStatus(true);
        this.abortFetchController.abort();
        this.abortFetchController = new AbortController();
        const response = await fetchApiData(minDatesUrl, this.abortFetchController);
        // We don't update this value from user interaction. So we set this in global.

        window.minDatesForTemplateEntries = response;
        this.props.dispatchSetMinDatesForTemplates(response);
        this.props.dispatchSetMinDatesLoadingStatus(false);
        if(isEligibleAdvancedForecasting()) {
          this.props.dispatchSetMinDatesTemplatesForForecast(response);
        }
      }
    }catch(e){
      this.props.dispatchSetMinDatesForTemplates([]);
      this.props.dispatchSetMinDatesLoadingStatus(false);
    }
  }

  renderAppRoute() {
    const { currentUser, userFromBellerophon } = this.props;
    // Initialization of pendo configuration.
    initiatePendo(currentUser, userFromBellerophon);
    const currentUserName = _.get(currentUser, 'user.nickname', '');
    const currentEmail = _.get(currentUser, 'user.email', '');
    const isAuthenticated = !_.isEmpty(currentUserName);
    const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);

    return (
      <Switch>
        <Route
          exact
          path="/about"
          render={() => {
            if (isAuthenticated) {
              return (<ReadMoreContent />)
            } else {
              return (<Redirect to="/overview" />)
            }
          }}
        />
        {
          isAuthenticated && showAdvanceSearch() &&
          <Route exact path="/advanced-search"><AdvanceSearch /></Route>
        }
        <Route
          exact
          path="/app/subscriptions"
          render={() => {
            if (isAuthenticated) {
              return (<SubscriptionsManager />)
            } else {
              return (<Redirect to="/overview" />)
            }
          }}
        />
        <Route
          exact
          path="/insights"
          render={() => {
            if (showInsight()) {
              return (<InsightsCalculationsPage />)
            } else {
              return (<Redirect to="/overview" />)
            }
          }}
        />
        <Route
          exact
          path="/radar"
          render={() => {
            if (showRadar()) {
              return (<Radar />)
            } else {
              return (<Redirect to="/overview" />)
            }
          }}
        />
        {(isUserIsInSecurityList(currentEmail) || isLaunchpadAdmin) &&
          <Route
            exact
            path="/admin"
            render={() => {
              return (<Admin />)
            }}
          />
        }

        {isEligibleAdvancedForecasting() && <Route
          exact
          path="/forecasting"
          render={() => {
            if (isAuthenticated) {
              return (<Forecasting />)
            } else {
              return (<Redirect to="/overview" />)
            }
          }}
        />}
        <Route
          exact
          path="/forecasting/concepts"
          render={() => {
            if (isAuthenticated) {
              return (<ForecastingConcepts />)
            } else {
              return (<Redirect to="/overview" />)
            }
          }}
        />
        <Route
          exact
          path='/'
          render={() => {
            return (
              <Redirect to="/overview" />
            )
          }}
        />
        <AppContent />
      </Switch>
    );
  }

  render() {
    const { currentUser, userFromBellerophon } = this.props;
    const isDashboard = _.isEqual(location.hash.split('?')[0], "#!/overview");
    const dashboardClassNames = classNames({ "dashboard-page": isDashboard });
    // const currentUserName = _.get(currentUser, 'user.nickname', '')
    const isAdmin = _.get(currentUser, 'admin', false);
    const isAdminUser = _.get(userFromBellerophon, 'isAdminUser', false);

    const newUserRequest =_.includes(location.hash, '!/request-user')
    if(newUserRequest){
      return this.renderAppRoute();
    }

    return (
      <div className={dashboardClassNames}>
        <TylerForgeOmniBar
          title={getBrandingTitle}
          currentUser={currentUser}
          isAdminUser={isAdminUser || isAdmin}
        />
        <UserCredentialCheck currentUser={currentUser} />
        {isApplicationExpired ? <AppExpiry /> : this.renderAppRoute()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    userFromBellerophon: state.userFromBellerophon
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetMinDatesForTemplates: (minDates) => {
      dispatch(setMinDatesForTemplates(minDates));
    },
    dispatchSetMinDatesLoadingStatus: (isLoading) => {
      dispatch(setMinDatesLoadingStatus(isLoading));
    },
    dispatchSetMinDatesTemplatesForForecast: (minDates) => {
      dispatch(setMinDatesTemplatesForForecast(minDates));
    }
  }
}

Layout.propTypes = {
  currentUser: userPropTypes,
  userFromBellerophon: PropTypes.object,
  dispatchSetMinDatesForTemplates: PropTypes.func,
  dispatchSetMinDatesLoadingStatus: PropTypes.func,
  dispatchSetMinDatesTemplatesForForecast: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
