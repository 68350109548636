import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PermissionSearchField from './PermissionSearchField';
import ConfirmationDialogue from './ConfirmationDialogue';
import { getStrictPermissionColumn } from 'common/config/customerConfiguration';
import { userPermissionChangeMessage } from 'helpers/toastMessages';
import { getConfirmationMessage } from './permissionHelper';
import PermissonValueSelector from 'modules/User/PermissonValueSelector';
import CustomTooltip from 'common/components/CustomTooltip';
const ALL_VALUE = 'All';
class PermissionFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPermissionMapping: _.get(props, 'permissionMapping'),
      showDialogue: false,
      confirmationMsg: '',
      loggerMessage: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { permissionMapping } = this.props;
    if(!_.isEqual(_.get(prevProps, 'permissionMapping'), permissionMapping)){
      this.setState({newPermissionMapping: permissionMapping});
    }
  }
  handleFieldValueChange = (field, value) => {
    let { permissionMapping, email } = this.props;
    const previousValue = _.get(permissionMapping, `${field}`, 'All');
    const previousValueString = previousValue.replaceAll(';', ' or ');
    const newPermissionMapping = {
      ...permissionMapping,
      [field]: value
    };
    const valueString = value.replaceAll(';', ' or ');
    const isConfirm =  !_.isEqual(previousValueString, valueString);
    const confirmationMsg = getConfirmationMessage(field, value, email);

    const loggerMessage = `User ${email} dataset permissions changed from `+
      `${field}: ${previousValueString} to ${field}: ${valueString}`;

    this.setState({
      newPermissionMapping, confirmationMsg,
      showDialogue: isConfirm,
      loggerMessage
    });
  }

  handleConfirmation = (isConfirm) => {
    const { newPermissionMapping, loggerMessage } = this.state;
    const { email, onPermissionChange, permissionMapping } = this.props;
    this.setState({
      newPermissionMapping: permissionMapping,
      confirmationMsg: '',
      showDialogue: false,
      loggerMessage: ''
    });
    if(isConfirm){
      onPermissionChange(
        email,
        {
          permissionMapping: newPermissionMapping,
          toastMessage: userPermissionChangeMessage,
          loggerMessage,
          isPermissionUpdate: true
        }
      );
    }
  }

  renderConfirmationDialogue() {
     const { showDialogue, confirmationMsg } = this.state

    if(!showDialogue){
      return
    }

    return (<ConfirmationDialogue
      message={confirmationMsg}
      messageTitle={'Confirm change'}
      onConfirmationCallBack = {this.handleConfirmation} />)
  }

  renderPermissionFields(){
    const { email, currentUserPermission, currentUser } = this.props;
    const { newPermissionMapping } = this.state;
    const strictPermissionColumn = getStrictPermissionColumn();
    let strictPermissionValue = _.get(
      currentUserPermission,
      `permission_mapping.${strictPermissionColumn}`,
      null
    );

    strictPermissionValue = strictPermissionValue == ALL_VALUE ? null : strictPermissionValue;
    if(_.isEqual(email, currentUser['email'])){
      return _.map(newPermissionMapping, (value, column) => {
        const key = `${column}-${value}`;
        return (
          <CustomTooltip key={key} label="This option cannot be changed." placement='top'>
            <div>
              <PermissonValueSelector category={column} value={value} key={key}/>
            </div>
          </CustomTooltip>
        )
      });
    } else {
      return _.map(newPermissionMapping, (value, column) => {
        const isDisabledField = _.isEqual(strictPermissionColumn, column) && !_.isNull(strictPermissionValue);
        return (
          <PermissionSearchField
            key={value+column+email}
            onValueChange={this.handleFieldValueChange}
            field={column}
            isDisabledField={isDisabledField}
            strictPermissionColumn={strictPermissionColumn}
            strictPermissionValue={strictPermissionValue}
            currentValue={value} />
        );
      });
    }
  }
  render(){
    return(
      <>
        {this.renderPermissionFields()}
        {this.renderConfirmationDialogue()}
      </>
    )
  }
}

PermissionFields.propTypes = {
  permissionMapping: PropTypes.object,
  email: PropTypes.string,
  onPermissionChange: PropTypes.func,
  user: PropTypes.object,
  currentUserPermission: PropTypes.object,
  currentUser: PropTypes.object
}
export default PermissionFields;
