// Vendor Imports
import React, { Component } from 'react';

// Project Imports
import FilterBadges from 'common/components/FilterBadges';
import SearchDropdownListItems from 'common/components/SearchDropdownListItems';

import { getNullValueLabel } from 'common/config/templateConfiguration';
import {
  getFilterItem,
  getNewFilterConditions,
  removeValueAndGetConditions,
  getFormatConditionValues
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { BOOLEAN_TYPES_FIELD, EMPTY_VALUE } from 'appConstants';
import { isEnterButtonPressed, isEscapeButtonPressed } from 'helpers/mouseEventsHelper';
import { templateIdPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';

const DEFAULT_LOGICAL_ENTRY = { name: "IS", value: "=" };

class BooleanFilter extends Component {
  state = {
    logicalOperatorEntry: DEFAULT_LOGICAL_ENTRY,
    conditions: [],
    showDropdown: false
  };

  componentDidMount() {
    const { filter } = this.props;
    this.setState({ conditions: _.get(filter, 'conditions', []) });
  }

  handleClickOutside = (e) => {
    if (this.searchDropdwonRef && !this.searchDropdwonRef.current.contains(e.target)) {
      this.setState({ showDropdown: false });
    }
  }

  getEmptyValue = () => {
    const { templateId } = this.props;
    return getNullValueLabel(templateId);
  }

  onKeyDownToggleButton = (e) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      this.onToggleDropdown(e);
    }
  }

  onKeyDownTopValue = (e, topValue) => {
    if (isEscapeButtonPressed(e)) {
      this.onToggleDropdown(e);
    }

    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      this.setFilterConditions([topValue]);
    }
  }

  onToggleDropdown = () => {
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  }

  handleClickRemoveFilter = (removedValue) => {
    const { conditions, logicalOperatorEntry } = this.state;
    const options = { conditions, logicalOperatorEntry, removedValue, emptyValue: this.getEmptyValue() };
    const newConditions = removeValueAndGetConditions(options);
    const emptyValue = this.getEmptyValue();

    this.updateConditions(getFormatConditionValues({ conditions: newConditions }, emptyValue));
  }

  updateConditions = (conditions) => {
    const { filter, onSearchChange } = this.props;
    this.setState({ conditions }, () => {
      const filterItem = getFilterItem(filter, conditions, BOOLEAN_TYPES_FIELD);
      onSearchChange({ ...filterItem, renderType: BOOLEAN_TYPES_FIELD });
    });
  }

  setFilterConditions = (selectedItems) => {
    const { conditions, logicalOperatorEntry } = this.state;
    const newSelectedItems = (_.get(selectedItems, '0') === this.getEmptyValue()) ?
      [EMPTY_VALUE] :
      selectedItems;
    const newConditions = getNewFilterConditions(conditions, logicalOperatorEntry, newSelectedItems);

    this.updateConditions(newConditions);
  }

  renderFilterBadge = () => {
    const conditionValues = _.get(this.state, 'conditions[0].value');
    return !_.isEmpty(conditionValues) && (
      <FilterBadges values={conditionValues} onRemoveItem={this.handleClickRemoveFilter} />
    );
  }

  renderTopValuesContent = () => {
    const conditionValues = _.get(this.state, 'conditions[0].value', []);
    return _.map([this.getEmptyValue(), true, false], (topValue, index) => {
      const newValue = (topValue === this.getEmptyValue()) ? EMPTY_VALUE : topValue;
      if (_.includes(conditionValues, newValue)) {
        return null;
      }
      return (<li
        aria-label={topValue}
        className="list-group-item"
        tabIndex={0}
        key={index}
        onKeyDown={(e) => this.onKeyDownTopValue(e, topValue)}
        onClick={() => this.setFilterConditions([topValue])}>{topValue + ''}</li>)
    });
  }

  renderSelectedItems = () => {
    const conditionValues = _.get(this.state, 'conditions[0].value');

    return !_.isEmpty(conditionValues) && (
      <SearchDropdownListItems
        searchValues={conditionValues}
        onRemoveSearchItem={this.handleClickRemoveFilter}
        showSearchItems={true}/>
    );
  }

  renderFilterInputDropdown = () => {
    const conditionValues = _.get(this.state, 'conditions[0].value');

    return this.state.showDropdown && (
      <div
        className="dropdown-menu show w-100 border-0"
        aria-labelledby="dropdownMenuButton">
        <div className="value-list">
          <ul className="list-group">
            {this.renderSelectedItems()}
            {!_.isEmpty(conditionValues) && <div className="dropdown-divider"></div>}
            {this.renderTopValuesContent()}
          </ul>
        </div>
        <div className="dropdown-filter-footer m-0">
          <button
            tabIndex={0}
            aria-label="Ok"
            onKeyDown={this.onKeyDownToggleButton}
            className="btn btn-sm btn-primary ml-auto"
            onClick={this.onToggleDropdown}
          >
            Update
          </button>
        </div>
      </div>
    );
  }

  renderSearchDropdownContent = () => {
    return (
      <div className="dropdown w-100"
        ref={this.searchDropdwonRef}
        tabIndex={0}
        aria-label="select a value"
        onKeyDown={this.onKeyDownToggleButton}
      >
        <button className="btn filter-btn"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          tabIndex={-1}
          onClick={this.onToggleDropdown}
          aria-expanded="false">
          Select a value
          <i className="icons-chevron-down text-primary ml-2 align-middle"/>
        </button>
        {this.renderFilterInputDropdown()}
      </div>
    );
  }

  render(){
    return(
      <>
        <div className="d-flex align-items-center gap-5">
          <div className="flex-grow-1 filter-items">
            <label>Value</label>
            {this.renderSearchDropdownContent()}
          </div>
        </div>
        {this.renderFilterBadge()}
      </>
    );
  }
}

BooleanFilter.propTypes = {
  filter: PropTypes.object,
  templateId: templateIdPropTypes,
  onSearchChange: PropTypes.func
};

export default BooleanFilter;
