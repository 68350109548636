 import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  enableSecurityRoles,
  getSecurityRoles
} from 'common/config/customerConfiguration';
import { 
  userRoleChangeMessage,
  userManagerRoleSavedMessage,
  userManagerRoleSavedErrorMessage,
  userRoleSavedErrorMessage
} from 'helpers/toastMessages';
import { getStrictPermissionColumn } from 'common/config/customerConfiguration';

import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import ConfirmationDialogue from './ConfirmationDialogue';

class SecurityRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRole: {},
      showDialogue: false,
      confirmationMsg: ''
    };
  }

  getSecurityRole = (roleId) => {
    const securityRoles = getSecurityRoles();
    return _.find(securityRoles, (role) => {
      return role.role_id == roleId
    });
  }

  handleRoleChange = (user, newRole) => {
    const previousRoleId = _.get(user, 'role');
    if(_.isEqual(previousRoleId, newRole['role_id'])){
      return;
    }
    const oldRoleName = _.get(this.getSecurityRole(previousRoleId), 'name', previousRoleId);
    const confirmationMsg = 'Are you sure you want to change this user’s'+
      ` role from ${oldRoleName} to ${newRole['name']}?`;
    const loggerMessage = `User ${user.email} role changed from ${oldRoleName} to ${newRole['name']}`;
    this.setState({newRole, confirmationMsg, showDialogue: true, loggerMessage});
  }

  handleConfirmation = (isConfirm) => {
    const { newRole, loggerMessage } = this.state;
    const { user, onRoleChange } = this.props;
    this.setState({
      newRole: {},
      confirmationMsg: '',
      showDialogue: false,
      loggerMessage: ''
    });
    const showAdminMessage = (_.isEqual(newRole['can_manage_user'], 'true') &&
      _.isEqual(user['isManageUser'], 'false'));
    const toastMessage = showAdminMessage ? userManagerRoleSavedMessage : userRoleChangeMessage;
    const toastErrorMessage = showAdminMessage ? userManagerRoleSavedErrorMessage : userRoleSavedErrorMessage;

    if(isConfirm){
      onRoleChange(user.email, {newRole, toastMessage, loggerMessage, toastErrorMessage });
    }
  }

  renderConfirmationDialogue() {
    const { showDialogue, confirmationMsg } = this.state

    if(!showDialogue){
      return
    }

    return (<ConfirmationDialogue
      message={confirmationMsg}
      messageTitle={'Confirm change'}
      onConfirmationCallBack = {this.handleConfirmation} />)
  }

  getAllowedSecurityRoles = () => {
    const { currentUserRole } = this.props;
    const strictPermissionColumn = getStrictPermissionColumn();
    const assignmentPrivileges = _.map(_.get(currentUserRole, 'assignment_privileges', []), 'id');
    const securityRoles = _.compact(_.map(getSecurityRoles(), (role) =>{
      if(!_.isEmpty(role.name)){
        return role
      }
    }));
    const allowedSecurityRoles = _.filter(securityRoles, (role) => {
      return _.includes(assignmentPrivileges, role.role_id);
    })
    return _.isEmpty(strictPermissionColumn) ? securityRoles : allowedSecurityRoles;
  }

  renderSecurityRoles() {
    if(!enableSecurityRoles()) {
      return null
    }
    const { user, trIndex } = this.props;
    const securityRoles = this.getAllowedSecurityRoles();
    const isWildCardUser = _.startsWith(user.email, '*');
    const currentRole = this.getSecurityRole(user.role);
    return(
      <td>
        <div className='security-roles-dropdown'>
          <SolutionDropDown
            className="p-0 xl-overflow"
            size="sm"
            id={'security'+trIndex}
            title= {_.get(currentRole, 'name', 'Select')}
            options={securityRoles}
            disabled={isWildCardUser}
            onSelect={(option) =>this.handleRoleChange(user, option)}
            showDescription={true}/>
        </div>
      </td>
    )
  }

  render(){
    return(
      <>
        {this.renderSecurityRoles()}
        {this.renderConfirmationDialogue()}
      </>
    )
  }
}

SecurityRoles.propTypes = {
  user: PropTypes.object,
  currentUserRole: PropTypes.object,
  onRoleChange: PropTypes.func,
  trIndex: PropTypes.number
}
export default SecurityRoles;
