import _ from 'lodash';
import { TOOLTIP_CONSTANTS } from '@tylertech/forge';
import { buildQueryString } from 'helpers/HttpHelper';

const delay = TOOLTIP_CONSTANTS.numbers.DEFAULT_DELAY;

export default class PropertyPdfHandler {

  async getPropertyPdf(flyoutDetails, value) {
    if (_.isEmpty(flyoutDetails)) {
      return '';
    }
  
    const queryParams = {
      country: _.get(flyoutDetails, 'comp_finder_country', '').replace(/County/i, "").trim(),
      quickRefId: _.get(flyoutDetails, 'comp_finder_quick_ref_id', ''),
      taxYear: _.get(flyoutDetails, 'comp_finder_tax_year', '')
    };
    const href = `/api/advanced_search/get_external_pdf.pdf?${buildQueryString(queryParams)}`;
  
    try {
      const response = await fetch(href);
      if (!response.ok) {
        throw new Error(`API returned status ${response.status}`);
      }
      
      return (
        `<a href=${href} target='_blank' rel='noopener noreferrer'>` +
          value +
          `<forge-tooltip text='View PRC in new tab' delay=${delay} 
                position='top'></forge-tooltip>` +
        "</a>"
      );
    } catch (error) {
      return (
        `<span>` +
          value +
          `<forge-tooltip text='PRC is not public' delay=${delay} 
                position='top'></forge-tooltip>` +
        `</span>`
      );
    }
  }

  getTooltipText(isApiAvailable, isPropertyColumn, text) {
    if(isApiAvailable === null && isPropertyColumn){
      return(`<span>` +
          text +
          `<forge-tooltip text='Documents are not available for this county. Please contact them directly.' 
            delay=${delay} 
            position='top'></forge-tooltip>` +
        `</span>`)
    }else{
      return text;
    }
  }
}
