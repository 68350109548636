import $ from 'jquery';

const TIPSY_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};
const HOVER_WIDTH = 27;
const HOVER_HEIGHT = 14;

export const getTipsyPosition = (anchor, container) => {
  const containerRect = container.getBoundingClientRect();
  const containerHeight = containerRect.height / 2;
  const containerWidth = containerRect.width / 2;
  let position;

  if (anchor.x < (containerWidth / 2)) {
    position = TIPSY_POSITION.RIGHT;
  } else if(anchor.x > (containerWidth + (containerWidth / 2 ))) {
    position = TIPSY_POSITION.LEFT;
  }  else if (anchor.y > containerHeight) {
    position = TIPSY_POSITION.TOP;
  } else if (anchor.y < containerHeight) {
    position = TIPSY_POSITION.BOTTOM;
  } else {
    position = TIPSY_POSITION.BOTTOM;
  }

  return position;
};

// TODO: need refactoring this function
export const getTipsyStyle = (anchor, position, container, viewMode, isTimelineChart) => {
  const containerRect = container.getBoundingClientRect();
  const hoverWidth = (viewMode === 'small') ? HOVER_WIDTH : 0;
  const hoverHeight = (viewMode === 'small') ? HOVER_HEIGHT : 0;

  if (isTimelineChart) {
    if (position === TIPSY_POSITION.LEFT) {
      return {
        // bottom: containerRect.height - (anchor.y + hoverHeight) - 35,
        // right: containerRect.width - (anchor.x + hoverWidth) - 6
        bottom: containerRect.height - (anchor.y + hoverHeight) + 45,
        right: containerRect.width - (anchor.x + hoverWidth) - 170
      };
    } else if (position === TIPSY_POSITION.RIGHT) {
      return {
        bottom: containerRect.height - (anchor.y + hoverHeight) - 35,
        left: anchor.x - 6
      };
    } else if (position === TIPSY_POSITION.BOTTOM) {
      return {
        top: anchor.y,
        left: anchor.x
      };
    } else {
      return {
        bottom: containerRect.height - (anchor.y + hoverHeight),
        left: anchor.x
      };
    }
  } else {
    if (position === TIPSY_POSITION.TOP) {
      return {
        bottom: containerRect.height - (anchor.y + hoverHeight),
        left: anchor.x
      };
    } else if(position === TIPSY_POSITION.LEFT) {
      return {
        top: anchor.y,
        right: containerRect.width - (anchor.x + hoverWidth)
      };
    } else {
      return {
        top: anchor.y,
        left: anchor.x
      };
    }
  }
};

export function getMargins(chartContainer) {
  const $plotElem = $(chartContainer).find('svg.main-svg .cartesianlayer .subplot.xy .plot');
  const transform = $plotElem.attr('transform');
  const match = transform.match(/translate\((?<x>\d*), ?(?<y>\d*)\)/);

  if (match) {
    return { x: Number(match.groups.x), y: Number(match.groups.y) };
  } else {
    return { x: 0, y: 0 };
  }
}


export function getPieChartMargins(chartContainer) {
  const $plotElem = $(chartContainer).find('svg.main-svg .hoverlayer .hovertext');
  const transform = $plotElem.attr('transform');
  const match = transform.match(/translate\((?<x>\d+\.?\d*), ?(?<y>\d+\.?\d*)\)/)

  if (match) {
    return { x: Number(Math.floor(match.groups.x)), y: Number(Math.floor(match.groups.y)) };
  } else {
    return { x: 0, y: 0 };
  }
}
