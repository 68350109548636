// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

// Project Imports
import { getPointsTileUrl } from 'common/api/map';
import { isHeatMap } from '../helpers/MapOptionsHelper';

const SOURCES = {
  HEAT_CLUSTERS: 'heat-clusters-source'
};
export const LAYERS = {
  HEAT_CLUSTERS: 'heat-clusters-layer'
};

class HeatMapPartial extends Component {
  componentDidMount() {
    this.initSources();
    this.initLayers();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.tileParams, this.props.tileParams)){
      this.removeSourcesAndLayers();
      this.initSources();
      this.initLayers();
    } else if (!_.isEqual(prevProps.currentMapView, this.props.currentMapView)){
      this.updateLayoutProperty();
    }
  }

  updateLayoutProperty(){
    const { map, currentMapView } = this.props;

    map.setLayoutProperty(LAYERS.HEAT_CLUSTERS,
      'visibility',
      isHeatMap(currentMapView) ? 'visible' : 'none'
    );
  }

  initSources() {
    const { map, tileParams } = this.props;

    map.addSource(SOURCES.HEAT_CLUSTERS, {
      'type': 'vector',
      'geojsonTile': true,
      'tiles': [getPointsTileUrl(tileParams)]
    });
  }

  initLayers() {
    const { map, currentMapView, isEsriBaseMapStyle } = this.props;
    const insertBeforeLayer = isEsriBaseMapStyle ? '' : 'waterway-label';

    map.addLayer({
      'id': LAYERS.HEAT_CLUSTERS,
      'type': 'heatmap',
      'source': SOURCES.HEAT_CLUSTERS,
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': isHeatMap(currentMapView) ? 'visible' : 'none'
      },
      'paint': {
        // Increase the heatmap weight based on number of points
        'heatmap-weight': {
          'property': 'count',
          'type': 'exponential',
          'stops': [
          [0, 0.2],
          [10, 0.5],
          [150, 1]
          ]
        },
        // Increase the heatmap color intensity by zoom level
        // (heatmap-intensity is a multiplier on top of heatmap-weight)
        'heatmap-intensity': {
          'stops': [
          [0, 0.5],
          [9, 1]
          ]
        },
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
            0, 'rgba(33,102,172,0)',
            0.2, 'rgb(103,169,207)',
            0.4, 'rgb(209,229,240)',
            0.6, 'rgb(253,219,199)',
            0.8, 'rgb(239,138,98)',
            1, 'rgb(178,24,43)'
        ],
        // Adjust the heatmap radius by zoom level
        'heatmap-radius': {
          'stops': [
          [0, 20],
          [9, 15]
          ]
        }
      }
    }, insertBeforeLayer);
  }

  removeSourcesAndLayers() {
    const { map } = this.props;

    if (map.getLayer(LAYERS.HEAT_CLUSTERS)) {
      map.removeLayer(LAYERS.HEAT_CLUSTERS);
    }

    if (map.getSource(SOURCES.HEAT_CLUSTERS)) {
      map.removeSource(SOURCES.HEAT_CLUSTERS);
    }
  }

  render() {
    return null;
  }
}

HeatMapPartial.propTypes = {
  tileParams: PropTypes.object,
  map: PropTypes.object,
  currentMapView: PropTypes.object,
  isEsriBaseMapStyle: PropTypes.bool
};

export default HeatMapPartial;
