import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const SearchInputAndDropdownItems = ({
  value = '', items, onClick, onFilterItemClick, onShowFilterValue
}) => {
  const [inputValue, setInputValue] = useState('');
  const [show, setShow] = useState(false);

  const onInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
  }

  const onClickItem = (item) => {
    onClick(item);
    setShow(false);
  }

  const onToggleDropdown = () => {
    setShow(!show);
    onShowFilterValue(!show);
    onFilterItemClick('FilterBy');
  }

  const onKeyDownToggleDropdown = (e) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      setShow(!show);
    }
  }

  const onKeyDownClickItem = (e, item) => {
    if (isEnterButtonPressed(e)) {
      onClickItem(item);
    }
  }

  const renderListItem = (item, index) => {
    const { name, field } = item;
    const activeClass = (field === value) ? 'active' : '';

    return (
      <li tabIndex="0" aria-label={name}
        key={index}
        className={`list-group-item ${activeClass}`}
        onClick={() => onClickItem(item)}
        onKeyDown={(e) => onKeyDownClickItem(e,item)}
      >
      <div className="d-flex align-items-center">
        <div className="list-value-items">
          {name}
        </div>
      </div>
      </li>
    );
  }

  const renderBodyContent = () => {
    const activeClass = show ? 'show' : '';
    const inputValueLowerCase = inputValue.toLowerCase();
    const newFilteredItems = _.filter(items, ({ name }) => {
      return (name && _.includes(name.toLowerCase(), inputValueLowerCase));
    });

    return show && (
      <div className={`${activeClass} type-head-value border-0`}>
        <div className="topvalue-search-wrapper">
            <input
              placeholder='Search'
              type="text"
              value={inputValue}
              className="form-control"
              onChange={onInputChange}
            />
            <div className="value-list with-help-text">
              <div className="help-text ">
                <em>Begin typing to filter or scroll to select a value</em>
              </div>
              <ul className="list-group">
                {_.map(newFilteredItems, renderListItem)}
                {_.isEmpty(newFilteredItems) && <li className='list-group-item'>No Results</li>}
              </ul>
            </div>
        </div>
      </div>
    )
  }

  const className = show ? '' : 'search-column';
  const matchedItem = _.find(items, { field: value });
  const newValue = _.get(matchedItem, 'name', 'Search or select a value');

  return (
    <div className={`dropdown w-100 ${className} filter-items`} tabIndex="0"
      onKeyDown={(e) => onKeyDownToggleDropdown(e)}
      aria-label="select a value">
      {!show &&
        <button className="btn filter-btn"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          onClick={onToggleDropdown}
          onKeyDown={(e) => onKeyDownToggleDropdown(e)}
          tabIndex={-1}
          aria-expanded="false">
            {newValue}
          <i className="icons-chevron-down text-primary align-middle"/>
        </button>
      }
      {renderBodyContent()}
    </div>
  );
}

SearchInputAndDropdownItems.propTypes = {
  value: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func,
  onShowFilterValue: PropTypes.func,
  onFilterItemClick: PropTypes.func
};

SearchInputAndDropdownItems.defaultProps = {
  onShowFilterValue: _.noop,
  onFilterItemClick: _.noop
}


export default SearchInputAndDropdownItems;
