import { buildQueryString } from 'helpers/HttpHelper';
import { fetchApiDataWithStatus } from 'helpers/apiResponseHelper';

export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    return (
      !_.isEqual(_.get(vizOptions,'apiParams'), _.get(previousVizOptions, 'apiParams'))
    );
  },
  getData: (vizOptions, abortableFetchController) => {
    const { apiParams } = vizOptions;
    const apiUrl = `/api/visualization/distribution_chart.json?${buildQueryString(apiParams)}`;
    return fetchApiDataWithStatus(apiUrl, abortableFetchController);
  }
};
