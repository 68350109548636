import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import { getMonitorConditionName } from 'pages/SubscriptionsManager/components/Bookmark/helper';
import { setDefaultSubscriptionBookmarkActions } from './NotificationListActions';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const NotificationListItem = (props) => {
  const { bookmark, onSetOpen } = props;
  const monitorConditions = _.get(bookmark, 'bookmarkOptions.monitorConditions');
  const dispatch = useDispatch();
  let history = useHistory();

  const onClickListItem = () => {
    setDefaultSubscriptionBookmarkActions({ bookmark, dispatch });

    history.push("/analysis");

    onSetOpen(false);
  }

  const handlePressListItem = (e) => {
    if(isEnterButtonPressed(e)) {
      onClickListItem();
    }
  }

  const renderBookmarkCondition = (monitorCondition, index) => {
    const monitorConditionName = getMonitorConditionName(monitorCondition, bookmark);

    return (
      <div className="list-subtitle" key={index}>{monitorConditionName}</div>
    );
  }

  const renderNotificationListItem = () => {
    return (
      <li
        className="list-group-item"
        tabIndex="0"
        key={bookmark.id}
        onKeyDown={handlePressListItem}
        onClick={onClickListItem}>
        <div className="list-title">{bookmark.name}</div>
        {_.map(monitorConditions, renderBookmarkCondition)}
      </li>
    );
  }

  return renderNotificationListItem();
}

NotificationListItem.propTypes = {
  bookmark: PropTypes.object,
  onSetOpen: PropTypes.func
}

NotificationListItem.defaultProps = {
  bookmark: {},
  onSetOpen: _.noop
}

export default NotificationListItem;
