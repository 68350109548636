import { VIEW_MODE } from 'modules/visualization/constants';
import moment from 'moment';

import * as timeline from './timeline';
import * as area from './area';
import * as combo from './combo';
import * as yearOnYear from './year_on_year';
import * as benchMark from './benchMark';
import {
  getXAxisPlotlyTickInterval,
  getXAxisPlotlyTickFormat,
  shouldShowRangeSlider,
  getSliderRange,
  getPeriodType,
  isYearOnYear,
  ticksCountForScreenSize
} from '../vizOptionsHelper';
import {
  OVERTIME_VISUALIZATION_TYPES,
  OVERTIME_TIME_FRAME_OPTIONS,
  X_AXIS_TICK_CONFIG,
  OVERTIME_TIME_CHART_HEIGHT,
  DATE_FORMAT
} from 'appConstants';
import { prefixText, suffixText } from 'helpers/chartDataHelper';
import { isBienniumFiscalYear } from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';
import { getDateColumnLabel } from 'common/config/viewConfiguration';
import { isTimeDurationEnabled, getDateRangeStartMonth } from 'common/config/customerConfiguration';
import { isForecastEndDateIsBeforeToday } from 'pages/Forecasting/ForecastHelper';
import { getDatePeriods } from 'helpers/dateHelper';
import { getQuarterMonths } from '../Helpers/overtimeHelper';
import { getEveryNthElementFromFirst } from '../dataFormatter';
import { isMobileOrTablet, isMobileView } from 'helpers/DomPageHelper';
export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    return (
      !_.isEqual(_.get(vizOptions, 'benchMarkEntries'), _.get(previousVizOptions, 'benchMarkEntries')) ||
      !_.isEqual(_.get(vizOptions, 'dimensionConfigs'), _.get(previousVizOptions, 'dimensionConfigs')) ||
      !_.isEqual(_.get(vizOptions, 'forecastingOption'), _.get(previousVizOptions, 'forecastingOption')) ||
      !_.isEqual(_.get(vizOptions, 'forecastPrepareDataTime'),
        _.get(previousVizOptions, 'forecastPrepareDataTime'))
    );
  },
  toPlotlyParams: (vizOptions, _apiData, formattedData) => {
    const {
      viewMode,
      onApiDataLoad,
      dateRangeMode,
      templateId,
      viewEntry,
      onAfterSummaryTableDataLoad,
      isForecastingView
    } = vizOptions;
    const plotVariant = getPlotVariant(vizOptions);
    const periodType = getPeriodType(vizOptions);
    const isQuarterType = _.get(vizOptions, 'axisGranularity', '') == 'quarter';

    const lineTraces = plotVariant.toPlotlyTraces(vizOptions, formattedData);
    const summaryData = {
      formatData: lineTraces,
      dimensionConfigs: _.get(vizOptions, 'dimensionConfigs')
    }
    onAfterSummaryTableDataLoad(summaryData);
    const xAxisTickLabels = (periodType == 'week' || isQuarterType) ?
      getXAxisLabels(lineTraces, 'weekPeriodLabel', vizOptions) : [];
    const xAxisTickValues = (periodType == 'week' || isQuarterType) ?
      getXAxisLabels(lineTraces, 'x', vizOptions) : [];
    // const plotlyCustomData = getChartPeriodData(lineTraces);
    const sliderRange = (isBienniumFiscalYear({ dateRangeMode }) && isYearOnYear(vizOptions)) ?
      [] :
      getSliderRange(vizOptions, _apiData, []);
    const data = [
      ...lineTraces,
      ...benchMark.toPlotlyTraces(vizOptions, formattedData, lineTraces, sliderRange)
    ];
    const tickLabelStep = getTickLabelStep(vizOptions, sliderRange);
    const visibleLegends = _.filter(lineTraces, (trace) => { return trace.visible == true });
    const noofLineCount = _.size(_.compact(_.map(lineTraces, 'line')));
    const visibleLineCount = _.size(_.compact(_.map(visibleLegends, 'line')));

    if ((noofLineCount - visibleLineCount) > 0) {
      onApiDataLoad((noofLineCount - visibleLineCount));
    }
    const legacyChartOptions = getLegacyChartOptions(vizOptions)
    const isSmallView = viewMode === VIEW_MODE.SMALL;
    const showRangeSlider = shouldShowRangeSlider(vizOptions);
    const dateColumnLabel = getDateColumnLabel(templateId, viewEntry);
    const isSecondsFormat = isTimeDurationEnabled(viewEntry);
    const isCompareWithDay = _.includes(['day'], periodType) && isYearOnYear(vizOptions);
    const dTickValue = isCompareWithDay ? '' : getXAxisPlotlyTickInterval(vizOptions, tickLabelStep);

    const layout = {
      autosize: true,
      height: isSmallView ? '' : OVERTIME_TIME_CHART_HEIGHT,
      margin: isSmallView ? { l: 10, r: 10, t: 2, b: 50 } : { l: 10, r: 10, t: 10, b: 25, pad: 5 },
      showlegend: false,
      dragmode: false,
      // bargroupgap: isComboChart ? 0.2 : 'dont set'; TODO
      hovermode:"x",
      xaxis: {
        // spikemode:'toaxis',
        automargin: showRangeSlider ? false : true,
        tick0: _.get(lineTraces, '[0].x[0]'),
        dtick: dTickValue,
        tickformat: getXAxisPlotlyTickFormat(vizOptions),
        // To prevent display all years in we passed range.
        range: sliderRange,
        showticklabels: true, // TODO: forced
        tickangle: isSmallView ? 70 : 'auto',
        rangeslider: {
          visible: showRangeSlider,
          rangemode:'auto'
        },
        tickfont: {
          size: isSmallView ? 12 : 'auto',
          family: isSmallView ? 'Roboto' : ''
        }
      },
      yaxis: {
        automargin: true,
        tickprefix: prefixText(legacyChartOptions),
        ticksuffix: isSmallView ? "" : `${suffixText(legacyChartOptions)}`,
        tickfont: {
          family: isSmallView ? 'Roboto' : '',
          size: isSmallView ? 8 : 'auto'
        }
      },
      shapes: getShapesForForecastingPeriods(vizOptions, formattedData)
    };
    const config = {
      displayModeBar: false,
      scrollZoom: false,
      editable: false,
      showLink: false,
      responsive: true
    };

    const { tickConfigs } =  getTickConfigs(vizOptions, xAxisTickValues, xAxisTickLabels);

    if (_.includes(['week'], periodType) || isQuarterType) {
      const tickValues = _.map(tickConfigs, 'tickVal');
      layout['xaxis']['tickvals'] = getFormattedXaxisValue(tickValues, vizOptions);
      let displayLabel =  _.map(tickConfigs, 'tickLabel');
      if(isYearOnYear(vizOptions)){
        const xAxisLabels = _.uniq(xAxisTickValues);
        displayLabel = sortQuarterComparisonTickLabels(xAxisLabels);
      } else if(isForecastingView){
        displayLabel =  _.uniq(displayLabel);
      }
      layout['xaxis']['ticktext'] = getFormattedXaxisValue(displayLabel, vizOptions)
    }

    if (dateColumnLabel) {
      layout['xaxis']['title'] = { text: dateColumnLabel, font: { size: isSmallView ? 10 : 12 } };
    }

    if(!_.includes(['day','week'], periodType)){
      layout['xaxis']['ticklabelstep'] = tickLabelStep;
    }

    if (isSecondsFormat) {
      const {  minYLabel, maxYLabel } = getTickYLabelsAndMaxMin(data);
      layout.yaxis.tickformat = "%X";
      layout.yaxis.type = "liner";
      layout.yaxis.range = [minYLabel, maxYLabel];
    }

    const plotlyData = { data, layout, config };
    return plotlyData;
  }
};

const canShowAllTicks = (tickValues, vizOptions) => {
  const { viewMode } = vizOptions;
  const isSmallView = viewMode == VIEW_MODE.SMALL;

  if (isSmallView) return tickValues.length <= X_AXIS_TICK_CONFIG.MEDIUM;

  const countToCheck =  isMobileView() ? X_AXIS_TICK_CONFIG.MOBILE :
    isMobileOrTablet() ? X_AXIS_TICK_CONFIG.TABLET : ticksCountForScreenSize(vizOptions)

  return tickValues.length <= countToCheck ;
}

const getFormattedXaxisValue = (values, vizOptions) => {
  if (canShowAllTicks(values, vizOptions)) return values;

  const tickStepCount = ticksCountForScreenSize(vizOptions)
  const tickStepForWeek = _.ceil(values.length / tickStepCount);
  return getEveryNthElementFromFirst(values, tickStepForWeek);
}

const getTickConfigs = (vizOptions, xAxisTickValues, xAxisTickLabels) => {
  const isSmallView = vizOptions.viewMode === VIEW_MODE.SMALL;
  const uniqXAxisTickValues = _.chain(xAxisTickValues)
    .map((xAxisValue) => ({ xStringValue: _.toString(xAxisValue), xAxisValue }))
    .uniqBy('xStringValue')
    .value();

  const uniqXAxisTickLabels = _.chain(xAxisTickLabels)
    .map((xAxisLabel) => ({ xStringLabel: _.toString(xAxisLabel), xAxisLabel }))
    .uniqBy('xStringLabel')
    .value();


  const noOfTickLabels = isSmallView ? X_AXIS_TICK_CONFIG.MEDIUM : X_AXIS_TICK_CONFIG.DESKTOP;
  const xAxisLabelsSize = _.size(uniqXAxisTickValues);
  const size = Math.ceil(xAxisLabelsSize / noOfTickLabels);

  let divideValue = isSmallView ? X_AXIS_TICK_CONFIG.MEDIUM : X_AXIS_TICK_CONFIG.DESKTOP;
  let tickConfigs = [];

  if(size < 2){
    tickConfigs = _.map(uniqXAxisTickValues, (xAxis, index) => {
      return {
        tickVal: _.get(xAxis, 'xAxisValue'),
        tickLabel: _.get(uniqXAxisTickLabels[index], 'xAxisLabel')
      }
    });
    return { tickConfigs, xAxisLabelsSize };
  }

  const endTickVal = _.get(_.last(uniqXAxisTickValues), 'xAxisValue');
  const endTickLabel = _.last(xAxisTickLabels);

  tickConfigs = _.chain(0).
    range((divideValue-1)).
    map((interval) => {
      const index =  (interval * size);
      const tickVal = _.get(uniqXAxisTickValues[index], 'xAxisValue');
      const tickLabel = xAxisTickLabels[index];
      return tickVal ? { tickVal, tickLabel } : null;
    }).
    without(null).
    value();

  tickConfigs = tickConfigs.concat({ tickVal: endTickVal, tickLabel: endTickLabel});

  return { tickConfigs, xAxisLabelsSize };
}

const getTickYLabelsAndMaxMin = (formattedData) => {
  let tickYLabels = [];
  _.each(formattedData, (trace) => {
    tickYLabels = tickYLabels.concat(_.map(trace, 'y'));
  });

  const maxYLabel = _.max(tickYLabels);
  const minYLabel = 0;
  return { tickYLabels, minYLabel, maxYLabel };
}

const getXAxisLabels = (lineTraces , xFields = 'x', vizOptions) => {
  const { dateRange, forecastingOption, isForecastingView, axisGranularity } = vizOptions;
  let xAxisLabels = [];
  if (isForecastingView &&
    isForecastEndDateIsBeforeToday({dateRange: dateRange}, axisGranularity) &&
    axisGranularity === 'quarter') {
    const forecastDateRange = {
      startDate: _.get(dateRange, 'startDate'),
      endDate: _.get(forecastingOption, 'futureForecastDateRange.dateRange.endDate')
    }
    const periods = getDatePeriods(forecastDateRange, DATE_FORMAT, axisGranularity, true);
    const quarterMonths = getQuarterMonths();
    xAxisLabels = _.map(periods, (period) => {
      const periodMonth = period.month();
      const periodYear = period.year();
      const configMonth = getDateRangeStartMonth();
      let newStartYear = periodYear;
      if (configMonth > 0) {
        newStartYear = configMonth > periodMonth ? periodYear : Number(periodYear) + 1;
      }

      return `${quarterMonths[periodMonth]} ${newStartYear}`;
    });
  } else {
    _.forEach(lineTraces, (lineTrace) => {
      xAxisLabels = xAxisLabels.concat(_.get(lineTrace, xFields, []))
    });
  }

  return xAxisLabels;
}

const getPlotVariant = (vizOptions) => {
  const { isComboChart, renderType, renderTimeFrame } = vizOptions;
  if (isComboChart) {
    return combo;
  } else if (renderType === OVERTIME_VISUALIZATION_TYPES.AREA.type) {
    return area;
  } else if (isYearOnYear({ renderTimeFrame })) {
    return yearOnYear;
  } else {
    return timeline;
  }
};

const getLegacyChartOptions = (vizOptions) => {
  return {
    viewOptions: vizOptions.viewEntry,
    currentChartOption: _.find(OVERTIME_VISUALIZATION_TYPES, { type: vizOptions.renderType }),
    toggleLegendEntries: { [vizOptions.renderType]: vizOptions.dimensionConfigs },
    secondaryComparison: vizOptions.secondaryMetricEntry,
    currentBenchMarkEntry: vizOptions.benchMarkEntries,
    currentSelectedTimeFrame: OVERTIME_TIME_FRAME_OPTIONS[vizOptions.renderTimeFrame],
    isComboChart: vizOptions.isComboChart,
    isShowProjection: vizOptions.projectionEnabled,
    forecastOption: vizOptions.projectionType,
    forecastPercent: vizOptions.projectionPercentage,

    // Below are not seem to be used.
    legendEntries: [],
    totalVisbleOption: false,
    layoutOptions: {},
    prophetForecastingData: {}
  };
};

const getShapesForForecastingPeriods = (vizOptions, formattedData) => {
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  if(!isForecastingView){
    return [];
  }

  const projectionPeriods = _.get(formattedData, "projection.forecastingProjections[0].data", []);
  const tailingDrop = _.get(formattedData, "tailingDrop.total", []);
  const isQuarterType = _.get(vizOptions, 'axisGranularity', '') == 'quarter';
  const fieldText = isQuarterType ? 'periodLabel' : 'period';
  const projectionFirstPeriod = _.isEmpty(_.get(_.first(tailingDrop), fieldText)) ?
    _.get(_.first(projectionPeriods), fieldText) : _.get(_.first(tailingDrop), fieldText);
  const projectionLastPeriod = _.get(_.last(projectionPeriods), fieldText);

  const shapes = [
    {
      type: 'rect',
      // x-reference is assigned to the x-values
      xref: 'x',
      // y-reference is assigned to the plot paper [0,1]
      yref: 'paper',
      x0: projectionFirstPeriod,
      y0: 0,
      x1: projectionLastPeriod,
      y1: 1,
      fillcolor: '#b3bce9',
      opacity: 0.2,
      line: {
        width: 0
      }
    }
  ]

  return shapes;
}

const getTickLabelStep = (vizOptions, sliderRange) => {
  const startRange = _.first(sliderRange);
  const endRange = _.last(sliderRange);
  let periodType = 'months';
  switch (getPeriodType(vizOptions)) {
    case 'day':
      periodType = 'days'
      break;
    case 'month':
      periodType = 'months'
      break;
    case 'year':
      periodType = 'years'
      break;
    case 'week':
      periodType = 'weeks'
      break;
  }

  const diffPeriodCount =  _.round(moment(endRange).diff(moment(startRange), periodType, true));
  const ticksCount = ticksCountForScreenSize(vizOptions);

  return _.ceil(diffPeriodCount / ticksCount);
}

const sortQuarterComparisonTickLabels = (xAxisLabels) => {
  xAxisLabels.sort((a, b) => {
    const spacesCountA = (a.match(/\s/g) || []).length;
    const spacesCountB = (b.match(/\s/g) || []).length;

    const spaceComparison = spacesCountA - spacesCountB;

    if (spaceComparison !== 0) {
      return spaceComparison;
    }

    return a.localeCompare(b);
  });
  return xAxisLabels;
}
