import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import { getSLAFilterOptions } from '../slaWatchHelper';


class SlaFilter extends Component {
  renderDropDown(){
    const {chartFilter, handleSelectFilter, selectedDimension, selectedListItem } = this.props;
    const options = getSLAFilterOptions(selectedDimension, selectedListItem);
    const selectedOption = _.find(options, (option) => {
      return (option['type'] == chartFilter);
    }) || options[0];

    return(
      <SolutionDropDown
            className='p-0'
            showSelectedIcon={true}
            title={selectedOption['name']}
            selectedOption={selectedOption}
            options={options}
            onSelect={(option) => { handleSelectFilter(option)}}
          />
    )
  }

  render() {
    return (
      <div className="show-options-filter d-flex align-items-center">
        <span className="pr-1"> Show </span>
        {this.renderDropDown()}
      </div>
    );
  }
}

SlaFilter.propTypes = {
  chartFilter: PropTypes.object,
  handleSelectFilter: PropTypes.func,
  dimension: PropTypes.string,
  selectedDimension: PropTypes.object,
  selectedListItem: PropTypes.object
};

export default SlaFilter;