import _ from 'lodash';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';

import { getBenchMarkData } from './scatterChartDataFormatter';
import { sortChartData } from '../plotlyScatterChartHelper';
import {
  getWrapTickText,
  formatScatterChartTickLabel
} from '../vizOptionsHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { SORT_BY_OPTIONS, MORE_THAN_FIFTY_TEXT } from 'appConstants';
import { getSortByType, sortGroupByData } from '../barChartHelper';
import { getGroupByEntityField } from 'helpers/snapshotRendererHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsNumericTime } from 'helpers/visualizationHelper';

// const groupTitleStyle = 'fill:#4253b1; font-size:14px; font-weight: bold;'
const groupTitleStyle = `fill:#212121; font-size:13px; font-weight: bold; letter-spacing:2px; font-family: 'Roboto'`;

 // Input:
 //        apiData ===> {
 //           "entries":[
 //              {
 //                 "dimension":"Education",
 //                 "count":"8337",
 //                 "min_count":"Operating",
 //                 "max_count":"Personnel",
 //              },
 //              {
 //                 "dimension":"General Government",
 //                 "count":"8206",
 //                 "min_count":"Operating",
 //                 "max_count":"Personnel",
 //              }]
 //    }

// Output: Group None
//  formattedData =>
//      [
//        {
//          label: 'Education',
//          value: 21.98,
//          scatterEntry: {},
//          plotlyDescription: '<b>Education<b>...'
//          isBenchMark:false
//        },
//        {
//          label: 'General Government',
//          value: 22.98,
//          scatterEntry: {},
//          plotlyDescription: '<b>General Government<b>...'
//          isBenchMark:true,
//          benckMarkList:[{
//             label: Education,
//             value: 2000,
//             plotlyDescription: 'hover text',
//             scatterEntry : null,
//             clickMode:false
//           },
//           {
//             label: General Government,
//             value: 2000,
//             plotlyDescription: 'hover text',
//             scatterEntry : null,
//             clickMode:false
//           }]
//        }
//        ...
//      ]

export const formatGroupByScatterChartData = (vizOptions, apiResponse, isUpdateApiCall = false) => {
  const { groupByViewType, groupByEntry, onApiDataLoad,
    dimensionSortOption, onFormattedDataLoad } = vizOptions
  const apiRawData = _.get(apiResponse,'entries');
  const rowCount  = _.get(apiResponse,'row_count', 0);
  let formattedData;
  let visualizationData;
  const remainingGroupEntries = _.get(apiResponse, 'remaining_group_entries', 0);

  if(groupByEntry['name'] == 'None') {
    visualizationData = getGroupNoneChartData(apiRawData, vizOptions);
    if(isUpdateApiCall) {
      if (_.size(apiRawData) === 1) {
        onApiDataLoad(rowCount, _.values(apiRawData)[0]);
      } else {
        onApiDataLoad(rowCount, null, remainingGroupEntries);
      }
    }

  //  data filter based on view type option like (Top 20)
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    if(!_.isEmpty(groupByViewType)) {
      formattedData = sortChartData(visualizationData, dimensionSortOption);
      formattedData = getFilterVisualisationData(visualizationData, groupByViewType);
    }
  } else {
    formattedData = getGroupByChartData(apiRawData, vizOptions);
    if(isUpdateApiCall) {
      onApiDataLoad(rowCount, null, remainingGroupEntries);
    }
  }

  onFormattedDataLoad(formattedData);

  // Add Benchmark data
  const benchMarks = !_.isEmpty(formattedData) ? getBenchMarkData(formattedData, vizOptions) : [];
  formattedData = formattedData.concat(benchMarks);

  return formattedData;
};

// Group By Field None Chart Data
//````````````````````````````````
const getGroupNoneChartData = (apiRawData, vizOptions) => {
  const scatterChartData = _.flatten(_.values(apiRawData), []);
  const formattedData = _.map(scatterChartData, (scatterEntry) => {
    return getFormattedChartEntry(scatterEntry, vizOptions);
  });

  return formattedData;
}

const getFilterVisualisationData = (visualizationData, groupByViewType) => {
  const viewRowCount = _.get(groupByViewType,'rowCount', 0);
  if(_.isNumber(viewRowCount) && _.size(visualizationData) > viewRowCount) {
    return _.take(visualizationData, viewRowCount);
  } else {
    return visualizationData;
  }
}

// Group By Field Chart Data
//``````````````````````````
const getGroupByChartData = (apiRawData, vizOptions) => {
  const sortByEntry = getSortByType(vizOptions, true);
  let formattedData = _.chain(apiRawData).
                          groupBy((entry) => getGroupByEntityField(_.get(entry, 'group_by_field'))).
                          map((dimensions, category) =>
                            getFormattedChartEntries(dimensions, category, vizOptions)
                          ).
                          value() || [];

  formattedData = sortGroupByData(formattedData, sortByEntry);
  formattedData = _.chain(formattedData).
                          map('dimensionEntries').
                          tap(data => data).
                          flattenDeep().
                          value() || [];
  return formattedData;
}

const getFormattedChartEntries = (dimensionEntries, category, vizOptions) => {
  const { templateId, isCurrencyGroupByField } = vizOptions;
  const emptyBucket = {
    isGroup: true,
    isBenchMark:false,
    value: '',
    scatterEntry: null,
    label: ''
  }
  let groupName = getGroupByEntityField(category);
  if(isCurrencyGroupByField) {
    groupName = formatValueToCurrency(groupName, true);
  }
  const formattedDimensionEntries = _.map(dimensionEntries, (dimensionEntry) => {
    return getFormattedChartEntry(dimensionEntry, vizOptions)
  });
  const groupNameDescription = getWrapTickText(groupName, templateId);
  const tickLabel = (
    `<span style="${groupTitleStyle}">${groupNameDescription} </span>`);

  const isMoreThan50dimension = _.get(dimensionEntries, "[0].is_remaining_dimension", false);

  let seeMoreResultBucket = {};
  if(isMoreThan50dimension){
    seeMoreResultBucket = getSeeMoreEmptyDimensionBucket(category, vizOptions);
  }

  let sortedDimensionEntries = getSortedDimensionEntries(formattedDimensionEntries, vizOptions);
  sortedDimensionEntries = _.isEmpty(seeMoreResultBucket) ? sortedDimensionEntries :
     [...sortedDimensionEntries, seeMoreResultBucket];

  sortedDimensionEntries.unshift(
    {
      ...emptyBucket,
      label: groupName,
      tickLabel,
      plotlyDescription: groupNameDescription
    });

  return {
    category: groupName,
    count: _.sumBy(dimensionEntries, (dimensionEntry) => Number(_.get(dimensionEntry, 'count', 0))),
    dimensionEntries: sortedDimensionEntries
  };
}

export function getSeeMoreEmptyDimensionBucket(category, vizOptions) {
  const { templateId } = vizOptions;
  const groupName = getWrapTickText(category, templateId)
  const label  = `${groupName}-${templateId}`;
  const linkText = `  <span style="fill:#4253b1">
    <i aria-label="${groupName}">${MORE_THAN_FIFTY_TEXT}</i></span>
    <span style="fill:#ffffff">${groupName}</span>`

  return {
    groupByField: groupName,
    isBenchMark: false,
    label: label,
    tickLabel:'<span style="fill:#ffffff">1</span>',
    value: 0,
    customData: [groupName],
    plotlyDescription: linkText,
    isGroup : true,
    isSeeMoreDimension: true
  }
}

const getFormattedChartEntry = (apiChartEntry, vizOptions) => {
  const {
    viewEntry, secondaryMetricOption, templateId, viewMode, groupByEntry, isCurrencyDimensionField
  } = vizOptions;
  const { count, max_count, min_count, group_by_field } = apiChartEntry;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const isGroupByNone = (groupByEntry['name'] == 'None');
  const groupName = (group_by_field || '');
  const formattedLabel = formatScatterChartTickLabel(
    apiChartEntry, secondaryMetricOption, false, templateId, false,
    isCurrencyDimensionField
  );
  const label = isGroupByNone ? formattedLabel : `${formattedLabel} - ${groupName}`;
  const tickLabel = formatScatterChartTickLabel(
    apiChartEntry, secondaryMetricOption, true, templateId, isSmallView,
    isCurrencyDimensionField
  );
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);

  const countValue = isSecondsFormat ? getSecondsAsNumericTime(count, dataUnit) : Number(count) || 0;
  const minCountValue = isSecondsFormat ?
    getSecondsAsNumericTime(min_count, dataUnit) : Number(min_count) || 0;
  const maxCountValue = isSecondsFormat ?
    getSecondsAsNumericTime(max_count, dataUnit) : Number(max_count) || 0;

  return {
    isBenchMark:false,
    label,
    tickLabel,
    value: countValue,
    minValue: minCountValue,
    maxValue: maxCountValue,
    plotlyDescription: getFormattedNumberValue(count, viewEntry),
    scatterEntry : apiChartEntry,
    groupByField: (group_by_field || '')
  };
}

const getSortedDimensionEntries = (formattedData, vizOptions) => {
  const sortByEntry = getSortByType(vizOptions);
  const groupSortTypes = _.chain(SORT_BY_OPTIONS).map('type').take(2).value();
  let sortCategory = _.includes(groupSortTypes, _.get(sortByEntry, 'type')) ? 'tickLabel' : 'value';
  if(sortByEntry['isCustomSort']) {
    sortCategory = 'tickLabel';
  }
  const sortedFormattedData = sortGroupByData(formattedData, sortByEntry, sortCategory);
  return  _.chain(sortedFormattedData).
            tap(sortedDimensionEntries =>  sortedDimensionEntries).
            value();
}
