import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ForgeIcon } from '@tylertech/forge-react';

const ModalHeader = ({ toggleModalOpen }) => {
  const isCollaborateCollection = useSelector((state) => state.isCollaborateCollection);

  return (
    <header className="forge-dialog__header">
      <h2 className="forge-dialog__title">
        Collaborate
        {isCollaborateCollection &&
          <span className="float-right mt-3 cursor-pointer" onClick={() => toggleModalOpen(false)}>
            <ForgeIcon name="close"></ForgeIcon>
          </span>
        }
      </h2>
    </header>
  );
}

ModalHeader.propTypes = {
  toggleModalOpen: PropTypes.func
}

export default ModalHeader;
