import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';

import LoadingSpinner from 'common/components/LoadingSpinner';
import { shareCollection } from 'common/api/collectionApi';
import { trackEvent } from 'helpers/eventTracking';
import ShareSection from 'pages/SubscriptionsManager/components/ShareSection';
import UserFromBellerophonContext from "context/UserFromBellerophonContext";
import { updateGlobalFiltersByConfigDefaults } from 'pages/drilldown/components/QuickFilterBar/helper';
import { collectionSharedMessage, collectionSharedErrorMessage } from 'helpers/toastMessages';
class ShareCollection extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getDefaultStateValues();
  }
  static contextType = UserFromBellerophonContext;
  getDefaultStateValues = () => {
    const currentCollection =  _.get(this.props, 'currentCollection', {});
    const collectionFilters = updateGlobalFiltersByConfigDefaults(
      _.get(this.props, 'currentCollection.collectionFilters', []), currentCollection
    )
    return {
      isLoading: false,
      showShareSection: false,
      collectionName: _.get(this.props, 'currentCollection.name'),
      collectionFilters
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { showShareSection } = this.state;
    if (this.ShareButtonRef && !this.ShareButtonRef.contains(e.target) && showShareSection) {
      this.handleShareClose();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentCollection } = this.props;
    const { collectionFilters } = currentCollection;

    if (!_.isEqual(collectionFilters, _.get(prevProps, 'currentCollection.collectionFilters'))) {
      this.setState({
        collectionFilters: updateGlobalFiltersByConfigDefaults(collectionFilters, currentCollection)
      });
    }
  }

  handleCollectionShare = (options) => {
    const { currentCollection, currentUser } = this.props;
    const {
      collectionName,
      collectionFilters
    } = this.state;
    const shareEmails = _.get(options, 'shareEmails', []);

    const params = {
      shareEmails: shareEmails,
      includeSubscription: false,
      sendShareEmail: true,
      collection: {
        ...currentCollection,
        name: collectionName,
        collectionFilters: collectionFilters
      }
    };
    trackEvent('confirm_collection_share');
    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      shareCollection(_.get(currentCollection, 'id'), params)
        .then((response) => {
          this.handleShareClose();
          if (response.ok) {
            toast.success(collectionSharedMessage);
          } else {
            toast.error(collectionSharedErrorMessage);
          }
        }).catch((err) => {
          this.handleShareClose();
          console.error('collection share', err);
          toast.error(collectionSharedErrorMessage);
        });
    }
  }

  handleConfirmCopyLink = (overwriteFlag) => {
    const { updateOverwriteCollectionFilter } = this.props;
    updateOverwriteCollectionFilter(overwriteFlag)
  }

  handleShareClose = () => {
    const defaultState = this.getDefaultStateValues();
    this.setState(defaultState, () => {
      this.props.onShareClick(false);
    });
  }

  renderShareSection() {
    const { showShareSection } = this.state;
    const {
      currentUser,
      allowedUserRoles,
      currentCollection,
      updateOverwriteCollectionFilter
    } = this.props;
    const placeholderText = 'Enter one or more email addresses';
    const showCopyLink = !_.isEmpty(_.get(currentCollection, 'bellerophon_tag_id'));

    if (!showShareSection) {
      return null;
    }
    return (
      <ShareSection
        currentUser={currentUser}
        isShareEnabled={showShareSection}
        onCancelShare={this.handleShareClose}
        onSendClick={this.handleCollectionShare}
        emailInputPlaceholder={placeholderText}
        allowedUserRoles={allowedUserRoles}
        shareType='collection'
        showCopyLink={showCopyLink}
        updateOverwriteCollectionFilter={updateOverwriteCollectionFilter}
        trackEventName="overview_copy_link"
      />
    );
  }

  initiateCollectionShare = () => {
    const { currentCollection } = this.props;
    trackEvent('initiate_collection_share');

    this.setState({
      showShareSection: true,
      collectionName: _.get(currentCollection, 'name')
    }, () => {
      this.props.onShareClick(true);
    });
  }

  renderShareButton() {
    const { showShareSection } = this.state;
    if (showShareSection) {
      return null;
    }

    return (
      <forge-button type="outlined">
        <button tabIndex="0"
          onClick={this.initiateCollectionShare}
          className="collection-share-btn">
          Share
        </button>
      </forge-button>
    );
  }

  renderSpinner() {
    const { isLoading } = this.state;

    return (
      <LoadingSpinner isLoading={isLoading} />
    );
  }

  render() {
    return (
      <div className="share-collection-section"
        ref={(ref) => this.ShareButtonRef = ref}>
        {this.renderSpinner()}
        {this.renderShareButton()}
        {this.renderShareSection()}
      </div>
    );
  }
}

ShareCollection.propTypes = {
  currentCollection: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
    collectionFilters: PropTypes.array
  }),
  currentUser: PropTypes.object,
  onShareClick: PropTypes.func,
  allowedUserRoles: PropTypes.array,
  updateOverwriteCollectionFilter: PropTypes.func
}



export default ShareCollection;
