import moment from 'moment';
import _ from 'lodash';
import {
  OVERTIME_VISUALIZATION_TYPES,
  NO_OF_BENCH_MARKS,
  EXPONENTIAL_FORECAST,
  PROPHET_FORECAST,
  HISTORICAL_FORECAST
} from 'appConstants';
import {
  getPeriodType,
  getRangePeriods,
  getTailingDropStartDate,
  isQuarterPeriod,
  getBenchMarkConfigEntries,
  isYearOnYear
} from '../../vizOptionsHelper';
import { isDiscreteModeData } from 'common/config/viewConfiguration';
import {
  getConfiguredDataEndDate
} from 'common/config/customerConfiguration';
import {
  getProjectionDataItems
} from '../../Helpers/projectionHelper';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import {
  getGroupByQuarterData,
  getValidChartValue
} from '../../Helpers/overtimeHelper';
import { getYearTextByRange } from 'helpers/dateHelper';
import {
  getValidFormattedData,
  getForecastFormattedData,
  getCurrentQuarterStart,
  getCurrentQuarterEnd,
  isForecastEndDateIsBeforeToday
} from 'pages/Forecasting/ForecastHelper';
import { sumApiDataForActualData } from '../../Helpers/apiDataHelper';
import { getLastCurrentPeriodValue } from '../../Helpers/projectionDataGenerator';
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const formatEntityDataItems = (dataItems, periods, vizOptions, dimension) => {
  // There are 3 dependency function for this
  // getDateRangeByYearText, formatDataByFillingGaps, _addBurnupAccumulatedDate
  const { renderTimeFrame, dateRange, compareYearRanges, isComboChart } = vizOptions;
  const isBurnup = vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const periodType = getPeriodType(vizOptions);
  const sanitizedDataItems = _.map(dataItems, (dataItem) => {
    return {
      ...dataItem,
      periodMmt: moment(dataItem.period).startOf('day'),
    };
  });

  if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges) && !isComboChart) {
    // Here periods are updated for year wise data items, That with multiple years.
    let yearwisePeriod = [];
    const dateRangeByDimension = getDateRangeByYearText(vizOptions);
    let { startDate, endDate } = dateRangeByDimension[dimension] || {};
    if (getConfiguredDataEndDate().isBefore(endDate)) {
      endDate = getConfiguredDataEndDate().format(DATE_FORMAT);
    }
    yearwisePeriod = getRangePeriods(startDate, endDate, periodType);
    // One day subtracted for remove current period to get completed period list.
    const tailingDropStartDate = getTailingDropStartDate(vizOptions).subtract(1, 'day');
    periods = _.filter(yearwisePeriod, (period) => {
      return !period.isAfter(tailingDropStartDate);
    });
  }

  // Sort and also fill missing items.
  const formattedDataItems = formatDataByFillingGaps(sanitizedDataItems, periods);

  if (isBurnup && isDiscreteMode && !_.isEmpty(formattedDataItems)) {
    const formatData = _addBurnupAccumulatedDate(dataItems, formattedDataItems, periods[0], periodType);
    return isQuarterPeriod(vizOptions) ? getGroupByQuarterData(formatData, false, false, true) : formatData;
  } else {
    const returnFormattedData = isQuarterPeriod(vizOptions) ?
      getGroupByQuarterData(formattedDataItems, false, false, true) : formattedDataItems;
    return returnFormattedData;
  }
};

const getDateRangeByYearText = (vizOptions) => {
  const { dateRange, compareYearRanges, dateRangeMode } = vizOptions;
  let dateRangeByText = {};
  dateRangeByText[getYearTextByRange(dateRange, dateRangeMode)] = dateRange;
  _.each(compareYearRanges, (compareRange) => {
    dateRangeByText[getYearTextByRange(compareRange, dateRangeMode)] = compareRange;
  });
  return dateRangeByText;
}

const formatDataByFillingGaps = function (sanitizedDataItems, periods) {
  const groupedByPeriodDataItems = _.groupBy(sanitizedDataItems, (datum) => {
    return datum.periodMmt.format(DATE_FORMAT);
  });

  return _.map(periods, (period) => {
    const periodMoment = moment(period).format(DATE_FORMAT);
    if (groupedByPeriodDataItems[periodMoment]) {
      return _.get(groupedByPeriodDataItems[periodMoment], '0');
    }

    // Filling Gaps
    return {
      ..._.get(sanitizedDataItems, '[0]', {}),
      period: period.format(DATE_FORMAT),
      periodMmt: period,
      value: null,
      secondary_total: null
    };
  });
}

const _addBurnupAccumulatedDate = (dataItems, sanitizedDataItems, accumulatedEndDate, periodType) => {
  if (_.isEmpty(dataItems) || _.isEmpty(sanitizedDataItems)) {
    return sanitizedDataItems;
  }

  const filteredDataItems = _.filter(dataItems, (item) => {
    return moment(item.period).isBefore(accumulatedEndDate, periodType);
  });
  const accumulatedValue = _.sumBy(filteredDataItems, (item) => {
    return Number(item.value || 0);
  });
  const accumulatedSecondaryValue = _.sumBy(filteredDataItems, (item) => {
    return Number(item.secondary_total || 0);
  });

  if (!_.isNull(sanitizedDataItems[0].value)) {
    sanitizedDataItems[0].value = Number(sanitizedDataItems[0].value || 0) + accumulatedValue;
  }

  if (!_.isNull(sanitizedDataItems[0].secondary_total)) {
    sanitizedDataItems[0].secondary_total = (
      Number(sanitizedDataItems[0].secondary_total || 0) + accumulatedSecondaryValue
    );
  }

  return sanitizedDataItems
}

export const getFormattedBenchmarkData = (vizOptions, benchMarkApiData) => {
  const benchmarkConfigEntries = getBenchMarkConfigEntries(vizOptions);

  return _.map(benchmarkConfigEntries, (benchmarkConfigEntry) => {
    const valueKeys = _.chain(0).
      range(NO_OF_BENCH_MARKS).
      map((index) => index > 0 ? `value${index}` : 'value').
      value();

    const benchMarkField = _.get(benchmarkConfigEntry, 'field');
    const valuesFromConfig = _.values(_.pick(benchmarkConfigEntry, valueKeys));
    const valuesFromApiData = _.map(benchMarkApiData, benchMarkField);
    const isCheckFromConfigValue = _.isEmpty(valuesFromConfig) || _.isEmpty(_.get(valuesFromConfig, '0'));

    return {
      configEntry: benchmarkConfigEntry,
      values: isCheckFromConfigValue ? valuesFromApiData : valuesFromConfig
    }
  });
}

export const formatTailingDropItems = (dataItems, vizOptions) => {
  const { renderType, dateRange, axisGranularity } = vizOptions;
  const isQuarterType = axisGranularity == 'quarter';
  const isBurnup = (renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type);
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const periodType = getPeriodType(vizOptions)
  const tailingDropStartDate = getTailingDropStartDate(vizOptions);

  let sanitizedDataItems = _.chain(dataItems).map((dataItem, index) => {
    const dataItemPeriod = new Date(dataItem.period);
    const tailingDropDate = new Date(tailingDropStartDate);
    const currentQuarterStart = getCurrentQuarterStart(tailingDropDate);
    const currentQuarterEnd = getCurrentQuarterEnd(tailingDropDate);

  if (moment(dataItem.period).isAfter(tailingDropStartDate, periodType) ||
      moment(dataItem.period).isSame(tailingDropStartDate, periodType) ||
    ( isQuarterType && dataItemPeriod >= currentQuarterStart && dataItemPeriod <= currentQuarterEnd)
  ) {
    return {
        ...dataItem,
        periodMmt: moment(dataItem.period).startOf('day'),
        dataIndex: index
    };
  }
  }).
    compact().
    value();

  const momentOfStartDate = moment(dateRange.startDate);
  if (momentOfStartDate > tailingDropStartDate) {
    const periods = getRangePeriods(dateRange.startDate, dateRange.endDate, periodType);
    // Sort and also fill missing items.
    sanitizedDataItems = formatDataByFillingGaps(sanitizedDataItems, periods);
  }

  // Adding first accumulated value to first value
  // so in burn up trailing point will form last burn up value
  if (isBurnup && isDiscreteMode && !_.isEmpty(sanitizedDataItems)) {
    const formatData = _addBurnupAccumulatedDate(
      dataItems, sanitizedDataItems, tailingDropStartDate, periodType
    );
    return isQuarterPeriod(vizOptions) ? getGroupByQuarterData(formatData, vizOptions) : formatData;
  } else {
    const returnFormattedData = isQuarterPeriod(vizOptions) ?
      getGroupByQuarterData(sanitizedDataItems, false) : sanitizedDataItems;
    return returnFormattedData;
  }
}

export const formatActualDropItems = (dataItems, vizOptions) => {
  const {dateRange } = vizOptions;
  const periodType = getPeriodType(vizOptions)

  const tailingDropStartDate = getTailingDropStartDate(vizOptions);
  let actualStartDate = '';
  if (periodType == 'day' || periodType == 'week'){
    actualStartDate = moment(dateRange.endDate, "YYYY-MM-DD").
      subtract(1, periodType).format("YYYY-MM-DD");
  } else {
    actualStartDate = moment(dateRange.endDate, "YYYY-MM-DD").
      subtract(1, periodType).startOf('month').format("YYYY-MM-DD");
  }

  const actualDateRange = {
    startDate: actualStartDate,
    endDate: tailingDropStartDate
  };
  const actualDropItems = sumApiDataForActualData(dataItems, vizOptions, actualDateRange);
  let sanitizedDataItems = _.chain(actualDropItems).map((dataItem, index) => {
    if (moment(dataItem.period).isBefore(tailingDropStartDate, periodType) &&
        moment(dataItem.period).isAfter(actualStartDate, periodType)
    ) {
      return {
          ...dataItem,
          periodMmt: moment(dataItem.period).startOf('day'),
          dataIndex: index
      };
    }
  }).
    compact().
    value();

  return isQuarterPeriod(vizOptions) ?
  getGroupByQuarterData(sanitizedDataItems, false) : sanitizedDataItems;

}

export const formatProjectionEntries = (
  dataItems, currentPeriods, vizOptions, previousDataItems, dimension, onlyLastCurrentItem = false) => {
  const {dateRange, axisGranularity, isForecastingView, renderType } = vizOptions;

  const endDate = _.get(dateRange, 'dateRange.endDate')
  if (renderType == "area" || !vizOptions.projectionEnabled) {
    return []
  }
  const isQuarterType = axisGranularity == 'quarter';

  let totalDataItems = dataItems;
  const isBurnup = (renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type);
  if(isYearOnYear(vizOptions) && isQuarterPeriod(vizOptions) && isBurnup){
    totalDataItems = _.uniqBy(dataItems, 'period');
  }
  const periodType = getPeriodType(vizOptions);
  const tailingDropStartDate = isForecastingView &&
    isForecastEndDateIsBeforeToday({ dateRange }, axisGranularity) ?
    moment(endDate).startOf(periodType) : getTailingDropStartDate(vizOptions);

  // Filter the dataItems which is less than `configuredDataEndDate`.
  const sanitizedDataItems = _.chain(totalDataItems).map((dataItem) => {
    const dataItemPeriod = new Date(dataItem.period);
    const tailingDropDate = new Date(tailingDropStartDate);
    const currentQuarterStart = getCurrentQuarterStart(tailingDropDate);
    const currentQuarterEnd = getCurrentQuarterEnd(tailingDropDate);

    if (moment(dataItem.period).isAfter(tailingDropStartDate) ||
      (isQuarterType && dataItemPeriod >= currentQuarterStart && dataItemPeriod <= currentQuarterEnd)
  ) {
      return;
    }

    return {
      ...dataItem,
      dimension: dimension,
      periodMmt: moment(dataItem.period).startOf('day')
    };
  }).
    compact().
    value();


  if (isQuarterPeriod(vizOptions)) {
    const lastPeriod = _.last(currentPeriods);
    currentPeriods.push(lastPeriod.startOf('quarter'));
  }

  const sanitizedPreviousDataItems = _.map(previousDataItems, (dataItem) => {
    return {
      ...dataItem,
      dimension: dimension,
      periodMmt: moment(_.get(dataItem, 'period')).startOf('day')
    };
  });
  const newSanitizedDataItems = isQuarterPeriod(vizOptions) ?
    getGroupByQuarterData(sanitizedDataItems) :
    sanitizedDataItems;

  let projectionItems = null;
  if (onlyLastCurrentItem && isForecastingView) {
    projectionItems = [getLastCurrentPeriodValue(
      currentPeriods, newSanitizedDataItems, vizOptions, dimension)];

  } else {
    projectionItems = getProjectionDataItems(
      newSanitizedDataItems, currentPeriods, vizOptions, sanitizedPreviousDataItems, dimension
    );
  }
  return isQuarterPeriod(vizOptions) ? getGroupByQuarterData(projectionItems, true) : projectionItems;
}

export const getComboChartProjection =
  (metricValues, currentPeriods, vizOptions, apiData, dimension, index) => {
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  if (isForecastingView){
    const forecastingOption = _.get(vizOptions, 'forecastingOption', {});
    const projections = _.get(forecastingOption, 'projectionTypes');
    let totalProjections = [], projectionEntries, forecastVizOptions = _.cloneDeep(vizOptions);
    if (_.includes(projections, EXPONENTIAL_FORECAST) || _.includes(projections, PROPHET_FORECAST)){
      forecastVizOptions['projectionType'] = PROPHET_FORECAST;
      projectionEntries = _.get(apiData,
        `forecasting_data.combo_chart_secondary_totals.${dimension}`, []
      );
      totalProjections.push({
        data: formatProjectionEntries(
          metricValues, currentPeriods, forecastVizOptions, projectionEntries, dimension
        ),
        type: PROPHET_FORECAST
      });
    }

    if(_.includes(projections, HISTORICAL_FORECAST)) {
      forecastVizOptions['projectionType'] = HISTORICAL_FORECAST;
      projectionEntries = _.get(apiData,
        `historic_data.combo_chart_secondary_results[${index}].total.${dimension}`, []
      );
      totalProjections.push({
        data: formatProjectionEntries(
          metricValues, currentPeriods, forecastVizOptions, projectionEntries, dimension
        ),
        type: HISTORICAL_FORECAST

      });
    }
    return totalProjections;
  } else {
    let projectionEntries = [];
    if (_.includes([EXPONENTIAL_FORECAST, PROPHET_FORECAST], vizOptions.projectionType)) {
      projectionEntries = _.get(apiData,
        `forecasting_data.combo_chart_secondary_totals.${dimension}.forecasted_data`, []
      );
    } else {
      projectionEntries = _.get(apiData,
        `historic_data.combo_chart_secondary_results[${index}].total.${dimension}`, []
      );
    }

    return formatProjectionEntries(
      metricValues, currentPeriods, vizOptions, projectionEntries, dimension
    );
  }
}

export const updateAdjustedValueToValue = (apiData, vizOptions, isOnlyTotal = false) => {
  const apiCloneData = _.cloneDeep(apiData);
  const apiTotalData = apiCloneData.total;

  const { projectionAdjustedValues } = vizOptions;

  _.forEach(apiTotalData, (datum) => {
    let availableDataItem = _.find(projectionAdjustedValues, (dataItem) => {
      return dataItem.period == datum.period;
    });

    const  adujustValue = _.get(availableDataItem, 'adjustValue', _.get(availableDataItem, 'value'));
    datum['value'] = _.toNumber(_.get(datum, 'value')) !== 0 ? _.get(datum, 'value') :  adujustValue;
  })

  return isOnlyTotal ? (apiTotalData || []) : apiCloneData;
}

export const getTotalLastPoint =
  (dataItems, currentPeriods, vizOptions, previousDataItems, dimension, onlyLastCurrentItem) => {
  const {dateRange, axisGranularity, isForecastingView } = vizOptions;
  const isQuarterType = axisGranularity == 'quarter';
  if(isForecastingView && isQuarterType &&
    isForecastEndDateIsBeforeToday({ dateRange }, axisGranularity)) {
      const formattedTotalItems = formatEntityDataItems(dataItems, currentPeriods, vizOptions)
      const lastDataItem = _.last(formattedTotalItems);
      return [{
        ...lastDataItem,
        ignoreProjection: true
      }];
    } else {
      return formatProjectionEntries(
        dataItems, currentPeriods, vizOptions, previousDataItems, dimension, onlyLastCurrentItem)
    }
}

export const showHistoricProjections = (dataItems, vizOptions, currentPeriods) => {
  const totals = formatEntityDataItems(dataItems, currentPeriods, vizOptions)
  const forecastAttributeOptions = {
    currentChartView: vizOptions.renderType,
    selectedForecastMetric: {},
    isBurnup: vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type,
    isDiscreteMode: true,
    projectionAdjustedValues: vizOptions.projectionAdjustedValues
  };
  const totalResults = {
    current: {
      total: totals
    }
  }

  const forecastFormattedData = getForecastFormattedData(totalResults, forecastAttributeOptions);
  const validDataOptions = {
    formattedData: forecastFormattedData,
    currentForecastDateRange: { dateRange: vizOptions.dateRange },
    axisGranularity: vizOptions.axisGranularity
  }

  const formattedData = getValidFormattedData(validDataOptions);
  const isFoundGapValue = _.some(formattedData, (datum) => {
    const value = getValidChartValue(datum);
    const adjustValue = Number(_.get(datum, 'adjustValue', 0));

    return (value == 0 && adjustValue == 0 )
  });
  const { dateRange } = vizOptions;
  const startDate = moment(dateRange['startDate']);
  const endDate = moment(dateRange['endDate']);
  const diffDays = endDate.diff(startDate,'days');
  const isOneYear = diffDays > 365;
  return (isFoundGapValue || !isOneYear);
}
