import React, { useState, useEffect, useMemo} from "react";
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { isMobileOrTablet } from 'helpers/DomPageHelper';
import CardDrag from 'pages/dashboard/components/CardList/CardDrag';
import CardDrop from 'pages/dashboard/components/CardList/CardDrop';
import CustomDropDown from 'common/components/SolutionDropDown/CustomDropDown';

import {
  getGlobalFiltersFromTemplate,
  getOrderByRadarMetricEntries
} from 'common/config/customerConfiguration'
import {
  getCurrentTemplateGlobalFilters,
  updateRadarCardEntriesOrder
} from 'pages/dashboard/components/Collections/collectionHelper';
import Filters from 'common/components/Filters';
import {
  getCurrentViewEntry, getDimensionEntries, getQuickFilterEntries
} from "common/config/templateConfiguration";
import { getApiParams } from "helpers/apiParamsHelper";
import { updateStoreToUrl } from "helpers/UrlParamsHelper";
import { getDefaultDimensionField } from "helpers/visualizationHelper";
import PermissionFilter from 'common/components/Filters/PermissionFilter';
import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import { trackEvent } from 'helpers/eventTracking';
// import { getMetricDefaultFilters } from 'pages/Radar/radarHelper';

const RadarMetricCard = (props) => {
  const {
    metricCardEntry,
    index,
    selectedMetricEntries,
    onSelectedMetricsChange,
    onMetricFiltersChange,
    onMetricGlobalFiltersChange,
    metricFilters,
    currentDateRangeOptions,
    isMetricFilterActive,
    currentFilter,
    metricGlobalFilters,
    metricHasError
  } = props;
  const allRadarMetricEntries = useMemo(() => getOrderByRadarMetricEntries(), []);

  const templateId = _.get(metricCardEntry, 'template_id', '');
  const viewId = _.get(metricCardEntry, 'view_id', '');
  const metricCardId = _.get(metricCardEntry, 'id');

  const [filters, setFilters] = useState(currentFilter);
  const [radarMetricEntries, setRadarMetricEntries] = useState(allRadarMetricEntries);
  const currentDrilldownViewEntry =  getCurrentViewEntry(templateId, viewId);
  const state = {
    drilldown:{
      currentDrilldownTemplateId: templateId,
      currentDrilldownViewEntry
    }
  };

  useEffect(()=>{
    if(!_.isEqual(currentFilter, filters)){
      setFilters(currentFilter);
    }
  }, [currentFilter, metricCardId])

  const onMetricChange = (metricEntry) =>{
    trackEvent('radar_new_metric_selected');
    let newMetricEntries = _.cloneDeep(selectedMetricEntries);
    newMetricEntries[index] = metricEntry;
    const newCardId = _.get(metricEntry, 'id');
    _.set(_.find(metricFilters, {metricIndex : index}), 'filters', []);
    _.set(_.find(metricFilters, {metricIndex : index}), 'id', newCardId);
    onSelectedMetricsChange(newMetricEntries, metricFilters);
  }

  const handleCardMove = ({ targetCard, draggedCard, insertAfter }) => {
    const updatedEntries = updateRadarCardEntriesOrder(
      targetCard, draggedCard, selectedMetricEntries, metricFilters, insertAfter
    );
    const { updatedRadarEntries, updatedRadarFilters } = updatedEntries;
    onSelectedMetricsChange(updatedRadarEntries, updatedRadarFilters);
  }

  const onCardDelete = () =>{
    let newMetricEntries = _.cloneDeep(selectedMetricEntries);
    newMetricEntries.splice(index, 1);

    let newMetricFilters = _.cloneDeep(metricFilters);
    newMetricFilters.splice(index, 1);

    onSelectedMetricsChange(newMetricEntries,newMetricFilters);
  }

  const onGlobalFiltersChange = (filters) => {
    const currentMetric = {
      id : metricCardId,
      templateId : templateId,
      viewId : viewId
    };
    _.set(_.find(metricGlobalFilters, currentMetric),'filters', filters);
    onMetricGlobalFiltersChange(metricGlobalFilters);
  }

  const onFiltersChange = (filters) => {
    const currentMetric = {
      id: _.get(metricCardEntry, 'id'),
      metricIndex : _.get(metricCardEntry, 'metricIndex')
    };
    _.set(_.find(metricFilters, currentMetric),'filters', filters);
    onMetricFiltersChange(metricFilters);
  }

  const onCardExplore = () =>{
    const dimensionEntries = getDimensionEntries(templateId);
    const currentDrilldownDimensionField = getDefaultDimensionField(
      dimensionEntries, currentDrilldownViewEntry
    );
    const stateWithFilters = {
      drilldown: {
        ...state.drilldown,
        quickFilters: filters,
        currentDrilldownDimensionField: currentDrilldownDimensionField
      },
      commonFilters:{
        dateRange: currentDateRangeOptions.dateRange
      }
    }

    const href = updateStoreToUrl('/#!/analysis', stateWithFilters);
    window.open(href, '_blank');
  }

  const renderCardContent = () => {
    const cardProps = { userCardEntry: metricCardEntry};
    let content;

    if (isMobileOrTablet()) {
      content = renderCard(metricCardEntry, index);
    }else{
      content = (
        <CardDrag {...cardProps}>
          <CardDrop {...cardProps} onCardMove={handleCardMove}></CardDrop>
          <CardDrop
            {...cardProps}
            onCardMove={handleCardMove}
            insertAfter
            ></CardDrop>
          {renderCard(metricCardEntry, index)}
        </CardDrag>
      )
    }

    return (
      <div className="card-container metric-tile h-100"
        data-test={index} key={index}>
        {content}
      </div>
    );
  }

  const onSearchInputChange = (value) => {
    const valueLowerCase = value.toLowerCase()
    const filteredRadarMetricEntries = _.filter(allRadarMetricEntries, ({ name }) => {
      return _.includes(name.toLowerCase(), valueLowerCase);
    });
    const newTemplateWiseRadarMetricEntries = _.isEmpty(value) ?
                                              allRadarMetricEntries : filteredRadarMetricEntries;
    setRadarMetricEntries(newTemplateWiseRadarMetricEntries);
  }

  const renderCardHead = () => {
    return(
      <div>
        <div className="radar-metric-head">
          { renderMenus() }
        </div>
        <div onClick={e => e.stopPropagation()}>
          <div className="radar-card-inner">
            <CustomDropDown
              key="radar-drop-down"
              groupByColumn="template_id"
              selectedField={metricCardEntry.id}
              selectedFieldKey="id"
              size="sm"
              id={index}
              className="p-0 d-flex"
              title={metricCardEntry.name}
              options={radarMetricEntries}
              onSearchInputChange={onSearchInputChange}
              onSelect={onMetricChange}
              isRadarMetric={true}
              displayNameField='name'
            />
          </div>
        </div>
        <div className="info-sections ml-1"></div>
      </div>
    );
  }

  const renderMenus = () => {
    return (
      <Dropdown onClick={(e) => e.stopPropagation()}>
        <Dropdown.Toggle
          variant="link"
          className="expand-btn btn-sm text-muted"
          id={'radar-card-custom' + index}
          tabIndex={-1}
          aria-label="Actions">
          <i className="icons-ellipsis-v"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>

        { selectedMetricEntries.length <= 1 ? null :
          <Dropdown.Item eventKey="2" as="RadarCardDeleteButton"
            onClick={() => onCardDelete()}>
            Remove
          </Dropdown.Item>
        }
        <Dropdown.Item eventKey="2" as="RadarCardExploreButton"
          onClick={() => onCardExplore()}>
          Explore</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  const renderGlobalFilters = () => {
    const apiParams = getApiParams(state, {
      withShapeIds: false,
      withDrillDown: true,
      withPolygonFilter: false,
      withCommonFilters: false,
      isComparison: false
    })
    const radarGlobalFilter = _.find(metricGlobalFilters, (metricGlobalFilter) => {
      if (_.isEqual(_.get(metricGlobalFilter, 'templateId'), templateId) &&
        _.isEqual(_.get(metricGlobalFilter, 'id'), metricCardId) &&
        _.isEqual(_.get(metricGlobalFilter, 'metricIndex'), index)){
        return metricGlobalFilter
      }
    });
    const globalFilters = _.get(radarGlobalFilter, 'filters');
    const templateEntry = getCurrentTemplateEntry(templateId);
    const collectionFilters = getCurrentTemplateGlobalFilters(templateEntry, globalFilters);
    const globalFilterConfigEntries = getGlobalFiltersFromTemplate(templateEntry);

    if(_.isEmpty(collectionFilters)){
      return null;
    }

    return (
      <div onClick={(e) => e.stopPropagation()} className="radar-metric-filter">
        <Filters
          isDisabledAddFilterButton={true}
          // disabledFilters={true}
          isFilterBadge={true}
          type="quickFilters"
          filterFieldEntries={globalFilterConfigEntries}
          filters={collectionFilters}
          templateId={templateId}
          apiParams={apiParams}
          onFiltersChange={(filters) => onGlobalFiltersChange(filters)}
          isFilterActive={isMetricFilterActive}
        />
      </div>
    );
  }

  const renderFilters = () => {
    const filterFieldEntries = getQuickFilterEntries(templateId);
    const apiParams = getApiParams(state, {
      withShapeIds: false,
      withDrillDown: true,
      withPolygonFilter: false,
      withCommonFilters: false,
      isComparison: false
    })
    // const templateEntry = getCurrentTemplateEntry(templateId);
    // const templateDatasetId = _.get(templateEntry, 'dataset_id');

    return (
      <div onClick={(e) => e.stopPropagation()} className="radar-metric-filter">
        <PermissionFilter
          templateIds={[templateId]}
          showPermissionContainer={true}
        />
        <Filters
          type="quickFilters"
          filterFieldEntries={filterFieldEntries}
          filters={filters}
          templateId={templateId}
          apiParams={apiParams}
          onFiltersChange={(filters) => onFiltersChange(filters)}
          isFilterActive={isMetricFilterActive}
        />
      </div>
    );
  }

  const renderErrorLabel = () => {
    return (metricHasError ?
      <div className="radar-error-label">Something went wrong</div> :
      null);
  }

  const renderCard = () => {
    return (
      <div className="card h-100">
        <div className='card-body d-flex flex-column'>
          {renderCardHead()}
          {renderGlobalFilters()}
          {renderFilters()}
          {renderErrorLabel()}
        </div>
      </div>
    );
  }

  return renderCardContent();
}

RadarMetricCard.propTypes = {
  metricCardEntry: PropTypes.object,
  metricFilters: PropTypes.object,
  index: PropTypes.number,
  selectedMetricEntries: PropTypes.array,
  onSelectedMetricsChange: PropTypes.func,
  currentDateRangeOptions: PropTypes.object,
  isMetricFilterActive: PropTypes.func,
  metricGlobalFilters: PropTypes.array
};

export default RadarMetricCard;
