import _ from 'lodash';

import { DRILLDOWN_VISUALIZATION_TYPES, GROUP_BY_NONE_ENTRY, VISUALIZATION_TYPES } from 'appConstants';
import { getViewEntries,
  getDimensionEntries,
  getGroupByEntries
} from 'common/config/templateConfiguration';

import {
  SET_CURRENT_VISUALIZATION_TYPE,
  SET_DRILLED_DOWN_DIMENSIONS,
  UPDATE_DRILLDOWN_DIMENSION_FIELD,
  UPDATE_DRILLDOWN_GROUP_BY_ENTRY,
  UPDATE_DRILLDOWN_TEMPLATE_NAME,
  UPDATE_DRILLDOWN_VIEW_ENTRY,
  TOGGLE_INSPECT_VIEW_MODAL,
  UPDATE_QUICK_FILTERS,
  UPDATE_TEMPLATE_CHANGE,
  RESET_ALL_FILTERS,
  UPDATE_PERMISSION_FILTERS,
  UPDATE_NEW_LABEL_CARD_ID,
  UPDATE_TIME_OF_DAY_TOGGLE_BUTTON,
  UPDATE_TIME_OF_DAY_FILTERS
} from 'actions/drilldownActions';

import { getDefaultDimensionField, isGeoLocationOrUrlColumn } from 'helpers/visualizationHelper';
import { getDefaultGroupByColumn } from 'common/config/visualizationConfiguration';

export const drilldownReducerDefaultState = () => {
  return {
    currentDrilldownDimensionField: '',
    newLabelCardId: '',
    currentDrilldownGroupByEntry: {},
    currentDrilldownTemplateName: '',
    currentDrilldownViewEntry: {},
    currentVisualizationType: VISUALIZATION_TYPES.SNAPSHOT.type,
    drilledDownDimensions: [],
    permissionFilters: [],
    showInspectViewModal: false,
    quickFilters: [],
    isLeafPage: false,
    isTimeOfDayOn: false,
    timeOfDayFilters: []
  };
};

export const getTemplateState = (state, templateEntry, viewEntry = {}) => {
  const currentDrilldownTemplateName = templateEntry.name;
  const currentDrilldownTemplateId = templateEntry.template_id;
  const viewEntries = getViewEntries(currentDrilldownTemplateId);
  const currentDrilldownViewEntry = _.isEmpty(viewEntry) ? _.get(viewEntries, '[0]', {}) : viewEntry;
  const currentDrilldownDimensionField = getDefaultDimensionField(
    templateEntry.dimension_entries, currentDrilldownViewEntry);

  return {
    ...drilldownReducerDefaultState(),
    currentDrilldownDimensionField,
    currentDrilldownTemplateName,
    currentDrilldownTemplateId,
    currentVisualizationType: _.get(state, 'currentVisualizationType', VISUALIZATION_TYPES.SNAPSHOT.type),
    ...onViewEntryUpdate(state, currentDrilldownTemplateId, currentDrilldownViewEntry)
  }
}

const onViewEntryUpdate = (
  state,
  currentDrilldownTemplateId, viewEntry, isUpdateDrilldownDimensionField = true) => {

  const groupEntries = getGroupByEntries(currentDrilldownTemplateId);
  const currentVisualizationType = _.get(state,
    'currentVisualizationType', DRILLDOWN_VISUALIZATION_TYPES.SNAPSHOT);
  let currentDrilldownGroupByEntry = GROUP_BY_NONE_ENTRY;
  if(currentVisualizationType === VISUALIZATION_TYPES.SNAPSHOT.type){
    const defaultGroupByColumn = getDefaultGroupByColumn(viewEntry);
    const groupByColumn = isGeoLocationOrUrlColumn(defaultGroupByColumn) ?
      _.get(groupEntries, '0.column'):
      defaultGroupByColumn;

    currentDrilldownGroupByEntry = _.find(groupEntries, { 'column': groupByColumn });
  }
  const dimensionEntries = getDimensionEntries(currentDrilldownTemplateId);
  const currentDrilldownDimensionField = getDefaultDimensionField(dimensionEntries, viewEntry);
  let updatedViewOption = { currentDrilldownViewEntry: viewEntry };

  if (isUpdateDrilldownDimensionField) {
    return _.merge({}, updatedViewOption, { currentDrilldownDimensionField, currentDrilldownGroupByEntry });
  }

  return updatedViewOption;
}

const assignQuickFilterValues = (action) => {
  const quickFilters = _.get(action, 'quickFilters');
  return _.isEmpty(quickFilters) ? [] : quickFilters
}

export default function(state = drilldownReducerDefaultState(), action) {
  switch (action.type) {
    case SET_CURRENT_VISUALIZATION_TYPE:
      return {
        ...state,
        currentVisualizationType: _.get(action, 'visualizationType')
      }
    case UPDATE_DRILLDOWN_TEMPLATE_NAME:
      return {
        ...state,
        currentDrilldownTemplateName: _.get(action, 'name'),
        currentDrilldownTemplateId: _.get(action, 'templateId')
      }
    case UPDATE_DRILLDOWN_DIMENSION_FIELD:
      return {
        ...state,
        currentDrilldownDimensionField: _.get(action, 'field')
      }
    case UPDATE_DRILLDOWN_GROUP_BY_ENTRY:
      return {
        ...state,
        currentDrilldownGroupByEntry: _.get(action, 'entry')
      }
    case UPDATE_DRILLDOWN_VIEW_ENTRY:
      const entry = _.get(action, 'entry', {});
      const isUpdateDrilldownDimensionField = _.get(action, 'isUpdateDrilldownDimensionField', true);
      return {
        ...state,
        ...onViewEntryUpdate(state, state.currentDrilldownTemplateId, entry, isUpdateDrilldownDimensionField)
      }
    case SET_DRILLED_DOWN_DIMENSIONS:
      return {
        ...state,
        drilledDownDimensions: _.get(action, 'drilldownEntries')
      }
    case TOGGLE_INSPECT_VIEW_MODAL:
      return {
        ...state,
        showInspectViewModal: _.get(action, 'toggle')
      }
    case UPDATE_TEMPLATE_CHANGE:
      return {
        ...state,
        ...getTemplateState(state, _.get(action, 'templateEntry', {}), _.get(action, 'viewEntry', {}))
      }
    case UPDATE_QUICK_FILTERS:
      return {
        ...state,
        quickFilters: assignQuickFilterValues(action)
      }
    case UPDATE_NEW_LABEL_CARD_ID:
        return {
          ...state,
          newLabelCardId: _.get(action, 'newLabelCardId')
        }
    case UPDATE_PERMISSION_FILTERS:
      const filtersFromAction = _.get(action, 'permissionFilters');
      const permissionFilters = _.isEmpty(filtersFromAction) ? {} : filtersFromAction
      return {
        ...state,
        permissionFilters: permissionFilters
      }
    case RESET_ALL_FILTERS:
      return {
        ...state,
        quickFilters: [],
        drilledDownDimensions: []
      }
    case UPDATE_TIME_OF_DAY_TOGGLE_BUTTON:
      return {
        ...state,
        isTimeOfDayOn: _.get(action, 'isTimeOfDayOn')
      }
    case UPDATE_TIME_OF_DAY_FILTERS:
      return {
        ...state,
        timeOfDayFilters: _.get(action, 'timeOfDayFilters')
      }
    default:
      return state;
  }
}
