import React, { Component } from 'react';
import { getSearchEntriesAndQuickFilters } from './SearchHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { NUMBER_TYPES_FIELD, STRING_TYPES_FIELD } from 'appConstants';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import DropDownFooter from 'pages/drilldown/components/QuickFilterBar/DropDownFooter';

class TableFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: null
    };

    this.searchInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { tableSearchEntries } = this.props;
    if(!_.isEqual(prevProps.tableSearchEntries, tableSearchEntries) && _.isEmpty(tableSearchEntries)) {
      this.handleClearSearchInput();
    }
  }

  handleChangeSearchObject = (searchValue) => {
    const { searchFieldEntry, onClose, tableSearchEntries } = this.props;
    const tableSearchEntry = _.find(tableSearchEntries, {column: searchFieldEntry.column}) || [];
    const searchEntryValues = _.map(_.get(tableSearchEntry, 'values'), (val) => _.lowerCase(val));

    if(!_.isEmpty(tableSearchEntry) && _.includes(searchEntryValues, _.lowerCase(searchValue))) {
      onClose();
      return;
    }
    const { newQuickFilters, newSearchInputEntries } = getSearchEntriesAndQuickFilters(
      searchFieldEntry,
      searchValue,
      _.pick(this.props, ['tableSearchEntries', 'quickFilters', 'templateId']));

    this.updateSearchObjectAndQuickFilters(newSearchInputEntries, newQuickFilters)
    this.handleClearSearchInput();
    onClose();
  }

  handleClearSearchInput = () => {
    this.searchInput.current.value = '';
    this.setState({ searchInput: null });
  }

  updateSearchObjectAndQuickFilters = (searchEntries, newQuickFilters) => {
    const { onUpdateSearchObjectAndQuickFilters } = this.props;
    onUpdateSearchObjectAndQuickFilters(searchEntries, newQuickFilters)
  }

  handleSearchInputChange = (e) => {
    this.setState({ searchInput: e.target.value });
  }

  handleKeyDown = (e) => {
    if(isEnterButtonPressed(e)) {
      this.handleChangeSearchObject(this.state.searchInput);
    }
  }

  renderSearchInput() {
    const { searchFieldEntry } = this.props;
    const isNumericEntry = (_.get(searchFieldEntry, 'renderType') === NUMBER_TYPES_FIELD);

    return (
      <input
        autoFocus
        onKeyUp={this.handleSearchInputChange}
        onKeyDown={this.handleKeyDown}
        disabled={_.isEmpty(searchFieldEntry)}
        maxLength="36"
        ref={this.searchInput}
        className="form-control flex-grow-1 bg-transparent"
        type={isNumericEntry ? NUMBER_TYPES_FIELD : STRING_TYPES_FIELD}
        placeholder="Search" />
    );
  }

  render() {
    const { onClose } = this.props;
    const isButtonDisabled = _.isEmpty(this.state.searchInput);

    return(
        <div className="common-filters-wrapper">
          <div className="filters-body">
            <label className='filter-label'>Value</label>
            {this.renderSearchInput()}
            {/* { !_.isEmpty(this.state.searchInput) &&
              <i className="pointer icons-close mr-2" onClick={this.handleClearSearchInput}/>}        */}
          </div>
          <DropDownFooter
            onApply={() => this.handleChangeSearchObject(this.state.searchInput)}
            onRemove={onClose}
            isDisabledApplyButton={isButtonDisabled}
            hideResetButton={true}
            hideRemoveButton={false} />
      </div>
    )
  }
}

TableFilter.propTypes = {
  templateId: commonPropTypes.templateIdPropTypes,
  tableSearchEntries: PropTypes.array,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  searchFieldEntry: PropTypes.object,
  onUpdateSearchObjectAndQuickFilters: PropTypes.func,
  onClose: PropTypes.func
};

 export default  TableFilter;
