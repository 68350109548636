import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import classNames from 'classnames';
import { GREYOUT_TOOLTIP_TEXT } from 'appConstants';
import * as commonPropTypes from 'common/propTypes';

class SolutionDropDown extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  static defaultProps = {
    variant: 'link',
    disabled: false,
    ariaLabel: ''
  };

  getPlaceHolder = () => {
    const { title, selectedOption, placeHolder } = this.props;
    if (title) {
      return title;
    }
    if (!_.isEmpty(selectedOption)) {
      return (this.renderOptionName(selectedOption) || placeHolder);
    }
  }

  // this is to handle if the option name is in boolean type,
  // we need to show like (true or false) -> ('true' or 'false')
  renderOptionName(option) {
    const { optionDisplayField } = this.props;
    if (!_.isEmpty(optionDisplayField)) {
      return (option[optionDisplayField] || '').toString();
    } else if (!_.isEmpty(option.name)) {
      return option.name;
    } else {
      return option;
    }
  }

  renderDropdownRows() {
    const {
      options,
      onSelect,
      showCount,
      showSelectedIcon,
      selectedOption,
      showDescription
    } = this.props;

    const dropdownRows = options.map((option, index) => {
      const description = _.get(option, 'description', '');
      const isSelectionOption = _.isEqual(option, selectedOption);
      const dropDownItemClassNames = classNames({
        'd-flex justify-content-between': showCount,
        'active': isSelectionOption
      });
      return (
        <Dropdown.Item
          key={index}
          className={dropDownItemClassNames}
          disabled={option.disabled}
          onSelect={() => onSelect(option)} >
          {(isSelectionOption && showSelectedIcon) ? <i className="icons-check"></i> : null}
          {this.renderOptionName(option)}
          {showCount && !_.isUndefined(option.count) && <span className="ml-3">{option.count}</span>}
          {
            (!_.isEmpty(description) && showDescription) &&
            <div className="roles-explanation">{description}</div>
          }
        </Dropdown.Item>
      );
    });

    return dropdownRows;
  }

  render() {
    const {
      size, variant, className, disabled, showSelectedIcon,
      iconClass, textOverflowClass, ariaLabel, id
    } = this.props;
    const customClassName = _.isEmpty(className) ? '' : ' ' + className;
    const iconClassName = _.isEmpty(iconClass) ? '' : ' ' + iconClass;
    const overflowClassName = _.isEmpty(textOverflowClass) ?
                              'dropdown-placeholder forge-typography--body1' : ' ' + textOverflowClass;
    const titleText = this.getPlaceHolder();
    const dropDownClassNames = classNames({
      'with-selection-tick': showSelectedIcon
    });
    const customId = _.isEmpty(id) ? '' : '-'+id;
    return (
      <div className="solution-custom-dropdown">
        <Dropdown>
          <Dropdown.Toggle
            id={"dropdown-custom" + customId}
            className={'solution-dropdown' + customClassName}
            variant={variant}
            size={size}
            disabled={disabled}
            tabIndex={-1} 
            aria-label={ariaLabel}>
            <div
              className="dropdown-container"
              tabIndex={disabled ? -1 : 0}
              title={ariaLabel || titleText}>
              <div className={overflowClassName}>{titleText}</div>
              <div className={"dropdown-caret icons-chevron-down" + iconClassName}></div>
              {/*Sentence builder dropdown disabled hover flyout*/}
              <div className="flyout flyout-right">
                <div className="flyout-content">
                  {GREYOUT_TOOLTIP_TEXT}
                </div>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className={dropDownClassNames}>
            {this.renderDropdownRows()}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

SolutionDropDown.propTypes = {
  title: PropTypes.node,
  ariaLabel: PropTypes.string,
  placeHolder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  size: PropTypes.string,
  variant: PropTypes.string,
  showCount: PropTypes.bool,
  showSelectedIcon: PropTypes.bool,
  selectedOption: commonPropTypes.stringOrObjectProps,
  onSelect: PropTypes.func,
  optionDisplayField: PropTypes.string,
  disabled: PropTypes.bool,
  showDescription: PropTypes.bool,
  iconClass: PropTypes.string,
  textOverflowClass: PropTypes.string,
  id: PropTypes.string
}

export default (SolutionDropDown);
