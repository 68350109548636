import { getDimensionEntries } from 'common/config/templateConfiguration';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import { getSuffixByVersion } from 'helpers/chartDataHelper';
import { getDimensionName, getPrimaryMetricName } from 'helpers/displayNameHelper';
import { isBarchart } from './SnapshotVisualization/barChartHelper';
import { isScatterChart } from './SnapshotVisualization/plotlyScatterChartHelper';
import { getLineChartSummaryFormatter } from './SnapshotVisualization/SummaryTable/lineChartSummaryFormatter';
import { isStackTypeChart } from './SnapshotVisualization/vizOptionsHelper';

export const getChartAndSummaryConfig = ({ formattedData, vizOptions }) => {
  const {
    isDistributionChart,
    isLineChart,
    templateId,
    viewEntry,
    secondaryMetricEntry,
    currentSnapshotView
  } = vizOptions;
  let tableData = [];
  let tableHeaders = [];
  let groupByDimensionField = '';
  let flyoutValueField = '';
  let isStackedBar = false;
  let isPieChart = false;
  let showRange = false;
  const primaryMetricColor = getConfiguredMetricColor(templateId, 'primary');
  const secondaryMetricColor = getConfiguredMetricColor(templateId, 'secondary');
  const showSuffixInFlyout = JSON.parse(_.get(viewEntry, 'show_suffix_in_flyout', false));
  const suffixByVersion = showSuffixInFlyout ? getSuffixByVersion(viewEntry) : '';

  if(isDistributionChart) {
    const distributionChartConfig = getDistributionChartConfig(formattedData, vizOptions);
    isStackedBar = true;
    tableData = _.get(distributionChartConfig, 'tableData');
    tableHeaders = _.get(distributionChartConfig, 'tableHeaders');
    groupByDimensionField = _.get(distributionChartConfig, 'groupByDimensionField');
    flyoutValueField = _.get(distributionChartConfig, 'flyoutValueField');
  } else if(isStackTypeChart(vizOptions)) {
    tableHeaders = _.chain(formattedData).flatten().map((datum) => {
      return { name: datum['value'], columnField: datum['value'] };
    }).uniqBy('columnField').value();
    tableData = _.chain(formattedData).flatten().groupBy('view').value();
    groupByDimensionField = 'value';
    flyoutValueField = 'formattedViewCount';
    isStackedBar = true;
  }  else if (isLineChart) {
    const summaryData = getLineChartSummaryFormatter(formattedData, vizOptions);
    tableHeaders = _.get(summaryData, 'tableHeaders');
    tableData = _.get(summaryData, 'tableData');
  } else {
    const dimensionField = _.get(_.first(getDimensionEntries(templateId)), 'field');
    const dimensionName = getDimensionName(templateId, dimensionField);
    const primaryMetricName = getPrimaryMetricName(viewEntry);
    const color = _.isEmpty(secondaryMetricEntry) ? '' : secondaryMetricColor;
    tableData = _.chain(formattedData).flatten().reverse().value();

    if (isBarchart(currentSnapshotView)) {
      flyoutValueField = 'hoverText';
      groupByDimensionField = 'value';
    } else if (isScatterChart(currentSnapshotView)) {
      showRange = _.get(vizOptions, 'showRange', false);
      flyoutValueField = 'plotlyDescription';
      groupByDimensionField = 'label';
    } else {
      isPieChart = true;
      tableData = _.chain(formattedData).flatten().value();
      const total = _.sumBy(tableData, ({ value }) => Number(value));
      _.each(tableData, (tableDatum) => {
        const { value } = tableDatum;
        const roundOffValue = _.round(((value * 100) / total), 2);
        tableDatum['percetage'] = `(${roundOffValue} %)`;
      });
      groupByDimensionField = 'label';
      flyoutValueField = 'text';
    }
    tableHeaders = [
      { name: dimensionName, columnField: groupByDimensionField }
    ];
    if(showRange) {
      tableHeaders.push({
        name: 'Lower Range', columnField: 'minValue', suffix: suffixByVersion, toFixed: '2'
      });
    }
    if(!_.isEmpty(secondaryMetricEntry)) {
      const secondaryMetricName = _.get(secondaryMetricEntry, 'name');
      tableHeaders.push({
        name: secondaryMetricName, columnField: 'secondaryHoverText', color: primaryMetricColor
      });
    }
    tableHeaders.push({ name: primaryMetricName, columnField: flyoutValueField, color });
    if(isPieChart) {
      tableHeaders.push({ name: 'Percentage', columnField: 'percetage' });
    }

    if(showRange) {
      tableHeaders.push({
        name: 'Upper Range', columnField: 'maxValue', suffix: suffixByVersion, toFixed: '2'
      })
    }
  }

  return {
    tableData,
    tableHeaders,
    groupByDimensionField,
    flyoutValueField,
    isStackedBar
  };
}

function getDistributionChartConfig(formattedData, vizOptions) {
  const { distributionOption, templateId } = vizOptions;
  const { isCumulative, isDiscrete } = distributionOption;
  const { cumulativeEntriesData, discreteEntriesData } = formattedData;
  const primaryMetricColor = getConfiguredMetricColor(templateId, 'primary');
  const secondaryMetricColor = getConfiguredMetricColor(templateId, 'secondary');
  const discreteData = _.get(formattedData, 'discreteData', []);
  const cumulativeData = _.get(formattedData, 'cumulativeData', []);
  let chartData = discreteEntriesData;
  let groupByDimensionField = 'id';
  let flyoutValueField = 'value';
  let groupByField = 'dimension';
  let tableHeaders = _.map(discreteData, (discreteDatum) => {
    const { id, tickLabel } = discreteDatum;
    return { name: tickLabel, columnField: id };
  });

  if(isCumulative && isDiscrete) {
    chartData = [...discreteData, ...cumulativeData];
    tableHeaders = [
      { name: 'Discrete', color: primaryMetricColor, columnField: 'id'},
      { name: 'Cumulative', color: secondaryMetricColor, columnField: 'id'}
    ];
    groupByDimensionField = '';
    flyoutValueField = 'plotlyDescription';
    groupByField = 'tickLabel';
  } else if(isCumulative && !isDiscrete) {
    chartData = cumulativeEntriesData;
  }
  const tableData = _.chain(chartData).flatten().groupBy(groupByField).value();

  return { tableData, tableHeaders, groupByDimensionField, flyoutValueField };
}
