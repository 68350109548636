import React from 'react';
import PropTypes from 'prop-types';

import {
  getSLAWatchCalculationType,
  showCategoriesNoDataConfig,
  getCategoriesWithNoDataLabel,
  getSLAWatchChartType
} from 'common/config/customerConfiguration';
import { SLA_CALCULATION_TYPES } from 'appConstants';


const PercentagePoint = (props) => {
  const {
    showPercentagePoint,
    showCategoriesNoData,
    onPercentagePointChange,
    onCategoriesNoDataChange,
    selectedDimension,
    noDataCountLabel
  } = props;
  let noDataLabel = getCategoriesWithNoDataLabel();
  if (_.isEmpty(noDataLabel)) {
    noDataLabel = `Show ${selectedDimension['name']} No Data`;
  }
  noDataLabel = `${noDataLabel} ${noDataCountLabel}`;

  const label = "Show Distance in Percentage Point";
  const isPercentageOn = _.isEqual(getSLAWatchCalculationType(), SLA_CALCULATION_TYPES.PERCENTAGE)
  const isShowNoData = showCategoriesNoDataConfig() && getSLAWatchChartType() == 'bullet_chart'
  return (
    <div className="sla-bc-percentage-point">
      { !isPercentageOn &&
        <div
          tabIndex={0}
          aria-label="Comparison Mode"
          className="custom-control custom-checkbox"
          onKeyDown={(e) => this.handleKeyDown(e, onPercentagePointChange)}>
          <input
            type="checkbox"
            tabIndex={-1}
            className="custom-control-input"
            id="percentagePoint"
            checked={showPercentagePoint}
            onChange={onPercentagePointChange} />
          <label className="custom-control-label mb-0" htmlFor="percentagePoint">{label}</label>
        </div>
      }
      { isShowNoData &&
        <div
          tabIndex={0}
          aria-label="Comparison Mode No Data"
          className="custom-control custom-checkbox"
          onKeyDown={(e) => this.handleKeyDown(e, onCategoriesNoDataChange)}>
          <input
            type="checkbox"
            tabIndex={-1}
            className="custom-control-input"
            id="categoriesNoData"
            checked={showCategoriesNoData}
            onChange={onCategoriesNoDataChange} />
          <label className="custom-control-label mb-0" htmlFor="categoriesNoData">{noDataLabel}</label>
        </div>
      }
    </div>
  );
}

PercentagePoint.propTypes = {
  onPercentagePointChange: PropTypes.func,
  showPercentagePoint: PropTypes.bool,
  onCategoriesNoDataChange: PropTypes.func,
  showCategoriesNoData: PropTypes.bool,
  selectedDimension: PropTypes.object,
  noDataCountLabel: PropTypes.string
}
export default PercentagePoint;
