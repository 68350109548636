import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as commonPropTypes from 'common/propTypes';
import ForgeMenuOptions from 'common/components/MenuDropDown/ForgeMenuOptions';
import {
  ForgeButtonToggleGroup,
  ForgeButtonToggle,
  ForgeIcon
} from '@tylertech/forge-react';
import { getOvertimeChartOptions } from 'helpers/visualizationHelper';
import {
  getAxisGranularityOptions,
  getOrderedAxisGranularity
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import {
  defaultPrepareDataAxisGranularityOption
} from 'pages/Forecasting/ForecastHelper';
import ForecastLegends from './ForecastLegends';
import { FORECAST_VIEW } from 'appConstants';
import { isSmallForecastingChartSize } from 'helpers/DomPageHelper';
import classNames from 'classnames';

const ForecastLineChartOptions = (props) => {
  const {
    axisGranularity, onAxisGranularityChange, currentChartView,
    currentDrilldownViewEntry, handleChartViewChange, currentDrilldownTemplateId,
    forecastCurrentView, onForecastCurrentView, legendItems, isOpenLegend,
    toggleClickLegendButton, isOpenLeftPanel, onUpdateLegendWidth
  } = props;

  const [isSmallWindowMode, setIsSmallWindowMode] = useState(false);
  const isTableView = forecastCurrentView === FORECAST_VIEW.SUMMARY_TABLE;
  const axisGranularityOptions = getAxisGranularityOptions(
    currentDrilldownTemplateId,
    true
  );
  useEffect(() => {
    const handleResize = () => {
      const isSmallViewMode = isSmallForecastingChartSize();
      setIsSmallWindowMode(isSmallViewMode);
    };
    setTimeout(() => {
      handleResize();
    }, 200);
    window.addEventListener('resize', handleResize);  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpenLeftPanel]);
  

  const onChangeOvertimeChartType = (chart) => {
    handleChartViewChange(_.get(chart, 'detail.value'));
  }

  const handleAxisGranularityChange = (option) => {
    onAxisGranularityChange(_.get(option, 'detail.value'));
  }

  const renderOvertimeChartTypes = () => {
    const chartTypes = getOvertimeChartOptions(currentDrilldownViewEntry, true);
    const chartType = _.find(chartTypes, { type: currentChartView });

    if (_.isEmpty(chartType)) {
      return;
    }

    let menuLabelName = '';
    let menuLabelIcon = '';
    const customChartTypes = _.map(chartTypes, (chartType) => {
      if (chartType.type == currentChartView) {
        menuLabelName = chartType.name;
        menuLabelIcon = chartType.icon;
      }

      return {
        value: chartType.type,
        label: chartType.name,
        leadingIconType: 'component',
        leadingIcon: chartType.type == currentChartView ? 'check' : 'forge-empty-icon'
      }
    });

    return (
      <ForgeMenuOptions
        customIconName={menuLabelIcon}
        menuLabelName={menuLabelName}
        menuOptions={customChartTypes}
        onSelectOption={onChangeOvertimeChartType} />
    )
  }

  const renderOvertimeChartOptions = () => {
    // const isAverageMetric = _.get(currentDrilldownViewEntry, 'aggregate_type', '') === 'avg';
    const defaultAxisGranularity = defaultPrepareDataAxisGranularityOption(currentDrilldownTemplateId);
    if (defaultAxisGranularity === 'week') {
      return null;
    }
    const orderedAxisGranularityOptions = getOrderedAxisGranularity(axisGranularityOptions);

    const axisGranularityOption = _.find(axisGranularityOptions, { value: axisGranularity });
    if (_.isEmpty(axisGranularityOption)) {
      return;
    }
    const customOptions = _.map(orderedAxisGranularityOptions, (option) => {     
      return {
        value: option.value,
        label: option.name,
        leadingIconType: 'component',
        leadingIcon: option.value == axisGranularity ? 'check' : 'forge-empty-icon'
      }
    });
    const menuLabelName = _.get(axisGranularityOption, 'name');
    return (
      <ForgeMenuOptions
       customIconName={''}
       menuLabelName={menuLabelName}
       menuOptions={customOptions}
       onSelectOption={handleAxisGranularityChange} 
       customLabelIcon={isSmallWindowMode ? _.head(menuLabelName) : ''}
       />
    );
  }

  const renderToggleButton = () => {
    return (
      <div>
        <ForgeButtonToggleGroup>
          <ForgeButtonToggle
            button-aria-label="Chart view"
            key="overtimeChart"
            value={FORECAST_VIEW.OVER_TIME_CHART}
            selected={forecastCurrentView == FORECAST_VIEW.OVER_TIME_CHART}
            onClick={() => onForecastCurrentView(FORECAST_VIEW.OVER_TIME_CHART)}>
            <ForgeIcon name="chart_timeline_variant"></ForgeIcon>
          </ForgeButtonToggle>
          <ForgeButtonToggle
            button-aria-label="Table view"
            key="summaryTable"
            value={FORECAST_VIEW.SUMMARY_TABLE}
            selected={forecastCurrentView == FORECAST_VIEW.SUMMARY_TABLE}
            onClick={() => onForecastCurrentView(FORECAST_VIEW.SUMMARY_TABLE)}>
            <ForgeIcon name="table"></ForgeIcon>
          </ForgeButtonToggle>
        </ForgeButtonToggleGroup>
      </div>
    );
  }

  const renderLegends = () => {
    if (isTableView) {
      return null;
    }

    return (<ForecastLegends
      legendItems={legendItems}
      isOpenLegend={isOpenLegend}
      toggleClickLegendButton={toggleClickLegendButton}
      onUpdateLegendWidth={onUpdateLegendWidth} />)
  }

  const optionWrapperClass = classNames('snapshot-options', {
    'hide-forge-label': isSmallWindowMode
  });
  return (
    <div className={optionWrapperClass}>
      <div className="rside-action flex-wrap">
        {renderOvertimeChartTypes()}
        {_.size(axisGranularityOptions) === 1 ? null : renderOvertimeChartOptions()}
        {renderToggleButton()}
        <div className='ml-auto'>
          {renderLegends()}
        </div>
      </div>
    </div>
  );
}

ForecastLineChartOptions.propTypes = {
  currentDrilldownViewEntry: PropTypes.object,
  currentChartView: PropTypes.string,
  handleChartViewChange: PropTypes.func,
  onAxisGranularityChange: PropTypes.func,
  axisGranularity: PropTypes.string,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  forecastCurrentView: PropTypes.string,
  onForecastCurrentView: PropTypes.func,
  legendItems: PropTypes.array,
  isOpenLegend: PropTypes.bool,
  toggleClickLegendButton: PropTypes.func,
  isOpenLeftPanel: PropTypes.bool,
  onUpdateLegendWidth: PropTypes.func
}

export default ForecastLineChartOptions;
