import React from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import DateRangeSelector from 'pages/drilldown/components/DateRangeSelector';
import ComparisonModeDateRangeSelector from 'pages/drilldown/components/ComparisonModeDateRangeSelector';

import { DateRangeHandlerWrapper } from 'common/components/Hoc/DateRangeHandlerWrapper';
import { enableCollectionLevelDateFilters } from 'common/config/customerConfiguration';

function SecondaryHeader({ onDateFilterChange, onComparisonTypeChange,
  onChangeComparisonModeOn }) {

  const commonFilters = useSelector(state => _.get(state, 'commonFilters', {}));
  const currentDrilldownTemplateId = useSelector(state => _.get(state,
    'drilldown.currentDrilldownTemplateId'));
  const globalFilters = useSelector(state => _.get(state, 'commonFilters.globalFilters', []));

  const renderDateRangeSelector = () => {
    const currentDateRangeOptions = _.pick(commonFilters, 'dateRange', 'dateType', 'relativeDateFilterEntry');
    return (
      <div className='date-filter'>
        <DateRangeSelector
          key="currentDateRange"
          filterName=""
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          dateRangeOptions={currentDateRangeOptions}
          globalFilters={globalFilters}
          onDateFilterChange={(options) => onDateFilterChange({...options,
             isDashboardView: true}, 'currentDateRange')}
        />
      </div>
    )
  }

  if (enableCollectionLevelDateFilters()) {
    return null;
  }

  return (
    <div className='secondary-head'>
      <div className="secondary-head-filter">
        {renderDateRangeSelector()}
        <div className="comparison-period-filter">
          <ComparisonModeDateRangeSelector
            onUpdateComparisonType={onComparisonTypeChange}
            onUpdateComparisonModeOn={onChangeComparisonModeOn}
            isDashboardView />
        </div>
      </div>
    </div>
  )
}

SecondaryHeader.propTypes = {
  onChangeComparisonModeOn: PropTypes.func,
  onDateFilterChange: PropTypes.func,
  onComparisonTypeChange: PropTypes.func
}

export default (DateRangeHandlerWrapper(SecondaryHeader));

