import _ from 'lodash';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import LoadingSpinner from 'common/components/LoadingSpinner';
import { createTargetEntry, deleteTargetEntry, updateTargetEntry } from 'common/api/targetEntryApi';
import { getCustomerDomainId } from 'common/config/customerConfiguration';

import TargetEntryDetails from './TargetEntryDetails';
import TargetEntryContext from "context/TargetEntryContext";
import * as commonPropTypes from 'common/propTypes';

class TargetEntryModal extends Component {
  static contextType = TargetEntryContext;
  constructor(props, context) {
    super(props, context);
    const userTargetEntry = _.get(context, 'userTargetEntry', {});

    this.setDefaultState(userTargetEntry, true);
  }

  saveTargetEntry = () => {
    this.setState({ isLoading: true });
    createTargetEntry(this.getParams()).then(response => response.json()).then((response) => {
      this.updateState(response);
    }).catch((err) => {
      this.setState({ isLoading: false });
      console.error(err);   // eslint-disable-line no-console
    });
  }

  update = () => {
    const { currentTargetEntryId } = this.state;
    this.setState({ isLoading: true });

    updateTargetEntry(currentTargetEntryId, this.getParams()).then(response => response.json()).
      then((response) => {
        this.updateState(response);
      }).catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);   // eslint-disable-line no-console
      });
  }

  onDelete = () => {
    const { currentTargetEntryId } = this.state;
    if (currentTargetEntryId) {
      this.setState({ isLoading: true });
      deleteTargetEntry(currentTargetEntryId).then(response => response.json()).then(() => {
          this.updateState({});
        }).catch((err) => {
          this.setState({ isLoading: false });
          console.error(err);   // eslint-disable-line no-console
        });
    } else {
      this.updateState({});
    }
  }

  setDefaultState = (userTargetEntry, initialize = false, isDefaultSet = false) => {
    const { viewEntry } = this.props;
    const { configuration, id } = userTargetEntry;
    const { target_entries, target_entry_type, default_target_entry } = viewEntry;
    const newTargetEntries = _.get(configuration, 'targetEntries', target_entries);

    const state = {
      isLoading: false,
      isDefaultSet,
      targetEntries: _.cloneDeep(newTargetEntries),
      currentAccordion: _.get(configuration, 'currentAccordion') || 0,
      defaultTargetEntry: _.cloneDeep(_.get(configuration, 'defaultTargetEntry', default_target_entry)),
      type: _.get(configuration, 'type', target_entry_type)
    };
    if(!isDefaultSet) {
      state['currentTargetEntryId'] = id;
    }

    if(initialize) {
      // eslint-disable-next-line
      this.state = state;
    } else {
      this.setState(state);
    }
  }

  updateState = (response, isModalClose = true, isDefaultSet = false) => {
    if(!isDefaultSet) {
      this.context.updateUserEntry(response);
    }

    this.setDefaultState(response, false, isDefaultSet);
    if (isModalClose) {
      this.props.onClose();
    }
  }

  getParams = () => {
    const { templateId, viewEntry } = this.props;
    const { targetEntries, currentAccordion, defaultTargetEntry, type } = this.state;

    return {
      configuration: { targetEntries, currentAccordion, defaultTargetEntry, type },
      template_id: templateId,
      view_id: _.get(viewEntry, 'view_id'),
      domain_name: getCustomerDomainId(),
    };
  }

  onClickAccordion = (currentAccordion) => {
    this.setState({ currentAccordion });
  }

  onDeleteClick = () => {
    this.updateState({}, false, true);
  }

  onChangeTargetEntryContent = (value, key, index) => {
    const newTargetEntries = [...this.state.targetEntries];
    _.set(newTargetEntries, [index, key], value);
    this.setState({ targetEntries: newTargetEntries });
  }

  onChangeDefaultTargetEntryContent = (value, key) => {
    const { defaultTargetEntry } = this.state;
    this.setState({
      defaultTargetEntry: { ...defaultTargetEntry, [key]: value }
    });
  }

  onSave = () => {
    const { currentTargetEntryId, isDefaultSet } = this.state;

    if(isDefaultSet) {
      this.onDelete();
    } else {
      return currentTargetEntryId ? this.update() : this.saveTargetEntry();
    }
  }

  renderTargetEntryDetails = (targetEntry, index) => {
    const { currentAccordion } = this.state;
    const show = (currentAccordion === index);

    return(
      <TargetEntryDetails
        currentAccordion={currentAccordion}
        onChange={this.onChangeTargetEntryContent}
        onClickAccordion={this.onClickAccordion}
        entry={targetEntry} showAccordion={show} keyIndex={index} key={index}
      />
    );
  }

  renderDefaultTargetEntryDetails = () => {
    const { defaultTargetEntry, currentAccordion } = this.state;
    return (
      <TargetEntryDetails
        currentAccordion={currentAccordion}
        onChange={this.onChangeDefaultTargetEntryContent}
        onClickAccordion={this.onClickAccordion}
        entry={defaultTargetEntry}
        showAccordion={currentAccordion === 'default-target-entry'}
        keyIndex='default-target-entry'
        key="default-target-entry"
        hideInput={true}
      />
    );
  }

  renderModalFooter() {
    const { viewEntry: { target_entries, default_target_entry } } = this.props;
    const { targetEntries, defaultTargetEntry } = this.state;
    const disableResetButton = _.isEqual(targetEntries, target_entries) &&
      _.isEqual(default_target_entry, defaultTargetEntry);

    return (
      <Modal.Footer>
        <label>
          Changes made here apply to your profile only and will not appear for
          <br />others in your organization.
        </label>
        <div>
          <button
            className="btn btn-link mr-2"
            disabled={disableResetButton}
            onClick={this.onDeleteClick}>
            Reset defaults
          </button>
          <button className="btn btn-primary" onClick={this.onSave}>Save</button>
        </div>
      </Modal.Footer>
    );
  }

  renderModalBody() {
    return (
      <Modal.Body>
        {_.map(this.state.targetEntries, this.renderTargetEntryDetails)}
        {this.renderDefaultTargetEntryDetails()}
      </Modal.Body>
    );
  }

  renderModalHeader() {
    const { onClose } = this.props;

    return (
      <Modal.Header>
        <Modal.Title>
          Customize your status
        </Modal.Title>
        <button className='close' onClick={onClose}><i className="icons-close"></i></button>
      </Modal.Header>
    );
  }

  render() {
    const { onClose } = this.props;

    return (
      <Modal show={true} size="lg" onHide={onClose}>
        <div className="modal-wrapper target-entry-modal">
          {this.renderModalHeader()}
          {this.renderModalBody()}
          {this.renderModalFooter()}
          <LoadingSpinner isLoading={this.state.isLoading} />
        </div>
      </Modal>
    );
  }
}

TargetEntryModal.propTypes = {
  templateId: commonPropTypes.templateIdPropTypes,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  onClose: commonPropTypes.onClosePropTypes
}

export default (TargetEntryModal);
