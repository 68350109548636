import React from 'react';
import PropTypes from 'prop-types';
import { ForgeAppBarHelpButton } from '@tylertech/forge-react';

import { 
  customReadMoreLabel,
  getSupportLink,
  showReadMoreLink,
  showQuickStartVideosLink
} from 'common/config/customerConfiguration';

export const READ_MORE_CONFIG = {
  value: 'read-more',
  label: customReadMoreLabel,
  leadingIcon: 'info_outline',
  leadingIconType: 'component',
  to: '/about',
  onlyShowPrivateApp: true,
};

export const APP_BAR_HELP_BUTTON_OPTS = [
  // {
  //   value: 'take-the-tour',
  //   label: 'Take the tour',
  //   leadingIcon: 'help_outline',
  //   leadingIconType: 'component', to: ''
  // },
  {
    value: 'tyler-community',
    label: 'Tyler Community',
    leadingIcon: 'people',
    leadingIconType: 'component',
    href: 'https://tylercommunity.tylertech.com/'
  },
  {
    value: 'tyler-support',
    label: 'Tyler Support',
    leadingIcon: 'headset_mic',
    leadingIconType: 'component',
    href: getSupportLink()
  },
  {
    value: 'tyler-release-notes',
    label: 'Release notes',
    leadingIcon: 'note_text',
    leadingIconType: 'component',
    href: 'https://support.socrata.com/hc/en-us/sections/11962324910103-Executive-Insights-Release-Notes'
  }
];

export const QUICK_START_VIDEOS_CONFIG = {
  value: 'quick-start-videos',
  label: 'Quick start videos',
  leadingIcon: 'video_library',
  leadingIconType: 'component',
  href: 'https://tyler-data-insights.wistia.com/projects/gxusj60xq1'
};

const ForgeHelpIconSelector = ({ onSelect, currentUser }) => {
  const currentEmail = _.get(currentUser, 'user.email', '');
  const isAuthenticated = !_.isEmpty(currentEmail);
  
  if(isAuthenticated && showReadMoreLink()) {
    APP_BAR_HELP_BUTTON_OPTS.splice(2, 0, READ_MORE_CONFIG);
  }

  if(showQuickStartVideosLink()) {
    APP_BAR_HELP_BUTTON_OPTS.push(QUICK_START_VIDEOS_CONFIG);
  }

  const onHelpItemSelect =  ({ detail }) => {
    const { value } = detail;
    const { href, to } = _.find(APP_BAR_HELP_BUTTON_OPTS, {value }) || {};

    if(href) {
      window.open(href,'_blank');
    } else {
      if(to) {
        onSelect(to);
      }
    }
  }

  return (
    <ForgeAppBarHelpButton
      show={true}
      slot="end"
      on-forge-menu-select={onHelpItemSelect}
      options={_.uniqBy(APP_BAR_HELP_BUTTON_OPTS, 'value')}
    />
  );
}

ForgeHelpIconSelector.propTypes = {
  onSelect: PropTypes.func,
  currentUser: PropTypes.object
}

export default ForgeHelpIconSelector;
