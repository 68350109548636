import _ from 'lodash';
import React from 'react';

import { getMargins } from 'modules/PlotlyTooltip/helper';
import { getFlyoutPosition } from './helper';
import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { getBenchmarkFilter } from 'helpers/visualizationHelper';
import { getBenchmarkBucketConfig, isValidateJson } from './distributionBenchMarkHelper';
import { showDimensionsInDistribution } from 'common/config/visualizationConfiguration';
import { formatValueToCurrency } from 'helpers/numberHelper';

import { SINGLE_VALUE } from 'appConstants';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getSuffixByVersion } from '../../helpers/chartDataHelper';

export const getDistributionChartPopupConfigs = (options) => {
  const { chartContainer, data, viewMode, viewEntry, templateId, distributionOption } = options;
  const isDiscrete = _.get(distributionOption, 'isDiscrete', false);
  const isCumulative = _.get(distributionOption, 'isCumulative', false);
  const showDimensions = showDimensionsInDistribution(viewEntry) && !(isDiscrete && isCumulative);

  const margins = getMargins(chartContainer);
  const point = data.points[0];
  const tickLabel = _.get(point, "meta.tickLabel", '');
  const title =  _.isEmpty(tickLabel) ? _.get(point, 'x') : tickLabel ;
  const xValue = point.xaxis.d2p(point.x);
  const x = xValue + margins.x;
  let totalAndPercentage = { total: 0, percentage: 0, showDimensions };
  const tableBodyContent = _.without(getTableBodyContent(options, totalAndPercentage, showDimensions), null);
  const categoryContent = getCategoryContent(
    point, title, templateId, totalAndPercentage, showDimensions, viewEntry);
  let flyoutPosition = getFlyoutPosition(data, viewMode);
  const isChangePosition =  x + 600 > window.innerWidth;
  const y =  isChangePosition ? 200 : point.yaxis.d2p(point.y) + margins.y;
  flyoutPosition = isChangePosition ? 'left' : flyoutPosition;
  return [{
    viewMode,
    position: _.isEmpty(flyoutPosition) ? 'top' : flyoutPosition,
    anchor: {
      x: x,
      y: y
    },
    chartContainer,
    html: (
      <div>
       {categoryContent}
        <table className="flyout-table">
          <tbody>
            {!_.isEmpty(tableBodyContent) ? tableBodyContent : getNoDataContent()}
          </tbody>
        </table>
      </div>
    )
  }];
};

const getNoDataContent = () => {
  return (<tr>
      <td><div className="line-chart-color"></div></td>
      <td className="line-chart-category no-data-content">No data fits this category</td>
      <td><div className="total-and-percentage"></div></td>
    </tr>
  );
}

const getTableBodyContent = (options, totalAndPercentage, showDimensions) => {
  const { data, isCurrencyDimensionField } = options;
  const flyoutContents = [];

  return _.map(data.points, (point, index) => {
    const defaultColor = _.get(point, 'data.marker.color', '#fff');
    const color = _.get(point, 'marker.color', defaultColor);
    const percentageValue = _.get(point, 'meta.percentage', 0);
    const value = _.get(point, 'value');
    const dimensionValue = showDimensions ? _.get(point, 'meta.dimension', '') : point.text;
    let renderFlyoutContent;

    if (!point.text || point.y === " " || value < 1) {
      renderFlyoutContent = null;
    } else {
      if (showDimensions) {
        totalAndPercentage['total'] = totalAndPercentage['total'] + Number(point.y);
        totalAndPercentage['percentage'] = _.round(
          totalAndPercentage['percentage'] + Number(percentageValue), 2);
      }

      renderFlyoutContent = (
        <tr key={index}>
          <td><div style={{ backgroundColor: color }} className="line-chart-color"></div></td>
          <td>
            <div className="line-chart-category">
              {formatValueToCurrency(dimensionValue, isCurrencyDimensionField)}
            </div>
          </td>
          {showDimensions && <td>
            <div className="total-and-percentage">{point.text}</div></td>
          }
        </tr>
      );
    }
    flyoutContents.push(renderFlyoutContent);
    const isEmptyFlyoutContents = _.isEmpty(_.compact(flyoutContents));
    const renderBenchMarkContent = showDimensions && data.points.length - 1 > index ?
      null :
      getBenchMarksContent(options, point, totalAndPercentage);

    return !_.isEmpty(renderBenchMarkContent) && !isEmptyFlyoutContents ?
      [renderFlyoutContent, renderBenchMarkContent] :
      renderFlyoutContent;
  });
}

const getCategoryContent = (point, title, templateId, totalAndPercentage, showDimensions, viewEntry) => {
  const { total, percentage } = totalAndPercentage;
  const viewEntryClone = _.cloneDeep(viewEntry);
  const displayFormatEntry =  _.omit(viewEntryClone, ['precision']);
  const formattedTotalValue = getFormattedNumberValue(
    total,
    displayFormatEntry,
    false,
    false,
    true,
    false,
    true
  );
  const customdata = _.get(point, 'customdata', []);
  const nullValueLabel = getNullValueLabel(templateId);
  if(_.isEmpty(customdata)) {
    return (
      <div className="bar-chart-y-value pb-1 mb-2 border-bottom text-nowrap">
        <b><span>{title}</span>
          {showDimensions &&
            <span className="float-right">{formattedTotalValue} ({_.round(percentage, 2)}%)</span>
          }
        </b>
      </div>
    );
  } else {
    return _.map(customdata, (data, index) => {
      return (
        <div key={index} className="bar-chart-y-value pb-1 mb-2 border-bottom text-nowrap">
          <b>{data || nullValueLabel}</b>
        </div>
      );
    });
  }
}

const getBenchMarksContent = (options, point, totalAndPercentage) => {
  const { viewEntry, benchmarkMetricNames, quickFilters } = options;
  const benchmarkEntries = _.get(viewEntry, 'visualization.distribution.bench_mark_entries', []);

  if (point.y === " ") { return null; }

  return _.chain(benchmarkMetricNames).
    map((benchmarkName, benchmarkIndex) => {
      return getBenchMarkContent({
        benchmarkName, point, benchmarkEntries, benchmarkIndex, quickFilters, totalAndPercentage
      });
    }).
    without(null).
    value();
}

const getBenchMarkContent = ({
  benchmarkName,
  point,
  benchmarkEntries,
  benchmarkIndex,
  quickFilters,
  totalAndPercentage
}) => {
  const benchmarkEntry = _.find(benchmarkEntries, { name: benchmarkName });
  const onlyForMetricDefaults = JSON.parse(_.get(benchmarkEntry, 'only_for_metric_defaults', 'false'));
  const filterColumn = _.get(benchmarkEntry, 'filter_column');
  const filterValue = _.get(benchmarkEntry, 'filter_value');
  const currentFilter = getBenchmarkFilter(benchmarkEntry, quickFilters);

  const benchmarkHideCondition = filterColumn && filterValue ?
    _.isEmpty(currentFilter) :
    onlyForMetricDefaults && !_.isEmpty(quickFilters);

  if (_.isEmpty(benchmarkEntry) || benchmarkHideCondition) {
    return null;
  }
  const { bucket_label } = benchmarkEntry;
  const { aggregateType } = getBenchmarkBucketConfig(benchmarkEntry, point, totalAndPercentage);
  const pointX = _.get(point, 'x');
  const chartType = _.get(point, 'data.customData[1]');
  const isSameChartType = aggregateType === chartType;

  // bucket_label is empty string we show benchmark value to all buckets.
  if (_.isEmpty(bucket_label) && isSameChartType) {
    return getSelectedBenchMarkContent(benchmarkEntry, point, benchmarkIndex, totalAndPercentage);
  } else if (pointX === 'Others' && bucket_label === 'other_bucket' && isSameChartType) {
    return getSelectedBenchMarkContent(benchmarkEntry, point, benchmarkIndex, totalAndPercentage);
  } else {
    const bucket = isValidateJson(bucket_label) ? JSON.parse(bucket_label) : {};
    const formattedBucketLabel = `${bucket.from} - ${bucket.to}`;
    if (pointX === formattedBucketLabel && isSameChartType) {
      return getSelectedBenchMarkContent(benchmarkEntry, point, benchmarkIndex, totalAndPercentage);
    } else {
      return null;
    }
  }
}

const getSelectedBenchMarkContent = (benchmarkEntry, point, benchmarkIndex, totalAndPercentage) => {
  const { name } = benchmarkEntry;
  const { color, value } = getBenchmarkBucketConfig(benchmarkEntry, point, totalAndPercentage);
  const suffix = getSuffixByVersion(benchmarkEntry, (Number(value) === SINGLE_VALUE));

  return (
    <tr key={`benchmark-${benchmarkIndex}`}>
      <td colSpan="2"><div style={{ color: color }}>{name} {value} {suffix}</div></td>
    </tr>
  );
}
