import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';

import { DRAGGABLE } from 'appConstants';
import { childrenPropTypes } from 'common/propTypes';
import { isRestrictedCollection } from '../ManageCollection/collaboratorHelper';

function CardDrop({
  userCardEntry,
  viewEntry,
  templateEntry,
  onCardMove,
  insertAfter,
  children,
  collectionEntry
}) {
  const [{ isHoveredOver, canDrop }, drop] = useDrop({
    accept: [DRAGGABLE.TYPES.CARD],
    drop: (props, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      //TODO: only userCardEntry is needed
      const targetItem = { userCardEntry, viewEntry, templateEntry };
      const draggedItem = monitor.getItem();
      if (!_.isEmpty(targetItem)) {
        onCardMove({
          targetCard: targetItem,
          draggedCard: draggedItem,
          insertAfter,
        });
      }
    },
    collect: (monitor) => ({
      isHoveredOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isRestricted = isRestrictedCollection(collectionEntry);
  const dropClassNames = isRestricted ? '' : classNames(
    'card-drop',
    insertAfter ? 'insert-after' : 'insert-before',
    {
      'is-hovered-over': isHoveredOver,
      'can-drop': canDrop
    }
  );

  return (
    <div ref={drop} className={dropClassNames}>
      {children}
    </div>
  );
}

CardDrop.propTypes = {
  userCardEntry: PropTypes.object,
  viewEntry: PropTypes.object,
  templateEntry: PropTypes.object,
  collectionEntry: PropTypes.object,
  onCardMove: PropTypes.func,
  insertAfter: PropTypes.bool,
  children: childrenPropTypes,
};

export default CardDrop;
