import React,{ Component } from "react";
import $ from 'jquery';

import GlobalEvent from 'common/components/GlobalEvents';
import { SCROLL_TYPES, SCROLL_EVENT } from "modules/visualization/constants";

const WAIT_TIME = 1000;

export const PageScrollHandlerWrapper = (SourceComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this._scrollTimer = null;
      this._isScrolling = false;
    }

    componentDidMount() {
      window.addEventListener('wheel', this.onScroll);
    }

    componentWillUnmount() {
      window.removeEventListener('wheel', this.onScroll);
      clearTimeout(this._scrollTimer);
    }

    onScroll = (event) => {
      clearTimeout(this._scrollTimer);

      if(!this._isScrolling) {
        const isMapContainer = $(event.target).closest('.map-instance').length > 0;
        if(!isMapContainer) {
          this._isScrolling = true;
          GlobalEvent.emit(SCROLL_EVENT, SCROLL_TYPES.START);
        }
      }

      // Set a timeout to run after scrolling ends
      this._scrollTimer = setTimeout(() => {
        this._isScrolling = false;
        GlobalEvent.emit(SCROLL_EVENT, SCROLL_TYPES.END);
      }, WAIT_TIME);
    }

    render() {
      return ( <SourceComponent {...this.props} />);
    }
  }
}
