import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OPACITY_MATCHER_ENTRY } from 'appConstants';
import {
  getCurrentFilterDateRange,
  getComparisonPeriodDateRanges
} from 'helpers/dateHelper';
import { ForgeButton } from '@tylertech/forge-react';

export default class ComparisonControl extends Component {

  renderComparisonInProgressOverlay() {
    const { sliderPositionPercentage, commonFilters } = this.props;
    const { dateRange } = commonFilters;

    const roundedPercentageString = (Math.ceil(sliderPositionPercentage / 10) * 10) + '';
    const opacityEntry = _.get(OPACITY_MATCHER_ENTRY, roundedPercentageString);
    const currentDataRange = getCurrentFilterDateRange(dateRange, 'll');
    const previousDateRange = getCurrentFilterDateRange(
      getComparisonPeriodDateRanges(commonFilters)[0],
      'll'
    );

    $(document).find('.left-section').fadeTo('fast', _.get(opacityEntry, 'left-label'));
    $(document).find('.right-section').fadeTo('fast', _.get(opacityEntry, 'right-label'));

    return (
      <div className="map-custom-control-overlay">
        <div className="d-flex">
          <div className="left-section ml-4">
            <span className="d-block">Comparison period</span>
            <span className="d-block">{previousDateRange.startDate} - {previousDateRange.endDate}</span>
          </div>
          <div className="right-section mr-4">
            <span className="d-block">Current period</span>
            <span className="d-block">{currentDataRange.startDate} - {currentDataRange.endDate}</span>
          </div>
          <div className="action-btn">
            <Button
              className="close-btn align-self-center"
              variant="primary"
              size="lg"
              onClick={() => this.props.onChange(false)}>
              <span className="icons-close"></span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderComparisonButton() {
    return (
      <OverlayTrigger
        key="tactic-button"
        placement="left"
        overlay={
          <Tooltip id="tooltip-top" className="compstat-tooltip">
            <div className="text-left">Click to compare two time periods on the map.</div>
          </Tooltip>
        }>
        <ForgeButton type="outlined" className="map-forge-btn">
          <button
            className="view-watch-btn"
            onClick={() => this.props.onChange(true)}>
            <i className="icons-compare" />
            <span>Compare</span>
          </button>
        </ForgeButton>
      </OverlayTrigger>
    );
  }

  render() {
    const { isComparisonEnabled } = this.props;
    if (!isComparisonEnabled) {
      return null;
    }
    return (
      <div className="map-custom-control map-comparison-control">
        {/* { isComparisonEnabled ? null : this.renderComparisonButton() } */}
        {isComparisonEnabled ? this.renderComparisonInProgressOverlay() : null}
      </div>
    );
  }
}

ComparisonControl.propTypes = {
  isComparisonEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  sliderPositionPercentage: PropTypes.number,
  commonFilters: PropTypes.object
};
