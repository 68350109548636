import { updateCurrentDistributionOption } from "actions/distributionActions";
import { updateCenterAndZoom } from "actions/mapOptionsActions";
import { VISUALIZATION_TYPES } from "appConstants";
import { getTemplateEntries } from "common/config/customerConfiguration";
import { getMapCenter, getMapzoom } from "common/config/templateConfiguration";
import { getDefaultVisualizationView } from "common/config/visualizationConfiguration";
import { getCollectionId } from "helpers/collectionIdHelper";
import { getDefaultDistributionOption } from "helpers/distributionHelper";
import { getAvailableVisualizationType } from "helpers/visualizationHelper";
import { getVisibleBookmarks } from "pages/dashboard/components/CardList/cardHelper";
import { getDrilldownEntryParams } from "pages/dashboard/components/TemplateCards/TemplateCard";
import { getCardId, getCollectionCardEntries } from "pages/embed/helper";
import { getPermittedCollection } from 'pages/dashboard/components/Collections/collectionHelper';

export const dispatchMapCoordinates = (entry, dispatch) => {
  const { template_id } = entry;
  const center = getMapCenter(template_id);
  const zoom = getMapzoom(template_id);

  dispatch(updateCenterAndZoom({ center, zoom }));
}

export const dispatchUpdateCurrentDistributionOption = (templateEntry, viewEntry, dispatch) => {
  const {
    isCumulativeDistribution,
    isDiscrete,
  } = getDefaultDistributionOption(viewEntry);
  const defaultVizView = getDefaultVisualizationView(viewEntry);
  const templateId = templateEntry.template_id;
  const availableVizType = getAvailableVisualizationType(
    templateId,
    defaultVizView,
    viewEntry
  );
  if (availableVizType === VISUALIZATION_TYPES.DISTRIBUTION.type) {
    dispatch(
      updateCurrentDistributionOption(isCumulativeDistribution, isDiscrete)
    );
  }
}

export const modifyCollectionWiseMetricList = (collectionsWiseMetricList, commonFilters, globalFilters) => {
  const collectionUniqCardIds = [];
  const collectionCards = [];
  const bookmarkCards = [];
  const otherCards = [];
  const allMetricCards = [];
  const templateEntries = getTemplateEntries();
  const permittedCollections = getPermittedCollection(collectionsWiseMetricList);

  const collectionsWithMetricList = _.filter(permittedCollections, (collection) => {
    return _.get(collection, 'cards', []).length > 0 && _.trim(collection.name) !== 'My Views';
  });
  const myViewsCollection = _.first(_.filter(permittedCollections, (collection) => {
    return _.get(collection, 'cards', []).length > 0 && _.trim(collection.name) === 'My Views';
  }));

  const processBookmarks = (bookmarkCollection) => {
    const visibleBookmarks = getVisibleBookmarks(bookmarkCollection?.cards);
    visibleBookmarks?.forEach(card => {
      if (!card.is_radar && !card.is_forecast && card.email_strategy == "never") {
        const currentDrilldownTemplateId = card.drilldown.currentDrilldownTemplateId || '';
        const currentDrilldownViewId = card.drilldown.currentDrilldownViewEntry?.view_id || '';
        const cardId = `${currentDrilldownTemplateId}${currentDrilldownViewId}`;
        const currentCollectionId = getCollectionId(permittedCollections, cardId);
        bookmarkCards.push(
          {...card, cardId, isBookmarkCard: true, collectionName: 'My Views', currentCollectionId}
        );
      }
    });
  };

  const processCollectionsWithMetricList = (collection) => {
    const currentCollectionCards = _.get(collection, 'cards', []);
    const modifiedCollectionCards = currentCollectionCards.filter(card => _.isNull(card.bookmark_id));
    const bookmarkCardsInCollection = currentCollectionCards.filter(card => !_.isNull(card.bookmark_id));
    const bookmarkCards = _.get(myViewsCollection, 'cards', []);

    modifiedCollectionCards.forEach((card) => {
      const cardId = card['card_id'];
      collectionUniqCardIds.push(cardId);
      const { templateEntry, viewEntry } = getCollectionCardEntries(cardId, templateEntries);
      if (_.isUndefined(templateEntry) || _.isUndefined(viewEntry)) {
        return null;
      }
      const newCollection = {
        templateEntry,
        viewEntry,
        cardId,
        drilldownEntry: getDrilldownEntryParams(templateEntry, viewEntry),
        commonFilters,
        globalFilters,
        isNewCard: false,
        userCollectionsWithCards: permittedCollections,
        currentCollectionId: collection.id || '',
        currentCollectionTagId: collection.bellerophon_tag_id || '',
        isMetricCardClick: true,
        name: _.get(viewEntry, 'name'),
        collectionName: _.get(collection, 'name', '')
      };
      collectionCards.push(newCollection);
    });


    if(!_.isEmpty(bookmarkCards)){
      bookmarkCardsInCollection.forEach((card) => {
        const cardConfig = _.find(bookmarkCards, (bookmarkCard) => bookmarkCard.id == card.card_id);
        const drilldown = _.get(cardConfig, 'drilldown', {});
        const template_id = _.get(drilldown, 'currentDrilldownTemplateId', '');
        const view_id = _.get(drilldown, ['currentDrilldownViewEntry', 'view_id'], '');
        const cardId = `${template_id}${view_id}`

        collectionCards.push({
          ...cardConfig,
          isBookmarkCard: true,
          collectionName: _.get(collection, 'name', ''),
          cardId,
          currentCollectionId: getCollectionId(permittedCollections, cardId)
        });
      });
    }
  };

  const processTemplateEntries = () => {
    const templateAndViewIdEntry = {};
    const templateAndCardIds = [];

    templateEntries.forEach((templateEntry) => {
      templateEntry['view_entries'].forEach((viewEntry) => {
        const id = getCardId(templateEntry, viewEntry);
        templateAndViewIdEntry[id] = { templateEntry, viewEntry };
        templateAndCardIds.push(id);
      });
    });

    templateAndCardIds.forEach((cardId) => {
      if (!_.includes(_.compact(collectionUniqCardIds), cardId)) {
        const { templateEntry, viewEntry } = templateAndViewIdEntry[cardId];
        const otherCard = {
          templateEntry,
          viewEntry,
          cardId,
          drilldownEntry: getDrilldownEntryParams(templateEntry, viewEntry),
          commonFilters,
          globalFilters,
          isNewCard: false,
          userCollectionsWithCards: permittedCollections,
          currentCollectionId: 'allMetrics',
          currentCollectionTagId: 'allMetrics',
          isMetricCardClick: true,
          name: _.get(viewEntry, 'name'),
          collectionName: "Other"
        };
        otherCards.push(otherCard);
      }
    });
  };

  const processGroupedCards = () => {
    const groupedCards = _.groupBy([...bookmarkCards, ...collectionCards, ...otherCards], 'name');
    _.values(groupedCards).forEach(cards => {
      const collectionNames = cards.map(card => card.collectionName);
      cards[0]['collectionNames'] = _.uniq(collectionNames);
      cards[0]['label'] = cards[0]['name'];
      cards[0]['value'] = cards[0]['name'];
      allMetricCards.push(cards[0]);
    });
  };

  processBookmarks(myViewsCollection);
  collectionsWithMetricList.forEach(processCollectionsWithMetricList);
  processTemplateEntries();
  processGroupedCards();

  return allMetricCards;
};
