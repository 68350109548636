// Vendor imports
import React from 'react';
// Project Imports
import { getAttribution } from 'common/config/templateConfiguration';
import { templateIdPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';

const AttributionDescription = (props) => {
  const { templateId } = props;
  const { logo,text  } = getAttribution(templateId);

  if (_.isEmpty(logo) && _.isEmpty(text)){
    return null;
  }

  if(_.isEmpty(logo) && props.isVisualization ){
    return (
      <span dangerouslySetInnerHTML={{__html: text}} ></span>
    );
  }

  return (
    <div className="attribution-footer d-flex align-items-center">
      { logo && <div className="data-provider-logo mr-3">
        <img alt="DataProvider" src={logo}></img>
      </div> }
      <div>
        <div
          className="data-provider-info text-truncate forge-typography--caption line-clamp"
          dangerouslySetInnerHTML={{__html: text}}>
        </div>
      </div>
    </div>
  );
};

AttributionDescription.propTypes = {
  templateId: templateIdPropTypes,
  isVisualization: PropTypes.bool
}

export default AttributionDescription;
