import moment from 'moment';
import {
  isScheduleDate,
  getWeekdaysToNumber,
  isStartDateCrossed
} from 'helpers/frequencyDateTimeHelper';
const TOTAL_DAYS_IN_A_WEEK = 7;
export const getWeeklyDateTime = (scheduleOptions, scheduledDateTime) => {
  const orderedSelectedDays = getWeekdaysToNumber(scheduleOptions);
  const today = moment().isoWeekday();
  const todayWithUpcomingDays = _.filter(orderedSelectedDays, function(day){
    return day >= today;
  });
  const frequencyInterval = Number(scheduleOptions['frequencyInterval']);

  if(isStartDateCrossed(scheduleOptions, scheduledDateTime)){
    if (todayWithUpcomingDays.length == 0){
      const daysToBeAdded = nextWeekFirstSelectedDay(today, orderedSelectedDays[0]),
          calculateDays = getCalculateDays(daysToBeAdded, frequencyInterval);
      return moment(scheduledDateTime).add(calculateDays, 'days').utc().format();
    }else{
      return getScheduledDate(today, orderedSelectedDays, scheduledDateTime, frequencyInterval);
    }
  }else{
    return get_start_date_calculated_email_date(scheduleOptions);
  }
}

function get_start_date_calculated_email_date(scheduleOptions){
  const orderedSelectedDays = getWeekdaysToNumber(scheduleOptions);
  const today = moment().isoWeekday();
  const todayWithUpcomingDays = _.filter(orderedSelectedDays, function(day){
    return day >= today;
  });
  const startDate = moment(scheduleOptions['startDate']).format('YYYY-MM-DD');
  let emailSendingDay = moment(startDate);
  const startDateDay = moment(startDate).isoWeekday();
  const frequencyInterval = Number(scheduleOptions['frequencyInterval']);

  if (todayWithUpcomingDays.length == 0){
    emailSendingDay = get_next_day(startDate, orderedSelectedDays[0]);
    _.each(orderedSelectedDays, (ordered_selected_day) => {
      const next_email_day = get_next_day(startDate, ordered_selected_day)
      if (dateIsAfterStartDate(next_email_day, startDate)){
        emailSendingDay = next_email_day;
        return false;
      }
    });
  }else{
    _.each(todayWithUpcomingDays, function(todayWithUpcomingDay){
      if (startDateDay >= todayWithUpcomingDay){return true;}
      const next_email_day = get_next_day(startDate, todayWithUpcomingDay);
      if (dateIsAfterStartDate(next_email_day, startDate)){
        emailSendingDay = next_email_day;
        return false;
      }
    })
  }

  if(frequencyInterval == 1){
    if(todayWithUpcomingDays.length == 0){
      return get_next_day(startDate, orderedSelectedDays[0], true);
    }else{
      return upcomingDaysOrNextWeek(startDateDay, orderedSelectedDays, startDate)
    }
  }else{
    emailSendingDay =  upcomingDaysOrNextWeek(startDateDay, orderedSelectedDays, startDate)
    if(isStartDateCrossed(scheduleOptions, emailSendingDay)){
      // const emailSendingDayAsDay = moment(emailSendingDay).isoWeekday();
      const calculateDays = (TOTAL_DAYS_IN_A_WEEK *  (frequencyInterval - 1));
      return moment(emailSendingDay).add(calculateDays, 'days');
      // if(startDateDay === emailSendingDayAsDay){
      //   const calculateDays = (TOTAL_DAYS_IN_A_WEEK *  (frequencyInterval - 1));
      //   return moment(emailSendingDay).add(calculateDays, 'days');
      // }else{
      //   return emailSendingDay;
      // }
    }
  }
}

function dateIsAfterStartDate(emailDate, startDate){
  const formattedToday = emailDate.format('YYYY-MM-DD');
  const formattedDate = moment(startDate).format('YYYY-MM-DD');
  return moment(formattedToday).isAfter(formattedDate);
}

function get_next_day(date, day_of_week, skipStartDate = false){
  const today = moment();
  const formattedToday = today.format('YYYY-MM-DD');
  const formattedDate = moment(date).format('YYYY-MM-DD');
  date = (moment(formattedToday).isAfter(formattedDate) || skipStartDate) ? moment(date) : today;
  const dateDiff = (day_of_week - date.isoWeekday());
  let daysToBeAdded = 0;
  if(dateDiff <= 0){
    daysToBeAdded = dateDiff + TOTAL_DAYS_IN_A_WEEK;
  }else{
    daysToBeAdded = ((day_of_week - date.isoWeekday()) % TOTAL_DAYS_IN_A_WEEK)
  }

  return moment(date).add(daysToBeAdded, 'days');
}

function getScheduledDate (today, selectedDays, scheduledDateTime, frequencyInterval) {
  const todayWithUpcomingDays = _.filter(selectedDays, function(day){
    return day >= today;
  }), scheduleDay = todayWithUpcomingDays[0];

  if (today == scheduleDay){
    return scheduleDateIfSameDay(today, selectedDays, scheduledDateTime);
  }else{
    return scheduleDateIfOtherDays(today, scheduleDay, scheduledDateTime, frequencyInterval);
  }
}

function scheduleDateIfSameDay (today, selectedDays, scheduledDateTime) {
  if (isScheduleDate(scheduledDateTime)){
    return scheduledDateTime;
  }else{
    return upcomingDaysOrNextWeek(today, selectedDays, scheduledDateTime);
  }
}

function scheduleDateIfOtherDays (today, selectedDay, scheduledDateTime, frequencyInterval) {
  let daysToBeAdded = 0;
  if (today < selectedDay){
    daysToBeAdded = selectedDay - today
  }else{
    daysToBeAdded = nextWeekFirstSelectedDay(today, selectedDay)
  }
  const calculateDays = getCalculateDays(daysToBeAdded, frequencyInterval);

  return moment(scheduledDateTime).add(calculateDays, 'days').startOf('day').format();
}

function getCalculateDays (daysToBeAdded, frequencyInterval){
  if (frequencyInterval == 1){
    return daysToBeAdded;
  }else{
    return (daysToBeAdded + ( TOTAL_DAYS_IN_A_WEEK * (frequencyInterval - 1)));
  }
}

function upcomingDaysOrNextWeek (today, selectedDays, scheduledDateTime) {
  const nextSelectedDays = _.filter(selectedDays, function(day){
    return day > today;
  }); let daysToBeAdded = 0;

  if (nextSelectedDays.length > 0){
    daysToBeAdded = nextSelectedDays[0] - today;
    return moment(scheduledDateTime).add(daysToBeAdded, 'days').startOf('day').format();
  }else{
    daysToBeAdded = nextWeekFirstSelectedDay(today, selectedDays[0])
    return moment(scheduledDateTime).add(daysToBeAdded, 'days').startOf('day').format();
  }
}

function nextWeekFirstSelectedDay (today, selectedDay) {
  return (TOTAL_DAYS_IN_A_WEEK - today + selectedDay)
}
