import React from 'react';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import PropTypes from 'prop-types';

const LeafPageButton = (props) => {
  const { ariaLabel, disabled, onClick, iconName, className } = props;

  return(
    <ForgeIconButton dense="true">
      <button tabIndex={0} type="button"
        aria-label={ariaLabel} disabled={disabled}
        onClick={() => onClick(ariaLabel)}
      >
        <ForgeIcon name={iconName} className={className} />
      </button>
    </ForgeIconButton>
  )

}

LeafPageButton.propTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
}

LeafPageButton.defaultProps = {
  className: '',
  ariaLabel: '',
  disabled: false,
  iconName: '',
  onClick: _.noop,
}

export default LeafPageButton;
