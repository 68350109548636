import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeIconButton, ForgeMenu } from '@tylertech/forge-react';

import LoadingSpinner from 'common/components/LoadingSpinner';
import { getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
import SaveContainer from './SaveContainer';
import DeleteBookmarkDialog from './DeleteBookmarkDialog';
import { getBookMarks } from 'common/api/bookmarksApi';
import { updateAdvanceSearchOptions, updatePolygonGeojson } from 'actions/advanceSearchActions';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import { setCurrentCollectionId } from 'actions/dashboardActions';
import CustomDropdownList from 'common/components/SolutionDropDown/CustomDropdownList';
import { getPolygonGeoJson } from 'reducers/advanceSearchReducer';
const BookmarkList = ({ onCreateBookmark }) => {
  const filterPopupRef = useRef();
  const dropdownPopupRef = useRef();

  const dispatch = useDispatch();
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));
  const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));
  const advanceSearchOptions = useSelector(store => _.get(store, 'advanceSearch'));
  const polygonsGeojson = useSelector(store => _.get(store, 'visualization.mapOptions.filteredGeojson', {}));
  const currentBookmarkId = useSelector(store => _.get(store, 'bookmark.currentBookmarkId',''));

  let templateEntry = getCurrentTemplateEntryById(templateId);
  const [bookmarks, setBookmarks] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [openSaveContainer, setOpenSaveContainer] = useState(false);
  const [editBookmarkDetail, setEditBookmarkDetail] = useState();
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookmarkMenuType, setBookmarkMenuType] = useState('');

  useEffect(() => {
    templateEntry = getCurrentTemplateEntryById(templateId);
  }, [templateId]);

  useEffect(() => {
    fetchBookMarks();
  }, []);

  useEffect(() => {
    updateDropdownOptions(dropdownOptions);
  }, [searchFilters, selectedShapeIds]);

  const updateDropdownOptions = (bookmarks) => {
    setDropdownOptions(bookmarks);
  }

  const fetchBookMarks = async () => {
    getBookMarks()
      .then((response) => response.json())
      .then((bookmarks) => {
        const response = _.filter(bookmarks, (bookmark) => {
          return bookmark['is_custom_advance_search'];
        });
        setBookmarks(response);
        const options = _.map(response, (bookmark) => {
          const description = _.get(bookmark, 'bookmarkOptions.description');
          return { name: bookmark['name'], value: `${bookmark['id']}`, description: description }
        });
        options.unshift({leadingIcon:'plus_thick', name:'Save current filters', value:'new'})
        updateDropdownOptions(options);
      });
  }

  const handleUpdateCurrentCollectionId = (myViewCollectionId) => {
    dispatch(setCurrentCollectionId(myViewCollectionId));
  }

  const handleBookmarkChange = (currentBookmark) => {
    const matchedBookmark = _.find(bookmarks,
      (bookmark) => _.toString(bookmark['id']) === _.toString(currentBookmark['id']));
    if (_.isEmpty(matchedBookmark)) {
      dispatch(updateCurrentBookmark(currentBookmark['id']));
      bookmarks.push(currentBookmark);
      setBookmarks(bookmarks);
      const options = _.map(bookmarks, (bookmark) => {
        const description = _.get(bookmark, 'bookmarkOptions.description');
        return { 
          name: bookmark['name'], 
          value: `${bookmark['id']}`, 
          description: description
        }
      });
      setDropdownOptions(options);
    } else {
      const newBookmarks = _.filter(bookmarks, (bookmark) =>
        _.toString(bookmark['id']) != _.toString(currentBookmark['id']));
      const newDropdownOptions = _.filter(dropdownOptions, (option) =>
        _.toString(option['value']) != _.toString(currentBookmark['id']));
      newBookmarks.push(currentBookmark);
      setBookmarks(newBookmarks);
      const description = _.get(currentBookmark, 'bookmarkOptions.description');
      newDropdownOptions.push({
        name: currentBookmark['name'], 
        value: `${currentBookmark['id']}`,
        description: description
      });
      setDropdownOptions(newDropdownOptions);
    }
  }

  const handleDropdownChange = (option) => {
    const BookmarkId = _.isObject(option) ? _.get(option, 'value'): option;
    if (BookmarkId == 'new') {
      setEditBookmarkDetail('');
      setTimeout(() => {
        setOpenSaveContainer(true);
      }, 100);

    } else {
      dispatch(updateCurrentBookmark(BookmarkId));
      const bookmark = _.find(bookmarks, { id: JSON.parse(BookmarkId) });
      const advanceSearchOptions = _.get(bookmark, 'advanceSearchOptions');
      const includeSelectedReportRowIds = _.get(bookmark, 'advanceSearchOptions.include_search');
      const selectedReportRowIds = _.get(bookmark, 'advanceSearchOptions.selectedReportRowIds');
      const updatedReportRowIds = includeSelectedReportRowIds ? selectedReportRowIds : []
      dispatch(updateAdvanceSearchOptions({
        ...advanceSearchOptions,
        selectedReportRowIds: updatedReportRowIds,
        bookmarkId: _.get(bookmark, 'id'),
      }));
      dispatch(
          updatePolygonGeojson(getPolygonGeoJson(advanceSearchOptions))
      );
    }
  };

  const onDeleteBookmark = () => {
    dispatch(updateCurrentBookmark(''));
    setDropdownOptions(_.filter(dropdownOptions, (option) => option.value !=  currentBookmarkId));
  };

  const handleBookmarkDelete = () => {
    setDeletePopUp(true);
  };

  const HandelEditBookmark = (isUpdateAllFilters = false) => {
    let bookmarkDetail = _.cloneDeep(_.find(bookmarks, (bookmark) =>
      _.toString(bookmark.id) === _.toString(currentBookmarkId)));

    if(isUpdateAllFilters){
      const saveAdvanceSearchOption =  {
        ...advanceSearchOptions,
        polygonsGeojson: JSON.stringify(polygonsGeojson)
      }
      let updateAdvanceSearchOptions ={...saveAdvanceSearchOption };
      updateAdvanceSearchOptions['selectedReportRowIds'] = [];
      updateAdvanceSearchOptions['reportPageData'] = [];
      bookmarkDetail['advanceSearchOptions'] = updateAdvanceSearchOptions;
    }

    setEditBookmarkDetail(bookmarkDetail);
    setOpenSaveContainer(true);
  };

  const isChangeFilters = () => {
    const selectBookmarkDetail = _.find(bookmarks, (bookmark) =>
      _.toString(bookmark.id) === _.toString(currentBookmarkId));

     if(_.isEmpty(selectBookmarkDetail)){
      return false;
     }

    const bookmarkFilter  = selectBookmarkDetail['advanceSearchOptions'];
    const polygonsFilter = JSON.stringify(polygonsGeojson)
    const isChangeBookmark =  (
      !_.isEqual(_.get(bookmarkFilter, 'filters', []), _.get(advanceSearchOptions, 'filters', [])) ||
      !_.isEqual(_.get(bookmarkFilter, 'polygonsGeojson', ''), polygonsFilter) ||
      !_.isEqual(_.get(bookmarkFilter, 'selectedShapeIds', []),
        _.get(advanceSearchOptions, 'selectedShapeIds', []))
      )
    return isChangeBookmark;
  }

  const isChangeValues = isChangeFilters();

  const onMenuIconClick = ({detail}) => {
    const { value } = detail;
    if (value == 'delete') {
      handleBookmarkDelete();
    } else if(value == 'edit') {
      HandelEditBookmark(true);
    } else if(value == 'rename') {
      HandelEditBookmark();
    } else if (value == 'reset'){
      dispatch(updateCurrentBookmark(''));
      handleDropdownChange({value: currentBookmarkId });
    }
    setBookmarkMenuType(value);
  }

  const renderMenus = () => {
    if(_.isEmpty(currentBookmarkId)){
      return null;
    }

    const options = [
      { value: 'edit', label: 'Save changes', disabled: !isChangeValues },
      { value: 'reset', label: 'Discard changes', disabled: !isChangeValues },
      { value: 'rename', label: 'Rename' },
      { value: 'delete', label: 'Delete' }
    ];


    return (
      <div className="info-sections align-self-start ml-1">
        <ForgeMenu
          placement='bottom-start'
          options={options}
          on-forge-menu-select={(e) => onMenuIconClick(e)}>
            <ForgeIconButton  type="button">
              <button type="button">
                <ForgeIcon name='more_vert' className="more-icon" />
              </button>
            </ForgeIconButton>
        </ForgeMenu>
      </div>
    );
  }

  const renderBookmarkDropdown = () => {
    const selectBookmark  = _.find(bookmarks, (bookmark) =>
    _.toString(bookmark.id) === _.toString(currentBookmarkId));
    const title = _.get(selectBookmark, 'name', 'Save filters');

    return(
      <CustomDropdownList
        key="bookmark-drop-down"
        id="drilldown"
        className="p-0  d-flex"
        title={title}
        optionDisplayField="name"
        options={dropdownOptions}
        showDescription={true}
        refTargetElement={dropdownPopupRef}
        onSelect={handleDropdownChange}
      />
    )
  }

  return (
    <div className='bookmark-list-filter forge-popup-host'>
      <LoadingSpinner isLoading={isLoading} />
      <div className='d-flex align-items-center'>
        <div className='save-filter-dropdown' ref={dropdownPopupRef}>
          {renderBookmarkDropdown()}
        </div>
        {renderMenus()}
      </div>
      <div ref={filterPopupRef}></div>
        { isChangeValues &&
          <div className='bookmark-help-text'> You have unsaved changes to your filters</div>
        }
      <SaveContainer
        onCreateBookmark={onCreateBookmark}
        openSaveContainer={openSaveContainer}
        setOpenSaveContainer={setOpenSaveContainer}
        currentBookmarkName={_.get(templateEntry, 'name', '')}
        currentBookmark={editBookmarkDetail}
        advanceSearchOptions={{ ...advanceSearchOptions, polygonsGeojson: JSON.stringify(polygonsGeojson) }}
        onBookmarkChange={handleBookmarkChange}
        onUpdateCurrentCollectionId={handleUpdateCurrentCollectionId}
        filterPopupRef={filterPopupRef}
        bookmarkMenuType = {bookmarkMenuType }
      />

        <DeleteBookmarkDialog
          openPopUp={deletePopUp}
          setDeletePopUp={setDeletePopUp}
          onDeleteBookmark={onDeleteBookmark}
          currentBookmarkId={currentBookmarkId}
          setLoading={setIsLoading}
          targetElementRef={filterPopupRef}
        >
        </DeleteBookmarkDialog>

    </div>
  );
}

BookmarkList.propTypes = {
  onCreateBookmark: PropTypes.func,
  isEmptySelection: PropTypes.bool,
}

BookmarkList.defaultProps = {
  isEmptySelection: false,
};

export default BookmarkList;
