import _ from 'lodash';

import { COMPARE_VIEW_STACK_COLORS, PIE_CHART_DATA_LIMIT } from 'modules/visualization/constants';
import { getFormattedValue } from 'helpers/chartDataHelper';
import { getNullValueLabel  } from 'common/config/templateConfiguration';

// Input:
//      apiData ===> {
//         "entries":[
//           {
//             "dimension":"Education",
//             "count":"579364725.34",
//             "min_count":"0",
//             "max_count":"24145485.18"
//           },
//           {
//             "dimension":"Public Safety",
//             "count":"471066891.00",
//             "min_count":"0",
//             "max_count":"17323545"
//           },
//          .....
//         ]

export const formatPieChartData = (vizOptions, apiData, isUpdateApiCall = false) => {
  const {
    afterRender, drillDownTotal, onApiDataLoad,
    viewEntry, templateId
  } = vizOptions;
  const apiDataEntries = _.get(apiData, 'entries', []);

  if(_.size(apiDataEntries) === 1 && isUpdateApiCall){
    onApiDataLoad(apiDataEntries[0]);
  }
  const nullValueLabel = getNullValueLabel(templateId);
  const otherEntriesTotalData = getOtherEntriesTotalData(drillDownTotal, apiDataEntries);
  let formattedData = _.map(apiDataEntries, (entry, index) => {
    const entryCount = _.get(entry, 'count');
    return {
      color: COMPARE_VIEW_STACK_COLORS[index],
      label: _.get(entry, 'dimension', nullValueLabel),
      value: entryCount,
      text: getFormattedValue(entryCount, viewEntry)
    };
  });

  if (apiDataEntries.length === PIE_CHART_DATA_LIMIT && otherEntriesTotalData > 0) {
    formattedData.push({
      color: _.last(COMPARE_VIEW_STACK_COLORS),
      label: "Others",
      value: otherEntriesTotalData,
      text: getFormattedValue(otherEntriesTotalData, viewEntry)
    });
  }
  afterRender(formattedData);
  return formattedData;
}

// to get the total values of other drilldown category entries which is not shown in the pie chart.
const getOtherEntriesTotalData = (allEntriesTotal, apiDataEntries) => {
  const categoryTotals = _.isEmpty(apiDataEntries) ? [] :
                          _.map(apiDataEntries, (entry) => Number(_.get(entry, 'count')));
  if(_.isEmpty(categoryTotals) || _.isUndefined(allEntriesTotal)) {
    return null;
  }

  const currentPageEntriesTotal = _.sum(categoryTotals);
  return Math.abs(Number(allEntriesTotal) - currentPageEntriesTotal);
}
