import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  getBenchMarkMetricEntries
} from 'helpers/visualizationHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getCurrentDimensionBenchMarkEntries,
  shouldDisableBenchmarkOptions
 } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

class BenchmarkOptions extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedBenchmarkOptions: [],
      benchmarkOptions:[],
      renderType:''
    };
  }

  componentDidMount() {
    this.getBenchMarkOptions();
  }

  componentDidUpdate (prevProps) {
    const { viewEntry, drilldownDimensionField,
            drilldownTemplateId, visualizationType, quickFilters,
            renderType, isDiscrete, isCumulative } = this.props;

     if(!_.isEqual(viewEntry, prevProps.viewEntry) ||
      !_.isEqual(drilldownDimensionField, prevProps.drilldownDimensionField) ||
      !_.isEqual(drilldownTemplateId, prevProps.drilldownTemplateId) ||
      !_.isEqual(visualizationType, prevProps.visualizationType) ||
      !_.isEqual(isDiscrete, prevProps.isDiscrete) ||
      !_.isEqual(isCumulative, prevProps.isCumulative) ||
      !_.isEqual(quickFilters, prevProps.quickFilters) ||
      !_.isEqual(renderType, prevProps.renderType)) {
      this.getBenchMarkOptions();
     }
  }

  getBenchMarkOptions() {
    const {
      visualizationType,
      renderType,
      viewEntry,
      benchMarkNames,
      isDiscrete,
      isCumulative,
      quickFilters
    } = this.props;

    const options = { isCumulative, isDiscrete, quickFilters };
    const currentBenchMarkEntries = getBenchMarkMetricEntries(viewEntry,
      visualizationType, renderType, benchMarkNames, options);

    let benchmarks = getCurrentDimensionBenchMarkEntries(this.props);
    _.forEach(benchmarks, (benchmark) => {
        benchmark['checked'] = false;
        if(currentBenchMarkEntries.some(
          findBenchmark => _.get(findBenchmark, 'name') === _.get(benchmark, 'name'))
        ) {
          benchmark['checked'] = true;
        }
    });

    this.setState({
        benchmarkOptions : benchmarks,
        renderType
    });
  }

  renderBenchMarkOptions() {
    const { benchmarkOptions } = this.state;

    return _.map(benchmarkOptions, (benchmark, index) => {
      return this.renderBenchMark(benchmark, index);
    });
  }

  toggleBenchmarkUpdates = (isChecked, index) => {
    const { benchmarkOptions } = this.state;
    const { onBenchmarkOptionChange } = this.props;

    let benchmarks = _.cloneDeep(benchmarkOptions);
    benchmarks[index].checked = isChecked;
    const selectedBenchmark = _.filter(benchmarks, (option) => {return option.checked == true});
    onBenchmarkOptionChange(selectedBenchmark);

    this.setState({
      benchmarkOptions: benchmarks,
      selectedBenchmarkOptions: selectedBenchmark });
  }

  handleKeyDown = (e, index, benchmark) => {
    if(isEnterButtonPressed(e)) {
      this.toggleBenchmarkUpdates(!benchmark.checked, index);
    }
  }

  renderBenchMark(benchmark, index) {
    const { idName } = this.props;
    const id = benchmark.name + (idName || '');
    return (
      <div
        tabIndex={0}
        className="custom-control custom-checkbox"
        aria-label={benchmark.name}
        onKeyDown={(e) => this.handleKeyDown(e, index, benchmark)}
        key={index}>
        <input
          type="checkbox"
          tabIndex={-1}
          aria-checked={benchmark.checked}
          checked={benchmark.checked}
          className="custom-control-input"
          id={id}
          onChange={(event) => this.toggleBenchmarkUpdates(event.target.checked, index)} />

        <label
          className="custom-control-label forge-typography--body2"
          htmlFor={id}>{benchmark.name}</label>
      </div>
    );
  }

  render() {
    const {ignoreTitle} = this.props;

    if(shouldDisableBenchmarkOptions(this.props)) {
      return null;
    }
    const legendBenchmarkClass = classNames('legend-benchmark filter-wrapper', {
      'mt-1 ': ignoreTitle
    });

    let bookmarkTitle = (<div className="options-label mb-1">Benchmarks</div>);
    if(ignoreTitle){
      bookmarkTitle = (<label className="custom-label mb-2 mt-0">Benchmarks</label>);
    }

    return (
      <div className={legendBenchmarkClass}>
        { bookmarkTitle }
        {this.renderBenchMarkOptions()}
      </div>
    );
  }
}

BenchmarkOptions.propTypes = {
  visualizationType : PropTypes.string,
  renderType : PropTypes.string,
  currentSnapshotView : PropTypes.string,
  drilldownDimensionField : PropTypes.string,
  drilldownTemplateId : commonPropTypes.templateIdPropTypes,
  viewEntry : commonPropTypes.viewEntryPropTypes,
  isDiscrete: PropTypes.bool,
  isCumulative: PropTypes.bool,
  benchMarkNames : PropTypes.array,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  onBenchmarkOptionChange: PropTypes.func,
  idName: PropTypes.string,
  ignoreTitle: PropTypes.bool
};

BenchmarkOptions.defaultPropTypes = {
  isDiscrete: false,
  isCumulative: false,
};

const mapDispatchToProps = {
};

function mapStateToProps(state, ownProps) {
  return {
    viewEntry: _.isUndefined(ownProps.viewEntry) ?
      _.get(state.drilldown, 'currentDrilldownViewEntry', {}) : _.get(ownProps, 'viewEntry', {}),
    drilldownDimensionField: _.isUndefined(ownProps.drilldownDimensionField) ?
      _.get(state, 'drilldown.currentDrilldownDimensionField') : _.get(ownProps, 'drilldownDimensionField'),
    drilldownTemplateId: _.isUndefined(ownProps.drilldownTemplateId) ?
      _.get(state, 'drilldown.currentDrilldownTemplateId') : _.get(ownProps, 'drilldownTemplateId'),
    quickFilters: _.isNil(ownProps.quickFilters) ?
      _.get(state, 'drilldown.quickFilters', []) : _.get(ownProps, 'quickFilters', ''),
    visualizationType: _.isUndefined(ownProps.visualizationType) ?
      _.get(state, 'drilldown.currentVisualizationType') : _.get(ownProps, 'visualizationType')
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkOptions);
