import {
  getRelativeFilterDateRange,
  getComparisonPeriodDateRanges
} from 'helpers/dateHelper';
import {
  VARIANCE_COMPARISON_OPTIONS
} from 'appConstants';
import { getViewEntryByField, getCurrentViewEntry } from 'common/config/templateConfiguration';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getAbbreviatedStringValue } from 'helpers/numberHelper';
import {
  getViewEntryQuickFilters
} from 'pages/drilldown/components/QuickFilterBar/helper';

export const getApiParams = (insight) => {
  const templateId = _.get(insight, 'template_id');
  const viewEntryField = _.get(insight, 'viewEntryField');
  const viewEntry = getViewEntryByField(templateId, viewEntryField)
  const dateRange = getRelativeFilterDateRange({
    currentRelativeFilterOption: _.get(insight, 'dateRange')
  });
  const secondaryDateRange = getComparisonPeriodDateRanges({
    dateRange: dateRange,
    dateType: _.get(insight, 'comparisonRange')
  })[0];
  const quickFilters = getViewEntryQuickFilters(templateId, viewEntry);
  return {
    drilldownEntry: JSON.stringify({
      currentDrilldownTemplateId: templateId,
      currentViewEntryField: viewEntryField,
      quickFilters: quickFilters
    }),
    commonFilters: JSON.stringify({
      globalFilters: [],
      dateRange: dateRange //{"startDate":"2020-01-01","endDate":"2020-12-31"}
    }),
    math_value_type: _.get(insight, 'calculation'),
    math_value_threshold_percentage: _.get(insight, 'thresholdPercent'),
    math_value_threshold_diff: _.get(insight, 'thresholdDiff'),
    score_weight_math_value_type: _.get(insight, 'sampleSizeCalculation', 'count'),
    minimum_segment_count: insight['minimumSegmentSizeCount'],
    minimum_segment_metric_value: insight['minimumSegmentSizeMetricValue'],
    sort_order: 'score',
    limit: _.get(insight, 'resultLimit', 20),
    selectedDimensions: JSON.stringify(_.get(insight, 'dimensionsFields')),
    secondaryDateRange: JSON.stringify(secondaryDateRange),
    comparisonOption: _.get(_.first(VARIANCE_COMPARISON_OPTIONS), 'type')
  };
}

export const formatVarianceValue = (value, calculationType, viewEntry, isRoundOff = false) => {
  let absoluteValue = Math.abs(value);
  if (isRoundOff && absoluteValue >  1000) {
    absoluteValue = Math.round(absoluteValue);
  }
  const isPercentage = calculationType == 'variance_percentage';
  const isCustomPrecision = true;
  const stringValue = getFormattedNumberValue(
    absoluteValue, viewEntry, false, true, isPercentage, isCustomPrecision);
  const negativeSign = value < 0 ? '-': '';
  const percentageSign = isPercentage ? '%': '';
  return `${negativeSign}${stringValue}${percentageSign}`;
}

export const getEntitiesValue = (insightResult) => {
  return _.chain(insightResult)
    .get('entities')
    .values()
    .join(' | ')
    .value();
}

export const getInsightsOption = (selectedInsight) => {
  const templateId = _.get(selectedInsight, 'template_id');
  const viewId = _.get(selectedInsight, 'view_id');
  const dimensionFields = _.get(selectedInsight , 'dimensionsFields', []);
  const thresholdDiff = _.get(selectedInsight, 'thresholdDiff');
  const thresholdPercent = _.get(selectedInsight, 'thresholdPercent');
  const viewEntry = getCurrentViewEntry(templateId, Number(viewId));
  const metricName = _.get(viewEntry, 'name', '');
  const minimumSegmentSizeCount = _.get(selectedInsight, 'minimumSegmentSizeCount');
  const minimumSegmentSizeMetricValue = _.get(selectedInsight, 'minimumSegmentSizeMetricValue');
  let commonFilters = {};
  let secondaryDateRange = {};

  if(!_.isEmpty(selectedInsight.apiParams)){
    commonFilters = JSON.parse(_.get(selectedInsight, 'apiParams.commonFilters', {}));
    secondaryDateRange = JSON.parse(_.get(selectedInsight, 'apiParams.secondaryDateRange', {}));
  }

  return {
    templateId,
    dimensionFields,
    thresholdDiff,
    thresholdPercent,
    metricName,
    commonFilters,
    secondaryDateRange,
    minimumSegmentSizeCount,
    minimumSegmentSizeMetricValue,
    isMultiDimension : (_.size(dimensionFields) > 1)
  }
}

export const getTableDataValue = (result, tableOption, scoreWeightMathValueType) => {
  let variance = '';
  switch(tableOption['id']){
    case 'variance_percentage':
      variance = result['variance_percentage'];
      return variance ? Number(variance) : variance;
    case 'variance_absolute':
      variance = result['variance_diff'];
      return variance ? Number(variance) : variance;
    case 'segment_total_percentage':
      if (scoreWeightMathValueType == 'count'){
        return (Number(result['v1_group_count']) +
          Number(result['v2_group_count']))/Number(result['total_weight']);
      } else {
        return (Number(result['v1']) + Number(result['v2']))/Number(result['total_weight']);
      }
    case 'segment_weight':
      return Number(result['group_weight']);
    case 'score':
      return Number(result['score']);
    case 'previous_value':
      return Number(result['v2']);
    case 'current_value':
      return Number(result['v1']);
  }
}

export const varianceFormattingIds = ['variance_percentage', 'variance_absolute'];

export const getFormattedTableValue = (tableOption, viewEntry, value) => {
  const showNA = (_.isNull(value) || _.isUndefined(value)) && tableOption['id'] == 'variance_percentage';
  if(_.includes(varianceFormattingIds, tableOption['id'])){
    return showNA? 'NA' : formatVarianceValue(value, tableOption['id'], viewEntry);
  } else if(tableOption['id'] == 'segment_total_percentage'){
    return `${getAbbreviatedStringValue(Math.abs(value))}%`;
  } else {
    return getAbbreviatedStringValue(Math.abs(value));
  }
}
