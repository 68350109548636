import * as actionTypes from '../actionTypes/distributionActionTypes';

export const updateCurrentDistributionOption = (isCumulativeDistribution, isDiscrete) => ({
  type: actionTypes.UPDATE_DISTRIBUTION_OPTION,
  isCumulativeDistribution,
  isDiscrete
});

export const updateDistributionBenchMarkNames = (names) => ({
  type: actionTypes.UPDATE_DISTRIBUTION_BENCH_MARK_NAMES,
  names
});

export const updateVisualizationDefaults = (options) => ({
  type: actionTypes.UPDATE_VISUALIZATION_DEFAULTS,
  options
});
