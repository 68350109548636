import $ from 'jquery';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { connect } from 'react-redux';


/* eslint-disable no-unused-vars */
import { Compare as MapBoxGlCompare } from 'mapbox-gl-compare';
/* eslint-enable no-unused-vars */
import UndoButton from 'pages/drilldown/visualizations/VisualizationOptions/UndoButton';
import Map from '../Map';
import ComparisonControl from './ComparisonControl';
import { toggleTextSelectionInDom } from './domHelper';
import { isMapCompareOn, showGeoCoderControl } from 'common/config/templateConfiguration';
import { getMapAttributes, getMapForExportImage } from '../helpers/mapHelper';
import StreetViewMap from '../partials/StreetViewMap';
import NoteModal from 'pages/drilldown/visualizations/NotesPage/NoteModel';
import PolygonFilterButton from '../partials/PolygonFilterButton';
import GlobalEvent from 'common/components/GlobalEvents';
import MapControls from '../partials/MapControls';
import RecenterButton from '../partials/RecenterButton';

import MapViewTypes from '../MapViewTypes';
import MapStyleSelector from '../MapStyleSelector';
import { isChoroplethMap } from '../helpers/MapOptionsHelper';
import ChoroplethPolygonFilterButton from '../partials/ChoroplethPolygonFilterButton';
import MapLegends from '../Legends/MapLegends';
import LoadingSpinner from 'common/components/LoadingSpinner';
class ComparisonMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      areMapsReady: false,
      sliderPositionPercentage: 50,
      streetViewCoordinates: [],
      noteModelId: '',
      defaultCenter: _.get(props, 'center'),
      defaultZoom: _.get(props, 'zoom'),
      map: null,
      showSearchInput: false,
      isLassoSelectEnabled: false,
      isLoading: false,
      polygonFeatures: {}
    };
    this.isDraggingComparisonSlider = false;
    this.previousPeriodMap = null;
    this.currentPeriodMap = null;
    this.mapboxglCompare = null;
  }

  componentDidMount() {
    this.onEmitMapExport();
  }

  setCompareSlider = (width) => {
    if (this.mapboxglCompare) {
      this.mapboxglCompare.setSlider(width / 2);
    }
  }

  componentDidUpdate(prevProps) {
    const width = this.currentPeriodMap.getContainer().getBoundingClientRect().width;
    if (!_.isEqual(prevProps.commonFilters, this.props.commonFilters)) {
      this.setCompareSlider(width);
      this.setState({ sliderPositionPercentage: 50 });
    }

    if (!_.isEqual(prevProps.isComparisonEnabled, this.props.isComparisonEnabled)) {
      this.onEmitMapExport()
    }
  }

  onEmitMapExport = () => {
    const { commonFilters, isComparisonEnabled } = this.props;
    const map = getMapForExportImage(
      commonFilters,
      isComparisonEnabled,
      this.previousPeriodMap,
      this.currentPeriodMap
    )
    GlobalEvent.emit('MAP_BOX', map);
  }

  onPreviousPeriodMapCreated = (map) => {
    this.previousPeriodMap = map;
    this.onMapCreated();
  };

  onCurrentPeriodMapCreated = (map) => {
    this.currentPeriodMap = map;
    this.onMapCreated();
    this.setState({ map });
  };

  onUpdateMapLoadingStatus = (isLoading) => {
    this.setState({ isLoading });
  }

  onMapCreated = () => {
    if (this.currentPeriodMap && this.previousPeriodMap) {
      this.mapboxglCompare = new mapboxgl.Compare(this.previousPeriodMap, this.currentPeriodMap, {
        mousemove: false
      });

      $(this.mapboxglCompare._container).on('mousedown', () => {
        this.isDraggingComparisonSlider = true;
        this.onCompareStart();
      });
      $(document).on('mouseup', () => {
        this.isDraggingComparisonSlider = false;
        if (this.isDraggingComparisonSlider) {
          this.onCompareEnd();
        }
      });

      $(document).on('mousemove', _.throttle(this.fadingOutMapTextLable, 200));

      this.setState({ areMapsReady: true });
    }
  }

  isComparisonEnabled = () => {
    const { currentDrilldownTemplateId, isComparisonEnabled } = this.props;

    return isMapCompareOn(currentDrilldownTemplateId) && isComparisonEnabled;
  }

  fadingOutMapTextLable = (e) => {
    const mapViewportInitialPosition = parseInt(($(document).find('.left-side-section')).width());
    const mapContainerWith = parseInt(($(document).find('.map-container')).width());
    const mapViewportEndPosition = mapViewportInitialPosition + mapContainerWith;
    const mapViewPortWidth = mapViewportEndPosition - mapViewportInitialPosition;

    if (this.isDraggingComparisonSlider) {
      const currentPosition = e.clientX;
      const sliderPositionPercentage = (
        ((currentPosition - mapViewportInitialPosition) / mapViewPortWidth) * 100
      );
      this.setState({ sliderPositionPercentage });
    }
  }

  onToggleComparison = (isComparisonEnabled) => {
    this.props.dispatchUpdateComparisonModeOn(isComparisonEnabled);

  }

  onCompareStart = () => {
    toggleTextSelectionInDom(false);
  }

  onCompareEnd = () => {
    toggleTextSelectionInDom(false);
  }

  updateStreetMapCoordinates = (streetViewCoordinates) => {
    this.setState({ streetViewCoordinates });
  }

  toggleNoteModel = (noteModelId) => {
    this.setState({ noteModelId });
  }

  handleStreetMapClose = () => {
    this.setState({ streetViewCoordinates: [] });
  }

  updatePolygonFeatures = (polygonFeatures) => {
    this.setState({polygonFeatures: polygonFeatures})
  }

  renderComparisonControl() {
    const { isComparisonEnabled, commonFilters } = this.props;
    const { areMapsReady, sliderPositionPercentage } = this.state;

    if (!areMapsReady) {
      return null;
    }

    return (
      <>
        <ComparisonControl
          isComparisonEnabled={isComparisonEnabled}
          onChange={this.onToggleComparison}
          commonFilters={commonFilters}
          sliderPositionPercentage={sliderPositionPercentage} />
      </>
    );
  }

  renderMaps() {
    const { showSearchInput, isLassoSelectEnabled } = this.state;

    const {
      dispatchUpdateCenterAndZoom, dispatchUpdateLegends,
      isCurrencyDimensionField, onMapLoaded, containerHeight,
      isComparisonEnabled
    } = this.props;
    const { currentPeriodMapAttributes, previousPeriodMapAttributes } = getMapAttributes(this.props);
    const { apiParams: currentPeriodApiParams } = currentPeriodMapAttributes;
    const { apiParams: previousPeriodApiParams } = previousPeriodMapAttributes;
    const currentPeriodMapClasses = classNames(
      'map-item current-period-map',
      { 'draw-icon-filter': isLassoSelectEnabled }
    );
    const previousPeriodMap = (
      <div className="map-item previous-period-map" key="previous-period-map">
        <div className="see-through-overlay"></div>
        <Map
          showSearchInput={showSearchInput}
          onHideSearchInput={this.handleMapSearchInput}
          isComparisonEnabled={isComparisonEnabled}
          updatePolygonFeatures = {this.updatePolygonFeatures}
          onMapCreated={this.onPreviousPeriodMapCreated}
          mapStyle="mapbox://styles/mapbox/dark-v10"
          comparisonPeriod="Last Period"
          compareApiParams={currentPeriodApiParams}
          updateStreetMapCoordinates={this.updateStreetMapCoordinates}
          dispatchUpdateCenterAndZoom={dispatchUpdateCenterAndZoom}
          dispatchUpdateLegends={dispatchUpdateLegends}
          isCurrencyDimensionField={isCurrencyDimensionField}
          showNoteModel={this.toggleNoteModel}
          containerHeight={containerHeight}
          onUpdateMapLoadingStatus={this.onUpdateMapLoadingStatus}
          isComparisonMap={true}
          {...previousPeriodMapAttributes}
        ></Map>
      </div>
    );
    const currentPeriodMap = (
      <div className={currentPeriodMapClasses} key="current-period-map">
        <Map
          showSearchInput={showSearchInput}
          onHideSearchInput={this.handleMapSearchInput}
          onMapLoaded={onMapLoaded}
          onMapCreated={this.onCurrentPeriodMapCreated}
          isComparisonEnabled={isComparisonEnabled}
          updatePolygonFeatures = {this.updatePolygonFeatures}
          mapStyle="mapbox://styles/mapbox/light-v10"
          comparisonPeriod="This Period"
          updateStreetMapCoordinates={this.updateStreetMapCoordinates}
          compareApiParams={previousPeriodApiParams}
          isCurrencyDimensionField={isCurrencyDimensionField}
          dispatchUpdateCenterAndZoom={dispatchUpdateCenterAndZoom}
          dispatchUpdateLegends={dispatchUpdateLegends}
          showNoteModel={this.toggleNoteModel}
          containerHeight={containerHeight}
          onUpdateMapLoadingStatus={this.onUpdateMapLoadingStatus}
          isComparisonMap={true}
          {...currentPeriodMapAttributes}
        ></Map>
      </div>
    );

    return [previousPeriodMap, currentPeriodMap];
  }

  handleMapSearchInput = () => {
    const { showSearchInput } = this.state;
    this.setState({ showSearchInput: !showSearchInput });
  }

  mapSearchButton() {
    const { showSearchInput } = this.state;
    const { currentDrilldownTemplateId } = this.props;
    const shouldShowGeoCoderControl = showGeoCoderControl(currentDrilldownTemplateId);

    if (showSearchInput && shouldShowGeoCoderControl) {
      return null;
    }

    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          title="search"
          onClick={this.handleMapSearchInput}>
          <ForgeIcon className="map-search-icon" name="search" />
          <span>Search</span>
        </button>
      </ForgeButton>
    )
  }
  renderPolygonDrawButton = () => {
    const { currentMapView } = this.props;
    const { polygonFeatures } = this.state;
    if (isChoroplethMap(currentMapView)) {
      return <ChoroplethPolygonFilterButton
        onLassoFilterClick={() => this.setState({ isLassoSelectEnabled: true })}
        onLassoFilterComplete={() => this.setState({ isLassoSelectEnabled: false })}
      />
    } else {
      return <PolygonFilterButton
        polygonFeatures={polygonFeatures}
        updatePolygonFeatures={this.updatePolygonFeatures}/>
    }
  }

  render() {
    const {
      streetViewCoordinates, noteModelId, defaultCenter,
      defaultZoom, map, showSearchInput, isLoading } = this.state;
    let streetMapViewPartial, noteModel = null;
    const { currentMapView, isComparisonEnabled, isOpenLegend } = this.props;
    const isChoroplethMapView = isChoroplethMap(currentMapView);

    const comparisonClassNames = classNames(
      'comparison-map',
      { 'comparison-enabled-and-displayed': isComparisonEnabled },
      { 'comparison-map-search-expand': showSearchInput }
    );
    if (!_.isEmpty(streetViewCoordinates)) {
      streetMapViewPartial = <StreetViewMap
        coordinates={streetViewCoordinates}
        isComparisonEnabled={isComparisonEnabled}
        onStreetViewClose={this.handleStreetMapClose} />;
    }
    if (!_.isEmpty(noteModelId)) {
      noteModel = <NoteModal noteId={noteModelId} onClose={() => this.toggleNoteModel('')} />;
    }

    const reCenterButtonAttributes = {
      center: defaultCenter,
      zoom: defaultZoom,
      map
    };

    const mapSearchExpandClass = showSearchInput ? 'map-search-expand' : '';

    return (
      <div className={comparisonClassNames}>
        <MapControls mapControlClassName={mapSearchExpandClass}>
          <div className="map-controls-lside">
            {this.mapSearchButton()}
            {this.renderComparisonControl()}
            <RecenterButton {...reCenterButtonAttributes} />
            {this.renderPolygonDrawButton()}
          </div>
          <div className="map-controls-rside">
            <UndoButton />
            <MapViewTypes />
            <MapStyleSelector />
            {isChoroplethMapView &&
              <MapLegends legends={this.props.legendItems}
                isOpenLegend={isOpenLegend}
                toggleClickLegendButton={this.props.toggleClickLegendButton}
                showMobileView={false} />
            }
          </div>
        </MapControls>
        <LoadingSpinner isLoading={isLoading}></LoadingSpinner>
        {/* Used for Map accessibility events on tabbing */}
        <div id='map-accessibility-element'></div>
        {this.renderMaps()}
        {streetMapViewPartial}
        {noteModel}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentMapView: _.get(state, 'visualization.mapOptions.currentMapView', {})
  };
}

ComparisonMap.propTypes = {
  commonFilters: PropTypes.object,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comparison: PropTypes.object,
  dispatchUpdateComparisonModeOn: PropTypes.func,
  dispatchUpdateCenterAndZoom: PropTypes.func,
  dispatchUpdateLegends: PropTypes.func,
  isCurrencyDimensionField: PropTypes.bool,
  onMapLoaded: PropTypes.func,
  center: PropTypes.array,
  legendItems: PropTypes.array,
  isSideBar: PropTypes.bool,
  isComparisonEnabled: PropTypes.bool,
  currentMapView: PropTypes.object,
  zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerHeight: PropTypes.number,
  isOpenLegend: PropTypes.bool,
  toggleClickLegendButton: PropTypes.func
};

export default connect(mapStateToProps, {})(ComparisonMap);
