export const CHART_IMAGE_MIN_HEIGHT = 220;
const IMAGE_TITLE_FONT = `bold 21px "Roboto"`
const IMAGE_DATERANGE_FONT = `400 14px "Roboto"`
const IMAGE_FOOTER_FONT = `400 12px "Roboto"`
const IMAGE_FOOTER_HIGHLIGHT_FONT = `600 12px "Roboto"`
const FOOTER_CHAR_LENGTH = 180;
const WIDTH_FOR_MARGIN_THIRTY = 30;
const HEIGHT_FOR_FULL_IMAGE_HUNDRED = 130;
const DYNAMIC_HEIGHT_FOR_DESCRIPTION = 35;
const DESCRIPTION_YAXIS_SEVENTY = 105;
const DESCRIPTION_YAXIS_FOR_MAP = 120;
const DESCRIPTION_YAXIS_FOR_MAP_DATE_RANGE = 130;
const XAXIS_START_FOR_CANVAS = 10;
const YAXIS_FOR_TITLE = 20;
const YAXIS_FOR_DATERANGE = 50;
const YAXIS_FOR_IMAGE_WITHOUT_TITLE = 50;
const XAXIS_FOR_LEGEND = 15;
const DYNAMIC_YAXIS = 25;
const DYNAMIC_YAXIS_TOTAL = 10;
const DYNAMIC_YAXIS_DATE_RANGE = 40;
const DYNAMIC_YAXIS_FOR_DESCRIPTION = 15;
const DYNAMIC_YAXIS_FOR_TITLE = 30;
const PIE_CHART_INCREASING_WIDTH = 600;
const DEFAULT_SPLIT_REGEX = /(.{240}\S*)\s/
const NORMAL_SPLIT_REGEX = /(.{180}\S*)\s/
const ZOOM_SPLIT_REGEX = /(.{200}\S*)\s/
const MAP_ZOOM_SPLIT_REGEX = /(.{160}\S*)\s/
const DEFAULT_SPLIT_TITLE_REGEX = /(.{85}\S*)\s/
const ZOOM_SPLIT_TITLE_REGEX = /(.{65}\S*)\s/
const ZERO_POSITION_FOR_CANVAS = 0;
const DEFAULT_MAIN_IMAGE_WIDTH = 1000;
const PROMISE_WAITING_TIME_FOR_MAIN_IMAGE = 1500;
const PROMISE_WAITING_TIME_FOR_CHART = 500;
const YAXIS_FOR_LEGEND_OPTIONS = 10;
const PROMISE_WAITING_TIME_FOR_LEGEND = 100;
const EXCESS_LEGEND_HEIGHT = 30;
const XAXIS_START_FOR_SUMMARY_CANVAS = 20
const DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS = 30
const YAXIS_FOR_IMAGE_AFTER_DESCRIPTION = 30;
const DYNAMIC_HEIGHT_FOR_SUMMARY_TABLE = 50;
const DEFAULT_CHART_HEIGHT = 300;
const XAXIS_START_FOR_RADAR_TITLE = 50;
const RADAR_DESCRIPTION_SPLIT_REGEX = /(.{140}\S*)\s/;
const DESCRIPTION_TEXT_COLOR = "#444444";
const TITLE_TEXT_COLOR = "#000";

import _ from 'lodash';
import { SNAPSHOT_VISUALIZATION_TYPES } from 'appConstants';

export const getMapWithTitleURL = async ({
  chartContainer,
  chartImageCanvas,
  legendCanvas,
  options,
  dateRange,
  metricTotalImage,
  summaryTableImage
}) => {
  const { withFooterNote, withTitle, titleText,
    withSummaryTable, withChartImage, withFullPageImage } = options;

  let totalWidth = 0;
  let totalHeight = 0;

  const mapBoundingRect = chartContainer.querySelector('.mapboxgl-canvas').getBoundingClientRect()
  const legendContainer = chartContainer.querySelector('.legend-wrapper')
  const mapWidth = mapBoundingRect.width;
  const mapHeight = mapBoundingRect.height;

  let summaryTableWidth, summaryTableHeight = 0;
  let summaryTableContainer = chartContainer.querySelector('.summary-table .table');

  if (!_.isEmpty(summaryTableContainer)) {
    let summaryTableBoundingRect = summaryTableContainer.getBoundingClientRect()
    summaryTableWidth = summaryTableBoundingRect.width;
    summaryTableHeight = summaryTableBoundingRect.height;
  }

  const mapTitle = withTitle ? splittedTitle(titleText, mapWidth) : '';
  const legendWidth = !_.isEmpty(legendContainer) ?
    legendContainer.getBoundingClientRect().width : '';

  totalWidth += WIDTH_FOR_MARGIN_THIRTY;
  totalHeight += WIDTH_FOR_MARGIN_THIRTY;

  totalWidth = !_.isEmpty(legendContainer) ? mapWidth + legendWidth : mapWidth;
  const chatHeight = (withChartImage || withFullPageImage) ? mapHeight : 0;
  totalHeight += chatHeight;

  if (totalWidth < summaryTableWidth && (withSummaryTable || withFullPageImage)) {
    totalWidth = summaryTableWidth + WIDTH_FOR_MARGIN_THIRTY + 50;
  }

  if (summaryTableHeight > 0 && (withSummaryTable || withFullPageImage)) {
    totalHeight += summaryTableHeight + DYNAMIC_HEIGHT_FOR_SUMMARY_TABLE + 50;
  }

  const footerYaxis = chatHeight + DESCRIPTION_YAXIS_FOR_MAP;
  const footerYaxisDateRange = chatHeight + DESCRIPTION_YAXIS_FOR_MAP_DATE_RANGE;
  const footerXaxis = XAXIS_START_FOR_CANVAS;
  const footerText = chartContainer.querySelector('.visualization-footer>div');
  const totalNumberOfTextSize = legendCanvas ? null : MAP_ZOOM_SPLIT_REGEX;
  const mapFooter = withFooterNote && (withChartImage || withFullPageImage) ?
    splittedDescription(footerText, mapWidth, totalNumberOfTextSize) : '';
  if (!_.isEmpty(mapFooter)) {
    _.each(mapFooter, () => { totalHeight += DYNAMIC_HEIGHT_FOR_DESCRIPTION })
  }
  const resolutionOption = {
    imageWidth: mapWidth,
    imageHeight: mapHeight,
    totalWidth,
    totalHeight,
    footerXaxis,
    footerYaxis,
    dateRange,
    footerYaxisDateRange
  }
  const canvasOptions = {
    resolutionOption,
    chartTitle: mapTitle,
    canvasImage: chartImageCanvas,
    legendCanvas,
    options,
    chartFooter: mapFooter,
    metricTotalImage,
    summaryTableImage,
    isMap: true
  }
  return getCanvasImageURL(canvasOptions)
}

export const getChartWithTitleImageURL = async ({
  chartContainer,
  chartImageCanvas,
  snapshotView,
  legendCanvas,
  options,
  metricTotalImage,
  summaryTableImage
}) => {
  const { withFooterNote, withTitle, titleText,
    withSummaryTable, withFullPageImage, withChartImage } = options;
  let legendWidth, legendHeight;
  let summaryTableWidth, summaryTableHeight = 0;
  let totalWidth = 0;
  let totalHeight = 0;
  const svgContainer = chartContainer.querySelector('.svg-container');
  const chartHeight = getChartImageHeight(
    svgContainer ? svgContainer.getBoundingClientRect().height : DEFAULT_CHART_HEIGHT
  );
  const chartWidth = getChartImageWidth(snapshotView, chartContainer);
  const chartTitle = withTitle ? splittedTitle(titleText, chartWidth) : '';
  let legendContainer = chartContainer.querySelector('.legend-wrapper');

  let summaryTableContainer = chartContainer.querySelector('.summary-table .table');

  if (!_.isEmpty(summaryTableContainer)) {
    let summaryTableBoundingRect = summaryTableContainer.getBoundingClientRect()
    summaryTableWidth = summaryTableBoundingRect.width;
    summaryTableHeight = summaryTableBoundingRect.height;
  }

  if (!_.isEmpty(legendContainer)) {
    let legendBoundingRect = legendContainer.getBoundingClientRect()
    legendWidth = legendBoundingRect.width;
    legendHeight = legendBoundingRect.height;
  }

  if (withChartImage || withFullPageImage) {
    totalWidth = !_.isEmpty(legendContainer) ? chartWidth + legendWidth : chartWidth;

    totalHeight = (!_.isEmpty(legendContainer)) &&
      (legendHeight > chartHeight) ?
      chartHeight + (legendHeight - chartHeight) : chartHeight;
  }

  totalWidth += WIDTH_FOR_MARGIN_THIRTY
  totalHeight += HEIGHT_FOR_FULL_IMAGE_HUNDRED;

  if (totalWidth < summaryTableWidth && (withSummaryTable || withFullPageImage)) {
    totalWidth = summaryTableWidth + WIDTH_FOR_MARGIN_THIRTY + 50;
  }

  const footerYaxis = chartHeight + DESCRIPTION_YAXIS_SEVENTY;
  const footerXaxis = XAXIS_START_FOR_CANVAS;
  const footerText = chartContainer.querySelector('.visualization-footer>div')
  const defaultSplitReg = legendHeight > chartHeight ? NORMAL_SPLIT_REGEX : DEFAULT_SPLIT_REGEX;
  const chartFooter = withFooterNote && (withChartImage || withFullPageImage) ?
    splittedDescription(footerText, chartWidth, null, defaultSplitReg) : '';

  if (!_.isEmpty(chartFooter)) {
    _.each(chartFooter, () => { totalHeight += DYNAMIC_HEIGHT_FOR_DESCRIPTION })
  }

  if (summaryTableHeight > 0 && (withSummaryTable || withFullPageImage)) {
    totalHeight += summaryTableHeight + DYNAMIC_HEIGHT_FOR_SUMMARY_TABLE;
  }

  const resolutionOption = {
    imageWidth: chartWidth,
    imageHeight: chartHeight,
    totalWidth,
    totalHeight,
    footerXaxis,
    footerYaxis,
    dateRange: null
  }
  const canvasOptions = {
    resolutionOption,
    chartTitle: chartTitle,
    canvasImage: chartImageCanvas,
    legendCanvas,
    options,
    chartFooter,
    metricTotalImage,
    summaryTableImage,
    isMap: false
  }
  return getCanvasImageURL(canvasOptions)
}

export const getRadarChartWithImageURL = async ({
  chartContainer,
  options,
  summaryTableImage,
  barChartImage,
  mapChartImage
}) => {

  const {
    withMapImage,
    withSummaryTable,
    withPrePostChartImage,
    withFullPageImage
  } = options;

  let summaryTableWidth, summaryTableHeight = 0;
  let radarBarChartWidth, radarBarChartHeight = 0;
  let radarMapChartWidth, radarMapChartHeight = 0;

  let summaryTableContainer = chartContainer.querySelector('.radar-table-container');
  let radarBarChartContainer = chartContainer.querySelector('.radar-barchart-container');
  let radarMapChartContainer = chartContainer.querySelector('.radar-map-container');

  if (!_.isEmpty(summaryTableContainer)) {
    let summaryTableBoundingRect = summaryTableContainer.getBoundingClientRect()
    summaryTableWidth = summaryTableBoundingRect.width;
    summaryTableHeight = summaryTableBoundingRect.height;
  }

  if (!_.isEmpty(radarBarChartContainer) && (withPrePostChartImage || withFullPageImage)) {
    let barChartBoundingRect = radarBarChartContainer.getBoundingClientRect();
    radarBarChartWidth = barChartBoundingRect.width;
    radarBarChartHeight = barChartBoundingRect.height;
  }

  if (!_.isEmpty(radarMapChartContainer) && (withMapImage || withFullPageImage)) {
    let mapChartBoundingRect = radarMapChartContainer.getBoundingClientRect();
    radarMapChartWidth = mapChartBoundingRect.width;
    radarMapChartHeight = mapChartBoundingRect.height;
  }

  let totalWidth = summaryTableWidth + WIDTH_FOR_MARGIN_THIRTY;
  let totalHeight = 0;

  if (withSummaryTable || withFullPageImage) {
    totalHeight = summaryTableHeight;
  }

  if (withPrePostChartImage || withFullPageImage) {
    totalHeight += radarBarChartHeight;
  }

  if (withMapImage || withFullPageImage) {
    totalHeight += radarMapChartHeight;
  }

  totalHeight += HEIGHT_FOR_FULL_IMAGE_HUNDRED;
  const chartFooter = '';

  const resolutionOption = {
    imageSummaryWidth: summaryTableWidth,
    imageSummaryHeight: summaryTableHeight,
    imageBarChartWidth: radarBarChartWidth,
    imageBarChartHeight: radarBarChartHeight,
    imageMapChartWidth: radarMapChartWidth,
    imageMapChartHeight: radarMapChartHeight,
    totalWidth,
    totalHeight,
    dateRange: null
  }
  const canvasOptions = {
    resolutionOption,
    options,
    chartFooter,
    summaryTableImage,
    barChartImage,
    mapChartImage
  }
  return getRadarCanvasImageURL(canvasOptions)
}

const getRadarCanvasImageURL = (canvasOptions) => {
  const {
    resolutionOption,
    options,
    summaryTableImage,
    barChartImage,
    mapChartImage
  } = canvasOptions;
  const {
    imageSummaryHeight,
    imageBarChartHeight,
    totalWidth,
    totalHeight
  } = resolutionOption;
  const {
    withMapImage,
    withSummaryTable,
    withPrePostChartImage,
    withFullPageImage,
    radarTitle,
    radarDescription
  } = options;

  const canvas = document.createElement('canvas');
  canvas.height = totalHeight + 100;
  canvas.width = totalWidth + 20;
  canvas.style.backgroundColor = "#ffffff";
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = '#ffffff'
  ctx.fillRect(ZERO_POSITION_FOR_CANVAS, ZERO_POSITION_FOR_CANVAS, canvas.width, canvas.height)

  let yAxisStartIndex = 0;

  if (withFullPageImage && radarTitle) {
    yAxisStartIndex += DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS;
    ctx.fillStyle = TITLE_TEXT_COLOR;
    ctx.font = IMAGE_TITLE_FONT;
    ctx.fillText(radarTitle, XAXIS_START_FOR_RADAR_TITLE, yAxisStartIndex);
  }

  if (withFullPageImage && radarDescription) {
    let splittedDescription = splittedRadarDescription(radarDescription);
    _.each(splittedDescription, (string) => {
      yAxisStartIndex += DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS;
      ctx.fillStyle = DESCRIPTION_TEXT_COLOR;
      ctx.font = IMAGE_TITLE_FONT;
      ctx.fillText(string, XAXIS_START_FOR_RADAR_TITLE, yAxisStartIndex);
    });
  }

  if (!_.isNull(summaryTableImage) && (withSummaryTable || withFullPageImage)) {
    ctx.drawImage(summaryTableImage, XAXIS_START_FOR_SUMMARY_CANVAS,
      yAxisStartIndex + DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS);

    yAxisStartIndex += imageSummaryHeight + DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS;
  }

  if (!_.isNull(barChartImage) && (withPrePostChartImage || withFullPageImage)) {
    ctx.drawImage(barChartImage, XAXIS_START_FOR_SUMMARY_CANVAS,
      yAxisStartIndex + DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS);

    yAxisStartIndex += imageBarChartHeight + DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS;
  }

  if (!_.isNull(mapChartImage) && (withMapImage || withFullPageImage)) {
    ctx.drawImage(mapChartImage, XAXIS_START_FOR_SUMMARY_CANVAS,
      yAxisStartIndex + DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS);
  }

  return new Promise((resolve) => {
    setTimeout(resolve, PROMISE_WAITING_TIME_FOR_MAIN_IMAGE)
  }).then(() => {
    return canvas.toDataURL('image/jpeg');
  })
}

const getCanvasImageURL = (canvasOptions) => {
  const {
    resolutionOption,
    chartTitle,
    canvasImage,
    legendCanvas,
    options,
    chartFooter,
    metricTotalImage,
    summaryTableImage,
    isMap
  } = canvasOptions;
  const {
    imageWidth,
    imageHeight,
    totalWidth,
    totalHeight,
    footerXaxis,
    dateRange
  } = resolutionOption;
  const { withTitle, withFooterNote, withSummaryTable, withChartImage, withFullPageImage } = options;
  const canvas = document.createElement('canvas');

  canvas.height = totalHeight + (isMap ? HEIGHT_FOR_FULL_IMAGE_HUNDRED : 0),
    canvas.width = totalWidth + (isMap ? 50 : 0),
    canvas.style.backgroundColor = "white";
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = '#FFFFFF'
  ctx.fillRect(ZERO_POSITION_FOR_CANVAS, ZERO_POSITION_FOR_CANVAS, canvas.width, canvas.height)
  let dateRangeYaxis = withTitle ? YAXIS_FOR_TITLE : ZERO_POSITION_FOR_CANVAS
  if (withTitle) {
    _.each(chartTitle, (string) => {
      dateRangeYaxis = dateRangeYaxis + DYNAMIC_YAXIS_FOR_TITLE
      ctx.fillStyle = TITLE_TEXT_COLOR;
      ctx.font = IMAGE_TITLE_FONT;
      ctx.fillText(string, XAXIS_START_FOR_CANVAS, dateRangeYaxis);
    })

    dateRangeYaxis = _.isEqual(dateRangeYaxis, ZERO_POSITION_FOR_CANVAS) ?
      YAXIS_FOR_DATERANGE : dateRangeYaxis + DYNAMIC_YAXIS_TOTAL;
    if (!_.isNull(metricTotalImage)) {
      ctx.drawImage(metricTotalImage, XAXIS_START_FOR_CANVAS, dateRangeYaxis);
    }
  }
  if (!_.isEmpty(dateRange)) {
    dateRangeYaxis = _.isEqual(dateRangeYaxis, ZERO_POSITION_FOR_CANVAS) ?
      YAXIS_FOR_DATERANGE : dateRangeYaxis + DYNAMIC_YAXIS_DATE_RANGE
    ctx.fillStyle = TITLE_TEXT_COLOR;
    ctx.font = IMAGE_DATERANGE_FONT;
    let date = `${dateRange.startDate} - ${dateRange.endDate}`
    ctx.fillText(date, XAXIS_START_FOR_CANVAS, dateRangeYaxis);
  }

  let imageYaxis = _.isEqual(dateRangeYaxis, ZERO_POSITION_FOR_CANVAS) ?
    YAXIS_FOR_IMAGE_WITHOUT_TITLE : dateRangeYaxis + DYNAMIC_YAXIS;

  if (withChartImage || withFullPageImage) {
    ctx.drawImage(canvasImage, XAXIS_START_FOR_CANVAS, imageYaxis, imageWidth, imageHeight);
    if (!_.isNull(legendCanvas)) {
      ctx.drawImage(legendCanvas, imageWidth + XAXIS_FOR_LEGEND, imageYaxis)
    }
    imageYaxis = imageYaxis + imageHeight;
  }

  ctx.fillStyle = DESCRIPTION_TEXT_COLOR;
  let descriptionYaxis = imageYaxis + DYNAMIC_YAXIS_FOR_DESCRIPTION
  if (withFooterNote && (withChartImage || withFullPageImage)) {
    _.each(chartFooter, (string) => {
      descriptionYaxis += DYNAMIC_YAXIS_FOR_DESCRIPTION

      if (string.includes("<b>") || string.includes("</b>")) {
        let textWidth = footerXaxis;
        string = string.split(/(<b.*?b>)/);
        string = checkAndUpdateCutoffHighlightText(string);
        _.each(string, (text) => {
          if (text.includes("<b>")) {
            ctx.font = IMAGE_FOOTER_HIGHLIGHT_FONT;
            text = text.replace('<b>', '').replace('</b>', '');
            text = text.replace('&amp;', '&');
            ctx.fillText(text, textWidth, descriptionYaxis)
            textWidth = textWidth + ctx.measureText(text).width;
          } else {
            ctx.font = IMAGE_FOOTER_FONT;
            ctx.fillText(text, textWidth, descriptionYaxis)
            textWidth = textWidth + ctx.measureText(text).width;
          }
        });
      } else {
        ctx.font = IMAGE_FOOTER_FONT;
        ctx.fillText(string, footerXaxis, descriptionYaxis)
      }
    })
  }

  if (!_.isNull(summaryTableImage) && (withSummaryTable || withFullPageImage)) {
    let summaryTableYaxis = descriptionYaxis + YAXIS_FOR_IMAGE_AFTER_DESCRIPTION;

    if (!_.isNull(legendCanvas) && (withChartImage || withFullPageImage)) {
      const legenHeight = Number(_.get(legendCanvas, 'height', 0));
      if (legenHeight > summaryTableYaxis) {
        summaryTableYaxis = legenHeight + YAXIS_FOR_IMAGE_AFTER_DESCRIPTION;
      }
    }

    ctx.fillStyle = TITLE_TEXT_COLOR;
    ctx.font = IMAGE_TITLE_FONT;

    if (!_.isNil(summaryTableImage)) {
      ctx.fillText("Summary", XAXIS_START_FOR_CANVAS, summaryTableYaxis);
      ctx.drawImage(summaryTableImage, XAXIS_START_FOR_SUMMARY_CANVAS,
        summaryTableYaxis + DYNAMIC_YAXIS_FOR_SUMMARY_CANVAS);
    }
  }

  return new Promise((resolve) => {
    setTimeout(resolve, PROMISE_WAITING_TIME_FOR_MAIN_IMAGE)
  }).then(() => {
    return canvas.toDataURL('image/jpeg');
  })
}

export const checkAndUpdateCutoffHighlightText = (splittedArray) => {
  let array = [];
  _.forEach(splittedArray, (text) => {
    if (text.includes('<b>') || text.includes('</b>')) {
      if (_.startsWith(text, '<b>') && _.endsWith(text, '</b>')) {
        array.push(text);
      } else if (text.includes('</b>')) {
        text = text.split(/(.*?b>)/);
        _.forEach(text, (data) => {
          if (_.endsWith(data, '</b>')) {
            data = `<b>${data}`;
            array.push(data);
          } else {
            array.push(data);
          }
        });
      } else {
        text = text.split(/(<b.*)/);
        _.forEach(text, (data) => {
          if (_.startsWith(data, '<b>')) {
            data = `${data}</b>`;
            array.push(data);
          } else {
            array.push(data);
          }
        });
      }
    } else {
      array.push(text);
    }
  });
  return _.compact(array);
}

export const getPlotlyChartCanvas = async (plotlyChartData, chart, currentSnapshotView) => {
  const chartHeight = getChartImageHeight(
    chart.querySelector('.svg-container').getBoundingClientRect().height
  );
  const chartWidth = getChartImageWidth(currentSnapshotView, chart)
  const canvas = document.createElement('canvas');
  canvas.height = chartHeight,
    canvas.width = chartWidth,
    canvas.style.backgroundColor = "white";
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = '#FFFFFF'
  ctx.fillRect(ZERO_POSITION_FOR_CANVAS, ZERO_POSITION_FOR_CANVAS, canvas.width, canvas.height)
  const chartImageURL = await getChartImageURL(
    plotlyChartData,
    chartWidth,
    chartHeight,
    currentSnapshotView
  )
  let imageobj = new Image();
  imageobj.onload = () => {
    ctx.drawImage(imageobj, ZERO_POSITION_FOR_CANVAS, ZERO_POSITION_FOR_CANVAS, chartWidth, chartHeight);
  }
  imageobj.src = chartImageURL
  return new Promise((resolve) => {
    setTimeout(resolve, PROMISE_WAITING_TIME_FOR_CHART)
  }).then(() => {
    return canvas;
  })
}

const getChartImageURL = (plotlyChartData, chartWidth, chartHeight, currentSnapshotView) => {
  const invisibleTag = document.createElement("DIV")
  return Plotly.newPlot( // eslint-disable-line no-undef
    invisibleTag,
    plotlyChartData.data,
    getPlotlyExportLayout(plotlyChartData, currentSnapshotView)
  ).then(gd => {
    return Plotly.toImage(gd, { // eslint-disable-line no-undef
      format: 'jpeg',
      height: chartHeight,
      width: chartWidth
    }).then((dataURL) => {
      return dataURL
    })
  })
}

export const getChartImageHeight = (currentChartHeight) => {
  if (currentChartHeight < CHART_IMAGE_MIN_HEIGHT) {
    return CHART_IMAGE_MIN_HEIGHT;
  } else {
    return currentChartHeight;
  }
}

const splittedDescription = (description, imageWidth, totalNumberOfTextSize = null,
  defaultSplitReg = DEFAULT_SPLIT_REGEX) => {
  let splittedRegex = imageWidth >= DEFAULT_MAIN_IMAGE_WIDTH ?
    defaultSplitReg : ZOOM_SPLIT_REGEX;
  splittedRegex = totalNumberOfTextSize ? totalNumberOfTextSize : splittedRegex;
  let splittedDescription = []
  const customDescription = description.querySelector('.custom_description>p')
  if (!_.isEmpty(customDescription)) {
    const customDescriptionText = customDescription.innerText;
    const footerChartLength = totalNumberOfTextSize ? 160 : FOOTER_CHAR_LENGTH;
    customDescriptionText.length > footerChartLength ?
      customDescriptionText.split(splittedRegex).filter(string => {
        _.isEmpty(string) ? null : splittedDescription.push(string)
      }) : splittedDescription.push(customDescriptionText);
  }
  let dynamicDescription = description.querySelector(".description>p")
    .innerHTML.replace(/(\r\n|\n|\r)/gm, " ");

  dynamicDescription.split(splittedRegex).filter((string) => {
    _.isEmpty(string) ? null : splittedDescription.push(string);
  });
  return splittedDescription
}


const splittedRadarDescription = (radarDescription) => {
  let splittedDescription = [];
  let dynamicDescription = radarDescription.replace(/(\r\n|\n|\r)/gm, " ");
  dynamicDescription.split(RADAR_DESCRIPTION_SPLIT_REGEX).filter((string) => {
    _.isEmpty(string) ? null : splittedDescription.push(string);
  });
  return splittedDescription;
}

const splittedTitle = (titleText, imageWidth) => {
  const splittedRegex = imageWidth >= DEFAULT_MAIN_IMAGE_WIDTH ?
    DEFAULT_SPLIT_TITLE_REGEX : ZOOM_SPLIT_TITLE_REGEX;
  let splittedTitle = []
  titleText.split(splittedRegex).filter((string) => {
    _.isEmpty(string) ? null : splittedTitle.push(string);
  });
  return splittedTitle
}

export const getChartImageWidth = (currentSnapshotView, chartContainer) => {
  const chartBoundingRect = chartContainer.querySelector('.svg-container').getBoundingClientRect()
  return _.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type) ?
    chartBoundingRect.width + PIE_CHART_INCREASING_WIDTH : chartBoundingRect.width
}

export const getLegendContainer = async (chartContainer) => {
  const legendContainer = chartContainer.querySelector('.legend-wrapper')
  if (!_.isEmpty(legendContainer)) {
    const legendDetailsElement = legendContainer.querySelector('.legend-details');
    const legendBenchMarkElement = legendContainer.querySelector('.legend-benchmark');
    const legendCategoriesElement = legendContainer.querySelector('.categories');

    const legendDetailsCanvas = legendDetailsElement ? getOverrideCanvas(legendDetailsElement) : null;
    const legendBenchMarkCanvas = legendBenchMarkElement ? getOverrideCanvas(legendBenchMarkElement) : null;
    const legendCategoriesCanvas = legendCategoriesElement ?
      getOverrideCanvas(legendCategoriesElement) : null;

    let overtimeLegendCanvas = await getHtmlToCanvas(legendDetailsElement, legendDetailsCanvas);
    let benchMarkCanvas = await getHtmlToCanvas(legendBenchMarkElement, legendBenchMarkCanvas);
    let categoryCanvas = await getHtmlToCanvas(legendCategoriesElement, legendCategoriesCanvas);

    if (_.isNull(overtimeLegendCanvas) && _.isNull(benchMarkCanvas) && _.isNull(categoryCanvas)) {
      const legendContainerCanvas = legendContainer ? getOverrideCanvas(legendContainer) : null;
      return await getHtmlToCanvas(legendContainer, legendContainerCanvas);
    } else {
      return await getLegendCanvas(overtimeLegendCanvas, benchMarkCanvas, categoryCanvas, legendContainer)
    }
  } else {
    return null
  }
}

export const getMetricTotalContainer = async (chartContainer) => {
  const metricTotalContainer = chartContainer.querySelector('.metric-total');
  if (!_.isEmpty(metricTotalContainer)) {
    const overrideCanvas = getOverrideCanvas(metricTotalContainer);
    const imgcanvas = await getHtmlToCanvas(metricTotalContainer, overrideCanvas);
    return imgcanvas;
  } else {
    return null
  }
}

export const getSummaryTableContainer = async (chartContainer) => {
  const summaryTableContainer = chartContainer.querySelector('.summary-table .table')
  if (!_.isEmpty(summaryTableContainer)) {
    const overrideCanvas = getOverrideCanvas(summaryTableContainer);
    const imgcanvas = await getHtmlToCanvas(summaryTableContainer, overrideCanvas);
    return imgcanvas;
  } else {
    return null
  }
}

export const getAdvanceSearchTableContainer = async (chartContainer) => {
  // const reportContainer = chartContainer.querySelectorAll('.view-comp-report-screen');
  const summaryTableContainer = chartContainer.querySelector('#comp-finder-summary');

  const buttonContainer = summaryTableContainer.querySelectorAll(".btn-remove");
  const tableContainer = summaryTableContainer.querySelector(".table");

  // summaryTableContainer.style.width = '100%';
  
  // if(tableContainer.scrollWidth > summaryTableContainer.scrollWidth){
  //   reportContainer[0].style.display = "inline-block";
  // }

  buttonContainer.forEach(element => {
    element.style.display = 'none';
  });

  let options = {
    height: window.outerHeight + window.innerHeight,
    width: window.outerWidth + window.innerWidth,
    windowHeight: window.outerHeight + window.innerHeight,
    scrollY: -window.scrollY,
    scrollX: -window.scrollX
  }

  options.height = summaryTableContainer.scrollHeight > options.height ?
    summaryTableContainer.scrollHeight + 400 : options.height;

  options.width = summaryTableContainer.scrollWidth > options.width ?
    summaryTableContainer.scrollWidth + 50 : options.width;

  // options.height = summaryTableContainer.scrollHeight + 200;
  // options.width = summaryTableContainer.scrollWidth + 50;
  // options.windowHeight = options.height;

  options.height = tableContainer.scrollHeight + 200;
  options.width = tableContainer.scrollWidth + 80;
  options.windowHeight = options.height;  

  if (!_.isEmpty(summaryTableContainer)) {
    const imgcanvas = await getHtmlToCanvas(tableContainer, null, options);
    buttonContainer.forEach(element => {
      element.style.display = 'block';
    });
    // summaryTableContainer.style.width = '80%'
    // reportContainer[0].style.display = "block" 
    return imgcanvas;
  } else {
    return null
  }
}

export const getTimeOfDayChartContainer = async (chartContainer) => {
  const timeOfDayChartContainer = chartContainer.querySelector('.time-of-day-day-of-week')
  if (!_.isEmpty(timeOfDayChartContainer)) {
    const overrideCanvas = getOverrideCanvas(timeOfDayChartContainer);
    const imgcanvas = await getHtmlToCanvas(timeOfDayChartContainer, overrideCanvas);
    return imgcanvas;
  } else {
    return null
  }
}

export const getRadarSummaryTableContainer = async (chartContainer) => {
  const radarSummaryTableContainer = chartContainer.querySelector('.radar-table-container')
  if (!_.isEmpty(radarSummaryTableContainer)) {
    radarSummaryTableContainer.style.boxShadow = "none";
    const overrideCanvas = getOverrideCanvas(radarSummaryTableContainer);
    const imgcanvas = await getHtmlToCanvas(radarSummaryTableContainer, overrideCanvas);
    return imgcanvas;
  } else {
    return null
  }
}

export const getRadarBarChartContainer = async (chartContainer) => {
  const radarBarChartContainer = chartContainer.querySelector('.radar-barchart-container')
  if (!_.isEmpty(radarBarChartContainer)) {
    radarBarChartContainer.style.boxShadow = "none";
    const overrideCanvas = getOverrideCanvas(radarBarChartContainer);
    const imgcanvas = await getHtmlToCanvas(radarBarChartContainer, overrideCanvas);
    return imgcanvas;
  } else {
    return null
  }
}

export const getRadarMapChartContainer = async (chartContainer) => {
  const radarMapChartContainer = chartContainer.querySelector('.radar-map-container')
  if (!_.isEmpty(radarMapChartContainer)) {
    radarMapChartContainer.style.boxShadow = "none";
    const overrideCanvas = getOverrideCanvas(radarMapChartContainer);
    const imgcanvas = await getHtmlToCanvas(radarMapChartContainer, overrideCanvas);
    return imgcanvas;
  } else {
    return null
  }
}

const getHtmlToCanvas = (htmlContainer, overrideCanvas = null, canvasOptions = null) => {
  const innerHtml = !_.isEmpty(htmlContainer) ? htmlContainer.innerHTML : null;

  const overrideOptions = canvasOptions ? canvasOptions : {};
  const options = overrideCanvas ? { canvas: overrideCanvas, scale: 1, logging: true } : overrideOptions;

  if (!_.isEmpty(innerHtml)) {
    return html2canvas(htmlContainer, options).then(canvas => {  // eslint-disable-line no-undef
      return canvas
    })
  } else {
    return null
  }
}

const getOverrideCanvas = (container) => {
  const canvas = document.createElement('canvas');
  const contentRect = container.getBoundingClientRect();
  const ctx = canvas.getContext('2d');

  canvas.style.backgroundColor = "#ffffff";
  canvas.width = contentRect.width + 30;
  canvas.height = contentRect.height;
  ctx.fillStyle = '#FFFFFF';
  ctx.font = IMAGE_FOOTER_FONT;

  return canvas;
}

const getLegendCanvas = (overtimeLegend, benchmark, category, legendContainer) => {
  const legendBoundingRect = legendContainer.getBoundingClientRect()
  const canvas = document.createElement('canvas');
  canvas.height = legendBoundingRect.height + EXCESS_LEGEND_HEIGHT;
  canvas.width = legendBoundingRect.width;
  canvas.style.backgroundColor = "white";
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = '#FFFFFF'
  ctx.fillRect(ZERO_POSITION_FOR_CANVAS, ZERO_POSITION_FOR_CANVAS, canvas.width, canvas.height)
  const overtimeLegendHeight = !_.isNull(overtimeLegend) ? overtimeLegend.height : ZERO_POSITION_FOR_CANVAS
  const benchmarkHeight = !_.isNull(benchmark) ? benchmark.height : ZERO_POSITION_FOR_CANVAS
  if (!_.isNull(overtimeLegend)) {
    ctx.drawImage(overtimeLegend, ZERO_POSITION_FOR_CANVAS, ZERO_POSITION_FOR_CANVAS)
  }

  if (!_.isNull(benchmark)) {
    let benchMarkYaxis = overtimeLegendHeight + YAXIS_FOR_LEGEND_OPTIONS
    ctx.drawImage(benchmark, ZERO_POSITION_FOR_CANVAS, benchMarkYaxis)
  }

  if (!_.isNull(category)) {
    let categoryYaxis = overtimeLegendHeight + benchmarkHeight + YAXIS_FOR_LEGEND_OPTIONS
    ctx.drawImage(category, ZERO_POSITION_FOR_CANVAS, categoryYaxis)
  }

  return new Promise((resolve) => {
    setTimeout(resolve, PROMISE_WAITING_TIME_FOR_LEGEND)
  }).then(() => {
    return canvas;
  })
}

const getPlotlyExportLayout = (plotlyChart, currentSnapshotView) => {
  const newPlotlyChart = _.cloneDeep(plotlyChart);
  if (_.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type) ||
    _.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type)) {
    newPlotlyChart.layout.margin = { l: 245, t: 10, b: 55, r: 30, pad: 5 }
  } else {
    newPlotlyChart.layout.margin = { l: 10, t: 10, b: 75, r: 30, pad: 5 }
  }
  if (!_.isEmpty(newPlotlyChart.layout.xaxis)) {
    newPlotlyChart.layout.xaxis.rangeslider = { visible: false }
  }
  return newPlotlyChart.layout;
}

export const getMapURL = (currentMaps, chartContainer, legendCanvas, options, metricTotalImage) => {
  return _.map(currentMaps, async (currentMap) => {
    if (!_.isNull(currentMap)) {
      const mapImageCanvas = currentMap.map.getCanvas();
      let mapImageUrl = await getMapWithTitleURL(
        chartContainer,
        mapImageCanvas,
        legendCanvas,
        options,
        currentMap.dateRange,
        metricTotalImage
      )
      return mapImageUrl;
    }
  });
}
