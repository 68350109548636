// Vendor Imports
import _ from 'lodash';

// Project Imports
import { getAllMetricEntries, isShowDistributionChart } from 'helpers/templateHelper';
import { getViewEntryQuickFilters } from 'pages/drilldown/components/QuickFilterBar/helper';
import {
  getDefaultVisualizationView,
  getDefaultGroupByColumn
} from 'common/config/visualizationConfiguration';
import {
  getDefaultVisualizationChartView,
  getDefaultDimensionField,
  isGeoLocationOrUrlColumn
} from 'helpers/visualizationHelper';
import {
  getGroupByEntries,
  getMapCenter,
  getMapzoom,
  getMapStyleEntries,
  getDefaultShapeDatasetEntry
} from 'common/config/templateConfiguration';
import { getDefaultSortOptionForTable } from 'modules/DetailsTable/TableHelper';
import {
  GROUP_BY_NONE_ENTRY,
  VISUALIZATION_TYPES
 } from 'appConstants';
 import {
  getDefaultShapeOverlayEntries,
  getMapViews
} from 'modules/Map/helpers/MapOptionsHelper';
 import { getSnapshotState, getOvertimeState, getDistributionState } from 'reducers/helper';
 import { getTemplateState } from 'reducers/drilldownReducer';
 import { updateStoreToUrl } from 'helpers/UrlParamsHelper';
import {
  getCommonFilters,
} from 'pages/drilldown/components/Bookmark/bookmarkHelper';

export const getEmbedLink = (index, istesting = false) => {
  const isOverViewPage = location.hash.split('?')[0].match('overview');
  const isAnalysisPage = location.hash.split('?')[0].match('analysis');
  let url;
  let cardId = '01';

  if (isOverViewPage) {
    if(!_.isNil(index)) {
      const templateEntries= _.get(window.serverConfig, 'customerConfiguration.template_entries', []);
      const { templateEntry, viewEntry } = _.get(getAllMetricEntries(templateEntries), index, {});
      cardId = getCardId(templateEntry, viewEntry);

      url = getMetricUrl(cardId);
    } else {
      return 'Can you please give card index';
    }
  } else if(isAnalysisPage) {
    cardId = new Date().getTime();
    url = window.location.origin + '/embed/' + window.location.hash;
  } else {
    return 'Invalid url';
  }

  if (istesting) {
    return getLink(cardId, url);
  }

  return console.log(getLink(cardId, url));
};

export const getMetricUrl = (cardId)  => {
  const hashString = updateStoreToUrl('!/overview', window.defaultState);

  return window.location.origin + '/embed/' + cardId + '/#'+ hashString;
}

export const getLink = (cardId, url) => {
  return '<script>' +
    "window.addEventListener('message', function(e) {" +
      "if (e.data[0] === 'setHeight') {" +
        `document.getElementById('tyler-ei-embed-${cardId}').height = e.data[1] + 'px';` +
      '}' +
    '}, false);' +
  `</script><iframe id='tyler-ei-embed-${cardId}' scrolling='no' frameborder='0' border='0'
  marginwidth='0' marginheight='0' height='505' src='${url}' width='100%'></iframe>`;
}

export const getCardId = (templateEntry, viewEntry) => {
  const templateId = _.get(templateEntry, 'template_id', '');
  const viewId = _.get(viewEntry, 'view_id', '');

  return `${templateId}${viewId}`;
}

export const getCollectionCardEntries = (cardId, templateEntries) => {
  if(_.isEmpty(cardId)) {
    return {};
  }

  let groupedEntries = {};
  _.each(templateEntries, (templateEntry) => {
    _.each(templateEntry['view_entries'],(viewEntry) => {
      const id = getCardId(templateEntry, viewEntry);
      groupedEntries[id] = { templateEntry, viewEntry };
    });
  });

  return _.get(groupedEntries, cardId, {});
}

export const getViewMetricLinkForDashboardCard = (templateEntry, viewEntry, state) => {
  const templateId = _.get(templateEntry, 'template_id');
  const cardId = getCardId(templateEntry,viewEntry);
  const quickFilters = getViewEntryQuickFilters(templateId, viewEntry);
  const defaultVizView = getDefaultVisualizationView(viewEntry);
  const snapshotView = getDefaultVisualizationChartView(viewEntry, VISUALIZATION_TYPES.SNAPSHOT.type);
  const groupEntries = getGroupByEntries(templateId);
  const defaultSort = getDefaultSortOptionForTable(viewEntry, templateId)
  let currentVisualizationType = defaultVizView;
  if(!isShowDistributionChart(viewEntry) &&
    defaultVizView === VISUALIZATION_TYPES.DISTRIBUTION.type) {
    currentVisualizationType = VISUALIZATION_TYPES.SNAPSHOT.type;
  }
  let drilldownGroupByEntry = GROUP_BY_NONE_ENTRY;
  if(currentVisualizationType === VISUALIZATION_TYPES.SNAPSHOT.type){
    const defaultGroupByColumn = getDefaultGroupByColumn(viewEntry);
    const groupByColumn = isGeoLocationOrUrlColumn(defaultGroupByColumn) ?
      _.get(groupEntries, '0.column'):
      defaultGroupByColumn;
    drilldownGroupByEntry = _.find(groupEntries, { 'column': groupByColumn });
  }

  const drilldownDimensionField = getDefaultDimensionField(templateEntry.dimension_entries, viewEntry);
  const commonFilters = _.get(state, 'commonFilters', {});

  const snapshotDefaultOptions = {
    currentDrilldownTemplateName: _.get(templateEntry, 'name'),
    currentDrilldownTemplateId: templateId,
    currentDrilldownViewEntry: viewEntry,
    currentDrilldownDimensionField: drilldownDimensionField,
    currentDrilldownGroupByEntry: drilldownGroupByEntry,
    currentSnapshotView: snapshotView,
    commonFilters
  };

  const viewBasedDefaultMapView = getDefaultVisualizationChartView(viewEntry, VISUALIZATION_TYPES.MAP.type);
  const currentMapView = _.get(getMapViews(), viewBasedDefaultMapView);
  const centerAndZoom = {
    center: getMapCenter(templateId),
    zoom : getMapzoom(templateId)
  };

  const snapShot = getSnapshotState(snapshotDefaultOptions);
  const overTime = getOvertimeState(snapshotDefaultOptions);
  const distribution = getDistributionState(snapshotDefaultOptions, true);

  const newState = _.cloneDeep(state)

  newState['drilldown'] = getTemplateState(state, templateEntry, viewEntry);
  newState['drilldown']['currentVisualizationType'] = currentVisualizationType;
  newState['drilldown']['quickFilters'] = quickFilters;
  newState['dashboard']['currentCardId'] = cardId;
  newState['visualization']['snapshot'] = snapShot;
  newState['visualization']['overtime'] = overTime;
  newState['visualization']['distribution'] = distribution;
  newState['visualization']['table']['sortColumns'] = defaultSort || [];
  //set default map Options
  newState['visualization']['mapOptions']['currentMapStyleEntry'] = getMapStyleEntries(templateId)[0];
  newState['visualization']['mapOptions']['centerAndZoom'] = centerAndZoom;
  newState['visualization']['mapOptions']['selectedOutlines'] = getDefaultShapeOverlayEntries(templateId);
  newState['visualization']['mapOptions']['shape']['datasetEntry'] = getDefaultShapeDatasetEntry(templateId);
  newState['visualization']['mapOptions']['currentMapView'] = currentMapView;

  const url = updateStoreToUrl('!/analysis', newState);
  const embedUrl = `${window.location.origin}/#${url}`;

  return embedUrl;
}

export const getBookmarkAnalysisLink = (bookmarkEntry, state) => {
  let newEntry = {
    ...bookmarkEntry,
    isBookmarkCard: true
  }
  let commonFilters = getCommonFilters(newEntry, _.get(bookmarkEntry, 'commonFilters'));
  const newState = _.cloneDeep(state);
  newState['drilldown'] = _.get(bookmarkEntry, 'drilldown');
  newState['visualization']= _.get(bookmarkEntry, 'visualization');
  newState['commonFilters'] = commonFilters

  const url = updateStoreToUrl('!/analysis', newState);
  const analysisUrl = `${window.location.origin}/#${url}`;

  return analysisUrl;
}
