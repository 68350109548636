import {
  isMonitorEnabled,
  getBenchMarkEntries,
  getBenchmarkAxisRangeValues,
  getDateRangeBasedOnMonitor,
  getTransformedCardEntry
} from 'pages/SubscriptionsManager/components/Bookmark/helper';
import {
  VISUALIZATION_TYPES,
  DEFAULT_BOOKMARK_DIMENSION_OPTION,
} from 'appConstants';
import { getApiParams } from 'helpers/apiParamsHelper';
import { getVisualizationBenchMarkTotals, getDimenisonWiseData } from 'common/api/drilldown';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getCurrentViewEntry } from 'common/config/templateConfiguration';
import { getMetricTotalData } from 'common/api/metricTotal';

export default class BookmarkApiHelper {
  constructor(bookmark) {
    this._bookmark = bookmark;
    this._queryParams = this._getParams(bookmark);
    this.benchMarkApiController = new AbortController();
    this.dimensionApiController = new AbortController();
  }

  async fetchBenchMarkValues() {
    const { bookmarkOptions, drilldown, visualization } = this._bookmark;
    const { currentVisualizationType } = drilldown;
    const currentVizChartType = _.get(bookmarkOptions, 'currentVizChartType', []);
    const bookmarkEntries = getBenchMarkEntries(drilldown, visualization, currentVizChartType);
    const isNotEmptyBookmarkColumn = !_.isEmpty(_.find(bookmarkEntries, 'column'));
    const isOvertimeVisualizationType = _.isEqual(
      currentVisualizationType, VISUALIZATION_TYPES.OVERTIME.type);
    let benchmarkValues = [];

    if (isNotEmptyBookmarkColumn && isOvertimeVisualizationType) {
      try {
        this.benchMarkApiController.abort();
        this.benchMarkApiController = new AbortController();
        const benchmarkApiUrl = getVisualizationBenchMarkTotals(this._queryParams);
        const response = await fetchApiData(benchmarkApiUrl, this.benchMarkApiController);
        benchmarkValues = _.map(bookmarkEntries, (bookmarkEntry) => {
          const { field } = bookmarkEntry;
          return _.get(response, [0, field]);
        });
      } catch (e) {
        console.log('fetch fetch benchMark values failed:', e);
        return { benchmarkValues: [], isLoading: false };
      }
    } else {
      _.forEach(bookmarkEntries, (bookmarkEntry) => {
        benchmarkValues = benchmarkValues.concat(
          getBenchmarkAxisRangeValues(bookmarkEntry)
        );
      });
    }

    return { benchmarkValues, isLoading: false };
  }

  async fetchDimensionOptionValues(){
    const { bookmarkOptions } = this._bookmark;
    let dimensionOption = _.get(bookmarkOptions, 'dimensionOption', DEFAULT_BOOKMARK_DIMENSION_OPTION);

    if (dimensionOption === DEFAULT_BOOKMARK_DIMENSION_OPTION || !isMonitorEnabled(this._bookmark)){
      return { dimensionWiseData: [], isLoading: false };
    }

    try {
      const queryParams = {
        ...this._queryParams,
        dimensionField: dimensionOption
      };

      this.dimensionApiController.abort();
      this.dimensionApiController = new AbortController();
      const dimensionUrl = getDimenisonWiseData(queryParams);
      const response = await fetchApiData(dimensionUrl, this.dimensionApiController);

      return { dimensionWiseData: response, isLoading: false };
    } catch (e) {
      console.log('fetch dimension option values failed:', e);
      return { dimensionWiseData: [], isLoading: false };
    }
  }

  async fetchMetricTotal(){
    try {
      const {
        queryParams, viewEntry, dimensionWiseData, benchmarkValues
      } = await this._getMetricTotalConfig();
      const { currentPeriodMetricTotals } = await getMetricTotalData({ queryParams, viewEntry });
      const overrideOptions = { dimensionWiseData, benchmarkValues, bookmark: this._bookmark };

      return _.isEmpty(currentPeriodMetricTotals) ?
        { dimensionWiseData, benchmarkValues, bookmark: this._bookmark } :
        _.merge({}, currentPeriodMetricTotals, overrideOptions);
    } catch (e) {
      console.log('fetch metric total failed:', e);
      return {};
    }
  }

  _getParams(bookmark){
    let commonFilters = _.get(bookmark, 'commonFilters', {});
    commonFilters.dateRange = getDateRangeBasedOnMonitor(bookmark);
    const apiParams = getApiParams(bookmark, {});

    const queryParams = {
      ...apiParams,
      commonFilters: JSON.stringify(commonFilters),
    };

    return queryParams;
  }

  async _getMetricTotalConfig() {
    const { drilldown, commonFilters } = this._bookmark;
    const { currentDrilldownTemplateId, currentDrilldownViewEntry } = drilldown;
    const viewId = _.get(currentDrilldownViewEntry, 'view_id');
    const viewEntry = getCurrentViewEntry(currentDrilldownTemplateId, viewId);

    const { benchmarkValues } = await this.fetchBenchMarkValues();
    const { dimensionWiseData } = await this.fetchDimensionOptionValues();

    const cardEntryWithDimensionData = getTransformedCardEntry(
      this._bookmark,
      dimensionWiseData,
      benchmarkValues
    );
    const apiParams = getApiParams(cardEntryWithDimensionData, {});
    const queryParams = {
      ...apiParams,
      commonFilters: JSON.stringify(commonFilters),
    };

    return { queryParams, viewEntry, benchmarkValues, dimensionWiseData };
  }
}
