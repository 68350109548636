import React from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';

const BackButton = ({ onClick }) => {
  return (
    <ForgeIconButton dense="true" densityLevel="3" className="forge-back-btn">
      <button
        type="button"
        onClick={onClick}
        aria-label="go back advance search page">
        <ForgeIcon name="arrow_back" />
      </button>
    </ForgeIconButton>
  )
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
