import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import {
  updateStoreFromUrl
} from 'helpers/UrlParamsHelper';
import { resetStoreState } from 'actions/rootActions';
import GlobalEvent from 'common/components/GlobalEvents';
import {  PREVIOUS_URL_UPDATED } from 'appConstants';

class UndoButton extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      lastUrls: []
    };
  }

  componentDidMount() {
    this.setState({ lastUrls: this.getLastUrls() });
    GlobalEvent.on(PREVIOUS_URL_UPDATED, this.handleUrlUpdate);
  }
  componentDidUpdate(previousProps) {
    const { currentStore } = this.props;
    const { lastUrls } = this.state;
    const newLastUrls = this.getLastUrls();
    if(!_.isEqual(lastUrls, newLastUrls) || !_.isEqual(previousProps.currentStore, currentStore)) {
      setTimeout(() => {
        this.setState({ lastUrls: this.getLastUrls() });
      }, 500);
    }
  }

  componentWillUnmount() {
    GlobalEvent.off(PREVIOUS_URL_UPDATED, this.handleUrlUpdate);
  }

  getLastUrls = () => {
    return window.lastUrls || [];
  }

  handleUrlUpdate = () => {
    console.log('url updated');
    const lastUrls = this.getLastUrls();
    this.setState({ lastUrls });
  }

  onUndoClick = () => {
    const { currentStore } = this.props;
    const  { lastUrls } = this.state;
    lastUrls.pop(); //remove current url
    const previousUrl = lastUrls.pop(); //remove previousUrl
    if(!_.isEmpty(previousUrl)) {
      const hashString = _.split(previousUrl, '?')[1];
      const newStore = updateStoreFromUrl(currentStore, hashString, true);
      window.lastUrls = lastUrls;
      this.props.dispatchUndoChanges(newStore);
    }
  }

  render() {
    const lastUrls = this.state.lastUrls;
    const shouldDisable = lastUrls.length <= 1 && window.isUndoEnabled;

    if(lastUrls.length <= 1 && !window.isUndoEnabled) {
      return null;
    }
    return(
      <ForgeButton type="outlined">
        <button type="button" onClick={this.onUndoClick} disabled={shouldDisable}>
          <ForgeIcon name='undo' className="mr-0 pr-1" />
          <span> Undo </span>
        </button>
      </ForgeButton>
    )
  }
}

UndoButton.propTypes = {
  currentStore: PropTypes.object,
  dispatchUndoChanges: PropTypes.func
};


function mapDispatchToProps(dispatch) {
  return {
    dispatchUndoChanges: (previousStore) => {
      dispatch(resetStoreState(previousStore));
    }
  }
}

function mapStateToProps(state) {
  return {
    currentStore: state
  };
}

UndoButton.propTypes = {
  dispatchUndoChanges: PropTypes.func,
  currentStore: PropTypes.object
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UndoButton));