import { apiDataHelper } from './Helper/apiDataHelper';
import { LineChartOptionsHelper } from './Helper/LineChartOptionsHelper';
import {
  formatProjectionEntries,
  getComboChartProjection,
  getTotalLastPoint,
  showHistoricProjections,
  updateAdjustedValueToValue
} from './Helper/dataHelper';
import {
  getProjectionExtraItems,
  getProjectionPeriods
} from './../Helpers/projectionHelper';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import {
  getBienniumProjectionDimension,
  isBienniumFiscalYear
} from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';
import {
  updateAdjustedValueForData,
  getGroupByQuarterData
} from './../Helpers/overtimeHelper';
import {
  EXPONENTIAL_FORECAST,
  PROPHET_FORECAST,
  HISTORICAL_FORECAST,
  FORECASTING_TYPE
} from 'appConstants';
import { getDummyProjections } from './../Helpers/projectionDataGenerator';
import { isQuarterPeriod } from './../vizOptionsHelper';
export class projectionDataHandler {
  constructor(rawApiData, vizOptions) {
    this.rawApiData = rawApiData;
    this.vizOptions = vizOptions;
    this.apiDataHelper = new apiDataHelper(rawApiData, vizOptions);
    this.lineChartOptionsHelper = new LineChartOptionsHelper(rawApiData, vizOptions);
  }

  getProjectionData() {
    const { isForecastingView } = this.vizOptions;
    return !isForecastingView ? {
      total: this._getTotalProjectionLineData(),
      entries: this._formatProjectionRawEntries(),
      combo_projection_total: this._getComboChartProjectionTotal()
    } : {
      total_last_current: this._getTotalLastPoint(),
      forecastingProjections: this._getForecastingPageProjection()
    }
  }

  _getTotalProjectionLineData() {
    const totalData = this.apiDataHelper.getTotal();
    const comparisonTotal = this.apiDataHelper.getComparisonTotal();
    const data = _.concat(totalData, comparisonTotal);
    const currentPeriods = this.lineChartOptionsHelper.getCurrentPeriods();
    const extraItems = getProjectionExtraItems(this.apiDataHelper.formatApiData(), this.vizOptions, 'total')

    return this.apiDataHelper.isTotalLineEnabled() ?
      formatProjectionEntries(data, currentPeriods, this.vizOptions, extraItems) : [];
  }

  _formatProjectionRawEntries () {
    const entries = this.apiDataHelper.getEntries();
    const rawApiData = this.rawApiData;
    const apiData = this.apiDataHelper.formatApiData();
    const currentPeriods = this.lineChartOptionsHelper.getCurrentPeriods();
    const { renderTimeFrame, dateRange, compareYearRanges, isComboChart } = this.vizOptions;

    if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges) && !isComboChart) {
      let formattedResult = {};
      const dataItems = _.get(rawApiData, 'total', []);
      const extraItems = getProjectionExtraItems(rawApiData, this.vizOptions, 'total');
      const dimension = isBienniumFiscalYear(this.vizOptions) && _.size(compareYearRanges) > 1 ?
        getBienniumProjectionDimension(entries, this.vizOptions) :
        _.chain(entries).keys().first().value();

      formattedResult[dimension] = formatProjectionEntries(dataItems, currentPeriods,
        this.vizOptions, extraItems, dimension);
      return formattedResult;
    } else {
      return _.mapValues(entries, (dataItems, dimension) => {
        const compareDataItems = _.get(apiData, 'comparison_dimension_entries_result.' + dimension, []);
        const projectionEntries = _.get(
          getProjectionExtraItems(apiData, this.vizOptions, 'dimensions'), dimension, []
        );

        const formatProjectionEntriesData = formatProjectionEntries(
          _.concat(dataItems, compareDataItems),
          currentPeriods,
          this.vizOptions,
          projectionEntries, dimension);
        return formatProjectionEntriesData;
      });
    }
  }

  _getComboChartProjectionTotal() {
    const comboChartSecondaryResults = this.apiDataHelper.getComboMetrics();
    const apiData = this.apiDataHelper.formatApiData();
    const currentPeriods = this.lineChartOptionsHelper.getCurrentPeriods();
    const isForecastingView = _.get(this.vizOptions, 'isForecastingView', false);
    return _.map(comboChartSecondaryResults, (results, index) => {
      return _.mapValues(results.total, (metricValues, dimension) => {
        const newMetricValues = isForecastingView ?
            updateAdjustedValueForData(metricValues, this.vizOptions, dimension ) : metricValues;
        return getComboChartProjection(
            newMetricValues, currentPeriods, this.vizOptions, apiData, dimension, index);
      });
    });
  }

  _getTotalLastPoint() {
    const { isForecastingView } = this.vizOptions;

    const totalData = this.apiDataHelper.getTotalLastPointData();
    const currentPeriods = this.lineChartOptionsHelper.getCurrentPeriods();
    const extraItems = getProjectionExtraItems(this.apiDataHelper.formatApiData(), this.vizOptions, 'total')
    return isForecastingView ?
      getTotalLastPoint( totalData, currentPeriods, this.vizOptions, extraItems,undefined, true) : [];
  }

  _getForecastingPageProjection(){
    const { isForecastingView } = this.vizOptions;

    return isForecastingView ? this._getForecastingProjections() : []
  }

  _getForecastingProjections() {
    // Try to simplify this function by breaking it down into smaller functions
    const dataItems = _.concat(updateAdjustedValueToValue(
      this.apiDataHelper.formatApiData(), this.vizOptions, true),
      this.apiDataHelper.getComparisonTotal)
    const currentPeriods = this.lineChartOptionsHelper.getCurrentPeriods();
    const apiData = updateAdjustedValueToValue(this.apiDataHelper.formatApiData(), this.vizOptions)
    const forecastingOption = _.get(this.vizOptions, 'forecastingOption', {});
    const forecastModelOptions = _.get(this.vizOptions.forecastingOption,'forecastModelOptions',[]);
      let totalProjections = [];
      const projectionEnabled = _.get(this.vizOptions, 'projectionEnabled', false);
      const projections = _.get(forecastingOption, 'projectionTypes');
      let forecastVizOptions = _.cloneDeep(this.vizOptions);
      if(_.includes(projections, PROPHET_FORECAST)) {
        forecastVizOptions['projectionType'] = PROPHET_FORECAST;
        const prophetForecast = _.find(forecastModelOptions, {type: FORECASTING_TYPE.PROPHET});
        const previousDataItems = getProjectionExtraItems(apiData, forecastVizOptions , 'total');
        totalProjections.push(
          {
            data: formatProjectionEntries(dataItems, currentPeriods, forecastVizOptions, previousDataItems),
            type: FORECASTING_TYPE.PROPHET,
            name: prophetForecast['name']
          }
        );
      }

      if(_.includes(projections, EXPONENTIAL_FORECAST)) {
        forecastVizOptions['projectionType'] = FORECASTING_TYPE.SIMPLE_EXPONENTIAL;
        const exponentialForecast = _.find(forecastModelOptions, {type: FORECASTING_TYPE.SIMPLE_EXPONENTIAL});
        const previousDataItems = getProjectionExtraItems(apiData, forecastVizOptions , 'total');
        totalProjections.push(
          {
            data: formatProjectionEntries(dataItems, currentPeriods, forecastVizOptions, previousDataItems),
            type: FORECASTING_TYPE.SIMPLE_EXPONENTIAL,
            name: exponentialForecast['name']
          }
        );
      }

      if( _.includes(projections, HISTORICAL_FORECAST) &&
        !showHistoricProjections(dataItems, this.vizOptions, currentPeriods)) {
        forecastVizOptions['projectionType'] = HISTORICAL_FORECAST;
        const historicModels = _.filter(forecastModelOptions, {type: FORECASTING_TYPE.HISTORICAL_AVG});
        _.forEach(historicModels, (model) => {
          const previousDataItems = getProjectionExtraItems(apiData, forecastVizOptions,
                                    'multiple', `percent_${model.value}`);
          totalProjections.push({
            data: formatProjectionEntries(dataItems, currentPeriods, forecastVizOptions, previousDataItems),
            type: FORECASTING_TYPE.HISTORICAL_AVG,
            name : model['name'],
            color: model['color']
          });
        });
      } else if(!projectionEnabled) {
        const projectionPeriods = getProjectionPeriods(this.vizOptions);
        const dummyData = getDummyProjections(projectionPeriods)
        totalProjections.push(
          {
            data: isQuarterPeriod(this.vizOptions) ?
              getGroupByQuarterData(dummyData, this.vizOptions, true) : dummyData,
            type: "None"
          }
        )
      }

    return totalProjections;
  }

}