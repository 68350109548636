import React, { useState } from 'react';

import MetricTotal from 'common/components/MetricTotal';
import ComparisonTooltip from 'common/components/ComparisonTooltip';
import ComparisonValueSelector from 'common/components/ComparisonValueSelector';

import { getDrilldownEntryParams } from 'pages/dashboard/components/TemplateCards/TemplateCard';
import { getDrilldownEntryWithoutViewEntry } from 'helpers/templateHelper';
import { disableMetricTotal, getComparisonMode } from 'common/config/viewConfiguration';
import { enableComparisonMode, isTimeDurationEnabled } from 'common/config/customerConfiguration';
import { getComparisonTooltipAttributes } from 'helpers/comparisonHelper';
import { getComparisonPeriodDateRanges } from 'helpers/dateHelper';

import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { sanitizeCommonFilterParam } from 'helpers/apiParamsHelper';

const ComparisonSelector = (props) => {
  const {
    viewEntry, cardEntry, commonFilters, updateCurrentDimensionTotal,
    isEmptyData, visualizationType, drilldown, mapOptions
  } = props;
  const [comparisonData, setComparisonData] = useState([]);
  const comparisonModeOn = _.get(commonFilters, 'comparisonModeOn', false);
  const comparisonMode = getComparisonMode(viewEntry);
  const shouldShowComparison = (enableComparisonMode() && comparisonModeOn &&
    !isEmptyData && comparisonMode !== 'off'
  );
  const disabledTotal = disableMetricTotal(viewEntry);
  const tooltipId = _.get(viewEntry, 'name');
  const drilldownEntry = !_.isEmpty(drilldown) ?
    drilldown :
    getDrilldownEntryParams(cardEntry, viewEntry);
  const drilldownWithoutViewEntry = getDrilldownEntryWithoutViewEntry(
    drilldownEntry, visualizationType
  );

  const dateRangeMode = _.get(commonFilters, 'dateType');
  const dateRange = _.get(commonFilters, 'dateRange');
  const comparisonDateRanges = shouldShowComparison ? getComparisonPeriodDateRanges(commonFilters): [];
  const queryParams = {
    drilldownEntry: JSON.stringify(drilldownWithoutViewEntry),
    commonFilters: JSON.stringify(sanitizeCommonFilterParam(commonFilters)),
    polygonGeoJson: JSON.stringify(_.get(mapOptions, 'filteredGeojson', '{}')),
    shapeGroupId: _.get(mapOptions, 'shape.datasetEntry.shape_dataset_id'),
    currentDrilldownTemplateId: _.get(drilldownEntry, 'currentDrilldownTemplateId'),
    shapeIds: _.get(mapOptions, 'shape.selectedShapeIds', []).join(',')
  };

  const onMetricDataLoaded = (total, isLoading, secondaryTotal, response) => {
    setComparisonData(response);
    updateCurrentDimensionTotal(total, isLoading, secondaryTotal, response);
  }

  const renderTotalAndComparison = () => {
    if (shouldShowComparison) {
      const tooltipAttributes = getComparisonTooltipAttributes({
        metricTotalData: comparisonData,
        viewEntry, secondaryMetricEntry: {}, tooltipId,
        comparisonDateRanges, dateRangeMode, dateRange
      });
      const comparisonTooltipAttributes = _.get(tooltipAttributes, '[0]', {});
      return (
        <ComparisonTooltip {...comparisonTooltipAttributes}>
          {renderTotalAndComparisonContent(comparisonTooltipAttributes)}
        </ComparisonTooltip>
      );
    }

    return renderTotalAndComparisonContent({});
  }
  const renderMetricTotal = () => {
    const showAsDuration = isTimeDurationEnabled(viewEntry);

    return(
      <MetricTotal
        isComparison={shouldShowComparison}
        dateRangeMode={dateRangeMode}
        comparisonDateRanges={comparisonDateRanges}
        queryParams={queryParams}
        viewEntry={viewEntry}
        showAsDuration={showAsDuration}
        updateCurrentDimensionTotal={onMetricDataLoaded}
        shouldShowTemplateTotal={true}
      />
    );
  }

  const renderTotalAndComparisonContent = (tooltipAttributes) => {
    return (
      <span>
        <div className="target-value forge-typography--headline4">
          {renderMetricTotal()}
        </div>
        {shouldShowComparison &&
          <ComparisonValueSelector {...tooltipAttributes} viewEntry={viewEntry} isSmallView={true} />
        }
      </span>
    );
  }
  const className = disabledTotal ? 'display-none' : '';

  return (
    <div className={`target align-self-center ${className}`}>
      {!disabledTotal ? renderTotalAndComparison() : renderMetricTotal()}
    </div>
  );
}

ComparisonSelector.propTypes = {
  cardEntry: PropTypes.object,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  onComparisonDataLoaded: PropTypes.func,
  updateCurrentDimensionTotal: PropTypes.func,
  options: PropTypes.object,
  visualizationType: PropTypes.string,
  isEmptyData: PropTypes.bool,
  drilldown: PropTypes.object,
  mapOptions: PropTypes.object
}

ComparisonSelector.defaultProps = {
  cardEntry: {},
  updateCurrentDimensionTotal: _.noop,
  isEmptyData: false,
  drilldown: {}
}

export default React.memo(ComparisonSelector);
