import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ForgeAppBarSearch } from '@tylertech/forge-react';
import HeaderSearchList from './HeaderSearchList';

function HeaderSearch() {
	const [searchState, setSearchState] = useState({
		collectionsWithMetricList: [],
		redirectToAnalysis: false,
		searchText: "",
	});
	const commonFilters = useSelector(state => _.get(state, 'commonFilters', {}));
	const globalFilters = useSelector(state => _.get(state, 'globalFilters', {}));
  const history = useHistory();

	const onSelectMetric = () => {
		setSearchState(
			{
				...searchState,
				redirectToAnalysis: true,
				searchText: ''
			})
	}

	const updateMetricList = (collectionsWithMetricList) => {
		setSearchState({...searchState, collectionsWithMetricList})
	}

	useEffect(() => {
		if(searchState.redirectToAnalysis){
			setSearchState({...searchState, redirectToAnalysis: false});
			history.push('/analysis');
		}
	}, [searchState.redirectToAnalysis]);

  return (
		<>
			<ForgeAppBarSearch
				slot="center"
				className="header-search"
			>
				<HeaderSearchList
					updatedSelectedMetric={onSelectMetric}
					updatedSearchtext={searchState.searchText}
					globalFilters={globalFilters}
					commonFilters={commonFilters}
					userCollectionsWithCards={searchState.collectionsWithMetricList}
					updateMetricList={updateMetricList}
				/>
			</ForgeAppBarSearch>
		</>
  )
}

export default HeaderSearch;