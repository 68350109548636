import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { numberWithCommaSeparate } from './PaginationHelper';

class Pagination extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  static defaultProps = {
    showCurrentPageRowInfo: true
  };

  handleKeyDown = (e, navigationDirection) => {
    if (isEnterButtonPressed(e)) {
      this.props.switchPage(navigationDirection)
    }
  }

  renderCurrentPageRowInfo() {
    const {
      totalRecordsCount,
      currentPage,
      itemsPerPage,
      showCurrentPageRowInfo,
      enableShowText
    } = this.props;
    const recordStartsFrom = currentPage === 1 ? currentPage :
      (((currentPage * itemsPerPage) - itemsPerPage) + 1);
    const recordEndsTo = (currentPage * itemsPerPage) > totalRecordsCount ?
      totalRecordsCount : (currentPage * itemsPerPage);

    if (!showCurrentPageRowInfo) {
      return null;
    }

    return (
      <div className="current-page-row-count align-self-center d-flex">
        <div className="row-count">
          {enableShowText ? 'Show' : null}
          <span> {numberWithCommaSeparate(recordStartsFrom)} - {numberWithCommaSeparate(recordEndsTo)} </span>
          <span> of </span>
          <span>{numberWithCommaSeparate(totalRecordsCount)}</span>
        </div>
      </div>
    );
  }

  render() {
    const {
      currentPage,
      itemsPerPage,
      totalRecordsCount,
      switchPage,
      isHideIcon,
      showInfoInside
    } = this.props;
    const diasablePrevButton = (currentPage === 1);
    const diasableNextButton = (currentPage * itemsPerPage) >= totalRecordsCount;
    if (totalRecordsCount < itemsPerPage) {
      return null;
    }
    return (
      <div className="pagination d-flex justify-content-end">
        {showInfoInside ? null : this.renderCurrentPageRowInfo()}

        <div className="custom-pagination d-flex">
          <div
            role="button"
            className="previous-btn"
            onClick={() => switchPage('previous')}
            onKeyDown={(e) => this.handleKeyDown(e, 'previous')}
            disabled={diasablePrevButton}
            tabIndex={diasablePrevButton ? -1 : 0}
            aria-label="Previous page">
            {isHideIcon ?
              <span className="text-primary" >Previous</span> :
              <i className="icons-chevron-left"></i>}
          </div>
          {showInfoInside ? this.renderCurrentPageRowInfo() : null}
          <div
            role="button"
            className="next-btn"
            onClick={() => switchPage('next')}
            onKeyDown={(e) => this.handleKeyDown(e, 'next')}
            disabled={diasableNextButton}
            tabIndex={diasableNextButton ? -1 : 0}
            aria-label="Next page">
            {isHideIcon ?
              <span className="text-primary" >Next</span> :
              <i className="icons-chevron-right"></i>}
          </div>
        </div>
      </div>
    );
  }
}

Pagination.defaultPropTypes = {
  enableShowText: false,
  showInfoInside: false
}

Pagination.propTypes = {
  totalRecordsCount: PropTypes.number,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  switchPage: PropTypes.func,
  showCurrentPageRowInfo: PropTypes.bool,
  isHideIcon: PropTypes.bool,
  showInfoInside: PropTypes.bool,
  enableShowText: PropTypes.bool
}

export default Pagination;
