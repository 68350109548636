// Vendor Imports
import React, { useState } from 'react';

// Project Imports
import SummaryTableDataContext from 'context/SummaryTableDataContext';
import { getChartAndSummaryConfig } from 'modules/visualization/ChartAndSummaryHelper';
import ChartFlyoutTable from 'modules/visualization/ChartFlyoutTable';
import ViewMetricLink from './ViewMetricLink';
import { VISUALIZATION_TYPES } from 'appConstants';
import { ForgeButtonToggle, ForgeButtonToggleGroup, ForgeIcon } from '@tylertech/forge-react';

const EMBED_TABS = ['Chart', 'Summary Table'];

const EmbedTabs = (props) => {
  const { isEmbed, viewMetricLink, element, vizType } = props;
  const [formattedData, setFormatData] = useState([]);
  const [vizOptions, setVizOptions] = useState({});
  const [type, setType] = useState(EMBED_TABS[0]);

  const onUpdateSummaryData = (newFormattedData, newVizOptions) => {
    setFormatData(newFormattedData);
    setVizOptions(newVizOptions);
  }

  const renderSummaryTableData = () => {
    const {
      tableData, tableHeaders, groupByDimensionField, flyoutValueField, isStackedBar
    } = getChartAndSummaryConfig({ formattedData, vizOptions });
    const height = element.current.getBoundingClientRect().height;

    return (
      <ChartFlyoutTable
        height={height}
        isStackedBar={isStackedBar}
        tableHeaders={tableHeaders}
        tableData={tableData}
        groupByDimensionField={groupByDimensionField}
        flyoutValueField={flyoutValueField}
      />
    );
  }

  const renderTabGroup = () => {
    return (
      <ForgeButtonToggleGroup>
        {_.map(EMBED_TABS, renderTab)}
      </ForgeButtonToggleGroup>
    );
  }

  const renderTab = (tab) => {
    const isSelected = type === tab;
    return (
      <ForgeButtonToggle
        value={tab}
        selected={isSelected}
        button-aria-label={tab}
        onClick={() => setType(tab)}>
        {tab === EMBED_TABS[0] && <ForgeIcon name="bar_chart" />}
        {tab === EMBED_TABS[1] && <ForgeIcon name="table_large" />}
      </ForgeButtonToggle>
    );
  }

  const renderTabs = () => {
    return (
      <SummaryTableDataContext.Provider value={{ onUpdateSummaryData: onUpdateSummaryData }}>
        <div className='embed-metric-card-small'>
          {type === EMBED_TABS[0] && props.children}
          {type === EMBED_TABS[1] && renderSummaryTableData()}
          <div className='embed-tabs-footer'>
            <nav className="nav nav-pills embed-tabs">
              {vizType !== VISUALIZATION_TYPES.MAP.type &&
               vizType !== 'none' && renderTabGroup()}
            </nav>
            <ViewMetricLink isEmbed={isEmbed} viewMetricLink={viewMetricLink} />
          </div>
        </div>
      </SummaryTableDataContext.Provider>
    );
  }

  return isEmbed ? renderTabs() : props.children;
}

EmbedTabs.defaultProps = {
  isEmbed: false,
  vizType: ''
}

export default EmbedTabs;
