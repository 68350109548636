import {
  getLinearBuckets,
  DEFAULT_COLOR,
  DEFAULT_LEGEND_COLORS
} from "modules/Map/helpers/choroplethMapHelper";
import { prefixText, suffixText } from 'helpers/chartDataHelper';
import { SINGLE_VALUE } from "appConstants";

const DEFAULT_COLOR_BY_BUCKET_COUNT = 10;

export const getTimeOfDayColorBuckets = _.memoize((data, viewEntry = {}) => {
  return getColorByBuckets(data, viewEntry);
}, (data) => `${JSON.stringify(data)}`);

export const getColorByBuckets = (weekWiseData, viewEntry) => {
  if(_.isEmpty(weekWiseData)) {
    return [];
  }
  const weekWiseTotals = getWeekWiseTotals(weekWiseData);
  const linearBuckets =  getLinearBuckets(_.min(weekWiseTotals),
    _.max(weekWiseTotals),DEFAULT_COLOR_BY_BUCKET_COUNT
  );
  const prefix = prefixText({viewOptions: viewEntry}, false);
  const singularSuffix = suffixText({viewOptions: viewEntry}, true);
  const pluralSuffix = suffixText({viewOptions: viewEntry}, false);

  return _.chain(0).
    range(linearBuckets.length - 1, 1).
    map((index) => {
      const bucketFromSuffix = canUseSingular(linearBuckets[index]) ? singularSuffix : pluralSuffix;
      const bucketToSuffix = canUseSingular(linearBuckets[index + 1]) ? singularSuffix : pluralSuffix;
      const bucketFrom = `${prefix}${linearBuckets[index]}${bucketFromSuffix}`;
      const bucketTo = `${prefix}${linearBuckets[index + 1]}${bucketToSuffix}`;

      return {
        start: linearBuckets[index],
        end: linearBuckets[index + 1],
        label: `${bucketFrom} - ${bucketTo}`,
        color: DEFAULT_LEGEND_COLORS[index]
      };
    }).
    value();
};

export const getCellColor = ({
  weekWiseData, colorBuckets, weekOption, dayOption, defaultColor, selectedColor, selectedCells
}) => {
  const { total } = getColumnTotalAndCategories(weekOption, dayOption, weekWiseData);

  if(!_.isEmpty(selectedCells) && selectedColor) {
    const matchedCell = _.find(selectedCells, function(selectedCell) {
      return (selectedCell.day == weekOption.day && selectedCell.hour == dayOption.hour)
    });
    return matchedCell ? { color: selectedColor, total: 0 } : { color: defaultColor, total: 0 };
  } else {
    if (_.isEmpty(weekWiseData) || _.isNil(total)) {
      return { color: defaultColor || DEFAULT_COLOR, total };
    } else if(!_.isNil(total)) {
      return { color: selectedColor || getColorBasedOnValue(colorBuckets, total), total };
    }
  }
}

export const getColorBasedOnValue = (colorByBuckets, value) => {
  const rangeBucket = _.find(colorByBuckets, (colorByBucket, index) => {
    let isLastBucket = index === (colorByBuckets.length - 1);

    if (isLastBucket) {
      return colorByBucket;
    }
    return value >= colorByBucket.start && value < colorByBucket.end;
  });

  return _.isEmpty(rangeBucket) ? DEFAULT_COLOR : rangeBucket.color;
};

function getWeekWiseTotals(weekWiseData) {
  const weekWiseTotals = [];

  _.each(weekWiseData, function(weekWiseDatum) {
    _.each(weekWiseDatum, function(datum) {
      weekWiseTotals.push(datum.total);
    });
  });

  return weekWiseTotals;
}

export function getColumnTotalAndCategories(weekOption, dayOption, weekWiseData){
  const day = weekOption.id;
  const hour = dayOption.id;
  const columnValue = _.get(weekWiseData, [day, hour, 'total']);
  const weekWiseDataCategories = _.get(weekWiseData, [day, hour, 'split_up'], {});
  const columnCategories = _.chain(weekWiseDataCategories).
    map((value, category) => ({ value, category })).
    orderBy('value', 'desc').
    value();

  return { total: columnValue, categories: columnCategories };
}

export const canUseSingular = (value) => {
  return (Number(value) === SINGLE_VALUE)
}
