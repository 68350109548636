import React from 'react';
import PropTypes from 'prop-types';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';

const SearchDropdownListItems = (props) => {
  const {
    searchValues, onRemoveSearchItem, onBlurSearchItem, showSearchItems,
    isCurrencyRenderType, className, showRemoveIcon, values, onClickItem
  } = props;
  const onKeydownSearchItem = (e, searchItem) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      onRemoveSearchItem(searchItem, true);
    }
  }

  const onKeydownItem = (e, searchItem) => {
    if (isEnterButtonPressed(e)) {
      onClickItem(searchItem);
    }
  }

  const renderSearchListItem = (searchItem, index) => {
    const searchName = _.isObject(searchItem) ? searchItem['name'] : searchItem;
    const searchValue = _.isObject(searchItem) ? searchItem['shape_id'] : searchItem;
    const listClassName = _.includes(values, searchValue) ? 'active' : '';

    return(
      <li
        className={`list-group-item ${listClassName}`} key={index} tabIndex={0}
        onClick={() => onClickItem(searchItem)}
        onKeyDown={(e) => onKeydownItem(e, searchItem)}
        >
        {formatValueToCurrency(searchName + '', isCurrencyRenderType)}
        {showRemoveIcon &&
          <button
            tabIndex={0}
            aria-label="remove selected value"
            onKeyDown={(e) => onKeydownSearchItem(e, searchItem)}
            onClick={() => onRemoveSearchItem(searchItem, true)}
            onBlur={() => onBlurSearchItem(index)}
            className="value-close ml-auto">
            <i className="icons-times" />
          </button>
        }
      </li>
    );
  }
  const renderSearchItems = _.map(searchValues, renderSearchListItem);

  const renderSearchDropDownWithValues = () => {
    return (
      <div className={className}>
        <ul className="list-group">
          {renderSearchItems}
        </ul>
      </div>
    );
  }

  return showSearchItems ? renderSearchItems : renderSearchDropDownWithValues();
}

SearchDropdownListItems.propTypes = {
  className: PropTypes.string,
  searchValues: PropTypes.array.isRequired,
  showSearchItems: PropTypes.bool,
  isCurrencyRenderType: PropTypes.bool,
  showRemoveIcon: PropTypes.bool,
  values: PropTypes.array,
  onClickItem: PropTypes.func,
  onBlurSearchItem: PropTypes.func,
  onRemoveSearchItem: PropTypes.func
}

SearchDropdownListItems.defaultProps = {
  className: 'value-list dropdown-menu show w-100',
  values: [],
  showSearchItems: false,
  isCurrencyRenderType: false,
  showRemoveIcon: true,
  onBlurSearchItem: _.noop,
  onRemoveSearchItem: _.noop,
  onClickItem: _.noop
}

export default (SearchDropdownListItems);
