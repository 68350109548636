import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

class LoadingSpinner extends Component {

  render() {
    const { isLoading, size, className } = this.props;

    if(!isLoading) {
      return null;
    }

    return (
      <div className="spinner-overlay">
        <Spinner variant="primary" className={className} size={size} animation="border" />
      </div>
    );
  }
}

LoadingSpinner.defaultProps = {
  isLoading: false,
  size: 'default',
  className: 'loading-spinner'
}

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
}

export default (LoadingSpinner);
