import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getAbbreviatedStringValue } from 'helpers/numberHelper';
import DrilldownPath from './DrilldownPath';
import NestedResullt from './NestedResult';

class PriorPeriodResult extends Component {
  state = {
    expandedRowIndex: null
  }

  toggleRowExpansion = (index) => {
    const { isChildTable } = this.props;
    const { expandedRowIndex } = this.state;

    if (isChildTable) { 
      return;
    }
    const newExpandedRowIndex = (expandedRowIndex === index) ? null : index

    this.setState({ expandedRowIndex: newExpandedRowIndex });
  }

  renderVarianceTableRows = (varianceItem, index) => {
    const { primaryMetricField } = this.props;
    const { expandedRowIndex } = this.state;

    if(_.isEmpty(varianceItem)) {
      return null;
    }

    const { variance, item, entities } = varianceItem;
    const { v1, v2, v1_group_sample_size, v2_group_sample_size, total_sample_size, score } = item;
    
    return [
      <tr key={`detail-${index}`} onClick={() => this.toggleRowExpansion(index)}>
        <td>
          <DrilldownPath 
            fieldValues={entities} 
            snapshotComparePriorYear={true} 
            secondaryMetricField={primaryMetricField} 
          />
        </td>
        <td className="text-right">{getAbbreviatedStringValue(variance, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(v1, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(v2, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(v1_group_sample_size, 0, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(v2_group_sample_size, 0, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(total_sample_size, 0, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(score, 0, true, true)}</td>
      </tr>,
      (expandedRowIndex === index) ? this.renderExpandedRowDetails(varianceItem, index) : null
    ];
  }

  renderExpandedRowDetails = (varianceItem, index) => {
    const { fetchVarianceData, fetchAnovaData } = this.props;
    return (
      <tr key={`child-${index}`}>
        <td colSpan="8">
          <NestedResullt 
            fetchVarianceData={fetchVarianceData} 
            fetchAnovaData={fetchAnovaData}
            parentVarianceItem={varianceItem} 
            render={ (childVarianceData) => (
              <PriorPeriodResult {...this.props} isChildTable={true} varianceData={childVarianceData}/> 
            )}
          />
        </td>
      </tr>
    );
  }

  render() {
    const { varianceData } = this.props;
    if(_.isEmpty(varianceData)) {
      return null;
    }

    return (
      <table className="table table-bordered table-sm">
        <thead>
          <tr>
            <th className="text-center">Path</th>
            <th className="text-center">Variance</th>
            <th className="text-center">Current Period Value</th>
            <th className="text-center">Previous Period Value</th>
            <th className="text-center">Current Period Sample Size</th>
            <th className="text-center">Previous Period Sample Size</th>
            <th className="text-center">(Prev + Current)Total SS</th>
            <th className="text-center">Score</th>
          </tr>
        </thead>
        <tbody>
          {_.map(varianceData, this.renderVarianceTableRows)}
        </tbody>
      </table>
    );
  }
}

PriorPeriodResult.propTypes = {
  isChildTable: PropTypes.bool,
  primaryMetricField: PropTypes.string,
  varianceData: PropTypes.array.isRequired,
  fetchVarianceData: PropTypes.func,
  fetchAnovaData: PropTypes.func
}

export default PriorPeriodResult;
