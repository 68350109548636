import { fetchApiData } from "helpers/apiResponseHelper";
import { buildQueryString } from "helpers/HttpHelper";

export const getAdvanceSearchValues = (queryParams, abortController) => {
  const apiUrl = "/api/advanced_search/get_advance_search_values.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}

export const getAdvanceSearchResult = (queryParams, abortController) => {
  const apiUrl = "/api/advanced_search/search_result.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}

export const getSubjectData = (queryParams, abortController) => {
  const apiUrl = "/api/advanced_search/subject_result.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}

export const getReportData = (queryParams, abortController) => {
  const apiUrl = "/api/advanced_search/report_result.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}

export const getMapFlyoutData = (queryParams, abortController) => {
  const apiUrl = "/api/advanced_search/map_flyout_details.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}

export const getDistinctData = (queryParams, abortController) => {
  const apiUrl = "/api/advanced_search/distinct_values.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}

export const getReportDistinctData = (queryParams, abortController) => {
  const apiUrl = "/api/advanced_search/report_distinct_values.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}

export const checkImageExists = (params, abortController) => {
  const queryParams = {
    country: _.get(params, 'comp_finder_country', '').replace(/County/i, "").trim(),
    quickRefId: _.get(params, 'comp_finder_quick_ref_id', ''),
    taxYear: _.get(params, 'comp_finder_tax_year', '')
  };  
  const apiUrl = "/api/advanced_search/check_external_image.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}
