import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import SecondaryHeader from 'common/components/SecondaryHeader/SecondaryHeader';
import Tabs from '../Tabs/Tabs';

import { enableCollectionLevelDateFilters } from 'common/config/customerConfiguration';

class AppContent extends Component {

  /*componentDidMount() {
    // Browser back button event is handled
    window.onpopstate = function (evt) {
      if (evt.state) {
        window.location.reload(true);
      }
    };
  }*/

  render() {
    const { location } = this.props;
    const isAnalysisPage = location.pathname === '/analysis';
    if(location.pathname === '/about') {
      return null;
    }

    const mainClass = classNames('main-content', {
      'add-global-filter': !enableCollectionLevelDateFilters()
    });

    return (
      <main id="content" className={mainClass} role="main">
        {!isAnalysisPage && <SecondaryHeader /> }
        <section className="position-relative">
          <div className="app-content" id="app-root-id">
            <Tabs />
          </div>
        </section>
      </main>
    );
  }
}

AppContent.propTypes = {
  location: PropTypes.object
}

export default withRouter((AppContent));
