import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { isCustomDateBeginType, shouldShowComparison } from 'common/config/customerConfiguration';
import {
  BIENNIUM_FISCAL_YEAR,
  DATE_COMPARISON_TYPE,
  OVERTIME_TIME_FRAME_OPTIONS
} from 'appConstants';
import { updateSnapshotSecondaryMetricField }  from 'actions/snapshotActions';

// Actions Import
import {
  updateSliderRange,
  updateOvertimeTimeFrame,
  updateOvertimeShowProjection,
  updateAxisGranularity
} from 'actions/overtimeActions';
import {
  updateComparisonModeOn,
  updateComparisonDateRanges,
  updateComparisonType,
  updateDateRange,
  updateDateType,
  updateRelativeDateFilterEntry,
  updateDashboardComparisonDateRanges,
  updateDashboardComparisonType
} from 'actions/commonFiltersActions';
import { isWeekGranularityOnComparision } from 'modules/visualization/LineChart/vizOptionsHelper';
import { getDateRangeTextEntry, getSelectedYearsForBienniumYear } from 'helpers/dateHelper';
import { getCompareSamePeriodLastYearOptions } from 'helpers/fiscalPeriodUtils';
// import { updateComparison } from 'actions/mapOptionsActions';

const dateRangeClickHandler = (Component) => {
  const wrappedComponent = (props) => {
    const {
      dispatchDateRangeChange,
      dispatchUpdateDateType,
      dispatchUpdateRelativeDateFilterEntry,
      dispatchUpdateComparisonType,
      dispatchUpdateComparisonModeOn,
      dispatchUpdateComparisonDateRanges,
      // Only send the original props (+onDateFilterChange) to the component. Do not send other
      // props received from redux to the component.
      ...componentProps
    } = props;

    const onDateFilterChange = (options) => {
      const { dateRange, dateType, relativeDateFilterEntry, isDashboardView } = options;
      // const compareRange = getLastPeriodDateRange(
      //   _.cloneDeep(dateRange),
      //   dateType
      // );
      dispatchUpdateDateType(dateType);
      dispatchUpdateRelativeDateFilterEntry(relativeDateFilterEntry);
      dispatchDateRangeChange(dateRange);
      dispatchUpdateComparisonModeOn(false);
      dispatchUpdateComparisonType(DATE_COMPARISON_TYPE.SAME_PERIOD, [], options, isDashboardView);
    }

    return (
      <Component
        {...componentProps}
        onDateFilterChange={onDateFilterChange}
        onComparisonTypeChange={dispatchUpdateComparisonType}
        onCompareDateRangesChange={dispatchUpdateComparisonDateRanges}
        onChangeComparisonModeOn={dispatchUpdateComparisonModeOn}
      />
    );
  };

  wrappedComponent.propTypes = {
    dispatchDateRangeChange: PropTypes.func,
    dispatchUpdateDateType: PropTypes.func,
    dispatchUpdateRelativeDateFilterEntry: PropTypes.func,
    dispatchUpdateComparisonType: PropTypes.func,
    dispatchUpdateComparisonModeOn: PropTypes.func
  };

  return wrappedComponent;
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchDateRangeChange: (dateRange) => {
      if (isCustomDateBeginType() && !shouldShowComparison(dateRange)) {
        dispatch(updateComparisonModeOn(false));
      }
      dispatch(updateDateRange(dateRange));
      dispatch(updateSliderRange([]));
    },
    dispatchUpdateDateType: (dateType) => {
      dispatch(updateDateType(dateType));
    },
    dispatchUpdateRelativeDateFilterEntry: (relativeDateFilterEntry) => {
      dispatch(updateRelativeDateFilterEntry(relativeDateFilterEntry))
    },
    dispatchUpdateComparisonType: (type, dateRanges, options, isDashboardView) => {
      if(isDashboardView){
        dispatch(updateDashboardComparisonType(type));
        dispatch(updateDashboardComparisonDateRanges(dateRanges));
      }
      dispatch(updateComparisonType(type));
      dispatch(updateComparisonDateRanges(dateRanges));
      if(type == DATE_COMPARISON_TYPE.SAME_PERIOD){
        dispatch(updateOvertimeTimeFrame(OVERTIME_TIME_FRAME_OPTIONS.YEAR_ON_YEAR));
        if(isWeekGranularityOnComparision(options)){
          dispatch(updateAxisGranularity('month'))
        }
      }else{
        dispatch(updateOvertimeTimeFrame(OVERTIME_TIME_FRAME_OPTIONS.ROLLING));
      }
      dispatch(updateOvertimeShowProjection(false));
    },
     dispatchUpdateComparisonDateRanges: (dateRanges) => {
      dispatch(updateComparisonDateRanges(dateRanges));
    },
    dispatchUpdateComparisonModeOn:(isComparisonModeOn, comparisonType = null,
      comparisonDateRanges = [], options = []) => {
      if (isComparisonModeOn) {
        // dispatch(updateComparison({}));
        dispatch(updateSnapshotSecondaryMetricField());
        if(comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD && isWeekGranularityOnComparision(options)){
          dispatch(updateAxisGranularity('month'))
        }
      }

      if(comparisonType) {
        dispatch(updateComparisonType(comparisonType));
        dispatch(updateDashboardComparisonType(comparisonType));
        const rangeEntry = isComparisonModeOn? 
              getDateRangeTextEntry(options?.dateRange, options?.dateType): [];
        if(options?.dateType === BIENNIUM_FISCAL_YEAR){
          if(options?.isDashboardView){
          dispatch(updateDashboardComparisonDateRanges([rangeEntry?.range]));
          dispatch(updateComparisonDateRanges([rangeEntry?.range]));
          }else{
            const yearOptions = getCompareSamePeriodLastYearOptions(
              { primaryDateRange: options?.dateRange, primaryDateType: options?.dateType,
                 templateId: options?.currentDrilldownTemplateId });
            const selectYears = getSelectedYearsForBienniumYear(yearOptions, 
              comparisonDateRanges, options?.dateType);
            dispatch(updateComparisonDateRanges(isComparisonModeOn ? [selectYears[0]?.range] : []));
          }
        }else{
          if(options?.isDashboardView){
            dispatch(updateDashboardComparisonDateRanges(isComparisonModeOn ? comparisonDateRanges : []));
          }else{
            dispatch(updateComparisonDateRanges(isComparisonModeOn ? comparisonDateRanges : []));
          }
        }
        if(comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD){
          dispatch(updateOvertimeTimeFrame(OVERTIME_TIME_FRAME_OPTIONS.YEAR_ON_YEAR));
        }else{
          dispatch(updateOvertimeTimeFrame(OVERTIME_TIME_FRAME_OPTIONS.ROLLING));
        }
        dispatch(updateOvertimeShowProjection(false));        
      }
      dispatch(updateComparisonModeOn(isComparisonModeOn));

    }
  }
}

export const DateRangeHandlerWrapper = compose(
  connect(null, mapDispatchToProps),
  dateRangeClickHandler
);
