
import PropTypes from 'prop-types';
import React, { Component } from "react"
import { createShortenUrl } from 'common/api/drilldown';
import { toast } from 'react-toastify';
import { getHeaderIconColor } from 'common/config/customerConfiguration';
import { copyLinkSuccessMessage, copyLinkErrorMessage } from 'helpers/toastMessages';
import LoadingSpinner from '../LoadingSpinner';
import { trackEvent } from 'helpers/eventTracking';

class ShortenUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  copyUrlToClipboard = async () => {
    const { trackEventName } = this.props;
    
    const result = await this.getTinyUrl();
    setTimeout(() => {
      if (!_.isEmpty(result.short_link)) {
        if(!_.isEmpty(trackEventName)){
          trackEvent(trackEventName);
        }
        toast.success(copyLinkSuccessMessage)
      }
    }, 500);
    return result.short_link;
  }

  getTinyUrl = async () => {
    const longUrl = window.location.href;
    const apiParams = {
      original_url: longUrl,
      short_url_key: this.randomPassCode()
    }

    this.setState({ isLoading: true });
    let resultLink;
    await createShortenUrl(apiParams)
      .then(response => response.json())
      .then((response) => {
        resultLink = response
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toast.error(copyLinkErrorMessage);
        console.error(err);
      });
    return resultLink
  }

  randomPassCode() {
    return Array(10).fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)]
      }).join('');
  }

  render() {
    const { buttonText, isDisabled } = this.props
    const { isLoading } = this.state
    const iconContent = <i className="icons-share-alt" style={{ color: `${getHeaderIconColor()}` }}></i>
    const buttonTextOrIcon = _.isEmpty(buttonText) ? iconContent : buttonText;
    return (
      <div>
        <LoadingSpinner isLoading={isLoading} />
        <forge-button type="outlined">
          <button
            className="copy-link-btn text-nowrap"
            onClick={async () => navigator.clipboard.writeText((await this.copyUrlToClipboard()))}
            disabled={isDisabled}>
            {buttonTextOrIcon}
          </button>
          <forge-tooltip text="Copy a shortened link of this page" delay={500} position="top" />
        </forge-button>
      </div>
    )
  }
}
ShortenUrl.propTypes = {
  buttonText: PropTypes.string,
  trackEventName: PropTypes.string,
  isDisabled: PropTypes.bool
}
export default ShortenUrl;
