import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function UploadUserContent({ onClose, onTemplateDownload, onUpload }) {
  const [file, setFile] = useState([]);

  const changeHandler = (event) => {
    const file = event.target.files[0];
    setFile(file);
  }

  const renderFileImportSection = () => {
    return (
      <>
        <div className="modal-body">
          <div>Upload a .csv file created from this&nbsp;
            <a className="template-href" onClick={() => onTemplateDownload(true)}>template</a>
            &nbsp;in order to add multiple users at the same time.
          </div>
          <input className='form-control user-upload' type="file" id="fileLoader" name="files"
            title="Load File" onChange={changeHandler} />
        </div>
      </>
    );
  }

  const renderExportFooter = () => {
    return (
      <>
        <button
          tabIndex={0}
          className="btn btn-link mr-auto"
          onClick={onClose}>
          Cancel
        </button>
        <button
          tabIndex={0}
          className="btn btn-primary px-7"
          onClick={() => onUpload(file)}>
          Upload
        </button>
      </>
    );
  }


  return (
    <Modal size="lg" show={true} className="export-modal">
      <div className="modal-wrapper">
        <Modal.Header>
          <Modal.Title> Upload Users </Modal.Title>
          <button className='close' aria-label="close" onClick={onClose}>
            <i className="icons-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          {renderFileImportSection()}
        </Modal.Body>

        <Modal.Footer>
          {renderExportFooter()}
        </Modal.Footer>
      </div>
    </Modal>
  )
}

UploadUserContent.propTypes = {
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  onTemplateDownload: PropTypes.func
}