import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeButton } from '@tylertech/forge-react';

class AlertDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:true
    };
  }

  componentDidMount(){
    if(this.confirmInput){
      setTimeout(() => {
        this.confirmInput.focus();
      }, 100);
    }
  }

  handleClickConfirm = (event) => {
    const { onConfirmationCallBack } = this.props;
    this.setState({showModal:false});
    event.preventDefault();
    event.stopPropagation();
    onConfirmationCallBack(true);
  };

  handleKeyDownConfirm = (e, isShow) => {
    if(isEnterButtonPressed(e)) {
      this.onCloseConfirm(e, isShow);
    }
  }

  handleKeyDownRemove = (e) => {
    if(isEnterButtonPressed(e)) {
      this.handleClickConfirm(e);
    }
  }

  onCloseConfirm(event,isShow) {
    const { onConfirmationCallBack } = this.props;
    event.stopPropagation();
    this.setState({ showModal: isShow });
    onConfirmationCallBack(false);
  }

  renderConfirmModal() {
    const { showModal } = this.state;
    const { message, buttonText } = this.props;

      if(!showModal){
       return
      }

      return (
        <Modal show={true} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
          <div className="modal-wrapper">
              <Modal.Body>
                <div className="mb-4">
                  <span>{message}</span>
                </div>
                <ForgeButton type="raised" className="float-right">
                  <button
                    ref={ref => { this.confirmInput = ref; }}
                    aria-label="Confirm"
                    className="btn btn-primary"
                    tabIndex={0}
                    onClick={(event) => this.handleClickConfirm(event)}
                    onKeyDown={this.handleKeyDownRemove}>{buttonText}
                  </button>
                </ForgeButton>
              </Modal.Body>
          </div>
      </Modal>
      )
  }

  render() {

    return (
      <>
        {this.renderConfirmModal()}
      </>
    )
  }
}

AlertDialogue.propTypes = {
  onConfirmationCallBack: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string
}

export default AlertDialogue;
