// Vendor imports
import React from 'react';
import { Spinner } from 'react-bootstrap';

// Project Imports
import * as commonPropTypes from 'common/propTypes';
import { getComparisonMode } from 'common/config/viewConfiguration';
import { formatChartTotalText, getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getDateRangeTextEntry } from 'helpers/dateHelper';

const TooltipContent = (props) => {
  const {
    currentPeriodTotal, previousPeriodTotal, actualDifference, percentage, viewEntry,
    dateRangeMode, primaryDateRange, comparisonDateRange
  } = props;
  const comparisonMode = getComparisonMode(viewEntry);
  const currentRangeText = _.get(getDateRangeTextEntry(primaryDateRange, dateRangeMode),'text');
  const previousRangeText = _.get(getDateRangeTextEntry(comparisonDateRange, dateRangeMode),'text');
  let currentTotal, previousTotal, difference, discreteValue, actualDifferenceValue;
  if (_.isUndefined(currentPeriodTotal) && _.isUndefined(previousPeriodTotal)) {
    return (
      <table>
        <tbody>
          <Spinner variant="primary" size="sm" animation="border" />
        </tbody>
      </table>
    );
  } else {
    currentTotal = formatChartTotalText(currentPeriodTotal, {viewOptions: viewEntry}, true, false, false);
    previousTotal = formatChartTotalText(previousPeriodTotal, {viewOptions: viewEntry}, true, false, false);
    actualDifferenceValue = formatChartTotalText(
      actualDifference, 
      {viewOptions: viewEntry}, 
      true, 
      false, 
      false
    );
    discreteValue = getFormattedNumberValue(difference, viewEntry, true, false, true);
  }

  return(
    <table>
      <tbody>
        <tr>
          <th className="text-left comparison-column">{currentRangeText}</th>
          <td className="text-left comparison-value">{currentTotal}</td>
        </tr>
        <tr>
          <th className="text-left comparison-column">{previousRangeText}</th>
          <td className="text-left comparison-value">{previousTotal}</td>
        </tr>
        { comparisonMode === 'percentage' ?
          <>
            <tr>
              <th className="text-left comparison-column">Actual Change</th>  
              <td className="text-left comparison-value">{`${actualDifferenceValue || 0}`}</td>
            </tr>
            <tr>
              <th className="text-left comparison-column">Percentage Change</th>
              <td className="text-left comparison-value">{`${percentage || 0}%`}</td>
            </tr>
          </>
        :
          <tr>
            <th className="text-left comparison-column">Change</th>
            <td className="text-left comparison-value">{discreteValue}</td>
          </tr>
        }
        </tbody>
    </table>
  )
};

TooltipContent.propTypes = {
  currentPeriodTotal: commonPropTypes.stringOrNumberProps,
  previousPeriodTotal: commonPropTypes.stringOrNumberProps,
  percentage: commonPropTypes.stringOrNumberProps,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  comparisonDateRange: commonPropTypes.dateRangePropTypes,
  primaryDateRange: commonPropTypes.dateRangePropTypes,
  dateRangeMode: commonPropTypes.stringOrNumberProps,
  actualDifference: commonPropTypes.stringOrNumberProps
}

export default TooltipContent;
