import _ from 'lodash';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import { getAbbreviatedStringValue, convertAbbreviatedStringValue } from 'helpers/numberHelper';

import {
  SINGLE_VALUE,
  NO_OF_BENCH_MARKS,
  DEFAULT_DROPDOWN_OPTION,
  OVERTIME_TIME_FRAME_OPTIONS
} from 'appConstants';
import { getDateRangeDifference, YEAR_DAYS_COUNT } from 'helpers/dateHelper';
import { getSecondsAsDuration, getNearestRoundOffValue } from './visualizationHelper';
import { isTimeDurationEnabled,
  timeDurationDataUnit,
  isNearestRoundOffDisplayAsEnabled } from 'common/config/customerConfiguration';

export const prefixText = (chartOptions, useSecondaryMetric = false) => {
  const useSecondaryComparison = canUseSecondaryComparison(chartOptions);
  const isSecondaryMetricComparison = (
    (useSecondaryComparison || useSecondaryMetric) &&
    !_.get(chartOptions, 'secondaryComparison.isComparisonEntry', false)
  );
  const displayFormatEntry = isSecondaryMetricComparison ?
    chartOptions.secondaryComparison : chartOptions.viewOptions;
  const prefixValue = _.get(displayFormatEntry, 'prefix', '');

  return `${prefixValue}`;
}

export  const suffixText = (chartOptions, useSingular, useSecondaryMetric = false) => {
  const useSecondaryComparison = canUseSecondaryComparison(chartOptions);
  const isSecondaryMetricComparison = (
    (useSecondaryComparison || useSecondaryMetric) &&
    !_.get(chartOptions, 'secondaryComparison.isComparisonEntry', false)
  );
  const displayFormatEntry =  isSecondaryMetricComparison ?
  chartOptions.secondaryComparison : chartOptions.viewOptions;
  const suffix = getSuffixByVersion(displayFormatEntry, useSingular);
  const appendSuffixSpace = isSuffixSpaceNeeded(suffix) ? ' ' : '';
  return `${appendSuffixSpace}${suffix}`;
}

export const getSuffixByVersion = (displayFormatEntry, useSingular = false) => {
  const singularSuffix = _.get(displayFormatEntry, 'singular_suffix', '');
  if (!_.isEmpty(singularSuffix) && useSingular) {
    return singularSuffix;
  } else {
    return _.get(displayFormatEntry, 'plural_suffix', '');
  }
}

export const getCurrentDrilldownDimensionColumn = (templateId, drilldownDimensionField) => {
  const currentDrilldownTemplateEntries = getDimensionEntries(templateId);
  return _.get(_.find(currentDrilldownTemplateEntries, ['field', drilldownDimensionField]), 'column', '');
};

export const formatHoveroverText = (chartData ,chartOptions, useSecondaryComparison = false) => {
  const displayFormatEntry = useSecondaryComparison ?
    chartOptions.secondaryComparison : chartOptions.viewOptions;
  return _.map(chartData, (chartValue) => {
    if (_.isObject(chartValue)) {
      return ('<b>' + _.get(chartValue, 'dimension', '(No-value)') + '</b><br>' +
        '<i>' + getFormattedNumberValue(chartValue.count, displayFormatEntry) + '</i>'
      );
    } else {
      return getFormattedNumberValue(chartValue, displayFormatEntry);
    }
  });
}

export const formatChartTotalText = (
  totalValue,
  chartOptions,
  removeNegativeForZero = false,
  useSecondaryComparison = false,
  isConvertAbbreviated = false,
  isHidePrefixSuffix = false
  ) => {
  if(_.isUndefined(totalValue)){
    return '';
  }
  const displayFormatEntry = useSecondaryComparison ?
    chartOptions.secondaryComparison : chartOptions.viewOptions;
   return getFormattedNumberValue(
    totalValue,
    displayFormatEntry,
    removeNegativeForZero,
    isConvertAbbreviated,
    isHidePrefixSuffix, false);
}

// TODO: Remove this when code cleanup.
/*export const getBenchMarkMaxValue = (chartData, benchMarkEntry) => {
  if (_.isEqual(benchMarkEntry, DEFAULT_DROPDOWN_OPTION)){
    return 0;
  }
  const benchMarksRange = _.range(0, NO_OF_BENCH_MARKS);
  const valueKeys = _.map(benchMarksRange,  (benchMarkIndex) =>{
    return benchMarkIndex > 0 ? `value${benchMarkIndex}`: 'value';
  });
  const benchMarkValues = _.values(_.pick(benchMarkEntry, valueKeys));

  return Number(_.maxBy(benchMarkValues, function(value) { return Number(value); }));
}*/

export const getBenchMarkLines = (chartData, benchMarkEntry) => {
    if (_.isEqual(benchMarkEntry, DEFAULT_DROPDOWN_OPTION)){
      return [];
    }
  const benchMarksRange = _.range(0, NO_OF_BENCH_MARKS);
  const valueKeys = _.map(benchMarksRange,  (benchMarkIndex) =>{
    return benchMarkIndex > 0 ? `value${benchMarkIndex}`: 'value';
  })
  const benchMarkValues = _.values(_.pick(benchMarkEntry, valueKeys))

  return _.map(benchMarkValues, (value) => {
    return benchMarkLine(chartData, value, benchMarkEntry);
  })
}

const benchMarkLine = (chartData, value, benchMarkEntry) => {
  const formattedName = `${_.get(benchMarkEntry, 'name', '')} - ${value}`;
  const yAxixLable1 = _.first(chartData);
  const yAxixLable2 = _.last(chartData);
  return {
    y: [yAxixLable1, yAxixLable2],
    x: [Number(value), Number(value)],
    mode: 'lines+markers+text',
    showlegend: false,
    type: 'line',
    hoverinfo: 'none',
    customdata: [['benchMark', formattedName]],
    line: {
      color: '#000',
      width: 1,
      dash: 'dot'
    },
    clickmode:false
  }
}

const canUseSecondaryComparison = (chartOptions) => {
  const secondaryComparison = _.get(chartOptions, 'secondaryComparison',  {});
  const isSecondaryComparisonSelected = !_.isEmpty(_.get(secondaryComparison, 'field', ''))
  const chartType = _.get(secondaryComparison, 'render_type', '');
  const { isSecondaryMetric } = chartOptions;

  if(_.includes(['timeline', 'burn_up'], _.get(chartOptions, 'currentChartOption.type')) &&
    isSecondaryMetric){
    return isSecondaryComparisonSelected;
  }else{
    return isSecondaryComparisonSelected && (chartType === 'bullet' || chartType === 'bar')
  }
}

export const getFormattedValue = (
  value,
  entry,
  removeNegativeForZero = false,
  isConvertAbbreviated = false,
  isHidePrefixSuffix = false
  ) => {
  const showSuffixInFlyout = JSON.parse(_.get(entry, 'show_suffix_in_flyout', false));
  return showSuffixInFlyout ?
    getFormattedNumberValue(value, entry, removeNegativeForZero,
      isConvertAbbreviated, isHidePrefixSuffix) :
    getFormattedValueWithOutSuffix(
      value, entry, removeNegativeForZero, isConvertAbbreviated, isHidePrefixSuffix);
};

export const getFormattedNumberValue = (
  totalValue,
  displayFormatEntry,
  removeNegativeForZero = false,
  isConvertAbbreviated = false,
  isHidePrefixSuffix = false,
  isCustomPrecision = false,
  isSkipTimeDuration = false) => {
  let precision = _.get(displayFormatEntry, 'precision');

  if(isCustomPrecision){
    precision = isHidePrefixSuffix ? SINGLE_VALUE : precision;
  }
  const shouldShowDurationFormat = isTimeDurationEnabled(displayFormatEntry);
  const durationDataUnit = timeDurationDataUnit(displayFormatEntry);
  const prefixText = _.get(displayFormatEntry, 'prefix', '');
  const suffixText = getSuffixByVersion(displayFormatEntry,
    Number((+totalValue).toFixed(precision)) === SINGLE_VALUE);
  const appendSuffixSpace = isSuffixSpaceNeeded(suffixText) ? ' ' : '';
  totalValue = (_.isUndefined(totalValue) || _.isNull(totalValue))? 0 : totalValue;

  let formattedTotalValue;
  const formatToAbbreviation = displayFormatEntry['use_abbreviation_string'] == 'true';
  if(formatToAbbreviation){
    formattedTotalValue = convertAbbreviatedStringValue(totalValue);
  }else if(shouldShowDurationFormat && !isSkipTimeDuration) {
    formattedTotalValue = getSecondsAsDuration(totalValue, durationDataUnit);
  } else {

    const isRoundOffEnabled = isNearestRoundOffDisplayAsEnabled(displayFormatEntry);
    if(isRoundOffEnabled){
      totalValue = getNearestRoundOffValue(totalValue, displayFormatEntry);
    }
    formattedTotalValue = getAbbreviatedStringValue(totalValue,
      precision, removeNegativeForZero, isConvertAbbreviated);
  }

  if (isHidePrefixSuffix){
    return formattedTotalValue;
  } else {
    return `${prefixText}${formattedTotalValue}${appendSuffixSpace}${suffixText}`;
  }
}

const isSuffixSpaceNeeded = (suffixText) => {
  const suffixListWithoutSpace = ['%'];
  return !(_.includes(suffixListWithoutSpace,suffixText))
}

export const getFormattedValueWithOutSuffix = (
  totalValue,
  displayFormatEntry,
  removeNegativeForZero = false,
  isConvertAbbreviated = false,
  isHidePrefixSuffix = false
) => {
  const precision = _.get(displayFormatEntry, 'precision');
  const prefixText = _.get(displayFormatEntry, 'prefix', '');
  const shouldShowDurationFormat = isTimeDurationEnabled(displayFormatEntry);
  const durationDataUnit = timeDurationDataUnit(displayFormatEntry);

  totalValue = (_.isUndefined(totalValue) || _.isNull(totalValue))? 0 : totalValue;

  let formattedTotalValue;
  if(shouldShowDurationFormat) {
    formattedTotalValue = getSecondsAsDuration(totalValue, durationDataUnit);
  } else {
    formattedTotalValue = getAbbreviatedStringValue(
      totalValue, precision, removeNegativeForZero, isConvertAbbreviated);
  }

  if (isHidePrefixSuffix){
    return formattedTotalValue;
  } else {
    return `${prefixText}${formattedTotalValue}`;
  }
}

export const isIndependentAxesValues = (vizOptions) => {
  return _.isEqual(_.get(vizOptions , 'secondaryMetricEntry.independent_axis', 'false'), 'true');
}

export const shouldDisableDimensions = (dateRange, selectedTimeFrame, compareYearRanges = []) => {
  const dateRangeDifference = getDateRangeDifference(dateRange, 'days');
  return (
    ((dateRangeDifference > YEAR_DAYS_COUNT*2) || compareYearRanges.length >= 2 )&&
    _.isEqual(selectedTimeFrame, OVERTIME_TIME_FRAME_OPTIONS.YEAR_ON_YEAR)
  );
}
