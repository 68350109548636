// Vendor Imports
import _ from 'lodash';
import React from "react";
import PropTypes from 'prop-types';

// Project Imports
import TargetValueSelector from 'common/components/TargetValueSelector';
import { isDistributionType } from 'helpers/distributionHelper';
import { shouldTargetApplyAlways } from 'helpers/templateHelper';
import * as commonPropTypes from "common/propTypes";

class StatusBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      seeMoreLabelText:null,
      targetStatusDisable:false
    };
  }

  componentDidMount(){
    this.renderSeeMoreLebel();
  }

  componentWillMount() {
    this.statusTargetData();
  }

  componentDidUpdate (prevProps) {
    const { quickFilters, currentTargetEntry } = this.props;

    if(!_.isEqual(prevProps.quickFilters, quickFilters)
      || !_.isEqual(prevProps.currentTargetEntry, currentTargetEntry)) {
      this.statusTargetData();
      this.renderSeeMoreLebel();
    }
  }

  statusTargetData(){
    const { quickFilters, visualizationType, currentViewEntry, defaultQuickFilters } = this.props;
    const enableTargetAlways = shouldTargetApplyAlways(currentViewEntry);
    const defaultMigrateQuickFilters = defaultQuickFilters;
    this.setState({
      targetStatusDisable : (
        !enableTargetAlways &&
        !isDistributionType(visualizationType) &&
        !_.isEqual(quickFilters, defaultMigrateQuickFilters))
    });
  }

  renderSeeMoreLebel = () => {
     let lineElement = document.getElementById('contentHeight');
     let seeMoreLabelRender;

     if(_.isEmpty(lineElement)){
      return ;
     }

     const divHeight = lineElement.offsetHeight
     /* line height(24) must be equal in CSS line Height
        [Refer : className(.status-content)], Purpose of using find the No. of lines render.*/
     const lineHeight = 24;
     const lines = divHeight / lineHeight;

     if(lines > 2){
      lineElement.classList.add("two-line");
      seeMoreLabelRender = (
        <div className="text-right">
          <label htmlFor="expanded" tabIndex={0} role="button" className="see-more" aria-label="See more">
            See more
          </label>
          <label htmlFor="expanded" tabIndex={0} role="button" className="see-less" aria-label="See less">
            See less
          </label>
        </div>
      );

     this.setState({
        seeMoreLabelText : seeMoreLabelRender
      });
    }
  }

  render() {
    const { currentTargetEntry, currentViewEntry, secondaryTotal, templateId, currentUser } = this.props;
    const { targetStatusDisable, seeMoreLabelText } = this.state;

    return (
      <TargetValueSelector
        templateId={templateId}
        currentUser={currentUser}
        viewEntry={currentViewEntry}
        secondaryTotal={secondaryTotal}
        currentTargetEntry={currentTargetEntry}
        targetStatusDisable={targetStatusDisable}
        seeMoreLabelText={seeMoreLabelText}
      />
    );
  }
}

StatusBanner.propTypes = {
  currentTargetEntry: PropTypes.object,
  currentViewEntry: commonPropTypes.viewEntryPropTypes,
  secondaryTotal: commonPropTypes.stringOrNumberProps,
  templateId: commonPropTypes.templateIdPropTypes,
  currentUser: commonPropTypes.userPropTypes,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  visualizationType: PropTypes.string,
  defaultQuickFilters: PropTypes.array
};

export default StatusBanner
