// Vendor Imports
import $ from 'jquery';

// Project Imports
import {
  getRoundedOffBoundingPolygon,
  getSnappedFeaturesInCluster,
  getCharmSvgSrc,
  getMapIncidentCircleRadius,
  getMapIncidentDefaultStyle
} from '../helpers/mapHelper';
import { DEFAULT_MAP_POINT_COLOR } from 'modules/Map/helpers/mapHelper';
import { buildQueryString } from 'helpers/HttpHelper';
import { fetchApiData } from 'helpers/apiResponseHelper';

const DEFAULT_CHARM_HEIGHT = 9;

// Spiderfies a cluster. In every tile data call, we snap the geometries
// based on zoom level, group them (to deduplicate) and fetch them. The
// retrieved snapped-features are then again clustered by mapbox, otherwise
// the snapped features will look like a checker-board.
// To spiderfy, we make a soql call based on the snapped features in the
// cluster and get individual features and spiderfy them.
export default class SpiderfyHandler {
  constructor(map, popupHandler) {
    this._map = map;
    this._popupHandler = popupHandler;
    this._apiParams = {};
    this._spiderifier = new MapboxglSpiderifier(map, {
      animate: true,
      customPin: true,
      initializeLeg: this.initializeSpiderLeg
    });
  }

  // Spiderfies a cluster feature.
  async spiderfy(clusterFeature, apiParams, options = {}) {
    this._apiParams = apiParams;
    this._options = options;
    const clusterCoordinates = _.get(clusterFeature, 'geometry.coordinates');

    try {
      const snappedFeatures = await getSnappedFeaturesInCluster(clusterFeature, this._map);
      const boundingPolygon = getRoundedOffBoundingPolygon(snappedFeatures, this._map);

      const params = {
        ...apiParams,
        boundingPolygon: JSON.stringify(boundingPolygon),
        zoom: Math.floor(this._map.getZoom())
      };

      if (!_.isEmpty(snappedFeatures)) {
        const flyoutApiUrl = `api/visualization/map/flyout_incidents.json?${buildQueryString(params)}`;
        const features = await fetchApiData(flyoutApiUrl);
        this._spiderifier.spiderfy(clusterCoordinates, _.take(features, 100));
      }
    } catch (e) {
      console.log('Snapped features in cluster error', e); // eslint-disable-line no-console
    }
  }

  // Unspiderfies if there are spiderfies created by this instance in the map.
  unspiderfy() {
    this._spiderifier.each((spiderLeg) => this._popupHandler.destroyPopupForSpideredMarker(spiderLeg));
    this._spiderifier.unspiderfy();
  }

  // Creates/Decorates pin in each spider leg based on the feature, vif and renderOptions.
  initializeSpiderLeg = (spiderLeg) => {
    this._popupHandler.initializePopupForSpideredMarker(spiderLeg);
    renderSpiderLeg(spiderLeg, this._apiParams, this._options);
  }
}

function renderSpiderLeg(spiderLeg, apiParams, options = {}) {
  const isFeatureCollection = _.get(spiderLeg, 'feature.type', '') == 'FeatureCollection';
  const ignoreViewEntry = _.get(apiParams, 'ignore_view_entry', false);
  const excludeSearchValue = _.get(apiParams, 'exclude_search_value', false);
  const selectedReportRowIds = _.get(options, 'selectedReportRowIds', []);

  let feature = [];
  if (isFeatureCollection) {
    feature = _.get(spiderLeg, 'feature.features[0]', []);
  } else {
    feature = spiderLeg.feature;
  }
  const currentRowId = _.get(feature, 'properties.row_id_key', '');
  const isSelectedSubject = _.includes(selectedReportRowIds, currentRowId)

  const spiderClassName = ignoreViewEntry ? 'advance-search-spider': '';
  const $spiderPinCustom = $('<div>', {class: `spider-point-circle ${spiderClassName}`});
  let { circleRadius, color, iconName } = getFeatureCircleRadiusAndColor(feature);
  let circleColor = color || DEFAULT_MAP_POINT_COLOR;
  let src = getCharmSvgSrc(iconName);
  let charmHeight = DEFAULT_CHARM_HEIGHT;
  let className = "charm-icon";
  let opacity = 0.8;
  let marginTop = circleRadius;

  if(excludeSearchValue) {
    opacity = 1;
    marginTop = 25;
    circleRadius = 15;
    className = "charm-icon search-subject-pin";
    circleColor= 'transparent';
    src = getCharmSvgSrc('location_pin_unselected');
    charmHeight = 25;
  } else if(ignoreViewEntry) {
    marginTop = 25;
    opacity = 1;
    className = "charm-icon search-result-pin";
    circleColor= 'transparent';   
    charmHeight = 25;
    circleRadius = 15;
    src = isSelectedSubject ?
      getCharmSvgSrc('location_pin_checked') :
      getCharmSvgSrc('location_pin_unselected');
  }

  $(spiderLeg.elements.pin).append($spiderPinCustom);

  const $charmIcon = $('<img>', { class: className, src: src });
  $($charmIcon).css({
    'height': `${charmHeight}px`,
    'top': `5px`
  });

  $($spiderPinCustom).append($charmIcon);

  $spiderPinCustom.css({
    'width': `${2 * circleRadius}px`,
    'height': `${2 * circleRadius}px`,
    'margin-left': `-${circleRadius}px`,
    'margin-top': `-${marginTop}px`,
    'background-color': `${circleColor}`,
    'opacity': opacity
  });
}

function getFeatureCircleRadiusAndColor() {
  const circleRadius = getMapIncidentCircleRadius();
  const { color, iconName } = getMapIncidentDefaultStyle();

  return { circleRadius , color, iconName };
}
