import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { buildQueryString } from 'helpers/HttpHelper';
import { ForgeCircularProgress, ForgeTooltip } from '@tylertech/forge-react';
import { TOOLTIP_CONSTANTS } from '@tylertech/forge';

const PropertyPdf = ({ propertyDetails, value, length }) => {
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);
  const queryParams = {
    country: _.get(propertyDetails, 'comp_finder_country', '').replace(/County/i, "").trim(),
    quickRefId: _.get(propertyDetails, 'comp_finder_quick_ref_id', ''),
    taxYear: _.get(propertyDetails, 'comp_finder_tax_year', '')
  };
  const href = `/api/advanced_search/get_external_pdf.pdf?${buildQueryString(queryParams)}`;
  const delay = TOOLTIP_CONSTANTS.numbers.DEFAULT_DELAY;

  useEffect(() => {
    const checkApiStatus = async () => {
      try {
        setLoading(true);
        const response = await fetch(href);
        if (!response.ok) {
          setApiError(true);
        } else {
          setApiError(false);
        }
      } catch (error) {
        setApiError(true);
      } finally {
        setLoading(false);
      }
    };
    checkApiStatus();
  }, [href]);

  const renderLoader = () => {
    return (
      <ForgeCircularProgress
        open="true"
        determinate="false"
        progress="0.5"
        style={{
          '--forge-circular-progress-size': `20px`
        }}
      />
    )
  };

  const renderPdfLink = () => {
    return (
      apiError ? (
        <>
          <div>{value}
          <ForgeTooltip
            text='PRC is not public'
            delay={delay}
            position={length === 1 ? 'right': 'top'} />
          </div>          
        </>
      ) : (
        <a href={href} target='_blank' rel='noopener noreferrer'>
          {value}
          <ForgeTooltip
            text='View PRC in new tab'
            delay={delay}
            position="top"
          />
        </a>
      )
    )
  }

  return (
    <>
      {loading ? renderLoader() : renderPdfLink()}
    </>
  );
};

PropertyPdf.propTypes = {
  propertyDetails: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  length: PropTypes.number
};

export default PropertyPdf;
