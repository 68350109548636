import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

BadgeItems.propTypes = {
  onRemoveBadgeItem: PropTypes.func,
  customClasses: PropTypes.string,
  badgeObject: PropTypes.object
};

function BadgeItems(props) {
  const { onRemoveBadgeItem, badgeObject, customClasses } = props;
  const badgeClassNames = classNames('value-chip', {
    customClasses: !_.isEmpty(customClasses)
  });
  const { name, values } = badgeObject;
  const onKeydownFilterBadgeItem = (e, value) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      onRemoveBadgeItem({ column: name, value })
    }
  }

  return _.map(values, (value, index) => {
    return (
      <div key={index} className={badgeClassNames}>
        {value}
        <i
          tabIndex={0}
          aria-label="remove filtered value"
          className="icons-close tx-8"
          onKeyDown={(e) => onKeydownFilterBadgeItem(e, value)}
          onClick={() => onRemoveBadgeItem({ column: name, value })}
        />
      </div>
    );
  });
}

export default BadgeItems;
