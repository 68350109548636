import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ForgeIcon, ForgeIconButton, ForgeTooltip } from '@tylertech/forge-react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import InlineEdit from '../InlineEdit';
const DEFAULT_COLLECTION_NAME = 'New collection';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { trackEvent } from 'helpers/eventTracking';
import { TOOLTIP_CONSTANTS } from '@tylertech/forge';

class AddCollection extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleCollectionNameChange = (e) => {
    this.setState({ nextCollectionName: e.target.value });
  };

  handleInputKeyDown = (e, collection) => {
    const value = e.target.value;
    if (isEnterButtonPressed(e)) {
      this.handleCollectionOkClick(value, collection);
    }
  };

  handleCollectionEditClose = () => {
    this.setState({ showAddCollectionInput: false });
  };

  handleCollectionOkClick = (name) => {
    const { currentCollection } = this.props;
    const { showCollectionEdit } = this.state;
    const isEditModeEnabled = showCollectionEdit;

    this.setState(
      {
        showCollectionEdit: false,
        showAddCollectionInput: false,
        nextCollectionName: DEFAULT_COLLECTION_NAME,
      },
      () => {
        if (isEditModeEnabled) {
          this.props.onEditCollection({
            ...currentCollection,
            name,
          });
        } else {
          this.props.onAddCollection(name);
        }
      }
    );
  };

  handleAddCollectionIconClcik = () => {
    trackEvent('create_a_collection');
    this.setState({ showAddCollectionInput: true, showCollectionEdit: false });
  };

  handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key == "Enter") {
      this.handleAddCollectionIconClcik();
    }
  }

  renderAddCollectionInput() {
    const { showAddCollectionInput } = this.state;
    return (
      <InlineEdit
        onEditClose={this.handleCollectionEditClose}
        onInputChange={this.handleCollectionOkClick}
        enableInputEdit={showAddCollectionInput}
        defaultText={DEFAULT_COLLECTION_NAME}
      />
    );
  }

  renderAddCollectionForgeButton() {
    const { showAddCollectionInput } = this.state;
    const { currentUser } = this.props;
    if (showAddCollectionInput || _.isEmpty(currentUser)) {
      return null;
    }

    const delay = TOOLTIP_CONSTANTS.numbers.DEFAULT_DELAY;
    return (
      <ForgeIconButton dense={false} densityLevel={3}>
        <button
          type="button"
          aria-label="Add new collection"
          onClick={this.handleAddCollectionIconClcik}
          onKeyPress={this.handleKeypress}>
          <ForgeIcon
            className="add-collection-icon"
            name="add_circle_outline"
          />
        </button>
        <ForgeTooltip text="Create a new collection" delay={delay} position="top" />
      </ForgeIconButton>
    );
  }

  renderAddCollectionButton() {
    const { showAddCollectionInput } = this.state;
    const { currentUser } = this.props;
    if (showAddCollectionInput || _.isEmpty(currentUser)) {
      return null;
    }

    const tooltip = (
      <Tooltip id="newCollection"> Create a new collection </Tooltip>
    );

    return (
      <OverlayTrigger key="newCollection" placement="top" overlay={tooltip}>
        <i tabIndex={0} className="icons-circle-add-plus text-primary align-self-center tx-25"
        onClick={this.handleAddCollectionIconClcik} onKeyPress={this.handleKeypress} />
      </OverlayTrigger>
    );
  }

  handleClickOutside = (e) => {
    if (this.addCollectionRef && !this.addCollectionRef.contains(e.target)) {
      this.handleCollectionEditClose();
    }
  }

  render() {
    const { nextCollectionName, showAddCollectionInput } = this.state;
    return (
      <div className="collection-btn" role="tab" ref={(ref) => this.addCollectionRef = ref}>
        {this.renderAddCollectionForgeButton()}
        {showAddCollectionInput
          ? this.renderAddCollectionInput(nextCollectionName)
          : null}
      </div>
    );
  }
}

AddCollection.propTypes = {
  onAddCollection: PropTypes.func,
  onEditCollection: PropTypes.func,
  currentUser: PropTypes.object,
  currentCollection: PropTypes.object,
};

export default AddCollection;
