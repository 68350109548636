import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ShapeFilter from 'modules/MiniMap/shapeFilter';
import FilterChip from 'common/components/FilterChip';
import ShapeDropdownSelector from './components/ShapeDropdownSelector';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getShapeIdsAndExtent } from 'modules/Map/helpers/mapHelper';
import { shouldShowMiniMap, getMapCenter, getShapeFilterMapzoom } from 'common/config/templateConfiguration';
import { SOURCES } from 'modules/Map/partials/ShapeFilterPartial';
import { SOURCES as ShapeLayerSources } from 'modules/Map/partials/ShapeLayerPartial';

import { getShapeTileUrl } from 'common/api/map';

class MiniMapShapeFilter extends Component {
  state = {
    map: null
  }

  onMapStylesAreLoaded = (map) => {
    this.setState({ map });
  }

  getMap = () => {
    return (this.state.map || this.props.map);
  }

  getSource = ()  => {
    const { currentDrilldownTemplateId } = this.props;
    return shouldShowMiniMap(currentDrilldownTemplateId) ?
      SOURCES.SHAPES :
      ShapeLayerSources.SHAPES;
  }

  handleShapeFilterReset = () => {
    const { currentDrilldownTemplateId } = this.props;
    const { shapesExtent } = getShapeIdsAndExtent(this.getMap(), [], null, this.getSource());
    this.props.dispatchToggleShapeIdsFilter([], shapesExtent);
    this.updateMiniMapPosition(currentDrilldownTemplateId);
  }

  handleShapeFilterKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleShapeFilterReset();
    }
  }

  onRemoveShapeFiler = (removedShapeId) => {
    const { selectedShapeIds, currentDrilldownTemplateId} = this.props;
    const {
      shapeIds, shapesExtent
    } = getShapeIdsAndExtent(this.getMap(), selectedShapeIds, removedShapeId, this.getSource());
    this.props.dispatchToggleShapeIdsFilter(shapeIds, shapesExtent);

    if (_.isEmpty(shapeIds)){
      this.updateMiniMapPosition(currentDrilldownTemplateId);
    }
  }

  updateMiniMapPosition = (currentDrilldownTemplateId) =>
  {
    const center = getMapCenter(currentDrilldownTemplateId);
    const zoom = getShapeFilterMapzoom(currentDrilldownTemplateId);
    if(_.isEmpty(center)){
      return;
    }
    this.getMap().jumpTo({ center, zoom });
  }

  renderShapeFilterBadges = () => {
    const { selectedShapeIds } = this.props;
    const map = this.getMap();
    if (!map || _.isEmpty(selectedShapeIds) || _.isEmpty(map)) {
      return null;
    }
    const filter = ['all', ['in', 'shape_id'].concat(selectedShapeIds)];
    if(!map.getSource(this.getSource())) {
      return null;
    }
    const selectedShapeFeatures = map.querySourceFeatures(this.getSource(), { filter });
    const uniqProperties = _.chain(selectedShapeFeatures).
      map((feature) => {
        return{
          shapeId : _.get(feature, 'properties.shape_id'),
          shapeName:  _.get(feature, 'properties.shape_name')
        };
      }).
      uniqBy('shapeId').
      value();

    const filterChipsContent = _.map(uniqProperties, (feature, index) => {
      const { shapeId, shapeName } = feature;

      return (
        <div className="collections-global-filter" key={index}>
          <FilterChip value={shapeId} label={shapeName} onRemove={this.onRemoveShapeFiler} />
        </div>
      );
    });

    return (
      <div className="filters-container minmap-filter">
        {filterChipsContent}
      </div>
    );
  }

  renderFilterResetButton = () => {
    return (
      <span
        className="ml-auto reset-link forge-typography--caption"
        tabIndex={0}
        aria-label="Reset geographic filter"
        onClick={this.handleShapeFilterReset}
        onKeyDown={this.handleShapeFilterKeyDown}>Reset
      </span>
    )
  }

  render() {
    const {
      selectedShapeEntry, currentMapStyleEntry, currentDrilldownTemplateId, selectedShapeIds,
      dispatchToggleShapeIdsFilter, updateShapeDatasetEntry
    } = this.props;

    if (_.isEmpty(selectedShapeEntry) || _.isNil(currentMapStyleEntry)) {
      return null;
    }
    const center = getMapCenter(currentDrilldownTemplateId);
    const showMiniMap = (!_.isEmpty(center) && shouldShowMiniMap(currentDrilldownTemplateId));

    return(
      <>
      <hr className="dividers" />
        <div className="side-bar-filter">
          <div className="section-titles forge-typography--overline d-flex align-items-center">
            Geographic filter
            {!_.isEmpty(selectedShapeIds) && this.renderFilterResetButton()}
          </div>
            <ShapeDropdownSelector
              selectedShapeEntry={selectedShapeEntry}
              currentDrilldownTemplateId={currentDrilldownTemplateId}
              updateShapeDatasetEntry={updateShapeDatasetEntry}
            />
          {this.renderShapeFilterBadges()}
          { showMiniMap &&
            <ShapeFilter
              shapeTileApi={getShapeTileUrl}
              toggleShapeIdsFilter={dispatchToggleShapeIdsFilter}
              selectedShapeEntry={selectedShapeEntry}
              selectedShapeIds={selectedShapeIds}
              isDrawFilter ={false}
              currentMapStyleEntry={currentMapStyleEntry}
              currentDrilldownTemplateId={currentDrilldownTemplateId}
              onMapStylesAreLoaded={this.onMapStylesAreLoaded}/>
          }
        </div>
      </>
    );
  }
}

MiniMapShapeFilter.propTypes = {
  currentDrilldownTemplateId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  currentMapStyleEntry: PropTypes.object,
  dispatchToggleShapeIdsFilter: PropTypes.func,
  selectedShapeEntry: PropTypes.object,
  selectedShapeIds: PropTypes.array,
  map: PropTypes.object,
  updateShapeDatasetEntry: PropTypes.func
}

export default MiniMapShapeFilter;
