import React from 'react';
import PropTypes from 'prop-types';

import {
  PROPHET_FORECAST_COLOR,
  LINE_CHART_TOTAL_TRACE_COLOR,
  EXPONENTIAL_FORECAST_COLOR,
  FORECASTING_TYPE,
  HISTORICAL_FORECAST_COLOR
} from 'appConstants';

const Legend = (props) => {
  const {
    legendItems
  } = props;

  if (_.isEmpty(legendItems)) {
    return null;
  }

  const renderLegend = (item) => {
    const isHistoricalData = item.type == FORECASTING_TYPE.HISTORICAL_AVG;
    const isProphetData = item.type == FORECASTING_TYPE.PROPHET;
    const isExponentialData = item.type == FORECASTING_TYPE.SIMPLE_EXPONENTIAL;
    const historicalStyle = {
      backgroundColor: _.get(item, 'color', HISTORICAL_FORECAST_COLOR),
      borderColor: _.get(item, 'color', HISTORICAL_FORECAST_COLOR),
    };
    const prophetStyle = {
      backgroundColor: PROPHET_FORECAST_COLOR,
      borderColor: PROPHET_FORECAST_COLOR
    };
    const exponentialStyle = {
      backgroundColor: EXPONENTIAL_FORECAST_COLOR,
      borderColor: EXPONENTIAL_FORECAST_COLOR
    };
    return (
      <div>
        {isHistoricalData &&
        <div className='d-flex'>
          <div className="fake-check-box mr-3" style={historicalStyle}></div>
          <label>
            <div className="trace-name forge-typography--body2">{item.name}</div>
          </label>
        </div>
        }

        {isExponentialData &&
          <div className='d-flex'>
            <div className="fake-check-box mr-3" style={exponentialStyle}></div>
            <label>
              <div className="trace-name forge-typography--body2">Simple exponential smoothing</div>
            </label>
          </div>
        }

        {isProphetData &&
          <div className='d-flex'>
            <div className="fake-check-box mr-3" style={prophetStyle}></div>
            <label>
              <div className="trace-name forge-typography--body2">Prophet</div>
            </label>
          </div>
        }
      </div>
    )
  }

  return (
    <div className='p-2'>
      <div className="over-time-legend-item">
        <div className='d-flex'>
            <div className="fake-check-box mr-3"
              style={{
                backgroundColor: LINE_CHART_TOTAL_TRACE_COLOR,
                borderColor: LINE_CHART_TOTAL_TRACE_COLOR}}>
            </div>
            <label>
              <div className="trace-name forge-typography--body2">Historical data</div>
            </label>
          </div>
        {_.map(legendItems, renderLegend)}
      </div>
    </div>
  );
};


Legend.propTypes = {
  legendItems: PropTypes.array
};


export default Legend;
