import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateUserPreferences } from 'common/api/commonApi';

import DateRangeFilterWithBadge from './DateRangeFilterWithBadge';
import { updateDateFilters, updateAllMetricsDateFilters } from 'actions/commonFiltersActions';
import {
  shouldShowCommonDateFilter,
  getIndependentDateFiltersConfigs,
  getAdditionalDateFiltersConfigs
} from '../collectionHelper';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import {
  configuredDefaultDateType,
  enableCollectionLevelDateFilters,
  getGenericDatePickerLabel
} from 'common/config/customerConfiguration';
import { getDefaultDateRange, getRelativeFilterDateRange } from 'helpers/dateHelper';

import { COLLECTION_ID, RELATIVE_DATE_TYPE } from 'appConstants';
import { getMaxDate, getMinDateForTemplates } from 'helpers/dateUtils';

class CollectionDateFilters extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
    };
  }

  getDefaultDateFilters = () => {
    return{
      dateRange: getDefaultDateRange(relativeDateFilterEntryDefaultState),
      dateType: configuredDefaultDateType,
      relativeDateFilterEntry: relativeDateFilterEntryDefaultState,
    }
  }

  handleAllMetricFilterChange = (dateFilters) => {
    const { globalFilters, dispatchDateFiltersChange } = this.props;

    dispatchDateFiltersChange(dateFilters);
    const location= window.location.href;
    if(location.substring('/overview')!==-1){
      updateUserPreferences(globalFilters, dateFilters).
        then(() => {
        }).catch((err) => {
          console.log('Error on updating global filters ', err); // eslint-disable-line no-console
        });
    }
  }

  handleDateFiltersChange = (dateFilters) => {
    const { onDateFiltersChange, currentCollection } = this.props;
    const isAllMetricsCollection = _.isEqual(currentCollection.id, COLLECTION_ID.ALL_METRICS);
    onDateFiltersChange(dateFilters);
    if(isAllMetricsCollection){
      this.handleAllMetricFilterChange(dateFilters);
    }
  }

  handleCommonDateFilterChange = (filters) => {
    const { dateFilters } = this.props;
    const newDateFilters = {
      ...dateFilters,
      ...filters
    }
    this.handleDateFiltersChange(newDateFilters);
  }

  handleIndependentDateFiltersChange = (dateConfig, filters) => {
    const { dateFilters } = this.props;
    let newIndependentDateFilters = _.get(dateFilters, 'independentDateFilters', {});
    newIndependentDateFilters[dateConfig.id] = filters;
    const newDateFilters = {
      ...dateFilters,
      independentDateFilters: newIndependentDateFilters
    }
    this.handleDateFiltersChange(newDateFilters);
  }

  handleAdditionalDateFilterChange = (dateConfig, filters) => {
    const { dateFilters } = this.props;
    let newAdditionalDateFilters = _.get(dateFilters, 'additionalDateFilters', {});
    newAdditionalDateFilters[dateConfig.id] = filters;
    const newDateFilters = {
      ...dateFilters,
      additionalDateFilters: newAdditionalDateFilters
    }
    this.handleDateFiltersChange(newDateFilters);
  }

  updateDefaultDates = (dateConfigs, type) => {
    const { dateFilters } = this.props;
    let newFilters = _.get(dateFilters, type, {});
    _.each(dateConfigs, (config) => {
      const { id} = config;
      newFilters[id] = this.getDefaultDateFilters()
    });
    const newDateFilters = {
      ...this.getDefaultDateFilters(),
      ...dateFilters,
      [type]: newFilters
    }
    this.handleDateFiltersChange(newDateFilters);
  }

  renderCommonDateFilters(){
    const {
      cardEntries,
      globalFilters,
      dateFilters,
      hideCommonFilters,
      commonFilterName,
      alwaysShowFilterName,
      minDatesForTemplateEntries,
      disabledFilters
    } = this.props;

    if(!shouldShowCommonDateFilter(cardEntries) && hideCommonFilters){
      return null;
    }
    const minDate = getMinDateForTemplates(cardEntries, minDatesForTemplateEntries);
    const maxDate = getMaxDate();
    const dateRange = _.get(dateFilters, 'dateRange',
      getDefaultDateRange(relativeDateFilterEntryDefaultState)
    );
    const relativeDateFilterEntry =  _.get(
      dateFilters,
      'relativeDateFilterEntry',
      relativeDateFilterEntryDefaultState);
    const relativeDateRange = getRelativeFilterDateRange(relativeDateFilterEntry);
    const dateType =_.get(dateFilters, 'dateType', configuredDefaultDateType)
    const dateProps = {
      dateRange: (dateType === RELATIVE_DATE_TYPE ? relativeDateRange : dateRange),
      dateType,
      minDate: minDate,
      maxDate: maxDate,
      disabledFilters,
      relativeDateFilterEntry
    };
    let titleText = alwaysShowFilterName ? commonFilterName : getGenericDatePickerLabel();

    return(
      <DateRangeFilterWithBadge
        {...dateProps}
        filterName={titleText}
        onDateFilterChange={this.handleCommonDateFilterChange}
        globalFilters={globalFilters} />
    )
  }

  renderDateFilterBadge(config, dateFilter, onDateChange, isIndependentFilter){
    const { cardEntries, minDatesForTemplateEntries, disabledFilters } = this.props;
    const { templateEntry, id, name } = config;
    const minDate = isIndependentFilter ?
      getMinDateForTemplates([{ templateEntry }], minDatesForTemplateEntries) :
      getMinDateForTemplates(cardEntries, minDatesForTemplateEntries);

    const maxDate = getMaxDate();

    const dateRange = _.get(dateFilter, 'dateRange',
      getDefaultDateRange(relativeDateFilterEntryDefaultState)
    );
    const relativeDateFilterEntry =  _.get(
      dateFilter,
      'relativeDateFilterEntry',
      relativeDateFilterEntryDefaultState);
    const relativeDateRange = getRelativeFilterDateRange(relativeDateFilterEntry);
    const dateType =_.get(dateFilter, 'dateType', configuredDefaultDateType)

    const dateProps = {
      dateRange: (dateType === RELATIVE_DATE_TYPE ? relativeDateRange : dateRange),
      dateType,
      maxDate: maxDate,
      minDate: minDate,
      disabledFilters,
      relativeDateFilterEntry
    };

    return(
      <DateRangeFilterWithBadge
        key={id}
        {...dateProps}
        filterName={name}
        currentDrilldownTemplateId={_.get(templateEntry, 'template_id')}
        onDateFilterChange={(newDateFilters) => onDateChange(config, newDateFilters)} />
    );
  }

  renderAdditionalDateFiltersDateFilters(){
    const { cardEntries, dateFilters } = this.props;
    const additionalDateFilterConfigs = getAdditionalDateFiltersConfigs(cardEntries);

    if(_.isEmpty(additionalDateFilterConfigs)){
      return null;
    }
    if(_.isEmpty(_.get(dateFilters, 'additionalDateFilters'))){
      this.updateDefaultDates(additionalDateFilterConfigs, 'additionalDateFilters');
    }

    return _.map(additionalDateFilterConfigs, (config) => {
      const { id } = config;
      let filters = _.get(dateFilters, `additionalDateFilters.${id}`, {});
      return this.renderDateFilterBadge(config, filters, this.handleAdditionalDateFilterChange, false);
    });
  }

  renderIndependentDateFilters() {
    const { cardEntries, dateFilters, showIndependentFilters } = this.props;
    const dateFilterConfigs = getIndependentDateFiltersConfigs(cardEntries);

    if(_.isEmpty(dateFilterConfigs) || !showIndependentFilters){
      return null;
    }

    if(_.isEmpty(_.get(dateFilters, 'independentDateFilters'))){
      this.updateDefaultDates(dateFilterConfigs, 'independentDateFilters');
    }

    return _.map(dateFilterConfigs, (config) => {
      const { id } = config;

      let filters = _.get(dateFilters, `independentDateFilters.${id}`, {});
      return this.renderDateFilterBadge(config, filters, this.handleIndependentDateFiltersChange, true);
    });

  }

  render() {
    if(!enableCollectionLevelDateFilters()) {
      return null;
    }

    return (
      <>
        {this.renderCommonDateFilters()}
        {this.renderIndependentDateFilters()}
        {this.renderAdditionalDateFiltersDateFilters()}
      </>
    );
  }
}

CollectionDateFilters.defaultProps = {
  showIndependentFilters: true,
  currentCollection: {},
  hideCommonFilters: true,
  commonFilterName: 'Fiscal Year',
  onDateFiltersChange: _.noop,
  alwaysShowFilterName: false,
  disabledFilters: false,
};

CollectionDateFilters.propTypes = {
  cardEntries: PropTypes.array,
  onDateFiltersChange: PropTypes.func,
  dispatchDateFiltersChange: PropTypes.func,
  currentCollection: PropTypes.object,
  dateFilters: PropTypes.object,
  globalFilters: PropTypes.array,
  minDatesForTemplateEntries: PropTypes.array,
  showIndependentFilters: PropTypes.bool,
  hideCommonFilters: PropTypes.bool,
  alwaysShowFilterName: PropTypes.bool,
  commonFilterName: PropTypes.string,
  disabledFilters: PropTypes.bool
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDateFiltersChange: (dateFilters) => {
      dispatch(updateDateFilters(dateFilters));
      dispatch(updateAllMetricsDateFilters(dateFilters));
    }
  }
}

function mapStateToProps(state) {
  return {
    globalFilters: _.get(state,'commonFilters.globalFilters', []),
    minDatesForTemplateEntries: _.get(state, 'dashboard.minDatesForTemplateEntries'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDateFilters);
