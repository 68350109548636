import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ForgeTextField } from '@tylertech/forge-react';

import { getFormattedNumberValue } from 'helpers/chartDataHelper';
const ForecastingInput = (props) => {
  const {
    dateField,
    value,
    uniqueKey,
    isNumber,
    onHandleChange,
    placeholder,
    uniqueId,
    selectedForecastMetric } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    const reg =  /^-?\d*\.?\d*$/;
    let inputValue = e.target.value;
    inputValue = isNumber ? inputValue.replace(/(?!^-)[^0-9.]/g, "") : inputValue;
    const isValid = isNumber ? reg.test(inputValue) : !isNumber;

    if (inputValue === '' || inputValue === '-' || isValid) {
      const updateOption = {
        date: dateField,
        value: inputValue,
        isNumber,
        uniqueId
      }
      onHandleChange(updateOption);
    }

    if (!isNumber) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight - 6}px`;
    }
  }

  const handleOnFocus = (e) => {
    if (!isNumber) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight - 6}px`;
    }
  }

  const handleOnBlur = (e) => {
    if (!isNumber) {
      e.target.style.height = 'inherit';
      e.target.style.height = `40px`;
    }
  }

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const formatInputValue = (value) => {
    return _.isEmpty(value) ? value : getFormattedNumberValue(value, selectedForecastMetric)
  }

  const renderInputField = () => {
    return (
      <ForgeTextField>
        <input
          autoComplete="off"
          placeholder={placeholder}
          type="text"
          id={uniqueKey}
          value={isFocused ? value : formatInputValue(value)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleInputChange} />
      </ForgeTextField>
    )
  }

  const renderInputTextField = () => {
    return (
      <ForgeTextField>
       <textarea className="form-inline expand-area-input rounded-sm"
        autoComplete="off"
        placeholder={placeholder}
        id={uniqueKey}
        value={value}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleInputChange} />
      </ForgeTextField>
    )
  }

  return (
    <div>
      {isNumber && renderInputField()}
      {!isNumber && renderInputTextField()}
    </div>
  );
}

ForecastingInput.propTypes = {
  dateField: PropTypes.string,
  value: PropTypes.any,
  uniqueKey: PropTypes.string,
  isNumber: PropTypes.bool,
  placeholder: PropTypes.string,
  onHandleChange: PropTypes.func,
  uniqueId: PropTypes.string,
  selectedForecastMetric: PropTypes.object,
}

export default ForecastingInput;
