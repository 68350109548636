export const UPDATE_MAP_STYLE_ENTRY = 'UPDATE_MAP_STYLE_ENTRY';
export const UPDATE_SELECTED_SHAPE_OUTLINES = 'UPDATE_SELECTED_SHAPE_OUTLINES';
export const TOGGLE_SHAPE_IDS_FILTER = 'TOGGLE_SHAPE_IDS_FILTER';
export const UPDATE_SHAPE_DATA = 'UPDATE_SHAPE_DATA';
export const UPDATE_SHAPE_DATASET_ENTRY = 'UPDATE_SHAPE_DATASET_ENTRY';
export const SET_DEFAULT_DRILL_DOWN_MAP_OPTIONS = 'SET_DEFAULT_DRILL_DOWN_MAP_OPTIONS';
export const UPDATE_DRAWING_STATE_CHANGE = 'UPDATE_DRAWING_STATE_CHANGE';
export const UPDATE_POLYGON_FEATURE = 'UPDATE_POLYGON_FEATURE';
export const REMOVE_POLYGON_FILTER = 'REMOVE_POLYGON_FILTER';
export const UPDATE_CENTER_AND_ZOOM = 'UPDATE_CENTER_AND_ZOOM';
export const UPDATE_MAP_VIEW = 'UPDATE_MAP_VIEW';
export const UPDATE_LEGENDS = 'UPDATE_LEGENDS';
export const UPDATE_GEO_CODER = 'UPDATE_GEO_CODER';
export const UPDATE_COMPARISON = 'UPDATE_COMPARISON';
export const UPDATE_VISUALIZATION_DEFAULTS = 'UPDATE_VISUALIZATION_DEFAULTS';
export const UPDATE_RADAR_DRAWING_STATE_CHANGE = 'UPDATE_RADAR_DRAWING_STATE_CHANGE';
export const UPDATE_RADAR_DRAWING_LINE_STATE_CHANGE = 'UPDATE_RADAR_DRAWING_LINE_STATE_CHANGE';

