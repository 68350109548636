import React from 'react';
import PropTypes from 'prop-types';

import { NoDataWrapper } from 'common/components/NoDataFound/NoDataWrapper';

const EmptyBookmarkText = ({overrideClass}) => {
  return (
    <div role="listitem" className={overrideClass}>
      <img alt="No saved view" className="mb-6" src="../images/query_failed.png" />
      <div className='help-info-text'>
        You currently have no saved views. When viewing a metric in Analysis mode,
        you can click &quot;Save As&quot; to create a saved view with your current
        filters and chart preferences. After saving a view, it will appear here.
      </div>
    </div>
  );
}

EmptyBookmarkText.propTypes = {
  overrideClass: PropTypes.string
}

EmptyBookmarkText.defaultProps = {
  overrideClass: 'helpful-text'
}

export default NoDataWrapper(EmptyBookmarkText);
