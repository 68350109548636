import {
  getAdditionalDateFiltersConfigs,
  getGlobalFiltersFromCardEntries,
  getCurrentTemplateGlobalFilters,
  getDateFilterName,
  getIndependentDateFiltersConfigs,
  isDateIgnored,
  isIndependentDateFiltersEnabled,
  shouldShowCommonDateFilter
} from 'pages/dashboard/components/Collections/collectionHelper';
import {
  configuredDefaultDateType,
  enableCollectionLevelDateFilters,
  getGenericDatePickerLabel,
  getSavedViewFilterLabel,
  getGlobalFiltersFromTemplate
} from 'common/config/customerConfiguration';
import { STRING_TYPES_FIELD, CARD_TYPES } from 'appConstants';
import {
  getDefaultDateRange,
  getDateRangeTextEntry,
  getDateFilterParams
} from 'helpers/dateHelper';
import { isDateColumnConfigured } from 'common/config/templateConfiguration';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import {
  getCommonFilters,
} from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import { getNewQuickFiltersAndGlobalFilters } from 'helpers/FilterHelper';
import { isDateColumnConfiguredForViewEntry } from 'common/config/viewConfiguration';

export const getFiltersCount = (options) => {
  const {
    globalFilters,
    quickFilters,
    templateEntry,
    quickFilterEntries,
    filteredGeojson,
    isRadarFilter,
    shapeGeoJson
  } = options;
  const collectionFilters = getCurrentTemplateGlobalFilters(templateEntry, globalFilters);
  const globalFilterConfigEntries = getGlobalFiltersFromTemplate(templateEntry);
  const filtersText = getDateRangeFiltersText(options);
  const { newQuickFilters, newCollectionFilters } = getNewQuickFiltersAndGlobalFilters({
    collectionFilters: _.cloneDeep(collectionFilters),
    globalFilterConfigEntries,
    quickFilters: _.cloneDeep(quickFilters),
    quickFilterEntries
  });

  const removeEmptyFilters = (filters) => {
    return _.filter(filters, (filter) => !_.isEmpty(filter));
  }

  const multipleQuickFilters = formatMultipleFilterConditions(newQuickFilters);
  const multipleCollectionFilters = formatMultipleFilterConditions(newCollectionFilters);
  const formattedMultipleQuickFilterConditions = removeEmptyFilters(multipleQuickFilters);
  const formattedMultipleGlobalFilterConditions = removeEmptyFilters(multipleCollectionFilters);
  const dateRangeFilterCount = !isRadarFilter ? _.size(filtersText) : 0;
  return _.size(formattedMultipleGlobalFilterConditions) +
    dateRangeFilterCount +
    _.size(formattedMultipleQuickFilterConditions) +
    polygonFilterCount(filteredGeojson) + geographicFilterCount(shapeGeoJson);
}

export const getTemplateFiltersOptions = (
  options,
  type = CARD_TYPES.BOOKMARK,
  isRelativeDateSelected = false
) => {
  const {
    dateFilters,
    cardEntries,
    currentDrilldownViewEntry,
    globalFilters,
    templateEntry
  } = options;

  const collectionFilters = getGlobalFiltersFromCardEntries(cardEntries, globalFilters);
  const {
    dateFilters: bookmarkDateFilters,
    collectionFilters: bookmarkCollectionFilters
  } = getBookmarkFiltersOptions(options);
  const bookmarkFitlersText = getDateRangeFiltersText({
    dateFilters: bookmarkDateFilters,
    cardEntries: [{ templateEntry, viewEntry: currentDrilldownViewEntry }],
    currentDrilldownViewEntry,
    templateEntry,
    type
  }, false, isRelativeDateSelected);
  const templateDateFitlersText = getDateRangeFiltersText(options, true, isRelativeDateSelected);
  const bookmarkFilterNames = _.map(bookmarkFitlersText, 'dateFilterKey');
  const matchedFiltersText = _.map(templateDateFitlersText, (templateDateFitlerText) => {
    const { dateFilterLabel, dateFilterKey } = templateDateFitlerText;
    if(_.includes(bookmarkFilterNames, dateFilterKey)) {
      const matchedDateFilter = _.find(bookmarkFitlersText, { dateFilterLabel });
      return matchedDateFilter ? null : {};
    }
    return null;
  });

  return {
    dateFilters,
    bookmarkFitlersText,
    templateDateFitlersText,
    isEqualDateFilters: _.isEmpty(_.without(matchedFiltersText, null)),
    quickFilters: [],
    collectionFilters: getDifferenceCollectionFilters(
      _.cloneDeep(collectionFilters),
      _.cloneDeep(bookmarkCollectionFilters)
    )
  };
}

export const getBookmarkFiltersOptions = (options) => {
  const { cardEntry, templateEntry, dateFilters: defaultDateFilters } = options;
  const { drilldown } = cardEntry;
  const commonFilters = getCommonFilters(cardEntry, defaultDateFilters);
  const dateFilters = getDateFilterParams(commonFilters);
  const quickFilters = _.get(drilldown, 'quickFilters', []);
  const globalFilters = _.get(commonFilters, 'globalFilters', []);
  const collectionFilters = getCurrentTemplateGlobalFilters(templateEntry, globalFilters);

  return { dateFilters, quickFilters, collectionFilters };
}

export const getDateRangeFiltersText = (
  {
    cardEntries,
    templateEntry,
    hideCommonFilters = false,
    type,
    dateFilters,
    currentDrilldownViewEntry,
    isEmbed
  },
  isDashboard = false,
  isRelativeDateSelected = false
) => {
  const filterName =  type == CARD_TYPES.BOOKMARK ?
    getSavedViewFilterLabel() : getDateFilterName(templateEntry, currentDrilldownViewEntry);
  const independentDateFiltersConfigs = getIndependentDateFiltersConfigs(cardEntries);
  const additionalDateFilterConfigs  = getAdditionalDateFiltersConfigs(cardEntries);
  const isDateFilterEnabled = !isDateIgnored(currentDrilldownViewEntry);
  const isIndependentFilters = (
    !_.isEmpty(independentDateFiltersConfigs) &&
    (isIndependentDateFiltersEnabled(currentDrilldownViewEntry) ||
    isIndependentDateFiltersEnabled(templateEntry)) && isDateFilterEnabled
  );
  let dateFiltersText = [];

  const addIndependentDateFilters = () => {
    _.each(independentDateFiltersConfigs, (config) => {
      const { id } = config;
      let filters = _.get(dateFilters, `independentDateFilters.${id}`, {});
      dateFiltersText.push(getFilterLabel(filters, config.name, false, config.name));
    });
  }

  const dateFilterKey = isIndependentFilters && type == CARD_TYPES.BOOKMARK ?
    getDateFilterName(templateEntry, currentDrilldownViewEntry) : 'date filter';
  const dateFilterName = type == CARD_TYPES.BOOKMARK ?
    getSavedViewFilterLabel() : getGenericDatePickerLabel();
  if (isIndependentFilters && !isDashboard) {
    if(type == CARD_TYPES.BOOKMARK) {
      dateFiltersText.push(getFilterLabel(dateFilters, filterName, isRelativeDateSelected, dateFilterKey));
    } else {
      addIndependentDateFilters();
    }
  } else if (
    (enableCollectionLevelDateFilters() && !hideCommonFilters && shouldShowCommonDateFilter(cardEntries)) ||
    (shouldShowCommonDateFilter(cardEntries) || (isDateFilterEnabled && !isDashboard)) && !hideCommonFilters
  ) {
    const dateFilterConfig = getFilterLabel(
      dateFilters, dateFilterName, isRelativeDateSelected, dateFilterKey
    );
      if(isDateColumnConfigured(templateEntry) ||
        isDateColumnConfiguredForViewEntry(currentDrilldownViewEntry)) {
          dateFiltersText.push(dateFilterConfig);
      }
  } else if (!enableCollectionLevelDateFilters() && !hideCommonFilters) {
    dateFiltersText.push(getFilterLabel(dateFilters, dateFilterName, isRelativeDateSelected, dateFilterKey));
  }

  if (!_.isEmpty(additionalDateFilterConfigs)){
    _.each(additionalDateFilterConfigs, (config) => {
      const { id } = config;
      let filters = _.get(dateFilters, `additionalDateFilters.${id}`, {});
      dateFiltersText.push(getFilterLabel(filters, config.name, false, config.name));
    });
  }

  if(isDashboard) {
    addIndependentDateFilters();
  }
  if(!enableCollectionLevelDateFilters() && isEmbed) {
    dateFiltersText.push(getFilterLabel(dateFilters, filterName, false, dateFilterKey))
  }
  return dateFiltersText;
}

export const getDifferenceCollectionFilters = (templateCollectionFilters, bookmarkCollectionFilters) => {
  if (_.isEqual(templateCollectionFilters, bookmarkCollectionFilters) ||
    _.isEmpty(templateCollectionFilters)
  ) {
    return [];
  }

  const newCollectionFilters = _.map(templateCollectionFilters, (templateCollectionFilter) => {
    const { field } = templateCollectionFilter;
    const matchedFilter = _.find(bookmarkCollectionFilters, { field });

    return matchedFilter ?
      getDifferenceConditionsForStringType(templateCollectionFilter, bookmarkCollectionFilters):
      null;
  });

  return _.without(newCollectionFilters, null);
}

const getDifferenceConditionsForStringType = (filter, bookmarkFilters) => {
  const { field, conditions } = filter;
  const { conditions: matchedFilterConditions } = _.find(bookmarkFilters, { field }) || {};

  if (matchedFilterConditions) {
    const newConditions = _.filter(conditions, (condition) => {
      const { operator } = condition;
      const matchedCondition = _.find(matchedFilterConditions, { operator })|| {};
      return !_.isEqual(matchedCondition, condition);
    });

    if (_.isEmpty(newConditions)) {
      return null;
    }

    filter['conditions'] = newConditions;
  }
  return filter;
}

const getFilterLabel = (dateFilters, filterName,
  isRelativeDateSelected = false, dateFilterKey = 'date filter') => {
  const defaultDateRange = getDefaultDateRange(relativeDateFilterEntryDefaultState);
  const dateRange = _.get(dateFilters, 'dateRange', defaultDateRange);
  const dateType = _.get(dateFilters, 'dateType', configuredDefaultDateType);
  const { text: rangeText, startDateText = '' } = getDateRangeTextEntry(
    dateRange, dateType, isRelativeDateSelected
  );
  let newRangeText = rangeText;
  if(isRelativeDateSelected) {
    newRangeText = startDateText;
  }

  return {
    name: filterName,
    rangeText,
    dateFilterKey,
    dateFilterLabel: `${dateFilterKey} ${newRangeText}`,
    label: `${filterName} ${newRangeText}`
  };
}

export const formatMultipleFilterConditions = (filters) => {
  const newFilters = _.map(filters, (filterItem) => {
    const { conditions, type } = filterItem;
    if (type === STRING_TYPES_FIELD) {
      return _.map(conditions, (condition) => {
        return { ...filterItem, conditions: [condition] };
      });
    }
    return filterItem;
  });

  return _.flatten(newFilters);
}

export const polygonFilterCount = (filteredGeojson) => {
  return _.isEmpty(filteredGeojson) || _.isEmpty(_.get(filteredGeojson, 'features')) ?
  0 : 1;
}

export const geographicFilterCount = (shapeGeoJson) => {
  return _.isEmpty(shapeGeoJson) || _.isEmpty(_.get(shapeGeoJson, 'selectedShapeIds', [])) ?
  0 : 1;
}