import React from 'react';
import PropTypes from 'prop-types';

const ViewMetricLink = (props) => {
  const { isEmbed, viewMetricLink } = props;
  if(!isEmbed) {
    return null;
  }

  return (
    <span className="view-metric-link-container color-theme-primary">
      <a href={viewMetricLink} target="_blank" rel="noopener noreferrer">View metric</a>
    </span>
  );
}

ViewMetricLink.propTypes = {
  isEmbed: PropTypes.bool,
  viewMetricLink: PropTypes.string
}

ViewMetricLink.defaultProps = {
  isEmbed: false
}

export default ViewMetricLink;
