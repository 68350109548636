import moment from 'moment';
import { getUserFromBellerophon } from "common/config/customerConfiguration";
import { EDITOR_ROLE, VIEWER_ROLE } from "./constants";

export const isManageCollections = () => {
  const userFromBellerophon = getUserFromBellerophon();
  return _.get(userFromBellerophon, 'bellerophon_user_detail.isManageCollections') == 'true';
}

export const canUserRestrictCollections = () => {
  const userFromBellerophon = getUserFromBellerophon();
  return _.get(userFromBellerophon, 'bellerophon_user_detail.canRestrictCollections') == 'true';
}

export const isUserCollection = (currentCollection) => {
  return _.isNil(currentCollection['bellerophon_tag_id']);
}

export const isRestrictedCollection = (collection = {}) => {
  return (!isUserCollection(collection) && canUserRestrictCollections())
}

export const isCollaborateCollection = (collection = {}) => {
  return collection['is_collaborate_collection'] == true || collection['is_collaborate_collection'] == 'true';
}

export const isCollaborateSubscription = (subscription = {}) => {
  return subscription['is_collaborate_subscription'] == true ||
    subscription['is_collaborate_subscription'] == 'true';
}

export const canShowCopyButton = (currentCollection) => {
  const userFromBellerophon = getUserFromBellerophon();
  const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
  if(isCollaborateCollection(currentCollection)) {
    return isOwnerOrEditorRole(currentCollection) && (isLaunchpadAdmin || isManageCollections());
  } else {
    return true;
  }
}

export const canShowDeleteButton = (currentCollection) => {
  if(isCollaborateCollection(currentCollection)) {
    return isOwnerOrEditorRole(currentCollection);
  } else {
    return true;
  }
}

export const isOwnerOrEditorRole = (currentCollection) => {
  const { currentCollectionUser } = currentCollection;
  const role = _.get(currentCollectionUser, 'role_user.role', '');
  return _.get(currentCollectionUser, 'is_owner', false) || role === EDITOR_ROLE;
}

export const isViewerRole = (currentCollection = {}) => {
  const { currentCollectionUser } = currentCollection;
  const role = _.get(currentCollectionUser, 'role_user.role', '');
  return role === VIEWER_ROLE;
}

export const isCollectionViewAccess = (collection) => {
  return isCollaborateCollection(collection) && isViewerRole(collection);
}

export const hasBookmarkCard = (cardEntries) => {
  return _.some(cardEntries, { isBookmarkCard: true });
}

export const canShowNewTag = (collection) => {
  return _.get(collection, 'currentCollectionUser.role_user.is_new_tag', false);
}

export const getTimeStampForDate = (date) => {
  const currentDate = moment.utc(date).local().format('MMMM DD, YYYY');

  const currentTime = moment.utc(date).local().format('LT');

  return `${currentDate} at ${currentTime}`;
}
