import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { getPermissionFieldValues, getPermissionSearchValues } from 'common/api/adminApi';

import OutsideClickHandler from 'common/components/OutsideClickHandler';
import DropDownFooter from 'pages/drilldown/components/QuickFilterBar/DropDownFooter';
import TypeHeadAndTopValues from 'common/components/Filters/TypeHeadAndTopValues';
import BadgeItems from 'common/components/Badges/BadgeItems';
import { getWidthAndHeightForFilterBadge } from 'common/components/Filters/FilterDomHelper';

const SPLIT_OPERATOR = ';';
const ALL_VALUE = 'All';
class PermissionSearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showValueDropdown:false,
      fieldValues: [],
      isLoading: false ,
      currentSelectedValues: _.split(_.get(props, 'currentValue'), SPLIT_OPERATOR),
      offset: 0,
      isLoadMore: true
    };
    this.permissionFetchController = new AbortController();
  }
  componentDidUpdate(prevProps) {
    const { currentValue } = _.clone(this.props);
    if(!_.isEqual(currentValue, _.get(prevProps, 'currentValue'))){
      this.setState({currentSelectedValues: _.split(currentValue, SPLIT_OPERATOR)});
    }
  }

  toggleValueDropdown = () => {
    const { showValueDropdown } = this.state;
    if(!showValueDropdown) {
      this.fetchPermissionValues();
    }
    this.setState({ showValueDropdown: !showValueDropdown });
  }

  fetchPermissionValues = (params) => {
    const { strictPermissionColumn, strictPermissionValue } = this.props;
    this.permissionFetchController.abort();
    this.permissionFetchController = new AbortController();
    const newParams = {
      ...params,
      strictPermissionColumn,
      strictPermissionValue,
      includeAllTemplate: true
    };
    return getPermissionFieldValues(newParams, {
      signal: this.permissionFetchController.signal
    });
  }

  fetchPermissionSearchValues = (params) => {
    const { strictPermissionColumn, strictPermissionValue } = this.props;
    this.permissionFetchController.abort();
    this.permissionFetchController = new AbortController();
    const newParams = {
      ...params,
      strictPermissionColumn,
      strictPermissionValue,
      includeAllTemplate: true
    };
    return getPermissionSearchValues(newParams, {
      signal: this.permissionFetchController.signal
    });
  }

  handleOutSideClick = () => {
    const { currentValue } = this.props;
    if(!_.isEqual(currentValue.split(SPLIT_OPERATOR), this.state.currentSelectedValues)) {
      this.handleApplyClick()
    } else {
      this.setState({ showValueDropdown: false });
    }
  }

  handleApplyClick = () => {
    const { onValueChange, field } = this.props;
    const { currentSelectedValues } = this.state;
    const selectedValues = (_.isEmpty(currentSelectedValues) ? ALL_VALUE :
      currentSelectedValues.join(SPLIT_OPERATOR));
    onValueChange(field, selectedValues);
    this.setState({showValueDropdown: false});
  }

  handleRemoveClick = () => {
    const { currentValue } = this.props;
    this.setState({
      showValueDropdown: false,
      currentSelectedValues: _.split(currentValue, SPLIT_OPERATOR)
    });
  }

  handleListAdd = (values) => {
    const { currentSelectedValues } = this.state;
    let newSelectedValues = currentSelectedValues.concat(values)
    if(values.includes(ALL_VALUE)){
      newSelectedValues = [ALL_VALUE]
    }else {
      newSelectedValues = _.without(newSelectedValues, ALL_VALUE)
    }
    this.setState({currentSelectedValues: _.uniq(newSelectedValues)})
  }

  handleListRemove = (value) => {
    if(value == 'All') {
      return;
    }

    const { currentSelectedValues } = this.state;
    this.setState({currentSelectedValues: _.without(currentSelectedValues, value)})
  }

  renderValuesDropdown(){
    const { currentSelectedValues } = this.state;
    const { field, strictPermissionColumn, strictPermissionValue } = this.props;
    let extraOptions = [ALL_VALUE];
    if(_.isEqual(strictPermissionColumn,field) && !_.isEmpty(strictPermissionValue)){
      extraOptions = [];
    }

    return(
      <TypeHeadAndTopValues
        isCurrencyRenderType={false}
        apiParams={{}}
        fetchTopValues={this.fetchPermissionValues}
        fetchSearchInputValues={this.fetchPermissionSearchValues}
        field={field}
        hideInputBox={false}
        topValueLabel=""
        extraOptions={extraOptions}
        values={currentSelectedValues}
        selectedValues={currentSelectedValues}
        onAdd={this.handleListAdd}
        onRemove={this.handleListRemove}
        filterName={field}
      />
    )
  }

  renderFieldDropdown(){
    const { showValueDropdown } = this.state;

    if(!showValueDropdown) {
      return
    }
    return(
      <div className="common-filters-wrapper">
        <div className="filters-body">
          <div className="filter-items permission-field-dropdown">
            {this.renderValuesDropdown()}
            {this.renderCurrentSelectedFields()}
          </div>
        </div>
        <DropDownFooter
          onApply={this.handleApplyClick}
          onRemove={this.handleRemoveClick}
          hideResetButton={true}
          hideRemoveButton={false} />
      </div>
    )
  }

  renderCurrentSelectedFields() {
    const { currentSelectedValues } = this.state;
    if(_.isEmpty(currentSelectedValues)) {
      return null;
    }

    return (
      <div className="d-flex flex-wrap gap-8 mt-2">
        <BadgeItems
          badgeObject={{
            name: 'currentSelectedValues',
            values: currentSelectedValues
          }}
          onRemoveBadgeItem={(item) => this.handleListRemove(item.value)} />
      </div>
    )
  }

  renderFieldTitle(){
    const {field, currentValue } = this.props;
    const { showValueDropdown, currentSelectedValues } = this.state;
    const selectedValues = (_.isEmpty(currentSelectedValues) ? [ALL_VALUE] : currentSelectedValues);
    if(showValueDropdown) {
      return(
        <div className="mt-1">Edit permissions</div>
      )
    }
    return(
      <div className="field-current-value" key={field+currentValue} onClick={this.toggleValueDropdown}>
        <span>{field}</span> is <b>{selectedValues.join(' or ')}</b>
      </div>
    )
  }
  render(){
    const { isDisabledField } = this.props;
    const { showValueDropdown } = this.state;
    const dropdownClassNames = classNames('field-value-picker',
      {
        'show-filter-chip': showValueDropdown,
        'disabled': isDisabledField
      }
    );
    const style = showValueDropdown ? getWidthAndHeightForFilterBadge(this.fieldDropdownRef) : {};

    return(
      <div className={dropdownClassNames}
        ref={(ref) => this.fieldDropdownRef = ref} style={style}>
        <div className="filter-chip">
          <div className="field-title filter-chip-values pr-2">
            <i className="icons-np-key" />
            {this.renderFieldTitle()}
          </div>
          <OutsideClickHandler
            onClickOutSide={() => this.handleOutSideClick()} >
            <div className="fields-dropdown filter-chip-show">
              {this.renderFieldDropdown()}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    )
  }
}

PermissionSearchField.propTypes = {
  field: PropTypes.string,
  currentValue: PropTypes.string,
  strictPermissionColumn: PropTypes.string,
  strictPermissionValue: PropTypes.string,
  onValueChange: PropTypes.func,
  isDisabledField: PropTypes.bool
}
export default PermissionSearchField;
