export const OTHER_BUCKET_ID = 'OTHER_BUCKET_ID';
export const OTHER_BUCKET_VALUE = 'Others';

export const PLOTLY_HOVER_DEBOUNCE_WAIT_TIME = 100;
export const PIE_CHART_DATA_LIMIT = 10;

export const VIEW_MODE = { SMALL: 'small', LARGE: 'large'};

export const EMPTY_VALUE = "(No value)";
export const DEFAULT_CHOROPLETH_DATA_CLASSES = "5";
export const DEFAULT_CHOROPLETH_CLASSIFICATION_METHOD = "jenks";

export const DRILLDOWN_VIEW_TYPE = [
  {name: 'Compare', type: 'compare_view'},
  {name: 'Combine', type: 'combine_view'}
];
export const BAR_CHART_AREA_HEIGHT = {
  0: 230,
  1: 150,
  2: 180,
  3: 210,
  4: 220,
  5: 230
};

//Todo: these colors to be configured from bellerophon.
export const COMPARE_VIEW_STACK_COLORS = [
  '#3F51B5',
  '#E55100',
  '#FFB300',
  '#448AFF',
  '#C2185B',
  '#4CAF50',
  '#7C4EFF',
  '#80CBC4',
  '#F06292',
  '#FF9100',
  '#FDD735',
  '#9CCC65',
  '#448AFF',
  '#9575CD',
  '#A5D6A7',
  '#00897B',
  '#684655',
  '#FE982C',
  '#F78620',
  '#D9A295',
  '#52AF43',
  '#6F9E4C',
  '#DC9A88',
  '#F16666',
  '#E42022',
  '#A6CEE3',
  '#5B9EC9',
  '#2D82AF',
  '#7EBA98',
  '#98D277'
];

export const SCROLL_TYPES = {
  START: 'start',
  END: 'end'
};

export const SCROLL_EVENT = 'PAGE_SCROLL';
export const MAP_MOVE_END = 'MAP_MOVE_END';
export const  MAP_EVENTS = {
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  RE_CENTER: 'RE_CENTER',
  DRAG_END: 'DRAG_END',
  ZOOM_END: 'ZOOM_END'
}

export const POLYGON_DRAWING_HELPER_TEXT = "Click or tap on the map to begin drawing boundaries"
+" for this geofence. Double-click or tap to close the shape. Click 'Complete' to apply the geofence(s)."
