// Vendor Imports
import _ from 'lodash';

// Project Imports
import { DEFAULT_TOTAL_NAME } from 'appConstants';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { isYearOnYear } from './vizOptionsHelper';


export function toggleVisibilityForDimensionConfigs(toggleDimesionConfig, dimensionConfigs) {
  const { traceId,  visible, isRemoveTotal, dimension } = toggleDimesionConfig;
  return _.map(dimensionConfigs, (dimensionConfig) => {
    const traceField = isRemoveTotal ? 'dimension' : 'traceId';
    const compareTraceId = isRemoveTotal ? dimension : traceId;
    if (dimensionConfig[traceField] === compareTraceId) {
      return _.merge({}, dimensionConfig, { visible: !visible });
    }
    return dimensionConfig;
  });
}

export const getNewDimensionConfigs = (formattedData, previousDimensionConfigs, vizOptions) => {
  const { renderTimeFrame, isComboChart } = vizOptions;
  const configKey = isComboChart ? 'comboMetricConfigs': 'dimensionConfigs';
  const isDimensionsChanged = isDimensionsChange(
    formattedData,
    {...vizOptions, [configKey]: previousDimensionConfigs }
  );
  const dimensionOptions = {
    dimensionConfigs: previousDimensionConfigs,
    formattedData,
    vizOptions,
  };

  if (isComboChart) {
    return _.concat([], formattedData[configKey]);
  } else if(
    !isDimensionsChanged && !isYearOnYear({ renderTimeFrame }) && !_.isEmpty(previousDimensionConfigs)
  ) {
    return _.concat([], formattedData[configKey]);
  }
  return sortByDimensionConfigs(dimensionOptions);
}

const sortByDimensionConfigs = (dimensionOptions) => {
  const { formattedData, vizOptions } = dimensionOptions;
  const { isComboChart } = vizOptions;
  const dimensionConfigs = isComboChart ?
    formattedData['comboMetricConfigs'] :
    formattedData['dimensionConfigs'];

  const uniqLegendEntries = _.chain(dimensionConfigs).
                              filter((config) => !_.isEmpty(_.get(config, 'traceId'))).
                              uniqBy('traceId').
                              compact().
                              value();

  return uniqLegendEntries;
}

export const getDimensionsList = (dimensionConfigs, viewEntry, isComboChart) => {
  const primaryMetricName = isComboChart ? getPrimaryMetricName(viewEntry) : DEFAULT_TOTAL_NAME;
  const filteredDimesionConfigs = _.filter(dimensionConfigs, (config) => {
    const { traceId, visible } = config || {};
    return (traceId !== primaryMetricName) && visible;
  });

  return _.map(filteredDimesionConfigs, (dimensionConfig, index) => {
    const isLastDimension = (index === filteredDimesionConfigs.length -1);

    return isLastDimension ?
      ` and ${_.get(dimensionConfig, 'traceId')}` :
      `, ${_.get(dimensionConfig, 'traceId')}`;
  });
}

export const isDimensionsChange = (formattedData, vizOptions) => {
  if (_.isEmpty(formattedData)){
    return false;
  }
  const { isComboChart, viewEntry, dimensionConfigs, comboMetricConfigs } = vizOptions;
  const primaryMetricName = isComboChart ? getPrimaryMetricName(viewEntry) : DEFAULT_TOTAL_NAME;
  // TODO: Fix for hotfix we need to cleanup
  const metricsList = isComboChart ?
    _.map(formattedData['comboMetricConfigs'], 'traceId') :
    _.chain(formattedData['dimensionConfigs']).
    filter((config) => !_.isEqual(_.get(config, 'dimension'), primaryMetricName)).
    map('dimension').
    uniq().
    value();
  const configs = isComboChart ? comboMetricConfigs : dimensionConfigs;
  const dimensions = _.chain(configs).
    filter((config) => !_.isEqual(_.get(config, 'dimension'), primaryMetricName)).
    map('dimension').
    uniq().
    value();
  return !_.isEqual(dimensions, metricsList)
}
