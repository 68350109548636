import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom'

import { updateBookmarkDetails } from 'actions/bookmarkActions';
import { EMAIL_STRATEGY_TYPES } from 'appConstants';
import { getRadarPageTitle } from 'common/config/customerConfiguration';

const BookmarkBaseViewSelector = (props) => {
  const { bookmark, userCollectionsWithCards } = props;
  const dispatch = useDispatch();
  const isRadar = _.get(bookmark, 'is_radar', false);
  const drillDownViewName = _.get(bookmark, 'drilldown.currentDrilldownViewEntry.name', '');
  const isNeverEmailStrategy = _.get(bookmark, 'email_strategy') == EMAIL_STRATEGY_TYPES.NEVER;
  const bookmarkName = isNeverEmailStrategy ? '' : 'Alert Based On';
  const viewName = isNeverEmailStrategy ? bookmark.name :  drillDownViewName;
  const toLink = isRadar ? '/radar' : '/analysis';
  const newViewName = isRadar ? getRadarPageTitle() : viewName;

  const onClickView = () => {
    updateBookmarkDetails({bookmarkConfig: bookmark, dispatch, 
      isUpdateBookmarkId: isNeverEmailStrategy,
       userCollectionsWithCards});
  }

  return (
    <div className="ml-auto tx-14">
      {bookmarkName}
      <NavLink
        to={toLink}
        onClick={onClickView}>{` ${newViewName}`}
      </NavLink>
    </div>
  );
}

BookmarkBaseViewSelector.propTypes = {
  bookmark: PropTypes.object,
  userCollectionsWithCards: PropTypes.array
}

BookmarkBaseViewSelector.defaultProps = {
  bookmark: {},
  userCollectionsWithCards: []
}

export default BookmarkBaseViewSelector;
