import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { buildQueryString } from 'helpers/HttpHelper';

import { getShapeDatasetEntries } from 'common/config/templateConfiguration';
import { updateShapeData, updateShapeDatasetEntry } from 'actions/mapOptionsActions';
import { fetchApiData } from 'helpers/apiResponseHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import CustomDropdownList from 'common/components/SolutionDropDown/CustomDropdownList';

class ShapeDropdownSelector extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const { selectedShapeEntry } = this.props;
    const isShapeEntryChanged = !_.isEqual(selectedShapeEntry, prevProps.selectedShapeEntry);
    if(isShapeEntryChanged) {
      //when shape group is changed then shape details updated.
      this.updateShapeData(selectedShapeEntry);
    }
  }

  updateShapeData = async (selectedShapeEntry) => {
    const { currentDrilldownTemplateId } = this.props;
    const params = {
      currentDrilldownTemplateId,
      shapeGroupId: selectedShapeEntry.shape_dataset_id
    };
    const apiUrl = `api/shapes/details_without_geom.json?${buildQueryString(params)}`;
    const shapeEntries = await fetchApiData(apiUrl);
    this.props.dispatchUpdateShapeData(_.groupBy(shapeEntries, 'shape_id'));
  }

  handleShapeSelect = (selectedShapeEntry) => {
    if(this.props.updateShapeDatasetEntry) {
      this.props.updateShapeDatasetEntry(selectedShapeEntry);
    } else {
      this.props.dispatchUpdateShapeDatasetEntry(selectedShapeEntry);
    }
  }

  render() {
    const { selectedShapeEntry, labelText, currentDrilldownTemplateId } = this.props;
    if (_.isEmpty(selectedShapeEntry)) {
      return null;
    }
    return (
      <div className="forge-popup-host">
        {labelText && <h6>{labelText}</h6>}
        <CustomDropdownList
          key="shape-drop-down"
          id="select-by"
          className="p-0  d-flex"
          title={_.get(selectedShapeEntry, 'shape_name')}
          showCount={true}
          optionDisplayField="shape_name"
          options={getShapeDatasetEntries(currentDrilldownTemplateId)}
          onSelect={this.handleShapeSelect} />
        {/* <SolutionDropDown
          size='sm'
          id="select-by"
          className='p-0 btn-block'
          title={_.get(selectedShapeEntry, 'shape_name')}
          options={getShapeDatasetEntries(currentDrilldownTemplateId)}
          optionDisplayField="shape_name"
          onSelect={this.handleShapeSelect} /> */}
      </div>
    );
  }
}

ShapeDropdownSelector.propTypes = {
  labelText: PropTypes.string,
  dispatchUpdateShapeData: PropTypes.func,
  dispatchUpdateShapeDatasetEntry: PropTypes.func,
  updateShapeDatasetEntry: PropTypes.func,
  selectedShapeEntry: PropTypes.object,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes
};

const mapDispatchToProps = {
  dispatchUpdateShapeData: updateShapeData,
  dispatchUpdateShapeDatasetEntry: updateShapeDatasetEntry
};

export default connect(null, mapDispatchToProps)(ShapeDropdownSelector);
