import React, { useEffect } from 'react';
import {
  getForecastingConceptTitle,
  getForecastingConceptIntroTitle,
  getForecastingConceptIntro,
  getForecastingConceptModalTitle,
  getForecastingConceptProphetModal,
  getForecastingConceptHistoricalModal,
  getForecastingConceptExponentialModal,
  getForecastingConceptAccuracyTitle,
  getForecastingConceptAccuracyMAPE,
  getForecastingConceptAccuracyMAE
} from 'common/config/customerConfiguration';
import { useLocation } from 'react-router-dom';
import { ForgeIconButton } from '@tylertech/forge-react';
const Index = () => {
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('scroll');
    if (_.isEmpty(id)) {
      return;
    }
    var element = document.querySelectorAll(`[id="${id}"]`)[0];
    if (!_.isNull(element)) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      // Adjust scroll position after a slight delay to ensure smooth scrolling finishes
      setTimeout(() => {
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const offset = 150; // Change this value to adjust the final scroll position
        window.scrollTo({
          top: absoluteElementTop - offset,
          behavior: "smooth"
        });
      }, 300);
    }
  }, [location.search]);

  const handleBackButtonClick = () => {
    window.location = "/#!/forecasting";
    window.location.reload();
  };

  const backButton = () => (
    <ForgeIconButton dense="true" densityLevel="3" className="forge-back-btn">
      <button
        aria-label="Back To Overview"
        className="btn back-arrow-icon back-btn-light"
        onClick={() => handleBackButtonClick()}>
          <i className="icons-arrow-left2" />
      </button>
    </ForgeIconButton>
  );

  const forecastingIntro = () => (
    <div id="intro">
      <div className='forge-typography--headline3'>{getForecastingConceptIntroTitle()}</div>
      <div className="mt-4">
        <div dangerouslySetInnerHTML={{ __html: getForecastingConceptIntro() }} />
      </div>
    </div>
  );

  const forecastingModalConcepts = () => (
    <>
      <div className='forge-typography--headline3'>{getForecastingConceptModalTitle()}</div>
      <div className="mt-5" id="prophet">
        <div dangerouslySetInnerHTML={{ __html: getForecastingConceptProphetModal() }} />
      </div>
      <div className="mt-5" id="historical">
        <div dangerouslySetInnerHTML={{ __html: getForecastingConceptHistoricalModal() }} />
      </div>
      <div className="mt-5" id="exponential">
        <div dangerouslySetInnerHTML={{ __html: getForecastingConceptExponentialModal() }} />
      </div>
    </>
  );

  const forecastingAccuracyConcepts = () => (
    <>
      <div id="Past performance" className='forge-typography--headline3'>
        {getForecastingConceptAccuracyTitle()}
      </div>
      <div className="mt-5" id="MAPE score">
        <div dangerouslySetInnerHTML={{ __html: getForecastingConceptAccuracyMAPE() }} />
      </div>
      <div className="mt-5" id="MAE score">
        <div dangerouslySetInnerHTML={{ __html: getForecastingConceptAccuracyMAE() }} />
      </div>
    </>
  );

  return (
    <main className="read-more-page learn-more-page">
      <div className="page-main-head">
        <div className='page-main-header'>
          {backButton()}
          <div>{getForecastingConceptTitle()}</div>
        </div>
      </div>
      <div className='read-page-modals mt-5'>
        {forecastingIntro()}
        {forecastingModalConcepts()}
        {forecastingAccuracyConcepts()}
      </div>
    </main>
  );
}

export default Index;
