import PropTypes from 'prop-types';
import React, { Component } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Spinner } from 'react-bootstrap';
import GlobalEvent from 'common/components/GlobalEvents';
import NoDataFound from 'common/components/NoDataFound/NoDataFound';
import ErrorHandler from 'common/components/NoDataFound/ErrorHandler';
import { isEmptyApiData } from './helper';
import { VIEW_MODE } from 'modules/visualization/constants';

const Plot = createPlotlyComponent(window.Plotly);

const propTypes = {
  __stubApiData: PropTypes.object,
  extraPlotlyParams: PropTypes.object,
  onNewApiData: PropTypes.func,
  onNewFormattedData: PropTypes.func,
  onNewPlotlyParams: PropTypes.func,
  onAfterPlot: PropTypes.func,
  vizOptions: PropTypes.object,
  debounceOptions: PropTypes.object,
  plotlyTooltip: PropTypes.object
};
const defaultProps = {
  __stubApiData: null,
  extraPlotlyParams: {},
  onNewApiData: _.noop,
  onNewFormattedData: _.noop,
  onNewPlotlyParams: _.noop,
  onAfterPlot: _.noop,
  vizOptions: {
    isChartAndTotalLoading: false,
    onDataLoading: _.noop
  },
  debounceOptions: {}
};
const DEBOUNCE_WAIT_TIME = 100;

export default (apiStore, dataFormatter, plotlyParamsGenerator) => {
  const PlotlyView = class extends Component {
    constructor(props) {
      super(props);

      this.abortFetchController = new AbortController();
      this.state = {
        isLoading: false,
        apiData: props.__stubApiData,
        formattedData: null,
        plotlyParams: null,
        promiseCount: 0,
        apiStatus: 200,
        isValid: true
      };
      this.debouncedProcess = _.debounce(this.process, DEBOUNCE_WAIT_TIME, props.debounceOptions);
    }

    componentDidMount() {
      this._isMounted = true;
      this.process({ previousVizOptions: null });
    }

    componentWillUnmount() {
      this._isMounted = false;
      this.abortFetchController.abort();
    }

    componentDidUpdate(prevProps) {
      const { vizOptions } = prevProps;
      if (!_.isEqual(vizOptions, this.props.vizOptions)) {
        this.debouncedProcess({ previousVizOptions: vizOptions })
      }
    }

    updateLoadingSpinnerAfterRender = (vizOptions, isLoading) => {
      const { onDataLoading } = vizOptions;
        onDataLoading(isLoading);
    };

    updateLoadingStatus = (isLoading) => {
      const { promiseCount: newPromiseCount } = this.state;
      const { vizOptions } = this.props;
      const { viewMode, onDataLoading } = vizOptions;
      if (viewMode === VIEW_MODE.SMALL) {
        onDataLoading(newPromiseCount != 0);
      }

      this.setState((preState) => {
        let  promiseCount  = _.get(preState, 'promiseCount');
        if (isLoading) {
          promiseCount = promiseCount + 1;
        } else {
          promiseCount = promiseCount - 1;
        }

        return {
          isLoading: (promiseCount != 0), promiseCount
        }
      });
    }

    process = async ({ previousVizOptions }) => {
      const { __stubApiData, vizOptions, onNewFormattedData, onNewApiData, onNewPlotlyParams } = this.props;
      let { apiData, formattedData, plotlyParams, isLoading } = this.state;

      let isDirty = false;
      let isRequestAborted = false;
      if (apiStore.shouldUpdate(vizOptions, previousVizOptions)) {
        try {
          if (__stubApiData) {
            apiData = __stubApiData;
          } else {
            this.abortFetchController.abort();
            this.abortFetchController = new AbortController();
            this.updateLoadingStatus(true);
            this.updateLoadingSpinnerAfterRender(vizOptions, true);
            isRequestAborted = false;
            apiData = await apiStore.getData(
              vizOptions, this.abortFetchController, previousVizOptions,
              onNewApiData).
              then((response) => {
                isRequestAborted = false;
                const res = { ...response['api_data'], ...response }
                this.setState({ apiStatus: res['status'],isValid: res['isValid'] });
                this.updateLoadingStatus(false);
                // adding timeout because sometimes api load before cancel api code execute
                return new Promise((resolve) => {
                  setTimeout(resolve, 100)
                }).then(() =>{
                  return res;
                })
              })
          }
          // this function is called in data store.
          if(!_.get(vizOptions, 'isForecastingView', false)){
            onNewApiData(apiData);
          }
          isDirty = true;
        } catch (err) {
          console.log('err', err)
          isDirty = _.get(err, 'name') !== 'AbortError';
          if(_.get(err, 'name') == 'AbortError'){
            isRequestAborted = true;
          }
          this.updateLoadingStatus(false);
          this.updateLoadingSpinnerAfterRender(vizOptions, false);
        }
      }
      if (isDirty || dataFormatter.shouldUpdate(vizOptions, previousVizOptions)) {
        formattedData = dataFormatter.formatData(vizOptions, apiData, isDirty);
        if(!isRequestAborted){
          onNewFormattedData(formattedData, apiData);
        }
        isDirty = true;
        this.updateLoadingSpinnerAfterRender(vizOptions, true);
      }
      if (isDirty || plotlyParamsGenerator.shouldUpdate(vizOptions, previousVizOptions)) {
        plotlyParams = plotlyParamsGenerator.toPlotlyParams(vizOptions, apiData, formattedData);
        onNewPlotlyParams(formattedData, apiData, plotlyParams);
        isDirty = true;
        this.updateLoadingSpinnerAfterRender(vizOptions, false);
      }

      if (isDirty && this._isMounted) {
        this.setState({ apiData, formattedData, plotlyParams });
        GlobalEvent.emit('PLOTLY_PARAMS', plotlyParams)
        const isEmptyData = isEmptyApiData(apiData, vizOptions);

        if (isEmptyData) {
          vizOptions.onDataLoading(null, isEmptyData);
        }
        GlobalEvent.emit('IS_EMPTY_DATA', {
          isEmptyData: isEmptyData,
          isLoading: isLoading
        })
      }

    }

    renderLoadingSpinner() {
      const { isLoading } = this.state;

      if (isLoading || this.props.vizOptions.isChartAndTotalLoading) {
        return (
          <div className="spinner-overlay">
            <Spinner variant="primary" className="loading-spinner" animation="border" />
          </div>
        );
      }
    }

    render() {
      const { plotlyParams, apiData, isLoading, apiStatus, isValid } = this.state;
      const { extraPlotlyParams, vizOptions, plotlyTooltip } = this.props;
      const style = { width: "98%", height: "100%" };

      // We are sending the same layout object (from state) as props to react plotly.
      // In some cases, plotly is mutating the values especially height/width related
      // ones which is causing side effects. So we are cloning and sending the layout
      // object, to keep it away from plotly changing values in it.
      const plotProps = {
        ...extraPlotlyParams,
        ...plotlyParams,
        layout: _.clone(_.get(plotlyParams, 'layout')),
        useResizeHandler: true,
        style
      };
      const isEmptyData = isEmptyApiData(apiData, vizOptions);
      const viewMode = _.get(vizOptions, 'viewMode');
      const isChartAndTotalLoading = _.get(vizOptions, 'isChartAndTotalLoading');
      if (this.visualizationRef) {
        this.visualizationRef.plotProps = plotProps;
        this.visualizationRef.vizOptions = vizOptions;
        this.visualizationRef.plotlyTooltip = plotlyTooltip;
      }

      return (
        <div className="visualization-charts" ref={(ref) => this.visualizationRef = ref}>
          {this.renderLoadingSpinner()}
          {!isValid && <ErrorHandler viewMode={viewMode} statusCode={apiStatus} />}
          {isEmptyData && isValid && !isLoading && !isChartAndTotalLoading &&
            <div> <NoDataFound viewMode={viewMode} /> </div>
          }
          {!isEmptyData && isValid && !isChartAndTotalLoading && <Plot {...plotProps} />}
        </div>
      );
    }
  }

  PlotlyView.propTypes = propTypes;
  PlotlyView.defaultProps = defaultProps;

  return PlotlyView;
}
