import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function ResizableFont(props) {
  const { explorationEntry, cardTitleRef } = props;

  const [fontSize, setFontSize] = useState(34);
  const [startSize, setStartSize] = useState(10);
  const divResizeRef = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      let divHeight = (cardTitleRef && cardTitleRef.current) ? cardTitleRef.current.clientHeight : 200;
      const isImage = !_.isEmpty(_.get(explorationEntry,'image'));
      if(isImage){
        divHeight = 75;
      }
      const divHeightNew = divResizeRef.current.clientHeight;

      if (divHeightNew > divHeight) {
        let dividedValue  = isImage ? startSize / 5: startSize;
        const newFontSize = Math.min(34, (divHeight / dividedValue));
        setStartSize((startSize + 2))
        setFontSize(newFontSize);
      }
    };

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [startSize]);

  return (
    <div ref={divResizeRef}>
      <div style={{ fontSize: `${fontSize}px` }}>
        {_.get(explorationEntry,'name')}
      </div>
    </div>
  );
}

ResizableFont.propTypes = {
  cardTitleRef: PropTypes.any,
  explorationEntry: PropTypes.object
}

export default ResizableFont;
