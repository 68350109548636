import { useEffect, useState, useRef } from 'react';

import { getRadarData } from 'common/api/commonApi';
import {
  defaultRadarEntry
} from 'common/config/customerConfiguration';
import { getPreviousYearDateRange } from 'pages/Radar/radarHelper';

const UseDataFetching = (radarOptions) => {
  const [radarData, setRadarData] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);
  const params = getParams();

  function getParams() {
    const selectedMetricEntries = _.get(radarOptions, 'selectedMetricEntries', []);
    const selectedShapeAreaEntry = _.get(radarOptions, 'selectedShapeAreaEntry', {});
    const currentDateRangeOptions = _.get(radarOptions, 'currentDateRangeOptions', {});
    const previousDateRangeOptions = _.get(radarOptions, 'previousDateRangeOptions', {});
    const currentDateRange = _.get(currentDateRangeOptions, 'dateRange', {});
    const previousYearDateRange = getPreviousYearDateRange(previousDateRangeOptions, currentDateRange);

    const currentTemplateId = _.get(defaultRadarEntry(), 'template_id');
    const metricFilters = _.get(radarOptions, 'metricFilters', {});
    return {
      ignore_view_entry: true,
      selectedMetrics: JSON.stringify(_.map(selectedMetricEntries, 'id')),
      area_entry_id: _.get(selectedShapeAreaEntry, 'id'),
      commonFilters: JSON.stringify({dateRange: currentDateRange }),
      drilldownEntry: JSON.stringify({
        currentDrilldownTemplateId: currentTemplateId,
      }),
      radarDateRanges: JSON.stringify({
        current: currentDateRange,
        compare: previousYearDateRange
      }),
      radarMetricFilters: JSON.stringify(metricFilters)
    }
  }

  useEffect(() => {
    isMounted.current = true;
    if(abortController) {
      abortController.abort();
    }
    const abortController = new AbortController();

    setLoading(true);
    getRadarData(params, abortController)
      .then((response) => {
        if (isMounted.current) {
          setRadarData(response);
          setLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    return () => {
      isMounted.current = false;
      abortController.abort();
    };
  }, [JSON.stringify(params)])

  return { loading, data: radarData };
}

export default UseDataFetching;
