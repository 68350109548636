import _ from 'lodash';
import React, { Component } from 'react';

import GlobalEvent from 'common/components/GlobalEvents';
import GenericVisualization from '../GenericVisualization';
import dataStore from './dataStore';
import dataFormatter from './dataFormatter';
import plotlyParamsGenerator from './plotlyParamsGenerator';
import { lineChartPropTypes, defaultProps } from './propTypes';
import Legend from './Legend';
import { toggleVisibilityForDimensionConfigs, getNewDimensionConfigs } from './legendHelper';
import {
  getChartPeriodData,
  getPlotlySliderRange
} from './vizOptionsHelper';
import PlotlyTooltip from 'modules/PlotlyTooltip';
import { getLineChartPopupConfigs } from 'common/contentFormatter/lineChartContentFormatter';
import { getAreaChartPopupConfigs } from 'common/contentFormatter/areaChartContentFormatter';
import { getComboChartPopupConfigs } from 'common/contentFormatter/comboChartContentFormatter';
import {
  OVERTIME_VISUALIZATION_TYPES,
  PLOTLY_SLIDER_RANGE_OPTION,
  PREVIOUS_URL_UPDATED
} from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getDimensionsTraceConfigs } from './Helpers/dimensionHelper';
import { shouldShowDimensions } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { setIframeHeight } from 'modules/visualization/SnapshotVisualization/snapshotAfterPlotHelper';
import { handleAfterPlot } from 'modules/visualization/LineChart/Helpers/overtimeAfterPlotHelper';
import SummaryTableDataContext from 'context/SummaryTableDataContext';

const DEBOUNCE_WAIT_TIME = 1;
const GenericLineChart = GenericVisualization(dataStore, dataFormatter, plotlyParamsGenerator);

class LineChart extends Component {
  constructor(props, context) {
    super(props, context);
    this.plotlyData = [];
    this.state = {
      dimensionConfigsByRenderType: _.get(props, 'dimensionConfigsByRenderType', {})
    };
    this.debouncedPlotlyRelayout = _.debounce(this.onPlotlyRelayout, DEBOUNCE_WAIT_TIME);
  }
  static contextType = SummaryTableDataContext;

  componentDidMount() {
    if (this.popupContainer) {
      this.plotlyTooltip = new PlotlyTooltip(this.popupContainer);
    }
  }

  componentDidUpdate(prevProps) {
    const previousDrilldownEntry = JSON.parse(_.get(prevProps, 'apiParams.drilldownEntry', "{}"));
    const drilldownEntry = JSON.parse(_.get(this.props, 'apiParams.drilldownEntry', "{}"));
    const {
      currentDrilldownDimensionField,
      currentDrilldownTemplateId,
      currentViewEntryField
    } = drilldownEntry;
    const { isComboChart, renderType, viewEntry, isForecastingView } = this.props;
    const { dimensionConfigsByRenderType } = this.state;
    const dimensionConfigsWithoutTotal = _.filter(dimensionConfigsByRenderType, (config) => {
      return !_.get(config, 'isTotalLine');
    });
    
    const isSameRenderType = _.get(prevProps, 'renderType') === renderType;
    const previousDrilldownDimensionField = _.get(previousDrilldownEntry, 'currentDrilldownDimensionField');
    const previousDrilldownTemplateId = _.get(previousDrilldownEntry, 'currentDrilldownTemplateId');
    if(!_.isEqual(previousDrilldownDimensionField, currentDrilldownDimensionField) ||
      !_.isEqual(previousDrilldownTemplateId, currentDrilldownTemplateId) ||
      !_.isEqual(_.get(previousDrilldownEntry, 'currentViewEntryField'), currentViewEntryField) ||
      (!this.isAreaChart() && (!shouldShowDimensions(viewEntry, renderType) || isForecastingView) &&
        !isSameRenderType) ||
      (isComboChart  && !_.isEmpty(_.compact(dimensionConfigsWithoutTotal)))){
      this.setDimensionConfigs([]);
    } else {
      if(!_.isEqual(_.get(this.props, 'dimensionConfigsByRenderType'),
                    _.get(prevProps, 'dimensionConfigsByRenderType'))){
        this.setDimensionConfigs(_.get(this.props, 'dimensionConfigsByRenderType'));
      }
    }
  }

  setDimensionConfigs = (dimensionConfigs) => {
    const { handleDimensionConfigsChange } = this.props;

    if(!_.isEqual(this.state.dimensionConfigsByRenderType, dimensionConfigs)){
      this.setState({ dimensionConfigsByRenderType: dimensionConfigs }, () => {
        handleDimensionConfigsChange(dimensionConfigs);
      });
    }
  }

  handleToggleLegendClick = (dimensionConfig) => {
    const toggledDimensionConfigs = toggleVisibilityForDimensionConfigs(
      dimensionConfig,
      this.getDimensionConfigs()
    );

    this.setDimensionConfigs(toggledDimensionConfigs);
  }

  getDimensionConfigs = () => {
    const { dimensionConfigsByRenderType } = this.state;
    return dimensionConfigsByRenderType;
  }

  onNewFormattedData = (formattedData) => {
    const {
      onNewFormattedData,
      onApiDataLoad
    } = this.props;
    const previousDimensionConfigs = this.getDimensionConfigs();
    const newDimensionConfigs = getNewDimensionConfigs(formattedData, previousDimensionConfigs, this.props);
    let selectedLegendCount = _.size(
      _.filter(newDimensionConfigs, (legend) => {return _.get(legend,'visible') === true })
    );
    onApiDataLoad(selectedLegendCount);
    if (!_.isEqual(previousDimensionConfigs, newDimensionConfigs)) {
      // undo button stores pervious url before chart load
      // we are deleting the last url if we are loading the chart for the first time
      let lastUrls = window.lastUrls || [];
      lastUrls.pop();
      window.lastUrls = lastUrls;

      this.setDimensionConfigs(newDimensionConfigs);
    }
    setTimeout(() => {
      GlobalEvent.emit(PREVIOUS_URL_UPDATED);
    }, 500)

    onNewFormattedData(formattedData);
  }

  onAfterSummaryTableDataLoad = (summaryData) => {
    const { isEmbed, viewMode } = this.props;
    // Only we set the formattedData in Embed mode.
    // We need formattedData for display the flyout details in summary page.
    if(isEmbed && !_.isUndefined(this.context) && viewMode === VIEW_MODE.SMALL) {
      if( !_.isUndefined(this.context.onUpdateSummaryData)){
        this.context.onUpdateSummaryData(summaryData, this.getVizOptions());
      }
    }
    this.props.onAfterSummaryTableDataLoad(summaryData);
  }

  onNewPlotlyParams = (formattedData, _apiData, plotlyParams) => {
    this.plotlyData = getChartPeriodData(plotlyParams.data);
  }

  handleLegendConfigsChange = (newDimensionConfigs) => {
    const { onApiDataLoad, onUpdateOvertimeIsEmptyLegend } = this.props;
    let isEmptyLegendItems = !(_.size(newDimensionConfigs) > 0);
    let selectedLegendCount = _.size(
      _.filter(newDimensionConfigs, (legend) => { return legend.visible === true })
    );
    onUpdateOvertimeIsEmptyLegend(isEmptyLegendItems);
    onApiDataLoad(selectedLegendCount);

    const previousDimensionConfigs = this.getDimensionConfigs();

    if (!_.isEqual(
      _.filter(newDimensionConfigs, 'primaryTrace'),
      _.filter(previousDimensionConfigs, 'primaryTrace'))
    ) {
      const newConfigs = getDimensionsTraceConfigs(
          _.map(newDimensionConfigs, 'dimension'),
          newDimensionConfigs,
          {...this.props, dimensionConfigs: previousDimensionConfigs},
          true
        )
      this.setDimensionConfigs(newConfigs);
    }
  }

  isAreaChart = () => {
    const { renderType } = this.props;
    return (renderType === OVERTIME_VISUALIZATION_TYPES.AREA.type);
  }

  onPlotlyHover = (data) => {
    const dimensionConfigs = this.getDimensionConfigs();
    const {
      isComboChart,
      renderTimeFrame,
      renderType,
      secondaryMetricEntry,
      isCurrencyDimensionField,
      viewEntry,
      viewMode,
      compareYearRanges,
      projectionEnabled,
      templateId,
      dateType,
      dateRange,
      dateRangeMode,
      isDimensionHighToLow,
      forecastingOption,
      isForecastingView,
      axisGranularity
    } = this.props;
    // sometime we show range slider. so we set cursor pointer inherit.
    this.chartContainer.querySelector('g.draglayer .cursor-ew-resize').style['cursor'] = 'inherit';
    this.chartContainer.querySelector('g.draglayer').style['cursor'] = 'inherit';
    let popupConfigs;
    const options = {
      chartContainer: this.chartContainer,
      data,
      dateType,
      dateRangeMode,
      renderTimeFrame,
      secondaryMetricEntry,
      isCurrencyDimensionField,
      viewEntry,
      viewMode,
      projectionEnabled,
      compareYearRanges,
      templateId,
      dateRange,
      renderType,
      dimensionConfigs,
      isDimensionHighToLow,
      forecastingOption,
      isForecastingView,
      axisGranularity
    };
    if(this.isAreaChart()) {
      popupConfigs = getAreaChartPopupConfigs(options);
    } else if(isComboChart) {
      popupConfigs = getComboChartPopupConfigs(options);
    } else {
      popupConfigs = getLineChartPopupConfigs(options);
    }

    this.plotlyTooltip.showPopups(popupConfigs);
  }

  handleOnAfterPlot = () => {
    setIframeHeight();
    if(!_.isUndefined(this.chartContainer)) {
      handleAfterPlot(this.chartContainer, this.props.viewEntry, this.props.viewMode, this.props.cardImageId);
    }
  }

  getVizOptions = () => {
    return _.merge(_.pick(this.props,
      'axisGranularity',
      'apiParams',
      'benchMarkEntries',
      'dateRange',
      'dateRangeMode',
      'isComboChart',
      'projectionEnabled',
      'projectionOptions',
      'projectionTypes',
      'projectionPercent',
      'onDataLoading',
      'isChartAndTotalLoading',
      'isCurrencyDimensionField',
      'projectionType',
      'renderTimeFrame',
      'currentSelectedTimeFrame',
      'renderType',
      'secondaryMetricEntry',
      'templateId',
      'isEmbed',
      'viewEntry',
      'viewMode',
      'handleBenchMarkChange',
      'onApiDataLoad',
      'compareYearRanges',
      'benchMarkNames',
      'isDimensionHighToLow',
      'cardImageId',
      'isForecastingView',
      'forecastingOption',
      'forecastPrepareDataTime',
      'isUpdateChartData',
      'projectionAdjustedValues',
      'apiData',
      'isBookMark',
      'onUpdateShowLegendTotal',
      'showLegendTotalLine',
      'isOvertimeLegendItemsEmpty'
    ), { isLineChart: true, onAfterSummaryTableDataLoad: this.onAfterSummaryTableDataLoad });
  }

  onPlotlyUnhover = () => {
    this.plotlyTooltip.hidePopups();
  }

  onPlotlyRelayout = (evt) => {
    const { axisGranularity, dateRange } = this.props;
    const range = _.get(evt, 'xaxis.range');
    const plotlySliderRange = getPlotlySliderRange(range);

    const plotlySliderOption = {
      dateRange,
      sliderRange : plotlySliderRange,
      selectedPeriod: axisGranularity
    }

    localStorage.setItem(PLOTLY_SLIDER_RANGE_OPTION, JSON.stringify(plotlySliderOption));
  }

  renderLegend() {
    const {
      apiParams,
      compareYearRanges,
      comparisonType,
      compareToRanges,
      isComboChart,
      projectionEnabled,
      renderTimeFrame,
      renderType,
      secondaryMetricEntry,
      viewEntry,
      templateId,
      dateRange,
      handleSecondaryMetricChange,
      handleBenchMarkChange,
      benchMarkEntries,
      dateRangeMode,
      isCurrencyDimensionField,
      benchMarkNames,
      isDimensionHighToLow,
      isComparisonEnabled,
      onUpdateShowLegendTotal
    } = this.props;

    return (
      <Legend
        apiParams={apiParams}
        comparisonType={comparisonType}
        onLegendClick={this.handleToggleLegendClick}
        legendEntries={this.getDimensionConfigs()}
        isComboChart={isComboChart}
        isSecondaryMetricEnabled={!this.isAreaChart() && !_.isEmpty(secondaryMetricEntry.name)}
        isProjectionEnabled={!this.isAreaChart() && projectionEnabled}
        isComparisonEnabled={isComparisonEnabled}
        isCurrencyDimensionField={isCurrencyDimensionField}
        secondaryMetricEntry={secondaryMetricEntry}
        compareYearRanges={compareYearRanges}
        compareToRanges={compareToRanges}
        renderType={renderType}
        currentSelectedTimeFrame={renderTimeFrame}
        viewEntry={viewEntry}
        templateId={templateId}
        dateRange={dateRange}
        onLegendConfigsChange={this.handleLegendConfigsChange}
        onSecondaryMetricChange={handleSecondaryMetricChange}
        onBenchMarkChange={handleBenchMarkChange}
        benchMarkEntries={benchMarkEntries}
        dateRangeMode={dateRangeMode}
        benchMarkNames={benchMarkNames}
        isDimensionHighToLow={isDimensionHighToLow}
        onUpdateShowLegendTotal={onUpdateShowLegendTotal}
      />
    );
  }

  onSliderEndTest(e) {
    console.log("onSliderEndTest", e);
  }

  renderChart() {
    const { __stubApiData, sliderRange, onNewApiData } = this.props;
    let vizOptions = this.getVizOptions();
    vizOptions = {
      ...vizOptions,
      dimensionConfigs: this.getDimensionConfigs(),
      plotlySliderRange: sliderRange
    };

    const extraPlotlyParams = {
      onHover: this.onPlotlyHover,
      onSliderEnd: this.onSliderEndTest,
      onUnhover: this.onPlotlyUnhover,
      onAfterPlot: this.handleOnAfterPlot,
      onRelayout: this.debouncedPlotlyRelayout
    };

    return (
      <div
        className="line-chart"
        style={{position: 'relative'}}
        ref={(ref) => this.chartContainer = ref}
        onMouseOut={this.onContainerMouseOut}
      >
        <GenericLineChart
          vizOptions={vizOptions}
          extraPlotlyParams={extraPlotlyParams}
          onNewFormattedData={this.onNewFormattedData}
          onNewApiData={onNewApiData}
          onNewPlotlyParams={this.onNewPlotlyParams}
          __stubApiData={__stubApiData}
          plotlyTooltip={this.plotlyTooltip}/>
        <div className="popup-container" ref={(ref) => this.popupContainer = ref}></div>
      </div>
    );
  }

  render() {
    const renderLegendContent = (this.props.viewMode === VIEW_MODE.SMALL) ? null : this.renderLegend();

    return this.props.render(this.renderChart(), renderLegendContent, this.getDimensionConfigs());
  }
}

LineChart.propTypes = lineChartPropTypes;
LineChart.defaultProps = defaultProps;

export default LineChart;
