import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { onClosePropTypes } from 'common/propTypes';

import FrequencySettings from 'common/components/FrequencySettings/FrequencySettings';
import FrequencySettingOption from 'common/components/FrequencySettings/FrequencySettingOption';
import Monitors from './Monitors';
import LoadingSpinner from 'common/components/LoadingSpinner';

import { updateBookmark, deleteBookmark } from 'common/api/bookmarksApi';
import { createRecipients } from 'common/api/recipientsApi';
import {
  EMAIL_STRATEGY_OPTIONS,
  DEFAULT_BOOKMARK_DIMENSION_OPTION
} from 'appConstants';
import { getDefaultMetricCondition } from 'pages/SubscriptionsManager/components/Bookmark/helper';
import { anyOneInputValueIsEmpty } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import {
  alertDeleteMessage,
  alertDeleteErrorMessage
} from  'helpers/toastMessages'
import EmailArrivalText from 'common/components/FrequencySettings/EmailArrivalText';
import { isManageCollections } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';

const TAB_OPTIONS = {schedule: 'schedule', alert: 'alert' };
class BookmarkEmailOptions extends Component {

  constructor(props, context) {
    super(props, context);
    let defaultTab = TAB_OPTIONS.schedule;
    const emailStrategy = _.get(props, 'currentBookmark.email_strategy', EMAIL_STRATEGY_OPTIONS[1].type)
    if(emailStrategy != EMAIL_STRATEGY_OPTIONS[1].type){
      defaultTab = TAB_OPTIONS.alert
    }
    const dimensionField = _.get(props.currentBookmark, 'drilldown.currentDrilldownDimensionField');

    this.state = {
      currentTab: defaultTab,
      emailOptions: _.get(props, 'currentBookmark.email_options'),
      monitorConditions: _.get(props, 'currentBookmark.bookmarkOptions.monitorConditions', []),
      emailStrategy,
      defaultDimensionField: dimensionField,
      dimensionOption: _.get(props,
        'currentBookmark.bookmarkOptions.dimensionOption', dimensionField)
    };
  }

  onSettingValueChange = (value, field) =>{
    const { emailOptions } = this.state;
    let formattedValue = value;
    this.setState({ emailOptions : {
        ...emailOptions,
        [field]: formattedValue
      }
    }, () => {
      this.props.onFreqOptionsChange(this.state.emailOptions);
    });
  }

  onTabChange = (tab) => {
    const { currentBookmark, currentDimensionTotal } = this.props;
    const { currentTab } = this.state;
    const viewEntry = _.get(currentBookmark, 'drilldown.currentDrilldownViewEntry')

    if(currentTab == tab) {
      return;
    }

    let emailStrategy = EMAIL_STRATEGY_OPTIONS[1].type;
    let monitorConditions = [];

    if(tab == TAB_OPTIONS.alert) {
      emailStrategy = EMAIL_STRATEGY_OPTIONS[2].type;
      let defaultCondition = getDefaultMetricCondition(viewEntry);
      defaultCondition['value'] = currentDimensionTotal;
      monitorConditions = [defaultCondition];
    }
    this.setState({ currentTab: tab, emailStrategy, monitorConditions});
  }

  handleDimensionTypeChange = (dimensionOption) => {
    this.setState({ dimensionOption: _.get(dimensionOption, 'type', DEFAULT_BOOKMARK_DIMENSION_OPTION)})
  }

  handleEmailStrategyChange = (emailStrategy, newlyCreatedRecordValue) => {
    this.setState({ emailStrategy});
    this.onSettingValueChange(newlyCreatedRecordValue, 'newlyCreatedRecordValue')
  }

  isCollaborateAlert = () => {
    const { recipientsEmails, currentUser } = this.props;
    const isOnlyCurrentUser =  recipientsEmails.length == 0 ||
      (_.isEqual(_.first(recipientsEmails), currentUser['email']) &&
      recipientsEmails.length == 1);
    return isManageCollections() && !isOnlyCurrentUser;
  };

  handleDeleteBookmark = () => {
    const { currentBookmark, onClose } = this.props;
    const bookmarkId = _.get(currentBookmark, 'id');
    deleteBookmark(bookmarkId)
      .then((response) => {
        this.setState({ isLoading: false });
        if(response.ok) {
          toast.success(alertDeleteMessage);
        } else {
          toast.error(alertDeleteErrorMessage);
        }
        onClose();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toast.error(alertDeleteErrorMessage);
        console.error(err); // eslint-disable-line no-console
      });
  }

  handleSaveBookmark = () => {
    const { currentBookmark, onSaveBookmark, recipientsEmails } = this.props;
    const { monitorConditions, emailOptions, emailStrategy, dimensionOption } = this.state;
    const bookmarkId = _.get(currentBookmark, 'id');

    if(this.isDisableSave()) {
      toast.error('Error while saving changes');
    } else {
      const isCollaborateAlert = this.isCollaborateAlert();
      const params = {
        ...currentBookmark,
        email_options: emailOptions,
        email_strategy: emailStrategy,
        bookmarkOptions: {
          ..._.get(currentBookmark, 'bookmarkOptions'),
          monitorConditions,
          dimensionOption
        },
        is_collaborate_alert: isCollaborateAlert
      }
      this.setState({ isLoading: true });
      if (isCollaborateAlert){
        createRecipients({
          alert_id: bookmarkId,
          alert_type: 'bookmark',
          recipients: recipientsEmails
        });
      }
      updateBookmark(bookmarkId, params)
        .then((response) => response.json())
        .then((response) => {
          this.setState({ isLoading: false });
          onSaveBookmark(response);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toast.error(`Error while editing view. ${err}`);
          console.error(err); // eslint-disable-line no-console
        });
    }
  }

  handleWeekdaysOptionsChange = (isChecked, day) => {
    const { emailOptions } = this.state;
    let selectedDays = _.cloneDeep(_.get(emailOptions, 'emailSendingDays'));
    if(!isChecked && selectedDays.length === 1){
      return;
    }
    selectedDays = isChecked ? [...selectedDays, day] : _.without(selectedDays, day)
    this.setState({ emailOptions : {
        ...emailOptions,
        emailSendingDays: selectedDays
      }
    });
  }

  isDisableSave = () => {
    const { isRecipientsEmailError } = this.props;
    const { monitorConditions, emailOptions, emailStrategy } = this.state;
    const anyOneInputValueEmpty = anyOneInputValueIsEmpty({
      monitorConditions,
      emailStrategy,
      frequencyInterval: _.get(emailOptions, 'frequencyInterval')
    });

    return anyOneInputValueEmpty || isRecipientsEmailError
  }

  handleMonitorConditionChange = (monitorConditions) => {
    this.setState({ monitorConditions });
  }

  renderScheduleOptions() {
    const { currentTab, emailOptions } = this. state;

    if(currentTab != TAB_OPTIONS.schedule){
      return null
    }

    return (
      <>
        <p className="m-0 p-0"> Email me every</p>
        <FrequencySettings {...emailOptions} onSettingValueChange={this.onSettingValueChange} />
        <FrequencySettingOption
          {...emailOptions}
          onSettingValueChange={this.onSettingValueChange}
          handleWeekdaysOptionsChange={this.handleWeekdaysOptionsChange}/>
        <EmailArrivalText {...emailOptions}/>
      </>
    );
  }

  renderSpinner() {
    return (
      <LoadingSpinner isLoading={this.state.isLoading} />
    )
  }

  renderAlertOptions() {
    const { currentTab, monitorConditions, dimensionOption, defaultDimensionField } = this.state;
    const { currentBookmark, currentDimensionTotal } = this.props;

    if(currentTab != TAB_OPTIONS.alert){
      return null
    }
    const {
      bookmarkOptions,
      drilldown,
      visualization
    } = currentBookmark;
    const { currentVizChartType } = (bookmarkOptions || {});
    const currentDrilldownTemplateId = _.get(drilldown, 'currentDrilldownTemplateId');

    return(
      <Monitors
        {...{
          currentVizChartType,
          drilldown,
          monitorConditions,
          visualization,
          currentDimensionTotal,
          currentDrilldownTemplateId,
          dimensionOption
        }}
        defaultDimensionField={defaultDimensionField}
        onHandleDimensionTypeChange={this.handleDimensionTypeChange}
        onMonitorConditionsChange={this.handleMonitorConditionChange}
        onEmailStrategyChange={this.handleEmailStrategyChange} />
    )
  }

  renderTabButtons(){
    const { currentTab } = this.state;

    return(
      <div className="schedule-group mb-2 d-flex justify-content-center align-items-center">
        <div className="btn-group btn-active-check w-100" role="group" aria-label="Tab Options">
          <Button
              className={currentTab == TAB_OPTIONS.schedule ? 'active': 'border-primary'}
              variant="outline-primary"
              onClick={() => this.onTabChange(TAB_OPTIONS.schedule)}>
              Send regular updates
            </Button>
            <Button
              className={currentTab == TAB_OPTIONS.alert ? 'active': 'border-primary'}
              variant="outline-primary"
              onClick={() => this.onTabChange(TAB_OPTIONS.alert)}>
              Only email me when
            </Button>
        </div>
      </div>
    )
  }

  renderBookMarkNotificationSaveButton = () => {
    return (
      <forge-button class="float-right">
        <button
          disabled = {this.isDisableSave()}
          onClick={this.handleSaveBookmark}
          variant="outline-primary">
          Save
        </button>
      </forge-button>
    );
  }

  renderBookMarkNotificationCancelButton = () => {
    return (
      <forge-button>
        <button
          onClick={this.props.onCancel}
          variant="outline-primary">
          Cancel
        </button>
      </forge-button>
    );
  }

  render(){
    return(
      <div className="bookmark-email-options">
        {this.renderTabButtons()}
        {this.renderScheduleOptions()}
        {this.renderAlertOptions()}
        {this.renderSpinner()}
        <div className='mt-auto'>
          {this.renderBookMarkNotificationCancelButton()}
          {this.renderBookMarkNotificationSaveButton()}
        </div>
      </div>
    )
  }
}

BookmarkEmailOptions.propTypes = {
  currentBookmark: PropTypes.object,
  onClose: onClosePropTypes,
  onSaveBookmark: PropTypes.func,
  onCancel: PropTypes.func,
  onFreqOptionsChange: PropTypes.func,
  currentDimensionTotal: PropTypes.number,
  isRecipientsEmailError: PropTypes.bool,
  recipientsEmails: PropTypes.array,
  currentUser: PropTypes.object,
}

BookmarkEmailOptions.defaultProps = {
  onCancel: _.noop,
  isRecipientsEmailError: false,
}
export default BookmarkEmailOptions;
