import React from 'react';
import PropTypes from 'prop-types';

import ShareSection from 'pages/SubscriptionsManager/components/ShareSection';

const Recipients = (props) => {
  const {
    currentUser, recipientsEmails, editMode, onEmailUpdate,
    allowedUserRoles
  } = props;
  const placeholderText = 'Enter one or more email addresses';

  return (
    <div>
      <div className="forge-typography--subtitle1-secondary">Recipients</div>
      <ShareSection
        isAddCurrentUser={true}
        showCopyLink={false}
        showButtons={false}
        currentUser={currentUser}
        emailInputPlaceholder={placeholderText}
        editMode={editMode}
        recipientsEmails={recipientsEmails}
        onEmailUpdate={onEmailUpdate}
        allowedUserRoles={allowedUserRoles}
      />
    </div>
  );
}

Recipients.propTypes = {
  currentUser: PropTypes.object,
  recipientsEmails: PropTypes.array,
  allowedUserRoles: PropTypes.array,
  editMode: PropTypes.bool,
  onEmailUpdate: PropTypes.func,
};

Recipients.defaultProps = {
  editMode: false,
  recipientsEmails: [],
};

export default Recipients;
