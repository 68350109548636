export const REMOVE_CARD_FROM_COLLECTION = 'REMOVE_CARD_FROM_COLLECTION';
export const COPY_CARD_IN_COLLECTION = 'COPY_CARD_IN_COLLECTION';

export const removeCardFromCollection = (collectionId, cardId) => ({
  type: REMOVE_CARD_FROM_COLLECTION,
  collectionId,
  cardId
});

export const copyCardInCollection = (copyToCollectionId, copyCardId, templateEntry, viewEntry) => ({
  type: COPY_CARD_IN_COLLECTION,
  copyToCollectionId,
  copyCardId,
  templateEntry,
  viewEntry
});
