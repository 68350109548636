import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import OvertimeChartOptions from 'pages/drilldown/components/LineChartOptions/OvertimeChartOptions';
import LineChart from 'modules/visualization/LineChart';
import {
  getSecondaryMetricEntry,
  getDefaultSecondaryMetricEntry,
  getDefaultBenchMarkEntries,
  updateBookMarkCardLoadingStatusForImageGenerator
} from 'helpers/visualizationHelper';
import {
  isShowProjectionDefault,
  isComboChartEnable
} from 'common/config/visualizationConfiguration';
import { configuredDefaultDateType } from 'common/config/customerConfiguration';
import classNames from 'classnames';

import {
  OVERTIME_VISUALIZATION_TYPES,
  OVERTIME_TIME_FRAME_OPTIONS,
  DEFAULT_SELECT_DROPDOWN_OPTION,
  AXIS_GRANULARITY_TYPES,
  OVERTIME_DIMENSION_ORDER,
  DATE_COMPARISON_TYPE
} from 'appConstants';
import {
  updateAxisGranularity,
  updateOvertimeSecondaryMetricField,
  updateOvertimeBenchMarkName,
  updateOvertimeChartView,
  updateOvertimeShowProjection,
  updateDimensionConfigsByType,
  updateCurrentForecastOption,
  updateOvertimeDimensionSortOrder,
  updateOvertimeShowLegendTotal,
  updateOvertimeIsEmptyLegend
} from 'actions/overtimeActions';
import VisualizationDescription from 'pages/drilldown/visualizations/VisualizationDescription';
import {
  disableShowProjection,
  getDefaultProjectionMetric,
  hasLinearDataToShowProjection,
  canShowProjectionMetric
} from 'modules/visualization/LineChart/Helpers/projectionHelper';
import { getDimensionsList } from 'modules/visualization/LineChart/legendHelper';
import { getLineChartAttributes } from './helper';
import ProjectionOverlay from './ProjectionOverlay';
import {
  getDefaultAxisGranularity,
  getOvertimeDimensionSortOrder
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { getDefaultDateMode } from 'helpers/dateHelper';
import SummaryTable from 'modules/visualization/SnapshotVisualization/SummaryTable';
import {
  getLineChartSummaryFormatter,
  getSummarySubHeader
} from 'modules/visualization/SnapshotVisualization/SummaryTable/lineChartSummaryFormatter';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { isMobileOrTablet, scrollLegendElementToTarget } from 'helpers/DomPageHelper';
import MenuDropDownSelector from 'common/components/MenuDropDown/MenuDropDownSelector';
class LineChartVisualization extends Component {
  constructor(props) {
    super(props);
    const { currentDrilldownViewEntry, currentChartView, currentForecastOption } = this.props;

    this.state = {
      visualizationData: [],
      isLoading: false,
      currentPage: 0,
      legendEntries: [],
      prophetForecastingData: {},
      dimensionConfig: {},
      comboMetricConfigs: {},
      toggleLegendEntries: {},
      currentForecastOption: getDefaultProjectionMetric(currentDrilldownViewEntry, currentForecastOption),
      currentForecastPercentage: 3,
      isComboChart: isComboChartEnable(currentDrilldownViewEntry, currentChartView),
      chartFormattedData: [],
      summaryFormattedData: [],
      totalRowCount: 0,
      isOpenLegend: true
    };
  }

  // Deprecated. To be removed.
  formatChartData = () => {
    return { data: [] };
  };

  componentDidUpdate(prevProps) {
    const {
      currentDrilldownViewEntry,
      currentDrilldownDimensionField,
      currentChartView,
      currentForecastOption,
      currentDrilldownTemplateId
    } = this.props;
    const isCurrentViewEntryChanged = !_.isEqual(
      prevProps.currentDrilldownViewEntry, currentDrilldownViewEntry);
    const isCurrentTemplateIdChanged = !_.isEqual(
      prevProps.currentDrilldownTemplateId, currentDrilldownTemplateId);
    const iscurrentChartViewChanged = !_.isEqual(
      prevProps.currentChartView, currentChartView);
    const isCurrentDimensionChanged = !_.isEqual(
      prevProps.currentDrilldownDimensionField, currentDrilldownDimensionField);
    // Set default options when chartType changed
    if (isCurrentViewEntryChanged || isCurrentDimensionChanged || iscurrentChartViewChanged) {
      this.setState({
        isComboChart: isComboChartEnable(currentDrilldownViewEntry, currentChartView),
      });
    }
    // projection is reseted.
    if (isCurrentTemplateIdChanged || isCurrentViewEntryChanged) {
      this.setState({
        currentForecastOption: getDefaultProjectionMetric(currentDrilldownViewEntry, currentForecastOption)
      });
    }
  }

  handleSelectView = (currentChartView) => {
    const {
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      currentDrilldownDimensionField,
      dispatchUpdateAxisGranularity,
      dispatchUpdateChartView,
      dispatchUpdateSecondaryMetric,
      dispatchUpdateBenchMarkName,
      dispatchUpdateShowProjection,
      dispatchUpdateOvertimeDimensionSortOrder,
      dispatchUpdateShowLegendTotal,
      dispatchUpdateOvertimeIsEmptyLegend
    } = this.props;
    const defaultSecondaryMetric = getDefaultSecondaryMetricEntry(
      currentDrilldownViewEntry, 'overtime', currentChartView);
    const defaultBenchMarkEntries = getDefaultBenchMarkEntries(currentDrilldownViewEntry, 'overtime',
      currentChartView, currentDrilldownTemplateId, currentDrilldownDimensionField);
    const isShowProjection = isShowProjectionDefault(currentDrilldownViewEntry, currentChartView);
    const defaultAxisGranularity = getDefaultAxisGranularity(currentDrilldownTemplateId);
    const dimensionSortOrder = getOvertimeDimensionSortOrder(currentDrilldownViewEntry, currentChartView);

    dispatchUpdateAxisGranularity(defaultAxisGranularity);
    dispatchUpdateOvertimeDimensionSortOrder(dimensionSortOrder);
    dispatchUpdateSecondaryMetric(_.get(defaultSecondaryMetric, 'field', ''));
    dispatchUpdateBenchMarkName(_.compact(_.map(defaultBenchMarkEntries, 'name', '')));
    dispatchUpdateShowProjection(isShowProjection);
    dispatchUpdateChartView(currentChartView);
    dispatchUpdateShowLegendTotal(true);
    dispatchUpdateOvertimeIsEmptyLegend(false);
  }

  handleSelectProjection = (isChecked) => {
    this.props.dispatchUpdateShowProjection(isChecked);
  }

  toggleClickLegendButton = (isOpenLegend) => {
    this.setState({ isOpenLegend: isOpenLegend });
    scrollLegendElementToTarget(isOpenLegend);
  }

  onAxisGranularityChange = (axisGranularity) => {
    this.props.dispatchUpdateAxisGranularity(axisGranularity);
  }

  disableShowProjectionOption = () => {
    const {
      commonFilters,
      currentDrilldownViewEntry,
      currentSelectedTimeFrame,
      axisGranularity
    } = this.props;
    const { chartFormattedData } = this.state;
    const dateRange = _.get(commonFilters, 'dateRange', {});
    return disableShowProjection(
      dateRange,
      chartFormattedData,
      currentSelectedTimeFrame,
      currentDrilldownViewEntry,
      axisGranularity
    );
  }

  onShowLinearProjection = () => {
    const { chartFormattedData } = this.state;
    return hasLinearDataToShowProjection(chartFormattedData);
  }

  handleSecondaryMetricChange = (option) => {
    const { visualizationData } = this.state;
    const secondaryMetricEntry = _.isEqual(option.name, DEFAULT_SELECT_DROPDOWN_OPTION.name) ? {} : option;
    const { dispatchUpdateSecondaryMetric } = this.props;
    const newVisualizationData = {
      ...visualizationData,
      comparison_metric_entries: {},
      comparison_total: {}
    }
    this.setState({
      visualizationData: newVisualizationData
    });

    dispatchUpdateSecondaryMetric(secondaryMetricEntry['field']);
  }

  handleBenchMarkChange = (currentBenchMarkEntries) => {
    const benchmarkNames = _.map(currentBenchMarkEntries, 'name');
    this.props.dispatchUpdateBenchMarkName(benchmarkNames);
  }

  onApiDataLoad = (rowCount) => {
    const { dimensionRowCount } = this.props;
    if (_.isNumber(rowCount)) {
      this.setState({ totalRowCount: (dimensionRowCount - rowCount) });
    }

  }

  handleForecastOptionChange = (option) => {
    this.setState({ currentForecastOption: option }, () => {
      this.props.dispatchUpdateCurrentForecastOption(option);
    });
  }

  handleForecastPercentChange = (event) => {
    const currentForecastPercentage = event.target.value;
    if (event.target.validity.valid || currentForecastPercentage === '-') {
      this.setState({ currentForecastPercentage: currentForecastPercentage });
    }
  }

  onNewFormattedData = (formattedData) => {
    this.setState({ chartFormattedData: formattedData });
  }

  onDataLoading = (isLoading) => {
    updateBookMarkCardLoadingStatusForImageGenerator(isLoading);
  };

  onAfterSummaryTableDataLoad = (plotlyFormattedData) => {
    if (!_.isEmpty(plotlyFormattedData)) {
      this.setState({
        summaryFormattedData: plotlyFormattedData
      });
    }
  }

  renderOvertimeChartMobileLegend = (legend) => {
    const { isOpenLegend } = this.state;
    if (!isMobileOrTablet() || !isOpenLegend) {
      return null;
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
        className="" leadingIconName="category" toggleClickLegendButton={this.toggleClickLegendButton}>
        {legend}
      </MenuDropDownSelector>
    );
  }

  renderProjectionOverlay() {
    const { currentForecastOption, currentForecastPercentage } = this.state;
    const projectionOverlayAttributes = {
      currentForecastOption,
      currentChartView: _.get(this.props, 'currentChartView'),
      currentForecastPercentage,
      disableShowProjectionOption: this.disableShowProjectionOption(),
      handleSelectProjection: this.handleSelectProjection,
      handleForecastPercentChange: this.handleForecastPercentChange,
      handleForecastOptionChange: this.handleForecastOptionChange,
      showLinearProjection: this.onShowLinearProjection()
    };

    return (<ProjectionOverlay {...projectionOverlayAttributes} />);
  }

  renderOverTimeViewOptions = (legend) => {
    const { isComboChart, chartFormattedData, isOpenLegend } = this.state;
    const {
      axisGranularity,
      commonFilters,
      compareYearRanges,
      currentChartView,
      currentDrilldownTemplateId,
      currentDrilldownViewEntry,
      currentSecondaryMetricField,
      currentSelectedTimeFrame,
      currentVisualizationType,
      isShowProjection,
      currentBenchMarkNames
    } = this.props;
    const dateRange = _.get(commonFilters, 'dateRange');
    let dateRangeMode = _.get(commonFilters, 'dateType', getDefaultDateMode());
    if (_.isEmpty(dateRangeMode)) {
      dateRangeMode = getDefaultDateMode();
    }

    const currentSecondaryMetricEntry = getSecondaryMetricEntry(
      currentDrilldownViewEntry, currentVisualizationType, currentChartView, currentSecondaryMetricField);

    const showProjectionButton = (
      !_.isEqual(OVERTIME_VISUALIZATION_TYPES.AREA.type, currentChartView) &&
      canShowProjectionMetric(currentDrilldownViewEntry, currentChartView) &&
      !this.disableShowProjectionOption()
    );

    return (
      <OvertimeChartOptions
        dateRangeMode={dateRangeMode}
        dateRange={dateRange}
        currentCompareYearRanges={compareYearRanges}
        templateId={currentDrilldownTemplateId}
        chartData={chartFormattedData}
        axisGranularity={axisGranularity}
        currentChartView={currentChartView}
        currentSelectedTimeFrame={currentSelectedTimeFrame}
        isComboChart={isComboChart}
        currentSecondaryMetricEntry={currentSecondaryMetricEntry}
        handleChartViewChange={this.handleSelectView}
        onAxisGranularityChange={this.onAxisGranularityChange}
        legend={legend}
        isShowProjection={isShowProjection}
        showProjectionButton={showProjectionButton}
        onClickProjectionButton={this.handleSelectProjection}
        toggleClickLegendButton={this.toggleClickLegendButton}
        handleSecondaryMetricChange={this.handleSecondaryMetricChange}
        benchMarkNames={currentBenchMarkNames}
        onBenchMarkChange={this.handleBenchMarkChange}
        isOpenLegend={isOpenLegend}
      />
    );
  }

  renderLineChart(renderChartContent) {
    const { dispatchUpdateDimensionConfigsByType, commonFilters,
      isBookMark, dispatchUpdateShowLegendTotal, showLegendTotalLine,
      dispatchUpdateOvertimeIsEmptyLegend, isOvertimeLegendItemsEmpty } = this.props;
    const lineChartAttributes = {
      ...getLineChartAttributes(this.props, this.state, this.disableShowProjectionOption()),
      onNewFormattedData: this.onNewFormattedData,
      handleSecondaryMetricChange: this.handleSecondaryMetricChange,
      handleDimensionConfigsChange: dispatchUpdateDimensionConfigsByType,
      handleBenchMarkChange: this.handleBenchMarkChange,
      render: renderChartContent,
      dateType: _.get(commonFilters, 'dateType', configuredDefaultDateType),
      onApiDataLoad: this.onApiDataLoad,
      onDataLoading: this.onDataLoading,
      onAfterSummaryTableDataLoad: this.onAfterSummaryTableDataLoad,
      onUpdateShowLegendTotal: dispatchUpdateShowLegendTotal,
      isBookMark: isBookMark,
      showLegendTotalLine: showLegendTotalLine,
      onUpdateOvertimeIsEmptyLegend: dispatchUpdateOvertimeIsEmptyLegend,
      isOvertimeLegendItemsEmpty: isOvertimeLegendItemsEmpty
    };

    return (<LineChart {...lineChartAttributes} />);
  }

  renderVisualizationDescription(dimensionConfigs) {
    const {
      compareToDateRange,
      currentSecondaryMetricField,
      currentSelectedTimeFrame,
      currentChartView,
      currentVisualizationType,
      currentDrilldownViewEntry
    } = this.props;
    const { isComboChart, totalRowCount } = this.state;
    const dimensionsList = getDimensionsList(dimensionConfigs, currentDrilldownViewEntry, isComboChart);
    // TODO: Description need to change
    const compareToYear = moment(_.get(compareToDateRange, 'endDate')).format('YYYY')
    const compareTo = (
      !_.isEmpty(compareToDateRange) && !isComboChart &&
      !_.isEqual(OVERTIME_VISUALIZATION_TYPES.AREA.type, currentChartView) &&
      _.isEqual(currentSelectedTimeFrame, OVERTIME_TIME_FRAME_OPTIONS.ROLLING)) ?
      ` and ${compareToYear}` : ``;
    const currentSecondaryMetricEntry = getSecondaryMetricEntry(
      currentDrilldownViewEntry, currentVisualizationType, currentChartView, currentSecondaryMetricField);
    const remainingRowCount = totalRowCount;
    return (
      <VisualizationDescription
        isComboChart={isComboChart}
        compareTo={compareTo}
        currentSecondaryMetricEntry={currentSecondaryMetricEntry}
        comboChartMetricList={dimensionsList}
        totalRowCount={remainingRowCount} />
    )
  }

  renderSummaryTable() {
    const { isShowProjection, currentDrilldownTemplateId, isEmbed } = this.props;
    const summaryTableOptions = getLineChartAttributes(this.props, this.state,
      this.disableShowProjectionOption());
    const { summaryFormattedData, currentForecastOption, currentForecastPercentage } = this.state;
    let subHeader = '';
    const summaryTableData = getLineChartSummaryFormatter(summaryFormattedData, summaryTableOptions);
    if (isShowProjection) {
      subHeader = getSummarySubHeader(currentForecastOption, currentForecastPercentage);
    }

    if (_.isEmpty(summaryTableData) || isEmbed) {
      return null;
    }

    return (
      <SummaryTable
        subHeader={subHeader}
        tableHeaders={summaryTableData.tableHeaders}
        tableData={summaryTableData.tableData}
        summaryTableOptions={summaryTableOptions}
        currentDrilldownTemplateId={currentDrilldownTemplateId} />
    );
  }

  render() {
    const { isOpenLegend } = this.state;
    const vizWrapperClass = classNames('visualization-wrapper', {
      'visualization-width': isOpenLegend && !isMobileOrTablet()
    });

    return this.renderLineChart((chart, legend, dimensionConfigs) => (
      <div className="chartContainer viz-wrapper legend-container">
        {this.renderOverTimeViewOptions(legend)}
        <div className={vizWrapperClass}>
          <div className="visualization-lside">
            <div>
              {this.renderProjectionOverlay()}
              {chart}
              {this.renderOvertimeChartMobileLegend(legend)}
              <div className="visualization-footer">
                {this.renderVisualizationDescription(dimensionConfigs)}
              </div>
            </div>
          </div>
        </div>
        {this.renderSummaryTable()}
      </div>
    ));
  }
}

LineChartVisualization.propTypes = {
  drilldown: commonPropTypes.drilldownPropTypes,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentDrilldownDimensionField: PropTypes.string,
  axisGranularity: PropTypes.string,
  dimensionSortOrder: PropTypes.string,
  currentForecastOption: PropTypes.string,
  currentSelectedTimeFrame: PropTypes.string,
  compareYearRanges: PropTypes.array,
  currentSecondaryMetricField: PropTypes.string,
  currentBenchMarkNames: PropTypes.array,
  isShowProjection: PropTypes.bool,
  toggleLegendEntries: PropTypes.object,
  sliderRange: PropTypes.array,
  currentVisualizationType: PropTypes.string,
  dimensionConfigsByRenderType: PropTypes.object,
  isComparisonEnabled: PropTypes.bool,
  mapOptions: PropTypes.bool,
  currentChartView: PropTypes.string,
  compareToDateRange: PropTypes.object,
  dispatchUpdateAxisGranularity: PropTypes.func,
  dispatchUpdateOvertimeDimensionSortOrder: PropTypes.func,
  dispatchUpdateChartView: PropTypes.func,
  dispatchUpdateSecondaryMetric: PropTypes.func,
  dispatchUpdateBenchMarkName: PropTypes.func,
  dispatchUpdateShowProjection: PropTypes.func,
  dimensionRowCount: commonPropTypes.stringOrNumberProps,
  dispatchUpdateCurrentForecastOption: PropTypes.func,
  dispatchUpdateDimensionConfigsByType: PropTypes.func,
  dispatchUpdateShowLegendTotal: PropTypes.func,
  isEmbed: PropTypes.bool,
  isBookMark: PropTypes.bool,
  showLegendTotalLine: PropTypes.bool,
  dispatchUpdateOvertimeIsEmptyLegend: PropTypes.func,
  isOvertimeLegendItemsEmpty: PropTypes.bool
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateSecondaryMetric: (field) => {
      dispatch(updateOvertimeSecondaryMetricField(field));
    },
    dispatchUpdateBenchMarkName: (entry) => {
      dispatch(updateOvertimeBenchMarkName(entry));
    },
    dispatchUpdateChartView: (selectedView) => {
      dispatch(updateOvertimeChartView(selectedView));
    },
    dispatchUpdateShowProjection: (enable) => {
      dispatch(updateOvertimeShowProjection(enable));
    },
    dispatchUpdateAxisGranularity: (axisGranularity) => {
      dispatch(updateAxisGranularity(axisGranularity));
    },
    dispatchUpdateOvertimeDimensionSortOrder: (dimensionSortOrder) => {
      dispatch(updateOvertimeDimensionSortOrder(dimensionSortOrder));
    },
    dispatchUpdateDimensionConfigsByType: (configs) => {
      dispatch(updateDimensionConfigsByType(configs));
    },
    dispatchUpdateCurrentForecastOption: (forecastOption) => {
      dispatch(updateCurrentForecastOption(forecastOption));
    },
    dispatchUpdateShowLegendTotal: (enable) => {
      dispatch(updateOvertimeShowLegendTotal(enable));
    },
    dispatchUpdateOvertimeIsEmptyLegend: (isEmpty) => {
      dispatch(updateOvertimeIsEmptyLegend(isEmpty));
    }
  }
}

function mapStateToProps(state) {
  const isComparisonEnabled = _.get(state, 'commonFilters.comparisonModeOn', false);
  const comparisonType = _.get(state, 'commonFilters.comparisonType', DATE_COMPARISON_TYPE.SAME_PERIOD);

  const isBookMark = Number(_.get(_.get(state, 'bookmark', {}), 'currentBookmarkId', "")) > 0;

  const currentChartView = _.get(
    state, 'visualization.overtime.currentChartView', OVERTIME_VISUALIZATION_TYPES.TIMELINE.type);
  const currentDrilldownViewEntry = _.get(state, 'drilldown.currentDrilldownViewEntry', {});
  let currentSelectedTimeFrame = '';
  const isComboChart = isComboChartEnable(currentDrilldownViewEntry, currentChartView);
  if ((isComparisonEnabled && comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD) && !isComboChart) {
    currentSelectedTimeFrame = OVERTIME_TIME_FRAME_OPTIONS.YEAR_ON_YEAR;
  } else {
    currentSelectedTimeFrame = OVERTIME_TIME_FRAME_OPTIONS.ROLLING;
  }
  return {
    comparisonType,
    currentForecastOption: _.get(state, 'visualization.overtime.currentForecastOption', ''),
    axisGranularity: _.get(state, 'visualization.overtime.axisGranularity', AXIS_GRANULARITY_TYPES[0].value),
    dimensionSortOrder: _.get(
      state, 'visualization.overtime.dimensionSortOrder', OVERTIME_DIMENSION_ORDER.HIGH_TO_LOW.type),
    commonFilters: _.get(state, 'commonFilters', {}),
    currentDrilldownViewEntry,
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    currentChartView,
    currentSelectedTimeFrame: currentSelectedTimeFrame,
    isComparisonEnabled: _.get(state, 'commonFilters.comparisonModeOn', false),
    compareYearRanges: _.get(state, 'commonFilters.comparisonDateRanges', []),
    currentSecondaryMetricField: _.get(state, 'visualization.overtime.currentSecondaryMetricField', {}),
    currentBenchMarkNames: _.get(state, 'visualization.overtime.currentBenchMarkMetricNames', ''),
    isShowProjection: JSON.parse(_.get(state, 'visualization.overtime.isShowProjection', false)),
    toggleLegendEntries: _.get(state, 'visualization.overtime.toggleLegendEntries', {}),
    sliderRange: _.get(state, 'visualization.overtime.sliderRange', []),
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    dimensionConfigsByRenderType: _.get(state, 'visualization.overtime.dimensionConfigsByRenderType', {}),
    mapOptions: _.get(state, 'visualization.mapOptions', {}),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false),
    drilldown: _.get(state, 'drilldown', {}),
    isBookMark: isBookMark,
    showLegendTotalLine: JSON.parse(_.get(state, 'visualization.overtime.showLegendTotalLine', true)),
    isOvertimeLegendItemsEmpty: JSON.parse(_.get(state, 'visualization.overtime.isLegendItemsEmpty', true))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LineChartVisualization);
