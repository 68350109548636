import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import BenchmarkOptions from 'pages/drilldown/components/BenchmarkOptions';
import GlobalEvent from 'common/components/GlobalEvents';
import ToggleButton from 'pages/Radar/RadarSideBar/ToggleButton';
import UndoButton from 'pages/drilldown/visualizations/VisualizationOptions/UndoButton';

import {
  EVENTS,
  VISUALIZATION_TYPES
} from 'appConstants';
import { } from 'appConstants';

import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import MenuDropDownPopupSelector from 'common/components/MenuDropDown/MenuDropDownPopupSelector';
import DistributionLegends from './visualizationRenders/DistributionLegends';

class DistributionOptions extends Component {
  constructor(props, context) {
    super(props, context);
  }

  handleToggleChartOptions = (e) => {
    if (isEnterButtonPressed(e)) {
      this.toggleChartOptions();
    }
  }

  toggleCumulative = (isChecked) => {
    const { onChangeCumulative, isDiscrete } = this.props;
    const isCumulative = isChecked;

    GlobalEvent.emit(EVENTS.DISTRIBUTION_CHART_TYPE_CHANGE, { isCumulative: isChecked, isDiscrete });

    onChangeCumulative(isCumulative);
  }

  handleKeyDown = (e, isChecked, triggerFunction) => {
    if (isEnterButtonPressed(e)) {
      triggerFunction(!isChecked);
    }
  }

  toggleDiscrete = (isChecked) => {
    const { onChangeDiscrete, isCumulativeDistribution } = this.props;
    const isDiscrete = isChecked;

    GlobalEvent.emit('DISTRIBUTION_CHART_TYPE_CHANGE',
      { isCumulative: isCumulativeDistribution, isDiscrete });
    onChangeDiscrete(isDiscrete);
  }

  renderBenchmarkEntries = () => {
    const { onBenchmarkOptionChange,
      isCumulativeDistribution,
      isDiscrete,
      benchmarkMetricNames } = this.props;
    const currentChartView = VISUALIZATION_TYPES.DISTRIBUTION.type

    return (
      <BenchmarkOptions
        onBenchmarkOptionChange={onBenchmarkOptionChange}
        isDiscrete={isDiscrete}
        isCumulative={isCumulativeDistribution}
        benchMarkNames={benchmarkMetricNames}
        renderType={currentChartView}
      />
    );
  }


  renderBenchmarkContainer() {
    return (
      <div className="legend-wrapper snap-shot-legend-container">
        {this.renderBenchmarkEntries()}
      </div>
    );
  }

  renderDistributionOptions() {
    const { isCumulativeDistribution, isDiscrete } = this.props;
    return (
      <>
        <div className="toggle-button filter-wrapper">
          <div className="control-labels mb-0">Discrete</div>
          <ToggleButton id="discreteCheck" isChecked={isDiscrete} onClickCheckBox={this.toggleDiscrete} />
        </div>

        <div className="toggle-button filter-wrapper">
          <div className="control-labels mb-0">Cumulative</div>
          <ToggleButton id="cumulativeCheck"
            isChecked={isCumulativeDistribution}
            onClickCheckBox={this.toggleCumulative} />
        </div>
      </>
    );
  }

  renderDistributionOptionDropDown = () => {
    return (
      <div className="vertical-filter-wrapper">
        {this.renderBenchmarkContainer()}
        <div className="filter-bar-wrapper">
          <div className="filter-bar-container p-0">
            <div className="distribution-options">
              {this.renderDistributionOptions()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDistributionLegends = () => {
    const {
      legends,
      isDiscrete,
      isCumulativeDistribution,
      dimensionName,
      isCurrencyDimensionField,
      currentDrilldownTemplateId,
      toggleClickLegendButton,
      isOpenLegend
    } = this.props;

    const legendOptions =  {
      legends,
      isDiscrete,
      isCumulativeDistribution,
      dimensionName,
      isCurrencyDimensionField,
      currentDrilldownTemplateId,
      toggleClickLegendButton,
      fromMobileView: false,
      isOpenLegend
    }

    return (
      <DistributionLegends legendOptions = {legendOptions} />
    );
  }

  render() {

    return (
      <div className="snapshot-options">
        <div className="rside-action">
          <UndoButton></UndoButton>
          <MenuDropDownPopupSelector toggleButtonLabel="Options"
            trailingIconName="keyboard_arrow_down" className="custom-dropdown-inner">
            {this.renderDistributionOptionDropDown()}
          </MenuDropDownPopupSelector>
          {this.renderDistributionLegends()}
        </div>
      </div>
    )
  }
}

DistributionOptions.propTypes = {
  isCumulativeDistribution: PropTypes.bool,
  isDiscrete: PropTypes.bool,
  onChangeCumulative: PropTypes.func,
  onChangeView: PropTypes.func,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  isCurrencyDimensionField: PropTypes.bool,
  dimensionName: PropTypes.string,
  onChangeDiscrete: PropTypes.func,
  onBenchmarkOptionChange: PropTypes.func,
  benchmarkMetricNames: PropTypes.array,
  legends: PropTypes.array,
  toggleClickLegendButton: PropTypes.func,
  isOpenLegend: PropTypes.bool
};

const mapDispatchToProps = {
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributionOptions);
