import _ from 'lodash';
import React, { Component } from 'react';

import GenericVisualization from '../GenericVisualization';
import dataStore from './dataStore';
import dataFormatter from './dataFormatter';
import plotlyParamsGenerator from './plotlyParamsGenerator';
import { ON_DISTRIBUTION_CHART_CLICK } from 'appConstants';
import {
  OTHER_BUCKET_ID,
  PLOTLY_HOVER_DEBOUNCE_WAIT_TIME,
  VIEW_MODE
} from 'modules/visualization/constants';
import PlotlyTooltip from 'modules/PlotlyTooltip';
import { getDistributionChartPopupConfigs } from 'common/contentFormatter/distributionChartContentFormatter';
import GlobalEvent from 'common/components/GlobalEvents';
import { setIframeHeight } from 'modules/visualization/SnapshotVisualization/snapshotAfterPlotHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import SummaryTableDataContext from 'context/SummaryTableDataContext';
import { updateCardLoadingStatusForImageGenerator } from 'helpers/visualizationHelper';

const GenericDistributionChart = GenericVisualization(dataStore, dataFormatter, plotlyParamsGenerator);

const distributionChartPropTypes = {
  afterRender: PropTypes.func,
  viewMode: commonPropTypes.viewModePropTypes,
  apiParams: PropTypes.object,
  onBarClick: PropTypes.func,
  onAfterPlot: PropTypes.func,
  viewEntry: commonPropTypes.viewEntryPropTypes, //Temporary
  dimensionColumn: PropTypes.string, //Temporary
  distributionOption: PropTypes.object,
  isBarClickable: PropTypes.func,
  isCumulativeDistribution: PropTypes.bool,
  isEmbed: PropTypes.bool,
  isDiscrete: PropTypes.bool,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  benchmarkMetricNames: PropTypes.array,
  templateId: commonPropTypes.templateIdPropTypes,
  isCurrencyDimensionField: PropTypes.bool,
  // onClick:
  __stubApiData: PropTypes.object,
  isChartAndTotalLoading: PropTypes.bool,
  onDataLoading: PropTypes.func,
  cardImageId: PropTypes.string
};
const defaultProps = {
  afterRender: _.noop,
  viewMode: VIEW_MODE.LARGE,
  apiParams: {},
  distributionOption: {},
  onBarClick: _.noop,
  onAfterPlot: _.noop,
  quickFilters: [],
  benchmarkMetricNames: [],
  templateId: '',
  __stubApiData: null,
  isChartAndTotalLoading: false,
  isEmbed: false,
  isCurrencyDimensionField: false,
  onDataLoading: _.noop
};

class DistributionChart extends Component {
  constructor(props, context) {
    super(props, context);
    this.containerRef = React.createRef();
  }
  static contextType = SummaryTableDataContext;

  componentDidMount() {
    if (this.popupContainer) {
      this.plotlyTooltip = new PlotlyTooltip(this.popupContainer);
    }
    GlobalEvent.on(ON_DISTRIBUTION_CHART_CLICK, this.handleDistributionKeyDownEvent);
  }

  componentWillUnmount() {
    GlobalEvent.off(ON_DISTRIBUTION_CHART_CLICK, this.handleDistributionKeyDownEvent);
  }

  onNewPlotlyParams = (formattedData) => {
    const { isEmbed, viewMode } = this.props;
    // Only we set the formattedData in Embed mode.
    // We need formattedData for display the flyout details in summary page.
    if(isEmbed && !_.isUndefined(this.context) && viewMode === VIEW_MODE.SMALL) {
      if( !_.isUndefined(this.context.onUpdateSummaryData)){
        this.context.onUpdateSummaryData(formattedData, this.getVizOptions());
      }
    }
  }

  getVizOptions = () => {
    return _.merge(_.pick(this.props,
      'apiParams',
      'dimensionColumn',
      'viewEntry',
      'isEmbed',
      'viewMode',
      'distributionOption',
      'isChartAndTotalLoading',
      'afterRender',
      'onDataLoading',
      'templateId',
      'cardImageId'),
    {'isDistributionChart': true});
  }

  handleDistributionKeyDownEvent = (event, bucketEntry) => {
    if(!_.isUndefined(bucketEntry) && this.isBarClickable(event, {id: bucketEntry.bucket_id})) {
      this.props.onBarClick(bucketEntry);
    }
  }

  onPlotlyClick = (event) => {
    const { onBarClick } = this.props;

    if (!this.isBarClickable(event)) {
      return;
    }

    const formattedDataItem = _.get(event, 'points[0].meta');
    if (_.get(formattedDataItem, 'id') === OTHER_BUCKET_ID) {
      formattedDataItem['bucketEntry'] = formattedDataItem;
    }
    onBarClick(formattedDataItem.bucketEntry);
  }

  handleAfterPlot = () => {
    const { viewMode } = this.props
    setIframeHeight();
    if(!_.isUndefined(this.distributionContainer)) {

      const chartRowItems  = this.distributionContainer.querySelectorAll('.barlayer .points g');
      _.each(chartRowItems, (item) => {
        item.tabIndex = 0;
      });

      if(viewMode === VIEW_MODE.SMALL){
        updateCardLoadingStatusForImageGenerator(this.props.cardImageId, false);
      }
    }
  }

  onPlotlyHover = _.throttle((event) => {
    const {
      viewMode, templateId, viewEntry, benchmarkMetricNames, quickFilters,
      distributionOption, isCurrencyDimensionField
    } = this.props;
    this.setChartMouseCursor(this.isBarClickable(event) ? 'pointer' : 'inherit');
    const popupConfigs = getDistributionChartPopupConfigs({
      chartContainer: this.distributionContainer,
      data: event,
      benchmarkMetricNames,
      quickFilters,
      viewMode,
      viewEntry,
      isDistributionChart: true,
      isCurrencyDimensionField,
      templateId,
      distributionOption
    });
    this.plotlyTooltip.showPopups(popupConfigs);

  }, PLOTLY_HOVER_DEBOUNCE_WAIT_TIME);

  isBarClickable = (event, bucketEntry = {}) => {
    const formattedDataItem = _.get(event, 'points[0].meta', bucketEntry);
    return !_.isUndefined(formattedDataItem);
  }

  onPlotlyUnhover = () => {
    this.setChartMouseCursor('inherit');
    this.plotlyTooltip.hidePopups();
  };

  onContainerMouseOut = () => {
    this.setChartMouseCursor('inherit');
    this.plotlyTooltip.hidePopups();
  }

  setChartMouseCursor(cursor) {
    this.distributionContainer.querySelector('g.draglayer').style.cursor = cursor;
  }

  render() {
    const { __stubApiData } = this.props;
    const vizOptions = this.getVizOptions();

    const extraPlotlyParams = {
      onClick: this.onPlotlyClick,
      onHover: this.onPlotlyHover,
      onUnhover: this.onPlotlyUnhover,
      onAfterPlot: this.handleAfterPlot
    };

    // Render pagination.
    // Handle mouse click and drilldown.
    // Handle tooltip and stuff.
    // Create the vizOptions from props/state.
    return (
      <div className="h-100"
        style={{position: 'relative'}}
        ref={(ref) => this.distributionContainer = ref}
        onMouseOut={this.onContainerMouseOut}
        >
        <GenericDistributionChart
          vizOptions={vizOptions}
          extraPlotlyParams={extraPlotlyParams}
          onNewPlotlyParams={this.onNewPlotlyParams}
          __stubApiData={__stubApiData}
          plotlyTooltip={this.plotlyTooltip}/>
        <div className="popup-container" ref={(ref) => this.popupContainer = ref}></div>
      </div>
    );
  }
}

DistributionChart.propTypes = distributionChartPropTypes;
DistributionChart.defaultProps = defaultProps;

export default DistributionChart;
