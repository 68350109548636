import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import pluralize from 'pluralize';

import { INSIGHT_CALCULATION_OPTIONS, INSIGHT_COMPARISON_MODE_OPTIONS } from 'appConstants';
import { getVarianceExclusionAnalysisData } from 'common/api/drilldown';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import { getCurrentViewEntry } from 'common/config/templateConfiguration';
import { ErrorBoundaryWrapper } from 'pages/errorboundary/ErrorBoundaryWrapper';
import { getDimensionName } from 'helpers/displayNameHelper';
import AnalyticsLink from './AnalyticsLink';
import {
  formatVarianceValue,
  getEntitiesValue,
  getInsightsOption
} from './InsightsHelper';
import {
  getInsightTableHeaders,
  getFormattedDimensionEntry,
  getInsightTableDimensionConfigEntry
} from 'common/config/customerConfiguration';
import TableDataRender from './TableDataRender';

class InsightsTabsContent extends PureComponent{
  constructor(props) {
    super(props);

    this.state = {
      dimensionEntries: [],
      exclusionResults: [],
      exclusionLoading: true,
      calculationType: ''
    }
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.fetchExclusionResults();
  }
  
  componentDidUpdate(prevProps) {
     if (
      _.get(prevProps, ['selectedInsight', 'isLoading']) !== 
      _.get(this.props, ['selectedInsight', 'isLoading'])
    ) {
      this.fetchExclusionResults();
    }
  }

  fetchExclusionResults() {
    const { selectedInsight } = this.props;
    const { apiParams, apiData, template_id: templateId } = selectedInsight;
    const entitiesList = _.map(apiData['results'], 'entities');

    if (_.isEmpty(entitiesList)) {
      return;
    }
    const dimensionEntries = _.chain(entitiesList)
      .flatMap((entities) => _.keys(entities))
      .uniq()
      .map((dimensionField) => getSelectedDimensionEntry(templateId, dimensionField))
      .value();

    this.setState({ exclusionLoading: true, dimensionEntries });
    const apiUrl = getVarianceExclusionAnalysisData({ 
      ...apiParams, 
      entities_list: JSON.stringify(entitiesList) 
    });
    
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    fetchApiData(apiUrl, this.abortFetchController)
      .then((exclusionResults) => this.setState({ exclusionResults }))
      .finally(() => this.setState({ exclusionLoading: false }));
  }

  renderResults() {
    const { selectedInsight } = this.props;
    if(_.isEmpty(selectedInsight)){
      return <tr>No Insights Selected</tr>;
    } else {
      return _.map(_.get(selectedInsight, 'apiData.results'), this.renderResult);
    }
  }

  getViewEntry = () => {
    const {selectedInsight} = this.props;
    const {template_id, view_id} = selectedInsight;
    return getCurrentViewEntry(template_id, Number(view_id));
  }

  renderResult = (result) => {
    const { selectedInsight } = this.props;
    const { id: resultId } = result; 

    const previous_count = Number(_.get(result, 'item.v2_group_count'));
    const isNew = previous_count <= 0;
    const entities = getEntitiesValue(result);
    
    return (
      <tr key={resultId}>
        <td>
          <div className="d-flex gap-10">
            <div className="entities">
              {entities}
              {isNew && <span className="badge new-entity-badge">NEW</span>}
            </div>
          </div>
        </td>
        {this.renderTableValues(result, resultId)}
        <td><AnalyticsLink insight={selectedInsight} result={result} /></td>
      </tr>
    );
  }

  renderTableValues(result, resultId){
    const { selectedInsight } = this.props;
    return (
      _.map(getInsightTableHeaders(), (headerEntry) => {
        if(_.get(headerEntry, 'id') === 'avg_of_remainig_columns') {
          return this.renderExclusionValues(resultId);
        }

        const {id, show} = headerEntry;
        if(show == 'true'){
          return (
            <td key={id}>
              <TableDataRender
                ViewEntry={this.getViewEntry()}
                result={result['item']}
                tableOption={headerEntry}
                scoreWeightMathValueType={_.get(selectedInsight, 'sampleSizeCalculation', 'count')}
              ></TableDataRender> 
            </td>
          )
        }
      })
    );
  }

  getSubTitle = () => {
    const {selectedInsight} = this.props;
    const { templateId, dimensionFields, isMultiDimension } = getInsightsOption(selectedInsight);
    const name = _.get(selectedInsight, 'name', '');

    if(!isMultiDimension){
      const dimension = getDimensionName(templateId, dimensionFields[0]);
      return `Top <b>${pluralize(dimension)}</b> for <b>${name}</b>`;
    } else {
      const dimension1 = getDimensionName(templateId, dimensionFields[0]);
      const dimension2 = getDimensionName(templateId, dimensionFields[1]);
      return `Top <b>${dimension1}</b> and <b>${dimension2}</b> segments for <b>${name}</b>`;
    }
  }

  renderDescriptionText = () => {
    const { selectedInsight } = this.props;
    const {
      templateId,
      dimensionFields,
      metricName,
      commonFilters,
      secondaryDateRange,
      isMultiDimension
    } = getInsightsOption(selectedInsight);

    if(_.isEmpty(commonFilters)){
      return;
    }

    const { dateRange } = commonFilters; 
    const fromDateRange = `${moment(dateRange.startDate).format('MMM DD, YYYY')} -
      ${moment(dateRange.endDate).format('MMM DD, YYYY')}`;

      const toDateRange = `${moment(secondaryDateRange.startDate).format('MMM DD, YYYY')} -
      ${moment(secondaryDateRange.endDate).format('MMM DD, YYYY')}`;

    const calculationType = _.find(INSIGHT_CALCULATION_OPTIONS, (calcType) => {
      return calcType.value === _.get(selectedInsight, 'calculation')
    });
    const calculationName = _.get(calculationType, 'name');

  
    if(!isMultiDimension){
      const dimension = getDimensionName(templateId, dimensionFields[0]);
      const description = `Showing the top ${pluralize(dimension)} for ${metricName} by ${calculationName}
        change comparing ${fromDateRange} to ${toDateRange}. ${this.renderMinimumSizeText()}`;

      return description;

    } else {
      const firstDimensionName = getDimensionName(templateId, dimensionFields[0]);
      const secondDimensionName = getDimensionName(templateId, dimensionFields[1]);
      const description = `Showing the top ${firstDimensionName} and ${secondDimensionName}
        combinations for ${metricName} by ${calculationName} change comparing
        ${fromDateRange} to ${toDateRange}. ${this.renderMinimumSizeText()}`;

        return description;
    }
  }

  renderMinimumSizeText(){
    const { selectedInsight } = this.props;
    const {
      templateId,
      dimensionFields,
      minimumSegmentSizeCount,
      minimumSegmentSizeMetricValue,
      isMultiDimension
    } = getInsightsOption(selectedInsight);

    let sampleSizeText = "";
    if(!_.isEmpty(minimumSegmentSizeCount) && !_.isEmpty(minimumSegmentSizeMetricValue)){
      sampleSizeText = `smaller than ${minimumSegmentSizeMetricValue} or with fewer than 
        ${minimumSegmentSizeCount} records`;
    } else if(!_.isEmpty(minimumSegmentSizeCount)) {
      sampleSizeText = `with fewer than ${minimumSegmentSizeCount} records`;
    } else if(!_.isEmpty(minimumSegmentSizeMetricValue)) {
      sampleSizeText = `smaller than ${minimumSegmentSizeMetricValue}`;
    }

    if(_.isEmpty(sampleSizeText)){
      return "";
    }

    if(!isMultiDimension){
      const dimension = getDimensionName(templateId, dimensionFields[0]);
      return `${pluralize(dimension)} ${sampleSizeText} have been excluded.`;
    }else{
      const firstDimensionName = getDimensionName(templateId, dimensionFields[0]);
      const secondDimensionName = getDimensionName(templateId, dimensionFields[1]);
      return `${firstDimensionName} and ${secondDimensionName} combinations ${sampleSizeText} 
        have been excluded.`;
    }
  }

  renderTitle(){    
    return(
      <>
        <h2 className="insights-title">
          <span dangerouslySetInnerHTML={{__html: this.getSubTitle()}}></span>
          {this.renderComparisonTitle()}
        </h2>
      </>
    )
  }

  renderComparisonTitle(){
    const {selectedInsight} = this.props;
    const {comparisonRange, dateRange} = selectedInsight;
    const comparisonRangeName = _.get(_.find(INSIGHT_COMPARISON_MODE_OPTIONS, (option) => {
      return option.value === comparisonRange
    }), 'name');

    return (
      <small>Comparing this {dateRange} to the {comparisonRangeName}.</small>
    );
  }

  renderExclusionHeaders() {
    const { dimensionEntries } = this.state;
    if(_.get(getInsightTableDimensionConfigEntry(), 'show') === 'false') {
      return;
    }

    return (
      _.map(dimensionEntries, (dimensionEntry) => {
        return (
          <th scope="col" key={_.get(dimensionEntry, 'field')}>
            {getFormattedDimensionEntry(_.get(dimensionEntry, 'name'))}
            {/*<i className="icons-info-circle text-primary" />*/}
          </th>
        )
      })
    );
  }

  renderTableHeaders(){
    return (
      _.map(getInsightTableHeaders(), (headerEntry) => {
        if(_.get(headerEntry, 'id') === 'avg_of_remainig_columns') {
          return this.renderExclusionHeaders();
        }

        const {show, displayName, name } = headerEntry;
        if(show == 'true'){
          return (
            <th key={name} scope="col" className="text-left">{displayName}</th>
          )
        }
      })
    );
  }

  renderExclusionValues(resultId) {
    const { selectedInsight } = this.props;
    const { dimensionEntries, exclusionResults, exclusionLoading } = this.state;
    const calculationType = _.get(selectedInsight, 'calculation');

    if(_.get(getInsightTableDimensionConfigEntry(), 'show') === 'false') {
      return;
    }

    if (exclusionLoading) {
      return _.map(dimensionEntries, (dimensionEntry) => {
        return (
          <td key={dimensionEntry.field}>
            <Spinner variant="primary" className="loading-spinner" size="sm" animation="border" />
          </td>
        );
      })
    }

    const exclusionResult = _.find(exclusionResults, { id: resultId }) || {};
    return (
      _.map(dimensionEntries, (dimensionEntry) => {
        const value = _.get(exclusionResult, ['exclusion_values', dimensionEntry.field]);
        const arrowClassNames = classNames({
          'icons-arrow-up2': value > 0,
          'icons-arrow-down2': value < 0
        });
        return (
          <td key={dimensionEntry.field}>
            <i className={arrowClassNames} />
            {formatVarianceValue(value, calculationType, this.getViewEntry())}
          </td>
        )
      })
    );
  }

  render(){
    return (
      <div className="insights-content">
        {this.renderTitle()}
        <div className="notes">
          <i className="icons icons-lightbulb"></i>
          <div className="info">
            Total number of sales did not changes a lot compared to last year, but high increases In 
            Ballard&apos;s condo sales <b>(68%)</b>, Queen Anne&apos;s Colonial sales<b>(43%)</b> and 
            Freemont&apos;s townhome sales (22%) might need some attentions.
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col" className="text-left">Segments</th>
                {this.renderTableHeaders()}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.renderResults()}
            </tbody>
          </table>
        </div>
        <div className="insight-description">
          {this.renderDescriptionText()}
        </div>
      </div>
    );
  }
}

InsightsTabsContent.propTypes = {
  selectedInsight: PropTypes.object
}

function errorContent() {
  return (
    <div className="mr-2">
      something went wrong.
    </div>
    );
}

export default ErrorBoundaryWrapper(InsightsTabsContent, errorContent());
