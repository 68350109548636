import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon } from '@tylertech/forge-react';

function MetricSearch(props) {
  const { selectedLibraryType, onHandleSearchMetric } = props;
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSearchText('');
  }, [selectedLibraryType])
  
  const handleOnChange = (e) => {
    setSearchText(e.target.value);
    onHandleSearchMetric(e.target.value);
  }

  return (
    <div className='search-metric mx-3'>
      <ForgeIcon name="search" className="search-icon"></ForgeIcon>
      <input
        type='text'
        className='search-input'
        placeholder='Search'
        value={searchText}
        onChange={handleOnChange} />
    </div>
  )
}

MetricSearch.propTypes = {
  selectedLibraryType: PropTypes.string,
  onHandleSearchMetric: PropTypes.func
}

export default MetricSearch
