import moment from 'moment';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import {
	getPeriodType, getTailingDropStartDate
} from 'modules/visualization/LineChart/vizOptionsHelper';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import { isValidDateSummaryData } from 'pages/Forecasting/ForecastHelper';
import { getQuarterMonths } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { FORECASTING_TYPE } from 'appConstants';

export const getForecastChartSummaryFormatter = (summaryData,
	summaryTableOptions) => {

	let formattedData = _.get(summaryData, 'formatData');
	if (_.size(formattedData) === 1 && _.isEmpty(formattedData[0]['meta'])) {
		return
	}

	const tableHeaders = getForecastTableHeaders(formattedData, summaryTableOptions);
	const tableData = getForecastSummaryTableRows(formattedData, summaryTableOptions);
	return {
		tableHeaders,
		tableData
	}
}

const getForecastSummaryTableRows = (formattedData, summaryTableOptions) => {
	const { viewEntry, isForecastingChart } = summaryTableOptions;
	const filterFormattedData = _.filter(formattedData, (datum) => {
		if (!_.isEmpty(datum['x'])) {
			return datum
		}
	});

	const isSecondsFormat = isTimeDurationEnabled(viewEntry);
	const periodType = getPeriodType(summaryTableOptions);
	const isQuarterPeriod = _.get(summaryTableOptions, 'axisGranularity', '') == 'quarter';

	let customFormatData = [];

	const legendEntries = getForecastLegends(summaryTableOptions);
	_.forEach(legendEntries , (entry) => {

		var lineData = {
			isGroup: false,
			dimension: entry.name,
			raw_dimension: entry.columnField,
			periodIndex: 0,
		}

		_.forEach(filterFormattedData, (datum) => {
      //To support multiple historic models , given projection name as dimension value is unique
			const projectionName = _.get(datum, 'meta.projectionName', '')
			const projectionDimension = _.get(datum,'meta.dimension', '');
      const dimension = projectionDimension || projectionName
			if(dimension !== entry.columnField){
				return
			}

			const periodWeekLabels = _.get(datum, 'weekPeriodLabel', []);
			const periodLabels = ((periodType == 'week' || isQuarterPeriod) && !_.isEmpty(periodWeekLabels)) ?
				periodWeekLabels : _.get(datum, 'x', []);

			const periodValues = _.get(datum, 'y', []);
			const isProjection = _.get(datum, 'meta.isProjection');
			const periodCustomValues = _.get(datum, 'customData', []);

			_.forEach(periodLabels, (period, index) => {

				const formatText = getPeriodFormatType(summaryTableOptions);
				const labelDate =  moment(period).format(formatText);
				const customPeriod = _.get(periodCustomValues[index],'period');
				let periodLabel = labelDate === "Invalid date" ? period : labelDate;
				let rawPeriodLabel = moment(customPeriod).format("DD/MM/YYYY");

				const validForecastDate = isForecastingChart && isValidDateSummaryData(period, summaryTableOptions);
				if ((isProjection && index < 1) || validForecastDate) {
					return;
				}

				const entry = viewEntry;
				const isDisplayTimeFormat = isSecondsFormat;

				let periodValue = 0;
				let rawPeriodValue = 0;
				let value = '';
				if (isDisplayTimeFormat) {
					const datePeriod = 'value';
					value = _.get(periodCustomValues[index], datePeriod);
					periodValue = getFormattedNumberValue(value, entry);
					rawPeriodValue = value;
				} else {
					value = periodValues[index];
					periodValue = getFormattedNumberValue(periodValues[index], entry);
					rawPeriodValue = value;
				}

				periodValue = _.isNaN(periodValues[index]) ? '-' : periodValue;
				rawPeriodValue = _.isNaN(periodValues[index]) ? '-' : rawPeriodValue;

				lineData[periodLabel] = periodValue;
				lineData[rawPeriodLabel] = rawPeriodValue;
			});
		});
		customFormatData.push(lineData);
	});
	return customFormatData;
}

const getForecastTableHeaders = (formattedData, summaryTableOptions) => {
	const { projectionEnabled, isForecastingChart } = summaryTableOptions;
	let tableHeaders = [{ name: "Category", columnField: "dimension", sortOrder: 0 }];

	const periodType = getPeriodType(summaryTableOptions);
	const isQuarterPeriod = _.get(summaryTableOptions, 'axisGranularity', '') == 'quarter';
	const tailingDropStartDate = getTailingDropStartDate(summaryTableOptions);
	let tailingDropQuarterPeriod;
	if(isQuarterPeriod){
		const periodMonth = moment(tailingDropStartDate).month();
		const quarterMonths = getQuarterMonths();
		const quarterYear = periodMonth < 10 ? moment(tailingDropStartDate).year() :
			moment(tailingDropStartDate).year() + 1 ;
		tailingDropQuarterPeriod = `${quarterMonths[periodMonth]} ${quarterYear}`;
	}

	const filterFormattedData = _.filter(formattedData, (datum) => {
		if (!_.isEmpty(datum['x'])) {
			return datum
		}
	});
	_.forEach(filterFormattedData, (datum) => {
		const periodWeekLabels = _.get(datum, 'weekPeriodLabel', []);

		let periodLabels = ((periodType == 'week' || isQuarterPeriod) && !_.isEmpty(periodWeekLabels)) ?
			periodWeekLabels : _.get(datum, 'x', []);

		const isProjection = _.get(datum, 'meta.isProjection');
		const periodCustomValues = _.get(datum, 'customData', []);
		if(isProjection){
			periodLabels = _.drop(periodLabels);
		}

		_.forEach(periodLabels, (period, index) => {
			const formatText = getPeriodFormatType(summaryTableOptions);

			const labelDate = moment(period).format(formatText);
			let periodLabel = labelDate === "Invalid date" ? period : labelDate;
			const customPeriod = _.get(periodCustomValues[index],'period');
			const newLabelDate = labelDate === "Invalid date" ?
				moment(customPeriod).format(formatText) :
				labelDate;
			const sortValue = labelDate === "Invalid date" ?
				moment(customPeriod).valueOf() : moment(period).valueOf();
			const tailingDropStartDateLabel = moment(tailingDropStartDate).format(formatText);

			const indexOfData = _.findIndex(tableHeaders, function (header) {
				return _.get(header, 'columnField') === periodLabel;
			});

			let badgeName='';

			if(isProjection){
				periodLabel = isForecastingChart ? periodLabel : `${periodLabel} - Projected`;
				badgeName = "Projected";
			}

			if(tailingDropStartDateLabel == newLabelDate && projectionEnabled) {
				periodLabel =  isForecastingChart ? periodLabel : `${periodLabel} - Incomplete`;
				badgeName = "Incomplete";
			}

			if(isQuarterPeriod && tailingDropQuarterPeriod == periodLabel && projectionEnabled){
				periodLabel =  isForecastingChart ? periodLabel: `${periodLabel} - Incomplete`;
				badgeName = "Incomplete";
			}

			const headerWithDifferentBadge = _.find(tableHeaders, function (header) {
				return (_.get(header, 'columnField') === periodLabel &&
						_.get(header, 'badgeName') !== badgeName);
			});

			if (indexOfData === -1) {
				tableHeaders.push({
					name: periodLabel,
					columnField: periodLabel,
					badgeName: badgeName,
					sortOrder: sortValue
				})
			} else if (indexOfData > 0 && !_.isEmpty(headerWithDifferentBadge)){
				tableHeaders = _.reject(tableHeaders, function (header) {
					return (_.get(header, 'columnField') === periodLabel)
				});

				tableHeaders.push({
					name: periodLabel,
					columnField: periodLabel,
					badgeName: badgeName,
					sortOrder: sortValue
				})
			}
		});
	});

	return _.sortBy(tableHeaders, ['sortOrder']);
}

const getPeriodFormatType = (summaryTableOptions) => {
	const periodType = getPeriodType(summaryTableOptions)
	switch (periodType) {
		case 'year':
			return 'yy';
		case 'month':
			return 'MMM yy';
		case 'week':
			return '';
		case 'day':
			return 'MMM DD, YYYY';
		default:
			return undefined;
	}
}

const getForecastLegends = (summaryTableOptions) => {
	const forecastModelOptions = _.get(summaryTableOptions, 'forecastModelOptions');
	let legends = [];
	const totalLine = {
		name: "Historical data",
		columnField: 'Total',
	}
	legends.push(totalLine);

	_.forEach(forecastModelOptions, (modelDatum) => {
		let line = {
			name: modelDatum.name,
			columnField: (modelDatum.type ===  FORECASTING_TYPE.HISTORICAL_AVG) ?
                   (modelDatum.name + modelDatum.color) : modelDatum.name
		}
		legends.push(line);
	});
	return legends;
}