import React, { Component } from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import {
  getHeatChartConditionHeaders,
  getSelectedDimensionName
} from '../slaWatchHelper';
const DEFAULT_SORT_VALUE = 'most_failed_check';

class HeatChartSort extends Component {
  renderSortBy(){
    const { selectedListItem, selectedDimension, chartSortBy,  handleSortByChange} = this.props;

    const dimension = getSelectedDimensionName(selectedDimension, selectedListItem);
    const conditions = getHeatChartConditionHeaders(selectedDimension, selectedListItem);

    const options = [
      {name: `${pluralize(dimension)} with the most failed checks`, value: DEFAULT_SORT_VALUE}
    ];
    _.each(conditions, (condition) => {
      const label = condition['label']
      options.push({
        name: `${pluralize(dimension)} least compliant for ${label}`,
        value: `${condition['bucket_id']}_distance`
      });
    });
    options.push({name: 'Alphabetically', value: 'alphabetically'});
    const selectedOption = _.find(options, (option) => {
      return (option['value'] == chartSortBy);
    }) || options[0];

    return(
      <div className="sort-by-filter d-flex align-items-center">
        <span className="pr-1"> Sort by </span>
          <SolutionDropDown
            className='p-0'
            showSelectedIcon={true}
            title={selectedOption['name']}
            selectedOption={selectedOption}
            options={options}
            onSelect={(option) => { handleSortByChange(option)}}
          />
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderSortBy()}
      </>
    );
  }
}

HeatChartSort.propTypes = {
  selectedListItem: PropTypes.object,
  selectedDimension: PropTypes.object,
  chartSortBy: PropTypes.string,
  handleSortByChange: PropTypes.func
};

export default HeatChartSort;