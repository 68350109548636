import React from 'react';
import PropTypes from 'prop-types';

import TextFilter from '../TextFilter';
import NumberFilter from '../NumberFilter';
import DateFilter from '../DateFilter';
import BooleanFilter from '../BooleanFilter';
import {
  STRING_TYPES_FIELD,
  NUMBER_TYPES_FIELD,
  DATE_TYPES_FIELD,
  BOOLEAN_TYPES_FIELD
} from 'appConstants';

const FilterItem = (props) => {
  const {
    filter, filterKey, isEditMode, filterFieldEntries, templateId, apiParams, allFilters, onFilterChange
  } = props;

  const renderFilterBy = () => {
    return (
      <div className='filter-items filter-name forge-typography--body2'>
        {_.get(filter, 'name', '---')}
      </div>
    )
  }

  const onFilterItemClick = (item) => {
    console.log(item);
    // onFilterChange(item);
  }

  const onSearchChange = (item) => {
    const matchedFilter = _.find(filterFieldEntries, { field: filter.field });
    const renderType = _.get(matchedFilter, 'renderType', STRING_TYPES_FIELD);
    if (renderType === NUMBER_TYPES_FIELD) {
      const selectedFilter = _.find(allFilters, { field: item.field });
      if (_.isEmpty(item['value']) && _.isEmpty(item['to'])) {
        onFilterChange(item, true);
      } else {
        if(_.isEmpty(selectedFilter)) {
          onFilterChange(item);
        } else {
          onFilterChange({
            ...selectedFilter,
            value: item['value'],
            to: item['to']
          });
        }
      }
    } else {
      onFilterChange(item);
    }
  }

  const onOperatorChange = (item) => {
    const matchedFilter = _.find(filterFieldEntries, { field: filter.field });
    const renderType = _.get(matchedFilter, 'renderType', STRING_TYPES_FIELD);
    if (renderType === DATE_TYPES_FIELD) {
      onFilterChange({...matchedFilter, dateRange: item['dateRange'], type: DATE_TYPES_FIELD});
    } else if (renderType === NUMBER_TYPES_FIELD) {
      onFilterChange({...filter,
        operator: item['operator'],
        value: item['value'],
        to: item['to']
      });
    } else {
      onFilterChange({field: filter.field}, true);
    }

  }

  const renderFilterContent = () => {
    const matchedFilter = _.find(filterFieldEntries, { field: filter.field });
    const renderType = _.get(matchedFilter, 'renderType', STRING_TYPES_FIELD);
    if (_.isEmpty(filter)) {
      return null;
    } else {
      const attributes = {
        type: 'quickFilters',
        key: `${filterKey}-${filter.field}`,
        filterKey: filterKey,
        templateId,
        onFilterItemClick: onFilterItemClick,
        apiParams,
        isEditMode,
        allFilters,
        filterFieldEntries,
        filter,
        viewEntry: {},
        onSearchChange: onSearchChange,
        onOperatorChange: onOperatorChange,
        isExpanded: true,
      };

      if (renderType === NUMBER_TYPES_FIELD) {
        const currentFilter = _.find(allFilters, { field: filter.field });
        if (_.isEmpty(currentFilter) && _.isEmpty(_.get(filter, 'operator'))){
          attributes['filter'] = {
            ...filter,
            operator: _.get(filter, 'operator', "between"),
            value: _.get(filter, 'value', ''),
            to: _.get(filter, 'to', '')
          }
        } else {
          attributes['filter'] = currentFilter;
        }
      }

      // This needs to be capitalized - JSX checks the case of the first letter.
      let SpecificFilter;
      if (renderType === NUMBER_TYPES_FIELD) {
        SpecificFilter = NumberFilter;
      } else if (renderType === DATE_TYPES_FIELD) {
        SpecificFilter = DateFilter;
      } else if (renderType === BOOLEAN_TYPES_FIELD) {
        SpecificFilter = BooleanFilter;
      } else {
        SpecificFilter = TextFilter;
      }

      return (<SpecificFilter {...attributes} />);
    }
  }

  return (
    <>
      {renderFilterBy()}
      {renderFilterContent()}
    </>
  );
}

FilterItem.propTypes = {
  filters: PropTypes.array,
  filter: PropTypes.object,
  filterKey: PropTypes.string,
  isEditMode: PropTypes.bool,
  filterFieldEntries: PropTypes.array,
  templateId: PropTypes.string,
  apiParams: PropTypes.object,
  allFilters: PropTypes.array,
  onFilterChange: PropTypes.func,
};

export default FilterItem;
