import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { DropdownButton, Form } from 'react-bootstrap';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import * as commonPropTypes from 'common/propTypes';

class MultiSelectDropdown extends Component {

  isChecked = (selectedOption) => {
    const { selectedOptions } = this.props;
    return (_.findIndex(selectedOptions, (option) => {
      return _.isEqual(option, selectedOption);
    }) >= 0);
  }

  handleKeyDown = (e, option) => {
    if(isEnterButtonPressed(e)) {
      this.onSelectedOptionChange(option)
    }
  }

  onSelectedOptionChange = (selectedOption) => {
    let { selectedOptions, onOptionChange } = this.props;
    const outLineIndex = _.findIndex(selectedOptions, (option) => {
      return _.isEqual(option, selectedOption);
    });

    if(outLineIndex >= 0){
      selectedOptions.splice(outLineIndex, 1);
    }else{
      selectedOptions.push(selectedOption);
    }

     onOptionChange(selectedOptions);
  }

  renderCheckboxes = () => {
    const { options, displayNameField, maxSelectLimit, selectedOptions } = this.props;

    return _.map(options, (option, index) => {
      const checkboxId = _.uniqueId(_.get(option, displayNameField));

      const isChecked = this.isChecked(option);
      const isDisabled = maxSelectLimit ?
                          (selectedOptions.length >= Number(maxSelectLimit) && !isChecked) : false
      return(
        <li
          key={index}
          className="dropdown-item"
          tabIndex={0}
          onKeyDown={(e) => this.handleKeyDown(e, option)}>
          <Form.Check custom type="checkbox" id={checkboxId}
            checked={isChecked}
            disabled={isDisabled}
            onChange={() => this.onSelectedOptionChange(option)}
            label={option[displayNameField] || option.name || option}/>
        </li>
      );
    });
  }

  render() {
    const {
      className, title, variant, loading, size, showAsList, description
    } = this.props;

    if(showAsList) {
      return (
        <div className="multi-select-dropdown">
          {loading ? <div className="loader" /> : null}
          <div className="multi-select-list-item">
            {this.renderCheckboxes()}
          </div>
        </div>
      )
    }

    const dropdownOptions = {
      title:(
        <>
          {title}
          <div className="dropdown-caret icons-chevron-down"></div>
          {description ? <span>{description}</span> : null}
        </>
      ),
      variant,
      size,
      className: classNames('multi-select-dropdown', className),
      tabIndex: 0
    };

    return (
      <DropdownButton {...dropdownOptions}>
        {loading ? <div className="loader" /> : null}
        <div className="multi-select-dropdown-item">
          {this.renderCheckboxes()}
        </div>
      </DropdownButton>
    );
  }
}

MultiSelectDropdown.propTypes = {
  selectedOptions: PropTypes.array,
  onOptionChange: PropTypes.func,
  options: PropTypes.array,
  displayNameField: PropTypes.string,
  description: PropTypes.string,
  maxSelectLimit: commonPropTypes.stringOrNumberProps,
  className: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  showAsList: PropTypes.bool
};

export default MultiSelectDropdown;
