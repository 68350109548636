import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';

import {
  getBenchMarkEntries,
  getMonitorConditionOptions,
  getConditionDisplayName
} from './helper';

import { DEFAULT_DROPDOWN_OPTION } from 'appConstants';

import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import MonitorConditionValueSelector from 
  'pages/drilldown/components/Bookmark/BookmarkNotifyButton/MonitorConditionValueSelector';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

class Monitors extends Component {

  addMonitorCondition = (condition) => {
    const { onMonitorConditionsChange } = _.cloneDeep(this.props);

    onMonitorConditionsChange([condition]);
  }

  handleMonitorValueChange = (e, index, valueKey) => {
    const newMonitorConditions = _.cloneDeep(this.props.monitorConditions);
    if (e.target.validity.valid) {
      newMonitorConditions[index][valueKey] = e.target.value;
      this.props.onMonitorConditionsChange(newMonitorConditions);
    }
  }

  renderMonitorConditionOptionDropDown() {
    const { drilldown, currentVizChartType, visualization } = this.props;
    const { currentDrilldownViewEntry } = drilldown;
    const currentBenchMarkEntries  = getBenchMarkEntries(drilldown, visualization, currentVizChartType);
    const monitorConditionsOptions = getMonitorConditionOptions(
      currentDrilldownViewEntry, currentBenchMarkEntries, false);

    return (
      <SolutionDropDown
        className="add-condition"
        size="sm"
        selectedOption=" "
        ariaLabel="MonitorConditionOption"
        options={monitorConditionsOptions}
        onSelect={this.addMonitorCondition} />
    );
  }

  renderPercentage(monitorCondition, index) {
    if(monitorCondition.operator != '%') {
      return null
    }
    const currentBenchMarkName =  _.get(monitorCondition,'benchmarkName','');
    if(monitorCondition.type == 'benchmark') {
      return (<label className="ml-2 font-weight-bold"> percent of {currentBenchMarkName} </label>);
    }else {
      return (
        <>
          <label className="ml-2 font-weight-bold"> percent of </label>
          <input
            type="text"
            pattern="^\-?[0-9]\d*\.?\d*$"
            value={monitorCondition.percentValue}
            className="form-control monitor-input align-self-center ml-2"
            onChange={(e) => this.handleMonitorValueChange(e, index, 'percentValue')} />
        </>
      );
    }
  }

  renderValueField(monitorCondition, index) {
    const { drilldown } = this.props;
    const { currentDrilldownViewEntry } = drilldown;
    if(monitorCondition.operator != '%' && monitorCondition.type == 'benchmark'){
      return null
    }
    const monitorConditionValueAttributes = {
      className: 'ml-2',
      index,
      value: monitorCondition.value,
      viewEntry: currentDrilldownViewEntry,
      handleMonitorValueChange: this.handleMonitorValueChange,
    };

    return(<MonitorConditionValueSelector {...monitorConditionValueAttributes} />);
  }

  isDisableBenchMarkEntry(monitorCondition) {
    const { drilldown, visualization } = this.props;
    const { currentVisualizationType } = drilldown;
    const selectedBenchmarkNames = _.get(visualization, 
      `${currentVisualizationType}.currentBenchMarkMetricNames`);

    const benchMarkName = _.find(selectedBenchmarkNames, (name) => {
       return name ===_.get(monitorCondition,'benchmarkName','')
    });

    const disableBenchMarkEntry = ((_.isEmpty(benchMarkName) ||
        _.isEqual(benchMarkName, DEFAULT_DROPDOWN_OPTION.name)) &&
        monitorCondition.type == 'benchmark');
    return disableBenchMarkEntry;
  }

  renderMonitor(monitorCondition, index) {
    const { drilldown } = this.props;
    const { currentDrilldownViewEntry } = drilldown;
    const benchMarkName =  _.get(monitorCondition,'benchmarkName','');
    const monitorClassNames = classNames(
      'd-flex align-items-center mb-3',
      {'disabled':  this.isDisableBenchMarkEntry(monitorCondition)}
    );
    return (
      <div className={monitorClassNames} key={index}>
        <div>
          <label className="font-weight-bold" htmlFor={"monitorCheck" + index}>
            {getConditionDisplayName(currentDrilldownViewEntry, benchMarkName, monitorCondition)}
          </label>
        </div>
        {this.renderValueField(monitorCondition, index)}
        {this.renderPercentage(monitorCondition, index)}
      </div>
    );
  }

  renderMonitorConditions() {
    const { monitorConditions } = _.cloneDeep(this.props);
    const monitorConditionList = monitorConditions.map((condition, index) => {
      return this.renderMonitor(condition, index);
    });
    return (
      <div className="d-flex">
       {monitorConditionList}
       {this.renderMonitorConditionOptionDropDown()}
      </div>
    )
  }

  render() {
    return this.renderMonitorConditions()
  }
}

Monitors.propTypes = {
  monitorConditions: PropTypes.array,
  onMonitorConditionsChange: PropTypes.func,
  drilldown: commonPropTypes.drilldownPropTypes,
  currentVizChartType: PropTypes.string,
  visualization: PropTypes.object
}

export default (Monitors);
