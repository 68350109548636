import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import SearchFilterResultTable from '../SearchFilterResultTable';
import SearchTabBar from '../SearchResultPage/SearchTabBar';
import MapView from '../Map/MapView';
import { downloadMapPdf } from '../Export/pdfExport';
import { getTemplateDatasetNames,
  getAdvanceSearchTemplateId } from 'common/config/customerConfiguration';
import {
  updateSelectedReportIds,
  updateReportPageData,
  updateSearchPage,
  updateSearchFilters,
  updateShapeDatasetEntry,
  updateSortColumns,
  updateTemplateId,
  updateIsComparisonListOpen,
  updatePolygonGeojson
} from 'actions/advanceSearchActions';
import {
  getTableColumnEntries,
  getCurrentTemplateEntryById,
  getDefaultShapeDatasetEntry
} from 'common/config/templateConfiguration';
import { ADVANCE_SEARCH_PAGE } from 'appConstants';
import CompareList from './CompareList';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import TemplateDropdown from 'common/components/TemplateDropDown/TemplateDropdown';
import QuickFilter from '../QuickFilter/QuickFilter';
import BookmarkList from '../BookmarkSave/BookmarkList';
import GlobalEvents from 'common/components/GlobalEvents';

const RightSideCustomSearch = ({ onMapLoaded, map, selectedTab, setSelectedTab }) => {
  const dispatch = useDispatch();
  const reportPageData = useSelector(state => state.advanceSearch.reportPageData);
  const searchProperty = useSelector(state => state.advanceSearch.searchProperty);
  const searchMethod = _.get(searchProperty, 'searchMethod');
  const selectedReportRowIds = useSelector(state => state.advanceSearch.selectedReportRowIds);
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId',
    getAdvanceSearchTemplateId()));
  const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));
  const isApplyFilters = (!_.isEmpty(searchFilters) || !_.isEmpty(selectedShapeIds));

  const templateEntry = getCurrentTemplateEntryById(templateId);
  const templateEntries = _.filter(getTemplateDatasetNames(), (entry) => {
    return _.toString(entry['enable_advance_search']) == 'true';
  });

  const centerLng = _.get(templateEntry, 'map.centerLng');
  const centerLat = _.get(templateEntry, 'map.centerLat');
  const mapZoom = _.get(templateEntry, 'map.zoom');
  const isShowMap = !_.isEmpty(mapZoom) && !_.isEmpty(centerLat) && !_.isEmpty(centerLng);
  const detailsEntries = getTableColumnEntries(templateId);
  const paramsRef = useRef({});

  paramsRef.current = {
    templateId: templateId,
    map: map
  };

  useEffect(()=>{
    dispatch(updateIsComparisonListOpen(!_.isEmpty(selectedReportRowIds)));
  }, [selectedReportRowIds])

  useEffect(() => {
    GlobalEvents.on('EXPORT_SEARCH_PAGE', handleDownloadPdf);

    return () => {
      GlobalEvents.off('EXPORT_SEARCH_PAGE', handleDownloadPdf);
    }
  }, [])

  const renderComparePage = () => {
    return (
      <CompareList
        isCustomSearch={true}
        reportPageData={reportPageData}
        onReportPageClick={onReportPageClick}
        onRemoveCompare={onRemoveCompare}
        customSearchField={_.get(detailsEntries, '0.field', '')}
      />
    )
  }

  const handleDownloadPdf = (selectedTab) => {
    if(selectedTab === 'table') {
      return;
    }

    downloadMapPdf(paramsRef.current.map, paramsRef.current.templateId);
  }

  const onReportPageClick = () => {
    dispatch(updateSearchPage(ADVANCE_SEARCH_PAGE.REPORT_PAGE));
  }

  const onRemoveCompare = (data) => {
    // Data is passed empty if need to clear all the data.
    if(_.isEmpty(data)){
      dispatch(updateSelectedReportIds([]));
      dispatch(updateReportPageData([]));
    } else {
      const id = _.get(data, 'row_id_field');
      const newSelectedReportRowIds = _.filter(selectedReportRowIds, (newId) => id !== newId);
      dispatch(updateReportPageData(
        _.filter(reportPageData, (selectedItem) => selectedItem['row_id_field'] !== id)
      ));
      dispatch(updateSelectedReportIds(newSelectedReportRowIds));
    }
  };

  const updateSelectedTab = (event) => {
    setSelectedTab(event.detail.value);
  }

  const renderDetailsPage = () => {
    if (selectedTab === 'table') {
      return (
        <>
          <SearchFilterResultTable isCustomSearch={true} />
        </>
      )
    } else {
      return (
        <>
          <SearchFilterResultTable isCustomSearch={true} hideContent={true} />
          { isShowMap && <MapView onMapLoaded={onMapLoaded} /> }
        </>
      )
    }
  }

  const resetSelection = () => {
    dispatch(updateSortColumns([]));
    dispatch(updateSearchFilters([]));
    dispatch(updatePolygonGeojson({ featuresGeojson: {} }));
    dispatch(updateCurrentBookmark(''));
  }

  const onTemplateChange = (template) => {
    resetSelection();
    const selectedTemplateId = _.get(template, 'template_id');
    dispatch(updateTemplateId(selectedTemplateId));
    const newShapeDatasetEntry = getDefaultShapeDatasetEntry(selectedTemplateId)
    dispatch(updateShapeDatasetEntry(newShapeDatasetEntry));
  }

  const renderCustomSearchEmptyState = () => {
    return(
      <div className="custom-serach-empty-state h-100">
        <div className="property-wrapper">
          <img src='https://cdn.forge.tylertech.com/v1/images/spot-hero/real-estate-spot-hero.svg'
            alt='property' />
          <div>
            To begin your search, first specify which property you are looking for
          </div>
          <div className="custom-search-filter-wrapper">
            <TemplateDropdown
              searchMethod={searchMethod}
              templateId={templateId}
              onTemplateChange={onTemplateChange}
              templateEntries={templateEntries} />
            <div className="filter-bookmark">
              <QuickFilter isRightSideContainer={true}/>
              <span>or</span>
              <BookmarkList isEmptySelection={true}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="property-right-side">
      { !isApplyFilters && renderCustomSearchEmptyState() }
      { isApplyFilters &&
        <>
          <SearchTabBar
            selectedTab={selectedTab}
            templateId={templateId}
            updateSelectedTab={updateSelectedTab}
            onReportPageClick={onReportPageClick}
            searchFilters={searchFilters}
            selectedShapeIds={selectedShapeIds}
          />
          {renderDetailsPage()}
          {renderComparePage()}
        </>
      }
    </div>
  );
}

RightSideCustomSearch.propTypes = {
  onMapLoaded: PropTypes.func,
  map: PropTypes.object,
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func
};

export default RightSideCustomSearch;