import moment from 'moment-timezone';
import { getMonthlyDateTime } from './EmailFrequencyHelpers/Monthly';
import { getWeeklyDateTime } from './EmailFrequencyHelpers/Weekly';
import { DATE_FORMAT, TIMEZONE_OPTIONS, DEFAULT_TIME } from 'appConstants';

export const getEmailArrivalDateTime = (scheduleOptions) => {
  const frequency = _.get(scheduleOptions, 'frequency');
  const scheduledDateTime = emailTimeToDateTime(scheduleOptions['emailSendingTime'],
    scheduleOptions['timeZone']
  );
  let emailDate = '';
  switch(frequency) {
    case 'month':
      emailDate = getMonthlyDateTime(scheduleOptions, scheduledDateTime);
      break;
    case 'week':
      emailDate = getWeeklyDateTime(scheduleOptions, scheduledDateTime);
      break;
    case 'day':
      emailDate =  daily(scheduleOptions);
      break;
    default:
      return '';
  }
  return _.isEmpty(emailDate) ? '' : moment(emailDate, DATE_FORMAT).format('LL');
};

const daily = (scheduleOptions) => {
  const frequencyInterval = Number(scheduleOptions['frequencyInterval']);
  const scheduledDateTime = emailTimeToDateTime(scheduleOptions['emailSendingTime'],
    scheduleOptions['timeZone']);
  const startDate = moment(scheduleOptions['startDate']).format('YYYY-MM-DD');

  if(isStartDateCrossed(scheduleOptions, scheduledDateTime)){
    return moment(scheduledDateTime).add(frequencyInterval, 'days').format();
  }

  return moment(startDate).add(frequencyInterval, 'days').format();

}

export const isScheduleDate = (schedularDateTime) => {
  return (
    (moment(schedularDateTime).utc().hour() - moment().utc().hour()) > 0 ||
    (moment(schedularDateTime).utc().minutes() - moment().utc().minutes()) > 0
  )
}

export const isStartDateCrossed = (scheduleOptions, scheduledDateTime) => {
  const startDate = moment(scheduleOptions['startDate']).format('YYYY-MM-DD');
  const formattedScheduledDate = moment(scheduledDateTime).format('YYYY-MM-DD');

  return moment(formattedScheduledDate).isAfter(startDate);
}

export const getWeekdaysToNumber = (scheduleOptions) => {
  const daysIntoWeek = {
    'sunday': 0, 'monday': 1, 'tuesday': 2,
    'wednesday': 3, 'thursday': 4, 'friday': 5,
    'saturday': 6
  },scheduleDays = scheduleOptions['emailSendingDays'];

  return _.sortBy(_.map(scheduleDays, function(day){
    return daysIntoWeek[day];
  }));
}

function emailTimeToDateTime(schedule_time = DEFAULT_TIME, time_zone = _.get(TIMEZONE_OPTIONS, '0')) {
  const timeZoneToRegion = getTimeZoneToRegion();
  let dateTime = ''
  if(time_zone == 'UTC'){
    dateTime =  moment(schedule_time, ['h:m a', 'H:m']).utc().format();
  }else{
    dateTime =  moment(schedule_time, ['h:m a', 'H:m']).tz(timeZoneToRegion[time_zone]).utc().format();
  }
  return dateTime
}

function getTimeZoneToRegion(){
  return {
    'HST': 'America/Adak',
    'AKST': 'America/Anchorage',
    'PST': 'America/Los_Angeles',
    'MST': 'America/Denver',
    'CST': 'America/Chicago',
    'EST': 'America/New_York'
  };
}
