import { getCurrentTemplateEntryById, getGroupByEntries } from 'common/config/templateConfiguration';
import {
  getDefaultDimensionField,
  getDefaultVisualizationChartView,
  isGeoLocationOrUrlColumn
} from 'helpers/visualizationHelper';
import {
  GROUP_BY_NONE_ENTRY,
  VISUALIZATION_TYPES
} from 'appConstants';
import {
  getDefaultGroupByColumn,
  getDefaultVisualizationView,
} from 'common/config/visualizationConfiguration';

export const getDefaultSnapshotOptions = (bookmark) => {
  const { drilldown, commonFilters } = bookmark;
  const currentDrilldownViewEntry = _.get(drilldown, 'currentDrilldownViewEntry');
  const currentDrilldownTemplateId = _.get(drilldown, 'currentDrilldownTemplateId');
  const templateEntry = getCurrentTemplateEntryById(currentDrilldownTemplateId);
  const currentVisualizationType = getDefaultVisualizationView(currentDrilldownViewEntry);

  const drilldownDimensionField = getDefaultDimensionField(
    templateEntry.dimension_entries,
    currentDrilldownViewEntry
  );
  const snapshotView = getDefaultVisualizationChartView(
    currentDrilldownViewEntry,
    VISUALIZATION_TYPES.SNAPSHOT.type
  );
  const groupEntries = getGroupByEntries(currentDrilldownTemplateId);

  let drilldownGroupByEntry = GROUP_BY_NONE_ENTRY;
  if (currentVisualizationType === VISUALIZATION_TYPES.SNAPSHOT.type) {
    const defaultGroupByColumn = getDefaultGroupByColumn(currentDrilldownViewEntry);
    const groupByColumn = isGeoLocationOrUrlColumn(defaultGroupByColumn)
      ? _.get(groupEntries, '0.column')
      : defaultGroupByColumn;
    drilldownGroupByEntry = _.find(groupEntries, { column: groupByColumn });
  }

  return {
    currentDrilldownTemplateName: _.get(templateEntry, 'name'),
    currentDrilldownTemplateId: currentDrilldownTemplateId,
    currentDrilldownViewEntry: currentDrilldownViewEntry,
    currentDrilldownDimensionField: drilldownDimensionField,
    currentDrilldownGroupByEntry: drilldownGroupByEntry,
    currentSnapshotView: snapshotView,
    commonFilters,
  };
}
