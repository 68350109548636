import React from "react";
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { deleteBookmark } from 'common/api/bookmarksApi';
import { deleteCard } from 'common/api/collectionApi';
import DeleteConfirmModal from '../DeleteConfirmModal';
import { isMyViews } from 'pages/dashboard/components/CardList/cardHelper';
import { VIEW_DELETE_MESSAGE } from 'appConstants';

export const BookmarkErrorBoundaryWrapper = (SourceComponent) => {
 class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        errorInfo: null,
        hasError: false
      };
    }

    componentDidCatch(error, errorInfo) {
      this.setState({
        hasError: true,
        error: error,
        errorInfo: errorInfo
      })
    }

    onMenuToggle = (_, e) => {
      if (e) {
        e.stopPropagation();
      }
    };

    onDelete = () => {
      const { cardEntry, collectionEntry } = this.props;
      const currentCollectionId = _.get(collectionEntry, 'id');
      const isBookmarkCard = _.get(cardEntry, 'isBookmarkCard', false);
      const cardId = isBookmarkCard ? _.get(cardEntry, 'cardEntry.id') : _.get(cardEntry, 'id');
      (isMyViews(collectionEntry) ? deleteBookmark(cardId) : deleteCard(currentCollectionId, cardId))
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log('Error on deleting bookmark', error);
        });
    }

    render() {
      const { hasError, error, errorInfo } = this.state;
      const { cardEntry, currentDrilldownViewEntry } = this.props;
      if (hasError) {
        console.log("Component Error : " + JSON.stringify(error));
        console.log("Error info : " + JSON.stringify(errorInfo));
        return(
          <div
            aria-label={cardEntry.name}
            className="card metric-card bookmark-card">
            <div className="card-body d-flex flex-column">
              <div className="card-head">
                <div className="metric-head">
                  <div className="metric-title line-clamp text-truncate">
                    {cardEntry.name}
                  </div>
                </div>
                <div className="info-sections ml-1">
                  <Dropdown onToggle={(isOpen, e, m) => this.onMenuToggle(isOpen, e, m)}>
                    <Dropdown.Toggle
                      className="expand-btn btn-sm text-muted"
                      id={'bookmarkcard-custom' + cardEntry.id}
                      tabIndex={-1}>
                      <i className="icons-ellipsis-v"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item className="card-remove" eventKey="1" as="BookmarkDeleteButton">
                        <DeleteConfirmModal
                          title="Delete saved view"
                          message={VIEW_DELETE_MESSAGE}
                          onDeleteConfirm={this.onDelete}/>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="mt-auto">
                <div className="metric-card-visualization">
                  <div className="status">
                    <div className="target">
                      {_.isEmpty(currentDrilldownViewEntry) ?
                        'Metric not available' :
                        'something went wrong.'
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }else {
        return (
          <SourceComponent {...this.props}/>
          );
      }
    }
  }

  ErrorBoundary.propTypes = {
    cardEntry: PropTypes.object,
    collectionEntry: PropTypes.object,
    currentDrilldownViewEntry: PropTypes.object
  }

  return ErrorBoundary;
}
