import React from 'react';
import PropTypes from 'prop-types';
import { checkWGAColorContrast } from 'helpers/colorHelper';

const operatorEntries = {
  '<': 'less than',
  '>': 'greater than',
  '>=': 'greater than or equal to',
  '<=': 'less than or equal to',
  '==': 'equal to'
}

const TargetEntryDetails = (props) => {
  const {
    entry, hideInput, showAccordion, keyIndex, onClickAccordion, onChange, currentAccordion
  } = props;
  const { name, color, icon, target_entry_description, value, operator  } = entry;
  const showClassName = showAccordion ? 'show' : '';
  const operatorDescription = _.get(operatorEntries, operator, '');
  const flagContentColor = checkWGAColorContrast(color);

  const onClickToggleButton = ()  => {
    const newKeyIndex = showAccordion && (currentAccordion === keyIndex) ? '' : keyIndex;
    onClickAccordion(newKeyIndex);
  }

  const renderInput = () => {
    if (hideInput) {
      return null;
    }

    return (
      <div className="target-form">
        <label>If the value {operatorDescription}</label>
        <span>
          <input
            className="form-control"
            type="number"
            value={value}
            onChange={(e) => onChange(e.target.value, 'value', keyIndex)}
          />
        </span>
      </div>
    );
  }

  const renderDescription = () => {
    const description = (keyIndex === 'default-target-entry') ?
      `Otherwise the status is considered ${name} and the message next to status is` :
      'The message next to the status is';

    return (
      <div className="target-form">
        <label>{description}</label>
        <span>
          <textarea
            value={target_entry_description}
            onChange={(e) => onChange(e.target.value, 'target_entry_description', keyIndex)}
            className="form-control"
            rows="3"
            >
          </textarea>
        </span>
      </div>
    );
  }

  const renderTargetEntryToggleButton = () => {
    return (
      <div className="target-toggle" onClick={onClickToggleButton}>
        <i className="icons icons-chevron-right" />
        <div className="flag align-self-center" style={{ background: color }}>
          <div className="target-entry-name" style={{color : flagContentColor}}>
            <i className={`icons-${icon}`}></i> {name}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={`target-entry ${showClassName}`}>
      {renderTargetEntryToggleButton()}
      <div className="target-entry-body">
        {renderInput()}
        {renderDescription()}
      </div>
    </div>
  );
}

TargetEntryDetails.propTypes = {
  entry: PropTypes.object,
  hideInput: PropTypes.bool,
  showAccordion: PropTypes.bool,
  keyIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClickAccordion: PropTypes.func,
  onChange: PropTypes.func,
  currentAccordion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])
}

TargetEntryDetails.defaultProps = {
  entry: {},
  hideInput: false,
  showAccordion: false
}

export default (TargetEntryDetails);
