import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactStreetview from 'react-streetview';
import { Modal } from 'react-bootstrap';

import { getGoogleStreetViewMapKey  } from 'common/config/customerConfiguration';


class StreetViewMap extends Component {
  handleModelClose = () => {
    const { onStreetViewClose } = this.props;
    onStreetViewClose();
  }

  getMapContainerElem = () => {
    const { isComparisonEnabled } = this.props;
    const mapPeriodClass = isComparisonEnabled ? '.previous-period-map' : '.current-period-map';

    return _.head($(document).find(`${mapPeriodClass} .map-container .map-instance`));
  }

  render() {
    const { coordinates } = this.props;

    if (!_.isEmpty(coordinates)) {
      // see https://developers.google.com/maps/documentation/javascript
      const googleMapsApiKey = getGoogleStreetViewMapKey();
      // see
      // https://developers.google.com/maps/documentation/javascript/3.exp/reference#StreetViewPanoramaOptions
      const streetViewPanoramaOptions = {
        position: {lat: coordinates[1], lng: coordinates[0]},
        pov: {heading: 100, pitch: 0},
        zoom: 1
      };

      return (
        <Modal container={this.getMapContainerElem}
          size="lg"
          dialogClassName="street-view-modal"
          show={true}
          onHide={this.handleModelClose}
        >
          <Modal.Header closeButton>
            <div className="modal-title h4"> Street View </div>
          </Modal.Header>
          <Modal.Body className="street-view">
            <ReactStreetview
              apiKey={googleMapsApiKey}
              streetViewPanoramaOptions={streetViewPanoramaOptions}
            />
          </Modal.Body>
        </Modal>
      );
    }
  }
}

StreetViewMap.propTypes = {
  coordinates: PropTypes.array,
  isComparisonEnabled: PropTypes.bool,
  onStreetViewClose: PropTypes.func
};

export default StreetViewMap;
