import React, { useState }  from 'react';
import PropTypes from 'prop-types';

import FilterChip from 'common/components/FilterChip';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const TractChips = ({ tracts, handleTractRemove, shapeDetails }) => {
  const [isShowAllBtn, hideShowAllBtn] = useState(true);

  const renderTractChipsContent = () => {
    var selectedTracts = tracts;
    if(isShowAllBtn){
      selectedTracts = _.take(_.cloneDeep(tracts),6);
    }
    const tractChipsContent = _.map(selectedTracts, (tract, index) => {
      const label = _.chain(shapeDetails).find({shape_id: tract}).get('shape_name', tract).value();
      return (
        <div className="collections-global-filter" key={index}>
          <FilterChip value={tract} label={label} onRemove={handleTractRemove} />
        </div>
      );
    });
    return tractChipsContent;
  }

  const shouldShowAllButton = () => {
    return (!_.isEmpty(tracts) && tracts.length > 6 && isShowAllBtn)
  }

  const handleShowAllBtn = () => {
    hideShowAllBtn(false)
  }

  const handleKeyDownShowAllBtn = (e) => {
    if(isEnterButtonPressed(e)) {
      hideShowAllBtn();
    }
  }

  const renderShowAllButton = () => {

    if(!shouldShowAllButton()){
      return null;
    }

    return(
      <a className='p-0 tx-14 show-all-btn'
      onClick={handleShowAllBtn}
      onKeyDown={handleKeyDownShowAllBtn} >
       {`Show all (${tracts.length})`}
      </a>
    );
  }

  return(
    <div className='mb-2'>
      <div className='mb-2 d-flex gap-8 flex-wrap'>
        {renderTractChipsContent()}
      </div>
      {renderShowAllButton()}
    </div>
  );
}

TractChips.propTypes = {
  tracts: PropTypes.array,
  handleTractRemove: PropTypes.func,
  shapeDetails: PropTypes.array
}

export default TractChips;
