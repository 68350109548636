export const UPDATE_INSIGHTS_ID = 'UPDATE_INSIGHTS_ID';
export const UPDATE_HISTORY_TAB_HASH = 'UPDATE_HISTORY_TAB_HASH';
export const updateInsightsId = (insightId) => ({
  type: UPDATE_INSIGHTS_ID,
  insightId
});

export const updateHistoryTabHash = (hash) => ({
  type: UPDATE_HISTORY_TAB_HASH,
  hash
});
