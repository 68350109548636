import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import ShapeFilter from 'modules/MiniMap/shapeFilter';
import SearchAndListItems from './SearchAndListItems';

import { getRadarAreaEntries } from 'common/config/customerConfiguration';
import { SHAPE_AREA_TABS } from 'appConstants';
import { templateIdPropTypes } from 'common/propTypes';
import { getShapeFeaturesFromMap } from '../radarHelper';
import LoadingSpinner from 'common/components/LoadingSpinner';
import InlineEditWithLabel from 'pages/dashboard/components/InlineEditWithLabel';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

class ShapeComparisonModal extends Component {
  constructor(props) {
    super(props);

    const {
      selectedShapeAreaEntry, selectedTracts, comparisonTracts, selectedShapeTab, shapeDetails,
      selectAreaName, comparisonAreaName
    } = props;
    this.state = {
      shapeArea: selectedShapeAreaEntry,
      selectedTracts,
      comparisonTracts,
      selectedShapeTab,
      shapeDetails,
      isShapeAreaChanged: false,
      selectAreaName,
      comparisonAreaName,

    };
  }

  onKeyDownTab = (e, value) => {
    if (isEnterButtonPressed(e)) {
      this.onClickTab(value);
    }
  }

  onClickTab = (selectedShapeTab) => {
    this.setState({ selectedShapeTab })
  }

  onShapeAreaChange = (shapeArea) => {
    this.setState({
      shapeArea, isShapeAreaChanged: true, selectedTracts: [], comparisonTracts: []
    });
  }

  handleSelectedTractsChange = (newTracts, type = 'selectedTracts') => {
    this.setState({ [type]: newTracts });
  }

  onUpdate = (newTracts, type) => {
    this.setState({ [type] : newTracts });
  }

  onMapStylesAreLoaded = (map) => {
    this.setState({ shapeDetails: getShapeFeaturesFromMap(map), isShapeAreaChanged: false });
  }

  onUpdateSearchItems = (inputValue) => {
    const { shapeDetails } = this.props;
    const inputValueLowerCase = inputValue.toLowerCase()
    const filteredShapeAreas = _.filter(shapeDetails, ({ shape_name }) => {
      return _.includes(shape_name.toLowerCase(), inputValueLowerCase);
    });

    this.setState({ shapeDetails: _.isEmpty(inputValue) ? shapeDetails: filteredShapeAreas });
  }

  onSaveClick = () => {
    this.props.onSave(this.state);
  }

  onInputChange = (name, index) => {
    const key = index === 0 ? 'selectAreaName' : 'comparisonAreaName';

    this.setState({ [key]: name });
  }

  renderBodyContent = () => {
    const { selectedTracts, comparisonTracts, selectedShapeTab } = this.state;

    if(selectedShapeTab == SHAPE_AREA_TABS[0].value) {
      return this.renderShapeSection(selectedTracts, 'selectedTracts');
    } else {
      return this.renderShapeSection(comparisonTracts, 'comparisonTracts');
    }
  }

  renderShapeSection = (tracts, type) => {
    const { shapeTileApi, isRadarDrawingEnabled } = this.props;
    const area_entry_id = _.get(this.state.shapeArea, 'id');
    const mapAttributes = _.pick(this.props, 'isRadarDrawingEnabled',
    'currentMapStyleEntry', 'currentDrilldownTemplateId');
    const shapeDatasetId = _.get(this.state, 'shapeArea.shape_dataset_id');
    const selectedShapeEntry = {
      shape_dataset_id: shapeDatasetId
    };

    const comparisonRightClass = isRadarDrawingEnabled ?
      'comparison-right-side draw-icon-filter' : 'comparison-right-side';
    return (
      <section className="comparison-content-area">
        <SearchAndListItems
          key={`${shapeDatasetId}-${type}`}
          selectedValues={tracts}
          searchItems={_.sortBy(this.state.shapeDetails, 'name')}
          onUpdate={(newTracts) => this.onUpdate(newTracts, type)}
          onUpdateSearchItems={this.onUpdateSearchItems}
        />
        <div className={comparisonRightClass}>
          <ShapeFilter
            {...mapAttributes}
            selectedShapeEntry={selectedShapeEntry}
            shapeTileApi={(params) => shapeTileApi(params, { area_entry_id })}
            selectedShapeIds={tracts}
            isDrawFilter={true}
            toggleShapeIdsFilter={(newTracts) => this.handleSelectedTractsChange(newTracts, type)}
            onMapStylesAreLoaded={this.onMapStylesAreLoaded}
          />
        </div>
        <LoadingSpinner isLoading={this.state.isShapeAreaChanged} />
      </section>
    );
  }

  renderDropDownSection = () => {
    return(
      <div className='d-flex gap-10 ml-auto align-items-center  tx-14 mb-0'>
        <div>Select by</div>
        <SolutionDropDown
          className='p-0 compare-mode'
          size='sm'
          optionDisplayField='shape_name'
          selectedOption={this.state.shapeArea}
          options={getRadarAreaEntries()}
          onSelect={this.onShapeAreaChange}
        />
      </div>
    );
  }

  renderTab = ({ name, value, key }, index) => {
    const { selectedShapeTab } = this.state;
    const activeClass = (selectedShapeTab === value) ? 'active' : '';
    const label = _.get(this.state, key, name);
    const comparisonListClassNames = value === SHAPE_AREA_TABS[1].value ?
      'comparison-name-list' :
      'selected-name-list';

    return(
      <li key={index}
        className={`list-inline-item ${activeClass} ${comparisonListClassNames}`} tabIndex="0"
        aria-label={label}
        onKeyDown={(e) => this.onKeyDownTab(e, value)}
        onClick={() => this.onClickTab(value)}
      >
        <InlineEditWithLabel
          keyIndex={index}
          name={label}
          onlyClickIcon={true}
          onInputChange={(newName) => this.onInputChange(newName, index)}
        />
      </li>
    );
  }

  render() {
    const {
      showModal, onClose, comparisonTracts, selectedTracts,
      selectAreaName, comparisonAreaName, isMiniMapSelectEvent
    } = this.props;
    const isDisabled = (
      _.isEqual(comparisonTracts, this.state.comparisonTracts) &&
      _.isEqual(selectedTracts, this.state.selectedTracts) &&
      selectAreaName === this.state.selectAreaName &&
      comparisonAreaName === this.state.comparisonAreaName &&
      !isMiniMapSelectEvent
    );

    return (
      <Modal
        show={showModal}
        bsPrefix='radar-comparison-modal modal'
        centered
        onHide={onClose}>
        <Modal.Header bsPrefix='modal-header border-bottom-0' >
          <Modal.Title>Map selector</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="comparison-head">
            <div className="comparison-tabs-list">
              <ul className="list-inline">
                {_.map(SHAPE_AREA_TABS, this.renderTab)}
              </ul>
            </div>
            <div className="comparison-action">
              {this.renderDropDownSection()}
            </div>
          </section>
          {this.renderBodyContent()}
        </Modal.Body>
        <Modal.Footer bsPrefix='modal-footer border-top-0'>
          <Button variant="outline-primary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.onSaveClick} disabled={isDisabled}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ShapeComparisonModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  selectedShapeTab: PropTypes.string,
  selectedShapeAreaEntry: PropTypes.object,
  shapeTileApi: PropTypes.func,
  selectedTracts: PropTypes.array,
  comparisonTracts: PropTypes.array,
  currentMapStyleEntry: PropTypes.object,
  currentDrilldownTemplateId: templateIdPropTypes,
  shapeDetails: PropTypes.array,
  selectAreaName: PropTypes.string,
  comparisonAreaName: PropTypes.string,
  isRadarDrawingEnabled: PropTypes.bool,
  isMiniMapSelectEvent: PropTypes.bool
}

ShapeComparisonModal.defaultProps = {
  showModal: false,
  selectedShapeTab: ''
}

export default ShapeComparisonModal;
