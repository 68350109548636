// Vendor imports.
import _ from 'lodash';

// Project imports
import {
  isStackTypeChart,
  isCompareView,
  getChartType,
  getNoOfBarsCount,
  formatDimensionText
} from '../vizOptionsHelper';
import { formatChartTotalText } from 'helpers/chartDataHelper';
import { COMPARE_VIEW_DRILLDOWN_OPTIONS, MORE_THAN_FIFTY_TEXT } from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getGroupByEntityField } from 'helpers/snapshotRendererHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsNumericTime } from 'helpers/visualizationHelper';

export const getAnnotations = (apiDataEntries, vizOptions, formattedData) => {
  const { groupByEntry, groupType } = vizOptions;

  if (_.get(groupByEntry, 'name') === 'None' || _.isEmpty(groupByEntry)) {
    if (isStackTypeChart(vizOptions)) {
      return formatAnnotationGroupByNoneViewData(formattedData, vizOptions);
    } else {
      return '';
    }
  } else {
    if (isCompareView(groupType)) {
      return formatAnnotationCompareViewData(apiDataEntries, vizOptions);
    } else {
      return formatAnnotationCombineViewData(apiDataEntries, vizOptions);
    }
  }
}

// TODO
export const formatAnnotationGroupByNoneViewData = (formattedData, vizOptions) => {
  const { viewEntry, secondaryMetricEntry, templateId, viewMode } = vizOptions;
  let yAxisData = [];
  const chartType = getChartType(vizOptions);
  const options = {
    viewOptions: viewEntry,
    secondaryComparison: secondaryMetricEntry
  };
  const useSecondaryComparison = chartType === 'bar';
  const dimensionEntries = _.chain(formattedData).get(0).groupBy('value').value();

  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const labelField = isSecondsFormat ? 'actualLabel' : 'label';
  let result = [];
  let xAxisValues = [];
  _.each(dimensionEntries, (values, category) => {
    // To skip the annotation text for benchmark entries.
    if (category === '' || category === ' ') {
      return;
    }

    yAxisData = formatDimensionText(category, false, templateId);
    const sumOfEntryCounts = _.sumBy(values, function (entry) {
      return _.isNaN(Number(entry[labelField])) ? 0 : Number(entry[labelField]);
    });

    const commonSumOfValue = isSecondsFormat ? getSecondsAsNumericTime(sumOfEntryCounts) : sumOfEntryCounts;
    let annotation = getAnnotationData({
      x: commonSumOfValue,
      y: yAxisData,
      text: formatChartTotalText(
        sumOfEntryCounts,
        options,
        false,
        useSecondaryComparison,
        (viewMode === VIEW_MODE.SMALL)),
    });
    xAxisValues.push(commonSumOfValue);
    result.push(annotation);
  });

  _.max(xAxisValues)
  return {
    annotations: _.flattenDeep(result),
    xAxisMaxValue: _.max(xAxisValues)
  }
}

// TODO
function formatAnnotationCompareViewData(chartData, vizOptions) {
  const { groupByViewType } = vizOptions;
  const compareViewType = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: groupByViewType });

  const result = _.chain(chartData).
    groupBy('group_by_field').
    map((dimensionEntries, category) => {
      const groupByField = getGroupByEntityField(category);
      const noOfBarsCount = getNoOfBarsCount(compareViewType, _.size(_.keys(dimensionEntries)));
      const limitedDimensionEntries = _.take(dimensionEntries, noOfBarsCount);
      return formatChartDataEntries(limitedDimensionEntries, {
        groupByEntity: 'dimension',
        groupByField,
        vizOptions
      });
    }).
    flattenDeep().
    value();

    return {
      annotations: _.flatten( _.map(result, 'annotations')),
      xAxisMaxValue: _.max(_.map(result, 'xAxisMaxValue'))
    }
}

// TODO
function formatAnnotationCombineViewData(chartData, vizOptions) {
  return formatChartDataEntries(chartData, {
    vizOptions,
    groupByEntity: 'group_by_field',
    isCombineView: true
  });
}

const formatChartDataEntries = (chartData, options) => {
  const { vizOptions, groupByEntity, isCombineView, groupByField } = options;
  const { groupByViewType, viewEntry, secondaryMetricEntry, templateId, showChartValues } = vizOptions;
  const compareViewType = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: groupByViewType });
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);

  const totalTextFormatOptions = {
    viewOptions: viewEntry,
    secondaryComparison: secondaryMetricEntry
  };
  const chartType = _.get(vizOptions, 'secondaryMetricEntry.render_type', '');
  const useSecondaryComparison = chartType === 'bar';
  const nullValueLabel = getNullValueLabel(templateId);

  let drilldownDimensionEntries = [];
  let xAxisValues = [];
  let annotationText = _.chain(chartData).
    groupBy(groupByEntity).
    map((dimensionEntries, dimension) => {
      let yValue = [];
      const category = getGroupByEntityField(dimension) || nullValueLabel;
      if (isCombineView) {
        const yAxisData = [getCombineYaxisLabel(category, dimensionEntries, vizOptions)];
        drilldownDimensionEntries = dimensionEntries;
        yValue = formatYAxisData(yAxisData)[0];
      } else {
        const drilldownDimensionLimit = getNoOfBarsCount(compareViewType, _.size(dimensionEntries));
        drilldownDimensionEntries = _.take(dimensionEntries, drilldownDimensionLimit);
        yValue = `${groupByField}-${category}`;
      }
      const sumOfEntryCounts = _.sumBy(drilldownDimensionEntries, function (entry) {
        return _.isNaN(Number(entry['count'])) ? 0 : Number(entry['count']);
      });

      const commonSumOfValue = isSecondsFormat ?
        getSecondsAsNumericTime(sumOfEntryCounts, dataUnit) :
        sumOfEntryCounts;
      xAxisValues.push(commonSumOfValue);
      return getAnnotationData({
        x: commonSumOfValue,
        y: yValue,
        text: !showChartValues ? '' : formatChartTotalText(
          sumOfEntryCounts,
          totalTextFormatOptions,
          false,
          useSecondaryComparison,
          false,
          true
        ),
      })
    }).
    value();

  const isMoreThan50dimension = _.get(chartData, "[0].is_remaining_dimension", false);
  if (isMoreThan50dimension) {
    const dimensionText = formatDimensionText(groupByField, false, templateId);
    const groupByFieldValue = _.isNull(groupByField) ? '' : dimensionText;
    const yValue = `${groupByFieldValue}-${templateId}`
    const seeMoreText = getAnnotationData({
      x: 0,
      y: yValue,
      text: ` <span style="fill:#4253b1"><i>${MORE_THAN_FIFTY_TEXT}</i></span>`
    })
    annotationText.push(seeMoreText)
  }

  return {
    annotations: annotationText,
    xAxisMaxValue: _.max(xAxisValues)
  }

  // return annotationText;

}

export const getAnnotationData = (options) => {
  const { x, y, text, showarrow, xanchor } = options;
  return {
    x,
    y,
    text,
    showarrow: (showarrow || false),
    xanchor: (xanchor || 'left')
  }
};

const getCombineYaxisLabel = (dimension, chartData, chartOptions) => {
  return `${dimension} ${chartOptions.dimensionName}  ${_.get(chartData, [0, 'dimension_count'], 0)}`
}

const formatYAxisData = (yAxisData) => {
  return _.map(yAxisData, (label) => {
    if (_.isArray(label)) {
      return formatYAxisData(label)
    }
    return formatDimensionText(label);
  });
}
