
import _ from 'lodash';
import $ from 'jquery';

export const isMarkerOnCenterOfMap = (mapContainer, flyoutContainer) => {
  if(_.isUndefined(flyoutContainer._container)){
    return false;
  }
  const PADDING_TOP_AND_BOTTOM = 300;
  var markerRect = flyoutContainer._container.getBoundingClientRect();
  var mapRect = mapContainer._container.getBoundingClientRect();
  window.markerRect = markerRect;
  window.mapRect = mapRect;
  var markerVerticalCenter = (markerRect.top + markerRect.height);
  const mapTopPadding = mapRect.top + PADDING_TOP_AND_BOTTOM;
  const mapBottomPadding = (mapRect.top + mapRect.height) - PADDING_TOP_AND_BOTTOM;
  const isCentre = markerVerticalCenter > mapTopPadding && markerVerticalCenter < mapBottomPadding;
  const isBottomCutOff = markerRect.bottom > mapRect.bottom;

  return isCentre || isBottomCutOff;
}

export const getNewAnchorPosition = (mapContainer, flyoutContainer) => {

  var markerRect = flyoutContainer._container.getBoundingClientRect();
  var mapRect = mapContainer._container.getBoundingClientRect();

  var centerX = mapRect.left + mapRect.width / 2;
  var markerX = markerRect.left + markerRect.width / 2;
  var isOnRight = markerX > centerX;

  return isOnRight ? "right" : "left";
}


export const updatePopupAnchorClass = (_featurePopup,newAnchor) => {
  var popupElement = $(_featurePopup._content);
  var newClassName = newAnchor === 'right' ? "mapboxgl-popup-anchor-right" : "mapboxgl-popup-anchor-left";
  var classNames = popupElement.closest('.mapboxgl-popup').attr("class").split(" ");

  var classToRemove = "mapboxgl-popup-anchor-";
  classNames.forEach(function(className) {
    if (className.startsWith(classToRemove)) {
      popupElement.closest('.mapboxgl-popup').removeClass(className);
    }
  });
  popupElement.closest('.mapboxgl-popup').addClass(newClassName);
}

export const calculateSpiderfyTransformValue = (flyoutContainer,spiderfyMarkerParam, anchor) => {
  var translateX = flyoutContainer._pos.x + spiderfyMarkerParam.x;
  var translateY = flyoutContainer._pos.y + spiderfyMarkerParam.y;

  if(anchor == 'right'){
    return `translate(-100%, -50%) translate(${translateX}px, ${translateY}px)`;
  } else{
    return `translate(0%, -50%) translate(${translateX}px, ${translateY}px)`;
  }
}

export const calculateTransformValue = (flyoutContainer, anchor) => {
 var translateX = flyoutContainer._pos.x;
  var translateY = flyoutContainer._pos.y;

  if(anchor == 'right'){
    return `translate(-100%, -50%) translate(${translateX}px, ${translateY}px)`;
  }else{
    return `translate(0%, -50%) translate(${translateX}px, ${translateY}px)`;
  }
}
