import React from 'react';
import { ForgeDivider, ForgeIcon } from '@tylertech/forge-react';
import PropTypes from 'prop-types';

function LibraryTitle({ selectedLibrary, onClickBack }) {
  const { name } = selectedLibrary;
  return (
    <div onClick={() => onClickBack('')} className='cursor-pointer'>
      <ForgeDivider />
      <div className='cursor-pointer d-flex align-items-center gap-10 px-2 my-2'>
        <ForgeIcon name="arrow_back" />
        {name}
      </div>
      <ForgeDivider />
    </div>
  )
}

LibraryTitle.propTypes = {
  selectedLibrary: PropTypes.object,
  onClickBack: PropTypes.func
};

export default LibraryTitle;
