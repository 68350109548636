import _ from 'lodash';

import { POINT_AND_STACK_STYLES } from '../PointsAndStackPartial';
import { getGeojson } from '../../helpers/mapHelper';
import { LAYERS as choroplethLayers } from '../ChoroplethMapPartial';
import { LAYERS as heatMapLayers } from '../HeatMapPartial';
import { LAYERS as shapeFilterLayers } from '../ShapeFilterPartial';

const SOURCES = {
  HIGHLIGHT: 'stack-highlight-source'
};
export const LAYERS = {
  HIGHLIGHT: 'stack-highlight-layer'
};

export default class StackHighlight {
  constructor(map) {
    this._map = map;
  }

  initialize = () => {
    if(!this._map) {
      return;
    }

    this._map.addSource(SOURCES.HIGHLIGHT, {
      type: 'geojson',
      data: getGeojson()
    });

    this._map.addLayer({
      'id': LAYERS.HIGHLIGHT,
      'type': 'circle',
      'source': SOURCES.HIGHLIGHT,
      'paint': {
        'circle-radius': 22,
        'circle-color': POINT_AND_STACK_STYLES.STACK_COLOR,
        'circle-opacity': 0.75,
        'circle-radius-transition': { duration: 0, delay: 0 },
        'circle-color-transition': { duration: 0, delay: 0 }
      }
    });
  }

  update = (feature = null) => {
    const otherMapLayers = _.compact(_.flatten([
      _.values(choroplethLayers),
      _.values(shapeFilterLayers),
      _.values(heatMapLayers)]));
    if(_.includes(otherMapLayers, _.get(feature, 'layer.id'))) {
      return;
    }

    if (this._map && this._map.getSource(SOURCES.HIGHLIGHT)) {
      this._map.getSource(SOURCES.HIGHLIGHT).setData(getGeojson(feature));
    }
  }

  destroy() {
    _.each(SOURCES, (sourceId) => {
      const source = this._map.getSource(sourceId);
      if (!_.isUndefined(source)) {
        source.setData(getGeojson());
      }
    });
  }
}
