import { DISCRETE_AGGREGATE_TYPES, CUMULATIVE_AGGREGATE_TYPES, DISTRIBUTION_CHART_TYPES } from 'appConstants';

export const isValidateJson = (string) => {
  if (_.includes(['false', 'true', 'null', true, false, null, ""], string) || !isNaN(Number(string))) {
    return false;
  } else {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  }
}

export const getBenchmarkBucketConfig = (bucket, point, totalAndPercentage) => {
  let pointValue = _.get(point, 'y');
  let percentage = _.get(point, 'meta.percentage');
  let { bucket_aggregate_type, source, value, field, above_color, below_color } = bucket;

  if (_.get(totalAndPercentage, 'showDimensions')) {
    percentage = _.get(totalAndPercentage, 'percentage');
    pointValue = _.get(totalAndPercentage, 'total');
  }

  if (source === 'column') {
    value = _.get(point, `data.customData[0][0].${field}`);
  }
  const getColor = (yValue, benchmarkValue) => yValue >= Number(benchmarkValue) ? above_color: below_color;

  switch(bucket_aggregate_type) {
    case DISCRETE_AGGREGATE_TYPES[0]:
      return {
        color: getColor(pointValue, value),
        value,
        aggregateType: DISTRIBUTION_CHART_TYPES.DISCRETE
      };
    case DISCRETE_AGGREGATE_TYPES[1]:
      return {
        color: getColor(percentage, value),
        value: `${value}%`,
        aggregateType: DISTRIBUTION_CHART_TYPES.DISCRETE
      };
    case CUMULATIVE_AGGREGATE_TYPES[0]:
      return {
        color: getColor(pointValue, value),
        value,
        aggregateType: DISTRIBUTION_CHART_TYPES.CUMULATIVE
      };
    case CUMULATIVE_AGGREGATE_TYPES[1]:
      return {
        color: getColor(percentage, value),
        value: `${value}%`,
        aggregateType: DISTRIBUTION_CHART_TYPES.CUMULATIVE
      };
  }
}
