import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { getLngLatBounds } from '../helpers/mapHelper';
import { getShapesOutlineWidth, getShapesOutlineHighlightWidth } from 'common/config/templateConfiguration';
import { getMapCenter, getMapzoom } from 'common/config/templateConfiguration';

export const SOURCES = {
  SHAPES: 'shapes-layer-source'
};
const LAYERS = {
  SHAPES_OUTLINE: 'shapes-line',
  COMPARISON_SHAPES_OUTLINE: 'comparison-shapes-line',
  SHAPES_OUTLINE_HIGHLIGHT: 'shapes-line-highlight'
};


class ShapeLayerPartial extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  componentDidMount() {
    this.initSourcesAndLayers();
  }

  componentDidUpdate(prevProps) {
    const { apiParams } = this.props;
    const isApiParamsChanged = !_.isEqual(apiParams, prevProps.apiParams);

    if (!_.isEqual(prevProps.shapeGroupId, this.props.shapeGroupId) || isApiParamsChanged){
      this.removeSourcesAndLayers();
      this.initSourcesAndLayers();
    }

    if (
      !_.isEqual(this.props.selectedShapesExtent, prevProps.selectedShapesExtent) &&
      this.props.selectedShapesExtent
    ) {
      this.panMapToShapes();
    }
  }

  initSourcesAndLayers(){
    const { shapeGroupId, isCensusTract } = this.props;
    // Initialize sources and Layers only when shapesDatasets are configured
    if(!_.isEmpty(shapeGroupId) || isCensusTract){
      this.initSources();
      this.initLayers();
    }
  }

  initSources() {
    const { shapeGroupId, map, currentDrilldownTemplateId, shapeTileUrl } = this.props;
    const queryParams = { shapeGroupId, currentDrilldownTemplateId };

    map.addSource(SOURCES.SHAPES, {
      type: 'vector',
      'geojsonTile': true,
      'tiles': [shapeTileUrl(queryParams)]
    });
  }

  initLayers() {
    const { map, selectedShapeIds, currentDrilldownTemplateId, comparisonShapeIds } = this.props;

    map.addLayer({
      'id': LAYERS.SHAPES_OUTLINE,
      'type': 'line',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'paint': {
        'line-color':  {
          'type': 'identity',
          'property': 'border_color'
        },
        'line-width': getShapesOutlineWidth(currentDrilldownTemplateId)
      }
    });

    map.addLayer({
      'id': LAYERS.SHAPES_OUTLINE_HIGHLIGHT,
      'type': 'line',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'paint': {
        'line-color':  {
          'type': 'identity',
          'property': 'outline_highlight_color'
        },
        'line-width': getShapesOutlineHighlightWidth(currentDrilldownTemplateId)
      },
      'filter': ['all', ['in', 'shape_id'].concat(selectedShapeIds)]
    });

    map.addLayer({
      'id': LAYERS.COMPARISON_SHAPES_OUTLINE,
      'type': 'line',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'paint': {
        'line-color':  {
          'type': 'identity',
          'property': 'outline_highlight_color'
        },
        'line-width': getShapesOutlineHighlightWidth(currentDrilldownTemplateId),
        "line-dasharray": [4, 2]
      },
      'filter': ['all', ['in', 'shape_id'].concat(comparisonShapeIds)]
    });
  }

  removeSourcesAndLayers() {
    const { map } = this.props;

    _.each(LAYERS, (layerId) => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });
    _.each(SOURCES, (sourceId) => {
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    });
  }

  // TODO Move to common function/Component
  panMapToShapes() {
    const { map, selectedShapesExtent, selectedShapeIds, currentDrilldownTemplateId } = this.props;
    let lngLatBounds = [];
    // For Old Tactics 'selectedShapesExtent' is not array. so converting it to Array format.
    lngLatBounds = getLngLatBounds(selectedShapesExtent);
    if(!_.isEmpty(selectedShapeIds)){
      map.fitBounds(lngLatBounds, { padding: 80 });
    }else{
      const center = getMapCenter(currentDrilldownTemplateId);
      const zoom = getMapzoom(currentDrilldownTemplateId);
      map.jumpTo({ center, zoom });
    }
  }

  render() {
    const { map, selectedShapeIds, comparisonShapeIds } = this.props;

    if (map.getLayer(LAYERS.SHAPES_OUTLINE)) {
      const newSelectedShapes = [...selectedShapeIds, ...comparisonShapeIds];
      map.setFilter(LAYERS.SHAPES_OUTLINE, ['all', ['!in', 'shape_id'].concat(newSelectedShapes)]);
      map.setFilter(LAYERS.SHAPES_OUTLINE_HIGHLIGHT, ['all', ['in', 'shape_id'].concat(selectedShapeIds)]);
    }

    if (map.getLayer(LAYERS.COMPARISON_SHAPES_OUTLINE)) {
      map.setFilter(LAYERS.COMPARISON_SHAPES_OUTLINE, ['all', ['in', 'shape_id'].concat(comparisonShapeIds)]);
    }

    return null;
  }
}

ShapeLayerPartial.propTypes = {
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shapeGroupId: PropTypes.string,
  selectedShapeIds: PropTypes.array,
  comparisonShapeIds: PropTypes.array,
  selectedShapesExtent: PropTypes.object,
  map: PropTypes.object,
  shapeTileUrl: PropTypes.func,
  isCensusTract: PropTypes.bool,
  apiParams: PropTypes.bool
};

ShapeLayerPartial.defaultProps = {
  selectedShapeIds: [],
  comparisonShapeIds: [],
  isCensusTract: false
};

export default ShapeLayerPartial;
