import React from 'react';
import { ForgeBadge, ForgeTooltip } from '@tylertech/forge-react';

const ProphetBadge = () => {

  return (
    <div>
      <ForgeBadge className="forge-machine-model">
        Machine learning model
        <ForgeTooltip position='top' className="forge-machine-model-tooltip">
          This metric has been equipped with a machine learning model that has been optimized
          to perform well on datasets with recurring seasonal trends.
        </ForgeTooltip>
      </ForgeBadge>
    </div>
  );
}

export default ProphetBadge;
