import abortableFetch from 'common/abortableFetch';

export const addCollaborateCollectionUser = (collectionId, data) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(data),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = `/api/collections/${collectionId}/collaborate_collection_users.json`;
  return abortableFetch(apiUrl, fetchOptions);
}

export const updateCollaborateCollectionUserFromSubscription = (collectionId, data) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(data),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = `/api/collections/update_collaborate_collection_users/${collectionId}.json`;
  return abortableFetch(apiUrl, fetchOptions);
}

export const getCollaborateCollectionUsers = (collectionId) => {
  return `/api/collections/get_collaborate_collection_users/${collectionId}.json`;
};

export const getCollaborateUserActivities = (collectionId) => {
  return `/api/collections/get_collaborate_user_activities/${collectionId}.json`;
};

export const updateCollaborateCollectionUser = (collectionId, user) => {
  const userId = user.id;

  const options =  {
    method: 'PUT',
    body: JSON.stringify(user),
    headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = `/api/collections/${collectionId}/collaborate_collection_users/${userId}.json`;
  return abortableFetch(apiUrl, options);
};

export const deleteCollaborateUser = (collectionId, user) => {
  const userId = user.id;
  const params =  {
    method: 'DELETE',
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = `/api/collections/${collectionId}/collaborate_collection_users/${userId}.json`;
  return abortableFetch(apiUrl, params);
};
