import {
  UPDATE_ON_TOGGLE_MODAL,
  UPDATE_IS_LOADING,
  UPDATE_COLLECTION,
  UPDATE_USERS,
  UPDATE_REFETCH,
  UPDATE_IS_USER_DATA_LOADING
} from './collaboratorActions';

export const getInitialState = () => {
  return {
    isLoading: false,
    users: [],
    isUserDataLoading: false,
    reFetch: false
  };
};

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case UPDATE_ON_TOGGLE_MODAL:
      return {
        ...state,
        isOpenModal: _.get(action, 'isOpenModal')
      }
    case UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: _.get(action, 'isLoading')
      }
    case UPDATE_REFETCH:
      return {
        ...state,
        reFetch: _.get(action, 'reFetch')
      }
    case UPDATE_COLLECTION:
      return {
        ...state,
        collection: _.get(action, 'collection'),
        isCollaborateCollection: _.get(action, 'collection.is_collaborate_collection')
      }
    case UPDATE_IS_USER_DATA_LOADING:
      return {
        ...state,
        isUserDataLoading: _.get(action, 'isUserDataLoading', false)
      }
    case UPDATE_USERS:
      return {
        ...state,
        users: _.get(action, 'users'),
        isUserDataLoading: _.get(action, 'isUserDataLoading', false),
        reFetch: _.get(action, 'reFetch', false),
      }
    default:
      return state;
  }
}
