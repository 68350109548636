import _ from 'lodash';
import { isMultipleAddressColumnConfigured } from 'common/config/templateConfiguration';
export const getNearestCoordinates = (coordinates) => {
  if (!_.isEmpty(coordinates) && coordinates.length === 2) {
    const svService = new google.maps.StreetViewService(); // eslint-disable-line
    const panoRequest = {
      location: {lat: coordinates[1], lng: coordinates[0]},
      preference: google.maps.StreetViewPreference.NEAREST, // eslint-disable-line
      radius: 50,
      source: google.maps.StreetViewSource.OUTDOOR // eslint-disable-line
    };
    return new Promise( (resolve) => {
      svService.getPanorama(panoRequest, (panoData, status) => {
        let svCoordinates = [];
        if (status === google.maps.StreetViewStatus.OK) { // eslint-disable-line
          svCoordinates = [ panoData.location.latLng.lng(), panoData.location.latLng.lat() ];
        }
        return resolve(svCoordinates);
      });
    });
  } else {
    return [];
  }
};

const isValidAddress = (address) => {
  return _.toNumber(_.first(_.split(address, ' ', ))) > 0;
}

export const getCoordinatesFromAddress = (address, defaultCoordinates) => {
  if (!_.isEmpty(address) && isValidAddress(address)) {
    const geoCoder = new google.maps.Geocoder(); // eslint-disable-line

    return new Promise( (resolve) => {
      geoCoder.geocode({'address': address}, (results, status) => {
        let svCoordinates = defaultCoordinates;
        if (status == google.maps.GeocoderStatus.OK) { // eslint-disable-line
          svCoordinates = [results[0].geometry.location.lng(), results[0].geometry.location.lat()];
        }
        return resolve(svCoordinates);
      });
    });
  } else {
    return defaultCoordinates;
  }
};

export const formatAddressForGoogleService = (features, templateId) => {
  if (!isMultipleAddressColumnConfigured(templateId)) {
    return _.get(features[0], 'properties.address', '');
  } else {
    let address = [];
    address.push(_.get(features[0], 'properties.house_number'));
    address.push(_.get(features[0], 'properties.street_name'));
    address.push(_.get(features[0], 'properties.city'));
    address.push(_.get(features[0], 'properties.state'));
    address.push(_.get(features[0], 'properties.zip_code'));
    if(!_.includes(address, undefined)) {
      return address.join(',');
    } else {
      return '';
    }
  }
};
