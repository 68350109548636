import _ from 'lodash';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { fetch } from 'whatwg-fetch';
import { removeLocalStorageItem } from 'helpers/localStorageHelper';
import { trackEvent } from 'helpers/eventTracking';
import moment from 'moment';
import { getUserFromBellerophon } from './config/customerConfiguration';
import { KEY_USER_READ_MESSAGE } from 'appConstants';

// use native browser implementation if it supports aborting
const abortableFetch  = ('signal' in new Request('')) ? window.fetch : fetch;
const POLL_INTERVAL = 1;
const POLL_DECAY = 1;
const MAX_POLL_INTERVAL = 20;
const POLL_RETRIES = 50;
let RELOAD_ALERT_COUNT = 0;

const afterXSeconds = (seconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
};
const retryableFetch = (
  arg1, arg2, arg3,
  retries = POLL_RETRIES,
  pollInterval = POLL_INTERVAL
) => {
  return abortableFetch(arg1, arg2, arg3)
    .then(async (response) => {
      if (response.status === 202 || response.status === 429) {
        // if aborted return abort;
        const delayTime = response.status === 202 ?
          _.min([pollInterval * POLL_DECAY, MAX_POLL_INTERVAL]) :
          window.TOO_MANY_REQUESTS_RETRY_DELAY;
        const message = (response.status === 202 ? 'poll delay' : 'too many requests retry delay');

        console.log(message, 'delay', delayTime);
        await afterXSeconds(delayTime);
        // if aborted return abort;
        return await retryableFetch(arg1, arg2, arg3, retries - 1, pollInterval * POLL_DECAY);
      }else if(response.status === 401) {
        if(RELOAD_ALERT_COUNT > 0){
          return;
        }
        if(window.serverConfig.isTestingMode != 'true'){
          RELOAD_ALERT_COUNT += 1;
          // Reload page if session timed out
          alert("Your session has expired. Click ok to reload page")
          removeLocalStorageItem(KEY_USER_READ_MESSAGE);
          window.location.reload();
        }
      }else {
        const userFromBellerophon = getUserFromBellerophon();
        const userEmail = _.get(userFromBellerophon, 'userDetails.user.email', '');
        trackEvent('User Last Visit', {
          email: userEmail,
          time: moment().format('DD/MM/YYYY')
        })
        return response;
      }
    });
}

const fetchCache = {};

(arg1, arg2, arg3) => {
  // Problem:
  // `componentWillMount` making an api call /api?foo=bar.
  // immediately `componentWillUpdate` is making the same api call /api?foo=bar and also cancelling
  // the previous call.
  // The first and the second api call will return the same promise. But it will be cancelled since the
  // second guy is cancelling it.
  const cacheKey = `${JSON.stringify(arg1)}-${JSON.stringify(arg2)}-${JSON.stringify(arg1)}`;

  if (fetchCache[cacheKey]) {
    console.log('Local Cache Hit', arg1);
    return fetchCache[cacheKey];
  }

  const fetchPromise = abortableFetch(arg1, arg2, arg3)//.
    // then((response) => response).
    // catch((err) => {
    //   fetchCache[cacheKey] = null;
    //   return Promise.reject(err);
    // });

  fetchCache[cacheKey] = fetchPromise;
  return fetchPromise;
};

export default retryableFetch;
