import _ from 'lodash';
import {
  SET_CURRENT_COLLECTION_ID,
  SET_DEFAULT_TEMPLATE_ENTRY,
  SET_BELLEROPHON_COLLECTION_TAG_ID,
  SET_OVERWRITE_COLLECTION_FILTER,
  SET_MIN_DATES_FOR_TEMPLATES,
  SET_MIN_DATES_LOADING_STATUS,
  SET_IS_MANAGE_COLLECTION,
  SET_MANAGE_COLLECTION_DETAILS,
  SET_CURRENT_CARD_ID,
  SET_ANALYSIS_PAGE_COLLECTION_ID,
  SET_ANALYSIS_PAGE_COLLECTION_TAG_ID,
  SET_IS_MANAGE_METRIC_LIBRARY,
  SET_SELECTED_VIEW
} from 'actions/dashboardActions';

export const dashboardReducerDefaultState = (collectionId) => {
  return {
    currentCollectionId: collectionId,
    // Based on this key we are displaying the analysis page metric selection.
    // TOOD: Feature my view story we need to remove this key use exiting currentCollectionId.
    analysisPageCollectionId: collectionId,
    defaultTemplateId: null,
    defaultViewId: null,
    currentCollectionTagId: null,
    isOverwriteCollectionFilter: false,
    minDatesForTemplateEntries: [],
    isManageCollection: false,
    manageCollection: {},
    currentCardId: null,
    isManageMetricLibrary: false,
    isMinDateResultsLoading: false,
    selectedView: '',
  };
};

export default function (state = dashboardReducerDefaultState(), action) {
  switch (action.type) {
    case SET_CURRENT_COLLECTION_ID:
      return {
        ...state,
        currentCollectionId: _.get(action, 'collectionId'),
        analysisPageCollectionId: _.get(action, 'collectionId'),
      }
    case SET_ANALYSIS_PAGE_COLLECTION_ID:
      return {
        ...state,
        analysisPageCollectionId: _.get(action, 'collectionId'),
      }
    case SET_SELECTED_VIEW:
      return {
        ...state,
        selectedView: _.get(action, 'selectedView'),
      }
    case SET_CURRENT_CARD_ID:
      return {
        ...state,
        currentCardId: _.get(action, 'cardId')
      }
    case SET_IS_MANAGE_COLLECTION:
      return {
        ...state,
        isManageCollection: _.get(action, 'isManageCollection')
      }
    case SET_MANAGE_COLLECTION_DETAILS:
      return {
        ...state,
        manageCollection: _.get(action, 'collection')
      }
    case SET_MIN_DATES_FOR_TEMPLATES:
      return {
        ...state,
        minDatesForTemplateEntries: _.get(action, 'minDates')
      }
    case SET_MIN_DATES_LOADING_STATUS:
      return {
        ...state,
        isMinDateResultsLoading: _.get(action, 'isMinDateResultsLoading')
      }
    case SET_BELLEROPHON_COLLECTION_TAG_ID:
      return {
        ...state,
        currentCollectionTagId: _.get(action, 'collectionTagId'),
        analysisPageCollectionTagId: _.get(action, 'collectionTagId'),
      }
    case SET_ANALYSIS_PAGE_COLLECTION_TAG_ID:
      return {
        ...state,
        analysisPageCollectionTagId: _.get(action, 'collectionTagId'),
      }
    case SET_OVERWRITE_COLLECTION_FILTER:
      return {
        ...state,
        isOverwriteCollectionFilter: _.get(action, 'isOverwriteFilter')
      }
    case SET_IS_MANAGE_METRIC_LIBRARY:
      return {
        ...state,
        isManageMetricLibrary: _.get(action, 'isManageMetricLibrary')
      };

    case SET_DEFAULT_TEMPLATE_ENTRY:
      const { viewEntry, templateEntry } = _.get(action, 'templateEntry', {})
      const defaultTemplateId = _.get(templateEntry, 'template_id');
      const defaultViewId = _.get(viewEntry, 'view_id');
      return {
        ...state,
        defaultTemplateId,
        defaultViewId
      }
    default:
      return state;
  }
}
