import _ from 'lodash';
import StreetMap from 'images/Map.png';
import HeatMap from 'images/HeatMap.png';
import ChoroplethMap from 'images/ChoroplethMap.png';
import { getMapTypes } from 'common/config/visualizationConfiguration';

import { getCurrentTemplateEntry,
  getMapStyleEntries,
  getShapeOverlayDatasetEntries
} from 'common/config/templateConfiguration';
import { getDefaultVisualizationChartView } from 'helpers/visualizationHelper';
import { VISUALIZATION_TYPES,
  MINIMUM_MAP_RESIZE_HEIGHT
} from 'appConstants';

export const getMapViews = () => {
  // TODO label from configuration & image from image folder
  return {
    'point': {
      type: 'point',
      name: 'Point Map',
      image: StreetMap,
      icon: 'icons-map-point'
    },
    'heat': {
      type: 'heat',
      name: 'Heat Map',
      image: HeatMap,
      icon: 'icons-heatmap'
    },
    'choropleth': {
      type: 'choropleth',
      name: 'Choropleth Map',
      image: ChoroplethMap,
      icon: 'icons-map-cloropleth'
    }
  };
};

export const getDefaultShapeOverlayEntries = (currentTemplateId) => {
  return _.filter(getShapeOverlayDatasetEntries(currentTemplateId), (entry) => {
    return _.toString(_.get(entry, 'show_by_default')) === 'true'; });
};

export const isLocationsConfigured = (currentTemplateId) => {
  const templateEntry = getCurrentTemplateEntry(currentTemplateId);

  return !_.isEmpty(_.get(templateEntry, 'fields.location', ''));
};

export const isChoroplethMap = (currentMapView) => {
  return _.get(currentMapView, 'type') === getMapViews().choropleth.type;
};

export const isPointMapView = (currentMapView) => {
  return _.get(currentMapView, 'type') === getMapViews().point.type;
};

export const isHeatMap = (currentMapView) => {
  return _.get(currentMapView, 'type') === getMapViews().heat.type;
};

export const getSelectedMapStyleEntry = (templateId, currentMapStyleName) => {
  const mapStyleEntries = getMapStyleEntries(templateId);
  const selectedMapStyleEntry =  _.find(mapStyleEntries, {'name': currentMapStyleName});
  return _.isEmpty(selectedMapStyleEntry) ? _.first(mapStyleEntries) : selectedMapStyleEntry;
}

export const getSelectedMapViewEntry = (selectedViewType) => {
  return _.find(getMapViews(), {'type': selectedViewType});
}

export const getSelectedOutlineEntries = (currentTemplateId, selectedOutlinesDatasetId) => {
  if(_.isUndefined(selectedOutlinesDatasetId)){
    return [];
  }
  const selectedOutlines = selectedOutlinesDatasetId.split(',');
  return _.map(selectedOutlines, (outlineId) => {
    return _.find(getShapeOverlayDatasetEntries(currentTemplateId), {'shape_outline_dataset_id': outlineId});
  });
}

export const getAvailableMapViews = (viewEntry) => {
  const mapTypes = getMapTypes(viewEntry);
  return _.map(mapTypes, (mapType) => {
    return _.find(getMapViews(), { type: mapType.value });
  });
}

export const getMapViewFromViewEntry = (viewEntry, currentMapView) => {
  const availableMapViews = _.map(getAvailableMapViews(viewEntry), 'type');
  const defaultMapView = getDefaultVisualizationChartView(viewEntry, VISUALIZATION_TYPES.MAP.type);

  if (_.includes(availableMapViews, currentMapView.type)) {
    return currentMapView;
  } else {
    return _.get(getMapViews(), defaultMapView);
  }
}

export const getMinimumMapResizeHeight = () => {
  let minimumHeight = MINIMUM_MAP_RESIZE_HEIGHT;
  const choroplethMapLegendDiv = document.getElementsByClassName('legend-wrapper')[0];
  if(choroplethMapLegendDiv){
    minimumHeight = document.getElementsByClassName('legend-wrapper')[0].offsetHeight
      + MINIMUM_MAP_RESIZE_HEIGHT;
  }
  return minimumHeight;
}

export const calculateMapContainerHeight = (mapContainerHeight, descriptionRef) => {
  let containerHeight = `${mapContainerHeight}px`;
  if(descriptionRef?.current) {
    const height = descriptionRef.current.getBoundingClientRect().height;
    containerHeight = `${mapContainerHeight + height + 23}px`;
  }
  return containerHeight;
}
