import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as commonPropTypes from 'common/propTypes';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getThirdPartyWarningStatus } from 'common/config/templateConfiguration';
import ExportImage from './ExportImage';

import { ForgeCard, ForgeCheckbox, ForgeButton } from '@tylertech/forge-react';

import {
  DOWNLOAD_TABLE_WARNING_MESSAGE,
  THIRD_PARTY_DATA_WARNING_MESSAGE,
  WITH_OUT_SUMMARY_TABLE_CHARTS
} from 'appConstants';
class ExportMultipleModal extends Component {
  constructor(props, context) {
    super(props, context);
    const { plotlyChart } = props;
    const chartContainer = plotlyChart();
    const titleText = chartContainer.querySelector('.viz-title').innerText;

    this.state = {
      isLoading: false,
      withFooterNote: true,
      withTitle: true,
      withSummaryTable: false,
      withSummaryCsv: false,
      withTableRawDataCsv: false,
      withChartImage: false,
      withFullPageImage: false,
      showThirdPartyWarning: false,
      titleText
    };
    this._exportImage = new ExportImage();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { showExportModal, onClose } = this.props;
    if (this.exportModalRef && !this.exportModalRef.contains(e.target)) {
      if (showExportModal) {
        onClose(false);
      }
    }
  }


  handleChangeTitleOption = () => {
    const { withTitle } = this.state;
    this.setState({ withTitle: !withTitle });
  }

  handleChangeFooterNoteOption = () => {
    const { withFooterNote } = this.state;
    this.setState({ withFooterNote: !withFooterNote });
  }

  handleChangeSummaryTableOption = () => {
    const { withSummaryTable } = this.state;
    this.setState({ withSummaryTable: !withSummaryTable });
  }

  handleChangeRawDataCsvOption = () => {
    const { withTableRawDataCsv } = this.state;
    this.setState({ withTableRawDataCsv: !withTableRawDataCsv });
  }

  handleChangeChartImageOption = () => {
    const { withChartImage } = this.state;
    this.setState({ withChartImage: !withChartImage });
  }

  handleChangeSummaryCsvOption = () => {
    const { withSummaryCsv } = this.state;
    this.setState({ withSummaryCsv: !withSummaryCsv });
  }

  handleChangeFullPageOption = () => {
    const { withFullPageImage } = this.state;
    this.setState({ withFullPageImage: !withFullPageImage });
  }

  onCompleteExportImage = () => {
    this.setState({ isLoading: false });
  }

  handleClickExportButton = async () => {
    const newProps = { ...this.props, onCompleteExportImage: this.onCompleteExportImage };

    try {
      this.setState({ isLoading: true });
      await this._exportImage.exportImageBasedOnOptions(newProps, this.state);
    } catch (error) {
      console.error('Export multiple image failed', error);
      this.onCompleteExportImage();
    }

    this.props.onClose(false);
  }

  handleChangeTitleText = (event) => {
    this.setState({ titleText: event.target.value });
  }

  handleKeyDown = (e, triggerEventFunction) => {
    if (isEnterButtonPressed(e)) {
      triggerEventFunction()
    }
  }

  renderChartImageSection() {
    const { withChartImage } = this.state;

    return (
      <>
        <div
          tabIndex={0}
          className="custom-export-modal"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeChartImageOption)}>
          <ForgeCheckbox>

            <input type="checkbox"
              tabIndex={-1}
              id="withPrePostChartImage"
              checked={withChartImage}
              onChange={this.handleChangeChartImageOption}
            />
          </ForgeCheckbox>

          <label
            className="forge-typography--body1 custom-export-label"
            htmlFor="withPrePostChartImage">
            JPG of chart
          </label>
        </div>
      </>
    );
  }

  renderSummaryTableCsvSection() {
    const { withSummaryCsv } = this.state;
    const { shouldHideSummaryTableSection, currentVisualizationType } = this.props;

    if (shouldHideSummaryTableSection ||
      _.includes(WITH_OUT_SUMMARY_TABLE_CHARTS, currentVisualizationType)) {
      return null;
    }

    return (
      <>
        <div
          tabIndex={0}
          className="custom-export-modal"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeSummaryCsvOption)}>
          <ForgeCheckbox>
            <input
              type="checkbox"
              tabIndex={-1}
              id="withSummaryCsv"
              checked={withSummaryCsv}
              onChange={this.handleChangeSummaryCsvOption}
            />
          </ForgeCheckbox>
          <label
            className="forge-typography--body1 custom-export-label"
            htmlFor="withSummaryCsv">
            CSV of summary table
          </label>
        </div>
      </>
    );
  }

  renderSummaryTableSection() {
    const { withSummaryTable } = this.state;
    const { shouldHideSummaryTableSection, currentVisualizationType } = this.props;

    if (shouldHideSummaryTableSection ||
      _.includes(WITH_OUT_SUMMARY_TABLE_CHARTS, currentVisualizationType)) {
      return null;
    }

    return (
      <div
        tabIndex={0}
        className="custom-export-modal"
        onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeSummaryTableOption)}>
        <ForgeCheckbox>
          <input
            type="checkbox"
            tabIndex={-1}
            id="withSummaryTable"
            checked={withSummaryTable}
            onChange={this.handleChangeSummaryTableOption}
          />

        </ForgeCheckbox>
        <label
          className="forge-typography--body1 custom-export-label"
          htmlFor="withSummaryTable">
          JPG of summary table
        </label>
      </div>
    );
  }

  renderFullPageImageSection() {
    const { currentVisualizationType } = this.props;
    const { withFullPageImage } = this.state;
    if (_.includes(WITH_OUT_SUMMARY_TABLE_CHARTS, currentVisualizationType)) {
      return null;
    }

    return (
      <>
        <div
          tabIndex={0}
          className="custom-export-modal"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeFullPageOption)}>
          <ForgeCheckbox>
            <input type="checkbox"
              tabIndex={-1}
              id="withFullPageImage"
              checked={withFullPageImage}
              onChange={this.handleChangeFullPageOption}

            />
          </ForgeCheckbox>
          <label
            className="forge-typography--body1 custom-export-label"
            htmlFor="withFullPageImage">
            JPG of entire page
          </label>
        </div>
      </>
    );
  }

  renderTableRawCsvDownloadSection() {
    const { withTableRawDataCsv } = this.state;
    const { isExportWarning, currentDrilldownTemplateId, shouldHideCsvRawDataDownload } = this.props;
    const isThirdPartyWarning = getThirdPartyWarningStatus(currentDrilldownTemplateId);

    if (shouldHideCsvRawDataDownload) {
      return null;
    }

    return (
      <>
        <div
          tabIndex={0}
          className="custom-export-modal"
          onKeyDown={(e) => this.handleKeyDown(e, this.handleChangeRawDataCsvOption)}>
          <ForgeCheckbox>
            <input
              type="checkbox"
              tabIndex={-1}
              id="withTableRawDataCsv"
              checked={withTableRawDataCsv}
              onChange={this.handleChangeRawDataCsvOption}
            />
          </ForgeCheckbox>
          <label
            className="forge-typography--body1 custom-export-label"
            htmlFor="withTableRawDataCsv">
            CSV of raw data
          </label>
        </div>
        {isExportWarning &&
          <div className="csv-warning-label mb-2 mt-1">
            <i className='icons icons-alert-circled text-danger'></i>
            <div>
              <div className='csv-title'>Large dataset warning</div>
              <p>{DOWNLOAD_TABLE_WARNING_MESSAGE}</p>
            </div>
          </div>
        }
        {isThirdPartyWarning &&
          <div className="csv-warning-label">
            <i className='icons icons-alert-circled text-danger'></i>
            <div>
              <div className='csv-title'>Third-Party Data warning</div>
              <p>{THIRD_PARTY_DATA_WARNING_MESSAGE}</p>
            </div>
          </div>
        }
      </>
    );
  }

  isEnableExport = () => {
    const {
      withFullPageImage,
      withChartImage,
      withSummaryTable
    } = this.state;
    return (withFullPageImage || withChartImage || withSummaryTable);
  }

  renderExportFooter() {
    const { onClose } = this.props;
    const { withSummaryCsv, withTableRawDataCsv } = this.state;
    const isDisabled = !(withSummaryCsv || withTableRawDataCsv || this.isEnableExport());

    return (
      <>
        <ForgeButton type="flat">

          <button
            tabIndex={0}
            className="btn btn-link mr-auto"
            onClick={onClose}>
            Cancel
          </button>
        </ForgeButton>
        <ForgeButton type="raised" className="ml-auto">

          <button
            disabled={isDisabled}
            tabIndex={0}
            className="btn btn-primary px-7"
            onClick={this.handleClickExportButton}>
            Export
          </button>
        </ForgeButton>
      </>
    );
  }

  renderLoadingSpinner() {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="spinner-overlay">
          <Spinner variant="primary" className="loading-spinner" animation="border" />
        </div>
      );
    }
  }

  render() {
    return (
      <ForgeCard className="export-multimodal-forge">
        <div className="bookmark-dropdown-wrapper dropdown-menu show h-auto"
          ref={(ref) => this.exportModalRef = ref}>
          {this.renderLoadingSpinner()}
          <h6 className='forge-typography--subtitle1-secondary mb-3'> Export other</h6>
          <span className="tx-14 forge-typography--body2">
            Select which option(s) you would like to export.
          </span>
          <div className="d-flex flex-column gap-10 my-3">
            {this.renderTableRawCsvDownloadSection()}
            {this.renderSummaryTableCsvSection()}
            {this.renderChartImageSection()}
            {this.renderSummaryTableSection()}
            {this.renderFullPageImageSection()}
          </div>
          <div className="dropdown-footer">
            {this.renderExportFooter()}
          </div>
        </div>
      </ForgeCard>
    );
  }
}

ExportMultipleModal.propTypes = {
  onClose: commonPropTypes.onClosePropTypes,
  plotlyChart: PropTypes.func,
  currentVisualizationType: PropTypes.string,
  currentMaps: PropTypes.array || [],
  currentSnapshotView: PropTypes.string,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  visualization: PropTypes.object,
  plotlyChartData: PropTypes.object,
  csvDownloadCallBack: PropTypes.func,
  apiParams: PropTypes.object,
  isExportWarning: PropTypes.bool,
  shouldHideSummaryTableSection: PropTypes.bool,
  shouldHideCsvRawDataDownload: PropTypes.bool,
  csvSummaryTableDownloadCallBack: PropTypes.func,
  showExportModal: PropTypes.bool
}

const mapDispatchToProps = {
};

function mapStateToProps(state) {
  return {
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView', ''),
    visualization: _.get(state, 'visualization', ''),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportMultipleModal);
