import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import FrequencySettings from 'common/components/FrequencySettings/FrequencySettings';
import FrequencySettingOption from 'common/components/FrequencySettings/FrequencySettingOption';
import Monitors from './Monitors';

import { getBenchMarkEntries } from 'helpers/visualizationHelper';
import { getFormattedNumberValue, getCurrentDrilldownDimensionColumn } from 'helpers/chartDataHelper';
import { getBenchMarkName } from './helper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import {
  DEFAULT_DROPDOWN_OPTION,
  EMAIL_STRATEGY_OPTIONS,
  EMAIL_STRATEGY_TYPES,
  NEW_CREATED_RECORD_OPTIONS,
  DEFAULT_BOOKMARK_DIMENSION_OPTION
} from 'appConstants';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import CheckBox from 'common/components/CheckBox';
import EmailArrivalText from 'common/components/FrequencySettings/EmailArrivalText';

class BookmarkSettings extends Component {
  constructor(props){
    super(props);
    this.state = {
      isCheckedForDimension: !this.isAllDimensionOption(),
      dimensionOption: _.get(props, 'dimensionOption')
    };
  }

  componentDidUpdate(prevProps) {
    const { dimensionOption } = this.props;
    const isDimensionOptionChanged = !_.isEqual(prevProps.dimensionOption, dimensionOption);

    if(isDimensionOptionChanged) {
      this.setState({
        isCheckedForDimension: !this.isAllDimensionOption(dimensionOption),
        dimensionOption
      });
    }
  }

  isAllDimensionOption = (dimensionOption) => {
    return (dimensionOption || _.get(this.props, 'dimensionOption')) === DEFAULT_BOOKMARK_DIMENSION_OPTION;
  }

  isAnyOneOfTheMonitorIsEnabled = (conditions) => {
    return _.some(conditions, {enable: true});
  }

  // TODO: remove this function after discussion.
  handleKeyDown = (e) => {
    const { onToggleSendEmailUpdates, sendEmailUpdates } = this.props
    if(isEnterButtonPressed(e)) {
      onToggleSendEmailUpdates(!sendEmailUpdates);
    }
  }

  handleToggleIncludeImage = (e, includeImage) => {
    if(isEnterButtonPressed(e)) {
      this.props.onIncludeImageChange(includeImage)
    }
  }

  onHandleDimensionTypeChange = (dimensionOption) => {
    const { isCheckedForDimension } = this.state;
    const newDimensionOption = _.get(dimensionOption, 'type', DEFAULT_BOOKMARK_DIMENSION_OPTION)
    this.setState({ dimensionOption: newDimensionOption });

    if(isCheckedForDimension) {
      this.props.onHandleDimensionTypeChange(dimensionOption);
    }
  }

  onCheckBoxChange = (isChecked) => {
    const { defaultDimension } = this.props;

    this.setState({ isCheckedForDimension: isChecked }, () => {
      if(isChecked) {
        this.props.onHandleDimensionTypeChange({ type: defaultDimension });
      } else {
        this.props.onHandleDimensionTypeChange({
          'type': DEFAULT_BOOKMARK_DIMENSION_OPTION, name: 'all selected data'
        });
      }
    })
  }

  renderForDimensionCheckbox = () => {
    const { isCheckedForDimension } = this.state;
    const { emailStrategy } = this.props;

    if(emailStrategy != EMAIL_STRATEGY_TYPES.THRESHOLD ){
      return null;
    }

    return (
      <div className='d-flex mt-2'>
        <CheckBox
          id="for-dimension-checkbox"
          index="0"
          isChecked={isCheckedForDimension}
          name="for any"
          onChange={(isChecked) => this.onCheckBoxChange(isChecked)}
        />
        <div className='pl-1 mt-2'>
          {this.renderDimensionOptions()}
        </div>
      </div>
    );
  }

  renderSentEmailDropDown() {
    return(
      <>
        <div className="d-flex align-items-center flex-wrap">
          <p className="mb-0">Send email updates</p>
          {this.renderEmailStrategyOptions()}
        </div>
        {this.renderForDimensionCheckbox()}
      </>
    );
  }

  renderBenchMarkText() {
    const { drilldown, currentVizChartType, visualization, currentDrilldownTemplateId } = this.props;
    const { currentDrilldownViewEntry, currentVisualizationType, currentDrilldownDimensionField } = drilldown;
    const benchMarkName = getBenchMarkName(drilldown, visualization);
    let benchMarkText = '';
    const configuredBenchMarkEntries = getBenchMarkEntries(
      currentDrilldownViewEntry, currentVisualizationType, currentVizChartType
    );
    const dimensionColumn = getCurrentDrilldownDimensionColumn(
      currentDrilldownTemplateId, currentDrilldownDimensionField
    );
    const currentDimensionBenchMarkEntries = _.filter(configuredBenchMarkEntries, (entry) => {
      return (entry.dimension_column === dimensionColumn || _.isEmpty(entry.dimension_column))
    });

    if(_.isEmpty(currentDimensionBenchMarkEntries)) {
      benchMarkText = '';
    }
    else if(_.isEmpty(benchMarkName) || _.isEqual(DEFAULT_DROPDOWN_OPTION.name, benchMarkName)) {
      benchMarkText = 'No benchmark is set.'
    }else {
      benchMarkText = `The benchmark is ${benchMarkName}.`
    }

    return <>{benchMarkText}</>;
  }

  renderFrequnceSetting() {
    const {
      emailStrategy, emailOptions, onSettingValueChange, handleWeekdaysOptionsChange, isDisabled
    } = this.props;

    if (emailStrategy === EMAIL_STRATEGY_TYPES.ON_SCHEDULE) {
      return (
        <div className="d-flex">
          <div className="mr-3 mt-2">
            Send an email every
          </div>
          <div className='d-flex flex-column gap-15'>
            <FrequencySettings {...emailOptions}
              onSettingValueChange={onSettingValueChange} isDisabled={isDisabled}/>
            <FrequencySettingOption
              {...emailOptions}
              onSettingValueChange={onSettingValueChange}
              isDisabled={isDisabled}
              handleWeekdaysOptionsChange={handleWeekdaysOptionsChange}/>
            <EmailArrivalText {...emailOptions}/>
          </div>
        </div>
      );
    }

    return null;
  }

  renderTotalRevenue() {
    const { drilldown, currentDimensionTotal} = this.props;
    const { currentDrilldownViewEntry } = drilldown;
    const metricName = getPrimaryMetricName(currentDrilldownViewEntry);
    const total = getFormattedNumberValue(currentDimensionTotal, currentDrilldownViewEntry, true);

    return(
      <div className="notify-text text-center font-italic">
        The total of {metricName} is currently {total}. {this.renderBenchMarkText()}
      </div>
    )
  }

  renderIncludeImage = () => {
    const { includeImage, onIncludeImageChange, isDisabled } = this.props;

    return(
      <div
        className="custom-control custom-checkbox mt-8"
        tabIndex="0"
        onKeyDown={(e) => this.handleToggleIncludeImage(e, !includeImage)}>
        <input
          type="checkbox"
          tabIndex="-1"
          checked={includeImage}
          disabled={isDisabled}
          className="custom-control-input"
          onChange={(e) => onIncludeImageChange(e.target.checked)}
          id="include-images" />
        <label className="custom-control-label font-weight-bold" htmlFor="include-images">
          Include images
        </label>
      </div>
    );
  }

  renderMonitors() {
    const {
      currentVizChartType,
      drilldown,
      monitorConditions,
      visualization,
      onHandleMonitorConditionChange
    } = this.props;

    return(
      <Monitors
        {...{
          currentVizChartType,
          drilldown,
          monitorConditions,
          visualization
        }}
        onMonitorConditionsChange={onHandleMonitorConditionChange}
      />
    )
  }

  renderDimensionOptions() {
    const {
      currentUser,
      currentDrilldownTemplateId,
      emailStrategy,
      defaultDimension
    } = this.props;
    const { dimensionOption } = this.state;
    if(emailStrategy != EMAIL_STRATEGY_TYPES.THRESHOLD ){
      return null;
    }

    let dimensionOptions = [];
    const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
    _.each(dimensionsEntries, (entry) => {
      dimensionOptions.push({
        type: entry.field,
        name: entry.name
      })
    });
    const newDimensionOption = this.isAllDimensionOption(dimensionOption) ?
      defaultDimension :
      dimensionOption;
    const currentOption = _.find(dimensionOptions, { type: newDimensionOption });

    return(
      <div className="d-flex dimesion-options ml-1 align-items-center">
        <SolutionDropDown
          className="font-weight-bold ml-1 xl-overflow email-strategy-type"
          size="sm"
          id="dimension"
          title={_.get(currentOption, 'name')}
          options={dimensionOptions}
          disabled={_.isEmpty(currentUser)}
          onSelect={this.onHandleDimensionTypeChange} />
      </div>
    )
  }

  renderEmailStrategyOptions() {
    const { currentUser,
      emailStrategy,
      onHandleEmailStrategyChange,
      isManagePage,
      isDisabled
    } = this.props;
    const isDisableEmailStrategyOption  = _.isEmpty(currentUser) || isDisabled;
    const currentEmailStrategy = _.find(EMAIL_STRATEGY_OPTIONS, {type: emailStrategy})
    let emailStrategyOptions = EMAIL_STRATEGY_OPTIONS;
    if(isManagePage) {
      emailStrategyOptions = _.filter(EMAIL_STRATEGY_OPTIONS, (option) => {
        return (option.type !== EMAIL_STRATEGY_TYPES.NEVER);
      });
    }

    return(
      <SolutionDropDown
        className="font-weight-bold ml-1 xl-overflow email-strategy-type"
        size="sm"
        id="email-strategy"
        title={_.get(currentEmailStrategy, 'name')}
        options={emailStrategyOptions}
        disabled={isDisableEmailStrategyOption}
        onSelect={onHandleEmailStrategyChange} />
    )
  }

  renderNewlyCreatedRecordRemoveButton() {
    const { onSettingValueChange, emailOptions } = this.props;
    const newlyCreatedRecordValue = _.get(emailOptions, 'newlyCreatedRecordValue');

    return newlyCreatedRecordValue && (
      <button className="close remove-new-create-button"
        onClick={() => onSettingValueChange('', 'newlyCreatedRecordValue')}>
        <i className="icons-close"></i>
      </button>
    );
  }

  renderNewlyCreatedRecordDropdown() {
    const { emailStrategy, onSettingValueChange, emailOptions } = this.props;
    const newlyCreatedRecordValue = _.get(emailOptions, 'newlyCreatedRecordValue');
    if (emailStrategy !== EMAIL_STRATEGY_TYPES.RECORDS_MATCH_SELECTED_FILTERS) {
      return null;
    }
    const selectedOption = newlyCreatedRecordValue ? newlyCreatedRecordValue : '+ Add a condition';

    return (
      <div className="ml-space mb-2 mt-2 d-flex">
        <SolutionDropDown
          className="add-condition email-strategy-type"
          size="sm"
          title={selectedOption}
          options={NEW_CREATED_RECORD_OPTIONS}
          onSelect={(option) => onSettingValueChange(option, 'newlyCreatedRecordValue')} />
        {this.renderNewlyCreatedRecordRemoveButton()}
      </div>
    )
  }

  renderMonitorsCondition(){
    const { emailStrategy } = this.props;
    const hideMonitorsConditionOptions = [
      EMAIL_STRATEGY_TYPES.NEVER,
      EMAIL_STRATEGY_TYPES.ON_SCHEDULE,
      EMAIL_STRATEGY_TYPES.RECORDS_MATCH_SELECTED_FILTERS
    ];
    if (_.includes(hideMonitorsConditionOptions, emailStrategy)) {
      return null;
    }

    return (
      <div className="ml-space mb-2 mt-2">
        {this.renderMonitors()}
      </div>
    );
  }

  render() {
    const { currentTab} = this.props;

    if( currentTab != 'bookmarkSettings') {
      return;
    }
    return(
      <>
        <div className="bookmark-monitor sent-email-block">
          <div>
            <h2><b>Email updates</b></h2>
            <p className="my-5">Choose the frequency of emails you will receive.
              You can set up your emails to send on a schedule or when a threshold or condition is met.
            </p>
            {this.renderSentEmailDropDown()}
            {this.renderFrequnceSetting()}
            {this.renderMonitorsCondition()}
            {this.renderNewlyCreatedRecordDropdown()}
            {this.renderTotalRevenue()}
            {this.renderIncludeImage()}
          </div>
        </div>
      </>
    );
  }
}

BookmarkSettings.propTypes = {
  currentUser: PropTypes.object,
  drilldown: PropTypes.object,
  currentVizChartType: PropTypes.string,
  currentTab: PropTypes.string,
  emailStrategy: PropTypes.string,
  sendEmailUpdates: PropTypes.bool,
  currentDimensionTotal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onToggleSendEmailUpdates: PropTypes.func,
  onHandleMonitorConditionChange: PropTypes.func,
  onHandleEmailStrategyChange:PropTypes.func,
  onSettingValueChange:PropTypes.func,
  handleWeekdaysOptionsChange:PropTypes.func,
  onIncludeImageChange:PropTypes.func,
  emailOptions: PropTypes.object,
  includeImage: PropTypes.bool,
  monitorConditions: PropTypes.array,
  visualization: PropTypes.object,
  currentDrilldownTemplateId: PropTypes.number,
  onHandleDimensionTypeChange:PropTypes.func,
  dimensionOption: PropTypes.string,
  isManagePage: PropTypes.bool,
  defaultDimension: PropTypes.string,
  isDisabled: PropTypes.bool,
}

BookmarkSettings.defaultProps = {
  isManagePage: false
}
export default BookmarkSettings;
