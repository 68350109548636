import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ForgeIcon, ForgeIconButton, ForgeMenu } from '@tylertech/forge-react';

import { addhttp } from 'helpers/HttpHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';
import ResizableFont from './ResizableFont';

const ExplorationCard = (props) => {
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const { explorationEntry, userCardEntry, keyId, showDelete, onCardDelete } = props;
  const cardTitleRef = useRef();

  const openNewPage =(link) => {
    if(link){
      const linkText = addhttp(link)
      window.open(`${linkText}`,"_blank");
    }
  }

  const onMenuIconClick = () => {
    setIsDeleteClicked(true);
  }

  const handleKeyDown = (e, link) => {
    if(isEnterButtonPressed(e)){
      openNewPage(link);
    }
  }

  const handleCardDelete = () => {
    onCardDelete(userCardEntry);
  }
  const handleClickMenuItems = () => {
    document.body.click();
  }

  const renderMenus = () => {
    if(!showDelete){
      return null;
    }

    const options = [
      { value: 'delete', label: 'Delete' }
    ];

    return (
      <div className="info-sections align-self-start ml-1">
        <ForgeMenu
          placement='bottom-start'
          options={options}
          on-forge-menu-select={(e) => onMenuIconClick(e)}>
            <ForgeIconButton type="button">
              <button type="button">
              <ForgeIcon name='more_vert' className="more-icon" /></button>
            </ForgeIconButton>
        </ForgeMenu>
      </div>
    );
  }

  const renderContent = () => {
    if(!_.isEmpty(explorationEntry.exploration_content)) {
      return (
        <div className="exploration-footer mt-3 line-clamp text-truncate">
         {explorationEntry.exploration_content}
        </div>
      );
    }else{
      return null;
    }
  }
  const renderImage = () => {
    if(!_.isEmpty(explorationEntry.image)) {
      return (
        <img src={explorationEntry.image} alt="" className="img-exploration" />
      );
    }else{
      return null;
    }
  }

  const renderExploreName = (explorationEntry, keyId) => {
    const uniqueKey = `idText${keyId}`;
    return (
      <div key={uniqueKey} className="exploration-title mb-3">
        <ResizableFont explorationEntry={explorationEntry} cardTitleRef={cardTitleRef} />
      </div>
    )
  } 

  const renderDeleteModel = () => {
    const deleteMessage = `Are you sure you would like to delete exploration card? This cannot be undone.`;
    return ( isDeleteClicked &&
      <DeleteConfirmModal
        title="Delete exploration"
        message={deleteMessage}
        showButtons={false}
        showMenuItems={handleClickMenuItems}
        onDeleteConfirm={handleCardDelete}
        onCancelConfirm={() => setIsDeleteClicked(false)}
      />
    )
  }
  const cardClassNames = classNames({
    'metric-card-visualization': (!_.isEmpty(explorationEntry.image)),
    'metric-card-visualization img-exploration-disabled': (_.isEmpty(explorationEntry.image))
  });
  return (
    <div className="exploration-container position-relative h-100" key={keyId}>
      <div
        role="listitem"
        className="card metric-card exploration h-100"
        aria-label={explorationEntry.name}
        tabIndex={0}>
        <div className="card-body d-flex flex-column forge-popup-host">
          <div className='metric-head-wrapper'>
            <div className="metric-head exploration-header mb-3 text-truncate d-flex">
              {explorationEntry.link && <div
                onClick={() => openNewPage(explorationEntry.link)}
                onKeyDown={(e) => handleKeyDown(e, explorationEntry.link)}>
                <i className="icons-external-link"></i>More Information
              </div>}
            </div>
            {renderMenus()}
          </div>
          <div className={cardClassNames}>
            <div className="exploration-inner d-flex flex-column" ref={cardTitleRef}>
              {renderExploreName(explorationEntry, keyId)}
              {renderImage()}
            </div>
          </div>
          {renderContent()}
          {renderDeleteModel()}
        </div>
      </div>
    </div>
  );
}

ExplorationCard.defaultProps = {
  showDelete: false
}

ExplorationCard.propTypes = {
  explorationEntry: PropTypes.object,
  userCardEntry: PropTypes.object,
  showDelete: PropTypes.bool,
  keyId: PropTypes.string,
  onCardDelete: PropTypes.func
}

export default ExplorationCard;