import React from 'react'
import PropTypes from 'prop-types'
import MenuDropDownSelector from 'common/components/MenuDropDown/MenuDropDownSelector';
import { isSmallForecastingChartSize } from 'helpers/DomPageHelper';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';

function ForecastLegends(props) {
  const {
    legendItems,
    toggleClickLegendButton,
    isOpenLegend,
    fromMobileView,
    onUpdateLegendWidth
  } = props;

  const renderLegendItem = (item, index) => {
    const style = {
      backgroundColor: item.color,
      borderColor: item.color
    };

    const isHistoricalData = item.traceId == 'Historical data';

    return (
      <div className="over-time-legend-item" key={`${item.traceId}-${index}`}>
        {isHistoricalData &&
          <div className="d-flex">
            <div className="fake-check-box mr-3" style={style}></div>
            <div className="trace-name forge-typography--body2">{item.traceId}</div>
          </div>
        }

        {!isHistoricalData &&
          <div className='d-flex'>
            <div className="fake-check-box mr-3" style={style}></div>
            <div className="trace-name forge-typography--body2">{item.traceId}</div>
          </div>
        }
      </div>
    )
  }

  const renderLegendItems = () => {

    return (
      <div className='mt-6 p-2 forecast-legend'>
        {
          _.map(legendItems, (legend, index) => {
            return renderLegendItem(legend, index)
          })
        }
      </div >
    )
  }

  const onButtonClick = () => {
    toggleClickLegendButton(!isOpenLegend);
  }

  const renderButtonMobileView = () => {

    if (fromMobileView) {
      return null
    }

    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          className=""
          onKeyDown={() => onButtonClick()}
          onClick={() => onButtonClick()}>
          <ForgeIcon name="category" />
        </button>
      </ForgeButton>
    );
  }

  const renderMobileViewLegend = () => {

    if (_.isEmpty(legendItems) || !isOpenLegend || !fromMobileView) {
      return null;
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
        isForecastingLegends
        className="forecast-legend-width"
        leadingIconName="category"
        toggleClickLegendButton={toggleClickLegendButton}>
        {renderLegendItems()}
      </MenuDropDownSelector>
    )
  }

  const renderDesktopView = () => {

    if (_.isEmpty(legendItems)) {
      return null;
    }

    if (isSmallForecastingChartSize()) {
      return renderButtonMobileView();
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
        isForecastingLegends
        className="forecast-custom-legend"
        leadingIconName="category"
        toggleClickLegendButton={toggleClickLegendButton} 
        onUpdateLegendWidth={onUpdateLegendWidth} >
        {renderLegendItems()}
      </MenuDropDownSelector>
    );
  }

  const renderLegends = () => {

    if (_.isEmpty(legendItems)) {
      return null;
    }

    return (
      <>
        {isSmallForecastingChartSize() && renderMobileViewLegend()}
        {renderDesktopView()}
      </>
    );
  }

  return (
    <>
      {renderLegends()}
    </>
  )
}

ForecastLegends.propTypes = {
  legendItems: PropTypes.array,
  fromMobileView: PropTypes.bool,
  isOpenLegend: PropTypes.bool,
  toggleClickLegendButton: PropTypes.func,
  onUpdateLegendWidth: PropTypes.func
}

ForecastLegends.defaultPropTypes = {
  fromMobileView: false
}

export default ForecastLegends
