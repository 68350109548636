import React from 'react'
import PropTypes from 'prop-types'

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const ApplyButton = ({ onUpdate, label, disabled }) => {
  const onApply = () => {
    onUpdate();
  }

  const onKeyDownApplyButton = (e) => {
    if(isEnterButtonPressed(e)) {
      onApply();
    }
  }

  return (
    <div className="dropdown-filter-footer m-0">
      <button
        disabled={disabled}
        tabIndex={0}
        aria-label={label}
        onKeyDown={onKeyDownApplyButton}
        className="btn btn-sm btn-primary ml-auto"
        onClick={onApply}
      >
        {label}
      </button>
    </div>
  )
}

ApplyButton.propTypes = {
  onUpdate: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool
}

ApplyButton.defaultProps = {
  label: 'Apply',
  disabled: false
}

export default ApplyButton;
