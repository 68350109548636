import { useEffect, useState } from 'react';
import { getCollaborateUserActivities } from 'common/api/collaborateApi';

export const useFetchCollectionActivities = (collectionId) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const url = getCollaborateUserActivities(collectionId);

  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);

    fetch(url, {signal: controller.signal}).
      then((res) => res.json()).
      then(setData).
      catch(setError).
      finally(() => setLoading(false))

      return () => {
        controller.abort();
      }
  }, [url]);

  return { loading, data, error};
}
