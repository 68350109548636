import React from 'react';
import PropTypes from 'prop-types';

const MapControls = ({ children, mapControlClassName }) => {
  const mapClassNames = `map-controls-wrapper ${mapControlClassName}`;
  return !_.isNil(children) && (
    <div className="map-custom-controls">
      <div className={mapClassNames}>
        {children}
      </div>
    </div>
  );
}

MapControls.defaultProps = {
  children: null
}

MapControls.propTypes = {
  children: PropTypes.any,
  mapControlClassName: PropTypes.string
}

export default MapControls;
