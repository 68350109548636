import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import TipsyRow from './TipsyRow';

import { getColumnTotalAndCategories, canUseSingular } from 'modules/utils/timeOfDayOrDayOfWeekHelper';
import { prefixText, suffixText } from 'helpers/chartDataHelper';

const DEFAULT_CATEGORY_COUNT = 10;

const TimeOfDayTipsyTable = (props) => {
  const { weekOption, dayOption, weekWiseData, viewEntry } = props;
  const { total, categories } = useMemo(() => {
    return getColumnTotalAndCategories(weekOption, dayOption, weekWiseData);
  }, [weekWiseData]);
  let prefix = prefixText({ viewOptions: viewEntry }, false);
  let suffix = suffixText({ viewOptions: viewEntry }, canUseSingular(total)),
  useSingular = false ;
  const totalText = `${prefix}${total}${suffix}`;

  const renderTableRow = (rowItem, index) => (
    useSingular = canUseSingular(_.get(rowItem, 'value')),
    prefix = prefixText({ viewOptions: viewEntry }, false),
    suffix = suffixText({ viewOptions: viewEntry }, useSingular),
    <TipsyRow key={index} {...rowItem} index={index}
      prefixText={prefix}
      suffixText={suffix}
    />
  );

  return (
    <>
      <div className='tipsy-title'><b>{weekOption.long} - {dayOption.long}</b></div>
      <table className='time-of-day-tipsy-table'>
        <tbody>
          {_.map(_.take(categories, DEFAULT_CATEGORY_COUNT), renderTableRow)}
          {_.size(categories) > DEFAULT_CATEGORY_COUNT &&
            <tr>
              <td colSpan={2} className="above-ten-category">Only the top ten results displayed</td>
            </tr>
          }
        </tbody>
      </table>
      <div className='tipsy-total'>Total : <b>{totalText}</b></div>
    </>
  );
}

TimeOfDayTipsyTable.propTypes = {
  weekOption: PropTypes.exact({
    id: PropTypes.string,
    short: PropTypes.string,
    short_x: PropTypes.string,
    medium: PropTypes.string,
    long: PropTypes.string,
    name: PropTypes.string,
    day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  dayOption: PropTypes.exact({
    id: PropTypes.string,
    short: PropTypes.string,
    long: PropTypes.string,
    hour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  weekWiseData: PropTypes.object,
  viewEntry: PropTypes.object
}

export default TimeOfDayTipsyTable
