import React, { Component } from 'react';
import PropTypes from 'prop-types';


import { ForgeTextField } from '@tylertech/forge-react';


class BookmarkTitle extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { name, description, onInputChange } = this.props;

    return (
      <>
        <div className="mb-5">
          <label className="forge-typography--body1">Title</label>
          <ForgeTextField>
            <input type="text" value={name}
              onChange={(e) => onInputChange(e, 'name')} />
          </ForgeTextField>

        </div>

        <div className="mb-5"> 
          <label className="forge-typography--body1">Description</label>
            <ForgeTextField>
              <textarea type="text" aria-label="description"
                value={description} rows="3"
                onChange={(e) => onInputChange(e, 'description')}></textarea>
            </ForgeTextField>
        </div>
      </>
    );
  }
}

BookmarkTitle.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  onHandleNameChange: PropTypes.func,
  onHandleDescriptionChange: PropTypes.func,
  onInputChange: PropTypes.func
}

export default BookmarkTitle;
