import React from 'react';
import PropTypes from 'prop-types';
import { ForgeRadio } from '@tylertech/forge-react';

const RadioButton = (props) => {
  const { className, children, id, isChecked, label, onChange, value, name, disabled } = props;
  const labelClassNames = children ? '' : 'only-label';

  return (
    <div className={className}>
      <div className="date-range-forge-radio">
      <ForgeRadio dense="true">
        <input
          disabled={disabled}
          key={id}
          tabIndex={0}
          type="radio"
          id={id}
          aria-label={id}
          checked={isChecked}
          name={name}
          onChange={() => onChange(value)}
        />
        <label htmlFor={id}></label>
      </ForgeRadio>

        {/* <input
          key={id}
          tabIndex={0}
          type="radio"
          className="custom-control-input"
          id={id}
          aria-label={id}
          checked={isChecked}
          onChange={() => onChange(value)} />
        <label className="custom-control-label" htmlFor={id}></label> */}
      </div>
      <div className="filter-items flex-grow-1">
        <label className={labelClassNames}>{label}</label>
        {children}
      </div>
    </div>
  );
}

RadioButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool
}

RadioButton.defaultProps = {
  className: 'd-flex align-items-center gap-5',
  id: 'radio-button',
  children: null,
  label: '',
  name: 'radioButton',
  disabled: false
}

export default (RadioButton);
