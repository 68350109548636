export const UPDATE_SEARCH_FIELD = 'UPDATE_SEARCH_FIELD';
export const UPDATE_SEARCH_FILTERS = 'UPDATE_SEARCH_FILTERS';
export const UPDATE_SELECTED_SHAPE_IDS = 'UPDATE_SELECTED_SHAPE_IDS';
export const UPDATE_SHAPE_DATASET_ENTRY = 'UPDATE_SHAPE_DATASET_ENTRY';
export const UPDATE_SUBJECT_ROW_IDS = "UPDATE_SUBJECT_ROW_IDS";
export const UPDATE_SEARCH_METHOD = 'UPDATE_SEARCH_METHOD';
export const UPDATE_SEARCH_PAGE = 'UPDATE_SEARCH_PAGE';
export const SELECTED_REPORTS = 'SELECTED_REPORTS';
export const REPORT_PAGE_DATA = 'REPORT_PAGE_DATA';
export const REPORT_PAGE_DISTINCT_DATA = 'REPORT_PAGE_DISTINCT_DATA';
export const SUBJECT_ID = 'SUBJECT_ID';
export const SUBJECT_DATA = 'SUBJECT_DATA';
export const UPDATE_TABLE_SORT_COLUMNS = 'UPDATE_TABLE_SORT_COLUM';
export const UPDATE_SEARCH_PROPERTY = 'UPDATE_SEARCH_PROPERTY';
export const UPDATE_TEMPLATE_ID = 'UPDATE_TEMPLATE_ID';
export const UPDATE_EXPANDED_FILTER_MODE = 'UPDATE_EXPANDED_FILTER_MODE';
export const UPDATE_ADVANCE_SEARCH_OPTIONS = 'UPDATE_ADVANCE_SEARCH_OPTIONS';
export const UPDATE_SEARCH_RESULT_COUNT = 'UPDATE_SEARCH_RESULT_COUNT';
export const UPDATE_IS_COMPARISON_LIST_OPEN = 'UPDATE_IS_COMPARISON_LIST_OPEN';
export const UPDATE_SECONDARY_TABLE_SORT_COLUMNS = 'UPDATE_SECONDARY_TABLE_SORT_COLUMNS';
export const UPDATE_POLYGON_FEATURE = 'UPDATE_POLYGON_FEATURE';
export const UPDATE_IS_DRAWING_ENABLED = 'UPDATE_IS_DRAWING_ENABLED';
export const REMOVE_POLYGON_FILTER = 'REMOVE_POLYGON_FILTER';
export const SUBJECT_DISTINCT_DATA = 'SUBJECT_DISTINCT_DATA';

export const updateSearchField = (searchField) => ({
  type: UPDATE_SEARCH_FIELD,
  searchField
});

export const updateSearchFilters = (filters) => ({
  type: UPDATE_SEARCH_FILTERS,
  filters
});

export const updateShapeDatasetEntry = (shapeDatasetEntry) => ({
  type: UPDATE_SHAPE_DATASET_ENTRY,
  shapeDatasetEntry
});

export const updateSelectedShapeIds = (shapeIds, shapeExtent) => ({
  type: UPDATE_SELECTED_SHAPE_IDS,
  shapeIds,
  shapeExtent
});

export const updateSearchPage = (searchPage) => ({
  type: UPDATE_SEARCH_PAGE,
  searchPage
});

export const updateSelectedReportIds = (selectedReportRowIds) => ({
  type: SELECTED_REPORTS,
  selectedReportRowIds
});

export const updateReportPageData = (reportPageData) => ({
  type: REPORT_PAGE_DATA,
  reportPageData
});

export const updateReportPageDistinctData = (reportPageDistinctData) => ({
  type: REPORT_PAGE_DISTINCT_DATA,
  reportPageDistinctData
});

export const updateSubjectRowId = (subjectRowId) => ({
  type: SUBJECT_ID,
  subjectRowId
});

export const updateSubjectData = (subjectData) => ({
  type: SUBJECT_DATA,
  subjectData
});

export const updateSubjectDistinctData= (subjectDistinctData) => ({
  type: SUBJECT_DISTINCT_DATA,
  subjectDistinctData
});

export const updateSortColumns = (sortColumns) => ({
  type: UPDATE_TABLE_SORT_COLUMNS,
  sortColumns
});

export const updateSearchProperty = (searchProperty) => ({
  type: UPDATE_SEARCH_PROPERTY,
  searchProperty
});

export const updateTemplateId = (templateId) => ({
  type: UPDATE_TEMPLATE_ID,
  templateId
});

export const updateExpandedFilterMode = (isExpandedFilterMode) => ({
  type: UPDATE_EXPANDED_FILTER_MODE,
  isExpandedFilterMode
});

export const updateAdvanceSearchOptions = (advanceSearchOptions) => ({
  type: UPDATE_ADVANCE_SEARCH_OPTIONS,
  advanceSearchOptions
});

export const updateSearchResultCount = (searchResultCount) => ({
  type: UPDATE_SEARCH_RESULT_COUNT,
  searchResultCount
})

export const updateIsComparisonListOpen = (isComparisonListOpen) => ({
  type: UPDATE_IS_COMPARISON_LIST_OPEN,
  isComparisonListOpen
})

export const updateSecondarySortColumns = (secondarySortColumns) => ({
  type: UPDATE_SECONDARY_TABLE_SORT_COLUMNS,
  secondarySortColumns
});

export const updatePolygonGeojson = (polygonsGeojson) => ({
  type: UPDATE_POLYGON_FEATURE,
  featuresGeojson: polygonsGeojson
});

export const updatedIsDrawingEnabled = (isDrawingEnabled) => ({
  type: UPDATE_IS_DRAWING_ENABLED,
  isDrawingEnabled
})

export const removePolygonFilter = () => ({
  type: REMOVE_POLYGON_FILTER
})
