import _ from 'lodash';
import { formatChartTotalText, getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getTargetEntryField } from 'helpers/visualizationHelper';

export const metricTotalFormatter = (data, options) => {
  const { viewEntry } = options;
  const total = _.get(data, 'total');

  const templateTotal = formatChartTotalText(
    total, { viewOptions: viewEntry }, true, false, true);
  let secondaryformattedValues = {};
  const newSecondaryMetrics = getFilterSecondaryMetrics(options);

  if (!_.isEmpty(newSecondaryMetrics)) {
    _.each(newSecondaryMetrics, (secondaryMetricEntry) => {
      const { field } = secondaryMetricEntry;
      const defaultSecondaryTotal = _.get(data, 'secondary_count', '');
      const secondaryTotal = _.get(data, `secondary_${field}`, defaultSecondaryTotal);
      const formattedsecondaryTotal = getFormattedNumberValue(
        secondaryTotal, secondaryMetricEntry, true, false, false, false);
      secondaryformattedValues[`formatted_${field}`] = formattedsecondaryTotal;
      secondaryformattedValues['secondaryTotal'] = secondaryTotal;
    });
  }

  return {
    ...data,
    ...secondaryformattedValues,
    templateTotal,
    formattedTotalValue: getFormattedNumberValue(total, viewEntry, true, false, false)
  };
}

export const getFilterSecondaryMetrics = ({ viewEntry, secondaryMetrics }) => {
  const { targetEntryField } = getTargetEntryField(viewEntry);
  const filteredSecondaryMetrics = _.filter(secondaryMetrics, (secondaryMetricEntry) =>  {
    return !_.isEmpty(secondaryMetricEntry);
  });
  const targetEntryMetric = { field: targetEntryField, isComparePrevious: false, secondaryDateRange: '' };

  return _.isEmpty(filteredSecondaryMetrics) ?
    [targetEntryMetric]:
    _.uniqBy([...filteredSecondaryMetrics, targetEntryMetric], 'field');
}
