import _ from 'lodash';

import {
  SNAPSHOT_VISUALIZATION_TYPES,
  SINGLE_VALUE,
  MORE_THAN_FIFTY_TEXT
} from 'appConstants';

import { getBarDimensionConfigs } from './tickLabelToolTipHelper';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import { isStackTypeChart } from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { updateCardLoadingStatusForImageGenerator } from 'helpers/visualizationHelper';

export const handleOnAfterPlot = (currentContainerRef, options) => {
  const { currentSnapshotView, chartType, viewEntry } = options;
  if (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type) {
    handleBarChartAfterPlot(currentContainerRef, options);
  } else if (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type) {
    if (chartType === "groupChart") {
      handleGroupedScatterChartAfterPlot(currentContainerRef, options)
    } else {
      handleScatterChartAfterPlot(currentContainerRef, viewEntry);
    }
  } else {
    handlePieChartAfterPlot(currentContainerRef)
  }

  updateLoadingStatusPlotlyChart(options);
}

export const setIframeHeight = () => {
  const height = document.getElementsByTagName("html")[0].scrollHeight;
  if (window.parent) {
    window.parent.postMessage(["setHeight", height], "*");
  }
}

const updateLoadingStatusPlotlyChart = (options) => {
  const { viewMode, cardImageId } = options
  if (viewMode == VIEW_MODE.SMALL) {
    updateCardLoadingStatusForImageGenerator(cardImageId, false);
  }
}

// barchart & group by barchart.
const handleBarChartAfterPlot = (currentContainerRef, options) => {
  const { viewEntry, secondaryMetricEntry } = options;
  const yTicks = currentContainerRef.querySelectorAll('.ytick');
  const xAxisTicks = currentContainerRef.querySelectorAll('.xaxislayer-above');
  updateXaxisTickLabelsSuffix(xAxisTicks, viewEntry);
  if (!_.isEmpty(secondaryMetricEntry)) {
    const xAxis2Ticks = currentContainerRef.querySelectorAll('.overaxes-above');
    updateXaxisTickLabelsSuffix(xAxis2Ticks, secondaryMetricEntry, '.x2tick');
  }
  const vizChartContainer = currentContainerRef.querySelector('.visualization-charts')
  const plotPropData = _.get(vizChartContainer, ['plotProps', 'data'], []);
  const withoutBenchMarkData = _.filter(plotPropData, (item) => {
    return _.get(item, ['customdata', 0, 0]) !== 'benchMark'
  })
  const dimensionConfigs = getBarDimensionConfigs(withoutBenchMarkData);
  _.each(yTicks, (yTick, index) => {
    const isNullTick = _.isEmpty(_.trim(yTick.textContent)) || _.isNull(yTick.textContent);
    const customData = _.get(dimensionConfigs, [index, 'customdata'], []);
    if (!_.isEqual(customData[0], { isTitlePlaceholder: true }) && !isNullTick) {
      yTick.tabIndex = 0;
    }
  });

  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  if ((isSecondsFormatSecondary || isSecondsFormat) && !isStackTypeChart(options)) {
    updateTimeDurationBarStatPath(currentContainerRef);
  }

  updateBarText(currentContainerRef);
}

// Update nodevalue == > if XAxsis Seconds duration  
const updateTimeDurationBarStatPath = (currentContainerRef) => {
  const paths = currentContainerRef.querySelectorAll('.visualization-charts .plot .trace.bars .points .point')
  _.each(paths, (path) => {
    if (!_.isEmpty(path.querySelector('path'))) {
      let nodeValue = path.querySelector('path').attributes['d'].nodeValue.replace(/M[-\d]*/, 'M-100');
      path.querySelector('path').attributes['d'].nodeValue = nodeValue;
    }
  })
}

// Update bar text  
const updateBarText = (currentContainerRef) => {
  const multiplePaths = currentContainerRef.querySelectorAll('.visualization-charts .plot .trace.bars')
  _.each(multiplePaths, (multiPath) => {
    const paths = multiPath.querySelectorAll('.points .point')
    _.each(paths, (path) => {
      if (!_.isEmpty(_.get(path, 'textContent', ''))) {
        let nodeValue = _.get(path, 'textContent', '');
        nodeValue = _.isEmpty(nodeValue) ? "" : nodeValue.replaceAll(',', '');
        if (Number(nodeValue) < 0) {
          path.querySelector('text').classList.add('text-9');
        }
      }
    })
  })
}

const updateXaxisTickLabelsSuffix = (xAxisTicks, displayFormatEntry, axis = '.xtick') => {
  const precision = _.get(displayFormatEntry, 'precision', null);
  const pluralSuffix = _.get(displayFormatEntry, 'plural_suffix', '');
  const singularSuffix = _.get(displayFormatEntry, 'singular_suffix', '');
  const xtickNodes = _.get(xAxisTicks, [0]) ? xAxisTicks[0].querySelectorAll(axis) : [];
  _.each(xtickNodes, (tick) => {
    const { textContent } = tick;
    const tickVal = (_.isNull(precision) || precision == 0) ?
      Number(textContent.match(/[\d.+]/g).join('')) :
      Number(textContent.match(/[\d.+]/g).join('')).toFixed(precision);
    if (+(tickVal) === SINGLE_VALUE) {
      const suffix = _.isEmpty(singularSuffix) ? pluralSuffix : singularSuffix;
      tick.querySelector('text').textContent = textContent.replace(pluralSuffix, suffix);
    }
  });
};

// Group By ScatterPlot
const handleGroupedScatterChartAfterPlot = (currentContainerRef, options = {}) => {
  const { showScatterPlotRange, viewEntry } = options;
  const chartRowItems = currentContainerRef.querySelectorAll('.scatterlayer .trace');
  const chartRowItemsLabels = currentContainerRef.querySelectorAll('.ytick');
  const xAxisTicks = currentContainerRef.querySelectorAll('.xaxislayer-above');
  updateXaxisTickLabelsSuffix(xAxisTicks, viewEntry);
  let scatterPoints;
  if (showScatterPlotRange) {
    scatterPoints = chartRowItems[chartRowItemsLabels.length].querySelectorAll('.points .point');
  } else {
    if (chartRowItems[0]) {
      scatterPoints = chartRowItems[0].querySelectorAll('.points .point');
    }
  }
  _.each(scatterPoints, (item, index) => {
    // item.tabIndex = 0;
    item.ariaLabel = _.get(chartRowItemsLabels, [index, 'textContent'], '');
  });

  updateScatterChartAboveLimitGridColor(currentContainerRef);
}

// scatter limit text grid color disable
const updateScatterChartAboveLimitGridColor = (currentContainerRef) => {
  const chartRowItemText = currentContainerRef.querySelectorAll('.scatterlayer .trace .text .textpoint');

  let rowIndex = -1;
  _.forEach(chartRowItemText, (rowItemData) => {

    if (!_.isUndefined(rowItemData)) {
      const chartTextPoint = rowItemData.__data__.tx;
      const isIncludeText = _.includes(chartTextPoint, MORE_THAN_FIFTY_TEXT);
      rowIndex = rowItemData.__data__.y;
      if (isIncludeText) {
        updateGridColorForScatterPlotByIndex(currentContainerRef, rowIndex);
      }

      // added tabIndex
      const chartYGridRowItem = currentContainerRef.querySelectorAll('.gridlayer .y');
      if (!_.isUndefined(chartYGridRowItem[0])) {
        if (!_.isEmpty(chartYGridRowItem[0].childNodes[rowIndex])) {
          chartYGridRowItem[0].childNodes[rowIndex].tabIndex = 0;
        }
      }
    }
  });
}

const updateGridColorForScatterPlotByIndex = (currentContainerRef, rowIndex) => {
  const chartYGridRowItem = currentContainerRef.querySelectorAll('.gridlayer .y');

  if (!_.isUndefined(chartYGridRowItem[0])) {
    if (!_.isEmpty(chartYGridRowItem[0].childNodes[rowIndex])) {
      chartYGridRowItem[0].childNodes[rowIndex].style.strokeOpacity = 0;
    }
  }
}

// scatter plot
const handleScatterChartAfterPlot = (currentContainerRef, viewEntry) => {
  const chartItems = currentContainerRef.querySelectorAll('g .points .point');
  const chartLabelItems = currentContainerRef.querySelectorAll('.ytick');
  const xAxisTicks = currentContainerRef.querySelectorAll('.xaxislayer-above');
  updateXaxisTickLabelsSuffix(xAxisTicks, viewEntry);
  _.each(chartItems, (item, index) => {
    item.tabIndex = 0;
    item.ariaLabel = _.get(chartLabelItems, [index, 'textContent'], '');
  });

  updateScatterChartAboveLimitGridColor(currentContainerRef)
}

// pie chart visualization
const handlePieChartAfterPlot = (currentContainerRef) => {
  const chartItems = currentContainerRef.querySelectorAll(".pielayer .trace .slice");
  _.each(chartItems, (item) => {
    item.tabIndex = 0;
  });
}
