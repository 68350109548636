import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';

import { getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
import { addhttp } from 'helpers/HttpHelper';
import { isAllowToEDP } from 'common/config/customerConfiguration';
import { getApiParams } from 'helpers/apiParamsHelper';
import { getMetricDataQuery } from 'common/api/drilldown';
const GotoEDP = () => {
  const [metricQuery, setMetricQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const drilldown = useSelector(state => _.get(state, 'drilldown', {}));
  const commonFilters = useSelector(state => _.get(state, 'commonFilters', {}));
  const templateId = _.get(drilldown, 'currentDrilldownTemplateId', null);
  const apiParamsOptions = useSelector(state =>
      _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']));

  useEffect(() => {
    fetchQuery();
  }, [drilldown, commonFilters]);

  const fetchQuery = () => {
    const controller = new AbortController();
    controller.abort();
    const apiParams = getApiParams(apiParamsOptions, { signal: controller.signal });
    setIsLoading(true);
    getMetricDataQuery(apiParams)
      .then((response) => response.json())
      .then((response) => {
        setMetricQuery(response.query);
        setIsLoading(false);
    });
  }

  const renderGotoEDPButton = () => {
    if(isLoading || !isAllowToEDP()){
      return null;
    }

    return(
      <ForgeButton type="outlined">
        <button onClick={() => onGotoEDP()}>
          <ForgeIcon slot="end" name="open_in_new"></ForgeIcon>
          Query data
        </button>
      </ForgeButton>
    )
  }

  const onGotoEDP = () => {
    const entry = getCurrentTemplateEntryById(templateId);
    const domain = _.get(entry, 'dataset_domain', '');
    const id = _.get(entry, 'dataset_id', '');
    const url = `${domain}/dataset/Timeline-Ruleset/${id}/explore/query/${metricQuery}/page/code`
    window.open(addhttp(url), '_blank');
  }

  return (
    <div>
      {renderGotoEDPButton()}
    </div>
  );
};


GotoEDP.propTypes = {

};


export default GotoEDP;
