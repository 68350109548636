import React from 'react';
import PropTypes from 'prop-types';

const MetricPercentageChangeSelector = ({ percentage, name }) => {
  const changeClass = (percentage > 0 ? 'icons-arrow-up2' : 'icons-arrow-down2');
  const sign = (percentage > 0 ? '+' : '');
  const isNumber = !_.isNaN(Number(percentage));
  const value = isNumber ? `${percentage.toFixed(2)}%` : percentage;

  return (
    <div className='percent-details'>
      <div className='percent-clip'>
        {sign}{value} <i className={changeClass} />
      </div>
      <div className='metric-name'>{name}</div>
    </div>
  );
}

MetricPercentageChangeSelector.defaultProps = {
  name: ''
}

MetricPercentageChangeSelector.propTypes = {
  percentage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string
}

export default MetricPercentageChangeSelector;
