import _ from 'lodash';
import moment from 'moment';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import { isTraceVisible } from '../vizOptionsHelper';
import { isDimensionsChange } from '../legendHelper';
import { getYearTextByRange } from 'helpers/dateHelper';
import {
  COMPARE_VIEW_STACK_COLORS,
  LINE_CHART_TRACE_COLORS,
  DEFAULT_TOTAL_NAME,
  LINE_CHART_COMPARE_TRACE_COLORS
} from 'appConstants';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';

export const getDimensionsConfigs = (allEntries, vizOptions, entries, shouldShowTotalLine) => {
  const dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', []);
  const templateId = _.get(vizOptions, 'templateId');
  const isDimensionHighToLow = _.get(vizOptions, 'isDimensionHighToLow');
  const isForecastingView = _.get(vizOptions, 'isForecastingView');

  let dimensions = _.map(allEntries, (dataItems, dimension) => {
    return dimension;
  });

  if (isDimensionHighToLow) {
    dimensions = getReOrderDimensions(dimensions, dimensionConfigs, entries, templateId);
  }

  const isAvailableTotalLine = _.some(dimensionConfigs, {dimension: DEFAULT_TOTAL_NAME});
  if(isAvailableTotalLine && shouldShowTotalLine){
    dimensions.unshift(DEFAULT_TOTAL_NAME);
  }

  if (isForecastingView) {
    dimensions = [];
  }

  return getDimensionsTraceConfigs(dimensions, dimensionConfigs, vizOptions);
}

const getReOrderDimensions = (dimensions, dimensionConfigs, entries, templateId) => {
  const sortedDimensions = getDescendingOrderDimensions(entries);
  const nullValueLabel = getNullValueLabel(templateId);

  let newOrderDimensions = [];
  let defaultDimensions = sortedDimensions;
  if (!_.isEmpty(dimensionConfigs) && _.size(dimensionConfigs) > 1) {
    defaultDimensions = _.compact(_.map(dimensionConfigs, (datum) => {
      if (!datum.isTotalLine) {
        return datum.traceId;
      }
    }));
  }

  _.forEach(defaultDimensions, (datum) => {
    const dimensionDatum = _.isEqual(nullValueLabel, datum) ? "" : datum;
    if (_.includes(dimensions, dimensionDatum)) {
      newOrderDimensions.push(datum)
    }
  });

  const totalDimensionConfigs = _.filter(dimensionConfigs, (datum) => {
    return datum['isTotalLine'] == true
  });
  const isTotalDimensionConfig = _.size(dimensionConfigs) == _.size(totalDimensionConfigs);

  if (isTotalDimensionConfig && _.size(totalDimensionConfigs) > 0) {
    return [];
  } else {
    return _.isEmpty(newOrderDimensions) ? dimensions : newOrderDimensions;
  }
}

const getDescendingOrderDimensions = (entries) => {

  let dimensionWiseTotal = {};
  _.forEach(entries, (values, key) => {
    dimensionWiseTotal[key] = getSumOfDimensionValue(values);
  })

  const sortedDimensions = Object.keys(dimensionWiseTotal).sort(function (a, b) {
    return dimensionWiseTotal[a] - dimensionWiseTotal[b]
  }).reverse();

  return sortedDimensions;
}

const getSumOfDimensionValue = (dimensionValues) => {
  let totalValues = _.sum(_.map(dimensionValues, function (entry) {
    if (!_.isNaN(Number(entry.value))) {
      return Number(entry.value);
    }
  }));

  return totalValues;
}

export const getTraceId = (dimension, vizOptions, lineType) => {
  const { dateRange, compareYearRanges, dateRangeMode, renderTimeFrame } = vizOptions;
  let year;
  const compareYearRange = _.first(compareYearRanges);
  if (_.size(compareYearRanges) != 1) {
    return dimension;
  }
  const currentYear = getYearTextByRange(dateRange, dateRangeMode);
  const compareYear = getYearTextByRange(compareYearRange, dateRangeMode);
  if (_.isEqual(compareYear, currentYear) && (lineType == 'current')) {
    return dimension;
  }
  year = (lineType == 'current') ? currentYear : compareYear;
  if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges)) {
    year = '';
  }
  return dimension + ' ' + year;
}

export const addTotalLegend = (formattedData, vizOptions) => {
  const { isComboChart, viewEntry, compareYearRanges, dateRange, templateId } = vizOptions;
  const configKey = isComboChart ? 'comboMetricConfigs' : 'dimensionConfigs';
  const dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', []);
  const primaryMetricName = isComboChart ? getPrimaryMetricName(viewEntry) : DEFAULT_TOTAL_NAME;
  const newVizOptions = { ...vizOptions, [configKey]: _.cloneDeep(dimensionConfigs) };
  const isDimensionsChanged = isDimensionsChange(formattedData, newVizOptions);
  let totalTraceColor = getConfiguredMetricColor(templateId, 'primary');
  let currentTraceId = primaryMetricName;
  let compareTraceId;
  const currentYear = moment(dateRange.endDate).format('YYYY');

  if (_.size(compareYearRanges) == 1 && !isComboChart) {
    currentTraceId = getTraceId(primaryMetricName, vizOptions, 'current');
    compareTraceId = getTraceId(primaryMetricName, vizOptions, 'compare');
  }
  const totalTrace = _.find( formattedData['dimensionConfigs'], { traceId : currentTraceId});
  totalTraceColor = _.get(totalTrace, 'color') || totalTraceColor;
  const traceConfig = {
    traceId: currentTraceId,
    year: currentYear,
    dimensionName: primaryMetricName,
    traceType: 'current',
    isTotalLine: true,
    color: totalTraceColor
  };
  const totalVisible = isDimensionsChanged ? true : isTraceVisible(vizOptions, traceConfig);
  let config = [{
    ...traceConfig,
    visible: totalVisible,
    primaryTrace: true,
    dimension: primaryMetricName,
  }];

  if (!_.isEmpty(compareTraceId)) {
    const compareYearRange = _.first(compareYearRanges);
    const compareYear = moment(compareYearRange.endDate).format('YYYY');
    const comapreTraceConfig = {
      traceId: compareTraceId,
      year: compareYear,
      dimensionName: primaryMetricName,
      traceType: 'compare',
      isTotalLine: true
    };
    const compareTotalVisible = isDimensionsChanged ? true : isTraceVisible(vizOptions, comapreTraceConfig);
    config.push({
      traceId: compareTraceId,
      color: getCompareTraceColor(totalTraceColor),
      visible: compareTotalVisible,
      isTotalLine: true,
      dimension: primaryMetricName,
      year: compareYear
    });
  }
  const newConfig = _.unionBy(config, formattedData[configKey], 'traceId');
  formattedData[configKey] = newConfig;
  return {
    ...formattedData
  }
  // return {
  //   ...formattedData,
  //   [configKey]: _.concat([], config, formattedData[configKey])
  // }
}

export const getCompareTraceColor = (color) => {
  const colorConfig = _.find(LINE_CHART_COMPARE_TRACE_COLORS, { color });
  return _.get(colorConfig, 'lite', color);
}


export const getDimensionsTraceConfigs = (
  dimensions,
  dimensionConfigs,
  vizOptions,
  useLegendConfig = false
) => {
  const {
    renderType,
    dateRange,
    renderTimeFrame,
    compareYearRanges,
    isComboChart,
    templateId
  } = vizOptions;
  const nullValueLabel = getNullValueLabel(templateId);
  const isAreaChart = renderType == 'area';
  const compareYearRange = _.first(compareYearRanges);
  let newDimensionConfigs = _.isEmpty(dimensionConfigs) ? [] : [...dimensionConfigs];
  dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', dimensionConfigs);
  if (!useLegendConfig) {
    newDimensionConfigs = dimensionConfigs;
  }
  let newDimensions = dimensions;
  if (isAreaChart) {
    const totalConfig = _.find(newDimensionConfigs, { isTotalLine: true }) || {};
    if (!_.isEmpty(totalConfig)) {
      // Adding total trace in Area chart dimensions
      // so that we can maintain the trace visible state while switch between overtime charts
      newDimensions = [totalConfig['traceId']].concat(newDimensions);
    }
  }
  if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges)) {
    newDimensions = newDimensions.sort().reverse()
  }

  return _.chain(newDimensions).
    map((dimension, index) => {
      const dimensionName = _.isEmpty(dimension) ? nullValueLabel : dimension;
      const currentYear = moment(dateRange.endDate).format('YYYY');
      let currentTraceId = dimensionName;
      let compareTraceId;
      if (_.size(compareYearRanges) == 1 && !isAreaChart && !isComboChart) {
        currentTraceId = getTraceId(dimensionName, vizOptions, 'current');
        compareTraceId = getTraceId(dimensionName, vizOptions, 'compare');
      }
      const dimensionConfig = _.find(dimensionConfigs, { traceId: currentTraceId }) || {};
      const dimensionConfigByName = _.find(newDimensionConfigs,
        { dimension: dimensionName, primaryTrace: true }) || {};
      let color = _.get(dimensionConfigByName, 'color', LINE_CHART_TRACE_COLORS[index]);
      if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges)) {
        color = LINE_CHART_TRACE_COLORS[index];
      }
      const currentConfig = _.isEmpty(dimensionConfig) ?
        dimensionConfigByName : dimensionConfig;

      let isCurrentYearTraceVisible = (isTraceVisible(vizOptions, {
        traceId: currentTraceId, year: currentYear, dimensionName, dimensionConfigs
      }
      )) || _.get(currentConfig, 'visible', true);

      const areaChartColor = _.get(dimensionConfigByName, 'areaChartColor', COMPARE_VIEW_STACK_COLORS[index]);
      let currentTrace = {
        traceId: currentTraceId,
        color,
        visible: isCurrentYearTraceVisible,
        areaChartColor,
        isTotalLine: _.get(dimensionConfigByName, 'isTotalLine') ||
          _.get(dimensionConfig, 'isTotalLine', false),
        primaryTrace: true,
        year: currentYear,
        dimension: dimensionName
      };
      if (isAreaChart && dimensionConfig['isTotalLine']) {
        currentTrace['hideTrace'] = true;
      }
      let config = [currentTrace];
      if (!_.isEmpty(compareTraceId)) {
        const compareYear = moment(compareYearRange.endDate).format('YYYY');
        const comapreDimensionConfig = _.find(dimensionConfigs, { traceId: compareTraceId }) || {};
        const comapreConfig = _.isEmpty(comapreDimensionConfig) ?
          dimensionConfigByName : comapreDimensionConfig;

        let iscomapreYearTraceVisible = (
          isTraceVisible(vizOptions, {
            traceId: compareTraceId, year: compareYear, dimensionName, dimensionConfigs
          }) ||
          _.get(comapreConfig, 'visible', true)
        );

        let compareTrace = {
          traceId: compareTraceId,
          color: getCompareTraceColor(color),
          visible: iscomapreYearTraceVisible,
          areaChartColor,
          isTotalLine: _.get(dimensionConfig, 'isTotalLine', false),
          year: compareYear,
          dimension: dimensionName
        };
        if (isAreaChart && dimensionConfig['isTotalLine']) {
          // hiding total trace in area chart
          // to maintain Total legend visible state in burnup/timeline chart
          compareTrace['hideTrace'] = true;
        }

        config.push(compareTrace);
      }
      return config;
    }).
    flattenDeep().
    value();
}
