import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { formatTimeOfDayFiltersBasedOnWeek, getHourText } from './helper';
import { TIME_OF_WEEKS, DEFAULT_WEEK_KEY } from 'appConstants';

const SelectedCellDescriptions = ({ filters , weekKey }) => {
  const formattedTimeOfDayFilters = useMemo(() => {
    return formatTimeOfDayFiltersBasedOnWeek(filters, weekKey);
  }, [filters]);

  const renderWeekAndHoursContent = (weekItem, index) => {
    const matchedWeekData = _.get(formattedTimeOfDayFilters, [weekItem[weekKey]]);

    return !_.isEmpty(matchedWeekData) &&(
      <div className="d-flex" key={index}>
        <div>{weekItem[weekKey]},&nbsp;</div>
          {_.compact(_.map(matchedWeekData, getHourText)).join(', ')}
      </div>
    );
  }

  return _.map(TIME_OF_WEEKS, renderWeekAndHoursContent);
}

SelectedCellDescriptions.propTypes = {
  filters: PropTypes.array
}

SelectedCellDescriptions.defaultProps = {
  weekKey: DEFAULT_WEEK_KEY
}

export default SelectedCellDescriptions;
