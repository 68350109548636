import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getFeatureCollectionBounds } from '../helpers/mapHelper';
import { getShapesOutlineWidth  } from 'common/config/templateConfiguration';


const SOURCES = {
  POLYGONS_SOURCE: 'polygons-source'
};

const LAYERS = {
  POLYGONS_LAYER: 'polygons-layer'
};

class PolygonPartial extends Component {
  constructor(props) {
    super(props);

    this.lastRenderedPolygonsGeojson = null;
  }

  componentDidMount() {
    this.initSources();
    this.initLayers();
    this.updatePolygon();
  }

  componentDidUpdate() {
    const { map, polygonsGeojson } = this.props;
    if (
      !_.isEqual(polygonsGeojson, this.lastRenderedPolygonsGeojson) &&
      map.getSource(SOURCES.POLYGONS_SOURCE)
    ) {
      this.updatePolygon();
    }

    this.lastRenderedPolygonsGeojson = polygonsGeojson;
  }

  updatePolygon = () => {
    const { map, polygonsGeojson } = this.props;
    if(!_.isEmpty(polygonsGeojson)) {
      map.getSource(SOURCES.POLYGONS_SOURCE).setData(polygonsGeojson);
      this.panMapToPolygon();
    }
  }

  initSources = () => {
    const { map } = this.props;

    map.addSource(SOURCES.POLYGONS_SOURCE, {
      type: 'geojson',
      data: {
        'type': 'FeatureCollection',
        'features': []
      }
    });
  }

  initLayers = () => {
    const { map } = this.props;

    map.addLayer({
      'id': LAYERS.POLYGONS_LAYER,
      'type': 'line',
      'source': SOURCES.POLYGONS_SOURCE,
      'layout': {},
      'paint': {
        'line-color': 'red',
        'line-opacity': 0.8,
        'line-width': getShapesOutlineWidth()
      }
    });
  }

  panMapToPolygon() {
    const { map, polygonsGeojson } = this.props;

    if(_.isEmpty(_.get(polygonsGeojson, 'features'))){
      return;
    }
    map.fitBounds(getFeatureCollectionBounds(polygonsGeojson), { padding: 80 });
  }

  render() {
    return null;
  }
}

PolygonPartial.propTypes = {
  map: PropTypes.object,
  polygonsGeojson: PropTypes.object
};

export default PolygonPartial;
