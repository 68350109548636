import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Dropdown, ButtonGroup } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ForgeButton } from '@tylertech/forge-react';

import BookmarkSaveButton from './Bookmark/BookmarkSaveButton/BookmarkSaveButton';
import BookmarkNotifyButton from './Bookmark/BookmarkNotifyButton/BookmarkNotifyButton';
import LoadingSpinner from 'common/components/LoadingSpinner';

import { getBookmark } from 'common/api/bookmarksApi';
import { shareViaEmail } from 'common/api/commonApi';
import GlobalEvent from 'common/components/GlobalEvents';
import {
  showShareViaEmail,
  isShowExportButton,
  isDemoApplication,
  enableTemplateAndMetricPermission
} from 'common/config/customerConfiguration';
import {
  VISUALIZATION_TYPES,
  DOWNLOAD_TABLE_WARNING_MESSAGE,
  WITH_OUT_SUMMARY_TABLE_CHARTS
} from 'appConstants';
import { getApiParams } from 'helpers/apiParamsHelper';
import { buildQueryString } from 'helpers/HttpHelper';
import {
  getCurrentVizBasedChartType,
  getAvailableVisualizationType,
  shouldHideVisualizationButton
} from 'helpers/visualizationHelper';
import { disableTableVisualization } from 'modules/DetailsTable/TableHelper';

import { EVENTS, THIRD_PARTY_DATA_WARNING_MESSAGE } from 'appConstants';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import { getGroupByEntries, getThirdPartyWarningStatus } from 'common/config/templateConfiguration';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import * as commonPropTypes from 'common/propTypes';
import DownloadConfirmModal from 'pages/dashboard/components/DownloadConfirmModal';
import { getInspectViewData } from 'common/api/drilldown';
import { fetchApiData } from 'helpers/apiResponseHelper';
import ShareSection from 'pages/SubscriptionsManager/components/ShareSection';
import { trackEvent } from 'helpers/eventTracking';
import { viewSharedMessage, viewSharedErrorMessage } from 'helpers/toastMessages';
import { getBookmarkDefaultParams, getBookmarkType } from './Bookmark/bookmarkHelper';
import ThirdPartyWarningModal from 'pages/dashboard/components/ThirdPartyWarningModal';
import ExportMultipleModal from 'common/components/ExportContent/ExportMultipleModal';
import ExportImage from 'common/components/ExportContent/ExportImage';
import GotoEDP from './GotoEDP';
const CSV_FILE_ROW_LIMIT = 1000000;

class VisualizationButtons extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showExportModal: false,
      loading: false,
      showShareModal: false,
      plotlyChartData: {},
      currentMaps: {},
      isEmptyData: false,
      distributionChartOptions: { isCumulative: false, isDiscrete: true },
      totalRowCount: 0,
      groupByCount: 0,
      isLoading: false,
      currentBookmark: {},
      csvRowCount: 0,
      showThirdPartyWarning: false,
      showExportOtherDropdown: false
    };

    this.abortFetchController = new AbortController();
    this.csvDataController = new AbortController();
    this._exportImage = new ExportImage();
  }

  componentDidMount() {
    GlobalEvent.on('PLOTLY_PARAMS', this.setPlotlyChartData);
    GlobalEvent.on('MAP_BOX', this.setCurrentMaps);
    GlobalEvent.on('IS_EMPTY_DATA', this.setIsEmptyData);
    GlobalEvent.on(EVENTS.DISTRIBUTION_CHART_TYPE_CHANGE, this.setDistributionChartOptions);
    GlobalEvent.on('DIMENSION_COUNT', this.setTotalRowCount);
    GlobalEvent.on('DIMENSION_GROUP_COUNT', this.setGroupByCount);
    this.fetchBookmark();
    this.fetchCsvFileRowCount();
  }

  componentDidUpdate(prevProps) {
    const { currentBookmarkId, apiParams } = this.props;
    if (!_.isEqual(currentBookmarkId, _.get(prevProps, 'currentBookmarkId'))) {
      this.fetchBookmark();
    }

    if (!_.isEqual(apiParams, prevProps.apiParams)) {
      this.fetchCsvFileRowCount();
    }
  }
  componentWillUnmount() {
    GlobalEvent.off('PLOTLY_PARAMS', this.setPlotlyChartData);
    GlobalEvent.off('MAP_BOX', this.setCurrentMaps);
    GlobalEvent.off('IS_EMPTY_DATA', this.setIsEmptyData);
    GlobalEvent.off(EVENTS.DISTRIBUTION_CHART_TYPE_CHANGE, this.setDistributionChartOptions);
    GlobalEvent.off('DIMENSION_COUNT', this.setTotalRowCount);
    GlobalEvent.off('DIMENSION_GROUP_COUNT', this.setGroupByCount);
    this.abortFetchController.abort();
    this.csvDataController.abort();
  }

  fetchBookmark = () => {
    const { currentBookmarkId, dispatchUpdateBookmark } = this.props;

    if (!_.isEmpty(currentBookmarkId)) {
      getBookmark(currentBookmarkId)
        .then((response) => response.json())
        .then((response) => {
          dispatchUpdateBookmark(response);
          this.setState({ currentBookmark: response });
        });
    }
  }

  fetchCsvFileRowCount() {
    const { apiParams } = this.props;

    const queryParams = _.merge(apiParams, {
      onlyCsvRowCount: true
    });

    const csvDataApiUrl = getInspectViewData(queryParams);
    this.csvDataController.abort();
    this.csvDataController = new AbortController();

    fetchApiData(csvDataApiUrl, this.csvDataController).then(response => {
      const csvRowCount = _.get(_.first(response.data), 'row_count', 0);
      this.setState({
        csvRowCount: csvRowCount,
        isLoading: false,
      });
    }).catch(() => {
      this.setState({ csvRowCount: 0, isLoading: false });
    });
  }

  setPlotlyChartData = (plotlyParams) => {
    this.setState({ plotlyChartData: plotlyParams })
  }

  setCurrentMaps = (map) => {
    this.setState({ currentMaps: map });
  }

  setIsEmptyData = (data) => {
    this.setState({
      isEmptyData: _.get(data, 'isEmptyData') && !(_.get(data, 'isLoading'))
    });
  }

  setDistributionChartOptions = (options) => {
    this.setState({ distributionChartOptions: options });
  }

  toggleLoading = (loading) => {
    this.setState({ isLoading: loading });
  }

  setTotalRowCount = (dimensions) => {
    const { currentDrilldownDimensionField } = this.props;
    this.setState({ totalRowCount: _.get(dimensions, `count_${currentDrilldownDimensionField}`, 0) });
  }

  setGroupByCount = (groupByValues) => {
    const { currentDrilldownGroupByEntry } = this.props;
    const field = _.get(currentDrilldownGroupByEntry, 'field', '');
    this.setState({ groupByCount: `count_${_.get(groupByValues, field, 0)}` });
  }

  handleCloseExportModal = () => {
    this.setState({ showExportModal: false });
    this.handleChartLegendDownload(false);
  }

  handleClickExportModalButton = () => {
    this.setState({ showExportModal: true });
  }

  handleShareModalOpen = () => {
    this.setState({
      showShareModal: true
    });
  }

  handleShareModalClose = () => {
    this.setState({
      showShareModal: false
    });
  }

  handleSummaryTableDownload = () => {
    GlobalEvent.emit('SUMMARY_DOWNLOAD', true);
  }

  handleChartLegendDownload = (isLegend) => {
    GlobalEvent.emit('SHOW_CHART_LEGEND', isLegend);
  }

  onCompleteExportImage = () => {
    this.setState({ isLoading: false });
  }

  onExportImage = async () => {
    const { currentVisualizationType, plotlyChart, currentSnapshotView } = this.props;
    const { currentMaps, plotlyChartData } = this.state;
    const propsOption = {
      currentVisualizationType,
      plotlyChart,
      plotlyChartData,
      currentMaps,
      currentSnapshotView,
      onCompleteExportImage: this.onCompleteExportImage
    };
    const stateOption = { withChartImage: true };

    try {
      this.setState({ isLoading: true });
      await this._exportImage.exportImageBasedOnOptions(propsOption, stateOption);
    } catch (error) {
      console.error('Export image failed', error);
      this.onCompleteExportImage();
    }
  }

  handleDownloadButton = async (isExportModalCallback = false, onlyDownloadImage = false) => {
    await this.handleChartLegendDownload(true);

    const { currentDrilldownTemplateId, currentVisualizationType, visualization } = this.props;
    const isThirdPartyWarning = getThirdPartyWarningStatus(currentDrilldownTemplateId);
    const isMapChoropleth = _.get(visualization.mapOptions, 'currentMapView.type', '') === "choropleth";
    const canDownloadImage = (
      (currentVisualizationType === 'map' && !isMapChoropleth && this.shouldHideCsvRawDataDownload()) ||
      _.includes(WITH_OUT_SUMMARY_TABLE_CHARTS, currentVisualizationType) && onlyDownloadImage
    );

    if (canDownloadImage) {
      await this.onExportImage();
    } else if (!isExportModalCallback && isThirdPartyWarning) {
      this.setState({ showThirdPartyWarning: true });
    } else {
      this.handleDownload();
    }

    setTimeout(() => {
      this.handleChartLegendDownload(false);
    }, 2000);
  }

  onHideThirdPartyWarningModel = () => {
    this.setState({ showThirdPartyWarning: false });
  }

  handleDownload = () => {
    const { apiParams } = this.props;
    this.setState({ showThirdPartyWarning: false, showExportOtherDropdown: false });
    const queryParams = _.merge(apiParams, {
      onlyCsvRowCount: false
    });
    window.location.href = `/api/visualization/inspect_table.csv?${buildQueryString(queryParams)}`;
  }

  handleDownloadXlsx = () => {
    const { apiParams } = this.props;
    this.setState({ showThirdPartyWarning: false, showExportOtherDropdown: false });
    const queryParams = _.merge(apiParams, {
      onlyCsvRowCount: false
    });
    window.location.href = `/api/visualization/inspect_table.xlsx?${buildQueryString(queryParams)}`;
  }

  handleClickCsvDownload = () => {
    this.setState({ showExportOtherDropdown: false });
    if (!this.shouldHideCsvRawDataDownload()) {
      this.handleDownloadButton();
    } else if (!this.shouldHideSummaryTableSection()) {
      this.handleSummaryTableDownload();
    } else {
      this.toggleExportAsDropdown();
    }
  }

  canShowExportButton = () => {
    const { currentVisualizationType, visualization } = this.props;
    const hideExportModal = _.includes(WITH_OUT_SUMMARY_TABLE_CHARTS, currentVisualizationType) &&
      this.shouldHideCsvRawDataDownload();
    const isMapChoropleth = _.get(visualization.mapOptions, 'currentMapView.type', '') === "choropleth";

    if (currentVisualizationType === 'map' && !isMapChoropleth && this.shouldHideCsvRawDataDownload()) {
      return true;
    }

    return _.isEqual(currentVisualizationType, VISUALIZATION_TYPES.TABLE.type) || hideExportModal;
  }

  shouldHideCsvRawDataDownload() {
    const { currentDrilldownViewEntry, currentDrilldownTemplateId } = this.props;
    const isDisableTableView = disableTableVisualization(currentDrilldownTemplateId);
    const shouldHideTableView = shouldHideVisualizationButton(
      currentDrilldownTemplateId,
      currentDrilldownViewEntry,
      VISUALIZATION_TYPES.TABLE.type)

    return shouldHideTableView || isDisableTableView;
  }

  shouldHideSummaryTableSection() {
    const {
      currentDrilldownTemplateId,
      currentVisualizationType,
      currentDrilldownViewEntry,
      visualization } = this.props;

    const availableVizType = getAvailableVisualizationType(
      currentDrilldownTemplateId,
      currentVisualizationType,
      currentDrilldownViewEntry
    );
    const isMapChoropleth = _.get(visualization.mapOptions, 'currentMapView.type', '') === "choropleth";
    const isSnapshotVisualization = VISUALIZATION_TYPES.SNAPSHOT.type === availableVizType;
    const isOvertimeVisualization = VISUALIZATION_TYPES.OVERTIME.type === availableVizType;
    const isDistributionVisualization = VISUALIZATION_TYPES.DISTRIBUTION.type === availableVizType;

    return (!isSnapshotVisualization && !isOvertimeVisualization && !isMapChoropleth) ||
      isDistributionVisualization
  }

  renderThirdPartyWarningModel() {

    const { showThirdPartyWarning } = this.state;
    const title = "Third-Party Data warning";

    return showThirdPartyWarning && (<ThirdPartyWarningModal
      title={title}
      message={THIRD_PARTY_DATA_WARNING_MESSAGE}
      onClose={this.onHideThirdPartyWarningModel}
      onDownloadConfirm={this.handleDownload} />)
  }

  renderExportDropDownModal() {
    const { showExportModal, plotlyChartData, currentMaps, csvRowCount } = this.state;
    const { plotlyChart } = this.props;

    if (!showExportModal) {
      return null;
    }

    const isExportWarning = csvRowCount > CSV_FILE_ROW_LIMIT;

    return (
      <ExportMultipleModal
        plotlyChartData={plotlyChartData}
        currentMaps={currentMaps}
        plotlyChart={plotlyChart}
        isExportWarning={isExportWarning}
        shouldHideSummaryTableSection={this.shouldHideSummaryTableSection()}
        shouldHideCsvRawDataDownload={this.shouldHideCsvRawDataDownload()}
        csvDownloadCallBack={this.handleDownloadButton}
        csvSummaryTableDownloadCallBack={this.handleSummaryTableDownload}
        onClose={this.handleCloseExportModal}
        showExportModal={showExportModal} />
    )
  }

  toggleExportAsDropdown = () => {
    const { showExportModal } = this.state;
    this.setState({ showExportModal: !showExportModal });
    this.handleChartLegendDownload(true);
  }

  renderExportButtonWithWarning() {
    const { isLoading } = this.state;

    return (
      <Dropdown as={ButtonGroup}>
        {this.renderExportButtonExceedLimit('Export', false)}
        <Dropdown.Toggle split disabled={isLoading}
          aria-label="Export Options"
          variant="outline-primary"
          className="save-dropdown-icon" />
        <Dropdown.Menu>
          <Dropdown.Item eventKey="2" variant="outline-primary">
            {this.renderExportButtonExceedLimit('Export CSV', true)}
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" variant="outline-primary"
            onClick={() => { this.toggleExportAsDropdown() }}>
            Export other
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  onToggleHandler = (isOpen, e, metadata) => {
    if (metadata.source != 'select') {
      this.setState({ showExportOtherDropdown: isOpen });
    }
  }

  renderExportButtonDropDown() {
    const { isLoading, csvRowCount, showExportOtherDropdown } = this.state;

    if (csvRowCount > CSV_FILE_ROW_LIMIT && !this.shouldHideCsvRawDataDownload()) {
      return (this.renderExportButtonWithWarning());
    }

    return (
      <Dropdown as={ButtonGroup}
        className='export-csv-button'
        alignRight
        show={showExportOtherDropdown}
        onToggle={(isOpen, e, metadata) => this.onToggleHandler(isOpen, e, metadata)}>
        <ForgeButton type="flat">
          <button className='rounded-right-0'
            onClick={() => this.handleClickCsvDownload()}>
            Export
          </button>
        </ForgeButton>
        <Dropdown.Toggle split disabled={isLoading}
          aria-label="Export Options"
          variant="outline-primary"
          className="save-dropdown-icon dropdown-toggle-btn border-left-none  " />
        <Dropdown.Menu>
          <Dropdown.Item eventKey="2" variant="outline-primary"
            onClick={() => { this.handleClickCsvDownload() }}
          >
            Export CSV
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" variant="outline-primary"
            onClick={() => { this.handleDownloadXlsx() }}
          >
            Export XLS
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" variant="outline-primary"
            onClick={() => { this.toggleExportAsDropdown() }}
          >
            Export other
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderExportModal() {
    if (this.canShowExportButton()) {
      return null;
    }

    return (
      <div className="d-flex radar-export">
        <div className="position-relative"
          ref={(ref) => this.exportButtonRef = ref}>
          {this.renderExportButtonDropDown()}
          {this.renderExportDropDownModal()}
        </div>
      </div>
    )
  }

  isDisabledChartExportandShare() {
    let isDisabledChart = false;
    const { totalRowCount, groupByCount } = this.state;
    const {
      currentVisualizationType,
      visualization,
      currentDrilldownTemplateId,
      currentDrilldownDimensionField,
      currentDrilldownGroupByEntry
    } = this.props;

    const isSnapshot = _.isEqual(currentVisualizationType, VISUALIZATION_TYPES.SNAPSHOT.type);
    const groupByColumn = _.get(currentDrilldownGroupByEntry, 'column', '');
    const groupEntries = getGroupByEntries(currentDrilldownTemplateId);
    const groupByEntry = _.find(groupEntries, { column: groupByColumn });
    const dimensionEntry = getSelectedDimensionEntry(
      currentDrilldownTemplateId, currentDrilldownDimensionField);
    const isDimensionRangeMode = _.get(dimensionEntry, 'renderAxis') === 'range';
    const isGroupByRangeMode = _.get(groupByEntry, 'renderAxis') === 'range';
    const isRangeMode = isDimensionRangeMode || isGroupByRangeMode;
    const currentGroupByViewType = _.get(visualization,
      `${currentVisualizationType}.currentGroupByViewType`, '');

    if (isSnapshot && isRangeMode) {
      if (isGroupByRangeMode) {
        isDisabledChart = Number(_.get(dimensionEntry, 'bucket_size', 0)) > 1000 &&
          currentGroupByViewType == 'all'
      } else {
        isDisabledChart = groupByCount > 1000 && currentGroupByViewType == 'all'
      }
    } else {
      isDisabledChart = ((totalRowCount > 1000) && currentGroupByViewType == 'all');
    }
    return isDisabledChart;
  }

  getBookmarkName() {
    const { plotlyChart, currentDrilldownViewEntry, currentBookmarkId } = this.props;
    const { currentBookmark } = this.state;
    const currentBookmarkName = _.get(currentBookmark, 'name', "");
    let name = currentBookmarkName;

    if (_.isEmpty(currentBookmarkId)) {
      const chart = plotlyChart();
      if (chart.querySelector('.visualization-title .viz-title')) {
        name = chart.querySelector('.visualization-title .viz-title').innerText;
      } else {
        name = _.get(currentDrilldownViewEntry, 'name');
      }
    }
    return name;
  }

  handleAnalysisShare = (options) => {
    const { currentUser, currentBookmarkId } = this.props;
    const vizTitle = this.getBookmarkName();
    const shareEmails = _.get(options, 'shareEmails', []);

    const params = {
      shareEmails: shareEmails,
      bookmark: getBookmarkDefaultParams(this.props, { name: vizTitle }),
      metricName: vizTitle,
      applicationUrl: window.location.href,
      bookmark_id: currentBookmarkId
    };
    trackEvent('shared-via-email');
    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      shareViaEmail(params)
        .then((response) => {
          this.handleShareModalClose();
          if (response.ok) {
            toast.success(viewSharedMessage);
          } else {
            toast.error(viewSharedErrorMessage);
          }
          this.setState({ isLoading: false });
        }).catch(() => {
          this.handleShareModalClose();
          toast.error(viewSharedErrorMessage);
          this.setState({ isLoading: false });
        });
    }
  }

  renderShareModal() {
    const { currentUser, currentDrilldownViewEntry, userFromBellerophon } = this.props;
    const { showShareModal } = this.state;
    const placeholderText = 'Enter one or more email addresses';
    let allowedUserRoles = [];
    const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
    if (!isLaunchpadAdmin && enableTemplateAndMetricPermission()) {
      allowedUserRoles = _.map(_.get(currentDrilldownViewEntry, 'user_list', []), 'role_id');
    }

    if (!showShareModal) {
      return null;
    }
    return (
      <ShareSection
        currentUser={currentUser}
        isShareEnabled={showShareModal}
        onCancelShare={this.handleShareModalClose}
        onSendClick={this.handleAnalysisShare}
        emailInputPlaceholder={placeholderText}
        allowedUserRoles={allowedUserRoles}
        shareType='metric'
        showCopyLink={true}
        trackEventName="analysis_copy_link"
      />
    );
  }

  renderExportButtonExceedLimit(buttonLabel, isRenderLabel) {
    const title = `Large file alert`;

    return (
      <DownloadConfirmModal
        title={title}
        buttonLabel={buttonLabel}
        isRenderLabel={isRenderLabel}
        classNames="btn btn-outline-primary mr-1"
        message={DOWNLOAD_TABLE_WARNING_MESSAGE}
        onDownloadConfirm={this.handleDownloadButton}
      />
    )
  }

  renderDownloadButton() {
    const { csvRowCount, showExportOtherDropdown } = this.state;
    if (!this.canShowExportButton()) {
      return null;
    }

    if (csvRowCount > CSV_FILE_ROW_LIMIT && !this.canShowExportButton()) {
      return (this.renderExportButtonExceedLimit());
    }

    return (
      <Dropdown as={ButtonGroup}
        className='export-csv-button'
        alignRight
        show={showExportOtherDropdown}
        onToggle={(isOpen, e, metadata) => this.onToggleHandler(isOpen, e, metadata)}>
        <ForgeButton type="flat">
          <button className='rounded-right-0'
            onClick={() => this.handleDownloadButton(false, true)}>
            Export
          </button>
        </ForgeButton>
        <Dropdown.Toggle split
          aria-label="Export Options"
          variant="outline-primary"
          className="save-dropdown-icon dropdown-toggle-btn border-left-none  " />
        <Dropdown.Menu>
          <Dropdown.Item eventKey="2" variant="outline-primary"
            onClick={() => { this.handleDownloadButton(false, true) }}
          >
            Export CSV
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" variant="outline-primary"
            onClick={() => { this.handleDownloadXlsx() }}
          >
            Export XLS
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  render() {
    const { currentBookmark } = this.state;
    const {
      currentUser, isLeafPage, currentVisualizationType, plotlyChart, userTargetEntryId,
      isManageCollection
    } = this.props;
    const showShareButton = showShareViaEmail() && !_.isEmpty(currentUser) && !isManageCollection;
    const hideBookMarkButton = (
      _.isEmpty(currentUser)) ||
      (currentVisualizationType === VISUALIZATION_TYPES.TABLE.type && isLeafPage);
    const showExportButton = isShowExportButton()

    return (
      <>
        <LoadingSpinner isLoading={this.state.isLoading} />
        <div className="vis-sentence-rs chart-control"
          ref={(ref) => this.buttonContainer = ref}>
          <div className="action-control">
            {hideBookMarkButton ? null :
              <BookmarkNotifyButton onLoad={this.toggleLoading} plotlyChart={plotlyChart} />}
            {showShareButton ?
              <ForgeButton type="outlined">
                <button
                  className="view-share-btn"
                  onClick={this.handleShareModalOpen}>
                  Share
                </button> </ForgeButton> : null}
            {this.renderThirdPartyWarningModel()}
            <GotoEDP />
            {this.renderDownloadButton()}
            {showExportButton ? this.renderExportModal() : null}
            {hideBookMarkButton && !isDemoApplication() ? null :
              <BookmarkSaveButton
                isManageCollection={isManageCollection}
                onUpdateBookmark={(bookmark) => this.setState({ currentBookmark: bookmark })}
                currentBookmark={currentBookmark} plotlyChart={plotlyChart}
                userTargetEntryId={userTargetEntryId} />}
            {/* {this.renderExportContentModal()} */}
            {this.renderShareModal()}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const currentVisualizationType = _.get(state, 'drilldown.currentVisualizationType');
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);

  return {
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    mapOptions: _.get(state, 'visualization.mapOptions'),
    drilldown: _.get(state, 'drilldown', {}),
    visualization: _.get(state, 'visualization', {}),
    currentUser: _.get(state.currentUser, 'user', {}),
    userFromBellerophon: _.get(state, 'userFromBellerophon', {}),
    commonFilters: _.get(state, 'commonFilters', {}),
    currentVisualizationType: currentVisualizationType,
    apiParams: getApiParams(apiParamsOptions, {}),
    table: _.get(state, 'visualization.table'),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    currentVizBasedChartType: getCurrentVizBasedChartType(
      currentVisualizationType, _.get(state, 'visualization', {})),
    isLeafPage: _.get(state, 'visualization.table.isLeafPage', false),
    currentChartView: _.get(state, 'visualization.overtime.currentChartView', ''),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView', ''),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField', ''),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry', {}),
    currentBookmarkId: _.get(state, 'bookmark.currentBookmarkId', null),
    isManageCollection: _.get(state, 'dashboard.isManageCollection', false)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateBookmark: (bookmark) => {
      const { id, name } = bookmark;
      const bookmarkType = getBookmarkType(bookmark);
      dispatch(updateCurrentBookmark(id, name, bookmarkType));
    }
  }
}

VisualizationButtons.propTypes = {
  drilldown: commonPropTypes.drilldownPropTypes,
  mapOptions: PropTypes.object,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  visualization: PropTypes.object,
  currentVisualizationType: PropTypes.string,
  currentBookmarkId: PropTypes.string,
  dispatchUpdateBookmark: PropTypes.func,
  currentDrilldownDimensionField: PropTypes.string,
  currentDrilldownGroupByEntry: commonPropTypes.groupByEntryPropTypes,
  apiParams: PropTypes.object,
  plotlyChart: PropTypes.func,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  table: PropTypes.object,
  currentUser: commonPropTypes.userPropTypes,
  userFromBellerophon: commonPropTypes.userPropTypes,
  isLeafPage: PropTypes.bool,
  userTargetEntryId: PropTypes.number,
  currentSnapshotView: PropTypes.string,
  isManageCollection: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(VisualizationButtons);
