import _ from 'lodash';
import { prefixText, suffixText, getFormattedNumberValue } from 'helpers/chartDataHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import { getXLabelsMaxMinValues } from './scatterChart';
import { getNormalLegends } from '../legendHelper';
// Input:
//  formattedData =>
//      [
//        {
//          label: 'High',
//          value: 21.98,
//          scatterEntry: {},
//          plotlyDescription: '<b>High<b>...'
//          isBenchMark:false
//        },
//        {
//          label: 'Low',
//          value: 22.98,
//          scatterEntry: {},
//          plotlyDescription: '<b>High<b>...'
//          isBenchMark:true,
//          benckMarkList:[{
//             label: labelText,
//             value: benchMarkvalue,
//             plotlyDescription: 'hover text',
//             scatterEntry : null,
//             clickMode:false
//           }]
//        }
//        ...
//      ]
export const toPlotlyTraces = (vizOptions, apiData, formattedData) => {
  const { templateId, viewMode, viewEntry, showRange, afterRender } = vizOptions;
  const isSmallView = viewMode === VIEW_MODE.SMALL;
  const height = getHeight(formattedData);
  const primaryColor = getConfiguredMetricColor(templateId, 'primary')

  const scatterData = _.filter(formattedData, (f) => {
                        return _.get(f, 'isBenchMark', false) === false;
                      });
  const benchMarkData = _.filter(formattedData, (f) => {
                          return _.get(f, 'isBenchMark', false) === true;
                        });

  const prefix = prefixText({viewOptions: viewEntry}, false);
  const suffix = suffixText({ viewOptions: viewEntry }, false);
  const tickTextLabels = scatterData.map((d) => d.tickLabel);
  const tickValueLabels = scatterData.map((d) => d.label);
  const rangeLastValue = _.size(tickValueLabels);
  const isSecondsFormat  = isTimeDurationEnabled(viewEntry);
  const legendsItems = getNormalLegends(vizOptions);
  afterRender(legendsItems);

  const textArray = _.map(scatterData, (datum) => {
     return datum.isSeeMoreDimension ? datum.plotlyDescription :
     `<span style="fill:#ffffff"></span>`; // only purpose for more than fifty dimension text
    });

    const pointColors = _.map(scatterData, (datum) => {
      return datum.isSeeMoreDimension ? '#FFFFFF' : primaryColor;
    });

  let scatterPlotlyData = {
      "x": scatterData.map((d) => d.value),
      "y": scatterData.map((d) => d.label),
      "meta": scatterData,
      "name": scatterData.map((d) => d.plotlyDescription),
      "text": textArray,
      "type":"scatter",
      "mode": 'markers+text',
      "orientation":"h",
      "textposition":"right",
      "hoverinfo":"none",
      "visible":true,
      "marker": { "color": pointColors, "size": 12 },
    }


  let benchMarkPlotlyData = benchMarkData.map((data) => {
    return {
      "x": data.benckMarkList.map((d) => d.value),
      "y": data.benckMarkList.map((d) => d.label),
      "meta": data.benckMarkList,
      "mode": 'lines+markers+text',
      "hoverinfo": 'none',
      "showlegend": false,
      "type": 'line',
      "line": {
        "color": _.get(data, 'color', '#000000'),
        "width": 1,
        "dash": _.get(data, 'lineType', 'dot')
      },
      "clickmode":false
    }
  });

  let rangeLineTraces =[];
  if(showRange){
    rangeLineTraces = getRangeLines(scatterData, vizOptions);
  }

  const data = [...rangeLineTraces ,scatterPlotlyData, ...benchMarkPlotlyData];
  const layout = {
    "showlegend": false,
    "autosize": true,
    "shapes": [],
    "margin": {"l":10,"t":10,"b":20,"r":10,"pad":5},
    "bargap":"",
    "dragmode":false,
    "showspikes":false,
    "bargroupgap":"",
    "hovermode": 'closest',
    "height":height,
    "xaxis":{
      "autorange":true,
      "tickprefix": prefix,
      "ticksuffix":  isSmallView ? "" : `${suffix}`,
      "showticklabels":true,
      "showgrid":false,
      "zeroline":false,
      "side": 'top',
      "automargin":true,
      "tickfont": {
        "family": isSmallView ? 'Roboto' : '',
        "size": isSmallView ? 8 : "auto"
      }
    },
    "yaxis":{
      "autorange": false,
      "range":[rangeLastValue, -0.5],
      "showticklabels":true,
      "automargin":true,
      "type":"category",
      "tickfont" : { "color" : "#3F51B5" },
      "ticktext": tickTextLabels,
      "tickvals": tickValueLabels,
      "gridwidth": 5
    },
    "annotations":""
  };

  const config = {
    "displayModeBar":false,
    "scrollZoom":false,
    "editable":false,
    "showLink":false,
    "responsive":true
  };

  if(isSecondsFormat){
    const { minXLabel, maxXLabel} = getXLabelsMaxMinValues(formattedData);
    layout.xaxis.tickformat = "%X";
    layout.xaxis.type = "liner";
    layout.xaxis.range = [minXLabel, maxXLabel];
  }

  return { data, layout, config };
}

const getRangeLines = (chartData, vizOptions) => {
  const { viewEntry, templateId } = vizOptions;
  return _.map(chartData, (datum) => {
      const yaxisLable = datum.label;
      const minValue = _.get(datum, 'minValue', 0);
      const maxValue = _.get(datum, 'maxValue', 0);
      const formattedMinValue = getFormattedNumberValue(minValue, viewEntry);
      const formattedMaxValue = getFormattedNumberValue(maxValue, viewEntry);
      return (
        {
          "x": [minValue, maxValue],
          "y": [yaxisLable, yaxisLable],
          "meta": datum,
          "mode": datum.isGroup? 'none' :'lines+markers',
          "type": 'scatter',
          "marker": {
            "symbol": [142,142],
            "color": getConfiguredMetricColor(templateId, 'range'),
            "size": 10,
            "line": { "width": 4 }
          },
          customdata: ['range', formattedMinValue, formattedMaxValue],
          hoverinfo: 'none'
        }
      )
  });
}

const getHeight = (formattedData) => {
  const noofRows =_.size(formattedData);
  let height = noofRows <= 6 ? 55 : 55;
  switch (noofRows) {
    case 1:
      height = 80;
      break;
    case 2:
      height = 50;
      break;
  }

  return (noofRows * height);
}
