import {
  SUBSCRIPTION_FREQUENCY_OPTIONS,
  TIMEZONE_OPTIONS,
  WEEKDAYS_OPTIONS,
  WEEK_NUMBER_OPTIONS,
  DEFAULT_TIME,
  MONTHLY_REGULARITY_TYPES,
  TIMEZONE_DEFAULT_ALERT,
  DATE_FORMAT,
  TIME_DIFFERENCE_FOR_ZONE
} from 'appConstants';
import { dateWithTimeZone } from 'helpers/dateHelper';
import { alertEmailDeliveryTimeZone } from 'common/config/customerConfiguration';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

export const getSubscriptionNotifyText = (subscription) => {
 return getFormattedNotificationText(subscription)
}

export const getSubscriptionTimeZone = () => {
  const timeZoneAbbr = getBrowserTimeZone();
  if(_.includes(TIMEZONE_OPTIONS, timeZoneAbbr)){
    return timeZoneAbbr;
  }else{
    const timeZone = _.get(TIME_DIFFERENCE_FOR_ZONE, alertEmailDeliveryTimeZone, '-10:00');
    return _.isEmpty(timeZone) ?  _.first(TIMEZONE_OPTIONS): TIMEZONE_DEFAULT_ALERT[timeZone];
  }
}

export const subscriptionDefaultParams = (collection) => {
  const subscriptionName = `${_.get(collection, 'name')} subscription`;
  return {
    sendEmail: true,
    includeImage: true,
    name: subscriptionName,
    frequency: _.get(SUBSCRIPTION_FREQUENCY_OPTIONS, '1.value'),
    frequencyInterval: 1,
    monthlyWeekNumber: _.get(WEEK_NUMBER_OPTIONS, '0.value'),
    emailSendingTime: DEFAULT_TIME,
    timeZone: getSubscriptionTimeZone(),
    emailSendingDays: [_.get(WEEKDAYS_OPTIONS, '1.day')],
    startDate: dateWithTimeZone().format(DATE_FORMAT),
    regularityType: MONTHLY_REGULARITY_TYPES.WEEK,
    timePeriodDay: 1,
    bellerophonTagId: _.get(collection, 'bellerophon_tag_id', ''),
    isCollaborateSubscription: false,
  };
}

export const getFormattedNotificationText = (options) => {
  const {
    email_sending_time,
    time_zone,
    email_sending_days,
    frequency_interval,
    frequency,
    week_number_for_month,
    start_date,
    regularity_type,
    time_period_day
  } = options;
  let successMessage = '';
  const timeText = `at ${email_sending_time} ${time_zone}`;
  let startDateAsText = '';
  const daysLength = email_sending_days.length;
  const sortedDays = _.sortBy(email_sending_days, function(d) {
    return new moment(d, 'ddd');
  });
  let emailSendingDaysText = '';
  _.each(sortedDays, (day, index) => {
    if(daysLength > 1 && (index == (daysLength - 1))) {
      emailSendingDaysText += ` and ${pluralize(_.capitalize(day))}`;
    }else {
      emailSendingDaysText += pluralize(_.capitalize(day));
      if(daysLength > 1 && ((index+1) != (daysLength - 1))){
        emailSendingDaysText += ', ';
      }
    }
  });

  let intervalText = _.isEqual(frequency_interval, 1) ? `${frequency}` :
  `${frequency_interval} ${frequency}s`;

  if(frequency == 'day'){
    startDateAsText =  `starting ${formatStartDate(start_date)}`
    successMessage = `Success! You’ll receive email updates every ${intervalText} ${timeText} `+
    `${startDateAsText}.`;
  }else if(frequency == 'month'){
    startDateAsText =  `starting ${formatStartDate(start_date)}`
    const monthlyWeekText = _.get(
      _.find(WEEK_NUMBER_OPTIONS, {'value': week_number_for_month}),
      'name',
      week_number_for_month
      );
      successMessage = `Success! You’ll receive email updates every ${intervalText} `+
      `${timeText}`;
      if(regularity_type == 'week_period'){
        successMessage = successMessage +  ` on the ${monthlyWeekText} ` +
      `${emailSendingDaysText} ${startDateAsText}.`;
      }else{
        const timePeriodText = `on day ${time_period_day} of the period`;
        successMessage = successMessage + ` ${timePeriodText}` +
        ` ${startDateAsText}.`;
      }

  }else{
    startDateAsText =  `starting ${formatStartDate(start_date)}`
    successMessage = `Success! You’ll receive email updates every ${intervalText} `+
    `${timeText} on ${emailSendingDaysText} ${startDateAsText}.`;
  }

  return successMessage
}

const getBrowserTimeZone = () => {
  const timeZone = moment.tz.guess();
  const time = new Date();
  const timeZoneOffset = time.getTimezoneOffset();

  return moment.tz.zone(timeZone).abbr(timeZoneOffset);
}

export const formatStartDate = (startDate) => {
  return dateWithTimeZone(startDate).format("MMM D, YYYY");
}
