import PropTypes from 'prop-types';
import React from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { Preview } from 'react-dnd-preview';
import classNames from 'classnames';

import { DRAGGABLE } from 'appConstants';
import { childrenPropTypes, viewEntryPropTypes } from 'common/propTypes';
import dragPlaceholder from 'pages/dashboard/components/dragPlaceholder';
import { isMobileOrTablet } from 'helpers/DomPageHelper';

function CardDrag({  userCardEntry, viewEntry, templateEntry, isMetricLibrary, children, customClass}) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DRAGGABLE.TYPES.CARD,
      userCardEntry,
      viewEntry,
      templateEntry,
      isMetricLibrary
    },
    endDrag: () => {
      // Hidden plotly's drag handler div so that card's drag and drop will not be interrupted.
      // As a side-effect of that, after dragging and dropping, the internal state of the chart
      // is still as `_dragging = true` resulting in
      // 1. trigger a plotly click on any next mouseup event
      // 2. plotly hoverover not working properly.
      /*eslint-disable */
      $('div.js-plotly-plot').each(function ($elem) {
        this._dragging = false;
      });
      /*eslint-enable */
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const dragClass = _.isEmpty(customClass) ? 'card-drag h-100' : customClass; 
  const dragClassNames = classNames(dragClass, {
    'is-dragging': isDragging,
  });

  const generatePreview = ({ style }) => {
    style = _.merge({}, style, { opacity: 0.1, zIndex: 1001, backgroundColor: '#e6e8f2' })
    return (
      <div className="item-list__item" style={style}>
        <img width="250" height="150" src={dragPlaceholder} />
      </div>
    );
  }
  const renderPrieImageContent = isMobileOrTablet() ?
    <Preview generator={generatePreview} /> :
    <DragPreviewImage connect={preview} src={dragPlaceholder} />;

  return (
    <div ref={drag} className={dragClassNames}>
      {renderPrieImageContent}
      {children}
    </div>
  );
}

CardDrag.propTypes = {
  userCardEntry: PropTypes.object,
  viewEntry: viewEntryPropTypes,
  templateEntry: PropTypes.object,
  children: childrenPropTypes,
  style: PropTypes.object,
  isMetricLibrary: PropTypes.bool,
  customClass: PropTypes.string
};

export default CardDrag;
