import { setDefaultViewActions } from 'actions/defaultViewActions';
import { updateDateFilters, updateGlobalFilter } from 'actions/commonFiltersActions';
import { updateVisualizationDefaults } from 'actions/overtimeActions';
import { updateVisualizationReducer } from 'actions/visualizationActions';
import {
  updateDrilldownDimensionField,
  updateDrilldownTemplateNameAndId,
  setDrilldownDimensions
} from 'actions/drilldownActions';

import { getCurrentTemplateEntry, getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
import { getDefaultSnapshotOptions } from './helper';

export const setDefaultSubscriptionBookmarkActions = ({ bookmark, dispatch }) => {
  const { drilldown, commonFilters, bookmarkOptions, visualization } = bookmark;
  const snapshotDefaultOptions = getDefaultSnapshotOptions(bookmark);
  const globalFilters = _.get(commonFilters, 'globalFilters', []);
  const currentChartVizType = _.get(bookmarkOptions, 'currentVizChartType');
  const currentDrilldownViewEntry = _.get(drilldown, 'currentDrilldownViewEntry');
  let templateId = _.get(drilldown, 'currentDrilldownTemplateId');
  const quickFilters = _.get(drilldown, 'quickFilters', []);
  let templateName = _.get(drilldown, 'currentDrilldownTemplateName');
  const currentVisualizationType = _.get(drilldown, 'currentVisualizationType');
  const dimensionFilters = _.get(drilldown, 'drilledDownDimensions');
  const dimensionField = _.get(drilldown, 'currentDrilldownDimensionField');

  if (_.isNull(templateId)) {
    templateId = _.get(getCurrentTemplateEntry(templateName), 'template_id');
  } else {
    templateName = _.get(getCurrentTemplateEntryById(templateId), 'name');
  }

  dispatch(updateDrilldownTemplateNameAndId(templateName, templateId));
  dispatch(updateDateFilters(commonFilters));
  dispatch(updateVisualizationDefaults(snapshotDefaultOptions));
  setDefaultViewActions({
    currentVisualizationType,
    viewEntry: currentDrilldownViewEntry,
    templateId,
    dispatch,
    quickFilters,
    currentChartVizType
  });
  dispatch(setDrilldownDimensions(dimensionFilters));
  dispatch(updateDrilldownDimensionField(dimensionField));
  dispatch(updateGlobalFilter(globalFilters));
  if (!_.isEmpty(visualization)) {
    dispatch(updateVisualizationReducer(visualization));
  }
}
