import React from 'react';
import PropTypes from 'prop-types';

const NotificationLabelAndCount = (props) => {
  const { label, count } = props;

  return (
    <div className="alert-head border-bottom border-primary text-center py-2">
      <span className="text-primary font-weight-bold">{label} ({count})</span>
    </div>
  );
}

NotificationLabelAndCount.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number
}

NotificationLabelAndCount.defaultProps = {
  label: 'Alerts',
  count: 0
}

export default (NotificationLabelAndCount);
