import {
  pendoApiKey,
  environment,
  userIpAddress,
  enableSecurityRoles,
  getSecurityRoles,
  divisionName
} from 'common/config/customerConfiguration';


export const initiatePendo = (currentUser, userFromBellerophon) => {
  if(_.isEqual(environment, 'development') || _.isNil(pendoApiKey)) {
    return;
  }

  let visitor = {
    ip: userIpAddress,
    product: 'Executive Insights'
  };

  if (_.isEmpty(currentUser) || currentUser === 'null') {
    visitor.userRole = 'anonymous user';
  } else {
    const { user, app_user } = currentUser;
    const { bellerophon_user_detail } = userFromBellerophon;

    const { nickname, socrata_id } = user;
    const { email } = app_user;

    visitor.userrights = _.get(bellerophon_user_detail, 'isAdmin', false);

    if(!_.isEmpty(email)) {
      visitor.id = email;
    }

    if(!_.isEmpty(nickname)) {
      visitor.name = nickname;
    }

    if(!_.isEmpty(socrata_id)) {
      visitor.socrataId = socrata_id;
    }
    if(enableSecurityRoles()){
      const securityRoles = getSecurityRoles();
      const currentRole = _.find(securityRoles, (role) => {
        return role.role_id == _.get(bellerophon_user_detail, 'role')
      });
      if(!_.isEmpty(currentRole)){
        visitor.userrole = _.get(currentRole, 'name');
      }
    }

    if(!_.isEmpty(divisionName)) {
      visitor.division = divisionName;
    }
  }

 return pendo.initialize({  // eslint-disable-line no-undef
    apiKey: pendoApiKey,
    visitor: visitor,
    account: {
      id: document.location.host,
      domain: document.location.host,
      environment: environment,
      hasPerspectives: false
    }
  });
}
