import _ from 'lodash';
import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import * as commonPropTypes from "common/propTypes";

import StatusBanner from './StatusBanner'
import TargetEntryContext from 'context/TargetEntryContext';


import { getTargetEntry } from 'common/api/targetEntryApi';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getCurrentTargetEntry } from 'helpers/templateHelper';

export class TargetStatusBanner extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.abortFetchController = new AbortController();

    this.state = {
      chartTotalData: {},
      secondaryTotal: null,
      currentDimensionTotal: null,
      userTargetEntry: {}
    };
    this._previousTargetEntry = null;
  }

  componentDidMount() {
    this.fetchTargetEntry();
  }

  componentDidUpdate(prevProps) {
    const { templateId, currentViewEntry, bookmarkId } = this.props;
    const isViewEntryChanged = !_.isEqual(prevProps.currentViewEntry, currentViewEntry);
    const isTemplateChanged = !_.isEqual(prevProps.templateId, templateId);
    const isBookmarkIdChanged = !_.isEqual(prevProps.bookmarkId, bookmarkId);

    if(isViewEntryChanged || isTemplateChanged || isBookmarkIdChanged) {
      this.fetchTargetEntry();
    }
  }

  fetchTargetEntry = () => {
    const { templateId, currentViewEntry, bookmarkId } = this.props;
    const params = {
      template_id: templateId,
      view_id: _.get(currentViewEntry, 'view_id'),
      bookmark_id: bookmarkId
    };

    this.setState({ isLoading: true });
    this.abortFetchController.abort();

    fetchApiData(getTargetEntry(params)).
      then((response) => {
        this.setState({ isLoading: false, userTargetEntry: _.get(response, '0', {}) });
      }).
      catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);   // eslint-disable-line no-console
      });
  }

  getTargetEntry = () => {
    const {
      currentViewEntry, visualizationType, quickFilters, benchMarkNames, isDiscrete,
      isCumulative, metricTotalData
    } = this.props;
    const currentDimensionTotal = _.get(metricTotalData, 'total');
    const { userTargetEntry } = this.state;

    const targetEntryOptions = {
      visualizationType, chartTotalData: metricTotalData, quickFilters, benchMarkNames,
      isDiscrete, isCumulative, userTargetEntry
    };

    return getCurrentTargetEntry(currentDimensionTotal, currentViewEntry, targetEntryOptions);
  }

  updateUserEntry = (targetEntry) => {
    this.setState({ userTargetEntry: targetEntry }, () => {
      this.props.setUserTargetEntryId(_.get(this.state.userTargetEntry, 'id', null))
    });
  }

  renderTargetStatus = () => {
    const { secondaryTotal, userTargetEntry, isLoading } = this.state;
    const { quickFilters, currentViewEntry, visualizationType, templateId, currentUser, defaultQuickFilters,
      bookmarkId } = this.props;
    const targetEntry = this.getTargetEntry();
    this._previousTargetEntry = targetEntry;

    const options = { userTargetEntry, isLoading, updateUserEntry: this.updateUserEntry, bookmarkId };
    return (
      <TargetEntryContext.Provider value={options}>
        <StatusBanner
          templateId={templateId}
          currentUser={currentUser}
          visualizationType={visualizationType}
          currentViewEntry={currentViewEntry}
          secondaryTotal={secondaryTotal}
          quickFilters={quickFilters}
          defaultQuickFilters={defaultQuickFilters}
          currentTargetEntry={targetEntry}
        />
      </TargetEntryContext.Provider>
    );
  }

  render() {
    const { isLoading } = this.state;
    const { metricTotalData, metricTotalLoading } = this.props;
    const targetEntry = this.getTargetEntry();
    let targetEntryClassName = isLoading || !_.isEmpty(targetEntry) || _.isEmpty(metricTotalData) ?
      'target-entry-wrapper target-entry-container' : 'target-entry-container';
    if(_.isEmpty(this._previousTargetEntry) && _.isEmpty(targetEntry)) {
      return null;
    }

    return (
      <div className={targetEntryClassName}>
        {isLoading || metricTotalLoading && <forge-skeleton list-item></forge-skeleton>}
        {!isLoading && !_.isEmpty(targetEntry) && this.renderTargetStatus()}
      </div>
    );
  }
}

TargetStatusBanner.propTypes = {
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  defaultQuickFilters: PropTypes.array,
  queryParams: PropTypes.object,
  currentViewEntry: commonPropTypes.viewEntryPropTypes,
  visualizationType: PropTypes.string,
  setUserTargetEntryId: PropTypes.func,
  bookmarkId: PropTypes.string,
  templateId: commonPropTypes.templateIdPropTypes,
  currentUser: commonPropTypes.userPropTypes,
  benchMarkNames: PropTypes.array,
  isDiscrete: PropTypes.bool,
  isCumulative: PropTypes.bool,
  metricTotalLoading: PropTypes.bool,
  metricTotalData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number
  ])
};

export default TargetStatusBanner;
