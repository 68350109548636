import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ForgeButton } from '@tylertech/forge-react';
import CopyCollectionModal from 'common/components/CopyCollectionModal';

const CopyCollection = (props) => {
  const {
    currentCollection,
    onCopyCollectionSave,
    isMoveCollaborate
  } = props

  const [copyCollectionName, setCopyCollectionName] = useState('');
  const [showCollectionCopyModel, setShowCollectionCopyModel] = useState(false);

  useEffect(() => {
    const name = ` ${_.get(currentCollection, 'name')} Copy`
    setCopyCollectionName(name)
  }, [currentCollection])

  const handleCollectionSave = () => {
    if (!_.isEmpty(copyCollectionName)) {
      setShowCollectionCopyModel(false);
      onCopyCollectionSave(copyCollectionName, isMoveCollaborate)
    }
  }

  const handleCollectionTitleChange = (e) => setCopyCollectionName(e.target.value);
  const handleCollectionCopyClick = (status = false) => setShowCollectionCopyModel(status);

  const renderCopyCollectionModal = () => {
    if (!showCollectionCopyModel) {
      return
    }
    return <CopyCollectionModal
      name={copyCollectionName}
      isMoveCollaborate={isMoveCollaborate}
      onInputChange={handleCollectionTitleChange}
      onClose={handleCollectionCopyClick}
      onSave={handleCollectionSave} />;
  }

  const renderLabel = () =>{
    if(isMoveCollaborate){
      return 'Collaborate'
    } else {
      return 'Duplicate'
    }
  }

  const renderCopyButton = () => {
    return (
      <ForgeButton type="outlined">
        <button
          disabled={showCollectionCopyModel}
          variant="outline-primary"
          onClick={() => handleCollectionCopyClick(true)}>
          {renderLabel()}
        </button>
      </ForgeButton>
    )
  }

  return (
    <div>
      {renderCopyButton()}
      {renderCopyCollectionModal()}
    </div>
  )
}

CopyCollection.propTypes = {
  currentCollection: PropTypes.object,
  onCopyCollectionSave: PropTypes.func,
  isMoveCollaborate: PropTypes.bool
}

export default CopyCollection;