import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeButton } from '@tylertech/forge-react';

const ForecastingBackButton = (props) => {
  const { onSaveConfirm, shouldEnableModel } = props;
  const [showModal, setShowModal] = useState(false);

  const handleKeyDownCancel = (e) => {
    if (isEnterButtonPressed(e)) {
      handleCloseModal(e);
    }
  };

  const handleKeyDownSave = (e, shouldSave) => {
    if (isEnterButtonPressed(e)) {
      onSaveButtonClick(e, shouldSave);
    }
  };

  const onSaveButtonClick = (event, shouldSave) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onSaveConfirm(shouldSave);
    setShowModal(false);
  }

  const handleCloseModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setShowModal(false);
  }

  const onBackButtonClick = () =>{
    if(!shouldEnableModel){
      onSaveConfirm(false);
    }else{
      setShowModal(true);
    }
  }

  const renderModel = () => {
    if (!showModal) {
      return;
    }
    return (
      <Modal
        onHide={(event) => handleCloseModal(event)}
        show={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-wrapper delete-container">
          <Modal.Header className="align-items-center">
            <Modal.Title><span className="text-dark">Do you want to save your changes?</span></Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <ForgeButton className="mr-auto" type="outlined">
              <button
                aria-label="Cancel"
                tabIndex={0}
                onClick={(event) => handleCloseModal(event)}
                onKeyDown={(event) => handleKeyDownCancel(event)}
              >
                Cancel
              </button>
            </ForgeButton>

            <ForgeButton className="mr-auto" type="outlined">
              <button
                aria-label="Exit Without Saving"
                tabIndex={0}
                onClick={(event) => onSaveButtonClick(event, false)}
                onKeyDown={(event) => handleKeyDownSave(event, false)}
              >
                Exit Without Saving
              </button>
            </ForgeButton>
            <ForgeButton type="raised">
              <button
                aria-label="Save and Exit"
                tabIndex={0}
                onClick={(event) => onSaveButtonClick(event, true)}
                onKeyDown={(event) => handleKeyDownSave(event, true)}
              >
                Save and Exit
              </button>
            </ForgeButton>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
  return (
    <>
    <button
      aria-label="Back To Overview"
      className="btn back-arrow-icon back-btn-light"
      onClick={() => onBackButtonClick(true)}>
      <i className="icons-arrow-left2" />
    </button>
      {renderModel()}
    </>
  )
};

ForecastingBackButton.propTypes = {
  onSaveConfirm: PropTypes.func,
  shouldEnableModel: PropTypes.bool
}

export default ForecastingBackButton;