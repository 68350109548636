import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeIconButton, ForgeButton } from '@tylertech/forge-react';
import { useDispatch, useSelector } from 'react-redux';
import CompareListChip from './CompareListChip';
import { updateIsComparisonListOpen } from 'actions/advanceSearchActions';

const CompareList = (props) => {
  const {
    onReportPageClick,
    customSearchField,
    onRemoveCompare,
    isCustomSearch
  } = props;

  const isComparisonListOpen = useSelector(state => state.advanceSearch.isComparisonListOpen);
  const dispatch= useDispatch();

  const handleToggleBtnClick = () => {
    dispatch(updateIsComparisonListOpen(!isComparisonListOpen));
  };
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));
  const currentSearchField = useSelector(state => _.get(state.advanceSearch, 'searchField', ''));
  const searchProperty = useSelector(state => state.advanceSearch.searchProperty);
  const searchMethod = _.get(searchProperty, 'searchMethod');
  const subjectData = useSelector(state => state.advanceSearch.subjectData);
  const reportPageData = useSelector(state => state.advanceSearch.reportPageData);
  const selectedReportRowIds = useSelector(state => state.advanceSearch.selectedReportRowIds);

  useEffect(()=>{
    if (isCustomSearch){
      dispatch(updateIsComparisonListOpen(!_.isEmpty(selectedReportRowIds)));
    }
  }, [selectedReportRowIds]);

  const renderCompareChip = () => {
    return (
      <div className='d-flex flex-column gap-15'>
        {
          !isCustomSearch && <CompareListChip
          compareData={subjectData}
          isCustomSearch={isCustomSearch}
          customSearchField={currentSearchField['field']}
          isSubjectData={true}
          templateId={templateId}
          searchMethod={searchMethod}
        />
        }
        {
          _.map(reportPageData, (compareData) => {
              return (
                <CompareListChip
                  compareData={compareData}
                  isCustomSearch={isCustomSearch}
                  searchMethod={searchMethod}
                  customSearchField=
                    {_.isEmpty(customSearchField) ? currentSearchField['field'] : customSearchField}
                  isSubjectData=''
                  templateId={templateId}
                  onRemoveCompare={onRemoveCompare}
                />
              );
            })
        }
      </div>
    );
  }
  return (
    <>
    {isCustomSearch && _.isEmpty(selectedReportRowIds) ? null :
    <div className={`comparelist-side-panel ${isComparisonListOpen ? '' : 'toggle-close'}`}>
      <div className="comparelist-panel-wrapper">
        <div className="comparelist-panel-header">
          <div className="section-titles forge-typography--overline mb-0">COMPARE LIST</div>
            <ForgeIconButton toggle dense={true} onClick={handleToggleBtnClick}>
              <button type="button" aria-label="Toggle favorite">
                <ForgeIcon name="close" ForgeIcon-button-on></ForgeIcon>
                <ForgeIcon name="close"></ForgeIcon>
              </button>
            </ForgeIconButton>
        </div>
        <div className="comparelist-panel-body">
          {renderCompareChip()}
          <div className="mt-3 d-flex">
            <div>
              <ForgeButton type="raised">
                <button type="button" onClick={onReportPageClick}>
                  <span>Generate report</span>
                </button>
              </ForgeButton>
            </div>
            <div className='pl-3'>
              <ForgeButton>
                <button type="button" onClick={() =>  onRemoveCompare()}
                  disabled={_.isEmpty(selectedReportRowIds)}>
                  <span>Clear all</span>
                </button>
              </ForgeButton>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
}


CompareList.propTypes = {
  onReportPageClick: PropTypes.func,
  onRemoveCompare: PropTypes.func,
  isCustomSearch: PropTypes.bool,
  customSearchField: PropTypes.string,
};

export default CompareList;