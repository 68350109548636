import _ from 'lodash';
import turfConvex from 'turf-convex';

import {
  getMapIncidentStyle,
  isEnableEsriBaseMap,
  getEsriBaseMapLink
} from 'common/config/templateConfiguration';
import iconNameToCodeMap from 'common/fonts/mapIcons.json';
import { getApiParamsForMap } from 'helpers/apiParamsHelper';
import { getMapStyle } from 'appConstants';
import { getCurrentFilterDateRange, getComparisonPeriodDateRanges } from 'helpers/dateHelper';
import { SOURCES } from 'modules/Map/partials/ShapeFilterPartial';

export const DEFAULT_COLOR = '#FAF0E6';
const STYLE_BY_KEY = 'incident_type';
export const DEFAULT_MAP_POINT_COLOR = '#e41a1c';

export const getMapStackClusterRadius = () => {
  return 20;
};

export const getMapStackCircleRadius = () => {
  return 10;
};

export const getMapStackCircleHighlightRadius = () => {
  return 13;
};

export const getMapIncidentIconSize = () => {
  return 10*1.2
};

export const getMapIncidentCircleRadius = () => {
  return 10;
};

export const getMapIncidentDefaultStyle = () => {
  return {"color": "#e41a1c","iconName": "asterisk"};
};

export const getIconCode = (iconName) => {
  return iconName ? String.fromCharCode(iconNameToCodeMap[iconName]) : '';
};

export const getPaintPropertyForPointIcon = () => {
  const styleConfig = getMapIncidentStyle();
  const defaultStyleConfig = getMapIncidentDefaultStyle();

  if (_.isEmpty(styleConfig)) {
    if (_.isEmpty(defaultStyleConfig.iconName)) {
      return null;
    }
    return getIconCode(defaultStyleConfig.iconName);
  }

  let paintProperty = [
    'match',
    ['to-string', ['get', STYLE_BY_KEY]]
  ];

  _.each(styleConfig, ({ name, iconName }) => {
    paintProperty.push(name, getIconCode(iconName));
  });

  paintProperty.push(getIconCode(defaultStyleConfig.iconName));
  return paintProperty;
};

export const getPaintPropertyForPointColor = () => {
  const styleConfig = getMapIncidentStyle();
  const defaultStyleConfig = getMapIncidentDefaultStyle();
  const incidentColor = _.get(getMapIncidentDefaultStyle(), 'color') || DEFAULT_MAP_POINT_COLOR;

  if (_.isEmpty(styleConfig)) {
    if(_.isEmpty(defaultStyleConfig.color)){
      return DEFAULT_MAP_POINT_COLOR;
    }
    return defaultStyleConfig.color;
  }

  let paintProperty = [
    'match',
    ['to-string', ['get', STYLE_BY_KEY]]
  ];

  _.each(styleConfig, ({ name, color }) => {
    color = _.isEmpty(color) ? incidentColor : color;
    paintProperty.push(name, color);
  });

  paintProperty.push(defaultStyleConfig.color || DEFAULT_MAP_POINT_COLOR);
  return paintProperty;
};

export const getMapForExportImage = (
  commonFilters,
  isComparisonEnabled,
  previousPeriodMap,
  currentPeriodMap
) => {
  const { dateRange } = commonFilters;
  const comparisonDateRange = getComparisonPeriodDateRanges(commonFilters)[0];
  const currentDateRange = getCurrentFilterDateRange(dateRange, 'll', dateRange);
  const previousDateRange = getCurrentFilterDateRange(comparisonDateRange, 'll');
  const previousMap = isComparisonEnabled ? {
    map: previousPeriodMap,
    dateRange: previousDateRange
  } : null
  const currentMap = {
    map: currentPeriodMap,
    dateRange: isComparisonEnabled ? currentDateRange : null
  }
  const map = {
    currentMap,
    previousMap
  }
  return map
}

// get a set of bounds for the given features, bounds are used to fit in the map
export const getFeatureCollectionBounds = (featureCollection) => {
  var latMin = Infinity,
  latMax = -99999,
  lngMin = Infinity,
  lngMax = -99999;
  _.each(featureCollection.features, function (feature) {
    var coords = feature.geometry.coordinates,
    multiPolygonCoords = (feature.geometry.type == 'Polygon') ? [coords] : coords;
    _.each(multiPolygonCoords, function (polygonCoordsAndHoles) {
      _.each(polygonCoordsAndHoles[0], function (coords) {
        var latLng = new mapboxgl.LngLat(Number(coords[0]), Number(coords[1])).wrap(),
        lng = latLng.lng,
        lat = latLng.lat;
        if (lng > lngMax) {
          lngMax = lng;
        }
        if (lng < lngMin) {
          lngMin = lng;
        }
        if (lat > latMax) {
          latMax = lat;
        }
        if (lat < latMin) {
          latMin = lat;
        }
      });
    });
  });

  return [
    [lngMin, latMin],
    [lngMax, latMax]
  ];
};

export const getLngLatBounds = (selectedShapesExtent) => {
  if(_.isArray(selectedShapesExtent)) {
    return selectedShapesExtent;
  } else if(_.isObject(selectedShapesExtent)){
    let lngLatArr = [];
    _.each(_.values(selectedShapesExtent), (lngLat) => {
      lngLatArr.push([ lngLat.lng, lngLat.lat ]);
    });
    return lngLatArr;
  } else {
    return [];
  }
};
// Retrieves the snappedFeatures in a cluster.
export async function getSnappedFeaturesInCluster(feature, map) {
  const layerId = _.get(feature, 'layer.id');
  const featureCoordinates = _.get(feature, 'geometry.coordinates');
  const featureClusterId = _.get(feature, 'properties.cluster_id');
  const featureTileCoordinate = getTileCoordinateForLatLng(map, featureCoordinates);
  const limit = 1000;
  const page = 0;
  const sourceId = _.get(map.getLayer(layerId), 'source');

  return new Promise((resolve, reject) => {
    if (!feature.properties.cluster) {
      return resolve([feature]);
    }

    map.getSource(sourceId).
      getClusterLeaves(featureTileCoordinate, featureClusterId, limit, page, (err, leaves) => {
        if (err === null) {
          resolve(leaves);
        } else {
          reject(err);
        }
      });
  });
}

// Background:
//    (Refer issue: https://github.com/mapbox/mapbox-gl-js/issues/5639), mapboxgl
// rounds off geometries of features that we send in for rendering. While fetching the feature that
// was clicked on, it returns the feature with a rounded of geometry based on the current zoom level.
// snappedFeatures =>
//    features grouped by snapped-point(snap_to_grid(point_column)) retrieved via soql on tile calls.
//
// On clicking of a snappedFeature or a stack(group of stacked feature), we get the snappedFeatures
// from mapbox. Since the geometries are rounded of by mapbox-glbased on current zoom level.
// We prepare a bounding polygon encompassing all the features with mapbox's rounding factor.
export const getRoundedOffBoundingPolygon = (pointFeatures, map) => {
  const perPixelLatLngRange = getPerPixelLatLngRange(map);

  const roundedOffBoundaryPointFeatures = _.chain(pointFeatures)
    .map((snappedFeature) => {
      const lng = _.get(snappedFeature, 'geometry.coordinates[0]');
      const lat = _.get(snappedFeature, 'geometry.coordinates[1]');

      if (!_.isNumber(lat) || !_.isNumber(lng)) {
        // A rendered point will always have a geometry and coordinates.
        throw new Error(`Unexpected lat lng in feature lat: ${lat}, lng: ${lng}`);
      }
      // [NE, SE, SW, NW]
      return [
        buildGeojsonFeature([lng + perPixelLatLngRange.lngDiff / 2, lat + perPixelLatLngRange.latDiff / 2]),
        buildGeojsonFeature([lng + perPixelLatLngRange.lngDiff / 2, lat - perPixelLatLngRange.latDiff / 2]),
        buildGeojsonFeature([lng - perPixelLatLngRange.lngDiff / 2, lat - perPixelLatLngRange.latDiff / 2]),
        buildGeojsonFeature([lng - perPixelLatLngRange.lngDiff / 2, lat + perPixelLatLngRange.latDiff / 2])
      ];
    })
    .compact()
    .flatten()
    .value();

  return getBoundingPolygonFor(roundedOffBoundaryPointFeatures);
};

// Gets a path to the given icon as an SVG. Note that `domain` must be set to a Socrata-hosted domain.
// The default of `window.location.hostname` will work in most cases, but visualization code will need
// to pass in the VIF data source domain as visualizations may be embedded on 3rd party sites.
export const getCharmSvgSrc = (charmName) => {
  return !_.isEmpty(charmName) ? `/charms/svg/${charmName}.svg` : '';
};

export const getMapAttributes = (props) => {
  const {
    center,
    currentMapStyleEntry,
    currentMapView,
    drilldown,
    mapOptions,
    shapeGroupId,
    zoom,
    commonFilters,
    polygonsGeojson,
    selectedShapeIds,
    selectedShapesExtent,
    isSideBar
  } = props;
    const currentPeriodApiParams = getApiParamsForMap({ drilldown, shapeGroupId, mapOptions, commonFilters });
    const previousPeriodApiParams = getApiParamsForMap(
      {
        drilldown,
        shapeGroupId,
        mapOptions,
        commonFilters
      },
      _.get(commonFilters, 'comparisonModeOn', false)
    );
    // TODO: Need to pass previousPeriodApiParams as empty if comparisonModeOn is off.
    const mapAttributes = {
      center,
      currentMapStyleEntry,
      currentMapView,
      drilldown,
      isDrilldownVisualizationMap: true,
      shapeGroupId,
      zoom,
      polygonsGeojson,
      selectedShapeIds,
      selectedShapesExtent,
      isSideBar
    };
    const currentPeriodMapAttributes = _.merge({}, mapAttributes, { apiParams: currentPeriodApiParams });
    const previousPeriodMapAttributes = _.merge({}, mapAttributes, { apiParams: previousPeriodApiParams });

    return { currentPeriodMapAttributes, previousPeriodMapAttributes };
}

// Given a set of features, it returns a polygon feature, that encompases all the points.
function getBoundingPolygonFor(pointFeatures) {
  if (pointFeatures.length < 3) {
    return null;
  }

  return turfConvex({
    type: 'FeatureCollection',
    features: pointFeatures
  });
}

function buildGeojsonFeature(coords) {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: coords
    }
  };
}

// For the maps current zoom/location, it returns the lat/lng range that each pixel takes.
// For instance on very wide zoom level (like when viewing the whole world) each pixel
// would almost take 1-2 degrees.
function getPerPixelLatLngRange(map) {
  return {
    lngDiff: map.unproject([1, 0]).lng - map.unproject([0, 0]).lng,
    latDiff: map.unproject([0, 1]).lat - map.unproject([0, 0]).lat
  };
}

// Params:
//    map         : mapboxgl map instance
//    coordinates : [lng, lat]
// Returns:
//    the tile coordinates(row, column, zoom) of the tile in which the
//    given coordinates fall for the current zoom level.
function getTileCoordinateForLatLng(map, coordinates) {
  const tileCoordinate = map.transform.locationCoordinate({
    lng: coordinates[0],
    lat: coordinates[1]
  });

  tileCoordinate.row = Math.floor(tileCoordinate.row);
  tileCoordinate.column = Math.floor(tileCoordinate.column);
  tileCoordinate.zoom = Math.floor(tileCoordinate.zoom);

  return tileCoordinate;
}

export const getShapeIdsAndExtent = (map, selectedShapeIds, shapeId, source = SOURCES.SHAPES) => {
  let newSelectedShapeIds;

  if (_.includes(selectedShapeIds, shapeId)) {
    newSelectedShapeIds = _.without(selectedShapeIds, shapeId);
  } else if (_.isNil(shapeId)) {
    newSelectedShapeIds = [];
  } else {
    newSelectedShapeIds = selectedShapeIds.concat([shapeId]);
  }

  let filter = ['all', ['in', 'shape_id'].concat(newSelectedShapeIds)];
  if (_.isEmpty(newSelectedShapeIds)) {
    filter = ['any', ['!in', 'shape_id', ''], ['in', 'shape_id', '']];
  }
  const selectedShapeFeatures = map.querySourceFeatures(source , { filter });

  let selectedShapesExtent = null;
  if (!_.isEmpty(selectedShapeFeatures)){
    selectedShapesExtent = getFeatureCollectionBounds({features: selectedShapeFeatures});
  }

  return { shapeIds: newSelectedShapeIds, shapesExtent: selectedShapesExtent };
}

export function getGeojson(feature = null) {
  let highlightFeatures = [];

  if (!_.isNil(feature)) {
    highlightFeatures = [
      {
        type: 'Feature',
        geometry: feature.geometry
      }
    ];
  }

  return {
    type: 'FeatureCollection',
    features: highlightFeatures
  };
}

export const getTemplateMapStyle = (currentMapStyleEntry, templateId) => {
  let mapStyle = currentMapStyleEntry.style;
  if(isEnableEsriBaseMap(templateId)) {
    mapStyle = getMapStyle(getEsriBaseMapLink(templateId));
  }
  return mapStyle;
}
