import _ from 'lodash';
import React from 'react';
import { getDefaultSortOption } from 'helpers/visualizationHelper';
import { getApiParams } from 'helpers/apiParamsHelper';
import { getSummaryTableCsvData } from 'common/api/drilldown';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { formatValueToCurrency } from 'helpers/numberHelper';

export const getColumnData = (datum, totalValue, options = {}) => {
  const { dimension, count } = datum;
  const { viewEntry, tableData, templateId, isCurrencyType } = options;
  const nullValueLabel = getNullValueLabel(templateId)
  const color = getCategoryColor(datum, tableData, nullValueLabel);
  const percentage = getPercentage(count, totalValue);
  const dimesionName = (formatValueToCurrency(dimension, isCurrencyType) || nullValueLabel);

  return (
    <tr>
      <td className="legend-item text-left">
        <span className="legend-color" style={{ 'backgroundColor': color }}/>
        {dimesionName}
      </td>
      <td className="text-right">{getFormattedNumberValue(count, viewEntry)}</td>
      <td className="text-right">{percentage}</td>
    </tr>
  );
}

const getPercentage = (value, total) => {
  if(_.isUndefined(value) || _.isUndefined(total)) {
    return null;
  }

  const percentage  = ((value / total) * 100);
  return `${percentage.toFixed(2)}%`;
}

const getCategoryColor = (datum, tableData, nullValueLabel) => {
  let chartDataEntry = _.find(tableData, {label: _.get(datum, 'dimension', nullValueLabel)});
  if(_.isUndefined(chartDataEntry)) {
    chartDataEntry = _.find(tableData, {label: 'Others'});
  }

  return _.get(chartDataEntry, 'color')
}

export const summaryTableApiUrl = (options, isCsv = true) => {
  const {
    commonFilters,
    drilldown,
    mapOptions,
    currentDrilldownDimensionField,
    currentDrilldownViewEntry,
    currentBarChartSortType,
    currentDrilldownTemplateId
  } = options;
  const apiParams = getApiParams({commonFilters, drilldown, mapOptions}, {});
  const { sortBy, sortOrder } = getDefaultSortOption(
    currentDrilldownDimensionField,
    currentDrilldownViewEntry,
    currentBarChartSortType,
    currentDrilldownTemplateId);
  return getSummaryTableCsvData(_.merge({}, apiParams, {
    page: 0, ignoreGroupBy: true, sortBy, sortOrder, isGroupChart: false
  }), isCsv);
}
