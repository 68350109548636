import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import SearchTabBar from './SearchResultPage/SearchTabBar';
import TabViewContent from './TabViewContent';

import { getAdvanceSearchResult } from 'common/api/advanceSearchApi';
import {
  updateSelectedReportIds,
  updateReportPageData,
  updateSubjectData,
  updateSearchPage,
  updateSubjectRowId,
} from 'actions/advanceSearchActions';
import { ADVANCE_SEARCH_PAGE } from 'appConstants';
import CompareList from './CustomSearch/CompareList';

import LoadingSpinner from 'common/components/LoadingSpinner';
import QuickFilter from './QuickFilter/QuickFilter';
import BookmarkList from './BookmarkSave/BookmarkList';
const RightSideContainer = ({ onMapLoaded }) => {
  const dispatch = useDispatch();
  const searchField = useSelector(state => state.advanceSearch.searchField);
  const reportPageData = useSelector(state => state.advanceSearch.reportPageData);
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));
  const selectedReportRowIds = useSelector(state => state.advanceSearch.selectedReportRowIds);
  const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));
  const [detailsData, setDetailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('table');
  const isApplyFilters = (!_.isEmpty(searchFilters) || !_.isEmpty(selectedShapeIds))

  const fetchDetailsData = async () => {
    const params = {
      advanceSearchSubjectField: JSON.stringify(searchField),
      limit: 50000,
      currentDrilldownTemplateId: templateId
    };
    setIsLoading(true);
    const response = await getAdvanceSearchResult(params);
    dispatch(updateSubjectRowId(_.get(_.first(response), 'row_id_field')));
    dispatch(updateSubjectData(_.first(response)));
    setDetailsData(response);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchDetailsData();
  }, []);

  const updateSelectedTab = (event) => {
    setSelectedTab(event.detail.value);
  }
  const tabViewAttributes = { selectedTab, isLoading, data: detailsData, onMapLoaded };

  const onReportPageClick = () => {
    dispatch(updateSearchPage(ADVANCE_SEARCH_PAGE.REPORT_PAGE));
  }

  const onRemoveCompare = (data) => {
    // Data is passed empty if need to clear all the data.
    if(_.isEmpty(data)){
      dispatch(updateSelectedReportIds([]));
      dispatch(updateReportPageData([]));
    } else {
      const id = _.get(data, 'row_id_field');
      const newSelectedReportRowIds = _.filter(selectedReportRowIds, (newId) => id !== newId);
      dispatch(updateReportPageData(
        _.filter(reportPageData, (selectedItem) => selectedItem['row_id_field'] !== id)
      ));
      dispatch(updateSelectedReportIds(newSelectedReportRowIds));
    }
  };

  const mainClass = classNames('property-right-side', {
    'add-view-report': !_.isEmpty(selectedReportRowIds)
  });

  const renderEmptyPage = () => {
    return !isApplyFilters && (
      <div className="property-search-container custom-serach-empty-state h-100">
        <div className="property-wrapper">
          <img src='https://cdn.forge.tylertech.com/v1/images/spot-hero/real-estate-spot-hero.svg'
            alt='property' />
          <div>
            To begin your search, first specify which properties you are looking for
          </div>
          <div className="custom-search-filter-wrapper">
            <div className="filter-bookmark">
              <QuickFilter isRightSideContainer={true}/>
              <span>or</span>
              <BookmarkList isEmptySelection={true}/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderComparePage = () => {
    return (
      <CompareList
        isCustomSearch={false}
        onReportPageClick={onReportPageClick}
        onRemoveCompare={onRemoveCompare}
      />
    )
  }

  return (
    <div className={mainClass}>
      <LoadingSpinner isLoading={isLoading} />
      {isApplyFilters &&
        <>
          <div className='property-sticky-table'>
            <SearchTabBar
              selectedTab={selectedTab}
              updateSelectedTab={updateSelectedTab}
              templateId={templateId}
              onReportPageClick={onReportPageClick}
              searchFilters={searchFilters}
              selectedShapeIds={selectedShapeIds}
            />
          </div>
          <TabViewContent {...tabViewAttributes} />
        </>
      }
      {renderEmptyPage()}
      {renderComparePage()}
    </div>
  );
}

RightSideContainer.propTypes = {
  onMapLoaded: PropTypes.func
};

export default RightSideContainer;
