import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { FILTER_SELECT_ALL } from 'appConstants';

const FilterBadges = (props) => {
  const { values, onRemoveItem, isCurrencyRenderType } = props;
  const filterValues = _.filter(values, (datum) => {
     return datum != FILTER_SELECT_ALL 
  });

  const onKeydownFilterBadgeItem = (e, valueItem) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      onRemoveItem(valueItem);
    }
  }

  const renderFilterBadges = _.map(filterValues, (valueItem, index) => {
    return (
      <div className="value-chip" key={index} tabIndex={0}>
        {formatValueToCurrency(valueItem + '', isCurrencyRenderType)}
        <i
          tabIndex={0}
          aria-label="remove filtered value"
          className="icons-close tx-8"
          onKeyDown={(e) => onKeydownFilterBadgeItem(e, valueItem)}
          onClick={() => onRemoveItem(valueItem)}
        >
        </i>
      </div>
    );
  });

  return (
    <div className="d-flex flex-wrap gap-8 filter-badge-chip">
      {renderFilterBadges}
    </div>
  );
}

FilterBadges.propTypes = {
  values: PropTypes.array.isRequired,
  onRemoveItem: PropTypes.func,
  isCurrencyRenderType: PropTypes.bool
}

export default FilterBadges;
