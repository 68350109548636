// vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

// Project Imports
import { isClickOutSideFilter } from 'common/components/Filters/FilterDomHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getQuickFilterEntriesFromTemplate,
  getLogicalOperatorEntry } from 'pages/drilldown/components/QuickFilterBar/helper';
import {
  getCurrentTemplateEntry,
  getCurrentViewEntry,
  getNullValueLabel
 } from 'common/config/templateConfiguration';
import { getGlobalFiltersFromTemplate } from 'common/config/customerConfiguration';
import {
  STRING_TYPES_FIELD,
  BOOLEAN_TYPES_FIELD,
  CURRENCY_TYPE
} from 'appConstants';
import {
  getFilterConditions,
  isCurrencyRenderType
} from 'common/components/Filters/helper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { OTHER_BUCKET_VALUE } from 'modules/visualization/constants';
import {
  getGlobalFiltersFromCardEntries
} from 'pages/dashboard/components/Collections/collectionHelper';
import { getDifferenceCollectionFilters } from 'helpers/FilterIconHelper';

class RadarFilterIcon extends Component {
  state = {
    showFilterContent: false,
    filterCount: 0,
    radarFilters: []
  };

  componentDidMount() {
    this.fetchRadarFilters();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { showFilterContent } = this.state;
    if (isClickOutSideFilter(e, this.filterRef) && showFilterContent) {
      this.setState({ showFilterContent: false });
    }
  }

  handleEnterButtonPressed = (event) => {
    if (isEnterButtonPressed(event)) {
      this.toggleAddFilterButton(event);
    }
  }

  toggleAddFilterButton = (e) => {
    const { showFilterContent } = this.state;
    e.stopPropagation();

    this.setState({ showFilterContent: !showFilterContent });
  }

  renderFilterChipLabel = () => {
    const { filterCount } = this.state;
    return (
      <div
        className="filter-chip-values filter-count-values filter-chip-active"
        tabIndex={0}
        onClick={this.toggleAddFilterButton}
        onKeyDown={this.handleEnterButtonPressed}>
        <forge-icon name="filter_variant_check" class="filter-icon tx-18" />
        <div className="chip-values-text">
          <span className="font-weight-bolds">
            {filterCount}
          </span>
        </div>
      </div>
    );
  }

  getFilterMetricEntry = (filter) => {
    const templateEntry = getCurrentTemplateEntry(filter.templateId);
    const viewEntry = getCurrentViewEntry(filter.templateId, filter.viewId);
    const quickFilterEntries = getQuickFilterEntriesFromTemplate(
      filter.templateId,
      viewEntry
    );
    const filterEntries = _.startsWith(filter.field,'global_filter') ?
      getGlobalFiltersFromTemplate(templateEntry) : quickFilterEntries;
    return filterEntries;
  }

  fetchRadarFilters =() => {
    const { radarOptions, radarGlobalFilter } = this.props;
    const { comparisonAreaName, comparisonTracts, selectAreaName,
      selectedTracts, selectedShapeAreaEntry } = radarOptions;
    const selectedMetricEntries = _.get(radarOptions, 'selectedMetricEntries', []);
    let metricEntryFilters = _.get(radarOptions, 'metricFilters', []);

    const globalfilters = _.flatten(_.map(radarGlobalFilter, (datum) => {
      let filters = datum.filters;
      filters = filters.map(value => {
        value.templateId = _.get(datum,'templateId');
        value.viewId = _.get(datum,'viewId');
        return value;
      });
      return filters;
    }));

    const metricFilters = _.flatten(_.map(metricEntryFilters, (datum) => {
      let filters = datum.filters;
      const metricDetail = _.find(selectedMetricEntries, { id: datum.id }) || {};
      filters = filters.map(value => {
        value.templateId = _.get(metricDetail,'template_id');
        value.viewId = _.get(metricDetail,'view_id');
        return value;
      });
      return filters;
    }));

    let filters = _.concat(globalfilters,metricFilters);

    let uniqueFilters = _.uniqWith(filters, (filter1, filter2) => {
      const filterItem1 = _.omit(filter1, ['templateId','viewId']);
      const filterItem2 = _.omit(filter2, ['templateId','viewId']);
      return _.isEqual(filterItem1, filterItem2);
    });

    uniqueFilters = _.compact(_.map(uniqueFilters, (filter) => {
      const filterEntries = this.getFilterMetricEntry(filter);
      filter['filterEntries'] = filterEntries;
      const matchedFilterEntry = _.find(filterEntries, { field: filter.field });
      if(matchedFilterEntry){
        return filter;
      }
    }));

    if(!_.isEmpty(selectedTracts)){
      uniqueFilters.push(
        {
          column: _.get(selectedShapeAreaEntry,'name',''),
          values: selectedTracts,
          field: selectAreaName,
          type:"mini_map"
        }
      );
    }

    if(!_.isEmpty(comparisonTracts)){
      uniqueFilters.push(
        {
          column: _.get(selectedShapeAreaEntry,'name',''),
          values: comparisonTracts,
          field: comparisonAreaName,
          type:"mini_map"
        }
      );
    }

    this.setState({
      radarFilters: uniqueFilters,
      filterCount: _.size(uniqueFilters)
    });

  }

  renderFilterItems = () => {
    const { radarFilters } = this.state;

    if (_.isEmpty(radarFilters)) {
      return null;
    }

    return _.map(radarFilters, (filter) => {
      if (filter.type == 'mini_map' ){
        return this.renderFilterItem(filter, 'mini_map');
      }
      if (_.get(filter, 'type') === 'text') {
        return _.map(filter.conditions, (filterCondition) => {
          const newFilter = { ...filter, conditions: [filterCondition] };
          return this.renderFilterItem(newFilter, 'quick_filter', filter['filterEntries']);
        });
      }
      else{
        return this.renderFilterItem(filter, 'quick_filter', filter['filterEntries']);
      }

    });
  }

  renderFilterChipValue = (filter, filterEntries) => {
    const { field, templateId } = filter;
    const { radarFilters } = this.state;
    const defaultEntry = { renderType: STRING_TYPES_FIELD, name: '' };
    const { name, renderType } = _.find(filterEntries, { field }) || defaultEntry;
    const filterConditions = getFilterConditions({
      filter, filterEntries, type: "quickFilters", filters: radarFilters
    });

    return _.map(filterConditions, (filterCondition, index) => {
      const { value: filterValues, operator: filterOperator, isOthersBucket } = filterCondition;
      let operatorEntry = {};
      const newRenderType = (renderType === BOOLEAN_TYPES_FIELD || renderType === CURRENCY_TYPE) ?
        STRING_TYPES_FIELD :
        renderType;
      if (filterOperator) {
        operatorEntry = getLogicalOperatorEntry(filterOperator, newRenderType || STRING_TYPES_FIELD);
      }
      const operatorName = isOthersBucket ? 'is' : _.lowerCase(_.get(operatorEntry, 'name', ''));
      const filterName = `${name} ${operatorName} `;
      const filterValuesContent = _.map(filterValues, (valueItem) => {
        return _.isNil(valueItem) ?
          getNullValueLabel(templateId) :
          formatValueToCurrency(valueItem, isCurrencyRenderType(filterEntries, filter));
      }).join(' or ');

      return (
        <div className="chip-values-text" key={index}>{filterName}
          <span className="font-weight-bold">
            {isOthersBucket ? OTHER_BUCKET_VALUE : filterValuesContent}
          </span>
        </div>
      );
    });
  }

  renderMiniMapFilterChipValue = (filter) => {
    return (
      <div className="chip-values-text">{`${filter.field} ${filter.column} is `}
        <span className="font-weight-bold">
          {_.join(filter.values, ' or ')}
        </span>
      </div>
    );
  }

  renderFilterItem = (filter, type, filterEntries = [], isUnselect = false) => {
    const filterChipClassName = classNames('global-filter', {
      'unselect': isUnselect
    });
    return (
      <div className="filter-badge">
        <div className="collection-filter-badge">
          <div className={filterChipClassName}>
            <div className="filter-chip" tabindex="0">
              <div className="filter-chip-values">
                <forge-icon aria-hidden="true" name="filter_list" class="tx-18"></forge-icon>
                { type == 'mini_map' ?
                  this.renderMiniMapFilterChipValue(filter) :
                  this.renderFilterChipValue(filter,filterEntries) }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFilterBody = () => {
    return(
      <div className="filters-body filters-container mb-5">
        <div className="d-flex align-items-center flex-wrap gap-8">
          {this.renderFilterItems()}
        </div>
      </div>
    )
  }

  renderUnselectFilterBody = () => {
    return(
      <div className="filters-body filters-container mb-5">
        <div className="d-flex align-items-center flex-wrap gap-8">
          {this.renderUnselectFilterChips()}
        </div>
      </div>
    )
  }

  renderUnselectFilterChips = () => {
    const { collectionEntry, cardEntries, radarGlobalFilter } = this.props;
    const globalfilters = _.flatten(_.map(radarGlobalFilter, 'filters'));
    const currentCollectionFilters = _.get(collectionEntry, 'collectionFilters', []);
    const collectionFilters = getGlobalFiltersFromCardEntries(cardEntries, currentCollectionFilters );
    const unSelectedFilters = getDifferenceCollectionFilters(
      _.cloneDeep(collectionFilters),
      _.cloneDeep(globalfilters)
    );

    if (_.isEmpty(unSelectedFilters)) {
      return null;
    }

    return _.map(unSelectedFilters, (filter) => {
      const globalFilterDetails = _.find(globalfilters, {field : filter.field});
      if (_.get(filter, 'type') === 'text') {
        return _.map(filter.conditions, (filterCondition) => {
          const newFilter = { ...globalFilterDetails, conditions: [filterCondition] };
          return this.renderFilterItem(newFilter, 'quick_filter', globalFilterDetails['filterEntries'], true);
      });
    }
    else{
      this.renderFilterItems(filter, 'quick_filter', globalFilterDetails['filterEntries'], true)
    }
    });
  }

  render() {
    const { showFilterContent, filterCount } = this.state;
    if (filterCount < 1) {
      return null;
    }
    const filterClassNames = classNames('global-filter filter-count',
      { 'show-filter-chip': showFilterContent }
    );

    return (
      <>
        <div className="metric-card-filter-count" ref={(ref) => this.filterRef = ref}>
          <div className={filterClassNames}>
            <div className="filter-chip">
              {this.renderFilterChipLabel()}
              <div className="filter-chip-show">
                <div className="common-filters-wrapper pt-0">
                  {this.renderFilterBody()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="metric-card-collection-filters">
            {this.renderUnselectFilterBody()}
        </div>
      </>
    );
  }
}

RadarFilterIcon.propTypes = {
  radarOptions: PropTypes.object,
  radarGlobalFilter: PropTypes.array,
  collectionEntry: PropTypes.object,
  cardEntries: PropTypes.array
};

RadarFilterIcon.defaultProps = {
  radarOptions: {},
  radarGlobalFilter: [],
  collectionEntry: {},
  cardEntries: []
};

export default RadarFilterIcon;
