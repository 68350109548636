import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getShapeOutlineTileUrl } from 'common/api/map';

const SOURCES = {
  SHAPES_OUTLINE: 'shapes-outline-source'
};
const LAYERS = {
  SHAPE_OUTLINE_LAYER: 'shape-outlines-layer'
};

class ShapeOutlinePartial extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.initSourcesAndLayers();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.selectedOutlines, this.props.selectedOutlines)){
      this.removeSourcesAndLayers();
      this.initSourcesAndLayers();
    }
  }

  initSourcesAndLayers(){
    // Initialize sources and Layers only when outlines are selected
    if(!_.isEmpty(this.props.selectedOutlines)){
      this.initSources();
      this.initLayers();
    }
  }

  initSources() {
    const { map, selectedOutlines, currentDrilldownTemplateId } = this.props;
    const selectedOutlineDatasets = _.map(selectedOutlines, 'shape_outline_dataset_id').join(',');
    const queryParams = { selectedOutlineDatasets, currentDrilldownTemplateId };

    map.addSource(SOURCES.SHAPES_OUTLINE, {
      type: 'vector',
      'geojsonTile': true,
      'tiles': [getShapeOutlineTileUrl(queryParams)]
    });
  }

  initLayers() {
    const { map } = this.props;

    map.addLayer({
      'id': LAYERS.SHAPE_OUTLINE_LAYER,
      'type': 'line',
      'source': SOURCES.SHAPES_OUTLINE,
      'source-layer': '_geojsonTileLayer',
      'paint': {
        'line-color': {
          'type': 'identity',
          'property': 'color'
        },
        'line-width': 2
      }
    },'hover-point');
  }

  removeSourcesAndLayers() {
    const { map } = this.props;

    _.each(LAYERS, (layerId) => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });
    _.each(SOURCES, (sourceId) => {
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    });
  }

  render() {
    return null;
  }
}

ShapeOutlinePartial.propTypes = {
  map: PropTypes.object,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedOutlines: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    selectedOutlines: _.cloneDeep(_.get(state, 'visualization.mapOptions.selectedOutlines')),
  };
};

export default connect(mapStateToProps, null)(ShapeOutlinePartial);
