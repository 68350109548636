import moment from 'moment';
import jsPDF from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import { getAdvanceSearchTableContainer } from 'helpers/exportImageHelper';
import { getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
import { buildQueryString } from 'helpers/HttpHelper';
import { showExternalProperty } from 'common/config/customerConfiguration';

export const pdfDownload = async (container, downloadOption) => {
  const { templateId, isAddExternalDocument, downloadStatusUpdate } = downloadOption;
  const summaryTableImage = await getAdvanceSearchTableContainer(container.current);

  const { summaryTitle, pdfSummaryHeight } = getPdfPageDimensions(summaryTableImage, templateId);

  const isOverFlowHeight = pdfSummaryHeight > 210;
  const pdfOption = {
    orientation: isOverFlowHeight ? '' : "l",
    unit: "mm",
    format: isOverFlowHeight ? [summaryTableImage.width + 10, summaryTableImage.height + 10] : "a1"
  }

  const pdf = new jsPDF(pdfOption);
  if (!isOverFlowHeight) {
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const imgSummaryProps = pdf.getImageProperties(summaryTableImage);
    const pdfTableHeight = (imgSummaryProps.height * pdfWidth) / imgSummaryProps.width;
    pdf.setFontSize(30);
    pdf.text(summaryTitle, 10, 15);
    pdf.addImage(summaryTableImage, 'jpeg', 5, 25, pdfWidth - 5, pdfTableHeight - 5);
  } else {
    pdf.setFontSize(50);
    pdf.text(summaryTitle, 70, 40);
    pdf.addImage(summaryTableImage, 'jpeg', 65, 55,
      summaryTableImage.width - 30, summaryTableImage.height - 20);
  }

  if (isAddExternalDocument){
    const externalPdfUrls = getExternalPdfUrls(downloadOption);
    downloadPdfWithExternalPdf(pdf, templateId, externalPdfUrls, downloadStatusUpdate);
  } else {
    downloadStatusUpdate('complete');
    pdf.save(getFileName(templateId));
  }
}

export const downloadMapPdf = (map, templateId) => {
  const mapImageCanvas = map.getCanvas('image/jpeg');
  const mapPdf = new jsPDF('l');
  const { mapTitle } = getPdfPageDimensions(mapImageCanvas, templateId);

  mapPdf.text(mapTitle, 10, 10);
  mapPdf.addImage(mapImageCanvas, 'jpeg', 10, 15, 275, 0);
  mapPdf.save(getFileName(templateId, true));
}

export const pdfDownloadBoth = async (downloadOption, map) => {
  const { tableContainer, templateId, isAddExternalDocument, downloadStatusUpdate } = downloadOption;
  const mapImageCanvas = map.getCanvas('image/jpeg');
  const summaryTableImage = await getAdvanceSearchTableContainer(tableContainer.current);
  const { pdfSummaryHeight, mapTitle, summaryTitle } = getPdfPageDimensions(summaryTableImage, templateId);

  const isOverFlowHeight = pdfSummaryHeight > 210;
  const pdfOption = {
    orientation: isOverFlowHeight ? '' : "l",
    unit: "mm",
    format: isOverFlowHeight ?
      [(summaryTableImage.width * 0.9)  + 10, (summaryTableImage.height * 0.9) + 10] : "a1"
  }

  const pdf = new jsPDF(pdfOption);
  if (!isOverFlowHeight) {
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const imgSummaryProps = pdf.getImageProperties(summaryTableImage);
    const pdfTableHeight = ((imgSummaryProps.height * 0.9)  * pdfWidth) / (imgSummaryProps.width * 0.9);

    pdf.text(summaryTitle, 10, 10);
    pdf.addImage(summaryTableImage, 'jpeg', 5, 15, pdfWidth - 5, pdfTableHeight - 5);

    pdf.addPage('a4', 'l');
    pdf.text(mapTitle, 10, 20);
    pdf.addImage(mapImageCanvas, 'jpeg', 10, 30, 275, 0);
  } else {
    pdf.setFontSize(50);
    pdf.text(summaryTitle, 60, 40);
    pdf.addImage(summaryTableImage, 'jpeg', 60, 55,
      summaryTableImage.width * 0.9, summaryTableImage.height * 0.9);

    pdf.addPage('a4', 'l');
    pdf.setFontSize(20);
    pdf.text(mapTitle, 10, 20);
    pdf.addImage(mapImageCanvas, 'jpeg', 10, 30, 275, 0);
  }

  if (isAddExternalDocument){
    const externalPdfUrls = getExternalPdfUrls(downloadOption);
    downloadPdfWithExternalPdf(pdf, templateId, externalPdfUrls, downloadStatusUpdate);
  } else {
    downloadStatusUpdate('complete');
    pdf.save(getFileName(templateId));
  }
}

export const showPrcDownload = (subjectData, reportPageData) => {
  let isExternalPdfAvailable = false;
  isExternalPdfAvailable = _.get(subjectData, 'is_property_details_available', false);
  _.each(reportPageData, (data) => {
    if (!isExternalPdfAvailable) {
      isExternalPdfAvailable = _.get(data, 'is_property_details_available', false)
    }
  });

  return showExternalProperty() && isExternalPdfAvailable;
};

const getPdfPageDimensions = (summaryTableImage, templateId) => {
  const doc = new jsPDF('l', "mm");
  const pdfWidth = doc.internal.pageSize.getWidth();
  const imgSummaryProps = doc.getImageProperties(summaryTableImage);
  const pdfSummaryHeight = (imgSummaryProps.height * pdfWidth) / imgSummaryProps.width;

  const templateName = getTemplateName(templateId);

  return {
    pdfSummaryHeight,
    mapTitle: `${templateName} - Map Report`,
    summaryTitle: `${templateName} - Comparison Report`
  }
}

const getTemplateName = (templateId) => {
  return _.get(getCurrentTemplateEntryById(templateId), 'name');
}

const getFileName = (templateId, isMap = false) => {
  const templateName = getTemplateName(templateId);
  const currentDate = moment().format('YYYYMMDD');
  if (isMap) {
    return `${templateName}_${currentDate}_map.pdf`;
  } else {
    return `${templateName}_${currentDate}.pdf`;
  }
}

const getExternalPdfUrls = (downloadOption) => {
  const { subjectData, reportPageData } = downloadOption;
  let url = [];
  if (!_.isEmpty(subjectData) &&  _.get(subjectData, 'is_property_details_available', false) ){
    url.push(`/api/advanced_search/get_external_pdf.pdf?${buildQueryString(getQueryParams(subjectData))}`)
  }
  _.each(reportPageData, (reportData) => {
    if (_.get(reportData, 'is_property_details_available', false)){
      url.push(`/api/advanced_search/get_external_pdf.pdf?${buildQueryString(getQueryParams(reportData))}`)
    }
  });
  return url;
}

const getQueryParams = (data) => {
  return {
    country: _.get(data, 'comp_finder_country', '').replace(/County/i, "").trim(),
    quickRefId: _.get(data, 'comp_finder_quick_ref_id', ''),
    taxYear: _.get(data, 'comp_finder_tax_year', '')
  };
}

const downloadPdfWithExternalPdf = async (pdf, templateId, externalPdfUrls, downloadStatusUpdate) => {
  const mergedPdf = await PDFDocument.create();
  const jsPdfBytes = pdf.output('arraybuffer');
  const jsPdfDoc = await PDFDocument.load(jsPdfBytes);
  const jsPdfPages = await mergedPdf.copyPages(jsPdfDoc, jsPdfDoc.getPageIndices());
  _.each(jsPdfPages, (page) => mergedPdf.addPage(page));

  const externalPdfBytesArray = await Promise.all(
    externalPdfUrls.map(url => fetch(url).then(res => {
      return res.arrayBuffer();
    }))
  );

  for (const externalPdfBytes of _.compact(externalPdfBytesArray)) {
      try {
          const externalPdf = await PDFDocument.load(externalPdfBytes);
          const externalPdfPages = await mergedPdf.copyPages(externalPdf, externalPdf.getPageIndices());
          _.each(externalPdfPages, (page) => mergedPdf.addPage(page));
      } catch (error) {
          downloadStatusUpdate('error');
          console.error('Error processing PDF:', error);
      }
  }

  const mergedPdfBytes = await mergedPdf.save();
  const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
  const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);

  // Download the merged PDF
  const link = document.createElement('a');
  link.href = mergedPdfUrl;
  link.download = getFileName(templateId);
  link.click();

  // Clean up
  URL.revokeObjectURL(mergedPdfUrl);
  downloadStatusUpdate('complete');
};