export const UPDATE_AXIS_GRANULARITY = 'UPDATE_AXIS_GRANULARITY';
export const UPDATE_FORECAST_OPTION  = 'UPDATE_FORECAST_OPTION';
export const UPDATE_OVERTIME_BENCH_MARK_NAME = 'UPDATE_OVERTIME_BENCH_MARK_NAME';
export const UPDATE_OVERTIME_CHART_VIEW = 'UPDATE_OVERTIME_CHART_VIEW'
export const UPDATE_OVERTIME_COMPARE_TO_DATE_RANGE = 'UPDATE_OVERTIME_COMPARE_TO_DATE_RANGE';
export const UPDATE_OVERTIME_LEGENDS = 'UPDATE_OVERTIME_LEGENDS';
export const UPDATE_OVERTIME_SECONDARY_METRIC = 'UPDATE_OVERTIME_SECONDARY_METRIC';
export const UPDATE_OVERTIME_SHOW_PROJECTION = 'UPDATE_OVERTIME_SHOW_PROJECTION';
export const UPDATE_OVERTIME_TIME_FRAME = 'UPDATE_OVERTIME_TIME_FRAME';
export const UPDATE_SLIDER_RANGE = 'UPDATE_SLIDER_RANGE';
export const UPDATE_VISUALIZATION_DEFAULTS = 'UPDATE_VISUALIZATION_DEFAULTS';
export const UPDATE_OVERTIME_DIMENSION_SORT_ORDER = 'UPDATE_OVERTIME_DIMENSION_SORT_ORDER';
export const UPDATE_OVERTIME_SHOW_LEGEND_TOTAL = 'UPDATE_OVERTIME_SHOW_LEGEND_TOTAL';
export const UPDATE_OVERTIME_IS_EMPTY_LEGEND = 'UPDATE_OVERTIME_IS_EMPTY_LEGEND';
