import React from 'react';
import PropTypes from 'prop-types';

const HeatChartIcon = ({ icon, color }) => {
  return (
    <i className={icon} style={{ color: color }}></i>
  );
}

HeatChartIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string
}

export default (HeatChartIcon);
