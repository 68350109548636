import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NoDataWrapper } from './NoDataWrapper';
class SlaNoDataFound extends Component {
  renderByFilterType() {
      const { slaOption } = this.props;

      let svgImage = '../images/success-empty-data.svg';
      let noDataText = `Great news! All checks are currently passing. 
        To see all checks that were run, update your filter selection.`;
      if(slaOption.chartFilterType === "only_failed_checks"){
        noDataText = `Great news! There are no ${slaOption.name} with all checks failing.
        To see any failing checks, update your filter selection.`
      } else if(slaOption.chartFilterType === "all") {
        noDataText = `No data available 
        (It may be that no data exists or the data has all been filtered out).`;
        svgImage ='../images/no-search-results.svg';
      }

      return (
        <div className="sla-no-data">
          <img src={svgImage} />
          <span className="sla-text-color">{ noDataText }</span>
        </div>
      );
  }

  render(){
    return !this.props.hidden && (
      this.renderByFilterType()
    );
  }
}

SlaNoDataFound.propTypes = {
  slaOption: PropTypes.object,
  hidden: PropTypes.bool
};

// Hoc Component (NoDataWrapper) for 'No Data'
export default NoDataWrapper(SlaNoDataFound);
