import React, { Component } from 'react';

import { getSLAWatchFlyoutEntries, getSLAWatchCalculationType } from 'common/config/customerConfiguration';
import { SLA_CALCULATION_TYPES } from 'appConstants';
const DEFAULT_BAR_LEGEND_VALUE = "Actual Percentage";
const DEFAULT_LINE_LEGEND_VALUE = "Target";
class Legend extends Component {
  renderBar(){
    const flyOutEntry = _.find(getSLAWatchFlyoutEntries(), {id: 'actual_percentage'});
    if(_.isEmpty(flyOutEntry)){
      return null;
    }
    const name = _.isEmpty(flyOutEntry['name']) ? DEFAULT_BAR_LEGEND_VALUE: flyOutEntry['name'];
    return(
      <div className="legend-list">
        <div className="bar"></div>
        <span>{name}</span>
      </div>
    );
  }

  renderLine(){
    const flyOutEntry = _.find(getSLAWatchFlyoutEntries(), {id: 'target_percentage'});
    if(_.isEmpty(flyOutEntry)){
      return null;
    }
    const name = _.isEmpty(flyOutEntry['name']) ? DEFAULT_LINE_LEGEND_VALUE: flyOutEntry['name'];

    return(
      <div className="legend-list">
        <div className="line"></div>
        <span>{name}</span>
      </div>
    );
  }

  render() {
    if(_.isEqual(getSLAWatchCalculationType(), SLA_CALCULATION_TYPES.PERCENTAGE)){
      return null;
    }

    return (
      <div className='d-flex flex-column gap-16'>
        {this.renderBar()}
        {this.renderLine()}
      </div>
    );
  }
}

export default Legend;
