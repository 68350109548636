import moment from 'moment';
import { getFormattedValue } from 'helpers/chartDataHelper';
import {
  OVERTIME_VISUALIZATION_TYPES
} from 'appConstants';
export class FlyoutFormatter {
  constructor(data, options) {
    this.data = data;
    this.options = options;
  }

  getHeader() {
    const granularity = this._getAxisGranularity();
    switch (granularity) {
      case 'week':
        return this._getWeekHeader();
      case 'month':
        return this._getMonthHeader();
      case 'quarter':
        return this._getQuarterHeader();
      case 'year':
        return this._getYearHeader();
      default:
        return this._getDayHeader();
    }
  }

  getContent() {
    return this._getFlyoutContent();
  }

  getAccuracyChartContent() {
    return _.chain(this._filterData())
      .map(data => ({
        title: `${_.get(data, 'data.meta.dimension')}:`,
        value: this._getFormattedValue(_.get(data, 'y')),
        color: _.get(data, 'data.line.color'),
        sortOrder: this._getSortOrder(_.get(data, 'data.meta.dimension'))
      }))
      .sortBy('sortOrder')
      .value();
  }

  _getFlyoutContent() {
    return this._isBeforeCurrentDate() || !this.options.projectionEnabled
      ? this._getTotalFlyoutContent()
      : this._getProjectionFlyoutContent();
  }

  _getAxisGranularity() {
    return this.options.axisGranularity;
  }

  _filterData() {
    const selectedXAxis = _.get(this.data, '0.x');
    return _.filter(this.data, point => !_.isArray(_.get(point, 'x')) && _.get(point, 'x') === selectedXAxis);
  }

  _getMonthHeader() {
    return moment(this._getFirstDataPoint().x).format('MMM YYYY');
  }

  _getYearHeader() {
    return moment(this._getFirstDataPoint().x).format('YYYY');
  }

  _getDayHeader() {
    return moment(this._getFirstDataPoint().x).format('DD MMM YYYY');
  }

  _getWeekHeader() {
    const firstDataPoint = this._getFirstDataPoint();
    return _.get(this._getCustomData(firstDataPoint), 'periodLabel', firstDataPoint.x);
  }

  _getQuarterHeader() {
    return this._getFirstDataPoint().x;
  }

  _isBeforeCurrentDate() {
    const periodMoment = _.get(this._getCustomData(this._getFirstDataPoint()), 'periodMmt');
    return periodMoment.isBefore(moment().startOf(this._getAxisGranularity()));
  }

  _getCustomData(pointData) {
    const xAxisValue = this._getFirstDataPoint().x;
    const customData = _.get(pointData, 'data.customData');
    const granularity = this._getAxisGranularity();

    return _.find(customData, data => {
      if (granularity === 'quarter') {
        return data.quarterText === xAxisValue;
      } else {
        return moment(data.period).format('DD-MM-YYYY') === moment(xAxisValue).format('DD-MM-YYYY');
      }
    });
  }

  _getTotalFlyoutContent() {
    const pointData = this._getTraceCustomData();
    const content = [];

    if (this._hasAdjustedValue(pointData)) {
      content.push(this._createContentItem('Adjusted value:', pointData.adjustValue));
    }

    const totalTraceTitle = this._isAdjustedForInflation(pointData) ? 'Original value:' : 'Value:';
    content.push(this._createContentItem(totalTraceTitle, this._getValueForOriginalValue(pointData)));

    if (!_.isEmpty(pointData.note)) {
      content.push(this._createContentItem('Note:', pointData.note, true));
    }

    return content;
  }

  _getValueForOriginalValue(pointData) {
    // For burn up chart, we need to get the value from the chart data.
    return this.options.renderType == OVERTIME_VISUALIZATION_TYPES.BURN_UP.type ?
      _.get(
        _.find(this._filterData(), data => !_.isEmpty(this._getCustomData(data))), 'y') :
      pointData.value;
  }

  _getProjectionFlyoutContent() {
    const content = _.map(this._filterData(), data => {
      const meta = _.get(data, 'data.meta', {});
      const title = meta.isProjection ? meta.projectionName : 'Value';
      return {
        title: `${title}:`,
        value: this._getFormattedValue(data.y),
        color: meta.color,
        sortOrder: this._getSortOrder(title)
      };
    });

    return _.sortBy(_.uniqBy(content, 'title'), 'sortOrder');
  }

  _isAdjustedForInflation(pointData) {
    return !_.isUndefined(pointData.adjustValue);
  }

  _getFormattedValue(value) {
    return getFormattedValue(value, this.options.viewEntry);
  }

  _getFirstDataPoint() {
    return this._filterData()[0];
  }

  _getTraceCustomData() {
    let pointData = {};
    _.each(this._filterData(), (data) => {
      if (_.isEmpty(pointData)){
        pointData = this._getCustomData(data);
      }
    });

    return pointData;
  }

  _hasAdjustedValue(pointData) {
    return !_.isUndefined(pointData.adjustValue);
  }

  _createContentItem(title, value, isText = false) {
    return {
      title,
      value: isText ? value : this._getFormattedValue(value)
    };
  }

  _getForecastModals() {
    return _.get(this.options, 'forecastingOption.forecastModelOptions', []);
  }

  _mappedForecastModals() {
    return _.reduce(this._getForecastModals(), (orderArr, item, index) => {
      orderArr[item.name] = index;
      return orderArr;
    }, {});
  }

  _getSortOrder(title) {
    const sortValue = this._mappedForecastModals()[title];
    return _.isUndefined(sortValue) ? -1 : sortValue;
  }
}