import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import TextFilter from './TextFilter';
import NumberFilter from './NumberFilter';
import DateFilter from './DateFilter';
import BooleanFilter from './BooleanFilter';
import DropDownFooter from 'pages/drilldown/components/QuickFilterBar/DropDownFooter';
import { isSingleSelectFilter, isMultiValueSelect } from 'pages/drilldown/components/QuickFilterBar/helper';

import { getFilterWithDefaultOption, isNotValidNumericValue } from './helper';
import {
  STRING_TYPES_FIELD,
  NUMBER_TYPES_FIELD,
  DATE_TYPES_FIELD,
  BOOLEAN_TYPES_FIELD,
  CURRENCY_TYPE
} from 'appConstants';
import SearchInputAndDropdownItems from './SearchInputAndDropdownItems';

class FilterEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // existingFilter: _.cloneDeep(_.get(props, 'filter', {})),
      filter: _.get(props, 'filter', {}),
      showFilterValue: false,
    };
  }

  onFilterByColumnChange = (option) => {
    const { allFilters, filterPopupRef } = this.props;
    const filter = getFilterWithDefaultOption(option);
    // TODO remove this condition and refactor by moving DropDownFooter
    const stateOption = { filter, showFilterValue: false };
    const filterConditions = _.chain(allFilters).
      filter((filterItem) => (filterItem.field === _.get(filter, 'field'))).
      map('conditions').
      flatten().
      value();

    if (filterConditions) {
      stateOption['existingFilterConditions'] = filterConditions;
    }

    this.setState(stateOption, () => this.props.onFilterChange(filter));
    // added focus to apply filter on enter
    filterPopupRef.current.focus();
  }

  onOperatorChange = (option) => {
    const { filterPopupRef } = this.props;
    const { filter } = this.state;
    const newFilter = { ...filter, ...option };
    this.setState({ filter: newFilter }, () => this.props.onFilterChange(newFilter));
    // added focus to apply filter on enter
    filterPopupRef.current.focus();
  }

  onSearchChange = (filter) => {
    this.setState({ filter }, () => this.props.onFilterChange(filter));
  }

  saveGlobalFilter = () => {
    const { filter } = this.state;
    const { onApply } = this.props;

    onApply(filter);
  }

  renderFilterContent = () => {
    const { filter, showFilterValue } = this.state;
    const {
      filterFieldEntries, allFilters, type, apiParams, templateId, filterKey, isEditMode, viewEntry,
      onFilterItemClick
    } = this.props;
    const matchedFilter = _.find(filterFieldEntries, { field: filter.field });
    const renderType = _.get(matchedFilter, 'renderType', STRING_TYPES_FIELD);

    if (_.isEmpty(filter) || showFilterValue) {
      return null;
    } else {
      const attributes = {
        type,
        key: `${filterKey}-${filter.field}`,
        filterKey: filterKey,
        templateId,
        onFilterItemClick: onFilterItemClick,
        apiParams,
        isEditMode,
        allFilters,
        filterFieldEntries,
        filter,
        viewEntry,
        onApply: this.saveGlobalFilter,
        onSearchChange: this.onSearchChange,
        onOperatorChange: this.onOperatorChange
      };
      // This needs to be capitalized - JSX checks the case of the first letter.
      let SpecificFilter;
      if (renderType === NUMBER_TYPES_FIELD) {
        SpecificFilter = NumberFilter;
      } else if (renderType === DATE_TYPES_FIELD) {
        SpecificFilter = DateFilter;
      } else if (renderType === BOOLEAN_TYPES_FIELD) {
        SpecificFilter = BooleanFilter;
      } else {
        SpecificFilter = TextFilter;
      }

      return (<SpecificFilter {...attributes} />);
    }
  }

  renderFilterBodyContent() {
    const { filterFieldEntries, onFilterItemClick } = this.props;
    const field = _.get(this.state.filter, 'field', '');
    const options = _.filter(filterFieldEntries, (filterEntry) => {
      return (!isSingleSelectFilter(filterEntry) && !filterEntry['is_default_collection_filter'])
    });

    return (
      <div className="filters-body">
        <div className='filter-items'>
          <label>Filter by</label>
          <SearchInputAndDropdownItems
            onFilterItemClick={onFilterItemClick}
            key={field}
            items={options}
            onClick={this.onFilterByColumnChange}
            onShowFilterValue={(show) => this.setState({ showFilterValue: show })}
            value={field} />
        </div>
        <div className='filter-editor-container'>
          {this.renderFilterContent()}

        </div>
      </div>
    );
  }

  render() {
    const { onClose, filterKey, isModifiedFilter } = this.props;
    const { filter, existingFilterConditions } = this.state;
    const { conditions, type } = filter;
    const filterValues = _.chain(conditions).map('value').flatten().value();
    const isFiltersChanged = !_.isEqual(existingFilterConditions, _.get(filter, 'conditions'));

    const isDisabledApplyButton = (
      (
        (
          (_.includes([STRING_TYPES_FIELD, BOOLEAN_TYPES_FIELD, CURRENCY_TYPE], type) ||
            isMultiValueSelect(filter)
          )
          && _.isEmpty(filterValues)
        ) || _.isEmpty(filter)
      ) || !isModifiedFilter
      || (!_.isEmpty(existingFilterConditions) && !isFiltersChanged)
    );

    const isButtonDisabled = isNotValidNumericValue([filter]) || isDisabledApplyButton;

    return (
      <div className="common-filters-wrapper" key={filterKey}>
        {this.renderFilterBodyContent()}
        <DropDownFooter
          onApply={() => this.saveGlobalFilter()}
          onRemove={onClose}
          isDisabledApplyButton={isButtonDisabled}
          hideResetButton={true}
          hideRemoveButton={false} />
      </div>
    );
  }
}

FilterEditor.propTypes = {
  allFilters: PropTypes.array,
  filter: PropTypes.object,
  filterFieldEntries: PropTypes.array,
  onApply: PropTypes.func,
  onClose: commonPropTypes.onClosePropTypes,
  onFilterChange: PropTypes.func,
  type: PropTypes.string,
  apiParams: PropTypes.object,
  templateId: commonPropTypes.templateIdPropTypes,
  filterKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isEditMode: PropTypes.bool,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  isModifiedFilter: PropTypes.bool,
  filterPopupRef: PropTypes.any,
  onFilterItemClick: PropTypes.func
}

export default FilterEditor;
