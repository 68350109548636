import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  getCurrentTemplateEntry,
  isTableAggregationEnabled,
  getTableColumnEntries
} from 'common/config/templateConfiguration';
import { isDateColumnConfigured } from 'helpers/dateHelper';
import { CURRENCY_TYPE, DATE_RENDER_TYPES, NUMBER_TYPES_FIELD } from 'appConstants';
import { getAbbreviatedStringValue } from 'helpers/numberHelper';
import { addhttp } from 'helpers/HttpHelper';
import { getSecondsAsDuration } from 'helpers/visualizationHelper';

export const getNewSortableColumns = (columnName, sortColumns, isSortByAscending) => {
  let newSortColumns = skipEmptyObjects(_.cloneDeep(sortColumns));
  newSortColumns = _.filter(newSortColumns, (sortColumn) => sortColumn?.columnName !== columnName);
  if (isSortByAscending) {
    newSortColumns.push({ columnName, ascending: false });    
  }

  // add filterOrder to each column
  newSortColumns = getSortColumnsWithFilterOrder(newSortColumns);

  return newSortColumns;
};

export const skipEmptyObjects = (sortColumns) => {
  return _.filter(_.cloneDeep(sortColumns), obj => !_.isEmpty(obj))
}

export const getSortColumnsWithFilterOrder = (newSortColumns) => {
 const isOneColumnSort = _.size(newSortColumns) == 1; 
  
 return _.map(newSortColumns, (sortColumn, index) => {
    return { ...sortColumn, filterOrder: isOneColumnSort ? '' : index + 1 };
  });
}

export const getRowsLimitOptions = (totalRecords) => {
  if (totalRecords < 10) {
    return [];
  }
  let options = [10];
  if (totalRecords > 10) {
    options.push(15);
  }
  if (totalRecords > 15) {
    options.push(25);
  }
  if (totalRecords > 25) {
    options.push(50);
  }
  if (totalRecords > 50) {
    options.push(100);
  }

  return options;
}

const getDefaultSortOptionByView = (viewEntry) => {
  const defaultSort = _.get(viewEntry, 'visualization.table.default_sort', {});
  const columnName = _.get(defaultSort, 'table_sort_column', '');
  const sortType = _.get(defaultSort, 'table_sort_type', '');
  const field = _.get(defaultSort, 'field', '');
  if (!_.isEmpty(columnName) && !_.isEmpty(sortType) && !_.isEmpty(field)) {
    return { columnName: field, ascending: sortType == 'ascending' ? true : false }
  } else {
    return {};
  }
}

const getTemplateDefaultSort = (templateName) => {
  const templateEntry = getCurrentTemplateEntry(templateName);
  const templateDefaultSort = _.get(templateEntry, 'fields.table.default_sort', {});
  const columnName = _.get(templateDefaultSort, 'table_sort_column', '');
  const sortType = _.get(templateDefaultSort, 'table_sort_type', '');
  const field = _.get(templateDefaultSort, 'field', '');
  if (!_.isEmpty(columnName) && !_.isEmpty(sortType) && !_.isEmpty(field)) {
    return { columnName: field, ascending: sortType == 'ascending' ? true : false }
  } else {
    return {};
  }
}

export const getDetailViewColumnSortOrderType = (templateEntry) => {
  const templateDefaultSort = _.get(templateEntry, 'fields.table.default_sort', {});
  const sortType = _.get(templateDefaultSort, 'details_view_column_order', '');
  return sortType == 'alphabetical' ? 'asc' : '';
}

export const getDefaultSortOptionForTable = (viewEntry, templateName) => {
  const defaultTemplateSortColumn = getTemplateDefaultSort(templateName);
  const defaultViewSortColumn = getDefaultSortOptionByView(viewEntry);
  return !_.isEmpty(defaultViewSortColumn) ? defaultViewSortColumn : defaultTemplateSortColumn;
}

export const disableTableVisualization = (currentDrilldownTemplateId) => {
  if (_.isEmpty(getTableColumnEntries(currentDrilldownTemplateId))) {
    return true;
  } else if (isTableAggregationEnabled(currentDrilldownTemplateId)
    && !isDateColumnConfigured(currentDrilldownTemplateId)) {
    return true;
  } else {
    return false;
  }
}

export const activeTableSearchEntry = (searchInputEntries, removedSearchItems) => {
  const { column, value } = removedSearchItems;
  let removedFilterEntry = _.find(searchInputEntries, { column });
  const renderType = _.get(removedFilterEntry, 'render_type') || _.get(removedFilterEntry, 'renderType');
  const isNumericEntry = (renderType === NUMBER_TYPES_FIELD);

  let { values } = removedFilterEntry;
  values = _.reject(values, (val) => (val == value));
  if (_.isEmpty(values) || isNumericEntry) {
    searchInputEntries = _.reject(searchInputEntries, removedFilterEntry);
  } else {
    removedFilterEntry['values'] = values;
  }
  return searchInputEntries
}

export const getSearchHighlightedColumnValue = (
  currentColumnEntry,
  customColumnEntries,
  tableSearchEntries) => {
  const { value, name } = currentColumnEntry;
  const customColumnEntryColumn = _.get(_.find(customColumnEntries, ['field', name]), 'column');
  const searchColumnEntry = _.find(tableSearchEntries, { column: customColumnEntryColumn });
  if (_.isEmpty(searchColumnEntry)) {
    return value
  }

  const searchTextValue = _.get(searchColumnEntry, 'values', []);
  const searchString = _.flatten([searchTextValue]).map((searchString) => {
    return stringHasSpecialChar(searchString) ? `.${searchString}` : searchString;
  }).join('|');
  const regex = RegExp(searchString, 'gim'); // case insensitive
  let formattedColumnValue = value;
  _.each(value.match(regex), (item) => {
    const replacement = (`<span class="highlight-text">${item}</span>`);
    formattedColumnValue = formattedColumnValue.replace(item, replacement);
  });

  return formattedColumnValue;
}

const stringHasSpecialChar = (string) => {
  let spChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/; //eslint-disable-line
  if (spChars.test(string)) {
    return true;
  } else {
    return false;
  }
}

export const shouldShowTableAccordionIcon = (rowEntry, columnEntries) => {
  return _.some(columnEntries, (entry) => {
    return ((Number(_.get(rowEntry, `${entry['field']}_count__`, 0)) > 1) &&
      !_.isEmpty(entry['column_dataset']));
  })
}

export const formattedValueByDataType = (value, rowEntry, isWithoutTime = false) => {
  const dataType = _.get(rowEntry, 'renderType', '');
  const dataUnit = _.get(rowEntry, 'data_unit', '');
  if(_.includes(DATE_RENDER_TYPES, dataType)){
    if(!_.isEmpty(value)){
      return isWithoutTime ? moment(value).format("MMM DD, YYYY")
                    : moment(value).format("MMM DD, YYYY hh:mm a");
    }
    return '';
  } else if (dataType === 'url' || dataType === 'hyperlink') {
    const url = _.isObject(value) ? _.get(value, 'url') : value;
    const formattedUrl = addhttp(url);
    return (
      <a
        href={formattedUrl}
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: url }}>
      </a>
    );
  } else if (_.includes(['point'], dataType)) {
    return 'N/A';
  } else if (_.includes(['amount', CURRENCY_TYPE], dataType)) {
    const valueAmount = (_.isUndefined(value) || _.isNull(value)) ? 0 : value;
    const formattedValue = `$${getAbbreviatedStringValue(valueAmount, null, false, false)}`;
    return formattedValue;
  }else if(dataType === 'duration'){
    return getSecondsAsDuration(value, dataUnit);
  }else {
    // converting to string so boolean values wont change
    return value + '';
  }
}
export const formattedHyperLinkValue = (columnEntry, rowEntry) => {
  const columnName = columnEntry['field'];
  const tdValue = rowEntry[columnName];
  const hyperLinkColumnName = columnEntry['hyperlink_field'];
  const hyperLinkValue = rowEntry[hyperLinkColumnName];
  const url = _.isObject(hyperLinkValue) ? _.get(hyperLinkValue, 'url') : hyperLinkValue;
  const formattedUrl = addhttp(url);
  return (
    <a
      href={formattedUrl}
      target="_blank"
      rel="noopener noreferrer"
      dangerouslySetInnerHTML={{ __html: tdValue }}>
    </a>
  );
}

export const getColumnNames = (propsOption) => {
  const { customColumnEntries, templateEntry } = propsOption;
  let columnHeaderEntries = _.cloneDeep(customColumnEntries);

  const orderBy = getDetailViewColumnSortOrderType(templateEntry);
  if (!_.isEmpty(orderBy)) {
    columnHeaderEntries = _.orderBy(customColumnEntries, ['name'], [orderBy]);
  }

  if (!_.isEmpty(templateEntry['notes_dataset_id']) &&
    !_.isEmpty(templateEntry['notes_dataset_join_column']) &&
    !_.isEmpty(templateEntry['parent_dataset_join_column'])) {
    columnHeaderEntries = [{ field: 'note' }].concat(customColumnEntries)
  }

  return columnHeaderEntries;
}
