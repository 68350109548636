import React, { useState } from 'react';
import { ForgeAppBarNotificationButton } from '@tylertech/forge-react';

import SubscriptionBookmarkWrapper from '../Notifications/SubscriptionBookmarkWrapper';
import NotificationPanel from '../Notifications/NotificationPanel';

import {
  getLocalStorageItem,
  updateUserMessageReadStatusInLocalStorage
} from 'helpers/localStorageHelper';
import { isClickWithinClosestElement } from 'helpers/DomPageHelper';
import { removeRadarSessionStorage } from 'pages/Radar/radarSessionStorageHelper';
import PropTypes from 'prop-types';
import { KEY_USER_READ_MESSAGE } from 'appConstants';

const TylerForgeOmniBarNotification = (props) => {
  const { subscriptionBookmarks, isLoading, setNotificationPanelOpen } = props;
  const [isOpen, setOpen] = useState(false);

  const subscriptionBookmarksSize = _.size(subscriptionBookmarks);
  const showBadge = getLocalStorageItem(KEY_USER_READ_MESSAGE) && subscriptionBookmarksSize > 0;

  const onClickNotificationIcon = (e) => {
    if(isClickWithinClosestElement(e, '.notification-panel')) {
      return;
    }

    updateUserMessageReadStatusInLocalStorage(KEY_USER_READ_MESSAGE);
    removeRadarSessionStorage();
    setOpen(!isOpen);
    setNotificationPanelOpen(!isOpen);
  }

  return(
    <ForgeAppBarNotificationButton
      className="notification-bell"
      onClick={onClickNotificationIcon}
      slot="end"
      count={subscriptionBookmarksSize}
      showBadge={showBadge}
    >
    {isOpen &&
      <NotificationPanel
        onSetOpen={(isOpen) => setOpen(isOpen)}
        subscriptionBookmarks={subscriptionBookmarks}
        isLoading={isLoading}
      />
    }
    </ForgeAppBarNotificationButton>
  );
}

TylerForgeOmniBarNotification.propTypes = {
  subscriptionBookmarks: PropTypes.array,
  isLoading: PropTypes.bool,
  setNotificationPanelOpen: PropTypes.func
}

TylerForgeOmniBarNotification.defaultProps = {
  subscriptionBookmarks: [],
  isLoading: false,
  setNotificationPanelOpen: () => {}
}

export default SubscriptionBookmarkWrapper(TylerForgeOmniBarNotification);
