import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setCurrentVisualizationType, updateDrilldownGroupByEntry } from 'actions/drilldownActions';
import { updateMapView } from 'actions/mapOptionsActions';
import {
  VISUALIZATION_TYPES,
  GROUP_BY_NONE_ENTRY,
  MAP_TYPES,
  FORGE_BAR_DEFAULT,
  NONE_DIMENSION_FIELD
} from 'appConstants';
import { isLocationsConfigured, getMapViewFromViewEntry } from 'modules/Map/helpers/MapOptionsHelper';
import { isDateColumnConfigured } from 'helpers/dateHelper';
import { updateTableSortColumns, updateShowLeafPage } from 'actions/tableActions';
import { getDefaultSortOptionForTable, disableTableVisualization } from 'modules/DetailsTable/TableHelper';
import {
  shouldDisableDistributionTab,
  shouldHideVisualizationButton,
  getVisualizationTypes,
  getAvailableVisualizationType
} from 'helpers/visualizationHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { getShapeDatasetEntries } from 'common/config/templateConfiguration';
import { ForgeTab, ForgeTabBar } from '@tylertech/forge-react';
import { showDateFilter } from 'common/config/viewConfiguration';
import { addCustomAccessbilityForForgeTabBarScroll } from 'helpers/DomPageHelper';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import { updateDrilldownDimensionField } from 'actions/drilldownActions';
class VisualizationSelector extends Component {
  static propTypes = {
    currentVisualizationType: PropTypes.string,
    setCurrentVisualizationType: PropTypes.func,
    dispatchSetCurrentVisualizationType: PropTypes.func,
    dispatchUpdateTableVizOptions: PropTypes.func,
    dispatchUpdateMapView: PropTypes.func,
    currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
    currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
    currentMapView: PropTypes.object,
    currentDrilldownDimensionField: PropTypes.string,
    dispatchUpdateDrilldownDimensionField: PropTypes.func,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.visualizationTabRef = React.createRef(null);

    this.state = {};
  }

  componentDidMount() {
    addCustomAccessbilityForForgeTabBarScroll(this.visualizationTabRef);
  }

  onVisualizationTypeChange = (visualizationType) => {
    const {
      currentVisualizationType,
      currentMapView,
      dispatchSetCurrentVisualizationType,
      dispatchUpdateTableVizOptions,
      dispatchUpdateMapView,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      currentDrilldownDimensionField
    } = this.props;

    const defaultSort = getDefaultSortOptionForTable(currentDrilldownViewEntry, currentDrilldownTemplateId)
    if (visualizationType == VISUALIZATION_TYPES.TABLE.type) {
      dispatchUpdateTableVizOptions(defaultSort);
    }
    window.lastUrls = []; // reset undo button when switching visualization type
    window.isUndoEnabled = false; // reset undo button when switching visualization type
    if (currentVisualizationType !== visualizationType) {
      if (visualizationType === VISUALIZATION_TYPES.MAP.type) {
        const mapView = getMapViewFromViewEntry(currentDrilldownViewEntry, currentMapView);
        dispatchUpdateMapView(mapView);
      }
      dispatchSetCurrentVisualizationType(visualizationType);
      const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
      if (currentDrilldownDimensionField == NONE_DIMENSION_FIELD){
        this.props.dispatchUpdateDrilldownDimensionField(_.first(dimensionsEntries)['field']);
      }
    }
  }

  handleKeyDown = (e, visualizationType) => {
    if (isEnterButtonPressed(e)) {
      this.onVisualizationTypeChange(visualizationType);
    }
  }

  getCurrentVisualizationType = () => {
    const {
      currentVisualizationType,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId
    } = this.props;
    return getAvailableVisualizationType(
      currentDrilldownTemplateId, currentVisualizationType, currentDrilldownViewEntry);
  }

  renderVisualizationTypeBtn = (visualizationEntry, id) => {
    const {
      currentDrilldownTemplateId,
      currentDrilldownViewEntry,
      currentVisualizationType,
      dispatchSetCurrentVisualizationType,
    } = this.props;
    const availableVizType = this.getCurrentVisualizationType();
    const isDisabledLink = this.isCurrentVisualizationTypeDisabled(visualizationEntry.type)
    const shouldHideVisualization = shouldHideVisualizationButton(
      currentDrilldownTemplateId, currentDrilldownViewEntry, _.get(visualizationEntry, 'type'));

    if(shouldHideVisualization || isDisabledLink) {
      if (currentVisualizationType === _.get(visualizationEntry, 'type')) {
        dispatchSetCurrentVisualizationType(availableVizType);
      }
      return null;
    }

    const isActiveTab = visualizationEntry.type === availableVizType;
    return (
      <ForgeTab
        disabled={false} active={isActiveTab}
        tabIndex={isDisabledLink ? -1 : 0}
        key={id}
        ondblclick={(e) => this.handleCollectionDoubleClick(e)}
        onkeydown={(e) => this.handleKeyDown(e, visualizationEntry.type)}
        onClick={() => this.onVisualizationTypeChange(visualizationEntry.type)}
        aria-label={visualizationEntry.name}
        className="h-100">
        <div>
          {visualizationEntry.name}
        </div>
      </ForgeTab>
    );
  }

  isCurrentVisualizationTypeDisabled = (visualizationType) => {
    const { currentDrilldownTemplateId, currentDrilldownViewEntry } = this.props;
    let mapTypes = _.get(currentDrilldownViewEntry, 'visualization.map.map_types', '');
    mapTypes = !_.isEmpty(mapTypes) ? _.map(mapTypes, 'value') : [];
    const shapeDatasetEntries = getShapeDatasetEntries(currentDrilldownTemplateId);
    switch (visualizationType) {
      case VISUALIZATION_TYPES.MAP.type:
        const isChoropleth = _.includes(mapTypes, MAP_TYPES.CHOROPLETH);
        if (isLocationsConfigured(currentDrilldownTemplateId) &&
          isChoropleth && !_.isEmpty(shapeDatasetEntries)) {
          return false
        }
        return !isLocationsConfigured(currentDrilldownTemplateId);
      case VISUALIZATION_TYPES.OVERTIME.type:
        return !showDateFilter(currentDrilldownTemplateId,currentDrilldownViewEntry);
      case VISUALIZATION_TYPES.TABLE.type:
        return disableTableVisualization(currentDrilldownTemplateId);
      case VISUALIZATION_TYPES.DISTRIBUTION.type:
        return shouldDisableDistributionTab(currentDrilldownViewEntry)
      case VISUALIZATION_TYPES.TIME_OF_DAY.type:
        return !isDateColumnConfigured(currentDrilldownTemplateId);
      default:
        return false;
    }
  }

  getAvailableVisualizationTypes = () => {
    const {
      currentDrilldownViewEntry,
      currentDrilldownTemplateId
    } = this.props;

    const visualizationTypes = getVisualizationTypes();

    const availableVizTypes = _.map(visualizationTypes, (visualizationEntry) => {
      const isDisabledLink = this.isCurrentVisualizationTypeDisabled(visualizationEntry.type)
      const shouldHideVisualization = shouldHideVisualizationButton(
        currentDrilldownTemplateId, currentDrilldownViewEntry, _.get(visualizationEntry, 'type'));

      if (!isDisabledLink && !shouldHideVisualization) {
        return visualizationEntry;
      }
    });

    return _.compact(availableVizTypes);
  }

  render() {
    const visualizationTypes = this.getAvailableVisualizationTypes();
    const availableVizType = this.getCurrentVisualizationType();

    const findActiveIndex = _.findIndex(visualizationTypes, { type: availableVizType })

    return (
      <div ref={this.visualizationTabRef}>
        <ForgeTabBar
          activeTab={findActiveIndex}
          layoutMode={FORGE_BAR_DEFAULT.mode.clustered}
          layoutAlign={FORGE_BAR_DEFAULT.align.start}
          underline={false}
          className="custom-forge-tab-bar"
          stacked={FORGE_BAR_DEFAULT.stacked}
          style={{ flex: 1 }}>

          {_.map(visualizationTypes, this.renderVisualizationTypeBtn)}
        </ForgeTabBar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry'),
    currentMapView: _.get(state, 'visualization.mapOptions.currentMapView', ''),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetCurrentVisualizationType: (visualizationType) => {
      dispatch(setCurrentVisualizationType(visualizationType));
      dispatch(updateShowLeafPage(false));
      dispatch(updateDrilldownGroupByEntry(GROUP_BY_NONE_ENTRY));
    },
    dispatchUpdateTableVizOptions: (defaultSort) => {
      dispatch(updateTableSortColumns([defaultSort]));
    },
    dispatchUpdateMapView: (mapView) => {
      dispatch(updateMapView(mapView));
    },
    dispatchUpdateDrilldownDimensionField : (dimensionField) => {
      dispatch(updateDrilldownDimensionField(dimensionField));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisualizationSelector);
