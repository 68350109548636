import React from "react";
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { shouldHideSeeMoreLink } from 'helpers/snapshotRendererHelper';

function SeeMoreOption(props) {
  const { stateOptions, propsOptions, handleKeyDown, handleClickSeeMore } = props;

  const renderSeeMoreLink = () => {
    return (
      <div className="text-center">
        <Button
          variant="link"
          aria-label="See more"
          onKeyDown={handleKeyDown}
          onClick={handleClickSeeMore}>See more</Button>
      </div>
    );
  }
  return shouldHideSeeMoreLink(stateOptions, propsOptions) ? null : renderSeeMoreLink();
}

SeeMoreOption.propTypes = {
  stateOptions: PropTypes.object,
  propsOptions: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleClickSeeMore: PropTypes.func
}

export default SeeMoreOption;
