import React from "react";
import moment from 'moment';
import { ForgeBanner } from '@tylertech/forge-react';
import CustomButton from 'common/components/CustomButton';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateForecastDateRange
} from 'actions/forecastingActions';
import { DATE_FORMAT } from "appConstants";
const ReRunModal = () => {
  const dispatch = useDispatch();
  const currentForecastDateRange = useSelector(state =>
    _.get(state, 'forecasting.currentForecastDateRange', {}));

  const onReRunClick = () => {
    const dateRange = {
      startDate: _.get(currentForecastDateRange, 'dateRange.startDate'),
      endDate: moment().format(DATE_FORMAT)
    }
    dispatch(updateForecastDateRange({ dateRange }));
  };

  return (
    <div>
      <ForgeBanner theme="warning">
        <div className="rerun-modal">
          New data is available since you created this forecast.
          Would you like to re-run the models? If so, your forecast may change.
          <CustomButton type="outlined"
            onClick={() => onReRunClick()} label="Re-run models" />
        </div>

      </ForgeBanner>
    </div>
  );
}

export default ReRunModal;
