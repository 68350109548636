import React from 'react'
import { useSelector } from 'react-redux';

import SearchResultPage from './SearchResultPage';
import SearchInputBox from './SearchInputBox';
import CustomSearchPage from './CustomSearch/CustomSearchPage';
import AdvanceSearchMethods from './AdvanceSearchMethods';

import {
  ADVANCE_SEARCH_PAGE,
  SEARCH_METHOD
} from 'appConstants';
import SingleRecordReport from './ReportsPage/SingleRecordReport';

const AdvanceSearch = () => {
  const searchPage = useSelector(state => state.advanceSearch.searchPage);
  const searchField = useSelector(state => state.advanceSearch.searchField);
  const searchProperty = useSelector(state => state.advanceSearch.searchProperty);
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));
  const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));

  const renderSubjectPropertySearch = () => {
    switch (searchPage) {
      case ADVANCE_SEARCH_PAGE.SEARCH_PAGE:
        return <SearchInputBox />;
      case ADVANCE_SEARCH_PAGE.DETAIL_PAGE:
        return <SearchResultPage searchField={searchField} />;
      case ADVANCE_SEARCH_PAGE.REPORT_PAGE:
        return <SingleRecordReport />;
      default:
        return <SearchInputBox />;
    }
  };

  const renderCustomSearchPages = () => {
    switch (searchPage) {
      case ADVANCE_SEARCH_PAGE.REPORT_PAGE:
        return <SingleRecordReport isCustomSearch={true} />;
      default:
        return <CustomSearchPage
          selectedShapeIds={selectedShapeIds}
          searchFilters={searchFilters} />;
    }
  };

  const renderContent = () => {
    const defaultSearchMethod = _.get(searchProperty, 'defaultSearchMethod');
    const searchMethod = _.get(searchProperty, 'searchMethod')

    return (
      <>
        {(_.isEmpty(searchMethod) || _.isEmpty(defaultSearchMethod)) &&
          <AdvanceSearchMethods searchProperty={searchProperty} />
        }
        {searchMethod == SEARCH_METHOD.SUBJECT_PROPERTY &&
          defaultSearchMethod == SEARCH_METHOD.SUBJECT_PROPERTY && renderSubjectPropertySearch()}
        {searchMethod == SEARCH_METHOD.CUSTOM && defaultSearchMethod == SEARCH_METHOD.CUSTOM &&
          renderCustomSearchPages()}
      </>
    )

  }

  return (
    <main className="advance-search-page main-content">
      {renderContent()}
    </main>
  );
}

AdvanceSearch.propTypes = {};

export default AdvanceSearch;
