// Vendor Imports
import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';

// Project Imports
import './MainStyle';
import RequestUsers from 'common/components/RequestUsers/RequestUsers';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const UserAccountRequest = (props) => (
  <>
    <RequestUsers {...props} />
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      transition={Slide}
      draggable={false}
      role="status"
    />
  </>
);

export default UserAccountRequest;
