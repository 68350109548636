import { disableMetricTotal } from 'common/config/viewConfiguration';
import { apiDataHelper } from './Helper/apiDataHelper';
import { LineChartOptionsHelper } from './Helper/LineChartOptionsHelper';
import { getSecondaryMetricEntries, isTraceVisible } from '.././vizOptionsHelper';
import { projectionDataHandler } from './projectionDataHandler';
import {
  formatEntityDataItems,
  getFormattedBenchmarkData,
  formatTailingDropItems,
  formatActualDropItems
} from './Helper/dataHelper';
import { getDimensionsConfigs, addTotalLegend } from '.././Helpers/dimensionHelper';
import { isEmptyApiData } from 'modules/visualization/helper';
import { COMPARE_VIEW_STACK_COLORS, COMPARE_VIEW_STACK_LITE_COLORS } from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
export default class LineChartDataHandler {
  constructor(rawApiData, vizOptions) {
    this.rawApiData = rawApiData;
    this.vizOptions = vizOptions;
    this.apiDataHelper = new apiDataHelper(rawApiData, vizOptions);
    this.lineChartOptionsHelper = new LineChartOptionsHelper(rawApiData, vizOptions);
    this.projectionDataHandler = new projectionDataHandler(rawApiData, vizOptions);
  }

  getCurrentPeriodData() {
    return {
      total: this._getTotalLineData(),
      entries: this._getEntriesLines(),
      combo_metrics: this._getComboMetrics(),
      year: this.lineChartOptionsHelper.getFiscalYear(),
    };
  }

  getComparisonPeriodData() {
    return {
      total: this._getComparisonTotalLineData(),
      entries: this._getComparisonTotalLineEntries(),
      year: this.lineChartOptionsHelper.getComparisonYear(),
    };
  }

  getBenchmarkData() {
    return getFormattedBenchmarkData(this.vizOptions, this.apiDataHelper.getBenchmarkEntries());
  }

  getTailingDrops() {
    return {
      total: this._getTailDropsTotal(),
      entries: this._getTailingDropsEntries(),
      year: this.lineChartOptionsHelper.getFiscalYear(),
    };
  }

  getTailingDropComparisonData() {
    return {
      total: this._getTailDropComparisonData(),
      entries: this._getTailingDropsEntriesWithComparison(),
      year: this.lineChartOptionsHelper.getComparisonYear(),
    };
  }

  getTailingDropComboMetrics() {
    const comboChartSecondaryResults = this.apiDataHelper.getComboMetrics();
    return _.map(comboChartSecondaryResults, (results) => {
      return _.mapValues(results.total, (metricValues) => {
        return formatTailingDropItems(metricValues, this.vizOptions);
      });
    });
  }

  getActualDropData() {
    const { isForecastingView } = this.vizOptions;
    return {
      total: isForecastingView
        ? formatActualDropItems(
            _.get(this.apiDataHelper.formatApiData(), 'api_data.total', this.apiDataHelper.getTotal()),
            this.vizOptions
          )
        : [],
    };
  }

  getProjectionData() {
    return this.projectionDataHandler.getProjectionData();
  }

  getDimensionConfigs() {
    const entries = this.apiDataHelper.getEntries();
    const comparisonDimensionEntriesResult = this.apiDataHelper.comparisonDimensionEntriesResult();
    const allEntries = {
      ...entries,
      ...comparisonDimensionEntriesResult,
    };
    const shouldShowTotalLine = this.apiDataHelper.isTotalLineEnabled();
    return getDimensionsConfigs(allEntries, this.vizOptions, entries, shouldShowTotalLine);
  }

  getComboMetricConfigs() {
    if (!isEmptyApiData(this.apiDataHelper.formatApiData(), this.vizOptions)) {
      return _.chain(getSecondaryMetricEntries(this.vizOptions))
        .map((secondaryMetricEntry, index) => {
          const dimension = _.get(secondaryMetricEntry, 'name');
          return {
            metricField: _.get(secondaryMetricEntry, 'field'),
            dimension,
            traceId: dimension,
            color: COMPARE_VIEW_STACK_COLORS[index + 1],
            liteColor: COMPARE_VIEW_STACK_LITE_COLORS[index + 1],
            metricEntry: _.omit(secondaryMetricEntry, ['parent_queries']),
            isTotalLine: true,
            visible: isTraceVisible(this.vizOptions, { traceId: dimension }),
          };
        })
        .value();
    }
  }

  addTotalLegend(formattedData) {
    const { showLegendTotalLine, viewMode } = this.vizOptions;
    const isSmallView = viewMode === VIEW_MODE.SMALL;
    if (this.apiDataHelper.isTotalLineEnabled() && (showLegendTotalLine || isSmallView)) {
      return addTotalLegend(formattedData, this.vizOptions);
    } else {
      return formattedData;
    }
  }

  _getTotalLineData() {
    const totalData = this.apiDataHelper.getTotal();
    const currentPeriods = this.lineChartOptionsHelper.getCurrentPeriods();
    return this.apiDataHelper.isTotalLineEnabled()
      ? formatEntityDataItems(totalData, currentPeriods, this.vizOptions)
      : [];
  }

  _getEntriesLines() {
    const entries = this.apiDataHelper.getEntries();
    const currentPeriods = this.lineChartOptionsHelper.getCurrentPeriods();
    return _.mapValues(entries, (dataItems, dimension) => {
      return formatEntityDataItems(dataItems, currentPeriods, this.vizOptions, dimension);
    });
  }

  _getComboMetrics() {
    const comboChartSecondaryResults = this.apiDataHelper.getComboMetrics();
    const currentPeriods = this.lineChartOptionsHelper.getCurrentPeriods();
    return _.map(comboChartSecondaryResults, (results) => {
      return _.mapValues(results.total, (metricValues) => {
        return formatEntityDataItems(metricValues, currentPeriods, this.vizOptions);
      });
    });
  }

  _getComparisonTotalLineData() {
    const comparisonTotal = this.apiDataHelper.getComparisonTotal();
    const comparisonPeriods = this.lineChartOptionsHelper.getComparePeriods();
    return this.apiDataHelper.isTotalLineEnabled()
      ? formatEntityDataItems(comparisonTotal, comparisonPeriods, this.vizOptions)
      : [];
  }

  _getComparisonTotalLineEntries() {
    const comparisonDimensionEntriesResult = this.apiDataHelper.comparisonDimensionEntriesResult();
    const comparisonPeriods = this.lineChartOptionsHelper.getComparePeriods();
    return _.mapValues(comparisonDimensionEntriesResult, (dataItems) => {
      return formatEntityDataItems(dataItems, comparisonPeriods, this.vizOptions);
    });
  }

  _getTailDropsTotal() {
    const tailingDropStartDate = this.lineChartOptionsHelper.getTailingDropStartDate();
    const subtractValue = this.lineChartOptionsHelper.getTailingDropSubtractValue();
    const tailDropItems = this.apiDataHelper.getTailingDrops(tailingDropStartDate, subtractValue);
    return this.apiDataHelper.isTotalLineEnabled()
      ? formatTailingDropItems(tailDropItems, this.vizOptions)
      : [];
  }

  _getTailingDropsEntries() {
    const entries = this.apiDataHelper.getEntries();
    return _.mapValues(entries, (dataItems) => {
      return formatTailingDropItems(dataItems, this.vizOptions);
    });
  }

  _getTailDropComparisonData() {
    const { viewEntry } = this.vizOptions;
    const comparisonTotal = this.apiDataHelper.getComparisonTotal();
    return disableMetricTotal(viewEntry)
      ? []
      : formatTailingDropItems(comparisonTotal, this.vizOptions);
  }

  _getTailingDropsEntriesWithComparison() {
    const comparisonDimensionEntriesResult = this.apiDataHelper.comparisonDimensionEntriesResult();
    return _.mapValues(comparisonDimensionEntriesResult, (dataItems) => {
      return formatTailingDropItems(dataItems, this.vizOptions);
    });
  }
}