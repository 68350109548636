import React from 'react'
import PropTypes from 'prop-types'

import TimeOfDayOrDayOfWeek from 'modules/visualization/TimeOfDayOrDayOfWeek';
import ResetButton from './ResetButton';
import SelectedCellDescriptions from './SelectedCellDescriptions';

const FilterBody = ({
  selectedCells,
  onSelectedCellsUpdate,
  children,
  showFilterContent,
  onClickResetButton }) => {
  const timeOfDayAttributes = {
    defaultColor: "#d9d9d9",
    selectedColor: '#3f51b5',
    selectedCells: selectedCells,
    onSelectedCellsUpdate: onSelectedCellsUpdate
  };

  return (
    <div className="filter-chip-show">
      <div>
        {showFilterContent && <TimeOfDayOrDayOfWeek  {...timeOfDayAttributes} />}
      </div>
      <div className='time-of-day-text-label'>
        <span>Select the time periods and days you&apos;d like to see data for.</span>
        {!_.isEmpty(selectedCells) &&
          <ResetButton onUpdate={onClickResetButton} ariaLabel='Reset time of day day of week filters' />
        }
        <SelectedCellDescriptions filters={selectedCells}/>
      </div>
        {children}
    </div>
  );
}

FilterBody.propTypes = {
  selectedCells: PropTypes.array,
  onSelectedCellsUpdate: PropTypes.func,
  onClickResetButton: PropTypes.func,
  children: PropTypes.node,
  showFilterContent: PropTypes.bool
}

export default FilterBody;
