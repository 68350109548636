import _ from 'lodash';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './MainStyle';
import configureStore from './Store';
import UserFromBellerophonContext from './context/UserFromBellerophonContext';
import Layout from 'common/Layout';
import { HashRouter } from 'react-router-dom';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const Main = (props) => (
  <Provider store={configureStore(props)}>
    <HashRouter hashType="hashbang">
      <UserFromBellerophonContext.Provider
        value={{ userFromBellerophon: _.get(props, 'userFromBellerophon', {})}}
      >
        <Layout />
        <ToastContainer className="toaster-message"
          hideProgressBar={true}
          transition={Slide}
          draggable={false}
          role="status"
        />
      </UserFromBellerophonContext.Provider>
    </HashRouter>
  </Provider>
);

export default Main;
