import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import SearchDropdownListItems from 'common/components/SearchDropdownListItems';
import FilterChip from 'common/components/FilterChip';
// import ColorSelector from './ColorSelector';
import SearchInput from './SearchInput';
import { isBottomOfElementReached } from 'helpers/DomPageHelper';

const DEFAULT_LIMIT = 20;
const DEBOUNCE_WAIT_TIME = 300;

const SearchAndListItems = ({ selectedValues, searchItems, onUpdate, onUpdateSearchItems }) => {
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const ref = useRef();

  const onRemoveFilterChip = (removedItem) => {
    const filteredValues = _.filter(selectedValues, (value) => {
      return value !== removedItem;
    });

    onUpdate(filteredValues);
  }

  const onScrollSearchItem = () => {
    if(isBottomOfElementReached(ref.current)) {
      setLimit(limit + DEFAULT_LIMIT);
    }
  }
  const debounceScroll = _.debounce(onScrollSearchItem, DEBOUNCE_WAIT_TIME);

  const onClickItem = (selectedItem) => {
    onUpdate(_.uniq([...selectedValues, selectedItem.shape_id]));
  }

  const onChange = (event) => {
    const inputValue = event.target.value;
    onUpdateSearchItems(inputValue);
  }

  const handleResetBtn = () => {
    onUpdate([]);
  }

  const renderSelectedFilterChip = (selectedTracts) => {
    const selectedFilterChips= _.map(selectedTracts, (tract , index) => {
      const label = _.chain(searchItems).find({shape_id: tract}).get('shape_name', tract).value();
      return (
        <FilterChip value={tract} onRemove={onRemoveFilterChip} label={label} key={`filter-chip-${index}`} />
      );
    });

    return !_.isEmpty(selectedFilterChips) && (
      <div className="filter-chip-wrapper">
        {selectedFilterChips}
      </div>
    );
  }
  const disabledClass = _.isEmpty(selectedValues) ? 'disabled' : '';
  const searchValues = _.uniqBy(searchItems, 'name')
  return (
    <div className="comparison-left-side" onScroll={debounceScroll} ref={ref}>
      {/* <ColorSelector /> */}
      <div
        className={`d-flex justify-content-end reset-link my-2 mr-2 ${disabledClass}`}
        tabIndex={0}
        aria-label="Reset all filters"
        onClick={handleResetBtn}>Reset</div>
      <SearchInput onChange={onChange} />
      {renderSelectedFilterChip(selectedValues)}
      <div>
        <SearchDropdownListItems
          onClickItem={onClickItem}
          searchValues={_.take(searchValues, limit)}
          className="value-list show"
          showRemoveIcon={false}
          values={selectedValues}
        />
      </div>
    </div>
  );
}

SearchAndListItems.defaultProps = {
  selectedValues: [],
  searchItems: [],
  onUpdate: _.noop,
  onUpdateSearchItems: _.noop,
}

SearchAndListItems.propTypes = {
  selectedValues: PropTypes.array,
  searchItems: PropTypes.array,
  onUpdate: PropTypes.func,
  onUpdateSearchItems: PropTypes.func
}

export default SearchAndListItems;
