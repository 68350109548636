import React  from 'react';
import PropTypes from 'prop-types';
import {
  getSecurityRoles
} from 'common/config/customerConfiguration';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const BulkUserManagement = (props) => {
  const { userFromBellerophon, showUserImportModal, exportTemplateDownload  } = props;
  const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
  const securityRoles = getSecurityRoles();
  const currentUserRole =  _.get(userFromBellerophon.bellerophon_user_detail, 'role', '');
  const canBulkUserManagement =  _.get(
    _.find(securityRoles, ['role_id', currentUserRole]), 'can_bulk_user_management' , "false");
  if (!isLaunchpadAdmin && !_.isEqual(canBulkUserManagement,"true")) {
    return null;
  }

  const onKeyDownButton = (e, type) => {
    if (isEnterButtonPressed(e)) {
      if (type == 'Upload'){
        showUserImportModal();
      }
      if (type == 'Download'){
        exportTemplateDownload(false);
      }
      if (type == 'Template'){
        exportTemplateDownload(true);
      }
    }
  }

  return (
    <div className="d-flex">
      <a
        tabIndex={0}
        aria-label="Upload Users"
        className="security-buttons d-flex align-items-center"
        onKeyDown={(e) => onKeyDownButton(e,'Upload')}
        onClick={() => showUserImportModal()}>
        <i className="icons-cloud-upload-alt mr-1" aria-hidden="true"></i>
        <span className="button-label">Bulk Upload</span>
      </a>
      <a
        tabIndex={0}
        aria-label="Download Users"
        className="security-buttons d-flex align-items-center" target="_blank"
        onKeyDown={(e) => onKeyDownButton(e,'Download')}
        onClick={() => exportTemplateDownload(false)}>
          <i className="icons-cloud-download-alt mr-1" aria-hidden="true"></i>
          <span className="button-label">Download</span>
      </a>
      <a
        tabIndex={0}
        aria-label="Upload Template"
        className="security-buttons d-flex align-items-center"
        onKeyDown={(e) => onKeyDownButton(e,'Template')}
        onClick={() => exportTemplateDownload(true)}>
        <i className="icons-cloud-download-alt mr-1" aria-hidden="true"></i>
        <span className="button-label">Template</span>
      </a>
    </div>
  );
};

BulkUserManagement.propTypes = {
  userFromBellerophon: PropTypes.object,
  showUserImportModal: PropTypes.func,
  exportTemplateDownload: PropTypes.func
}

export default BulkUserManagement;
