import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { getComparisonPeriodDateRanges } from 'helpers/dateHelper';
import ShapeFilter from 'modules/MiniMap/shapeFilter';
import ToggleButton from './ToggleButton';
import TractChips from './TractChips';
import RadarTimeFrames from '../RadarTimeFrames';
import ShapeComparisonModal from './ShapeComparisonModal';

import { getRadarShapeTileUrl } from 'common/api/commonApi';
import { getRadarAreaEntries } from 'common/config/customerConfiguration';
import { SHAPE_AREA_TABS } from 'appConstants';
import { getShapeFeaturesFromMap } from '../radarHelper';
import { trackEvent } from 'helpers/eventTracking';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  getRadarMiniMapSelectSession,
  setRadarMiniMapSelectSession
} from './../radarSessionStorageHelper';
import CustomDropdownList from 'common/components/SolutionDropDown/CustomDropdownList';

class RadarSideBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: false,
      hideSideBar: false,
      enableMapEvent: true,
      currentMapStyleEntry: {name: 'Street', style: 'mapbox://styles/mapbox/streets-v10'},
      selectedShapeTab: SHAPE_AREA_TABS[0].value,
      shapeDetails: [],
      selectedTracts: _.get(props, 'selectedTracts'),
      comparisonTracts: _.get(props, 'comparisonTracts'),
      isMiniMapSelectEvent: false
    };
  }

  fetchTileApi = (params, overrideParams = {}) => {
    const { selectedShapeAreaEntry, selectedMetrics, currentTemplateId } = this.props;
    const area_entry_id = _.isEmpty(overrideParams) ?
    _.get(selectedShapeAreaEntry, 'id') :
    _.get(overrideParams, 'area_entry_id');

    const extraParams = _.merge({},
      {
        ...params,
        ignore_view_entry: true,
        selectedMetrics: JSON.stringify(_.map(selectedMetrics, 'id')),
        area_entry_id,
        drilldownEntry: JSON.stringify({
          currentDrilldownTemplateId: currentTemplateId,
        }),
      }
    );

    return getRadarShapeTileUrl(extraParams);
  }

  handleTractRemove = (removeTract, selectedTracts, type) => {
    const { toggleSelectedTracts } = this.props;
    const updatedTracts = _.without(selectedTracts, removeTract);

    var updateStateObj = {};
    if(type == 'selectedTracts') {
      updateStateObj['selectedTracts'] = updatedTracts;
    }else{
      updateStateObj['comparisonTracts'] = updatedTracts;
    }
    this.setState(updateStateObj);

    toggleSelectedTracts(updatedTracts, type);
  }

  onToggleButtonClick = () => {
    const { hideSideBar } = this.state;

    this.setState({ hideSideBar: !hideSideBar}, () => {
      setTimeout(() => {
        // dispatch map resize event, So map will resize automatically.
        window.dispatchEvent(new Event('map_resize'));
      }, 150);
    });
  }

  toggleAreaButton = (isChecked, key) => {
    const isComparisonKey = key !== 'isCheckedUnSelectedArea';
    const selectedShapeTab = isComparisonKey ? SHAPE_AREA_TABS[1].value : SHAPE_AREA_TABS[0].value;
    const comparisonTracts = isChecked ? this.props.comparisonTracts : [];

    if(isChecked && isComparisonKey){
      trackEvent('radar_comparison_area_toggled_on');
    }

    this.setState({ selectedShapeTab }, () => {
      this.props.toggleArea(key, isChecked);
      if(isComparisonKey) {
        this.props.toggleSelectedTracts(comparisonTracts, 'comparisonTracts', true);
      }
    });
  }

  onDateFilterChange = (dateRangeOptions, type) => {
    const {previousDateRangeOptions}=this.props;
    let options = _.cloneDeep(dateRangeOptions);
    if(type === 'currentDateRangeOptions') {
      options = _.omit(options, 'comparisonPeriod');
    } else {
      options = _.omit(options, 'relativeDateFilterEntry');
    }
    this.setState({ enableMapEvent: true});
    if(type == 'currentDateRangeOptions'){
    const compareYearDateRangeOptions = {
      comparisonType: previousDateRangeOptions['comparisonPeriod'],
      dateRange: dateRangeOptions['dateRange'],
      dateType: dateRangeOptions['dateType'],
      comparisonDateRanges: {}
    }

    let prevDateRangeOptions = _.cloneDeep(previousDateRangeOptions);
    prevDateRangeOptions['dateRange'] = _.first(getComparisonPeriodDateRanges(compareYearDateRangeOptions))
    this.props.onYearChange(prevDateRangeOptions, 'previousDateRangeOptions');
    }
    this.props.onYearChange(options, type);
  }

  onMapStylesAreLoaded = (map) => {
    const { enableMapEvent } = this.state;
    if(enableMapEvent){
      this.setState({
        shapeDetails: getShapeFeaturesFromMap(map)
      });
    }
  }

  onMiniMapSelect = (tracts, type) => {
    var updateStateObj = {showModal : true, isMiniMapSelectEvent: true};

    if(type == 'selectedTracts' && !getRadarMiniMapSelectSession(type)){
      trackEvent('radar_minimap_area_selected');
      setRadarMiniMapSelectSession(type,true);
    }

    if(type == 'comparisonTracts' && !getRadarMiniMapSelectSession(type)){
      trackEvent('radar_comparison_minimap_area_selected');
      setRadarMiniMapSelectSession(type,true);
    }

    if(type == 'selectedTracts') {
      updateStateObj['selectedTracts'] = tracts;
    }else{
      updateStateObj['comparisonTracts'] = tracts;
    }

    const selectedTabValue = (type == 'selectedTracts' )? 'selectedArea' : 'comparisonArea';
    this.onClickTab(selectedTabValue);
    this.setState(updateStateObj);
  }

  renderShapeFilter(selectedTracts, type){
    const {
      selectedShapeAreaEntry,
      currentTemplateId
    } = this.props;
    const { currentMapStyleEntry } = this.state;

    return(
      <ShapeFilter
        showRecenterButton={true}
        shapeTileApi={this.fetchTileApi}
        toggleShapeIdsFilter={(tracts) => this.onMiniMapSelect(tracts, type)}
        selectedShapeEntry={selectedShapeAreaEntry}
        selectedShapeIds={selectedTracts}
        currentMapStyleEntry={currentMapStyleEntry}
        currentDrilldownTemplateId={currentTemplateId}
        onMapStylesAreLoaded={this.onMapStylesAreLoaded}
      />
    );
  }

  renderTractChips(tracts, type) {
    const { shapeDetails } = this.state;

    return (
      <TractChips tracts={tracts} shapeDetails={shapeDetails}
        handleTractRemove={(removedTract) => this.handleTractRemove(removedTract, tracts, type)}
      />
    );
  }

  renderTimeFrameOptions(){
    const timeFramesAttributes = _.pick(this.props,
      'currentDateRangeOptions',
      'previousDateRangeOptions',
      'currentTemplateId'
    );
    return (<RadarTimeFrames
      {...timeFramesAttributes}
      onComparePopUpToggle={(isClicked) => this.setState({enableMapEvent: !isClicked})}
      onDateFilterChange={this.onDateFilterChange}/>
    );
  }

  renderGeographicFilterTitle(){
    return(
      <>
        <div className='section-titles forge-typography--overline d-flex align-items-center'>
          <span>Geographic filter</span>
          {this.renderGeographicFilterReset()}
        </div>
      </>
    )
  }

  handleKeyDownGeographicFiltersReset = (e) => {
    if(isEnterButtonPressed(e)) {
      this.handleGeographicFiltersReset();
    }
  }

  handleGeographicFiltersReset = () => {
    const { resetGeographicFilters } = this.props;
    resetGeographicFilters();
  }

  isNoFilterApplied = () => {
    const { selectedTracts,comparisonTracts } = this.props;
    return (_.isEmpty(selectedTracts) && _.isEmpty(comparisonTracts));
  }

  renderGeographicFilterReset() {
    if(this.isNoFilterApplied()){
      return null;
    }
    return (
      <div className='ml-auto'>
        <span
          className="text-uppercase reset-link forge-typography--caption"
          tabIndex={0}
          role="button"
          aria-label="Reset Geographic Filters"
          onClick={this.handleGeographicFiltersReset}
          onKeyDown={this.handleKeyDownGeographicFiltersReset}
        >
          Reset
        </span>
      </div>
    );
  }

  renderShapeAreaDropDown(){
    const shapeEntries = getRadarAreaEntries();

    const  { selectedShapeAreaEntry, onShapeAreaChange } = this.props;
    const title = _.get(selectedShapeAreaEntry, 'shape_name');
    return(
      <>
        <div className='d-flex align-items-center gap-10 mb-2'>
          <div className="text-muted tx-14">Select by</div>
            <CustomDropdownList
              key="radar-drop-down"
              id="radar"
              className="p-0 d-flex"
              title={title}
              options={shapeEntries}
              showCount={true}
              optionDisplayField="shape_name"
              onSelect={onShapeAreaChange} />
        </div>
      </>
    )
  }

  handleShow = () => {
    const { toggleDrawPolygon, isRadarDrawingEnabled , selectedTracts, comparisonTracts} = this.props;
    this.setState({ showModal: !this.state.showModal ,
        selectedTracts: selectedTracts,
        comparisonTracts: comparisonTracts });

    if(isRadarDrawingEnabled){
      toggleDrawPolygon(false)
    }
  }

  onClickTab = (tab) => {
    this.setState({ selectedShapeTab: tab });
  }

  onSave = (saveOptions) => {
    const { selectedTracts, comparisonTracts } = saveOptions

    this.setState({ showModal: false,
      selectedTracts: selectedTracts,
      comparisonTracts: comparisonTracts
    }, () => {
      this.props.onShapeModalSave(saveOptions);
    });
  }

  onClickAreaLabel = (areaKey) => {
    const index = areaKey == 'isCheckedComparisonArea' ? 1 : 0;
    const selectedTabValue = _.get(SHAPE_AREA_TABS, [index, 'value'])

    if(selectedTabValue == 'selectedArea'){
      trackEvent('radar_selected_area_modal_opened');
    }
    if(selectedTabValue == 'comparisonArea'){
      trackEvent('radar_comparison_modal_opened');
    }
    this.setState({isMiniMapSelectEvent: false});
    this.handleShow();
    if(areaKey !== 'isCheckedUnSelectedArea') {
      this.onClickTab(selectedTabValue);
    }
  }

  renderModal() {
    const { showModal, selectedShapeTab, currentMapStyleEntry,
      shapeDetails, selectedTracts, comparisonTracts, isMiniMapSelectEvent } = this.state;

    const {
      selectedShapeAreaEntry,
      currentTemplateId,
      selectAreaName,
      comparisonAreaName,
      isRadarDrawingEnabled
    } = this.props;

    return showModal && (
      <ShapeComparisonModal
        showModal={showModal}
        selectAreaName={selectAreaName}
        comparisonAreaName={comparisonAreaName}
        shapeDetails={shapeDetails}
        shapeTileApi={this.fetchTileApi}
        selectedTracts={selectedTracts}
        comparisonTracts={comparisonTracts}
        currentMapStyleEntry={currentMapStyleEntry}
        currentDrilldownTemplateId={currentTemplateId}
        selectedShapeAreaEntry={selectedShapeAreaEntry}
        onClose={this.handleShow}
        onSave={this.onSave}
        selectedShapeTab={selectedShapeTab}
        isRadarDrawingEnabled={isRadarDrawingEnabled}
        isMiniMapSelectEvent={isMiniMapSelectEvent}
      />
    );
  }

  renderAreaToggleButton = (label, areaKey, showCheckBox = true) => {
    const isChecked = _.get(this.props, areaKey, false);
    const onClickLabel = (areaKey !== 'isCheckedUnSelectedArea') ? this.onClickAreaLabel : _.noop;

    return (
      <ToggleButton
        showCheckBox={showCheckBox}
        label={label}
        id={label}
        isChecked={isChecked}
        onClickCheckBox={(isChecked) => this.toggleAreaButton(isChecked, areaKey)}
        onClickLabel={() => onClickLabel(areaKey)}
      />
    );
  }

  renderRadarShapeAreaFilter = () => {
    const { comparisonTracts, isCheckedComparisonArea, comparisonAreaName } = this.props;
    const showComparisonArea = isCheckedComparisonArea && !_.isEmpty(comparisonTracts);

    return (
      <div className="radar-filter border-top-0">
        <div className="comparison-area radar-sidebar-toggle">
          {this.renderAreaToggleButton(comparisonAreaName, 'isCheckedComparisonArea')}
          {showComparisonArea && this.renderTractChips(comparisonTracts, 'comparisonTracts')}
          {isCheckedComparisonArea && this.renderShapeFilter(comparisonTracts, 'comparisonTracts')}
        </div>
        <div className='radar-sidebar-toggle mt-2'>
          {this.renderAreaToggleButton('Unselected area', 'isCheckedUnSelectedArea')}
        </div>
      </div>
    );
  }

  onToggleButtonKeyDown = (e) => {
    if(isEnterButtonPressed(e)) {
      this.onToggleButtonClick();
    }
  }

  render() {
    const { hideSideBar } = this.state;
    const { selectedTracts, selectAreaName } = this.props;
    const sideBarClass = classNames('radar-sidebar drawer-panel',
      {'drawer-panel-hide': hideSideBar});

    return(
      <div className={sideBarClass}>
        <div className="toggle-btn"
          tabIndex={0}
          onClick={this.onToggleButtonClick}
          onKeyDown={this.onToggleButtonKeyDown}>
          <span className="icons-chevron-left"></span>
        </div>
        <div className='drawer-panel-wrapper'>
          {this.renderTimeFrameOptions()}
          <hr className='dividers' />
          <div className='radar-filter'>
            {this.renderGeographicFilterTitle()}
            {this.renderShapeAreaDropDown()}
            {this.renderAreaToggleButton(selectAreaName, 'isCheckedSelectedArea', false)}
            {this.renderTractChips(selectedTracts, 'selectedTracts')}
            {this.renderShapeFilter(selectedTracts, 'selectedTracts')}
          </div>
          {this.renderRadarShapeAreaFilter()}
          {this.renderModal()}
        </div>
      </div>
    )
  }
}

RadarSideBar.propTypes = {
  selectedMetrics: PropTypes.array,
  selectedTracts: PropTypes.array,
  comparisonTracts: PropTypes.array,
  selectedShapeAreaEntry: PropTypes.object,
  isCheckedComparisonArea: PropTypes.bool,
  onShapeAreaChange: PropTypes.func,
  onYearChange: PropTypes.func,
  toggleSelectedTracts: PropTypes.func,
  currentTemplateId: PropTypes.string,
  currentDateRangeOptions: PropTypes.object,
  previousDateRangeOptions: PropTypes.object,
  onShapeModalSave: PropTypes.func,
  toggleArea: PropTypes.func,
  comparisonAreaName: PropTypes.string,
  selectAreaName: PropTypes.string,
  isRadarDrawingEnabled: PropTypes.bool,
  toggleDrawPolygon: PropTypes.func,
  resetGeographicFilters: PropTypes.func

};

export default RadarSideBar;
