import React from 'react';
import PropTypes from 'prop-types';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

function UserWarningMessage({bulkUploadOptions}) {
  const { showUserImportModal, exportTemplateDownload } = bulkUploadOptions;

  const onKeyDownButton = (e, type) => {
    if (isEnterButtonPressed(e)) {
      if (type == 'Upload'){
        showUserImportModal();
      }
      if (type == 'Template'){
        exportTemplateDownload(true);
      }
    }
  }

  const renderTemplateDownload = () => {
    return (
      <a
        tabIndex={0}
        aria-label="Upload Template"
        className='cursor-pointer link-color'
        onKeyDown={(e) => onKeyDownButton(e, 'Template')}
        onClick={() => exportTemplateDownload(true)}>
        template
      </a>
    );
  };

  const renderBulkUpload = () => {
    return (
      <a
        tabIndex={0}
        aria-label="Upload Users"
        className='cursor-pointer link-color'
        onKeyDown={(e) => onKeyDownButton(e, 'Upload')}
        onClick={() => showUserImportModal()}>
        bulk upload
      </a>
    );
  }

  return (
    <div className="invalid-feedback warning-message ml-2">
      To add more than 5 users, use this {renderTemplateDownload()} to {renderBulkUpload()} new users.
    </div>
  );
}

UserWarningMessage.propTypes = { 
  bulkUploadOptions: PropTypes.object
};

export default UserWarningMessage;
