import React from 'react'
import PropTypes from 'prop-types'

const Legend = (props) => {
  const { data } = props;

  const renderLegendItem = (legendItem) => {
    const { label, color } = legendItem;

    return(
      <div className='time-of-day-legend-item'>
        <div className='color' style={{backgroundColor: color }}></div>
        <div className='label'>{label}</div>
      </div>
    );
  }

  return (
    <div className='time-of-day-legend'>
      {_.map(data, renderLegendItem)}
    </div>
  );
}

Legend.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
      label: PropTypes.string,
      color: PropTypes.string,
    })
  )
}

export default Legend;
