import React, { Component } from 'react';
import { connect } from 'react-redux';

import InsightsTabsContent from './InsightsTabsContent';
import InsightsTabs from './InsightsTabs';
import { getApiParams } from './InsightsHelper';
import { getInsights  } from 'common/config/customerConfiguration';
import { getVarianceData } from 'common/api/drilldown';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { updateInsightsId } from 'actions/insightActions';
import PropTypes from 'prop-types';

class InsightsActivitiesContainer extends Component {
  constructor(props, context) {
    super(props, context);

    const allInsights = _.map(getInsights(), (insight) => {
      return {
        ...insight,
        apiData: {},
        isLoading: true,
      }
    });
    
    const insightId =  _.get(this.props, 'insightId');
    const initialInsightId = (insightId || _.get(allInsights, '0.insight_id'));
    this.state = {
      allInsights,
      selectedInsightId: initialInsightId
    };
    this.updateStoreForInsightId(initialInsightId);
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.fetchInsights();
  }

  fetchInsights = () => {
    const { allInsights } = this.state;
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    _.each(allInsights, (insight) => {
      this.fetchInsight(insight, this.abortFetchController);
    });
  };

  fetchInsight = (insight, abortFetchController) => {
    const apiParams = getApiParams(insight);
    const apiUrl = getVarianceData(apiParams);

    fetchApiData(apiUrl, abortFetchController)
      .then((response) => {
        const newInsight = { ...insight, apiData: response, isLoading: false, apiParams };
        this.updateInsightDetails(newInsight);
      })
      .catch(() => {
        const newInsight = { ...insight, apiData: { entities: [], extras: {} }, isLoading: false };
        this.updateInsightDetails(newInsight);
      });
  }

  updateInsightDetails = (newInsight) => {
    const { allInsights } = this.state;
    const index = _.findIndex(allInsights, {'insight_id': newInsight['insight_id']});
    const newAllInsights = [].concat(_.get(this.state, 'allInsights'));
    newAllInsights[index] = newInsight;
    this.setState({
      allInsights: newAllInsights
    });
  }

  getSelectedInsight = () => {
    const { allInsights, selectedInsightId } = this.state;
    return _.find(allInsights, {'insight_id': selectedInsightId});
  }

  handleTabSelection = (id) => {
    this.setState({ selectedInsightId: id });
    this.updateStoreForInsightId(id);
  }

  updateStoreForInsightId = (insightId) => {
    const { dispatchUpdateInsightsId } = this.props;
    dispatchUpdateInsightsId(insightId);
  }

  render(){  
    const { allInsights, selectedInsightId } = this.state;  
    
    return (
      <div className="insights-container">
        <InsightsTabs 
          allInsights={allInsights} 
          selectedInsightId={selectedInsightId}
          onTabSelection={this.handleTabSelection} />
        <div className="insights-tabs-content tab-content">
          <InsightsTabsContent 
            key={selectedInsightId}
            selectedInsight={this.getSelectedInsight()}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    insightId:_.get(state, 'insight.insightId', '')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateInsightsId: (insightId) => {
      dispatch(updateInsightsId(insightId));
    }
  };
}

InsightsActivitiesContainer.propTypes = {
  dispatchUpdateInsightsId: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsActivitiesContainer);
