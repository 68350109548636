import React from "react";
import { useSelector } from "react-redux";

const TableOwnerRow = () => {
  const collection = useSelector((store) => store.collection);
  const currentUser = useSelector((store) => store.currentUser);
  const userName = _.get(collection, 'currentCollectionUser.email', '');
  const userText = currentUser['email'] === userName ? ' (You)' : '';

  return(
    <tr>
      <td className="email-name">{userName}{userText}</td>
      <td>Owner</td>
    </tr>
  );
}

export default TableOwnerRow;
