export const DIALOG_PROPS = {
  backdropClose: true,
  escapeClose: true,
  fullscreen: false,
  moveable: true,
  positionType: 'absolute'
};


export const ONBOARD_DIALOG_PROPS = {
  backdropClose: false,
  escapeClose: false,
  fullscreen: false,
  moveable: true,
  positionType: 'absolute'
};


export const COLLABORATOR_TABS = [
  { name: 'Details'},
  { name: 'Activity'},
];

export const VIEWER_ROLE = 'viewer';
export const EDITOR_ROLE = 'editor';
export const OWNER_ROLE = 'Owner';

export const COLLABORATOR_ROLES = [
  { name: 'Viewer', value: VIEWER_ROLE },
  { name: 'Editor', value: EDITOR_ROLE },
];