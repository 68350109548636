import { DATE_TIME_FORMAT } from "appConstants";
import {
  enableFeatureDates,
  getConfiguredDataEndDate,
  getDateDataBeginsFrom,
  getFeatureEndDate
} from "common/config/customerConfiguration";
import moment from "moment";
import _ from 'lodash';

export const getMinDates = () => window.minDatesForTemplateEntries;

export const getMinDate = (templateId) => {
  const dataBeginDate = getDateDataBeginsFrom(templateId);

  if (_.isEmpty(dataBeginDate)){
    return getMinDateFromTemplateEntries();
  } else {
    return new Date(moment(dataBeginDate).format(DATE_TIME_FORMAT));
  }
}

export const getMaxDate = () => {
  const dataEndDate = getConfiguredDataEndDate();

  if(enableFeatureDates()) {
    const featureEndDate = getFeatureEndDate();
    return _.isEmpty(featureEndDate) ? new Date() : new Date(featureEndDate);
  } else {
    return _.isEmpty(dataEndDate) ? new Date(): new Date(dataEndDate);
  }
}

export const getMinDateForTemplates = (cardEntries, minDatesForTemplates = []) => {
  const dataBeginDate = getDateDataBeginsFrom();

  if (_.isEmpty(dataBeginDate)) {
    const groupedByTemplateId = _.groupBy(minDatesForTemplates, 'template_id');
    const minDates = _.chain(cardEntries).
      map(({ templateEntry }) => {
        const templateId = _.toString(_.get(templateEntry, 'template_id'));
        return _.get(groupedByTemplateId, templateId);
      }).
      flatten().
      uniqBy('template_id').
      compact().
      map(({ min_date }) => new Date(min_date)).
      value();
    const overAllMinDate = Math.min(...minDates);

    return new Date(overAllMinDate);
  } else {
    return new Date(dataBeginDate);
  }
}

const getMinDateFromTemplateEntries = () => {
  const minDates = _.map(getMinDates(), ({ min_date }) => new Date(min_date));
  const overAllMinDate = Math.min(...minDates);

  return new Date(overAllMinDate);
}
