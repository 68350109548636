import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames';
import PropTypes from 'prop-types'

import UndoButton from 'pages/drilldown/visualizations/VisualizationOptions/UndoButton';
import TimeOfDayOrDayOfWeek from 'modules/visualization/TimeOfDayOrDayOfWeek';
import Legend from 'modules/visualization/TimeOfDayOrDayOfWeek/Legend';
import MenuDropDownSelector from 'common/components/MenuDropDown/MenuDropDownSelector';
import VisualizationDescription from '../VisualizationDescription';

import { getTimeOfDayUrlBasedOnProps } from "pages/dashboard/components/TemplateCards/templateCardHelper";
import { isMobileOrTablet, scrollLegendElementToTarget } from 'helpers/DomPageHelper';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';

class TimeOfDayVisualization extends PureComponent {
  state = {
    isOpenLegend: true,
    legends: []
  }

  toggleClickLegendButton = (isOpenLegend) => {
    this.setState({ isOpenLegend });
    scrollLegendElementToTarget(isOpenLegend);
  }

  onUpdateLegend = (legends) => {
    this.setState({ legends });
  }

  onMobileViewButtonClick = () => {
    this.toggleClickLegendButton(!this.state.isOpenLegend);
  }

  renderLegendButtonMobileView = () => {
    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          className=""
          onKeyDown={() => this.onMobileViewButtonClick()}
          onClick={() => this.onMobileViewButtonClick()}>
          <ForgeIcon name="category" />
        </button>
      </ForgeButton>
    );
  }

  renderHeaderActions = () => {
    return (
      <div className="snapshot-options">
        <div className="rside-action">
          <UndoButton />
          {this.renderLegend()}
        </div>
      </div>
    );
  }

  renderLegend = () => {
    const { legends } = this.state;

    if (isMobileOrTablet()) {
      return this.renderLegendButtonMobileView()
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
        className="" leadingIconName="category" toggleClickLegendButton={this.toggleClickLegendButton}>
        <div className="legend-wrapper">
          <Legend data={legends} />
        </div>
      </MenuDropDownSelector>
    );
  }

  renderMobileLegend = () => {
    const { isOpenLegend, legends } = this.state;
    if (!isMobileOrTablet() || !isOpenLegend) {
      return null;
    }

    return (
      <div className="snapshot-options d-block" >
        <div className="rside-action">
          <MenuDropDownSelector toggleButtonLabel="Legend" menuType="Legend"
            className="" leadingIconName="category" toggleClickLegendButton={this.toggleClickLegendButton}>
            <div className="legend-wrapper">
              <Legend data={legends} />
            </div>
          </MenuDropDownSelector>
        </div>
      </div>
    );
  }

  renderTimeOfDayChart = () => {
    const { drilldown, mapOptions, commonFilters } = this.props;
    const url = getTimeOfDayUrlBasedOnProps({
      drilldownEntry: drilldown, mapOptions, commonFilters, cardEntry: {}
    });

    return (
      <TimeOfDayOrDayOfWeek url={url} onUpdateLegend={this.onUpdateLegend}
        viewEntry={_.get(drilldown, 'currentDrilldownViewEntry', {})}
      />
    );
  }

  render() {
    const vizWrapperClass = classNames('visualization-wrapper', {
      'visualization-width': this.state.isOpenLegend && !isMobileOrTablet()
    });

    return (
      <div className='chartContainer viz-wrapper legend-container'>
        {this.renderHeaderActions()}
        <div className={vizWrapperClass}>
          <div className="visualization-lside">
            <div className="chart-view">
              {this.renderTimeOfDayChart()}
            </div>
            {this.renderMobileLegend()}
            <div className="visualization-footer">
              <VisualizationDescription />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TimeOfDayVisualization.propTypes = {
  drilldown: PropTypes.object,
  mapOptions: PropTypes.object,
  commonFilters: PropTypes.object
}

function mapStateToProps(state) {
  return {
    commonFilters: _.get(state, 'commonFilters', {}),
    drilldown: _.get(state, 'drilldown', {}),
    mapOptions: _.get(state, 'visualization.mapOptions', {}),
    currentDrilldownViewEntry: _.get(state.drilldown, 'currentDrilldownViewEntry', {}),
  };
}

export default connect(mapStateToProps, null)(TimeOfDayVisualization);
