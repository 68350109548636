// Vendor Imports
import PropTypes from 'prop-types';

// Project Imports
import {
  AXIS_GRANULARITY_TYPES,
  HISTORICAL_FORECAST,
  LINEAR_FORECAST,
  PROPHET_FORECAST,
  EXPONENTIAL_FORECAST,
  OVERTIME_VISUALIZATION_TYPES,
  OVERTIME_TIME_FRAME_OPTIONS
} from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import * as commonPropTypes from 'common/propTypes';

export const lineChartPropTypes = {
  // Config Entries From Bellerophon to be used to draw the chart:
  viewEntry: commonPropTypes.viewEntryPropTypes.isRequired,
  secondaryMetricEntry: commonPropTypes.secondaryMetricEntryPropTypes,
  benchMarkEntries: PropTypes.array,
  // Render Options
  renderType: PropTypes.oneOf(_.map(OVERTIME_VISUALIZATION_TYPES, 'type')), //timeline/area/burnup
  renderTimeFrame: PropTypes.oneOf(_.values(OVERTIME_TIME_FRAME_OPTIONS)), //rolling/yoy
  isComboChart: PropTypes.bool,
  projectionEnabled: PropTypes.bool,
  projectionType: PropTypes.oneOf(
      [HISTORICAL_FORECAST, LINEAR_FORECAST, PROPHET_FORECAST, EXPONENTIAL_FORECAST]
    ),
  projectionPercent: PropTypes.string,
  axisGranularity: PropTypes.oneOf(_.map(AXIS_GRANULARITY_TYPES, 'value')),
  benchMarkNames:PropTypes.array,
  // Other Options:
  apiData: PropTypes.any,
  onNewApiData: PropTypes.func,
  apiParams: PropTypes.object,
  dateRange: PropTypes.exact({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  compareYearRanges: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string
    })
  ),
  compareToRanges: PropTypes.arrayOf(
    {
      name: PropTypes.string,
      range: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string
      })
  }),
  templateName: PropTypes.string,
  dateRangeMode: PropTypes.string,
  viewMode: commonPropTypes.viewModePropTypes,
  __stubApiData: PropTypes.object,
  sliderRange: PropTypes.array,
  onNewFormattedData: PropTypes.func,
  onApiDataLoad: PropTypes.func,
  dimensionConfigsByRenderType: PropTypes.object,
  onSliderChange: PropTypes.func,
  handleSecondaryMetricChange: PropTypes.func,
  handleDimensionConfigsChange: PropTypes.func,
  handleBenchMarkChange: PropTypes.func,
  isChartAndTotalLoading: PropTypes.bool,
  onDataLoading: PropTypes.func,
  onAfterSummaryTableDataLoad: PropTypes.func,
  isEmbed: PropTypes.bool,
  isDimensionHighToLow: PropTypes.bool,
  isForecastingView: PropTypes.bool,
  forecastingOption: PropTypes.object,
  forecastPrepareDataTime: PropTypes.string,
  isUpdateChartData: PropTypes.array,
  projectionAdjustedValues: PropTypes.array,
  isBookMark: PropTypes.bool,
  onUpdateShowLegendTotal: PropTypes.func
};

export const defaultProps = {
  apiParams: {},
  viewMode: VIEW_MODE.LARGE,
  axisGranularity: AXIS_GRANULARITY_TYPES[0].name,
  secondaryMetricEntry: null,
  benchMarkEntries: null,
  compareYearRanges: [],
  compareToRanges:[],
  projectionEnabled: false,
  projectionType: LINEAR_FORECAST,
  projectionPercent: 3,
  sliderRange: [],
  __stubApiData: null,
  templateName: '',
  dimensionConfigsByRenderType: {},
  forecastingOption: {},
  forecastFormattedData: {},
  onNewFormattedData: _.noop,
  onApiDataLoad: _.noop,
  onSliderChange: _.noop,
  apiData: {},
  onNewApiData: _.noop,
  handleSecondaryMetricChange:_.noop,
  handleDimensionConfigsChange: _.noop,
  handleBenchMarkChange: _.noop,
  isChartAndTotalLoading: false,
  isEmbed: false,
  isDimensionHighToLow: false,
  isForecastingView: false,
  onDataLoading: _.noop,
  onAfterSummaryTableDataLoad: _.noop,
  isUpdateChartData: [],
  projectionAdjustedValues: [],
  isBookMark: false,
  onUpdateShowLegendTotal: _.noop
};
