import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';

import DropdownGroup from 'common/components/DropdownGroup/DropdownGroup';
import SearchAndTopValues from './SearchAndTopValues';

import {
  textOperatorEntries,
  isMultiValueSelect
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { getApiParams, getTextFilterParams } from 'helpers/apiParamsHelper';
import { getLogicalOperatorEntry } from './helper';
import { getGlobalFilterFieldValues, getGlobalFilterColumnValues } from 'common/api/commonApi';
import { getSearchFieldValues, getColumnTopValues } from 'common/api/drilldown';
import { isCurrencyRenderType } from './helper';

const TextFilter = (props) => {
  const {
    allFilters, filterFieldEntries, filter, onOperatorChange,
    apiParams, filterKey, onFilterItemClick, isExpanded
  } = props;

  const mapOptions = useSelector(state => _.get(state, 'visualization.mapOptions', {}));
  const drilldown = useSelector(state => _.get(state, 'drilldown', {}));
  const newFilterFieldEntries = isExpanded ?  allFilters : filterFieldEntries;

  const matchedFilterEntry = _.find(newFilterFieldEntries, (filterItem) =>{
    return (filterItem.field === _.get(filter, 'field'))
  });

  const newFilter = isExpanded ? matchedFilterEntry : filter;
  const [logicalOperatorEntry, setLogicalOperatorEntry] =
    useState(getLogicalOperatorEntry(newFilter));

  const operator = _.get(logicalOperatorEntry, 'value');

  const onChangeOperator = (option) => {
    setLogicalOperatorEntry(option);
    onOperatorChange({ operator: option.value });
  }
  const getSearchApiParams = () => {
    if (isMultiValueSelect(matchedFilterEntry)) {
      const commonFilters = useSelector(state => _.get(state, 'commonFilters', {}));
      const { availableTemplateIds } = apiParams;
      const apiParamsOptions = { commonFilters, mapOptions, drilldown };

      const params = getApiParams(apiParamsOptions, {
        withShapeIds: false,
        withPolygonFilter: false,
        withCommonFilters: true,
        globalFilterOptions: {
          replaceGlobalFilters: true,
          filterColumnEntry: matchedFilterEntry,
          globalFilters: allFilters
        }
      });
      return {
        ...params,
        availableTemplateIds: JSON.stringify(availableTemplateIds)
      }
    }else{
      return getTextFilterParams(apiParams, filter);
    }
  }

  const renderLogicalOperator = () => {
    return (
      <DropdownGroup
        key={`${filterKey}-logical-operator`}
        value={operator}
        label="Condition"
        optionDisplayField="name"
        activeOptionField="value"
        toggleButtonName="Select a value"
        options={textOperatorEntries}
        onChange={onChangeOperator} />
    );
  }

  const renderSearchAndTopValues = () => {
    const fetchTopValues = isMultiValueSelect(matchedFilterEntry) ?
      getGlobalFilterColumnValues:
      getColumnTopValues;
    const fetchSearchInputValues = isMultiValueSelect(matchedFilterEntry) ?
      getGlobalFilterFieldValues:
      getSearchFieldValues;


    return (
      <SearchAndTopValues
        onFilterItemClick={onFilterItemClick}
        key={`${filterKey}-search-input`}
        {...props}
        isCurrencyRenderType={isCurrencyRenderType(filterFieldEntries, filter)}
        searchApiParams={getSearchApiParams()}
        fetchTopValues={fetchTopValues}
        fetchSearchInputValues={fetchSearchInputValues}
        isExpanded={isExpanded}
        logicalOperatorEntry={logicalOperatorEntry} />
    );
  }

  if (isExpanded) {
    return (
      <div className='group-filter'>
        {renderLogicalOperator()}
        {renderSearchAndTopValues()}
      </div>
    );
  } else {
    return [renderLogicalOperator(), renderSearchAndTopValues()];
  }
}

TextFilter.propTypes = {
  allFilters: PropTypes.array,
  filterFieldEntries: PropTypes.array,
  filter: PropTypes.object,
  logicalOperatorEntry: PropTypes.object,
  onSearchChange: PropTypes.func,
  onOperatorChange: PropTypes.func,
  onApply: PropTypes.func,
  apiParams: PropTypes.object,
  filterKey: PropTypes.string,
  onFilterItemClick: PropTypes.func,
  isExpanded: PropTypes.bool,
};

export default TextFilter;
