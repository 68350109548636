import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames'

import Visualization from '../drilldown/visualizations/Visualization';
import TemplateCard from '../dashboard/components/TemplateCards/TemplateCard';
import BookmarkEmbedView from './BookmarkEmbedView';
import { getCollectionCardEntries, getViewMetricLinkForDashboardCard } from './helper';
import {
  getTemplateCardCommonFilters
} from 'pages/dashboard/components/Collections/collectionHelper';
import { getBookmark, getEmbedBookmark } from 'common/api/bookmarksApi';
import { renderVisualizationTitle } from 'pages/drilldown/drilldownHelper';
import { isChoroplethMap } from 'modules/Map/helpers/MapOptionsHelper';
import { OVERTIME_VISUALIZATION_TYPES } from 'appConstants';
import VisualizationHeader from 'pages/drilldown/visualizations/visualizationRenders/VisualizationHeader';
import VisualizationTitleInfo
  from 'pages/drilldown/visualizations/visualizationRenders/VisualizationTitleInfo';

class EmbedVisualization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookmarkEntry: {}
    }
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    const { bookmarkId } = this.props
    if (bookmarkId) {
      this.fetchBookmark(bookmarkId);
    }
  }

  fetchBookmark = (bookmarkId) => {
    const { isEmbedBookmarkCard } = this.props
    let bookmarkPromise;
    if (isEmbedBookmarkCard) {
      bookmarkPromise = getEmbedBookmark;
    } else {
      bookmarkPromise = getBookmark;
    }
    bookmarkPromise(bookmarkId)
      .then((response) => response.json())
      .then((response) => {
        this.setState({ bookmarkEntry: response });
      });
  }

  renderCard() {
    const { templateEntries, currentUser, cardId, commonFilters, state, globalFilters } = this.props;
    const cardEntry = getCollectionCardEntries(cardId, templateEntries);
    const { templateEntry, viewEntry, userCardEntry } = cardEntry;
    const viewMetricLink = getViewMetricLinkForDashboardCard(templateEntry, viewEntry, state);
    const options = {
      globalFilters,
      commonFilters,
      collectionEntry: {},
      cardEntry: cardEntry
    };
    const newCommonFilters = getTemplateCardCommonFilters(options);

    return (
      <TemplateCard
        globalFilters={globalFilters}
        onCardDelete={_.noop}
        currentUser={currentUser}
        userCardEntry={userCardEntry}
        cardEntry={templateEntry}
        viewEntry={viewEntry}
        index={cardId}
        onCardClick={_.noop}
        onCardMove={_.noop}
        commonFilters={newCommonFilters}
        isUserCard={false}
        key={cardId}
        viewMetricLink={viewMetricLink}
        isEmbed={true}
      />
    );
  }

  renderBookmarkCard = () => {
    const { currentUser, state } = this.props;
    const { bookmarkEntry } = this.state

    if (_.isEmpty(bookmarkEntry)) {
      return
    }
    return (
      <BookmarkEmbedView
        state={state}
        currentUser={currentUser}
        bookmarkEntry={bookmarkEntry} />
    )
  }

  renderVisualizationHeader(boundaryMapName) {
    const { isShowProjection } = this.props;
    const {
      visualizationTitle,
      isStackedChart,
      showProjectionButton
    } = renderVisualizationTitle(boundaryMapName, this.props);
    return (
      <VisualizationHeader
        isStackedChart={isStackedChart}
        isShowProjection={isShowProjection}
        showProjectionButton={showProjectionButton}
        render={(resetFilterContent, metricTotalContent, viewSourceContent) => (
          <VisualizationTitleInfo
            visualizationTitle={visualizationTitle}
            resetFilterContent={resetFilterContent}
            metricTotalContent={metricTotalContent}
            viewSourceContent={viewSourceContent} />
        )}
      />
    );
  }

  renderEmbedVisualization() {
    const { currentMapView, shapeName } = this.props;
    const boundaryMapName = isChoroplethMap(currentMapView) ? ` by ${shapeName}` : '';

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="embed-visualization">
            <div className="card-body">
              <div className="analysis-header">
                <div className="visualization-header">
                  {this.renderVisualizationHeader(boundaryMapName)}
                </div>
              </div>
              <div className="visualization-container">
                <Visualization isEmbed={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { cardId, isDarkTheme, isBookmarkCard } = this.props;

    const containerClassNames = classNames(
      'embed-visualization-container embed-navbar-wrapper',
      isDarkTheme ? 'embed-dark-theme' : 'embed-light-theme'
    );

    return (
      <div className={containerClassNames}>
        {isBookmarkCard ? this.renderBookmarkCard() :
          _.isNil(cardId) ? this.renderEmbedVisualization() : this.renderCard()
        }
      </div>
    );
  }
}

EmbedVisualization.propTypes = {
  currentUser: PropTypes.object,
  templateEntries: PropTypes.array,
  cardId: PropTypes.string,
  isDarkTheme: PropTypes.bool,
  isBookmarkCard: PropTypes.bool,
  isEmbedBookmarkCard: PropTypes.bool,
  commonFilters: PropTypes.object,
  bookmarkId: PropTypes.string,
  globalFilters: PropTypes.array,
  state: PropTypes.object,
  shapeName: PropTypes.string,
  currentMapView: PropTypes.object,
  isShowProjection: PropTypes.bool
}

EmbedVisualization.defaultProps = {
}

const mapDispatchToProps = {
};

function mapStateToProps(state) {
  const currentVisualizationType = _.get(state, 'drilldown.currentVisualizationType');
  return {
    state,
    currentUser: _.get(state.currentUser, 'user', {}),
    templateEntries: _.get(state, 'configurations.template_entries', []),
    cardId: _.get(state, 'embedOptions.card_id'),
    isDarkTheme: _.get(state, 'embedOptions.isDarkTheme') == 'true',
    isBookmarkCard: _.get(state, 'embedOptions.is_bookmark_card') == 'true',
    isEmbedBookmarkCard: _.get(state, 'embedOptions.is_embed_bookmark_card') == 'true',
    bookmarkId: _.get(state, 'embedOptions.bookmark_id'),
    commonFilters: _.get(state, 'commonFilters', {}),
    globalFilters: _.get(state, 'commonFilters.globalFilters', []),
    currentMapView: _.get(state, 'visualization.mapOptions.currentMapView'),
    shapeName: _.get(state, 'visualization.mapOptions.shape.datasetEntry.shape_name'),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView'),
    currentSecondaryMetricField: _.get(state, 'visualization.snapshot.currentSecondaryMetricField'),
    dimensionConfigsByRenderType: _.get(state, 'visualization.overtime.dimensionConfigsByRenderType', {}),
    currentChartView:  _.get(
      state, 'visualization.overtime.currentChartView', OVERTIME_VISUALIZATION_TYPES.TIMELINE.type),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    currentVisualizationType: currentVisualizationType,
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry', ''),


  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EmbedVisualization);
