import moment from 'moment';
import React, { Component } from 'react';

import {
  getSelectedDate,
  getStartDate
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { getConfiguredDateColumn, getValidStartAndEndDate } from 'helpers/dateHelper';
import { getDateDataBeginsFrom } from 'common/config/customerConfiguration';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'appConstants';
import { templateIdPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';
import ForgeDateInputPicker from '../ForgeDatePicker/ForgeDateInputPicker';

class DateFilter extends Component {
  constructor(props) {
    super(props);
    const { allFilters, filter } = this.props;

    this.state = this.getDefaultDateRangeOptions(allFilters, filter);
  }

  componentDidUpdate(prevProps) {
    const { allFilters, filter } = this.props;
    const isFilterChanged = !_.isEqual(prevProps.allFilters, allFilters);

    if(isFilterChanged) {
      this.setState(this.getDefaultDateRangeOptions(allFilters, filter));
    }
  }

  getMinDate = () => {
    const { templateId, filter, filterFieldEntries } = this.props;
    const filterEntry = _.find(filterFieldEntries, { field: filter.field }) || filter;
    const dataBeginDate = getDateDataBeginsFrom(templateId);
    const isTemplateDateColumn = filterEntry['column'] === getConfiguredDateColumn(templateId);

    return _.isEmpty(dataBeginDate) || !isTemplateDateColumn ?
      null :
      new Date(moment(dataBeginDate).format(DATE_TIME_FORMAT));
  }

  getDefaultDateRangeOptions = (filters, filterEntry) => {
    const  { isExpanded } = this.props;
    const defaultStartDate = getSelectedDate(filters, filterEntry, "startDate", isExpanded);
    const defaultEndDate = getSelectedDate(filters, filterEntry, "endDate", isExpanded);

    return {
      isStartDateChanged: false,
      isEndDateChanged: false,
      startDate: _.isDate(defaultStartDate) ?
        moment(defaultStartDate.toString()).format(DATE_FORMAT) : '',
      endDate: _.isDate(defaultEndDate) ?
        moment(defaultEndDate.toString()).format(DATE_FORMAT) : '',
      customDateRangeOptions: {
        start_date: moment(defaultStartDate).endOf('day').format(DATE_FORMAT),
        end_date: moment(defaultEndDate).endOf('day').format(DATE_FORMAT)
      }
    };
  }

  updateStartAndEndDateStatus = () => {
    setTimeout(() => {
      this.setState({ isEndDateChanged: false, isStartDateChanged: false });
    }, 1000);
  }

  handleSelectStartDate = (date) => {
    const { customDateRangeOptions, endDate } = this.state;
    const minDate = this.getMinDate();
    const newStartDate = moment(date.toString()).format(DATE_FORMAT);
    const { startDate, endDate: newEndDate, isEndDateChanged } = getValidStartAndEndDate({
      startDate: newStartDate,
      endDate,
      isStartDateChanged: true
    });

    let validStartDate = startDate;
    if(moment(startDate) < moment(minDate)){
      validStartDate = moment(minDate).format("YYYY-MM-DD");
    }

    const dateRangeOptions = {
      startDate: validStartDate,
      endDate: newEndDate,
      isEndDateChanged,
      customDateRangeOptions: {
        ...customDateRangeOptions,
        start_date: validStartDate,
        end_date: newEndDate
      }
    };
    this.setState(dateRangeOptions, () => {
      this.props.onOperatorChange({ dateRange: dateRangeOptions.customDateRangeOptions });
      this.updateStartAndEndDateStatus();
    });
  }

  handleSelectEndDate = (date) => {
    const { customDateRangeOptions, startDate } = this.state;
    const minDate = this.getMinDate();
    const newEndDate = moment(date.toString()).format(DATE_FORMAT);
    const { startDate: newStartDate, endDate, isStartDateChanged } = getValidStartAndEndDate({
      startDate, endDate: newEndDate
    });

    let validEndDate = endDate;
    if(moment(endDate) < moment(minDate)){
      validEndDate = moment(minDate).format("YYYY-MM-DD");
    }

    let validStartDate = newStartDate;
    if(moment(newStartDate) < moment(minDate)){
      validStartDate = moment(minDate).format("YYYY-MM-DD");
    }

    const dateRangeOptions = {
      startDate: validStartDate,
      endDate: validEndDate,
      isStartDateChanged,
      customDateRangeOptions: {
        ...customDateRangeOptions,
        start_date: validStartDate,
        end_date: validEndDate
      }
    };
    this.setState(dateRangeOptions, () => {
      this.props.onOperatorChange({ dateRange: dateRangeOptions.customDateRangeOptions });
      this.updateStartAndEndDateStatus();
    });
  }

  render() {
    const { startDate, endDate } = this.state;
    let forgeStartDate = "", forgeEndDate = "";
    if (!_.isEmpty(startDate)) {
      forgeStartDate = getStartDate(startDate);
    }

    if (!_.isEmpty(endDate)) {
      forgeEndDate = getStartDate(endDate);
    }

    return (
      <div className="filter-items d-flex align-items-center gap-10">
        <div>
          <ForgeDateInputPicker
            key='1'
            minDate={this.getMinDate()}
            value={forgeStartDate}
            label="Start Date"
            handleChangeDate={this.handleSelectStartDate} />
        </div>
        <div>
          <ForgeDateInputPicker
            key='2'
            minDate={this.getMinDate()}
            value={forgeEndDate}
            label="End Date"
            handleChangeDate={this.handleSelectEndDate} />
        </div>
      </div>
    );
  }
}

DateFilter.propTypes = {
  allFilters: PropTypes.array,
  filter: PropTypes.object,
  onOperatorChange: PropTypes.func,
  templateId: templateIdPropTypes,
  filterFieldEntries: PropTypes.array,
  isExpanded: PropTypes.bool,
}

export default DateFilter;
