import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import VisualizationSelector from './components/VisualizationSelector';
import Visualization from './visualizations/Visualization';
import TargetStatusBanner from './components/StatusBanner/TargetStatusBanner';

import VisualizationHeader from './visualizations/visualizationRenders/VisualizationHeader';
import VisualizationTitleInfo from './visualizations/visualizationRenders/VisualizationTitleInfo';

import VisualizationButtons from 'pages/drilldown/components/VisualizationButtons';
import {
  showTemplateWarningBanner,
  getTemplateExpirationMessage
} from 'helpers/templateHelper';
import {
  getDateFilterParams,
  getDefaultDateRange
} from 'helpers/dateHelper';
import {
  enableCollectionLevelDateFilters,
  configuredDefaultDateType,
  getGlobalFiltersFromTemplate
} from 'common/config/customerConfiguration';
import {
  getNextDrilldownDimensionField,
  getCurrentTemplateEntry,
  shouldShowMiniMap
} from 'common/config/templateConfiguration';
import ExpiryOverlay from 'pages/Expiry/ExpiryOverlay';
import {
  updateQuickFilters,
  setDrilldownDimensions,
  updateTemplateEntryChange,
  updateDrilldownDimensionField
} from 'actions/drilldownActions';
import { updateShowTableViewOnDrilldown, updateShowLeafPage } from 'actions/tableActions';
import {
  setDefaultDrilldownMapOptions,
  removePolygonFilter,
  updatePolygonFeature
} from 'actions/mapOptionsActions';
import {
  updateGlobalFilter,
  updateDateFilters,
  updateDateRange,
  updateDateType,
  updateComparisonModeOn,
  updateComparisonDateRanges
} from 'actions/commonFiltersActions';
import { getApiParams } from 'helpers/apiParamsHelper';
import {
  getNewQuickFilters,
  getViewEntryQuickFilters,
  getQuickFilterEntriesFromTemplate
} from 'pages/drilldown/components/QuickFilterBar/helper';
import GlobalEvent from 'common/components/GlobalEvents';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import { getNewQuickFiltersAndGlobalFilters } from 'helpers/FilterHelper';
import {
  updateBreadcrumbsSelect,
  getDrillDownEntries,
  dispatchUpdateDrilldownFilters,
  renderVisualizationTitle,
} from './drilldownHelper';
import LeftSideSection from './LeftSideSection';
import { updateCurrentTemplateCardId } from 'common/api/drilldown';

import { isChoroplethMap } from 'modules/Map/helpers/MapOptionsHelper';
import {
  OVERTIME_VISUALIZATION_TYPES,
  AXIS_GRANULARITY_TYPES
} from 'appConstants';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getSingleCollectionDetails } from 'common/api/collectionApi';
import { setManageCollectionDetails } from 'actions/dashboardActions';
import { isMobileOrTablet } from 'helpers/DomPageHelper';
import ProjectionBanner from './components/LineChartOptions/ProjectionBanner';

class Drilldown extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      commonFilters: _.get(props, 'commonFilters', {})
    }
  }

  state = {
    isRedirectHome: false,
    isRedirectToOverviewPage: false,
    dimensionsTotalEntry: {},
    userTargetEntryId: null,
    isLoading: false,
    isDrilledDownToNextLevel: false,
    map: null,
    isSideBar: false
  }

  componentDidMount() {
    const {
      currentDrilldownTemplateName,
      newLabelCardId,
      dispatchTemplateSelection,
      templateEntries,
      currentDrilldownViewEntry,
      isManageCollection,
      currentCollectionId,
      dispatchSetManageCollectionDetails
    } = this.props;

    if (_.isEmpty(currentDrilldownTemplateName) && !_.isEmpty(templateEntries)) {
      dispatchTemplateSelection(_.first(templateEntries));
    }
    if (_.isEmpty(currentDrilldownViewEntry)) {
      this.setState({ isRedirectToOverviewPage: true });
    }
    if (!_.isEmpty(newLabelCardId)) {
      updateCurrentTemplateCardId(newLabelCardId).then((response) => {
        console.log('User card preference successfully updated!!', response);
      }).catch((err) => {
        console.error('User card preference error', err)
      });
    }
    if (isManageCollection && currentCollectionId) {
      const abortcontroller = new AbortController();
      getSingleCollectionDetails(currentCollectionId, abortcontroller).then((response) => {
        dispatchSetManageCollectionDetails(_.get(response, '0', {}));
      });
    }

    this.loadSidebarOpen();

    window.scrollTo(0, 0);
    $("#branding-logo").focus();
  }

  componentDidUpdate(prevProps) {
    const {
      drilledDownDimensions, showTableViewOnDrilldown, currentVisualizationType
    } = this.props;

    const isDrilledDownDimensionsChanged = !_.isEqual(prevProps.drilledDownDimensions, drilledDownDimensions);
    const isVisualizationChanged = !_.isEqual(prevProps.currentVisualizationType, currentVisualizationType);
    const isTableViewChanged = !_.isEqual(prevProps.showTableViewOnDrilldown, showTableViewOnDrilldown);

    if (isDrilledDownDimensionsChanged || isTableViewChanged) {
      // if prevProps.isLeafPage is true its DrilledUp from leaf page

      const isDrilledDownToNextLevel = (drilledDownDimensions.length >
        prevProps.drilledDownDimensions.length) ||
        (prevProps.showTableViewOnDrilldown === false && showTableViewOnDrilldown === true) &&
        (prevProps.isLeafPage !== true)

      this.setState({ isDrilledDownToNextLevel })
    }

    if (isVisualizationChanged) {
      this.setState({ map: null })
    }
  }

  setUserTargetEntryId = (userTargetEntryId) => {
    this.setState({ userTargetEntryId });
  }

  componentWillUnmount() {
    GlobalEvent.emit('TEMPLATE_EXPIRY_MESSAGE', {
      message: ''
    });
  }

  onMapLoaded = (map) => {
    const { currentDrilldownTemplateId } = this.props;
    if (!shouldShowMiniMap(currentDrilldownTemplateId)) {
      this.setState({ map })
    }
  }

  onFilterChange = (filters) => {
    dispatchUpdateDrilldownFilters(this.props, filters);
  }

  onResetFilter = ({ collectionFilters, dateFilters, quickFilters, filteredGeojson }) => {
    const {
      dispatchGlobalFiltersChange,
      dispatchDateFiltersChange,
      dispatchRemovePolygonFilter,
      dispatchUpdatePolygonFeature } = this.props;

    if (enableCollectionLevelDateFilters()) {
      dispatchDateFiltersChange(dateFilters);
    }

    dispatchGlobalFiltersChange(collectionFilters);
    if (_.isEmpty(filteredGeojson)) {
      dispatchRemovePolygonFilter();
    } else {
      dispatchUpdatePolygonFeature(filteredGeojson);
    }
    this.onFilterChange(quickFilters);
  }

  onBreadcrumbsSelect = (breadcrumbEntry) => {
    updateBreadcrumbsSelect(this.props, breadcrumbEntry);
  }

  onExportChart = () => {
    return this.plotContainer.querySelector('.content-section')
  }

  onBackClick = (breadcrumbList) => {
    const {
      currentDrilldownTemplateId,
      currentUser,
      currentDrilldownGroupByEntry,
      currentDrilldownDimensionField,
      currentDrilldownViewEntry,
      dispatchUpdateDrilldownDimensionField,
      drilledDownDimensions,
      quickFilters,
      dispatchSetDrilldownDimensions,
      dispatchUpdateQuickFilters,
      dispatchUpdateDateRange,
      dispatchDateFiltersChange,
      dispatchUpdateComparisonDateRanges,
      dispatchUpdateShowLeafPage,
      dispatchUpdateShowTableViewOnDrilldown,
      insightId,
      isComparisonEnabled,
      comparisonDateRanges
    } = this.props;
    const { commonFilters } = this.state;
    const lastBreadCrumbEntry = _.last(breadcrumbList);
    const groupByFiled = _.get(currentDrilldownGroupByEntry, 'field');
    let removedDimensions = drilledDownDimensions;
    let alreadyDrilledDownDimensions = [{ field: groupByFiled }];
    let newQuickFilters = _.cloneDeep(quickFilters);
    const newDrilledDownDimensions = _.cloneDeep(drilledDownDimensions);

    if (!_.isEmpty(insightId)) {
      this.setState({ isRedirectHome: true });
    }

    if (lastBreadCrumbEntry.isLeafPageLink) {
      dispatchUpdateShowTableViewOnDrilldown(true);
      dispatchUpdateShowLeafPage(false);
    } else {
      dispatchUpdateShowTableViewOnDrilldown(false);
      if (!_.isEmpty(newDrilledDownDimensions)) {
        newDrilledDownDimensions.pop();
        if (!_.isEmpty(newDrilledDownDimensions)) {
          removedDimensions = [_.last(drilledDownDimensions)];
          alreadyDrilledDownDimensions = alreadyDrilledDownDimensions.concat(newDrilledDownDimensions);
        }
        newQuickFilters = getNewQuickFilters(
          removedDimensions,
          currentDrilldownTemplateId,
          quickFilters
        );
      }
      this.setState({ isRedirectHome: true }, () => {
        dispatchUpdateQuickFilters([]);
        dispatchDateFiltersChange(commonFilters);
        // dispatchUpdateComparisonModeOn(false);
        if(isComparisonEnabled && _.size(comparisonDateRanges) > 1){
          const firstDateRange = _.first(comparisonDateRanges);
          const updateDateRange = [firstDateRange]
          dispatchUpdateComparisonDateRanges(updateDateRange);
        }

        if (_.isEmpty(currentUser)) {
          const dateRange = getDefaultDateRange(relativeDateFilterEntryDefaultState);
          dispatchUpdateDateRange(dateRange);
        }
      });
      const nextDrilldownDimensionField = getNextDrilldownDimensionField(
        currentDrilldownTemplateId,
        alreadyDrilledDownDimensions,
        currentDrilldownViewEntry
      );

      if (_.isEmpty(currentDrilldownDimensionField)) {
        dispatchUpdateDrilldownDimensionField(nextDrilldownDimensionField);
      }

      if (!_.isEmpty(nextDrilldownDimensionField)) {
        dispatchSetDrilldownDimensions(newDrilledDownDimensions);
        dispatchUpdateQuickFilters(newQuickFilters);
      }
    }
  }

  renderTargetStatus = () => {
    const {
      drilldown, currentDrilldownViewEntry, apiParams, currentVisualizationType, currentBenchMarkMetricNames,
      isDiscrete, isCumulative, currentDrilldownTemplateId, currentUser, globalFilters, metricTotalData,
      metricTotalLoading, currentBookmarkId
    } = this.props;
    const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
    const quickFilterEntries = getQuickFilterEntriesFromTemplate(
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    );

    const globalFilterConfigEntries = getGlobalFiltersFromTemplate(templateEntry);
    const quickFilters = getViewEntryQuickFilters(currentDrilldownTemplateId, currentDrilldownViewEntry);
    const { newQuickFilters } = getNewQuickFiltersAndGlobalFilters({
      collectionFilters: _.cloneDeep(globalFilters),
      globalFilterConfigEntries,
      quickFilters: _.cloneDeep(quickFilters),
      quickFilterEntries
    });

    return (
      <TargetStatusBanner
        metricTotalData={_.get(metricTotalData, 'currentPeriodMetricTotals', {})}
        metricTotalLoading={metricTotalLoading}
        currentUser={currentUser}
        isCumulative={isCumulative}
        isDiscrete={isDiscrete}
        benchMarkNames={currentBenchMarkMetricNames}
        visualizationType={currentVisualizationType}
        currentViewEntry={currentDrilldownViewEntry}
        defaultQuickFilters={newQuickFilters}
        quickFilters={drilldown.quickFilters}
        templateId={currentDrilldownTemplateId}
        queryParams={apiParams}
        bookmarkId={currentBookmarkId}
        setUserTargetEntryId={this.setUserTargetEntryId} />
    );
  }

  renderExpiryOverlay() {
    const {
      currentDrilldownTemplateId,
      currentDrilldownTemplateName
    } = this.props;
    const expirationConfigMessage = getTemplateExpirationMessage(currentDrilldownTemplateId);
    const templateExpiryMessage = _.replace(expirationConfigMessage,
      '{{template_name}}', `"${currentDrilldownTemplateName}"`);

    return (showTemplateWarningBanner(currentDrilldownTemplateId) &&
      <ExpiryOverlay expiryMessage={templateExpiryMessage} />);
  }

  onToggleButtonClick = () => {
    const { isSideBar } = this.state;
    this.setState({ isSideBar: !isSideBar });

    if (!isSideBar) {
      document.getElementById('app-root-id').classList.add("toggle-sidebar-hide");
    } else {
      document.getElementById('app-root-id').classList.remove("toggle-sidebar-hide");
    }

    if (isMobileOrTablet()) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 150);
    }

  }

  loadSidebarOpen = () => {
    document.getElementById('app-root-id').classList.remove("toggle-sidebar-hide");
  }

  renderVisualizationHeader(boundaryMapName) {
    const { isShowProjection } = this.props;
    const { isRedirectHome, chartFormattedData } = this.state;
    const {
      visualizationTitle,
      isStackedChart,
      showProjectionButton
    } = renderVisualizationTitle(boundaryMapName, this.props, chartFormattedData);
    const listItems = getDrillDownEntries(this.props, this.state);
    return (
      <VisualizationHeader
        isStackedChart={isStackedChart}
        isShowProjection={isShowProjection}
        showProjectionButton={showProjectionButton}
        render={(resetFilterContent, metricTotalContent, viewSourceContent) => (
          <VisualizationTitleInfo
            visualizationTitle={visualizationTitle}
            resetFilterContent={resetFilterContent}
            metricTotalContent={metricTotalContent}
            viewSourceContent={viewSourceContent}
            onBackClick={this.onBackClick}
            backListItems={listItems}
            isRedirectHome={isRedirectHome} />
        )}
      />
    );
  }

  onToggleButtonKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.onToggleButtonClick();
    }
  }

  render() {
    const { isDrilledDownToNextLevel, map, userTargetEntryId, isSideBar } = this.state;
    const { currentMapView, shapeName, dispatchGlobalFiltersChange } = this.props;
    const boundaryMapName = isChoroplethMap(currentMapView) ? ` by ${shapeName}` : '';
    if (this.state.isRedirectToOverviewPage) {
      return <Redirect push to="/overview" />;
    }

    if (this.state.isRedirectToForecasting) {
      return <Redirect push to="/forecasting" />;
    }

    return (
      <div className="drilldown-page">
        {this.renderExpiryOverlay()}
        <div className="analysis-page forge-inline-message" ref={(ref) => this.plotContainer = ref}>
          {/* LeftSideSection */}
          <div className="filters-section drawer-panel">
            <div className="toggle-btn"
              tabIndex={0}
              onClick={this.onToggleButtonClick}
              onKeyDown={this.onToggleButtonKeyDown}>
              <span className="icons-chevron-left"></span>
            </div>
            <LeftSideSection
              map={map}
              onResetFilter={this.onResetFilter}
              onFilterChange={this.onFilterChange}
              dispatchGlobalFiltersChange={dispatchGlobalFiltersChange}
            />
          </div>
          {/* MiddleSection */}
          <div className="content-section">
            <div className="analysis-header">
              <div className="visualization-header">
                {this.renderVisualizationHeader(boundaryMapName)}
              </div>

              <VisualizationButtons
                userTargetEntryId={userTargetEntryId}
                plotlyChart={this.onExportChart} />
            </div>
            <ProjectionBanner />
            {this.renderTargetStatus()}
            <VisualizationSelector />
            <div className="card analysis-details-container">
              <div className="card-body">
                <div
                  className="visualization-container">
                  <Visualization
                    onShowTableView={this.onShowTableView}
                    onMapLoaded={this.onMapLoaded}
                    isDrilledDownToNextLevel={isDrilledDownToNextLevel}
                    isSideBar={isSideBar} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);
  const currentVisualizationType = _.get(state, 'drilldown.currentVisualizationType');

  return {
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    currentDrilldownTemplateName: _.get(state, 'drilldown.currentDrilldownTemplateName'),
    drilledDownDimensions: _.cloneDeep(_.get(state, 'drilldown.drilledDownDimensions', [])),
    templateEntries: _.get(state, 'configuratifilteredGeojsonons.template_entries', []),
    quickFilters: _.get(state, 'drilldown.quickFilters', []),
    drilldown: _.get(state, 'drilldown', {}),
    isLeafPage: _.get(state, 'visualization.table.isLeafPage', false),
    showTableViewOnDrilldown: _.get(state, 'visualization.table.showTableViewOnDrilldown', false),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry', ''),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentBenchMarkMetricNames: _.get(state, 'visualization.distribution.currentBenchMarkMetricNames', []),
    apiParams: getApiParams(apiParamsOptions, {}),
    currentVisualizationType: currentVisualizationType,
    dateFilters: getDateFilterParams(_.get(state, 'commonFilters', {})),
    currentUser: _.get(state, 'currentUser.user', {}),
    commonFilters: _.get(state, 'commonFilters', {}),
    globalFilters: _.get(state, 'commonFilters.globalFilters', []),
    isDiscrete: _.get(state, 'visualization.distribution.isDiscrete'),
    isCumulative: _.get(state, 'visualization.distribution.isCumulativeDistribution'),
    metricTotalData: _.get(state, 'metricTotal.totals', {}),
    metricTotalLoading: _.get(state, 'metricTotal.isLoading', false),
    insightId: _.get(state, 'insight.insightId', ''),
    currentBookmarkId: _.get(state, 'bookmark.currentBookmarkId', null),
    filteredGeojson: _.get(state, 'visualization.mapOptions.filteredGeojson'),
    newLabelCardId: _.get(state, 'drilldown.newLabelCardId'),
    currentMapView: _.get(state, 'visualization.mapOptions.currentMapView'),
    shapeName: _.get(state, 'visualization.mapOptions.shape.datasetEntry.shape_name'),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView'),
    currentSecondaryMetricField: _.get(state, 'visualization.snapshot.currentSecondaryMetricField'),
    dimensionConfigsByRenderType: _.get(state, 'visualization.overtime.dimensionConfigsByRenderType', {}),
    currentChartView: _.get(
      state, 'visualization.overtime.currentChartView', OVERTIME_VISUALIZATION_TYPES.TIMELINE.type),
    axisGranularity: _.get(state, 'visualization.overtime.axisGranularity', AXIS_GRANULARITY_TYPES[0].value),
    currentSelectedTimeFrame: _.get(state, 'visualization.overtime.currentSelectedTimeFrame', ''),
    isShowProjection: JSON.parse(_.get(state, 'visualization.overtime.isShowProjection', false)),
    minDatesTemplateForForecast: _.get(state, 'forecasting.minDatesTemplateForForecast', []),
    isManageCollection: _.get(state, 'dashboard.isManageCollection', false),
    currentCollectionId: _.get(state, 'dashboard.currentCollectionId', null),
    isComparisonEnabled: _.get(state, 'commonFilters.comparisonModeOn', false),
    comparisonDateRanges: _.get(state, 'commonFilters.comparisonDateRanges', [])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetDrilldownDimensions: (drilldownEntries) => {
      dispatch(setDrilldownDimensions(drilldownEntries));
    },
    dispatchSetManageCollectionDetails: (collection) => {
      dispatch(setManageCollectionDetails(collection));
    },
    dispatchUpdateDrilldownDimensionField: (field) => {
      dispatch(updateDrilldownDimensionField(field));
    },
    dispatchDateFiltersChange: (filters) => {
      dispatch(updateDateFilters(filters));
    },
    dispatchUpdateComparisonModeOn: (comparisonModeOn) => {
      dispatch(updateComparisonModeOn(comparisonModeOn));
    },
    dispatchUpdateQuickFilters: (filters) => {
      dispatch(updateQuickFilters(filters))
    },
    dispatchUpdateShowLeafPage: (isLeafPage) => {
      dispatch(updateShowLeafPage(isLeafPage));
    },
    dispatchUpdateShowTableViewOnDrilldown: (isTableView) => {
      dispatch(updateShowTableViewOnDrilldown(isTableView));
    },
    dispatchTemplateSelection: (templateEntry) => {
      const templateId = templateEntry.template_id;
      const viewEntry = _.first(_.get(templateEntry, 'view_entries', []))
      const quickFilters = getViewEntryQuickFilters(templateId, viewEntry);

      dispatch(updateTemplateEntryChange(templateEntry));
      dispatch(updateQuickFilters(quickFilters));
      dispatch(setDefaultDrilldownMapOptions(templateId, viewEntry)); //Default Map Options
    },
    dispatchGlobalFiltersChange: (filters) => {
      dispatch(updateGlobalFilter(filters));
    },
    dispatchUpdateDateRange: (dateRange) => {
      dispatch(updateDateRange(dateRange));
      dispatch(updateDateType(configuredDefaultDateType));
    },
    dispatchUpdateComparisonDateRanges: (dateRanges) => {
      dispatch(updateComparisonDateRanges(dateRanges));
    },
    dispatchRemovePolygonFilter: () => {
      dispatch(removePolygonFilter());
    },
    dispatchUpdatePolygonFeature: (featuresGeojson) => {
      dispatch(updatePolygonFeature({ featuresGeojson }));
    }
  };
}

Drilldown.propTypes = {
  currentDrilldownDimensionField: PropTypes.string,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentDrilldownTemplateName: PropTypes.string,
  currentVisualizationType: PropTypes.string,
  showTableViewOnDrilldown: PropTypes.bool,
  templateEntries: commonPropTypes.templateEntriesPropTypes,
  drilledDownDimensions: PropTypes.array,
  isLeafPage: PropTypes.bool,
  currentUser: commonPropTypes.userPropTypes,
  currentDrilldownGroupByEntry: commonPropTypes.groupByEntryPropTypes,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  insightId: commonPropTypes.stringOrNumberProps,
  dateFilters: PropTypes.object,
  apiParams: PropTypes.object,
  drilldown: commonPropTypes.drilldownPropTypes,
  isDiscrete: PropTypes.bool,
  isCumulative: PropTypes.bool,
  globalFilters: PropTypes.array,
  currentBenchMarkMetricNames: PropTypes.array,
  currentBookmarkId: commonPropTypes.stringOrNumberProps,
  dispatchSetDrilldownDimensions: PropTypes.func,
  dispatchUpdateDrilldownDimensionField: PropTypes.func,
  dispatchDateFiltersChange: PropTypes.func,
  dispatchUpdateQuickFilters: PropTypes.func,
  dispatchUpdateShowLeafPage: PropTypes.func,
  dispatchUpdateAxisGranularity: PropTypes.func,
  // dispatchUpdateComparisonType: PropTypes.func,
  // dispatchUpdateComparisonModeOn: PropTypes.func,
  dispatchUpdateShowTableViewOnDrilldown: PropTypes.func,
  dispatchTemplateSelection: PropTypes.func,
  dispatchSetCurrentVisualizationType: PropTypes.func,
  dispatchGlobalFiltersChange: PropTypes.func,
  dispatchUpdateComparisonModeOn: PropTypes.func,
  dispatchUpdateDateRange: PropTypes.func,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  currentChartView: PropTypes.string,
  currentVizBasedChartType: PropTypes.string,
  metricTotalData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  metricTotalLoading: PropTypes.bool,
  dispatchRemovePolygonFilter: PropTypes.func,
  dispatchUpdatePolygonFeature: PropTypes.func,
  filteredGeojson: PropTypes.object,
  newLabelCardId: PropTypes.string,
  currentSnapshotView: PropTypes.string,
  currentSelectedTimeFrame: PropTypes.string,
  axisGranularity: PropTypes.string,
  currentMapView: PropTypes.object,
  dimensionConfigsByRenderType: PropTypes.object,
  isShowProjection: PropTypes.bool,
  shapeName: PropTypes.string,
  currentSecondaryMetricField: PropTypes.string,
  minDatesTemplateForForecast: PropTypes.array,
  dispatchSetManageCollectionDetails: PropTypes.func,
  currentCollectionId: PropTypes.string,
  isManageCollection: PropTypes.bool,
  isComparisonEnabled: PropTypes.bool,
  dispatchUpdateComparisonDateRanges: PropTypes.func,
  comparisonDateRanges: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(Drilldown);
