import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Security from 'modules/Administration/Security';
import Branding from 'modules/Administration/Branding';
import UserProfile from 'modules/User/UserProfile';

import {
  getAppConfigurations,
  bulkUpdateConfigurations,
  getPermissionDatasetData,
  updateUserConfigurations
} from 'common/api/adminApi';
import { fetchApiData } from 'helpers/apiResponseHelper';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { trackEvent } from 'helpers/eventTracking';
import { getAdminConfigurations } from './adminHelper';
import { isRowLevelPermissionEnabled, versionName } from 'common/config/customerConfiguration';
import { mapPermissionDataForUsers } from 'modules/Administration/permissionHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  userRoleSavedMessage,
  brandingSavedMessage,
  userRoleSavedErrorMessage,
  brandingSavedErrorMessage
} from 'helpers/toastMessages';
import { handleBackButtonClick } from 'helpers/visualizationHelper';
import { withRouter } from 'react-router-dom';
import {
  SECURITYLIST_PAGE,
  BRANDING_PAGE,
  USER_PROFILE_PAGE
} from 'appConstants';
import { updateUserMenu } from 'actions/managePageActions';

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configurations: [],
      enableSave: false,
      isLoading: false,
      configData: [],
      isBrandingChanges: false,
      permissionData: []
    };
  }

  componentDidMount() {
    this.fetchAppConfigurations();
  }

  componentDidUpdate(prevProps, prevState) {
    const { configurations } = this.state;
    const { currentMenu } = this.props;
    const preMenu = _.get(prevProps, 'currentMenu')
    const isSecurityPage = ((currentMenu == SECURITYLIST_PAGE) &&
      !_.isEmpty(preMenu) && preMenu != SECURITYLIST_PAGE);
     if (!_.isEqual(configurations, prevState.configurations)) {
      this.updateButtonStatus(true);
    }
    if(isSecurityPage){
      setTimeout(() => {
        this.fetchAppConfigurations();
      },100);
    }
  }

  componentWillUnmount() {
    if (this.abortFetchController) {
      this.abortFetchController.abort();
    }
  }

  fetchAppConfigurations = () => {
    const { userFromBellerophon } = this.props;
    this.setState({ isLoading: true });
    const queryParams = {
      app_id: _.get(userFromBellerophon, "application.application_id"),
      version_name: versionName
    };

    const configDataApi = getAppConfigurations(queryParams);
    const appConfigPromise = fetchApiData(configDataApi);
    const permissionPromise = this.fetchPermissionData();
    Promise.all([appConfigPromise, permissionPromise]).
      then((results) => {
        const adminConfigs = getAdminConfigurations(results[0]);
        this.setState({
          isLoading: false,
          configData: mapPermissionDataForUsers(adminConfigs, results[1])
        });
      }).catch((error) => {
        console.log("Error on fetching user config ", error);
        this.setState({ isLoading: false });
      });
  }

  fetchPermissionData = () => {
    if (!isRowLevelPermissionEnabled()) {
      return Promise.resolve();
    } else {
      return getPermissionDatasetData()
    }
  }

  updateButtonStatus(flagValue) {
    this.setState({ enableSave: flagValue });
  }

  handleSwitchMenu = (currentMenu) => {
    this.props.dispatchUpdateUserMenu(currentMenu);
  }

  handlePressEnterButton = (e, page) => {
    if (isEnterButtonPressed(e)) {
      this.handleSwitchMenu(page)
    }
  }

  handleConfigurationChange = (options) => {
    const {
      configKeyValues,
      isBrandingChange,
      immediateUpdate,
      toastMessage,
      loggerMessage,
      toastErrorMessage,
      isBulkUpload
    } = options;
    const { configurations } = this.state

    let update_configurations = _.cloneDeep(configurations);
    _.forEach(configKeyValues, (config) => {
      const existConfigIndex = _.findIndex(update_configurations, { 'app_config_key': config.app_config_key })

      if (existConfigIndex < 0) {
        update_configurations.push(config);
      } else {
        update_configurations[existConfigIndex] = config
      }
    });
    this.setState({
      configurations: update_configurations,
      isBrandingChanges: isBrandingChange || false,
      isBulkUpload : isBulkUpload || false
    }, () => {
      if (immediateUpdate) { this.onSave(toastMessage, loggerMessage, toastErrorMessage); }
    })
  }

  handleUserSecurityChange = (options) => {
    const { userFromBellerophon } = this.props;
    const {
      userConfig,
      updateType,
      email,
      toastMessage,
      toastErrorMessage,
      loggerMessage,
      isPermissionUpdate
    } = options;

    const configParams = {
      app_id: _.get(userFromBellerophon, "application.application_id"),
      update_type: updateType,
      user_config: JSON.stringify(userConfig),
      email,
      site_type: "executiveInsights",
      include_permission_mapping_update: isPermissionUpdate,
      loggerMessage
    }

    this.setState({ isLoading: true });
    updateUserConfigurations(configParams).
      then((response) => {
        if (response.ok) {
          this.setState({ enableSave: false });
          if (toastMessage) {
            toast.success(toastMessage);
          } else {
            trackEvent('click_save_user');
            const successMessage = userRoleSavedMessage;
            if(successMessage){
              toast.success(successMessage);
            }
          }
          setTimeout(() => {
            this.fetchAppConfigurations();
          }, 100);
        } else {
          this.setState({ isLoading: false });
          if (toastMessage) {
            toast.error(toastErrorMessage);
          } else {
            const errorMessage = userRoleSavedErrorMessage;
            toast.error(errorMessage);
          }
        }
      }).
      catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        toast.error(toastErrorMessage);
      });
  }

  onSave = (toastMessage = null, loggerMessage = null, toastErrorMessage = null) => {
    const { userFromBellerophon } = this.props;
    const { configurations, isBrandingChanges , isBulkUpload } = this.state;

    const configParams = {
      app_id: _.get(userFromBellerophon, "application.application_id"),
      configs: JSON.stringify(configurations),
      site_type: "executiveInsights",
      loggerMessage
    }

    this.setState({ isLoading: true });
    bulkUpdateConfigurations(configParams).
      then((response) => {
        if (response.ok) {
          this.setState({ enableSave: false });
          if (toastMessage) {
            toast.success(toastMessage);
          } else {
            trackEvent('click_save_user');
            const successMessage = isBrandingChanges ? brandingSavedMessage :
              (isBulkUpload ? null : userRoleSavedMessage);
            if(successMessage){
              toast.success(successMessage);
            }
          }
          if (isBrandingChanges) {
            window.location.reload(false);
          }
          setTimeout(() => {
            this.fetchAppConfigurations();
          }, 100);
        } else {
          this.setState({ isLoading: false });
          if (toastMessage) {
            toast.error(toastErrorMessage);
          } else {
            const errorMessage = isBrandingChanges ? brandingSavedErrorMessage : userRoleSavedErrorMessage;
            toast.error(errorMessage);
          }
        }
      }).
      catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);
        toast.error(toastErrorMessage);
      });
  }

  enableLoading = (isLoading) => {
    this.setState({ isLoading });
  }

  renderSecurityListPage() {
    const { currentMenu, userFromBellerophon } = this.props;
    const { configData } = _.cloneDeep(this.state);
    const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
    if (currentMenu !== SECURITYLIST_PAGE || (!isLaunchpadAdmin && _.isEmpty(configData.appConfigUsers))) {
      return null;
    }

    return (
      <>
        <div className="admin-rightside">
          <Security
            configData={configData.appConfigUsers}
            handleUserSecurityChange={this.handleUserSecurityChange}
            userFromBellerophon={userFromBellerophon}
            enableLoading={this.enableLoading}
          />
        </div>
      </>
    )
  }

  renderUserProfilePage() {
    const { currentMenu } = this.props;
    const { configData, isLoading } = this.state;
    if (currentMenu !== USER_PROFILE_PAGE) {
      return null;
    }
    const appConfigUsers = _.get(configData, 'appConfigUsers', {});

    return (
      <div className="admin-rightside p-0">
        <UserProfile {...this.props} isLoading={isLoading} appConfigUsers={appConfigUsers} />
      </div>
    )
  }

  renderBrandingPage() {
    const { currentMenu } = this.props;
    const { configData } = this.state
    if (currentMenu !== BRANDING_PAGE) {
      return null;
    }

    return (
      <>
        <div className="admin-rightside">
          <Branding
            configData={configData['branding']}
            onHandleConfigurationChange={this.handleConfigurationChange}
            onSaveClick={this.onSave} />
        </div>
      </>
    )
  }

  renderSpinner() {
    const { isLoading } = this.state;

    return (
      <LoadingSpinner isLoading={isLoading} />
    );
  }

  renderBackOverView() {
    return (
      <button
        aria-label="Back To Overview"
        className="btn back-arrow-icon back-btn-light"
        onClick={() => handleBackButtonClick(this.props.history)}>
        <i className="icons-arrow-left2" />
      </button>
    );
  }

  renderMenu = ({ page, pageName }) => {
    const { currentMenu } = this.props;
    const menuClassNames = (currentMenu === page) ? 'active link-items' : 'link-items';

    return (
      <div
        tabIndex="0"
        key={page}
        onKeyDown={(e) => this.handlePressEnterButton(e, page)}
        className={menuClassNames}
        onClick={() => this.handleSwitchMenu(page)}> {pageName}
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderSpinner()}
        <main className="bg-white admin-page">
          <div className="page-main-head admin-head">
            {this.renderBackOverView()}
            <span className="title-head">Administration</span>
          </div>

          <div className="admin-wrapper">
            <div className="main-container d-flex ">
              {this.renderUserProfilePage()}
              {this.renderSecurityListPage()}
              {this.renderBrandingPage()}
            </div>
          </div>
        </main>
      </>
    )
  }
}

Admin.propTypes = {
  userFromBellerophon: PropTypes.object,
  history: PropTypes.object,
  dispatchUpdateUserMenu: PropTypes.func,
  currentMenu: PropTypes.string
}

function mapStateToProps(state) {
  return {
    userFromBellerophon: state.userFromBellerophon,
    currentUser: state.currentUser,
    currentMenu: _.get(state, 'manage.userMenu', USER_PROFILE_PAGE)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateUserMenu: (menu) => {
      dispatch(updateUserMenu(menu));
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
