import React from "react"
import PropTypes from 'prop-types';

import ForgeDropdowns from 'common/components/ForgeDropdowns/ForgeDropdowns';
import { DEFAULT_COMPARE_TO_DROPDOWN_OPTION } from "appConstants";


const CompareToOptions = (props) => {
  const {
    label, key,
    dropdownOptions,
    currentCompareOption,
    onOptionChange,
  } = props;

  const currentValue = _.get(currentCompareOption, 'name') ||
    _.get(DEFAULT_COMPARE_TO_DROPDOWN_OPTION, 'name');

    if (_.isEmpty(dropdownOptions)) {
    return null;
  }

  const customOptions = _.map(dropdownOptions, (option) => {
    option['value'] = _.get(option, 'name')
    return option;
  })

  return (
    <div className='dropdown-field'>
      <ForgeDropdowns
        key={key}
        value={currentValue}
        label={label}
        options={customOptions}
        handleOnSelect={(option) => onOptionChange(option)}
      />
    </div>
  );
};

CompareToOptions.propTypes = {
  key: PropTypes.string,
  label: PropTypes.string,
  dropdownOptions: PropTypes.array,
  currentCompareOption: PropTypes.object,
  onOptionChange: PropTypes.func,
}
export default CompareToOptions;
