import { getCompFinderIcon } from 'common/config/templateConfiguration';
import React from 'react';
import PropTypes from 'prop-types';

function CompFinderIcon(prop) {
  const { templateId, searchMethod } = prop;
  let iconName = getCompFinderIcon(templateId, searchMethod);

  if (_.isEmpty(iconName)) {
    return null;
  }
  iconName = `./images/${iconName}`;

  return (
    <img src={iconName} alt='house-appraisal-spot' />
  )
}

CompFinderIcon.prototype = {
  templateId: PropTypes.any,
  searchMethod: PropTypes.string
}

export default CompFinderIcon