import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

class SlaLeftContainer extends Component {

  constructor(props, context) {
    super(props, context);
  }

  renderName(item){
    return(
      <div className="dimension-name">
        {item['name']}
      </div>
    )
  }

  renderPassCount(item){
    return(
      <div className="counts">
        <i className="icons-check-circle text-success" /> {item['pass_count']}
      </div>
    )
  }

  renderFailCount(item){
    return(
      <div className="counts">
        <i className="icons-times-circle text-danger" /> {item['fail_count']}
      </div>
    )
  }

  renderItem = (item, index) => {
    const { onCallbackSelectedItem, selectedItem } = this.props;
    const selectionClassNames = classNames('dimension-link', {
      'active': _.isEqual(item, selectedItem)
    });

    return(
      <div className={selectionClassNames} eventKey={index} key={item['dimension']}
        onClick={() => onCallbackSelectedItem(item)}>
        <div className="dimension-link-container">
          {this.renderName(item)}

          <div className="dimension-counts">
            {this.renderPassCount(item)}
            {this.renderFailCount(item)}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {allItems} = this.props;
    if (_.isEmpty(allItems)){
      return null;
    }

    return (
      <div className="sla-left-container">
        {_.map(allItems, this.renderItem)}
      </div>
    )
  }
}

SlaLeftContainer.propTypes = {
  allItems: PropTypes.array,
  onCallbackSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object
};

function mapDispatchToProps() {
  return {}
}

function mapStateToProps(state) {
  return {
    commonFilters: _.get(state, 'commonFilters', {}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlaLeftContainer);
