import {
  UPDATE_SEARCH_FIELD,
  UPDATE_SEARCH_FILTERS,
  UPDATE_SELECTED_SHAPE_IDS,
  UPDATE_SHAPE_DATASET_ENTRY,
  UPDATE_SUBJECT_ROW_IDS,
  UPDATE_SEARCH_PAGE,
  SELECTED_REPORTS,
  REPORT_PAGE_DATA,
  REPORT_PAGE_DISTINCT_DATA,
  SUBJECT_ID,
  SUBJECT_DATA,
  UPDATE_TABLE_SORT_COLUMNS,
  UPDATE_SEARCH_PROPERTY,
  UPDATE_TEMPLATE_ID,
  UPDATE_EXPANDED_FILTER_MODE,
  UPDATE_ADVANCE_SEARCH_OPTIONS,
  UPDATE_SEARCH_RESULT_COUNT,
  UPDATE_IS_COMPARISON_LIST_OPEN,
  UPDATE_SECONDARY_TABLE_SORT_COLUMNS,
  UPDATE_POLYGON_FEATURE,
  UPDATE_IS_DRAWING_ENABLED,
  REMOVE_POLYGON_FILTER,
  SUBJECT_DISTINCT_DATA
} from "actions/advanceSearchActions";
import { getAdvanceSearchTemplateId } from "common/config/customerConfiguration";
import {
  getCurrentTemplateEntryById,
  getDefaultShapeDatasetEntry
} from "common/config/templateConfiguration";
import { ADVANCE_SEARCH_PAGE } from 'appConstants';

export const getInitialState = () => {
  const templateId = getAdvanceSearchTemplateId();
  return {
    searchField: {},
    filters: [],
    templateId: templateId,
    templateEntry: getCurrentTemplateEntryById(templateId),
    shapeDatasetEntry: getDefaultShapeDatasetEntry(templateId),
    selectedShapeIds: [],
    selectedShapesExtent: null,
    subjectRowIds: [],
    searchPage: ADVANCE_SEARCH_PAGE.SEARCH_PAGE,
    selectedReportRowIds: [],
    reportPageData: [],
    subjectRowId: '',
    subjectData: {},
    sortColumns: [],
    secondarySortColumns: [],
    searchProperty: {},
    isExpandedFilterMode: false,
    searchResultCount: 0,
    isComparisonListOpen: false,
    polygonsGeojson: {},
    isDrawingEnabled: false
  };
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case UPDATE_SEARCH_FIELD:
      return {
        ...state,
        searchField: _.get(action, 'searchField', {}),
        filters: [],
        reportPageData: [],
        selectedReportRowIds: [],
        subjectData: {},
        selectedShapeIds: []
      }
    case UPDATE_SEARCH_FILTERS:
      return {
        ...state,
        filters: _.get(action, 'filters', []),
        reportPageData: [],
        selectedReportRowIds: [],
      }
    case UPDATE_SELECTED_SHAPE_IDS:
      return {
        ...state,
        selectedShapeIds: _.get(action, 'shapeIds', []),
        selectedShapesExtent: _.get(action, 'shapeExtent', []),
        reportPageData: [],
        selectedReportRowIds: [],
      }
    case UPDATE_SHAPE_DATASET_ENTRY:
      return {
        ...state,
        shapeDatasetEntry: _.get(action, 'shapeDatasetEntry', {}),
        selectedShapeIds: [],
        selectedShapesExtent: null,
        reportPageData: [],
        selectedReportRowIds: [],
      }
    case UPDATE_SUBJECT_ROW_IDS:
      return {
        ...state,
        subjectRowIds: _.get(action, 'rowIds', [])
      }
    case UPDATE_SEARCH_PAGE:
      return {
        ...state,
        searchPage: _.get(action, 'searchPage', '')
      }
    case SELECTED_REPORTS:
      return {
        ...state,
        selectedReportRowIds: _.get(action, 'selectedReportRowIds', [])
      }
    case REPORT_PAGE_DATA:
      return {
        ...state,
        reportPageData: _.get(action, 'reportPageData', [])
      }
    case REPORT_PAGE_DISTINCT_DATA:
      return {
        ...state,
        reportPageDistinctData: _.get(action, 'reportPageDistinctData', [])
      }
    case SUBJECT_DISTINCT_DATA:
      return {
        ...state,
        subjectDistinctData: _.get(action, 'subjectDistinctData', [])
      }
    case SUBJECT_ID:
      return {
        ...state,
        subjectRowId: _.get(action, 'subjectRowId', '')
      }
    case SUBJECT_DATA:
      return {
        ...state,
        subjectData: _.get(action, 'subjectData', {})
      }
    case UPDATE_TABLE_SORT_COLUMNS:
      return {
        ...state,
        sortColumns: _.get(action, 'sortColumns', [])
      }
    case UPDATE_SECONDARY_TABLE_SORT_COLUMNS:
      return {
        ...state,
        secondarySortColumns: _.get(action, 'secondarySortColumns', [])
      }
    case UPDATE_SEARCH_PROPERTY:
      return {
        ...state,
        searchProperty: _.get(action, 'searchProperty', {}),
        filters: [],
        selectedShapeIds: [],
        shapeDatasetEntry:{},
      }
    case UPDATE_TEMPLATE_ID:
      const templateId = _.get(action, 'templateId')
      return {
        ...state,
        templateId: templateId,
        filters: [],
        selectedShapeIds: [],
        shapeDatasetEntry:{},
      }
    case UPDATE_EXPANDED_FILTER_MODE:
      return {
        ...state,
        isExpandedFilterMode: _.get(action, 'isExpandedFilterMode')
      }
    case UPDATE_ADVANCE_SEARCH_OPTIONS:
        return {
          ...state,
          ...action.advanceSearchOptions
        }
    case UPDATE_SEARCH_RESULT_COUNT:
      return {
        ...state,
        searchResultCount: action.searchResultCount
      }
    case UPDATE_IS_COMPARISON_LIST_OPEN:
      return {
        ...state,
        isComparisonListOpen: action.isComparisonListOpen
      }
    case UPDATE_POLYGON_FEATURE:
      return {
        ...state,
        polygonsGeojson: action.featuresGeojson
      }
    case UPDATE_IS_DRAWING_ENABLED:
      return {
        ...state,
        isDrawingEnabled: action.isDrawingEnabled
      }
    case REMOVE_POLYGON_FILTER:
      return {
        ...state,
        polygonsGeojson: {
          type: 'FeatureCollection',
          features: []
        },
        isDrawingEnabled: false
      }
    default:
      return state;
  }
}

export const getPolygonGeoJson = (options) => {
  return formatPolygonGeojson(options?.polygonsGeojson || {});
}

const formatPolygonGeojson = (polygonsGeojson) => {
  const formatedPolygonGeojson = isJsonString(polygonsGeojson) ? JSON.parse(polygonsGeojson)
        : polygonsGeojson;
  return formatedPolygonGeojson;
}

const isJsonString = (str) => {
  try {
    JSON.parse(str)
  } catch {
    return false;
  }
  return true;
}
