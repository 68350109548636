import React from 'react'
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'

import TimeOfDayToggleButton from './TimeOfDayToggleButton';
import TimeOfDayFilter from './TimeOfDayFilter';

const Index = () => {
  const isTimeOfDayOn = useSelector(state => _.get(state, 'drilldown.isTimeOfDayOn', false));

  return (
    <div className="time-filter">
      <TimeOfDayToggleButton />
      {isTimeOfDayOn && <TimeOfDayFilter />}
    </div>
  );
}

Index.propTypes = {
  isTimeOfDayOn: PropTypes.bool
}

export default Index;
