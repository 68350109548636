import React from "react";
import PropTypes from 'prop-types';
import { ForgeButton } from "@tylertech/forge-react";

const CustomButton = ({ onClick, label, type, className, style, disabled }) => {
  return (
    <ForgeButton type={type} className={className} style={style}>
      <button type="button" onClick={onClick} disabled={disabled}>{label}</button>
    </ForgeButton>
  );
}

CustomButton.defaultProps = {
  style: {},
  className: '',
  label: '',
  type: 'outlined',
  disabled: false,
  onClick: _.noop
};

CustomButton.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default CustomButton;
