import React from 'react';
import { ForgeListItem, ForgeIcon} from '@tylertech/forge-react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { getAdvanceSearchName } from 'common/config/customerConfiguration';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  MANAGE_ADVANCED_SEARCH_PAGE_CONFIG
} from 'appConstants';
import {
  updateSearchProperty,
  updateSearchField,
  updateSearchPage
} from 'actions/advanceSearchActions';

const AdvanceSearchItem = ({onListItemClick}) => {
  const dispatch = useDispatch();

  const onKeyDownMenuButton = (e) => {
    if (isEnterButtonPressed(e)) {
      onClickListItem();
    }
  }

  const onClickListItem = () => {
    const { page, to } = MANAGE_ADVANCED_SEARCH_PAGE_CONFIG;
    dispatch(updateSearchProperty({}));
    dispatch(updateSearchField({}));
    dispatch(updateSearchPage(''));
    onListItemClick(page,to);
  }

  return (
    <ForgeListItem key={'advance-search' + 1}
      onkeydown={onKeyDownMenuButton}
      onClick={onClickListItem}>
      <ForgeIcon slot="leading" name="home" />
      {getAdvanceSearchName()}
    </ForgeListItem>
  )
}

AdvanceSearchItem.propTypes = {
  onListItemClick: PropTypes.func
};

export default AdvanceSearchItem;
