import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';

import { getBookmark, updateBookmark } from 'common/api/bookmarksApi';
import SaveAndSaveAsButton from 'pages/dashboard/components/SaveAndSaveAsButton';
import { getBookmarkDefaultParams } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import { createBookmark } from 'common/api/bookmarksApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { getForecastOptions, isForecastViewChanged } from 'pages/Forecasting/ForecastHelper';
class SaveContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      currentBookmark: {},
      isLoading: false,
      hideSaveAsDropDown: true
    };
  }

  componentDidMount() {
    this.fetchBookmark();
  }

  componentDidUpdate(prevProps){
    const { bookmarkId, shouldUpdateBookmark} = this.props;
    const { isLoading, currentBookmark } = this.state;
    if(!_.isEqual(bookmarkId, _.get(prevProps, 'bookmarkId')) &&
        _.get(currentBookmark,'id') !== bookmarkId){
      this.fetchBookmark();
    }
    if(shouldUpdateBookmark && !isLoading) {
      this.handleEditBookmark();
    }
  }

  fetchBookmark = () => {
    const { bookmarkId, onBookmarkChange, onDataLoading } = this.props;
    if(!_.isEmpty(bookmarkId)){
      onDataLoading(true);
      getBookmark(bookmarkId)
        .then((response) => response.json())
        .then((response) => {
          this.setState({currentBookmark: response});
          onBookmarkChange(response);
          onDataLoading(false);
        });
    } else {
      this.setState({currentBookmark: {}});
      onBookmarkChange({});
    }
  }

  navigateToMyView = () => {
    const { myViewCollectionId } = this.state;
    const { dispatchSetCurrentCollectionId } = this.props;
    dispatchSetCurrentCollectionId(myViewCollectionId)
    this.props.history.push({ pathname: "/overview" });
  }

  handleSaveAsClick = (name, description) => {
    const { onBookmarkChange } = this.props;
    const bookmarkParams = {
      ...getBookmarkDefaultParams(this.props, {name, description}),
      is_forecast: true,
      forecastOptions: getForecastOptions(this.props)
    }
    const params = {
      shareEmails: [],
      bookmark: bookmarkParams,
      shareFieldInputs: {},
      include_current_user: true,
      ignore_view_entry: true,
      includeMyViewCollectionId: true
    };

    this.setState({ isLoading: true, hideSaveAsDropDown: false });
    createBookmark(params).
      then(response => response.json()).
      then((response) => {
        const currentBookmark = _.get(_.values(response), '[0]', {})
        const myViewCollectionId = _.get(_.values(response), '[1]', 0);
        this.setState({
          isLoading: false,
          hideSaveAsDropDown: true,
          currentBookmark,
          myViewCollectionId
        });
        onBookmarkChange(currentBookmark);
        if(response.error) {
          toast.error("error on saving forecast view");
        } else {
          toast.success(this.CustomToastWithLink());
        }
      }).
      catch((err) => {
        this.setState({ isLoading: false});
        toast.error(`Error while creating view. ${err}`);
        console.error(err);   // eslint-disable-line no-console
      }).
      then(() => {
        // trackEvent('saved_view_created', {});
      });
  }

  handleEditBookmark = () => {
    const { bookmarkId, onBookmarkChange, title, description } = this.props;
    const { currentBookmark } = this.state;
    const { bookmarkOptions } = currentBookmark;
    if(!_.isEmpty(bookmarkId)){
      this.setState({ isLoading: true });
      const params = {
        ...currentBookmark,
        name: _.isEmpty(title) ? _.get(currentBookmark, 'name') : title,
        bookmarkOptions: {
          ...bookmarkOptions,
          description
        },
        is_forecast: true,
        forecastOptions: getForecastOptions(this.props)
      }
      updateBookmark(bookmarkId, params)
       .then((response) => response.json())
       .then((response) => {
          onBookmarkChange(response);
          this.setState({ isLoading: false, currentBookmark: response});
          toast.success(`Success! Your forecast analysis has been saved.`);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toast.error(`Error while editing forecast view. ${err}`);
          console.error(err); // eslint-disable-line no-console
        });
    }else{
      this.handleSaveAsClick(title, description);
    }
  }

  CustomToastWithLink = () => (
    <div className="saved-view-toast">Success! Your forecast has been saved. Go to&nbsp;
      <a className="toast-nav-link" onClick={() => this.navigateToMyView()} >My Views</a>.
    </div>
  );
  renderSpinner() {
    return (
      <LoadingSpinner isLoading={this.state.isLoading} />
    )
  }

  render() {
    const { isLoading, currentBookmark, hideSaveAsDropDown } = this.state;
    const { title, description, defaultForecastOptions } = this.props;
    const  isForecastChanged = isForecastViewChanged(this.props, currentBookmark, defaultForecastOptions)
    return(
      <div >
        {this.renderSpinner()}
        <SaveAndSaveAsButton {...this.props}
          currentBookmark={currentBookmark}
          bookmarkId={`${_.get(currentBookmark, 'id')}`}
          hideSaveAsDropDown={hideSaveAsDropDown}
          hideSaveAsButton={true}
          name={title}
          description={description}
          variant={isForecastChanged ? 'primary' : 'outline-primary'}
          isViewChanged={isForecastViewChanged}
          onEditBookmarkSave={this.handleEditBookmark}
          onBookmarkSave={this.handleSaveAsClick}
          isLoading={isLoading}/>
      </div>
    )
  }
}

SaveContainer.propTypes = {
  bookmarkId: PropTypes.string,
  onBookmarkChange: PropTypes.func,
  dispatchSetCurrentCollectionId: PropTypes.func,
  onDataLoading: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  shouldUpdateBookmark: PropTypes.bool,
  defaultForecastOptions: PropTypes.object,
  history: PropTypes.array
}

export default SaveContainer;
