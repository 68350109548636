import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';
import { ForgeCard } from '@tylertech/forge-react';

import {
  updateSearchPage,
  updateSearchProperty,
  updateSearchField,
  updateTemplateId,
  updateSearchResultCount
} from 'actions/advanceSearchActions';

import { ADVANCE_SEARCH_PAGE, SEARCH_METHOD } from 'appConstants';
import AdvanceSearchHeader from './AdvanceSearchHeader';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import { getAdvanceSearchTemplateId, getTemplateEntries } from 'common/config/customerConfiguration';
import { removePolygonFilter } from 'actions/mapOptionsActions';

const AdvanceSearchMethods = () => {
  const dispatch = useDispatch();
  const searchTemplateId = getAdvanceSearchTemplateId();

  const onClickSearch = (method) => {
    const isSubjectPropertySearch = method === SEARCH_METHOD.SUBJECT_PROPERTY;
    const searchProperty = {
      searchMethod: method,
      defaultSearchMethod: method
    }

    dispatch(removePolygonFilter());
    dispatch(updateSearchResultCount(0));
    dispatch(updateSearchPage(ADVANCE_SEARCH_PAGE.SEARCH_PAGE));
    dispatch(updateSearchProperty(searchProperty));
    dispatch(updateCurrentBookmark(""));
    dispatch(updateSearchField({}));
    if(isSubjectPropertySearch){
      dispatch(updateTemplateId(searchTemplateId));
    } else {
      const generalSearchTemplateEntry = _.find(getTemplateEntries(), (entry) => {
        return _.toString(entry['enable_advance_search']) == 'true';
      });
      dispatch(updateTemplateId(_.get(generalSearchTemplateEntry, 'template_id', '')));
    }
  }

  const renderBodyContent = () => {
    return (
      <div className="search-method d-flex gap-16">
        <ForgeCard outlined="true" onClick={() => onClickSearch(SEARCH_METHOD.SUBJECT_PROPERTY)}>
          <div className="d-flex align-items-center gap-20">
            <img src="./images/house-appraisal-spot.svg" alt='house-appraisal-spot' />
            <div>
              <div className="forge-typography--subtitle1-secondary">Subject Property Search</div>
              <div className="forge-typography--subtitle2-secondary">
                Start with a subject property and search for other comparable properties.
              </div>
            </div>
          </div>
        </ForgeCard>

        <ForgeCard outlined="true" onClick={() => onClickSearch(SEARCH_METHOD.CUSTOM)}>
          <div className="d-flex align-items-center gap-20">
            <img src="./images/no-search-results-spot.svg" alt='house-appraisal-spot' />
            <div>
              <div className="forge-typography--subtitle1-secondary">General Search</div>
              <div className="forge-typography--subtitle2-secondary">
                Search across various datasets to find comparable properties based on your chosen criteria.
              </div>
            </div>
          </div>
        </ForgeCard>
      </div>
    )
  }


  return (
    <>
      <AdvanceSearchHeader/>
      <div className='search-method-page-container'>
        <div className='search-method-wrapper
          d-flex align-items-center justify-content-center flex-column gap-30'>
          <img src='https://cdn.forge.tylertech.com/v1/images/spot-hero/real-estate-spot-hero.svg'
            alt='property'
            className='' />
          {renderBodyContent()}
        </div>
      </div>
    </>
  )
}

AdvanceSearchMethods.propTypes = {
  searchProperty: PropTypes.object
}

export default AdvanceSearchMethods
