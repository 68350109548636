// Vendor imports.
import _ from 'lodash';

// Project Imports
import { getFormattedValue } from 'helpers/chartDataHelper';
import {
  isStackTypeChart,
  getApiDataEntries,
  isBenchMarkEmpty,
  formatDimensionText,
  isCompareView,
  getWrapTickText
} from '../vizOptionsHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { formatGroupByNoneBarData } from './groupByNoneDataFormatter';
import { formatCompareViewData, getEmptyBucket } from './compareViewDataFormatter';
import { formatCombineViewData } from './combineViewDataFormatter';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsNumericTime } from 'helpers/visualizationHelper';


export const formatBarChartData = (vizOptions, apiData, isUpdateApiCall = false) => {
  const { groupByEntry, onApiDataLoad, groupType } = vizOptions;
  const apiDataEntries = getApiDataEntries(apiData, vizOptions);
  const isGroupByNone = _.get(groupByEntry, 'name') === 'None';
  const apiDataCount = _.size(_.uniqBy(apiDataEntries, 'dimension'));
  const apiDataRowCount = _.get(apiData, 'row_count', apiDataCount || 0);

  if (apiData) {
    if (_.size(apiDataEntries) === 1 && (isGroupByNone || _.isEmpty(groupByEntry))) {
      const entryValues = _.values(apiDataEntries);
      const entry = _.first(entryValues);
      if(isUpdateApiCall) {
        onApiDataLoad(apiDataRowCount, entry);
      }
    } else {
      const remainingGroupEntries = _.get(apiData, 'remaining_group_entries', 0);
      if(isUpdateApiCall) {
        onApiDataLoad(apiDataRowCount, null, remainingGroupEntries);
      }
    }
  }

  if (_.isEmpty(groupByEntry)) {
    return isStackTypeChart(vizOptions) ?
      formatGroupByNoneBarData(apiDataEntries, vizOptions):
      formatNormalBarData(apiDataEntries, vizOptions);
  } else if (isGroupByNone) {
    return formatGroupByNoneBarData(apiDataEntries, vizOptions);
  } else if (isCompareView(groupType)) {
    return formatCompareViewData(apiDataEntries, vizOptions);
  } else {
    return formatCombineViewData(apiDataEntries, vizOptions);
  }
}

/*-------------------------------------------------------------------------------
 Input:
---------------------------------------------------------------------------------
 {
  "entries":[
      {
        "dimension":"Education",
        "count":"8337",
        "min_count":"Operating",
        "max_count":"Personnel",
        "secondary_count":"8337"
      },
      {
        "dimension":"General Government",
        "count":"8206",
        "min_count":"Operating",
        "max_count":"Personnel",
        "secondary_count":"8206"
      },
      ...
    ]
 }
---------------------------------------------------------------------------------
Output
---------------------------------------------------------------------------------
  [
    {
      label: 10,
      secondaryLabel: 20,
      value: 'Education',
      text: 'Education - 10 funds' ,
      secondaryText: '20 funds',
    },
    ....
  ]
*/

function formatNormalBarData(apiDataEntries, vizOptions) {
  const {
    benchMarkEntries,
    viewEntry,
    secondaryMetricEntry,
    templateName,
    templateId,
    viewMode,
    onFormattedDataLoad
  } = vizOptions;

  const isSecondsFormatSecondary  = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat  = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry)
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry)
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const dimensionEntries = _.cloneDeep(apiDataEntries);

  const formatData = _.map(dimensionEntries, (entry) => {
    const { period } = entry;
    const dimensionValue = formatDimensionText(_.get(entry, 'dimension'), false, templateId);
    const count =  _.get(entry, 'count', 0);
    const secondaryCount = _.get(entry, 'secondary_count', 0);

    const countLabel = isSecondsFormat ? getSecondsAsNumericTime(count, dataUnit) : count;
    const secondaryCountLabel  =  isSecondsFormatSecondary ?
      getSecondsAsNumericTime(secondaryCount, secondaryDataUnit) : secondaryCount;

    return {
      label: countLabel,
      color: 0,
      value: dimensionValue,
      secondaryLabel: secondaryCountLabel,
      actualLabel: Number(count) || 0,
      actualSecondaryLabel: Number(secondaryCount) || 0,
      secondaryText: getFormattedValue(secondaryCount, secondaryMetricEntry, false, isSmallView),
      secondaryHoverText: getFormattedValue(secondaryCount, secondaryMetricEntry),
      customData: [dimensionValue],
      text: getFormattedValue(count, viewEntry, true, isSmallView),
      hoverText: getFormattedValue(count, viewEntry),
      ticktext: getWrapTickText(_.get(entry, 'dimension'), templateName),
      period: period
    };
  });

  onFormattedDataLoad(_.cloneDeep(formatData));

  if (!isBenchMarkEmpty(benchMarkEntries)) {
    const bucketOption = {
      dimension: '',
      isBenchMark: true,
      isGroup: false,
      options: vizOptions
    }
    const emptyBucket = getEmptyBucket(bucketOption);
    formatData.unshift(emptyBucket);
    formatData.push(emptyBucket);
  }
  return [formatData];
}
