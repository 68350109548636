import React from 'react';
import PropTypes from 'prop-types';
import {
  ForgeButtonToggleGroup,
  ForgeButtonToggle,
  ForgeIcon
} from '@tylertech/forge-react';
import { useSelector } from 'react-redux';
import { isMapAvailable } from 'modules/AdvanceSearch/advanceSearchHelper';
const SearchTabBar = ({
  selectedTab,
  updateSelectedTab,
  templateId,
  searchFilters,
  selectedShapeIds }) => {
  const isComparisonListOpen = useSelector(state => state.advanceSearch.isComparisonListOpen);
  const combinedClassName = `search-tab-bar ${isComparisonListOpen ? 'added' : 'closed'}`;
  const searchResultCount = useSelector(state => state.advanceSearch.searchResultCount);
  const showMap = isMapAvailable(templateId);

  return (
    <div className={combinedClassName}>
      <div className="forge-typography--body1">
        { (!_.isEmpty(searchFilters) || !_.isEmpty(selectedShapeIds)) &&
          <label>
            Showing <b>{searchResultCount}</b> results
          </label>
        }
      </div>
      {searchResultCount > 0 && (
        <div className="search-tab-bar-action">
          <ForgeButtonToggleGroup on-forge-button-toggle-select={updateSelectedTab}>
            <ForgeButtonToggle button-aria-label="table" value="table" selected={selectedTab == 'table'}>
              <ForgeIcon name="format_list_bulleted" />
            </ForgeButtonToggle>
            { showMap &&
              <ForgeButtonToggle button-aria-label="map" value="map" selected={selectedTab == 'map'}>
                <ForgeIcon name="location_on" />
              </ForgeButtonToggle>
            }
          </ForgeButtonToggleGroup>
        </div>
      )}
    </div>
  );
}

SearchTabBar.propTypes = {
  selectedTab: PropTypes.string,
  updateSelectedTab: PropTypes.func,
  onReportPageClick: PropTypes.func,
  selectedReportRowIds: PropTypes.array,
  searchFilters: PropTypes.array,
  selectedShapeIds: PropTypes.array,
  toggleCompareList: PropTypes.bool,
  templateId: PropTypes.string
};

export default SearchTabBar;
