import moment from 'moment';
import { getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
export const forecastExportDownload = (options) => {
  const { currentDrilldownTemplateId } = options;

  const templateName = _.get(getCurrentTemplateEntryById(currentDrilldownTemplateId), 'name');
  const currentDate = moment().format('YYYYMMDD');
  const fileName = `${templateName}_summary_${currentDate}.csv`;
  const csvContent = getCSVData(options);
  const blob = new Blob(csvContent, { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const getCSVData = (csvOptions) => {
  const {
    tableData,
    tableHeaders
  } = csvOptions;
  let csvContent = [];
  const header = []

  _.each(tableHeaders, (data) => {
    header.push(_.get(data, 'name'));
  });

  header.push('\n');
  csvContent.push(header);

  _.each(tableData, (entry) => {
    let row = [];

    _.each(tableHeaders, (data) => {
      row.push(`"${_.get(entry, data.columnField, '-')}"`);
    });
    row.push('\n');
    csvContent.push(row);
  });
  return csvContent;
}