import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DropdownGroup from 'common/components/DropdownGroup/DropdownGroup';
import DropDownFooter from 'pages/drilldown/components/QuickFilterBar/DropDownFooter';
import { ErrorBoundaryWrapper } from 'pages/errorboundary/ErrorBoundaryWrapper';

import StartAndEndForgeDatePicker from './StartAndEndForgeDatePicker';
import YearlyDateRangeFilter from './YearlyDateRangeFilter';
import BienniumFiscalYear from './BienniumFiscalYear';
import CalendarYear from './CalendarYear';

import { ForgeRadio } from '@tylertech/forge-react';

import {
  getConfiguredDateType,
  configuredDefaultDateType,
  getDateTypeOptions
} from 'common/config/customerConfiguration';
import {
  getDefaultDateRange,
  getRelativeFilterDateRange,
  getCustomPeriodDateRange,
  getCurrentDateRangeByDateType,
  getValidStartAndEndDate,
  isValidInputForRelativeCustom,
  getCalendarYearDateRange
} from 'helpers/dateHelper';
import {
  BIENNIUM_FISCAL_YEAR,
  RELATIVE_FILTER_OPTIONS,
  RELATIVE_FILTER_PERIOD_TYPE_ENTRIES,
  DATE_OPTIONS_TYPE,
  YEARLY_CUSTOM_OPTION,
  COMPARISON_PERIOD_TYPES,
  RELATIVE_DATE_TYPE,
  CALENDAR_DATE_TYPE
} from 'appConstants';
import { getAllFiscalYears } from 'helpers/fiscalPeriodUtils';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import ComparisonPeriodSelector from '../ComparisonDateSelector/ComparisonPeriodSelector';
import ForgeInputTextField from 'common/components/ForgeInputFields/ForgeInputTextField';
import { applyFilters } from '../Filters/FilterDomHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import LoadingSpinner from '../LoadingSpinner';

const dateOptionType = getConfiguredDateType();
const isYearlyDateEnabled = (dateOptionType == DATE_OPTIONS_TYPE.YEARLY);
const dateTypeOptions = _.map(getDateTypeOptions(), 'value');

class DateRangeFilterForCollection extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      dateRange,
      relativeDateFilterEntry,
      dateType,
      comparisonPeriod,
      previousDateRangeConfig
    } = this.props;
    const {
      currentRelativeFilterOption,
      currentRelativePeriodType,
      isRelativeFilter,
      customPeriodCount,
    } = relativeDateFilterEntry;

    const fiscalYearDateRange = this.getDefaultDateRangeForDateType(DATE_OPTIONS_TYPE.YEARLY);
    const customDateRange = this.getDefaultDateRangeForDateType(DATE_OPTIONS_TYPE.CUSTOM_RANGE);
    const bienniumYearDateRange = this.getDefaultDateRangeForDateType(BIENNIUM_FISCAL_YEAR);
    const calendarDateRange = this.getDefaultDateRangeForDateType(CALENDAR_DATE_TYPE);

    const previousCustomDateRange = _.get(previousDateRangeConfig, DATE_OPTIONS_TYPE.CUSTOM_RANGE, {});
    const previousYearlyRange = _.get(previousDateRangeConfig, DATE_OPTIONS_TYPE.YEARLY, {});
    const previousBienniumYearDateRange = _.get(previousDateRangeConfig, BIENNIUM_FISCAL_YEAR, {});
    const previousCalendarDateRange = _.get(previousDateRangeConfig, CALENDAR_DATE_TYPE, {});

    const defaultDateTypesRange = {
      [BIENNIUM_FISCAL_YEAR]: { ...bienniumYearDateRange, ...previousBienniumYearDateRange },
      [DATE_OPTIONS_TYPE.CUSTOM_RANGE]: { ...customDateRange, ...previousCustomDateRange },
      [DATE_OPTIONS_TYPE.YEARLY]: {...fiscalYearDateRange, ...previousYearlyRange },
      [CALENDAR_DATE_TYPE]: {...calendarDateRange, ...previousCalendarDateRange}
    };

    this.state = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      currentRelativeFilterOption,
      currentRelativePeriodType,
      isRelativeFilter,
      customPeriodCount,
      isEndDateChanged: false,
      isStartDateChanged: false,
      ...defaultDateTypesRange,
      defaultDateType: dateType || dateOptionType,
      [dateType || dateOptionType]: dateRange,
      comparisonPeriod: comparisonPeriod || COMPARISON_PERIOD_TYPES[0].value,
      isFocusApplyButton: false
    };
  }

  componentDidMount() {
    this.updateRelativeDateState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { dateType,
      dateRange,
      hideFooter,
      relativeDateFilterEntry } = this.props;

    if (!_.isEqual(prevProps.dateRange, dateRange) && !dateType) {
      this.setState({ defaultDateType: configuredDefaultDateType });
    }

    if (!_.isEqual(prevProps.dateType, dateType) && dateType) {
      this.setState({ defaultDateType: dateType });
    }

    if (hideFooter) {
      if (!_.isEqual(prevState, this.state)) {
        this.getDateRangeFromState();
      }
    }

    if (!_.isEqual(prevProps.relativeDateFilterEntry, relativeDateFilterEntry)) {
      this.updateRelativeDateState();
    }
  }

  getDefaultDateRangeForDateType = (dateType) => {
    const { relativeDateFilterEntry } = this.props;
    const {
      currentRelativeFilterOption,
      currentRelativePeriodType,
      customPeriodCount,
    } = relativeDateFilterEntry;

    return getCurrentDateRangeByDateType({
      dateType,
      relativeDateFilterEntry,
      currentRelativeFilterOption,
      currentRelativePeriodType,
      customPeriodCount
    });
  }

  updateRelativeDateState = () => {
    const { dateRange, relativeDateFilterEntry } = this.props;
    const {
      isRelativeFilter,
      currentRelativeFilterOption,
      currentRelativePeriodType,
      customPeriodCount
    } = _.cloneDeep(relativeDateFilterEntry);
    this.setState({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      isRelativeFilter,
      currentRelativeFilterOption,
      currentRelativePeriodType,
      customPeriodCount
    });
  }

  updateStartAndEndDateStatus = (isApply = false) => {
    setTimeout(() => {
      this.setState({ isEndDateChanged: false, isStartDateChanged: false });
      if (isApply) {
        applyFilters();
      }
    }, 500);
  }

  isDisabled = () => {
    const { startDate, endDate, currentRelativeFilterOption,
      customPeriodCount, defaultDateType } = this.state;
    const { minDate } = this.props;
    const isActiveApply = defaultDateType === "relative"
      && currentRelativeFilterOption === 'custom'
      && _.isEmpty(_.toString(customPeriodCount));
    return (minDate > new Date(startDate) && minDate > new Date(endDate)) || isActiveApply;
  }

  isDisabledDateType = (dateTypes, defaultDateType) => {
    return !_.includes(dateTypes, defaultDateType)
  }

  handleSelectStartDate = (date, isApply = false) => {
    const { endDate } = this.state;
    const newStartDate = moment(date).format("YYYY-MM-DD");
    const { startDate, endDate: newEndDate, isEndDateChanged } = getValidStartAndEndDate({
      startDate: newStartDate,
      endDate,
      isStartDateChanged: true
    });

    this.setState({
      startDate, endDate: newEndDate, isEndDateChanged,
      [DATE_OPTIONS_TYPE.CUSTOM_RANGE]: { startDate, endDate: newEndDate }
    }, () => {
      this.updateStartAndEndDateStatus(isApply);
    });
  }

  handleSelectEndDate = (date, isApply = false) => {
    const { startDate } = this.state;
    const newEndDate = moment(date).format("YYYY-MM-DD");
    const { startDate: newStartDate, endDate, isStartDateChanged } = getValidStartAndEndDate({
      startDate, endDate: newEndDate
    });

    this.setState({
      startDate: newStartDate, endDate, isStartDateChanged,
      [DATE_OPTIONS_TYPE.CUSTOM_RANGE]: { startDate: newStartDate, endDate: newEndDate }
    }, () => {
      this.updateStartAndEndDateStatus(isApply);
    });
  }

  handleRemove = () => {
    const { relativeDateFilterEntry, onClose } = this.props;
    const defaultDateRange = getDefaultDateRange(relativeDateFilterEntry);
    this.setState({
      startDate: defaultDateRange.startDate,
      endDate: defaultDateRange.endDate
    });
    onClose('cancel');
  }

  handleSelectRelativeFilter = (currentRelativeFilterOptionEntry, isApply = false) => {
    const { customPeriodCount, currentRelativePeriodType } = this.state;
    const currentRelativeFilterOption = _.get(currentRelativeFilterOptionEntry, 'type');
    const { startDate, endDate } = getRelativeFilterDateRange({
      currentRelativeFilterOption, customPeriodCount, currentRelativePeriodType
    });

    this.setState({ currentRelativeFilterOption, startDate, endDate, isFocusApplyButton: isApply });
  }

  handleRadioKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      applyFilters();
    }
  }

  handleSelectRelativePeriod = (currentRelativePeriodTypeEntry) => {
    const { customPeriodCount } = this.state;
    const { startDate, endDate } = getCustomPeriodDateRange(
      customPeriodCount, _.get(currentRelativePeriodTypeEntry, 'type'));
    this.setState({
      currentRelativePeriodType: _.get(currentRelativePeriodTypeEntry, 'type'),
      startDate,
      endDate
    });
  }

  onBienniumFiscalYearSelect = (bienniumFiscalYearOption) => {
    const { startDate, endDate } = this.state;
    const { range } = bienniumFiscalYearOption;
    const newStartDate = _.get(range, 'startDate', startDate);
    const newEndDate = _.get(range, 'endDate', endDate);
    this.setState({
      startDate: newStartDate,
      endDate: newEndDate,
      [BIENNIUM_FISCAL_YEAR]: { startDate: newStartDate, endDate: newEndDate }
    });
  }

  onComparisonPeriodSelect = (comparisonPeriodOption) => {
    this.setState({ comparisonPeriod: _.get(comparisonPeriodOption, 'value') });
  }

  handleClickRadioButton = (dateType) => {
    let {
      currentRelativeFilterOption,
      currentRelativePeriodType,
      customPeriodCount
    } = _.cloneDeep(this.state);
    const { isDropDownView, relativeDateFilterEntry } = this.props;
    const isRelativeFilterEnabled = (dateType == RELATIVE_DATE_TYPE);
    let { startDate, endDate } = _.get(this.state, dateType, {});
    if (isRelativeFilterEnabled) {
      const dateRange = getCurrentDateRangeByDateType({
        dateType,
        relativeDateFilterEntry,
        currentRelativeFilterOption,
        currentRelativePeriodType,
        customPeriodCount
      });
      startDate = _.get(dateRange, 'startDate', startDate);
      endDate = _.get(dateRange, 'endDate', endDate);
    }

    this.setState({
      currentRelativeFilterOption,
      currentRelativePeriodType,
      customPeriodCount,
      startDate: startDate,
      endDate: endDate,
      isRelativeFilter: isRelativeFilterEnabled ? 'true' : 'false',
      defaultDateType: dateType
    }, () => {
      if (!isDropDownView) {
        this.getDateRangeFromState(dateType);
      }
    });
  }

  handleChangeCustomPeriodCount = (event) => {
    const inputValue = event.target.value;
    if (isValidInputForRelativeCustom(inputValue)) {
      return
    }

    const { currentRelativePeriodType } = this.state;
    const customPeriodCount = _.isEmpty(inputValue) ? '' : Number(inputValue);
    const periodCount = _.isEmpty(inputValue) ? 0 : Number(inputValue);
    const { startDate, endDate } = getCustomPeriodDateRange(periodCount, currentRelativePeriodType);
    this.setState({
      customPeriodCount,
      startDate,
      endDate
    });
  }

  getFiscalYearOptions = () => {
    const { currentDrilldownTemplateId } = this.props;
    const { endDate } = this.state;
    let selectedYear = "";
    if (!_.isEmpty(endDate)) {
      selectedYear = moment(endDate).format('YYYY');
    }

    const dropdownOptions = getAllFiscalYears(selectedYear, currentDrilldownTemplateId, true, true);

    return dropdownOptions.concat(YEARLY_CUSTOM_OPTION);
  }

  getDateRangeFromState = (isApplyButtonClick = false) => {
    const {
      defaultDateType,
      startDate,
      endDate,
      currentRelativeFilterOption,
      currentRelativePeriodType,
      isRelativeFilter,
      customPeriodCount,
      comparisonPeriod
    } = this.state;

    const {
      onDateFilterChange,
      onUpdateUserPreference,
      dateRange,
      dateType,
      relativeDateFilterEntry
    } = this.props;

    const dateFilterDateRange = { startDate, endDate };

    const newRelativeDateFilterEntry = {
      currentRelativeFilterOption,
      currentRelativePeriodType,
      isRelativeFilter,
      customPeriodCount,
      defaultDateType
    }
    const dateFilters = {
      dateRange: dateFilterDateRange,
      dateType: defaultDateType,
      relativeDateFilterEntry: newRelativeDateFilterEntry
    };

    const existingValues = { dateRange, dateType, relativeDateFilterEntry };

    const isDateRangeConfigChanged = !_.isEqual(existingValues, dateFilters);

    if(!isDateRangeConfigChanged && isApplyButtonClick) {
      return;
    }
    onDateFilterChange(defaultDateType, dateFilterDateRange, newRelativeDateFilterEntry, comparisonPeriod);
    onUpdateUserPreference(dateFilters);
  }

  onYearChange = (dateRange, selectedName, isApply = false) => {
    this.setState({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      [DATE_OPTIONS_TYPE.YEARLY]: { startDate: dateRange.startDate, endDate: dateRange.endDate },
      isFocusApplyButton: isApply
    });
  }

  onCalendarYearSelect = (selectedYear) => {
    const dateRange = getCalendarYearDateRange(selectedYear);

    this.setState({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      [CALENDAR_DATE_TYPE]: { startDate: dateRange.startDate, endDate: dateRange.endDate }
    });
  }

  renderRelativeCustomPeriods() {
    const { currentRelativePeriodType, currentRelativeFilterOption, customPeriodCount } = this.state;
    const isRelativeCustomPeriod = _.isEqual(currentRelativeFilterOption,
      _.get(_.last(RELATIVE_FILTER_OPTIONS), 'type'));
    const titleEntry = _.find(RELATIVE_FILTER_PERIOD_TYPE_ENTRIES, { type: currentRelativePeriodType });

    if (currentRelativeFilterOption !== 'custom') {
      return null
    }

    return (
      <div className="d-flex align-items-center gap-10">
        <div className="filter-items w-50">
          <ForgeInputTextField
            type="text"
            label="Last"
            placeholderLabel='Number'
            id="period_count"
            value={customPeriodCount}
            isDisabled={!isRelativeCustomPeriod}
            handleOnChange={this.handleChangeCustomPeriodCount} />
        </div>
        <DropdownGroup
          disabled={!isRelativeCustomPeriod}
          key="custom-range-dropown"
          value={_.get(titleEntry, 'name')}
          label=""
          toggleButtonName={_.get(titleEntry, 'name')}
          options={RELATIVE_FILTER_PERIOD_TYPE_ENTRIES}
          optionDisplayField="name"
          onChange={this.handleSelectRelativePeriod}
        />
      </div>
    );
  }

  renderRelativeFilter() {
    if (!_.includes(dateTypeOptions, 'relative')) {
      return null;
    }
    const { index, isDisabled } = this.props;
    const { currentRelativeFilterOption, defaultDateType } = this.state;
    const titleEntry = _.find(RELATIVE_FILTER_OPTIONS, { type: currentRelativeFilterOption });
    const id = index + "relative";

    return (
      <div className="d-flex">
        <div className="date-range-forge-radio">
          <ForgeRadio dense={true}>
            <input
              disabled={isDisabled}
              key='3'
              tabIndex={(defaultDateType == 'relative') ? 0 : -1}
              type="radio"
              aria-label="Relative Date"
              id={id}
              name="radios"
              value={id}
              checked={defaultDateType == 'relative'}
              onKeyDown={this.handleRadioKeyDown}
              onChange={() => this.handleClickRadioButton('relative')}
            />
            <label htmlFor={id}></label>
          </ForgeRadio>
        </div>
        <div className="flex-grow-1">
          <DropdownGroup
            key="relative-date-filter-option"
            value={_.get(titleEntry, 'name')}
            label="Relative Date"
            toggleButtonName={_.get(titleEntry, 'name')}
            options={RELATIVE_FILTER_OPTIONS}
            optionDisplayField="name"
            disabled={defaultDateType != 'relative'}
            onChange={this.handleSelectRelativeFilter} />

          {this.renderRelativeCustomPeriods()}
        </div>
      </div>
    );
  }

  renderCalendarYearFilter() {
    const { currentDrilldownTemplateId } = this.props;
    if (!_.includes(dateTypeOptions, 'calendar_year')) {
      return null;
    }

    const dateRange = _.get(this.state, CALENDAR_DATE_TYPE);
    const { defaultDateType } = this.state;
    const calendarYearAttributes = {
      dateRange,
      defaultDateType,
      onInputChange: this.handleClickRadioButton,
      onSelect: this.onCalendarYearSelect,
      currentDrilldownTemplateId
    };

    return (<CalendarYear {...calendarYearAttributes} />);

  }

  renderYearlyFilter() {
    if (!_.includes(dateTypeOptions, 'yearly')) {
      return null;
    }
    const { defaultDateType } = this.state;
    const dateRange = _.get(this.state, DATE_OPTIONS_TYPE.YEARLY);
    const { currentDrilldownTemplateId, isDashboardView, index } = this.props;

    if (!isYearlyDateEnabled) {
      return null;
    }

    const id = index + "yearly";

    return (
      <div className="d-flex">
        <div className="date-range-forge-radio">
          <ForgeRadio dense={true}>
            <input
              disabled={this.props.isDisabled}
              key='1'
              type="radio"
              tabIndex={(defaultDateType == 'yearly') ? 0 : -1}
              id={id}
              name="radios"
              value={id}
              aria-label="Fiscal Year"
              checked={defaultDateType == 'yearly'}
              onKeyDown={this.handleRadioKeyDown}
              onChange={() => this.handleClickRadioButton('yearly')} />
            <label className="" htmlFor={id}></label>
          </ForgeRadio>

        </div>
        <div className="flex-grow-1">
          <YearlyDateRangeFilter
            currentDrilldownTemplateId={currentDrilldownTemplateId}
            isDashboardView={isDashboardView}
            dateRange={dateRange}
            dropdownOptions={this.getFiscalYearOptions()}
            onDateFilterChange={this.onYearChange}
            isDisabled={defaultDateType != 'yearly'}
            labelName="Fiscal Year" />
        </div>
      </div>
    );
  }

  renderBienniumFiscalYear() {
    if (dateOptionType != BIENNIUM_FISCAL_YEAR || !_.includes(dateTypeOptions, 'yearly')) {
      return null;
    }
    const dateRange = _.get(this.state, BIENNIUM_FISCAL_YEAR);
    const { defaultDateType } = this.state;
    const bienniumFiscalYearAttributes = {
      dateRange,
      defaultDateType,
      onInputChange: this.handleClickRadioButton,
      onSelect: this.onBienniumFiscalYearSelect,
    };

    return (<BienniumFiscalYear {...bienniumFiscalYearAttributes} />);
  }

  renderComparisonPeriod = () => {
    const { defaultDateType, comparisonPeriod } = this.state;
    const comparisonSelectorAttributes = {
      defaultDateType,
      comparisonPeriod,
      onInputChange: this.handleClickRadioButton,
      onSelect: this.onComparisonPeriodSelect,
      comparisonLabel: this.props.comparisonLabel
    };

    return (<ComparisonPeriodSelector {...comparisonSelectorAttributes} />);
  }

  renderDateRangeInputs() {
    if (!_.includes(dateTypeOptions, 'custom_range')) {
      return null;
    }
    const { defaultDateType } = this.state;
    const { index, isDisabled } = this.props;
    const isCustomRange = _.includes(['custom_range', 'range'], defaultDateType);
    const id = index + "custom";


    return (
      <div className="d-flex">
        <div className="date-range-forge-radio">
          <ForgeRadio dense={true}>
            <input
              disabled={isDisabled}
              key='2'
              tabIndex={isCustomRange ? 0 : -1}
              type="radio"
              id={id}
              name="radios"
              value={id}
              aria-label="Custom Date Range"
              checked={isCustomRange}
              onKeyDown={this.handleRadioKeyDown}
              onChange={() => this.handleClickRadioButton('custom_range')}
            />
            <label htmlFor={id}></label>
          </ForgeRadio>
        </div>
        <div className="filter-items flex-grow-1">
          <label>Date Range</label>
          {this.renderStartAndEndDatePicker()}
        </div>
      </div>
    );
  }

  renderStartAndEndDatePicker = () => {
    let { startDate, endDate } = _.get(this.state, DATE_OPTIONS_TYPE.CUSTOM_RANGE);
    const { defaultDateType } = this.state;
    const { minDate, maxDate } = this.props;

    if (minDate > new Date(startDate)) {
      startDate = new Date(moment(startDate).format('LL'));
    }

    if (new Date(endDate) > maxDate) {
      endDate = new Date(moment(endDate).format('LL'));
    }

    return (
      <StartAndEndForgeDatePicker
        isDisabled={this.isDisabledDateType(['custom_range', 'range'], defaultDateType)}
        minDate={minDate}
        maxDate={maxDate}
        startDate={startDate}
        endDate={endDate}
        handleSelectStartDate={this.handleSelectStartDate}
        handleSelectEndDate={this.handleSelectEndDate} />
    );
  }

  render() {
    const {
      hideFooter,
      shouldHideCustomDateRange,
      hideRelativeDateRange,
      showComparisonPeriod,
      isMinDateResultsLoading
    } = this.props;
    return (
      <div className="filter-chip-show">
        <LoadingSpinner isLoading={isMinDateResultsLoading} />
        <div className="common-filters-wrapper date-range-filters" role="radiogroup">
          <div className='filters-body'>
            {this.renderCalendarYearFilter()}
            {this.renderBienniumFiscalYear()}
            {!showComparisonPeriod && this.renderYearlyFilter()}
            {showComparisonPeriod && this.renderComparisonPeriod()}
            {!shouldHideCustomDateRange && this.renderDateRangeInputs()}
            {(!shouldHideCustomDateRange && !hideRelativeDateRange) && this.renderRelativeFilter()}
          </div>
          {hideFooter ? null :
            <DropDownFooter
              message='No data available for the selected date.'
              isDisabledApplyButton={this.isDisabled()}
              onApply={() => this.getDateRangeFromState(true)}
              onRemove={this.handleRemove}
              hideResetButton={true}
              hideRemoveButton={false}
              isFocusApply={this.state.isFocusApplyButton}
            />
          }
        </div>
      </div>
    );
  }
}

DateRangeFilterForCollection.propTypes = {
  onClose: commonPropTypes.onClosePropTypes,
  onComparisonTypeChange: PropTypes.func,
  onDateFilterChange: PropTypes.func,
  onUpdateUserPreference: PropTypes.func,
  isDropDownView: PropTypes.bool,
  hideFooter: PropTypes.bool,
  filterName: PropTypes.string,
  dateRange: PropTypes.object,
  relativeDateFilterEntry: PropTypes.object,
  comparisonType: PropTypes.string,
  dateType: PropTypes.string,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  minDate: commonPropTypes.datePropTypes,
  maxDate: commonPropTypes.datePropTypes,
  index: PropTypes.number,
  isDashboardView: PropTypes.bool,
  comparisonModeOn: PropTypes.bool,
  shouldHideCustomDateRange: PropTypes.bool,
  hideRelativeDateRange: PropTypes.bool,
  showComparisonPeriod: PropTypes.bool,
  isMinDateResultsLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  comparisonPeriod: PropTypes.string,
  comparisonLabel: PropTypes.string,
  previousDateRangeConfig: PropTypes.object
}

DateRangeFilterForCollection.defaultProps = {
  onClose: _.noop,
  onComparisonTypeChange: _.noop,
  onUpdateUserPreference: _.noop,
  isDropDownView: true,
  hideFooter: false,
  isDashboardView: false,
  filterName: null,
  showFilterLabel: false,
  comparisonModeOn: false,
  shouldHideCustomDateRange: false,
  showComparisonPeriod: false,
  hideRelativeDateRange: false,
  isDisabled: false,
  previousDateRangeConfig: {}
}

function mapDispatchToProps() {
  return {
  }
}

function mapStateToProps(state) {
  return {
    isMinDateResultsLoading: _.get(state, 'dashboard.isMinDateResultsLoading', false)
  };
}

function errorContent() {
  return (
    <div className="date-range-filter d-flex align-items-center">
      something went wrong.
    </div>
  );
}

export default ErrorBoundaryWrapper(
  connect(mapStateToProps, mapDispatchToProps)(DateRangeFilterForCollection), errorContent());
