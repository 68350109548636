import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import combine from '@turf/combine';
import FreehandMode from "mapbox-gl-draw-freehand-mode";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { ForgeButton } from '@tylertech/forge-react';

import { drawControlCustomStyle } from './drawControlStyles';
import { isLocationsConfigured } from '../helpers/MapOptionsHelper';
import { updateDrilldownDimensionField } from 'actions/drilldownActions';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import {
    removePolygonFilter,
    updatePolygonGeojson,
    updatedIsDrawingEnabled
} from 'actions/advanceSearchActions';
const GEOJSON_POLYGON = 'Polygon';

class AdvanceSearchDrawControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableSaveButton: false
        };
        const { map } = this.props;

        this._draw = new MapboxDraw({  // eslint-disable-line no-undef
            displayControlsDefault: false,
            controls: {
                polygon: false,
                trash: false
            },
            modes: Object.assign(MapboxDraw.modes, {  // eslint-disable-line no-undef
                draw_polygon: FreehandMode
            }),
            styles: drawControlCustomStyle
        });

        map.addControl(this._draw, 'top-left');
        map.on('draw.create', this.onDrawCreate);
    }

    componentWillUnmount() {
        const { map } = this.props;
        if (map && this._draw) {
            map.removeControl(this._draw);
            map.off('draw.create', this.onDrawCreate);
        }
    }

    componentDidUpdate(prevProps) {
        const { isDrawingEnabled } = this.props;
        if ((prevProps !== this.props) && (isDrawingEnabled)) {
            this.enablePolygonDrawing();
        }
        if ((prevProps !== this.props) && (!isDrawingEnabled)) {
            this.disablePolygonDrawing();
        }
    }

    enablePolygonDrawing = () => {
        this._draw.changeMode('draw_polygon');
    }

    disablePolygonDrawing = () => {
        this.setState({ enableSaveButton: false });
        this._draw.deleteAll();
        this._draw.changeMode('simple_select');
    }

    onDrawCreate = () => {
        setTimeout(() => {
            this._draw.changeMode('draw_polygon');
        });
        this.setState({ enableSaveButton: true });
    }

    handleMapPolygonFilter = () => {
        this.props.dispatchUpdateDrawingStateChange(true);
    }

    handleClickCancelButton = () => {
        const {
            currentDrilldownDimensionField,
            currentDrilldownTemplateId,
            dispatchResetAllFilters,
            dispatchUpdateDrilldownDimensionField
        } = this.props;

        if (_.isEmpty(currentDrilldownDimensionField)) {
            const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
            dispatchUpdateDrilldownDimensionField(_.first(_.get(dimensionsEntries, 'field', '')));
        }

        dispatchResetAllFilters();
    }

    handleCompleteDrawing = () => {
        const featureCollection = this._draw.getAll();
        const features = _.get(featureCollection, 'features', []);

        let polygonFeatures = _.filter(features, (feature) => {
            if(_.get(feature, 'geometry.type', '') === GEOJSON_POLYGON){
                return _.size(_.get(feature, 'geometry.coordinates.0', []))>2;
            }
        });

        const multipolygonFeature = combine({
            type: 'FeatureCollection',
            features: polygonFeatures
        });

        if (!_.isEmpty(polygonFeatures)) {
            this.props.dispatchUpdateDrawingStateChange(false);
            this.props.dispatchUpdatePolygonFeature(multipolygonFeature);
        }
        this.setState({ enableSaveButton: false });
    }
    renderDrawButton() {
        const { isDrawingEnabled, polygonsGeojson, currentDrilldownTemplateId } = this.props;

        if (!isLocationsConfigured(currentDrilldownTemplateId)) {
            return null;
        }
        if (!_.isEmpty(_.get(polygonsGeojson, 'features')) || isDrawingEnabled) {
            return null;
        }

        return (
            <>
                <OverlayTrigger
                    key="create-polygon-button"
                    placement="left"
                    overlay={
                        <Tooltip id="tooltip-top">
                            <div className="text-left">Click to apply a lasso mode.</div>
                        </Tooltip>
                    }>
                    <ForgeButton type="outlined" className="map-forge-btn"
                        onClick={() => this.handleMapPolygonFilter()}>
                        <button type="button" title="Filter by selection">
                            <i className="icons-lasso"></i>
                            <span> Filter by selection </span>
                        </button>
                    </ForgeButton>
                </OverlayTrigger>
            </>
        );
    }
    renderDrawingInProgressOverlay() {
        const { isDrawingEnabled } = this.props;
        const { enableSaveButton } = this.state;
        if (!isDrawingEnabled) {
            return null;
        }
        const disableClassName = classNames(
            'btn btn-primary align-self-center',
            { 'disabled': !enableSaveButton }
        );

        const saveDrawingBtnContent = <button
            className={disableClassName}
            onClick={() => this.handleCompleteDrawing()}>Save</button>;

        const cancelDrawingBtnContent = <button
            className="btn btn-link cancel-btn align-self-center mr-4"
            onClick={() => this.handleClickCancelButton()}>Cancel</button>;

        return (
            <div className="map-custom-control-overlay d-flex justify-content-between">
                <div className="pl-2 pr-2 align-self-center">
                    <span className="d-none d-lg-block">
                        Click and drag to lasso the shapes you’d like to select.
                        Click “Cancel” to exit out of lasso selection mode.
                    </span>

                    <span className="d-block d-lg-none">
                        Click and drag to lasso
                    </span>
                </div>
                <div className="mr-4">
                    {cancelDrawingBtnContent}
                    {saveDrawingBtnContent}
                </div>
            </div>
        );
    }
    render() {

        return (
            <div className="map-custom-control">
                {this.renderDrawButton()}
                {this.renderDrawingInProgressOverlay()}
            </div>
        );
    }
}

AdvanceSearchDrawControl.propTypes = {
    map: PropTypes.object,
    isDrawingEnabled: PropTypes.bool,
    dispatchUpdateDrawingStateChange: PropTypes.func,
    dispatchUpdatePolygonFeature: PropTypes.func,
    currentDrilldownDimensionField: PropTypes.string,
    dispatchUpdateDrilldownDimensionField: PropTypes.func,
    currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatchResetAllFilters: PropTypes.func,
    polygonsGeojson: PropTypes.object
};

const mapDispatchToProps = {
    dispatchUpdateDrawingStateChange: updatedIsDrawingEnabled,
    dispatchUpdatePolygonFeature: updatePolygonGeojson,
    dispatchResetAllFilters: removePolygonFilter,
    dispatchUpdateDrilldownDimensionField: updateDrilldownDimensionField
};

const mapStateToProps = (state) => {
    return {
        // isDrawingEnabled: _.get(state, 'visualization.mapOptions.isDrawingEnabled', false),
        isDrawingEnabled: _.get(state, 'advanceSearch.isDrawingEnabled', false),
        currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
        currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
        // polygonsGeojson: _.cloneDeep(_.get(state, 'visualization.mapOptions.filteredGeojson', [])),
        polygonsGeojson: _.cloneDeep(_.get(state, 'advanceSearch.polygonsGeojson', {}))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearchDrawControl);
