import _ from 'lodash';
import * as actionTypes from '../actions/managePageActions';
import { USER_PAGE_CONFIGS, SECURITYLIST_PAGE } from 'appConstants';

export const getInitialState = () => {
  const locationHash = location.hash;
  const isAdminPage = _.includes(locationHash, '!/admin');
  const userMenu = _.includes(locationHash, 'menu=userRoles');
  const defaultMenu = isAdminPage && userMenu ? SECURITYLIST_PAGE : _.get(USER_PAGE_CONFIGS, [0, 'page']);
  return {
    collectionId: '',
    subscriptionId: '',
    userMenu: defaultMenu
  };
};

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.UPDATE_MANAGE_VIEW_COLLECTION_ID:
      return {
        ...state,
        collectionId: (_.get(action, 'collectionId') || '') + ''
      }
    case actionTypes.UPDATE_MANAGE_VIEW_SUBSCRIPTION_ID:
      return {
        ...state,
        collectionId: (_.get(action, 'collectionId') || '') + '',
        subscriptionId: (_.get(action, 'subscriptionId') || '') + ''
      }
    case actionTypes.UPDATE_USER_MENU:
      return {
        ...state,
        userMenu: _.get(action, 'userMenu')
      }
    default:
      return state;
  }
}
