import { enableCollectionLevelDateFilters } from 'common/config/customerConfiguration';
import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import {
  getDateFilterName,
  isIndependentDateFiltersEnabled,
  isDateIgnored,
  getAdditionalDateFiltersConfigs
} from 'pages/dashboard/components/Collections/collectionHelper';
import { getPreviousYearDateRange } from 'pages/Radar/radarHelper';
import { BIENNIUM_FISCAL_YEAR, DATE_OPTIONS_TYPE, COMPARISON_PERIOD } from 'appConstants';
import { showDateFilter } from 'common/config/viewConfiguration';
import { getAllMetricCardEntry } from 'pages/dashboard/components/CardList/cardHelper';
import { getCardId } from 'pages/embed/helper';
import { getAllMetricEntries } from './templateHelper';

export const getCollectionDateFiltersProps = (options) => {
  const { currentDrilldownTemplateId, currentDrilldownViewEntry, dateFilters } = options;
  const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);

  if (!enableCollectionLevelDateFilters()) {
    return {};
  }
  const cards = [{ templateEntry, viewEntry: currentDrilldownViewEntry }];
  const filterName = getDateFilterName(templateEntry, currentDrilldownViewEntry);
  const isDateFilterEnabled = !isDateIgnored(currentDrilldownViewEntry);
  const isIndependentFilters = (isIndependentDateFiltersEnabled(currentDrilldownViewEntry) ||
    isIndependentDateFiltersEnabled(templateEntry)) && isDateFilterEnabled
  const additionalDateFilterConfigs = getAdditionalDateFiltersConfigs(cards);

  if (!showDateFilter(templateEntry, currentDrilldownViewEntry) ||
    (_.isEmpty(additionalDateFilterConfigs) && !isDateFilterEnabled)) {
    return {};
  }

  return {
    cardEntries: cards,
    showIndependentFilters: false,
    hideCommonFilters: !isDateFilterEnabled,
    dateFilters: dateFilters,
    commonFilterName: filterName,
    alwaysShowFilterName: isIndependentFilters,
  };
}

export const getCollectionFiltersProps = (options) => {
  const { currentDrilldownTemplateId, currentUser, dateFilters } = options;

  return {
    availableTemplateIds: [],
    isDisabledAddFilterButton: true,
    hideCollectionDateFilters: true,
    currentDrilldownTemplateId: currentDrilldownTemplateId,
    disableOtherTemplateEntries: true,
    currentUser: currentUser,
    dateFilters: dateFilters,
    hideShowMore: true
  };
}

export const getTimeFrameProps = ({ commonFilters, currentDrilldownTemplateId }) => {
  const currentDateRange = _.get(commonFilters, 'dateRange', {});
  const options = {
    dateType: COMPARISON_PERIOD,
    comparisonPeriod: _.get(commonFilters, 'comparisonType')
  };
  const previousYearDateRange = getPreviousYearDateRange(options, currentDateRange);
  return {
    currentDateRangeOptions: _.pick(commonFilters, 'dateRange', 'dateType', 'relativeDateFilterEntry'),
    previousDateRangeOptions: { dateRange: previousYearDateRange, ...options },
    currentTemplateId: currentDrilldownTemplateId,
    isComparisonOn: _.get(commonFilters, 'comparisonModeOn', false)
  };
}

export const getDateFilterLabel = (currentDateType) => {
  let filterName = '';
  if (currentDateType === DATE_OPTIONS_TYPE.YEARLY) {
    filterName = 'Fiscal Year ';
  } else if (currentDateType == BIENNIUM_FISCAL_YEAR) {
    filterName = 'Biennium Fiscal Year ';
  }

  return filterName;
}

export const getNonCollectionCardEntries = (collectionMetrics, templateEntries) => {
  let groupedEntries = {};
  let nonGroupedEntries = [];
  _.each(templateEntries, (templateEntry) => {
    return _.each(templateEntry['view_entries'], (viewEntry) => {
      const id = getCardId(templateEntry, viewEntry);
      groupedEntries[id] = { templateEntry, viewEntry };
    });
  });
  const collectionCardEntries = _.flatMap(collectionMetrics, 'cards');
  const allMetricsCardEntries = getAllMetricEntries(templateEntries);

  const collectionCardIds = _.map(collectionCardEntries, ({ card_id }) => {
    return card_id
  });

  const allMetricsCardIds = _.map(allMetricsCardEntries, (cardEntry) => {
    return getCardId(cardEntry?.templateEntry, cardEntry?.viewEntry)
  })
  const otherCardIds = _.difference(collectionCardIds, allMetricsCardIds)
    .concat(_.difference(allMetricsCardIds, collectionCardIds));


  _.each(otherCardIds, (cardId) => {
    if (groupedEntries[cardId]) {
      nonGroupedEntries.push(getAllMetricCardEntry(groupedEntries[cardId]));
    }
  });

  return nonGroupedEntries;
}
