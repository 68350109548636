import _ from 'lodash';

export const getAdminConfigurations = (response) => {
  let splitConfigData = {};

  if( !_.isEmpty(response.branding)){
    splitConfigData['branding'] = response.branding;
  }

  if( !_.isEmpty(response.executive_insights_app_users)){
    splitConfigData['appConfigUsers'] = JSON.parse(response.executive_insights_app_users);
  }

  return splitConfigData
}
