import React from 'react'
import PropTypes from 'prop-types';
import OnboardCollections from './OnboardCollections';

function OnboardBodySection({ nextStep, collectionData, setCollectionData }) {

  const renderBodySection = () => {

    if (nextStep == 1) {
      return renderFinalBodySection();
    }

    return (
      <section className="forge-dialog__body" style={{ width: '600px' }}>
        <p className="pl-2">
          Welcome! Before you get started, let’s take a minute to customize your dashboard.
        Please select the topics you’re interested in seeing.</p>
        <OnboardCollections collections={collectionData} onCollectionData={setCollectionData} />
      </section>
    )
  }

  const renderFinalBodySection = () => {
    return (
      <section className="forge-dialog__body" style={{ width: '600px' }}>
        <p className="pl-0">
          You’re all set! Your dashboard has been updated to reflect your personal preferences. 
          You can make changes at any time from the Settings page.
        </p>

        <img src="/images/Settings_button.png" alt="setting" className="mt-3 setting-image" />
      </section>
    )
  }

  return (renderBodySection())
}

OnboardBodySection.propTypes = {
  nextStep: PropTypes.number,
  collectionData: PropTypes.array,
  setCollectionData: PropTypes.func 
}

export default OnboardBodySection