import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ForgePaginator } from '@tylertech/forge-react';

import { DEFAULT_PAGINATION_SIZE } from 'appConstants';

const Paginator = ({ totalRowsCount, setTableOptions }) => {
  const [size] = useState(DEFAULT_PAGINATION_SIZE);
  const [pageIndex] = useState(0);

  return (
    <ForgePaginator
      total={totalRowsCount}
      pageSizeOptions={[10, 15, 25, 50, 100]}
      pageSize={size}
      pageIndex={pageIndex}
      on-forge-paginator-change={setTableOptions}
    />
  );
}

Paginator.propTypes = {
  totalRowsCount: PropTypes.number,
  pageIndex: PropTypes.number,
  setTableOptions: PropTypes.func
}

export default React.memo(Paginator);
