import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { Pane } from 'react-split-pane';

// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

import { viewSharedMessage, viewSharedErrorMessage } from 'helpers/toastMessages';
import { getRadarData, updateRadarPreferences } from 'common/api/commonApi';
import DragAndDrop from 'common/components/DragAndDrop';
import {
  getRadarPageTitle,
  getRadarPageDescription,
  getDefaultRadarAreaEntry,
  getDefaultRadarMetricEntries,
  enableTemplateAndMetricPermission,
  getRadarOptions,
  getDefaultRadarMetricFilters,
  defaultRadarEntry
} from 'common/config/customerConfiguration';
import LoadingSpinner from 'common/components/LoadingSpinner';
import RadarTable from './RadarTable/RadarTable';
import RadarChoroplethContainer from './RadarMap/RadarChoroplethContainer';
import RadarSideBar from './RadarSideBar/RadarSideBar';
import RadarMetricCardList from './RadarMetrics/RadarMetricCardList';
import {
  getCensusTrackData,
  tractMapBuckets,
  radarDownloadCsvFile,
  getPreviousYearDateRange,
  getMetricPermissionUserRoles,
  getRadarMetricFilterWithGlobalFilter
} from './radarHelper';

import { removeRadarSessionStorage } from './radarSessionStorageHelper'

import RadarSaveButtonContainer from './RadarSaveButton/SaveButtonContainer';
import { PageScrollHandlerWrapper } from 'common/components/Hoc/PageScrollHandlerWrapper';
import {
  updateToDefaultRadarOptions,
  updateRadarOptions,
  updateCurrentDateRangeOptions,
  updatePreviousDateRangeOptions,
  updateRadarShapeAreaEntry,
  updateRadarSelectedTracts,
  updateRadarSelectedMetricEntries,
  updateRadarSelectedMapMetric,
  updateRadarToggleArea,
  updateSelectedAreaName,
  updateRadarMetricFilters,
  updateRadarMetricGlobalFilters
} from 'actions/radarActions';
import { updateRadarDrawingStateChange } from 'actions/mapOptionsActions';
import { defaultRadarOptions } from 'reducers/radarReducer';
import RadarExportModal from './RadarExport/RadarExportModal';
import RadarMapHeader from './RadarMapHeader';
import BookmarkTitleDescription from 'pages/BookmarkTitleDescription';
import ShareSection from 'pages/SubscriptionsManager/components/ShareSection';
import { shareRadarViaEmail } from 'common/api/commonApi';
import { getBookmarkDefaultParams } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import Legends from 'modules/Map/Legends/Legends';
import RadarMapControls from './RadarMapControls';
import ReactSplitPane from './ReactSplitPane';
import { trackEvent } from 'helpers/eventTracking';
import { ForgeButton } from '@tylertech/forge-react';

const DEFAULT_MAP__CONTAINER_HEIGHT = 650;
const DEFAULT_MAP_LEGEND_HEIGHT = 240;
class Radar extends Component {
  constructor(props, context) {
    super(props, context);
    const defaultMetrics = getDefaultRadarMetricEntries();
    const defaultMetricsFilters = getDefaultRadarMetricFilters(defaultMetrics);
    const templateId = _.get(defaultRadarEntry(), 'template_id');

    this.state = {
      selectedMetricEntries: _.get(props, 'selectedMetricEntries', defaultMetrics),
      selectedShapeAreaEntry: _.get(props, 'selectedShapeAreaEntry', getDefaultRadarAreaEntry()),
      selectedTracts: _.get(props, 'selectedTracts', []),
      comparisonTracts: _.get(props, 'comparisonTracts', []),
      currentDateRangeOptions: _.get(props, 'currentDateRangeOptions'),
      previousDateRangeOptions: _.get(props, 'previousDateRangeOptions'),
      currentTemplateId: templateId,
      metricFilters: _.get(props, 'metricFilters', defaultMetricsFilters),
      metricGlobalFilters: _.get(props, 'metricGlobalFilters', []),
      showExportModal: false,
      selectedMapMetric: _.get(props, 'selectedMapMetric', defaultMetrics[0]),
      title: getRadarPageTitle(),
      description: getRadarPageDescription(),
      shouldUpdateBookmark: false,
      showShareModal: false,
      isCheckedSelectedArea: false,
      isCheckedComparisonArea: _.get(props, 'isCheckedComparisonArea', false),
      isCheckedUnSelectedArea: _.get(props, 'isCheckedUnSelectedArea', true),
      legendData: {},
      isLoadBookmarkData: true,
      isLoadMapData: true,
      containerHeight: DEFAULT_MAP__CONTAINER_HEIGHT,
      mapContainerHeight: DEFAULT_MAP__CONTAINER_HEIGHT + DEFAULT_MAP_LEGEND_HEIGHT,
      showExportOtherDropdown: false,
      totalAggregateType: _.get(props, 'totalAggregateType', 'total'),
      isCurrentMetricsAltered: false
    };
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    window.radarPageLoadingStatus = true;
    this.fetchRadarData();
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { showExportModal } = this.state;
    if (this.exportButtonRef && !this.exportButtonRef.contains(e.target)) {
      if (showExportModal) {
        this.setState({ showExportModal: false, });
      }
    }
  }

  onClickBackButton = () => {
    const { dispatchDefaultRadarOptions, history } = this.props;
    dispatchDefaultRadarOptions();
    removeRadarSessionStorage();
    history.push('/overview');
  }

  onLoadLegendData = (key, data) => {
    this.setState((prevState) => {
      return {
        legendData: {
          ...prevState.legendData,
          [key]: data
        }
      }
    });
  }

  getParams = () => {
    const {
      selectedShapeAreaEntry,
      selectedMetricEntries,
      currentTemplateId,
      metricFilters,
      currentDateRangeOptions,
      previousDateRangeOptions,
      metricGlobalFilters
    } = this.state;
    const metricFiltersWithGlobalFilter = getRadarMetricFilterWithGlobalFilter(
      metricFilters, metricGlobalFilters);
    const currentDateRange = _.get(currentDateRangeOptions, 'dateRange', {});
    const previousYearDateRange = getPreviousYearDateRange(previousDateRangeOptions, currentDateRange);
    return {
      ignore_view_entry: true,
      selectedMetrics: JSON.stringify(_.map(selectedMetricEntries, 'id')),
      area_entry_id: _.get(selectedShapeAreaEntry, 'id'),
      commonFilters: JSON.stringify({ dateRange: currentDateRange }),
      drilldownEntry: JSON.stringify({
        currentDrilldownTemplateId: currentTemplateId,
      }),
      radarDateRanges: JSON.stringify({
        current: currentDateRange,
        compare: previousYearDateRange
      }),
      radarMetricFilters: JSON.stringify(metricFiltersWithGlobalFilter)
    }
  }

  handleAggregateChange = (totalAggregateType) => {
    this.setState({ totalAggregateType });
  }

  fetchRadarData = () => {
    const params = this.getParams();
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    this.setState({ isLoading: true });
    getRadarData(params, this.abortFetchController)
      .then((response) => {
        this.setState({
          radarData: response,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  onUpdateUserPreferences = (selectAreaName, comparisonAreaName) => {
    const { bookmarkId } = this.props;

    if (!bookmarkId) {
      this.setState({ isLoading: true });
      updateRadarPreferences({ selectAreaName, comparisonAreaName }).
        then(() => {
          window.radarOptions = { selectAreaName, comparisonAreaName };
          this.setState({ isLoading: false });
        }).catch((err) => {
          console.log('Error on updating radar options preferences ', err); // eslint-disable-line no-console
          this.setState({ isLoading: false });
        });
    }
  }

  onShapeModalSave = ({
    shapeArea, selectedTracts, comparisonTracts, selectAreaName, comparisonAreaName
  }) => {
    const isCheckedComparisonArea = !_.isEmpty(comparisonTracts);
    const isShapeEntryChanged = !_.isEqual(this.state.selectedShapeAreaEntry, shapeArea);
    const isSelectAreaName = selectAreaName !== this.props.selectAreaName;
    const isComparisonAreaName = comparisonAreaName !== this.props.comparisonAreaName;

    const isSelectedTractsChanged = !_.isEqual(this.state.selectedTracts, selectedTracts);
    const isComparisonTractsChanged = !_.isEqual(this.state.comparisonTracts, comparisonTracts);

    if (isSelectedTractsChanged)
      trackEvent('radar_selected_area_saved');

    if (isComparisonTractsChanged)
      trackEvent('radar_comparison_area_saved');

    this.setState({
      selectedTracts,
      comparisonTracts,
      selectedShapeAreaEntry: shapeArea,
      isCheckedComparisonArea
    }, () => {
      if (isShapeEntryChanged) {
        this.fetchRadarData();
      }
      if (isSelectAreaName || isComparisonAreaName) {
        this.onUpdateUserPreferences(selectAreaName, comparisonAreaName);
      }
      this.onUpdateTracts({ shapeArea, selectedTracts, comparisonTracts, isCheckedComparisonArea });
      this.props.dispatchUpdateSelectedAreaName('selectAreaName', selectAreaName);
      this.props.dispatchUpdateSelectedAreaName('comparisonAreaName', comparisonAreaName);
    });
  }

  onUpdateTracts = ({ shapeArea, selectedTracts, comparisonTracts, isCheckedComparisonArea }) => {
    this.props.dispatchRadarShapeAreaEntry(shapeArea);
    this.props.dispatchRadarSelectedTracts(selectedTracts, 'selectedTracts');
    this.props.dispatchRadarSelectedTracts(comparisonTracts, 'comparisonTracts');
    this.props.dispatchUpdateRadarToggleArea('isCheckedComparisonArea', isCheckedComparisonArea);
  }

  handleYearChange = (options, type) => {
    this.setState({ [type]: options }, () => {
      this.props.dispatchRadarDateFilterOptions(options, type);
      this.fetchRadarData();
    });
  }

  handleShapeAreaChange = (selectedShapeAreaEntry) => {
    this.setState({
      selectedShapeAreaEntry, selectedTracts: [],
      comparisonTracts: [], isCheckedComparisonArea: false,
      isCheckedUnSelectedArea: true
    }, () => {
      this.onUpdateTracts({
        shapeArea: selectedShapeAreaEntry,
        selectedTracts: [],
        comparisonTracts: [],
        isCheckedComparisonArea: false
      });
      this.props.dispatchUpdateRadarToggleArea('isCheckedUnSelectedArea', true);
      this.fetchRadarData();
    });
  }

  handleMapMetricChange = (metric) => {
    this.props.dispatchRadarSelectedMapMetric(metric);
    this.setState({ selectedMapMetric: metric });
  }

  handleSelectedTractsChanges = (newTracts, type = 'selectedTracts') => {
    const newState = { [type]: newTracts };
    this.setState(newState, () => {
      this.props.dispatchRadarSelectedTracts(newTracts, type);
    });
  }

  resetGeographicFilters = () => {
    this.setState({
      selectedTracts: [],
      comparisonTracts: []
    }, () => {
      this.props.dispatchRadarSelectedTracts([], 'selectedTracts');
      this.props.dispatchRadarSelectedTracts([], 'comparisonTracts');
      this.fetchRadarData();
    });
  }

  handleSelectedMetricsChange = (selectedMetricEntries, newMetricFilters = {}) => {
    let metricFilters = _.isEmpty(newMetricFilters) ? this.state.metricFilters : newMetricFilters;

    selectedMetricEntries = _.chain(selectedMetricEntries).compact()
      .map((entry, index) => {
        entry.metricIndex = index;
        return entry;
      })
      .value();

    metricFilters = _.chain(metricFilters).compact()
      .map((filter, index) => {
        filter.metricIndex = index;
        return filter;
      })
      .value();

    this.setState({
      selectedMetricEntries,
      metricFilters,
      selectedMapMetric: selectedMetricEntries[0],
      isCurrentMetricsAltered: true
    }, () => {
      window.dispatchEvent(new Event('map_resize'));
      this.fetchRadarData();
    });
    this.props.dispatchRadarMetricFilters(metricFilters);
    this.props.dispatchRadarSelectedMetricEntries(selectedMetricEntries);
  }

  handleBookmarkChange = (currentBookmark) => {
    const title = _.get(currentBookmark, 'name', getRadarPageTitle());
    const description = _.get(currentBookmark, 'bookmarkOptions.description', getRadarPageDescription());
    this.props.dispatchRadarOptions(currentBookmark);
    this.setState({ currentBookmark, title, description, shouldUpdateBookmark: false });
  }

  handleNameDescriptionChange = (title, description) => {
    this.setState({ title, description, shouldUpdateBookmark: true });
  }

  handleMetricFiltersChange = (metricFilters) => {
    this.setState({ metricFilters }, () => {
      this.props.dispatchRadarMetricFilters(metricFilters);
      this.fetchRadarData();
    });
  }

  handleMetricGlobalFiltersChange = (metricGlobalFilters) => {
    this.setState({ metricGlobalFilters }, () => {
      this.props.dispatchRadarMetricGlobalFilters(metricGlobalFilters);
      this.fetchRadarData();
    });
  }

  handleDefaultRadarClick = () => {
    const {
      dispatchDefaultRadarOptions,
      dispatchUpdateSelectedAreaName
    } = this.props;
    const radarOptions = getRadarOptions();
    const { selectAreaName, comparisonAreaName } = radarOptions;

    dispatchDefaultRadarOptions();
    dispatchUpdateSelectedAreaName('selectAreaName', selectAreaName);
    dispatchUpdateSelectedAreaName('comparisonAreaName', comparisonAreaName);
    // reducer and this component state have common names
    // so we directly updating state variables
    this.setState({ ...defaultRadarOptions(radarOptions), currentBookmark: {} }, () => {
      window.dispatchEvent(new Event('map_resize'));
    });
  }

  toggleArea = (key, isChecked) => {
    this.setState({ [key]: isChecked }, () => {
      this.props.dispatchUpdateRadarToggleArea(key, isChecked);
    });
  }

  toggleDrawPolygon = (toggle) => {
    this.props.dispatchUpdateRadarDrawingStateChange(toggle);
  }

  getMetricErrorStatusOptions = () => {
    const { selectedMetricEntries, radarData } = this.state
    let metricErrorStatusOptions = {};
    if (_.isEmpty(radarData)) {
      return null;
    }
    _.each(selectedMetricEntries, (metric) => {
      const metricId = metric?.id;
      const currentMetricData = _.find(radarData, { radar_metric_id: metricId })
      metricErrorStatusOptions[metricId] = { hasError: _.get(currentMetricData, 'has_error', false) }
    })
    return metricErrorStatusOptions;
  }

  renderMeticCards() {
    const { selectedMetricEntries, metricFilters, currentDateRangeOptions, metricGlobalFilters } = this.state;
    const metricErrorStatusOptions = this.getMetricErrorStatusOptions();
    return (
      <DragAndDrop>
        <RadarMetricCardList
          selectedMetricEntries={selectedMetricEntries}
          onSelectedMetricsChange={this.handleSelectedMetricsChange}
          currentDateRangeOptions={currentDateRangeOptions}
          metricFilters={metricFilters}
          metricErrorStatusOptions={metricErrorStatusOptions}
          onMetricFiltersChange={this.handleMetricFiltersChange}
          onMetricGlobalFiltersChange={this.handleMetricGlobalFiltersChange}
          metricGlobalFilters={metricGlobalFilters}
        />
      </DragAndDrop>
    )
  }

  renderLeftSideBar() {
    const { selectAreaName, comparisonAreaName, isRadarDrawingEnabled } = this.props;
    const radarLeftSideBarOptions = _.pick(this.state,
      'selectedShapeAreaEntry',
      'currentDateRangeOptions',
      'previousDateRangeOptions',
      'currentTemplateId',
      'selectedTracts',
      'comparisonTracts',
      'isCheckedComparisonArea',
      'isCheckedUnSelectedArea',
      'selectedMetricEntries'
    );

    return (
      <RadarSideBar
        {...radarLeftSideBarOptions}
        selectAreaName={selectAreaName}
        comparisonAreaName={comparisonAreaName}
        toggleSelectedTracts={this.handleSelectedTractsChanges}
        onYearChange={this.handleYearChange}
        onShapeAreaChange={this.handleShapeAreaChange}
        onShapeModalSave={this.onShapeModalSave}
        toggleArea={this.toggleArea}
        isRadarDrawingEnabled={isRadarDrawingEnabled}
        toggleDrawPolygon={this.toggleDrawPolygon}
        resetGeographicFilters={this.resetGeographicFilters}
      />
    )
  }

  updateRadarPageLoadingStatusForImageGenerator = () => {
    // Setting window object to check whether card id loaded the charts or not
    // from the image_generator
    const { isLoadMapData } = this.state;

    if (_.isEmpty(window.radarPageLoadingStatus)) {
      window.radarPageLoadingStatus = true;
    }

    if (!isLoadMapData) {
      window.radarPageLoadingStatus = false;
    }
  }

  onMapDataLoading = (isLoading) => {
    this.setState({ isLoadMapData: isLoading }, () => {
      this.updateRadarPageLoadingStatusForImageGenerator();
    });
  };

  renderMap(selectedEntry, showControl = false) {
    const {
      currentDateRangeOptions,
      previousDateRangeOptions,
      radarData,
      selectedMetricEntries,
      selectedTracts,
      comparisonTracts,
      selectedShapeAreaEntry,
      currentMapView,
      selectedMapMetric,
      containerHeight
    } = this.state;

    return (
      <RadarChoroplethContainer
        containerHeight={containerHeight}
        showControl={showControl}
        selectedShapeAreaEntry={selectedShapeAreaEntry}
        currentDrilldownTemplateId={_.get(selectedEntry, 'template_id')}
        metricEntry={selectedEntry}
        currentMapView={currentMapView}
        shapeGroupId={selectedShapeAreaEntry['id']}
        isCensusTract={_.get(selectedShapeAreaEntry, 'is_census_tract', false)}
        currentDateRangeOptions={currentDateRangeOptions}
        previousDateRangeOptions={previousDateRangeOptions}
        radarData={radarData}
        toggleShapeIdsFilter={this.handleSelectedTractsChanges}
        apiParams={this.getParams()}
        selectedShapeIds={selectedTracts}
        comparisonShapeIds={comparisonTracts}
        selectedMapMetric={selectedMapMetric}
        onLoadLegendData={this.onLoadLegendData}
        onSelectedMapMetricChange={this.handleMapMetricChange}
        selectedMetrics={selectedMetricEntries}
        onMapDataLoading={this.onMapDataLoading} />
    )
  }

  renderTable() {
    const {
      currentDateRangeOptions,
      previousDateRangeOptions,
      radarData,
      selectedMetricEntries,
      selectedTracts,
      comparisonTracts,
      selectedShapeAreaEntry,
      isCheckedUnSelectedArea,
      isCheckedComparisonArea,
      metricFilters,
      totalAggregateType,
      isCurrentMetricsAltered
    } = this.state;
    const { selectAreaName, comparisonAreaName } = this.props;
    const allTractRecords = getCensusTrackData(radarData, selectedMetricEntries);
    const bucketRanges = tractMapBuckets(allTractRecords, selectedMetricEntries);
    const params = this.getParams();
    return (
      <RadarTable
        selectedShapeAreaEntry={selectedShapeAreaEntry}
        selectAreaName={selectAreaName}
        comparisonAreaName={comparisonAreaName}
        bucketRanges={bucketRanges}
        currentDateRangeOptions={currentDateRangeOptions}
        previousDateRangeOptions={previousDateRangeOptions}
        radarData={radarData}
        isCheckedUnSelectedArea={isCheckedUnSelectedArea}
        isCheckedComparisonArea={isCheckedComparisonArea}
        selectedTracts={selectedTracts}
        comparisonTracts={comparisonTracts}
        metricFilters={metricFilters}
        selectedMetrics={selectedMetricEntries}
        apiParams={params}
        totalAggregateType={totalAggregateType}
        onAggregateChange={this.handleAggregateChange}
        isCurrentMetricsAltered={isCurrentMetricsAltered}
      />
    )
  }

  handleCloseRadarExportModal = (onCancel) => {
    if (onCancel) {
      trackEvent('radar_export_cancel');
    }
    this.setState({ showExportModal: false });
  }

  handleClickExportModalButton = () => {
    this.setState({ showExportModal: true });
  }

  handleShareModalOpen = () => {
    this.setState({
      showShareModal: true
    });
  }

  handleShareModalClose = () => {
    this.setState({
      showShareModal: false
    });
  }

  getRadarOptions = () => {
    return _.pick(
      this.props,
      'selectedTracts',
      'currentDateRangeOptions',
      'previousDateRangeOptions',
      'selectedMetricEntries',
      'selectedShapeAreaEntry',
      'metricFilters',
      'selectedMapMetric',
      'selectAreaName',
      'comparisonAreaName'
    );
  }

  getRadarEmailFilters = (bookmarkParams) => {
    const { comparisonTracts, isCheckedUnSelectedArea,
      isCheckedComparisonArea, selectedMetricEntries,
      selectedMapMetric, metricFilters } = this.state;
    const { radarOptions } = bookmarkParams;
    const { bookmarkId } = this.props;

    return {
      selectedShapeAreaEntryId: _.get(radarOptions, 'selectedShapeAreaEntry.id'),
      selectedRadarTracts: _.get(radarOptions, 'selectedTracts', []),
      comparisonTracts,
      isCheckedComparisonArea,
      isCheckedUnSelectedArea,
      selectedRadarMetricIds: _.map(selectedMetricEntries, (metric) => { return metric.id }),
      selectedRadarMapMetricId: _.get(selectedMapMetric, 'id'),
      radarMetricFilters: metricFilters,
      originBookmarkId: bookmarkId
    }
  }



  handleRadarShare = (options) => {
    const { currentUser } = this.props;
    const { title, description } = this.state;
    const name = title;
    const shareEmails = _.get(options, 'shareEmails', []);

    const bookmarkParams = {
      ...getBookmarkDefaultParams(this.props, { name, description }),
      is_radar: true,
      radarOptions: this.getRadarOptions()
    }

    const params = {
      shareEmails: shareEmails,
      bookmark: bookmarkParams,
      metricName: name,
      radarFilters: this.getRadarEmailFilters(bookmarkParams),
      applicationUrl: window.location.href
    };

    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      shareRadarViaEmail(params)
        .then((response) => {
          this.handleShareModalClose();
          if (response.ok) {
            toast.success(viewSharedMessage);
          } else {
            toast.error(viewSharedErrorMessage);
          }
          this.setState({ isLoading: false });
        }).catch(() => {
          this.handleShareModalClose();
          toast.error(viewSharedErrorMessage);
          this.setState({ isLoading: false });
        });
    }
  }

  onRadarExport = () => {
    return this.radarContainer.querySelector('.radar-right-side-body')
  }

  onChangeSize = (containerHeight) => {
    const defaultMapContainerHeight = (DEFAULT_MAP__CONTAINER_HEIGHT + DEFAULT_MAP_LEGEND_HEIGHT);
    const currentMapContainerHeight = containerHeight + DEFAULT_MAP_LEGEND_HEIGHT;

    const mapContainerHeight = currentMapContainerHeight > defaultMapContainerHeight ?
      currentMapContainerHeight :
      defaultMapContainerHeight;

    this.setState({ containerHeight, mapContainerHeight }, () => {
      window.dispatchEvent(new Event('map_resize'));
    });
  }

  handleClickRadarCsvDownload = (isExportOthers) => {
    const {
      radarData,
      selectedMetricEntries,
      selectedTracts,
      comparisonTracts,
      selectedShapeAreaEntry
    } = this.state;
    const { selectAreaName, comparisonAreaName } = this.props;
    const allTractRecords = getCensusTrackData(radarData, selectedMetricEntries);
    const csvOptions = {
      selectAreaName,
      comparisonAreaName,
      radarData: allTractRecords,
      selectedMetrics: selectedMetricEntries,
      selectedTracts,
      comparisonTracts,
      selectedShapeAreaEntry
    }
    if (!isExportOthers) {
      trackEvent('radar_export_csv');
    }
    this.setState({ showExportOtherDropdown: false });
    radarDownloadCsvFile(csvOptions);
  }

  renderRadarExportDropDownModal() {
    const { showExportModal, title, description } = this.state;

    if (!showExportModal) {
      return null;
    }

    return (
      <RadarExportModal
        plotlyChart={this.onRadarExport}
        csvRadarDownloadCallBack={this.handleClickRadarCsvDownload}
        onClose={this.handleCloseRadarExportModal}
        showExportModal={showExportModal}
        radarTitle={title}
        radarDescription={description} />
    )
  }

  toggleExportAsDropdown = () => {
    const { showExportModal } = this.state;
    trackEvent('radar_initiate_export_other');
    this.setState({ showExportModal: !showExportModal });
  }

  onToggleHandler = (isOpen, e, metadata) => {
    if (metadata.source != 'select') {
      this.setState({ showExportOtherDropdown: isOpen });
    }
  }

  renderRadarExportButton() {
    const { isLoading, showExportOtherDropdown } = this.state;

    return (
      <Dropdown as={ButtonGroup}
        show={showExportOtherDropdown}
        onToggle={(isOpen, e, metadata) => this.onToggleHandler(isOpen, e, metadata)}>
        <ForgeButton type="outlined">
          <button className='rounded-right-0'
            disabled={isLoading}
            onClick={() => { this.handleClickRadarCsvDownload() }}>
            Export
          </button>
        </ForgeButton>
        <Dropdown.Toggle split disabled={isLoading}
          variant="outline-primary"
          className="save-dropdown-icon dropdown-toggle-btn"
          aria-label="Export Options" />
        <Dropdown.Menu>
          <Dropdown.Item eventKey="2" variant="outline-primary"
            onClick={() => { this.handleClickRadarCsvDownload() }}
          >
            Export CSV
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" variant="outline-primary"
            onClick={() => { this.toggleExportAsDropdown() }}
          >
            Export other
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderSaveButton() {
    const { bookmarkId, selectAreaName, comparisonAreaName } = this.props;
    const radarSaveButtonAttributes = _.pick(this.state,
      'title',
      'description',
      'shouldUpdateBookmark',
      'metricFilters',
      'metricGlobalFilters',
      'currentDateRangeOptions',
      'previousDateRangeOptions',
      'selectedTracts',
      'comparisonTracts',
      'isCheckedComparisonArea',
      'isCheckedUnSelectedArea',
      'selectedMapMetric',
      'selectedMetricEntries',
      'selectedShapeAreaEntry',
      'totalAggregateType'
    );
    return (
      <RadarSaveButtonContainer
        {...radarSaveButtonAttributes}
        selectAreaName={selectAreaName}
        comparisonAreaName={comparisonAreaName}
        onBookmarkChange={this.handleBookmarkChange}
        bookmarkId={bookmarkId}
      />
    )
  }

  renderShareModal() {
    const { currentUser, templateEntries, userFromBellerophon } = this.props;
    const { showShareModal, selectedMetricEntries } = this.state;
    const placeholderText = 'Enter one or more email addresses';
    const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
    let allowedUserRoles = [];

    if (!showShareModal || _.isEmpty(currentUser)) {
      return null;
    }

    if (!isLaunchpadAdmin && enableTemplateAndMetricPermission()) {
      allowedUserRoles = getMetricPermissionUserRoles(templateEntries, selectedMetricEntries);
    }

    return (
      <ShareSection
        currentUser={currentUser}
        isShareEnabled={showShareModal}
        onCancelShare={this.handleShareModalClose}
        onSendClick={this.handleRadarShare}
        emailInputPlaceholder={placeholderText}
        allowedUserRoles={allowedUserRoles}
        shareType='metric'
        showCopyLink={true}
        trackEventName="radar_copy_link"
      />
    );
  }

  renderLegend = (metric, index) => {
    const { selectedTracts, comparisonTracts, legendData } = this.state;
    const { selectAreaName, comparisonAreaName } = this.props;
    const data = _.get(legendData, metric.name, []);

    return (
      <div className="radar-legend">
        <h3 key={index}>Percent change</h3>
        <Legends legends={data}></Legends>
        {(!_.isEmpty(selectedTracts) || !_.isEmpty(comparisonTracts)) &&
          <div className='radar-legend'>
            {!_.isEmpty(selectedTracts) && <div className='d-flex area-legend'>
              <span className='select-area-legend' style={{ backgroundColor: '#3f51b5' }}></span>
              <span className="area-label">{selectAreaName}</span>
            </div>
            }
            {!_.isEmpty(comparisonTracts) && <div className='d-flex'>
              <span className='comparison-area-legend' style={{ borderTop: '3px dotted #3f51b5' }}></span>
              <span className="area-label">{comparisonAreaName}</span>
            </div>
            }
          </div>
        }
      </div>
    );
  }

  renderMapAndLegend = (selectedEntry, index) => {
    const name = _.get(selectedEntry, 'name', '');

    return (
      <div key={`${name}-${index}`} className="map-items">
        {this.renderMap(selectedEntry, false)}
      </div>
    );
  }

  renderMultipleMaps = () => {
    return (
      <div className='radar-multiple-maps'>
        <div className="multiplemap-wrapper">
          {_.map(this.state.selectedMetricEntries, this.renderMapAndLegend)}
        </div>
        <RadarMapControls templateId={this.state.currentTemplateId} />
      </div>
    );
  }

  renderRadarMapHeader = () => {
    const radarHeaderAttributes = _.pick(this.state,
      'selectedShapeAreaEntry',
      'currentDateRangeOptions',
      'previousDateRangeOptions',
      'selectedMetricEntries'
    );
    return (<RadarMapHeader {...radarHeaderAttributes} />);
  }

  renderMapContainer = () => {
    const { selectedMetricEntries } = this.state;
    const initialHeight = `${DEFAULT_MAP__CONTAINER_HEIGHT}px`;
    const maxSize = `${DEFAULT_MAP__CONTAINER_HEIGHT + 100}px`;
    const mapContainerHeight = `${this.state.mapContainerHeight}px`;

    return (
      <div className="map-resize-wrapper" style={{ height: mapContainerHeight }}>
        <ReactSplitPane onChangeSize={this.onChangeSize}>
          <Pane initialSize={initialHeight} maxSize={maxSize}>
            {this.renderMultipleMaps()}
          </Pane>
          <Pane>
            <div className="radar-multiple-maps">
              <div className='radar-legend-container'>
                {_.map(selectedMetricEntries, this.renderLegend)}
              </div>
            </div>
          </Pane>
        </ReactSplitPane>
      </div>

    );
  }

  renderShareButton = () => {
    const { currentUser } = this.props;

    if (_.isEmpty(currentUser)) {
      return null;
    }

    return (
      <ForgeButton type="outlined">
        <button
          variant="outline-primary"
          onClick={this.handleShareModalOpen}>Share
        </button>
      </ForgeButton>
    )
  }

  render() {
    const { currentBookmark, title, description, selectedMetricEntries } = this.state;

    if (_.isEmpty(selectedMetricEntries)) {
      return <Redirect push to="/overview" />;
    }

    return (
      <main role="main" className="radar-page">
        <div className='radar-container '>
          {/* Left sidebar */}
          {this.renderLeftSideBar()}

          {/* Right side  */}
          <div className='radar-right-side' ref={(ref) => this.radarContainer = ref}>
            <header className='radar-page-top'>
              <div className='radar-page-head'>
                <button aria-label="Back To Overview" className="btn back-arrow-icon back-btn-light"
                  onClick={this.onClickBackButton}>
                  <i className="icons-arrow-left2"></i>
                </button>
                <BookmarkTitleDescription
                  currentBookmark={currentBookmark}
                  title={title}
                  onDefaultRadarClick={this.handleDefaultRadarClick}
                  onNameDescriptionChange={this.handleNameDescriptionChange}
                  description={description} />
              </div>
            </header>
            <div className='radar-right-side-body'>
              <div className='right-side-head'>
                <div className='d-flex gap-8 action-section'>
                  {this.renderShareButton()}
                  <div className="d-flex radar-export">
                    <div className="position-relative"
                      ref={(ref) => this.exportButtonRef = ref}>
                      {this.renderRadarExportButton()}
                      {this.renderRadarExportDropDownModal()}
                    </div>
                  </div>
                  {this.renderSaveButton()}
                  {this.renderShareModal()}
                </div>
              </div>
              <LoadingSpinner isLoading={this.state.isLoading} />
              {this.renderMeticCards()}
              {this.renderTable()}
              <div className="radar-card radar-map-container">
                {this.renderRadarMapHeader()}
                {this.renderMapContainer()}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

Radar.propTypes = {
  history: PropTypes.object,
  metricFilters: PropTypes.object,
  bookmarkId: PropTypes.string,
  currentUser: PropTypes.object,
  userFromBellerophon: PropTypes.object,
  dispatchDefaultRadarOptions: PropTypes.func,
  dispatchRadarOptions: PropTypes.func,
  dispatchRadarDateFilterOptions: PropTypes.func,
  dispatchRadarShapeAreaEntry: PropTypes.func,
  dispatchRadarSelectedTracts: PropTypes.func,
  dispatchRadarSelectedMetricEntries: PropTypes.func,
  dispatchRadarSelectedMapMetric: PropTypes.func,
  dispatchRadarMetricFilters: PropTypes.func,
  dispatchUpdateRadarToggleArea: PropTypes.func,
  dispatchUpdateSelectedAreaName: PropTypes.func,
  dispatchUpdateRadarDrawingStateChange: PropTypes.func,
  dispatchRadarMetricGlobalFilters: PropTypes.func,
  selectedMapMetric: PropTypes.object,
  templateEntries: PropTypes.object,
  selectAreaName: PropTypes.string,
  comparisonAreaName: PropTypes.string,
  isRadarDrawingEnabled: PropTypes.bool,
  metricGlobalFilters: PropTypes.array
}

function mapStateToProps(state) {
  return {
    selectedMetricEntries: _.get(state, 'radar.selectedMetricEntries'),
    selectedMapMetric: _.get(state, 'radar.selectedMapMetric'),
    selectedShapeAreaEntry: _.get(state, 'radar.selectedShapeAreaEntry'),
    selectedTracts: _.get(state, 'radar.selectedTracts', []),
    comparisonTracts: _.get(state, 'radar.comparisonTracts', []),
    metricFilters: _.get(state, 'radar.metricFilters', []),
    currentDateRangeOptions: _.get(state, 'radar.currentDateRangeOptions'),
    previousDateRangeOptions: _.get(state, 'radar.previousDateRangeOptions'),
    totalAggregateType: _.get(state, 'radar.totalAggregateType'),
    currentUser: _.get(state.currentUser, 'user', {}),
    userFromBellerophon: _.get(state, 'userFromBellerophon', {}),
    isCheckedUnSelectedArea: _.get(state, 'radar.isCheckedUnSelectedArea'),
    isCheckedComparisonArea: _.get(state, 'radar.isCheckedComparisonArea'),
    selectAreaName: _.get(state, 'radar.selectAreaName'),
    comparisonAreaName: _.get(state, 'radar.comparisonAreaName'),
    templateEntries: _.get(state, 'configurations.template_entries', []),
    isRadarDrawingEnabled: _.get(state, 'visualization.mapOptions.isRadarDrawingEnabled', false),
    bookmarkId: _.get(state, 'radar.bookmarkId', '') + '',
    metricGlobalFilters: _.get(state, 'radar.metricGlobalFilters', [])
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatchDefaultRadarOptions: () => {
      dispatch(updateToDefaultRadarOptions());
    },
    dispatchRadarOptions: (bookmarkConfig) => {
      const { id, radarOptions } = bookmarkConfig;
      dispatch(updateRadarOptions({
        ...radarOptions,
        bookmarkId: id
      }));
    },
    dispatchRadarDateFilterOptions: (options, type) => {
      if (type == 'currentDateRangeOptions')
        dispatch(updateCurrentDateRangeOptions(options));
      else if (type == 'previousDateRangeOptions')
        dispatch(updatePreviousDateRangeOptions(options));
    },
    dispatchRadarShapeAreaEntry: (selectedShapeAreaEntry) => {
      dispatch(updateRadarShapeAreaEntry(selectedShapeAreaEntry));
    },
    dispatchRadarSelectedTracts: (selectedTracts, shapeAreaType) => {
      dispatch(updateRadarSelectedTracts(selectedTracts, shapeAreaType));
    },
    dispatchRadarSelectedMetricEntries: (selectedMetricEntries) => {
      dispatch(updateRadarSelectedMetricEntries(selectedMetricEntries));
    },
    dispatchRadarSelectedMapMetric: (selectedMapMetric) => {
      dispatch(updateRadarSelectedMapMetric(selectedMapMetric));
    },
    dispatchUpdateRadarToggleArea: (key, isChecked) => {
      dispatch(updateRadarToggleArea(key, isChecked));
    },
    dispatchUpdateSelectedAreaName: (selectedKey, selectedAreaName) => {
      dispatch(updateSelectedAreaName(selectedKey, selectedAreaName));
    },
    dispatchRadarMetricFilters: (metricFilters) => {
      dispatch(updateRadarMetricFilters(metricFilters));
    },
    dispatchUpdateRadarDrawingStateChange: (toggle) => {
      dispatch(updateRadarDrawingStateChange(toggle));
    },
    dispatchRadarMetricGlobalFilters: (metricGlobalFilters) => {
      dispatch(updateRadarMetricGlobalFilters(metricGlobalFilters));
    }
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageScrollHandlerWrapper(Radar)));
