import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toggleShapeIdsFilter, updateDrawingStateChange } from 'actions/mapOptionsActions';
import { ForgeButton } from '@tylertech/forge-react';

class ChoroplethPolygonFilterButton extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      prevSelectedShapeIds: [],
      isPopupContentOpened: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { isPopupContentOpened } = this.state;
    const isMapElement = (e.target.className == 'mapboxgl-canvas');
    const isFilterChipClose = !_.isNull(e.target.closest('.filter-chip'))
    if (this.filterContainerRef && !this.filterContainerRef.contains(e.target)
        && !isMapElement && !isFilterChipClose && isPopupContentOpened) {
      this.onCancelClick();
    }
  }

  handleMapPolygonFilter = () => {
    const { selectedShapeIds, onLassoFilterClick } = this.props;
    this.setState({
      prevSelectedShapeIds: selectedShapeIds,
      isPopupContentOpened: true
    }, () => {
      this.props.dispatchUpdateDrawingStateChange(true);
    })
    onLassoFilterClick();
  }

  onCancelClick = () => {
    const { prevSelectedShapeIds } = this.state;
    this.props.dispatchToggleShapeIdsFilter(prevSelectedShapeIds, null);
    this.props.dispatchUpdateDrawingStateChange(false);
    this.props.onLassoFilterComplete();
    this.setState({ isPopupContentOpened: false });
  }

  onSaveClick = () => {
    this.props.dispatchUpdateDrawingStateChange(false);
    this.props.onLassoFilterComplete();
    this.setState({ isPopupContentOpened: false });
  }

  renderDrawButton() {
    const { isDrawingEnabled } = this.props;

    if (isDrawingEnabled) {
      return null;
    }

    return (
      <>
        <OverlayTrigger
          key="create-polygon-button"
          placement="left"
          overlay={
            <Tooltip id="tooltip-top">
              <div className="text-left">Click to apply a lasso mode.</div>
            </Tooltip>
          }>
          <div>
            <ForgeButton type="outlined" className="map-forge-btn"  
              onClick={() => this.handleMapPolygonFilter()}>
              <button type="button" title="Select by lasso">
                <i className="icons-lasso"></i>
                <span> Select by lasso </span>
              </button>
            </ForgeButton>
          </div>
        </OverlayTrigger>
      </>
    );
  }

  renderDrawingInProgressOverlay() {
    const { isDrawingEnabled, selectedShapeIds } = this.props;
    const { prevSelectedShapeIds } = this.state;
    const isShapesAlreadySelected = _.isEqual(prevSelectedShapeIds, selectedShapeIds);
    if (!isDrawingEnabled) {
      return null;
    }
    const disableClassName = classNames(
      'btn btn-primary align-self-center',
      { 'disabled': (isShapesAlreadySelected || _.isEmpty(selectedShapeIds)) }
    );

    const cancelBtnContent = <button
      className="btn btn-link cancel-btn align-self-center mr-4"
      onClick={() => this.onCancelClick()}>Cancel</button>;

    const saveBtnContent = <button className={disableClassName}
      onClick={() => this.onSaveClick()}>Save</button>;

    return (
      <div className="map-custom-control-overlay d-flex justify-content-between">
        <div className="pl-2 pr-2 align-self-center">
          <span className="d-none d-lg-block">
            Click and drag to lasso the shapes you’d like to select.
            Click “Cancel” to exit out of lasso selection mode.
          </span>

          <span className="d-block d-lg-none">
            Click and drag to lasso
          </span>
        </div>
        <div className="mr-4">
          {cancelBtnContent}
          {saveBtnContent}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="map-custom-control" ref={(ref) => this.filterContainerRef = ref}>
        {this.renderDrawButton()}
        {this.renderDrawingInProgressOverlay()}
      </div>
    );
  }
}

ChoroplethPolygonFilterButton.propTypes = {
  dispatchUpdateDrawingStateChange: PropTypes.func,
  dispatchToggleShapeIdsFilter: PropTypes.func,
  onLassoFilterClick: PropTypes.func,
  onLassoFilterComplete: PropTypes.func,
  isDrawingEnabled: PropTypes.bool,
  selectedShapeIds: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateDrawingStateChange: (toggle) => {
      dispatch(updateDrawingStateChange(toggle))
    },
    dispatchToggleShapeIdsFilter: (newShapeIds = [], shapeExtent = null) => {
      dispatch(toggleShapeIdsFilter(newShapeIds, shapeExtent))
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isDrawingEnabled: _.get(state, 'visualization.mapOptions.isDrawingEnabled', false),
    selectedShapeIds: _.cloneDeep(_.get(state, 'visualization.mapOptions.shape.selectedShapeIds', []))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoroplethPolygonFilterButton);
