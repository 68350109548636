import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NoDataWrapper } from './NoDataWrapper';
class NoDataFound extends Component {

  componentDidMount() {
    const { onUpdateLoadingStatus, viewMode } = this.props;
    if (!_.isUndefined(onUpdateLoadingStatus) && _.isEqual(viewMode, 'small')) {
      onUpdateLoadingStatus(false);
    }
  }

  render() {
    const { viewMode, hidden } = this.props;
    const noDataClassName = classNames({ 'no-data-lg': _.isEqual(viewMode, 'large') })

    return !hidden && (
      <div className={`no-data ${noDataClassName}`}>
        <img className='no-data-img' alt='No search results' src="../images/no-search-results.svg" />
        <p className="forge-typography--headline4 my-4">
          We didn’t find any data that matches your request.
        </p>
        <div className="forge-typography--headline5 mb-4">
          Please try adjusting your date range or your filters.
        </div>
      </div>
    );
  }
}

NoDataFound.propTypes = {
  viewMode: PropTypes.string,
  hidden: PropTypes.bool,
  onUpdateLoadingStatus: PropTypes.func
};

export default NoDataWrapper(NoDataFound);