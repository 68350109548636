import moment from 'moment';
import {
  getGlobalFilters,
  formatGlobalFilters,
  getGlobalFiltersFromTemplate,
  getDefaultGlobalFilterEntries
} from 'common/config/customerConfiguration';
import {
  getDimensionEntries,
  getQuickFilterEntries,
  getCurrentTemplateEntryById,
  getCurrentTemplateEntry
} from 'common/config/templateConfiguration';

import { getDistributionBucketEntry} from 'common/config/visualizationConfiguration';
import { mergeFilterWithEntries } from 'helpers/FilterHelper';

import {
  DEFAULT_GLOBAL_FILTER_TYPE,
  NUMBER_TYPES_FIELD,
  STRING_TYPES_FIELD,
  BOOLEAN_TYPES_FIELD,
  DATE_TYPES_FIELD,
  BETWEEN,
  NOT_BETWEEN,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  CURRENCY_TYPE,
  AMOUNT_TYPE
} from 'appConstants';
import { OTHER_BUCKET_VALUE } from 'modules/visualization/constants';

export const textOperatorEntries = [
  {name: "Is", value: "="},
  {name: "Is not", value: "!="},
  {name: "Contains", value: "like"},
  {name: "Does not contain", value: "not_like"}
];

export const numberOperatorEntries = [
  {name: "Is equal to", value: "=", placeHolder: "equals"},
  {name: "Is not equal to", value: "!=", placeHolder: "not equals"},
  {name: "Is greater than or equal to", value: ">=", placeHolder: "above"},
  {name: "Is less than or equal to", value: "<=", placeHolder: "below"},
  {name: "Is greater than", value: ">", placeHolder: "above"},
  {name: "Is less than", value: "<", placeHolder: "below"},
  {name: "Is between", value: "between", placeHolder: "to"},
  {name: "Is not between", value: "not between", placeHolder: "to"}
];

const booleanOperatorEntries = [
  {name: "No value", operator: "=", value: "No value"},
  {name: "True", operator: "=", value: "True"},
  {name: "False", operator: "=", value: "False"}
];

export const getTextFilterTopValueTitle = (filterEntry) => {
  const recommendedSortType = _.get(filterEntry, 'sort_type', 'desc');
  if (recommendedSortType === 'desc'){
    return 'Begin typing to filter. Values shown in order of most common to least common.';
  } else {
    return 'Begin typing to filter. Values shown in alphabetical order.';
  }
}

export const getLogicalOperatorEntries = (type) => {
  if(type == STRING_TYPES_FIELD || type == CURRENCY_TYPE || type == AMOUNT_TYPE) {
    return textOperatorEntries;
  } else if(type == NUMBER_TYPES_FIELD) {
    return numberOperatorEntries;
  } else if(type == BOOLEAN_TYPES_FIELD) {
    return booleanOperatorEntries;
  }
};

export const getLogicalOperatorEntry = (operator, type) => {
  const entries = getLogicalOperatorEntries(type);
  if(!_.isEmpty(entries)){
    return (_.find(entries, { value: operator }) || entries[0]);
  }
};

export const isTextSearchOperator = (operator) => {
  return _.includes(['like', 'not_like'], operator);
};

const isAutoFillSearchOperator = (operator) => {
  return _.includes(['=', '!='], operator);
};

export const isResetSearchSelectedValues = (oldOperator, newOperator) => {
  let isResetSearchValue = false;
  if(isTextSearchOperator(oldOperator) != isTextSearchOperator(newOperator)) {
    isResetSearchValue = true;
  } else if(isAutoFillSearchOperator(oldOperator) != isAutoFillSearchOperator(newOperator)) {
    isResetSearchValue = true;
  }
  return isResetSearchValue;
};

export const getAppliedFilterValueCount = (entry, quickFilters) => {
  const selectedFilterEntry = getCurrentFilterValueEntry(entry, quickFilters);
  if (_.isEmpty(selectedFilterEntry)) {
    return 0;
  }
  const filterConditions = _.get(selectedFilterEntry, 'conditions');
  const filterValues = _.chain(filterConditions).map('value').flatten().value();
  return _.isEmpty(filterValues) ? 1 : _.size(filterValues);
};

export const getFilterButtonPlaceHolder = (entry, quickFilters) => {
  const filterValue = getCurrentFilterValueEntry(entry, quickFilters);
  const type = (entry.renderType || entry.type);
  let placeHolder;

  if(_.isEmpty(filterValue)) {
    return 'Select....';
  }

  if(type == NUMBER_TYPES_FIELD) {
    const operatorEntry = getLogicalOperatorEntry(_.get(filterValue, 'operator'), 'number');
    const operatorText = _.get(operatorEntry, 'placeHolder', '');
    const value = _.get(filterValue, 'value');

    if (_.isNil(value)) {
      return 'Select....';
    } else if(operatorEntry.value === BETWEEN || operatorEntry.value === NOT_BETWEEN){
      placeHolder = `${_.upperFirst(operatorEntry.value)}
        ${value} ${operatorText} ${_.get(filterValue, 'to')}`;
    } else {
      placeHolder =  operatorText + ' - ' + value;
    }
  } else if(type == STRING_TYPES_FIELD) {
    const selectedValueLength = _.size(filterValue.conditions);
    if(selectedValueLength === 0) {
      return 'Select....';
    } else if(isTextSearchOperator(_.get(filterValue, 'operator'))){
      const textOperatorName = _.find(textOperatorEntries, ['value', _.get(filterValue, 'operator')]).name;
      placeHolder = (`${textOperatorName}  ${_.get(filterValue, 'values')[0]}`);
    } else {
      placeHolder = (selectedValueLength >= 2 ?
        `${selectedValueLength} - selected` :  _.get(filterValue, 'values[0]'));
    }
  } else if(type == DATE_TYPES_FIELD) {
    const dateFilter = _.find(quickFilters, ['field', entry.field]);
    const dateRange = _.get(dateFilter, 'dateRange', {});
    const startDate = moment(_.get(dateRange, 'start_date')).format('ll');
    const endDate = moment(_.get(dateRange, 'end_date')).format('ll');
    if(_.isEmpty(dateRange)){
      placeHolder = 'Select....';
    }else {
      placeHolder = startDate + " - " + endDate;
    }
  }
  return placeHolder;
};

export const getNumberFilterValue = (selectedFilter) => {
  const operatorEntry = getLogicalOperatorEntry(_.get(selectedFilter, 'operator'), 'number');
  const isOthersBucket = _.get(selectedFilter, 'isOthersBucket', false);
  const operatorText = _.get(operatorEntry, 'placeHolder', '');
  const value = _.get(selectedFilter, 'value');

  if (isOthersBucket) {
    return OTHER_BUCKET_VALUE;
  }else if(operatorEntry.value === BETWEEN || operatorEntry.value === NOT_BETWEEN){
    const toValue = _.get(selectedFilter, 'to');
    return `${_.upperFirst(operatorEntry.value)} ` + value + ` ${operatorText} ` + toValue;
  } else {
    return (operatorText + ' - ' + value);
  }
}

export const getDateFilterValue = (selectedFilter) => {
  const dateRange = _.get(selectedFilter, 'dateRange', {});
  const startDate = moment(_.get(dateRange, 'start_date')).format('ll');
  const endDate = moment(_.get(dateRange, 'end_date')).format('ll');

  return startDate + " - " + endDate;
}

export const getCurrentFilterValueEntry = (entry, quickFilters) => {
  return (_.find(quickFilters, { 'field': entry.field }) || {});
};

export const getCurrentFilterEntries = (filterEntries, quickFiltersEntries) => {
  const filters = _.uniqBy([...filterEntries, ...quickFiltersEntries], 'column');

  return _.map(filters, (filterEntry) => {
    const currentQuickFilterEntry = getCurrentFilterValueEntry(filterEntry, quickFiltersEntries);
    return {
      ...filterEntry,
      renderType: _.get(currentQuickFilterEntry, 'renderType', filterEntry.renderType)
    }
  });
}

export const getQuickFilterEntriesFromTemplate = (
  currentDrilldownTemplateId,
  viewEntry,
  isGroupByEntry = false
) => {
  let filterConfigs = getQuickFilterEntries(currentDrilldownTemplateId);
  const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);

  let configEntries = templateEntry['dimension_entries'];
  if(isGroupByEntry){
    configEntries = templateEntry['group_by_entries'];
  }

  filterConfigs = _.map(filterConfigs, (filterConfig) => {
    if(_.includes(['text'], filterConfig.renderType)){
      const findMatchColumn = _.find(configEntries, (findConfig) => {
        return findConfig.column == filterConfig.column
      });
      if(
        !_.isEmpty(findMatchColumn) &&
        _.includes([ CURRENCY_TYPE, AMOUNT_TYPE], findMatchColumn.renderType)
      ){
        filterConfig.renderType = findMatchColumn.renderType;
      }
    }
    return filterConfig
  })

  if(!_.isEmpty(getDistributionBucketEntry(viewEntry))){
    const distributionFilter = {..._.pick(viewEntry, ['column', 'name', 'field']), renderType:'number'}
    filterConfigs.push(distributionFilter);
  }

  return filterConfigs;
}

export const getSelectedDate = (quickFilters, filterEntry, dateOption, shouldReturnEmpty) => {
  const currentSelectedFilter = _.find(quickFilters, ['field', filterEntry.field]);
  const currentStartDate = _.get(currentSelectedFilter, 'dateRange.start_date');
  const currentEndDate = _.get(currentSelectedFilter, 'dateRange.end_date');
  if(dateOption === 'startDate') {
    return shouldReturnEmpty && _.isEmpty(currentStartDate) ?  '' : getStartDate(currentStartDate);
  } else {
    return shouldReturnEmpty && _.isEmpty(currentEndDate) ? '' : getEndDate(currentEndDate);
  }
}

export const getGroupDrilldownDimensionField = (
  currentDrilldownTemplateId,
  currentDrilldownDimensionField) => {
  const drillDownDimensionColumn = _.chain(getDimensionEntries(currentDrilldownTemplateId)).
    find({ field: currentDrilldownDimensionField }).
    get('column').
    value();
  const groupByField = _.chain(getQuickFilterEntries(currentDrilldownTemplateId)).
    find({ column: drillDownDimensionColumn }).
    get('field').
    value();
  return groupByField ? groupByField : currentDrilldownDimensionField;
};

export const getTableSearchField = (currentDrilldownTemplateId, options) => {
  const { field, column } = options;

  const searchField = _.chain(getQuickFilterEntries(currentDrilldownTemplateId)).
    find({ column }).
    get('field').
    value();
  return searchField ? searchField : field;
};

export const getNewQuickFilters = (dimensions, templateId, quickFilters) => {
  const removedDimensions = _.map(dimensions, (dimensionItem) => {
    const dimensionField = getGroupDrilldownDimensionField(templateId, dimensionItem.field);
    return {...dimensionItem, field: dimensionField };
  });

  return _.chain(quickFilters).
    map((filterItem) => {
      const removedFilter = _.find(removedDimensions, { field: filterItem.field });
      return removedFilter ? removeDimensionValuesFromFilter(filterItem, removedFilter) : filterItem;
    }).
    without(null).
    value();
};

export const getTypeHeadSelectedValues = (conditions, logicalOperatorEntry) => {
  if(isTextSearchOperator(logicalOperatorEntry.value)) {
    return [];
  } else {
    return _.chain(conditions).
      filter((condition) => !isTextSearchOperator(condition.operator)).
      map('value').
      flatten().
      value();
  }
}

export const getFilterItem = (filterEntry, conditions, type) => {
  const filterObject = {
    type: type || STRING_TYPES_FIELD,
    column: filterEntry.column,
    field: filterEntry.field
  };

  return _.isEmpty(conditions) ? filterObject : _.merge({}, filterObject, { conditions });
}

export const updateFilterItems = (filters, filterItem) => {
  const filterFiled = _.chain(filters).find({ field: filterItem.field }).get('field').value();
  let newFilters = [];

  if (_.isNil(filterFiled)) {
    newFilters = _.cloneDeep(filters || []).concat(filterItem);
  } else {
    newFilters = _.map(filters, (filter) => {
      const { field, type, renderType } = filter;
      if (_.get(filterItem, 'field') === field) {
        if(type === NUMBER_TYPES_FIELD || type === DATE_TYPES_FIELD) {
          return filterItem;
        } else if(renderType === NUMBER_TYPES_FIELD || renderType === DATE_TYPES_FIELD) {
          return filterItem;
        } else {

          let filterConditions = _.cloneDeep(_.get(filter, 'conditions', []));
          if(!_.isEmpty(filterConditions) && _.size(filterConditions) > _.size(filterItem.conditions)){
            filterConditions = _.map(filterConditions, (datum) => {
                const existingFilter = _.find(filterItem.conditions, { operator : datum.operator });
                if(!_.isEmpty(existingFilter)){
                  datum = existingFilter;
                }
                return datum;
            });

          } else {
            filterConditions = filterItem.conditions
          }

          filter['conditions'] = _.chain(filterConditions).
            uniqBy('operator').
            filter((condition) => !_.isEmpty(_.get(condition, 'value'))).
            value();
        }
      }
      return filter;
    });
  }

  return _.uniqBy(newFilters, 'field');
}

// TODO: this function not used anywhere in code. Check and remove it if not needed for future.
export const updateFilterValuesAndGetFilters = (globalFilters, currentFilter) => {
  const filteredValues = _.get(currentFilter, 'values', []);
  let isFilterPresent = !_.isEmpty(_.find(globalFilters, { 'field': currentFilter.field }));
  let newFilters = _.cloneDeep(globalFilters);
  if(_.isEmpty(newFilters)) {
    newFilters = [];
  }
  const currentFilterOperator = _.get(currentFilter, 'operator');


  if(isFilterPresent){
    newFilters =  _.map(newFilters, (filterItem) => {
      const { field, operator } = filterItem;
      let newValues = _.compact([...filteredValues]);

      if (_.get(currentFilter, 'field') === field) {
        if(!_.isEqual(currentFilterOperator, operator)){
          return currentFilter;
        }

        filterItem['values'] = newValues;
      }
      return filterItem;
    });
  } else {
    newFilters.push(currentFilter);
  }

  return _.remove(newFilters, (item) => {
    return  !_.isEmpty(item.values) || !_.isEmpty(item.conditions)
  });
}

export const getFormatConditionValues = (filter, emptyValue, isApply = false) => {
  let filterValueCondition = (value) => _.isNil(value) ? emptyValue : value;
  if (isApply) {
    filterValueCondition = (value) => (value === emptyValue) ? null : value;
  }

  return _.chain(filter).
    get('conditions').
    map((condition) => {
      const newValues = _.map(condition.value, filterValueCondition);
      return _.merge({}, condition, { value: newValues });
    }).
    value();
}

export const getNewFilterConditions = (conditions, logicalOperatorEntry, selectedItems) => {
  let newConditions = _.cloneDeep(conditions);
  const selectedFilterCondition = _.find(newConditions, { operator: logicalOperatorEntry.value });

  if (selectedFilterCondition) {
    newConditions = _.map(newConditions, (condition) => {
      if (condition.operator === logicalOperatorEntry.value) {
        if (isTextSearchOperator(logicalOperatorEntry.value)) {
          condition['value'] = selectedItems;
        } else {
          condition['value'] = _.uniq([...condition.value, selectedItems[0]]);
        }
      }
      return condition;
    });
  } else {
    newConditions.push({ operator: logicalOperatorEntry.value, value: selectedItems });
  }

  return newConditions;
}

export const removeValueAndGetConditions = ({
  conditions,
  logicalOperatorEntry,
  removedValue,
  emptyValue
}) => {
  const newConditions = _.chain(conditions).
    map((condition) => {
      if (_.get(logicalOperatorEntry, 'value') === _.get(condition, 'operator')) {
        const elementIndex = _.indexOf(condition.value, removedValue);
        condition.value.splice(elementIndex, 1);
        return _.isEmpty(condition.value) ? null : condition;
      }
      return condition;
    }).
    without(null).
    value();

  return getFormatConditionValues({ conditions: newConditions }, emptyValue, true);
}

export const removeFilterValueAndGetFilters = (filters, removedFilter, removedValue) => {
  const filteredItems =  _.map(_.cloneDeep(filters), (filterItem) => {
    const { field, values } = filterItem;
    if (field === _.get(removedFilter, 'field')) {
      filterItem['values'] = _.filter(values, (valueItem) => (valueItem !== removedValue));
    }
    return filterItem;
  });
  return _.remove(filteredItems, (item) => {
    return  !_.isEmpty(item.values);
  });
}

export const isMultiValueSelect = (filterColumnEntry) => {
  const filterType = _.get(filterColumnEntry, 'filter_type');
  const renderType = _.get(filterColumnEntry, 'renderType') || STRING_TYPES_FIELD;

  return (
    (filterType === DEFAULT_GLOBAL_FILTER_TYPE) && ( renderType == STRING_TYPES_FIELD)
  );
}

export const isSingleSelectFilter = (filterColumnEntry) => {
  const filterType = _.get(filterColumnEntry, 'filter_type');
  const renderType = _.get(filterColumnEntry, 'renderType', STRING_TYPES_FIELD);
  return (filterType === 'single-select') && ( renderType == STRING_TYPES_FIELD);
}

export const isDefaultCollectionFilter = (filterEntry, currentCollection) => {
  let collectionId = _.toString(_.get(currentCollection, 'bellerophon_tag_id'));
  collectionId =  _.isEmpty(collectionId) ? _.toString(_.get(currentCollection, 'tag_id')) : collectionId;
  const defaultGlobalFilterEntries = getDefaultGlobalFilterEntries();
  const matchedFilter = _.find(defaultGlobalFilterEntries, (collectionEntry) => {
    return _.toString(filterEntry['global_id']) === _.toString(collectionEntry['global_filter_id']) &&
      collectionId === _.toString(collectionEntry['collection'])
  });
  return !_.isEmpty(matchedFilter);
}

const getSingleSelectObject = (configuredFilterEntry, filter) => {
  const singleSelectValue = _.get(configuredFilterEntry, 'single_select_value');
  const values = _.get(filter, 'conditions[0].value');
  const newValues = _.isEmpty(values) ? [singleSelectValue] : [_.first(values)];

  return {
    field: _.get(configuredFilterEntry, 'field'),
    type: 'text',
    conditions: [{ operator: "=", value: newValues }]
  };
}

const getTextCondition = (configuredFilterEntry, entry) => {
  const conditionValue = JSON.parse(entry['values']);
  const values = _.chain(conditionValue)
    .split(',')
    .map(value => _.trimStart(value))
    .value();
  return {
    field: configuredFilterEntry['field'],
    type: entry['type'],
    conditions: [{operator: entry['operator'], value: values}]
  }
}

const getNumberCondition = (configuredFilterEntry, entry) => {
  return {
    ...configuredFilterEntry,
    ...entry,
    value: entry['values'],
    to: entry['to']
  }
}

const getMultiSelectObject = (configuredFilterEntry, currentCollection = {}) => {
  const defaultGlobalFilterEntries = getDefaultGlobalFilterEntries();
  let collectionId = _.toString(_.get(currentCollection, 'bellerophon_tag_id'));
  collectionId =  _.isEmpty(collectionId) ? _.toString(_.get(currentCollection, 'tag_id')) : collectionId;
  const currentCollectionDefaultFilters = _.filter(defaultGlobalFilterEntries, {collection: collectionId });

  const matchedFilter = _.find(currentCollectionDefaultFilters, (defaultEntry) => {
    return _.toString(defaultEntry['global_filter_id']) === _.toString(configuredFilterEntry['global_id'])
  });

  if(matchedFilter){
    if(matchedFilter['type'] == STRING_TYPES_FIELD){
      return getTextCondition(configuredFilterEntry, matchedFilter);
    }else if(matchedFilter['type'] == NUMBER_TYPES_FIELD){
      return getNumberCondition(configuredFilterEntry, matchedFilter);
    }else{
      return {...configuredFilterEntry, ...matchedFilter}
    }
  }else{
    return null;
  }
}

export const updateGlobalFiltersByConfigDefaults = (globalFilters, collection = {}) => {
  const configuredFilterEntries = getGlobalFilters();
  return  _.chain(configuredFilterEntries)
    .map((configuredFilterEntry) => {
      const matchedGlobalFilter = _.find(globalFilters, { field: configuredFilterEntry.field });
      const renderType = _.get(configuredFilterEntry, 'renderType', STRING_TYPES_FIELD);
      const isStringTypeField = (renderType === STRING_TYPES_FIELD);
      if (matchedGlobalFilter) {
        if(!isMultiValueSelect(configuredFilterEntry) && isStringTypeField) {
          // we are replacing user filter to default filter
          // default filter takes priority over user filter
          return getSingleSelectObject(configuredFilterEntry, matchedGlobalFilter);
        }else{
          const defaultFilter = getMultiSelectObject(configuredFilterEntry, collection)
          return _.isEmpty(defaultFilter) ? matchedGlobalFilter : defaultFilter;
        }
      } else {
        const singleSelectValue = _.get(configuredFilterEntry, 'single_select_value');
        //set the default values in filter if filter type is single select.
        if (!isMultiValueSelect(configuredFilterEntry) && singleSelectValue && isStringTypeField) {
          return getSingleSelectObject(configuredFilterEntry);
        } else {
          return getMultiSelectObject(configuredFilterEntry, collection);
        }
      }
    })
    .compact()
    .value();
}

export const quickFiltersWithoutTableSearchEntry = (removedFilterEntry, quickFilters, isNumericEntry) => {
  const { column, values } = removedFilterEntry;
  let newQuickFilters = _.cloneDeep(quickFilters);

  if(isNumericEntry) {
    newQuickFilters = _.reject(newQuickFilters, { column });
  } else {
    let currentFilterValues = _.chain(newQuickFilters).
                                find({ column }).
                                get(['conditions', 0, 'value'], []).
                                value();
    _.each(values, (value) => {
      currentFilterValues.splice(_.indexOf(currentFilterValues, value), 1);
    })
    if(_.isEmpty(currentFilterValues)) {
      newQuickFilters = _.reject(newQuickFilters, { column });
    }
  }

  return newQuickFilters;
}

const removeDimensionValuesFromFilter = (filterItem, removedFilter) => {
  const newConditions = _.chain(filterItem.conditions).
    map((condition) => {
      if (condition.operator === textOperatorEntries[0].value) {
        const newValues = _.filter(condition.value, (valueItem) => {
          return !_.includes(removedFilter.values, valueItem)
        });
        if (_.isEmpty(newValues)) { return null; }
        condition['value'] = newValues;
      }
      return condition;
    }).
    without(null).
    value();

  if (_.isEmpty(newConditions)) { return null; }
  filterItem['conditions'] = newConditions;

  return filterItem;
}

export function getStartDate(currentStartDate) {
  if(_.isEmpty(currentStartDate)) {
    return moment().toDate();
  }

  return new Date(moment(currentStartDate, DATE_FORMAT).format(DATE_TIME_FORMAT));
}

export function getEndDate(currentEndDate) {
  if(_.isEmpty(currentEndDate)) {
    return moment().toDate();
  }

  return new Date(moment(currentEndDate, DATE_FORMAT).format(DATE_TIME_FORMAT));
}

export const getFilterEntriesWithoutCollectionFilters = (
  templateId,
  viewEntry,
  globalFilters,
  isGroupByEntry = false
) => {
  const quickFilterEntries = getQuickFilterEntriesFromTemplate(templateId, viewEntry, isGroupByEntry);
  const templateEntry = getCurrentTemplateEntryById(templateId)

  const collectionFilterEntries = formatGlobalFilters(getGlobalFiltersFromTemplate(templateEntry));
  const collectionFilterWithEntries = mergeFilterWithEntries(globalFilters, collectionFilterEntries);
  const newQuickFilterEntries = _.filter(quickFilterEntries, (quickFilterEntry) => {
    const fieldName = `${quickFilterEntry.column}-${quickFilterEntry.name}`;

    return !_.get(collectionFilterWithEntries, fieldName);
  });

  return newQuickFilterEntries;
}

const migrateNewGenericFiltersFormat = (filters) => {
  const newFilters = _.map(filters, (filter) => {
    if (filter.type === STRING_TYPES_FIELD) {
      const allConditionsAreNotObject = !_.every(filter.conditions, _.isObject);
      const allValuesAreNotObject = !_.every(filter.values, _.isObject);
      if (allConditionsAreNotObject) {
        filter['conditions'] = [{ operator: filter.operator, value: filter.conditions }];
      } else if(allValuesAreNotObject) {
        filter['conditions'] = [{ operator: filter.operator, value: filter.values }];
      }
    } else if (filter.type === NUMBER_TYPES_FIELD) {
      const newValue = _.get(filter, 'values', 0);
      filter['value'] = _.get(filter, 'value', newValue);
    }

    return _.omit(filter, ['values']);
  });

  return _.chain(newFilters).
    groupBy('field').
    map((groupedByFieldFilters, field) => {
      const { column, type, exclude_null_values } = _.first(groupedByFieldFilters);
      if (type === STRING_TYPES_FIELD) {
        const conditions = _.chain(groupedByFieldFilters).map('conditions').flatten().value();
        if(!_.isEmpty(_.compact(conditions))){
          return { column, type, field, conditions: [..._.compact(conditions)], exclude_null_values };
        }
      }

      return _.first(groupedByFieldFilters);
    }).
    value();
}

export const getViewEntryQuickFilters = (templateId, viewEntry) => {
  const quickFilters = _.get(viewEntry, 'quick_filters', []);
  const configuredQuickFilters = getQuickFilterEntries(templateId);
  _.each(quickFilters, (filter) => {
    const matchedFilter = _.find(configuredQuickFilters, [
      'column',
      _.get(filter, 'column', ''),
    ]);
    if (!_.isEmpty(matchedFilter)) {
      filter['field'] = matchedFilter['field'];
    }
  });

  return migrateNewGenericFiltersFormat(quickFilters);
};

export const removeEmptyFilterConditions = (filters) => {
  return _.filter(filters, (filterItem) => {
    const { type, conditions } = filterItem;
    return type === STRING_TYPES_FIELD ? checkEmptyConditionValues(conditions) : true;
  });
}

const checkEmptyConditionValues = (conditions) => {
  return !_.isEmpty(conditions) ? _.some(conditions, (datum) => { return !_.isEmpty(datum.value) }) : false;
}