import { ForgeButton, ForgeCard, ForgeRadio } from '@tylertech/forge-react'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateForecastSelectedMetric, updateForecastTemplateId } from 'actions/forecastingActions';
import { storeFromPath } from './ForecastHelper';
import ProphetBadge from './ProphetBadge';

const ForecastMetricWiseList = (props) => {
  const {
    eligibleForecastMetrics,
    metricWiseSavedForecasts,
    updateForeCastCards,
    handleBackButtonClick
  } = props;

  const selectedForecastMetric = useSelector(
    state => _.get(state, ['forecasting', 'selectedForecastMetric'], {})
  );
  const dispatch = useDispatch();
  const [selectedForecasts, setSelectedForecasts] = useState(
    _.get(metricWiseSavedForecasts, _.get(selectedForecastMetric, 'name', ''), [])
  );

  const setSelectedMetric = (forecastMetric) => {
    const forecastMetricName = _.get(forecastMetric, 'name', '');
    const templateId = _.get(forecastMetric, 'template_id', '');
    const selectedForecasts = _.get(metricWiseSavedForecasts, forecastMetricName, []);
    dispatch(updateForecastSelectedMetric(forecastMetric));
    dispatch(updateForecastTemplateId(templateId));
    setSelectedForecasts(selectedForecasts);
  }

  const backToPreviosPage = () => {
    storeFromPath('/overview');
    handleBackButtonClick()
  }

  useEffect(() => {
    setSelectedForecasts(_.get(metricWiseSavedForecasts, _.get(selectedForecastMetric, 'name', ''), []));
  }, [metricWiseSavedForecasts])
  return (
    <div className='forecast-metric-wiselist'>
      <div className='page-main-head pl-2 py-1 h-auto'>
        <button
          aria-label="Back To Overview"
          className="btn back-arrow-icon back-btn-light"
          onClick={() => { backToPreviosPage() }}>
          <i className="icons-arrow-left2" />
        </button>
        <div className='forge-typography--headline4'>Advanced Forecasting</div>
      </div>

      <div className='select-metric-warpper row m-0 pt-10'>
        <div className='col-md-10 offset-md-1 d-flex flex-column h-100'>
          <div className='metric-head mb-8'>
            <div className='forge-typography--title	mb-3'>Select metric</div>
            <div className='forge-typography--subtitle1'>
              Forecasting compatible metrics are displayed here.
              If you cannot find a particular metric, please contact Support.
            </div>
          </div>
          <div
            role="radiogroup"
            className='select-metric-list d-flex gap-12 pb-3 pr-2 flex-column overflow-auto'
          >
            {_.map(eligibleForecastMetrics, (eligibleForecastMetric, index) => {
              return (
                <ForgeCard
                  key={`eligible-forecast${index}`}
                  outlined="true"
                  onClick={() => {
                    setSelectedMetric(eligibleForecastMetric)
                  }}
                >
                  <div className='d-flex align-items-center'>
                    <ForgeRadio className="mx-7">
                      <input
                        type="radio" id="radio-1" name="radios"
                        value="one"
                        checked={_.isEqual(selectedForecastMetric['name'], eligibleForecastMetric['name'])}
                      />
                    </ForgeRadio>
                    <div className='flex-grow-1'>
                      <div className='forge-typography--subtitle1 tx-20'>
                        {_.get(eligibleForecastMetric, 'name', '')}
                      </div>
                    </div>
                    <div className='saved-info-badge'>
                      {_.get(eligibleForecastMetric, 'isProphetAvailable', false) &&
                        <ProphetBadge />
                      }
                      <div className='forge-typography--subtitle1	mt-1 saved-info'>
                        {(() => {
                          const numberOfSavedForecasts = _.get(eligibleForecastMetric, 'savedForecastLength');
                          return (numberOfSavedForecasts == 1) ? `${numberOfSavedForecasts} saved forecast`
                            : `${numberOfSavedForecasts} saved forecasts`
                        })()}
                      </div>
                    </div>
                  </div>
                </ForgeCard>
              )
            })}
          </div>
          <div className='select-metric-footer d-flex align-items-center justify-content-between mt-5'>
            <ForgeButton type="outlined">
              <button
                type='button'
                onClick={() => { backToPreviosPage() }}
              >Cancel</button>
            </ForgeButton>
            <ForgeButton type="raised">
              <button
                type='button'
                onClick={() => {
                  updateForeCastCards(selectedForecasts);
                  storeFromPath('/forecasting')
                }}
              >
                Next
              </button>
            </ForgeButton>
          </div>
        </div>
      </div>
    </div>
  )
}

ForecastMetricWiseList.propTypes = {
  eligibleForecastMetrics: PropTypes.array,
  metricWiseSavedForecasts: PropTypes.object,
  updateForeCastCards: PropTypes.func,
  handleBackButtonClick: PropTypes.func
}

export default ForecastMetricWiseList;