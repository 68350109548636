
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "common/components/LoadingSpinner";
import AddUserSection from "./AddUserSection";
import CollaborateUserTableRow from "./CollaborateUserTableRow";
// TODO this need future story
// import DetailSettingButton from "./DetailSettingButton";
import TableOwnerRow from "./TableOwnerRow";

import { useFetchUsers } from "./hooks/useFetchUsers";
import { updateCollaborateUser } from "./api/updateCollaborateUser";
import { updateUsers } from "./collaboratorActions";


const CollaborateUsersTable = () => {
  const collection = useSelector((store) => store.collection);
  const users = useSelector((store) => store.users);
  const isUserDataLoading = useSelector((store) => store.isUserDataLoading);
  const reFetch = useSelector((store) => store.reFetch);
  const [showEmailInput, setEmailInput] = useState(false);
  const dispatch = useDispatch();
  const collectionId = _.get(collection, 'id');
  useFetchUsers(collectionId, reFetch);

  const onRoleSelect = async ({ value }, user) => {
    const newUser = {...user, role: value, can_update: true };
    const updatedUser = await updateCollaborateUser({ collectionId, newUser });
    const newUsers = _.map(users, (userItem) => {
      return userItem['id'] == user['id'] ? updatedUser : userItem;
    });
    dispatch(updateUsers(newUsers, false, false));
  }

  const renderCollaborateUserTableRow = (user) => {
    return(<CollaborateUserTableRow onRoleSelect={onRoleSelect} user={user} key={user.id} />);
  }

  const addedUsers = _.map(users, (user) => _.get(user, 'email'));

  return (
    <div className="details-table collaborate-details">
      {/* <div className="table-head">
        <span>People with access</span>
        <DetailSettingButton />
      </div> */}
      <LoadingSpinner isLoading={isUserDataLoading}></LoadingSpinner>
      <AddUserSection showEmailInput={showEmailInput} setEmailInput={setEmailInput} addedUsers={addedUsers}/>
      <table className="table table-layout-fixed">
        <tbody>
          <TableOwnerRow />
          {_.map(users, renderCollaborateUserTableRow)}
        </tbody>
      </table>
    </div>
  );
}

export default CollaborateUsersTable;
