import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RadarRow from './RadarRow';
import RadarTableWrapper from './RadarTableWrapper';
import RadarBarChart from '../RadarBarComparison/RadarBarChart';
import { getCensusEntry } from 'common/config/customerConfiguration';
import {
  getCensusTrackData,
  getRadarDateRangeText,
  getRadarFormattedBarChartData
} from '../radarHelper';
import { ForgeIcon, ForgeButtonToggleGroup, ForgeButtonToggle } from '@tylertech/forge-react';
class RadarTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      accordionStates: {
        selectedTracts: true,
        unSelectedTracts: true,
        comparisonTracts: true,
      },
      sortObject: {
        sortColumn: 'trackId',
        metricId: 'TRACT_ID',
        sortBy: 'asc',
        clickCount: 1,
        metricIndex: 0
      },
    };
  }

  toggleAccordionStates = (key) => {
    const { accordionStates } = this.state;
    const newStates = {
      ...accordionStates,
      [key]: !_.get(accordionStates, key)
    };
    this.setState({ accordionStates: newStates });
  }

  renderRadarRow(radarRowData, rowKey, rowOptions = {}) {
    const { selectedMetrics, bucketRanges, totalAggregateType, loadingStates } = this.props;
    const { accordionStates } = this.state;
    const { isAccordionRow, isTotalRow, isExpandRow } = rowOptions;
    const isLoading = loadingStates[rowKey];

    return (
      <RadarRow
        key={rowKey}
        totalAggregateType={totalAggregateType}
        rowKey={rowKey}
        isTotalRow={isTotalRow}
        isAccordionRow={isAccordionRow}
        isAccordionOpen={_.get(accordionStates, rowKey, false)}
        isExpandRow={isExpandRow}
        toggleAccordion={this.toggleAccordionStates}
        radarRowEntry={radarRowData}
        bucketRanges={bucketRanges}
        isLoading={isLoading}
        selectedMetrics={selectedMetrics} />
    );
  }

  renderRows(rowData, isExpandRow = false) {
    return _.map(rowData, (rowDatum, rowIndex) => {
      return this.renderRadarRow(rowDatum, rowIndex, { isExpandRow });
    })
  }

  renderAccordionTotalRow(trackId, rowKey, isAccordionRowTotal = true) {
    const { metricWiseData } = this.props;
    const metricData = metricWiseData[rowKey] || {};
    const rowEntry = { trackId, metricData };
    return this.renderRadarRow(rowEntry, rowKey, { isAccordionRow: isAccordionRowTotal, isTotalRow: true });
  }

  getSortData(tractData) {
    const { sortObject } = this.state;
    const sortColumn = _.get(sortObject, 'sortColumn');
      const isSortable = !_.isEmpty(sortColumn)
    let sortTractData = [];

    if (isSortable) {
      let filterColumn = sortColumn;
      if (sortColumn != 'trackId') {
        filterColumn = `metricData.${sortObject.metricIndex}.${sortObject.metricId}.${sortColumn}`
      }
      sortTractData = _.orderBy(tractData, [filterColumn], [sortObject.sortBy]);
    } else {
      sortTractData = _.orderBy(tractData, ['trackId'], ['asc']);
    }

    return sortTractData;
  }

  renderMetricsValueRows() {
    const {
      radarData, selectedTracts, selectedMetrics, comparisonTracts, isCheckedUnSelectedArea,
      selectAreaName, comparisonAreaName, isCheckedComparisonArea, totalAggregateType,
    } = this.props;

    const totalText = totalAggregateType == 'average' ? 'Average' : 'Total';
    const { accordionStates } = this.state;
    const allSelectedTracts = [...selectedTracts, ...comparisonTracts];
    let selectedTractData = _.filter(radarData, (datum) => {
      return _.includes(selectedTracts, datum['tract_id']);
    });
    let comparisonTractData = _.filter(radarData, (datum) => {
      return _.includes(comparisonTracts, datum['tract_id']);
    });
    let unSelectedTractData = _.filter(radarData, (datum) => {
      return !_.includes(allSelectedTracts, datum['tract_id']);
    });
    const isExpandSelectedTracts = accordionStates['selectedTracts'];
    const isExpandUnSelectedTracts = accordionStates['unSelectedTracts'];
    const isExpandComparisonTracts = accordionStates['comparisonTracts'];
    const totalSelectAreaName = `${totalText} of ${selectAreaName}`;
    const totalComparisonAreaName = `${totalText} of ${comparisonAreaName}`;

    let selectedCensusTractData = getCensusTrackData(selectedTractData, selectedMetrics);
    let comparisonCensusTractData = getCensusTrackData(comparisonTractData, selectedMetrics);
    let unSelectedCensusTractData = getCensusTrackData(unSelectedTractData, selectedMetrics);

    selectedCensusTractData = this.getSortData(selectedCensusTractData);
    comparisonCensusTractData = this.getSortData(comparisonCensusTractData);
    unSelectedCensusTractData = this.getSortData(unSelectedCensusTractData);

    return (
      <>
        {this.renderAccordionTotalRow(totalSelectAreaName, 'selectedTracts')}
        {isExpandSelectedTracts &&
          this.renderRows(selectedCensusTractData, isExpandSelectedTracts)
        }
        {isCheckedComparisonArea &&
          this.renderAccordionTotalRow(totalComparisonAreaName, 'comparisonTracts')}
        {isExpandComparisonTracts && isCheckedComparisonArea &&
          this.renderRows(comparisonCensusTractData, isExpandComparisonTracts)
        }
        {isCheckedUnSelectedArea &&
          this.renderAccordionTotalRow(`${totalText} of Unselected Area`, 'unSelectedTracts')
        }
        {isExpandUnSelectedTracts && isCheckedUnSelectedArea &&
          this.renderRows(unSelectedCensusTractData, isExpandUnSelectedTracts)
        }
      </>
    );
  }

  renderTotalRow() {
    const { totalAggregateType } = this.props;
    const totalText = totalAggregateType == 'average' ? 'Average' : 'Total';

    return this.renderAccordionTotalRow(`${totalText} of All`, 'total', false);
  }

  renderMetricHeaderRows() {
    const { selectedMetrics } = this.props;
    return (
      <tr>
        <td className="name-cell border-0"></td>
        {_.map(selectedMetrics, (metric, index) => {
          return <td key={`m-${index}`} className='border-0'>
            <div className='text-center'>
              <div className='radar-metric-title'>
                {metric['name']}
                {/* <i className='icons icons-external-link ml-2' /> */}
              </div>
              {/* <div className='text-primary'>
                +Add a filter
              </div> */}
            </div>
          </td>
        })}
      </tr>
    )
  }

  renderEmptyHeaders() {
    const { selectedMetrics } = this.props;
    const tableHeaders = _.map(_.times(selectedMetrics.length), (index) => {
      return [
        <th key={index + 'current'} ></th>,
        <th key={index + 'compare'} ></th>,
        <th key={index + 'change'} ></th>
      ];
    });
    return (
      <tr>
        <th className="name-cell border-0"></th>
        {tableHeaders}
      </tr>
    );
  }

  onClickTableSortedHeader(metricId, columnName, sortBy = '', metricIndex = 0) {
    const { sortObject } = this.state

    let sortType = 'asc';
    let clickCount = sortObject.clickCount;
    if (sortObject.sortColumn == columnName) {
      clickCount += 1;
      if (sortObject.clickCount > 2) {
        clickCount = 1;
      }
    } else {
      clickCount = 1;
    }

    sortType = _.isEmpty(sortBy) ? sortType : sortBy;

    const sortObjectValue = {
      sortColumn: columnName,
      metricId: metricId,
      sortBy: sortType,
      clickCount: clickCount,
      metricIndex: metricIndex
    }
    this.setState({ sortObject: sortObjectValue });
  }

  renderSortOption(metricId, columnName) {
    const { sortObject } = this.state;
    const isAscending = sortObject.sortBy == 'asc';
    // const iconName = isAscending ? "icons-arrow-up2" : "icons-arrow-down2";
    const iconName = isAscending ? 'arrow_downward' : 'arrow_upward';
    // const sortBy = isAscending ? 'asc' : 'desc';

    const sortColumn = _.get(sortObject, 'sortColumn');
    const isSortable = !_.isEmpty(sortColumn);
    const showIcon = (sortColumn == columnName && sortObject.metricId == metricId)

    if (!isSortable || !showIcon || sortObject.clickCount > 2) {
      return null
    }

    return (
      // <i key={index + 'sort' + columnName} className={iconName} ></i>
      <ForgeIcon className="tx-15" key={metricId + 'sort' + columnName} name={iconName} />
    )
  }

  renderYearRows() {
    const { sortObject } = this.state;
    const {
      selectedMetrics, previousDateRangeOptions, currentDateRangeOptions,
      selectedShapeAreaEntry
    } = this.props;
    const {
      currentDateRangeText, compareDateRangeText
    } = getRadarDateRangeText(currentDateRangeOptions, previousDateRangeOptions, true);

    const isAscending = sortObject.sortBy == 'asc';
    const changeSortBy = isAscending ? 'desc' : 'asc';
    const tableCells = _.map(_.times(selectedMetrics.length), (index) => {
      const selectedMetric = selectedMetrics[index];
      const metricId = selectedMetric['id'];
      const metricIndex = selectedMetric['metricIndex'];
      return [
        // eslint-disable-next-line react/jsx-key
        <td className='border-top-0' key={index + 'table-data'}>
          <div className='compare-render-row rotate '>
            <div
              key={index + 'compare'}
              className='compare-text d-flex gap-10 justify-content-center shape-row '
              onClick={() => this.onClickTableSortedHeader(metricId,
                              'compareValue', changeSortBy, metricIndex)}>
              <div>
                <div className='date-text'>{_.get(compareDateRangeText, 'startDateText')} -</div>
                <div className='date-text end-text'>{_.get(compareDateRangeText, 'endDateText')}</div>
              </div>
              {this.renderSortOption(metricId, 'compareValue')}
            </div>
            <div
              key={index + 'current'}
              className='compare-text  d-flex gap-10 justify-content-center shape-row '
              onClick={() => this.onClickTableSortedHeader(metricId,
                              'currentValue', changeSortBy, metricIndex)}>
              <div>
                <div className='date-text'>{_.get(currentDateRangeText, 'startDateText')} -</div>
                <div className='date-text end-text'>{_.get(currentDateRangeText, 'endDateText')}</div>
              </div>
              {this.renderSortOption(metricId, 'currentValue')}
            </div>
            <div
              key={index + 'change'}
              className='compare-text change-head d-flex align-items-center gap-10 shape-row '
              onClick={() => this.onClickTableSortedHeader(metricId, 'change', changeSortBy, metricIndex)}>
              <span className='date-text end-text'>Change</span>
              {this.renderSortOption(metricId, 'change')}
            </div>
          </div>
        </td>
      ];
    });
    return (
      <tr>
        <td className="name-cell border-top-0 shape-name-column">
          <div className='compare-render-row rotate compare-text d-flex gap-10 justify-content-end shape-row '
            onClick={() => this.onClickTableSortedHeader('TRACT_ID', 'trackId', changeSortBy)}>
            {_.get(selectedShapeAreaEntry, 'shape_name', '')}
            {this.renderSortOption('TRACT_ID', 'trackId')}
          </div>
        </td>
        {tableCells}
      </tr>
    );
  }

  renderTable() {
    return (
      <table className="table radar-data-table">
        <thead>
          {/* {this.renderEmptyHeaders()} */}
        </thead>
        <tbody>
          {this.renderMetricHeaderRows()}
          {this.renderYearRows()}
          {this.renderMetricsValueRows()}
          {this.renderTotalRow()}
        </tbody>
      </table>
    )
  }

  renderRadarBarCharts() {
    const censusEntry = getCensusEntry();
    const options = {
      ...this.props,
      useMetricWiseData: _.isEmpty(censusEntry),
    }
    const radarBarChartData = getRadarFormattedBarChartData(options);

    if (_.isEmpty(radarBarChartData)) {
      return null;
    }

    return _.map(radarBarChartData, (barChartData, index) => {
      return this.renderBarChart(barChartData, index);
    });
  }

  renderBarChart(barChartData, index) {
    const {
      currentDateRangeOptions,
      previousDateRangeOptions,
      selectedTracts,
      comparisonTracts,
      selectedMetrics
    } = this.props;

    const uniqueKey = "radarChart" + index;
    const isSelectedTracts = _.isEmpty(selectedTracts) ? false : true;
    const isComparisonTracts = _.isEmpty(comparisonTracts) ? false : true;

    return (
      <div className="barchart-item" key={uniqueKey}>
        <p className='tx-18 text-center'>
          {barChartData.name}
        </p>
        <RadarBarChart
          currentDateRangeOptions={currentDateRangeOptions}
          previousDateRangeOptions={previousDateRangeOptions}
          radarData={barChartData}
          selectedTracts={selectedTracts}
          comparisonTracts={comparisonTracts}
          selectedMetrics={selectedMetrics}
          isSelectedTracts={isSelectedTracts}
          isComparisonTracts={isComparisonTracts}
        />
      </div>
    )
  }

  renderAggregateButtons(){
    const { onAggregateChange, totalAggregateType } = this.props;
    const censusEntry = getCensusEntry();

    if(!_.isEmpty(censusEntry)){
      return null;
    }

    return(
      <div className='ml-auto'>
        <div className='d-flex gap-8 action-section'>
          <ForgeButtonToggleGroup>
            <ForgeButtonToggle
              key="total"
              value="total"
              selected={totalAggregateType == 'total'}
              onClick={() => onAggregateChange('total')}>
              Total
            </ForgeButtonToggle>
            <ForgeButtonToggle
              key="average"
              value="average"
              selected={totalAggregateType == 'average'}
              onClick={() => onAggregateChange('average')}>
              Average
            </ForgeButtonToggle>
          </ForgeButtonToggleGroup>
        </div>
      </div>
    )
  }
  render() {
    const { radarData, currentDateRangeOptions, previousDateRangeOptions, selectedMetrics } = this.props;

    const {
      currentDateRangeText, compareDateRangeText
    } = getRadarDateRangeText(currentDateRangeOptions, previousDateRangeOptions);

    let radarBarchartClassName = "";

    if (_.size(selectedMetrics) == 1) radarBarchartClassName = 'one-columns'
    if (_.size(selectedMetrics) == 2) radarBarchartClassName = 'two-columns'

    let selectedMatricsName = _.join(_.map(selectedMetrics, 'name'), ', ').replace(/,(?!.*,)/gmi, ' and ');


    if (_.isEmpty(radarData)) {
      return null;
    }
    return (
      <>
        <div className="radar-card radar-table-container">
          <div className="radar-card-head d-flex flex-row">
            <h2 className='section-title'>
              Metric comparison summary
            </h2>
            {this.renderAggregateButtons()}
          </div>
          {this.renderTable()}
        </div>

        <div className='radar-card radar-barchart-container h-100'>
          <div className="radar-card-head">
            <h2 className='section-title'>
              Pre/post comparison bar charts
            </h2>
            <p className="section-description">
              Percent change in {selectedMatricsName} across the selected geographies
              from {compareDateRangeText} to {currentDateRangeText}.
            </p>
          </div>

          <div className={`radar-barchart ${radarBarchartClassName}`}>
            {this.renderRadarBarCharts()}
          </div>
        </div>
      </>
    );
  }
}

RadarTable.propTypes = {
  bucketRanges: PropTypes.object,
  isCheckedUnSelectedArea: PropTypes.bool,
  selectAreaName: PropTypes.string,
  comparisonAreaName: PropTypes.string,
  totalAggregateType: PropTypes.string,
  radarData: PropTypes.array,
  selectedTracts: PropTypes.array,
  comparisonTracts: PropTypes.array,
  selectedMetrics: PropTypes.array,
  previousDateRangeOptions: PropTypes.object,
  currentDateRangeOptions: PropTypes.object,
  selectedShapeAreaEntry: PropTypes.object,
  isCheckedComparisonArea: PropTypes.bool,
  apiParams: PropTypes.object,
  metricWiseData: PropTypes.object,
  loadingStates: PropTypes.object,
  onAggregateChange: PropTypes.func,
  isCurrentMetricsAltered: PropTypes.bool
}

export default RadarTableWrapper(RadarTable);
