import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'

import ToggleButton from 'pages/Radar/RadarSideBar/ToggleButton';
import {
  updateTimeOfDayToggleButton,
  updateTimeOfDayFilters
} from 'actions/drilldownActions';

const TimeOfDayToggleButton = () => {
  const dispatch = useDispatch();
  const isTimeOfDayOn = useSelector(state => _.get(state, 'drilldown.isTimeOfDayOn', false));

  const onToggleTimeOfDayButton = (isChecked) => {
    dispatch(updateTimeOfDayToggleButton(isChecked));
    dispatch(updateTimeOfDayFilters([]));
  }

  return (
    <div className="toggle-button">
      <div className="forge-typography--caption">Time of Day, Day of Week</div>
      <ToggleButton
        id="time-of-day-filter"
        isChecked={isTimeOfDayOn}
        onClickCheckBox={onToggleTimeOfDayButton}
      />
    </div>
  );
}

TimeOfDayToggleButton.propTypes = {
  isTimeOfDayOn: PropTypes.bool
}

export default TimeOfDayToggleButton
