import React from 'react'
import PropTypes from 'prop-types'
import {
  ForgeIcon,
  ForgeList,
  ForgeListItem,
  ForgeMiniDrawer,
  ForgeScaffold,
  ForgeTooltip
} from '@tylertech/forge-react'
import { LIBRARY_TYPE } from 'appConstants';
import MetricLibraryCards from './MetricLibraryCards';

function MetricLibraryTabs({ metricLibraryOption, libraryType, onSelectLibraryType, metricCardEntries }) {

  const renderCards = () => {
    return <MetricLibraryCards
      metricLibraryOption={metricLibraryOption}
      metricCardEntries={metricCardEntries}
      libraryType={libraryType} />;
  }
  
  return (
    <ForgeScaffold className='metric-library-scaffold'>
      <ForgeMiniDrawer slot="left" direction="left" hover={false}>
        <ForgeList>
          <ForgeListItem id="tooltip-host-1"
            active={libraryType === LIBRARY_TYPE.ALL_METRICS}
            onclick={() => onSelectLibraryType(LIBRARY_TYPE.ALL_METRICS)}>
            <ForgeIcon slot="leading" name="file_document_multiple" />
            All metrics
          </ForgeListItem>
          <ForgeListItem id="tooltip-host-2"
            active={libraryType === LIBRARY_TYPE.MY_SAVED_VIEWS}
            onclick={() => onSelectLibraryType(LIBRARY_TYPE.MY_SAVED_VIEWS)}>
            <ForgeIcon slot="leading" name="book_outline" />
            My saved views
          </ForgeListItem>
          <ForgeTooltip target="#tooltip-host-1" position="top">All metrics</ForgeTooltip>
          <ForgeTooltip target="#tooltip-host-2" position="top">My saved views</ForgeTooltip>
        </ForgeList>
      </ForgeMiniDrawer>
      <div slot="body" className='metric-library-scaffold-cards'>
        {renderCards()}
      </div>
    </ForgeScaffold>
  )
}

MetricLibraryTabs.propTypes = {
  metricLibraryOption: PropTypes.object,
  libraryType: PropTypes.string,
  onSelectLibraryType: PropTypes.func,
  metricCardEntries: PropTypes.array
}

export default MetricLibraryTabs
