import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SearchInput = ({ onChange }) => {
  const [value, setValue] = useState('');

  const onClickRemove = () => {
    const event = { target: { value: ''}};
    setValue('');
    onChange(event);
  }

  const onChangeInput = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onChange(event);
  }

  return (
    <div className='input-search-container'>
      <div className="input-search mb-1">
        <i className="icons-search"></i>
        <input className="form-control form-control-sm" value={value} type="text" onChange={onChangeInput}/>
        {value.length > 0 && <i className="icons-times" onClick={onClickRemove}></i>}
      </div>
    </div>
  );
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default SearchInput;
