import React from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SearchFilterResultTable from '../SearchFilterResultTable';


const DetailsViewTab = () => {
  const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));

  return (
    <>
      {(!_.isEmpty(searchFilters) || !_.isEmpty(selectedShapeIds)) && <SearchFilterResultTable />}
    </>
  );
}

DetailsViewTab.propTypes = {
  isLoading : PropTypes.bool,
  detailsData : PropTypes.object
}

export default DetailsViewTab;
