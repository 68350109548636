import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { ForgeButton } from '@tylertech/forge-react';

import { THIRD_PARTY_DATA_WARNING_MESSAGE, MAP_TYPES } from 'appConstants';
import { buildQueryString } from 'helpers/HttpHelper';
import ThirdPartyWarningModal from 'pages/dashboard/components/ThirdPartyWarningModal';
import { getThirdPartyWarningStatus } from 'common/config/templateConfiguration';
import { getMapAttributes } from 'modules/Map/helpers/mapHelper';
import GlobalEvent from 'common/components/GlobalEvents';
import { summaryTableApiUrl } from './pieChartHelper';
import { forecastExportDownload } from './forecastHelper';

const SummaryTableDownload = (props) => {
    const { isOvertimeChart, isLoading, currentDrilldownTemplateId,
        summaryTableOptions, isForecastingChart } = props;
    const [showThirdPartyWarning, setShowThirdPartyWarning] = useState(false);
    const [showExportOtherDropdown, setShowExportOtherDropdown] = useState(false);

    useEffect(() => {
        GlobalEvent.on('SUMMARY_DOWNLOAD', summaryDownload);
        return () => {
            GlobalEvent.off('SUMMARY_DOWNLOAD', summaryDownload);
        }
    })


    const summaryDownload = (isDownload) => {
        if(isDownload){
            handleClickDownloadButton()
        }
    }

    const onHideThirdPartyWarningModel = () => {
        setShowThirdPartyWarning(false);
    }

    const getDownloadCsvUrl = (includeAllDimensionColumns) => {
        const { apiParams, isOvertimeChart, shapeName } = summaryTableOptions;
        const isChoroplethView = _.get(
            summaryTableOptions, 'currentMapView.type', '') === MAP_TYPES.CHOROPLETH;

        let csvDataUrl = '';
        if (summaryTableOptions['isPieChart']) {
            csvDataUrl = summaryTableApiUrl(_.pick(summaryTableOptions, [
                'commonFilters',
                'drilldown',
                'mapOptions',
                'currentDrilldownDimensionField',
                'currentDrilldownViewEntry',
                'currentBarChartSortType']));
        } else if (isOvertimeChart) {
            apiParams['includeAllDimensionColumns'] = includeAllDimensionColumns;
            apiParams['selectedDimensions'] = JSON.stringify(
                _.get(summaryTableOptions, 'selectedDimensions'));
            csvDataUrl = `/api/summary_table/overtime_download.csv?${buildQueryString(apiParams)}`;
        } else if (isChoroplethView) {
            const { currentPeriodMapAttributes } = getMapAttributes(summaryTableOptions);
            let { apiParams } = currentPeriodMapAttributes;
            apiParams = _.merge({}, apiParams, { shapeName });
            csvDataUrl = `/api/visualization/map/map_summary_download.csv?${buildQueryString(apiParams)}`;
        } else {
            csvDataUrl = `/api/summary_table/snapshot_download.csv?${buildQueryString(apiParams)}`;
        }

        return csvDataUrl
    }

    const handleClickDownloadButton = (includeAllDimensionColumns = false) => {
        setShowThirdPartyWarning(false);
        if (isForecastingChart) {
            forecastExportDownload(props);
        } else {
            const csvDataUrl = getDownloadCsvUrl(includeAllDimensionColumns);
            window.open(csvDataUrl, '_blank');
        }

    }

    const renderThirdPartyWarningModel = () => {
        const title = "Third-Party Data warning";

        return showThirdPartyWarning && (<ThirdPartyWarningModal
            title={title}
            message={THIRD_PARTY_DATA_WARNING_MESSAGE}
            onClose={onHideThirdPartyWarningModel}
            onDownloadConfirm={handleClickDownloadButton} />)
    }

    const renderExportButton = () => {
        return (
            <ForgeButton type="outlined">
                <button
                    className="ml-auto"
                    onClick={() => onClickSummaryDownloadButton()}>
                    Export
                </button>
            </ForgeButton>
        );
    }

    const onClickSummaryDownloadButton = (includeAllDimensionColumns = false) => {
        const isThirdPartyWarning = getThirdPartyWarningStatus(currentDrilldownTemplateId);
        if (isThirdPartyWarning) {
            setShowThirdPartyWarning(true);
        } else {
            handleClickDownloadButton(includeAllDimensionColumns);
        }
    }

    const onToggleHandler = (isOpen, e, metadata) => {
        if (metadata.source != 'select') {
            setShowExportOtherDropdown(isOpen);
        }
    }

    const onClickOverTimeChartSummaryDownload = (includeAllDimensionColumns) => {
        setShowExportOtherDropdown(false);
        onClickSummaryDownloadButton(includeAllDimensionColumns);
    }

    const renderExportButtonDropDown = () => {
        return (
            <Dropdown as={ButtonGroup}
                alignRight
                show={showExportOtherDropdown}
                onToggle={(isOpen, e, metadata) => onToggleHandler(isOpen, e, metadata)}>
                <ForgeButton type="outlined">
                    <button className='rounded-right-0'
                        onClick={() => onClickOverTimeChartSummaryDownload(false)}>
                        Export
                    </button>
                </ForgeButton>
                <Dropdown.Toggle split disabled={isLoading}
                    aria-label="Export Options"
                    variant="outline-primary"
                    className="save-dropdown-icon dropdown-toggle-btn" />
                <Dropdown.Menu>
                    <Dropdown.Item eventKey="2" variant="outline-primary"
                        onClick={() => { onClickOverTimeChartSummaryDownload(false) }}
                    >
                        Export current columns
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" variant="outline-primary"
                        onClick={() => { onClickOverTimeChartSummaryDownload(true) }}
                    >
                        Export all columns
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <>
            {renderThirdPartyWarningModel()}
            {isOvertimeChart ? renderExportButtonDropDown() : renderExportButton()}
        </>
    );
};

SummaryTableDownload.propTypes = {
    isOvertimeChart: PropTypes.bool,
    isLoading: PropTypes.bool,
    currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    summaryTableOptions: PropTypes.object,
    isForecastingChart: PropTypes.bool,
}

export default SummaryTableDownload;
