import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isEnterButtonPressed, isEscapeButtonPressed } from 'helpers/mouseEventsHelper';

class InlineEdit extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      inputText: _.get(props, 'defaultText'),
      showInputField: false,
    };
  }

  componentDidMount() {
    const { defaultText } = this.props;
    this.setState({ inputText: defaultText });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(preProps) {
    const { defaultText } = this.props;
    if (!_.isEqual(preProps.defaultText, defaultText)) {
      this.setState({ inputText: defaultText });
    }
  }

  handleInputTextChange = (e) => {
    this.setState({ inputText: e.target.value });
  };

  handleInputKeyDown = (e) => {
    const { onEditClose, onInputChange } = this.props;
    const value = e.target.value;
    if (isEnterButtonPressed(e)) {
        onInputChange(value);
    }else if(isEscapeButtonPressed(e)){
      onEditClose();
    }
  };

  handleEditClose = () => {
    const { onEditClose, defaultText } = this.props;
    this.setState({ inputText: defaultText });
    onEditClose();
  }

  handleClickOutside = (e) => {
    if (this.editCollectionRef && !this.editCollectionRef.contains(e.target)) {
      this.handleEditClose();
    }
  }

  renderInputField() {
    const { onInputChange, style } = this.props;
    const { inputText } = this.state;
    const newStyle = style ? style : {};

    return (
      <div
        style={newStyle}
        className="inline-edit-input d-flex align-items-center"
        ref={(ref) => this.editCollectionRef = ref}>
        <input autoFocus={true}
          value={inputText}
          className="form-control"
          onChange={(e) => this.handleInputTextChange(e)}
          onKeyDown={(e) => this.handleInputKeyDown(e)}
          type="text"
        />

        <div className='action'>
          <button className="btn p-0 change-btn"
            onClick={() => onInputChange(inputText)}></button>
          <button className="btn p-0 align-top edit-close-btn"
            onClick={this.handleEditClose}></button>
        </div>
      </div>
    );
  }

  render() {
    const { enableInputEdit, children } = this.props;
    return <>{enableInputEdit ? this.renderInputField() : children}</>;
  }
}

InlineEdit.propTypes = {
  onInputChange: PropTypes.func,
  onEditClose: PropTypes.func,
  defaultText: PropTypes.string,
  enableInputEdit: PropTypes.bool,
  children: PropTypes.element.isRequired,
  style: PropTypes.object
};

export default InlineEdit;
