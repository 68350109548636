import { VIEW_MODE } from 'modules/visualization/constants';
import { COMPARE_VIEW_STACK_COLORS, DISTRIBUTION_CHART_TYPES } from 'appConstants';
import {
  showDimensionsInDistribution,
  getConfiguredMetricColor
} from 'common/config/visualizationConfiguration';

import { getNullValueLabel } from 'common/config/templateConfiguration';

// Input:
//  formattedData =>
//      [
//        {
//          id: 'bucket_0_..',
//          label: 'High',
//          value: 21.98,
//          bucketEntry: <bellerophonBucketEntry>|null,
//          plotlyDescription: '<b>High<b>...'
//        },
//        ...
//      ]
export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    return !_.isEqual(vizOptions, previousVizOptions);
  },
  toPlotlyParams: (vizOptions, apiData, formattedData) => {
    if(_.isEmpty(formattedData)) {
      return {};
    }
    const { afterRender, templateId } = vizOptions;
    const isSmallView = vizOptions.viewMode === VIEW_MODE.SMALL;
    const primaryMetricColor = getConfiguredMetricColor(templateId, 'primary');
    const secondaryMetricColor = getConfiguredMetricColor(templateId, 'secondary');
    const isDiscrete = _.get(vizOptions, 'distributionOption.isDiscrete', false);
    const isCumulative = _.get(vizOptions, 'distributionOption.isCumulative', false);
    const showDimensions = showDimensionsInDistribution(_.get(vizOptions,'viewEntry'));
    const isStackBarMode = showDimensions && !(isDiscrete && isCumulative);
    let plotlyData = [], cumulativeBar, discreteBar, discreteStackBar, cumulativeStackBar;
    discreteBar = getPlotlyBarData(formattedData.discreteData, primaryMetricColor, true);
    cumulativeBar = getPlotlyBarData(formattedData.cumulativeData, secondaryMetricColor);

    if(showDimensions){
      discreteStackBar = _.map(formattedData.discreteEntriesData, (entriesData, index) => {
        return getPlotlyBarData(entriesData, COMPARE_VIEW_STACK_COLORS[index], true);
      });

      cumulativeStackBar = _.map(formattedData.cumulativeEntriesData, (entriesData, index) => {
        return getPlotlyBarData(entriesData, COMPARE_VIEW_STACK_COLORS[index]);
      });
    }

    if(isStackBarMode){
      if(isDiscrete){
        plotlyData.push(discreteStackBar)
      } else if (isCumulative) {
        plotlyData.push(cumulativeStackBar)
      }
    } else {
       if(isDiscrete){
        plotlyData.push(discreteBar)
      }
      if (isCumulative) {
        plotlyData.push(cumulativeBar)
      }
    }

    const data = _.flatMapDeep(plotlyData);
    const layout = {
      "shapes": [],
      "showlegend": false,
      "autosize": true,
      "barmode": isStackBarMode ? "stack" : "group",
      "margin": {"l":20,"t":15,"b":15,"r":10,"pad":5},
      "bargap":"",
      "dragmode":false,
      "showspikes":false,
      "bargroupgap":"",
      "height":isSmallView ? '' : 450,
      "xaxis":{
        "showticklabels":true,
        "showgrid":false,
        "zeroline":false,
        "showcrossline":false,
        "automargin":true,
        "side":"bottom",
        "tickfont": {
          "color":"#1d2124",
          "family": isSmallView ? 'Roboto' : '',
          "size": isSmallView ? 8 : "auto"
        },
        "ticktext": formattedData.cumulativeData.map((d) => d.tickLabel),
        "tickvals": formattedData.cumulativeData.map((d) => d.label)
      },
      "yaxis":{
        "showticklabels":true,
        "showdividers":true,
        "dividercolor":"block",
        "dividerwidth":1,
        "automargin":true,
        "tickfont":{
          "family": isSmallView ? 'Roboto' : '',
          "size":"auto"
        },
        "tickprefix": '',
        "ticksuffix": '',
        "zeroline":true,
        "showcrossline":true
      },
      "annotations":"",
      "hovermode":"x"
    };
    const config = {
      "displayModeBar":false,
      "scrollZoom":false,
      "editable":false,
      "showLink":false,
      "responsive":true
    };

    afterRender(getlegendsItems(apiData, vizOptions));
    return { data, layout, config };
  }
};

const getPlotlyBarData = (data, markerColor, isDiscrete = false) => {
  const customData = isDiscrete ?
    [data.map((d) => d.buckets), DISTRIBUTION_CHART_TYPES.DISCRETE] :
    [data.map((d) => d.buckets), DISTRIBUTION_CHART_TYPES.CUMULATIVE];
  
    return {
      "x": data.map((d) => d.label),
      "y": data.map((d) => d.value),
      "meta": data,
      "name": data.map((d) => d.plotlyDescription),
      "text": data.map((d) => d.plotlyDescription),
      "textposition":'none',
      "type":"bar",
      'customData': customData,
      "orientation":"v",
      "hoverinfo":"none",
      "visible":true,
      "marker": { "color": markerColor }
    };
}

export const getlegendsItems = (apiData, vizOptions) => {
  const entries = _.keys(_.get(apiData, 'entries', {}));
  const drilldownEntry = JSON.parse(_.get(vizOptions, 'apiParams.drilldownEntry', '{}'));
  const nullValueLabel = getNullValueLabel(drilldownEntry['currentDrilldownTemplateId']);

  let legendItems = _.map(entries, (dimensionName, index) => {
    return {
      label: dimensionName || nullValueLabel,
      color: COMPARE_VIEW_STACK_COLORS[index]
    };
  });

  if(entries.length === 10){
    legendItems = legendItems.concat({
      color: COMPARE_VIEW_STACK_COLORS[entries.length],
      label: 'Others'
    });
  }

  return legendItems;
}
