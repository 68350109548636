import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { configuredDefaultDateType, getGenericDatePickerLabel } from 'common/config/customerConfiguration';
import { getDefaultDateRange } from 'helpers/dateHelper';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import DateRangeFilterWithBadge from
  'pages/dashboard/components/Collections/CollectionDateFilters/DateRangeFilterWithBadge';

class DateFilter extends Component {
  renderDateFilter() {
    const { slaDateFilters, handleDateFilterChange, shouldHideCustomDateRange } = this.props;
     const dateProps = {
      dateRange: _.get(slaDateFilters, 'dateRange', getDefaultDateRange(relativeDateFilterEntryDefaultState)),
      dateType: _.get(slaDateFilters, 'dateType', configuredDefaultDateType),
      relativeDateFilterEntry: _.get(
        slaDateFilters,
        'relativeDateFilterEntry',
        relativeDateFilterEntryDefaultState)
    };
    let titleText = getGenericDatePickerLabel();

    return(
      <DateRangeFilterWithBadge
        {...dateProps}
        shouldHideCustomDateRange={shouldHideCustomDateRange}
        filterName={titleText}
        onDateFilterChange={handleDateFilterChange} />
    )
  }

  render(){
    return(
      <>
        {this.renderDateFilter()}
      </>
    )
  }
}

DateFilter.propTypes = {
  slaDateFilters: PropTypes.object,
  handleDateFilterChange: PropTypes.func,
  shouldHideCustomDateRange: PropTypes.bool
};

export default DateFilter;
