import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ForgeIcon, ForgePopup, ForgeList, ForgeListItem } from '@tylertech/forge-react';
import { PopupAnimationType } from '@tylertech/forge';

import Dropdown from 'react-bootstrap/Dropdown';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import LoadingSpinner from '../LoadingSpinner';
import { isPrivate } from 'common/config/customerConfiguration';
import { getCollectionTagId } from 'helpers/collectionIdHelper';

const CustomDropDown = (props) => {
  const {
    id, title, options, groupByColumn, selectedField, selectedFieldKey, displayNameField,
    onSelect, onSearchInputChange, isRadarMetric, collectionsWithMetrics, onMetricSelect,
    isMetricsLoading
  } = props;
  const placement = "bottom-start", animationType = PopupAnimationType.Menu, manageFocus = true;
  const targetPopupRef = useRef();
  const isPublicApp = !isPrivate();
  const [value, setValue] = useState('');
  const [showContent, setShowContent] = useState(false);
  const groupedByColumnEntries = _.groupBy(options, groupByColumn);
  const analysisPageCollectionId = useSelector(state =>
    _.get(state, 'dashboard.analysisPageCollectionId', null));
  const currentCardId = useSelector(state => _.get(state, 'dashboard.currentCardId', null));
  const currentUser = useSelector(state => _.get(state, 'currentUser', {}));
  const currentCollectionTagId = useSelector(state =>
    _.get(state, 'dashboard.analysisPageCollectionTagId', null));
  const currentDrilldownTemplateId = useSelector(state =>
    _.get(state, 'drilldown.currentDrilldownTemplateId', null));

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  const handleClickOutside = (e) => {
    if (targetPopupRef && targetPopupRef.current && !targetPopupRef.current.contains(e.target)) {
      setShowContent(false);
    }
  }

  const onInputChange = (e) => {
    const value = e.target.value;
    setValue(e.target.value);
    onSearchInputChange(value)
  }

  const renderSearchInput = () => {
    return (
      <>
      <div className='spinner-container'>
      <LoadingSpinner isLoading={isMetricsLoading} className='input-loading-spinner'/>
      </div>
      <input
        style={{ border: 'none' }}
        placeholder="Search metrics"
        className="form-control sticky-top"
        value={value}
        onChange={onInputChange}
      />
      </>
    );
  }

  const onEnterListItem = (e, entry) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      setValue('');
      onSearchInputChange('');
      setShowContent(!showContent);
      onSelect(entry);
    }
  }

  const onEnterMetricListItem = (e, entry) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      setValue('');
      onSearchInputChange('');
      setShowContent(!showContent);
      onMetricSelect(entry);
    }
  }

  const renderListItem = (entry, index) => {
    const name = entry[displayNameField];
    const iconClassName = _.get(entry, selectedFieldKey) === selectedField ? 'check' : '';

    return (
      <ForgeListItem
        key={index}
        onClick={() => {
          setValue('');
          onSearchInputChange('');
          setShowContent(!showContent);
          onSelect(entry)
        }
        }
        onkeydown={(e) => onEnterListItem(e, entry)}
      >
        <ForgeIcon slot="leading" className="forge-icon-class" name={iconClassName}></ForgeIcon>
        <span slot="title" className="forge-list-line-clamp">{name}</span>
      </ForgeListItem>
    );
  }

  const renderCollectionWiseCardListItem = (entry, index) => {
    const cardName = _.get(entry, 'name', '');
    const selectedCardId = _.get(entry, 'card_id', null);
    const selectedCollectionId = _.get(entry, 'collection_id', null);
    const selectedCollectionTagId = _.get(entry, 'collection_tag_id', 'allMetrics');
    const collectionTagId =
      getCollectionTagId(collectionsWithMetrics,currentDrilldownTemplateId,currentCardId);
    const isCardIdEqual = _.isEqual(selectedCardId, currentCardId)
    const isCollectionIdEqual = _.isEqual(selectedCollectionId, analysisPageCollectionId);
    const isCollectionTagIdEqual = _.isEqual(selectedCollectionTagId,
      (currentCollectionTagId || collectionTagId));
    const iconClassName = (isCardIdEqual &&
      ( (isPublicApp && (currentUser=="null")) ? isCollectionTagIdEqual : isCollectionIdEqual))
                          ? 'check' : '';

    return (
      <ForgeListItem
        key={index}
        onClick={() => {
          setValue('');
          onSearchInputChange('');
          setShowContent(!showContent);
          onMetricSelect(entry)
        }
        }
        onkeydown={(e) => onEnterMetricListItem(e, entry)}
      >
        <ForgeIcon slot="leading" className="forge-icon-class" name={iconClassName}></ForgeIcon>
        <span slot="title" className="forge-list-line-clamp">{cardName}</span>
      </ForgeListItem>
    );
  }

  const renderListOption = (columnEntries) => {
    const categoryName = _.get(_.first(columnEntries), 'templateName');

    return (
      <div className='title-and-list-container forge-list-custom'>
        <div className="metric-title ml-2 mt-2">{categoryName}</div>
        <ForgeList className="forgelist">
          {_.map(columnEntries, renderListItem)}
        </ForgeList>
        <Dropdown.Divider />
      </div>
    );
  }

  const renderCollectionWiseMetricListOption = (columnEntries, index) => {
    const categoryName = _.get(columnEntries, 'name', '');
    const cardList = _.get(columnEntries, 'cards', [])
    return (
      <div className='title-and-list-container forge-list-custom' key={index}>
        <div className="metric-title ml-2 mt-2">{categoryName}</div>
        <ForgeList className="forgelist">
          {_.map(cardList, renderCollectionWiseCardListItem)}
        </ForgeList>
        <Dropdown.Divider />
      </div>
    );
  }

  const renderBodyContent = () => {
    return (
      <div className="dropdown-option-container">
        {renderSearchInput()}
        <Dropdown.Divider />
        {_.map(groupedByColumnEntries, renderListOption)}
      </div>
    );
  }

  const renderCollectionBodyContent = () => {
    const collectionsWithCards = collectionsWithMetrics.filter(metric => metric.cards.length > 0)
    return (
      <div className="dropdown-option-container">
        {renderSearchInput()}
        <Dropdown.Divider />
        {_.map(collectionsWithCards, renderCollectionWiseMetricListOption)}
      </div>
    );
  }

  const onKeydownCustomDropdown = (e) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      setShowContent(!showContent)
    }
  }

  return (
    <div ref={targetPopupRef} className='forge-custom-dropdown forge-popup-host' id={"dropdown-custom" + id}>
      <div
        className="dropdown-container color-theme-primary cursor-pointer d-flex"
        onClick={() => setShowContent(!showContent)}
        onKeyDown={(e) => onKeydownCustomDropdown(e)}
        tabIndex={0}
        title={title}>
        <div className="dropdown-placeholder forge-typography--body1">{title}</div>
        <div className="dropdown-caret icons-chevron-down"></div>
      </div>

      <div>
        <ForgePopup
          style={{ maxHeight: "100px" }}
          open={showContent}
          targetElementRef={targetPopupRef}
          options={{ placement, manageFocus, animationType }}>

          {isRadarMetric ? renderBodyContent() : renderCollectionBodyContent()}
        </ForgePopup>
      </div>
    </div>
  );
}

CustomDropDown.propTypes = {
  className: PropTypes.string,
  groupByColumn: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array,
  selectedField: PropTypes.string.isRequired,
  size: PropTypes.string,
  title: PropTypes.node,
  variant: PropTypes.string,
  onSelect: PropTypes.func,
  onSearchInputChange: PropTypes.func,
  selectedFieldKey: PropTypes.string,
  displayNameField: PropTypes.string,
  isRadarMetric: PropTypes.bool,
  collectionsWithMetrics: PropTypes.array,
  onMetricSelect: PropTypes.func,
  isMetricsLoading: PropTypes.bool,
};

CustomDropDown.defaultProps = {
  id: '1',
  variant: 'link',
  onSelect: _.noop,
  onSearchInputChange: _.noop,
  isRadarMetric: false,
  onMetricSelect: _.noop,
  displayNameField: 'name',
  isMetricsLoading: false
};

export default CustomDropDown;