import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeIconButton, ForgeIcon, ForgeButton, ForgeCheckbox, ForgeDivider } from '@tylertech/forge-react';
import { FORECASTING_TYPE, FORECAST_MODEL_TYPES } from 'appConstants';
import {
  historicalAverageModelLabel,
  exponentialModelLabel,
  exponentialModelDescription,
  prophetDocsLink,
  prophetModelDescription,
  prophetModelLabel
} from 'common/config/customerConfiguration';
import { historicalAverageModelDescription } from 'common/config/customerConfiguration';
import WarningInfo from './WarningInfo';
import {
  getValidFormattedData,
  getForecastFormattedData,
  isModalAvailable
} from '../ForecastHelper';
import { getValidChartValue } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import ProphetBadge from '../ProphetBadge';

const ForecastingModel = (props) => {
  const { onUpdateForecastOptions, forecastTypeModels, buttonTitle, openForecastingConceptPage,
    forecastAttributeOptions, defaultGranularityFormattedData } = props;
  const { selectedForecastTemplateId } = forecastAttributeOptions;
  const forecasting = useSelector(state => _.get(state, 'forecasting', {}));
  const { selectedForecastMetric } = forecasting;
  const prophetRef = useRef();
  const historicRef = useRef();
  const exponentialRef = useRef();
  const isShowHistoricProjection = isModalAvailable(
    selectedForecastTemplateId, selectedForecastMetric['view_id'], FORECASTING_TYPE.HISTORICAL_AVG);
  const isShowProphetProjection = isModalAvailable(
    selectedForecastTemplateId, selectedForecastMetric['view_id'], FORECASTING_TYPE.PROPHET);
  const isShowExponentialProjection = isModalAvailable(
    selectedForecastTemplateId, selectedForecastMetric['view_id'], FORECASTING_TYPE.SIMPLE_EXPONENTIAL);
  const initialProphetType = !_.isEmpty(_.find(forecastTypeModels, { type: FORECASTING_TYPE.PROPHET }));
  const initialHistoricType = !_.isEmpty(_.find(forecastTypeModels,
    { type: FORECASTING_TYPE.HISTORICAL_AVG }));
  const initialExponentialType = !_.isEmpty(_.find(forecastTypeModels,
    { type: FORECASTING_TYPE.SIMPLE_EXPONENTIAL }));

  const [showModel, setShowModel] = useState(false);
  const [prophetCheck, setProphetCheck] = useState(initialProphetType);
  const [historicalCheck, setHistoricalCheck] = useState(initialHistoricType);
  const [exponentialCheck, setExponentialCheck] = useState(initialExponentialType);

  useEffect(() => {
    const isProphetType = !_.isEmpty(_.find(forecastTypeModels, { type: FORECASTING_TYPE.PROPHET }));
    const isHistoricType = !_.isEmpty(_.find(forecastTypeModels,
      { type: FORECASTING_TYPE.HISTORICAL_AVG }));
    const isExponentialType = !_.isEmpty(_.find(forecastTypeModels,
      { type: FORECASTING_TYPE.SIMPLE_EXPONENTIAL }));
    setProphetCheck(isProphetType);
    setHistoricalCheck(isHistoricType);
    setExponentialCheck(isExponentialType);
  }, [forecastTypeModels]);

  const renderInfoPageLink = (scrollId, isDisabled = false) => {
    return (
      <ForgeButton type="outlined">
        <button disabled={isDisabled} onClick={() => openForecastingConceptPage(scrollId)}>
          <ForgeIcon slot="end" name="open_in_new"></ForgeIcon>
          Learn more
        </button>
      </ForgeButton>
    )
  }

  const handleKeyDownConfirm = (e, isShow) => {
    if (isEnterButtonPressed(e)) {
      onShowConfirm(e, isShow);
    }
  };

  const handleKeyDownConfirmModel = (e) => {
    if (isEnterButtonPressed(e)) {
      updateSelectedModels();
    }
  };

  const handleKeyDownAddModel = (event) => {
    if (isEnterButtonPressed(event)) {
      onShowConfirm(event, true)
    }
  }

  const onShowConfirm = (event, isShow) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setShowModel(isShow);
  }

  const updateSelectedModels = () => {
    const selectedModelTypes = getSelectedModelTypes();

    let modelForecastTypes = forecastTypeModels;
    _.forEach(selectedModelTypes, (datum) => {
      const findModel = _.find(forecastTypeModels, { type: datum.type });

      if (_.isEmpty(findModel)) {
        modelForecastTypes.push(datum);
      } else {
        modelForecastTypes.push(findModel);
      }
    });
    onUpdateForecastOptions(_.uniq(modelForecastTypes));
    setShowModel(false);
  }

  const getSelectedModelTypes = () => {
    let selectedModelTypes = [];
    if (prophetCheck) {
      const modelType = _.find(FORECAST_MODEL_TYPES, { type: FORECASTING_TYPE.PROPHET });
      selectedModelTypes.push(modelType);
    }

    if (historicalCheck) {
      const modelType = _.cloneDeep(_.find(FORECAST_MODEL_TYPES, { type: FORECASTING_TYPE.HISTORICAL_AVG }));
      selectedModelTypes.push(modelType);
    }

    if (exponentialCheck) {
      const modelType = _.cloneDeep(_.find(FORECAST_MODEL_TYPES,
        { type: FORECASTING_TYPE.SIMPLE_EXPONENTIAL }));
      selectedModelTypes.push(modelType);
    }

    return selectedModelTypes;
  }

  const renderExponential = () => {
    if (!isShowExponentialProjection) {
      return null;
    }
    return (
      <div>
        <div className="d-flex align-items-baseline">

          <div className='prophet-badge-head-container'>
            <div className='prophet-badge-head mb-2'>
              <div className='d-flex align-items-center'>

                <ForgeCheckbox className="mx-4 forge-checkbox"
                  onClick={() => setExponentialCheck(!exponentialCheck)}>
                  <input ref={exponentialRef} type="checkbox"
                    checked={exponentialCheck} id="checkbox-field"
                    value={exponentialCheck} />
                </ForgeCheckbox>
                <div className="forge-typography--headline6">
                  {exponentialModelLabel}
                </div>
              </div>
              {renderInfoPageLink('exponential')}
            </div>
            <div className='forge-typography--body2 mb-3'>{exponentialModelDescription}</div>
          </div>
        </div>
        <ForgeDivider className="my-2"></ForgeDivider>
      </div>
    )
  }

  const renderProphet = () => {
    const prophetLink = `<a href=${prophetDocsLink}
      id="prophet-link" target="_blank" rel="noreferrer"> here</a>`
    const prophetDescription = _.replace(prophetModelDescription, '{here}', prophetLink);
    if (!isShowProphetProjection) {
      return null;
    }
    return (
      <div>
        <div className="d-flex align-items-baseline">

          <div className='prophet-badge-head-container'>
            <div className='prophet-badge-head mb-2'>
              <div className='d-flex align-items-center'>

                <ForgeCheckbox className="mx-4 forge-checkbox" onClick={() => setProphetCheck(!prophetCheck)}>
                  <input ref={prophetRef} type="checkbox"
                    checked={prophetCheck} id="checkbox-field"
                    value={prophetCheck} />
                </ForgeCheckbox>
                <div className="forge-typography--headline6">{prophetModelLabel} </div>

              </div>
              <div className='prophet-badge-head-link'>
                <ProphetBadge />
                {renderInfoPageLink('prophet')}
              </div>
            </div>

            <div className='forge-typography--body2 mb-3'
              dangerouslySetInnerHTML={{ __html: prophetDescription }}></div>
          </div>
        </div>
        <ForgeDivider className="my-2"></ForgeDivider>
      </div>
    )
  }

  const checkHistoricProjection = () => {
    // Need to move into helper function.
    // used in 3 places
    const { currentForecastDateRange } = forecastAttributeOptions;
    const forecastFormattedData = getForecastFormattedData(
      defaultGranularityFormattedData, forecastAttributeOptions
    );
    const validDataOptions = {
      formattedData: forecastFormattedData,
      ...forecastAttributeOptions
    }

    const formattedData = getValidFormattedData(validDataOptions);
    const isFoundGapValue = _.some(formattedData, (datum) => {
      const value = getValidChartValue(datum);
      const adjustValue = Number(_.get(datum, 'adjustValue', 0));

      return (value == 0 && adjustValue == 0)
    });
    const { dateRange } = currentForecastDateRange;
    const startDate = moment(dateRange['startDate']);
    const endDate = moment(dateRange['endDate']);
    const diffDays = endDate.diff(startDate, 'days');
    const isOneYear = diffDays > 365;
    return (isFoundGapValue || !isOneYear);
  }

  const renderHistoricAverage = () => {
    const isDisableHistoricProjection = checkHistoricProjection();
    const discriptionClassNames = classNames(
      { "disabled": isDisableHistoricProjection }
    );

    if (!isShowHistoricProjection) {
      return null;
    }

    return (
      <div>
        <div className="d-flex align-items-baseline">

          <div className={`${discriptionClassNames} prophet-badge-head-container`}>
            <div className='prophet-badge-head mb-2'>
              <div className='d-flex align-items-center'>
                <ForgeCheckbox className="mx-4 forge-checkbox">
                  <input ref={historicRef} type="checkbox"
                    checked={historicalCheck} id="checkbox-field"
                    onClick={() => setHistoricalCheck(!historicalCheck)}
                    disabled={isDisableHistoricProjection}
                    value={historicalCheck} />
                </ForgeCheckbox>
                <div className="forge-typography--headline6">
                  {historicalAverageModelLabel}
                </div>
              </div>
              {renderInfoPageLink('historical', isDisableHistoricProjection)}
            </div>
            <div className='forge-typography--body2 mb-3'>{historicalAverageModelDescription}</div>
            <WarningInfo
              defaultGranularityFormattedData={defaultGranularityFormattedData}
              forecastAttributeOptions={forecastAttributeOptions}
            />
          </div>


        </div>
        <ForgeDivider className="my-2"></ForgeDivider>
      </div>
    )
  }


  const renderForecastModal = () => {

    if (!showModel) {
      return;
    }

    return (
      <Modal
        onHide={(event) => onShowConfirm(event, false)}
        show={true}
        className='forcasting-modal'
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-wrapper">
          <Modal.Header className="align-items-center">
            <Modal.Title>
              <div className="forge-typography--headline5">Add forecasting model(s)</div>
            </Modal.Title>
            <ForgeIconButton dense="true" densityLevel="5">
              <button
                aria-label="Close"
                tabIndex={0}
                onKeyDown={(event) => handleKeyDownConfirm(event, false)}
                onClick={(event) => onShowConfirm(event, false)}
              >
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex flex-column gap-15 forecasting-list'>
              <div> {renderHistoricAverage()} </div>
              <div> {renderExponential()} </div>
              <div> {renderProphet()} </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <ForgeButton className="mr-auto">
              <button
                aria-label="Cancel"
                tabIndex={0}
                onClick={(event) => onShowConfirm(event, false)}
                onKeyDown={(event) => handleKeyDownConfirm(event, false)} >
                Cancel
              </button>
            </ForgeButton>

            <ForgeButton type="raised">
              <button disabled={!prophetCheck && !historicalCheck && !exponentialCheck}
                aria-label="Confirm"
                tabIndex={0}
                onClick={(event) => updateSelectedModels(event)}
                onKeyDown={handleKeyDownConfirmModel} >
                Run selected models
              </button>
            </ForgeButton>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }

  const renderAddModelButton = () => {
    // Enable this when we have more than 2 models.
    // const addButtonDisable = forecastTypeModels.length === FORECAST_MODEL_TYPES.length;

    return (
      <ForgeButton type="raised">
        <button
          tabIndex={0}
          onClick={(event) => onShowConfirm(event, true)}
          onKeyDown={(event) => handleKeyDownAddModel(event)}>
          {buttonTitle}
        </button>
      </ForgeButton>
    )
  }


  return (
    <>
      {renderForecastModal()}
      {renderAddModelButton()}
    </>
  );
}

ForecastingModel.propTypes = {
  forecastTypeModels: PropTypes.array,
  buttonTitle: PropTypes.string,
  onUpdateForecastOptions: PropTypes.func,
  forecastAttributeOptions: PropTypes.object,
  disableHistoricProjection: PropTypes.bool,
  defaultGranularityFormattedData: PropTypes.object,
  openForecastingConceptPage: PropTypes.func
}

export default ForecastingModel;
