import moment from 'moment';
import {
  getWeekdaysToNumber,
  isStartDateCrossed
 } from 'helpers/frequencyDateTimeHelper';
import { DATE_FORMAT } from 'appConstants';

export const getMonthlyDateTime = (scheduleOptions, scheduledDateTime) => {
  // scheduledDateTime is timeZone specific date format

  const orderedSelectedDays = getWeekdaysToNumber(scheduleOptions);
  const frequencyInterval = Number(scheduleOptions['frequencyInterval']);
  const startDate = moment(scheduleOptions['startDate']).format(DATE_FORMAT);
  const weekNumber = Number(scheduleOptions['monthlyWeekNumber']);
  const isTimePeriodType  = scheduleOptions['regularityType'] === 'time_period';
  const timePeriodDay = Number(scheduleOptions['timePeriodDay']);
  const startDateDay = moment(scheduleOptions['startDate'], DATE_FORMAT).get('date');
  let nextFrequencyMonth;

  if(isTimePeriodType){
    if(timePeriodDay > startDateDay){
      if(frequencyInterval == 1 && timePeriodDay > moment(scheduledDateTime).date()){
        nextFrequencyMonth = moment(startDate).utc().format();
      }else{
        nextFrequencyMonth = moment(startDate).add(frequencyInterval, 'months').utc().format();
      }
    }else{
      nextFrequencyMonth = moment(startDate).add(frequencyInterval, 'months').utc().format();
    }

    const monthNumber = moment(nextFrequencyMonth).get('month');
    let calculatedTimePeriodDay = timePeriodDay;
    if(monthNumber == 1){
      // Feb month
      calculatedTimePeriodDay = timePeriodDay >= 28 ? 28 : timePeriodDay;
    }else if(_.includes([3,5,8,10], monthNumber)){
      // 30 days months
      calculatedTimePeriodDay = timePeriodDay >= 30 ? 30 : timePeriodDay;
    }
    return moment(nextFrequencyMonth).set('date', calculatedTimePeriodDay);
  }else{
    let emailSendingDate = scheduledDateTime;
    if(frequencyInterval > 1){
      emailSendingDate = setNextFrequencyMonthDate(startDate, weekNumber,
        orderedSelectedDays, startDate, frequencyInterval
      );
    }else{
      const year = Number(moment(startDate).format('YYYY'));
      const month = Number(moment(startDate).format('MM'));
      emailSendingDate = getNthWeekday(year, month, weekNumber, orderedSelectedDays[0]);
    }

    if(isStartDateCrossed(scheduleOptions, emailSendingDate)){
      return emailSendingDate;
    }else{
      return setNextFrequencyMonthDate(startDate, weekNumber,
        orderedSelectedDays, scheduledDateTime, frequencyInterval
      );
    }
  }
}

function setNextFrequencyMonthDate(today, weekNumber, selectedDays, scheduledDateTime, frequencyInterval){
  const nextFrequencyMonth = moment(today).add(frequencyInterval, 'months').startOf('day').format()
  const year = Number(moment(nextFrequencyMonth).format('YYYY'));
  const month = Number(moment(nextFrequencyMonth).format('MM')),
        nthWeekDate = getNthWeekday(year, month, weekNumber, selectedDays[0]),
        noOfDaysToBeAdded = moment(nthWeekDate).diff(today, 'days');

  return moment(scheduledDateTime).add(noOfDaysToBeAdded, 'days').startOf('day').format();
}

function getNthWeekday(year, month, week, day){
  // Will return given week's date
  // Ex: year=>2021, month => 3, week => 4, day => 1
  // returned as Mon, 22 Mar 2021,
  // March 4th week's first day date is 22nd
  const monthFirstDate = new Date(year, (month - 1), 1),
      firstWeekday = moment(monthFirstDate).isoWeekday(),
      offset = firstWeekday - (day % 7);
  // If month start date is lesser then current day
  // will get the previous week date
  const calculatedWeek = day < firstWeekday ? week : week - 1;
  const calculateDays = ((calculatedWeek) * 7) - offset;
  return moment(monthFirstDate).add(calculateDays, 'days').startOf('day').format(DATE_FORMAT);
}
