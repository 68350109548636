import {
  OVERTIME_TIME_CHART_HEIGHT
} from 'appConstants';
import { prefixText, suffixText } from 'helpers/chartDataHelper';

export const getPlotlyLayout = (lineTraces, options) => {
  return{
    autosize: true,
    height: _.get(options, 'isSmallView', true) ? OVERTIME_TIME_CHART_HEIGHT : '',
    hovermode: 'x',
    margin: { l: 10, r: 10, t: 10, b: 50, pad: 10 },
    showlegend: false,
    dragmode: false,
    // bargroupgap: isComboChart ? 0.2 : 'dont set'; TODO
    xaxis: {
      automargin: false,
      tick0: _.get(lineTraces, '[0].x[0]'),
      dtick: 'M1',
      tickformat: "%b %Y",
      // To prevent display all years in we passed range.
      range: options.sliderRange,
      showticklabels: true, // TODO: forced
      tickangle: 'auto',
      rangeslider: {
        visible: false
      },
      tickfont: {
        size: 'auto'
      }
    },
    yaxis: {
      automargin: true,
      tickprefix: prefixText(options.legacyChartOptions),
      ticksuffix: `${suffixText(options.legacyChartOptions)}`,
      tickfont: {
        size: 'auto'
      }
    }
  }
};