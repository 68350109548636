import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';

import NotificationListContainer from 'common/components/Notifications/NotificationListContainer';
import NotificationLabelAndCount from 'common/components/Notifications/NotificationLabelAndCount';
import NotificationManageAllButton from 'common/components/Notifications/NotificationManageAllButton';

import LoadingSpinner from 'common/components/LoadingSpinner';
import OutsideClickHandler from 'common/components/OutsideClickHandler';

const NotificationPanel = (props) => {
  const { onSetOpen, subscriptionBookmarks, isLoading } = props;

  const shouldClickOutSide = (event) => {
    const isClickWithInNotificationIcon = $(event.target).closest('.notification-bell').length > 0;

    return !isClickWithInNotificationIcon;
  }

  const renderNotificationPanel = () => {
    const lastBokmarkId = _.get(_.last(subscriptionBookmarks), 'id', '');

    return(
      <>
        <NotificationLabelAndCount count={_.size(subscriptionBookmarks)}/>
        <NotificationListContainer bookmarks={subscriptionBookmarks} onSetOpen={onSetOpen} />
        <NotificationManageAllButton id={lastBokmarkId} onSetOpen={onSetOpen} />
      </>
    );
  }

  return (
    <OutsideClickHandler onClickOutSide={onSetOpen} shouldClickOutSide={shouldClickOutSide}>
      <div className="notification-panel">
        <div className="notification-wrapper">
          {isLoading && <LoadingSpinner isLoading={isLoading} />}
          {!isLoading && renderNotificationPanel()}
        </div>
      </div>
    </OutsideClickHandler>
  );
}

NotificationPanel.propTypes = {
  isLoading: PropTypes.bool,
  onSetOpen: PropTypes.func,
  subscriptionBookmarks: PropTypes.array
}

NotificationPanel.defaultProps = {
  isLoading: false,
  onSetOpen: _.noop,
  subscriptionBookmarks: []
}

export default NotificationPanel;
