import pluralize from 'pluralize';

import {
  getSLAWatchAllChecks,
  getSummarizeByField,
  getSelectedTemplateForSLAWatch,
  slaHeatChartBuckets,
  getSLAWatchFlyoutEntries,
  getSLAWatchCalculationType
} from 'common/config/customerConfiguration';
import { getDimensionName } from 'helpers/displayNameHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { SLA_CALCULATION_TYPES, SLA_WATCH_FILTER_OPTIONS, HEAT_CHART_BACKGROUND_COLORS } from 'appConstants';

export const showSlaWatch = () => {
  return getSLAWatchAllChecks().length > 0;
}

export const getApiParamsForChart = (params) => {
  const {
    dateRange,
    dimensionField,
    summarizeByValue,
    globalFilters
  } = params;
  return {
    drilldownEntry: JSON.stringify({
      currentDrilldownTemplateId: getSelectedTemplateForSLAWatch(),
    }),
    commonFilters: JSON.stringify({
      dateRange,
      globalFilters
    }),
    dimensionField,
    summarizeByField: getSummarizeByField(),
    summarizeByValue: summarizeByValue || ''
  }
}

export const isSummarizeConfigured = () => {
  return !_.isEmpty(getSummarizeByField())
}

export const getDimensionList = () => {
  const templateId = getSelectedTemplateForSLAWatch();
  const allChecks = getSLAWatchAllChecks();
  let dimensionList = [];
  _.each(allChecks, (check) => {
    _.each(check['dimensions'], (dimension) => {
      const { field } = dimension;
      const existDimensionIndex = _.findIndex(dimensionList, function(datum) {
              return datum['field'] == field;
            });

      if(existDimensionIndex < 0){
        const dimensionTab = {
          name : getDimensionName(templateId, field),
          field
        }
        dimensionList.push(dimensionTab);
      }
    });
  });
  return dimensionList;
}

export const formatTotalResult = (data) => {
  const nullValueLabel = getNullValueLabel(getSelectedTemplateForSLAWatch());
  return _.map(data, (dimension) => {
    if(isSummarizeConfigured()){
      dimension['name'] = dimension['dimension'] || nullValueLabel;
    } else {
      const name = getDimensionName(getSelectedTemplateForSLAWatch(), dimension['dimension']);
      dimension['name'] = name ;
    }
    return dimension;
  });
}

export const getHeatChartConditionHeaders = (selectedDimension, selectedListItem) => {
  const allChecks = getSLAWatchAllChecks();
  let headers = [];
  const selectedField = isSummarizeConfigured() ?
    _.get(selectedDimension, 'field') :
    _.get(selectedListItem, 'dimension');
  _.each(allChecks, (check) => {
    const matchedDimension = _.find(check['dimensions'], (entry) => {
      return (entry['field'] == selectedField);
    });

    if(getSLAWatchCalculationType() == SLA_CALCULATION_TYPES.PERCENTAGE && !_.isEmpty(matchedDimension)){
      _.each(check['conditions'], (condition) => {
        headers.push(condition);
      });
    }
  });
  return headers;
}

export const getConditionCellEntry = (value, condition) => {
  const bucketRanges = slaHeatChartBuckets();
  let distance = getSlaDistance(value, condition);

  const cellEntry = {
    'tick-color': '#fff',
    'background-color': _.last(HEAT_CHART_BACKGROUND_COLORS)
  };
  _.each(bucketRanges, (bucket, index) => {
    if(distance <= Number(bucket)){
      cellEntry['tick-color'] = (index <= 1 ? '#B00020' : '#fff');
      cellEntry['background-color'] = HEAT_CHART_BACKGROUND_COLORS[index];
      return false;
    }
  });
  return cellEntry;
}

export const getSlaDistance = (value, condition) => {
  let distance = (Number(value) - Number(condition['to'] || 0));
  if(Number(value) <= Number(condition['from'] || 0)){
    distance = (Number(condition['from'] || 0) - Number(value));
  }
  return distance.toFixed(2);
}

export const getFlyoutEntries = (chartData) => {
  return _.compact(_.map(getSLAWatchFlyoutEntries(), (entry) => {
    if(entry['show'] == 'true'){
      return getCumulativeFlyoutEntry(entry, chartData);
    }
  }));
}

const getCumulativeFlyoutEntry = (entry, chartData) => {
  if(entry['id'] == 'actual_percentage'){
    return {name: entry['name'], value: `${chartData['value']}%`}
  } else if(entry['id'] == 'target_percentage'){
    return {name: entry['name'], value: `${chartData['target']}%`}
  } else {
    const targetPercentage = Number(chartData['target']);
    const totalCount = _.ceil(chartData['dimension_count'] * (targetPercentage/100));
    let slaDistance = `${Math.abs(_.ceil(chartData['count'] - totalCount))} ${chartData['suffix']}`;

    if(chartData['isConditionPassed']){
      slaDistance = 'NA'
    }
    return {name: entry['name'], value: slaDistance}
  }
}

export const getBulletChartSlaDistance = (chartData) => {
  const targetPercentage = Number(chartData['target']);
  const totalCount = _.ceil(chartData['dimension_count'] * (targetPercentage/100));
  const slaDistance = _.ceil(chartData['count'] - totalCount);
  return slaDistance >= 0 ? `+${slaDistance}` : slaDistance;
}

export const getSLAFilterOptions = (selectedDimension, selectedListItem) => {
  const dimension = getSelectedDimensionName(selectedDimension, selectedListItem);
  const pluralizedDimension = pluralize(dimension);
  return _.map(_.cloneDeep(SLA_WATCH_FILTER_OPTIONS), (option) => {
    const dimensionName = option['type'] == 'all' ?
    _.lowerCase(pluralizedDimension) : _.capitalize(pluralizedDimension);
    option['name'] = _.replace(option['name'],'SELECTED_DIMENSION', dimensionName);
    return option;
  });
}

export const sortHeatChartEntries = (entries, sortBy) => {
  if(_.isEmpty(entries)){
    return entries;
  }

  if(sortBy == 'most_failed_check'){
    return _.sortBy(entries, -['noOfFail']);
  }else if(sortBy == 'alphabetically'){
    return _.sortBy(entries, ['dimension']);
  }else {
    return _.sortBy(entries, [function(e) { return -Number(e[sortBy]); }]);
  }
}

export const filterChartData = (entries, filterBy) => {
  if(filterBy == _.first(SLA_WATCH_FILTER_OPTIONS)['type']){
    return _.filter(entries, (data) => {
      return data['noOfFail'] >= 1;
    });
  } else if(filterBy == SLA_WATCH_FILTER_OPTIONS[1]['type']){
     return _.filter(entries, (data) => {
      return data['noOfPass'] == 0;
    });
  } else {
    return entries;
  }
}

export const getSelectedDimensionName = (selectedDimension, selectedListItem) => {
  const selectedField = isSummarizeConfigured() ?
    _.get(selectedDimension, 'name') :
    _.get(selectedListItem, 'name');

  return selectedField;
}