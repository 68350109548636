export const getCollectionId = (collectionsWithCards, cardId) => {
  const collection = _.find(collectionsWithCards, (collection) =>
    _.some(collection.cards, { card_id: cardId })
  );
  return collection ? collection.id : 'allMetrics';
}

export const getCollectionTagId = (collectionsWithCards, templateId, cardId) => {
  const collection = _.find(collectionsWithCards, (collection) =>
    _.some(collection.cards, { template_id: templateId, card_id: cardId})
  );
  const currentCardEntry = _.find(collection?.cards,(card)=>
  card.template_id === templateId
  )
  return currentCardEntry ? currentCardEntry.collection_tag_id : 'allMetrics';
}