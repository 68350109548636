import React, { PureComponent } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import ConfirmationDialogue from 'modules/Administration/ConfirmationDialogue';
import LoadingSpinner from 'common/components/LoadingSpinner';

import { createEmbedBookmark } from 'common/api/bookmarksApi';
import { embedCopyMessage, embedCopyErrorMessage } from 'helpers/toastMessages';
import { getLink } from 'pages/embed/helper';

class EmbedBookmark extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false
    };
    this.abortController = new AbortController();
  }

  createEmbedLink = () => {
    const { bookmark, onModalClose } = this.props;
    this.abortController.abort();
    this.abortController = new AbortController();
    this.setState({ loading: true});
    createEmbedBookmark(bookmark)
      .then((response) => response.json())
      .then((response) => {
        const id = _.get(response, 'id');
        const url =  window.location.origin + 
          `/embed?is_bookmark_card=true&is_embed_bookmark_card=true&bookmark_id=${id}`;
        const link = getLink(id, url);
        try {
          const $inputElement = $("<input>");
          $("body").append($inputElement);
          $inputElement.val(link).select();
          document.execCommand('copy');
          $inputElement.remove();
          toast.success(embedCopyMessage);
        } catch (err) {
          toast.error(embedCopyErrorMessage);
        }
        this.setState({ loading: false});
        onModalClose(false);
      }).catch(() => {
        this.setState({ loading: false});
      });
  }

  handleBookmarkChangeConfirm = (isConfirm) => { 
    const { onModalClose } = this.props;
    if(isConfirm){
      this.createEmbedLink();
    }else {
      onModalClose(false);
    }
  }

  renderEmbedConfirmModal = () => {
    const message =`Please note that the embedded version of this view will keep the current settings. `+
      `Any future changes to filters or settings will not appear in the embedded version.`+
      ` Would you like to continue?`;
    return (<ConfirmationDialogue
      message={message}
      messageTitle={'Copy embed link'}
      onConfirmationCallBack = {this.handleBookmarkChangeConfirm} />)
  }

  render(){
    return (
      <div className="bookmark-embed-confirm-modal">
        {this.renderEmbedConfirmModal()}
      <LoadingSpinner isLoading={this.state.loading} />
      </div>
    );
  }
}

EmbedBookmark.propTypes = {
  bookmark: PropTypes.object,
  onModalClose: PropTypes.func
}

EmbedBookmark.defaultProps = {
  bookmark: {}
}

export default EmbedBookmark;
