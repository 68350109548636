import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

// eslint-disable-line no-unused-vars
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import {
  COMPARE_YEAR_TYPES,
  DATE_OPTIONS_TYPE,
  OVERTIME_TIME_FRAME_OPTIONS,
  OVERTIME_VISUALIZATION_TYPES,
  DEFAULT_BENCHMARK_COLOR,
  LAST_PERIOD_COMPARISON_TYPE,
  RELATIVE_DATE_TYPE,
  DATE_COMPARISON_TYPE
} from 'appConstants';
import LegendPicker from './LegendPicker';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { getYearTextByRange } from 'helpers/dateHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import * as commonPropTypes from 'common/propTypes';
import { disableMetricTotal } from 'common/config/viewConfiguration';

class Legend extends Component {
  state = {
    showSelectItems: true
  }

  handleLegendEntriesChange = (newLegendEntries) => {
    this.props.onLegendConfigsChange(newLegendEntries);
  }

  handleLegendItems = (show) => {
    this.setState({ showSelectItems: show });
  }

  handleKeyDown = (e, legendItemEntry) => {
    if (isEnterButtonPressed(e)) {
      this.props.onLegendClick(legendItemEntry)
    }
  }

  isCustomDateType = () => {
    const { dateRangeMode } = this.props;
    return (dateRangeMode === DATE_OPTIONS_TYPE.CUSTOM_RANGE);
  }

  isYearlyDateType = () => {
    const { dateRangeMode } = this.props;
    return (dateRangeMode === DATE_OPTIONS_TYPE.YEARLY);
  }

  isRelativeDateType = () => {
    const { dateRangeMode } = this.props;
    return (dateRangeMode === RELATIVE_DATE_TYPE);
  }


  sortLegendEntries = (selectedLegendEntries) => {
    const {
      dateRange,
      currentSelectedTimeFrame,
      compareYearRanges,
      isDimensionHighToLow
    } = this.props;
    let sortedEntries = _.isEmpty(selectedLegendEntries) ? [] : _.cloneDeep(selectedLegendEntries);
    if (!isDimensionHighToLow) {
      sortedEntries = _.chain(selectedLegendEntries).
        compact().
        sortBy(['dimension']).
        sortBy((e) => { return !_.get(e, 'isTotalLine', false) }).
        value();
    }
    if (shouldDisableDimensions(dateRange, currentSelectedTimeFrame, compareYearRanges)) {
      return _.orderBy(sortedEntries, 'traceId', 'desc');
    } else {
      return sortedEntries;
    }
  }

  renderLegendItems() {
    const {
      legendEntries, renderType, onLegendClick, isCurrencyDimensionField, compareToRanges
    } = this.props;
    const isAreaChart = _.isEqual(renderType, _.get(OVERTIME_VISUALIZATION_TYPES.AREA, 'type', ''));
    const { name: compareName } = _.first(compareToRanges) || {};

    const legendItems = this.sortLegendEntries(legendEntries).map((legendItemEntry) => {
      let {
        color, traceId, visible, areaChartColor, hideTrace, primaryTrace, dimension
      } = legendItemEntry || {};

      if (hideTrace) {
        return null;
      }
      const legendLableClassNames = classNames('legend-label', { 'active': visible });
      const traceColor = isAreaChart ? areaChartColor : color;
      const style = {
        backgroundColor: visible ? traceColor : '',
        borderColor: visible ? traceColor : ''
      };

      if ((
        this.isCustomDateType() ||
        this.isRelativeDateType()) &&
        (compareName === COMPARE_YEAR_TYPES.SAME_LAST_PERIOD ||
          compareName === LAST_PERIOD_COMPARISON_TYPE ||
          compareName === COMPARE_YEAR_TYPES.PRIOR_PERIOD
        )
      ) {
        traceId = primaryTrace ? `${dimension} - (This period)` : `${dimension} - (Previous period)`;
      }
      const newTraceId = formatValueToCurrency(traceId, isCurrencyDimensionField);

      return (
        <div className="over-time-legend-item" key={traceId}>
          <div
            role="button"
            tabIndex={0}
            className="d-flex"
            onClick={() => onLegendClick(legendItemEntry)}
            onKeyDown={(e) => this.handleKeyDown(e, legendItemEntry)}
            aria-label={`Legend item - ${traceId}`}>
            <span className="fake-check-box" style={style}></span>
            <label className={legendLableClassNames}>
              <div className="trace-name forge-typography--body2">{newTraceId}</div>
            </label>
          </div>
        </div>
      );
    });

    return (_.size(legendEntries) > 0 ?
      <div>{legendItems}</div> :
      <div className="trace-name forge-typography--body2">No categories selected</div>
    );
  }

  renderLegends() {
    const { isComboChart, benchMarkEntries } = this.props;

    if (isComboChart && _.isEmpty(benchMarkEntries)) {
      return;
    }

    return (
      <div className="legend-details">
        <div className="legend-info-inner filter-wrapper">
          {this.renderLineChartLegends()}
          {this.renderBenchmarkLegendItems()}
        </div>
      </div>
    );
  }

  renderLineChartLegends() {
    const {
      isSecondaryMetricEnabled,
      isProjectionEnabled,
      isComparisonEnabled,
      viewEntry,
      secondaryMetricEntry,
      compareYearRanges
    } = this.props;


    const primaryMetricName = getPrimaryMetricName(viewEntry);
    const secondaryName = _.get(secondaryMetricEntry, 'name');
    const isMultipleYear = _.size(compareYearRanges) > 1;
    const legendOptions = [{
      name: primaryMetricName,
      isPrimary: true,
      isCompare: isComparisonEnabled && !isMultipleYear,
      image: "dash",
      compareImage: "dash_thin",
      isMultipleYear
    }]

    if (isSecondaryMetricEnabled) {
      legendOptions.push({
        name: secondaryName,
        isPrimary: false,
        isCompare: isComparisonEnabled && !isMultipleYear,
        image: "dash_dot",
        compareImage: "dash_dot_thin",
        isMultipleYear
      })
    }

    const legends = _.map(legendOptions, (legendOption, index) => {
      return this.renderLineChartLegendItems(legendOption, index)
    })

    return (
      <div>
        {isProjectionEnabled && <div className="legend-info-details projected-year">
          <img alt="" src="../images/dot.png" />
          <span className="forge-typography--body2">
            Projection
          </span>
        </div>}
        {legends}
      </div>)
  }

  renderLineChartLegendItems = (legendOption) => {
    const { dateRange, compareToRanges } = this.props;
    const { isCompare } = legendOption;
    const combineDateRanges = _.compact([dateRange, _.first(compareToRanges)]);

    return _.map(combineDateRanges, (combineDateRange, rowIndex) => {
      return (<>
        <div>{rowIndex == 0 &&
          this.renderLegendWithoutComboChart(combineDateRange, legendOption)}</div>
        <div>{rowIndex == 1 && isCompare &&
          this.renderComparisonLegendItems(combineDateRange, legendOption)}</div>
      </>)
    })
  }

  renderLegendWithoutComboChart(combineDateRange, legendOption) {
    const {
      isComboChart,
      isComparisonEnabled,
      dateRangeMode,
      comparisonType
    } = this.props;


    if (isComboChart) {
      return;
    }
    const { name, isPrimary, image, isMultipleYear } = legendOption;

    const isExcludeSuffix =  _.includes([DATE_COMPARISON_TYPE.PRIOR, DATE_COMPARISON_TYPE.CUSTOM], 
      comparisonType);
    const dateRangeText = getYearTextByRange(combineDateRange, dateRangeMode);
    const  currentYearSuffixText = isComparisonEnabled && !isExcludeSuffix && !isMultipleYear ? 
      ` - ${dateRangeText}` : '';

    const imageIcon = `../images/${image}.png`;
    const legendClass = classNames('legend-info-details', {
      'secondary-text': !isPrimary,
    })

    return (
      <div className={legendClass}>
        <img alt="" src={imageIcon} />
        <span className="forge-typography--body2">
          {name + currentYearSuffixText}
        </span>
      </div>
    );
  }

  renderComparisonLegendItems(compareToDateRange, legendOption) {
    const {
      isComparisonEnabled,
      renderType,
      currentSelectedTimeFrame,
      dateRangeMode
    } = this.props;
    const { isPrimary, isCompare, compareImage } = legendOption;
    const legendName = _.get(legendOption, 'name');

    const isAreaChart = _.isEqual(renderType, OVERTIME_VISUALIZATION_TYPES.AREA.type);
    const isYearOnYear = !_.isEqual(currentSelectedTimeFrame, OVERTIME_TIME_FRAME_OPTIONS.ROLLING);

    if (!isComparisonEnabled || isAreaChart || !isYearOnYear) {
      return;
    }

    let dateRangeText = getYearTextByRange(compareToDateRange, dateRangeMode);
    let comparisonMetricLegendText = legendName + ` - ${dateRangeText}`;
    
    const imageIcon = `../images/${compareImage}.png`;
    const legendClass = classNames('legend-info-details previous-year', {
      'secondary-text': !isPrimary && isCompare,
    })

    return (
      <div className={legendClass}>
        <img src={imageIcon} className="thin-img" />
        <span className="forge-typography--body2">
          {comparisonMetricLegendText}
        </span>
      </div>
    );
  }

  renderBenchmarkLegendItems() {
    const { benchMarkEntries } = this.props;

    if (_.isEmpty(benchMarkEntries)) {
      return;
    }

    return _.map(benchMarkEntries, (benchmark, index) => {
      return this.renderBenchmarkLegendItem(benchmark, index)
    });
  }

  renderBenchmarkLegendItem(benchmark, index) {
    const benchmarkName = _.get(benchmark, 'name', '');
    const colorCode = _.get(benchmark, 'color', DEFAULT_BENCHMARK_COLOR) || DEFAULT_BENCHMARK_COLOR;
    const lineType = _.get(benchmark, 'lineType', 'dot');
    return (
      <div key={index}>
        {lineType === 'dot' &&
          <div className="legend-info-details previous-year secondary-text">
            <span className="line-style dot" style={{ color: `${colorCode}` }}>&#8943;&#8943;</span>
            <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
          </div>
        }
        {lineType === 'dashdot' &&
          <div className="legend-info-details previous-year secondary-text">
            <span
              className="line-style dashdot"
              style={{ color: `${colorCode}` }}>&#8722; &#8729; &#8722;</span>
            <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
          </div>
        }
        {lineType === 'longdash' &&
          <div className="legend-info-details previous-year secondary-text">
            <span className="line-style longdash" style={{ color: `${colorCode}` }}>&#9472; &#9472;</span>
            <span className="forge-typography--body2">{benchmarkName}</span>
          </div>
        }
        {lineType === '' &&
          <div className="legend-info-details previous-year secondary-text">
            <span className="line-style longline" style={{ color: `${colorCode}` }}>&#9472;&#9472;</span>
            <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
          </div>
        }
        {lineType === 'dash' &&
          <div className="legend-info-details previous-year secondary-text">
            <span className="line-style" style={{ color: `${colorCode}` }}>&#8722; &#8722; &#8722;</span>
            <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
          </div>
        }
      </div>
    );
  }

  renderLegendPicker() {
    const {
      legendEntries,
      apiParams,
      renderType,
      templateId,
      isComboChart,
      dateRange,
      currentSelectedTimeFrame,
      isCurrencyDimensionField,
      compareYearRanges,
      isDimensionHighToLow,
      viewEntry,
      onUpdateShowLegendTotal
    } = this.props;

    if (isComboChart ||
      shouldDisableDimensions(dateRange, currentSelectedTimeFrame, compareYearRanges)) {
      return (
        <div className="filter-wrapper">
          <div className="legend-title align-self-center forge-typography--body2 mb-2"> Categories</div>
        </div>
      )
    }
    const showMetricTotal = !disableMetricTotal(viewEntry);
    return (
      <LegendPicker
        isCurrencyDimensionField={isCurrencyDimensionField}
        templateId={templateId}
        legendEntries={_.cloneDeep(legendEntries)}
        apiParams={apiParams}
        renderType={renderType}
        onLegendEntriesChange={this.handleLegendEntriesChange}
        onDisplayLegendItems={this.handleLegendItems}
        isDimensionHighToLow={isDimensionHighToLow}
        showMetricTotal={showMetricTotal}
        onUpdateShowLegendTotal={onUpdateShowLegendTotal}
      />
    )
  }

  render() {
    const classNameLegend = this.state.showSelectItems ? "legend-wrapper over-time-legend-container" :
      "legend-wrapper over-time-legend-container mb-0";
    return (
      <div className={classNameLegend}>
        {this.renderLegends()}
        <div className="categories filter-options">
          {this.renderLegendPicker()}
          {this.state.showSelectItems && this.renderLegendItems()}
        </div>
      </div>
    );
  }
}

Legend.propTypes = {
  compareYearRanges: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string
    })),
  dateRange: PropTypes.exact({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  compareToRanges: PropTypes.arrayOf(
    {
      name: PropTypes.string,
      range: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string
      })
    }),
  apiParams: PropTypes.object,
  renderType: PropTypes.oneOf(_.map(OVERTIME_VISUALIZATION_TYPES, 'type')),
  viewEntry: commonPropTypes.viewEntryPropTypes,
  secondaryMetricEntry: commonPropTypes.secondaryMetricEntryPropTypes,
  currentSelectedTimeFrame: PropTypes.oneOf(_.values(OVERTIME_TIME_FRAME_OPTIONS)),
  isComparisonEnabled: PropTypes.bool,
  isProjectionEnabled: PropTypes.bool,
  isSecondaryMetricEnabled: PropTypes.bool,
  legendEntries: PropTypes.array,
  onLegendClick: PropTypes.func,
  onLegendConfigsChange: PropTypes.func,
  isComboChart: PropTypes.bool,
  isCurrencyDimensionField: PropTypes.bool,
  templateId: commonPropTypes.templateIdPropTypes,
  dateRangeMode: PropTypes.string,
  onSecondaryMetricChange: PropTypes.func,
  onBenchMarkChange: PropTypes.func,
  benchMarkEntries: PropTypes.array,
  benchMarkNames: PropTypes.array,
  isDimensionHighToLow: PropTypes.bool,
  comparisonType: PropTypes.any,
  onUpdateShowLegendTotal: PropTypes.func
}

export default Legend;
