import ReactGA from 'react-ga';
import GA4React from 'ga-4-react';
import _ from 'lodash';

import {
  verticalSolutionsGaAccount,
  getCustomerGAAccount,
  gaExecutiveInsightsAccount,
  getGaG4Account
} from 'common/config/customerConfiguration';

const customTrackerPrefix = 'customerTracker';
const verticalSolutionsAppTrackerPrefix = 'verticalSolutionsAppTracker';

const hasExecutiveInsightsAccount = () => {
  return !_.isEmpty(gaExecutiveInsightsAccount);
};

const hasVerticalSolutionsAccount = () => {
  return !_.isEmpty(verticalSolutionsGaAccount);
};

const hasCustomConfig = () => {
  return !_.isEmpty(getCustomerGAAccount());
};

const hasGaG4Config = () => {
  return !_.isEmpty(getGaG4Account());
};
let gaG4;
if(hasGaG4Config()){
  const ga4React = new GA4React(getGaG4Account());
  ga4React.initialize().then((ga4) => {
    gaG4 = ga4;
    gaG4.pageview(window.location.href);
  },(err) => {
    console.error(err);
  });
}

export const initializeReactGA =  () => {
  if (hasExecutiveInsightsAccount()) {
    ReactGA.initialize(gaExecutiveInsightsAccount);
  }
  // also tracking to a customer's configured account
  if (hasCustomConfig()) {
    ReactGA.initialize(getCustomerGAAccount(), {
      gaOptions: {
        'name': customTrackerPrefix
      }
    });
  }
  // also tracking to a all vertical solutions app account
  if (hasVerticalSolutionsAccount()) {
    ReactGA.initialize(verticalSolutionsGaAccount, {
      gaOptions: {
        'name': verticalSolutionsAppTrackerPrefix
      }
    });
  }
};

export const trackPageview = () => {
  if (hasExecutiveInsightsAccount()) {
    ReactGA.ga('send', 'pageview', window.location.href);
  }
  if (hasCustomConfig()) {
  ReactGA.ga(customTrackerPrefix + '.send', 'pageview', window.location.href);
  }
  if (hasVerticalSolutionsAccount()) {
    ReactGA.ga(verticalSolutionsAppTrackerPrefix + '.send', 'pageview', window.location.href);
  }
  if(hasGaG4Config() && !_.isUndefined(gaG4)){
    gaG4.pageview(window.location.href);
  }
  return;
};

export const trackEvent = (eventType, eventAction, eventLabel) => {
  if (hasExecutiveInsightsAccount()) {
    ReactGA.ga('send', 'event', eventType, eventAction, eventLabel);
  }
  if (hasCustomConfig()) {
    ReactGA.ga(customTrackerPrefix + '.send', 'event', eventType, eventAction, eventLabel);
  }
  if (hasVerticalSolutionsAccount()) {
    ReactGA.ga(verticalSolutionsAppTrackerPrefix + '.send', 'event', eventType, eventAction, eventLabel);
  }
  if(hasGaG4Config() && !_.isUndefined(gaG4)){
    gaG4.event(eventType, eventLabel, eventAction);
  }
};
