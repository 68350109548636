import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';

import LoadingSpinner from 'common/components/LoadingSpinner';

import ShareSection from 'pages/SubscriptionsManager/components/ShareSection';
import { shareBookmarkViaEmail, shareRadarViaEmail } from 'common/api/commonApi';
import { createBookmark } from 'common/api/bookmarksApi';
import { getBookmarkType } from 'pages/drilldown/components/Bookmark/bookmarkHelper'
import { EMAIL_STRATEGY_TYPES } from 'appConstants';
import { trackEvent } from 'helpers/eventTracking';
import {
  alertSharedMessage,
  viewSharedMessage,
  alertSharedErrorMessage,
  viewSharedErrorMessage
} from 'helpers/toastMessages';
import { enableTemplateAndMetricPermission } from 'common/config/customerConfiguration';
import { getRadarShareParams } from 'pages/Radar/radarHelper';
import { ForgeButton } from '@tylertech/forge-react';
class ShareBookmarkFromManage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getDefaultStateValues();
  }

  getDefaultStateValues = () => {
    return{
      isLoading: false,
      showShareSection: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  isNeverEmailStrategy = () => {
    const { currentBookmarkEntry } = this.props;
    return _.get(currentBookmarkEntry, 'email_strategy') === EMAIL_STRATEGY_TYPES.NEVER;
  }

  handleClickOutside = (e) => {
    if (this.ShareButtonRef && !this.ShareButtonRef.contains(e.target) && this.state.showShareSection) {
      this.handleShareClose();
    }
  }

  shareRadarView = (options) => {
    const { currentBookmarkEntry, currentUser } = this.props;
    const params = getRadarShareParams(currentBookmarkEntry, options);

    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      shareRadarViaEmail(params)
        .then((response) => {
          this.handleShareClose();
          if (response.ok) {
            toast.success(viewSharedMessage);
          } else {
            toast.error(viewSharedErrorMessage);
          }
          this.setState({ isLoading: false });
        }).catch(() => {
          this.handleShareClose();
          toast.error(viewSharedErrorMessage);
          this.setState({ isLoading: false });
        });
    }
  }

  handleBookmarkShare = (options) => {
    const { currentBookmarkEntry, currentUser } = this.props;
    const isRadar = _.get(currentBookmarkEntry, 'is_radar', false);

    if(isRadar) {
      this.shareRadarView(options);
    } else {
      const { shareFieldInputs } = this.state;
      const shareEmails = _.get(options, 'shareEmails', []);
      const params = {
        shareEmails: shareEmails,
        bookmark: currentBookmarkEntry,
        shareFieldInputs,
        include_current_user: false,
      };
      if (!_.isEmpty(currentUser)) {
        if(this.isNeverEmailStrategy()) {
          trackEvent('manage_confirm_view_share');
        } else {
          trackEvent('manage_confirm_alert_share');
        }
        this.setState({ isLoading: true });
        createBookmark(params)
          .then(response => response.json())
          .then((response) => {
            this.setState({ isLoading: false });
            this.sendBookmarkShareEmail(params, response);
            this.handleShareClose();
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            console.error(err); // eslint-disable-line no-console
          });
      }
    }
  };

  sendBookmarkShareEmail = (bookmarkParams, bookmarkResponse) => {
    const { currentUser, currentBookmarkEntry } = this.props;
    const bookmarkType = getBookmarkType(currentBookmarkEntry);

    const shareBookmarkEmailParams = {
      userName: _.get(currentUser, 'email', ''),
      recipient_mail: _.get(bookmarkParams, 'shareEmails', []),
      recaptcha_response: '',
      bookmarkIds: _.map(bookmarkResponse, (res, emailId) =>{
        return { emailId: emailId, id: res['id'] }
      })
    };
    const isAlertType = bookmarkType == 'alert';
    this.setState({ isLoading: true });
    shareBookmarkViaEmail(shareBookmarkEmailParams)
      .then((response) => {
        if (response.status === 500 || !response.ok) {
          toast.error(isAlertType ? alertSharedErrorMessage : viewSharedErrorMessage);
        } else {
          const toastMessage = isAlertType ? alertSharedMessage : viewSharedMessage;
          toast.success(toastMessage);
        }
        this.setState({ isLoading: false });
        response.json();
      })
      .then(() => {
        this.setState({ isLoading: false });
        this.handleShareClose();
      });
  };

  handleShareClose = () => {
    this.setState({showShareSection : false}, () =>{
      this.props.onShareClick(false);
    });
  }

  renderShareSection() {
    const { showShareSection } = this.state;
    const { currentUser, currentBookmarkEntry, userFromBellerophon } = this.props;
    const currentDrilldownViewEntry = _.get(currentBookmarkEntry, 'drilldown.currentDrilldownViewEntry');
    const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
    let allowedUserRoles = [];
    if(!isLaunchpadAdmin && enableTemplateAndMetricPermission()){
      allowedUserRoles = _.map(_.get(currentDrilldownViewEntry, 'user_list', []), 'role_id');
    }
    const bookmarkType = getBookmarkType(currentBookmarkEntry);
    const placeholderText = 'Enter one or more email addresses';
    if(!showShareSection) {
      return null;
    }

    return(
      <ShareSection
        currentUser={currentUser}
        isShareEnabled={showShareSection}
        onCancelShare={this.handleShareClose}
        onSendClick={this.handleBookmarkShare}
        emailInputPlaceholder={placeholderText}
        allowedUserRoles={allowedUserRoles}
        shareType={bookmarkType}
      />
    );
  }

  initiateCollectionShare = () => {
    if(this.isNeverEmailStrategy()) {
      trackEvent('manage_initiate_view_share');
    } else {
      trackEvent('manage_initiate_alert_share');
    }
    this.setState({showShareSection: true}, () =>{
      this.props.onShareClick(true);
    });
  }

  renderShareButton() {
    const { showShareSection } = this.state;
    if(showShareSection) {
      return null;
    }

    return(
      <ForgeButton type="outlined">
        <button tabIndex="0"
          onClick={this.initiateCollectionShare}
          className="bookmark-share-btn">
          Share
        </button>
      </ForgeButton>
    );
  }

  renderSpinner() {
    const { isLoading } = this.state;

    return (
      <LoadingSpinner isLoading={ isLoading } />
    );
  }

  render() {
    return(
      <div className="share-bookmark-section"
        ref={(ref) => this.ShareButtonRef = ref}>
        {this.renderSpinner()}
        {this.renderShareButton()}
        {this.renderShareSection()}
      </div>
    );
  }
}

ShareBookmarkFromManage.propTypes = {
  currentUser: PropTypes.object,
  userFromBellerophon: PropTypes.object,
  onShareClick: PropTypes.func,
  currentBookmarkEntry: PropTypes.object
}

export default ShareBookmarkFromManage;
