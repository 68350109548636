// Vendor Imports
import React, { Component } from 'react';
import classNames from 'classnames';

// Project Imports
import Filters from 'common/components/Filters';

import { getQuickFilterEntriesFromTemplate } from './helper';
import ResetFilterRenderer from 'common/components/ResetFilterRenderer/ResetFilterRenderer';
import { ErrorBoundaryWrapper } from 'pages/errorboundary/ErrorBoundaryWrapper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { getSupportLink } from 'common/config/customerConfiguration';
import {
  isMultiMetricAscOrder
} from 'common/config/templateConfiguration';
import { isMobileOrTablet } from 'helpers/DomPageHelper';


class QuickFilter extends Component {
  onAllFilterReset = () => {
    this.setState({ filterEntries: [] });
  }

  renderAddFilterButtonAndDropdown() {
    const {
      templateId, viewEntry, quickFilters, apiParams, 
      onFilterChange, filterEntries, disabledFilters, isAnalysisPageFilter
    } = this.props;
    let newFilterEntries = _.isEmpty(filterEntries) ?
      getQuickFilterEntriesFromTemplate(templateId, viewEntry) :
      filterEntries;

    newFilterEntries = (isMultiMetricAscOrder(templateId) ?
      _.sortBy(newFilterEntries, filterEntries => _.lowerCase(filterEntries.name)) : newFilterEntries );

    const newParams = {
      ...apiParams,
      availableTemplateIds: [templateId]
    }

    const position = isMobileOrTablet() ? 'top': 'right-start';

    return (
      <>
        <Filters
          disabledFilters={disabledFilters}
          placement={position}
          apiParams={newParams}
          type="quickFilters"
          isDisabledAddFilterButton={false}
          filterFieldEntries={newFilterEntries}
          filters={quickFilters}
          templateId={templateId}
          viewEntry={viewEntry}
          onFiltersChange={onFilterChange}
          isAnalysisPageFilter={isAnalysisPageFilter}
        />
      </>
    );
  }

  renderFilterHeaderContent() {
    return (
      <div className="card-header">
        <span>Filters </span>
        <ResetFilterRenderer onAllFilterReset={this.onAllFilterReset}/>
      </div>
    );
  }

  render() {
    const { className, showOnlyFiltersContent } = this.props;
    const cardClasses = classNames('quick-filter-bar', { 'card': _.isEmpty(className) });
    const cardHeaderContent = !_.isEmpty(className) ? null : this.renderFilterHeaderContent();
    if (showOnlyFiltersContent) {
      return this.renderAddFilterButtonAndDropdown();
    }

    return (
      <div className="side-bar-filter quick-filter">
        <div className={cardClasses}>
          {cardHeaderContent}
          <div className="card-body">
            <div className="filters-container">
              {this.renderAddFilterButtonAndDropdown()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

QuickFilter.propTypes = {
  onAddFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  showOnlyFiltersContent: PropTypes.bool,
  className: PropTypes.string,
  templateId: commonPropTypes.templateIdPropTypes,
  filterEntries: PropTypes.array,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  apiParams: PropTypes.object,
  onFilterChange: PropTypes.func,
  disabledFilters: PropTypes.bool,
  isAnalysisPageFilter: PropTypes.bool
};

QuickFilter.defaultProps = {
  className: null,
  showOnlyFiltersContent: false,
  filterEntries: [],
  disabledFilters: false
}

function errorContent() {
  return (
    <div className="filter-error-container">
      <i className='icons icons-times-circle' />
      <div className="filter-error-content">
        A filter error has occurred. Please&nbsp;
          <a target="_blank" rel="noreferrer" href={getSupportLink()}>contact Support</a>.
      </div>
    </div>
    );
}

export default ErrorBoundaryWrapper(QuickFilter, errorContent());
