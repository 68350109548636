import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import {
  getAxisGranularityOptions,
  shouldDisableAxisGranularity,
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';

import * as commonPropTypes from "common/propTypes";
import BenchmarkOptions from 'pages/drilldown/components/BenchmarkOptions';
import { isYearOnYear } from 'modules/visualization/LineChart/vizOptionsHelper';
class LineChartOptions extends Component {

  state =  {
    showOptionDropDown:false,
    showLegendDropDown: false
  }

  handleAxisGranularityChange = (option) => {
    this.props.onAxisGranularityChange(option.value);
  }

  renderAxisGranularity() {
    const {
      axisGranularity,
      templateId,
      currentSelectedTimeFrame
    } = this.props;

    if(shouldDisableAxisGranularity(this.props)){
      return null;
    }
    let axisGranularityOptions = getAxisGranularityOptions(templateId);
    const isComparisonEnabled = isYearOnYear({renderTimeFrame: currentSelectedTimeFrame});
    if(isComparisonEnabled){
      _.remove(axisGranularityOptions, { value: 'week' });
    }

    const axisGranularityOption = _.find(axisGranularityOptions, { value: axisGranularity });

    return (
      <div key="overtime-axis-granularity">
        <label className="d-block mb-0 options-label">Axis Granularity</label>
        <SolutionDropDown
          size='sm'
          id="axis-granularity"
          className='mr-4'
          title={_.get(axisGranularityOption, 'name')}
          options={axisGranularityOptions}
          onSelect={this.handleAxisGranularityChange} />
      </div>
    );
  }

  renderBenchMarks() {
    const { onBenchMarkChange, benchMarkNames, currentChartView } = this.props;

    return (
      <BenchmarkOptions onBenchmarkOptionChange={onBenchMarkChange}
      benchMarkNames={benchMarkNames}
      renderType={currentChartView} />
    );
  }

  renderViewOptions() {
    return [
      this.renderAxisGranularity(),
      this.renderBenchMarks()
    ]
  }

  render() {
    const currentViewOptions = this.renderViewOptions();
    return(
      <div className="filter-bar-wrapper">
        <div className="filter-bar-container line-chart-options">
          {currentViewOptions}
        </div>
      </div>
    )
  }
}

LineChartOptions.propTypes = {
  onAxisGranularityChange: PropTypes.func,
  templateId: commonPropTypes.templateIdPropTypes,
  axisGranularity: PropTypes.string,
  currentChartView: PropTypes.string,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  isComboChart: PropTypes.bool,
  handleSecondaryMetricChange: PropTypes.func,
  onBenchMarkChange: PropTypes.func,
  benchMarkNames: PropTypes.array,
  currentSecondaryMetricEntry: PropTypes.object,
  currentSelectedTimeFrame: PropTypes.string
}

const mapDispatchToProps = {
};

function mapStateToProps(state) {
  return {
    commonFilters: _.get(state, 'commonFilters', {}),
    currentDrilldownViewEntry: _.get(state.drilldown, 'currentDrilldownViewEntry', {}),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LineChartOptions);
