import { useEffect, useState } from 'react'
import { getPermittedCollection } from '../Collections/collectionHelper';
import { useRef } from 'react';
import { fetchApiDataWithStatus } from 'helpers/apiResponseHelper';

function UseCollectionFetch() {

  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    if (abortController) {
      abortController.abort();
    }
    const abortController = new AbortController();
    const apiUrl = "/api/collections/domain_wise_all_collection.json";
    fetchApiDataWithStatus(apiUrl, abortController)
      .then((response) => {
        if (isMounted.current) {
          const collections = _.sortBy(getPermittedCollection(response['api_data']), 'sort_order');
          setCollections(collections);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted.current = false;
      abortController.abort();
    };
  }, [])

  return ({ isLoading, collections })
}

export default UseCollectionFetch