import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';

const CopyCollectionModal = (props) => {

  const copyCollectionGroupRef = useRef();
  const { name, onInputChange, onClose, onSave, isMoveCollaborate } = props;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  })

  const handleClickOutside = (e) => {
    if (copyCollectionGroupRef && copyCollectionGroupRef.current
      && !copyCollectionGroupRef.current.contains(e.target)) {
      onClose();
    }
  }

  const renderCollaborateText = () => {
    if (!isMoveCollaborate) {
      return null;
    }

    return (
      <>
        <div className="dropdown-header">
          <ForgeIconButton dense="true" densityLevel="5" className="ml-auto">
            <button
              aria-label="Close"
              tabIndex={0}
              onKeyDown={() => onClose()}
              onClick={() => onClose()} >
              <ForgeIcon name="close" />
            </button>
          </ForgeIconButton>
        </div>
        <p className='mb-2'>
          This is a collection provided by Tyler. To collaborate,
          a copy of this collection needs to be made. Would you like to proceed?
        </p>
      </>
    )
  }

  return (
    <div className="bookmark-dropdown-wrappers" ref={copyCollectionGroupRef}>
      <div className="bookmark-dropdown-wrapper dropdown-menu show">
        {renderCollaborateText()}
        <div className="mb-5">
          <label className="font-weight-bold">Title</label>
          <input
            className="form-control rounded-0"
            type="text"
            placeholder="Title"
            value={name}
            onChange={(e) => onInputChange(e)} />
        </div>
        <div className="dropdown-footer">
          <button
            className="btn btn-link"
            tabIndex={0}
            aria-label="cancel"
            onClick={() => onClose()}>
            Cancel
          </button>
          <button
            disabled={_.isEmpty(name)}
            className="btn btn-primary ml-auto"
            tabIndex={0}
            aria-label="Save"
            onClick={() => onSave()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

CopyCollectionModal.propTypes = {
  name: PropTypes.string,
  onInputChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isMoveCollaborate: PropTypes.bool
}

export default CopyCollectionModal;
