import abortableFetch from 'common/abortableFetch';
import { fetchApiData } from 'helpers/apiResponseHelper';

export const getCollections = (options) => {
  return abortableFetch("/api/collections/domain_wise.json", options);
};

export const getUserAllCollections = (options) => {
  return abortableFetch("/api/collections/domain_wise_all_collection.json", options);
};

export const getCollectionWiseMetricList = (options) => {
  return abortableFetch("/api/collections/get_collection_wise_metric_list.json", options);
};

export const createCollection = (name, sortOrder) => {
  const options =  {
      method: 'POST',
      body: JSON.stringify({ name, sort_order: sortOrder }),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/collections.json"
  return abortableFetch(apiUrl, options);
};

export const shareCollection = (collectionId, params) => {
  const options =  {
    method: 'POST',
    body: JSON.stringify(params),
    headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = `/api/collections/${collectionId}/share_collection.json`;
  return abortableFetch(apiUrl, options);
};

export const acceptCollection = (collectionId) => {
  const options =  {
    method: 'POST',
    headers:{ 'content-type': 'application/json' },
  };
   const apiUrl = `/api/collections/${collectionId}/accept_collection.json`;
  return abortableFetch(apiUrl, options);
};

export const resetCollection = (collectionId) => {
  const options =  {
    method: 'POST',
    headers:{ 'content-type': 'application/json' },
  };
   const apiUrl = `/api/collections/${collectionId}/reset_collection.json`;
  return abortableFetch(apiUrl, options);
};

export const updateCollection = (collection) => {
  const collectionId = collection.id;
  const options =  {
      method: 'PUT',
      body: JSON.stringify(collection),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = `/api/collections/${collectionId}.json`;
  return abortableFetch(apiUrl, options);
};

export const deleteCollection = (collectionId, options={}) => {
  const params =  {
      method: 'DELETE',
      headers:{ 'content-type': 'application/json' },
      body: JSON.stringify(options),
  };
  const apiUrl = `/api/collections/${collectionId}.json`;
  return abortableFetch(apiUrl, params);
};

export const updateCollectionsOrder = (collections) => {
  const params =  {
    method: 'POST',
    body: JSON.stringify({collections}),
    headers:{ 'content-type': 'application/json'}
  };
  const apiUrl = `/api/collections/update_sort_order.json`;
  return abortableFetch(apiUrl, params);
}

export const getCollectionCards = (collectionId, abortcontroller) => {
  const url = `/api/collections/${collectionId}/cards/collection_wise_cards.json`
  return fetchApiData(url, abortcontroller);
}

export const getSingleCollectionDetails = (collectionId, abortcontroller) => {
  const url = `/api/collections/get_single_collection/${collectionId}.json`
  return fetchApiData(url, abortcontroller);
}

export const createCard = (collectionId, cardsParams, options) => {
  const params =  {
      method: 'POST',
      body: JSON.stringify(cardsParams),
      headers:{ 'content-type': 'application/json' },
      ...options
  };
  const apiUrl = `/api/collections/${collectionId}/cards.json`;
  return abortableFetch(apiUrl, params);
}

export const deleteCard = (collectionId, cardId) => {
  const params =  {
      method: 'DELETE',
      headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = `/api/collections/${collectionId}/cards/${cardId}.json`;
  return abortableFetch(apiUrl, params);
}

export const updateCardsOrder = (collectionId, cards) => {
  const params =  {
    method: 'POST',
    body: JSON.stringify({cards}),
    headers:{ 'content-type': 'application/json'}
  };
  const apiUrl = `/api/collections/${collectionId}/cards/update_sort_order.json`;
  return abortableFetch(apiUrl, params);
}

export const bulkUpdateCollections = (collections) => {
  const params = {
    method: 'POST',
    body: JSON.stringify({collections}),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/collections/bulk_update_collection_subscription.json';
  return abortableFetch(apiUrl, params);
}

export const copyCollection = (collection) => {
  const options =  {
      method: 'POST',
      body: JSON.stringify(collection),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/collections/copy_collection.json"
  return abortableFetch(apiUrl, options);
};


export const getCollectionWatchUsers= (collectionId) => {
  const url = `/api/collections/get_watch_users/${collectionId}.json`
  return abortableFetch(url);
}
