import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

import { DATE_FORMAT, MONTH_OF_YEAR } from 'appConstants';
import {
  getAxisGranularity,
  getDifferenceDaysInWord,
  getMinimumDateForForecast,
  isForecastEndDateIsBeforeToday } from '../ForecastHelper';
import { getListOfFutureYears, getListOfYears } from 'helpers/dateHelper';
import ForgeDropdowns from 'common/components/ForgeDropdowns/ForgeDropdowns';
import { ForgeButton } from '@tylertech/forge-react';
import ReRunDialogModal from '../ReRunModal/ReRunDialogModal';
const ForecastingDateFilter = (props) => {
  const {
    currentDrilldownTemplateId,
    minDatesTemplateForForecast,
    forecastAttributeOptions,
    dispatchUpdateForecastDateRange,
    dispatchUpdateFutureForecastDateRange,
    dispatchUpdateAxisGranularity,
    currentBookmark
  } = props;
  const [showModal, setShowModal] = useState(false);

  const {
    currentForecastDateRange,
    futureForecastDateRange,
    isAverageMetric,
    axisGranularity
  } = forecastAttributeOptions;

  const getMinStartDate = () => {
    const startDate = _.get(currentForecastDateRange, 'dateRange.startDate');
    let newStartDate = startDate;
    const minForecastDate = getMinimumDateForForecast(
      currentDrilldownTemplateId,
      minDatesTemplateForForecast
    );
    if (!_.isEmpty(minForecastDate)) {
      newStartDate = minForecastDate
    }

    return newStartDate
  }

  const minStartDateValue = getMinStartDate();
  const endDate = moment().format(DATE_FORMAT);
  const initialStartDate = _.get(currentForecastDateRange, 'dateRange.startDate');

  const initialForecastMonth = _.get(futureForecastDateRange, 'forecastMonth');
  const initialForecastYear = _.get(futureForecastDateRange, 'forecastYear');

  const [startDate, setStartDate] = useState(initialStartDate);

  const [forecastMonth, setForecastMonth] = useState(initialForecastMonth);
  const [forecastYear, setForecastYear] = useState(initialForecastYear);
  useEffect(() => {
    if (_.isEmpty(startDate)){
      setStartDate(_.get(currentForecastDateRange, 'dateRange.startDate'))
    }
  }, [currentForecastDateRange]);
  const isDisableButton = () => {
    const disabled = (
      (_.isEqual(forecastMonth, initialForecastMonth)) &&
      _.isEqual(forecastYear, initialForecastYear) &&
      _.isEqual(startDate, initialStartDate));

    return disabled;
  }

  const getValidMonths = () => {
    const startYear = moment(startDate).year();
    const minYear = moment(minStartDateValue).year();
    const maxYear = moment(endDate).year();

    let starIndex = 0;
    let endIndex = 12;
    if (startYear == minYear) {
      starIndex = moment(minStartDateValue).month();
    } else if (startYear == maxYear) {
      endIndex = moment(endDate).month();
    }

    let monthOptions = [];

    _.forEach(MONTH_OF_YEAR, (datum, index) => {
      if (starIndex <= index && endIndex >= index) {
        monthOptions.push(datum);
      } else if (starIndex == 0 && endIndex == 12) {
        monthOptions.push(datum);
      }
    })

    return monthOptions;
  }

  const handleMonthChange = (option) => {
    const month = _.padStart(_.get(option, 'value'), 2, '0');
    const startYear = moment(startDate).year();
    const newStartDate = `${startYear}-${month}-01`;

    setStartDate(newStartDate);
  }

  const handleYearChange = (option) => {
    const year = _.get(option, 'value');
    const startMonth = moment(startDate).month() + 1;
    const newStartDate = `${year}-${startMonth < 9 ? "0"+startMonth : startMonth}-01`;

    setStartDate(newStartDate);
  }

  const handleForecastMonthChange = (option) => {
    const month = _.get(option, 'value');
    setForecastMonth(month);
  }

  const handleForecastYearChange = (option) => {
    const year = _.get(option, 'value');
    setForecastYear(year);
  }

  const updateForecastValues = () => {
    const current_Date = moment().format(DATE_FORMAT);
    const forecastDate = moment([forecastYear, forecastMonth - 1, 1]).endOf('month').format(DATE_FORMAT)

    const newFutureRanges = {
      dateRange: { startDate: current_Date, endDate: forecastDate },
      forecastYear: forecastYear,
      forecastMonth: forecastMonth
    }

    let newDateRanges = {
      startDate: startDate,
      endDate: endDate
    }

    const updateForecastDateRange = {
      dateRange: _.cloneDeep(newDateRanges)
    }

    if(isAverageMetric){
      const axisGranularity = getAxisGranularity(newDateRanges);
      dispatchUpdateAxisGranularity(axisGranularity);
    }
    dispatchUpdateFutureForecastDateRange(newFutureRanges);
    dispatchUpdateForecastDateRange(updateForecastDateRange);
    setShowModal(false);
  }

  const onApplyClick = () => {
    if ( isForecastEndDateIsBeforeToday(currentForecastDateRange, axisGranularity)){
      setShowModal(true);
    } else {
      updateForecastValues();
    }
  }

  const resetToBookmarkValues = () => {
    const forecastMonth = _.get(currentBookmark, 'forecastOptions.futureForecastDateRange.forecastMonth');
    const forecastYear =  _.get(currentBookmark, 'forecastOptions.futureForecastDateRange.forecastYear');
    setForecastMonth(forecastMonth);
    setForecastYear(forecastYear);
    const startDate = _.get(currentBookmark, 'forecastOptions.currentForecastDateRange.dateRange.startDate');
    setStartDate(startDate);
  };

  const getValidMonthsByForecastYear = () => {
    const currentYear = moment().year();
    const forecastYearValue = _.isUndefined(forecastYear) ? currentYear : forecastYear;

    let starIndex = 0;
    let endIndex = 12;
    if (currentYear == forecastYearValue) {
      starIndex = moment().month();
    }

    let monthOptions = [];

    _.forEach(MONTH_OF_YEAR, (datum, index) => {
      if (starIndex <= index && endIndex >= index) {
        monthOptions.push(datum);
      } else if (starIndex == 0 && endIndex == 12) {
        monthOptions.push(datum);
      }
    })

    return monthOptions;
  }

  const renderMonthDropDownFutureForecast = () => {
    const monthOptions = getValidMonthsByForecastYear();

    return (
      <div className="dropdown-field">
        <ForgeDropdowns
          key="month-type-forecast"
          value={forecastMonth}
          label="Month"
          options={monthOptions}
          handleOnSelect={handleForecastMonthChange}
        />
      </div>
    )
  }

  const renderYearDropdownFutureForecast = () => {
    const yearOptions = getListOfFutureYears(11);

    return (
      <div className="dropdown-field">
        <ForgeDropdowns
          key="year-type-forecast"
          value={forecastYear}
          label="Year"
          options={yearOptions}
          handleOnSelect={handleForecastYearChange}
        />
      </div>
    )
  }

  const renderMonthDropdown = () => {
    const monthOptions = getValidMonths();
    const monthNumber = `${moment(startDate).month() + 1}`;

    return (
      <div className="dropdown-field">
        <ForgeDropdowns
          key="month-type"
          value={monthNumber}
          label="Start Month"
          options={monthOptions}
          handleOnSelect={handleMonthChange}
        />
      </div>
    )
  }

  const renderYearDropdown = () => {
    const minStartYear = moment(minStartDateValue).year();
    const yearOptions = getListOfYears(minStartYear);
    const year = `${moment(startDate).year()}`;

    return (
      <div className="dropdown-field">
        <ForgeDropdowns
          key="year-type"
          value={year}
          label="Start Year"
          options={yearOptions}
          handleOnSelect={handleYearChange}
        />
      </div>
    )
  }

  const renderFutureForecastDateText = () => {
    const currentDate = moment().format(DATE_FORMAT);
    const forecastDate = moment([forecastYear, forecastMonth - 1, 1]).endOf('month').format(DATE_FORMAT);
    const formattedInWord = getDifferenceDaysInWord(currentDate, forecastDate, true);

    return (
      <div className='forge-typography--caption'>
        Your forecast will be <b>{formattedInWord}</b> long
      </div>
    )
  }

  const renderForecastDateText = () => {
    const startMonthText = moment(startDate).format("MMMM, YYYY");
    const endMonthText = moment(endDate).format("MMMM, YYYY");
    const formattedInWord = getDifferenceDaysInWord(startDate, endDate, false);

    return (
      <div className='forge-typography--caption'>
        Your forecast will be based on <b>{formattedInWord}</b> of historical data,
        from {startMonthText} through {endMonthText}.
      </div>
    )
  }

  const renderApplyButton = () => {

    return (
      <div>
        <ForgeButton type="raised">
          <button type="button" disabled={isDisableButton()}
            onClick={() => onApplyClick()}> Apply</button>
        </ForgeButton>
      </div>
    )
  }

  const reRunModal = () => {
    if (showModal) {
      return (
        <ReRunDialogModal
          showModal={showModal}
          setShowModal={setShowModal}
          onModalClick={updateForecastValues}
          onModalCancel={resetToBookmarkValues}
        >
        </ReRunDialogModal>
      );
    }
  }

  return (
    <div className="mt-5">
      <div className='forge-typography--subtitle1 mb-2'>
        How far into the future would you like to forecast?
      </div>
      <div className='d-flex align-items-center gap-30 mb-10 forecast-date-filter'>
        {renderMonthDropDownFutureForecast()}
        {renderYearDropdownFutureForecast()}
        {renderFutureForecastDateText()}
      </div>

      <div className='forge-typography--subtitle1 mb-2'>
        How much historical data would you like to use to create your forecast?
      </div>
      <div className='d-flex align-items-center gap-30 mb-10 forecast-date-filter'>
        {renderMonthDropdown()}
        {renderYearDropdown()}
        {renderForecastDateText()}
      </div>
      {renderApplyButton()}
      {reRunModal()}
    </div>
  );
}

ForecastingDateFilter.propTypes = {
  currentDrilldownTemplateId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  minDatesTemplateForForecast: PropTypes.array,
  commonFilters: PropTypes.shape({}),
  dispatchUpdateForecastDateRange: PropTypes.func,
  dispatchUpdateFutureForecastDateRange: PropTypes.func,
  dispatchUpdateAxisGranularity: PropTypes.func,
  forecastAttributeOptions: PropTypes.object,
  currentBookmark: PropTypes.object,
}

export default ForecastingDateFilter;
