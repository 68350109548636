import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ForgeBadge, ForgeIcon, ForgeIconButton, ForgeMenu } from '@tylertech/forge-react';

import ForgeInputTextField from 'common/components/ForgeInputFields/ForgeInputTextField';
import InlineEdit from 'pages/dashboard/components/InlineEdit';
import ForecastingModel from './ForecastingModel';
import WarningInfo from './WarningInfo';

import { FORECASTING_TYPE, FORECASTING_TYPE_LABEL, HISTORICAL_FORECAST_COLORS } from 'appConstants';
import dataFormatter from 'modules/visualization/LineChart/dataFormatter';
import { isForecastEndDateIsBeforeToday } from '../ForecastHelper';
import ReRunDialogModal from '../ReRunModal/ReRunDialogModal';
import {
  updateForecastDateRange
} from 'actions/forecastingActions';
import { DATE_FORMAT } from "appConstants";
const ForecastingTypes = (props) => {
  const dispatch = useDispatch();
  const { forecastAttributeOptions, lineChartAttributes, currentBookmark } = props;
  const {
    forecastModelOptions,
    dispatchUpdateForecastModelOptions,
    dispatchUpdateShowProjection,
    leastGranularityApiData,
    currentForecastDateRange,
    axisGranularity
  } = forecastAttributeOptions;
  const [showModal, setShowModal] = useState(false);
  const [updatedModelTypes, setUpdatedModelTypes] = useState({});
  const [forecastTypeModels, setForecastTypeModels] = useState(forecastModelOptions || []);
  const [defaultGranularityFormattedData, setDefaultGranularityFormattedData] = useState({});
  const [modelEditInputIndex, setModelEditInputIndex] = useState(null);

  useEffect(() => {
    setForecastTypeModels(forecastModelOptions)
  }, [forecastModelOptions]);

  useEffect(() => {
    const apiData = { ...leastGranularityApiData['api_data'], ...leastGranularityApiData }
    const formattedChartData = dataFormatter.formatData(lineChartAttributes, apiData);
    setDefaultGranularityFormattedData(formattedChartData);
  }, [leastGranularityApiData]);

  const renderForecastModel = (renderType = "") => {
    const buttonClass = _.isEmpty(renderType) ? '' : "mt-5";
    const buttonTitle = _.isEmpty(renderType) ? "Add a model" : "Add a new model";
    return (
      <div className={buttonClass}>
        <ForecastingModel
          buttonTitle={buttonTitle}
          openForecastingConceptPage={openForecastingConceptPage}
          forecastTypeModels={forecastTypeModels}
          forecastAttributeOptions={forecastAttributeOptions}
          onUpdateForecastOptions={updateForecastModelOptions}
          defaultGranularityFormattedData={defaultGranularityFormattedData}
        />
      </div>
    )
  }

  const updateForecastModals = () => {
    const dateRange = {
      startDate: _.get(currentForecastDateRange, 'dateRange.startDate'),
      endDate: moment().format(DATE_FORMAT)
    }
    dispatch(updateForecastDateRange({ dateRange }));
    setForecastTypeModels(updatedModelTypes);
    dispatchUpdateForecastModelOptions(updatedModelTypes);
    dispatchUpdateShowProjection(!_.isEmpty(updatedModelTypes));
    setShowModal(false);
    setUpdatedModelTypes({});
  };

  const resetToBookmarkValues = () => {
    setForecastTypeModels(_.uniq(_.get(currentBookmark, 'forecastOptions.forecastModelOptions')));
  };

  const updateForecastModelOptions = (selectedModelTypes) => {
    if ( isForecastEndDateIsBeforeToday(currentForecastDateRange, axisGranularity)){
      setShowModal(!_.isEqual(forecastModelOptions, selectedModelTypes));
      setUpdatedModelTypes(selectedModelTypes);
    } else {
      setForecastTypeModels(selectedModelTypes);
      dispatchUpdateForecastModelOptions(selectedModelTypes);
      dispatchUpdateShowProjection(!_.isEmpty(selectedModelTypes));
    }
  }

  const renderForecastingPercentField = (forecastType, typeIndex) => {
    const label = !_.isEmpty(forecastType.value) ? "Growth percent" : 'Number';
    return (
      <div className="percent-input">
        <ForgeInputTextField
          type="text"
          key={typeIndex}
          label={label}
          id="historic-input"
          value={forecastType.value || 0}
          hasHelperText={true}
          helperText={"May be positive, negative, or zero"}
          hasLabel={true}
          ariaLabel="Growth percent"
          handleOnChange={(e) => handleHistoricInputChange(e, typeIndex)}
          handleOnBlur={() => updateForecastModelOptions(forecastTypeModels)}
        />
        <WarningInfo
          defaultGranularityFormattedData={defaultGranularityFormattedData}
          forecastAttributeOptions={forecastAttributeOptions}
        ></WarningInfo>
      </div>
    )
  }

  const handleHistoricInputChange = (e, typeIndex) => {
    const reg = /^-?[0-9]\d*(\.\d+)?$/;
    let inputValue = e.target.value;
    if (e.target.value == 0) {
      inputValue = "0";
    } else if (/^0[0-9]/.test(inputValue)) {
      inputValue = inputValue.substring(1);
    } else {
      inputValue = e.target.value
    }
    inputValue = inputValue.replace(/(?!^-)[^0-9.]/g, "");
    const isValid = reg.test(inputValue);

    if (inputValue === '' || inputValue === '-' || isValid) {
    const newModels =_.map(forecastTypeModels, (model, index) => {
        if ((model.type == FORECASTING_TYPE.HISTORICAL_AVG) && (index === typeIndex)) {
          return { ...model, value: inputValue};
        } else {
          return model;
        }
      });
      setForecastTypeModels(newModels);
    }
  }

  const renderForecastOptions = (forecastType, index) => {
    if (forecastType['type'] == FORECASTING_TYPE.HISTORICAL_AVG) {
      return renderForecastingPercentField(forecastType, index)
    } else {
      return <div className="forge-typography--subtitle2 mt-1">No adjustable inputs</div>
    }
  }

  const handleModelNameChange = (modelName, index) => {
    const updatedModelTypes = [...forecastTypeModels];
    updatedModelTypes[index] = {
      ...updatedModelTypes[index],
      name: modelName
    };
    setForecastTypeModels([...updatedModelTypes]);
    updateForecastModelOptions([...updatedModelTypes]);
    setModelEditInputIndex(null);
  }

  const renderModelName = (modelName, index) => {
    return (
      <div className="clearfix">
        <InlineEdit
          onEditClose={() => setModelEditInputIndex(null)}
          onInputChange={(e) => handleModelNameChange(e, index)}
          enableInputEdit={modelEditInputIndex == index}
          defaultText={modelName}>
          <span tabIndex={0}
            className="viz-title forge-typography--title"
            onClick={() => setModelEditInputIndex(index)}>
            {modelName}
            <ForgeIcon name="mode_edit" className="edit-icon" />
          </span>
        </InlineEdit>
      </div>
    )
  }

  const renderModelType = (type) => {
    return (
      <ForgeBadge className="forge-badge" theme="info-secondary">{FORECASTING_TYPE_LABEL[type]}</ForgeBadge>
    )
  }

  const openForecastingConceptPage = (scrollId) => {
    const href = `/#!/forecasting/concepts?scroll=${scrollId}`;
    window.open(href, '_blank');
  }

  const renderForecastType = (forecastType, index) => {
    const modelName = _.get(forecastType, 'name', '');
    const isHistoricalType = forecastType.type === FORECASTING_TYPE.HISTORICAL_AVG;
    const historicalData = _.filter(forecastTypeModels, { type: FORECASTING_TYPE.HISTORICAL_AVG })
    const SIMPLE_OPTIONS = [
      { value: 'duplicate', label: 'Duplicate', disabled: !isHistoricalType || historicalData.length > 10 },
      { value: 'remove', label: 'Remove' },
      { value: 'learnMore', label: 'Learn more', type: modelName },
    ]

    const handleMenuSelect = ({ detail }) => {
      if (detail.value == 'remove') {
        handleModelDelete(forecastType);
      } else if (detail.value == 'duplicate') {
        handleModelDuplicate(forecastType)
      } else if (detail.value == 'learnMore') {
        let scrollId = '';
        switch(forecastType.type) {
          case FORECASTING_TYPE.HISTORICAL_AVG:
            scrollId = 'historical';
            break;
          case FORECASTING_TYPE.PROPHET:
            scrollId = 'prophet';
            break;
          case FORECASTING_TYPE.SIMPLE_EXPONENTIAL:
            scrollId = 'exponential';
            break;
        }
        openForecastingConceptPage(scrollId);
      }
    }

    return (
      <div className='detail-items'>
        <div className='d-flex align-items-start'>
          <div>
            <div className="forge-typography--headline6">{renderModelName(modelName, index)}</div>
            {renderForecastOptions(forecastType, index)}
          </div>
          <div className="info-sections ml-auto">
            <ForgeMenu
              className='d-flex align-items-center'
              options={SIMPLE_OPTIONS}
              mode={"click"}
              open={false}
              placement={"left"}
              persistSelection={false}
              on-forge-menu-select={handleMenuSelect}
            >
              {renderModelType(forecastType?.type)}

              <ForgeIconButton type="button">
                <button type="button">
                  <ForgeIcon name='more_vert' className="more-icon" />
                </button>
              </ForgeIconButton>
            </ForgeMenu>
          </div>
        </div>
      </div>
    )
  }

  const handleModelDelete = (forecastType) => {
    let newForecastTypeModel;
    if (forecastType.type === FORECASTING_TYPE.HISTORICAL_AVG) {
      newForecastTypeModel = _.reject(forecastTypeModels, { index: forecastType.index });
    } else {
      newForecastTypeModel = _.reject(forecastTypeModels, { type: forecastType.type });
    }
    setForecastTypeModels(newForecastTypeModel);
    dispatchUpdateForecastModelOptions(newForecastTypeModel);
    dispatchUpdateShowProjection(!_.isEmpty(newForecastTypeModel));
  }

  const handleModelDuplicate = (forecastType) => {
    const historicModels = _.filter(forecastTypeModels, { type: FORECASTING_TYPE.HISTORICAL_AVG })
    const historicalCopy = {
      name: `${forecastType.name} copy`,
      type: FORECASTING_TYPE.HISTORICAL_AVG,
      value: forecastType.value,
      index: forecastTypeModels.length,
      color: HISTORICAL_FORECAST_COLORS[historicModels.length]
    }
    setForecastTypeModels([...forecastTypeModels, historicalCopy]);
    updateForecastModelOptions([...forecastTypeModels, historicalCopy])
  }

  const renderSelectedForecastTypes = () => {
    if (_.isEmpty(forecastTypeModels)) {
      return
    }

    return (
      <div className='forecasting-detail-list'>
        {
          _.map(forecastTypeModels, (forecastType, index) => {
            return renderForecastType(forecastType, index)
          })
        }
      </div>
    )
  }

  const renderEmptyModelImage = () => {
    return (
      <div className='forecasting-modal gap-20 flex-column d-flex align-items-center
        justify-content-center mt-8'>
        <img src='../images/launch-spot-hero.svg' alt='imge' />
        <div className='forge-typography--subtitle1'>Apply a forecasting model to get started.</div>
        {renderForecastModel()}
      </div>
    )
  }

  const reRunModal = () => {
    if (showModal) {
      return (
        <ReRunDialogModal
          showModal={showModal}
          setShowModal={setShowModal}
          onModalClick={updateForecastModals}
          onModalCancel={resetToBookmarkValues}
        >
        </ReRunDialogModal>
      );
    }
  }

  return (
    <div>
      {renderSelectedForecastTypes()}
      {_.isEmpty(forecastTypeModels) && renderEmptyModelImage()}
      {!_.isEmpty(forecastTypeModels) && renderForecastModel('old')}
      {reRunModal()}
    </div>
  );
}

ForecastingTypes.propTypes = {
  forecastAttributeOptions: PropTypes.object,
  lineChartAttributes: PropTypes.object,
  currentBookmark: PropTypes.object
}

export default ForecastingTypes;
