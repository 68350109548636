import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import QuickFilterBarHandler from 'pages/drilldown/components/QuickFilterBar/QuickFilterBarHandler';
import DateFilters from './DateFilters';
import CollectionFilters from 'pages/dashboard/components/Collections/CollectionFilters/CollectionFilters';

import { getVisualizationTypes, getAvailableVisualizationTypes } from 'helpers/visualizationHelper';
import {  getVizBasedChartTypes } from './helper';
import { VISUALIZATION_TYPES, EMAIL_STRATEGY_TYPES } from 'appConstants';
import BenchmarkOptions from 'pages/drilldown/components/BenchmarkOptions';
import {  getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import { enableCollectionLevelDateFilters  } from 'common/config/customerConfiguration';
import * as commonPropTypes from "common/propTypes";
import {
  getAdditionalDateFiltersConfigs,
  isDateIgnored,
  isIndependentDateFiltersEnabled
} from 'pages/dashboard/components/Collections/collectionHelper';
import { showDateFilter } from 'common/config/viewConfiguration';

class BookmarkFiltersView extends Component {
  constructor(props) {
    super(props);
  }

  onAdditionalFiltersChange = (id, filters) => {
    const { onHandleCommonFiltersChange, commonFilters } = this.props;
    let newAdditionalDateFilters = _.get(commonFilters, 'additionalDateFilters', {});
    newAdditionalDateFilters[id] = filters;
    const newFilters = {
      ...commonFilters,
      additionalDateFilters: newAdditionalDateFilters
    }
    onHandleCommonFiltersChange(newFilters);
  }

  onCollectionFiltersChange = (filters) => {
    const { onHandleCommonFiltersChange, commonFilters } = this.props;
    const newFilters = {
      ...commonFilters,
      globalFilters: filters
    };
    onHandleCommonFiltersChange(newFilters);
  }

  renderAdditionalDateFilters() {
    const {
      currentDrilldownTemplateId,
      commonFilters,
      isNoneAdditionalDateFilters,
      onHandleRelativeDateTypeChange,
      drilldown,
      emailStrategy,
      isDisabled
    } = this.props;

    const { currentDrilldownViewEntry } = drilldown;
    const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
    const cards = [{templateEntry, viewEntry: currentDrilldownViewEntry }];
    const additionalDateFilterConfigs = getAdditionalDateFiltersConfigs(cards);

    if(_.isEmpty(additionalDateFilterConfigs) || !enableCollectionLevelDateFilters()){
      return null;
    }

    return _.map(additionalDateFilterConfigs, (config) => {
      const { id, name } = config;
      let filters = _.get(commonFilters, `additionalDateFilters.${id}`, {});

      return(
        <DateFilters
          isDisabled={isDisabled}
          key={id}
          labelText={name}
          index={id}
          isRelativeDateSelected={false}
          isNoneDateSelected={isNoneAdditionalDateFilters}
          hideRelativeDateOption={true}
          hideNoneDateOption={emailStrategy !== EMAIL_STRATEGY_TYPES.NEVER}
          templateId={currentDrilldownTemplateId}
          commonFilters={filters}
          onRelativeDateTypeChange={(isRelativeDateSelected, isNoneDateSelected) => {
              onHandleRelativeDateTypeChange(isRelativeDateSelected, isNoneDateSelected, true)
            }
          }
          onCommonFiltersChange={(newFilters) => this.onAdditionalFiltersChange(id, newFilters)} />
      );
    });

  }

  renderDateRangeFilters() {
    const {
      currentDrilldownTemplateId,
      commonFilters,
      onHandleCommonFiltersChange,
      isRelativeDateSelected,
      isNoneDateSelected,
      onHandleRelativeDateTypeChange,
      emailStrategy,
      isDisabled
    } = this.props;
    let title = "Date Range";
    const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
    const { currentDrilldownViewEntry } = _.cloneDeep(this.props.drilldown);

    if(enableCollectionLevelDateFilters()) {
      if(isDateIgnored(currentDrilldownViewEntry)){
        return null;
      }
      if(isIndependentDateFiltersEnabled(currentDrilldownViewEntry)){
        title = _.get(currentDrilldownViewEntry, 'fields.date_column_label', 'Date Range');
      }else if(isIndependentDateFiltersEnabled(templateEntry)){
        title = _.get(templateEntry, 'fields.date_column_label', 'Date Range');
      }else{
        title = "Date Range";
      }
    }

    return(
      <DateFilters
        isDisabled={isDisabled}
        key="common-filters"
        labelText={title}
        isRelativeDateSelected={isRelativeDateSelected}
        isNoneDateSelected={isNoneDateSelected}
        templateId={currentDrilldownTemplateId}
        commonFilters={commonFilters}
        hideNoneDateOption={emailStrategy !== EMAIL_STRATEGY_TYPES.NEVER}
        onRelativeDateTypeChange={(isRelativeDateSelected, isNoneDateSelected) => {
          onHandleRelativeDateTypeChange(isRelativeDateSelected, isNoneDateSelected, false)
        }
      }
        onCommonFiltersChange={onHandleCommonFiltersChange} />
    );
  }

  renderCollectionFilters() {
    const { commonFilters, currentUser, currentDrilldownTemplateId, isDisabled } = this.props
    const globalFilters = _.get(commonFilters, `globalFilters`, []);
    return(
      <div className="bookmark-collection-filters-container">
        <CollectionFilters
          disabledFilters={isDisabled}
          availableTemplateIds={[currentDrilldownTemplateId]}
          isDisabledAddFilterButton={true}
          hideCollectionDateFilters={true}
          disableOtherTemplateEntries={true}
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          currentUser={currentUser}
          hideShowMore={true}
          onCollectionFiltersChange={this.onCollectionFiltersChange}
          collectionFilters={globalFilters} />
      </div>
    )
  }
  renderQuickFilters() {
    const { quickFilters, currentDrilldownViewEntry } = _.cloneDeep(this.props.drilldown);
    const { onQuickFiltersChange, currentDrilldownTemplateId, apiParams, isDisabled } = this.props;

    return (
      <div className="quick-filter-section row">
        <QuickFilterBarHandler
          disabledFilters={isDisabled}
          className="col-md-6"
          quickFilters={quickFilters}
          onFilterChange={onQuickFiltersChange}
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          apiParams={apiParams}
          viewEntry={currentDrilldownViewEntry}
        />
      </div>
    );
  }

  renderVizBasedChartDropDown() {
    const { bookmarkFieldOptions, drilldown, onVisChartTypeSelect, isDisabled } = this.props;
    let { currentVizChartType } = this.props;
    const { currentDrilldownViewEntry, currentVisualizationType } = drilldown;
    const chartTypes = getVizBasedChartTypes(currentVisualizationType, currentDrilldownViewEntry);
    const hideVizBasedCharts = [
      VISUALIZATION_TYPES.TABLE.type, VISUALIZATION_TYPES.DISTRIBUTION.type,
      VISUALIZATION_TYPES.TIME_OF_DAY.type,
    ];

    if(_.isEmpty(currentVizChartType)) {
      currentVizChartType = _.get(bookmarkFieldOptions, 'currentVizChartType', currentVizChartType)
    }

    let vizChartEntry = _.find(chartTypes, (entry) => {
      return (entry.type == currentVizChartType);
    });

    if (_.includes(hideVizBasedCharts, currentVisualizationType)) {
      return null;
    }

    return (
      <SolutionDropDown
        disabled={isDisabled}
        className="p-0 xl-overflow"
        size="sm"
        id="chart"
        title= {_.get(vizChartEntry, 'name', 'Select') }
        options={chartTypes}
        onSelect={onVisChartTypeSelect} />
    );
  }

  renderVisualizationDropDown() {
    const { currentDrilldownTemplateId, drilldown, onVisualizationSelect, isDisabled } = this.props;
    const {
      currentVisualizationType,
      currentDrilldownViewEntry
    } = drilldown;
    const visualizations = getVisualizationTypes();
    const availableVisualizationTypes = getAvailableVisualizationTypes(
      currentDrilldownTemplateId, currentDrilldownViewEntry);
    const visEntry = _.find(visualizations, (entry) => {
      return (entry.type == currentVisualizationType);
    });
    return (
      <SolutionDropDown
        disabled={isDisabled}
        className="p-0 xl-overflow"
        size="sm"
        id="visualization"
        title= {_.get(visEntry, 'name', 'Select')}
        options={availableVisualizationTypes}
        onSelect={onVisualizationSelect} />
    );
  }

  renderBenchMarkOptions() {
    const { currentDrilldownTemplateId,
      distributionChartOptions,
      drilldown,
      currentVizChartType,
      visualization,
      onBenchmarkOptionChange } = this.props;

    const {
      currentDrilldownViewEntry,
      currentVisualizationType,
      currentDrilldownDimensionField } = drilldown;
    const currentBenchMarkNames = _.get(
      visualization, currentVisualizationType + '.currentBenchMarkMetricNames');

    const bookmarkVizOption = {
      currentVizChartType,
      visualization,
      currentDrilldownTemplateId,
      currentDrilldownViewEntry,
      currentVisualizationType,
      currentDrilldownDimensionField,
      currentBenchMarkNames
    };

   return (
      <BenchmarkOptions idName="bm"
        bookmarkVizOption = {bookmarkVizOption}
        renderType = {currentVizChartType}
        drilldownDimensionField = {currentDrilldownDimensionField}
        drilldownTemplateId = {currentDrilldownTemplateId}
        viewEntry = {currentDrilldownViewEntry}
        benchMarkNames = {currentBenchMarkNames}
        visualizationType = {currentVisualizationType}
        isDiscrete={distributionChartOptions.isDiscrete}
        isCumulative={distributionChartOptions.isCumulative}
        onBenchmarkOptionChange={onBenchmarkOptionChange}
        ignoreTitle={true} />
    );
  }

  render() {
    const { currentDrilldownTemplateId, drilldown } = this.props;
    const { currentDrilldownViewEntry } = drilldown;
    const showDateFilterContent = showDateFilter(currentDrilldownTemplateId, currentDrilldownViewEntry);

    return(
      <div>
        <div className="bookmark-filters mb-10">
          {showDateFilterContent &&
            <div className="date-and-additional-filter">
              {this.renderDateRangeFilters()}
              {this.renderAdditionalDateFilters()}
            </div>
          }
          <div className="default-view-filter">
            {this.renderBenchMarkOptions()}
            <label className="custom-label mb-0 mt-0">Default View</label>
            <div className="d-flex gap-10">
              {this.renderVisualizationDropDown()}
              {this.renderVizBasedChartDropDown()}
            </div>
          </div>
        </div>

        <label className="custom-label mb-0">Filters</label>
        {this.renderCollectionFilters()}
        {this.renderQuickFilters()}

      </div>

    );
  }
}

BookmarkFiltersView.propTypes = {
  currentVizChartType: PropTypes.string,
  drilldown: commonPropTypes.drilldownPropTypes,
  visualization: PropTypes.object,
  distributionChartOptions: PropTypes.object,
  commonFilters: PropTypes.object,
  onHandleCommonFiltersChange: PropTypes.func,
  onVisChartTypeSelect: PropTypes.func,
  onBenchmarkOptionChange: PropTypes.func,
  isRelativeDateSelected: PropTypes.bool,
  isNoneDateSelected: PropTypes.bool,
  isNoneAdditionalDateFilters: PropTypes.bool,
  onHandleRelativeDateTypeChange: PropTypes.func,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  emailStrategy: PropTypes.string,
  onQuickFiltersChange: PropTypes.func,
  apiParams: PropTypes.object,
  bookmarkFieldOptions: PropTypes.object,
  onVisualizationSelect: PropTypes.func,
  currentUser: PropTypes.object,
  isDisabled: PropTypes.bool
}

export default BookmarkFiltersView;
