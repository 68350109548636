import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Filters from 'common/components/Filters';
import CollectionDateFilters from '../CollectionDateFilters/CollectionDateFilters';

import { updateUserPreferences } from 'common/api/commonApi';
import { getGlobalFilters, isCollectionFilterAscOrder } from 'common/config/customerConfiguration';
import { getGlobalFilterConfig, getCollectionFiltersAndEntries } from '../collectionHelper';

import { COLLECTION_ID } from 'appConstants';
import { isCollaborateCollection, isCollectionViewAccess } from '../../ManageCollection/collaboratorHelper';
import { isDefaultCollectionFilter } from 'pages/drilldown/components/QuickFilterBar/helper';
import { ForgeTooltip } from '@tylertech/forge-react';

const MAX_FILTER_LIMIT = 5;
class CollectionFilters extends Component {
  state = {
    filterBadgeEntries: [],
    showAllFilterBadges: false
  };

  componentDidMount() {
    this.updateFilterBadges();
  }

  componentDidUpdate(prevPops) {
    const { collectionFilters } = this.props;
    if (!_.isEqual(_.get(prevPops, 'collectionFilters'), collectionFilters)) {
      this.updateFilterBadges();
    }
  }

  updateFilterBadges = () => {
    const { collectionFilters } = this.props;

    this.setState({
      filterBadgeEntries: collectionFilters
    });
  }

  handleCollectionFiltersChange = (filters) => {
    const {
      currentUser,
      currentCollection,
      onCollectionFiltersChange
    } = this.props;
    const isAllMetricsCollection = _.isEqual(_.get(currentCollection, 'id'), COLLECTION_ID.ALL_METRICS);
    onCollectionFiltersChange(filters);
    if (isAllMetricsCollection && !_.isEmpty(currentUser)) {
      this.handleAllMetricsFilterChange(filters);
    }
  }

  handleAllMetricsFilterChange = (globalFilters) => {
    const { currentCollection } = this.props;
    this.setState({ loading: true });

    const location= window.location.href;
    if(location.substring('/overview')!==-1){
      updateUserPreferences(globalFilters, _.get(currentCollection, 'dateFilters', {})).
        then(() => {
          this.setState({ loading: false });
        }).catch((err) => {
          console.log('Error on updating global filters ', err); // eslint-disable-line no-console
          this.setState({ loading: false });
        });
      }
  }

  renderShowMoreBadge() {
    const { filterBadgeEntries, showAllFilterBadges } = this.state;
    const { hideShowMore } = this.props;
    const badgeCount = _.size(filterBadgeEntries);
    if ((badgeCount <= MAX_FILTER_LIMIT) || showAllFilterBadges || hideShowMore) {
      return null;
    }

    return (
      <>
        <div className="chip badge pr-2">
          <i className="icons icons-filter" />
          <span>+{badgeCount - MAX_FILTER_LIMIT}</span>
        </div>
        <div
          className="btn btn-sm mb-1 text-primary pointer"
          onClick={() => { this.setState({ showAllFilterBadges: true }) }}>
          Show more
        </div>
      </>
    )
  }

  renderShowLessBadge() {
    const { filterBadgeEntries, showAllFilterBadges } = this.state;
    const badgeCount = _.size(filterBadgeEntries);
    if ((badgeCount <= MAX_FILTER_LIMIT) || !showAllFilterBadges) {
      return null;
    }

    return (
      <div
        className="btn btn-sm mb-1 text-primary pointer"
        onClick={() => { this.setState({ showAllFilterBadges: false }) }}>
        Show Less
      </div>
    )
  }

  renderDateFilters() {
    const {
      cardEntries,
      currentCollection,
      onDateFiltersChange,
      dateFilters,
      disabledFilters,
      hideCollectionDateFilters
    } = this.props;

    if (hideCollectionDateFilters) {
      return null;
    }

    return (
      <CollectionDateFilters
        disabledFilters={disabledFilters}
        cardEntries={cardEntries}
        dateFilters={dateFilters}
        onDateFiltersChange={onDateFiltersChange}
        currentCollection={currentCollection} />
    )
  }

  renderAddFilterBadge() {
    const {
      currentDrilldownTemplateId,
      isDisabledAddFilterButton,
      availableTemplateIds,
      disabledFilters,
      currentCollection
    } = this.props;
    const collectionOptions = _.pick(this.props,
      'collectionFilters',
      'currentDrilldownTemplateId',
      'isDisabledAddFilterButton',
      'cardEntries',
      'isMyViewCollection'
    );
    const collectionFiltersAndEntries = this.getFilterEntries(collectionOptions);
    if (_.isEmpty(collectionFiltersAndEntries)) {
      return null
    }
    const multiSelectFilterEntries = _.get(collectionFiltersAndEntries, 'multiSelectFilterEntries');
    const isCollaborated = isCollaborateCollection(currentCollection);

    let filterFieldEntries = _.get(collectionFiltersAndEntries, 'globalFilterEntries');
    filterFieldEntries = (isCollectionFilterAscOrder() ?
      _.sortBy(filterFieldEntries, entries => _.lowerCase(entries.name)) :
      filterFieldEntries);


    return (
      <>
      <Filters
        disabledFilters={disabledFilters}
        isDisabledAddFilterButton={isDisabledAddFilterButton || _.isEmpty(multiSelectFilterEntries)}
        type="quickFilters"
        filterFieldEntries={filterFieldEntries}
        filters={_.get(collectionFiltersAndEntries, 'collectionFilters')}
        templateId={currentDrilldownTemplateId}
        apiParams={{ availableTemplateIds: availableTemplateIds }}
        onFiltersChange={this.handleCollectionFiltersChange}
        isCollaborated={isCollaborated}
        currentCollection={currentCollection}
      />
      { isCollectionViewAccess(currentCollection) &&
        <ForgeTooltip
          text={"This filter is set by the collection administrator and cannot be changed"}
        >
        </ForgeTooltip>
      }
      </>
    );
  }

  getFilterEntries = (collectionOptions) =>{
    const {
      globalFilterFieldConfigs,
      multiSelectFilterFiledConfig,
      collectionFilters
    } = getCollectionFiltersAndEntries(collectionOptions);

    const { currentCollection } = this.props;

    const newFilterEntries = _.map(globalFilterFieldConfigs, (fieldEntry) => {
      fieldEntry['is_default_collection_filter'] = false;
      if(isDefaultCollectionFilter(fieldEntry, currentCollection)) {
        fieldEntry['is_default_collection_filter'] = true;
      }
      return fieldEntry;
    })

    return {
      globalFilterEntries : newFilterEntries,
      multiSelectFilterEntries: multiSelectFilterFiledConfig,
      collectionFilters
    };

  }

  render() {
    const globalFilters = getGlobalFilters();
    const { currentDrilldownTemplateId, cardEntries, isShareModal } = this.props;
    const { isDisableFilterButton } = getGlobalFilterConfig(cardEntries, currentDrilldownTemplateId);

    if (isDisableFilterButton) {
      return null;
    }

    return (
      <div className="collections-global-filter">
        {!isShareModal && this.renderDateFilters()}
        {_.isEmpty(globalFilters) ? null :
          <>
            {this.renderShowMoreBadge()}
            {this.renderShowLessBadge()}
          </>
        }
        {this.renderAddFilterBadge()}
      </div>
    );
  }
}

CollectionFilters.propTypes = {
  availableTemplateIds: PropTypes.array,
  collectionFilters: PropTypes.array,
  onCollectionFiltersChange: PropTypes.func,
  currentUser: PropTypes.object,
  disableOtherTemplateEntries: PropTypes.bool,
  isDisabledAddFilterButton: PropTypes.bool,
  hideShowMore: PropTypes.bool,
  isShareModal: PropTypes.bool,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentCollection: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  cardEntries: PropTypes.array,
  onDateFiltersChange: PropTypes.func,
  dateFilters: PropTypes.object,
  hideCollectionDateFilters: PropTypes.bool,
  isMyViewCollection: PropTypes.bool,
  disabledFilters: PropTypes.bool,
  isAnalysisPage: PropTypes.bool,
}

CollectionFilters.defaultProps = {
  disabledFilters: false,
  isAnalysisPage: false
}

export default (CollectionFilters);
