import * as actionTypes from '../actionTypes/overtimeActionTypes';
import { UPDATE_VISUALIZATION_REDUCER } from 'actions/visualizationActions';
import { getOvertimeState } from './helper';
import { AXIS_GRANULARITY_TYPES } from 'appConstants';

export const getInitialState = () => {
  return {
    axisGranularity: _.get(AXIS_GRANULARITY_TYPES[0], 'value'),
    sliderRange: [],
    showLegendTotalLine: true,
    isLegendItemsEmpty: false
  };
};

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.UPDATE_AXIS_GRANULARITY:
      return {
        ...state,
        axisGranularity: _.get(action, 'axisGranularity')
      }
    case actionTypes.UPDATE_OVERTIME_DIMENSION_SORT_ORDER:
        return {
          ...state,
          dimensionSortOrder: _.get(action, 'dimensionSortOrder')
        }
    case actionTypes.UPDATE_FORECAST_OPTION:
      return {
        ...state,
        currentForecastOption: _.get(action, 'currentForecastOption', '')
      }

    case actionTypes.UPDATE_OVERTIME_BENCH_MARK_NAME:
      return {
        ...state,
        currentBenchMarkMetricNames: _.get(action, 'name', '')
      }

    case actionTypes.UPDATE_OVERTIME_CHART_VIEW:
      return {
        ...state,
        currentChartView: _.get(action, 'selectedView', '')
      }

    case actionTypes.UPDATE_OVERTIME_COMPARE_TO_DATE_RANGE:
      return {
        ...state,
        compareYearRanges: _.get(action, 'dateRanges', [])
      }

    case actionTypes.UPDATE_OVERTIME_LEGENDS:
      return {
        ...state,
        dimensionConfigsByRenderType: _.get(action, 'dimensionConfigsByRenderType', {})
      }

    case actionTypes.UPDATE_OVERTIME_SECONDARY_METRIC:
      return {
        ...state,
        currentSecondaryMetricField: _.get(action, 'field', '')
      }

    case actionTypes.UPDATE_OVERTIME_SHOW_PROJECTION:
      return {
        ...state,
        isShowProjection: _.get(action, 'isShowProjection', {})
      }

    case actionTypes.UPDATE_OVERTIME_TIME_FRAME:
      return {
        ...state,
        currentSelectedTimeFrame: _.get(action, 'timeFrame', '')
      }

    case actionTypes.UPDATE_SLIDER_RANGE:
      return {
        ...state,
        sliderRange: _.get(action, 'sliderRange', [])
      }

    case actionTypes.UPDATE_VISUALIZATION_DEFAULTS:
      return {
        ...state,
        ...getOvertimeState(_.get(action, 'options'))
      }

    case actionTypes.UPDATE_OVERTIME_SHOW_LEGEND_TOTAL:
      return {
        ...state,
        showLegendTotalLine: _.get(action, 'showLegendTotalLine')
      }

    case actionTypes.UPDATE_OVERTIME_IS_EMPTY_LEGEND:
      return {
        ...state,
        isLegendItemsEmpty: _.get(action, 'isLegendItemsEmpty')
      }

    case UPDATE_VISUALIZATION_REDUCER:
      const currentChartView = _.get(action, 'visualization.overtime.currentChartView', '');
      const dimensionConfigs = _.get(action,
        `visualization.overtime.dimensionConfigsByRenderType[${currentChartView}]`, []);
      const visualizationOvertime = _.get(action, 'visualization.overtime', {});
      const overtimeOptions = _.merge({},
        visualizationOvertime, { dimensionConfigsByRenderType: dimensionConfigs});

      return {
        ...state,
        ...overtimeOptions
      }

    default:
      return state;
  }
}
