import React from 'react';
import PropTypes from 'prop-types';
import { ForgeDialog, ForgeButton } from '@tylertech/forge-react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const ReRunDialogModal = ({ showModal, setShowModal, onModalClick, onModalCancel }) => {

  const handleKeyDownConfirm = (e) => {
    if (isEnterButtonPressed(e)) {
      onModalClick();
    }
  };

  const handleKeyDownRemoveCard = (e) => {
    if (isEnterButtonPressed(e)) {
      setShowModal(false);
      onModalCancel();
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    onModalCancel();
  };

  const renderDeleteModal = ()  => {
    return (
      <ForgeDialog
        onDismiss={() => handleModalClose()}
        open={showModal}
      >
        <div className="modal-wrapper modal-container py-2 rerun-dialog-modal">
          <header className="forge-dialog__header align-items-center mb-1 pt-1" forge-dialog-move-target="">
            <span className='forge-typography--headline5'> Re-run forecasting models? </span>
          </header>

          <section className="forge-dialog__body">
            <div className="row">
              <div className="col-md-12">
                This kind of change requires forecasting models to update.
                Because there is new data available since the last time models were run,
                this may change your forecast.
              </div>
            </div>
          </section>

          <footer className="rerun-dialog-modal-footer px-4">
            <div>
            <ForgeButton type="outlined" className="mr-auto">
              <button
                aria-label="Cancel"
                tabIndex={0}
                onClick={() => handleModalClose()}
                onKeyDown={(event) =>
                  handleKeyDownRemoveCard(event)
                }
              >
                Cancel
              </button>
            </ForgeButton>

            <ForgeButton type="raised">
              <button
                aria-label="Confirm"
                tabIndex={0}
                onClick={(event) => onModalClick(event)}
                onKeyDown={(event) =>
                  handleKeyDownConfirm(event)
                }
              >
                Re-run models
              </button>
            </ForgeButton>
            </div>
          </footer>
        </div>
      </ForgeDialog>
    );
  }

  return (
    <div>
      { renderDeleteModal() }
    </div>
  );
}


ReRunDialogModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onModalClick: PropTypes.func,
  onModalCancel: PropTypes.func,
};

ReRunDialogModal.defaultProps = {
  onModalCancel: _.noop,
  onModalClick: _.noop,
  showModal: false,
  setShowModal: _.noop,
}

export default ReRunDialogModal;
