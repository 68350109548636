import React from "react";
import { ForgeIcon } from "@tylertech/forge-react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';

import { deleteCollaborateUser } from "common/api/collaborateApi";
import { updateUsers } from "./collaboratorActions";
import { toast } from "react-toastify";

const CollaboratorRemoveIcon = ({ user }) => {
  const users = useSelector((store) => store.users);
  const collectionId = useSelector((store) => _.get(store, 'collection.id'));
  const dispatch = useDispatch();

  const onUserRemove = async() => {
    try {
      await deleteCollaborateUser(collectionId, user);

      const newUsers = _.filter(users, (userItem) => {
        return userItem['id'] !== user['id'];
      })
      dispatch(updateUsers(newUsers, false, false));
      toast.success('User removed from collection');
    } catch (e) {
      console.log('Delete collaborate collection user failed', e);
    }
  }

  return (
    <ForgeIcon name="close" className="cursor-pointer" onClick={onUserRemove}></ForgeIcon>
  )
}

CollaboratorRemoveIcon.propTypes = {
  user: PropTypes.object
};

export default CollaboratorRemoveIcon;
