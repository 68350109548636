import React from 'react';
import { usePreview } from 'react-dnd-preview'
import PropTypes from 'prop-types';
const SCROLL_LENGTH = 50;
const MyPreview = ({ collectionTabRef }) => {
  const options = usePreview()
  const { display, item, itemType, style } = options;

  if (!display || itemType !=='collection') {
    return null
  }
  const boundingClientRect = collectionTabRef.current.getBoundingClientRect();
  const forgeTabBarElement = collectionTabRef.current.querySelector('forge-tab-bar');
  const forgeTabScrollerElement = forgeTabBarElement.
    shadowRoot.querySelector('.mdc-tab-scroller__scroll-area');
  const scrollLeft = forgeTabScrollerElement.scrollLeft;

  style['top'] = Number('-'+ boundingClientRect.top);
  style['left'] = scrollLeft - SCROLL_LENGTH;

  return (
    <div className="item-list__item dragging-item" style={style}>
      {item.collection.name}
    </div>
  );
}

MyPreview.propTypes = {
  collectionTabRef: PropTypes.object
};

export default MyPreview;
