import React from 'react'
import PropTypes from 'prop-types'
import { ForgeButton, ForgeBadge, ForgeTooltip } from '@tylertech/forge-react'

function MetricLibrary(props) {

  const { isManageMetricLibrary, onHandleMetricLibrary } = props;
  const handleClickMetricLibrary = () => {
    onHandleMetricLibrary(!isManageMetricLibrary);
  }

  return (
    <div>
      <ForgeButton type="outlined">
        <button className='metric-library-btn'
          variant="outline-primary"
          onClick={handleClickMetricLibrary}>
          Metric Library
          <div>
            <ForgeBadge id="beta" className='ml-1'>
              Beta
            </ForgeBadge>
          </div>
          <ForgeTooltip delay="500" position="top">
            The metric library is a new feature that is currently under
            development and will soon replace the All Metrics tab. If you’d like to
            weigh in on what you’d like to see, please send us a message via the feedback button.
          </ForgeTooltip>
        </button>
      </ForgeButton>
    </div>
  )
}

MetricLibrary.propTypes = {
  isManageMetricLibrary: PropTypes.bool,
  onHandleMetricLibrary: PropTypes.func
}

export default MetricLibrary
