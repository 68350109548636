// Vendor Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Project Imports
import ComparisonTooltip from 'common/components/ComparisonTooltip';
import ComparisonValueSelector from 'common/components/ComparisonValueSelector';

import { disableMetricTotal, getComparisonMode } from 'common/config/viewConfiguration';
import { updateStoreToUrl } from 'helpers/UrlParamsHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { getComparisonPeriodDateRanges } from 'helpers/dateHelper';
import {
  getCurrentVizBasedChartType,
  getCurrentSecondaryMetricFields
} from 'helpers/visualizationHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { trackEvent } from 'helpers/eventTracking';
import ViewMetricLink from 'pages/embed/ViewMetricLink';
import * as commonPropTypes from 'common/propTypes';
import { VISUALIZATION_TYPES, OVERTIME_VISUALIZATION_TYPES } from 'appConstants';
import { getComparisonTooltipAttributes } from 'helpers/comparisonHelper';
import { isComboChartEnable } from 'common/config/visualizationConfiguration';
class VisualizationHeader extends Component {
  state = {
    currentPeriodTotal: null,
    previousPeriodTotal: null,
    percentage: null,
    currentSecPeriodTotal: null,
    previousSecPeriodTotal: null,
    secondaryPercentage: null
  }

  handleOnComparisonDataLoaded = (isLoading, options = {}) => {
    const {
      currentPeriodTotal,
      previousPeriodTotal,
      percentage,
      currentSecPeriodTotal,
      previousSecPeriodTotal,
      secondaryPercentage
    } = options;
    this.setState({
      currentPeriodTotal,
      previousPeriodTotal,
      percentage,
      currentSecPeriodTotal,
      previousSecPeriodTotal,
      secondaryPercentage
    })
  }

  handleKeyDownShowProjection = (e) => {
    const { onClickProjectionButton, isShowProjection } = this.props;
    if (isEnterButtonPressed(e)) {
      trackEvent('enabled-projection');
      onClickProjectionButton(!isShowProjection);
    }
  }

  onClickProjectionButton = () => {
    const { onClickProjectionButton, isShowProjection } = this.props;
    trackEvent('enabled-projection');
    onClickProjectionButton(!isShowProjection);
  }

  getComparisonAttributes = (secondaryMetricField) => {
    const { drilldown } = this.props;
    const viewEntry = _.get(drilldown, 'currentDrilldownViewEntry', {});

    return {
      viewEntry,
      onComparisonDataLoaded: this.handleOnComparisonDataLoaded,
      secondaryMetricField
    };
  }

  renderViewSource() {
    const { state, isEmbed } = this.props;
    const url = updateStoreToUrl('!/analysis', state);
    const embedUrl = `${window.location.origin}/#${url}`;

    return (
      <div>
        <ViewMetricLink isEmbed={isEmbed} viewMetricLink={embedUrl} />
      </div>
    );
  }

  renderProjectionButton() {
    const { showProjectionButton, isShowProjection } = this.props;
    let projectionButtonContent = null;
    if (showProjectionButton && !isShowProjection) {
      projectionButtonContent = (
        <div className="d-flex justify-content-end projection-btn align-items-center mt-2">
          <button className="btn btn-outline-primary forecast-btn d-flex"
            onKeyDown={this.handleKeyDownShowProjection}
            onClick={this.onClickProjectionButton}>
            <i className="icons-forecast align-self-center mr-1" /> <span>Projections</span>
          </button>
        </div>
      );
    }

    return projectionButtonContent;
  }

  renderMetricTotal(metricEntry = null, showMetricLabel) {
    const { metricTotalData, isStackedChart, drilldown, commonFilters } = this.props;
    const viewEntry = _.get(drilldown, 'currentDrilldownViewEntry', {});

    if (disableMetricTotal(viewEntry)) {
      return null;
    }
    const isComparePrevious = _.get(metricEntry, 'isComparePrevious', false);
    const showSecondaryTotal = !_.isEmpty(metricEntry);
    const comparisonMode = getComparisonMode(viewEntry);
    const comparisonModeOn = _.get(commonFilters, 'comparisonModeOn', false);
    const showComparisonMode = comparisonMode !== 'off' && comparisonModeOn;
    const dateRangeMode = _.get(commonFilters, 'dateType');
    const dateRange = _.get(commonFilters, 'dateRange');
    const comparisonDateRanges = comparisonModeOn ? getComparisonPeriodDateRanges(commonFilters) : [];
    const metricName = showSecondaryTotal ? _.get(metricEntry, 'name') : getPrimaryMetricName(viewEntry);

    if (_.isNil(metricName)) {
      return null;
    }
    const metricLabelClassNames = classNames({
      "metric-label": showMetricLabel,
      "mr-1": showComparisonMode
    });
    const { currentPeriodMetricTotals } = metricTotalData;
    let metricTotalValue = _.get(currentPeriodMetricTotals, 'formattedTotalValue', 0);
    let tooltipId = 'primary-comparison';

    if (showSecondaryTotal) {
      const field = _.get(metricEntry, 'field');
      tooltipId = `secondary-${field}`;
      const defaultSecondaryTotal = _.get(currentPeriodMetricTotals, 'secondary_count', 0);
      metricTotalValue = _.get(currentPeriodMetricTotals, `formatted_${field}`, defaultSecondaryTotal);
    }
    const metricTotal = (
      <>
        {(showMetricLabel && !isStackedChart) ?
          <span className={metricLabelClassNames}>{metricName}</span> : null
        }
        {metricTotalValue}
      </>
    );

    if (showComparisonMode && !isComparePrevious) {
      const comparisonTooltipAttributes = getComparisonTooltipAttributes({
        metricTotalData, viewEntry,
        secondaryMetricEntry: metricEntry, tooltipId,
        comparisonDateRanges, dateRangeMode, dateRange
      });
      let comparisonContent = null;
      if (comparisonModeOn && !_.isEmpty(comparisonTooltipAttributes)) {
        comparisonContent = _.map(comparisonTooltipAttributes, (tooltipAttributeEntry, index) => {
          return (
            <ComparisonTooltip {...tooltipAttributeEntry}
              tooltipId={tooltipId} key={_.get(tooltipAttributeEntry, 'key', index)}>
              <div>
                <ComparisonValueSelector {...tooltipAttributeEntry} viewEntry={viewEntry} />
              </div>
            </ComparisonTooltip>
          )
        });
      }
      return (
        <div className="d-inline-flex align-items-center">
          <div className="d-flex">{metricTotal}</div>
          {comparisonContent}
        </div>
      )
    }

    return metricTotal;
  }

  renderMultiSecondaryTotalAndComparison() {
    const {
      currentSecondaryMetricField,
      currentChartView,
      currentVisualizationType,
      drilldown,
      commonFilters,
      currentVizBasedChartType,
      dimensionConfigsByRenderType,
      currentDrilldownViewEntry
    } = this.props;
    const viewEntry = _.get(drilldown, 'currentDrilldownViewEntry', {});
    let selectedSecondaryTotalLines = [];
    const currentSecondaryMetricFields = getCurrentSecondaryMetricFields(
      viewEntry,
      currentChartView,
      currentSecondaryMetricField,
      currentVisualizationType,
      commonFilters,
      currentVizBasedChartType
    );
    const isComboChart = isComboChartEnable(currentDrilldownViewEntry, currentChartView);
    const isOvertimeChart = (currentVisualizationType === _.get(VISUALIZATION_TYPES, ['OVERTIME', 'type']));

    if (isOvertimeChart && isComboChart) {
      selectedSecondaryTotalLines = _.chain(dimensionConfigsByRenderType)
        .filter({ 'isTotalLine': true, 'visible': true })
        .map('metricField').compact()
        .value()
    } else {
      selectedSecondaryTotalLines = _.map(currentSecondaryMetricFields, 'field');
    }

    if (_.isEmpty(currentSecondaryMetricFields) && _.isEmpty(currentSecondaryMetricField)) {
      return null;
    }

    return _.map(currentSecondaryMetricFields, (secondaryMetricEntry) => {
      if (_.includes(selectedSecondaryTotalLines, secondaryMetricEntry['field'])) {
        return this.renderMetricTotal(secondaryMetricEntry, true);
      }
    });
  }

  renderMetricTotalAndComparison() {
    const {
      currentSecondaryMetricField,
      currentChartView,
      currentVisualizationType,
      drilldown,
      isStackedChart,
      commonFilters,
      metricTotalLoading,
      metricTotalData
    } = this.props;

    if (!metricTotalLoading && _.isEmpty(metricTotalData)) {
      return null;
    }
    const viewEntry = _.get(drilldown, 'currentDrilldownViewEntry', {});
    const secondaryMetricFields = getCurrentSecondaryMetricFields(
      viewEntry,
      currentChartView,
      currentSecondaryMetricField,
      currentVisualizationType,
      commonFilters
    )
    const showMetricLabel = !_.isEmpty(secondaryMetricFields);
    return (
      <>
        <div className="vis-values-header minHeight">
          {metricTotalLoading && <forge-skeleton button></forge-skeleton>}
          <div className="metric-total">
            {!metricTotalLoading && this.renderMetricTotal(null, showMetricLabel)}
            {!metricTotalLoading && !isStackedChart && this.renderMultiSecondaryTotalAndComparison()}
          </div>
        </div>
        {this.renderProjectionButton()}
      </>
    );
  }

  render() {
    return this.props.render(null, this.renderMetricTotalAndComparison(), this.renderViewSource());
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  const currentVisualizationType = _.get(state, 'drilldown.currentVisualizationType');
  return {
    state,
    commonFilters: _.get(state, 'commonFilters', {}),
    comparisonModeOn: _.get(state, 'commonFilters.comparisonModeOn', false),
    mapOptions: _.get(state, 'visualization.mapOptions', {}),
    drilldown: _.get(state, 'drilldown', {}),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false),
    currentChartView: _.get(state,
      'visualization.overtime.currentChartView', OVERTIME_VISUALIZATION_TYPES.TIMELINE.type),
    currentVisualizationType,
    currentVizBasedChartType: getCurrentVizBasedChartType(
      currentVisualizationType, _.get(state, 'visualization', {})),
    currentSecondaryMetricField: _.get(state,
      `visualization.${currentVisualizationType}.currentSecondaryMetricField`),
    metricTotalData: _.get(state, 'metricTotal.totals', {}),
    metricTotalLoading: _.get(state, 'metricTotal.isLoading', false),
    dimensionConfigsByRenderType: _.get(state,
      `visualization.${currentVisualizationType}.dimensionConfigsByRenderType`),
    currentDrilldownViewEntry: _.get(state.drilldown, 'currentDrilldownViewEntry', {})
  };
}

VisualizationHeader.propTypes = {
  state: PropTypes.object,
  isEmbed: PropTypes.bool,
  drilldown: commonPropTypes.drilldownPropTypes,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  currentVisualizationType: PropTypes.string,
  currentSecondaryMetricField: PropTypes.string,
  showProjectionButton: PropTypes.bool,
  isShowProjection: PropTypes.bool,
  isStackedChart: PropTypes.bool,
  onClickProjectionButton: PropTypes.func,
  currentVizBasedChartType: PropTypes.string,
  setDrillDownTotal: PropTypes.func,
  dimensionConfigsByRenderType: PropTypes.array,
  currentChartView: PropTypes.string,
  render: PropTypes.func,
  metricTotalLoading: PropTypes.bool,
  metricTotalData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
}

VisualizationHeader.defaultProps = {
  showProjectionButton: false,
  isShowProjection: false,
  isStackedChart: false,
  onClickProjectionButton: _.noop
}

export default connect(mapStateToProps, mapDispatchToProps)(VisualizationHeader);
