import _ from 'lodash';
import { SNAPSHOT_VISUALIZATION_TYPES, SORT_BY_OPTIONS } from 'appConstants';
import { getSortDropdownOptions, getGroupBySortDropdownOptions } from 'helpers/visualizationHelper';
import { getViewEntryByField } from 'common/config/templateConfiguration';

const chartData = [];
const autosize = true;
const barmode = 'stack';
const margin = { l:20, t:15, b: 15, r: 10, pad: 5 };
const showticklabels = true;
const showgrid = false;
const zeroline = true;
const showcrossline = true;
const showdividers = true;
const dividercolor = 'block';
const dividerwidth = 1;
const displayModeBar = false;
const scrollZoom = false;
const editable = false;
const showLink = false;
const responsive = true;
const automargin = true;
const dragmode = false;
const barChartAreaHeightForCount = {
  0: 240,
  1: 150,
  2: 180,
  3: 210,
  4: 230,
  5: 250
};

export const getBarChartHeight = (barsCount, isCardView = false, chartType = '') => {
  if (isCardView) {
    return _.get(barChartAreaHeightForCount, barsCount, barChartAreaHeightForCount[0]);
  }
  const heightVal = chartType == 'parallel'? 40 : 30;
  return (barsCount * heightVal) + 60;
};

export const getBarChartData = (options, isCardView = false) => {
  let tickvals = [];
  let ticktext = [];

  _.each(options.chartData, (trace) =>{
    if (trace.tickvals) {
      tickvals = tickvals.concat(trace.tickvals);
      ticktext = ticktext.concat(trace.ticktext);
    }
  });
  const isCumulativeDistribution = _.get(options, 'isCumulativeDistribution');
  let barChartData =  {
    data: options.chartData || chartData,
    layoutOptions: {
      shapes: _.get(options,'shapes', []),
      showlegend: false,
      autosize: options.autosize || autosize,
      barmode: options.barmode || barmode,
      margin: options.margin || margin,
      bargap: options.bargap || _.includes(['distribution'], options['chartType']) ? '':0.5,
      dragmode : dragmode,
      showspikes: false,
      bargroupgap: _.includes(['group','parallel'],  options.barmode) ?
                    (isCumulativeDistribution ? '' : 0.2) : '',
      xaxis: {
        showticklabels: _.get(options, 'xAxisshowticklabels', showticklabels),
        showgrid: _.get(options, 'showgrid', showgrid),
        zeroline: _.get(options, 'zeroline', zeroline),
        showcrossline: _.get(options, 'showcrossline', showcrossline),
        automargin: _.get(options, 'automargin', automargin),
        side: _.get(options, 'xAxisSide', 'top'),
        tickprefix: _.get(options, 'xAxisPrefixText'),
        ticksuffix: _.get(options, 'xAxisSuffixText'),
        tickfont: {
          color:  _.get(options, 'xAxistickfont', '#1d2124'),
          family: isCardView ? 'Roboto' : '',
          size: isCardView ? 8 : _.get(options, 'xAxisTickLabelFontSize', 'auto')
        }
      },
      yaxis: {
        showticklabels: _.get(options, 'yAxisshowticklabels', showticklabels) || showticklabels,
        showdividers: _.get(options, 'showdividers', showdividers),
        dividercolor: _.get(options, 'dividercolor', dividercolor),
        dividerwidth: _.get(options, 'dividerwidth', dividerwidth),
        automargin: _.get(options, 'automargin', automargin),
        // TODO remove _.reverse and add autorange: 'reversed'
        // in order to draw chart from top to bottom
        tickfont: {
          family: isCardView ? 'Roboto' : '',
          size: isCardView ? 12 : _.get(options, 'yAxisTickLabelFontSize', 'auto')
        },
        tickprefix: _.get(options, 'yAxisPrefixText'),
        ticksuffix: _.get(options, 'yAxisSuffixText'),
      },
      xaxis2: {
        showticklabels: _.get(options, 'xAxisshowticklabels2', showticklabels),
        showgrid: _.get(options, 'showgrid', showgrid),
        zeroline: _.get(options, 'zeroline', zeroline),
        showcrossline: _.get(options, 'showcrossline', showcrossline),
        automargin: _.get(options, 'automargin', automargin),
        overlaying: 'x',
        side: _.get(options, 'xAxis2Side', 'bottom'),
        tickfont: {color:  _.get(options, 'xAxis2tickfont', '#1d2124')},
        tickprefix: _.get(options, 'xAxis2PrefixText'),
        ticksuffix: _.get(options, 'xAxis2SuffixText')
      },
      annotations: ( options.showAnnotations ? options.annotations : '')
    },
    config: {
      displayModeBar: _.get(options, 'displayModeBar', displayModeBar),
      scrollZoom: _.get(options, 'scrollZoom', scrollZoom),
      editable: _.get(options, 'editable', editable),
      showLink: _.get(options, 'showLink', showLink),
      responsive: _.get(options, 'responsive', responsive),
    }
  };
  if(options.isTrunckXaxisTickLabels) {
    barChartData['layoutOptions']['xaxis']['tickvals'] = _.get(options, 'xaxis.tickvals', []);
    barChartData['layoutOptions']['xaxis']['ticktext'] = _.get(options, 'xaxis.ticktext', []);
    barChartData['layoutOptions']['xaxis2']['tickvals'] = _.get(options, 'xaxis2.tickvals', []);
    barChartData['layoutOptions']['xaxis2']['ticktext'] = _.get(options, 'xaxis2.ticktext', []);
  }
  if(options.isTrunckYaxisTickLabels) {
    barChartData['layoutOptions']['yaxis']['tickvals'] = _.get(options, 'yaxis.tickvals', []);
    barChartData['layoutOptions']['yaxis']['ticktext'] = _.get(options, 'yaxis.ticktext', []);
  }
  if(!_.isUndefined(options.height)) {
    barChartData['layoutOptions']['height'] = options.height;
  }
  if(options.showlegend){
    barChartData['layoutOptions']['legend']= { orientation: "h" };
  }
  if (!_.isEmpty(tickvals)) {
    barChartData['layoutOptions']['yaxis']['tickvals'] = tickvals;
    barChartData['layoutOptions']['yaxis']['ticktext'] = ticktext;
  }

  if(_.includes(['group','bullet'], options.barmode) && !_.isEmpty(options.range)) {
    const minRange = _.min([options.range[0], options.range_for_xaxis_2[0]]);
    const maxRange = _.max([options.range[1], options.range_for_xaxis_2[1]]);

    let xaxisEnd = options.range[1];
    let xaxis2End = options.range_for_xaxis_2[1];

    // if data has negative values, then we setting range as for both axis even if its
    // isIndependentAxesValues, so center position of axis(0) wont move
    if(options.isIndependentAxesValues && minRange >= 0) {
      barChartData['layoutOptions']['xaxis']['range'] = [minRange, xaxisEnd];
      barChartData['layoutOptions']['xaxis2']['range'] = [minRange, xaxis2End];
    } else {
      barChartData['layoutOptions']['xaxis']['range'] = [minRange, maxRange];
      barChartData['layoutOptions']['xaxis2']['range'] = [minRange, maxRange];
    }
  }

  // Distribution chart not needed some layout option
  if(_.includes(['distribution'], options['chartType'])) {
    barChartData['layoutOptions']['yaxis']['zeroline'] = true;
    barChartData['layoutOptions']['yaxis']['showcrossline'] = true;
    barChartData['layoutOptions']['xaxis']['zeroline'] = false;
    barChartData['layoutOptions']['xaxis']['showcrossline'] = false;
    barChartData['layoutOptions']['hovermode'] = 'x';
  } else {
    barChartData['layoutOptions']['yaxis']['type'] = _.get(options, 'yaxisCategoryType', 'category');
    barChartData['layoutOptions']['yaxis']['dtick'] = 1;
    barChartData['layoutOptions']['hovermode'] = 'y';
    barChartData['layoutOptions']['yaxis']['autorange'] = _.get(options, 'autorange', true);
  }

  return barChartData;
};

export const getAnnotationData = (options) => {
  return {
    x: options.x,
    y: options.y,
    text: options.text,
    showarrow: options.showarrow  || false,
    xanchor: options.xanchor || 'left'
  }
};

export const getBarData = (options) => {
  let barDataOptions = {
    x: options.x,
    y: options.y,
    name: options.name,
    type: 'bar',
    orientation: options.orientation  || 'h',
    text: options.text,
    hoverinfo: options.hoverinfo || 'text',
    traceId: options.traceId,
    visible: _.get(options, 'isVisible', true),
    marker:{
      color: options.color
    }
  }
  if(!_.isUndefined(options.width)) {
    barDataOptions['width'] = _.get(options,'isCardView', false) ? '' : options.width;
  }
  if(!_.isUndefined(options.offset)) {
    barDataOptions['offset'] = options.offset;
  }
  if(!_.isUndefined(options.customdata)) {
    barDataOptions['customdata'] = options.customdata;
  }
  if(!_.isUndefined(options.xaxis)) {
    barDataOptions['xaxis'] = options.xaxis;
  }
  if(!_.isUndefined(options.legendgroup)) {
    barDataOptions['legendgroup'] = options.legendgroup;
  }
  if(!_.isUndefined(options.showlegend)) {
    barDataOptions['showlegend'] = options.showlegend;
  }
  if (!_.isUndefined(options.tickvals) && !_.isUndefined(options.ticktext)) {
    barDataOptions['tickvals'] = options.tickvals;
    barDataOptions['ticktext'] = options.ticktext;
  }
  return barDataOptions;
};

export const isBarchart = (currentSnapshotView) => {
  return _.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
};

// this function is to find the sorting order of barchart. (i.e: 'a-z' | 'z-a' | 'asc' | 'desc').
export const getSortByType = (vizOptions, isGroupChart = false) => {
  const { apiParams } = vizOptions;
  const { drilldownEntry, sortBy, groupSortBy, sortOrder, groupSortOrder} = apiParams;
  const parsedDrilldownEntry = JSON.parse(drilldownEntry || {});
  const sortByField = isGroupChart ? groupSortBy : sortBy;
  const orderValue = isGroupChart ? groupSortOrder : sortOrder;
  const {
    currentViewEntryField,
    currentDrilldownDimensionField,
    currentDrilldownTemplateId,
    currentDrilldownGroupByEntry
  } = parsedDrilldownEntry;

  const viewEntry = getViewEntryByField(currentDrilldownTemplateId, currentViewEntryField);
  let sortOptions = [];
  if (isGroupChart) {
    sortOptions = getGroupBySortDropdownOptions(
      currentDrilldownGroupByEntry, viewEntry, currentDrilldownTemplateId);
  } else {
    sortOptions = getSortDropdownOptions(
      currentDrilldownDimensionField, viewEntry, currentDrilldownTemplateId);
  }
  let currentOption = _.find(sortOptions, (option) => {
    return (_.isEqual(option['sortBy'], sortByField)) && (_.isEqual(option['sortOrder'], orderValue));
  });
  return _.isEmpty(currentOption) ? sortOptions[0] : currentOption;

}


export const sortGroupByData = (data, sortOption, forceSortCategory=null) => {
  const { sortOrder, orderValues } = sortOption;
  const groupSortTypes = _.chain(SORT_BY_OPTIONS).map('type').take(2).value();
  let sortCategory = _.includes(groupSortTypes, _.get(sortOption, 'type')) ? 'category' : 'count';
  sortCategory = sortOption['isCustomSort'] ? 'category' : sortCategory;
  sortCategory = forceSortCategory || sortCategory;

  let customSortData = [];
  let groupSortData = [];
  _.each(data, (item) => {
    if(_.includes(orderValues, item[sortCategory])) {
      customSortData.push(item);
    } else {
      groupSortData.push(item);
    }
  })
  let sortedData = _.orderBy(groupSortData, [sortCategory], [sortOrder]);
  customSortData = customSortData.sort(function (a, b) {
      return orderValues.indexOf(a.id) - orderValues.indexOf(b.id);
  });
  return sortedData.concat(customSortData);
}