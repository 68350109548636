import { VIEW_MODE } from 'modules/visualization/constants';
import { toLineTrace } from './timeline';
import { getPeriodType, isTraceVisible } from '../vizOptionsHelper';
import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';

export const toPlotlyTraces = (vizOptions, formattedData) => {
  const {
    viewMode, templateId, viewEntry, axisGranularity,
     dateRangeMode, dateRange
  } = vizOptions;
  const nullValueLabel = getNullValueLabel(templateId);
  const isSmallView = viewMode === VIEW_MODE.SMALL;
  const periodType = getPeriodType(vizOptions);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);
  let dimensionConfigs = _.get(vizOptions, 'dimensionConfigs', []);
  if(_.isEmpty(dimensionConfigs)) {
    dimensionConfigs = _.get(formattedData, 'dimensionConfigs', []);
  }

  const formatData = _.get(formattedData,'current.entries', []);
  const data = _.chain(formatData).
    values().
    map((dataItems, index) => {
      const dimension = _.get(dataItems, '[0].dimension');
      const traceId = _.isEmpty(dimension)? nullValueLabel: dimension;
      const trace = toLineTrace(dataItems, 'value', {
        meta: { segmentType: 'current', dimension: traceId, value: 'primary' },
        lineColor: _.get(_.find(dimensionConfigs, {traceId}), 'areaChartColor'),
        lineWidth: 2,
        visible: isTraceVisible(vizOptions, { traceId }),
        isSecondsFormat,
        dataUnit,
        periodType,
        axisGranularity,
        isSmallView,
        dateRangeMode, dateRange
      });

      trace.fill = (index === 0) ? 'tozeroy' : 'tonexty';
      trace.stackgroup = 'one';
      return trace;
    }).
    value();

  return data;
};
