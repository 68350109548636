import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { updateRadarDrawingStateChange } from 'actions/mapOptionsActions';

class RadarPolygonFilterButton extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  handleMapPolygonFilter = () => {
    this.props.dispatchUpdateRadarDrawingStateChange(true);
  }

  handleClickCancelButton = () => {
    this.props.dispatchUpdateRadarDrawingStateChange(false);
  }

  renderDrawButton() {
    const { isRadarDrawingEnabled } = this.props;

    if(isRadarDrawingEnabled) {
      return null;
    }

    return (
      <>
        <OverlayTrigger
          key="create-polygon-button"
          placement="left"
          overlay={
            <Tooltip id="tooltip-top">
              <div className="text-left">Click to apply a lasso mode.</div>
            </Tooltip>
          }>
          <Button
            variant='outline-primary'
            className='draw-ctrl-btn'
            title="Select by lasso"
            onClick={() => this.handleMapPolygonFilter()}
            >
              <i className="icons-lasso"></i>
              <span>Select by lasso</span>
          </Button>
        </OverlayTrigger>
      </>
    );
  }

  renderDrawingInProgressOverlay() {
    const { isRadarDrawingEnabled } = this.props;

    if(!isRadarDrawingEnabled) {
      return null;
    }

    const cancelDrawingBtnContent = <button
      className="btn btn-link cancel-btn align-self-center"
      onClick={() => this.handleClickCancelButton()}>Cancel</button>;

    return (
      <div className="map-custom-control-overlay d-flex justify-content-between">
        <div className="pl-2 pr-2 align-self-center">

          <span className="d-none d-lg-block">
            Click and drag to lasso the shapes you’d like to select.
            Click “Cancel” to exit out of lasso selection mode.
          </span>

          <span className="d-block d-lg-none">
            Click and drag to lasso
          </span>
        </div>

        {cancelDrawingBtnContent}
      </div>
    );
  }

  render() {
    return(
      <div className="map-custom-control ml-auto">
        {this.renderDrawButton()}
        {this.renderDrawingInProgressOverlay()}
      </div>
    );
  }
}

RadarPolygonFilterButton.propTypes = {
  dispatchUpdateRadarDrawingStateChange: PropTypes.func,
  dispatchUpdateRadarDrawingLineStateChange: PropTypes.func,
  isRadarDrawingEnabled: PropTypes.bool,
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateRadarDrawingStateChange: (toggle) => {
      dispatch(updateRadarDrawingStateChange(toggle))
    }
  }
}

function mapStateToProps(state) {
  return {
    isRadarDrawingEnabled: _.get(state, 'visualization.mapOptions.isRadarDrawingEnabled', false)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RadarPolygonFilterButton);
