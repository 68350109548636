import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import FreehandMode from "mapbox-gl-draw-freehand-mode";
import turfBbox from '@turf/bbox';
import turfBoolIntersects from '@turf/boolean-intersects';
import turfBooleanContains from '@turf/boolean-contains';

import { drawControlCustomStyle } from './drawControlStyles';
import { updateDrawingStateChange } from 'actions/mapOptionsActions';

class ChoroplethDrawControl extends Component {
  constructor(props) {
    super(props);

    const { map, onUpdateDraw, pointMapDraw, choroplethMapDraw } = this.props;
    if(pointMapDraw) {
      map.removeControl(pointMapDraw);
      onUpdateDraw(null, false);
    }
    if(choroplethMapDraw) {
      this._draw = choroplethMapDraw;
    } else {
      this._draw = new MapboxDraw({ // eslint-disable-line no-undef
        displayControlsDefault: false,
        controls: {
          polygon: false,
          trash: false
        },
        modes: Object.assign(MapboxDraw.modes, {  // eslint-disable-line no-undef
          draw_polygon: FreehandMode
        }),
        styles: drawControlCustomStyle
      });

      map.addControl(this._draw, 'top-right');
    }

    map.on('draw.create', this.updateArea);
    map.on('draw.delete', this.updateArea);
    map.on('draw.update', this.updateArea);
    onUpdateDraw(this._draw, true);
  }

  componentWillUnmount() {
    const { map } = this.props;
    if (map && this._draw) {
      // map.removeControl(this._draw);
      map.off('draw.create', this.updateArea);
      map.off('draw.delete', this.updateArea);
      map.off('draw.update', this.updateArea);
    }
  }

  componentDidUpdate(prevProps) {
    const { isDrawingEnabled } = this.props;
    if ((prevProps !== this.props) && isDrawingEnabled) {
      this.enablePolygonDrawing();
    }
    if ((prevProps !== this.props) && !isDrawingEnabled) {
      this.disablePolygonDrawing();
    }
  }

  enablePolygonDrawing = () => {
    var drawMode = 'draw_polygon';
    if (this._draw.getMode() != drawMode) {
      this._draw.changeMode(drawMode);
    }
  }

  disablePolygonDrawing = () => {
    if (this._draw.getMode() != 'simple_select') {
      this._draw.deleteAll();
      this._draw.changeMode('simple_select');
    }
  }

  updateArea = () => {
    const { map, toggleShapeIdsFilter, selectedShapeIds, onDrawUpdate } = this.props;
    onDrawUpdate();
    const data = this._draw.getAll();
    if (!data || !data.features || !data.features[0]) {
      return;
    }

    const filterPolygon = data.features[0];
    const bbox = turfBbox(filterPolygon);
    const featuresInBBox = map.queryRenderedFeatures(
      [
        map.project([bbox[0], bbox[1]]),
        map.project([bbox[2], bbox[3]]),
      ],
      { layers: ['shapes-fill-layer', 'shapes-fill-highlight-layer', 'shapes-line'] }
    );

    const filteredFeatures = featuresInBBox.filter(
        (feature) => this.isWithinOrIntersecting(filterPolygon, feature));

    let shapeIds = filteredFeatures.map((feature) => feature.properties.shape_id);
    if(!_.isEmpty(selectedShapeIds)){
      shapeIds = shapeIds.concat(selectedShapeIds);
    }
    map.setFilter('shapes-fill-highlight-layer', ['in', 'shape_id', ...shapeIds]);
    toggleShapeIdsFilter(_.uniqBy(shapeIds));

    if (this._draw.getMode() != 'simple_select') {
      this.disablePolygonDrawing();
    }
  }

  isWithinOrIntersecting = (filterPolygon, feature) => {
    if (feature.geometry.type == 'MultiPolygon') {
      return turfBoolIntersects(filterPolygon, feature);
    } else {
      return turfBoolIntersects(filterPolygon, feature) || turfBooleanContains(filterPolygon, feature);
    }
  }

  render() {
    return null;
  }
}

ChoroplethDrawControl.propTypes = {
  map: PropTypes.object,
  onUpdateDraw: PropTypes.func,
  pointMapDraw: PropTypes.object,
  choroplethMapDraw: PropTypes.object,
  isDrawingEnabled: PropTypes.bool,
  dispatchUpdateDrawingStateChange: PropTypes.func,
  toggleShapeIdsFilter: PropTypes.func,
  onDrawUpdate: PropTypes.func,
  selectedShapeIds: PropTypes.array
};

ChoroplethDrawControl.defaultProps = {
  selectedShapeIds: []
};

const mapDispatchToProps = {
  dispatchUpdateDrawingStateChange: updateDrawingStateChange
};

const mapStateToProps = (state) => {
  return {
    isDrawingEnabled: _.get(state, 'visualization.mapOptions.isDrawingEnabled', false)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoroplethDrawControl);
