import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InlineEdit from 'pages/dashboard/components/InlineEdit';
import {
  getRadarPageTitle
} from 'common/config/customerConfiguration';
import { isEllipsisActive, isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { DEFAULT_DESCRIPTION } from 'appConstants';
import { ForgeIcon } from '@tylertech/forge-react';

class BookmarkTitleDescription extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showTitleEditInput: false,
      showDescriptionEditInput: false,
    };
  }

  componentDidMount() {
    document.body.classList.add('viz-body-title')
  }

  componentWillUnmount() {
    document.body.classList.remove('viz-body-title')
  }

  handlePressEditInputButton = (e, flag) => {
    if (isEnterButtonPressed(e)) {
      this.setState({ [flag]: true });
    }
  }

  handleTitleChange = (title) => {
    const { onNameDescriptionChange, description } = this.props;
    onNameDescriptionChange(title, description);
    this.setState({ showTitleEditInput: false });
  }

  handleDescriptionChange = (description) => {
    const { onNameDescriptionChange, title } = this.props;
    onNameDescriptionChange(title, description);
    this.setState({ showDescriptionEditInput: false });
  }

  renderBasedMessage() {
    const { currentBookmark, onDefaultRadarClick, hideBasedOnMessage } = this.props;
    if (!_.isEmpty(currentBookmark) && !hideBasedOnMessage) {
      return (
        <div className="tx-15 font-weight-normal mb-2">Based on
          <span
            className="text-primary pointer"
            onClick={onDefaultRadarClick}>
            {` ${getRadarPageTitle()}`}
          </span>
        </div>
      )
    }
  }



  renderDescription() {
    const { showDescriptionEditInput } = this.state;
    const { description } = this.props;
    return (
        <InlineEdit
          onEditClose={() => this.setState({ showDescriptionEditInput: false })}
          onInputChange={this.handleDescriptionChange}
          enableInputEdit={showDescriptionEditInput}
          defaultText={description}>
          <span tabIndex={0}
            className="viz-description-title forge-typography--body2"
            onClick={() => this.setState({ showDescriptionEditInput: true })}
            onKeyDown={(e) => this.handlePressEditInputButton(e, 'showDescriptionEditInput')}
            aria-label={`Radar description - ${description}`}>
            <div className="viz-description-text">{description}</div>
            {_.isEmpty(description) && <span className='placeholder'>{DEFAULT_DESCRIPTION}</span>}
            <ForgeIcon name="mode_edit" className="edit-icon desc-edit-icon" />
          </span>
        </InlineEdit>
    )
  }

  renderTitle() {
    const { showTitleEditInput } = this.state;
    const { title } = this.props;
    return (
        <InlineEdit
          onEditClose={() => this.setState({ showTitleEditInput: false })}
          onInputChange={this.handleTitleChange}
          enableInputEdit={showTitleEditInput}
          defaultText={title}>
          <span tabIndex={0}
            className="viz-title forge-typography--title"
            onClick={() => this.setState({ showTitleEditInput: true })}
            onKeyDown={(e) => this.handlePressEditInputButton(e, 'showNameEditInput')}
            aria-label={`Radar Title - ${title}`}>
            <div className="viz-title-text">{title}</div>
            {isEllipsisActive && <forge-tooltip
              position="bottom"
              target=".viz-title-text"
              text={title}
            />}
            <ForgeIcon name="mode_edit" className="edit-icon" />
          </span>
        </InlineEdit>
    )
  }

  render() {
    const { currentBookmark, title, description, showInputEditByDefault } = this.props;
    if (_.isEmpty(currentBookmark) && !showInputEditByDefault) {
      return (
        <div>
          <div className="page-title">{title}</div>
          <div className="page-description ">{description}</div>
        </div>
      )
    } else {
      return (
        <div className="title-description-info">
          <div className="page-title">
            <div className='page-title-back'>
              {this.props.renderBackButton}
              {this.renderTitle()}
            </div>
            {this.props.renderActionButtons}
          </div>
          {this.renderBasedMessage()}
          <div className="page-description">{this.renderDescription()}</div>
        </div>
      )
    }
  }
}
BookmarkTitleDescription.defaultProps = {
  hideBasedOnMessage: false,
  showInputEditByDefault: false
}

BookmarkTitleDescription.propTypes = {
  renderBackButton: PropTypes.node,
  renderActionButtons: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  currentBookmark: PropTypes.object,
  onNameDescriptionChange: PropTypes.func,
  onDefaultRadarClick: PropTypes.func,
  hideBasedOnMessage: PropTypes.bool,
  showInputEditByDefault: PropTypes.bool
}

export default BookmarkTitleDescription;
