import { getGlobalFiltersFromTemplate } from 'common/config/customerConfiguration';
import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import { getFiltersDescription } from 'helpers/displayNameHelper';

export const isGlobalFilterConfigWithinTemplate = (templateId, configEntry) => {
  const templateEntry = getCurrentTemplateEntry(templateId);

  const globalFilterConfigEntries = _.filter(getGlobalFiltersFromTemplate(templateEntry), (entry) => {
    return !_.isEmpty(_.get(entry, 'column'));
  });
  const matchedEntry = _.find(globalFilterConfigEntries, { field: configEntry.field });
  return !_.isEmpty(matchedEntry)
}

export const getGlobalFiltersDescriptionsByTemplate = (templateId, globalFilters) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  const globalFilterConfigEntries = _.filter(getGlobalFiltersFromTemplate(templateEntry), (entry) => {
    return !_.isEmpty(_.get(entry, 'column'));
  });
  return getFiltersDescription(globalFilters, globalFilterConfigEntries, templateId);
}
