import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';

import InlineEdit from 'pages/dashboard/components/InlineEdit';
import { getBookmark, updateBookmark } from 'common/api/bookmarksApi';
import { getDefaultVisualizationView } from 'common/config/visualizationConfiguration';
import { updateCurrentBookmark, updateCanUpdateBookmarkViewCount } from 'actions/bookmarkActions';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  updateDrilldownViewEntry,
  updateQuickFilters,
  setDrilldownDimensions,
  setCurrentVisualizationType
} from 'actions/drilldownActions';
import { updateOvertimeChartView, updateSliderRange } from 'actions/overtimeActions';
import {
  setDefaultDrilldownMapOptions,
  toggleShapeIdsFilter,
} from 'actions/mapOptionsActions';
import { updateCurrentSnapshotView } from 'actions/snapshotActions';
import { getDefaultVisualizationChartView } from 'helpers/visualizationHelper';
import { VISUALIZATION_TYPES,
  DEFAULT_DESCRIPTION
} from 'appConstants';
import { trackEvent } from 'helpers/eventTracking';
import { templateIdPropTypes } from 'common/propTypes';
import { isViewerRole } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
import { getBookmarkType } from 'pages/drilldown/components/Bookmark/bookmarkHelper'

class VisualizationTitleInfo extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      currentBookmark: {},
      showNameEditInput: false,
      showDescriptionEditInput: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.fetchBookmark();
  }

  componentDidUpdate(prevProps) {
    const { currentBookmarkId } = this.props;
    if (!_.isEqual(currentBookmarkId, _.get(prevProps, 'currentBookmarkId'))) {
      this.fetchBookmark();
    }
  }
  handlePressEditInputButton = (e) => {
    if (isEnterButtonPressed(e)) {
      this.setState({ showNameEditInput: true });
    }
  }

  handlePressEditDescInputButton = (e) => {
    if (isEnterButtonPressed(e)) {
      this.setState({ showDescriptionEditInput: true });
    }
  }

  fetchBookmark = () => {
    const {
      currentBookmarkId, dispatchUpdateBookmark, canUpdateBookmarkViewCount,
      dispatchUpdateCanUpdateBookmarkViewCount
    } = this.props;

    if (!_.isEmpty(currentBookmarkId)) {
      this.setState({ isLoading: true });
      getBookmark(currentBookmarkId, { canUpdateBookmarkViewCount })
        .then((response) => response.json())
        .then((response) => {
          if (_.isEmpty(response)) {
            this.setState({ currentBookmark: {}, isLoading: false });
            dispatchUpdateBookmark({})
            dispatchUpdateCanUpdateBookmarkViewCount();
          } else {
            this.setState({ currentBookmark: response, isLoading: false });
            dispatchUpdateBookmark(response)
          }
        });
    }
  }

  handleBookmarkNameChange = (name) => {
    const { currentBookmark } = this.state;
    const { dispatchUpdateBookmark } = this.props;
    if (_.isEmpty(name)) {
      return null;
    }
    const newBookmark = {
      ...currentBookmark,
      name
    };
    trackEvent('confirm_view_title');
    updateBookmark(currentBookmark.id, newBookmark)
      .then((response) => response.json())
      .then((response) => {
        dispatchUpdateBookmark(response);
        this.setState({
          currentBookmark: response,
          showNameEditInput: false
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err); // eslint-disable-line no-console
      });
  }

  handleBookmarkDescriptionChange = (description) => {
    const { currentBookmark } = this.state;
    const { dispatchUpdateBookmark } = this.props;
    if (_.isEmpty(description)) {
      return null;
    }

    let newBookmark = currentBookmark;

    if (!_.isUndefined(newBookmark.bookmarkOptions.description)) {
      newBookmark.bookmarkOptions.description = description
    }

    trackEvent('confirm_view_description');
    updateBookmark(currentBookmark.id, newBookmark)
      .then((response) => response.json())
      .then((response) => {
        dispatchUpdateBookmark(response);
        this.setState({
          currentBookmark: response,
          showDescriptionEditInput: false
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err); // eslint-disable-line no-console
      });
  }

  renderBasedOnValue() {
    const {
      currentBookmarkId,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      dispatchUpdateViewEntry
    } = this.props;
    const { isLoading } = this.state;

    if (_.isEmpty(currentBookmarkId) || isLoading) {
      return null;
    }
    return (
      <>
        <div
          tabIndex={0}
          className="based-on-value"
          title={`Visualization Title - ${currentDrilldownViewEntry.name}`}>
          <div>Based on
            <span
              className="view-entry"
              onClick={() => dispatchUpdateViewEntry(currentDrilldownViewEntry, currentDrilldownTemplateId)}>
              {` ${currentDrilldownViewEntry.name}`}
            </span>
          </div>
        </div>
      </>
    );
  }

  renderVizTitle() {
    const { currentBookmark, showNameEditInput, isLoading } = this.state;
    const { currentBookmarkId, visualizationTitle, manageCollection } = this.props;
    let vizTitle = visualizationTitle;
    if (isLoading) {
      return null;
    }
    if (!_.isEmpty(currentBookmarkId)) {
      vizTitle = _.get(currentBookmark, 'name', "");
      return (
        <div className="bookmark-saved-title">
          {isViewerRole(manageCollection) ? vizTitle:
            <InlineEdit
              onEditClose={() => {
                this.setState({ showNameEditInput: false })
              }}
              onInputChange={this.handleBookmarkNameChange}
              enableInputEdit={showNameEditInput}
              defaultText={vizTitle}>
              <span
                tabIndex={0}
                className="viz-title"
                onClick={() => {
                  trackEvent('initiate_edit_view_title');
                  this.setState({ showNameEditInput: true })
                }
                }
                onKeyDown={this.handlePressEditInputButton}
                title={`Visualization Title - ${vizTitle}`}>
                {vizTitle} <ForgeIcon name="mode_edit" className="edit-icon" />
              </span>
            </InlineEdit>
          }
        </div>
      );
    }
  }

  renderMetricDescription() {
    const { currentBookmark, showDescriptionEditInput, isLoading } = this.state;
    const { currentBookmarkId, manageCollection } = this.props;

    if (isLoading || _.isEmpty(currentBookmarkId)) {
      return null;
    }

    const bookmarkDescription =  _.get(currentBookmark, 'bookmarkOptions.description');
    const defaultBookmarkDescription = bookmarkDescription ||
      <span className='placeholder'>{DEFAULT_DESCRIPTION}</span>;
    const bookmarkDescriptionString = bookmarkDescription || DEFAULT_DESCRIPTION;

    return (
      <div className="bookmark-saved-title bookmark-saved-description">
        {isViewerRole(manageCollection) ? <span className='based-on-value'>{bookmarkDescription}</span> :
          <InlineEdit
            onEditClose={() => {
              this.setState({ showDescriptionEditInput: false })
            }}
            onInputChange={this.handleBookmarkDescriptionChange}
            enableInputEdit={showDescriptionEditInput}
            defaultText={bookmarkDescription}>
            <span tabIndex={0}
              className="based-on-value"
              onClick={() => {
                trackEvent('initiate_edit_view_description');
                this.setState({ showDescriptionEditInput: true })
              }
              }
              onKeyDown={this.handlePressEditDescInputButton}
              title={`Visualization Description - ${bookmarkDescriptionString}`}>
              {defaultBookmarkDescription}
              {defaultBookmarkDescription &&
                <ForgeIcon name="mode_edit" className="edit-icon desc-edit-icon" />
              }
            </span>
          </InlineEdit>
        }
      </div>
    );
  }

  renderBack() {
    const { onBackClick, backListItems, isEmbed } = this.props;

    if(isEmbed){
      return null
    }

    return (
      <ForgeIconButton dense="true" densityLevel="3" className="forge-back-btn">
        <button
          type="button"
          onClick={() => onBackClick(backListItems)}
          aria-label="Toggle favorite">
          <ForgeIcon name="arrow_back" />
        </button>
      </ForgeIconButton>
    )
  }

  render() {
    const {
      resetFilterContent,
      viewSourceContent,
      metricTotalContent,
      visualizationTitle,
      currentBookmarkId,
      isRedirectHome
    } = this.props;

    if (isRedirectHome) {
      return <Redirect push to="/" />;
    }

    return (
      <>
        <div className="vis-title">
          <div className="d-flex align-items-center">
            {this.renderBack()}
            {this.renderVizTitle()}
            {_.isEmpty(currentBookmarkId) ?
              <div className="viz-title viz-header" tabIndex="0">{visualizationTitle}</div> : null}
          </div>
          {this.renderBasedOnValue()}
          {this.renderMetricDescription()}

          {viewSourceContent}
        </div>
        {resetFilterContent}
        {metricTotalContent}
      </>
    )
  }
}

VisualizationTitleInfo.propTypes = {
  currentBookmarkId: PropTypes.string,
  currentDrilldownTemplateId: templateIdPropTypes,
  visualizationTitle: PropTypes.string,
  resetFilterContent: PropTypes.node,
  viewSourceContent: PropTypes.node,
  metricTotalContent: PropTypes.node,
  currentDrilldownViewEntry: PropTypes.object,
  dispatchUpdateBookmark: PropTypes.func,
  dispatchUpdateViewEntry: PropTypes.func,
  onBackClick: PropTypes.func,
  dispatchUpdateCanUpdateBookmarkViewCount: PropTypes.func,
  backListItems: PropTypes.array,
  isRedirectHome: PropTypes.bool,
  isEmbed: PropTypes.bool,
  currentDrilldownTemplateName: PropTypes.string,
  canUpdateBookmarkViewCount: PropTypes.bool,
  manageCollection: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUpdateBookmark: (bookmark) => {
      const { id, name } = bookmark;
      const bookmarkType = getBookmarkType(bookmark);
      dispatch(updateCurrentBookmark(id, name, bookmarkType));
    },
    dispatchUpdateCanUpdateBookmarkViewCount: () => {
      dispatch(updateCanUpdateBookmarkViewCount(false));
    },
    dispatchUpdateViewEntry: (entry, templateId) => {
      const defaultView = getDefaultVisualizationView(entry);
      const defaultChartView = getDefaultVisualizationChartView(entry, defaultView);

      dispatch(updateDrilldownViewEntry(entry));
      dispatch(updateCurrentBookmark());
      dispatch(updateSliderRange([]));
      dispatch(updateQuickFilters([]));
      dispatch(setDrilldownDimensions([]));
      dispatch(toggleShapeIdsFilter([], null));
      dispatch(setDefaultDrilldownMapOptions(templateId, entry, true));
      dispatch(setCurrentVisualizationType(defaultView));
      if (defaultView == VISUALIZATION_TYPES.SNAPSHOT.type) {
        dispatch(updateCurrentSnapshotView(defaultChartView));
      } else if (defaultView == VISUALIZATION_TYPES.OVERTIME.type) {
        dispatch(updateOvertimeChartView(defaultChartView));
      }
    }
  }
};

function mapStateToProps(state) {
  return {
    currentBookmarkId: _.get(state, 'bookmark.currentBookmarkId', null),
    canUpdateBookmarkViewCount: _.get(state, 'bookmark.canUpdateBookmarkViewCount', false),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    currentDrilldownTemplateName: _.get(state, 'drilldown.currentDrilldownTemplateName'),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false),
    manageCollection: _.get(state, 'dashboard.manageCollection', {})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VisualizationTitleInfo);
