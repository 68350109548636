import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  getRadarPageDescription,
  getRadarPageTitle
} from 'common/config/customerConfiguration';

const CardFooter = ({isRadarBookmark, cardEntry}) => {
  const description = isRadarBookmark ? _.get(cardEntry, 'bookmarkOptions.description') : null;
  if(isRadarBookmark){
    return(
      <div className="attribution-footer align-items-center">
        <div className="metric-footer forge-typography--caption">
          <div className="text-truncate line-clamp">{description}</div>
        </div>
        <div className="view-metric-link-container mt-2">
          <i className="icons-bookmark-manager mr-2" />
          Saved analysis based on
          <NavLink
              to="/radar">{` ${getRadarPageTitle()}`}
          </NavLink>
        </div>
      </div>
    )
  }else{
    return(
      <div className="attribution-footer d-flex align-items-center">
        <div className="data-provider-info text-truncate line-clamp">
          {getRadarPageDescription()}
        </div>
      </div>
    );
  }

}

CardFooter.propTypes = {
  isRadarBookmark: PropTypes.bool,
  cardEntry: PropTypes.object
}
export default CardFooter;
