import React from "react";
import { useSelector } from "react-redux";

import CollaborateTabs from "./CollaborateTabs";
import CovertButton from "./ConvertButton";

const ModalBody = () => {
  const isCollaborateCollection = useSelector((state) => state.isCollaborateCollection);

  return (
    <section className="forge-dialog__body forge-dialog-body">
      {!isCollaborateCollection && <CovertButton />}
      {isCollaborateCollection && <CollaborateTabs />}
    </section>
  );
}

ModalBody.propTypes = {}

export default ModalBody;
