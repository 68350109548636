import _ from 'lodash';

import { getActualChange, getRateOfChange } from 'common/contentFormatter/helper';
import { getColorClassAndIconName } from 'helpers/visualizationHelper';
import { LINE_CHART_TRACE_COLORS } from 'appConstants';
import { getYearTextByRange } from './dateHelper';
export const getComparisonTooltipAttributes = ({
  metricTotalData, viewEntry, secondaryMetricEntry, tooltipId,
  comparisonDateRanges, dateRangeMode, dateRange
}) => {
  const showSecondaryTotal = !_.isEmpty(secondaryMetricEntry);
  const {
    comparisonDatesMetricTotals,
    currentPeriodMetricTotals,
  } = metricTotalData;
  let defaultTotal = 0;
  let field = 'total';
  if (showSecondaryTotal) {
    field = `secondary_${secondaryMetricEntry['field']}`;
    defaultTotal = _.get(currentPeriodMetricTotals, 'secondary_count', 0);
  }
  const currentPeriodTotal = _.get(currentPeriodMetricTotals, field, defaultTotal);

  let comparisonTooltipAttributes = [];
  if(!_.isEmpty(comparisonDateRanges)){
    comparisonTooltipAttributes = _.map(comparisonDateRanges, (comparisonDateRange, index) => {
      const yearText = getYearTextByRange(comparisonDateRange, dateRangeMode);
      const totalData = _.get(comparisonDatesMetricTotals, yearText, {});
      const defaultPrevious = _.get(totalData, 'secondary_count', 0);
      const previousTotal = _.get(totalData, field, defaultPrevious);
      const percentage = getRateOfChange([currentPeriodTotal], [previousTotal], false);
      const actualDifference = getActualChange(currentPeriodTotal, previousTotal);
      const { color, iconsClassName } = getColorClassAndIconName(viewEntry, percentage);

      return({
        discreteValue: Math.abs(Number(currentPeriodTotal) - Number(previousTotal)),
        color:  comparisonDateRanges.length <= 1 && index == 0 ? color : LINE_CHART_TRACE_COLORS[index+1],
        percentage,
        actualDifference,
        currentPeriodTotal,
        previousPeriodTotal: previousTotal,
        comparisonDateRange,
        dateRange,
        className: iconsClassName,
        dateRangeMode,
        viewEntry,
        tooltipId: (tooltipId || "metric-and-comparison"),
        key: `${tooltipId}-${index}`,
      });
    });
  }
  return comparisonTooltipAttributes;
}
