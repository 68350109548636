// Vendor Imports
import $ from 'jquery';

// Project Imports
import SpiderfyHandler from './SpiderfyHandler';
import PopupHandler from './PopupHandler';
import { LAYERS } from '../partials/PointsAndStackPartial';
import PointHighlight from '../partials/Highlight/PointHighlight';
import StackHighlight from '../partials/Highlight/StackHighlight';
import { LAYERS as CHOROPLETH_LAYERS } from '../partials/ChoroplethMapPartial';
import { LAYERS as SHAPE_FILTER_LAYERS } from '../partials/ShapeFilterPartial';
import { LAYERS as SEARCH_LAYERS } from '../searchPartials/SearchPointsAndStackPartial';
import { LAYERS as SEARCH_SUBJECT_LAYERS } from '../searchPartials/SearchSubjectPoints';
import GlobalEvent from 'common/components/GlobalEvents';

// Constants
export const MOUSE_MOVE_DEBOUNCE_WAIT = 150;
export const MOUSE_CURSOR_DEFAULT = 'auto';
export const MOUSE_CURSOR_HAND = 'pointer';
const SPIDERFY_LAYERIDS = [
  LAYERS.STACKS_LABEL,
  LAYERS.STACKS_CIRCLE,
  SEARCH_LAYERS.STACKS_LABEL,
  SEARCH_LAYERS.STACKS_CIRCLE,
  SEARCH_SUBJECT_LAYERS.STACKS_LABEL,
  SEARCH_SUBJECT_LAYERS.STACKS_CIRCLE,
];
const FLYOUT_SHOW = 'FLYOUT_SHOW';
const MAP_FLYOUT_REMOVE = 'MAP_FLYOUT_REMOVE';

export default class MouseInteractionHandler {
  constructor(map, apiParams, options) {
    this._map = map;
    this._apiParams = apiParams;
    this._options = _.cloneDeep(options);
    this._pointHighlight = new PointHighlight(map);
    this._stackHighlight = new StackHighlight(map);
    this._pointHighlight.initialize();
    this._stackHighlight.initialize();
    this._popupHandler = new PopupHandler(
      map,
      _.merge({ apiParams: apiParams }, options),
      this._onPopupRowIdsSelect
    );
    this._persistentPopupHandler = new PopupHandler(
      map,
      _.merge({ apiParams: apiParams }, options),
      this._onPopupRowIdsSelect
    );
    this._spiderfyHandler = new SpiderfyHandler(map, this._popupHandler);
    this._currentFeatureProperties = {};
    this._currentClickFeatureProperties = {};
    this._currentClickedOnLayerId = null;
    this._prevSpiderOptions = {};
    this._isComparionsStart = false;
    this._hoveredOverFeatures = null;
    this._isCurrentActiveMap = false;
    this.debouncedMouseMove = _.debounce(
      this._onMouseMove,
      MOUSE_MOVE_DEBOUNCE_WAIT
    );
    this.debouncedTabFocus = _.debounce(
      this._accessibilityFocus,
      MOUSE_MOVE_DEBOUNCE_WAIT
    );
    this.debouncedShapeFocus = _.debounce(
      this._accessibilityShapeFocus,
      MOUSE_MOVE_DEBOUNCE_WAIT
    );
    this.debouncedEnter = _.debounce(
      this._accessibilityEnter,
      MOUSE_MOVE_DEBOUNCE_WAIT
    );
    this.debouncedMouseOut = _.debounce(
      this._onMouseOut,
      MOUSE_MOVE_DEBOUNCE_WAIT
    );

    this._map.on('mouseout', this.debouncedMouseOut);
    this._map.on('accessibilityFocus', this.debouncedTabFocus);
    this._map.on('accessibilityEnter', this.debouncedEnter);
    this._map.on('accessibilityBlur', () => {
      this._pointHighlight.update([]);
      this._stackHighlight.update([]);
      this._popupHandler.removeFeaturePopup();
    });
    this._map.on('mousemove', this.debouncedMouseMove);
    this._map.on('click', this._onMouseClick);
    this._map.on('zoomstart', () => {
      this._spiderfyHandler.unspiderfy();
    });
    GlobalEvent.on('accessibilityShapeFocus', this.debouncedShapeFocus);
    $(document)
      .on('mousedown', this._onDocumentMouseDown)
      .on('mouseup', this._onDocumentMouseup)
      .on(
        'mousemove',
        _.debounce(this._onDocumentMouseMove, MOUSE_MOVE_DEBOUNCE_WAIT)
      );
    $(this._map._container).on('mouseout', this.debouncedMouseOut);

    const isRadarMap = _.get(this._options, 'isRadarMap', false);
    if (isRadarMap) {
      GlobalEvent.on(FLYOUT_SHOW, this._onFlyoutShow);
      GlobalEvent.on(MAP_FLYOUT_REMOVE, this._onFlyoutHide);
    }
  }

  destroy() {
    $(document)
      .off('mousedown', this._onDocumentMouseDown)
      .off('mouseup', this._onDocumentMouseup)
      .off('mousemove', this._onDocumentMouseMove);

    GlobalEvent.off(FLYOUT_SHOW, this._onFlyoutShow);
    GlobalEvent.off(MAP_FLYOUT_REMOVE, this._onFlyoutHide);
  }

  updateApiParams(apiParams) {
    this._apiParams = apiParams;
    this._popupHandler.updateApiParams(apiParams);
    this._persistentPopupHandler.updateApiParams(apiParams);
    this._spiderfyHandler.unspiderfy();
  }

  updateAdvanceSearchParams(advanceSearchParams) {
    this._popupHandler.updateAdvanceSearchParams(advanceSearchParams);
    this._persistentPopupHandler.updateAdvanceSearchParams(advanceSearchParams);
    this._options['selectedReportRowIds'] = _.get(
      advanceSearchParams,
      'selectedReportRowIds',
      []
    );
  }

  updateChoroplethData(choroplethData, currentDrilldownViewEntry) {
    this._popupHandler.updateChoroplethData(
      choroplethData,
      currentDrilldownViewEntry
    );
    this._persistentPopupHandler.updateChoroplethData(
      choroplethData,
      currentDrilldownViewEntry
    );
  }

  _getFeaturesAt(point, layers) {
    const availableLayers = _.filter(layers, (layer) =>
      this._map.getLayer(layer)
    );

    return this._map.queryRenderedFeatures(point, { layers: availableLayers });
  }

  // Event handler, gets called when mouse is clicked in the mapboxgl map element.
  _onMouseClick = (event) => {
    const clickableLayers = _.values(LAYERS).concat(
      SEARCH_SUBJECT_LAYERS.INCIDENT_ICON,
      SEARCH_SUBJECT_LAYERS.STACKS_CIRCLE,
      ..._.values(SEARCH_LAYERS)
    );
    const clickedOnFeatures = this._getFeaturesAt(event.point, clickableLayers);

    if (_.isEmpty(clickedOnFeatures)) {
      this._popupHandler.getMapFeaturePointForLeafPage([]);
      this._map.getCanvas().style.cursor = MOUSE_CURSOR_DEFAULT;
      this._spiderfyHandler.unspiderfy();
      this._currentFeatureGeometry = {};
      this._currentClickFeatureProperties = {};
      this._currentClickedOnLayerId = null;
      this._pointHighlight.update([]);
      this._stackHighlight.update([]);
      return;
    }

    const topMostClickedOnFeature = clickedOnFeatures[0];
    const topMostClickedOnLayerId = _.get(topMostClickedOnFeature, 'layer.id');
    const featureProperties = _.get(topMostClickedOnFeature, 'properties');
    const isSpiderfyLayerId = _.includes(
      SPIDERFY_LAYERIDS,
      topMostClickedOnLayerId
    );
    this._currentClickFeatureProperties = featureProperties;
    this._currentClickedOnLayerId = topMostClickedOnLayerId;

    if (_.isEqual(this._currentFeatureProperties, featureProperties)) {
      this._currentFeatureProperties = {};
      return;
    }

    if (!isSpiderfyLayerId) {
      this._popupHandler.getMapFeaturePointForLeafPage(topMostClickedOnFeature);
      this._persistentPopupHandler.showFeaturePopup(event, clickedOnFeatures);
      this._currentFeatureProperties = featureProperties;
      this._pointHighlight.update(
        topMostClickedOnFeature,
        this._currentClickFeatureProperties
      );
    } else {
      this._persistentPopupHandler.removeFeaturePopup();
    }

    if (isSpiderfyLayerId) {
      const featureId = _.get(topMostClickedOnFeature, 'layer.id');
      let apiParams = this._apiParams;
      let spiderOptions = this._options;
      if (SEARCH_SUBJECT_LAYERS.STACKS_CIRCLE === featureId) {
        apiParams = {
          ...apiParams,
          shapeIds: '',
          exclude_search_value: true,
          drilldownEntry: JSON.stringify({
            currentDrilldownTemplateId: apiParams.currentDrilldownTemplateId,
            quickFilters: [],
          }),
        };
      }

      this._prevSpiderOptions = {
        topMostClickedOnFeature,
        apiParams,
        spiderOptions,
      };
      this._spiderfyHandler.spiderfy(
        topMostClickedOnFeature,
        apiParams,
        spiderOptions
      );
    } else {
      this._spiderfyHandler.unspiderfy();
    }
  };

  _accessibilityFocus = ({ feature, layerId }) => {
    if (layerId === LAYERS.INCIDENT_CIRCLE) {
      this._pointHighlight.update(feature);
    } else {
      this._stackHighlight.update(feature);
    }
  };

  _accessibilityShapeFocus = ({ shapeId }) => {
    this._map.setFilter(CHOROPLETH_LAYERS.SHAPE_OUTLINE_HIGHLIGHT, [
      '==',
      'shape_id',
      shapeId,
    ]);
  };

  _accessibilityEnter = ({ event, feature }) => {
    this._popupHandler.showFeaturePopup(event, [feature]);
  };

  _accessibilityFocus = ({ feature, layerId }) => {
    if(layerId === LAYERS.INCIDENT_CIRCLE){
      this._pointHighlight.update(feature)
    }else{
      this._stackHighlight.update(feature);
    }
  }

  _accessibilityShapeFocus = ({ shapeId }) => {
    this._map.setFilter(CHOROPLETH_LAYERS.SHAPE_OUTLINE_HIGHLIGHT, ['==', 'shape_id', shapeId]);
  }

  _accessibilityEnter = ({ event, feature }) => {
    this._popupHandler.showFeaturePopup(event, [feature]);
  }

  _onPopupRowIdsSelect = (selectedRowIds) => {
    const { topMostClickedOnFeature, apiParams, spiderOptions } =
      this._prevSpiderOptions;
    this._spiderfyHandler.spiderfy(topMostClickedOnFeature, apiParams, {
      ...spiderOptions,
      selectedReportRowIds: selectedRowIds,
    });
  };

  _onDocumentMouseDown = (event) => {
    const isClickWithinPopup =
      $(event.target).closest('.mapboxgl-popup-content').length > 0;
    const isClickWithinSpiderLeg =
      $(event.target).closest('.spider-leg-container').length > 0;
    const isClickWithinStreetView = $(event.target).hasClass(
      '.street-view-button'
    );
    const isClickToOpenDialog = $(event.target).hasClass('.property-content-expand').length > 0;
    const isClickWithinStreetViewModel =
      $(event.target).closest('.street-view-modal').length > 0;
    const isClickWithinNotesModel =
      $(event.target).closest('.notes-modal').length > 0;
    const isClickWithinNotes = $(event.target).hasClass('.notes-button');

    if (this._isMouseWithinMapboxglCompare(event)) {
      this._isComparionsStart = true;
      this._hidePopup();
    }
    if (
      !isClickWithinPopup &&
      !isClickWithinSpiderLeg &&
      !isClickWithinStreetView &&
      !isClickWithinStreetViewModel &&
      !isClickWithinNotesModel &&
      !isClickWithinNotes &&  
      isClickToOpenDialog
    ) {
      // We are using mousedown as this needs to happen before we show a popup on clicking
      // of a feature in the map. On clicking anywhere persistent popup should be closed.
      this._persistentPopupHandler.removeFeaturePopup();
      this._popupHandler.spiderPersistPopupRemove();
      this._spiderfyHandler.unspiderfy();
      this._currentFeatureProperties = {};
    }
  };

  _onDocumentMouseup = () => {
    this._isComparionsStart = false;
    if (this._isComparionsStart) {
      this._hidePopup();
    }
  };

  _onDocumentMouseMove = (event) => {
    if (this._isMouseWithinMapboxglCompare(event)) {
      this._hidePopup();
    }
  };

  _hidePopup = () => {
    this._persistentPopupHandler.removeFeaturePopup();
    this._popupHandler.removeFeaturePopup();
  };

  // Event handler, gets called when mouse is moved in the mapboxgl map element.
  // If multiple points/stacks/clusters are displayed on the same location and
  // mouse is hovered over the common area, then on querying features at the mouse
  // location will have all those features. In that case, we take the first feature
  // and show tipsy for it.
  _onMouseMove = (event) => {
    const popupLayerIds = _.compact(
      _.values(LAYERS).concat(
        CHOROPLETH_LAYERS.SHAPES_FILL,
        SHAPE_FILTER_LAYERS.SHAPE_FILTER_LAYER,
        SHAPE_FILTER_LAYERS.SHAPE_FILTER_HIGHLIGHT,
        SEARCH_SUBJECT_LAYERS.INCIDENT_ICON,
        ..._.values(SEARCH_LAYERS)
      )
    );
    const hoveredOverFeatures = this._getFeaturesAt(event.point, popupLayerIds);

    if (
      _.isEmpty(hoveredOverFeatures) ||
      this._isComparionsStart ||
      this._isMouseWithinMapboxglCompare(event)
    ) {
      this._map.getCanvas().style.cursor = MOUSE_CURSOR_DEFAULT;
      this._popupHandler.removeFeaturePopup();
      if (this._hoveredOverFeatures) {
        GlobalEvent.emit(MAP_FLYOUT_REMOVE);
      }

      if (_.isEmpty(this._currentClickFeatureProperties)) {
        this._pointHighlight.update([]);
        this._stackHighlight.update([]);
      }
      return;
    }

    const topMostHoverOveredFeature = hoveredOverFeatures[0];
    const featureProperties = _.get(topMostHoverOveredFeature, 'properties');
    const topMostOveredOnLayerId = _.get(topMostHoverOveredFeature, 'layer.id');

    if (
      this._currentClickedOnLayerId !== topMostOveredOnLayerId ||
      !_.isEqual(this._currentClickFeatureProperties, featureProperties)
    ) {
      this._pointHighlight.update([]);
      this._stackHighlight.update([]);
      this._currentClickedOnLayerId = null;
      this._currentClickFeatureProperties = {};
    }

    if (
      _.includes(
        [LAYERS.INCIDENT_CIRCLE, LAYERS.INCIDENT_ICON],
        topMostOveredOnLayerId
      )
    ) {
      this._pointHighlight.update(
        topMostHoverOveredFeature,
        this._currentClickFeatureProperties
      );
    } else {
      this._stackHighlight.update(topMostHoverOveredFeature);
    }

    this._map.getCanvas().style.cursor = MOUSE_CURSOR_HAND;

    if (_.isEqual(this._currentFeatureProperties, featureProperties)) {
      this._popupHandler.removeFeaturePopup();
      return;
    }
    const isRadarMap = _.get(this._options, 'isRadarMap', false);
    if (isRadarMap) {
      GlobalEvent.emit(FLYOUT_SHOW, event, hoveredOverFeatures);
      this._isCurrentActiveMap = true;
    }

    this._popupHandler.showFeaturePopup(event, hoveredOverFeatures);
  };

  _onFlyoutShow = (event, hoveredOverFeatures) => {
    this._hoveredOverFeatures = hoveredOverFeatures;
    this._popupHandler.showFeaturePopup(event, hoveredOverFeatures);
  };

  _onFlyoutHide = () => {
    if (this._hoveredOverFeatures && !this._isCurrentActiveMap) {
      this._popupHandler.removeFeaturePopup();
    }
    this._hoveredOverFeatures = null;
  };

  _onMouseOut = () => {
    if (this._hoveredOverFeatures) {
      GlobalEvent.emit(MAP_FLYOUT_REMOVE);
    }
    this._isCurrentActiveMap = false;
    this._popupHandler.removeFeaturePopup();
  };

  _isMouseWithinMapboxglCompare = (event) => {
    return (
      $(event.target).hasClass('.mapboxgl-compare') ||
      $(event.target).closest('.mapboxgl-compare').length > 0
    );
  };
}
