import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomTooltip = ({ placement, delay, children, label, id }) => {
  const renderTooltip = (options) => (
    <Tooltip id={id} {...options}>
      {label}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={placement}
      delay={delay}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  );
}

CustomTooltip.defaultProps = {
  placement: 'right',
  delay: { show: 10, hide: 10 },
  label: 'Simple tooltip',
  children: null,
  id: 'button-tooltip'
}

CustomTooltip.propTypes = {
  placement: PropTypes.string,
  delay: PropTypes.object,
  label: PropTypes.string,
  children: PropTypes.any,
  id: PropTypes.string
}

export default CustomTooltip;
