import React from 'react'
import PropTypes from 'prop-types'

const TipsyRow = (props) => {
  const { value, category, index, prefixText, suffixText } = props;
  const tipsyValue = `${prefixText}${value}${suffixText}`;
  return (
    <tr key={`tipsy-row-${category}-${index}`}>
      <td><div className='tipsy-category'>{category}</div></td>
      <td><div className='tipsy-value'><b>{tipsyValue}</b></div></td>
    </tr>
  );
}

TipsyRow.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.string,
  prefixText: PropTypes.string,
  suffixText: PropTypes.string,
  index: PropTypes.number
}

export default TipsyRow
