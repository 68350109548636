import _ from 'lodash';
import $ from 'jquery';
import React from "react";
import { createRoot } from 'react-dom/client'

import GlobalEvent from 'common/components/GlobalEvents';
import {
  NO_OF_BENCH_MARKS,
  DEFAULT_DROPDOWN_OPTION,
  SNAPSHOT_VISUALIZATION_TYPES,
  ON_DISTRIBUTION_CHART_CLICK,
  ON_BAR_CLICK,
  DATE_OPTIONS_TYPE,
  PLOTLY_RANGE_UPDATE_DATE_TYPE,
  PLOTLY_SLIDER_RANGE_OPTION
} from 'appConstants';
import {
  getScatterPlotGroupAndDimension,
  getBarChartGroupAndDimension,
  getStackedGroupAndDimension
} from 'helpers/visualizationHelper';
import { getSnapshotChartType } from 'common/config/visualizationConfiguration';

import { isStackTypeChart } from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
import moment from 'moment';
import { updateDateRange, updateDateType } from 'actions/commonFiltersActions';
import { updateSliderRange } from 'actions/overtimeActions';
import { getSliderRangeValues } from 'pages/drilldown/visualizations/visualizationRenders/helper';

const TABLE_HEIGHT = 250;
const TABLE_COLUMN_PADDING = 14;

export const getBenchMarkDataValues = (benchMarkEntry) => {
  if (_.isEqual(benchMarkEntry, DEFAULT_DROPDOWN_OPTION)) {
    return [];
  }
  const benchMarksRange = _.range(0, NO_OF_BENCH_MARKS);
  const valueKeys = _.map(benchMarksRange, (benchMarkIndex) => {
    return benchMarkIndex > 0 ? `value${benchMarkIndex}` : 'value';
  })
  return _.values(_.pick(benchMarkEntry, valueKeys))
}

export const isEmptyApiData = (apiData, vizOptions) => {
  const currentChartType = _.get(vizOptions, 'apiParams.currentChartType', '');
  if (!_.isEmpty(currentChartType)) {
    const isEmptyTotal = _.isEmpty(_.get(apiData, 'total', []));
    const isEmptyEntries = _.isEmpty(_.get(apiData, 'entries', {}));
    const isEmptyPreviousEntries = _.isEmpty(_.get(apiData, 'previous_projection_entries', {}));
    const isComboChart = _.get(vizOptions, 'isComboChart');
    const isEmptyComboChart = isComboChart ? _.isEmpty(isCombochartEmpty(apiData)) : true;
    return isEmptyTotal && isEmptyEntries && isEmptyPreviousEntries && isEmptyComboChart
  } else if (vizOptions['isDistributionChart']) {
    return _.isEmpty(_.get(apiData, 'total', {}));
  } else {
    return _.isEmpty(_.get(apiData, 'entries', {}));
  }
}

const isCombochartEmpty = (apiData) => {
  const comboChartResults = _.get(apiData, 'combo_chart_secondary_results', [])
  return _.find(comboChartResults, function (result) { return !_.isEmpty(result.total); });
}

export const renderTableColumnFlyout = (container, removeContent = false) => {
  if (removeContent) {
    removeTableFlyout();
    return;
  }
  const tableBoundingRect = container.closest('.details-table').getBoundingClientRect();
  const { top, height } = container.getBoundingClientRect();

  if (container.scrollHeight > 105) {
    let tipsyAttributes = {
      key: 'table-column-tipsy',
      className: 'fade show tooltip bs-tooltip-bottom',
      role: "tooltip",
      'x-placement': 'bottom'
    };

    if (!container.closest('.visualization')) {
      let topPosition = (top + height) - (tableBoundingRect.top + TABLE_COLUMN_PADDING);
      if (topPosition > TABLE_HEIGHT) {
        topPosition = TABLE_HEIGHT;
      }

      tipsyAttributes = _.merge({}, tipsyAttributes, { style: { top: topPosition } });
    }

    const content = (
      <div {...tipsyAttributes}>
        <div className="arrow"></div>
        <div className="tooltip-inner">
          {$(container).text()}
        </div>
      </div>
    );
    if (_.isNil(window.tableFlyoutRoot)) {
      window.tableFlyoutRoot = createRoot($(container).find('.tooltip-container')[0]);
    }
    window.tableFlyoutRoot.render(content)
  } else {
    removeTableFlyout();
  }
}

function removeTableFlyout() {
  if (!_.isUndefined(window.tableFlyoutRoot)) {
    window.tableFlyoutRoot.render(null);
  }
}

export const triggerOnBarClick = (container, event) => {
  const chartDataContainer = container.closest('.visualization-charts');
  const vizOptions = _.get(chartDataContainer, 'vizOptions');
  const propData = _.get(chartDataContainer, 'plotProps');
  const {
    viewEntry,
    currentSnapshotView,
    groupByEntry,
    groupType,
    isDistributionChart,
    showRange
  } = vizOptions;
  const chartType = getSnapshotChartType(viewEntry);
  const currentSelectedIndex = getCurrentSelectedIndex(currentSnapshotView, event, container, chartType);
  const isGroupByNone = _.get(groupByEntry, 'name') == 'None';

  if (_.isEqual(chartType, 'groupChart') && _.isEqual(groupType, "combine_view")) {
    return;
  }

  let resultEntry = {};
  if (isDistributionChart) {
    const bucketEntry = _.get(propData, ['data', 0, 'meta', event.currentTarget.__data__.i, 'bucketEntry']);
    GlobalEvent.emit(ON_DISTRIBUTION_CHART_CLICK, event, bucketEntry);
  } else {
    if (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type) {
      resultEntry = barChartTabFocusDrilldown({
        vizOptions, propData, currentSelectedIndex, chartType, isGroupByNone
      });
    } else if (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type) {
      resultEntry = getScatterPlotGroupAndDimension({
        propData,
        currentSelectedIndex,
        chartType,
        isGroupByNone,
        showRange
      });
    } else {
      const label = _.get(event, 'currentTarget.__data__.label');
      if (label === 'Others') { return }
      resultEntry = { dimensionName: label };
    }
    const { dimensionName, groupByValue } = resultEntry;
    GlobalEvent.emit(ON_BAR_CLICK, dimensionName, groupByValue);
  }
}

function getCurrentSelectedIndex(currentSnapshotView, event, container, chartType) {
  const isGroupChart = _.isEqual(chartType, 'groupChart')
  const yTickLabels = _.map(
    container.closest('.visualization-charts').querySelectorAll('.ytick'), 'textContent'
  );
  if (_.isEmpty(currentSnapshotView) || currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type) {
    return event.currentTarget.__data__.i;
  } else {
    if (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type) {
      return event.currentTarget.__data__.y;
    } else {
      return isGroupChart ? event.currentTarget.__data__.x :
        _.indexOf(
          yTickLabels,
          (event.currentTarget.__data__.text).replace(/(<([^>]+)>)/ig, '')
        );
    }
  }
}

// this function is to handle all types of barchart drilldown(when tab focus event).
function barChartTabFocusDrilldown(options) {
  const { vizOptions, propData, currentSelectedIndex, chartType, isGroupByNone } = options;
  let resultEntry = {};
  if ((isStackTypeChart(vizOptions) && chartType == 'groupChart')) {
    resultEntry = getStackedGroupAndDimension(propData, currentSelectedIndex);
  } else {
    resultEntry = getBarChartGroupAndDimension(
      propData,
      currentSelectedIndex,
      chartType,
      isGroupByNone
    );
  }
  return resultEntry;
}

export const triggerOnScatterTextPointClick = (event) => {
  const data = _.get(event, 'currentTarget.__data__');
  const splittedValue = data.tx.split('<span style="fill:#ffffff">');

  if (splittedValue[1]) {
    const groupByName = splittedValue[1].replace('</span>', '');
    if (!_.isEmpty(groupByName)) {
      GlobalEvent.emit(ON_BAR_CLICK, undefined, groupByName, true);
    }
  }
}

export const triggerOnScatterRowClickEvent = (container, event) => {
  const chartContainer = container.closest('.bar-chart');
  const clickedIndex = _.get(event.currentTarget, '__data__.x');
  const dataContainer = chartContainer.querySelector('.visualization-charts')
  const propData = _.get(dataContainer, 'plotProps');
  const clickData = _.get(propData, 'data');
  var clickedEntry = _.get(clickData, [0, 'meta', clickedIndex]);
  if (_.isEmpty(clickedEntry)) {
    clickedEntry = _.get(clickData, [clickedIndex, 'meta']);
  }
  const isGroup = _.get(clickedEntry, 'isGroup', false);

  if (isGroup) {
    // console.log('GroupBy field', clickedEntry.label);
  } else {
    const { group_by_field, dimension } = _.get(clickedEntry, 'scatterEntry', {});
    GlobalEvent.emit(ON_BAR_CLICK, dimension, group_by_field);
  }
}

export const triggerOnSliderRangeEvent = (event, isApply) => {
  let chartContainer = document.querySelector('.line-chart');
  chartContainer = chartContainer.closest('.line-chart');
  var popupContainer = document.querySelector('.popup-container');
  popupContainer.classList.add('d-flex', 'align-items-center', 'justify-content-center');
  const dataContainer = document.querySelector('.visualization-charts');
  const plotlyTooltip = _.get(dataContainer, 'plotlyTooltip');

  const labelContent = getRenderContent();
  const plotlyLabelParams = {
    data: labelContent,
    anchor: {
      x: event.x,
      y: event.y
    }
  };

  const popupConfigs = [{
    position: 'bottom',
    chartContainer,
    anchor: plotlyLabelParams.anchor,
    popupContainer,
    html: (<div>{plotlyLabelParams.data}</div>),
    isHideArrow: true,
    customClass: 'border-0 shadow-none',
    isOverriderClass: true
  }];
  plotlyTooltip.showPopups(popupConfigs);

  if (isApply) {
    updateSliderDateRange();
    plotlyTooltip.hidePopups();
    popupContainer.classList.remove('d-flex', 'align-items-center', 'justify-content-center');
  }
}

function updateSliderDateRange() {
  const sliderRangeOption = getSliderRange();
  const { sliderRange, dateRange } = sliderRangeOption;

  let startDate = moment(_.first(sliderRange)).format("YYYY-MM-DD")
  let endDate = moment(_.last(sliderRange)).format("YYYY-MM-DD")
  const modifiedDate = getModifiedSliderDateType();

  if (modifiedDate == 'start_date') {
    endDate = _.get(dateRange, 'endDate')
  } else if (modifiedDate == 'end_date') {
    startDate = _.get(dateRange, 'startDate')
  }

  const newDateRange = {
    startDate: startDate,
    endDate: endDate
  }

  const { sliderRangeDate } = getSliderRangeValues(sliderRangeOption, modifiedDate);
  const isValidDate = moment(startDate) > moment(endDate)

  if(isValidDate ) {
    return
  }

  if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
    const dispatch = configureStore.dispatch; // eslint-disable-line no-undef

    dispatch(updateSliderRange(sliderRangeDate));
    dispatch(updateDateRange(newDateRange));
    dispatch(updateDateType(DATE_OPTIONS_TYPE.CUSTOM_RANGE));
  }
}

function getSliderRange() {
  const sliderOption = JSON.parse(localStorage.getItem(PLOTLY_SLIDER_RANGE_OPTION) || '{}');
  return sliderOption
}

function getModifiedSliderDateType() {
  // return => 'start_date' or 'end_date
  return sessionStorage.getItem(PLOTLY_RANGE_UPDATE_DATE_TYPE)
}

function getRenderContent() {
  const { sliderRange, dateRange } = getSliderRange();
  let startDate = _.first(sliderRange);
  let endDate = _.last(sliderRange);
  const modifiedDate = getModifiedSliderDateType();

  if (modifiedDate == 'start_date') {
    endDate = _.get(dateRange, 'endDate')
  } else if (modifiedDate == 'end_date') {
    startDate = _.get(dateRange, 'startDate')
  }

  startDate = moment(startDate).format("MMM DD, YYYY");
  endDate = moment(endDate).format("MMM DD, YYYY");

  const displayText = `${startDate} - ${endDate}`;
  return (<div className='font-weight-bold'> {displayText} </div>);
}