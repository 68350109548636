import { combineReducers } from 'redux';

import dashboardReducer from './dashboardReducer';
import metricTotalReducer from './metricTotalReducer';
import drilldownReducer from './drilldownReducer';
import mapOptionsReducer from './mapOptionsReducer';
import commonFiltersReducer from './commonFiltersReducer';
import tableReducer from './tableReducer';
import snapshotReducer from './snapshotReducer';
import overtimeReducer from './overtimeReducer';
import distributionReducer from './distributionReducer';
import leafPageReducer from './leafPageReducer';
import bookmarkReducer from './bookmarkReducer';
import insightReducer from './insightReducer';
import managePageReducer from './managePageReducer';
import radarReducer from './radarReducer';
import forecastingReducer from './forecastingReducer';
import advanceSearchReducer from './advanceSearchReducer';
import userCollectionsReducer from './userCollectionsReducer';

const configurations = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const currentUser = (state = '') => {
  return state;
};

const embedOptions = (state = '') => {
  return state;
};

const userFromBellerophon = (state = '') => {
  return state;
};

const userLoggedInDetails = (state = '') => {
  return state;
};

const appReducer = combineReducers({
  configurations,
  currentUser,
  embedOptions,
  userCollections: userCollectionsReducer,
  userLoggedInDetails,
  dashboard: dashboardReducer,
  drilldown: drilldownReducer,
  metricTotal: metricTotalReducer,
  commonFilters: commonFiltersReducer,
  visualization: combineReducers({
    snapshot: snapshotReducer,
    leafPage: leafPageReducer,
    overtime: overtimeReducer,
    distribution: distributionReducer,
    mapOptions: mapOptionsReducer,
    table: tableReducer,
  }),
  bookmark: bookmarkReducer,
  insight: insightReducer,
  manage: managePageReducer,
  radar: radarReducer,
  userFromBellerophon,
  forecasting: forecastingReducer,
  advanceSearch: advanceSearchReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = action.newState
  }
  return appReducer(state, action)
}

export default rootReducer;
