import React, { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types'

const DIMENSION_LABEL_COUNT = 90;

function HeatChartLabel(props) {
  const dimensionLabel = props.dimensionLabel;
  const index = props.labelIndex;
  const uniqueKey = `${dimensionLabel}-sla-label-key-${index}`;

  useEffect(() => {
    var lineElement = document.getElementById(`${uniqueKey}`);
    if (!_.isEmpty(lineElement)) {
      var divHeight = lineElement.offsetHeight;
      var lineHeight = 13;
      var lines = divHeight / lineHeight;

      if (lines >= 3) {
        lineElement.classList.add("dimension-label-height");
      }
    }
  });

  const renderTooltip = () => {
    const tooltip = (<Tooltip id={`sla-tooltip-${index}`}>{dimensionLabel}</Tooltip>);
    const uniqueKey = `sla-tooltip-key-${index}`;
    if (_.size(dimensionLabel) < DIMENSION_LABEL_COUNT) {
      return dimensionLabel
    }
    return (
      <OverlayTrigger key={uniqueKey} placement="top" overlay={tooltip}>
        <span>
          {dimensionLabel}
        </span>
      </OverlayTrigger>
    );

  }

  return (
    <td className="py-1" id={uniqueKey}>
      <div className="dimension-label" key={uniqueKey}>
        {renderTooltip()}
      </div>
    </td>
  )
}

HeatChartLabel.propTypes = {
  dimensionLabel: PropTypes.string,
  labelIndex: PropTypes.number
}

export default HeatChartLabel