// Vendor imports.
import _ from 'lodash';

// Project Imports
import { getFormattedValue } from 'helpers/chartDataHelper';
import {
  isStackTypeChart,
  isBenchMarkEmpty,
  formatDimensionText,
  getViewLabel,
  getWrapTickText,
  getNoOfBarsCount,
  getCompareRangeYears
} from '../vizOptionsHelper';
import { getSortByType, sortGroupByData } from '../barChartHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getStackedBarChartColors, getSortedViewValues } from 'helpers/colorHelper';
import { BENCHMARK_TEXT, COMPARE_VIEW_DRILLDOWN_OPTIONS, STACK_CHART_ADD_TEXT } from 'appConstants';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsDurationHours, getSecondsAsNumericTime } from 'helpers/visualizationHelper';

/*
---------------------------------------------------------------------------------
Input
---------------------------------------------------------------------------------
[
  {
    "dimension":"Investigation Complete",
    "count":"2796",
    "min_count":"Abandoned Property",
    "max_count":"Warrant Service",
    "secondary_count":"156"
    "view":"Closed"
  },
  {
    "dimension":"Investigation Complete",
    "count":"116",
    "min_count":"Aggravated Battery",
    "max_count":"Violation of Probation (adult)",
    "secondary_count":"156"
    "view":"Unfounded"
  },
  {
    "dimension":"Suspended",
    "count":"2009",
    "min_count":"Abandoned Vehicle",
    "max_count":"Violation of Probation (adult)",
    "secondary_count":"156"
    "view":"Closed"
  },
  {
    "count": "106",
    "dimension": "Adult Diversion",
    "max_count": "Trespassing",
    "min_count": "Battery",
    "secondary_count": "106"
  },
  ...
]
---------------------------------------------------------------------------------
Output
---------------------------------------------------------------------------------
[
  [
    {
      label: '100',
      secondaryLabel: '200',
      value: 'Education',
      text: 'Education - 100' ,
      secondaryText: '$200',
      color: '#fff'
    },
    {
      label: '300',
      SecondaryLabel: '150',
      value: 'Government',
      text: 'Government - 300' ,
      secondaryText: '$150',
      color: '#fff'
    },
     .....
  ]
]
*/

export function formatGroupByNoneBarData(apiDataEntries, vizOptions) {
  const { viewMode, templateId, onFormattedDataLoad } = vizOptions;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const nullValueLabel = getNullValueLabel(templateId);
  const chartDataValues = _.flattenDeep(_.values(apiDataEntries));
  const stackColors = getStackedBarChartColors(chartDataValues, nullValueLabel, isSmallView);
  const topDimensions = _.take(getSortedViewValues(chartDataValues, nullValueLabel), isSmallView ? 5 : 30);
  const newChartData = isStackTypeChart(vizOptions) ?
    getNewChartData(apiDataEntries, topDimensions, nullValueLabel) :
    apiDataEntries;

  const options = {
    chartData: newChartData,
    vizOptions,
    stackColors,
    nullValueLabel,
    isDataforSummary: false
  };

  const summaryTableOptions = {
    chartData: apiDataEntries,
    vizOptions,
    stackColors,
    nullValueLabel,
    isDataForSummaryTable: true
  };

  if (isStackTypeChart(vizOptions)) {
    const summmaryFormattedData = formatGroupByNoneStackBarData(summaryTableOptions);
    onFormattedDataLoad(summmaryFormattedData);
  }

  return isStackTypeChart(vizOptions) ?
    formatGroupByNoneStackBarData(options) :
    formatGroupByNoneNormalBarData(options);
}

function formatGroupByNoneNormalBarData(options) {
  const { vizOptions, chartData } = options;
  const {
    groupByViewType,
    viewEntry,
    secondaryMetricEntry,
    benchMarkEntries,
    templateId,
    viewMode,
    onFormattedDataLoad,
    isComparisonEnabled
  } = vizOptions;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const compareViewType = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: groupByViewType });
  const noOfBarsLimit = getNoOfBarsCount(compareViewType, _.size(chartData));
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry);
  const noOfBarsCount = isComparisonEnabled ?
    getCompareBarLimits(noOfBarsLimit, chartData, vizOptions) :
    noOfBarsLimit;

  let formattedData = _.chain(chartData).
    take(noOfBarsCount).
    map((dimensionEntry) => {
      const { count, secondary_count, dimension, period } = dimensionEntry;
      const countLabel = isSecondsFormat ? getSecondsAsDurationHours(count, dataUnit) : count;
      const secondaryCountLabel = isSecondsFormatSecondary ?
      getSecondsAsDurationHours(secondary_count, secondaryDataUnit) : secondary_count;
      return {
        label: countLabel || 0,
        secondaryLabel: secondaryCountLabel || 0,
        actualLabel: Number(count) || 0,
        actualSecondaryLabel: Number(secondary_count) || 0,
        value: formatDimensionText(dimension, false, templateId),
        text: getFormattedValue(count, viewEntry, false, isSmallView),
        secondaryText: getFormattedValue(
          secondary_count, secondaryMetricEntry, false, isSmallView),
        hoverText: getFormattedValue(count, viewEntry),
        secondaryHoverText: getFormattedValue(secondary_count, secondaryMetricEntry),
        customData: [dimension],
        ticktext: getWrapTickText(dimension, templateId),
        color: 0,
        period: period
      }
    }).
    tap((dimensionData) => dimensionData).
    value();
  //for summary table
  onFormattedDataLoad(_.cloneDeep(formattedData));

  if (!isBenchMarkEmpty(benchMarkEntries)) {
    const emptyBarData = getEmptyBarData([''], 0);
    const benchMarkBarData = getEmptyBarData([''], 0, true);
    formattedData.unshift(emptyBarData);
    formattedData.push(benchMarkBarData);
  }
  return [formattedData];
}

function getCompareBarLimits(noOfBarsCount, chartData, vizOptions) {
  let compareRanges = getCompareRangeYears(chartData, vizOptions);
  const compareCount = _.filter(compareRanges, (range) => {
    return !_.isNaN(Number(range.year));
  });

  return _.size(compareCount) > 0 ? _.size(compareCount) * noOfBarsCount : noOfBarsCount;
}

function formatGroupByNoneStackBarData({ chartData, vizOptions, stackColors,
  nullValueLabel, isDataForSummaryTable }) {

  const {
    viewEntry,
    benchMarkEntries,
    templateId,
    viewMode,
    isCurrencyDimensionField,
    secondaryMetricEntry
  } = vizOptions;
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry);

  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  // if benchMark entry is configured. We are adding start EMPTY_VALUE value
  // and end empty value in dimensions.
  const formattedData = _.map(chartData, (datum) => {
    const { count, secondary_count, view, dimension } = datum;

    const viewLabel = getViewLabel(view, nullValueLabel);
    const formattedText = formatDimensionText(dimension, false, templateId);
    let YAxistValue = formatValueToCurrency(formattedText, isCurrencyDimensionField);

    // Stacked chart annotation(show value) is not supported
    // for Numeric Value(YAxis) so add string characters
    YAxistValue = isNumericalValue(YAxistValue) ? `${YAxistValue}${STACK_CHART_ADD_TEXT}` : YAxistValue;

    const countLabel = isSecondsFormat ? getSecondsAsNumericTime(count, dataUnit) : count;
    const secondaryCountLabel = isSecondsFormatSecondary ?
      getSecondsAsNumericTime(secondary_count, secondaryDataUnit) : secondary_count;

    return {
      label: Number(countLabel) || 0,
      view: viewLabel,
      formattedViewCount: getFormattedValue(count, viewEntry),
      secondaryLabel: Number(secondaryCountLabel) || 0,
      actualLabel: Number(count) || 0,
      actualSecondaryLabel: Number(secondary_count) || 0,
      value: YAxistValue,
      text: `${viewLabel} - ${getFormattedValue(count, viewEntry, false,
        isSmallView, true)}`,
      secondaryText: Number(secondary_count) || 0,
      hoverText: `${viewLabel} - ${getFormattedValue(count, viewEntry)}`,
      secondaryHoverText: Number(secondary_count) || 0,
      customData: [dimension],
      color: stackColors[viewLabel],
      ticktext: getWrapTickText(dimension, templateId)
    };
  });

  const sortedFormattedData = getSortedFormattedData(formattedData, vizOptions);
  if (isDataForSummaryTable) {
    return sortedFormattedData;
  }

  return !isBenchMarkEmpty(benchMarkEntries) ?
    [formattedDataWithEmptyValue(sortedFormattedData, isSecondsFormat)] :
    [sortedFormattedData];
}

// we are grouping top dimension value are separated group.
// other values are group by other bucket.
// TODO: add comment and change function name.
const getNewChartData = (chartData, topDimensions, nullValueLabel) => {
  let topCategorys = [];
  let otherCategorys = [];
  _.each(chartData, (datum) => {
    const viewLabel = getViewLabel(datum.view, nullValueLabel);
    if (_.includes(topDimensions, viewLabel)) {
      topCategorys.push(datum);
    } else {
      otherCategorys.push(datum);
    }
  });
  const otherCategoryEntries = _.chain(otherCategorys).
    uniqBy('dimension').
    map((entry) => {
      return {
        dimension: entry.dimension,
        view: 'Others',
        count: getTotalCount(otherCategorys, entry, 'count'),
        secondary_count: getTotalCount(otherCategorys, entry, 'secondary_count')
      };
    }).
    sortBy('count').
    value();

  return [...topCategorys, ...otherCategoryEntries];
};

function getEmptyBarData(dimensions, index, isBenchMark = false) {
  const dimension = _.get(dimensions, index, ' ');
  return {
    label: 0,
    secondaryLabel: 0,
    actualLabel: 0,
    actualSecondaryLabel: 0,
    value: (dimension === 'EMPTY_VALUE' || dimension === '') ? "" :
      formatDimensionText(dimension, false, ""),
    text: '',
    secondaryText: '',
    color: 0,
    customData: [],
    ticktext: (dimension === 'EMPTY_VALUE' || dimension === '') ? "" : getWrapTickText(dimension, true, ""),
    period: isBenchMark ? BENCHMARK_TEXT : ''
  };
}

function getTotalCount(otherCategorys, entry, field) {
  return _.sumBy(otherCategorys, (category) => {
    if (category.dimension === entry.dimension) {
      return Number(category[field]);
    }
  });
}

function formattedDataWithEmptyValue(formattedData, isSecondsFormat) {
  //TODO: make this object as constants if needed.
  const labelValue = isSecondsFormat ? getSecondsAsNumericTime(0) : 0;
  return _.flattenDeep([
    { label: labelValue, value: '', ticktext: '' },
    formattedData,
    { label: labelValue, value: ' ', ticktext: ' ' }
  ]);
}

function getSortedFormattedData(formattedData, vizOptions) {
  const { groupByViewType, viewEntry } = vizOptions;
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  const compareViewType = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: groupByViewType });
  const dimensionNames = _.chain(formattedData).map('value').uniq().value();
  const noOfBarsCount = getNoOfBarsCount(compareViewType, _.size(dimensionNames));

  const countField = isSecondsFormat ? 'actualLabel' : 'count';
  const sortByEntry = getSortByType(vizOptions) || {};
  let sortField = _.includes(['asc', 'desc'], sortByEntry['type']) ? countField : 'category';
  if(sortByEntry['isCustomSort']) {
    sortField = 'category';
  }
  const groupedData = _.chain(formattedData).
    groupBy('value').
    map((entries, category) => {
      return {
        category,
        count: _.sumBy(entries, 'label'),
        entries: _.sortBy(entries, (entry) =>
          convertToLowerCase(_.get(entry, 'view', '')))
      }
    }).
    value()
  const sortedData = sortGroupByData(groupedData, sortByEntry, sortField);
  return _.chain(sortedData).
    take(noOfBarsCount).
    map('entries').
    flattenDeep().
    value();
}

function convertToLowerCase(text) {
  return _.isString(text) ? text.toLowerCase() : text;
}

function isNumericalValue(value) {
  if (!_.isEmpty(value)) {
    return !_.isNaN(Number(value))
  }
  return false
}
