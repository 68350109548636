import React, { Component } from 'react';

import { HEAT_CHART_BACKGROUND_COLORS } from 'appConstants';
import {
  slaHeatChartBuckets,
  slaLegendTitle
} from 'common/config/customerConfiguration';

class HeatChartLegend extends Component {
  
  renderLegendBlocks(){
    const bucketRanges = slaHeatChartBuckets();
    return _.map(HEAT_CHART_BACKGROUND_COLORS, (color, index) => {
      const bucket = bucketRanges[index];
      return( 
        <div key={`${bucketRanges}-${index}`}>
          <div
            className="legend-block"
            style={{backgroundColor: color }}>
          </div>
          <span className="legend-text">{bucket}</span>
        </div>
      )
    })
    
  }
  render(){
    return(
      <div className="hear-chart-legend">
        <span>{slaLegendTitle()}</span>
        <div className="d-flex"> {this.renderLegendBlocks()} </div>
      </div>
    )
  }
}

HeatChartLegend.propTypes = {};

export default HeatChartLegend;
