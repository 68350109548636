import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { formatValueToCurrency } from 'helpers/numberHelper';

export const getStackedChartSummaryFormatter = (formattedData, summaryTableOptions,
  summaryTotalData, drillDownTotal) => {

  const tableHeaders = getStackedChartHeaders(formattedData, summaryTableOptions);
  const tableData = getStackedSummaryDataFormatter(formattedData,
    tableHeaders,
    summaryTableOptions,
    summaryTotalData,
    drillDownTotal);

  return {
    tableHeaders,
    tableData
  }
}

const getStackedChartHeaders = (formattedData, summaryTableOptions) => {
  const { isGroupByNone, isGroupChart, dimensionName } = summaryTableOptions;
  const isGroupType = (!isGroupByNone && isGroupChart);

  let tableHeaders = [];
  _.forEach(formattedData, (datum) => {

    const indexOfHeader = _.findIndex(tableHeaders, function (tableHeader) {
      return tableHeader.name == _.get(datum, 'view');
    });

    const isWithoutGroup = !_.get(datum, 'isGroup', false);

    if (indexOfHeader == -1 && isWithoutGroup) {
      const header = {
        name: _.get(datum, 'view'),
        color: _.get(datum, 'color'),
        columnField: _.get(datum, 'view')
      }
      tableHeaders.push(header);
    }

  });

  const title = isGroupType ? "Category" : dimensionName;
  tableHeaders.unshift({ name: "Total", columnField: "dimensionTotal" })
  tableHeaders.unshift({ name: title, columnField: "dimension" })

  return tableHeaders;
}

const getDimensionLabel = (datum) => {
  const customData = _.get(datum, 'customData');
  if (_.size(customData) > 0) {
    return customData[_.size(customData) - 1];
  }
  return _.get(datum, 'ticktext');
}

const getStackedSummaryDataFormatter = (formattedStackData,
  tableHeaders,
  summaryTableOptions,
  summaryTotalData,
  drillDownTotal) => {

  const {
    currentDrilldownViewEntry,
    templateId,
    isGroupByNone,
    isGroupChart,
    groupType,
    isCurrencyDimensionField,
    isCurrencyGroupByField
  } = summaryTableOptions;

  const isCombineView = groupType === "combine_view";
  const isGroupType = (!isGroupByNone && isGroupChart);
  const nullValueLabel = getNullValueLabel(templateId);
  let customFormatData = [];
  let isAvailableGroupData = false;

  const formattedData = _.filter(formattedStackData, function (datum) {
    return _.get(datum, 'isSeeMoreDimension', false) === false;
  });

  _.forEach(formattedData, (datum) => {

    const indexOfHeader = _.findIndex(customFormatData, function (tableData) {
      return _.get(tableData, 'dimensionMapping') == _.get(datum, 'value');
    });

    const dimensionField = _.get(datum, 'view');
    const dimensionValue = _.get(datum, 'formattedViewCount');
    const isGroup = _.get(datum, 'isGroup', false);
    const dimensionLabel = isGroup ? _.get(datum, 'value') : getDimensionLabel(datum);

    let dimensionText = _.isEmpty(dimensionLabel) ? nullValueLabel : dimensionLabel;
    let dimensionEntry;
    let isCurrencyType = isCurrencyDimensionField;

    if (isGroupType) {

      if (isGroup) {
        dimensionEntry = getDimensionGroupTotal(summaryTotalData, dimensionText, nullValueLabel);
        isCurrencyType = isCurrencyGroupByField;
      } else {
        const groupNameWithDimension = _.get(datum, 'customData');
        let groupName = _.size(groupNameWithDimension) > 0 ? groupNameWithDimension[0] : dimensionText;
        groupName = _.isEmpty(groupName) ? nullValueLabel : groupName;

        const dimensionEntryValue = isCombineView ? nullValueLabel : dimensionText;
        dimensionEntry = getDimensionTotalWithGroup(summaryTotalData, groupName,
          dimensionEntryValue, nullValueLabel);
      }

    } else {
      dimensionEntry = getStackedDimensionTotal(summaryTotalData, dimensionText, nullValueLabel);
    }

    const dimensionTotalValue = getFormattedNumberValue(_.get(dimensionEntry, 'count'),
      currentDrilldownViewEntry);
    dimensionText = formatValueToCurrency(dimensionText, isCurrencyType);

    if (indexOfHeader == -1) {
      const stackData = {
        isGroup,
        dimensionMapping: _.get(datum, 'value'),
        dimension: dimensionText,
        dimensionTotal: dimensionTotalValue,
        [dimensionField]: dimensionValue
      }
      customFormatData.push(stackData);

      // Group Total
      if (isGroup) {
        isAvailableGroupData = true;
        const indexOfGroup = _.findIndex(customFormatData, function (tableData) {
          return _.get(tableData, 'dimensionMapping') == _.get(datum, 'value');
        });

        _.forEach(tableHeaders, (header) => {
          const dimensionViewField = _.get(header, 'name', '');

          const stackGroup = getStackedBarSummaryGroupTotal(summaryTotalData,
            dimensionText, dimensionViewField, nullValueLabel);

          const stackGroupTotal = getFormattedNumberValue(_.get(stackGroup, 'count'),
            currentDrilldownViewEntry);

          if (indexOfGroup > -1) {
            customFormatData[indexOfGroup][dimensionViewField] = stackGroupTotal;
          }
        });
      }

    } else {
      customFormatData[indexOfHeader][dimensionField] = dimensionValue;
    }

  });

  if (!isAvailableGroupData) {
    // Dimension total
    return getNormalStackedChartTotal(tableHeaders,
      customFormatData,
      summaryTableOptions,
      summaryTotalData,
      drillDownTotal);
  } else {
    return customFormatData;
  }
}

const getNormalStackedChartTotal = (tableHeaders,
  customFormatData,
  summaryTableOptions,
  summaryTotalData,
  drillDownTotal) => {

  const { currentDrilldownViewEntry } = summaryTableOptions;
  if (_.isEmpty(customFormatData)) {
    return customFormatData
  }

  _.forEach(tableHeaders, (header, index) => {

    const dimensionViewField = _.get(header, 'name', '');
    const stackSummary = getNormalStackedBarSummaryTotal(summaryTotalData, dimensionViewField);
    const stackTotal = getFormattedNumberValue(_.get(stackSummary, 'count'), currentDrilldownViewEntry);

    const drillDownTotalFormat = getFormattedNumberValue(drillDownTotal, currentDrilldownViewEntry);

    if (index === 0) {
      const stackData = {
        isGroup: false,
        dimensionMapping: 'Stack_Total',
        dimension: 'All',
        dimensionTotal: drillDownTotalFormat,
        [dimensionViewField]: stackTotal
      }
      customFormatData.unshift(stackData);
    } else {
      customFormatData[0][dimensionViewField] = stackTotal;
    }

  });

  return customFormatData
}

const getStackedBarSummaryGroupTotal = (summaryTotalData, groupName, dimensionViewField, nullValueLabel) => {
  const summaryTotals = _.get(summaryTotalData, 'stack_totals', []);
  const stackSummaryTotal = _.find(summaryTotals, function (total) {
    return _.get(total, "group_by_field", nullValueLabel) === groupName &&
    _.toString(_.get(total, "view")) === dimensionViewField
  });

  return stackSummaryTotal;
}

const getNormalStackedBarSummaryTotal = (summaryTotalData, dimensionViewField) => {
  const summaryTotals = _.get(summaryTotalData, 'stack_totals', []);
  const stackSummaryTotal = _.find(summaryTotals, function (total) {
    return _.toString(_.get(total, "view")) === dimensionViewField
  });

  return stackSummaryTotal;
}

const getStackedDimensionTotal = (summaryTotalData, dimenisonName, nullValueLabel) => {

  const summaryTotals = _.get(summaryTotalData, 'entries_totals', []);
  const stackSummaryTotal = _.find(summaryTotals, function (total) {
    return _.get(total, 'dimension', nullValueLabel) === dimenisonName;
  });

  return stackSummaryTotal;
}

export const getDimensionGroupTotal = (summaryTotalData, groupName, nullValueLabel) => {
  const summaryTotals = _.get(summaryTotalData, 'group_totals', []);
  const summaryTotal = _.find(summaryTotals, function (total) {
    return _.get(total, "group_by_field", nullValueLabel) === groupName
  });
  return summaryTotal;
}

const getDimensionTotalWithGroup = (summaryTotalData, groupName, dimensionField, nullValueLabel) => {
  const summaryTotals = _.get(summaryTotalData, 'entries_totals', []);

  const stackSummaryTotal = _.find(summaryTotals, function (total) {
    return _.get(total, "group_by_field", nullValueLabel) === groupName &&
      _.get(total, "dimension", nullValueLabel) === dimensionField
  });

  return stackSummaryTotal;
}
