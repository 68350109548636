import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ForgeIcon, ForgeListItem } from '@tylertech/forge-react';

import { updateMapView } from 'actions/mapOptionsActions';
import { getMapTypes } from 'common/config/visualizationConfiguration';
import { getMapViews } from './helpers/MapOptionsHelper';
import ForgeMenuOptions from 'common/components/MenuDropDown/ForgeMenuOptions';
class MapViewTypes extends Component {

  handleMapViewChange = (mapType) => {
    const mapViews = getMapViews();
    const mapView = _.find(mapViews, { type: _.get(mapType, 'detail.value') });

    this.props.dispatchUpdateMapView(mapView);
  }

  renderMapViews() {
    const { currentMapView, selectedShapeEntry, currentDrilldownViewEntry } = this.props;
    const mapViews = getMapViews();
    const mapTypes = getMapTypes(currentDrilldownViewEntry);

    return _.map(mapTypes, (mapType) => {
      const mapView = _.find(mapViews, { type: mapType.value });
      if ((_.isEmpty(selectedShapeEntry) && mapView.type === 'choropleth')) {
        return null;
      }
      const iconClassName = currentMapView.type == mapType.value ? 'check' : 'forge-empty-icon';

      return (
        <ForgeListItem onClick={() => this.handleMapViewChange(mapView)}>
          <ForgeIcon slot="leading" name={iconClassName} className="forge-icon-class"></ForgeIcon>
          <span slot="title">{mapType.name}</span>
        </ForgeListItem>
      );
    });
  }

  renderMapTypes = () => {
    const { currentDrilldownViewEntry, currentMapView, selectedShapeEntry } = this.props;
    const mapTypes = getMapTypes(currentDrilldownViewEntry);

    const customChartTypes = _.map(mapTypes, (chartType) => {
      if ((_.isEmpty(selectedShapeEntry) && chartType.value === 'choropleth')) {
        return null;
      }

      return {
        value: chartType.value,
        label: chartType.name,
        leadingIconType: 'component',
        leadingIcon: chartType.value == currentMapView.type ? 'check' : 'forge-empty-icon'
      }
    });

    return (
      <ForgeMenuOptions
        customIconName={currentMapView.icon}
        menuLabelName={currentMapView.name}
        menuOptions={_.compact(customChartTypes)}
        onSelectOption={this.handleMapViewChange} />
    )
  }

  render() {
    const { isEmbed, currentDrilldownViewEntry } = this.props;
    const mapTypes = getMapTypes(currentDrilldownViewEntry);
    if (_.size(mapTypes) < 1) {
      return null;
    }

    return !isEmbed && (this.renderMapTypes())
  }
}

MapViewTypes.propTypes = {
  dispatchUpdateMapView: PropTypes.func,
  currentDrilldownViewEntry: PropTypes.object,
  currentMapView: PropTypes.object,
  selectedShapeEntry: PropTypes.object,
  isEmbed: PropTypes.bool
};

const mapDispatchToProps = {
  dispatchUpdateMapView: updateMapView
};

function mapStateToProps(state) {
  return {
    currentMapView: _.get(state, 'visualization.mapOptions.currentMapView', {}),
    selectedShapeEntry: _.cloneDeep(_.get(state, 'visualization.mapOptions.shape.datasetEntry')),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry'),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapViewTypes);
