import React, { Component } from 'react';
import PropTypes from 'prop-types';
import createPlotlyComponent from 'react-plotly.js/factory';
import { RADAR_CHART_PRE_BAR_COLOR, RADAR_CHART_POST_BAR_COLOR } from 'appConstants';

const Plot = createPlotlyComponent(window.Plotly);
const TEXT_COLOR = "#606060";
const MAX_LEGEND_LENGTH = 25;
class RadarPlotly extends Component {
  constructor(props) {
    super(props);
  }

  getPlotlyData = (formattedData) => {
    let annotations = [], plotlyData = [];

    let CategoryTickLabels = _.uniq(_.map(formattedData, 'category'));
    const xCategoryTickLabel = _.map(CategoryTickLabels, (category) => {
      return `<span style="fill:${TEXT_COLOR}; font-weight: bold;"> ${category} </span>`
    });

    plotlyData.push(
      {
        x: xCategoryTickLabel,
        y: _.map(_.filter(formattedData,{'year':'Pre'}), 'value'),
        name: _.first(_.map(_.filter(formattedData,{'year':'Pre'}), 'dateRangeText')),
        hoverinfo: 'none',
        visible: true,
        type: 'bar',
        marker: {
          color: RADAR_CHART_PRE_BAR_COLOR
        },
        templateMetricSettings:_.first(_.map(formattedData,'templateMetricSettings')),
      },
      {
        x: xCategoryTickLabel,
        y: _.map(_.filter(formattedData,{'year':'Post'}), 'value'),
        name: _.first(_.map(_.filter(formattedData,{'year':'Post'}), 'dateRangeText')),
        hoverinfo: 'none',
        visible: true,
        type: 'bar',
        marker: {
          color: RADAR_CHART_POST_BAR_COLOR
        },
        templateMetricSettings:_.first(_.map(formattedData,'templateMetricSettings')),
      }
    );

    annotations = this.getAnnotations(formattedData);
    const isExceedTickText  = _.size(_.first(CategoryTickLabels)) > MAX_LEGEND_LENGTH;

    return { plotlyData, annotations, isExceedTickText };
  }

  getAnnotations(formattedData) {
    const { isSelectedTracts, isComparisonTracts } = this.props;

    const uniqueFormattedData = _.uniqBy(formattedData, 'category');
    const annotations = _.map(uniqueFormattedData, (datum, index) => {
      const datumCategory = _.get(datum, 'category');
      const xValueIndex = index;
      const yValueIndex = _.max(
        _.map(_.filter(formattedData,{'category':datumCategory}), 'value')
        );
      const percentage = _.get(datum, 'percentage', 0);
      let value = `${percentage.toFixed(2)}%`;
      value = percentage >= 0 ? `${value}`: _.replace(value,'-','');
      let annotationIconHTMLCode = percentage > 0 ? '&#8593;' : percentage < 0 ? '&#8595;' : '';
      
      if (!isSelectedTracts && datumCategory == 'Selected Area')
        value = "No areas selected"
      else if (!isComparisonTracts && datumCategory == 'Comparison Area')
        value = "No areas selected"

      return {
        x: xValueIndex,
        y: yValueIndex,
        text: `<span style="fill:#000000;
                  font-weight: 500; font-size:14px; font-family:Roboto;  display:inline;">
          <span style="font-size:20px;">${annotationIconHTMLCode}</span> ${value} </span>`,
        showarrow: false,
        yshift: 20
      }
    });

    return annotations
  }

  getLayout = ({ annotations, prefix, isExceedTickText }) => {

    const layout = {
      "showlegend": true,
      "autosize": true,
      "margin": { "t": 10, "r": 0, "l":0, "pad": 0 },
      "bargap": 0.1,
      "bargroupgap":0.05,
      "automargin": true,
      "height": 400,
      "dragmode": false,
      "hovermode": 'x',
      "showspikes": false,
      "xaxis": {
        "automargin": true,
        'showlegend': true,
        "tickson": "boundaries",
        "showdividers": true,
        "dividercolor": '#fff',
        "dividerwidth": 10,
        "tickfont": {
          "family": 'Roboto',
          "color": TEXT_COLOR
        },
      },
      "yaxis": {
        "automargin": true,
        'tickprefix': prefix,
        "rangemode":"nonnegative",
        "showticklabels": true,
        "tickfont": {
          "family": 'Roboto',
          "color": TEXT_COLOR
        }
      },
      "legend":{
        "margin": "autoexpand",
        "itemclick": false,
        "xanchor":"center",
        "yanchor":"bottom",
        "y": isExceedTickText ? -0.4 : -0.3,
        "x":0.5,
        "font": {
          "family": 'Roboto',
          "color": TEXT_COLOR
        },
      },
      "annotations": annotations
    }

    return layout;
  }

  getPlotlyConfigs = () => {
    const { data, extraPlotlyParams } = this.props;
    const config = {
      "displayModeBar": false,
      "scrollZoom": false,
      "editable": false,
      "showLink": false,
      "responsive": true
    };
    const templateMetricSettings = _.first(_.map(data,'templateMetricSettings'));
    const style = { width: "98%", height: "100%" };
    const prefix = _.get(templateMetricSettings,'prefix' , '');
    const { plotlyData, annotations, isExceedTickText } = this.getPlotlyData(data);
    const layout = this.getLayout({ annotations, prefix, isExceedTickText });

    return {
      ...extraPlotlyParams,
      data: plotlyData,
      useResizeHandler: true,
      layout,
      config,
      style
    };
  }

  render() {
    const plotlyParams = this.getPlotlyConfigs();
    return (
      <div>
        <Plot {...plotlyParams} />
      </div>
    );
  }
}

RadarPlotly.propTypes = {
  data: PropTypes.array,
  extraPlotlyParams: PropTypes.object,
  isSelectedTracts: PropTypes.bool,
  isComparisonTracts: PropTypes.bool
};

export default RadarPlotly;