import React from 'react';
import PropTypes from 'prop-types';

import { getRadarDateRangeText } from './radarHelper';

const RadarMapHeader = (props) => {
  const {
    currentDateRangeOptions,
    previousDateRangeOptions,
    selectedShapeAreaEntry,
    selectedMetricEntries
  } = props;

  const {
    currentDateRangeText, compareDateRangeText
  } = getRadarDateRangeText(currentDateRangeOptions, previousDateRangeOptions);

  let selectedMatricsName = _.join(_.map(selectedMetricEntries, 'name'),', ').replace(/,(?!.*,)/gmi, ' and ');

  return (
    <div className="radar-card-head">
      <h2 className='section-title'>
        {/* Map of percent change over time by {selectedShapeAreaEntry['name']} */}
        Side-by-side comparison maps
      </h2>
      <p className="section-description">
        Percent change in {selectedMatricsName} by {selectedShapeAreaEntry['shape_name']}
        &nbsp;from {compareDateRangeText} to {currentDateRangeText}.
      </p>
    </div>
  );
}

RadarMapHeader.propTypes = {
  selectedMetricEntries: PropTypes.array,
  currentDateRangeOptions: PropTypes.object,
  previousDateRangeOptions: PropTypes.object,
  selectedShapeAreaEntry: PropTypes.object,
}

RadarMapHeader.defaultProps = {
  selectedMetricEntries: [],
  currentDateRangeOptions: {},
  previousDateRangeOptions: {},
  selectedShapeAreaEntry: {},
}

export default RadarMapHeader;
