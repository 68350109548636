import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ForgeCheckbox } from '@tylertech/forge-react';
import { isMyViews } from '../CardList/cardHelper';
import { isCollaborateCollection } from '../ManageCollection/collaboratorHelper';

function OnboardCollections(props) {
  const { collections, onCollectionData } = props;
  const [isSelectAll, setIsSelectAll] = useState(true);

  const handleClickCollection = (collection) => {
    const newCollections = _.map(collections, (datum) => {
      if (datum.id === collection.id) {
        datum.is_hidden = !collection.is_hidden
      }
      return datum;
    });

    const filterCollections = _.filter(collections, (collection) => {
      if (!collection.is_hidden) {
        return collection;
      }
    })

    const selectStatus = (_.size(filterCollections) == _.size(collections));
    setIsSelectAll(!selectStatus);

    onCollectionData(newCollections);
  }

  const handleClickSelectAll = () => {

    const neCollections = _.map(collections, (datum) => {
      datum.is_hidden = !isSelectAll
      return datum;
    });
    setIsSelectAll(!isSelectAll);
    onCollectionData(neCollections);

  }

  const collectionItem = (collection, index) => {
    if (isMyViews(collection) || isCollaborateCollection(collection)) {
      return null;
    }
    const uniqueId = `onboard-wizard-${index}`;

    return (
      <ForgeCheckbox>
        <input type="checkbox" id={uniqueId}
          checked={!collection.is_hidden}
          onClick={() => handleClickCollection(collection)} />
        <label htmlFor={uniqueId} slot="label" className='mb-0'>{collection.name}</label>
      </ForgeCheckbox>
    )
  }

  const renderSelectAll = () => {
    const uniqueId = `onboard-wizard-select-all`;
    return (
      <ForgeCheckbox>
        <input type="checkbox" id={uniqueId}
          checked={!isSelectAll}
          onClick={() => handleClickSelectAll()} />
        <label htmlFor={uniqueId} slot="label" className='mb-0'>Select all</label>
      </ForgeCheckbox>
    )
  }

  const collectionItems = () => {
    return _.map(collections, (collection, index) => {
      return collectionItem(collection, index);
    })
  }

  return (
    <div className='d-flex flex-column'>
      {renderSelectAll()}
      {collectionItems()}
    </div>
  )
}

OnboardCollections.propTypes = {
  collections: PropTypes.array,
  onCollectionData: PropTypes.func
}

export default OnboardCollections
