import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

import GlobalEvent from 'common/components/GlobalEvents';
import { getAppExpirationBannerColor } from 'common/config/customerConfiguration';

export default function ExpiryOverlay({ expiryMessage }) {
  const [isEnabled, setIsEnabled] = useState(true);
  const [templateMessage, setTemplateMessage] = useState('');

  const bannerConfigColor = getAppExpirationBannerColor();
  const bannerColor = {
    backgroundColor: bannerConfigColor,
    borderColor: bannerConfigColor
  }

  useEffect(() => {
    GlobalEvent.on('TEMPLATE_EXPIRY_MESSAGE', setExpiryMessage);
    return () => {
      GlobalEvent.off('TEMPLATE_EXPIRY_MESSAGE', setExpiryMessage);
    };
  }, []);

  const setExpiryMessage = (data) => {
    setTemplateMessage(_.get(data, 'message'))
  }

  const handleClickClose = () => {
    setIsEnabled(false)
  }

  if (!isEnabled) {
    return null;
  }

  return (
    <div
      className="alert alert-danger alert-dismissible fade show text-center tx-14 mb-0"
      role="alert"
      style={bannerColor}>
      {expiryMessage} {templateMessage}
      <button type="button"
        className="close pad-top-2"
        onClick={() => handleClickClose()}
        data-dismiss="alert"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

ExpiryOverlay.propTypes = {
  expiryMessage: PropTypes.string
}
