import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LineChart from 'modules/visualization/LineChart';
import {
  isComboChartEnable
} from 'common/config/visualizationConfiguration';
import { configuredDefaultDateType } from 'common/config/customerConfiguration';
import {
  getForecastChartAttributes,
  defaultPrepareDataAxisGranularityOption
} from 'pages/Forecasting/ForecastHelper';
import { VIEW_MODE } from 'modules/visualization/constants';

import {
  VISUALIZATION_TYPES,
  OVERTIME_TIME_FRAME_OPTIONS,
  OVERTIME_DIMENSION_ORDER
} from 'appConstants';
import {
  updateOvertimeSecondaryMetricField,
  updateOvertimeBenchMarkName,
  updateOvertimeShowProjection,
  updateSliderRange,
  updateDimensionConfigsByType,
  updateCurrentForecastOption
} from 'actions/overtimeActions';
import {
  updateForecastAxisGranularity
} from 'actions/forecastingActions';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { updateDateRange } from 'actions/commonFiltersActions';
import { isSmallForecastingChartSize, resizeWindowHandler } from 'helpers/DomPageHelper';
import classNames from 'classnames';

class ForecastLineChart extends Component {
  constructor(props) {
    super(props);
    const { currentDrilldownViewEntry, currentChartView } = this.props;

    this.state = {
      visualizationData: [],
      isLoading: false,
      currentPage: 0,
      legendEntries: [],
      prophetForecastingData: {},
      dimensionConfig: {},
      comboMetricConfigs: {},
      toggleLegendEntries: {},
      currentForecastPercentage: 3,
      isComboChart: isComboChartEnable(currentDrilldownViewEntry, currentChartView),
      chartFormattedData: [],
      summaryFormattedData: [],
      totalRowCount: 0,
      isOpenLegend: true,
      leastGranularityApiData: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      currentDrilldownViewEntry,
      currentDrilldownDimensionField,
      currentChartView,
      forecastModelOptions,
      futureForecastDateRange,
      projectionAdjustedValues,
      currentForecastDateRange
    } = this.props;
    const isCurrentViewEntryChanged = !_.isEqual(
      prevProps.currentDrilldownViewEntry, currentDrilldownViewEntry);
    const isCurrentChartViewChanged = !_.isEqual(
      prevProps.currentChartView, currentChartView);
    const isCurrentDimensionChanged = !_.isEqual(
      prevProps.currentDrilldownDimensionField, currentDrilldownDimensionField);
    const isCurrentForecastModalChanged = !_.isEqual(
      prevProps.forecastModelOptions, forecastModelOptions);
    const isFutureDateRangeChanged = !_.isEqual(
      prevProps.futureForecastDateRange, futureForecastDateRange);
    const isCurrentDateRangeChanged = !_.isEqual(
      prevProps.currentForecastDateRange, currentForecastDateRange);

    const isAdjustValuesChanged = !_.isEqual(
      prevProps.projectionAdjustedValues, projectionAdjustedValues);

    // Set default options when chartType changed
    if (isCurrentViewEntryChanged || isCurrentDimensionChanged || isCurrentChartViewChanged) {
      this.setState({
        isComboChart: isComboChartEnable(currentDrilldownViewEntry, currentChartView),
      });
    }


    if (isCurrentForecastModalChanged || isCurrentChartViewChanged ||
      isCurrentDateRangeChanged || isFutureDateRangeChanged || isAdjustValuesChanged) {
      this.setState({ leastGranularityApiData: null });
    }
    if(!_.isEqual(this.props.legendWidth, prevProps.legendWidth)){
      resizeWindowHandler();
    }
  }

  onNewApiData = (lineChartData) => {
    this.setState({ leastGranularityApiData: lineChartData });
  }

  handleSelectView = (currentChartView) => {
    const { onHandleSelectView } = this.props;
    onHandleSelectView(currentChartView)
  }

  onNewFormattedData = (formattedData) => {
    const { leastGranularityApiData } = this.state;
    this.setState({ chartFormattedData: formattedData });
    this.props.onUpdateChartData(formattedData, leastGranularityApiData);
  }

  onAfterSummaryTableDataLoad = (plotlyFormattedData) => {
    if (!_.isEmpty(plotlyFormattedData)) {
      this.props.onUpdateSummaryData(plotlyFormattedData)
    }
  }

  renderLineChart(renderChartContent) {
    const {
      dispatchUpdateDimensionConfigsByType,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId
    } = this.props;
    const { isComboChart, leastGranularityApiData } = this.state;
    let lineChartAttributes = getForecastChartAttributes({
      forecastOptions: { ...this.props, isComboChart },
      viewEntry: currentDrilldownViewEntry,
      templateId: currentDrilldownTemplateId,
      commonFilters: {}
    });
    lineChartAttributes = {
      ...lineChartAttributes,
      onNewFormattedData: this.onNewFormattedData,
      handleDimensionConfigsChange: dispatchUpdateDimensionConfigsByType,
      render: renderChartContent,
      dateType: configuredDefaultDateType,
      onDataLoading: this.props.onDataLoading,
      isForecastingView: true,
      onAfterSummaryTableDataLoad: this.onAfterSummaryTableDataLoad,
      viewMode: VIEW_MODE.LARGE,
      isOvertimeChart: true,
      onNewApiData: this.onNewApiData,
      apiData: leastGranularityApiData
    }

    if (_.isEmpty(currentDrilldownViewEntry) || _.isEmpty(currentDrilldownTemplateId)) {
      return null;
    }

    if (isComboChart) {
      return <div className="py-3 text-center">
        Advanced Forecasting will only support Timeline and Burnrate Overtime charts
      </div>
    }

    return (<LineChart {...lineChartAttributes} />);
  }

  render() {
    const { isOpenLegend, legendWidth } = this.props;
    const vizWrapperClass = classNames('visualization-wrapper', {
      'visualization-width': isOpenLegend && !isSmallForecastingChartSize()
    });
    
    const legendWidthStyle = isOpenLegend && !isSmallForecastingChartSize() ? 
    `calc(100%  - ${legendWidth+5}px)` : '';

    return this.renderLineChart((chart) => (
      <div className="chartContainer viz-wrapper legend-container" id="id-forecasting-line-chart">
        <div className={vizWrapperClass} style={{ width: legendWidthStyle }}>
          <div className="visualization-lside">
            {chart}
          </div>
        </div>
        {/* {this.renderLegendItems()} */}
      </div>
    ));
  }
}

ForecastLineChart.propTypes = {
  minDatesTemplateForForecast: PropTypes.array,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentDrilldownDimensionField: PropTypes.string,
  axisGranularity: PropTypes.string,
  dimensionSortOrder: PropTypes.string,
  currentForecastOption: PropTypes.string,
  currentSelectedTimeFrame: PropTypes.string,
  compareYearRanges: PropTypes.array,
  currentSecondaryMetricField: PropTypes.string,
  currentBenchMarkNames: PropTypes.array,
  isShowProjection: PropTypes.bool,
  sliderRange: PropTypes.array,
  currentVisualizationType: PropTypes.string,
  dimensionConfigsByRenderType: PropTypes.object,
  isComparisonEnabled: PropTypes.bool,
  currentChartView: PropTypes.string,
  compareToDateRange: PropTypes.object,
  dispatchUpdateAxisGranularity: PropTypes.func,
  dispatchUpdateSecondaryMetric: PropTypes.func,
  dispatchUpdateBenchMarkName: PropTypes.func,
  dispatchUpdateShowProjection: PropTypes.func,
  dimensionRowCount: commonPropTypes.stringOrNumberProps,
  dispatchUpdateCurrentForecastOption: PropTypes.func,
  dispatchUpdateSliderRange: PropTypes.func,
  dispatchUpdateDimensionConfigsByType: PropTypes.func,
  onUpdateChartData: PropTypes.func,
  dispatchUpdateDateRange: PropTypes.func,
  onDataLoading: PropTypes.func,
  futureForecastDateRange: PropTypes.object,
  currentForecastDateRange: PropTypes.object,
  forecastModelOptions: PropTypes.array,
  forecastPrepareDataTime: PropTypes.string,
  isUpdateChartData: PropTypes.bool,
  projectionAdjustedValues: PropTypes.array,
  onHandleSelectView: PropTypes.func,
  onUpdateSummaryData: PropTypes.func,
  isOpenLegend: PropTypes.bool,
  legendWidth: PropTypes.number
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateSecondaryMetric: (field) => {
      dispatch(updateOvertimeSecondaryMetricField(field));
    },
    dispatchUpdateBenchMarkName: (entry) => {
      dispatch(updateOvertimeBenchMarkName(entry));
    },
    dispatchUpdateShowProjection: (enable) => {
      dispatch(updateOvertimeShowProjection(enable));
    },
    dispatchUpdateAxisGranularity: (axisGranularity) => {
      dispatch(updateForecastAxisGranularity(axisGranularity));
    },
    dispatchUpdateSliderRange: (sliderRange) => {
      dispatch(updateSliderRange(sliderRange));
    },
    dispatchUpdateDimensionConfigsByType: (configs) => {
      dispatch(updateDimensionConfigsByType(configs));
    },
    dispatchUpdateCurrentForecastOption: (forecastOption) => {
      dispatch(updateCurrentForecastOption(forecastOption));
    },
    dispatchUpdateDateRange: (dateRange) => {
      dispatch(updateDateRange(dateRange));
      // dispatch(updateDateType(configuredDefaultDateType));
    }
  }
}

function mapStateToProps(state) {
  const currentDrilldownViewEntry = _.get(state, 'forecasting.selectedForecastMetric', {});
  const currentDrilldownTemplateId = _.get(state, 'forecasting.selectedForecastTemplateId', '') + '';

  let currentSelectedTimeFrame = OVERTIME_TIME_FRAME_OPTIONS.ROLLING;
  const defaultAxisGranularity = defaultPrepareDataAxisGranularityOption(currentDrilldownTemplateId);

  return {
    axisGranularity: _.get(state, 'forecasting.axisGranularity', defaultAxisGranularity),
    dimensionSortOrder: OVERTIME_DIMENSION_ORDER.HIGH_TO_LOW.type,
    currentDrilldownViewEntry,
    currentDrilldownDimensionField: _.get(state, 'forecasting.currentDrilldownDimensionField'),
    currentDrilldownTemplateId: currentDrilldownTemplateId,
    currentSelectedTimeFrame: currentSelectedTimeFrame,
    isComparisonEnabled: false,
    compareYearRanges: [],
    currentSecondaryMetricField: _.get(state, 'forecasting.currentSecondaryMetricField', {}),
    currentBenchMarkNames: [],
    isShowProjection: false,
    sliderRange: [],
    currentVisualizationType: VISUALIZATION_TYPES.OVERTIME.type,
    dimensionConfigsByRenderType: _.get(state, 'forecasting.dimensionConfigsByRenderType', {}),
    minDatesTemplateForForecast: _.get(state, 'forecasting.minDatesTemplateForForecast', []),
    futureForecastDateRange: _.get(state, 'forecasting.futureForecastDateRange', {}),
    forecastModelOptions: _.cloneDeep(_.get(state, 'forecasting.forecastModelOptions', [])),
    forecastPrepareDataTime: _.get(state, 'forecasting.forecastPrepareDataTime', ''),
    currentForecastDateRange: _.get(state, 'forecasting.currentForecastDateRange', {}),
    projectionAdjustedValues: _.get(state, 'forecasting.projectionAdjustedValues', {})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForecastLineChart);
