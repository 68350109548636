import React, { Component } from 'react';

import RadarPlotly from './RadarPlotly';
import PlotlyTooltip from 'modules/PlotlyTooltip';
import { PLOTLY_HOVER_DEBOUNCE_WAIT_TIME } from 'modules/visualization/constants';
import { getBarChartPopupConfigs } from 'common/contentFormatter/barChartContentFormatter';
import PropTypes from 'prop-types';
import { getRadarDateRangeText } from '../radarHelper';
import { handleAfterPlot } from './RadarBarChartHelper';
class RadarBarChart extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showPercentagePoint: false
    };
  }

  componentDidMount() {
    if (this.popupContainer) {
      this.plotlyTooltip = new PlotlyTooltip(this.popupContainer);
    }
  }

  formatBarChartData = () => {
    const { radarData, isSelectedTracts, isComparisonTracts,
      currentDateRangeOptions, previousDateRangeOptions } = this.props;

    const {
      currentDateRangeText, compareDateRangeText
    } = getRadarDateRangeText(currentDateRangeOptions, previousDateRangeOptions);

    let formattedChartData = [];
    const categoryData = _.get(radarData, 'categoryData', []);

    _.each(categoryData, (radarEntry) => {
      const currentValue = radarEntry['currentValue'];
      const compareValue = radarEntry['compareValue'];
      let customData = isSelectedTracts || isComparisonTracts ? [radarEntry['category'], 'Pre'] : ['Pre'];
      let formatData = {
        value: compareValue,
        year: 'Pre',
        category: radarEntry['category'],
        percentage: radarEntry['percentage'],
        customData: customData,
        dateRangeText: compareDateRangeText,
        templateMetricSettings: radarEntry['templateMetricSettings'],
      };

      formattedChartData.push(formatData);
      customData = isSelectedTracts || isComparisonTracts ? [radarEntry['category'], 'Post'] : ['Post'];
      formatData = {
        value: currentValue,
        year: 'Post',
        category: radarEntry['category'],
        percentage: radarEntry['percentage'],
        customData: customData,
        dateRangeText: currentDateRangeText,
        templateMetricSettings: radarEntry['templateMetricSettings'],
      };

      formattedChartData.push(formatData);
    });

    return formattedChartData;
  }

  onPlotlyHover = _.throttle((event) => {

    const {isSelectedTracts, isComparisonTracts} = this.props;

    this.setMouseCursor('inherit');
    const popupConfigs = getBarChartPopupConfigs({
      chartContainer: this.chartContainer,
      data: event,
      templateId: 0,
      viewMode: "small",
      isCurrencyDimensionField: false,
      isCurrencyGroupByField: false,
      isRadarBarChart: true,
      isSelectedTracts: isSelectedTracts,
      isComparisonTracts: isComparisonTracts
    });
    this.plotlyTooltip.showPopups(popupConfigs);
  }, PLOTLY_HOVER_DEBOUNCE_WAIT_TIME);

  onPlotlyUnhover = () => {
    this.setMouseCursor('inherit');
    this.plotlyTooltip.hidePopups();
  };

  onContainerMouseOut = () => {
    this.setChartMouseCursor('inherit');
    this.plotlyTooltip.hidePopups();
  }

  setChartMouseCursor(cursor) {
    this.chartContainer.querySelector('g.draglayer').style.cursor = cursor;
  }

  setMouseCursor(cursor) {
    if (this.chartContainer.querySelector('g.draglayer')) {
      this.chartContainer.querySelector('g.draglayer').style['cursor'] = cursor;
    }
  }

  onPlotlyAfterPlot = () => {
    handleAfterPlot(this.chartContainer);
  }

  render() {
    const { isSelectedTracts, isComparisonTracts } = this.props;
    const chartData = this.formatBarChartData();

    const extraPlotlyParams = {
      onHover: this.onPlotlyHover,
      onUnhover: this.onPlotlyUnhover,
      onAfterPlot: this.onPlotlyAfterPlot,
    };

    return (
      <div className="radar-bar-chart position-relative" ref={(ref) => this.chartContainer = ref}
        onMouseOut={this.onContainerMouseOut}
      >
        <RadarPlotly
          isSelectedTracts={isSelectedTracts}
          isComparisonTracts={isComparisonTracts}
          data={chartData}
          extraPlotlyParams={extraPlotlyParams}
        ></RadarPlotly>
        <div className="popup-container" ref={(ref) => this.popupContainer = ref}>
        </div>
      </div>
    )
  }
}

RadarBarChart.propTypes = {
  radarData: PropTypes.array,
  selectedTracts: PropTypes.array,
  comparisonTracts: PropTypes.array,
  selectedMetrics: PropTypes.array,
  currentDateRangeOptions: PropTypes.object,
  previousDateRangeOptions: PropTypes.object,
  isSelectedTracts: PropTypes.bool,
  isComparisonTracts: PropTypes.bool
}

export default RadarBarChart;
