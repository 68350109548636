import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ForgeButton } from '@tylertech/forge-react';

import Tabs from 'pages/Tabs';
import CollectionsManager from './components/CollectionsManager';
import BookmarksManager from './components/BookmarksManager';
import LoadingSpinner from 'common/components/LoadingSpinner';

import { COLLECTION_TAB, SAVED_VIEWS_TAB, ALERTS_TAB } from 'appConstants';
import { getDateFilterParams } from 'helpers/dateHelper';
import { bulkUpdateCollections } from 'common/api/collectionApi';
import { bulkUpdateBookmarks } from 'common/api/bookmarksApi';
import { trackEvent } from 'helpers/eventTracking';
import { anyEmptyBookmarkValues, getBookmarkType } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import { userPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { handleBackButtonClick } from 'helpers/visualizationHelper';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import { getBookmark } from 'common/api/bookmarksApi';

class SubscriptionsManager extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      currentTab: COLLECTION_TAB.type,
      collections: [],
      bookmarks: [],
      isLoading: false,
      redirectToOverview: false,
      isBookmarkOrCollectionSaved: false,
      statusOption: {
        isBookmarkChanges : false,
        isCollectionChanges : false
      }
    };
  }
  componentDidMount() {
    const { currentBookmarkId, currentBookmarkType } = this.props;

    if(!_.isEmpty(currentBookmarkId)){
      if (!_.isEmpty(currentBookmarkType)){
        const currentTabByBookmark = (currentBookmarkType == SAVED_VIEWS_TAB.type)
        ? SAVED_VIEWS_TAB.type : ALERTS_TAB.type;
        this.setState({currentTab: currentTabByBookmark});
      }else{
        this.updateCurrentTabByBookmarkId(currentBookmarkId);
      }
    }
  }

  updateCurrentTabByBookmarkId(currentBookmarkId){
    const { dispatchUpdateBookmark } = this.props;

    getBookmark(currentBookmarkId)
      .then((response) => response.json())
      .then((response) => {
        dispatchUpdateBookmark(response);
        const bookmarkType = getBookmarkType(response);
        const currentTabByBookmark = (bookmarkType == SAVED_VIEWS_TAB.type)
        ? SAVED_VIEWS_TAB.type : ALERTS_TAB.type;
        this.setState({currentTab: currentTabByBookmark});
      });
  }

  handleTabChange = (tab) => {
    const { statusOption } = this.state;
    const currentTab = _.get(tab, 'type', COLLECTION_TAB.type);
    statusOption.isBookmarkChanges = false;
    statusOption.isCollectionChanges = false;
    this.setState({ currentTab, statusOption });
  }

  handleCollectionUpdate = (collections, isCollectionModify) => {
    let { statusOption } = this.state;
    statusOption.isCollectionChanges = isCollectionModify
    this.setState({collections, statusOption, isBookmarkOrCollectionSaved: false});
  }

  handleBookmarkUpdate = (bookmarks, isBookmarkModify) => {
    let { statusOption } = this.state;
    statusOption.isBookmarkChanges = isBookmarkModify
    this.setState({bookmarks, statusOption, isBookmarkOrCollectionSaved: false});
  }

  handleDoneButtonClick = () => {
    const { collections, bookmarks, currentTab, statusOption } = this.state;
    this.setState({isLoading: true });
    let anyOneInputValueEmpty = false;

    if(currentTab == SAVED_VIEWS_TAB.type || currentTab == ALERTS_TAB.type ){
      const updateBookmarks = _.map(bookmarks, (bookmarkEntry) => {
        anyOneInputValueEmpty = anyEmptyBookmarkValues(bookmarkEntry, anyOneInputValueEmpty);

        return {
          bookmark : bookmarkEntry,
          shareEmails: [],
          include_current_user:true,
          shareFieldInputs: {}
        }
      });

      if(anyOneInputValueEmpty) {
        this.setState({ isLoading: false });
        toast.error('Error while saving changes');
      } else {
        trackEvent('manage_done');
        bulkUpdateBookmarks(updateBookmarks)
          .then((response) => response.json())
          .then(() => {
          statusOption.isBookmarkChanges = false;
          this.setState({
            isLoading: false,
            redirectToOverview: false,
            statusOption,
            isBookmarkOrCollectionSaved: true});
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error(`Error while update bookmark. ${error}`);
        });
      }
    } else {
      _.each(collections, (collection) => {
        const { collection_subscriptions } = collection;
        _.each(collection_subscriptions, (collectionSubscription) => {
          const { frequency_interval } = collectionSubscription;
          if(!frequency_interval || (frequency_interval < 1)) {
            anyOneInputValueEmpty = true;
          }
        })
      })
      if(anyOneInputValueEmpty) {
        this.setState({ isLoading: false });
        toast.error('Error while saving changes');
        return;
      }
      trackEvent('manage_done');
      bulkUpdateCollections(collections)
        .then((response) => response.json())
        .then(() => {
          statusOption.isCollectionChanges = false;
          this.setState({
            isLoading: false,
            redirectToOverview: false,
            statusOption,
            isBookmarkOrCollectionSaved: true });
      });
    }
  }

  renderTabs(){
    const { currentTab } = this.state;
    const tabs = [COLLECTION_TAB, SAVED_VIEWS_TAB, ALERTS_TAB]
    return(
      <Tabs tabs={tabs} onTabChange={this.handleTabChange} currentTab={currentTab} />
    )
  }

  renderTabContent() {
    const { currentTab, isBookmarkOrCollectionSaved } = this.state;
    const {
      dateFilters,
      currentUser,
      currentBookmarkId,
      userCollectionsWithCards
    } = this.props;

    if(currentTab == SAVED_VIEWS_TAB.type || currentTab == ALERTS_TAB.type) {
      return(
        <BookmarksManager
          currentBookmarkId={currentBookmarkId}
          onBookmarksUpdate={this.handleBookmarkUpdate}
          dateFilters={dateFilters}
          currentUser={currentUser}
          isBookmarkSaved={isBookmarkOrCollectionSaved}
          currentTab={currentTab}
          userCollectionsWithCards = {userCollectionsWithCards}
        />
      )
    } else {
      return(
        <CollectionsManager
          onCollectionsUpdate={this.handleCollectionUpdate}
          dateFilters={dateFilters}
          currentUser={currentUser}
          isCollectionSaved={isBookmarkOrCollectionSaved}
        />
      )
    }
  }

  renderBackOverView(){
    return (
      <button
        aria-label="Back To Overview"
        className="btn back-arrow-icon back-btn-light"
        onClick={() => handleBackButtonClick(this.props.history)}>
        <i className="icons-arrow-left2" />
      </button>
    );
  }

  render() {
    const { isLoading, redirectToOverview, statusOption } = this.state;
    if (redirectToOverview) {
      return <Redirect push to="/overview" />;
    }
    return (
      <main className="bg-white subscription-management">
        <div className="page-main-head">
          <div className="d-flex align-items-center gap-16">
            {this.renderBackOverView()}
            <span className="title-head"> Settings </span>
          </div>
          <ForgeButton type="flat" className="ml-auto">
            <button
              tabIndex={0}
              disabled ={!statusOption.isBookmarkChanges && !statusOption.isCollectionChanges}
              onClick={this.handleDoneButtonClick}
              className="btn btn-primary">
              Save
            </button>
          </ForgeButton>
        </div>
        <div className="sub-management-wrapper">
          {this.renderTabs()}
          <LoadingSpinner isLoading={isLoading} />
          {this.renderTabContent()}
        </div>
      </main>
    )
  }
}

SubscriptionsManager.propTypes = {
   currentBookmarkId: PropTypes.string,
   currentBookmarkType: PropTypes.string,
   dateFilters: PropTypes.object,
   currentUser: userPropTypes,
   history: PropTypes.object,
   dispatchUpdateBookmark: PropTypes.func,
   userCollectionsWithCards: PropTypes.array
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateBookmark: (bookmark) => {
      const { id, name } = bookmark;
      const bookmarkType = getBookmarkType(bookmark);
      dispatch(updateCurrentBookmark(id, name, bookmarkType));
    }
  }
}

function mapStateToProps(state) {
  return{
    dateFilters: getDateFilterParams(_.get(state,'commonFilters', {})),
    currentUser: _.get(state.currentUser, 'user', {}),
    currentBookmarkId: _.get(state, 'bookmark.currentBookmarkId', ''),
    currentBookmarkType: _.get(state, 'bookmark.currentBookmarkType', ''),
    userCollectionsWithCards: _.get(state, 'userCollections.userCollectionsWithCards', [])

  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionsManager));
