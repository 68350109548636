import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PopupAnimationType } from '@tylertech/forge';
import { ForgePopup } from '@tylertech/forge-react';
import { isClickOutSideFilterPopup } from 'common/components/Filters/FilterDomHelper';
import $ from 'jquery';

class ForgePopupOption extends Component {

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { targetPopupRef, closeFilterChip, isIncludeOutsideClose } = this.props;
    const closestClassNames = ['.global-filter', 
      '.custom-forge-popup-option', '.dropdown-filter-footer', 'forge-popup'];

    if (isIncludeOutsideClose &&
      isClickOutSideFilterPopup(e, _.get(targetPopupRef, 'current'), closestClassNames)) {
      closeFilterChip(false);
    }
  }

  render() {
    const { children, targetPopupRef, showPopup, placement, childrenClass } = this.props;
    const animationType = PopupAnimationType.Menu, manageFocus = false;

    setTimeout(() => {
      if (showPopup){
        $("forge-popup").removeAttr("tabindex");
      }
    }, 100);
    const classNames = `custom-forge-popup-option ${childrenClass}`;
    return (
      <ForgePopup
        style={{ maxHeight: "100px" }}
        open={showPopup}
        targetElementRef={targetPopupRef}
        options={{ placement, manageFocus, animationType, static: 'true' }}>
        <div className={classNames}>
          {children}
        </div>
      </ForgePopup>
    )
  }
}

ForgePopupOption.propTypes = {
  children: PropTypes.any,
  placement: PropTypes.string,
  targetPopupRef: PropTypes.any,
  closeFilterChip: PropTypes.func,
  isIncludeOutsideClose: PropTypes.bool,
  showPopup: PropTypes.any,
  childrenClass: PropTypes.string,
};

ForgePopupOption.defaultProps = {
  placement: "bottom-start",
  childrenClass: ''
}

export default ForgePopupOption;
