import _ from 'lodash';
import $ from 'jquery';
import {
  calculateSpiderfyTransformValue,
  getNewAnchorPosition,
  isMarkerOnCenterOfMap,
  updatePopupAnchorClass
} from "./MapPopupPositionHelper";

export default class MapPopupPosition {
  constructor(map, options, spiderMarkerPopup, spiderLeg) {
    this._map = map;
    this._options = options;
    this._spiderMarkerPopup = spiderMarkerPopup;
    this._spiderLeg = spiderLeg;
    this._isAdvancedSearch = _.get(this._options, 'isAdvancedSearch', false);
  }

  adjustPopupPosition = () => {
    setTimeout(() => {
      const img = document.querySelector('.mapboxgl-popup-content .external_image img');
      if(img.complete && img.naturalHeight !== 0) {
        setTimeout(() => {
          this.onUpdatePopup();
        }, 200);
      } else {
        this._addImageLoadEvent(img);
      }
    }, 0);
  }

  onUpdatePopup = () => {
    if(this._isAdvancedSearch){
      const isOnCentreLayer = isMarkerOnCenterOfMap(this._map, this._spiderMarkerPopup);
      if (isOnCentreLayer){
        const newAnchor = getNewAnchorPosition(this._map, this._spiderMarkerPopup);
        updatePopupAnchorClass(this._spiderMarkerPopup, newAnchor);
        const transformValue =
          calculateSpiderfyTransformValue(this._spiderMarkerPopup, this._spiderLeg?.param, newAnchor);
        $(this._spiderMarkerPopup._content)
          .closest('.mapboxgl-popup').css('transform', transformValue);
      }
    }
  }

  _addImageLoadEvent = (img) => {
    img.addEventListener('load', () => {
      setTimeout(() => {
        this.onUpdatePopup();
      }, 200);
    });
  }
}
