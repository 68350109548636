import _ from 'lodash';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import DropDownFooter from 'pages/drilldown/components/QuickFilterBar/DropDownFooter';
import { getAllFiscalYears, getFiscalYearByStartMonth } from 'helpers/fiscalPeriodUtils';
import { getDateRangeForYearly } from 'helpers/dateHelper';
import { getDateRangeStartMonth  } from 'common/config/customerConfiguration';
import DropdownGroup from 'common/components/DropdownGroup/DropdownGroup';

class YearlyCustomRangeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startYear: '',
      endYear: '',
      showDropDownMenu: true
    };
  }

  componentDidMount(){
    this.assignStartEndYears();
  }

  componentDidUpdate(prevProp){
    const { dateRange, isDisabled } = this.props;

    if(!_.isEqual(prevProp.dateRange, dateRange) && !isDisabled){
      this.assignStartEndYears();
    }
  }

  assignStartEndYears(){
    const { dateRange } = this.props;
    let startYear = moment(dateRange.startDate).format('YYYY');
    if (getDateRangeStartMonth() > 1 ) {
      // In fiscal year if selected year is 2016 means date range will 
      // be 2015 to 2016, so here start date is added with one year.
      startYear = moment(dateRange.startDate).add(1, 'year').format('YYYY');
    }
    const endYear = moment(dateRange.endDate).format('YYYY');

    this.setState({
      startYear: startYear,
      endYear: endYear,
      showDropDownMenu: false
    });
  }

  handleSelectStartDate = (year) => {
    this.setState({ startYear: year });
  }

  handleSelectEndDate = (year) => {
    this.setState({ endYear: year });
  }

  getFiscalYearOptions = (selectedYear) => {
    const { currentDrilldownTemplateId } = this.props;
    return getAllFiscalYears(selectedYear, currentDrilldownTemplateId);
  }

  handleApplyButtonClick = () => {
    const { startYear, endYear } = this.state;
    const { onDateFilterChange } = this.props;

    const startYearByStartMonth = getFiscalYearByStartMonth(startYear);
    const endYearByStartMonth = getFiscalYearByStartMonth(endYear);
    const startYearDateRange = getDateRangeForYearly(startYearByStartMonth);
    const endYearDateRange = getDateRangeForYearly(endYearByStartMonth);

    const dateRange = {
      startDate: startYearDateRange.startDate,
      endDate: endYearDateRange.endDate
    }
    this.setState({ showDropDownMenu: false });
    onDateFilterChange(dateRange);
  }

  handleRemoveButtonClick = () => {
    const { removeCustomRangeYearly } = this.props;
    this.setState({ showDropDownMenu: false });
    removeCustomRangeYearly()
  }

  handleToggleDropdown = () => {
    let { showDropDownMenu } = this.state;
    this.setState({ showDropDownMenu: !showDropDownMenu });
  }

  renderRangeFilterTitle() {
    const { startYear, endYear } = this.state;
    const { dateRange, isDisabled } = this.props;
    let selectedYears = "";
    if(!_.isEmpty(dateRange) && isDisabled) {
      selectedYears = `${endYear}`;
    } else if(!_.isEmpty(dateRange)) {
      selectedYears = `${startYear} - ${endYear}`;
    }

    return (
      <div className="custom-year-label filter-items">
        <label> Fiscal Year </label>
        <div onClick={this.handleToggleDropdown}>
          <button className="btn filter-btn" disabled={isDisabled}>
            {selectedYears} <i className="icons-chevron-down"></i>
          </button>
        </div>
      </div>
    )
  }

  renderDateRangeInputFields() {
    const { startYear, endYear, showDropDownMenu } = this.state;
    const { isDisabled } = this.props;

    if(!showDropDownMenu) {
      return
    }

    return(
      <>
          <div className="yearly-custom-range-dropdown dropdown-menu show">
            <div className="yearly-custom-filter">
              <div>
                <DropdownGroup
                  disabled={isDisabled}
                  key="start-year"
                  value={_.isEmpty(startYear)?'select year': startYear}
                  label="Start Year"
                  toggleButtonName={_.isEmpty(startYear)? 'select year': startYear}
                  options={this.getFiscalYearOptions(startYear)}
                  optionDisplayField=""
                  onChange={this.handleSelectStartDate}
                />
              </div>
              <div>
                <DropdownGroup
                  disabled={isDisabled}
                  key="end-year"
                  value={_.isEmpty(endYear)?'select year': endYear}
                  label="End Year"
                  toggleButtonName={_.isEmpty(endYear)? 'select year': endYear}
                  options={this.getFiscalYearOptions(endYear)}
                  optionDisplayField=""
                  onChange={this.handleSelectEndDate}
                />
              </div>
            </div>
            <DropDownFooter
              onApply={this.handleApplyButtonClick}
              hideResetButton={true}
              onRemove={this.handleRemoveButtonClick}
            />
         </div>
      </>
    );
  }

  render() {

    return (
      <div className="yearly-custom-range custom-filter position-relative">
        {this.renderRangeFilterTitle()}
        {this.renderDateRangeInputFields()}
      </div>

      )
  }
}

YearlyCustomRangeFilter.propTypes = {
  dateRange:PropTypes.object,
  onDateFilterChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  removeCustomRangeYearly: PropTypes.func
}

function mapDispatchToProps() {
  return {};
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(YearlyCustomRangeFilter);
