import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { datePropTypes } from 'common/propTypes';
import { ForgeDateRangePicker, ForgeTextField } from '@tylertech/forge-react';
import { applyFilters, isClickOutSideFilter } from '../Filters/FilterDomHelper';
var _isOpenPicker = false;
var _isClosePicker = false;
var _isInputChangeValue = false;
var _isEnterKeyApply = false;
var _isStartDateValueChanged = false;
var _isEndDateValueChanged = false;

class StartAndEndForgeDatePicker extends PureComponent {
  constructor(props) {
    super(props);
    this.startAndEndDateRef = React.createRef();
    this._isApplyClicked = false;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onApply);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onApply);
  }

  handleChangeDatePicker = (e) => {
    if (!_isOpenPicker) {
      return
    }

    const targetId = _.get(e, 'target.id')
    if (targetId == 'input-date-range-picker-custom') {
      _isInputChangeValue = true;
      const selectDateRange = _.get(e, 'detail')
      if (!_.isNull(selectDateRange.from)) {
        _isStartDateValueChanged = true;
      }
      if (!_.isNull(selectDateRange.to)) {
        _isEndDateValueChanged = true;
      }
    }
  }

  handleChangeInputDate = (e) => {
    _isInputChangeValue = true;
    _isOpenPicker = false;
    const targetId = _.get(e, 'target.id')
    if (targetId == 'input-date-range-picker-01') {
      _isStartDateValueChanged = true;
    }

    if (targetId == 'input-date-range-picker-02') {
      _isEndDateValueChanged = true;
    }
  }

  openDatePicker = () => {
    _isOpenPicker = true;
  }

  closeDatePicker = () => {
    _isClosePicker = true;
    setTimeout(() => {
      this.handleChangeDateRangePicker('onApply');
    }, 200);
  }

  onBlueDateRangePicker = () => {
    if (this._isApplyClicked) {
      return;
    }
    this.handleChangeDateRangePicker('onBlur');
  }

  getValidStartDate = (date) => {
    const { minDate } = this.props;
    let startDate = _.cloneDeep(date);
    if(moment(startDate) < moment(minDate)){
      startDate = moment(minDate).format("YYYY-MM-DD");
    }
    document.getElementById('input-date-range-picker-01').value = startDate;
    return startDate;
  }

  getValidEndDate = (date) => {
    const { minDate } = this.props;
    let endDate = _.cloneDeep(date);
    if(moment(endDate) < moment(minDate)){
      endDate = moment(minDate).format("YYYY-MM-DD");
    }
    document.getElementById('input-date-range-picker-02').value = endDate;
    return endDate;
  }

  handleChangeDateRangePicker = () => {
    const {
      handleSelectEndDate, handleSelectStartDate,
      handleDateRangeChange, isComparisonMode
    } = this.props;
    const valueRef1 = document.getElementById('input-date-range-picker-01');
    const valueRef2 = document.getElementById('input-date-range-picker-02');

    const startDate = isComparisonMode ? _.cloneDeep(valueRef1.value)
      : this.getValidStartDate(valueRef1.value);
    const endDate = isComparisonMode ? _.cloneDeep(valueRef2.value)
      : this.getValidEndDate(valueRef2.value);
    if (!_.isEmpty(valueRef1) && !_.isEmpty(valueRef1.value) && !_.isEmpty(valueRef2.value)) {
      if (_isStartDateValueChanged) {
        handleSelectStartDate(startDate, _isEnterKeyApply);
      }

      if (_isEndDateValueChanged) {
        setTimeout(() => {
          handleSelectEndDate(endDate, _isEnterKeyApply);
        }, 50);
      }

      handleDateRangeChange(startDate, endDate, _isEnterKeyApply)
      _isInputChangeValue = false;
      _isOpenPicker = false;
      if (_isEnterKeyApply) {
        _isEnterKeyApply = false;
        _isClosePicker = false;
      }
      _isStartDateValueChanged = _isClosePicker ? _isClosePicker : false;
      _isEndDateValueChanged = _isClosePicker ? _isClosePicker : false;
    } else if (_isEnterKeyApply) {
      _isEnterKeyApply = false;
      applyFilters();
    }
  }

  onApply = (e) => {
    e.stopPropagation();
    if (!_isInputChangeValue) {
      return
    }

    const dropDownPickerRef = document.getElementById('input-date-range-picker-custom');
    if (isClickOutSideFilter(e, dropDownPickerRef) || _isEnterKeyApply) {
      this._isApplyClicked = true;
      this.handleChangeDateRangePicker('onApply');
    }
  }

  handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      _isEnterKeyApply = true;
      _isInputChangeValue = true;
      this.onApply(e);
    }
  }

  render() {
    const {
      minDate,
      maxDate,
      endDate,
      isDisabled,
      startDate
    } = this.props;

    const open = false,
      masked = false,
      maskFormat = 'MM/DD/YYYY',
      showMaskFormat = false,
      allowInvalidDate = true,
      showToday = true,
      showClear = false,
      disabledDaysOfWeek = []

    return (
      <div ref={this.startAndEndDateRef} onBlur={this.onBlueDateRangePicker}>
        <ForgeDateRangePicker id="input-date-range-picker-custom"
          disabled={isDisabled}
          from={startDate}
          to={endDate}
          min={minDate}
          max={maxDate}
          open={open}
          masked={masked}
          maskFormat={maskFormat}
          showMaskFormat={showMaskFormat}
          allowInvalidDate={allowInvalidDate}
          showToday={showToday}
          showClear={showClear}
          disabledDaysOfWeek={disabledDaysOfWeek}
          on-forge-date-range-picker-change={this.handleChangeDatePicker}
          on-forge-date-range-picker-open={this.openDatePicker}
          on-forge-date-range-picker-close={this.closeDatePicker}
          on-input={this.handleChangeInputDate}>
          <ForgeTextField>
            <input type="text" onKeyDown={this.handleKeyDown}
              id="input-date-range-picker-01" autoComplete="off" placeholder="mm/dd/yyyy" />
            <input type="text" onKeyDown={this.handleKeyDown}
              id="input-date-range-picker-02" autoComplete="off" placeholder="mm/dd/yyyy" />
            <label htmlFor="input-date-range-picker-01">Date</label>
          </ForgeTextField>
        </ForgeDateRangePicker>
      </div>
    );
  }
}

StartAndEndForgeDatePicker.propTypes = {
  endDate: datePropTypes,
  minDate: datePropTypes,
  maxDate: datePropTypes,
  isDisabled: PropTypes.bool,
  startDate: PropTypes.string,
  handleSelectStartDate: PropTypes.func,
  handleSelectEndDate: PropTypes.func,
  handleDateRangeChange: PropTypes.func,
  isComparisonMode: PropTypes.bool
}

StartAndEndForgeDatePicker.defaultProps = {
  isDisabled: false,
  handleSelectStartDate: _.noop,
  handleSelectEndDate: _.noop,
  handleDateRangeChange: _.noop,
  isComparisonMode: false
}

export default (StartAndEndForgeDatePicker);
