import _ from 'lodash';

import * as actionTypes from '../actionTypes/tableActionTypes';
import {  UPDATE_TABLE_LEAF_DATA }  from '../actionTypes/tableActionTypes';
import { UPDATE_VISUALIZATION_REDUCER } from 'actions/visualizationActions';

export const tableReducerDefaultState = () => {
  return {
    sortColumns: [],
    isLeafPage: false,
    showTableViewOnDrilldown: false,
    tableSearchEntries: []
  };
};

export default function (state = tableReducerDefaultState(), action) {
  switch (action.type) {
    case actionTypes.UPDATE_TABLE_SORT_COLUMNS:
      return {
        ...state,
        sortColumns: _.get(action, 'sortColumns')
      }

    case actionTypes.UPDATE_SHOW_LEAF_PAGE:
      return {
        ...state,
        isLeafPage: _.get(action, 'isLeafPage')
      }

    case actionTypes.UPDATE_LEAF_PAGE_RECORD_INDEX:
      return {
        ...state,
        leafPageCurrentRecordIndex: _.get(action, 'currentRecordIndex')
      }

    case actionTypes.UPDATE_SHOW_TABLE_ON_DRILLDOWN:
      return {
        ...state,
        showTableViewOnDrilldown: _.get(action, 'isTableView')
      }

    case actionTypes.UPDATE_LEAF_PAGE_NOTE_ID:
      return {
        ...state,
        leafPageCurrentNoteId: _.get(action, 'currentNoteId')
      }

    case UPDATE_VISUALIZATION_REDUCER:
      return {
        ...state,
        ..._.get(action, 'visualization.table')
      }

    case UPDATE_TABLE_LEAF_DATA:
      return {
        ...state,
        currentLeafData : _.get(action, 'currentLeafData')
      }

    default:
      return state;
  }
}
