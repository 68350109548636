import React from 'react';
import PropTypes from 'prop-types';
import SplitPane from 'react-split-pane';
import { DEFAULT_MAP_CONTAINER_HEIGHT } from 'appConstants';

const ReactSplitPane = ({ children, onChangeSize, containerHeight }) => {

  const onChange = (size) => {
    onChangeSize(size);
  }

  return (
    <SplitPane
      split='horizontal'
      defaultSize={containerHeight}
      onChange={onChange}
    >
      {children}
    </SplitPane>
  );
}

ReactSplitPane.defaultProps = {
  containerHeight: DEFAULT_MAP_CONTAINER_HEIGHT,
  children: null,
  onChangeSize: _.noop
}

ReactSplitPane.propTypes = {
  containerHeight: PropTypes.number,
  children: PropTypes.any,
  onChangeSize: PropTypes.func
}

export default ReactSplitPane;
