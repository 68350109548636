import React from 'react'
import PropTypes from 'prop-types'

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const ResetButton = ({ onUpdate, ariaLabel }) => {
  const handleResetBtn = () => {
    onUpdate([]);
  }

  const handleKeyDown = (e) => {
    if(isEnterButtonPressed(e)) {
      handleResetBtn();
    }
  }

  return (
    <span
      className="reset-link forge-typography--caption"
      tabIndex={0}
      role="button"
      aria-label={ariaLabel}
      onClick={handleResetBtn}
      onKeyDown={handleKeyDown}
    >Reset</span>
  );
}

ResetButton.propTypes = {
  onUpdate: PropTypes.func,
  ariaLabel: PropTypes.string
}

ResetButton.defaultProps = {
  ariaLabel: ''
}

export default ResetButton;
