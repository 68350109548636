import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import LoadingSpinner from 'common/components/LoadingSpinner';

import { getSearchHighlightedColumnValue } from './TableHelper';
import { GEO_LOCATION_COLUMN_TYPES } from 'appConstants';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getDistinctValue } from 'common/api/drilldown';
import {
  shouldShowTableAccordionIcon,
  formattedValueByDataType,
  formattedHyperLinkValue } from './TableHelper';
import { getSecondsAsDuration } from 'helpers/visualizationHelper';

class TableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowData: {},
      isLoading: false
    };
    this.abortFetchController = new AbortController();
  }

  componentDidUpdate(prevProps) {
    if(this.props.isEmptyRowData !== prevProps.isEmptyRowData) {
      this.setState({
        rowData: {}
      })
    }
  }

  fetchRowExtraData = (entry) => {
    const { apiParams } = this.props;
    const rowId = entry['row_id_field'];

    const queryParams = _.merge({}, apiParams, {
      row_id_field: rowId
    });
    const distinctDataApi = getDistinctValue(queryParams);
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    this.setState({ isLoading: true });
    fetchApiData(distinctDataApi, this.abortFetchController).then( (response) => {
      this.setState({
        rowData: {[rowId]: response},
        isLoading: false
      });
    }, (error) => {
      if (error.name !== 'AbortError') {
        console.error("Error on fetching data", error);
        this.setState({ isLoading: false });
      }
    });
  }

  isMultipleValueColumn = (field, rowEntry, columnEntry) => {
    const { templateEntry } = this.props;
    const isMultipleValueColumn = (!_.isEmpty(columnEntry['column_dataset']) &&
        Number(_.get(rowEntry, `${field}_count__`, 0)) > 1);
    return (isMultipleValueColumn && templateEntry['dedupe_last_startegy_option'] == 'all');
  }

  getNotes = (entry) => {
    const noteMessage = entry['notes_message'] || entry['message'] || '';
    const noteAssignee = entry['notes_assignee'] || entry['assignee'] || '' ;
    let noteLastCalled = entry['notes_last_called'] || entry['last_called'] || '';

    noteLastCalled = _.isEmpty(noteLastCalled) ? '' : moment(noteLastCalled).format("MMM DD, YYYY hh:mm a");

    return (
      <div>
        <div className="mb-3">{ noteMessage }</div>
        <div>{ noteAssignee }</div>
        <div>{ noteLastCalled }</div>
      </div>
    );
  }

  handleColumnValueClick = (e, isMultipleValueColumn, isHyperLinkColumn, rowEntry) => {
    const { rowData } = this.state;
    const rowId = rowEntry['row_id_field'];
    if((!_.isEmpty(rowData[rowId]) && isMultipleValueColumn) || isHyperLinkColumn){
      e.stopPropagation();
      this.setState({ rowData: {}});
    }else{
      if(isMultipleValueColumn) {
        this.setState({rowData: {}}, () => {
          this.fetchRowExtraData(rowEntry);
        });
        e.stopPropagation();
      }
    }

  }

   renderSpinner() {
    const { isLoading } = this.state;

    return (
      <LoadingSpinner isLoading={isLoading} />
    );
  }

  renderRowExtraData(rowEntry, field){
    const { rowData } = this.state;
    const rowId = rowEntry['row_id_field'];
    const rowDetails = _.get(rowData, rowId, []);

    if(!_.isEmpty(rowDetails)){
      return rowDetails.map((entry, index) => {
        return(<span key={index + rowId}>{entry[field]}</span>);
      });
    }else {
      return null;
    }
  }

  renderRow(){
    const {
      customColumnEntries,
      tableSearchEntries,
      rowEntry,
      columnEntries,
      templateEntry
    } = this.props;

    let notes;
    const isNotes = (_.includes(_.keys(rowEntry), 'notes_message')
      || (_.includes(_.keys(rowEntry), 'message') || _.includes(_.keys(rowEntry), 'assignee')));
    if(isNotes) {
      notes = this.getNotes(rowEntry);
    }
    return columnEntries.map((columnEntry, index) => {
      const columnName = columnEntry['field'];
      let tdValue = _.get(rowEntry, [columnName], '');
      const columnRenderType = _.get(columnEntry, 'renderType', '');
      const durationDataUnit = _.get(columnEntry, 'data_unit', 'seconds');
      const columnType = _.get(columnEntry, 'column');
      let isMultipleValueColumn;
      let isHyperLinkColumn;
      if (_.includes(GEO_LOCATION_COLUMN_TYPES, columnType)) {
        return null;
      }
      if(columnRenderType === 'hyperlink'){
        tdValue = formattedHyperLinkValue(columnEntry, rowEntry);
        isHyperLinkColumn = true;
      }else if(columnRenderType === 'duration'){
        tdValue = getSecondsAsDuration(tdValue, durationDataUnit);
      }else{
        isMultipleValueColumn = this.isMultipleValueColumn(columnName, rowEntry, columnEntry);
        if(isMultipleValueColumn && templateEntry['dedupe_last_startegy_option'] == 'all'){
          tdValue =  _.get(rowEntry, `${columnName}_count__`, '');
        }else{
          tdValue = formattedValueByDataType(tdValue, columnEntry);
        }
      }

      const isNoteIcon = _.isEmpty(tdValue) && _.includes(['note'], columnName) && isNotes;
      if(isNoteIcon) {
        tdValue = notes;
      }
      const toolTipClassNames = classNames({
        'note-tooltip': _.isEqual(columnName, 'note')
      });
      const tableDataClassNames = classNames({
        'multiline-ellipses': _.size(tdValue) > 50,
        'is-multiple-value-column':  isMultipleValueColumn
      });
      const highlightedColumnValue = getSearchHighlightedColumnValue(
        {value: tdValue, name: columnName},
        customColumnEntries,
        tableSearchEntries);

      const tooltip = (<Tooltip
        id={`tooltip-column-${index}`}
        className={toolTipClassNames} >{tdValue}</Tooltip>);

      let renderTdColumnContent;
      if(isNoteIcon) {
        renderTdColumnContent = (
          <OverlayTrigger key={index} placement="right" className="note-tooltip" overlay={tooltip}>
            <span className="column-icon align-self-center note-column">
              <i className='noteicon icons-noteIcon'/>
            </span>
          </OverlayTrigger>
        );
      } else {
        renderTdColumnContent = (
          <span className={tableDataClassNames}
            key={index}>
            {
              _.isString(highlightedColumnValue) ?
                <div
                  className="accordion-area"
                  dangerouslySetInnerHTML={{ __html: highlightedColumnValue }} /> :
                highlightedColumnValue
            }
            <span className="tooltip-container"></span>
            {isMultipleValueColumn ? this.renderRowExtraData(rowEntry, columnName) : null}
          </span>
        );
      }

      return (
        <td
          onClick={(e) => this.handleColumnValueClick(e, isMultipleValueColumn, isHyperLinkColumn, rowEntry)}
          key={index}>
          {renderTdColumnContent}
        </td>
      );
    });
  }
  renderAccordionIcon() {
    const { rowEntry, columnEntries, templateEntry } = this.props;
    const { rowData } = this.state;
    if(templateEntry['dedupe_last_startegy_option'] != 'all'){
      return null;
    }
    if(!shouldShowTableAccordionIcon(rowEntry, columnEntries)){
      return <td className="accordion-icon">{null}</td>
    }else{
      let iconContent;
      if(_.isEmpty(rowData)){
        iconContent = (<i className="icons-chevron-right"/>);
      }else {
        iconContent = (<i className="icons-chevron-down"/>);
      }
      return (
        <td className="accordion-icon" onClick={(e) => this.handleColumnValueClick(e, true, false, rowEntry)}>
          {iconContent}
        </td>
      )
    }
  }
  render(){
    return(
      <>
        {this.renderSpinner()}
        {this.renderAccordionIcon()}
        {this.renderRow()}
      </>
    )
  }

}
TableRow.propTypes = {
  templateEntry: PropTypes.object,
  columnEntries: PropTypes.array,
  customColumnEntries: PropTypes.array,
  tableSearchEntries: PropTypes.array,
  rowEntry: PropTypes.object,
  apiParams: PropTypes.object,
  isEmptyRowData: PropTypes.bool
}

export default TableRow;
