import React, { useState } from 'react';
import ForgeInputTextField from '../ForgeInputFields/ForgeInputTextField';
import { ForgeButton, ForgeCheckbox, ForgeIcon } from '@tylertech/forge-react';
import { toast } from 'react-toastify';
import { createUserAccountRequest } from 'common/api/drilldown';
import Recaptcha from '../Recaptcha/Recaptcha';
import LoadingSpinner from '../LoadingSpinner';
import { Typeahead } from 'react-bootstrap-typeahead';
import { isValidEmail } from './RequestHelper';

function RequestUsers(props) {
  const config = _.get(props, 'configurations', {});
  const isAccountCreation = _.get(config, 'allow_account_creation_request', 'false') == 'true';
  const showOffice = _.get(config, 'include_dropdown_menu_of_county', 'false') == 'true';
  const officeList = getFormattedCounty(JSON.parse(_.get(config, 'dropdown_menu_of_counties', '[]')));
  const showCountyDetail = showOffice && !_.isEmpty(officeList)

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [userRequest, setUserRequest] = useState({
    workEmail: '',
    firstName: '',
    lastName: '',
    title: '',
    officeName: '',
    countyEmail: '',
    recaptchaResponse: '',
    selectedCounty: [],
    isNotListedCounty: false
  });

  const handleChangeInput = (event, field) => {
    const value = { [field]: event.target.value };
    setUserRequest((prevObject) => {
      return { ...prevObject, ...value };
    });
  };

  const isValidInfo = () => {
    const isOffice = showCountyDetail ? !_.isEmpty(userRequest.officeName) : true;

    return (!_.isEmpty(userRequest.firstName) &&
      !_.isEmpty(userRequest.lastName) &&
      !_.isEmpty(userRequest.workEmail) &&
      !_.isEmpty(userRequest.recaptchaResponse) &&
      !_.isEmpty(userRequest.title) && (isOffice || userRequest.isNotListedCounty))
  };

  const onSubmit = () => {
    const domain = window.location.origin;
    let countyName = _.isArray(userRequest.officeName) ? userRequest.officeName[0] : userRequest.officeName;
    countyName = userRequest.isNotListedCounty ? 'My office/jurisdiction is not listed' : countyName;
    const apiParams = {
      first_name: userRequest.firstName,
      last_name: userRequest.lastName,
      work_email: userRequest.workEmail,
      domain_name: domain,
      title: userRequest.title,
      office_name: countyName,
      county_email: userRequest.countyEmail
    };

    if (!isValidEmail(userRequest.workEmail)) {
      toast.warning('Please enter valid email Id.');
      return;
    }

    setIsLoading(true);
    console.log('apiParams', apiParams);
    createUserAccountRequest(apiParams)
      .then((response) => response.json())
      .then((response) => {
        setIsLoading(false);
        if (response.error) {
          toast.error("Error! While creating request");
        } else {
          setIsSubmit(true);
        }
      }).
      catch((err) => {
        setIsLoading(false);
        toast.error('Request not send');
        console.error(err);
      });
  };

  const verifyCallback = (response) => {
    const value = { recaptchaResponse: response };
    setUserRequest((prevObject) => {
      return { ...prevObject, ...value };
    });
  };

  const renderRecaptcha = () => {
    return (
      <div className={`form-group`}>
        <div className="clearfix mb-2"></div>
        <Recaptcha disabled={!isValidInfo()} verifyResponse={verifyCallback} />
      </div>
    );
  };

  const gotoLoginPage = () => {
    window.location = window.location.origin;
  };

  const onSelectOffice = (office) => {
    const isNotCounty = userRequest.isNotListedCounty ?
      _.isEmpty(office) : userRequest.isNotListedCounty;

    const firstValue = _.first(office);
    const request = {
      officeName: _.get(firstValue, 'county'),
      countyEmail: _.get(firstValue, 'email'),
      isNotListedCounty: isNotCounty,
      selectedCounty: office
    }

    setUserRequest((prevObject) => {
      return { ...prevObject, ...request };
    });
  };

  const renderOffice = () => {
    if (!showCountyDetail) {
      return null;
    }

    return (
      <Typeahead
        placeholder='Office/Jurisdiction'
        id="office-list"
        onChange={(selected) => {
          onSelectOffice(selected);
        }}
        labelKey="county"
        options={officeList}
        selected={userRequest.selectedCounty} />
    );
  };

  const renderLoading = () => {
    return <LoadingSpinner isLoading={isLoading} />;
  };

  const handleCheckboxClick = () => {
    setUserRequest((prevObject) => {
      return { ...prevObject, isNotListedCounty: !userRequest.isNotListedCounty };
    });
  }

  const renderNotListedCheckbox = () => {
    if (!showCountyDetail) {
      return null;
    }

    return (
      <ForgeCheckbox>
        <input type="checkbox" disabled={!_.isEmpty(userRequest.officeName)}
          checked={userRequest.isNotListedCounty}
          id="county-checkbox-field"
          onClick={() => handleCheckboxClick()}
        />
        <label className="m-0" for="county-checkbox-field">My office/jurisdiction is not listed</label>
      </ForgeCheckbox>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <ForgeButton type="raised">
          <button
            className="w-100"
            disabled={!isValidInfo()}
            type="button"
            onClick={onSubmit} >
            <span>Request</span>
          </button>
        </ForgeButton>
        <hr className="w-100 mt-5 mb-2" vertical="true" />
        <div className="d-flex align-items-center gap-5">
          <span className="forge-typography--subtitle1">
            Already have an account?
          </span>
          <ForgeButton type="dense">
            <button type="button" onClick={() => gotoLoginPage()}>
              <ForgeIcon name="open_in_new" />
              <span>Sign in</span>
            </button>
          </ForgeButton>
        </div>
      </>
    );
  };

  if (!isAccountCreation) {
    return null;
  }

  const renderSubmitContent = () => {
    if (!isSubmit) {
      return null;
    }

    return (
      <div>
        Success! Your request to create an account has been submitted.
      </div>
    )
  }

  const renderContent = () => {

    if (isSubmit) {
      return null;
    }

    return (
      <div className="request-form">
        <img src="/images/tyler.png" alt="logo" className="min-logo" />
        <div className="my-2">
          <div className="forge-typography--headline3">
            Request an account
          </div>
          <div className="forge-typography--body1 mt-2">
            Your request will be sent to your site administrator
          </div>
        </div>
        <ForgeInputTextField
          type="text"
          label="Work email"
          placeholderLabel=""
          id="work_email"
          floatLabelType="always"
          value={userRequest.workEmail}
          handleOnChange={(e) => handleChangeInput(e, 'workEmail')}
        />
        <ForgeInputTextField
          type="text"
          label="First Name"
          placeholderLabel=""
          id="first_name"
          floatLabelType="always"
          value={userRequest.firstName}
          handleOnChange={(e) => handleChangeInput(e, 'firstName')}
        />
        <ForgeInputTextField
          type="text"
          label="Last Name"
          placeholderLabel=""
          id="last_name"
          floatLabelType="always"
          value={userRequest.lastName}
          handleOnChange={(e) => handleChangeInput(e, 'lastName')}
        />
        <ForgeInputTextField
          type="text"
          label="Title"
          placeholderLabel=""
          id="request_title"
          floatLabelType="always"
          value={userRequest.title}
          handleOnChange={(e) => handleChangeInput(e, 'title')}
        />
        {renderOffice()}
        {renderNotListedCheckbox()}
        {renderRecaptcha()}
        {renderFooter()}
      </div>
    )
  }

  return (
    <div className="request-wrapper">
      {renderLoading()}
      <img src="/images/tyler.png" alt="logo" className="logo-img" />
      <div className="request-panel">
        {renderContent()}
        {renderSubmitContent()}
      </div>
    </div>
  );

  function getFormattedCounty (countyData) {
    return _.compact(_.map(countyData, (datum) => {
      if(!_.isEmpty(datum['county'])){
        return datum;
      }
    }));
  }
}

export default RequestUsers;
