import { VIEW_MODE } from 'modules/visualization/constants';
import PropTypes from 'prop-types';

// TODO:
export const shapeProps = PropTypes.shape({});
export const dateTypeProps = PropTypes.instanceOf(Date);
export const stringOrObjectProps = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object
]);
export const stringOrNumberProps = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number
]);
const templateEntryPropTypes = PropTypes.shape({});

export const commonFiltersPropTypes = PropTypes.shape({});
export const userPropTypes = PropTypes.shape({});
export const templateEntriesPropTypes = PropTypes.arrayOf(templateEntryPropTypes);
// Object Props
export const viewEntryPropTypes = PropTypes.object;
export const currentDrilldownGroupByEntryPropTypes = PropTypes.object;
export const secondaryMetricEntryPropTypes = PropTypes.object;
export const groupByEntryPropTypes = PropTypes.object;
export const quickFiltersPropTypes = PropTypes.array;
export const permissionFiltersPropTypes = PropTypes.object;
export const drilldownPropTypes = PropTypes.object;

export const onClosePropTypes = PropTypes.func;

export const templateIdPropTypes = stringOrNumberProps;
export const viewModePropTypes = PropTypes.oneOf(_.values(VIEW_MODE));
export const dateRangePropTypes = PropTypes.exact({
  startDate: PropTypes.string,
  endDate: PropTypes.string,
});
export const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.string,
]);

export const datePropTypes = PropTypes.oneOfType([
  PropTypes.string,
  dateTypeProps
]);
