import React from 'react';
import PropTypes from 'prop-types';
import ColorWithCategorySelector from 'common/components/ColorWithCategorySelector/ColorWithCategorySelector';

const StackedBarRowSelector = (props) => {
  const { tableHeaders, tableData, groupByDimensionField, flyoutValueField } = props;

  const renderMatchedColumnContent = (dimensionKey, groupedData, index) => {
    const value = groupByDimensionField ?
      _.get(groupedData, [dimensionKey, 0, flyoutValueField], '') :
      _.get(groupedData, [index, flyoutValueField], '') ;

    return (<td key={`${dimensionKey}-${index}`}>{value}</td>)
  }

  const renderTableRow = (datum, category) => {
    const color = _.get(datum, [0, 'color']);
    const groupedData = groupByDimensionField ? _.groupBy(datum, groupByDimensionField) : datum;
    const matchedColumnContent = _.map(tableHeaders, ({ columnField }, index) => {
      return renderMatchedColumnContent(columnField, groupedData, index)
    });

    return (
      <tr key={category}>
        <td><ColorWithCategorySelector category={category} color={color} /></td>
        {matchedColumnContent}
      </tr>
    );
  }

  return _.map(tableData, renderTableRow);
}

StackedBarRowSelector.propTypes = {
  tableHeaders: PropTypes.array,
  tableData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  groupByDimensionField: PropTypes.string,
  flyoutValueField: PropTypes.string
}

export default StackedBarRowSelector;
