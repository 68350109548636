import React from 'react';
import PropTypes from 'prop-types';
import ColorBox from '../ColorBox';

const FlyoutPopupContent = ({ header, details }) => {
  const renderDetailRow = (detail, index) => (
    <tr key={index}>
      <td className="flyout-table-key"><strong>{detail.title}</strong></td>
      <td className="flyout-table-value d-flex gap-6">
        {detail.color && <ColorBox boxColor={detail.color} />}
        {detail.value}
      </td>
    </tr>
  );

  const renderDetails = () => (
    <table className="flyout-table">
      <tbody>
        {details.map(renderDetailRow)}
      </tbody>
    </table>
  );

  return (
    <div className='advance-flyout-popup'>
      <div className="flyout-popup-header"><strong>{header}</strong></div>
      <div className="flyout-popup-details">
        {renderDetails()}
      </div>
    </div>
  );
};

FlyoutPopupContent.propTypes = {
  header: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    color: PropTypes.string
  })).isRequired
};

export default FlyoutPopupContent;