import _ from 'lodash';
import { SINGLE_VALUE, OVERTIME_TIME_CHART_HEIGHT } from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import { updateCardLoadingStatusForImageGenerator } from 'helpers/visualizationHelper';

export const handleAfterPlot = (currentContainerRef, viewEntry, viewMode = "", cardImageId = "") => {
  handleLineChartAfterPlot(currentContainerRef, viewEntry, viewMode);
  updateLoadingStatusPlotlyChart(viewMode, cardImageId);
}

const handleLineChartAfterPlot = (currentContainerRef, viewEntry, viewMode = "") => {
  const precision = _.get(viewEntry, 'precision');
  const pluralSuffix  = _.get(viewEntry, 'plural_suffix', '');
  const singularSuffix  = _.get(viewEntry, 'singular_suffix', '');
  const yTicks = currentContainerRef.querySelectorAll('.yaxislayer-above .ytick');
  const xTicks = currentContainerRef.querySelectorAll('.g-xtitle .xtitle');

  if(_.size(xTicks) > 0 && viewMode != VIEW_MODE.SMALL) {
    xTicks[0].y.baseVal[0].value = OVERTIME_TIME_CHART_HEIGHT; 
  }

  _.each(yTicks, (ytick) => {
    const { textContent } = ytick;
    const tickVal = Number(textContent.match(/[\d.+]/g).join('')).toFixed(precision);
    if(+(tickVal) === SINGLE_VALUE) {
      const suffix = _.isEmpty(singularSuffix) ? pluralSuffix : singularSuffix;
      ytick.querySelector('text').textContent = textContent.replace(pluralSuffix, suffix);
    }
  })
}

const updateLoadingStatusPlotlyChart = (viewMode, cardImageId) => {
  if(viewMode == VIEW_MODE.SMALL){
    updateCardLoadingStatusForImageGenerator(cardImageId, false);
  }
} 