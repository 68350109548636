import $ from 'jquery';

export const getWidthAndHeightForFilterBadge = (filterRef, width) => {
  const filterBadgeElementWidth = width || filterRef.clientWidth || filterRef?.current?.clientWidth;
  const filterBadgeElementHeight = filterRef.clientHeight || filterRef?.current?.clientHeight;

  return { width: filterBadgeElementWidth, height: filterBadgeElementHeight};
}

export const isClickOutSideFilter = (event, filterRef) => {
  const isClickWithinDatePickerInput = $(event.target).closest('.MuiFormControl-root').length > 0;
  const isClickWithinCalendarDatePicker = $(event.target).closest('.MuiCalendarPicker-root').length > 0;

  return (
    filterRef &&
    !_.isUndefined(filterRef.contains) &&
    !filterRef.contains(event.target) &&
    !isClickWithinDatePickerInput &&
    !isClickWithinCalendarDatePicker
  );
}

export const isClickOutSideFilterPopup = (event, filterRef, classArray = []) => {
  let includeArray = classArray;
  includeArray.push('.MuiFormControl-root');
  includeArray.push('.MuiCalendarPicker-root');
  const isClickWithInClassNames = _.map(includeArray, (targetClass) => {
    return $(event.target).closest(targetClass).length > 0;
  });

  return (
    filterRef &&
    !filterRef.contains(event.target) &&
    !_.includes(isClickWithInClassNames, true)
  );
}

export const addFilterChipTitle = (filterChipRef, filterName, filterValueContent) => {
  const filterChip = filterChipRef.current;
  if (filterChip.offsetWidth < filterChip.scrollWidth) {
    filterChip.title = filterName + filterValueContent;
  } else {
    filterChip.title = '';
  }
}

export const applyFilters = () => {
  if(!_.isEmpty(document.getElementById("apply-filter-id"))){
    document.getElementById("apply-filter-id").click();
  }
}
