import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getPassConditionText, getFailConditionText } from 'common/config/customerConfiguration';

class TotalCountCards extends Component {
  render() {
    const {passCount, failCount} = this.props;
    return (
      <div className="row">
        <div className="col-md-12 col-lg-11 offset-lg-1">
          <div className="case-types-card">
              <div className="type-meet">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="count">{passCount}</div>
                  <i className="icons-check-circle" />
                </div>
                <p className="mb-0">{getPassConditionText()}</p>
              </div>

              <div className="type-miss">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="count">{failCount}</div>
                  <i className="icons-times-circle" />
                </div>
                <p className="mb-0">{getFailConditionText()}</p>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

TotalCountCards.propTypes = {
  passCount: PropTypes.string,
  failCount: PropTypes.string,
};

export default TotalCountCards;
