import _ from 'lodash';
import { getDelimiterString } from 'common/config/customerConfiguration';

const DEFAULT_PRECISION = 2;
// TODO repeated Code, Do a refactor
export const getAbbreviatedStringValue = (
  totalValue, precision, removeNegativeForZero, isConvertAbbreviated
) => {
  let formattedTotalValue;
  if(precision) {
    let value = Number(totalValue).toFixed(precision);
    if(removeNegativeForZero && value == 0) {
      // changing  -0.00 to 0.00
      value = Number('0').toFixed(precision);
    }
    // convert Abbreviated value => 1 million or above
    if((parseFloat(value) >= 1000000 || parseFloat(value) <= -1000000 ) && isConvertAbbreviated ){
      formattedTotalValue = convertAbbreviatedStringValue(value);
    }else {
      const wholeValue = Number(value).toFixed(precision).split('.')[0];
      const fraction = Number(value).toFixed(precision).split('.')[1];
      const thousandSeparatedValue = getThousandSeparatedValue(wholeValue);
      formattedTotalValue = (!_.isNaN(Number(fraction)) && !_.isUndefined(Number(fraction))) ?
        thousandSeparatedValue + '.' + fraction : thousandSeparatedValue ;
    }
  } else {
    const wholeValue = Number(totalValue).toFixed(DEFAULT_PRECISION).split('.')[0];
    const fraction = Number(totalValue).toFixed(DEFAULT_PRECISION).split('.')[1];
    // convert Abbreviated value => 1 million or above
    if((parseFloat(totalValue) >= 1000000 || parseFloat(totalValue) <= -1000000) && isConvertAbbreviated){
      formattedTotalValue = convertAbbreviatedStringValue(totalValue);
    } else {
      const thousandSeparatedValue = getThousandSeparatedValue(wholeValue);
      formattedTotalValue = Number(fraction) ?
        thousandSeparatedValue + '.' + fraction : thousandSeparatedValue ;
    }
  }

  return formattedTotalValue;
}

export const convertAbbreviatedStringValue = (value) => {
  if(value == 'N/A'){
    return value;
  }
  const abbreviatedLabels = [ '', 'K', 'M', 'B', 'T'];
  const numericValue = Math.abs(parseFloat(value));
  let fixedPrecision = DEFAULT_PRECISION; // by default precision 2 for abbreviated values.

  if (numericValue === 0) { return '0'; } // terminate early

  let convertedLength = Math.floor(Math.log(numericValue) / Math.log(1000));
  convertedLength = convertedLength > 0 ? convertedLength : 0;
  let result  = (value < 0 ? '-':'')+(parseFloat(
    (numericValue / Math.pow(1000, convertedLength)))).toFixed(fixedPrecision);
  return result += `${abbreviatedLabels[convertedLength]}`;
};

const getThousandSeparatedValue = (value) => {
  const replaceString = '$1' + getDelimiterString();
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, replaceString);
}

export const formatValueToCurrency = (value, isCurrencyType = false) => {
  if (isCurrencyType && !_.isNaN(Number(value))) {
    return '$' + parseFloat(value).toFixed(2);
  }

  return value;
}

export const isEmptyValue = (value) =>  _.isEmpty(_.toString(value));

export const getThousandSeparatedValueWithFraction = (value) => {
  const splittedValue = (value || '0').toString().split('.');
  const wholeValue = _.get(splittedValue, '0');
  const fraction = _.get(splittedValue, '1');
  const thousandSeparatedValue = getThousandSeparatedValue(wholeValue);

  return (!_.isNaN(Number(fraction)) && !_.isUndefined(Number(fraction))) ?
    thousandSeparatedValue + '.' + fraction : thousandSeparatedValue;
}
