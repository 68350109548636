import React, { Component } from 'react';
import { quickFiltersWithoutTableSearchEntry } from '../../pages/drilldown/components/QuickFilterBar/helper';
import { activeTableSearchEntry } from './TableHelper';
import { NUMBER_TYPES_FIELD } from 'appConstants';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import BadgeItems from '../../common/components/Badges/BadgeItems';

class TableFilterBadge extends Component {

  handleRemoveSearchItem = (removedSearchEntry) => {
    const { column, value } = removedSearchEntry;
    const { quickFilters, tableSearchEntries } = this.props;

    const activeTableSearchEntries = activeTableSearchEntry(
      _.cloneDeep(tableSearchEntries), { column, value });
    const isNumericEntry = (_.chain(activeTableSearchEntries).
      find({ column }).
      get('renderType').
      value() === NUMBER_TYPES_FIELD);

    const newQuickFilters = quickFiltersWithoutTableSearchEntry(
      { column, values: [value] }, quickFilters, isNumericEntry);
    this.updateSearchObjectAndQuickFilters(activeTableSearchEntries, newQuickFilters);
  }

  updateSearchObjectAndQuickFilters = (searchEntries, newQuickFilters) => {
    const { onUpdateSearchObjectAndQuickFilters } = this.props;
    onUpdateSearchObjectAndQuickFilters(searchEntries, newQuickFilters);
  }

  renderFilterRowBadge(columnObject, columnIndex) {
    const { tableSearchEntries } = this.props;

    const columnFilterValues = _.filter(tableSearchEntries, (searchEntry) => {
      return searchEntry.column == columnObject.column
    });

    if (_.isEmpty(columnFilterValues)) {
      return null;
    }

    return (
      <div className="d-flex align-items-center flex-wrap result-filter">
        {_.map(columnFilterValues, (entry, index) => {
          const { column, values, renderType } = entry;
          const newValues = (renderType === NUMBER_TYPES_FIELD) ? [values] : values;
          return this.renderBadgeContent({ name: column, values: newValues, columnIndex, index });
        })
        }
      </div>
    )
  }

  renderBadgeContent(badgeObject) {
    const uniqueBadgeId = `${badgeObject.columnIndex}-${badgeObject.index}-${badgeObject.column}`;
    return (
      <BadgeItems
        key={uniqueBadgeId}
        badgeObject={badgeObject}
        onRemoveBadgeItem={this.handleRemoveSearchItem} />
    );
  }

  render() {
    const { headerColumns } = this.props;

    return _.map(headerColumns, (columnName, index) => {
      return <th> {this.renderFilterRowBadge(columnName, index)} </th>
    })
  }

}

TableFilterBadge.propTypes = {
  headerColumns: PropTypes.array,
  tableSearchEntries: PropTypes.array,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  onUpdateSearchObjectAndQuickFilters: PropTypes.func
};

export default TableFilterBadge;
