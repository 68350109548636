import {
  isNewRadarCard,
  isPrivate,
  showRadar,
  getPermissionRadarMetricEntries,
  useAppLevelShapeConfigs
} from "common/config/customerConfiguration";

const RADAR_TEMPLATE_ID = 'radar';
const RADAR_VIEW_ID = 'view_radar';

export const getCardEntriesWithRadarCard = (cardEntries, isPrivateApp = isPrivate()) => {
  const radarMetricEntries = getPermissionRadarMetricEntries();
  if(showRadar() && isPrivateApp && !_.isEmpty(radarMetricEntries) && useAppLevelShapeConfigs) {
    cardEntries = [getDefaultRadarEntry()].concat(cardEntries);
  }
  return cardEntries;
}

export const getDefaultRadarEntry = () => {
  const templateEntry = {template_id: RADAR_TEMPLATE_ID, isRadar: true};
  const viewEntry = { view_id: RADAR_VIEW_ID, isRadar: true, is_new_card: isNewRadarCard() };
  const userCardEntry = {id: 'user_id'};
  return { templateEntry, viewEntry, userCardEntry };
}

export const getRadarCardId = () => `${RADAR_TEMPLATE_ID}${RADAR_VIEW_ID}`

export const showRadarCardNewLabel = (newLabelCards) => {
  const cardId = getRadarCardId();
  const matchedCard = _.find(newLabelCards, { template_card_id: cardId });
  return isNewRadarCard() && !_.isEmpty(matchedCard);
}
