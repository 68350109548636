import React from 'react';
import PropTypes from 'prop-types';
import { ForgeAppBarProfileButton } from '@tylertech/forge-react';
import { securityUsers } from 'common/config/customerConfiguration';
import { USER_PROFILE_PAGE } from 'appConstants';

const ForgeProfileButton = (props) => {
  const { currentUser, onSignOutBtnClick, onProfileBtnClick } = props;
  const email = _.get(currentUser, 'user.email', '');
  const nickName = _.get(currentUser, 'user.nickname', '');
  const name = _.get(currentUser, 'user.displayName', nickName);
  const showProfileButton = !_.isEmpty(_.find(securityUsers(), { 'email': email }));

  return (
    <ForgeAppBarProfileButton
      slot="end"
      avatarLetterCount={2}
      avatarText={name}
      fullName={name}
      email={email}
      signOutButton={true}
      profileButton={showProfileButton}
      on-forge-profile-card-sign-out={onSignOutBtnClick}
      on-forge-profile-card-profile={() => onProfileBtnClick(USER_PROFILE_PAGE, '/admin', true)}
    />
  );
}

ForgeProfileButton.propTypes = {
  currentUser: PropTypes.object,
  onSignOutBtnClick: PropTypes.func,
  onProfileBtnClick: PropTypes.func,
}

export default ForgeProfileButton;
