import React from 'react';
import PropTypes from 'prop-types';

import { COMPARISON_PERIOD_TYPES, COMPARISON_PERIOD } from 'appConstants';
import { ForgeRadio } from '@tylertech/forge-react';
import ForgeDropdowns from '../ForgeDropdowns/ForgeDropdowns';

const ComparisonPeriodSelector = (props) => {
  const {
    defaultDateType, onInputChange, onSelect, comparisonPeriod, comparisonLabel
  } = props;

  return (
    <div className="d-flex">
      <div className="date-range-forge-radio">
          <ForgeRadio dense={true}>
            <input
              key='comparisonPeriod'
              type="radio"
              tabIndex={0}
              id={COMPARISON_PERIOD}
              name="radios"
              value={defaultDateType}
              aria-label={COMPARISON_PERIOD}
              checked={defaultDateType == COMPARISON_PERIOD}
              onChange={() => onInputChange(COMPARISON_PERIOD)}
            />
            <label className="" htmlFor={COMPARISON_PERIOD}></label>
          </ForgeRadio>
        </div>
      {/* <div className="custom-control custom-radio align-self-center pt-3">
        <input
          key='comparisonPeriod'
          type="radio"
          tabIndex={0}
          className="custom-control-input"
          id={COMPARISON_PERIOD}
          checked={defaultDateType == COMPARISON_PERIOD}
          onChange={() => onInputChange(COMPARISON_PERIOD)} />
        <label className="custom-control-label" htmlFor={COMPARISON_PERIOD}></label>
      </div> */}
      {/* <DropdownGroup
        disabled={defaultDateType != COMPARISON_PERIOD}
        key="comparisonPeriod-dropdown"
        value={comparisonPeriod}
        label={comparisonLabel}
        toggleButtonName={comparisonName}
        options={COMPARISON_PERIOD_TYPES}
        optionDisplayField="name"
        onChange={onSelect}
      /> */}
      <div className='filter-items'>
        <ForgeDropdowns
          isDisabled={defaultDateType != COMPARISON_PERIOD}
          key="comparisonPeriod-dropdown"
          value={comparisonPeriod}
          label={comparisonLabel}
          options={COMPARISON_PERIOD_TYPES}
          handleOnSelect={onSelect} />
      </div>
    </div>
  );
}

ComparisonPeriodSelector.defaultProps = {
  comparisonLabel: "Comparison Period"
}

ComparisonPeriodSelector.propTypes = {
  defaultDateType: PropTypes.string,
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  comparisonPeriod: PropTypes.string,
  comparisonLabel: PropTypes.string
}

export default ComparisonPeriodSelector;
