import _ from 'lodash';

export const numberWithCommaSeparate = (value) => {
  const numberFormatter = Intl.NumberFormat('en-US');
  const formattedValue = numberFormatter.format(value);
  if (!_.isNaN(formattedValue)) {
    return formattedValue;
  } else {
    return value;
  }
}