import React from 'react';
import moment from 'moment';
import { ForgeIcon, ForgeInlineMessage } from '@tylertech/forge-react';
import PropTypes from 'prop-types';
import {
  getValidFormattedData, getForecastFormattedData
} from '../ForecastHelper';
import { getValidChartValue } from 'modules/visualization/LineChart/Helpers/overtimeHelper';

const WarningInfo = (props) => {
  const { forecastAttributeOptions, defaultGranularityFormattedData } = props;
  const { currentForecastDateRange } = forecastAttributeOptions;
  const forecastFormattedData = getForecastFormattedData(
    defaultGranularityFormattedData, forecastAttributeOptions
  );
  const validDataOptions = {
    formattedData: forecastFormattedData,
    ...forecastAttributeOptions
  }

  const formattedData = getValidFormattedData(validDataOptions);

  const renderWarningInstruction = () => {
    const isGapValueFound = _.some(formattedData, (datum) => {
      const value = getValidChartValue(datum);
      const adjustValue = Number(_.get(datum, 'adjustValue', 0));

      return (value == 0 && adjustValue == 0 )
    });
    const { dateRange } = currentForecastDateRange;
    const startDate = moment(dateRange['startDate']);
    const endDate = moment(dateRange['endDate']);
    const diffDays = endDate.diff(startDate,'days');
    const isLessThanOneYear = diffDays < 365;


    if (isLessThanOneYear && isGapValueFound) {
      return (
        <div>
          Your dataset has gaps and a historical date range is less than a year.
          If you’d like to use this model, please take the following actions:
          <ul>
            <li>
              Add adjusted values to any missing data in the Prepare Data section
            </li>
            <li>
              Select a date range of at least one year
            </li>
          </ul>
        </div>
      )
    } else if (isLessThanOneYear) {
      return (
        <div>
          Your historical date range is less than a year.
          If you’d like to use this model, please select a date range of at least one year.
        </div>
      )
    } else if (isGapValueFound) {
      return (
        <div>
          Your dataset has gaps. If you’d like to use this model,
          please add adjusted values to any missing data in the Prepare Data section
        </div>
      )
    } else {
      return null;
    }

  };


  const renderAlertIcon = () => {
    const styleInfo = {
      color: "#F6BE00"
    }

    return (
      <ForgeIcon slot="icon" className="tx-25" key={'issue-data'}
        style={styleInfo} name={'alert_outline'} />
    )
  }

  const renderWarningInfo = () => {
    const warningInfo = renderWarningInstruction();
    if(_.isEmpty(warningInfo)){
      return null
    }

    return (
      <div className='alert-error-inline-message'>
        <ForgeInlineMessage>
          <div className="d-flex align-items-center gap-15">
            {renderAlertIcon()}
            {warningInfo}
          </div>
        </ForgeInlineMessage>
      </div>
    )
  }


  return (
    <>
      {renderWarningInfo()}
    </>
  );
}

WarningInfo.propTypes = {
  forecastAttributeOptions: PropTypes.object,
  defaultGranularityFormattedData: PropTypes.object
}

export default WarningInfo;
