import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal} from 'react-bootstrap';

import LoadingSpinner from 'common/components/LoadingSpinner';
import VarianceResult from './Result';
import VarianceFilters from './VarianceFilters';
import { getAnovaData, getVarianceData, getVarianceOutliersData } from 'common/api/drilldown';
import { fetchApiData } from 'helpers/apiResponseHelper';

import { dimensionFieldsExceptDrilledDown } from 'common/config/templateConfiguration';
import { getSecondaryMetricEntries } from 'helpers/visualizationHelper';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import {
  SNAPSHOT_VISUALIZATION_TYPES,
  VARIANCE_COMPARISON_OPTIONS,
  COMPARISON_MODE_OPTIONS
} from 'appConstants';
import { getComparisonPeriodDateRanges } from 'helpers/dateHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

class VarianceModal extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      currentDrilldownViewEntry,
      commonFilters,
      currentDrilldownTemplateId,
      drilledDownDimensions
    } = props;
    const { dateRange } = commonFilters;
    const secondaryMetrics = getSecondaryMetricEntries(
      currentDrilldownViewEntry,
      'snapshot',
      SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type
    );
    let secondaryMetricField = _.get(props, 'currentSecondaryMetricField');
    if(_.isEmpty(secondaryMetricField)) {
      secondaryMetricField = _.get(secondaryMetrics, '[0].field');
    }
    const priorPeriodDateRange = getComparisonPeriodDateRanges({
      dateRange,
      comparisonType: COMPARISON_MODE_OPTIONS[1].type
    })[0];
    const availableDrilldownFields = dimensionFieldsExceptDrilledDown(
      currentDrilldownTemplateId,
      drilledDownDimensions
    );

    this.state = {
      isLoading: false,
      varianceData: {},
      secondaryMetricField: secondaryMetricField,
      sampleSize: 0,
      mathValueType: 'variance_diff',
      mathValueThreshold: 10,
      sortOrder: 'desc',
      // comparatorType: 'primary',
      // restrictToRoot: false,
      resultLimit: 10,
      primaryDataRange: dateRange,
      secondaryDateRange: priorPeriodDateRange,
      selectedDimensions: availableDrilldownFields,
      comparisonOption: _.get(VARIANCE_COMPARISON_OPTIONS, [0, 'type']),
      selectedDimensionEntity: null,
      validationErrors: {}
    };

    this.abortFetchController = new AbortController();
  }

  getApiParams = (extensions = {}) => {
    const {
      commonFilters,
      currentDrilldownTemplateId,
      currentDrilldownViewEntry,
      quickFilters
    } = this.props;
    const { primaryDataRange } = this.state;
    const { baseQuickFilters = [] } = extensions;

    return ({
      drilldownEntry: JSON.stringify({
        currentDrilldownTemplateId,
        currentViewEntryField: _.get(currentDrilldownViewEntry, 'field', ''),
        quickFilters: baseQuickFilters.concat(quickFilters)
      }),
      commonFilters: JSON.stringify({
        dateRange: primaryDataRange,
        globalFilters: _.get(commonFilters, 'globalFilters', [])
      })
    })
  }

  fetchVarianceData = async() => {
    const { comparisonOption } = this.state;
    this.setState({ isLoading: true, varianceData: {} });
    this.abortFetchController.abort()
    this.abortFetchController = new AbortController()

    const varianceData = await this.doFetchVarianceData();
    this.setState({
      isLoading: false,
      varianceData,
      varianceDataComparisonOption: comparisonOption
    });
  }

  doFetchAnovaData = async(apiParamsExtensions = {}) => {
    const { currentDrilldownTemplateId } = this.props;
    const { selectedDimensions } = this.state;
    const { discardDimensions } = apiParamsExtensions;
    const availableDimensions = _.without(selectedDimensions, ...discardDimensions);

    const anovaPromises = _.map(availableDimensions, (dimension) => {
      const apiParams = {
        ...this.getApiParams(apiParamsExtensions),
        selectedDimensions: JSON.stringify([dimension]),
      };
      const apiUrl = getAnovaData(apiParams);

      return fetchApiData(apiUrl).then((result) => ({
        dimensionEntry: getSelectedDimensionEntry(currentDrilldownTemplateId, dimension),
        result
      }));
    });

    return await Promise.all(anovaPromises)
  }

  doFetchVarianceData = async(apiParamsExtensions = {}) => {
    const { comparisonOption } = this.state;
    const apiParams = this.getCompareOptionBasedParams(apiParamsExtensions);

    if (apiParamsExtensions) {
      // TODO: overrider the apiparams
    }

    let apiUrl = '';
    if(comparisonOption === _.get(VARIANCE_COMPARISON_OPTIONS, [2, 'type'])) {
      apiUrl = getVarianceOutliersData(apiParams);
    } else {
      apiUrl = getVarianceData(apiParams);
    }
    return await fetchApiData(apiUrl, this.abortFetchController);
  }

  getCompareOptionBasedParams = (extensions = {}) => {
    const {
      secondaryMetricField,
      sampleSize,
      mathValueType,
      mathValueThreshold,
      sortOrder,
      resultLimit,
      secondaryDateRange,
      selectedDimensions,
      comparisonOption,
      selectedDimensionEntity
    } = this.state;
    const { discardDimensions = [] } = extensions;

    const commonApiParams = {
      ...this.getApiParams(extensions),
      secondaryMetricField: secondaryMetricField,
      sample_size: sampleSize,
      math_value_type: mathValueType,
      math_value_threshold: mathValueThreshold,
      sort_order: sortOrder,
      limit: resultLimit,
      secondaryDateRange:  JSON.stringify(secondaryDateRange),
      selectedDimensions: JSON.stringify(_.without(selectedDimensions, ...discardDimensions)),
      dimentionEntityField: _.get(selectedDimensionEntity, 'field', ''),
      comparisonOption
    };

    if(comparisonOption === _.get(VARIANCE_COMPARISON_OPTIONS, [0, 'type'])) {
      return _.omit(commonApiParams, ['secondaryMetricField', 'dimentionEntityField']);
    } else if (comparisonOption === _.get(VARIANCE_COMPARISON_OPTIONS, [1, 'type'])) {
      return _.omit(commonApiParams, ['secondaryDateRange', 'dimentionEntityField']);
    } else {
      return _.omit(commonApiParams, [
        "secondaryMetricField",
        "secondaryDateRange",
        "math_value_type",
        "math_value_threshold"
      ]);
    }
  }

  handleChangeCompareOption = (comparisonOption) => {
    this.setState({ comparisonOption });
  }

  handleSelectEntity = (selectedDimensionEntity) => {
    this.setState({ selectedDimensionEntity, validationErrors: {}});
  }

  handleFiltersChange = (filters) => {
    const {
      secondaryMetricField,
      sampleSize,
      mathValueType,
      mathValueThreshold,
      sortOrder,
      // comparatorType,
      // restrictToRoot,
      resultLimit,
      primaryDataRange,
      secondaryDateRange,
      selectedDimensions
    } = filters;

    this.setState({
      secondaryMetricField,
      sampleSize,
      mathValueType,
      mathValueThreshold,
      sortOrder,
      // comparatorType,
      // restrictToRoot,
      resultLimit,
      primaryDataRange,
      secondaryDateRange,
      selectedDimensions
    }, () => {

      if(this.state.comparisonOption === _.get(VARIANCE_COMPARISON_OPTIONS, [2, 'type'])) {
        this.validateInputs()
      } else {
        this.fetchVarianceData();
      }
    });
  }

  validateInputs = () => {
    const { selectedDimensionEntity, validationErrors } = this.state;
    if(_.isNull(selectedDimensionEntity)) {
      validationErrors['dimensionEntity'] = `Entity is not selected.`
    }

    this.setState({ validationErrors }, () => {
      if(_.isEmpty(this.state.validationErrors)) {
        this.fetchVarianceData();
      }
    });
  }

  renderSpinner() {
    const { isLoading } = this.state;

    return (
      <LoadingSpinner isLoading={ isLoading } />
    );
  }

  renderVarianceFilters() {
    const {
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      drilledDownDimensions
    } = this.props;
    const {
      secondaryMetricField,
      sampleSize,
      mathValueType,
      mathValueThreshold,
      sortOrder,
      validationErrors,
      // comparatorType,
      resultLimit,
      // restrictToRoot,
      primaryDataRange,
      secondaryDateRange,
      selectedDimensions,
      comparisonOption,
      selectedDimensionEntity
    } = this.state;

    return(
      <VarianceFilters
        {...{
          secondaryMetricField,
          sampleSize,
          mathValueType,
          mathValueThreshold,
          sortOrder,
          // comparatorType,
          resultLimit,
          // restrictToRoot,
          primaryDataRange,
          secondaryDateRange,
          selectedDimensions
        }}
        validationErrors={validationErrors}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        apiParams={this.getApiParams}
        onApply={this.handleFiltersChange}
        comparisonOption={comparisonOption}
        selectedDimensionEntity={selectedDimensionEntity}
        onSelectEntity={this.handleSelectEntity}
        onChangeCompareOption={this.handleChangeCompareOption}
        drilledDownDimensions={drilledDownDimensions}
        viewEntry={currentDrilldownViewEntry} />
    );
  }

  renderVariance() {
    const {
      varianceData,
      selectedDimensionEntity,
      secondaryMetricField,
      varianceDataComparisonOption
    } = this.state;
    const {
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    } = this.props;
    return (
      <VarianceResult
        comparisonOption={varianceDataComparisonOption}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        dispatchVariancePathLinkActions={this.dispatchVariancePathLinkActions}
        selectedDimensionEntity={selectedDimensionEntity}
        primaryMetricField={_.get(currentDrilldownViewEntry, 'field', '')}
        secondaryMetricField={secondaryMetricField}
        varianceData={varianceData}
        fetchVarianceData={this.doFetchVarianceData}
        fetchAnovaData={this.doFetchAnovaData} />
    );
  }

  render() {
    const { onClose } = this.props;
    const { isLoading } = this.state;
    return (
      <Modal size="xl" show={true} onHide={onClose} className="variance-modal">
        <div className="modal-wrapper border-0">
          <Modal.Header>
            <Modal.Title> Variance </Modal.Title>
            <button className='close' onClick={onClose}><i className="icons-close"></i></button>
          </Modal.Header>
          <Modal.Body>
            {this.renderSpinner()}
            {this.renderVarianceFilters()}
            {!isLoading && this.renderVariance()}
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

VarianceModal.propTypes = {
  currentDrilldownTemplateId: PropTypes.string,
  currentDrilldownViewEntry: PropTypes.object,
  onClose: commonPropTypes.onClosePropTypes,
  currentSecondaryMetricField: PropTypes.string,
  quickFilters: PropTypes.array,
  commonFilters: PropTypes.object,
  drilledDownDimensions: PropTypes.array
}

function mapDispatchToProps() {
  return {
  }
}

function mapStateToProps(state) {
  return {
    drilledDownDimensions: _.cloneDeep(_.get(state, 'drilldown.drilledDownDimensions', [])),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    commonFilters: _.get(state, 'commonFilters', {}),
    currentSecondaryMetricField: _.get(state, 'visualization.snapshot.currentSecondaryMetricField'),
    quickFilters: _.cloneDeep(_.get(state.drilldown, 'quickFilters', [])),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VarianceModal);
