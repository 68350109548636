import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { PopupAnimationType } from '@tylertech/forge';
import { ForgePopup } from '@tylertech/forge-react';
import CustomForgeList from './CustomForgeList';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const CustomDropdownList = (props) => {
  const { id, title } = props;
  const [showContent, setShowContent] = useState(false);
  const targetPopupRef = useRef();
  const placement = 'bottom-start', manageFocus = true, animationType = PopupAnimationType.Dropdown

  const onClickToggleButton = () => {
    setShowContent(!showContent);
  }

  const renderListOptions = () => {
    return (
      <CustomForgeList {...props} onClickToggleButton={onClickToggleButton} />
    );
  }

  const onKeydownDropdownList = (e) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      setShowContent(!showContent)
    }
  }

  return (
    <div className='forge-custom-dropdown' id={"dropdown-custom" + id}>
      <div ref={targetPopupRef}
        className="dropdown-container color-theme-primary cursor-pointer d-flex"
        onClick={() => setShowContent(!showContent)}
        onKeyDown={(e) => onKeydownDropdownList(e)}
        tabIndex={0} title={title}>
        <div className="dropdown-placeholder forge-typography--body1">{title}</div>
        <div className="dropdown-caret icons-chevron-down"></div>
      </div>
      <ForgePopup
        open={showContent}
        targetElementRef={targetPopupRef}
        options={{ placement, manageFocus, animationType }}>
        <div style={{ minWidth: '256px'}}>
          {renderListOptions()}
        </div>
      </ForgePopup>
    </div>
  );
}

CustomDropdownList.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array,
  title: PropTypes.node,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  showCount: PropTypes.bool,
  optionDisplayField: PropTypes.string,
  showDescription: PropTypes.bool,
  refTargetElement: PropTypes.any
};

CustomDropdownList.defaultProps = {
  id: '1',
  showDescription: false
};

export default CustomDropdownList;
