import { buildQueryString } from 'helpers/HttpHelper';
import { fetchApiDataWithStatus } from 'helpers/apiResponseHelper';
import { SNAPSHOT_VISUALIZATION_TYPES } from 'appConstants';
// const getCompareRangeParams = (vizOptions) => {
//   const { apiParams } = vizOptions;
//   const { commonFilters } = apiParams;
//   let newCommonFilters = JSON.parse(commonFilters);
//   newCommonFilters['dateRange'] = newCommonFilters['comparisonDateRanges'][0];
//   return{
//     ...apiParams,
//     commonFilters: JSON.stringify(newCommonFilters),
//   }
// }

const getParamsWithCompareRange = (vizOptions) => {
  const { apiParams, currentSnapshotView } = vizOptions;
  const { commonFilters } = apiParams;
  let newCommonFilters = JSON.parse(commonFilters);
  const isBarChartView = _.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
  if(isCompareDateRangeEnabled(vizOptions) && isBarChartView){
    return{
      ...apiParams,
      compareYearRanges: JSON.stringify(newCommonFilters['comparisonDateRanges']),
    }
  }else{
    return apiParams;
  }
}

const isCompareDateRangeEnabled = (vizOptions) => {
  const { apiParams, ignoreCompareDateRange } = vizOptions;
  if(ignoreCompareDateRange){
    return false;
  }
  const { commonFilters } = apiParams;
  let newCommonFilters = JSON.parse(commonFilters);
  return _.get(newCommonFilters, 'comparisonModeOn') || false;
}
export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    return (
      !_.isEqual(_.get(vizOptions,'apiParams'), _.get(previousVizOptions, 'apiParams')) ||
      !_.isEqual(_.get(vizOptions,'groupType'), _.get(previousVizOptions, 'groupType'))
    );
  },
  getData: (vizOptions, abortableFetchController) => {
    // const { currentSnapshotView } = vizOptions;
    const newParams = getParamsWithCompareRange(vizOptions)
    // const isBarChartView = _.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
    const apiUrl = `/api/visualization/bar_chart.json?${buildQueryString(newParams)}`;
    // let compareRangePromise = Promise.resolve({});
    // if(isCompareDateRangeEnabled(vizOptions) && isBarChartView){
    //   const params = getCompareRangeParams(vizOptions);
    //   const url = `/api/visualization/bar_chart.json?${buildQueryString(params)}`;
    //   compareRangePromise = fetchApiDataWithStatus(url, abortableFetchController)
    // }

    return fetchApiDataWithStatus(apiUrl, abortableFetchController)
  }
};
