export const updateSubscriptionParamsforApi = (collectionId, subscriptionSettings) => {
  const apiParams = {
    collection_id: collectionId,
    name: _.get(subscriptionSettings, 'name'),
    send_email: _.get(subscriptionSettings, 'sendEmail'),
    frequency: _.get(subscriptionSettings, 'frequency'),
    frequency_interval: _.get(subscriptionSettings, 'frequencyInterval'),
    week_number_for_month: _.get(subscriptionSettings, 'monthlyWeekNumber'),
    email_sending_time: _.get(subscriptionSettings, 'emailSendingTime'),
    time_zone: _.get(subscriptionSettings, 'timeZone'),
    email_sending_days: _.get(subscriptionSettings, 'emailSendingDays'),
    start_date: _.get(subscriptionSettings, 'startDate'),
    regularity_type: _.get(subscriptionSettings, 'regularityType'),
    time_period_day: _.get(subscriptionSettings, 'timePeriodDay'),
    include_image: _.get(subscriptionSettings, 'includeImage'),
    is_collaborate_subscription: _.get(subscriptionSettings, 'isCollaborateSubscription'),
    recipients: _.get(subscriptionSettings, 'recipients'),
    bellerophon_tag_id: _.get(subscriptionSettings, 'bellerophonTagId')
  }

  return apiParams;
}
