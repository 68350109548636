import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { configuredDefaultDateType } from 'common/config/customerConfiguration';

import DateRangeFilterForCollection from 'common/components/SecondaryHeader/DateRangeFilterForCollection';
import { COMPARISON_MODE_OPTIONS, DATE_TIME_FORMAT, NONE_DATE_RANGE } from 'appConstants';
import RadioButton from 'common/components/RadioButton';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { getMaxDate, getMinDate } from 'helpers/dateUtils';
import { ForgeRadio } from '@tylertech/forge-react';
import ForgeDateInputPicker from 'common/components/ForgeDatePicker/ForgeDateInputPicker';

const DATE_FORMAT = "YYYY-MM-DD";

class DateFilters extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  handleDateChange = (dateType, dateRange, relativeDateFilterEntry) => {
    const { onCommonFiltersChange, commonFilters, onRelativeDateTypeChange } = this.props;
    const oldDateType = _.get(commonFilters, 'dateType');
    const newDateRange = {
      startDate: moment(dateRange.startDate).format(DATE_FORMAT),
      endDate: moment(dateRange.endDate).format(DATE_FORMAT)
    };
    onCommonFiltersChange({
      ...commonFilters,
      dateRange: newDateRange,
      dateType,
      relativeDateFilterEntry
    });
    if (!_.isEqual(oldDateType, dateType) && !_.isEqual(dateType, ' ')) {
      onRelativeDateTypeChange(false, false);
    }
  }

  handleRelativeStartDateChange = (date) => {
    const { commonFilters, onCommonFiltersChange } = this.props;
    const newDateRange = {
      startDate: moment(date).format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT)
    };
    onCommonFiltersChange({
      ...commonFilters,
      dateRange: newDateRange
    });
  }

  renderNoneDateRange() {
    const {
      onRelativeDateTypeChange, isNoneDateSelected, hideNoneDateOption, index, isDisabled
    } = this.props;
    if (hideNoneDateOption) {
      return null;
    }
    const radioButtonAttributes = {
      disabled: isDisabled,
      id: `${index}-${NONE_DATE_RANGE}`,
      isChecked: isNoneDateSelected,
      label: 'None (respond to dashboard date filter)',
      value: NONE_DATE_RANGE,
      onChange: () => onRelativeDateTypeChange(false, !isNoneDateSelected),
      name: 'radios'
    };

    return (<RadioButton {...radioButtonAttributes} />);
  }

  renderRelativeDateOption() {
    const {
      commonFilters,
      templateId,
      onRelativeDateTypeChange,
      isRelativeDateSelected,
      hideRelativeDateOption,
      isDisabled
    } = this.props;

    if (hideRelativeDateOption) {
      return null;
    }

    const dateRange = _.get(commonFilters, 'dateRange', {});
    const { startDate } = dateRange;
    const startDateObj = new Date(moment(startDate).format(DATE_TIME_FORMAT));
    const tooltip = (
      <Tooltip id="saveAndShare">
        Monitor changes based on a starting date through<br />
        the current date. The start date remains constant,<br />
        but the information expands in scope as time progresses.
      </Tooltip>
    );
    return (
      <div className="d-flex gap-5 mb-4">
        <div className="date-range-forge-radio">
        <ForgeRadio dense="true">
          <input
            disabled={isDisabled}
            key='1'
            type="radio"
            tabIndex={isRelativeDateSelected ? 0 : -1}
            id="relativeStart"
            name="radios"
            aria-label="RelativeDateSelected"
            checked={isRelativeDateSelected}
            onChange={() => onRelativeDateTypeChange(!isRelativeDateSelected, false)}
          />
          <label htmlFor="relativeStart"></label>
        </ForgeRadio>

          {/* <input
            key='1'
            type="radio"
            tabIndex={isRelativeDateSelected ? 0 : -1}
            className="custom-control-input"
            id="relativeStart"
            aria-label="RelativeDateSelected"
            checked={isRelativeDateSelected}
            onChange={() => onRelativeDateTypeChange(!isRelativeDateSelected, false)} />
          <label className="custom-control-label" htmlFor="relativeStart"></label> */}
        </div>
        {/* <div className="date-range-forge-radio">
          <ForgeRadio dense={true}>
            <input
              key='1'
              tabIndex={isRelativeDateSelected ? 0 : -1}
              type="radio"
              aria-label="RelativeDateSelected"
              id="relativeStart"
              name="radios"
              value="relativeStart"
              checked={isRelativeDateSelected}
              onChange={() => onRelativeDateTypeChange(!isRelativeDateSelected, false)}
            />
            <label htmlFor="relativeStart"></label>
          </ForgeRadio>
        </div> */}

        <div className="flex-grow-1">
          <div className="d-flex filter-items align-items-center gap-5 mb-1">
            <label className="mb-0">Start Date</label>
            <OverlayTrigger key="saveAndShare" placement="top" overlay={tooltip}>
              <i className="icons-info-circle-icon" />
            </OverlayTrigger>
          </div>
          <ForgeDateInputPicker
            minDate={getMinDate(templateId)}
            maxDate={getMaxDate()}
            isDisabled={!isRelativeDateSelected || isDisabled}
            value={startDateObj}
            label=""
            onChange={this.handleRelativeStartDateChange} />
        </div>
      </div>
    )
  }

  render() {
    const {
      commonFilters,
      templateId,
      isRelativeDateSelected,
      isNoneDateSelected,
      labelText,
      index,
      isDisabled
    } = this.props;
    let dateType = _.get(commonFilters, 'dateType');

    if (_.isEmpty(dateType)) {
      const isRelativeEntrySelected = _.get(
        commonFilters, 'relativeDateFilterEntry.isRelativeFilter', "false") + "";
      if (isRelativeEntrySelected == 'true') {
        dateType = 'relative';
      } else {
        dateType = configuredDefaultDateType;
      }
    }

    if (isRelativeDateSelected || isNoneDateSelected) {
      dateType = ' ';
    }
    const dateProps = {
      dateRange: _.get(commonFilters, 'dateRange', {}),
      dateType: dateType,
      comparisonModeOn: _.get(commonFilters, 'comparisonModeOn', false),
      comparisonType: _.get(commonFilters, 'comparisonType', COMPARISON_MODE_OPTIONS[0].name),
      relativeDateFilterEntry: _.get(commonFilters, 'relativeDateFilterEntry', {})
    };

    return (
      <div className="relative-date-filter align-self-center bookmark-daterange mb-2" role="radiogroup">
        <label className="custom-label">{labelText}</label>
        <DateRangeFilterForCollection
          {...dateProps}
          isDisabled={isDisabled}
          index={index}
          currentDrilldownTemplateId={templateId}
          isDashboardView={false}
          minDate={getMinDate()}
          maxDate={getMaxDate()}
          disableComparison={true}
          hideFooter={true}
          isDropDownView={false}
          onDateFilterChange={this.handleDateChange} />
        {this.renderRelativeDateOption()}
        {this.renderNoneDateRange()}
      </div>
    );
  }
}

DateFilters.propTypes = {
  onCommonFiltersChange: PropTypes.func,
  onRelativeDateTypeChange: PropTypes.func,
  hideRelativeDateOption: PropTypes.bool,
  labelText: PropTypes.string,
  templateId: commonPropTypes.templateIdPropTypes,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  isNoneDateSelected: PropTypes.bool,
  hideNoneDateOption: PropTypes.bool,
  isRelativeDateSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  index: PropTypes.number
}

export default (DateFilters);
