import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeButton } from '@tylertech/forge-react';

class ConfirmationDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:true
    };
  }

  componentDidMount(){
    if(this.confirmInput){
      setTimeout(() => {
        this.confirmInput.focus();
      }, 100);
    }
  }

  handleClickConfirm = (event) => {
    const { onConfirmationCallBack } = this.props;
    this.setState({showModal:false});
    event.preventDefault();
    event.stopPropagation();
    onConfirmationCallBack(true);
  };

  handleKeyDownConfirm = (e, isShow) => {
    if(isEnterButtonPressed(e)) {
      this.onCloseConfirm(e, isShow);
    }
  }

  handleKeyDownRemove = (e) => {
    if(isEnterButtonPressed(e)) {
      this.handleClickConfirm(e);
    }
  }

  onCloseConfirm(event,isShow) {
    const { onConfirmationCallBack } = this.props;
    event.stopPropagation();
    this.setState({ showModal: isShow });
    onConfirmationCallBack(false);
  }

  renderConfirmModal() {
    const { showModal } = this.state;
    const { messageTitle, message } = this.props;

      if(!showModal){
       return
      }

      return (
        <Modal show={true} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
          <div className="modal-wrapper">
            <Modal.Header>
              <Modal.Title> {messageTitle} </Modal.Title>
                <button
                  aria-label="Close"
                  tabIndex={0}
                  className="close"
                  onKeyDown={(event) => this.handleKeyDownConfirm(event, false)}
                  onClick={(event) => this.onCloseConfirm(event, false)}>
                  <i className="icons-close"></i>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <span>{message}</span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
              <ForgeButton type="flat" className="mr-auto">
                  <button
                    aria-label="Cancel"
                    className="mr-auto"
                    tabIndex={0}
                    onClick={(event) => this.onCloseConfirm(event,false)}
                    onKeyDown={(event) => this.handleKeyDownConfirm(event, false)}>Cancel
                  </button>
                </ForgeButton>
                <ForgeButton type="raised">
                  <button
                    ref={ref => { this.confirmInput = ref; }}
                    aria-label="Confirm"
                    className=""
                    tabIndex={0}
                    onClick={(event) => this.handleClickConfirm(event)}
                    onKeyDown={this.handleKeyDownRemove}>Confirm
                  </button>
                </ForgeButton>
            </Modal.Footer>
          </div>
      </Modal>
      )
  }

  render() {

    return (
      <>
        {this.renderConfirmModal()}
      </>
    )
  }
}

ConfirmationDialogue.propTypes = {
  onConfirmationCallBack: PropTypes.func,
  message: PropTypes.string,
  messageTitle: PropTypes.string
}

export default ConfirmationDialogue;
