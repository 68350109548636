import { getNewFilters } from 'helpers/visualizationHelper';
import { NUMBER_TYPES_FIELD } from 'appConstants';
import { getTableSearchField } from 'pages/drilldown/components/QuickFilterBar/helper';

export const getSearchEntriesAndQuickFilters = (searchFieldEntry, searchValue, options) => {
  const { tableSearchEntries, quickFilters, templateId } = options;
  const { column, renderType } = searchFieldEntry;

  const isNumericSearch = (renderType === NUMBER_TYPES_FIELD);
  let newSearchInputEntries = _.cloneDeep(tableSearchEntries);
  let currentSearchEntry = _.find(newSearchInputEntries, { column });
  let newSearchValue = isNumericSearch ? searchValue : [searchValue];
  if(isNumericSearch && !_.isEmpty(currentSearchEntry)) {
    const numericValue = Number(searchValue);
    currentSearchEntry['values'] = numericValue;
  } else {
    if(_.isEmpty(currentSearchEntry)) {
      newSearchInputEntries.push({ values: newSearchValue, column, renderType });
    } else {
      currentSearchEntry['values'].push(searchValue);
    }
  }

  const searchObject = getSearchObject(searchValue, searchFieldEntry, currentSearchEntry, templateId);
  const newQuickFilters = getNewFilters(_.cloneDeep(quickFilters), searchObject);

  return { newQuickFilters, newSearchInputEntries };
}

export const getSearchEntries = (searchFieldEntry, searchValues, options) => {
  const { tableSearchEntries } = options;
  const { column, renderType } = searchFieldEntry;

  const isNumericSearch = (renderType === NUMBER_TYPES_FIELD);
  let newSearchInputEntries = _.cloneDeep(tableSearchEntries);
  let currentSearchEntry = _.find(newSearchInputEntries, { column });
  if(!_.isEmpty(currentSearchEntry)){
    currentSearchEntry['values'] = [];
  }
  
  _.map(searchValues, (searchValue) => {
    let newSearchValue = isNumericSearch ? searchValue : [searchValue];
    if(isNumericSearch && !_.isEmpty(currentSearchEntry)) {
      const numericValue = Number(searchValue);
      currentSearchEntry['values'] = numericValue;
    } else {
      if(_.isEmpty(currentSearchEntry)) {
        newSearchInputEntries.push({ values: newSearchValue, column, renderType });
      } else {
        currentSearchEntry['values'].push(searchValue);
      }
    }
  });
  
  return { newSearchInputEntries };
}

const getSearchObject = (searchValue, searchFieldEntry, searchInputEntry, templateId) => {
  const { column, field, renderType } = searchFieldEntry;
  const tableSearchField = getTableSearchField(templateId, { column, field });
  const isNumericSearch = (renderType === 'number');

  return _.omit({
    field: tableSearchField,
    column,
    type: renderType,
    operator: isNumericSearch ? '=' : "like",
    value: _.get(searchInputEntry, 'values', searchValue),
    values: _.get(searchInputEntry, 'values', [searchValue])
  }, [isNumericSearch ? 'values' : 'value']);
}

export const getNewTableSearchEntries = (templateId, quickFilters, tableSearchEntries) => {
  const newTableSearchEntries = _.filter(tableSearchEntries, (entry) => {
    const { column } = entry;
    const matchedEntry = _.find(quickFilters, { column });
    return !_.isEmpty(matchedEntry);
  });

  return _.without(newTableSearchEntries, null);
}
