import { DATE_FORMAT } from 'appConstants';
import _ from 'lodash';
import moment from 'moment';
import { isForecastEndDateIsBeforeToday } from 'pages/Forecasting/ForecastHelper';

export const lineChartSumDataByAxisGranularity =
  (data = [], vizOptions, isForecast, customDateRange = {}) => {
  let groupByDateResult = [], futureDateRange,
    dateRange = _.get(vizOptions, 'dateRange', {}),
    startDate, endDate;
  const axisGranularity = _.get(vizOptions, 'axisGranularity');
  if (isForecast){
    futureDateRange = _.get(vizOptions, 'forecastingOption.futureForecastDateRange.dateRange', {})
    if(isForecastEndDateIsBeforeToday({ dateRange }, axisGranularity)){
      dateRange = {
        ...futureDateRange,
        startDate: axisGranularity == 'quarter' ?
          moment(dateRange.endDate, DATE_FORMAT).startOf('quarter').format(DATE_FORMAT) : dateRange.endDate,
      }
    } else {
        dateRange = futureDateRange;
    }
  }

  if (!_.isEmpty(customDateRange)){
    dateRange = customDateRange
  }

  startDate = moment(dateRange['startDate']);
  endDate = moment(dateRange['endDate']);


  let dateList = [];
  switch (axisGranularity) {
    case 'week':
      // Setting Sunday as the first day of the week.
      startDate = startDate.startOf('week').day(0);
      dateList = generateTimeList(startDate, endDate, 'weeks', 'week');
      break;
    case 'month':
      startDate = startDate.clone().startOf('month');
      dateList = generateTimeList(startDate, endDate, 'months', 'month');
      break;
    case 'quarter':
      startDate = isForecast ? startDate.clone().startOf('quarter') : startDate;
      dateList = generateTimeList(startDate, endDate, 'quarters', 'quarter');
      break;
    case 'year':
      startDate = startDate.clone().startOf('year');
      dateList = generateTimeList(startDate, endDate, 'years', 'year');
      break;
    case 'day':
      startDate = startDate.clone().startOf('day');
      dateList = generateTimeList(startDate, endDate, 'days', 'day');
      break;
  }

  _.each(dateList, (dateGroup) => {
    groupByDateResult.push(
      {
        period: getPeriodFormatted(dateGroup, {axisGranularity, isForecast}),
        value: getSumOfValuesWithinDateRange(data, dateGroup)
      });
  });

  return groupByDateResult;
}

// Generate lists for weeks, months, quarters, and years
export const generateTimeList = (start, end, unit, displayUnit) => {
  const timeList = [];
  let currentTime = start;
  const isQuarter = displayUnit === 'quarter';

  while (currentTime.isSameOrBefore(end)) {
    const timeStart = currentTime.clone();
    const timeEnd = currentTime.clone().endOf(displayUnit);
    timeList.push({ start: timeStart, end: timeEnd });
    if(isQuarter && _.size(timeList) == 1){
      // Purpose find the second(next) quarter start date.
      const quarterStartDate = start.clone().startOf(unit)
      currentTime = quarterStartDate.add(1, unit);
    } else {
      currentTime.add(1, unit);
    }
  }

  return timeList;
};

export const getForecastData = (result) => {
  return {total: _.get(result, 'total.forecasted_data', [])};
}

export const getHistoricForecastData = (result) => {
  return _.mapValues(result, (value) => {
    return _.get(value, 'forecasted_data', []);
  });
};

export const lineChartSumApiData = (apiData, vizOptions) => {
  let updatedApiData = _.cloneDeep(apiData);

  const totalEntries = _.isEmpty(apiData['total']) ? _.get(apiData, 'api_data.total') : apiData['total'];
  updatedApiData['total'] = lineChartSumDataByAxisGranularity(totalEntries, vizOptions, false);

  if (!_.isEmpty(apiData['forecasting_data'])) {
    const forecastingData =
      mergeTotalWithProjection(_.get(apiData, 'forecasting_data.total'), totalEntries, vizOptions);
    updatedApiData['forecasting_data']= { total:
      lineChartSumDataByAxisGranularity(forecastingData, vizOptions, true)
    };
  }

  if (!_.isEmpty(apiData['historic_data'])) {
    let historic_data = {};
    _.each(_.get(apiData, 'historic_data'), (data, key) => {
      const forecastingData = mergeTotalWithProjection(data, totalEntries, vizOptions);
      historic_data = {
        ...historic_data,
        [`${key}`] : lineChartSumDataByAxisGranularity(forecastingData, vizOptions, true)
      };
    });
    updatedApiData['historic_data'] = historic_data;
  }

  if (!_.isEmpty(apiData['exponential_data'])) {
    const forecastingData =
      mergeTotalWithProjection(_.get(apiData, 'exponential_data.total'), totalEntries, vizOptions);
    updatedApiData['exponential_data'] = { total:
      lineChartSumDataByAxisGranularity(forecastingData, vizOptions, true)
    };
  }

  return updatedApiData;
};

export const sumApiDataForActualData = (dataItems, vizOptions, dateRange) => {
  return lineChartSumDataByAxisGranularity(dataItems, vizOptions, false, dateRange);
};

export const getNoOfPredictions = (vizOptions, defaultAxisGranularity) => {
  const dateRange = _.get(vizOptions, 'forecastingOption.futureForecastDateRange.dateRange', {})
  return countDatesInDateRange(
    dateRange['startDate'],
    dateRange['endDate'],
    defaultAxisGranularity
  );
}

const countDatesInDateRange = (startDate, endDate, axisGranularity) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  let periodType = "days";
  switch (axisGranularity) {
    case 'week':
      periodType = "weeks";
      break;
    case 'month':
      periodType = "months";
      break;
    case 'quarter':
      periodType = "quarters";
      break;
    case 'year':
      periodType = "years";
      break;
  }

  // Calculate the difference in the specified period
  const duration = endMoment.diff(startMoment, periodType);
  // Add 2 to include the start date and end date in the count
  const count = duration + 2;

  return count;
};

// Sum the data within the data range
const getSumOfValuesWithinDateRange = (data, dateRange) => {
  const filteredData = _.compact(_.filter(data, (item) =>{
    if(moment(item.period).isBetween(dateRange['start'], dateRange['end'], null, '[]')){
      return item;
    }
  }));
  return _.sumBy(filteredData, (item) =>{
    return _.isEmpty(item['adjustValue']) ? Number(item['value']) : Number(item['adjustValue']);
  })
};

const getPeriodFormatted = (dateRange, options) => {
  if (options['axisGranularity'] == 'year' && options['isForecast']){
    return `${dateRange['end'].format('YYYY-MM-DD')} 00:00:00`;
  } else {
    return  dateRange['start'].format('YYYY-MM-DDTHH:mm:ss.SSS');
  }
}

// To add incomplete periods value into projection.
const mergeTotalWithProjection = (projectionData, totalEntries, vizOptions) => {
  const totalData = updateAdjustedValueToValue(totalEntries, vizOptions)
  return projectionData.concat(totalData);
};

const updateAdjustedValueToValue = (totalEntries, vizOptions) => {
  const apiTotalData = _.cloneDeep(totalEntries);
  const { projectionAdjustedValues } = vizOptions;

  _.forEach(apiTotalData, (datum) => {
    let availableDataItem = _.find(projectionAdjustedValues, (dataItem) => {
      return dataItem.period == datum.period;
    });

    const adujustValue = _.get(availableDataItem, 'adjustValue',
      _.get(availableDataItem, 'value', _.get(datum, 'value')));
    datum['value'] = _.toNumber(_.get(datum, 'value')) !== 0 ? _.get(datum, 'value') :  adujustValue;
  })

  return apiTotalData;
}