import { FILTER_SELECT_ALL } from 'appConstants';
import $ from 'jquery';

const BODY_PADDING_FOR_LEGEND_PICKER = 320;
const BODY_PADDING_FOR_COMPARE_YEAR_DROPDOWN = 50;
const TABLE_SCREEN_BREAKPOINT = 768;
const TABLE_SCREEN_FORECAST_BREAKPOINT = 839;
const MOBILE_SCREEN_BREAKPOINT = 550;
const PAGE_SCROLL_BOTTOM_BUFFER = 300; // in pixels
const ELEMENT_SCROLL_BOTTOM_BUFFER = 50; // in pixels
const MAX_CARD_HEIGHT = 500;
const MAX_SCREEN_SIZE = 2800;
const GLOBAL_FILTER_BAR_HEIGHT = 100;
const TABS_HEIGHT = 100;
const COLLETION_FILTER_BAR_HEIGHT = 40;
const DEFAULT_LIST_ITEM_PADDING = 30;
const MIN_TIPSY_WIDTH = 300;

export const isBottomOfPageReached = () => {
  return Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
    (document.scrollingElement.scrollHeight - PAGE_SCROLL_BOTTOM_BUFFER);
}

export const setBodyBottomPadding = (isRemovePadding = true, componentType) => {
  const bodyBottomPadding = (componentType === 'compareYear') ?
    BODY_PADDING_FOR_COMPARE_YEAR_DROPDOWN :
    BODY_PADDING_FOR_LEGEND_PICKER;

  if (isRemovePadding) {
    document.body.style.paddingBottom = '';
  } else {
    if (!document.body.style.paddingBottom) {
      document.body.style.paddingBottom = `${bodyBottomPadding}px`;
    }
  }
}

export const isMobileOrTablet = () => {
  return window.screen.width <= TABLE_SCREEN_BREAKPOINT;
};

export const isSmallForecastingChartSize = () => {
  const chartElement = document.getElementById("id-forecasting-chart-card");
  if(chartElement){
    let chartSize = chartElement.getBoundingClientRect();
    return chartSize.width <= TABLE_SCREEN_FORECAST_BREAKPOINT
  }
  
}

export const getForecastingChartLegendWidth = () => {
  const legendElement = document.getElementById("id-legend-data");
  if(legendElement){
    return legendElement.getBoundingClientRect().width;
  }  
}

export const isMobileView = () => {
  return window.screen.width <= MOBILE_SCREEN_BREAKPOINT;
};

// Need to move this function somewhere related to the dashboard sections.
export const getNumberOfCardsPerRow = () => {
  const cardList = document.getElementsByClassName('dashboard-cards')[0];
  // const card = document.getElementsByClassName('card-container')[0];
  const dummyCard = document.getElementsByClassName('dummy-cards')[0];
  const explorationCard = document.getElementsByClassName('exploration-container')[0];
  if (_.isEmpty(cardList) || (_.isEmpty(explorationCard) && _.isEmpty(dummyCard))) {
    return null;
  }

  const rowWidth = cardList.clientWidth
  const cardWidth = (explorationCard || dummyCard).clientWidth;
  return Math.floor(rowWidth / cardWidth);
}

export const getNumberRow = () => {
  const cardHeight = (
    window.innerHeight - GLOBAL_FILTER_BAR_HEIGHT - TABS_HEIGHT - COLLETION_FILTER_BAR_HEIGHT
  );
  const numofRow = Math.ceil(cardHeight / MAX_CARD_HEIGHT);
  return (cardHeight > MAX_SCREEN_SIZE) ? numofRow + 1 : numofRow;
}

export const isClickInfoSection = (e) => {
  const isClickWithinDropdown = $(e.target).closest('.info-sections').length > 0;
  const isClickWithinDeleteModal = $(e.target).closest('.delete-container').length > 0;
  const isClickOutsideDeleteModal = $(e.target).closest('.modal').length > 0;
  const isClickWithinMenuModal = $(e.target).closest('.forge-menu__popup').length > 0;

  return (
    isClickWithinDropdown ||
    isClickWithinDeleteModal ||
    isClickOutsideDeleteModal ||
    isClickWithinMenuModal
  );
}

export const isBottomOfElementReached = (element) => {
  return Math.ceil(element.clientHeight + element.scrollTop) >=
    (element.scrollHeight - ELEMENT_SCROLL_BOTTOM_BUFFER);
}

export const getElementTransformAndWidth = () => {
  const width = ($('.selected-name-list').width() || 0) + DEFAULT_LIST_ITEM_PADDING;
  return { width: `calc(95% - ${width}px)`, transform: `translate(${width}px, 0)` };
}

export const isClickWithinClosestElement = (e, className) => {
  return $(e.target).closest(className).length > 0;
}

export const setBodyClassBasedOnHelpIconClick = (event) => {
  if (isClickWithinClosestElement(event, 'forge-app-bar-help-button')) {
    setBodyClassName('show-popup');
  } else {
    setTimeout(() => {
      removeBodyClassName('show-popup');
    }, 300)
  }
}

export const setBodyClassName = (className) => {
  document.body.classList.add(className);
}

export const removeBodyClassName = (className) => {
  document.body.classList.remove(className);
}

export const removeBodyAttribute = (attribute) => {
  document.body.removeAttribute(attribute);
}

export const getArrowPosition = (e) => {
  const isPageRightSideReached = e.clientX > (window.innerWidth - MIN_TIPSY_WIDTH);
  return isPageRightSideReached ? 'left' : 'bottom';
}

export const setZIndexForCollectionTabs = (zIndex) => {
  const collectionTabsElement = document.getElementsByClassName('collections-tabs')[0];
  if (collectionTabsElement) {
    collectionTabsElement.style.zIndex = zIndex
  }
}

export const setZIndexForCollectionActionHeader = (zIndex) => {
  const collectionActionElement = document.getElementsByClassName('collection-action-header')[0];
  if (collectionActionElement) {
    collectionActionElement.style.zIndex = zIndex
  }
}

export const setForgeTabBarTransform = ({ forgeTabBarRef, activeIndex }) => {
  const forgeTabBarElement = forgeTabBarRef.querySelector('forge-tab-bar');
  const scrollContentElement = forgeTabBarElement.
    shadowRoot.querySelector('.mdc-tab-scroller__scroll-content');
  const forgeTabScrollerElement = forgeTabBarElement.shadowRoot.querySelector('.mdc-tab-scroller');
  const collectionTabElements = forgeTabBarRef.querySelectorAll('.drop-target-tab');

  let width = 0;
  let isMatchedCurrentElement = false;
  const isLastElement = _.size(collectionTabElements) - 2 == activeIndex;
  if (activeIndex) {
    const currentActiveIndex = isLastElement ? activeIndex : activeIndex + 1;

    _.each(collectionTabElements, (element, index) => {
      if (!isMatchedCurrentElement) {
        width = element.clientWidth + width;
      }

      if (index == currentActiveIndex) {
        isMatchedCurrentElement = true;
      }
    });
  }

  if (width > forgeTabScrollerElement.clientWidth && activeIndex) {
    const scrollWidth = (width - forgeTabScrollerElement.clientWidth);
    setTimeout(() => {
      const forgeTabScrollerElement = forgeTabBarElement.
        shadowRoot.querySelector('.mdc-tab-scroller__scroll-area');
      forgeTabScrollerElement.scrollLeft = scrollWidth;
    }, 10);
  } else if (activeIndex < 3 && activeIndex != 0) {
    scrollContentElement.style.setProperty("transform", `translateX(10px)`);
  }
}

export const setForgeTabBarAnimate = (forgeTabBarRef) => {
  const forgeTabBarElement = forgeTabBarRef.querySelector('forge-tab-bar');
  var forgeTabScrollerElement = forgeTabBarElement.shadowRoot.querySelector('.mdc-tab-scroller');

  forgeTabScrollerElement.classList.add('mdc-tab-scroller--animating');

  setTimeout(() => {
    forgeTabScrollerElement.classList.remove('mdc-tab-scroller--animating');
  }, 100)
}

export const addCustomAccessbilityForForgeTabBarScroll = (forgeTabBarRef) => {
  const forgeTabBarElement = forgeTabBarRef.current.querySelectorAll('forge-tab-bar');

  _.each(forgeTabBarElement, (tabBarElement) => {
    var prevContainerScrollerElement =
      tabBarElement.shadowRoot.querySelector('.forge-tab-bar__scroll-prev-container');
    prevContainerScrollerElement.setAttribute('role', 'tab');

    var nextContainerScrollerElement =
      tabBarElement.shadowRoot.querySelector('.forge-tab-bar__scroll-next-container');
    nextContainerScrollerElement.setAttribute('role', 'tab');
  });

}

export const scrollLegendElementToTarget = (isOpenLegend, isForecastingView = false) => {

  if (!isMobileOrTablet()) {
    return
  }

  if (isOpenLegend) {
    setTimeout(() => {
      const element = document.querySelector(".legend-open");
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 200);
  } else {
    const elementClass = isForecastingView ? 'radar-page-top' : 'visualization-header'
    const element = document.querySelector(`.${elementClass}`);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}

export const formatForgeAutocompleteOption = () => {
  const forgePopup = document.body.querySelector('forge-popup');

  if(!_.isNull(forgePopup)){
    const forgeList = forgePopup.querySelector('forge-list');
    const forgeListItems = forgePopup.querySelectorAll('forge-list-item');

    forgeListItems.forEach((listItem) => {
      const textContent = listItem.textContent.trim();
      if (textContent === FILTER_SELECT_ALL) {
        forgeList.removeChild(listItem);
        forgeList.insertBefore(listItem, forgeList.firstChild);
      }
    })
  }
}

export const resizeWindowHandler = () => {
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 600);
}
