import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';

import SlaFilter from './SlaOptions/SlaFilter';
import HeatChartSort from './SlaOptions/HeatChartSort';
import { getSLAWatchData } from 'common/api/drilldown';
import { fetchApiData } from 'helpers/apiResponseHelper';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import {
  getDimensionList,
  isSummarizeConfigured,
  getApiParamsForChart,
  sortHeatChartEntries,
  filterChartData,
  getSelectedDimensionName
} from './slaWatchHelper';
import {
  getSlaWatchTitle,
  getSelectedTemplateForSLAWatch,
  getSLAWatchChartType
} from 'common/config/customerConfiguration';
import HeatChartContainer from './HeatChart/HeatChartContainer';
import BulletChart from './BulletChart/BulletChart';
import SlaNoDataFound from 'common/components/NoDataFound/SlaNoDataFound';
import classNames from 'classnames';

const DIMENSION_MIN_OPTION_SIZE = 1;
const DEFAULT_SORT_VALUE = 'most_failed_check';
const DEFAULT_FILTER_VALUE = 'least_one_failed_checks';
const chartType = getSLAWatchChartType();
class SlaRightContainer extends Component {

  constructor(props, context) {
    super(props, context);
    const dimensions = getDimensionList();

    this.state = {
      isLoading: true,
      rawApiData: {},
      formattedApiData: {},
      selectedDimension: _.first(dimensions),
      chartSortBy: DEFAULT_SORT_VALUE,
      chartFilter: DEFAULT_FILTER_VALUE,
      showNoDataCategories: true,
      rawWithoutNoData: [],
      noDataCount: 0
    };
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.fetchChartData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedItem, dateRange, globalFilters } = this.props;

    const { selectedDimension } = this.state;
    const isSelectedItemChanged = !_.isEqual(prevProps.selectedItem, selectedItem);
    const isSelectedDimensionChanged = !_.isEqual(prevState.selectedDimension, selectedDimension);
    const isDateChanged = !_.isEqual(dateRange, prevProps.dateRange);
    const isGlobalFiltersChanged = !_.isEqual(globalFilters, _.get(prevProps, 'globalFilters', []));
    if (isSelectedItemChanged ||
      isSelectedDimensionChanged ||
      isDateChanged ||
      isGlobalFiltersChanged) {
      this.setState({
        isLoading: true
      });
      this.fetchChartData();
    }

    if (isSelectedItemChanged || isSelectedDimensionChanged) {
      this.setState({
        chartSortBy: DEFAULT_SORT_VALUE,
        chartFilter: DEFAULT_FILTER_VALUE
      });
    }
  }

  fetchChartData = () => {
    const { selectedDimension } = this.state;
    const { dateRange, selectedItem, globalFilters } = this.props;
    const params = {
      templateId: getSelectedTemplateForSLAWatch(),
      dateRange: dateRange,
      globalFilters
    }
    if (isSummarizeConfigured()) {
      params['dimensionField'] = selectedDimension['field'];
      params['summarizeByValue'] = selectedItem['dimension'];
    } else {
      params['dimensionField'] = selectedItem['dimension'];
      params['summarizeByValue'] = '';
    }
    const apiParams = getApiParamsForChart(params);
    const apiUrl = getSLAWatchData(apiParams);

    fetchApiData(apiUrl, this.abortFetchController)
      .then((response) => {
        const { noDataCount, filterRawData } = this.getFilterRawData(response);
        this.setState({
          isLoading: false,
          rawApiData: response,
          rawWithoutNoData: filterRawData,
          noDataCount: noDataCount
        }, () => { this.formatRawApiData() });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          rawApiData: []
        });
      });
  }

  formatRawApiData = () => {
    const { rawApiData, rawWithoutNoData, chartFilter, chartSortBy, showNoDataCategories } = this.state;
    let formattedApiData = [];

    const filterRawData = showNoDataCategories ? rawApiData['entries'] : rawWithoutNoData;
    formattedApiData = filterChartData(filterRawData, chartFilter);
    if (chartType === 'heat_chart') {
      formattedApiData = sortHeatChartEntries(formattedApiData, chartSortBy)
    }
    this.setState({ formattedApiData });
  }

  getFilterRawData = (rawApiData) => {
    let filterRawData = [];
    let noDataCount = 0;
    _.forEach(rawApiData['entries'], (datum) => {
      const chartData = _.get(datum, 'chart_data', []);
      const valueList = _.filter(chartData, (filterData) => { return filterData.value > 0 });
      if (!_.isEmpty(valueList)) {
        filterRawData.push(datum);
      } if (_.isEmpty(valueList)) {
        noDataCount = noDataCount + 1;
      }
    });

    return {
      filterRawData,
      noDataCount
    };
  }

  renderTitle() {
    return (
      <div className="selected-dimension">
        {getSlaWatchTitle()}
        <span>by</span>
        {this.renderDimensionDropDown()}
      </div>
    );
  }

  renderDimensionDropDown() {
    const { selectedItem } = this.props;
    const { selectedDimension } = this.state;

    const allDimensions = getDimensionList();
    if (!isSummarizeConfigured()) {
      return <span>{selectedItem['name']}</span>;
    }

    if (_.size(allDimensions) <= DIMENSION_MIN_OPTION_SIZE) {
      return (<span>{selectedDimension['name']}</span>);
    }

    const availableDimensions = _.reject(allDimensions, { field: selectedDimension['field'] });

    return (
      <div>
        <SolutionDropDown
          className='p-0 compare-mode'
          size='sm'
          title={selectedDimension['name']}
          options={availableDimensions}
          onSelect={this.onDimensionChange}
        />
      </div>
    );
  }

  onDimensionChange = (dimension) => {
    this.setState({
      selectedDimension: dimension
    })
  }

  renderSLAFilter() {
    const { selectedItem } = this.props;
    const { chartFilter, selectedDimension } = this.state;
    return (
      <SlaFilter
        selectedDimension={selectedDimension}
        selectedListItem={selectedItem}
        chartFilter={chartFilter}
        handleSelectFilter={this.onFilterChange}
      />
    );
  }

  renderHeatChartSort() {
    const { selectedItem } = this.props;
    const { chartSortBy, selectedDimension } = this.state;

    if (chartType !== 'heat_chart') {
      return null;
    }

    return (
      <HeatChartSort
        selectedDimension={selectedDimension}
        selectedListItem={selectedItem}
        chartSortBy={chartSortBy}
        handleSortByChange={this.onSortByChange}
      />
    );
  }

  renderSLAOptions() {
    return (
      <div className='sla-filter'>
        {this.renderSLAFilter()}
        {this.renderHeatChartSort()}
      </div>
    )
  }

  onSortByChange = (option) => {
    this.setState({ chartSortBy: option['value'] }, () => {
      this.formatRawApiData();
    });
  }

  onFilterChange = (option) => {
    this.setState({
      chartFilter: option['type']
    }, () => {
      this.formatRawApiData();
    });
  }

  onShowNoDataChange = (showDataStatus) => {
    this.setState({
      showNoDataCategories: showDataStatus
    }, () => {
      this.formatRawApiData();
    });
  }

  renderChart() {
    const {
      isLoading, formattedApiData, selectedDimension, chartFilter, noDataCount, rawApiData
    } = this.state;
    const { selectedItem } = this.props

    if (_.isEmpty(formattedApiData) && !isLoading) {
      const dimensionName = getSelectedDimensionName(selectedDimension, selectedItem);
      const option = {
        chartFilterType: chartFilter,
        name: pluralize(dimensionName)
      }
      return <SlaNoDataFound slaOption={option} />;
    }

    if (chartType === 'heat_chart') {
      return (
        <HeatChartContainer
          selectedDimension={selectedDimension}
          selectedListItem={selectedItem}
          dimensionData={formattedApiData} 
          isLoading ={isLoading}>
        </HeatChartContainer>
      )
    } else {
      const noDataCountLabel = `(${noDataCount}/${_.size(rawApiData['entries'])})`
      return (
        <BulletChart
          dimensionData={formattedApiData}
          selectedDimension={selectedDimension}
          onShowNoDataChange={this.onShowNoDataChange}
          noDataCountLabel={noDataCountLabel}
          isLoading ={isLoading}>
        </BulletChart>
      )
    }
  }
  
  render() {
    const { isLoading } = this.state;
    const slaHeadClasses = classNames('sla-rs-head', {
      'action-disable' : isLoading
    });

    return (
      <div>
        <div className={slaHeadClasses}>
          {this.renderTitle()}
          {this.renderSLAOptions()}
        </div>
        {this.renderChart()}
      </div>
    )
  }
}

SlaRightContainer.propTypes = {
  commonFilters: PropTypes.object,
  selectedItem: PropTypes.object,
  dateRange: PropTypes.object,
  globalFilters: PropTypes.array
};

function mapDispatchToProps() {
  return {}
}

function mapStateToProps(state) {
  return {
    commonFilters: _.get(state, 'commonFilters', {}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlaRightContainer);
