import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  SUBSCRIPTION_FREQUENCY_OPTIONS,
  TIMEZONE_OPTIONS,
  WEEKDAYS_OPTIONS,
  TIME_PICKER_OPTIONS,
  WEEK_NUMBER_OPTIONS,
  MONTHLY_REGULARITY_TYPES
} from 'appConstants';
import FrequencySettings from 'common/components/FrequencySettings/FrequencySettings';
import FrequencySettingOption from 'common/components/FrequencySettings/FrequencySettingOption';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import EmailArrivalText from 'common/components/FrequencySettings/EmailArrivalText';
import { isManageCollections } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
import Recipients from 'pages/SubscriptionsManager/components/Recipients';
class SubscriptionSettings extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      name: props.name,
      sendEmail: true,
      frequency: _.get(SUBSCRIPTION_FREQUENCY_OPTIONS, '0.value'),
      frequencyInterval: 1,
      monthlyWeekNumber: _.get(WEEK_NUMBER_OPTIONS, '0.value'),
      emailSendingTime: _.get(TIME_PICKER_OPTIONS, '0'),
      timeZone: _.get(TIMEZONE_OPTIONS, '0'),
      emailSendingDays: [_.get(WEEKDAYS_OPTIONS, '1.day')],
      includeImage: true,
      recipients: this.props.currentSubscription.recipients,
      isErrorInRecipients: false
    };
  }

  componentDidMount() {
    const { isEditMode } = this.props;
    if(!isEditMode){
      this.updateCollectionSettings();
    }else{
      this.updateOptionsFormSubscriptions();
    }
  }

  componentDidUpdate(prevProps){
    const{ currentSubscription, isEditMode } = this.props;
    const prevSubscription = _.get(prevProps, 'currentSubscription', {});
    if(isEditMode){
      if(!_.isEqual(currentSubscription.id, prevSubscription.id) || _.isEmpty(prevSubscription)){
        this.updateOptionsFormSubscriptions();
      }
    }
  }

  updateOptionsFormSubscriptions = () => {
    const{ currentSubscription, isEditMode } = this.props;
    if(isEditMode){
      this.setState({
        name: currentSubscription.name,
        sendEmail: currentSubscription.send_email,
        frequency: currentSubscription.frequency,
        frequencyInterval: currentSubscription.frequency_interval,
        monthlyWeekNumber: currentSubscription.week_number_for_month,
        emailSendingTime: currentSubscription.email_sending_time,
        timeZone: currentSubscription.time_zone,
        emailSendingDays: currentSubscription.email_sending_days,
        includeImage: currentSubscription.include_image,
        startDate: _.get(currentSubscription, 'start_date', new Date()),
        regularityType: _.get(currentSubscription, 'regularity_type', MONTHLY_REGULARITY_TYPES.WEEK),
        timePeriodDay: _.get(currentSubscription, 'time_period_day', 1),
        recipients: _.get(currentSubscription, 'recipients', []),
        isCollaborateSubscription: _.get(currentSubscription, 'is_collaborate_subscription', false),
        isErrorInRecipients: false
      });
    }
  }
  onSettingValueChange = (value, field) =>{
    let formattedValue = value;
    this.setState({ [field]: formattedValue }, () => {
      this.updateCollectionSettings();
    });
  }

  handleIncludeImageChange = () => {
    const { includeImage } = this.state;
    this.setState({includeImage: !includeImage}, () => {
      this.updateCollectionSettings();
    });
  }

  handleToggleIncludeImage = (e) => {
    if(isEnterButtonPressed(e)) {
      this.handleIncludeImageChange();
    }
  }

  handleWeekdaysOptionsChange = (isChecked, day) => {
    const { emailSendingDays } = this.state;
    let selectedDays = _.cloneDeep(emailSendingDays);
    if(!isChecked && selectedDays.length === 1){
      return;
    }
    selectedDays = isChecked ? [...selectedDays, day] : _.without(selectedDays, day)

    this.setState({emailSendingDays: selectedDays}, () => {
      this.updateCollectionSettings();
    });
  }

  updateCollectionSettings = () => {
    const { onSettingsChange } = this.props;
    onSettingsChange(this.state);
  }

  onEmailUpdate = (emails, isError) => {
    const { recipients } = this.state;

    const updatedRecipients = {
      ...recipients,
      recipients: emails
    };

    this.setState({
      recipients: updatedRecipients,
      isErrorInRecipients: isError ||_.isEmpty(emails)
    }, () => {
      this.updateCollectionSettings();
    })
  }

  renderFrequencySettings = () => {
    const  { isDisabled } = this.props;
    const { frequency, timeZone, frequencyInterval, emailSendingTime } = this.state;
    const frequencySettingsAttributes = {
      frequency,
      timeZone,
      frequencyInterval,
      emailSendingTime,
      onSettingValueChange: this.onSettingValueChange,
      isDisabled,
    };

    return (<FrequencySettings {...frequencySettingsAttributes} />);
  }

  renderWeekdaysOption = () => {
    const  { isDisabled } = this.props;
    const frequencySettingsOptionAttributes = _.pick(this.state,
      'emailSendingDays',
      'frequency',
      'monthlyWeekNumber',
      'startDate',
      'timePeriodDay',
      'regularityType'
    );

    return (
      <FrequencySettingOption {...frequencySettingsOptionAttributes}
        handleWeekdaysOptionsChange={this.handleWeekdaysOptionsChange}
        onSettingValueChange={this.onSettingValueChange}
        isDisabled={isDisabled}
      />
    );
  }

  emailCadenceText = () => {
    const options = _.pick(this.state,
      'emailSendingDays',
      'frequency',
      'monthlyWeekNumber',
      'startDate',
      'timePeriodDay',
      'regularityType',
      'timeZone',
      'frequencyInterval',
      'emailSendingTime'
    );

    return (<EmailArrivalText {...options}/>);
  }

  renderRecipientsSection() {
    const {
      currentUser,
      currentSubscription,
      allowedUserRoles
    } = this.props;
    const { recipients } = this.state;

    if (!isManageCollections() ||
      !_.get(currentSubscription, 'is_collaborate_subscription', false)) {
      return null;
    }

    const recipientsEmails = _.get(recipients, 'recipients', []);

    return (
      <Recipients
        editMode={true}
        currentUser={currentUser}
        allowedUserRoles={allowedUserRoles}
        recipientsEmails={recipientsEmails}
        onEmailUpdate={this.onEmailUpdate}
      />
    );
  }

  renderIncludeImage = () => {
    const { isDisabled } = this.props;
    const { includeImage } = this.state;

    return(
      <div
        className="custom-control custom-checkbox mt-8"
        tabIndex="0"
        onKeyDown={(e) => this.handleToggleIncludeImage(e, !includeImage)}>
        <input
          type="checkbox"
          disabled={isDisabled}
          tabIndex="-1"
          checked={includeImage}
          className="custom-control-input"
          onChange={this.handleIncludeImageChange}
          id="include-images" />
        <label className="custom-control-label font-weight-bold" htmlFor="include-images">
          Include images
        </label>
      </div>
    );
  }

  render() {
    const { isDisabled } = this.props;
    const { name } = this.state;

    return (
      <div className="subscription-settings">
        <label className="custom-label"> Subscription Name </label>
        <input
          className="col-md-6 form-control text-dark tx-14"
          type="text"
          value={name}
          disabled={isDisabled}
          onChange={(e) => this.onSettingValueChange(e.target.value, 'name')}
          maxLength="200"
        />

        {this.renderRecipientsSection()}

        <label className="custom-label mt-8"> Choose the frequency of email </label>
        <div className="d-flex flex-wrap">
          <div className="mr-3 mt-2">
            Send an email of this collection every
          </div>

          <div className="subscription-filters d-flex flex-column gap-20">
            {this.renderFrequencySettings()}
            {this.renderWeekdaysOption()}
            {this.emailCadenceText()}
          </div>
        </div>
        {this.renderIncludeImage()}
      </div>
    )
  }
}

SubscriptionSettings.propTypes = {
  onSettingsChange: PropTypes.func,
  name: PropTypes.string,
  isEditMode: PropTypes.bool,
  currentSubscription: PropTypes.object,
  currentUser: PropTypes.object,
  allowedUserRoles: PropTypes.array,
  isDisabled: PropTypes.bool,
}

export default SubscriptionSettings;
