import abortableFetch from 'common/abortableFetch';

export const createRecipients = (recipientsParams) => {
  const recipientsOptions =  {
      method: 'POST',
      body: JSON.stringify(recipientsParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/alert_recipients.json"
  return abortableFetch(apiUrl, recipientsOptions);
};