// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from "prop-types";

class Legends extends Component {

  renderLegend = () => {
    const { legends } = this.props;
    const legendItems = _.map(legends, this.renderLegendItem);

    return(
      <div className="legend-btn">
        <div className="legend-menu">
          <ul className="list-unstyled">
            {legendItems}
          </ul>
        </div>
      </div>
    );
  }

  renderLegendItem = (legendItem, key) => {
    const attributes = {
      style: { backgroundColor: legendItem.color },
      className: 'legend-color'
    };

    return (
      <li key={key} >
        <div {...attributes}></div>
        <div className="legend-item forge-typography--body2">{legendItem.label}</div>
      </li>
    );
  }

  render() {
    return _.isEmpty(this.props.legends) ?  null : this.renderLegend();
  }
}

Legends.propTypes = {
  legends: PropTypes.array
};

export default Legends;
