import React from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

import CollaboratorRemoveIcon from "./CollaboratorRemoveIcon";
import CustomDropdownList from "common/components/SolutionDropDown/CustomDropdownList";
import { COLLABORATOR_ROLES, OWNER_ROLE } from "./constants";

const CollaborateUserTableRow = ({ onRoleSelect, user }) => {
  const currentUser = useSelector((store) => store.currentUser);
  const matchedRole = _.find(COLLABORATOR_ROLES, { value: user.role }) || COLLABORATOR_ROLES[0];
  const isCurrentUser = user['email'] === currentUser['email'];
  const isOwner = user['role'] == OWNER_ROLE;

  return !isOwner && (
    <tr>
      <td className="email-name">{user.email}</td>
      <td>
        {isCurrentUser ? matchedRole['name'] :
          <CustomDropdownList
            key="dimension-drop-down"
            id="role-item"
            className="p-0  d-flex"
            title={matchedRole['name']}
            showCount={true}
            optionDisplayField="name"
            options={COLLABORATOR_ROLES}
            onSelect={(roleItem) => onRoleSelect(roleItem, user)}
          />
        }
      </td>
      <td>
        {isCurrentUser ? '' : <div className="float-right"><CollaboratorRemoveIcon user={user} /></div>}
        </td>
    </tr>
  );
}

CollaborateUserTableRow.propTypes = {
  onRoleSelect: PropTypes.func,
  user: PropTypes.object
};

export default CollaborateUserTableRow;
