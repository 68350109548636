import React, { useEffect, useState } from 'react';

import CustomTooltip from 'common/components/CustomTooltip';
import GlobalEvent from 'common/components/GlobalEvents';
import { MAP_EVENTS } from "modules/visualization/constants";
import { getMapzoom } from 'common/config/templateConfiguration';
import { templateIdPropTypes } from 'common/propTypes';

import { ForgeButton } from '@tylertech/forge-react';

const RadarMapControls = ({ templateId }) => {
  const [showRecenterButton, setShowRecenterButton] = useState(false);
  const mapZoom = getMapzoom(templateId);

  const onMapMoveEnd = (zoom) => {
    const isZoomChanged = !_.isEqual(zoom, mapZoom);

    setShowRecenterButton(isZoomChanged);
  }
  const debouncedOnMapZoomEnd = _.debounce(onMapMoveEnd, 150);

  const onMapDragEnd = () => {
    setShowRecenterButton(true);
  }

  useEffect(() => {
    GlobalEvent.on(MAP_EVENTS.ZOOM_END, debouncedOnMapZoomEnd);
    GlobalEvent.on(MAP_EVENTS.DRAG_END, onMapDragEnd);
    return () => {
      GlobalEvent.off(MAP_EVENTS.ZOOM_END, debouncedOnMapZoomEnd);
      GlobalEvent.off(MAP_EVENTS.DRAG_END, onMapDragEnd);
    }
  }, [])

  const onClickRecenter = () => {
    GlobalEvent.emit(MAP_EVENTS.RE_CENTER);
    setShowRecenterButton(false);
  }

  const onClickZoomIn = () => {
    GlobalEvent.emit(MAP_EVENTS.ZOOM_IN);
  }

  const onClickZoomOut = () => {
    GlobalEvent.emit(MAP_EVENTS.ZOOM_OUT);
  }

  const renderRecenterButton = () => {
    return showRecenterButton && (
      <CustomTooltip
        key="map-center-button"
        placement="left"
        label="Re-center the map to where you started."
        >
      <div className="radar-recenter-btn">
        <ForgeButton type="outlined" onClick={onClickRecenter}>
          <button type="button" aria-label="Re-center">
            <span className="icons-location-arrow"></span>
          </button>
        </ForgeButton>
      </div>
      </CustomTooltip>
    );
  }

  return (
    <div className="map-ctrl-group">
      <div className="mapbox-ctrl-group">
        <button
          className="mapboxgl-zoom-in" type="button" aria-label="Zoom In"
          onClick={onClickZoomIn}
          >
        </button>
        <button
          className="mapboxgl-zoom-out" type="button" aria-label="Zoom Out"
          onClick={onClickZoomOut}
        >
        </button>
      </div>
      {renderRecenterButton()}
    </div>
  )
}

RadarMapControls.propTypes = {
  templateId: templateIdPropTypes
}

export default RadarMapControls;
