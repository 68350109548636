import React from "react";

export const ErrorBoundaryWrapper = (SourceComponent, errorContent) => {
 return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        errorInfo: null,
        hasError: false
      };
    }

    componentDidCatch(error, errorInfo) {
      this.setState({
        hasError: true,
        error: error,
        errorInfo: errorInfo
      })
    }

    render() {
      const { hasError, error, errorInfo } = this.state;
      if (hasError) {
        console.log("Component Error : " + JSON.stringify(error));
        console.log("Error info : " + JSON.stringify(errorInfo));
        return ( errorContent );
      }else {
        return (
          <SourceComponent {...this.props}/>
          );
      }
    }
  }
}
