import _ from 'lodash';
import React from 'react';

import { getMargins } from 'modules/PlotlyTooltip/helper';
import { getFlyoutPosition, getDurationFlyoutText, getYAxisDatePeriod } from './helper';
import { getFormattedValue } from 'helpers/chartDataHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import ColorBox from './ColorBox';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';

export const getAreaChartPopupConfigs = ({
  chartContainer,
  data,
  viewEntry,
  viewMode,
  isCurrencyDimensionField,
  axisGranularity,
  dateRange
}) => {
  const point = data.points[0];
  const margins = getMargins(chartContainer);
  const xaxis = point.xaxis;
  const yaxis = point.yaxis;
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  const primaryMetricName = getPrimaryMetricName(viewEntry);
  const period = getYAxisDatePeriod(point, dateRange, axisGranularity);

  const clonePoints = _.cloneDeep(data.points); 
  const tbodyContent = _.map(_.reverse(clonePoints), (point, index) => {
    const color = _.get(point, 'data.line.color', '#fff');
    const yAxisValue = _.get(point, 'y', 0);
    const originalTextValues = _.get(point, 'data.text_values', []);
    const pointIndex = _.get(point, 'pointIndex', 0);
    const originalYValue = _.nth(originalTextValues, pointIndex) || yAxisValue;
    const yValue = isSecondsFormat ? getDurationFlyoutText(originalYValue, viewEntry) :
      getFormattedValue(yAxisValue, viewEntry);
    const name = _.get(point, 'data.meta.dimension', '');
    return (
      <tr key={index}>
        <td>
          <div className='d-flex'>
            <ColorBox boxColor={color} />
            <div className="ml-3 font-weight-bold">
              {formatValueToCurrency(name, isCurrencyDimensionField)}
            </div>
          </div>
        </td>
        <td className="text-left">{yValue}</td>
      </tr>
    );
  });

  return [{
    isTimelineChart: true,
    position: getFlyoutPosition(data, viewMode),
    anchor: {
      x: xaxis.d2p(point.x) + margins.x - 6,
      y: yaxis.d2p(yaxis.range[0]) + margins.y
    },
    chartContainer,
    html: (
      <table className="flyout-table">
        <thead>
          <tr>
            <th className='pb-3'>{period}</th>
            <th className='pb-1'>{primaryMetricName}</th>
          </tr>
        </thead>
        <tbody>{_.without(tbodyContent, null)}</tbody>
      </table>
    )
  }];
};
