import moment from 'moment';
import { VIEW_MODE } from 'modules/visualization/constants';
import {
  AXIS_GRANULARITY_TYPES,
  OVERTIME_VISUALIZATION_TYPES,
  OVERTIME_TIME_FRAME_OPTIONS,
  DATE_OPTIONS_TYPE,
  DATE_TIME_FORMAT,
  X_AXIS_TICK_CONFIG
} from 'appConstants';

import { getProjectionPeriods } from './Helpers/projectionHelper';
import { disableMetricTotal } from 'common/config/viewConfiguration';

import { getConfiguredDataEndDate, getConfiguredDateType  } from 'common/config/customerConfiguration';
import { isEndDateOverridden } from 'common/config/viewConfiguration';

import {
  getFormattedWeekPeriod,
  getStartDateOfGivenPeriod,
  getDateRangeDifference,
  YEAR_DAYS_COUNT,
  MONTH_DAYS_COUNT
} from 'helpers/dateHelper';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import { filterCompareYearRanges } from 'common/contentFormatter/helper';
import { isBienniumFiscalYear } from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';
import { isShowRangeSlider, rangeSliderType } from 'common/config/visualizationConfiguration';
import { isForecastEndDateIsBeforeToday } from 'pages/Forecasting/ForecastHelper';
import { isMobileOrTablet, isMobileView } from 'helpers/DomPageHelper';
import { getQuarterMonths } from './Helpers/overtimeHelper';

const isFiscalYear = (getConfiguredDateType() == DATE_OPTIONS_TYPE.YEARLY);
export const MONTH_GROUPING_DAYS_COUNT = MONTH_DAYS_COUNT * 3;
const numberOfYears = isFiscalYear ? 3 : 2;
export const YEAR_GROUPING_DAYS_COUNT = (YEAR_DAYS_COUNT*numberOfYears);

const configuredDataEndDate = getConfiguredDataEndDate();
export const shouldShowRangeSlider = (vizOptions) => {
  const {
    renderTimeFrame,
    isComboChart,
    viewMode,
    isForecastingView
  } = vizOptions;

  if (viewMode === VIEW_MODE.SMALL || isForecastingView) {
    return false;
  }

  if (isYearOnYear({ renderTimeFrame })) {
    return false;
  }

  return isShowRangeSlider(isComboChart);
};

export const getSecondaryMetricEntries = (vizOptions) => {
  const { renderType, viewEntry } = vizOptions;
  return _.get(viewEntry, ['visualization', 'overtime', renderType, 'secondary_metric_entries'], []);
};

export const getComboChartPrimaryMetricRenderType = (vizOptions) => {
  const { renderType, viewEntry } = vizOptions;
  return _.get(viewEntry, ['visualization', 'overtime', renderType, 'render_type'], 'bar');
};


export const getBenchMarkConfigEntries = (vizOptions) => {
  const { viewEntry, renderType } = vizOptions;
  return _.get(viewEntry, `visualization.overtime.${renderType}.bench_mark_entries`, []);
};

export const getPeriodType = (vizOptions) => {
  const { axisGranularity, dateRange } = vizOptions;
  const isComparisonEnabled = isYearOnYear(vizOptions);
  if (isBienniumFiscalYear(vizOptions) && axisGranularity === 'default') {
    return 'month';
  }

  if (!_.isEmpty(axisGranularity)) {
    if (axisGranularity !== 'default') {
      if(isComparisonEnabled && axisGranularity == "week"){
        return 'month'
      }
      return axisGranularity == 'quarter' ? 'month' : axisGranularity;
    }
  }

  const startDateMmt = moment(dateRange.startDate);
  const endDateMmt = moment(dateRange.endDate)
  const daysDiff = endDateMmt.diff(startDateMmt, 'day');
  const yearGroupingDaysCount = getYearGroupingDaysCount(vizOptions);
  const leapYearCount = getLeapYearCount(dateRange);

  if ((daysDiff + 1) > (yearGroupingDaysCount + leapYearCount)) {
    return 'year';
  } else if ((daysDiff + 1) >= MONTH_GROUPING_DAYS_COUNT) {
    return 'month';
  } else if (shouldGroupByWeek(daysDiff)) {
    return isComparisonEnabled ? 'month' : 'week';
  } else {
    return 'day';
  }
};

export const getLeapYearCount = ({ startDate, endDate }) => {
  const startYear = Number(moment(startDate).format('YYYY'));
  const endYear = Number(moment(endDate).format('YYYY')) + 1;
  let leapYearCount = 0;

  _.each(_.range(startYear, endYear), (year) => {
    if (moment(_.toString(year)).isLeapYear()) {
      leapYearCount += 1;
    }
  });

  return leapYearCount;
}

export const getYearGroupingDaysCount = ({ dateRangeMode }) => {
  const isFiscalYear = (getConfiguredDateType() == DATE_OPTIONS_TYPE.YEARLY);
  const numberOfYears = (isFiscalYear && dateRangeMode === DATE_OPTIONS_TYPE.YEARLY) ? 3 : 2;

  return (YEAR_DAYS_COUNT * numberOfYears);
}

export const getXAxisPlotlyTickInterval = (vizOptions, tickStep = 1) => {
  const { renderTimeFrame } = vizOptions;
  if (isBienniumFiscalYear(vizOptions)) {
    return getTickInterval(vizOptions);
  }

  if (isYearOnYear({ renderTimeFrame })) {
    switch(getPeriodType(vizOptions)) {
      case 'year':
        return 'M12';
      case 'month':
        return 'M1';
      case 'week':
        return (7 * 24 * 60 * 60 * 1000);
      case 'day':
        return 'M1';
      default:
        return undefined;
    }
  }

  return getTickInterval(vizOptions, tickStep);
};

const getTickInterval = (vizOptions, tickStep = 1) => {
  const plotlyOneDayDTick = tickStep * 24 * 60 * 60 * 1000 // 86400000.0;
  switch(getPeriodType(vizOptions)) {
    case 'year':
      return `M12`;
    case 'month':
      return `M1`;
    case 'week':
      return (7 * 24 * 60 * 60 * 1000);
    case 'day':
      return plotlyOneDayDTick;
    default:
      return undefined;
  }
}

export const getXAxisPlotlyTickFormat = (vizOptions) => {
  const { renderType, renderTimeFrame } = vizOptions;
  if (isBienniumFiscalYear(vizOptions) && isYearOnYear({ renderTimeFrame })) {
    return '%b';
  }
  if (isYearOnYear({ renderTimeFrame: renderType })) {
    return undefined;
  }
  if (isYearOnYear({ renderTimeFrame })) {
    return getXAxisTickFormatForCompareMode(vizOptions);
  }
  return getXAxisTickFormat(vizOptions);
};

const getXAxisTickFormatForCompareMode = (vizOptions) => {
  switch(getPeriodType(vizOptions)) {
    case 'year':
      return 'Y';
    case 'month':
      return '%b %Y';
    case 'day':
      return '%b %d';
    default:
      return undefined;
  }
}

const getXAxisTickFormat = (vizOptions) => {
  // const plotlyOneDayDTick = 86400000.0;

  switch(getPeriodType(vizOptions)) {
    case 'year':
      return '%Y';
    case 'month':
      return '%b %Y';
    case 'week':
      return '%m %d %Y';
    case 'day':
      return '%d %b %Y';
    default:
      return undefined;
  }
}

export const isYearOnYear = ({ renderTimeFrame }) => {
  return (OVERTIME_TIME_FRAME_OPTIONS.YEAR_ON_YEAR === renderTimeFrame);
}

export const getCurrentDateRangePeriods = (vizOptions, apiData, showQuarterRange = false) => {
  let startDateMmt = moment(vizOptions.dateRange.startDate);
  let endDateMmt = moment(vizOptions.dateRange.endDate);

  if (vizOptions.isForecastingView &&
    isForecastEndDateIsBeforeToday({dateRange: vizOptions.dateRange}, vizOptions.axisGranularity)){
    const periodType = getPeriodType(vizOptions) || vizOptions.axisGranularity;
    endDateMmt = endDateMmt.subtract(1, periodType);
  }

  // endDate should be less than configuredDataEndDate, otherwise
  // configuredDataEndDate is taken as endDate of actual period.
  if (configuredDataEndDate.isBefore(vizOptions.dateRange.endDate)) {
    endDateMmt = configuredDataEndDate;
  }

  const periods = _.chain(apiData).
      get('total').
      map((totalDataItem) => totalDataItem.period).
      sort().
      value();
  const isEndDateBeforeLastData = endDateMmt.isBefore(moment(_.last(periods)));
  if(!vizOptions.isForecastingView && !_.isEmpty(periods) && isEndDateBeforeLastData) {
    endDateMmt = moment(_.last(periods));
  }
  if (shouldShowAllDates(vizOptions)) {
    // Since the period string is in YYYY-MM-DD format, sorting the string will sort properly based
    // on the date.

    const dataStartDateMmt = moment(_.first(periods));
    const dataEndDateMmt = moment(_.last(periods));

    startDateMmt = dataStartDateMmt.isBefore(startDateMmt, 'day') ? dataStartDateMmt : startDateMmt;
    endDateMmt = dataEndDateMmt.isAfter(endDateMmt, 'day') ? dataEndDateMmt : endDateMmt;
  }
  const periodType = (isQuarterPeriod(vizOptions) &&
                      showQuarterRange) ? 'quarters' : getPeriodType(vizOptions);
  if(periodType == 'week') {
    startDateMmt = getStartDateOfGivenPeriod(periodType, startDateMmt);
    endDateMmt = getStartDateOfGivenPeriod(periodType, endDateMmt).endOf(periodType);
  }

  return getRangePeriods(startDateMmt, endDateMmt, periodType);
};

export const isQuarterPeriod = (vizOptions) => {
  const { axisGranularity } = vizOptions;
  if (axisGranularity == "quarter") {
    return true;
  } else {
    return false;
  }
}

export const getComparisonDateRangePeriods = (vizOptions) => {
  const { compareYearRanges } = vizOptions;
  const periodType = getPeriodType(vizOptions);
  if (_.size(compareYearRanges) != 1) {
    return [];
  }
  const comparisonDateRange = _.first(compareYearRanges);
  return getRangePeriods(comparisonDateRange.startDate, comparisonDateRange.endDate, periodType);
};

export const isTraceVisible = (
  vizOptions,
  { traceId, year, dimensionName, isDimensionsChanged = false, traceType, dimensionConfigs, isTotalLine }
) => {
  const newDimensionConfigs = _.get(vizOptions, 'dimensionConfigs', dimensionConfigs);
  const defaultVisible = dimensionDefaultVisible(vizOptions, traceId, traceType, isTotalLine);

  if (!_.isEmpty(newDimensionConfigs)) {
    const dimensionAndYearConfig = _.find(newDimensionConfigs, (config) => {
      return (
        (_.get(config, 'dimension') === dimensionName) && (_.get(config, 'year') === year) ||
        (_.get(config, 'dimension') === dimensionName)
      );
    });

    const totalDimensionConfigs = _.filter(newDimensionConfigs, (datum) => {
      return _.get(datum, 'isTotalLine', false) == true
    });

    const isTotalDimensionConfig = _.size(newDimensionConfigs) == _.size(totalDimensionConfigs);
    const dimensionConfig = dimensionAndYearConfig || _.find(newDimensionConfigs, { traceId });
    if(isTotalDimensionConfig && _.size(totalDimensionConfigs) > 0){
      return _.get(dimensionConfig, 'visible', false);
    } else {
      return _.isUndefined(dimensionConfig) || isDimensionsChanged ? defaultVisible : dimensionConfig.visible;
    }
  } else {
    return defaultVisible;
  }
};

export const dimensionDefaultVisible = (vizOptions, traceId, traceType, isTotalLine = false) => {
  const {
    isComboChart,
    renderType,
    viewEntry,
    dateRange,
    renderTimeFrame,
    compareYearRanges,
    isForecastingView
  } = vizOptions;

  const isAreaChart = renderType === OVERTIME_VISUALIZATION_TYPES.AREA.type;

  let defaultVisible;
  if (isAreaChart) {
    defaultVisible = true;
  } else if (disableMetricTotal(viewEntry)) {
    return true;
  } else if (isTotalLine) {
    defaultVisible = true;
  } else if (isComboChart) {
    defaultVisible = true;
  } else if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges)) {
    defaultVisible = true;
  } else {
    defaultVisible = _.get(viewEntry,
      `visualization.overtime.${renderType}.show_dimensions`) === 'true' || isForecastingView;
  }
  return defaultVisible
}

export const shouldShowAllDates = (vizOptions) => {
  const { viewMode } = vizOptions;
  const showAllDatesConfig = rangeSliderType();
  return (viewMode !== VIEW_MODE.SMALL) && (showAllDatesConfig === 'all_years');
};

export const getSliderRange = (vizOptions, apiData, xAxisLabels = []) => {
  const { dateRange, plotlySliderRange, isForecastingView, isForecastAccuracy } = vizOptions;
  let periodType = getPeriodType(vizOptions);
  if (isForecastAccuracy){
    periodType = 'month';
  }
  if (periodType === AXIS_GRANULARITY_TYPES[4].value) {
    const momentStartDateObj = moment(dateRange.startDate);
    const momentEndDateObj = moment(dateRange.endDate);
    const numberOfDays = momentEndDateObj.diff(momentStartDateObj, 'day');
    if ((numberOfDays - 1) < YEAR_DAYS_COUNT) {
      return [];
    }
  }

  if (!_.isEmpty(plotlySliderRange)) {
    const sliderStartDate = new Date(plotlySliderRange[0]);
    const sliderEndDate = new Date(plotlySliderRange[1]);
    return [sliderStartDate, sliderEndDate];
  }

  let startDateIndex, endDateIndex, startDate, endDate, paddingValue, paddingUnit;
  startDate = moment(vizOptions.dateRange.startDate);
  const canShowSliderRange = isShowRangeSlider() && rangeSliderType() == 'all_years';
  const isProjectionEnabled = vizOptions['projectionEnabled'];

  // For week grouping we have set xaxis labes as string, So we get the start
  // and end date's index within xaxis labels to set default slider range.
  if(!_.isEmpty(xAxisLabels)) {
    const dateRange = vizOptions.dateRange;
    const { startDate } = dateRange;
    if (xAxisLabels.length <= 2) {
      return [];
    }
    if(periodType == 'week') {
      startDateIndex = _.indexOf(xAxisLabels,
        getFormattedWeekPeriod(startDate, dateRange, isProjectionEnabled)) - 0.5;
      // Period Week =>  end date index should be XAxis Total Length.
      endDateIndex = _.size(xAxisLabels);
    }
    return [startDateIndex, endDateIndex];
  }

  if(isProjectionEnabled || isForecastingView) {
    const projectionPeriods = getProjectionPeriods(vizOptions);
    const lastProjectionDate  = moment(_.last(projectionPeriods));
    const dateRangeEndDate = moment(vizOptions.dateRange.endDate);
    endDate = canShowSliderRange && !isForecastingView ?
      new Date(vizOptions.dateRange.endDate) :
      moment.max(dateRangeEndDate, lastProjectionDate).add(5, "days").format();
  } else {
    // In combo chart for day wise,If render type is bar then its not visible so added padding.
    if(periodType == 'day'){
      paddingValue = 8;
      paddingUnit = 'hour';
      endDate = new Date(moment(vizOptions.dateRange.endDate).add(paddingValue, paddingUnit));
    } else {
      if(periodType == 'month'){
        let lastPeriodDate = moment(vizOptions.dateRange.endDate);
        endDate = new Date(lastPeriodDate.endOf('month').format('l'));
      } else {
        endDate = new Date(vizOptions.dateRange.endDate);
      }
    }
  }

  switch(periodType){
    case 'year':
      paddingValue = 4;
      paddingUnit = 'month';
      break;
    case 'month':
      paddingValue = 10;
      paddingUnit = 'day';
      break;
    case 'week':
      paddingValue = 1;
      paddingUnit = 'day';
      break;
    case 'day':
      paddingValue = 8;
      paddingUnit = 'hour';
      break;
    default:
      paddingValue = 0;
      paddingUnit = 'day';
  }

  const subtractedStartDate = startDate.subtract(paddingValue, paddingUnit);
  const startDateObj = new Date(moment(subtractedStartDate).format(DATE_TIME_FORMAT));
  return [startDateObj, endDate];
}

export const getTailingDropStartDate = (vizOptions) => {
  const { axisGranularity } = vizOptions;
  const periodType = getPeriodType(vizOptions);
  return axisGranularity == 'quarter' ?
    getTailingDropStartDateQuarter() :
    getConfiguredDataEndDate().startOf(periodType);
}


export const getTailingDropStartDateQuarter = (period = '') => {
  const currentPeriodEndDate = _.isEmpty(period) ?  getConfiguredDataEndDate().startOf('month') : period;
  const quarterMonths = getQuarterMonths();

  const periodMonth = moment(currentPeriodEndDate).month();
  let periodYear = moment(currentPeriodEndDate).year();
  const quarter = quarterMonths[periodMonth];
  let selectedQuarterMonths = _.chain(quarterMonths).
    pickBy((q) => { return q == quarter }).keys().sortBy().value();

  let quarterMonth = getQuarterMonthIndex(currentPeriodEndDate);

  // some time quarter periods may calculate as ['nov', Dec, Jan]
  // so below if condition is for ['nov', Dec, Jan] scenario
  if (_.includes(selectedQuarterMonths, '10') && quarterMonth != '9') {
    periodYear = periodYear - 1;
    quarterMonth = 10;
  } else if (_.includes(selectedQuarterMonths, '11' && quarterMonth != '9')) {
    periodYear = periodYear - 1;
    quarterMonth = 11;
  }
  quarterMonth = quarterMonth + 1;
  return moment(`${periodYear}-${quarterMonth}-01`, 'YYYY-MM-DD');
}


const getQuarterMonthIndex = (tailDropDate) => {
  const quarterMonths = getQuarterMonths();
  const periodMonth = moment(tailDropDate).month();
  const quarter = quarterMonths[periodMonth];
  let selectedQuarterMonths = _.chain(quarterMonths).
    pickBy((q) => { return q == quarter }).keys().sortBy().value();

  return Number(_.last(selectedQuarterMonths));
}

export const getRangePeriods = (startDate, endDate, periodType) => {
  const startDateMmt = _.isObject(startDate) ? startDate : moment(startDate);
  const endDateMmt = _.isObject(endDate) ? endDate : moment(endDate);
  const range = moment().range(startDateMmt, endDateMmt).snapTo(periodType);

  return Array.from(range.by(periodType));
}

export const shouldGroupByWeek = (dateRangeDifference) => {
  return ((dateRangeDifference+1) > MONTH_DAYS_COUNT && (dateRangeDifference+1) < MONTH_GROUPING_DAYS_COUNT)
}

export const getOverriddenDateRangeParams = (chartData, chartOptions, dateRangeParams) => {
  const totalLineData  =_.get(chartData, 'total', []);
  const sortedDateEntries = _.sortBy(totalLineData, (entry) => {
    return moment(entry.period)
  });
  dateRangeParams['dateRange'] = getOverridenDateRange(sortedDateEntries, chartOptions, dateRangeParams);
  return dateRangeParams;
};

export const getOverridenDateRange = (chartDataEntry, chartOptions, dateRangeParams) => {
  const { endDate } = _.cloneDeep(_.get(dateRangeParams, 'dateRange', {}));
  let newDateRange = _.cloneDeep(_.get(dateRangeParams, 'dateRange', {}));
  const lastEntryDate = _.get(_.last(chartDataEntry), 'period');
  const newEndDate = moment(lastEntryDate).isAfter(endDate) ? lastEntryDate : endDate;

  if(isEndDateOverridden(_.get(chartOptions, 'viewOptions', {}))) {
    newDateRange['endDate'] = moment(newEndDate).format("YYYY-MM-DD");
  }
  return newDateRange;
};

export const getChartPeriodData = (chartData) => {
  return _.chain(chartData).
    map('customData').
    compact().
    flatten().
    map((datum) => moment(datum.period).format('YYYY-MM-DD')).
    uniq().
    value();
};

export const getPlotlySliderRange = (range) => {
  if(!range) {
    return [];
  }

  return [formatDate(range[0]), formatDate(range[1])];
}

export const getDateIndexFromWeekData = (weekDates, date) => {
  const newDate = new Date(date);
  const weekBuckets = _.chain(0).
    range(weekDates.length - 1).
    map((index) => {
      return { start: weekDates[index], end: weekDates[index + 1] };
    }).
    value();

  return _.findIndex(weekBuckets, (weekBucket) => {
    let weekStartDate = new Date(weekBucket.start);
    const weekEndDate = new Date(weekBucket.end);
    weekStartDate.setDate(weekStartDate.getDate()- 1);

    return (newDate > weekStartDate && newDate < weekEndDate);
  });
};

export const isMorethanTwoYears = (vizOptions) => {
  const { dateRange, compareYearRanges } = vizOptions;
  const filteredCompareYearRanges = filterCompareYearRanges(compareYearRanges, dateRange);

  return (
    (getDateRangeDifference(dateRange, 'days') > ((YEAR_DAYS_COUNT*2)+1)) ||
    _.size(filteredCompareYearRanges) >= 2
  );
}

const formatDate = (date) => {
  const newDate = new Date(date);
  let month = '' + (newDate.getMonth() + 1);
  let day = '' + newDate.getDate();
  let year = newDate.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const getFeb28Entries = (array) => {
  const feb28Objects = [];
  array.forEach(obj => {
    const objDate = new Date(obj.period);
    const nextDay = new Date(objDate);
    nextDay.setDate(objDate.getDate() + 1);

    if (objDate.getMonth() === 1 && nextDay.getMonth() === 2) {
      feb28Objects.push(obj);
    }
  });

  return feb28Objects;
}

export const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

export const findYearIndex = (years, date) => {
  const targetDate = new Date(date);
  const targetYear = targetDate.getFullYear();

  if (targetYear < years[0]) {
    return -1;
  }

  for (let i = 0; i < years.length; i++) {
    if (targetYear === years[i]) {
      return i;
    } else if (targetYear < years[i]) {
      return i - 1;
    }
  }

  return years.length - 1;
}

export const extractYearsBetweenDates = (startDate, endDate) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const years = [];

  while (startMoment.isSameOrBefore(endMoment, 'year')) {
    years.push(startMoment.year());
    startMoment.add(1, 'year');
  }

  return years;
}

export const isWeekGranularityOnComparision = (options) => {
  const { dateRange, axisGranularity } = options;

  const startDateMmt = moment(dateRange.startDate);
  const endDateMmt = moment(dateRange.endDate);
  const daysDiff = endDateMmt.diff(startDateMmt, 'day');

  return axisGranularity == "week" || (axisGranularity == "default" && shouldGroupByWeek(daysDiff))
}

export const generateYearsList = (yearsRange, dataItems) => {
  const [startYear, endYear] = yearsRange.split('-').map(year => parseInt(year.trim()));
  if (_.isNaN(startYear) || _.isNaN(endYear) || startYear > endYear) {
    return [];
  }

  let endYearValue = endYear
  if (_.isUndefined(endYearValue)){
    endYearValue = startYear
  }
  const yearsArray = [...Array(endYearValue - startYear + 1).keys()].map(year => startYear + year);
  const yearsArrayFromData = _.map(dataItems, item => _.parseInt(moment(item.period).format('YYYY')));
  const yearsList = _.sortBy(_.uniq(_.concat(yearsArray, yearsArrayFromData)));
  return yearsList;
}

export const ticksCountForScreenSize = (vizOptions) => {
  const { viewMode, isForecastingView } = vizOptions;
  const isSmallView = viewMode == VIEW_MODE.SMALL;

  const largeScreenCount = isSmallView? X_AXIS_TICK_CONFIG.MEDIUM :
    isForecastingView ? X_AXIS_TICK_CONFIG.FORECAST_DESKTOP : X_AXIS_TICK_CONFIG.DESKTOP;

  const ticksCount = isMobileView() ? X_AXIS_TICK_CONFIG.MOBILE :
    isMobileOrTablet() ? X_AXIS_TICK_CONFIG.TABLET : largeScreenCount

  return ticksCount;
}
