import _ from 'lodash';
import { prefixText, suffixText, getFormattedNumberValue } from 'helpers/chartDataHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getTickText } from 'helpers/displayNameHelper';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import { getNormalLegends } from '../legendHelper';

const LABEL_TEXT_COUNT = 12;
// Input:
//  formattedData =>
//      [
//        {
//          label: 'High',
//          value: 21.98,
//          scatterEntry: {},
//          plotlyDescription: '<b>High<b>...'
//          isBenchMark:false
//        },
//        {
//          label: 'Low',
//          value: 22.98,
//          scatterEntry: {},
//          plotlyDescription: '<b>High<b>...'
//          isBenchMark:true,
//          benckMarkList:[{
//             label: labelText,
//             value: benchMarkvalue,
//             plotlyDescription: 'hover text',
//             scatterEntry : null,
//             clickMode:false
//           }]
//        }
//        ...
//      ]
export const toPlotlyTraces = (vizOptions, apiData, formattedData) => {
  const { templateId, viewMode, viewEntry, showRange, isEmbed, afterRender } = vizOptions;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const height = getHeight(formattedData, (isSmallView && !isEmbed));
  const scatterData = formattedData.filter(f=> f.isBenchMark === false);
  const benchMarkData = formattedData.filter(f=> f.isBenchMark === true);

  const prefix = prefixText({viewOptions: viewEntry}, false);
  const suffix = suffixText({viewOptions: viewEntry}, false);
  const primaryColor = getConfiguredMetricColor(templateId, 'primary');
  const isSecondsFormat  = isTimeDurationEnabled(viewEntry);

  const legendsItems = getNormalLegends(vizOptions);
  afterRender(legendsItems);

 let scatterPlotlyData = {
    "x": scatterData.map((d) => d.value),
    "y": scatterData.map((d) => d.label),
    "meta": scatterData,
    "name": scatterData.map((d) => d.plotlyDescription),
    "text": scatterData.map((d) => d.plotlyDescription),
    "type":"scatter",
    "mode": 'markers',
    "orientation":"h",
    "hoverinfo":"none",
    "visible":true,
    "marker": { "color": primaryColor, "size": 12 }
  }

  let benchMarkPlotlyData = benchMarkData.map((data) => {
    return {
      "x": data.benckMarkList.map((d) => d.value),
      "y": data.benckMarkList.map((d) => d.label),
      "meta": data.benckMarkList,
      "mode": 'lines+markers+text',
      "hoverinfo": 'none',
      "showlegend": false,
      "type": 'line',
      "line": {
        "color": _.get(data, 'color', '#000000'),
        "width": 1,
        "dash": _.get(data, 'lineType', 'dot')
      },
      "clickmode":false
    }
  });

  let rangeLineTraces =[];
  if(showRange){
    rangeLineTraces = getRangeLines(scatterData, vizOptions);
  }

  const data = [...rangeLineTraces, scatterPlotlyData, ...benchMarkPlotlyData];
  let layout = {
    "shapes": [],
    "showlegend": false,
    "autosize": true,
    "margin": isSmallView ? { l: 10, r: 10, t: 0, b: 10} : {"l":10,"t":10,"b":20,"r":20,"pad":5},
    "bargap":"",
    "dragmode":false,
    "showspikes":false,
    "bargroupgap":"",
    "hovermode": 'closest',
    "height": height,
    "xaxis":{
      "tickprefix": prefix,
      "ticksuffix": isSmallView ? "" : `${suffix}`,
      "showticklabels":true,
      "showgrid":false,
      "zeroline":false,
      "side": 'top',
      "automargin":true,
      "tickfont": {
        "family": isSmallView ? 'Roboto' : '',
        "size": isSmallView ? 8 : "auto"
      }
    },
    "yaxis":{
      "autorange": 'reversed',
      "showticklabels":true,
      "showdividers":true,
      "automargin":true,
      "type":"category",
      "gridwidth": 5
    },
    "annotations":""
  };
  const config = {
    "displayModeBar":false,
    "scrollZoom":false,
    "editable":false,
    "showLink":false,
    "responsive":true
  };

  if(isSmallView) {
    layout.yaxis.tickvals = _.map(scatterData, 'label');
    layout.yaxis.ticktext = getTickText(_.map(scatterData, 'label'), LABEL_TEXT_COUNT);
  } else {
    layout.yaxis.tickvals = _.map(scatterData, 'label');
    layout.yaxis.ticktext = _.map(scatterData, 'tickLabel');
    layout.yaxis.tickfont = { color: "#3F51B5" };
  }

  if(isSecondsFormat){
    const { minXLabel, maxXLabel} = getXLabelsMaxMinValues(formattedData);
    layout.xaxis.tickformat = "%X";
    layout.xaxis.type = "date";
    layout.xaxis.range = [minXLabel, maxXLabel];
  }

  return { data, layout, config };
}

const getRangeLines = (chartData, vizOptions) => {
  const { viewEntry, templateId } = vizOptions;
  return _.map(chartData, (datum) => {
      const yaxisLable = datum.label;
      const minValue = _.get(datum, 'minValue', 0);
      const maxValue = _.get(datum, 'maxValue', 0);
      const formattedMinValue = getFormattedNumberValue(minValue, viewEntry);
      const formattedMaxValue = getFormattedNumberValue(maxValue, viewEntry);
      return (
        {
          "x": [minValue, maxValue],
          "y": [yaxisLable, yaxisLable],
          "meta": datum,
          "mode": 'lines+markers',
          "type": 'scatter',
          "hoverinfo": 'none',
          "marker": {
            "symbol": [142,142],
            "color": getConfiguredMetricColor(templateId, 'range'),
            "size": 10,
            "line": { "width": 4 }
          },
          customdata: ['range', formattedMinValue, formattedMaxValue]
        }
      )
  });
}

const getHeight = (formattedData, isSmallView) => {
  const noofRows =_.size(formattedData.filter(f=> f.isBenchMark !== true));
  if(isSmallView && noofRows >= 5){
    return 230;
  }
  let heightVal = isSmallView ? 40 : 50;
  const height = noofRows == 1 ? 80 : heightVal;

  return (noofRows * height);
}

export const getXLabelsMaxMinValues = (formattedData) => {
  let tickXLabels = [];
  tickXLabels = _.compact(_.map(formattedData, 'value'));
  const benchmarkData = formattedData.filter(f=> f.isBenchMark === true);

  _.each(benchmarkData, (trace) => {
    tickXLabels = tickXLabels.concat(_.map(trace.benckMarkList, 'value'));
  });

  const maxXLabel = _.max(tickXLabels);
  const minXLabel = 0;
  return { tickXLabels, minXLabel, maxXLabel };
}