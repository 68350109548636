import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (wrapperRef, props) =>  {
  const { shouldClickOutSide, onClickOutSide, checkDateDropdown } = props;

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {

      const isClickWithinPickerArrowButton =
        $(event.target).closest(".MuiCalendarPicker-root").length > 0;
      const isClickWithinYearPicker =
        $(event.target).closest(".MuiYearPicker-root").length > 0;
      const isClickWithinDay =
        $(event.target).closest(".PrivatePickersSlideTransition-root").length > 0;
      let isOutsideDateDropDownClick = true;
      if(checkDateDropdown){
        isOutsideDateDropDownClick = (
          !isClickWithinPickerArrowButton &&
          !isClickWithinYearPicker &&
          !isClickWithinDay
        )
      }
      const isClickOutSideElement = shouldClickOutSide(event);
      if (wrapperRef.current && 
        !wrapperRef.current.contains(event.target) &&
        isClickOutSideElement && 
        isOutsideDateDropDownClick) {
        onClickOutSide(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return  null;
}

function OutsideClickHandler(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props);

  return React.cloneElement(props.children, { ref: wrapperRef });
}


OutsideClickHandler.propTypes = {
  onClickOutSide: PropTypes.func,
  shouldClickOutSide: PropTypes.func,
  checkDateDropdown: PropTypes.bool
};

OutsideClickHandler.defaultProps = {
  onClickOutSide: _.noop,
  checkDateDropdown: false,
  shouldClickOutSide: () => true
}

export default OutsideClickHandler;
