import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from 'common/components/CustomTooltip';
import { VIEW_MODE } from 'modules/visualization/constants';
import { ForgeButton } from '@tylertech/forge-react';

const RecenterButton = ({ map, zoom, center, viewMode }) => {
  const [show, setShow] = useState(false);
  const isSmallView = viewMode === VIEW_MODE.SMALL;
  const placement = isSmallView ? 'bottom' : 'left';
  const renderLabel=(<span>Re-center</span>);
  const label = isSmallView ? null : renderLabel;

  const onRecenterClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (map) {
      map.flyTo({ center, zoom });
    }
    setShow(false);
  }

  const mapCenterAndZoomChange = () => {
    setShow(true);
  }
  const debouncedCenterAndZoomChange = _.debounce(mapCenterAndZoomChange, 150);

  const onZoomEnd = () => {
    const isZoomChanged = map.getZoom() != zoom;
    setShow(isZoomChanged);
  }

  useEffect(() => {
    if (map?.on) {
      map.on('dragend', debouncedCenterAndZoomChange);
      map.on('zoomend', onZoomEnd);
    }
  }, [map])

  const renderContent = () => {
    return (
      <>
        <CustomTooltip
          key="map-center-button"
          placement={placement}
          label="Re-center the map to where you started."
        >
          <ForgeButton type="outlined" className="map-forge-btn" onClick={onRecenterClick}>
            <button type="button" className='re-center-btn'>
              <i className="icons-location-arrow"></i>
              {label}
            </button>
          </ForgeButton>
        </CustomTooltip>
      </>
    );
  }

  return show ? renderContent() : null;
}

RecenterButton.defaultProps = {
  center: [],
  map: {},
  zoom: 10
}

RecenterButton.propTypes = {
  center: PropTypes.array,
  map: PropTypes.object,
  viewMode: PropTypes.string,
  zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default React.memo(RecenterButton);
