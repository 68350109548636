import {
  COLLECTION_ID,
  ALL_METRICS_COLLECTION
} from 'appConstants';
import {
  enableCollectionLevelDateFilters,
  configuredDefaultDateType,
  getGlobalFilters,
  formatGlobalFilters,
  getConfig,
  isDemoApplication,
  getTemplateEntries,
  enableTemplateAndMetricPermission,
  getGlobalFiltersFromTemplate,
  getTags,
  getExplorationCardEntries
} from 'common/config/customerConfiguration';
import { getDateFilterParams, getDefaultDateRange } from 'helpers/dateHelper';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import { isGlobalFilterConfigWithinTemplate } from 'common/components/GlobalFilter/helper';
import { getCurrentTemplateEntryById, isDateColumnConfigured } from 'common/config/templateConfiguration';
import {
  getCardEntryByTagId,
  getTemplateAndViewEntryFromBookmark,
  isMyViews
} from 'pages/dashboard/components/CardList/cardHelper';

import {
  getCustomTagMetricEntries
} from 'helpers/templateHelper';
import { getDefaultRadarEntry, getRadarCardId } from '../RadarCards/helper';
import { defaultRadarOptions } from 'reducers/radarReducer';
import { isRestrictedCollection } from '../ManageCollection/collaboratorHelper';

export const getAllMetricCollection = (commonFilters) => {
  let allMetricDateFilters = _.get(commonFilters, 'allMetricDateFilters', {});
  if (_.isEmpty(allMetricDateFilters)) {
    allMetricDateFilters = commonFilters;
  }

  return {
    ...ALL_METRICS_COLLECTION,
    collectionFilters: _.get(commonFilters, 'allMetricFilters', []),
    dateFilters: getDateFilterParams(allMetricDateFilters)
  }
}

export const getCollectionEntry = (collections, currentCollectionId,
  commonFilters, currentCollectionTagId) => {
  const allMetricCollection = getAllMetricCollection(commonFilters);
  const defaultCollections = [allMetricCollection];
  const allCollections = defaultCollections.concat(collections);

  if (_.isNull(currentCollectionId)) {
    if (_.size(collections) > 0) {
      const firstCollection = _.first(collections);
      return isMyViews(firstCollection) ? _.get(collections, '1', allMetricCollection) : firstCollection;
    } else {
      return allMetricCollection;
    }
  }

  const selectedCollectionId = (_.isNaN(Number(currentCollectionId)) ?
    currentCollectionId : Number(currentCollectionId));

  let findCollection = _.find(allCollections, (collection) => {
    return _.isEqual(collection.id, selectedCollectionId);
  });

  if (_.isEmpty(findCollection) && !_.isEmpty(currentCollectionTagId)) {
    findCollection = _.find(allCollections, (collection) => {
      return _.isEqual(collection.bellerophon_tag_id, currentCollectionTagId);
    });
  }

  return (findCollection || allMetricCollection);
};

export const getCollectionCardEntries = (cards, templateEntries, bookmarks = []) => {

  if (_.isEmpty(cards)) {
    return [];
  }
  const explorationCardEntries = getExplorationCardEntries();
  let groupedEntries = {};
  _.each(templateEntries, (templateEntry) => {
    return _.each(templateEntry['view_entries'], (viewEntry) => {
      const id = getCardId(templateEntry, viewEntry);
      groupedEntries[id] = { templateEntry, viewEntry };
    });
  });

  return _.chain(cards).cloneDeep()
    .map((card) => {
      const bookmarkId = _.get(card, 'bookmark_id');
      if (bookmarkId) {
        let bookmarkEntry = _.find(bookmarks, (bookmark) => (bookmark.id == bookmarkId)) || {};
        const { templateEntry, viewEntry } = getTemplateAndViewEntryFromBookmark(bookmarkEntry);
        if (_.isEmpty(viewEntry) && !bookmarkEntry['is_radar']) {
          return null;
        }
        if (bookmarkEntry) {
          return {
            ...bookmarkEntry,
            templateEntry,
            viewEntry,
            cardEntry: card,
            isBookmarkCard: true

          };
        }
      }
      if (_.get(card, 'card_id') === getRadarCardId()) {
        return { ...getDefaultRadarEntry(), cardEntry: card, isRadarCard: true, userCardEntry: card };
      }

      if (_.get(card, 'is_exploration_card')) {
        const explorationCardEntry = _.find(explorationCardEntries, (entry) => {
          return entry['exploration_id'] == card['exploration_id']
        });
        if (!_.isEmpty(explorationCardEntry)) {
          return {
            ...card,
            isExplorationCard: true,
            userCardEntry: {
              ...card,
              isExplorationCard: true,
            },
            explorationCardEntry
          };
        } else {
          return null
        }
      }

      const cardEntry = groupedEntries[card.card_id];

      if (_.isEmpty(cardEntry)) {
        return cardEntry;
      } else {
        return {
          ...cardEntry,
          userCardEntry: card,
        };
      }
    })
    .compact()
    .value();
};

export const getCardId = (templateEntry, viewEntry) => {
  return `${templateEntry.template_id}${_.get(viewEntry, 'view_id', '')}`;
};

export const isDroppableCollection = (dropCollection, currentCollection) => {
  const nonDroppableCollections = [
    COLLECTION_ID.ALL_METRICS,
    COLLECTION_ID.BOOKMARK,
    _.get(currentCollection, 'id'),
  ];

  return (!isRestrictedCollection(dropCollection) &&
    !_.includes(nonDroppableCollections, _.get(dropCollection, 'id'))
  );
};

export const updateCardEntriesOrder = (
  targetCard,
  draggedCard,
  cards,
  isAfter
) => {
  const targetCardEntry = _.get(targetCard, 'userCardEntry', {});
  let draggedCardEntry = _.get(draggedCard, 'userCardEntry', {});

  if(_.get(draggedCard, 'isMetricLibrary', false)) {
    const { templateEntry, viewEntry } = draggedCard;
    draggedCardEntry = _.merge(draggedCardEntry, { card_id:  getCardId(templateEntry, viewEntry) });
  }

  return updateEntriesOrder(
    targetCardEntry,
    draggedCardEntry,
    cards,
    isAfter
  );
};

export const getCurrentTemplateGlobalFilters = (templateEntry, globalFilters) => {
  const globalFilterConfigEntries = getGlobalFiltersFromTemplate(templateEntry);

  const currentTemplateFilters = _.chain(globalFilters).
    map((filterEntry) => {
      let filterItem = _.find(globalFilterConfigEntries, { field: filterEntry.field });
      if (!_.isEmpty(filterItem) && !_.isEmpty(_.get(filterItem, 'column'))) {
        return filterEntry;
      }
    }).compact().value();

  return currentTemplateFilters;
}

export const updateEntriesOrder = (targetEntry, draggedEntry, entries, isAfter) => {
  const isBookmarkCardForTargetEntry = _.get(targetEntry, 'isBookmarkCard');
  const isBookmarkCardForDraggedEntry = _.get(draggedEntry, 'isBookmarkCard');
  const targetCardId = isBookmarkCardForTargetEntry ?
    _.get(targetEntry, 'cardEntry.id') :
    _.get(targetEntry, 'id');
  const draggedCardId = isBookmarkCardForDraggedEntry ?
    _.get(draggedEntry, 'cardEntry.id') :
    _.get(draggedEntry, 'id');
  const newDraggedEntry = isBookmarkCardForDraggedEntry ? _.get(draggedEntry, 'cardEntry') : draggedEntry;
  let targetCardIndex = _.findIndex(entries, { id: targetCardId });
  if (isAfter) {
    targetCardIndex = targetCardIndex + 1;
  }
  const newEntries = _.chain(entries)
    .map((card) => (card.id === draggedCardId ? null : card))
    .value();

  newEntries.splice(targetCardIndex, 0, newDraggedEntry);


  return _.chain(newEntries)
    .compact()
    .map((card, index) => {
      card.sort_order = index;
      return card;
    })
    .value();
}

export const updateRadarCardEntriesOrder = (targetCard, draggedCard, entries, metricFilters, isAfter) => {

  const targetEntry = _.get(targetCard, 'userCardEntry', {});
  const draggedEntry = _.get(draggedCard, 'userCardEntry', {});

  const targetEntryIndex = _.get(targetEntry, 'metricIndex');
  const draggedEntryIndex = _.get(draggedEntry, 'metricIndex');

  const targetCardId = _.get(targetEntry, 'id');
  const draggedCardId = _.get(draggedEntry, 'id');

  const newDraggedEntry = _.find(entries, { id: draggedCardId, metricIndex: draggedEntryIndex });
  const newDraggedFilter = _.find(metricFilters, { id: draggedCardId, metricIndex: draggedEntryIndex });

  let targetCardIndex = _.findIndex(entries, { id: targetCardId, metricIndex: targetEntryIndex });
  if (isAfter) {
    targetCardIndex = targetCardIndex + 1;
  }

  const newEntries = _.chain(entries)
    .map((card) => (card.id === draggedCardId && card.metricIndex == draggedEntryIndex ? null : card))
    .value();
  newEntries.splice(targetCardIndex, 0, newDraggedEntry);

  const newMetricFilters = _.chain(metricFilters)
    .map((filter) => (filter.id === draggedCardId && filter.metricIndex == draggedEntryIndex ? null : filter))
    .value();
  newMetricFilters.splice(targetCardIndex, 0, newDraggedFilter);


  const updatedEntries = _.chain(newEntries)
    .compact()
    .map((card, index) => {
      card.metricIndex = index;
      return card;
    })
    .value();

  const updatedFilters = _.chain(newMetricFilters)
    .compact()
    .map((filter, index) => {
      filter.metricIndex = index;
      return filter;
    })
    .value();

  return {
    "updatedRadarEntries": updatedEntries,
    "updatedRadarFilters": updatedFilters
  }
}

export const shouldShowCommonDateFilter = (cardEntries) => {
  if (enableCollectionLevelDateFilters() && !_.isEmpty(cardEntries)) {
    return _.some(cardEntries, (cardEntry) => {
      const { templateEntry, viewEntry, isBookmarkCard } = cardEntry;
      const isNoneDateSelected = _.get(cardEntry, 'bookmarkOptions.isNoneDateSelected', false);
      if (!isDateColumnConfigured(templateEntry)) {
        return false;
      }

      return ((!isIndependentDateFiltersEnabled(templateEntry) &&
        !isIndependentDateFiltersEnabled(viewEntry)) && !isDateIgnored(viewEntry) &&
        (!isBookmarkCard || isNoneDateSelected))
    });

  } else {
    return false;
  }
}

export const isIndependentDateFiltersEnabled = (entry) => {
  if (!enableCollectionLevelDateFilters()) {
    return false;
  }

  return (_.get(entry, 'enable_date_filter_independently') == 'true')
}

export const isDateIgnored = (viewEntry) => {
  const isDiscreteMode = _.get(viewEntry, 'date_mode') === 'discrete';
  const isStartDateIgnored = _.get(viewEntry, 'start_date_override_and_ignore') === 'true';
  const isEndDateIgnored = _.get(viewEntry, 'end_date_override_and_ignore') === 'true';
  return (isDiscreteMode && isStartDateIgnored && isEndDateIgnored);
}

export const getIndependentDateFiltersConfigs = (cardEntries) => {
  let dateFilterConfigs = [];

  if (enableCollectionLevelDateFilters() && !_.isEmpty(cardEntries)) {
    _.each(cardEntries, (cardEntry) => {
      const { templateEntry, viewEntry } = cardEntry;


      if (isIndependentDateFiltersEnabled(viewEntry) && isDateColumnConfigured(viewEntry)) {
        if (!isDateIgnored(viewEntry)) {
          dateFilterConfigs.push({
            templateEntry,
            viewEntry,
            filterType: 'viewDateFilter',
            name: _.get(viewEntry, 'fields.date_column_label', 'Fiscal Year'),
            id: getCardId(templateEntry, viewEntry)
          });
        }
      } else if (isIndependentDateFiltersEnabled(templateEntry) && isDateColumnConfigured(templateEntry)) {
        if (!isDateIgnored(viewEntry)) {
          dateFilterConfigs.push({
            templateEntry,
            viewEntry,
            name: _.get(templateEntry, 'fields.date_column_label', 'Fiscal Year'),
            filterType: 'templateDateFilter',
            id: getCardId(templateEntry, {})
          });
        }
      }
    })
  }
  return _.uniqBy(dateFilterConfigs, 'id');
}

export const getDateFilterName = (templateEntry, viewEntry) => {
  if (isIndependentDateFiltersEnabled(viewEntry)) {
    return _.get(viewEntry, 'fields.date_column_label', 'Fiscal Year');
  } else if (isIndependentDateFiltersEnabled(templateEntry)) {
    return _.get(templateEntry, 'fields.date_column_label', 'Fiscal Year');
  } else {
    return 'Fiscal Year'
  }
}
export const getAdditionalDateFiltersConfigs = (cardEntries) => {
  let dateFilterConfigs = [];

  if (enableCollectionLevelDateFilters() && !_.isEmpty(cardEntries)) {
    _.each(cardEntries, (cardEntry) => {
      const { templateEntry, viewEntry } = cardEntry;
      const additionalDateFilters = _.get(templateEntry, 'additional_date_filters', []);
      if (!_.isEmpty(additionalDateFilters)) {
        _.each(additionalDateFilters, (filterEntry, index) => {
          if (shouldShowAdditionalDateFilter(templateEntry, viewEntry, filterEntry) &&
            isDateColumnConfigured(templateEntry)) {
            dateFilterConfigs.push({
              templateEntry,
              filterEntry,
              name: _.get(filterEntry, 'name', 'Additional Date'),
              id: `${_.get(templateEntry, 'template_id')}_${index}`
            });
          }
        });
      }
    })
  }
  return _.uniqBy(dateFilterConfigs, 'id');
}

export const getCollectionDateFilters = (collection, id, commonFilters = {}) => {
  let filters = _.get(collection, `dateFilters.independentDateFilters.${id}`, {});

  if (_.isEmpty(filters) && !_.isEmpty(commonFilters)) {
    const defaultFilters = _.get(commonFilters, `independentDateFilters.${id}`, {});
    filters = _.get(commonFilters, `allMetricDateFilters.independentDateFilters.${id}`, defaultFilters);
  }

  return {
    dateRange: _.get(filters, 'dateRange', getDefaultDateRange(relativeDateFilterEntryDefaultState)),
    dateType: _.get(filters, 'dateType', configuredDefaultDateType),
    relativeDateFilterEntry: _.get(filters, 'relativeDateFilterEntry', relativeDateFilterEntryDefaultState)
  };
}

export const getTemplateAdditionalFilters = (templateEntry, filters) => {
  let currentTemplateFilters = {};
  const additionalDateConfigsFilters = _.get(templateEntry, 'additional_date_filters', []);

  _.each(additionalDateConfigsFilters, (filterEntry, index) => {
    const id = `${_.get(templateEntry, 'template_id')}_${index}`;
    if (_.isEmpty(filters[id])) {
      currentTemplateFilters[id] = { dateRange: getDefaultDateRange(relativeDateFilterEntryDefaultState) };
    } else {
      currentTemplateFilters[id] = { dateRange: _.get(filters[id], 'dateRange', {}) };
    }

  });
  return currentTemplateFilters;
}

export const shouldShowAdditionalDateFilter = (templateEntry, viewEntry, dateFilterConfig) => {
  let parentQueries = [];
  parentQueries = _.get(templateEntry, 'parent_queries', []);
  if (_.get(viewEntry, 'ignore_template_parent_query') === 'true') {
    parentQueries = _.get(viewEntry, 'parent_queries');
  } else {
    parentQueries = parentQueries.concat(_.get(viewEntry, 'parent_queries', []))
  }

  return _.some(parentQueries, (query) => {
    return (query.includes(dateFilterConfig['start_date_variable']) ||
      query.includes(dateFilterConfig['end_date_variable']))
  })
}

export const getTemplateCardCommonFilters = (options) => {
  const {
    globalFilters,
    commonFilters,
    collectionEntry,
    cardEntry
  } = options;
  const { templateEntry, viewEntry } = cardEntry;

  let dateFilterId;
  const defaultAdditionalFilters = _.get(commonFilters, 'additionalDateFilters', {});
  const additionalDateFilters = _.get(collectionEntry,
    'dateFilters.additionalDateFilters', defaultAdditionalFilters
  );
  let newCommonFilters = { ...commonFilters };

  if (enableCollectionLevelDateFilters() &&
    (isDateColumnConfigured(templateEntry) || isIndependentDateFiltersEnabled(viewEntry))) {
    newCommonFilters = {
      ...newCommonFilters,
      additionalDateFilters: getTemplateAdditionalFilters(templateEntry, additionalDateFilters)
    }
    if (isIndependentDateFiltersEnabled(viewEntry)) {
      dateFilterId = getCardId(templateEntry, viewEntry);
    } else if (isIndependentDateFiltersEnabled(templateEntry)) {
      dateFilterId = getCardId(templateEntry, {});
    }

    if (dateFilterId) {
      newCommonFilters = {
        ...newCommonFilters,
        ...getCollectionDateFilters(collectionEntry, dateFilterId, commonFilters)
      }
    } else {
      newCommonFilters = {
        ...newCommonFilters,
        ...getDateFilterParams(_.get(collectionEntry, 'dateFilters', {}))
      }
    }
  }
  if (globalFilters) {
    newCommonFilters = {
      ...newCommonFilters,
      globalFilters: globalFilters
    };
  }
  return newCommonFilters;
}

export const getGlobalFilterConfig = (
  cardEntries,
  currentDrilldownTemplateId,
  isDisabledAddFilterButton = false,
  collectionFilters,
  isMyViewCollection = false
) => {
  let globalFilterFieldConfigs = [];
  if (_.isEmpty(cardEntries)) {
    const globalFilters = getGlobalFilters();

    _.each(globalFilters, (globalFilter) => {
      if (isGlobalFilterConfigWithinTemplate(currentDrilldownTemplateId, globalFilter)) {
        globalFilterFieldConfigs.push(globalFilter);
      }
    });

    let uniqGlobalFilterEntries = _.uniqBy(globalFilters, 'field');
    if (isDisabledAddFilterButton) {
      const collectionFilterFields = _.map(collectionFilters, 'field');
      uniqGlobalFilterEntries = _.filter(uniqGlobalFilterEntries, (filterEntry) => {
        return _.includes(collectionFilterFields, filterEntry.field);
      });
    }

    return {
      globalFilterFieldConfigs: uniqGlobalFilterEntries,
      isDisableFilterButton: _.isEmpty(globalFilterFieldConfigs)
    };
  } else {
    _.each(cardEntries, (cardEntry) => {
      const { viewEntry, is_radar, templateEntry } = cardEntry;
      const isRadar = (is_radar || _.get(viewEntry, 'isRadar', false));
      let globalFilterFields = [];
      if (isRadar) {
        globalFilterFields = getGlobalFilterForRadarCard(cardEntry);
      } else if (isMyViewCollection) {
        globalFilterFields = getGlobalFilterEntriesFromBookmark(cardEntry)
      } else {
        globalFilterFields = formatGlobalFilters(getGlobalFiltersFromTemplate(templateEntry));
      }

      _.each(globalFilterFields, (filter) => {
        const { column } = filter;
        if (column) {
          globalFilterFieldConfigs.push(filter);
        }
      });
    });

    return {
      globalFilterFieldConfigs: _.uniqBy(globalFilterFieldConfigs, 'field'),
      isDisableFilterButton: false
    };
  }
}

const getGlobalFilterForRadarCard = (cardEntry) => {
  const { userCardEntry, is_radar } = cardEntry;
  const radarOptions = _.get(userCardEntry, 'radarOptions', defaultRadarOptions());
  const selectedMetricEntries = is_radar ?
    _.get(cardEntry, 'radarOptions.selectedMetricEntries', []) :
    _.get(radarOptions, 'selectedMetricEntries', []);

  let radarGlobalFilters = [];
  _.map(selectedMetricEntries, (metricEntry) => {
    const templateId = _.get(metricEntry, 'template_id', '');
    const templateEntry = getCurrentTemplateEntryById(templateId);
    const globalFilter = formatGlobalFilters(getGlobalFiltersFromTemplate(templateEntry));
    radarGlobalFilters = [...radarGlobalFilters, ...globalFilter]
  });

  return radarGlobalFilters;
}

export const getCollectionFiltersAndEntries = ({
  cardEntries,
  currentDrilldownTemplateId,
  isDisabledAddFilterButton,
  collectionFilters,
  isMyViewCollection = false
}) => {
  const { globalFilterFieldConfigs } = getGlobalFilterConfig(
    cardEntries,
    currentDrilldownTemplateId,
    isDisabledAddFilterButton,
    collectionFilters,
    isMyViewCollection
  );
  if (_.isEmpty(globalFilterFieldConfigs)) {
    return {}
  }
  const matchedCollectionFilters = _.filter(collectionFilters, (filter) => {
    const matchedFilterEntry = _.find(globalFilterFieldConfigs, { field: filter.field });
    return !_.isEmpty(matchedFilterEntry);
  });
  const multiSelectFilterFiledConfig = _.filter(globalFilterFieldConfigs, (filter) => {
    return _.get(filter, 'filter_type') !== 'single-select';
  });

  return {
    globalFilterFieldConfigs,
    multiSelectFilterFiledConfig,
    collectionFilters: matchedCollectionFilters,
  };
}

export const getGlobalFiltersFromCardEntries = (cardEntries, globalFilters) => {
  const { collectionFilters } = getCollectionFiltersAndEntries({
    collectionFilters: globalFilters,
    isDisabledAddFilterButton: false,
    cardEntries
  });

  return collectionFilters || [];
}

export const getGlobalFilterEntriesFromBookmark = (bookmark) => {
  const templateId = _.get(bookmark, 'drilldown.currentDrilldownTemplateId');
  const templateEntry = getCurrentTemplateEntryById(templateId)

  return formatGlobalFilters(getGlobalFiltersFromTemplate(templateEntry));
}

export const collectionTopMovers = (cardEntries) => {
  const cardsWithoutBookmarks = excludeBookmarks(cardEntries);
  if (_.isEmpty(cardsWithoutBookmarks)) {
    return {};
  }

  const configuration = getConfig();
  const insightsConfigs = _.get(configuration, 'insights', {});
  const templateAndViewIds = _.map(cardsWithoutBookmarks, (entry) => {
    return {
      templateId: _.get(entry, ['templateEntry', 'template_id']) + '',
      viewId: _.get(entry, ['viewEntry', 'view_id']) + ''
    }
  });
  return _.chain(insightsConfigs).
    get('insights_entries', []).
    filter((insightsEntry) => {
      return _.find(templateAndViewIds, (entry) => {
        return (entry.templateId == insightsEntry.template_id &&
          entry.viewId == insightsEntry.view_id);
      });
    }).first().value();
}

export const collectionSLAWatch = (cardEntries) => {
  const cardsWithoutBookmarks = excludeBookmarks(cardEntries);
  if (_.isEmpty(cardsWithoutBookmarks)) {
    return {};
  }
  const templateIds = _.compact(_.map(cardsWithoutBookmarks, (entry) =>
    _.get(entry, ['templateEntry', 'template_id']) + ''));

  const slaWatchConfigs = _.get(getConfig(), ['slaWatch', 'entry'], {});
  const slaWatchTemplateId = _.get(slaWatchConfigs, 'template_id', '');
  const allChecks = _.get(slaWatchConfigs, 'allChecks', []);
  return _.includes(templateIds, slaWatchTemplateId + '') && !_.isEmpty(allChecks) ? allChecks : [];
}

const excludeBookmarks = (cardEntries) => {
  return _.reject(cardEntries, 'isBookmarkCard');
}

export const getDefaultCollectionSortOrder = (sortedCollection) => {
  const bellerophonCollections = getTags();

  let firstOrderCollection = [];

  _.forEach(bellerophonCollections, (datum) => {
    const findFilter = _.find(sortedCollection, { tag_id: datum['tag_id'] });
    if (!_.isEmpty(findFilter)) {
      firstOrderCollection.push(findFilter);
    }
  });

  _.forEach(sortedCollection, (datum) => {
    // My views
    if (datum['is_my_view_collection']) {
      firstOrderCollection.unshift(datum);
    }
    const findFilter = _.find(firstOrderCollection, { id: datum['id'] });

    if (_.isEmpty(findFilter)) {
      firstOrderCollection.push(datum);
    }
  });

  return firstOrderCollection;
}

export const getCollectionsWithMyViews = (collections, currentUser) => {
  const currentEmail = _.get(currentUser, 'email', '');
  const isAuthenticated = !_.isEmpty(currentEmail);

  let sortedCollection = _.sortBy(collections, 'id');
  if (!_.isEmpty(currentUser)) {
    let sortOrders = [];
    let isDuplicateSortOrder = false;
    sortedCollection = _.sortBy(collections, (collection) => {
      const { sort_order, id } = collection;
      if (_.includes(sortOrders, sort_order)) {
        isDuplicateSortOrder = true;
      }
      sortOrders.push(sort_order);
      return _.isNil(sort_order) ? id : sort_order;
    });

    if (isDuplicateSortOrder) {
      sortedCollection = _.sortBy(collections, 'id');
    }
  }

  if (!isAuthenticated) {
    sortedCollection = getDefaultCollectionSortOrder(sortedCollection)
  }

  const findByMyViewCollection = _.find(sortedCollection, (collectionItem) => {
    return isMyViews(collectionItem);
  });
  const normalCollections = [];
  const collaborateCollections = [];

  _.each(sortedCollection, (collection) => {
    const { is_collaborate_collection, currentCollectionUser } = collection;
    if (isMyViews(collection)) {
      return;
    } else if (is_collaborate_collection && !_.get(currentCollectionUser, 'is_owner')) {
      collaborateCollections.push(collection);
    } else {
      normalCollections.push(collection);
    }
    return !isMyViews(collection);
  });
  const filteredCollections = [...normalCollections, ...collaborateCollections];

  return (!isDemoApplication() && _.isEmpty(currentUser)) ?
    filteredCollections :
    [findByMyViewCollection, ...filteredCollections];
}

export const getPermittedCollection = (collections, collectionsWithUserMovedCards = {}) => {
  const templateEntries = getTemplateEntries();
  const newCollections = _.map(collections, (collection) => {
    const userMovedCards = _.get(collectionsWithUserMovedCards, [collection.id], []);

    if (!_.isEmpty(collection['bellerophon_tag_id'])) {
      const cardEntries = getCustomTagMetricEntries(
        templateEntries,
        _.get(collection, 'bellerophon_tag_id')
      );
      const newCardEntries = _.chain(cardEntries).
        map((card) => {
          if (_.isEmpty(card['viewEntry'])) {
            return null;
          } else {
            return card;
          }
        }).compact().value();
      return _.isEmpty(newCardEntries) && _.isEmpty(userMovedCards) ? null : collection;
    } else {
      return collection;
    }
  })
  return _.compact(newCollections);
}

export const viewEntriesUserRoles = (cardEntries) => {
  if (!enableTemplateAndMetricPermission()) {
    return [];
  }
  const viewEntriesRoles = _.chain(cardEntries).
    map((card) => {
      if (_.isEmpty(card['viewEntry'])) {
        return null;
      } else {
        return card['viewEntry']['user_list'];
      }
    }).compact().value();

  return _.uniq(_.map(_.flatten(viewEntriesRoles), 'role_id'));
}

export const getCardsTemplateEntryIds = (cardEntries) => {
  const templateIds = _.map(cardEntries, (card) => {
    if (_.isEmpty(card['templateEntry'])) {
      return null;
    } else {
      return card['templateEntry']['template_id'];
    }
  })

  return _.uniq(templateIds);
}

export const isUserCreatedCollection = (collection) => {
  return !_.get(collection, 'is_collaborate_collection', false) &&
    _.isEmpty(_.get(collection, 'bellerophon_tag_id', ''));
}

export const getCollectionWiseMetricListByTagId = (templateEntries) => {
  const bellerophonCollections = getTags();

  const collectionWiseMetrics = _.map(bellerophonCollections, (collection) => {
    let collectionEntry = [];

    const tagId = _.get(collection, 'tag_id', collection['tag_id']);
    const cardEntries = getCustomTagMetricEntries(templateEntries, tagId);

    _.forEach(cardEntries, (entry) => {
      const { templateEntry, viewEntry } = entry;
      const cardEntry = getCardEntryByTagId(templateEntry, viewEntry, tagId);
      collectionEntry.push(cardEntry);
    });

    return {
      name: collection['name'],
      cards: collectionEntry
    };
  });

  return { collectionWiseMetrics: collectionWiseMetrics || []};
};
