import _ from 'lodash';
import { getFormattedValue } from 'helpers/chartDataHelper';

import { getBenchMarkDataValues } from '../../helper';
import { formatScatterChartTickLabel } from '../vizOptionsHelper';
import { getNumericRangeData } from 'helpers/rangeHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { DEFAULT_BENCHMARK_COLOR } from 'appConstants';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsNumericTime } from 'helpers/visualizationHelper';
const SMALL_VIEW_DATA_LIMIT = 5;
// Input:
//        apiData ===> {
//           "entries":[
//              {
//                 "dimension":"Education",
//                 "count":"8337",
//                 "min_count":"Operating",
//                 "max_count":"Personnel",
//              },
//              {
//                 "dimension":"General Government",
//                 "count":"8206",
//                 "min_count":"Operating",
//                 "max_count":"Personnel",
//              }]
//    }

// Output:
//  formattedData =>
//      [
//        {
//          label: 'Education',
//          value: 21.98,
//          scatterEntry: {},
//          plotlyDescription: '<b>Education<b>...'
//          isBenchMark:false
//        },
//        {
//          label: 'General Government',
//          value: 22.98,
//          scatterEntry: {},
//          plotlyDescription: '<b>General Government<b>...'
//          isBenchMark:true,
//          benckMarkList:[{
//             label: Education,
//             value: 2000,
//             plotlyDescription: 'hover text',
//             scatterEntry : null,
//             clickMode:false
//           },
//           {
//             label: General Government,
//             value: 2000,
//             plotlyDescription: 'hover text',
//             scatterEntry : null,
//             clickMode:false
//           }]
//        }
//        ...
//      ]

export const formatScatterChartData = (vizOptions, apiResponse, isUpdateApiCall = false) => {
  let apiRawData = _.get(apiResponse, 'entries', []);
  const {
    viewEntry, secondaryMetricOption, onApiDataLoad, templateId,
    isCurrencyDimensionField, onFormattedDataLoad
  } = vizOptions;
  const isSmallView = vizOptions.viewMode === VIEW_MODE.SMALL;
  const rowCount = _.get(apiResponse, 'row_count', _.size(apiRawData) || 0);
  apiRawData = vizOptions.isNumericDimensionField ? getNumericRangeData(apiResponse) : apiRawData;
  apiRawData = _.cloneDeep(apiRawData);

  const hierarchyData = _.size(apiRawData) === 1 ? _.values(apiRawData)[0] : null;
  if (isUpdateApiCall) {
    onApiDataLoad(rowCount, hierarchyData);
  }
  const primaryColor = getConfiguredMetricColor(templateId, 'primary');
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const dataUnit = timeDurationDataUnit(viewEntry);

  let formattedData = _.map(apiRawData, (responseData) => {
    const { count, min_count, max_count } = responseData;

    const labelName = formatScatterChartTickLabel(
      responseData, secondaryMetricOption, false, templateId, isSmallView,
      isCurrencyDimensionField
    );
    const countValue = isSecondsFormat ? getSecondsAsNumericTime(count, dataUnit) : (count || 0);
    const minCountValue = isSecondsFormat ?
      getSecondsAsNumericTime(min_count, dataUnit) : (Number(min_count) || 0);
    const maxCountValue = isSecondsFormat ?
      getSecondsAsNumericTime(max_count, dataUnit) : (Number(max_count) || 0);

    return {
      label: labelName,
      value: countValue,
      minValue: minCountValue,
      maxValue: maxCountValue,
      tickLabel: formatScatterChartTickLabel(
        responseData, secondaryMetricOption, true, templateId, isSmallView, isCurrencyDimensionField
      ),
      plotlyDescription: getFormattedValue((count || 0), viewEntry),
      scatterEntry: responseData,
      isBenchMark: false,
      color: primaryColor
    };
  });
  formattedData = isSmallView ? getLimitedFormattedData(formattedData) : formattedData;

  onFormattedDataLoad(formattedData);

  // Add Benchmark data
  const benchMarks = !_.isEmpty(formattedData) ? getBenchMarkData(formattedData, vizOptions) : [];
  formattedData = formattedData.concat(benchMarks);

  return formattedData;
};

// const getFilterVisualisationData = (visualizationData, groupByViewOption) => {
//   const viewRowCount = _.get(groupByViewOption,'rowCount', 0);
//   if(_.isNumber(viewRowCount) && _.size(visualizationData) > viewRowCount) {
//       return _.take(visualizationData, viewRowCount);
//     } else {
//       return visualizationData;
//    }
// }

export const getBenchMarkData = (formattedData, vizOptions) => {
  const { benchMarkEntries, viewEntry } = vizOptions;

  const firstData = _.first(formattedData);
  const lastData = _.last(formattedData);
  const benchmarkPointLabels = [firstData.label, lastData.label];
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  let benchMarks = [];

  _.forEach(benchMarkEntries, (benchmark) => {
    const benchamarkValues = getBenchMarkDataValues(benchmark);
    const lineType = _.get(benchmark, 'lineType', 'dot');
    const colorCode = _.get(benchmark, 'color', DEFAULT_BENCHMARK_COLOR) || DEFAULT_BENCHMARK_COLOR;

    _.forEach(benchamarkValues, (benchMarkvalue) => {
      const commonValue = isSecondsFormat ?
        getSecondsAsNumericTime(benchMarkvalue) : benchMarkvalue;
      let benchMarkData = {
        isBenchMark: true,
        benckMarkList: [],
        lineType,
        color: colorCode
      }

      _.forEach(benchmarkPointLabels, (labelText) => {
        let benchMarkObject = {
          label: labelText,
          value: commonValue,
          plotlyDescription: _.get(benchmark, 'name', ''),
          scatterEntry: { count: benchMarkvalue, isSecondsFormat },
          clickMode: false
        }
        benchMarkData.benckMarkList.push(benchMarkObject);
      });
      benchMarks.push(benchMarkData);
    })
  });

  return benchMarks;
}

const getLimitedFormattedData = (formattedData) => {
  return _.chain(formattedData).
    take(SMALL_VIEW_DATA_LIMIT).
    value();
}
