import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getAbbreviatedStringValue } from 'helpers/numberHelper';
import DrilldownPath from './DrilldownPath';

class CompareEntityResult extends Component {
  renderVarianceTableRows(varianceItem) {
    const { selectedDimensionEntity } = this.props;
    if(_.isEmpty(varianceItem)) {
      return null;
    }

    const { value, item, entities } = varianceItem;
    const { band_avg, band_max, band_min, sample_size, total_sample_size, score } = item;
    
    return (
      <tr>
        <td>{_.get(item, selectedDimensionEntity.field, '')}</td>
        <td>
          <DrilldownPath 
            fieldValues={_.omit(entities, selectedDimensionEntity.field)} 
            dimensionField={selectedDimensionEntity.field} 
          />
        </td>
        <td className="text-right">{getAbbreviatedStringValue(value, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(band_min, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(band_avg, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(band_max, 2, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(sample_size, 0, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(total_sample_size, 0, true, true)}</td>
        <td className="text-right">{getAbbreviatedStringValue(score, 0, true, true)}</td>
      </tr>
    );
  }

  render() {
    const { selectedDimensionEntity, varianceData } = this.props;
    if(_.isEmpty(varianceData)) {
      return null;
    }

    return (
      <table className="table table-bordered table-sm">
        <thead>
          <tr>
            <th rowSpan="2" className="text-center">{_.get(selectedDimensionEntity, 'name', '')}</th>
            <th rowSpan="2" className="text-center">Path</th>
            <th rowSpan="2" className="text-center">Value</th>
            <th colSpan="6" className="text-center">Band</th>
          </tr>
          <tr>
            <th className="text-center">Min</th>
            <th className="text-center">Avg</th>
            <th className="text-center">Max</th>
            <th className="text-center">Sample Size</th>
            <th className="text-center">Total SS</th>
            <th className="text-center">Score</th>
          </tr>
        </thead>
        <tbody>
          {_.map(varianceData, (varianceItem) => this.renderVarianceTableRows(varianceItem))}
        </tbody>
      </table>
    );
  }
}

CompareEntityResult.propTypes = {
  selectedDimensionEntity: PropTypes.object,
  varianceData: PropTypes.array.isRequired,
}

export default CompareEntityResult;
