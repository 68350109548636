import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import { getPermissionDatasetData } from 'common/api/adminApi';
import { isRowLevelPermissionEnabled } from 'common/config/customerConfiguration';
import { getPermissionFiltersForTemplates } from 'modules/Administration/permissionHelper';
import { updatePermissionFilters } from 'actions/drilldownActions';
import PermissionFilterLabel from './PermissionFilterLabel';

const PermissionFilter = (props) => {
  const { templateIds, showPermissionContainer } = props;
  const currentUser = useSelector(state => _.get(state.currentUser, 'user', {}));
  const userEmail = _.get(currentUser, 'email', '');
  const [permissionData, setPermissionData] = useState({});
  const [permissionFilers, setPermissionFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPermissionData();
  }, [])

  useEffect(() => {
    updateAndDispatchPermissionFilters();
  }, [_.flatten([templateIds, isLoading]).toString()])

  const fetchPermissionData = () => {
    if (isRowLevelPermissionEnabled()) {
      getPermissionDatasetData(true)
        .then((response) => {
          setPermissionData(response);
          setIsLoading(false);
        })
    }
  }

  const updateAndDispatchPermissionFilters = () => {
    let permissionFiltersForDataset = [];
    if (!_.isEmpty(templateIds) && !isLoading) {
      permissionFiltersForDataset = getPermissionFiltersForTemplates(
        permissionData, templateIds, userEmail
      );
    }
    setPermissionFilters(permissionFiltersForDataset);
    dispatch(updatePermissionFilters(permissionFiltersForDataset));
  }

  const renderPermissionContainer = (filterContents) => {

    const filterClassNames = classNames('permission-dataset-filters', {
      'mr-2': showPermissionContainer
    });

    return (
      <div className={filterClassNames}>
        {filterContents}
      </div>
    );
  }

  const renderPermissionFilters = () => {
    if (_.isEmpty(permissionFilers)) {
      return null;
    }

    const filterContents = _.map(permissionFilers, (values, field) => {
      return (
        // <div className='permission-filters'>
        //   <div className="filter-chip">
        //     <div className="field-title filter-chip-values pr-2">
        //       <i className="icons-np-key" />
        //       <span className="text-capitalize">{field}</span>is<b>{values.join(' or ')}</b>
        //     </div>
        //   </div>
        // </div>
        <div className='d-flex flex-wrap gap-8 mb-2'>
          <PermissionFilterLabel
            permissionLabels={values}
            labelField={field} />
        </div>
      );
    });

    return renderPermissionContainer(filterContents);
  }

  return renderPermissionFilters();
}

PermissionFilter.propTypes = {
  templateIds: PropTypes.array,
  currentUser: PropTypes.object,
  showPermissionContainer: PropTypes.bool
};

PermissionFilter.defaultProps = {
  showPermissionContainer: false
}

export default PermissionFilter;
