import { getNullValueLabel  } from 'common/config/templateConfiguration';
import moment from 'moment';
import { DEFAULT_BENCHMARK_COLOR } from 'appConstants';
import { getPeriodType, isYearOnYear } from '../vizOptionsHelper';

export const toPlotlyTraces = (vizOptions, formattedData, lineTraces, sliderRange = [] ) => {
  const { benchMarkEntries, templateId } = vizOptions;
  const nullValueLabel = getNullValueLabel(templateId);
  const chartDataLables = _.chain(lineTraces).
    filter((lineTrace) => !_.isEmpty(lineTrace.x)).map('x')
    .flatten().uniq().value();

  const isYearPeriod  = isYearOnYear(vizOptions) && getPeriodType(vizOptions) == 'year';   
  const lastValue = new Date(_.last(chartDataLables));
  let lastBenchmarkValue = _.last(chartDataLables);
  if(_.isDate(lastValue) && _.toString(lastValue) !== "Invalid Date" && !isYearPeriod){
    lastBenchmarkValue = !_.isEmpty(sliderRange) ? _.last(sliderRange) : lastBenchmarkValue;
    const firstDate = moment(lastBenchmarkValue, 'YYYY-MM-DD h:m').startOf('month').format('YYYY-MM-DD hh:mm')
    lastBenchmarkValue = moment(firstDate).format();
  }

  let x = _.map(chartDataLables, (label) => {
    return label || nullValueLabel
  });

  if(!_.isEqual(lastBenchmarkValue,  _.last(chartDataLables))){
    x.push(lastBenchmarkValue)
  }

  const currentBenchmarks = _.compact(_.map(_.get(formattedData, 'benchMarks', []), (benchMark) => {
    const benchmarkField = _.get(benchMark, 'configEntry.field');
    if(benchMarkEntries.some(findBenchmark =>  _.get(findBenchmark, 'field') === benchmarkField)) {
      return benchMark;
    }
  }));

  let benchMarkLines = [];
  _.forEach(currentBenchmarks, (currentBenchmark) => {
    const benchMarkline =  _.chain(currentBenchmark).
      get('values').
      map((value) => {
        const formattedName = `${_.get(currentBenchmark.configEntry, 'name', '')}`;
        return {
          x,
          y: getYaxisValues(Number(value), x),
          mode: 'lines+text',
          showlegend: false,
          hoverinfo: 'none',
          customdata: [['benchMark', formattedName]],
          type: 'line',
          line: {
            color: _.get(currentBenchmark.configEntry, 'color') || DEFAULT_BENCHMARK_COLOR,
            width: 1,
            dash: _.get(currentBenchmark.configEntry, 'lineType', 'dot')
          }
        }
      }).
      value();
    benchMarkLines = benchMarkLines.concat(benchMarkline);
  })

  return benchMarkLines;
};

const getYaxisValues = (yValue, xAxis) => {
 let yAxisValues = [];
  _.forEach(xAxis, () => {
    yAxisValues.push(yValue)
 });
 return yAxisValues;
}