import { COLLECTION_ID } from "appConstants";
import { getTemplateEntries } from "common/config/customerConfiguration";
import { isMyViews } from "pages/dashboard/components/CardList/cardHelper";
import { getCardEntriesWithRadarCard } from "pages/dashboard/components/RadarCards/helper";
import { getCardId } from "pages/embed/helper";
import { getAllMetricEntries } from "./templateHelper";

export const shouldShowNewLabel= (collection, collectionWithNewLabelCards) => {
  let showNewLabel = false;
  const isAllMetrics = _.isEqual(collection.id, COLLECTION_ID.ALL_METRICS);

  if(isAllMetrics) {
    const cardIds = _.chain(collectionWithNewLabelCards).
      filter((_collectionCardIds, category) => category != 'all_metrics').
      flatMapDeep(_.flatten).
      uniqBy('template_card_id').
      map('template_card_id').
      value();
    const allMetricCards = _.get(collectionWithNewLabelCards, 'all_metrics', []);

    const templateEntries = getTemplateEntries();
    const cardEntries = getAllMetricEntries(templateEntries);
    const cardEntriesWithRadarCard = getCardEntriesWithRadarCard(cardEntries, true);
    _.each(cardEntriesWithRadarCard, (cardEntry) =>  {
      const { templateEntry, viewEntry } = cardEntry;
      const cardId = getCardId(templateEntry, viewEntry);

      if(isNewMetricLabelCard(viewEntry)) {
        showNewLabel = !_.includes(cardIds, cardId);
      }
    });
    if(_.isEmpty(allMetricCards)) {
      showNewLabel = false;
    }
  } else if(!isAllMetrics && !isMyViews(collection)){
    const collectionNewCards = _.flattenDeep(_.get(collectionWithNewLabelCards, collection.id, []));
    showNewLabel = !_.isEmpty(collectionNewCards);
  }

  return showNewLabel;
}

export const isNewMetricLabelCard = (viewEntry) => {
  return JSON.parse(_.get(viewEntry, 'is_new_card', 'false'));
}
