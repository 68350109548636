import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from 'common/components/CustomButton';
import { updateCollection } from 'common/api/collectionApi';
import { updateIsLoading, updateCollaborateCollection } from './collaboratorActions';

const CovertButton = () => {
  const collection = useSelector((store) => store.collection);
  const currentUser = useSelector((store) => store.currentUser);
  const dispatch = useDispatch();

  const onUpdateCollection = () => {
    if (!_.isEmpty(currentUser)) {
      collection['is_collaborate_collection'] = true;
      collection['manage_collaborate_updated_at'] = (new Date()).toISOString();
      dispatch(updateIsLoading(true));

      updateCollection(collection)
        .then((response) => response.json())
        .then((response) => {
          dispatch(updateCollaborateCollection(response));
          dispatch(updateIsLoading(false));
        });
    }
  }

  return (
    <div className="collaborator-content">
      <p className='text-center'>
        This is a personal collection.
        Do you want to convert it to a collection for others? Once converted this action cannot be undone.
      </p>
      <div className='text-center'>
        <CustomButton onClick={onUpdateCollection} label='Convert' className="mt-3" />
      </div>
    </div>
  );
}

CovertButton.propTypes = {
};

export default CovertButton;
