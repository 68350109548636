import abortableFetch from 'common/abortableFetch';
import { buildQueryString } from 'helpers/HttpHelper';

export const getTargetEntry = (params) => {
  const apiUrl = "/api/target_entry/domain_wise.json";
  return (`${apiUrl}?${buildQueryString(params)}`);
};

export const createTargetEntry = (params) => {
  const targetEntryOptions =  {
      method: 'POST',
      body: JSON.stringify(params),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/target_entry.json"
  return abortableFetch(apiUrl, targetEntryOptions);
};

export const updateTargetEntry = (targetEntryId, params) => {
  const targetEntryOptions =  {
      method: 'PUT',
      body: JSON.stringify(params),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = `/api/target_entry/${targetEntryId}.json`
  return abortableFetch(apiUrl, targetEntryOptions);
};

export const deleteTargetEntry = (targeEntryId) => {
  const targetEntryOptions =  {
      method: 'DELETE',
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = `/api/target_entry/${targeEntryId}.json`
  return abortableFetch(apiUrl, targetEntryOptions);
};
