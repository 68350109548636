import {
  UPDATE_RADAR_OPTIONS,
  UPDATE_DEFAULT_RADAR_OPTIONS,
  UPDATE_CURRENT_DATA_RANGE_OPTIONS,
  UPDATE_PREVIOUS_DATA_RANGE_OPTIONS,
  UPDATE_RADAR_SHAPE_AREA_ENTRY,
  UPDATE_RADAR_SELECTED_TRACTS,
  UPDATE_RADAR_SELECTED_METRIC_ENTRIES,
  UPDATE_RADAR_SELECTED_MAP_METRIC,
  UPDATE_RADAR_METRIC_FILTERS,
  UPDATE_RADAR_TOGGLE_AREA,
  UPDATE_SELECTED_AREA_NAME,
  UPDATE_RADAR_METRIC_GLOBAL_FILTERS
} from 'actions/radarActions';
import {
  getDefaultRadarMetricEntries,
  getDefaultRadarAreaEntry,
  configuredDefaultDateType
} from 'common/config/customerConfiguration';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import { DATE_COMPARISON_TYPE_OPTIONS, SHAPE_AREA_TABS } from 'appConstants';
import { getMetricDefaultFilters } from 'pages/Radar/radarHelper';

const defaultRadarFiltersForMetrics = (metricEntries) => {
  let filters = [];
  _.each(metricEntries, (metricEntry, index) => {
    let metricFilters = {}
    metricFilters['id'] = _.get(metricEntry, 'id');
    metricFilters['metricIndex'] = index;
    metricFilters['filters'] = getMetricDefaultFilters(metricEntry);
    filters.push(metricFilters);
  });
  return filters;
}

export const defaultRadarOptions = (radarOptions = {}) => {
  const defaultMetrics = getDefaultRadarMetricEntries();
  const selectAreaName = _.get(radarOptions, 'selectAreaName', SHAPE_AREA_TABS[0].name);
  const comparisonAreaName = _.get(radarOptions, 'comparisonAreaName', SHAPE_AREA_TABS[1].name);

  return{
    selectedMetricEntries: defaultMetrics,
    selectedShapeAreaEntry: getDefaultRadarAreaEntry(),
    bookmarkId: '',
    currentDateRangeOptions: {
      dateRange: {startDate: '2021-01-01', endDate: '2021-12-31'},
      dateType: "custom_range",
      relativeDateFilterEntry: relativeDateFilterEntryDefaultState
    },
    previousDateRangeOptions: {
      dateRange: { startDate: '2020-01-01', endDate: '2020-12-31' },

      dateType: configuredDefaultDateType || 'custom_range',
      comparisonPeriod: DATE_COMPARISON_TYPE_OPTIONS[1].value
    },
    metricFilters: defaultRadarFiltersForMetrics(defaultMetrics),
    metricGlobalFilters: [],
    selectedTracts: [],
    comparisonTracts: [],
    selectAreaName,
    comparisonAreaName,
    selectedMapMetric: defaultMetrics[0],
    isCheckedComparisonArea: false,
    isCheckedUnSelectedArea: true
  }
}

export default function(state = defaultRadarOptions(), action) {
  switch (action.type) {
    case UPDATE_RADAR_OPTIONS:

      return {
        ...state,
        ..._.get(action, 'radarOptions', {})
      }
    case UPDATE_DEFAULT_RADAR_OPTIONS:
      return {
        ...state,
        ...defaultRadarOptions(state)
      }
    case UPDATE_CURRENT_DATA_RANGE_OPTIONS:
      return {
        ...state,
        currentDateRangeOptions:_.get(action, 'dateRangeOptions', {})
      }
    case UPDATE_PREVIOUS_DATA_RANGE_OPTIONS:
      return {
        ...state,
        previousDateRangeOptions:_.get(action, 'dateRangeOptions', {})
      }
    case UPDATE_RADAR_SHAPE_AREA_ENTRY:
      return {
        ...state,
        selectedShapeAreaEntry:_.get(action, 'selectedShapeAreaEntry', {})
      }
    case UPDATE_RADAR_SELECTED_TRACTS:
      const shapeAreaType = _.get(action, 'shapeAreaType')
      return {
        ...state,
        [shapeAreaType]:_.get(action, 'selectedTracts', {})
      }
    case UPDATE_RADAR_SELECTED_METRIC_ENTRIES:
      return {
        ...state,
        selectedMetricEntries:_.get(action, 'selectedMetricEntries', {})
      }
    case UPDATE_RADAR_SELECTED_MAP_METRIC:
      return {
        ...state,
        selectedMapMetric:_.get(action, 'selectedMapMetric', {})
      }
    case UPDATE_RADAR_METRIC_FILTERS:
      return {
        ...state,
        metricFilters:_.get(action, 'metricFilters', {})
      }

    case UPDATE_RADAR_METRIC_GLOBAL_FILTERS:
        return {
          ...state,
          metricGlobalFilters:_.get(action, 'metricGlobalFilters', [])
        }  

    case UPDATE_SELECTED_AREA_NAME:
      const { selectedKey, selectedAreaName } = action;
      return {
        ...state,
        [selectedKey]: selectedAreaName
      }
    case UPDATE_RADAR_TOGGLE_AREA:
      const { key, isChecked } = action;

      return {
        ...state,
        [key]: isChecked
      }

    default:
      return state;
  }
}
