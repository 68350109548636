import _ from 'lodash';
import {
  getTemplateEntries,
  isTemplateLevelMapSettingEnable,
  getConfig,
  getAdvanceSearchTemplates,
  appLevelShapeDatasetEntries,
  useAppLevelShapeConfigs,
  appLevelShapeOutlineEntries,
  appLevelShapeFlyoutEntries,
  getCensusEntry
} from './customerConfiguration';
import { getDistributionBucketEntry } from './visualizationConfiguration';
import { isTemplateLevelConfigEnabled } from './viewConfiguration';
import {
  getDefaultDimensionColumn
} from 'helpers/visualizationHelper';
import {
  EMPTY_VALUE,
  DEFAULT_CHOROPLETH_DATA_CLASSES,
  VIEW_MODE
} from 'modules/visualization/constants';
import {
  GEO_LOCATION_COLUMN_TYPES,
  GROUP_BY_NONE_ENTRY,
  BENCHMARK_LINE_TYPE,
  URL_COLUMN_TYPES,
  STRING_TYPES_FIELD,
  NUMBER_TYPES_FIELD,
  DEFAULT_DISPLAY_ORDER,
  SEARCH_METHOD,
  VISUALIZATION_TYPES,
  SNAPSHOT_VISUALIZATION_TYPES,
  DIMENSION_BY_NONE_ENTRY
} from 'appConstants';

export const getCurrentTemplateEntry = (templateId) => {
  return _.cloneDeep(_.find(getTemplateEntries(), (entry) => {
    return (_.isEqual((entry['template_id'] + "").toString(), (templateId + "").toString()));
  })) || {};
}

export const getCurrentTemplateEntryById = (templateId) => {
  return _.cloneDeep(_.find(getTemplateEntries(), (entry) => {
    return _.isEqual(entry['template_id']+'', templateId+'');
  })) || {};
}

export const getAdvancedSearchTemplateEntryById = (templateId) => {
  return _.cloneDeep(_.find(getAdvanceSearchTemplates(), (entry) => {
    return _.isEqual(entry['template_id']+'', templateId+'');
  })) || {};
}

export const getCurrentTemplateEntryByName = (templateName) => {
  return _.find(getTemplateEntries(), (entry) => _.isEqual(entry['name']+'', templateName+'')) || {};
}

export const getCurrentViewEntry = (templateId, viewId) => {
  const templateEntry = getCurrentTemplateEntryById(templateId);
  const viewEntries = getViewEntries(_.get(templateEntry, 'template_id'));
  return (_.find(viewEntries, {view_id: viewId}) || {});
}

export const getViewEntries = (templateId) => {
  let templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry,'view_entries', []);
}

export const getViewEntryByField = (templateId, viewEntryField) => {
  return _.find(getViewEntries(templateId), {field: viewEntryField});
}

export const getNonGeoLocationColumn = (entries) => {
  return _.filter(entries, (entry) => {
    const { column } = entry;
    return !_.includes(GEO_LOCATION_COLUMN_TYPES.concat(URL_COLUMN_TYPES), column);
  });
}

export const getDimensionEntries = (templateId, currentVisualizationType, currentSnapshotView) => {
  let templateEntry = getCurrentTemplateEntry(templateId);
  let dimensionEntries = _.get(templateEntry,'dimension_entries', []);

  dimensionEntries =  getNonGeoLocationColumn(dimensionEntries);
  if (!_.isEmpty(dimensionEntries) &&
  currentVisualizationType == VISUALIZATION_TYPES.SNAPSHOT.type &&
  currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type
  ){
    dimensionEntries.push(DIMENSION_BY_NONE_ENTRY);
  }
  return dimensionEntries;
}

export const getGenericFilterEntries = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  let genericFilterEntries = []

  const groupByEntries = getGroupByEntries(templateId, true)
  genericFilterEntries.push(groupByEntries)

  const dimensionsEntries = getDimensionEntries(templateId);
  const dimensionEntriesWithoutDuplicate = _.differenceBy(
    dimensionsEntries, _.flatten(genericFilterEntries) , 'column');
  genericFilterEntries.push(dimensionEntriesWithoutDuplicate)

  const filterByEntries =  _.get(templateEntry,'filter_by_entries', []);
  const filterEntriesWithoutDuplicate = _.differenceBy(
    filterByEntries, _.flatten(genericFilterEntries) , 'column');
  genericFilterEntries.push(filterEntriesWithoutDuplicate)

  return _.uniqBy(_.flatten(genericFilterEntries), 'column')
}

export const getGroupByEntries = (templateId, withoutNone = false) => {
  let templateEntry = getCurrentTemplateEntry(templateId);
  let groupByEntries = _.chain(templateEntry).
    get('group_by_entries', []).
    filter((groupByEntry) => {
      const { column } = groupByEntry;
      return !_.includes(GEO_LOCATION_COLUMN_TYPES.concat(URL_COLUMN_TYPES), column);
    }).
    value();
  if (!_.isEmpty(groupByEntries) && !withoutNone){
    groupByEntries.push(GROUP_BY_NONE_ENTRY);
  }
  return groupByEntries;
}

export const getMapCenter = (templateId) => {
  const config = getMapConfig(templateId);
  const centerLng = _.get(config, 'map.centerLng');
  const centerLat = _.get(config, 'map.centerLat');
  if (_.isEmpty(centerLng) || _.isEmpty(centerLat)) {
    return [];
  }

  return [parseFloat(centerLng || 0), parseFloat(centerLat || 0)];
};

export const showGeoCoderControl = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return JSON.parse(_.get(templateEntry, 'map.show_geocoder_control', true));
};

export const getNullValueLabel = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'label_for_null_values', EMPTY_VALUE);
};

export const getCustomColorPalette = (templateId, viewEntry) => {
  const config = getMapConfig(templateId);
  const templateCustomPalette = _.get(config, 'map.choropleth.color_palette', 'default');
  const viewEntryCustomPalette = _.get(viewEntry, 'visualization.map.choropleth.color_palette');
  const isTemplateDefault = isTemplateLevelConfigEnabled(viewEntry, 'color_and_data_classes_type');

  return (isTemplateDefault ? templateCustomPalette : viewEntryCustomPalette);
};

export const getChoroplethDataClasses = (templateId, viewEntry) => {
  const config = getMapConfig(templateId);
  const templateDataClasses = Number(
    _.get(config, 'map.choropleth.data_classes', DEFAULT_CHOROPLETH_DATA_CLASSES)
  );
  const viewEntryDataClasses = _.get(viewEntry, 'visualization.map.choropleth.data_classes')
  const isTemplateDefault = isTemplateLevelConfigEnabled(viewEntry, 'color_and_data_classes_type');

  return (isTemplateDefault ? templateDataClasses : Number(viewEntryDataClasses));
};

export const getChoroplethClassificationMethod = (templateId, viewEntry) => {
  const isTemplateDefault = isTemplateLevelConfigEnabled(viewEntry, 'classification_method_type');
  const config = getMapConfig(templateId);
  const templateEntryClassificationMethod = _.get(
    config,
    'map.choropleth.classification_method',
    DEFAULT_CHOROPLETH_DATA_CLASSES
  )
  const viewEntryClassificationMethod = _.get(
    viewEntry,
    'visualization.map.choropleth.classification_method'
  );

  if(isTemplateLevelMapSettingEnable()) {
    return (isTemplateDefault ? templateEntryClassificationMethod : viewEntryClassificationMethod);
  } else {
    return (isTemplateDefault ?
      _.get(getConfig(), 'map.choropleth.classification_method') :
      viewEntryClassificationMethod
    );
  }
};

export const shouldSimplifyChoroplethLegends = (templateId) => {
  const templateConfig = getMapConfig(templateId);
  const templateSimplifyMethod = _.get(templateConfig, 'map.choropleth.simplify_legend', 'false') == 'true'


  if(isTemplateLevelMapSettingEnable()) {
    return templateSimplifyMethod;
  } else {
    return _.get(getConfig(), 'map.choropleth.simplify_legend', 'false') == 'true'
  }
};

export const isMultipleAddressColumnConfigured = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return  _.get(templateEntry, 'map.is_multiple_address_column', 'false') == 'true';
}

export const isAddressConfigured = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return (isTemplateLevelMapSettingEnable() &&
    _.get(templateEntry, 'map.is_address_configured', 'false') == 'true');
}

export const getChoroplethMidpoint = (templateId, viewEntry) => {
  const isTemplateDefault = isTemplateLevelConfigEnabled(viewEntry, 'classification_method_type');
  const templateEntry = getCurrentTemplateEntry(templateId);
  const templateEntryMidpoint = _.get(templateEntry, 'map.choropleth.midpoint');
  const viewEntryMidpoint = _.get(viewEntry, 'visualization.map.choropleth.midpoint');
  if(isTemplateLevelMapSettingEnable()) {
    return (isTemplateDefault ? templateEntryMidpoint : viewEntryMidpoint);
  } else {
    return (isTemplateDefault ? _.get(getConfig(), 'map.choropleth.midpoint') : viewEntryMidpoint);
  }
};

export const getMapzoom = (templateId, viewMode = "") => {
  const config = getMapConfig(templateId);
  const mapZoom = _.get(config, 'map.zoom');
  const metricTileMapZoom = _.get(config, 'map.metric_tile_map_zoom');
  const miniMapZoom = metricTileMapZoom || _.get(config, 'map.mini_map_zoom', mapZoom) - 0.6;
  const isSmallView = viewMode === VIEW_MODE.SMALL;

  if(isSmallView){
    return miniMapZoom;
  } else {
    return _.isEmpty(mapZoom) ? null : parseFloat(mapZoom || 10);
  }
};

export const isMapCompareOn = (templateId) => {
  let templateEntry = getCurrentTemplateEntry(templateId);

  return _.get(templateEntry, 'map.show_map_compare', true);
};

export const getShapeFilterMapzoom = (templateId) => {
  const config = getMapConfig(templateId);

  return parseFloat(_.get(config, 'map.mini_map_zoom', 10));
};

export const getMapStyleEntries = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);

  if(isTemplateLevelMapSettingEnable() && !_.isEmpty(templateEntry)) {
    return _.get(templateEntry, 'map.style_entries', []);
  } else {
    return JSON.parse(_.get(getConfig(), 'map.style_entries', []));
  }
};

export const shouldShowMiniMap = (templateId) => {
  const config = getMapConfig(templateId);

  return _.get(config, 'map.show_mini_map_in_analysis_view') == 'true';
};

export const isEnableEsriBaseMap = (templateId) => {
  const config = getMapConfig(templateId);

  return JSON.parse(_.get(config, 'map.enable_esri_base_map', false));
};

export const getEsriBaseMapLink = (templateId) => {
  const config = getMapConfig(templateId);

  return _.get(config, 'map.esri_base_map_link', '');
};

export const getShapeOverlayDatasetEntries = (templateId) => {
  if (useAppLevelShapeConfigs){
    return appLevelShapeOutlineEntries;
  }else{
    const templateEntry = getCurrentTemplateEntry(templateId);
    return _.get(templateEntry, 'shape_outline_dataset_entries', []);
  }
};

export const getMapIncidentStyle = () => {
  return _.get(getTemplateEntries(), 'incident_style_entries', []);
};

export const getMapFlyoutEntries = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);

  return _.get(templateEntry,'flyout_entries', []);
};

export const getShapeMapFlyoutEntries = (templateId, shapeId) => {
  if(useAppLevelShapeConfigs){
    return _.get(appLevelShapeFlyoutEntries, shapeId, []);
  }else{
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, ['shape_flyout_entries', shapeId], []);
  }
};

export const getShapesOutlineWidth = (templateId) => {
  const mapEntries = getMapEntries(templateId);

  return Number(_.get(mapEntries, 'shapes_outline_width', 2));
};

export const getShapesOutlineHighlightWidth = (templateId) => {
  const mapEntries = getMapEntries(templateId);

  return Number(_.get(mapEntries, 'shapes_outline_highlight_width', 4));
};

export const getMiniMapShapesOutlineColor = (templateId) => {
  const shapeDatasetEntries = getShapeDatasetEntries(templateId);

  return _.get(shapeDatasetEntries[0], 'mini_map_border_color', '#004cff');
};

export const getMapEntries = (templateId) => {
  const config = getMapConfig(templateId);
  return _.get(config, 'map', {});
};

export const getGroupBySortOrder = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'group_by_entry.display_order', DEFAULT_DISPLAY_ORDER);
}

export const getDimensionSortOrder = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'dimension_entry.display_order', DEFAULT_DISPLAY_ORDER);
}

export const getMetricFilterSortOrder = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'metric_filter.display_order', DEFAULT_DISPLAY_ORDER);
}

export const isGroupByAscOrder = (templateId) => {
  return (getGroupBySortOrder(templateId) !== DEFAULT_DISPLAY_ORDER);
}

export const isDimensionAscOrder = (templateId) => {
  return (getDimensionSortOrder(templateId) !== DEFAULT_DISPLAY_ORDER);
}

export const isMultiMetricAscOrder = (templateId) => {
  return (getMetricFilterSortOrder(templateId) !== DEFAULT_DISPLAY_ORDER);
}

export const getShapeDatasetEntries = (templateId) => {
  if (useAppLevelShapeConfigs){
    let shapeEntries = [];
    shapeEntries.push(appLevelShapeDatasetEntries);
    const censusShapeEntry = getCensusEntry();
    if(!_.isEmpty(censusShapeEntry)){
      shapeEntries.push(appLevelShapeCensusEntry())
    }
    return _.flatten(shapeEntries);
  }else{
    let shapeEntries = [];
    const templateEntry = getCurrentTemplateEntry(templateId);
    shapeEntries = _.get(templateEntry, 'shape_dataset_entries', []);
    if(templateEntry['show_shape_census_entry'] == 'true'){
      const censusShapeEntry = templateLevelShapeCensusEntry(templateId)
      shapeEntries.push(censusShapeEntry)
    }
    return _.flatten(shapeEntries);
  }
};

const appLevelShapeCensusEntry = () => {
  const shapeCensusEntry = getCensusEntry();
  return {
    ...shapeCensusEntry,
    shape_dataset_domain: shapeCensusEntry['dataset_domain'],
    shape_dataset_id: shapeCensusEntry['dataset_id'],
    shape_name: shapeCensusEntry['name'],
    is_census_entry: true,
    fields: {
      shape_id: shapeCensusEntry['tract_id'],
      shape_name: shapeCensusEntry['shape_name'],
      shape: shapeCensusEntry['geom_column']
    }
  }
}

const templateLevelShapeCensusEntry = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  const shapeCensusEntry = templateEntry['shape_census_entry'];
  return {
    ...shapeCensusEntry,
    shape_dataset_domain: templateEntry['dataset_domain'],
    shape_dataset_id: shapeCensusEntry['dataset_id'],
    shape_name: shapeCensusEntry['name'],
    is_census_entry: true,
    fields: {
      shape_id: shapeCensusEntry['tract_id'],
      shape_name: shapeCensusEntry['shape_name'],
      shape: shapeCensusEntry['geom_column']
    }
  }
}

export const getDefaultShapeDatasetEntry = (templateId, shapeDatasetEntryId = '') => {
  let shapeDatasetEntries = [], shapeDatasetEntry = {};
  if (useAppLevelShapeConfigs){
    shapeDatasetEntries = appLevelShapeDatasetEntries;
  }else{
    const templateEntry = getCurrentTemplateEntry(templateId);
    shapeDatasetEntries = _.get(templateEntry, 'shape_dataset_entries', []);
  }

  if (!_.isEmpty(shapeDatasetEntries)) {
    shapeDatasetEntry = _.isEmpty(shapeDatasetEntryId) ? _.first(shapeDatasetEntries) :
      _.find(shapeDatasetEntries, {id: shapeDatasetEntryId})
  }

  return shapeDatasetEntry;
};

export const getQuickFilterEntries = (templateId, viewEntry = {}, return_all_filters=false) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  let quickFilterEntries = [];
  const filterByEntries =  _.get(templateEntry,'quick_filter_entries', []);
  const groupedFilterEntries =  _.get(templateEntry,'grouped_quick_filter_entries', []);

  quickFilterEntries.push(filterByEntries);
  quickFilterEntries.push(groupedFilterEntries);

  if (!_.isEmpty(getDistributionBucketEntry(viewEntry))) {
    const distributionFilter = {..._.pick(viewEntry, ['column', 'name', 'field']), renderType:'number'}
    quickFilterEntries.push(distributionFilter);
  }
  if(return_all_filters) {
    return _.chain(quickFilterEntries).
    flatten().
    map((filter) => {
      return _.isNil(filter.renderType) ? _.merge({},
        filter,
        { renderType: _.get(filter, 'render_type', 'text') }) : filter;
    }).
    value();
  }
  return _.chain(quickFilterEntries).
    flatten().
    uniqBy((filter) => {
      if(templateEntry['enable_secondary_dataset'] == 'true'){
        return filter['column_dataset'] + filter['column'];
      }else{
        return filter['column'];
      }
    }).
    map((filter) => {
      return _.isNil(filter.renderType) ? _.merge({},
        filter,
        { renderType: _.get(filter, 'render_type', 'text') }) : filter;
    }).
    value();
};

export const getBenchMarkEntries = (templateId ) => {
  let templateEntry = getCurrentTemplateEntry(templateId);
  let benchmarkEntries = _.get(templateEntry,'bench_mark_entries', []);
  _.forEach(benchmarkEntries, (benchmark, index) => {
      benchmark['lineType'] = BENCHMARK_LINE_TYPE[index];
  });

  return benchmarkEntries;
}

export const isTableAggregationEnabled = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  const isTableAggregation =  _.get(templateEntry, 'table.aggregation.enable', 'false');
  const tableAggregationColumn =  _.get(templateEntry, 'table.aggregation.column');
  return isTableAggregation === 'true' && !_.isEmpty(tableAggregationColumn);
}

export const showAssociatedRecords = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  const isAssociated = _.get(templateEntry, 'leaf_page.show_associated_records', 'false') === 'true';
  // If table aggregation is true then we show Associated Records.
  return isAssociated && isTableAggregationEnabled(templateId);
}

export const showMapUnderLeafPage = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'leaf_page.show_leaf_page_under_map', 'false') === 'true';
}

export const getTableColumnEntries = (templateId ) => {
  let templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry,'table_column_entries', []);
}

export const getTableSearchColumnEntries = (templateId) => {
  const tableColumnEntries  = getTableColumnEntries(templateId);
  const searchColumnEntries = _.filter(tableColumnEntries, (tableEntry) => {
    return _.includes([STRING_TYPES_FIELD, NUMBER_TYPES_FIELD], _.get(tableEntry, 'renderType', null))
  });

  return { default_column_entry: _.first(searchColumnEntries), options: searchColumnEntries };
}

export const getLeafPageEntries = (templateId) => {
  const currentTemplateEntry = getCurrentTemplateEntry(templateId);
  return _.get(currentTemplateEntry, 'leaf_page_entries', []);
}

export const getLeafPageTitleEntry = (templateId) => {
  const currentTemplateEntry = getCurrentTemplateEntry(templateId);
  return _.get(currentTemplateEntry, 'leaf_page.title_entry', {});
}

export const getLeafPageEntryColumnFieldMaping = (tableRowEntry, leafPageEntries) => {
  let leafPageColumnMap = {};
  _.each(leafPageEntries, (leafPageEntry) => {
    if(!_.isEmpty(_.get(tableRowEntry, leafPageEntry.column))) {
      leafPageColumnMap[leafPageEntry.field] = _.get(tableRowEntry, leafPageEntry.column);
    }
  });
  return leafPageColumnMap;
}

export const getDimensionEntry = (currentDrilldownTemplateId, dimensionName) => {
  let dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
  return _.find(dimensionsEntries, (entry) =>{
    return (entry['name'] === dimensionName)
  });
}

export const getPropertyColumnForPdf = (isSubjectSearch, templateId ) => {
  const templateEntry = isSubjectSearch ? getAdvancedSearchTemplateEntryById(templateId) :
  getCurrentTemplateEntryById(templateId);
  return templateEntry['comp_finder_PRC_id']
};

// This function for to check user display name is a emailid
const isEmailId = (email) => {
  let regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regExp.test(email);
}

export const getInitialOfUserName = (userName) => {
  const splitUserName = _.split(userName, /[ .]+/);
  if(isEmailId(userName)) {
    return `${_.toUpper(userName.substring(0,2))}`
  } else {
    const multipleName = _.map(splitUserName, (name) => {
      if(splitUserName.length == 1) {
        return `${_.toUpper(name.substring(0,2))}`;
      } else {
        return `${_.toUpper(name.charAt(0))}`;
      }
    })
    return _.join(multipleName, '');
  }
}

// Returns geocoding boundary [minX, minY, maxX, maxY] if configured in vif.
export function getGeocodeBoundingbox(templateId) {
  const config = getMapConfig(templateId);
  const searchBoundary = _.get(config, 'map.search_boundary', {});
  const upperLeftLongitude = _.get(searchBoundary, 'upper_left_longitude');
  const lowerRightLatitude = _.get(searchBoundary, 'lower_right_latitude');
  const lowerRightLongitude = _.get(searchBoundary, 'lower_right_longitude')
  const upperLeftLatitude = _.get(searchBoundary, 'upper_left_latitude');

  if(upperLeftLongitude && lowerRightLatitude && lowerRightLongitude && upperLeftLatitude) {
    return [
      Number(upperLeftLongitude),
      Number(lowerRightLatitude),
      Number(lowerRightLongitude),
      Number(upperLeftLatitude)
    ];
  } else {
    return null;
  }
}

export function isMaxBoundsEnable(templateId) {
  const config = getMapConfig(templateId);
  return JSON.parse(_.get(config, 'map.enable_max_bounds', false));
}

export const  getAvailableDrilldownDropdownEntries = ({
  currentDrilldownTemplateId,
  drilledDownDimensions,
  currentDrilldownDimensionField,
  currentDrilldownGroupByColumn,
  currentVisualizationType,
  currentSnapshotView
}) => {
  let dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId, currentVisualizationType,
    currentSnapshotView);
  return _.compact(_.map(dimensionsEntries, (dimensionEntry) => {
    const isPrevDrillDownField = _.isEmpty(_.find(drilledDownDimensions, { 'field': dimensionEntry.field }));
    const iscurrentDrillDownField = !_.isEqual(dimensionEntry.field, currentDrilldownDimensionField);
    const isNotActiveColumnFromGroupBy = !_.isEqual(dimensionEntry.column, currentDrilldownGroupByColumn);
    if(isPrevDrillDownField && iscurrentDrillDownField && isNotActiveColumnFromGroupBy){
      return dimensionEntry;
    }
  }));
}

export const getNextDrilldownDimensionField = (
  currentDrilldownTemplateId,
  drilledDownDimensions,
  viewEntry, excludeFields = [],
  currentDrilldownGroupByEntry = {}) => {
  const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
  const currentDrilldownGroupByColumn = _.get(currentDrilldownGroupByEntry, 'column', '');
  let defaultDimensionIndex = _.findIndex(dimensionsEntries,
                                { 'column': getDefaultDimensionColumn(viewEntry) });
  defaultDimensionIndex = defaultDimensionIndex == -1 ? 0: defaultDimensionIndex;
  let orderedDimensionEntries = [];
  orderedDimensionEntries = dimensionsEntries.slice(defaultDimensionIndex, dimensionsEntries.length);
  orderedDimensionEntries = orderedDimensionEntries.concat(dimensionsEntries.slice(0, defaultDimensionIndex));
  const alreadyDrilledDownDimensions = _.map(drilledDownDimensions, 'field');
  let availableDrilldownEntries = _.filter(orderedDimensionEntries, (dimensionEntry) => {
    return !_.includes(alreadyDrilledDownDimensions, dimensionEntry.field) &&
    !_.includes(excludeFields, dimensionEntry.field);
  });

  availableDrilldownEntries = _.filter(availableDrilldownEntries, (entry) => {
    return !_.isEqual(entry.column,currentDrilldownGroupByColumn);
  });

  return _.get(availableDrilldownEntries, [0, 'field']);
};

export const getAvailableGroupByEntries = ({
  currentDrilldownTemplateId,
  currentDrilldownDimensionField,
  currentDrilldownGroupByEntry
}) => {
  const groupByEntries = getGroupByEntries(currentDrilldownTemplateId);
  // Not showed current selected entry.
  let availableGroupByEntries = _.reject(groupByEntries, { field: currentDrilldownGroupByEntry['field'] });
  // Both groupBy and dimension can't have same column
  let dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
  let currentDrilldownDimensionColumn = _.get(
    _.find(dimensionsEntries, { 'field': currentDrilldownDimensionField }),'column', '');
  availableGroupByEntries = _.filter(availableGroupByEntries, (entry) => {
    return !_.isEqual(entry.column,currentDrilldownDimensionColumn);
  });
  return availableGroupByEntries;
}

export const  getAvailableDrilldownEntries = ({
  currentDrilldownTemplateId,
  drilledDownDimensions,
  currentDrilldownDimensionField,
  currentDrilldownGroupByColumn,
  currentVisualizationType,
  currentSnapshotView
}) => {
  let dimensionsEntries = getDimensionEntries(
    currentDrilldownTemplateId, currentVisualizationType, currentSnapshotView
  );
  return _.compact(_.map(dimensionsEntries, (dimensionEntry) => {
    const isPrevDrillDownField = _.isEmpty(_.find(drilledDownDimensions, { 'field': dimensionEntry.field}));
    const iscurrentDrillDownField = !_.isEqual(dimensionEntry.field, currentDrilldownDimensionField);
    const isNotSameColumnFromGroupBy =  !_.isEqual(dimensionEntry.column, currentDrilldownGroupByColumn);
    if( isPrevDrillDownField && iscurrentDrillDownField && isNotSameColumnFromGroupBy){
      return dimensionEntry;
    }
  }));
}

export const isDateColumnConfigured = (templateEntry) => {
  return !_.isEmpty(_.get(templateEntry, 'fields.date_column', ''));
}

export const getDateColumnLabelFromTemplateEntry = (currentTemplateId) => {
  const templateEntry = getCurrentTemplateEntry(currentTemplateId);

  return _.get(templateEntry, 'fields.date_column_label', '');
};

export const getAttribution = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'attribution', {});
};

export const hideTableVisualization = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'hide_details_tab', 'false') === 'true';
}

export const shouldShowEntityCounts = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'show_entity_counts', 'false') === 'true';
}

export const dimensionFieldsExceptDrilledDown = (currentDrilldownTemplateId, drilledDownDimensions) => {
  const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
  const alreadyDrilledDownDimensions = _.map(drilledDownDimensions, 'field');
  const availableDrilldownEntries = _.filter(dimensionsEntries, (dimensionEntry) => {
    return !_.includes(alreadyDrilledDownDimensions, dimensionEntry.field);
  });

  return _.map(availableDrilldownEntries, 'field');
};

export const getMapConfig = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  if(isTemplateLevelMapSettingEnable()){
    return !_.isEmpty(templateEntry) ? templateEntry : getConfig();
  } else {
    return getConfig();
  }
}

export const getTemplateWiseViewEntries = () => {
  const templateEntries = getTemplateEntries();
  let templateWiseViewEntries = [];

  _.each(templateEntries, ({ name, template_id }) => {
    _.each(getViewEntries(template_id), (viewEntry) => {
      templateWiseViewEntries.push({ templateName: name, ...viewEntry, template_id });
    })

  });

  return templateWiseViewEntries;
}

export const getThirdPartyWarningStatus = (templateId) => {
  const currentTemplateEntry = getCurrentTemplateEntry(templateId);
  const thirdPartyWarningStatus = _.get(currentTemplateEntry,"show_third_party_data_warning","false");
  return _.isEqual(thirdPartyWarningStatus,"true");
}

export const showTimeOfDayFilter = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  const enableTimeOfDayCheckbox = JSON.parse(_.get(templateEntry,"show_time_of_day_filter", "false"));

  return enableTimeOfDayCheckbox && isDateColumnConfigured(templateEntry);
}

export const getCompFinderIcon = (templateId, searchMethod) => {
  let templateEntry = {};
  if(SEARCH_METHOD.SUBJECT_PROPERTY == searchMethod){
    templateEntry = _.find(getAdvanceSearchTemplates(), (template) => {
      return (_.toString(template['template_id']) === _.toString(templateId));
    });
  } else {
    templateEntry = getCurrentTemplateEntry(templateId);
  }

  return _.get(templateEntry, 'comp_finder_icon', '');
};

export const getCompFinderDescription = (templateId, searchMethod) => {
  let templateEntry = {};
  if(SEARCH_METHOD.SUBJECT_PROPERTY == searchMethod){
    templateEntry = _.find(getAdvanceSearchTemplates(), (template) => {
      return (_.toString(template['template_id']) === _.toString(templateId));
    });
  } else {
    templateEntry = getCurrentTemplateEntry(templateId);
  }

  return _.get(templateEntry, 'comp_finder_description', '');
};
