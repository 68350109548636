// Vendor Imports
import _ from 'lodash';
import moment from 'moment';

// Project Imports
import { BIENNIUM_FISCAL_YEAR } from 'appConstants';
import { getYearTextByRange } from 'helpers/dateHelper';
import { getDateRangeStartMonth } from 'common/config/customerConfiguration';

export const isBienniumFiscalYear = ({ dateRangeMode }) => {
  return (dateRangeMode === BIENNIUM_FISCAL_YEAR);
};

export const formatResultsInBienniumYearWise = (results, dateRange, dateRangeMode) => {
  let formattedResult = {};
  const bienniumFiscalYearLabel = getYearTextByRange(dateRange, dateRangeMode);
  const { startDate, endDate } = dateRange;

  const yearWiseResultEntries = _.filter(results, (entry) =>{
    return moment(entry.period).startOf('day').isBetween(startDate, endDate, 'month', '[]');
  });

  formattedResult[bienniumFiscalYearLabel] = yearWiseResultEntries;
  return formattedResult;
}

export const getBienniumProjectionDimension = (entries, vizOptions) => {
  const { dateRange, compareYearRanges, dateRangeMode } = vizOptions;
  const momentObjYearEndDates = [];
  const yearEndDates = _.map(compareYearRanges.concat(dateRange), (range) => {
    const { endDate } = range;
    const label = getYearTextByRange(range, dateRangeMode);
    momentObjYearEndDates.push(moment(endDate));

    return { label, endDate };
  });
  const maxEndDate = moment.max(...momentObjYearEndDates);
  const matchedYearLabel = _.chain(yearEndDates).find({ endDate: maxEndDate._i }).get('label').value();

  return matchedYearLabel ? matchedYearLabel : _.chain(entries).keys().last().value();
}

export const getBienniumXAxisValues = (dataItem, options, index) => {
  const isProjection = _.get(options, 'meta.isProjection', false);
  const renderType = _.get(options, 'renderType');
  const dateRange = _.get(options, 'dateRange');
  const month = moment(dataItem.period).format("MMM");
  const monthNumber = Number(moment(dataItem.period).format("M"));
  let year = moment(dataItem.period).format("YYYY");
  let xValue = (index > 11) ? `${month} ` : month;
  const dateRangeStartMonth = getDateRangeStartMonth();
  year = (dateRangeStartMonth > 0) && (monthNumber > dateRangeStartMonth) ? Number(year) + 1 : year;

  if (isProjection || renderType === 'markers') {
    const startDateObj = moment(dateRange.startDate);
    const diffenceMonthCount = moment(dataItem.period).diff(startDateObj, 'month');
    xValue = (diffenceMonthCount > 11) ? `${month} ` : month;
  }

  return { year, xValue };
}
