import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  getSlaInformationText
} from 'common/config/customerConfiguration';

class HeatChartDescription extends Component {
  renderInfoIcon(){
    return(
      <span className="ml-1 projection-info-icon">
        <i className="icons-info-circle"></i>
      </span>
    )
  }

  render(){
    const infoText = getSlaInformationText();
    if(_.isEmpty(infoText)){
      return null;
    }

    return(
      <div className="hear-chart-descriptions">
        <div className="projection-info">
          {this.renderInfoIcon()}
          {infoText}
        </div>
      </div>
    )
  }
}

HeatChartDescription.propTypes = {
  selectedDimension: PropTypes.object,
  selectedListItem: PropTypes.object
};

export default HeatChartDescription;
