import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ForgeIcon } from '@tylertech/forge-react';

import OutsideClickHandler from '../OutsideClickHandler';
import TimeOfDayFilterLabel from './TimeOfDayFilterLabel';
import FilterBody from './FilterBody';
import ApplyButton from './ApplyButton';

import { updateTimeOfDayFilters } from 'actions/drilldownActions';
import { getWidthAndHeightForFilterBadge, isClickOutSideFilter } from '../Filters/FilterDomHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getNewTimeOfDayFilters } from './helper';

const FILTER_ITEM_WIDTH = 960;

export default function TimeOfDayFilter() {
  const filterContainerRef = useRef();
  const filterItemRef = useRef();
  const filterLabelRef = useRef();
  const dispatch = useDispatch();
  const timeOfDayFilters = useSelector(state => _.get(state, 'drilldown.timeOfDayFilters', []));
  const [showFilterContent, setShowFilterContent] = useState(false);
  const [showFilterPosition, setShowFilterPosition] = useState(false);
  const [filters, setFilters] = useState(timeOfDayFilters);

  const shouldClickOutSide = (event) => {
    return isClickOutSideFilter(event, filterContainerRef.current);
  };

  const onClickResetButton = () => {
    setFilters([]);
  };

  const onUpdateFilters = (selectedCells, isToggleSelectedCell) => {
    setFilters((preFilters) => {
      return getNewTimeOfDayFilters(preFilters, selectedCells, isToggleSelectedCell)
    });
  };

  const handleKeyDownTimeOfDayFilter = (e) => {
    if (isEnterButtonPressed(e)) {
      toggleFilterLabelButton(e);
    }
  };

  const toggleFilterLabelButton = (e) => {
    e.stopPropagation();
    const boundingClientRect = filterLabelRef.current.getBoundingClientRect()
    let showFilterPosition = false;

    if (boundingClientRect.x + FILTER_ITEM_WIDTH > window.innerWidth) {
      showFilterPosition = true;
    }
    setShowFilterContent(!showFilterContent);
    setShowFilterPosition(showFilterPosition);
  };

  const onApply = () => {
    setFilters(updatedFilters => {
      dispatch(updateTimeOfDayFilters(updatedFilters));
      return updatedFilters; 
    });    
    setShowFilterContent(false);
    setShowFilterPosition(false);
  };

  const renderLabel = () => {
    return (
      <div
        tabIndex={0}
        className="filter-chip-values timeof-day-filterschip-label"
        ref={filterLabelRef}
        onClick={(e) => toggleFilterLabelButton(e, true)}
        onKeyDown={(e) => handleKeyDownTimeOfDayFilter(e)}
      >
        <ForgeIcon name="date_range" className="tx-18" />
        <TimeOfDayFilterLabel filters={filters} showFilterContent={showFilterContent} />
        <span tabIndex={0} className="tag-close rounded-circle icons-chevron-down"></span>
      </div>
    );
  };

  const style = showFilterContent ?
    getWidthAndHeightForFilterBadge(filterContainerRef, FILTER_ITEM_WIDTH) :
    {};
  const filterShipStyle = showFilterContent ?
    { width: FILTER_ITEM_WIDTH, maxWidth: FILTER_ITEM_WIDTH } : {};
  const filterClassNames = classNames('global-filter',
    { 'show-filter-chip': showFilterContent },
    { 'filter-position': showFilterPosition }
  );
  const isFilterChanged = _.isEqual(timeOfDayFilters, filters);

  return (
    <OutsideClickHandler onClickOutSide={onApply} shouldClickOutSide={shouldClickOutSide}>
      <div className="filter-badge" key='time-of-day-container'>
        <div className="collection-filter-badge d-flex">
          <div className={filterClassNames} ref={filterContainerRef} style={style}>
            <div className="filter-chip" ref={filterItemRef}
              style={filterShipStyle}>
              {renderLabel()}
              <FilterBody
                showFilterContent={showFilterContent}
                selectedCells={_.cloneDeep(filters)}
                onClickResetButton={onClickResetButton}
                onSelectedCellsUpdate={onUpdateFilters}>
                <ApplyButton onUpdate={onApply} disabled={isFilterChanged} />
              </FilterBody>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}