import React from 'react'
import PropTypes from 'prop-types'
import { showDateFilter } from 'common/config/viewConfiguration';
import CustomTooltip from 'common/components/CustomTooltip';
import classNames from 'classnames';
import { isIncludeBookmarkDateRange } from '../bookmarkHelper';
import { getDateRangeTextBasedOnType } from 'helpers/dateHelper';
import { SAVE_VIEW_INFO_TEXT } from 'appConstants';
import { ForgeButton } from '@tylertech/forge-react';

function BookmarkEditDateRangeInclude(props) {
  const { onToggleEditDropdown, onHandleEditDateRange, showEditDateDropDown, bookmarkProps } = props;
  const { drilldown, currentDrilldownViewEntry } = bookmarkProps;
  const { currentDrilldownTemplateId } = drilldown;

  const getBookmarkDateFilterText = (type, id = 0) => {
    const { commonFilters, currentBookmark } = bookmarkProps;
    const commonFilterBookmark = _.get(currentBookmark, 'commonFilters');
    const text = getDateRangeTextBasedOnType(commonFilters, type, id);
    const bookmarkText = getDateRangeTextBasedOnType(commonFilterBookmark, type, id);

    if (isIncludeBookmarkDateRange(currentBookmark)) {
      return `Change time frame filter from ${bookmarkText} to ${text}?`;
    } else {
      return `Include time frame filter of ${text}?`;
    }
  }

  const renderEditDateRange = () => {
    const showDateRangeCheckbox = showDateFilter(currentDrilldownTemplateId, currentDrilldownViewEntry);
    const textInfo = getBookmarkDateFilterText('includeDateRange');

    return showDateRangeCheckbox && (
      <div className='d-flex mb-3'>
        <p className='date-range-text'>{textInfo}</p>
        <div className='pl-1'>
          <CustomTooltip label={SAVE_VIEW_INFO_TEXT} placement='left'>
            <i className="icons-info-circle-icon"></i>
          </CustomTooltip>
        </div>
      </div>
    );
  }

  const renderBookmarkDateIncludeModal = () => {
    if (!showEditDateDropDown) {
      return null;
    }

    const filterClassNames = classNames('bookmark-dropdown-wrapper', {
      'dropdown-menu show': showEditDateDropDown
    });

    return (
      <div className="bookmark-dropdown-wrappers">
        <div className={filterClassNames}>
          {renderEditDateRange()}
          <div className="dropdown-footer justify-content-between">
            <div>
              <ForgeButton type="outlined">
                <button
                  tabIndex={0}
                  aria-label="cancel"
                  onClick={() => onToggleEditDropdown(false)}>
                  Cancel
                </button>
              </ForgeButton>
            </div>
            <div>
              <ForgeButton type="outlined" className="mr-3">
                <button
                  tabIndex={0}
                  aria-label="No"
                  onClick={() => onHandleEditDateRange(false)}>
                  No
                </button>
              </ForgeButton>
              <ForgeButton type="raised" className="ml-auto">
                <button
                  tabIndex={0}
                  aria-label="Yes, include filter"
                  onClick={() => onHandleEditDateRange(true)}>
                  Yes, include filter
                </button>
              </ForgeButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    renderBookmarkDateIncludeModal()
  )
}

BookmarkEditDateRangeInclude.propTypes = {
  bookmarkProps: PropTypes.object,
  showEditDateDropDown: PropTypes.bool,
  onToggleEditDropdown: PropTypes.func,
  onHandleEditDateRange: PropTypes.func
}

export default BookmarkEditDateRangeInclude
