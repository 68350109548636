// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

// Project Imports
import { getPointsTileUrl } from 'common/api/map';
import { getMetricRange } from 'common/api/drilldown';
import { isHeatMap } from '../helpers/MapOptionsHelper';
import { fetchApiData } from 'helpers/apiResponseHelper';

const SOURCES = {
  HEAT_CLUSTERS: 'metric-heat-clusters-source'
};
export const LAYERS = {
  HEAT_CLUSTERS: 'metric-heat-clusters-layer'
};

class MetricHeatMapPartial extends Component {
  constructor(props){
    super(props);
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.initSources();
    this.initLayers();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.tileParams, this.props.tileParams)){
      this.removeSourcesAndLayers();
      this.initSources();
      this.initLayers();
    } else if (!_.isEqual(prevProps.currentMapView, this.props.currentMapView)){
      this.updateLayoutProperty();
    }
  }

  updateLayoutProperty(){
    const { map, currentMapView, isMetricHeatMap } = this.props;
    if (map.getLayer(LAYERS.HEAT_CLUSTERS)) {
      map.setLayoutProperty(LAYERS.HEAT_CLUSTERS,
        'visibility',
        (isHeatMap(currentMapView) && isMetricHeatMap) ? 'visible' : 'none'
      );
    }
  }

  initSources() {
    const { map } = this.props;
    let params = _.cloneDeep(this.props.tileParams);
    params['mapType'] = 'heat';
    map.addSource(SOURCES.HEAT_CLUSTERS, {
      'type': 'vector',
      'geojsonTile': true,
      'tiles': [getPointsTileUrl(params)]
    });
  }

  initLayers = async() => {
    const { map, currentMapView, isEsriBaseMapStyle, tileParams, isMetricHeatMap } = this.props;
    const insertBeforeLayer = isEsriBaseMapStyle ? '' : 'waterway-label';
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();


    const response = await fetchApiData(getMetricRange(tileParams), this.abortFetchController);
    let minValue = Number(_.get(response, '[0].min_count', 0));
    let maxValue = _.get(response, '[0].max_count', 1000);
    const rangeValue = (Number(maxValue) - Number(minValue))/5;
    let heatChartWeight = [
      'interpolate',
      ['linear'],
      ['get', 'count'],
    ]
    heatChartWeight.push(0);
    heatChartWeight.push(0);
    _.times(5, (index) => {
      if(index > 0 ){
        heatChartWeight.push(index == 1 && minValue > 0 ? minValue : (minValue + rangeValue*index));
        heatChartWeight.push(index * 0.2);
      }
    });
    heatChartWeight.push(Number(maxValue));
    heatChartWeight.push(1);
    map.addLayer({
      'id': LAYERS.HEAT_CLUSTERS,
      'type': 'heatmap',
      'source': SOURCES.HEAT_CLUSTERS,
      'source-layer': '_geojsonTileLayer',
      'layout': {
        'visibility': (isHeatMap(currentMapView) && isMetricHeatMap) ? 'visible' : 'none'
      },
      'paint': {
        // Increase the heatmap weight based on number of points
        'heatmap-weight': heatChartWeight,

        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
            0,'rgba(0,0,0,0)',
            0.1, 'rgb(103,169,207)',
            0.6, 'rgb(255,0,0)',
            1, 'rgb(255,0,0)'
        ]
      }
    }, insertBeforeLayer);
  }

  removeSourcesAndLayers() {
    const { map } = this.props;

    if (map.getLayer(LAYERS.HEAT_CLUSTERS)) {
      map.removeLayer(LAYERS.HEAT_CLUSTERS);
    }

    if (map.getSource(SOURCES.HEAT_CLUSTERS)) {
      map.removeSource(SOURCES.HEAT_CLUSTERS);
    }
  }

  render() {
    return null;
  }
}

MetricHeatMapPartial.propTypes = {
  tileParams: PropTypes.object,
  map: PropTypes.object,
  currentMapView: PropTypes.object,
  isEsriBaseMapStyle: PropTypes.bool,
  isMetricHeatMap: PropTypes.bool
};

export default MetricHeatMapPartial;
