import { getMapCenter, getMapzoom,
  getMapStyleEntries,
  getDefaultShapeDatasetEntry
} from 'common/config/templateConfiguration';

import {
  getDefaultShapeOverlayEntries,
  getMapViews
} from 'modules/Map/helpers/MapOptionsHelper';
import { getDefaultVisualizationChartView } from 'helpers/visualizationHelper';
import { DEFAULT_MAP_VIEW, VISUALIZATION_TYPES } from 'appConstants';
import { UPDATE_VISUALIZATION_REDUCER } from 'actions/visualizationActions';
import * as actionTypes from '../actionTypes/mapActionTypes';
import { getMapTypes } from 'common/config/visualizationConfiguration';

export const mapOptionsReducerDefaultState = () => {
  return {
    currentMapStyleEntry: {},
    centerAndZoom: {},
    currentMapView: _.get(getMapViews(), DEFAULT_MAP_VIEW),
    geoCoder: {},
    legends: {},
    selectedOutlines: [],
    shapeData: [],
    filteredGeojson: {},
    shape: {
      datasetEntry: {},
      selectedShapeIds: [],
      selectedShapesExtent: null
    },
    isDrawingEnabled: false
  };
};

export default function(state = mapOptionsReducerDefaultState(), action) {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_DRILL_DOWN_MAP_OPTIONS:
      const currentTemplateId = _.get(action, 'currentTemplateId');
      const viewEntry = _.get(action, 'viewEntry');
      const canUseDefaultView = _.get(action, 'canUseDefaultView', false);
      const defaultShapeDatasetEntry = getDefaultShapeDatasetEntry(currentTemplateId);
      const newMapView = getNewMapView(state, viewEntry, defaultShapeDatasetEntry, canUseDefaultView);

      const centerAndZoom = {
        center: getMapCenter(currentTemplateId),
        zoom : getMapzoom(currentTemplateId)
      };

      return {
        ...state,
        currentMapStyleEntry: getMapStyleEntries(currentTemplateId)[0],
        centerAndZoom,
        selectedOutlines: getDefaultShapeOverlayEntries(currentTemplateId),
        shape:{
          ...state.shape,
          datasetEntry: defaultShapeDatasetEntry
        },
        currentMapView: newMapView
      }

    case actionTypes.UPDATE_CENTER_AND_ZOOM:
      return {
        ...state,
        centerAndZoom: _.get(action, 'centerAndZoom')
      }

    case actionTypes.UPDATE_MAP_STYLE_ENTRY:
      return {
        ...state,
        currentMapStyleEntry: _.get(action, 'mapStyleEntry')
      }

    case actionTypes.UPDATE_SELECTED_SHAPE_OUTLINES:
      return {
        ...state,
        selectedOutlines: _.get(action, 'selectedOutlines')
      }

    case actionTypes.TOGGLE_SHAPE_IDS_FILTER:
      return {
        ...state,
        shape: {
          ...state.shape,
          selectedShapeIds: action.shapeIds,
          selectedShapesExtent: action.shapesExtent
        }
      };

    case actionTypes.UPDATE_SHAPE_DATA:
      return {
        ...state,
        shapeData: action.shapeData
      };

    case actionTypes.UPDATE_SHAPE_DATASET_ENTRY:
      return {
        ...state,
        shape: {
          selectedShapeIds: [],
          datasetEntry: action.selectedShapeEntry
        }
      };

  case actionTypes.UPDATE_DRAWING_STATE_CHANGE:
    return {
      ...state,
      isDrawingEnabled: action.toggle
    };

  case actionTypes.UPDATE_POLYGON_FEATURE:
    return {
      ...state,
      filteredGeojson: action.featuresGeojson
    }

  case actionTypes.UPDATE_RADAR_DRAWING_STATE_CHANGE:
    return {
      ...state,
      isRadarDrawingEnabled: action.toggle
    }

  case actionTypes.UPDATE_RADAR_DRAWING_LINE_STATE_CHANGE:
    return {
      ...state,
      isRadarDrawingLineEnabled: action.toggle
    }

  case actionTypes.UPDATE_MAP_VIEW:
    return {
      ...state,
      currentMapView: action.mapView
    };

  case actionTypes.UPDATE_LEGENDS:
    return {
      ...state,
      legends: { [action.mapType]: action.legends }
    };

  case actionTypes.UPDATE_GEO_CODER:
    return {
      ...state,
      geoCoder: action.geoCoder
    };

  case actionTypes.UPDATE_VISUALIZATION_DEFAULTS:
    const { currentDrilldownTemplateId, currentDrilldownViewEntry } = action.options;
    const shapeDatasetEntry = getDefaultShapeDatasetEntry(currentDrilldownTemplateId);
    const mapView = getNewMapView(state, currentDrilldownViewEntry, shapeDatasetEntry, false);

    return {
      ...state,
      currentMapView: mapView
    };

  case actionTypes.REMOVE_POLYGON_FILTER:
    return {
      ...state,
      filteredGeojson: {
        type: 'FeatureCollection',
        features: []
      },
      isDrawingEnabled: false
    }

    case UPDATE_VISUALIZATION_REDUCER:
      return {
        ...state,
        ..._.get(action, 'visualization.mapOptions', {})
      }

    default:
      return state;
  }
}

function getNewMapView(state, viewEntry, defaultShapeDatasetEntry, canUseDefaultView) {
  const existingMapView = _.get(state, 'currentMapView');
  const viewBasedDefaultMapView = getDefaultVisualizationChartView(viewEntry, VISUALIZATION_TYPES.MAP.type);
  const defaultMapView = _.get(getMapViews(), viewBasedDefaultMapView);
  const mapTypes = getMapTypes(viewEntry);
  let currentMapView = existingMapView;

  if (canUseDefaultView) {
    return defaultMapView;
  }

  if (
    !_.includes(_.map(mapTypes, 'value'), _.get(currentMapView, 'type')) ||
    (_.isEmpty(defaultShapeDatasetEntry) && existingMapView.type === 'choropleth')
  ) {
    currentMapView = defaultMapView;
  }

  return currentMapView;
}
