import React from 'react';
import PropTypes from 'prop-types';
import ColorBox from './ColorBox';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { LINE_CHART_COMPARE_TRACE_COLORS } from 'appConstants';
import classNames from 'classnames';

function FlyoutPopupTableContent({ tableHeaders, tableData, period, options }) {
  const { templateId, compareYearRanges } = options;
  const nullValueLabel = getNullValueLabel(templateId);
  const isCompareSingleYear = _.size(compareYearRanges) == 1;

  const renderHeaderItem = (heading, index) =>{
    let metricName = _.get(heading, 'metricName');
    if(isCompareSingleYear){
      metricName = !_.isEmpty(metricName) ? <span className='' ><u>{metricName}</u><br/></span> : <br/>
    }

    return(
      <th key={index}>{metricName}<span className=''>{heading.name}</span> </th>
    )
  }

  const renderTableHeaders = () => {
    if (_.isEmpty(tableHeaders)) {
      return null;
    }

    return _.map(tableHeaders, (heading, index) => {
      return renderHeaderItem(heading, index);
    });
  };

  const renderTableData = () => {
    return tableData.map((entry, index) => {
      return (
        <tr key={index} tabIndex={0}>
          {renderTableRowData(entry)}
        </tr>
      );
    });
  };

  const getColor = (color, segmentType = 'current') => {
    let newColor = color;
    const colorField = segmentType === 'comparison' ? 'lite' : 'color'; 
    const colorConfig = _.find(LINE_CHART_COMPARE_TRACE_COLORS, { [colorField]: color });
    newColor = _.get(colorConfig, colorField, color);
    return newColor;
  }

  const renderProjectionLine = (tdValue, color, metricType) => {

    const projectionClasses = classNames('projection-color pr-3', {
      'last-period': (metricType === 'secondary')
    });
  
    return (
      <div className='d-flex justify-content-start'>
        <div className={projectionClasses}>
          <svg><line x1="30" y1="0" style={{ stroke: color }}></line></svg>
        </div>
        <div>{tdValue}</div>
      </div>
    );
  }

  const renderLegendMarker = (entry, column) => {
    const tdValue = _.get(entry, column.columnField, nullValueLabel);
    const segmentType = (_.get(column, 'segmentType'));
    const color = getColor(_.get(entry, column.lineColor), segmentType);
    const colorLineType = _.get(entry, column.lineColorType);
    const isLineSolid = colorLineType === 'solid';
    const isFillColor = column.lineColorType === 'fillSquare';
    const isProjection = column.isProjection;
    const metricType = column.metricType;
    const fontWeightClass = isFillColor ? 'ml-2 font-weight-bold' : 'ml-2';
    const lineClass = classNames('mr-1 mt-1', {
      'legend-color': segmentType !== 'comparison',
      'legend-color-compare': segmentType === 'comparison',
    })

    if (isProjection) {
      const projectionColor = _.isEmpty(color) ? entry.dimensionColor : color;
      return renderProjectionLine(tdValue, projectionColor, metricType);
    }
  
    if (_.isEmpty(color)) {
      return null;
    }

    if (isFillColor) {
      return (
        <span className='d-flex'>
          <ColorBox boxColor={color} />
          <span className={fontWeightClass}>{tdValue}</span>
        </span>
      );
    }
     if (!isLineSolid && !_.isEmpty(colorLineType)) {
      return (
        <div>
          <span className="line-style dashdot" style={{ color }}>
            &#8722; &#8729; &#8722;
          </span>{' '}
          {tdValue}
        </div>
      );
    }
     return (
      <span className='d-flex'>
        <div className={lineClass} style={{ backgroundColor: color }}></div>
        <span className={fontWeightClass}>{tdValue}</span>
      </span>
    );
  };

  const renderTableRowData = (entry) => {
    return tableHeaders.map((column, index) => {
      let tdValue = _.get(entry, column.columnField, nullValueLabel);
      const renderColorWithValue = renderLegendMarker(entry, column);

      return (
        <td key={index}>
          {renderColorWithValue || tdValue}
        </td>
      );
    });
  };

  if (_.isEmpty(tableHeaders)) {
    return null;
  }

  return (
    <table className="flyout-table line-chart-flyout">
      <thead>
        {!_.isEmpty(period) && <tr className='period-header'><th>{period}</th></tr>}
        <tr>{renderTableHeaders()}</tr>
      </thead>
      <tbody>{renderTableData()}</tbody>
    </table>
  );  
}

FlyoutPopupTableContent.propTypes = {
  tableHeaders: PropTypes.array,
  tableData: PropTypes.array,
  period: PropTypes.string,
  options: PropTypes.object
};

export default FlyoutPopupTableContent;
