import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

import {
  COLLECTION_ID,
  COLLECTION_TYPES
} from 'appConstants';
import {
  getCollectionEntry,
  getCardId,
  isDroppableCollection,
  updateEntriesOrder,
  getAllMetricCollection,
  getCollectionsWithMyViews,
  getPermittedCollection
} from './collectionHelper';
import {
  getCollections,
  createCollection,
  updateCollection,
  deleteCollection,
  acceptCollection,
  updateCollectionsOrder
} from 'common/api/collectionApi';
import { createCard } from 'common/api/collectionApi';
import { updateGlobalFiltersByConfigDefaults } from 'pages/drilldown/components/QuickFilterBar/helper';

import LoadingSpinner from 'common/components/LoadingSpinner';
import CardMoveConfirmModal from './CardMoveConfirmModal';
import AddCollection from './AddCollection';
import Collection from './Collection';
import CollectionActionIcons from './CollectionActionIcons';
import CollectionFilters from './CollectionFilters/CollectionFilters';
import ShareInfo from '../ShareInfo';
import PermissionFilter from 'common/components/Filters/PermissionFilter';

import { isMyViews } from 'pages/dashboard/components/CardList/cardHelper';
import {
  collectionCreatedMessage,
  collectionCreatedErrorMessage,
  cardCopiedErrorMessage,
  cardMovedMessage,
  cardCopiedMessage,
  cardMovedErrorMessage,
  metricCopiedMessage,
  metricCopiedErrorMessage,
  metricMovedMessage,
  metricMovedErrorMessage,
  collectionDeleteMessage,
  collectionDeleteErrorMessage
} from 'helpers/toastMessages';
import { getCardsTemplateEntryIds } from 'pages/dashboard/components/Collections/collectionHelper';
import { isNewMetricLabelCard, shouldShowNewLabel } from 'helpers/metricNewLabelHelper';
import { ForgeIcon, ForgeTab, ForgeTabBar, ForgeButton } from '@tylertech/forge-react';
import {
  setForgeTabBarAnimate, setForgeTabBarTransform,
  addCustomAccessbilityForForgeTabBarScroll
} from 'helpers/DomPageHelper';
import { isCollaborateCollection, isCollectionViewAccess } from '../ManageCollection/collaboratorHelper';
import { copyCardInCollection } from 'actions/userCollectionsActions';

class Collections extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.collectionTabRef = React.createRef(null);

    this.state = {
      isLoading: false,
      showCardMoveConfirmModal: false,
      collections: [],
      collectionWithNewLabelCards: []
    };
    this._collectionTabTransformStatus = 'initial';
    this.abortCardsApi = new AbortController();
  }

  componentDidMount() {
    this.fetchCollections();
    addCustomAccessbilityForForgeTabBarScroll(this.collectionTabRef);
    document.addEventListener("visibilitychange", this.checkAndUpdateCollectionsTabTransform);
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.checkAndUpdateCollectionsTabTransform);
  }

  componentDidUpdate(prevProps) {
    const {
      currentCollectionId,
      currentCollectionTagId,
      onCollectionChange,
      commonFilters,
      cardEntries,
      reloadCollection
    } = this.props;
    const { collections } = this.state;
    if (!_.isEqual(currentCollectionId, prevProps.currentCollectionId)) {
      const currentCollection = getCollectionEntry(
        collections,
        currentCollectionId,
        commonFilters,
        currentCollectionTagId
      );
      onCollectionChange(currentCollection);
    }

    if ((_.isEqual(currentCollectionId, prevProps.currentCollectionId) &&
      !_.isEqual(cardEntries, prevProps.cardEntries) && _.isEmpty(cardEntries) &&
      !_.isEmpty(prevProps.cardEntries)) || reloadCollection ) {
      this.fetchCollections();
    }
  }

  checkAndUpdateCollectionsTabTransform = () => {
    if (document.visibilityState == "visible") {
      this.calculateAndSetTransformForCollectionTabs();
    }
  }

  // This calculation use to set collection tab active state.
  calculateAndSetTransformForCollectionTabs = (timeInterval = 50) => {
    setTimeout(() => {
      const { collections } = this.state;
      const { currentUser, currentCollectionId } = this.props;
      const allCollections = getCollectionsWithMyViews(collections, currentUser);
      const findActiveIndex = _.findIndex(allCollections, { id: currentCollectionId })
      const options = {
        forgeTabBarRef: this.collectionTabRef.current,
        activeIndex: findActiveIndex,
        isInitialLoad: true
      };

      setForgeTabBarAnimate(this.collectionTabRef.current);
      setForgeTabBarTransform(options);
      this._collectionTabTransformStatus = 'updated';
    }, timeInterval);
  }

  getNewLabelCards = (collectionWithNewLabelCards, collection) => {
    const isAllMetrics = _.isEqual(collection.id, COLLECTION_ID.ALL_METRICS);

    if (isAllMetrics) {
      return _.map(_.get(collectionWithNewLabelCards, 'all_metrics', []));
    } else {
      return _.chain(collectionWithNewLabelCards).
        flatMapDeep(_.flatten).
        uniqBy('template_card_id').
        value();
    }
  }

  fetchCollections = (sortOrderOption = {}) => {
    const { currentCollectionId, currentCollectionTagId, onCollectionChange,
      commonFilters, collectionDateFilters, globalFilters, reloadCollection,
      isOverwriteCollectionFilter, updateOverwriteCollectionFilter, onReloadCollection } = this.props;

    this.setState({ isLoading: true });
    if(reloadCollection){
      onReloadCollection(false);
    }
    getCollections()
      .then((response) => response.json())
      .then((response) => {
        const {
          collections,
          collection_with_new_card_ids: collectionWithNewLabelCards
        } = response;
        const { collectionsWithUserMovedCards } = collectionWithNewLabelCards || {};
        const newCollections = getPermittedCollection(collections, collectionsWithUserMovedCards);
        const bookmarkCardsFromCollections = _.get(collectionWithNewLabelCards, 'bookmark_cards', []);

        let newCollectionId = currentCollectionId;
        const isMoveCollaborate = _.get(sortOrderOption, 'isMoveCollaborate', false);
        if (isMoveCollaborate) {
          newCollectionId = _.get(sortOrderOption, 'toBeUpdateCollection.id');
        }

        let currentCollection = getCollectionEntry(
          newCollections,
          newCollectionId,
          commonFilters,
          currentCollectionTagId
        );
        if (isOverwriteCollectionFilter) {
          currentCollection.dateFilters = collectionDateFilters;
          currentCollection.collectionFilters = globalFilters;
          if (!_.isEmpty(currentCollection)) {
            this.updateCollection(currentCollection);
            updateOverwriteCollectionFilter(false);
          }
        }
        const newLabelCards = this.getNewLabelCards(collectionWithNewLabelCards, currentCollection);

        this.setState({ collections: newCollections, isLoading: false, collectionWithNewLabelCards}, () => {
          onCollectionChange(currentCollection, newLabelCards, bookmarkCardsFromCollections);
          if (this._collectionTabTransformStatus == 'initial') {
            this.calculateAndSetTransformForCollectionTabs();
          }

          if (!_.isEmpty(sortOrderOption) && !isMoveCollaborate) {
            this.handleUpdateSortOrderCopyCollection(sortOrderOption);
          }

          if (isMoveCollaborate) {
            this.updateCollectionForCollaborate(currentCollectionId, currentCollection, sortOrderOption);
          }

        });
        this.updateCollectionFilterChange(currentCollection);
      });
  };

  updateCollections = (updatedCollection) => {
    const { collections } = this.state;
    const { onCollectionChange } = this.props;
    const newCollections = _.map(collections, (collection) => {
      if (_.isEqual(updatedCollection.id, collection.id)) {
        return updatedCollection;
      } else {
        return collection;
      }
    });
    if (!_.isEmpty(updatedCollection)) {
      this.setState({
        currentCollection: updatedCollection,
        collections: newCollections,
        isCollectionLoading: false,
      });
        onCollectionChange(updatedCollection);
    } else {
      this.setState({ isCollectionLoading: false });
    }
  }

  updateCollection = (newCollection, event) => {
    const { currentUser } = this.props;

    if (!_.isEmpty(currentUser)) {
      this.setState({ isCollectionLoading: true });
      if (event) {
        newCollection['event'] = event;
      }
      updateCollection(newCollection)
        .then((response) => response.json())
        .then((response) => {
          this.updateCollections(response);
        });
    } else {
      this.updateCollections(newCollection);
    }
  }

  updateCollectionForCollaborate = (prevCollectionId, collection, sortOrderOption) => {
    const { currentUser } = this.props;
    const { toBeUpdateCollection } = sortOrderOption;
    const newCollection = _.cloneDeep(collection);
    if (!_.isEmpty(currentUser)) {
      newCollection['is_collaborate_collection'] = true;
      newCollection['manage_collaborate_updated_at'] = (new Date()).toISOString();

      updateCollection(newCollection)
        .then((response) => response.json())
        .then(() => {

          const { collections } = this.state;
          const prevCollection = _.find(collections, { id: prevCollectionId })

          const newCollections = updateEntriesOrder(
            prevCollection,
            toBeUpdateCollection,
            collections,
            true
          );

          this.setState({ collections: newCollections }, () => {
            updateCollectionsOrder(newCollections);

            setTimeout(() => {
              this.fetchCollections();
            }, 200);
          });
        });
    }
  }

  updateCollectionFilterChange = (collection) => {
    const {
      onCollectionFiltersChange,
      onAllMetricFilterChanges
    } = this.props;
    const filters = updateGlobalFiltersByConfigDefaults(
      _.get(collection, 'collectionFilters', []), collection);
    const isAllMetricsCollection = _.isEqual(collection.id, COLLECTION_ID.ALL_METRICS);

    if (isAllMetricsCollection) {
      onAllMetricFilterChanges(filters);
      onCollectionFiltersChange(filters);
    } else {
      onCollectionFiltersChange(filters);
    }
  }

  isMyViewsSelected = () => {
    const { currentCollection } = this.props;
    return isMyViews(currentCollection);
  }

  handleDateFiltersChange = (dateFilters) => {
    const { onCollectionChange, currentCollection, currentCollectionId } = this.props;
    const collectionId = _.isEmpty(currentCollection) ? currentCollectionId : currentCollection.id;
    const isAllMetricsCollection = _.isEqual(collectionId, COLLECTION_ID.ALL_METRICS);

    const newCollection = {
      ...currentCollection,
      dateFilters
    };

    if (isAllMetricsCollection) {
      onCollectionChange(newCollection);
      this.setState({ currentCollection: newCollection });
    } else {
      this.updateCollection(newCollection, 'date_filter_change');
      onCollectionChange(newCollection);
    }
  }

  onCollaborateCollectionChange = (collection) => {
    const { collections } = this.state;
    const newCollections = _.map(collections, (collectionItem) => {
      return collectionItem['id'] == collection['id'] ? collection : collectionItem;
    });
    this.setState({ collections: newCollections }, () => {
      this.props.onUpdateCollection(collection);
    });
  }

  handleCollectionMove = (args) => {
    const {
      draggedCollection,
      targetCollection,
      insertAfter
    } = args;
    const { collections } = this.state;
    const newCollections = updateEntriesOrder(
      targetCollection,
      draggedCollection,
      collections,
      insertAfter
    );
    this.setState({ collections: newCollections }, () => {
      updateCollectionsOrder(newCollections);
    });
  }

  handleUpdateSortOrderCopyCollection = (sortOrderOption) => {
    const { toBeUpdateCollection, isUpdateSortOrder } = sortOrderOption;

    if (!isUpdateSortOrder) {
      return
    }
    const { currentCollection } = this.props;
    const { collections } = this.state;

    const newCollections = updateEntriesOrder(
      currentCollection,
      toBeUpdateCollection,
      collections,
      true
    );

    this.setState({ collections: newCollections }, () => {
      updateCollectionsOrder(newCollections);
    });
  }

  handleCollectionFiltersChange = (collectionFilters) => {
    const {
      currentUser,
      currentCollection,
      onCollectionFiltersChange,
      onAllMetricFilterChanges
    } = this.props;

    const newCollection = {
      ...currentCollection,
      collectionFilters,
    };
    const isAllMetricsCollection = _.isEqual(currentCollection.id, COLLECTION_ID.ALL_METRICS);
    if (!_.isEmpty(currentUser) && !isAllMetricsCollection) {
      this.updateCollection(newCollection, 'collection_filters_change');
    }
    if (isAllMetricsCollection) {
      onAllMetricFilterChanges(collectionFilters);
    }
    onCollectionFiltersChange(collectionFilters);
  }

  handleCollectionChange = (collection) => {
    const { onCollectionChange, updateOverwriteCollectionFilter } = this.props;
    const { collectionWithNewLabelCards } = this.state;
    const newLabelCards = this.getNewLabelCards(collectionWithNewLabelCards, collection);

    onCollectionChange(collection, newLabelCards);

    this.updateCollectionFilterChange(collection);
    updateOverwriteCollectionFilter(false);
  }

  handleEditCollection = (newName) => {
    const { currentUser, currentCollection } = this.props;
    const newCollection = {
      ...currentCollection,
      name: newName,
    };
    if (!_.isEmpty(newName) && !_.isEmpty(currentUser)) {
      this.updateCollection(newCollection)
    }
  };

  handleAddCollection = (collectionName) => {
    const { currentUser } = this.props;
    const { collections } = this.state;
    const lastSortOrder = _.get(_.maxBy(collections, 'sort_order'), 'sort_order', 0);
    if (!_.isEmpty(collectionName) && !_.isEmpty(currentUser)) {
      this.setState({ isCollectionLoading: true });
      createCollection(collectionName, lastSortOrder + 1)
        .then((response) => {
          if (!response.ok) {
            this.setState({ isCollectionLoading: false });
            console.error('error on collection create', response);
            toast.error(collectionCreatedErrorMessage);
            return null;
          }
          return response.json();
        })
        .then((response) => {
          this.setState({
            collections: _.compact(collections.concat([response])),
            isCollectionLoading: false,
          });
          if (!_.isEmpty(response)) {
            toast.success(collectionCreatedMessage);
          }
        });
    }
  };

  handleCollectionAccept = () => {
    const { currentCollection, onAcceptAllBookmarkClick } = this.props;
    const collectionId = _.get(currentCollection, 'id');

    if (isMyViews(currentCollection)) {
      onAcceptAllBookmarkClick();
    } else {
      this.setState({ isCollectionLoading: true });
      acceptCollection(collectionId)
        .then((response) => response.json())
        .then((response) => {
          this.updateCollections(response);
          window.location.reload();
        });
    }
  }

  handleDropIntoCollection = (card, dropCollection) => {
    const { currentCollection } = this.props;
    const isSharedAccepted = _.get(dropCollection, 'is_shared_accepted', false);
    // const isBookmarkCard = !_.isNil(_.get(card, 'userCardEntry.id')) &&
    //   _.isNil(_.get(card, 'userCardEntry.bellerophon_tag_id'));

    if (
      !isDroppableCollection(dropCollection, currentCollection) ||
      !isSharedAccepted ||
      isCollectionViewAccess(dropCollection)
    ) {
      return;
    }
    const isMetricLibrary = _.get(card, 'isMetricLibrary', false);

    const isAllMetrics = _.isEqual(currentCollection.id, COLLECTION_ID.ALL_METRICS) || isMetricLibrary;
    if (isMyViews(dropCollection) && _.get(card, 'viewEntry.isRadar', false)) {
      return;
    }

    if (isMyViews(dropCollection) && _.get(card, 'userCardEntry.isExplorationCard', false)) {
      return;
    }

    if (isAllMetrics || this.isMyViewsSelected()) {
      this.handleCardCopy(card, dropCollection);
    } else {
      this.setState({
        showCardMoveConfirmModal: true,
        dropCard: card,
        dropCollection: dropCollection,
      });
    }
  };

  handleCollectionDelete = () => {
    const { currentUser, currentCollection } = this.props;
    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      deleteCollection(_.get(currentCollection, 'id'), { is_share_rejected: true })
        .then((response) => {
          if (response.ok) {
            this.fetchCollections();
            this.setState({ isLoading: false });
            toast.success(collectionDeleteMessage);
          } else {
            toast.error(collectionDeleteErrorMessage);
          }
        });
    }
  };

  handleUpdateMyCollection = (collection) => {
    const { onCollectionFiltersChange } = this.props
    const { collectionFilters } = collection;
    this.updateCollection(collection);
    onCollectionFiltersChange(collectionFilters);
  }

  handleCardCopy = (card, collection, isMoved = false) => {
    const { templateEntry, viewEntry } = card;
    const { collectionWithNewLabelCards } = this.state;
    const { dispatchCopyCardInCollection } = this.props;
    const isBookmarkCard = _.get(card, 'userCardEntry.isBookmarkCard', false);
    const isExplorationCard = _.get(card, 'userCardEntry.isExplorationCard', false);
    const explorationCardId = _.get(card, 'userCardEntry.card_id')
    let isNewCard = false;
    if (!(this.isMyViewsSelected() || isBookmarkCard)) {
      const newLabelCards = this.getNewLabelCards(collectionWithNewLabelCards, collection);
      const templateCardId = isExplorationCard ? explorationCardId : getCardId(templateEntry, viewEntry);
      const matchedCard = _.find(newLabelCards, { template_card_id: templateCardId });

      isNewCard = isNewMetricLabelCard(viewEntry) && !_.isEmpty(matchedCard)
    }
    const isManageView = isCollaborateCollection(collection);

    const userCardEntryId = _.get(card, 'userCardEntry.id');
    const cardId = this.isMyViewsSelected() || isBookmarkCard ?
      userCardEntryId :
      isExplorationCard ? explorationCardId : getCardId(templateEntry, viewEntry);
    const bookmarkId = this.isMyViewsSelected() || isBookmarkCard ? userCardEntryId : null;
    const collectionId = _.get(collection, 'id');

    this.abortCardsApi.abort();
    this.abortCardsApi = new AbortController();
    this.setState({ isLoading: true });
    dispatchCopyCardInCollection(collectionId,cardId,templateEntry, viewEntry);

    createCard(
      collectionId,
      {
        card_id: cardId, bookmark_id: bookmarkId, isNewCard, isManageView,
        exploration_id: _.get(card, 'userCardEntry.exploration_id'), is_exploration_card: isExplorationCard
      },
      { signal: this.abortCardsApi.signal }
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({ isLoading: false, showCardMoveConfirmModal: false }, () => {
          if (isNewCard) {
            this.fetchCollections();
          }
        });
        if (response.error) {
          let toastMessage = isMoved ? metricMovedErrorMessage : metricCopiedErrorMessage;
          if (isExplorationCard) {
            toastMessage = isMoved ? cardMovedErrorMessage : cardCopiedErrorMessage;
          }
          toast.error(toastMessage);
        } else {
          let toastMessage = isMoved ? metricMovedMessage : metricCopiedMessage;
          if (isExplorationCard) {
            toastMessage = isMoved ? cardMovedMessage : cardCopiedMessage;
          }
          toast.success(toastMessage);
        }
      })
      .catch((error) => {
        const toastMessage = isMoved ? metricMovedErrorMessage : metricCopiedErrorMessage;
        toast.error(toastMessage);
        console.log('Error on Metric copy', error);
        this.setState({ isLoading: false, showCardMoveConfirmModal: false });
      });
  };

  handleConfirmModalClose = (e) => {
    this.setState({ showCardMoveConfirmModal: false });
    if (e) {
      e.stopPropagation();
    }
  };

  renderCardMoveModal() {
    const { onCardDelete, currentCollection } = this.props;
    const { showCardMoveConfirmModal, dropCard, dropCollection } = this.state;
    if (!showCardMoveConfirmModal) {
      return null;
    }
    return (
      <CardMoveConfirmModal
        dropCard={dropCard}
        onCardDelete={onCardDelete}
        currentCollection={currentCollection}
        dropCollection={dropCollection}
        onCardCopy={this.handleCardCopy}
        onClose={this.handleConfirmModalClose}
      />
    );
  }

  renderAddCollectionOption() {
    const { currentUser } = this.props;
    return (
      <AddCollection
        onAddCollection={this.handleAddCollection}
        currentUser={currentUser}
      />
    );
  }

  updateCurrentIndex = (currentActiveIndex) => {
    this.collectionTabRef.current.activeIndex = currentActiveIndex;
  }

  renderCollectionEntry = (collection, index) => {
    const { bookmarks, currentCollection, currentCollectionId } = this.props;
    const { collectionWithNewLabelCards } = this.state;
    if (!collection) {
      return null;
    }

    const isBookmarkCollection = isMyViews(collection);
    const isAllMetricsCollection = _.isEqual(currentCollection.id, COLLECTION_ID.ALL_METRICS);
    const sharedCount = _.filter(bookmarks, (bookmark) => {
      return !bookmark['is_hidden'] && !_.get(bookmark, 'is_shared_accepted', true);
    }).length;

    const isUserCollection = _.isEqual(
      _.get(collection, 'type'),
      COLLECTION_TYPES.USER
    );
    const showNewLabel = shouldShowNewLabel(collection, collectionWithNewLabelCards);

    return (
      <Collection
        collectionIndex={index}
        collectionTabRef={this.collectionTabRef}
        showNewLabel={showNewLabel}
        onCardDrop={this.handleDropIntoCollection}
        onCollectionEdit={this.handleEditCollection}
        onCollectionChange={this.handleCollectionChange}
        onCollectionMove={this.handleCollectionMove}
        isUserCollection={isUserCollection}
        isActiveCollection={_.isEqual(currentCollection.id, collection.id)}
        collection={collection}
        sharedCount={sharedCount}
        isBookmarkCollection={isBookmarkCollection}
        isAllMetricsCollection={isAllMetricsCollection}
        isDroppable={isDroppableCollection(collection, currentCollection)}
        key={_.get(collection, 'id')}
        currentCollectionId={currentCollectionId}
        onUpdateCurrentIndex={this.updateCurrentIndex} />
    );
  };

  renderForgeCollections = () => {
    const { collections } = this.state;
    const { currentUser, currentCollectionId } = this.props;
    const allCollections = getCollectionsWithMyViews(collections, currentUser);

    const defaultValue = {
      layoutMode: 'clustered',
      layoutAlign: 'start',
      underline: false,
      stacked: false,
      disabled: false,
    }

    const findActiveIndex = _.findIndex(allCollections, { id: currentCollectionId })

    return (
      <ForgeTabBar
        on-forge-tab-bar-activate={this.updateCollectionIndex}
        activeTab={findActiveIndex}
        layoutMode={defaultValue.outMode}
        layoutAlign={defaultValue.layoutAlign}
        underline={defaultValue.underline}
        stacked={defaultValue.stacked}
        style={{ flex: 1 }}>

        {_.map(allCollections, this.renderCollectionEntry)}
        {this.renderAddCollectionOption()}

      </ForgeTabBar>
    )
  }

  renderCollections = () => {
    const { collections } = this.state;
    const { currentUser } = this.props;
    const allCollections = getCollectionsWithMyViews(collections, currentUser);

    return (
      <div className="metric-tab-wrapper flex-grow-1">
        <div className="left-shadow"></div>
        <div className="metric-tab flex-grow-1">
          {_.map(allCollections, this.renderCollectionEntry)}
          {this.renderAddCollectionOption()}
        </div>
        <div className="right-shadow"></div>
      </div>
    );
  };

  goToSubscriptionPage = () => {
    window.location.href = "/#!/app/subscriptions";
  }

  renderManageForgeIcon() {
    const { currentUser } = this.props;
    if (_.isEmpty(currentUser)) {
      return null
    }

    return (
      <ForgeTab disabled={false} onClick={this.goToSubscriptionPage}>
        <ForgeButton type="flat">
          <button type="button" aria-label="settings">
            <ForgeIcon name="animation" />
            <span>Settings</span>
          </button>
        </ForgeButton>
      </ForgeTab>
    )
  }

  renderManageIcon() {
    const { currentUser, onManageClick } = this.props;
    if (_.isEmpty(currentUser)) {
      return null
    }

    return (
      <ForgeTab disabled={false}>
        <NavLink
          onClick={onManageClick}
          className="text-primary link ml-2 pb-0 pb-md-3"
          to="/app/subscriptions"
          aria-label="settings">
          <i className="icons-collections-icon" />
          <span className="d-none d-md-inline-block ml-1">Settings</span>
        </NavLink>
      </ForgeTab>
    )
  }

  render() {
    const { isLoading, isCollectionLoading } = this.state;
    const {
      availableTemplateIds,
      isCardsLoading,
      bookmarks,
      currentCollection,
      currentUser,
      collectionFilters,
      cardEntries,
      commonFilters,
      updateOverwriteCollectionFilter,
      isManageMetricLibrary,
      onHandleMetricLibrary
    } = this.props;

    const isSharedAccepted = _.get(currentCollection, 'is_shared_accepted', true);
    const allMetricsCollection = getAllMetricCollection(commonFilters);
    const templateIds = getCardsTemplateEntryIds(cardEntries);
    const defaultValue = {
      layoutMode: 'clustered',
      layoutAlign: 'start',
      underline: false,
      stacked: false,
      disabled: false,
    }

    return (
      <>
        <LoadingSpinner isLoading={isLoading || isCollectionLoading} />
        {this.renderCardMoveModal()}

        <div className="collections-tabs" ref={this.collectionTabRef}>
          {this.renderForgeCollections()}
          <ForgeTabBar activeTab={0}
            className="allmetrics-and-settings"
            layoutMode={defaultValue.outMode}
            layoutAlign={defaultValue.layoutAlign}
            underline={defaultValue.underline}
            stacked={defaultValue.stacked} >
            {this.renderCollectionEntry(allMetricsCollection)}
            {this.renderManageForgeIcon()}
          </ForgeTabBar>
        </div>
        <ShareInfo
          bookmarks={bookmarks}
          onShareDelete={this.handleCollectionDelete}
          onShareAccept={this.handleCollectionAccept}
          currentCollection={currentCollection}
          currentUser={currentUser} />
        <div className="collection-action-header container-fluid">
          {isSharedAccepted ? <PermissionFilter
            showPermissionContainer={true}
            templateIds={templateIds}
          /> : null}
          <div className="order-md-1 order-2 mt-2 mt-md-0">
            {(isSharedAccepted) ?
              <CollectionFilters
                disabledFilters={isCollectionViewAccess(currentCollection)}
                isMyViewCollection={isMyViews(currentCollection)}
                hideShowMore={true}
                currentUser={currentUser}
                cardEntries={cardEntries}
                availableTemplateIds={availableTemplateIds}
                dateFilters={_.get(currentCollection, 'dateFilters', {})}
                onDateFiltersChange={this.handleDateFiltersChange}
                currentCollection={currentCollection}
                onCollectionFiltersChange={this.handleCollectionFiltersChange}
                collectionFilters={collectionFilters} /> : null}
          </div>

          {isSharedAccepted ? <CollectionActionIcons
            currentUser={currentUser}
            dateFilters={_.get(currentCollection, 'dateFilters', {})}
            currentCollection={currentCollection}
            onFetchCollections={this.fetchCollections}
            onUpdateCollection={this.handleUpdateMyCollection}
            // onCollectionChange={this.onCollaborateCollectionChange}
            onCollectionChange={this.handleCollectionChange}
            onCollaborateCollectionChange={this.onCollaborateCollectionChange}
            cardEntries={cardEntries}
            areCollectionsLoaded={isCardsLoading}
            updateOverwriteCollectionFilter={updateOverwriteCollectionFilter}
            isManageMetricLibrary= {isManageMetricLibrary}
            onHandleMetricLibrary={onHandleMetricLibrary}
          /> : null}
        </div>
      </>
    );
  }
}

Collections.propTypes = {
  onCardDelete: PropTypes.func,
  onCollectionChange: PropTypes.func,
  onAcceptAllBookmarkClick: PropTypes.func,
  onCollectionFiltersChange: PropTypes.func,
  onAllMetricFilterChanges: PropTypes.func,
  onManageClick: PropTypes.func,
  currentCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentCollectionTagId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentUser: PropTypes.object,
  commonFilters: PropTypes.object,
  allMetricFilters: PropTypes.array,
  collectionFilters: PropTypes.array,
  availableTemplateIds: PropTypes.array,
  cardEntries: PropTypes.array,
  bookmarks: PropTypes.array,
  currentCollection: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  isCardsLoading: PropTypes.bool,
  collectionDateFilters: PropTypes.object,
  globalFilters: PropTypes.array,
  isOverwriteCollectionFilter: PropTypes.bool,
  updateOverwriteCollectionFilter: PropTypes.func,
  onUpdateCollection: PropTypes.func,
  onUpdateCollaborateToggleModal: PropTypes.func,
  isBookmarkCard: PropTypes.bool,
  dispatchCopyCardInCollection: PropTypes.func,
  isManageMetricLibrary: PropTypes.bool,
  onHandleMetricLibrary: PropTypes.func,
  reloadCollection: PropTypes.bool,
  onReloadCollection: PropTypes.func
};

const mapDispatchToProps =  {
  dispatchCopyCardInCollection: copyCardInCollection
};

function mapStateToProps() {
  return {
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Collections);
