import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ForgeBadge,
  ForgeDivider,
  ForgeDrawer,
  ForgeIcon,
  ForgePopup
} from '@tylertech/forge-react';

import { PopupAnimationType } from '@tylertech/forge';
import MetricSearch from './MetricSearch';
import { getAllMetricEntries, getBookmarkMetricEntries } from 'helpers/templateHelper';
import MetricLibraryTabs from './MetricLibraryTabs';
import ManageMetricLibrary from './ManageMetricLibrary';
import LibraryTitle from './LibraryTitle';
import { LIBRARY_TYPE, LIST_OF_LIBRARIES } from 'appConstants';

function MetricDrawer(props) {
  const { metricLibraryOption } = props;
  const {
    isManageMetricLibrary,
    onHandleMetricLibrary,
    cardEntries,
    bookmarkEntries,
    templateEntries
  } = metricLibraryOption;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLibraryType, setSelectedLibraryType] = useState('');
  const [metricCardEntries, setMetricCardEntries] = useState(cardEntries);
  
  const targetInfoRef = useRef();  

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  })

  useEffect(() => {
    if(_.isEmpty(selectedLibraryType)) {
      setMetricCardEntries([]);
    } else {
      const metricEntries =  selectedLibraryType === LIBRARY_TYPE.ALL_METRICS ? 
      cardEntries : bookmarkEntries;
      setMetricCardEntries(metricEntries);
    }
  }, [selectedLibraryType])


  const handleSelectedLibraryType = (libraryType) => {
    setSelectedLibraryType(libraryType);
  }

  const handleClickOutside = (e) => {
    if (targetInfoRef && targetInfoRef.current
      && !targetInfoRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }

  const handleSearchMetric = (searchMetricName) => {
    let metricCards =[];
    if(selectedLibraryType === LIBRARY_TYPE.ALL_METRICS) { 
      metricCards = getAllMetricEntries(templateEntries, searchMetricName);
    } else if(selectedLibraryType === LIBRARY_TYPE.MY_SAVED_VIEWS) {
      metricCards = getBookmarkMetricEntries(bookmarkEntries, searchMetricName);
    }
    
    setMetricCardEntries(metricCards);
  }

  const renderMetricInfo = () => {
    const defaultOption = {
      placement: 'bottom',
      animationType: PopupAnimationType.Dropdown,
      offset: { x: 0, y: 0 }
    }
    return (
      <div className='mt-1 cursor-pointer'>
        <ForgeIcon name="info" ref={targetInfoRef} onClick={() => setIsOpen(!isOpen)}></ForgeIcon>
        <ForgePopup
          targetElementRef={targetInfoRef}
          open={isOpen}
          onDismiss={() => setIsOpen(false)}
          options={defaultOption}>
          <div style={{ width: '256px', padding: '15px' }} className="forge-typography--body1">
            To add a card to your collection,
            simply drag it from the library and drop it into the collection workspace.
          </div>
        </ForgePopup>
      </div>
    );
  }
  
  const renderManageLibrary = () => {
    if(_.isEmpty(selectedLibraryType)) {
      return <ManageMetricLibrary onSelectLibraryType={handleSelectedLibraryType} />;
    }

    return <MetricLibraryTabs 
      metricLibraryOption={metricLibraryOption} 
      libraryType={selectedLibraryType}
      metricCardEntries={metricCardEntries} 
      onSelectLibraryType={handleSelectedLibraryType} />
  }

  const renderSearchMetric = () => {
    if(_.isEmpty(selectedLibraryType)){
      return null;
    }

    return (
      <MetricSearch selectedLibraryType={selectedLibraryType} 
        onHandleSearchMetric={handleSearchMetric} />
    )
  }

  const renderLibraryTitle = () => {
    if(_.isEmpty(selectedLibraryType)) {
      return null;
    }

    const selectedLibrary = _.find(LIST_OF_LIBRARIES, { type: selectedLibraryType });

    return (
      <LibraryTitle 
        onClickBack={handleSelectedLibraryType} 
        selectedLibrary={selectedLibrary} />
    )
  }

  return (
    <div className='forge-metric-drawer'>
      <ForgeDrawer direction="right" slot="right" open={isManageMetricLibrary}>
        <div className='metric-drawer-wrapper'>
          <div className='metric-drawer-head'>
            <div className='d-flex align-items-center gap-10'>
              <div className='forge-typography--title title'>Metric library</div>
              {renderMetricInfo()}
              <ForgeBadge>Beta</ForgeBadge>
            </div>
            <div className='cursor-pointer mt-2' onClick={() => onHandleMetricLibrary(false)}>
              <ForgeIcon name="close"></ForgeIcon>
            </div>
          </div>
          <ForgeDivider/>
          {renderSearchMetric()}
          {renderLibraryTitle()}
          {renderManageLibrary()}
        </div>
      </ForgeDrawer>
    </div>
  )
}

MetricDrawer.propTypes = {
  metricLibraryOption: PropTypes.object,
}

export default MetricDrawer
