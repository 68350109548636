import React from 'react';
import PropTypes from 'prop-types';

function ColorBox({ boxColor, width, height, extraClassName }) {
  const boxStyle = {
    width: width,
    height: height,
    backgroundColor: boxColor,
  };

  const boxClass =   extraClassName ? `snap-shot-border ${extraClassName}` : 'snap-shot-border';
  return (
    <div style={boxStyle} className={boxClass}></div>
  );
}

ColorBox.propTypes = {
  boxColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  extraClassName: PropTypes.string
};

ColorBox.defaultProps = {
  boxColor: 'black',
  width: 15,
  height: 15,
  extraClassName: ''
};

export default ColorBox;
