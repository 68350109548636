import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Dropdown } from 'react-bootstrap';
import UploadUserContent from 'common/components/UploadUserContent/UploadUserContent';
import Pagination from 'common/components/Pagination/Pagination';
import ConfirmationDialogue from './ConfirmationDialogue';
import PermissionFields from './PermissionFields';
import SecurityRoles from './SecurityRoles';
import AddUserSection from './AddUserSection';
import CustomTooltip from 'common/components/CustomTooltip';
import { buildQueryString } from 'helpers/HttpHelper';
import { importUsers } from 'common/api/adminApi';
import { Form, Alert } from 'react-bootstrap';
import { SHARE_EMAIL_SPLITTER, SHARE_EMAIL_LIMIT } from 'appConstants';
import { trackEvent } from 'helpers/eventTracking';
import BulkUserManagement from 'common/components/BulkUserManagement';
import {
  welcomeEmailMessage,
  welcomeEmailErrorMessage,
  userRemovalMessage,
  userRemovalErrorMessage,
  userAddingMessage,
  userAddingErrorMessage,
  userUploadMessage,
  userUploadErrorMessage
} from 'helpers/toastMessages';
import {
  enableSecurityRoles,
  getSecurityRoles,
  isRowLevelPermissionEnabled,
  getDefaultSecurityRole,
  viewLastLoginInfo,
  allowDefaultWelcomeEmail
} from 'common/config/customerConfiguration';
import {
  getDefaultPermissionMappingFields,
  splitUserEmails,
  validateImportUsers,
  isNewUsersImported,
  getUserListDataBasedOnSearchValue
} from './permissionHelper';
import { DATE_TIME_FORMAT, USER_SEARCH_TYPES } from 'appConstants';
import SearchUserList from './SearchUserList';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { toast } from 'react-toastify';
import { isAnyWildCardUsers } from 'pages/SubscriptionsManager/components/Bookmark/helper';

const moment = extendMoment(Moment);
const ITEM_PER_PAGE = 10;
class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: '',
      isEmailLimitExceed: false,
      searchText: '',
      users: [],
      wildCardUser: [],
      userPaginateData: [],
      currentPage: 1,
      totalRecordsCount: 0,
      showDialogue: false,
      selectedMail: '',
      currentUserPermission: {},
      hiddenUsers: [],
      isAddUserSection: false,
      showUploadUserModal: false,
      searchTitle: USER_SEARCH_TYPES.EMAIL,
      sortObject: {}
    };

    this.searchEmail = React.createRef();
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.getConfigUsers();
  }

  componentDidUpdate(prevProps) {
    const { configData } = this.props;
    if (!_.isEqual(configData, prevProps.configData)) {
      this.getConfigUsers();
    }
  }

  getConfigUsers() {
    const { configData, currentUser } = _.cloneDeep(this.props);
    const splittedUserEmails = splitUserEmails(configData, currentUser, false);
    let normalUser = splittedUserEmails['normalUser'];
    const currentUserPermission = _.find(configData, (user) => {
      return _.isEqual(user['email'].toLowerCase(), currentUser['email'].toLowerCase());
    });

    const totalRecordsCount = _.size(normalUser);
    let userPaginateUser = _.take(normalUser, ITEM_PER_PAGE);

    const securityRoles = getSecurityRoles();
    normalUser.map((user) => {
      const currentRole = _.find(securityRoles, {role_id: user.role});
      return user.role_name = _.get(currentRole, 'name', '');
    });

    // TODO remove this logic... so much confusion
    this.setState({
      users: normalUser,
      wildCardUser: splittedUserEmails['wildCardUser'],
      totalRecordsCount,
      userPaginateData: userPaginateUser,
      currentPage: 1,
      currentUserPermission,
      hiddenUsers: splittedUserEmails['hiddenUsers']
    });

  }

  onUserEmailChange = (emails) => {
    this.setState({ userEmail: emails.join(SHARE_EMAIL_SPLITTER) });
  }

  onAddNewUserClick = () => {
    this.setState({
      isAddUserSection: true
    });
  }

  onCancelUser = () => {
    this.setState({
      isAddUserSection: false
    });
  }

  onAddUserClick = (options) => {
    const { users, userEmail, currentUserPermission } = this.state;
    const { currentUser } = this.props;
    const securityRoles = getSecurityRoles();
    const currentRole = _.find(securityRoles, (role) => {
      return role.role_id == getDefaultSecurityRole()
    });
    trackEvent('click_add_user');
    const userEmails = _.get(options, 'userEmails', []);
    const alreadyExistUsers = _.get(options, 'alreadyExistUsers', []);

    let usersFromList = _.cloneDeep(users);
    const nickName = _.capitalize(_.get(currentUser, 'nickname', ''));
    const currentUserEmail = _.get(currentUser, 'email', '');
    const canManageUser = _.isEqual(_.get(currentRole, 'can_manage_user', ''), 'true');
    const canManageBranding = _.isEqual(_.get(currentRole, 'can_manage_branding', ''), 'true');
    const canManageBulkUserManagement = _.isEqual(_.get(currentRole, 'can_bulk_user_management', ''), 'true');
    const canManageCollections = _.isEqual(_.get(currentRole, 'can_manage_collections', ''), 'true');
    const canRestrictCollections = _.isEqual(_.get(currentRole, 'can_restrict_collections', ''), 'true');
    const canManageApp = (canManageUser || canManageBranding);
    let newUsers = [];
    _.each(_.uniq(userEmails), (userEmail) => {
      const newUser = {
        email: userEmail.toLowerCase(),
        isAdmin: _.toString(canManageApp),
        isManageUser: _.toString(canManageUser),
        isManageBranding: _.toString(canManageBranding),
        isBulkUserManagement: _.toString(canManageBulkUserManagement),
        isManageCollections: _.toString(canManageCollections),
        canRestrictCollections: _.toString(canRestrictCollections),
        send_welcome_email: allowDefaultWelcomeEmail ? 'true' : 'false',
        send_admin_welcome_email: _.toString(canManageUser),
        welcome_email_sent_at: '',
        admin_welcome_email_sent_at: '',
        is_email_sent: 'false',
        user_created_at: moment.utc(moment()).format(),
        user_created_from: 'administration_page',
        role: _.get(currentRole, 'role_id'),
        last_visit_at: '',
        last_logout_at: '',
        user_created_by: _.isEmpty(nickName) ? currentUserEmail : nickName,
        permission_mapping: getDefaultPermissionMappingFields(currentUserPermission)
      };
      newUsers.push(newUser);
      usersFromList.push(newUser);
    });

    const totalRecordsCount = _.size(usersFromList);

    this.setState({
      isEmailLimitExceed: false,
      users: usersFromList,
      userEmail: '',
      searchText: '',
      totalRecordsCount,
      isAddUserSection: !_.isEmpty(alreadyExistUsers)
    });
    const loggerMessage = `New user ${userEmail} added by ${currentUserEmail} ` +
      `at ${moment().format(DATE_TIME_FORMAT)} UTC`;

    this.handleUpdatedUser({
      updatedUser: newUsers,
      updateType: 'create',
      toastMessage: userAddingMessage,
      loggerMessage,
      toastErrorMessage: userAddingErrorMessage,
      isPermissionUpdate: false
    });
  }

  handleConfirmation = (isConfirm) => {
    const { users, selectedMail } = this.state;
    const { currentUser } = this.props;
    const currentUserEmail = _.get(currentUser, 'email', '');
    if (isConfirm) {
      let newUsers = _.cloneDeep(users);
      const index = _.findIndex(newUsers, (user) => { return user.email == selectedMail; });
      newUsers.splice(index, 1);
      const totalRecordsCount = _.size(newUsers);

      const loggerMessage = `User ${selectedMail} removed by ${currentUserEmail} ` +
        `at ${moment().format(DATE_TIME_FORMAT)} UTC`;

      this.handleUpdatedUser({
        updatedUser: {email: selectedMail},
        updateType: 'delete',
        toastMessage: userRemovalMessage,
        loggerMessage,
        toastErrorMessage: userRemovalErrorMessage,
        isPermissionUpdate: false
      });
      this.setState({
        users: newUsers,
        searchText: '',
        totalRecordsCount,
        selectedMail: '',
        showDialogue: false
      });
    } else {
      this.setState({
        selectedMail: '',
        showDialogue: false
      })
    }
  }

  onRemoveUser(email) {
    this.setState({
      showDialogue: true,
      selectedMail: email
    });
  }

  onEmailActionClick(user) {
    const { users } = this.state;
    const { currentUser } = this.props;
    const nickName = _.capitalize(_.get(currentUser, 'nickname', ''));
    const currentUserEmail = _.get(currentUser, 'email', '');
    let updatedUser = {}
    const newUsers = _.map(users, (userEntry) => {
      if (userEntry.email == user.email) {
        updatedUser = {
          ...userEntry,
          send_welcome_email: allowDefaultWelcomeEmail ? "true" : 'false',
          welcome_email_sent_at: '',
          user_created_by: _.isEmpty(nickName) ? currentUserEmail : nickName,
        }
        return updatedUser
      } else {
        return userEntry;
      }
    });
    this.setState({ users: newUsers }, () => {
      this.handleUpdatedUser({
        updatedUser: updatedUser,
        updateType: 'update',
        toastMessage: welcomeEmailMessage,
        toastErrorMessage: welcomeEmailErrorMessage,
        isPermissionUpdate: false
      });
    })
  }

  handleUpdatedUser(options) {
    const { currentPage } = this.state;
    const {
      updatedUser, updateType, toastMessage,
      loggerMessage, toastErrorMessage, isPermissionUpdate
    } = options;
    const { handleUserSecurityChange } = this.props;

    handleUserSecurityChange({
      userConfig: updatedUser,
      updateType,
      email: _.get(updatedUser, 'email'),
      toastMessage,
      toastErrorMessage,
      loggerMessage,
      isPermissionUpdate
    });
    this.loadPageData(currentPage);
  }

  handleSearchEmail = (event) => {
    let { currentPage } = this.state;

    this.setState({ searchText: event.target.value }, () => {
      this.loadPageData(currentPage);
    });
}

  onSearchDropDownSelect = (searchOption) => {
    this.setState({ searchTitle: searchOption });
  }

  onCloseAlert = (isEmailLimitExceed) => {
    this.setState({ isEmailLimitExceed });
  }

  onFocusUserEmail = () => {
    trackEvent('user_email_entered');
  }

  loadPageData = (pageNo, newUsers = null) => {
    let { users, searchText, searchTitle, sortObject } = this.state;

    let isSearchText = !_.isEmpty(searchText)

    let filteredUsers;
    if (isSearchText) {
      filteredUsers = getUserListDataBasedOnSearchValue(searchText, users, searchTitle);
      // filteredUsers = this.getSearchData(this.searchEmail.current.value);
    }

    let copyOfUsers = (_.isEmpty(newUsers) && isSearchText) ? _.cloneDeep(filteredUsers) :
      (!_.isEmpty(newUsers) ? _.cloneDeep(newUsers) : _.cloneDeep(users));


    // sort users list
    const sortColumn = _.get(sortObject, 'sortColumn');
    const isSortable = !_.isEmpty(sortColumn)

    if (isSortable) {
      copyOfUsers = _.orderBy(copyOfUsers, [sortColumn], [sortObject.sortBy]);
    }


    let selectedIndex = (pageNo * ITEM_PER_PAGE) - ITEM_PER_PAGE;

    const totalRecordsCount = _.size(copyOfUsers);

    let selectedUser = [];
    if (selectedIndex > 0 && totalRecordsCount > selectedIndex) {
      copyOfUsers.splice(0, selectedIndex);
    }

    selectedUser = _.take(copyOfUsers, ITEM_PER_PAGE);

    this.setState({
      currentPage: pageNo,
      totalRecordsCount,
      userPaginateData: selectedUser
    });
  }

  handleSwitchPage = (switchDirection) => {
    let { currentPage } = this.state;
    let selectedPageNo = (switchDirection === 'next') ? (currentPage + 1) : (currentPage - 1);
    this.loadPageData(selectedPageNo);
  }

  handleUserSettingChange = (email, options) =>{
    const {
      isPermissionUpdate, permissionMapping, newRole,
      toastMessage, loggerMessage, toastErrorMessage
    } = options;
    const { users } = this.state;
    const { currentUser } = this.props;
    const currentUserEmail = _.get(currentUser, 'email', '');
    const canManageUser = _.isEqual(_.get(newRole, 'can_manage_user', ''), 'true');
    const canManageBranding = _.isEqual(_.get(newRole, 'can_manage_branding', ''), 'true');
    const canManageBulkUserManagement = _.isEqual(_.get(newRole, 'can_bulk_user_management', ''), 'true');
    const canManageCollections = _.isEqual(_.get(newRole, 'can_manage_collections', ''), 'true');
    let updatedUser = {};
    const newUsers = _.map(users, (userEntry) => {
      if (userEntry.email == email) {
        let newUser = { ...userEntry };
        if (permissionMapping) {
          newUser['permission_mapping'] = permissionMapping
        }
        if (newRole) {
          if (newUser['isManageUser'] === 'false' && canManageUser) {
            newUser['send_admin_welcome_email'] = 'true';
          }
          if (!canManageUser) {
            newUser['send_admin_welcome_email'] = 'false';
          }

          newUser['isManageUser'] = _.toString(canManageUser);
          newUser['isManageBranding'] = _.toString(canManageBranding);
          newUser['isBulkUserManagement'] = _.toString(canManageBulkUserManagement);
          newUser['isManageCollections'] = _.toString(canManageCollections);
          newUser['role'] = newRole.role_id;
        }
        updatedUser = newUser;
        return newUser
      } else {
        return userEntry;
      }
    });

    let newLoggerMessage = loggerMessage;
    if (loggerMessage) {
      newLoggerMessage += ` by ${currentUserEmail} at ${moment().format(DATE_TIME_FORMAT)} UTC`;
    }
    this.setState({ users: newUsers }, () => {
      this.handleUpdatedUser({
        updatedUser,
        updateType: 'update',
        toastMessage,
        newLoggerMessage, toastErrorMessage, isPermissionUpdate});
    })
  }

  renderErrorMessage = () => {
    const { isEmailLimitExceed } = this.state;
    if (!isEmailLimitExceed) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <Alert variant="danger" onClose={() => this.onCloseAlert(false)} dismissible>
            Maximum user email limit is {SHARE_EMAIL_LIMIT}
          </Alert>
        </div>
      </div>
    );
  }

  renderConfirmationDialogue() {
    const { showDialogue } = this.state

    if (!showDialogue) {
      return
    }

    const message = `Are you sure you want to remove this user's access?
     They will no longer be able to view the application.`;
    return (<ConfirmationDialogue
      message={message}
      messageTitle={'Remove user'}
      onConfirmationCallBack={this.handleConfirmation} />)
  }

  renderPagination() {
    // TODO remove this and use react-js-pagination
    const { currentPage, totalRecordsCount } = this.state;
    return (
      <Pagination
        currentPage={currentPage}
        itemsPerPage={ITEM_PER_PAGE}
        totalRecordsCount={totalRecordsCount}
        switchPage={this.handleSwitchPage} />
    );
  }


  exportTemplateDownload = (onlyHeader) => {
    const newParams = {
      only_header: onlyHeader
    };
    var csvDataUrl =`/api/export_permission_data.csv?${buildQueryString(newParams)}`;
    window.open(csvDataUrl, '_blank');
  }

  onClosePopupModel = () => {
    this.setState({showUploadUserModal : false})
  }

  showUserImportModal = () => {
    this.setState({showUploadUserModal : true});
  }

  onCsvUpload = (file) => {
    const { enableLoading } = this.props;

    if(!_.isUndefined(file)){
      const formData = new FormData();
      formData.append('users_file', file);
      this.setState({showUploadUserModal : false})
      enableLoading(true);
      importUsers(formData)
        .then((response) => response.json())
        .then((response) => {
          this.onImportUserList(response);
        });
    }
  }

  onImportUserList = (usersFromCsv) => {
    const { currentUser, configData, enableLoading } = this.props;
    const { wildCardUser, hiddenUsers } = this.state;
    const currentUserEmail = _.get(currentUser, 'email', '');
    const userEmails = _.map(usersFromCsv, 'email');
    if(isAnyWildCardUsers(userEmails)){
      enableLoading(false);
      toast.error('User email cannot contain an asterisk. ' +
        'Please remove any user emails containing an asterisk and try again');
      return;
    }

    const usersFromList =  validateImportUsers(configData, currentUser, usersFromCsv);
    const isNewUserAdded = isNewUsersImported(configData, usersFromCsv);
    let userUploadToastMessage = isNewUserAdded ? userUploadMessage : null;
    const totalRecordsCount = _.size(usersFromList);

    this.setState({
      isEmailLimitExceed: false,
      users: usersFromList,
      userEmail: '',
      searchText: '',
      totalRecordsCount,
      isAddUserSection: false
    });
    const loggerMessage = `Bulk User Imported by ${currentUserEmail} ` +
      `at ${moment().format(DATE_TIME_FORMAT)} UTC`;

    this.handleUpdatedUser({
      updatedUser: usersFromList,
      updateType: 'create',
      toastMessage: userUploadToastMessage,
      loggerMessage,
      toastErrorMessage: userUploadErrorMessage,
      isPermissionUpdate: false
    });

    if(!isNewUserAdded && (!_.isEmpty(wildCardUser) || !_.isEmpty(hiddenUsers))){
      this.getConfigUsers();
    }
  }

  renderModalContent = () => {
    const { showUploadUserModal } = this.state;

    return showUploadUserModal && (
      <UploadUserContent
        onClose={this.onClosePopupModel}
        onUpload={this.onCsvUpload}
        onTemplateDownload={this.exportTemplateDownload}
      />
    )  ;
  }

  renderBulkManagementSection() {

    const { userFromBellerophon } = this.props;
    return (
      <BulkUserManagement
        userFromBellerophon={userFromBellerophon}
        showUserImportModal={this.showUserImportModal}
        exportTemplateDownload={this.exportTemplateDownload}
      />
    );
  }

  renderPermissionOptions(user) {
    const { currentUser } = this.props;
    const { currentUserPermission } = this.state;
    if (!isRowLevelPermissionEnabled()) {
      return null;
    }
    const { email, permission_mapping } = user;
    return (
      <td>
        <div className="permission-fields-wrapper">
          <PermissionFields
            onPermissionChange={this.handleUserSettingChange}
            email={email}
            user={user}
            currentUser = {currentUser}
            currentUserPermission={currentUserPermission}
            permissionMapping={permission_mapping} />
        </div>
      </td>
    )
  }

  renderSecurityRoles(user,index) {
    const { currentUser } = this.props;
    const { currentUserPermission } = this.state;
    const securityRoles = getSecurityRoles();
    let currentUserRole = {};
    if(!_.isEmpty(currentUserPermission)){
      const { role } = currentUserPermission;
      currentUserRole = _.find(securityRoles, {role_id: role});
    }

    if (!enableSecurityRoles()) {
      return;
    }

    if(_.isEqual(user['email'], currentUser['email'])){
      return(
        <td>
          <CustomTooltip label="This option cannot be changed." placement='left'>
            <div className='security-roles-dropdown forge-typography--body1'>
              {_.get(currentUserRole, 'name')}
            </div>
          </CustomTooltip>
        </td>
      )
    }

    return (
      <SecurityRoles
        onRoleChange={this.handleUserSettingChange}
        user={user}
        trIndex={index}
        currentUserRole={currentUserRole}/>
    )
  }

  renderLastLogin(user) {
    const { last_visit_at } = user;
      const lastVisit = !_.isEmpty(last_visit_at) ?
        moment(last_visit_at).format('ll') : '';
    return(
      <td>{lastVisit}</td>
    );
  }

  renderActions(user) {
    const { currentUser } = this.props;
    const { email } = user;
    const isUserNotReceivedAnEmail = (_.isEqual(_.get(user, 'send_welcome_email'), "false") &&
      _.isEqual(_.get(user, 'is_email_sent'), "false"));
    const isEmailSent = _.isEqual(_.get(user, 'is_email_sent'), "true");
    const showSendEmailButton = ((isUserNotReceivedAnEmail || isEmailSent) && allowDefaultWelcomeEmail);
    const sendEmailButtonText = isEmailSent ? 'Resend Welcome Email' : 'Send Welcome Email';
    const showRemoveUserButton = !_.isEqual(user['email'], currentUser['email']);
    return (
      <td className="actions">
        {(showSendEmailButton || showRemoveUserButton) &&
          <Dropdown>
            <Dropdown.Toggle
              aria-label="Actions"
              className="btn-sm p-0 bg-transparent border-0"
              variant="light"
            ><i className="icons-ellipsis-v"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {showSendEmailButton ? <Dropdown.Item onClick={() => this.onEmailActionClick(user)}>
                {sendEmailButtonText}</Dropdown.Item> : null}
                {showRemoveUserButton ? <Dropdown.Item onClick={() => this.onRemoveUser(email)}>
                Remove user</Dropdown.Item> : null}
            </Dropdown.Menu>
          </Dropdown>
        }
      </td>
    );
  }


  onClickTableSortedHeader(columnName, sortBy = ''){
    const { sortObject, currentPage } = this.state;

    let sortType = 'asc';
    let clickCount = sortObject.clickCount;
    if (sortObject.sortColumn == columnName) {
      clickCount += 1;
      if (sortObject.clickCount > 2) {
        clickCount = 1;
      }
    } else {
      clickCount = 1;
    }

    sortType = _.isEmpty(sortBy) ? sortType : sortBy;

    const sortObjectValue = {
      sortColumn: columnName,
      sortBy: sortType,
      clickCount: clickCount
    }

    const sortObj = clickCount > 2 ? {} : sortObjectValue;

    this.setState({ sortObject: sortObj },() =>{
      this.loadPageData(currentPage);
    });

  }

  renderColumnSortIcon(columnName){
    const { sortObject } = this.state;
    const isAscending = sortObject.sortBy == 'asc';
    const iconName = isAscending ? 'arrow_downward' : 'arrow_upward';

    const sortColumn = _.get(sortObject, 'sortColumn');
    const isSortable = !_.isEmpty(sortColumn) && sortColumn == columnName;

    if (!isSortable || sortObject.clickCount > 2) {
      return null
    }

    return (
      <ForgeIcon className="sort-icon" key={ 'sort' + columnName} name={iconName} />
    )
  }

  renderColumnHeaderWithIcon(displayName,columnName){
    const { sortObject } = this.state;
    var sortBy = '';

    if(columnName == sortObject.sortColumn){
      const isAscending = sortObject.sortBy == 'asc';
      sortBy = isAscending ? 'desc' : 'asc';
    }

    return(
      <th className="cursor-pointer">
        <div
          className="d-flex align-items-center"
          onClick={() => this.onClickTableSortedHeader(columnName, sortBy)}>
            <span>{displayName}</span>
            {this.renderColumnSortIcon(columnName)}
        </div>
      </th>
    )
  }

  renderTableHeader(){
    return (
      <tr>
        {this.renderColumnHeaderWithIcon('User email ID','email')}
        {isRowLevelPermissionEnabled() ? <th>Permissions</th> : null}
        {enableSecurityRoles() ? this.renderColumnHeaderWithIcon('Roles','role_name') : null}
        {viewLastLoginInfo() ? this.renderColumnHeaderWithIcon('Last login','last_visit_at') : null}
        <th className="actions-header">Actions</th>
      </tr>
    )
  }

  renderTableData() {
    const { userPaginateData } = this.state;

    return userPaginateData.map((user, index) => {
      const { email } = user;
      const uniqueId = `${index + 'security'}-${email} `;
      return (
        <tr key={uniqueId}>
          <td>{email}</td>
          {isRowLevelPermissionEnabled() ? this.renderPermissionOptions(user) : null}
          {this.renderSecurityRoles(user,index)}
          {viewLastLoginInfo() ? this.renderLastLogin(user) : null}
          {this.renderActions(user)}
        </tr>
      )
    })
  }

  renderAddButton() {
    const { isAddUserSection } = this.state;

    if (isAddUserSection) {
      return null
    }

    const addButton = (
      <ForgeButton type="outlined">
        <button onClick={this.onAddNewUserClick}>
          Add user
        </button>
      </ForgeButton>);

    return addButton
  }

  getBulkUploadOptions() {
    const { userFromBellerophon } = this.props;
    const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
    const currentUserRole =  _.get(userFromBellerophon.bellerophon_user_detail, 'role', '');
    const securityRoles = getSecurityRoles();
    const canBulkUserManagement =  _.get(
    _.find(securityRoles, ['role_id', currentUserRole]), 'can_bulk_user_management' , "false");
    const showBulkUpload = isLaunchpadAdmin || _.isEqual(canBulkUserManagement,"true"); 
    return {
      showUserImportModal: this.showUserImportModal, 
      exportTemplateDownload: this.exportTemplateDownload,
      showBulkUpload
    }
  }

  renderAddUserSection() {
    const { isAddUserSection, users, hiddenUsers } = this.state
    if (!isAddUserSection) {
      return
    }
    const bulkUploadOptions =  this.getBulkUploadOptions();

    return (
      <AddUserSection
        users={users}
        hiddenUsers={hiddenUsers}
        onAddUserClick={this.onAddUserClick}
        onCancelUser={this.onCancelUser}
        bulkUploadOptions={bulkUploadOptions} />
    )
  }

  render() {
    const { searchText, searchTitle } = this.state;
    const OPTIONS = [USER_SEARCH_TYPES.EMAIL, USER_SEARCH_TYPES.ROLES];
    if(isRowLevelPermissionEnabled()) {
      OPTIONS.push(USER_SEARCH_TYPES.PERMISSIONS);
    }

    return (
      <div className="h-100">
        {this.renderModalContent()}
        {this.renderConfirmationDialogue()}
        <Form.Group className="security-form-group">
          {this.renderErrorMessage()}
          <div className='security-head d-flex align-items-center mb-4'>
            <div className="forge-typography--title">Users & roles</div>
            <div className="actions-btn add-user-section ml-auto">
              {this.renderAddButton()}
              {this.renderAddUserSection()}
            </div>
          </div>
          <div className="rightside-page-head">
            <SearchUserList
              inputRef={this.searchEmail}
              onInputChange={this.handleSearchEmail}
              inputValue={searchText}
              onSelect={this.onSearchDropDownSelect}
              options={OPTIONS}
              title={searchTitle}
            />
          </div>

          <div className="data-table">
            <div className="custom-responsive-table">
              <table className="table" id='table-resizing'>
                <thead>
                  {this.renderTableHeader()}
                </thead>
                <tbody>
                  {this.renderTableData()}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end">
              {this.renderBulkManagementSection()}
              {this.renderPagination()}
            </div>
          </div>
        </Form.Group>
      </div>
    )
  }
}

Security.propTypes = {
  configData: PropTypes.array,
  handleUserSecurityChange: PropTypes.func,
  currentUser: PropTypes.object,
  userFromBellerophon: PropTypes.object,
  enableLoading: PropTypes.func
}

function mapDispatchToProps() {
  return {
  }
}

function mapStateToProps(state) {
  return {
    currentUser: _.get(state.currentUser, 'user', {})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Security);
