import { useEffect, useState, useRef } from 'react';
import dataStore from 'modules/visualization/LineChart/dataStore';

const UseSummaryTableDataFetch = ( lineChartAttributes, currentDateRange,
  axisGranularity, currentChartView, futureForecastDateRange,
  previousLineChartAttributes, forecastModelOptions, projectionPercent ) => {
  const [rawData, setRawData] = useState({});
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    if(abortController) {
      abortController.abort();
    }
    const abortController = new AbortController();
    setLoading(true);
    const fetchData = async () => {
      try {
        const apiData = await dataStore.getData(lineChartAttributes, abortController,
          previousLineChartAttributes).
            then((response) => {
              return { ...response['api_data'], ...response }
            });
            setLoading(false);
            setRawData(apiData)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    fetchData();
    return () => {
      isMounted.current = false;
      abortController.abort();
    };
  },[
      JSON.stringify(currentDateRange), axisGranularity, currentChartView,
      JSON.stringify(futureForecastDateRange), forecastModelOptions, JSON.stringify(projectionPercent)
  ])

  return { loading, rawData: rawData };
}

export default UseSummaryTableDataFetch;
