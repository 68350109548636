import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { DRAGGABLE } from 'appConstants';
import { isMyViews } from "pages/dashboard/components/CardList/cardHelper";
import DragSortableCollectionItem from './DragSortableCollectionItem';
import InlineEdit from '../InlineEdit';
import { ForgeTab, ForgeBadge, ForgeIcon } from '@tylertech/forge-react';
import { setForgeTabBarTransform } from 'helpers/DomPageHelper';
import {
  canShowNewTag,
  isCollaborateCollection,
  isCollectionViewAccess
} from '../ManageCollection/collaboratorHelper';
import { updateCollaborateUser } from '../ManageCollection/api/updateCollaborateUser';

class Collection extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showCollectionEdit: false,
    };
  }

  updateCollaborateUserDetails = async (collection) => {
    if(canShowNewTag(collection)) {
      const newCollaborateUser = _.get(collection, 'currentCollectionUser.role_user', {});
      if(!_.isEmpty(newCollaborateUser)) {
        newCollaborateUser['is_new_tag'] = false;
        const updatedUser = await updateCollaborateUser({
          collectionId: collection['id'], newUser: newCollaborateUser, message: ''
        });
        collection['currentCollectionUser']['role_user'] = updatedUser;
      }
    }
  }

  componentDidMount() {
    if(!_.isUndefined(this.props.collectionIndex)){
      this.props.onUpdateCurrentIndex(this.props.collectionIndex || 0);
    }
  }

  handleCollectionDoubleClick = (e, collection) => {
    const { isActiveCollection, isUserCollection } = this.props;
    if(isCollectionViewAccess(collection)) {
      return;
    }
    if (isActiveCollection && isUserCollection && !isMyViews(collection) ) {
      this.setState({ showCollectionEdit: true });
      e.stopPropagation();
    }
  };

  handleCollectionEditClose = () => {
    this.setState({ showCollectionEdit: false });
  };

  handleCollectionNameChange = (name) => {
    const { onCollectionEdit } = this.props;
    this.setState({ showCollectionEdit: false }, () => {
      onCollectionEdit(name);
    });
  };

  handleCollectionKeyDown = (e, collection) => {
    if (isEnterButtonPressed(e)) {
      this.props.onCollectionChange(collection);
    }
  };

  handleCollectionClick = async (collection) => {
    const { collectionTabRef, collectionIndex } = this.props;

    setTimeout(() => {
      setForgeTabBarTransform({
        forgeTabBarRef: collectionTabRef.current,
        activeIndex: collectionIndex,
        isInitialLoad: false
      })
    }, 50);
    await this.updateCollaborateUserDetails(collection);

    const { onCollectionChange, showCollectionEdit } = this.props;
    if (!showCollectionEdit) {
      onCollectionChange(collection);
    }
  };

  render() {
    const {
      isBookmarkCollection,
      collection,
      collectionTabRef,
      isDroppable,
      sharedCount,
      onCardDrop,
      showNewLabel,
      isUserCollection,
      onCollectionMove,
      currentCollectionId
    } = this.props;
    const { showCollectionEdit } = this.state;
    const isActiveTab = currentCollectionId ==  _.get(collection, 'id');

    let isSharedCollection = false;
    if(isUserCollection) {
      isSharedCollection = !_.get(collection, 'is_shared_accepted', true);
    }

    const tabClassNames = classNames('h-100 forge-tab-with-badge',
      { 'edit-collection-name': showCollectionEdit }
    );

    return (
      <DropTargetTab
        key={collection.id}
        collection={collection}
        isDroppable={isDroppable && !isBookmarkCollection}
        onDrop={(item) => onCardDrop(item, collection)}
      >
        <DragSortableCollectionItem
          isUserCollection={isUserCollection}
          collection={collection}
          collectionTabRef={collectionTabRef}
          onCollectionMove={onCollectionMove}>
          <ForgeTab disabled={false} active={isActiveTab} tabIndex={0}
            key={collection.name}
            ondblclick={(e) => this.handleCollectionDoubleClick(e, collection)}
            onkeydown={(e) => this.handleCollectionKeyDown(e, collection)}
            onClick={() => this.handleCollectionClick(collection)}
            className={tabClassNames}
            aria-label={collection.name}>
          <div>
            <InlineEdit
              onEditClose={this.handleCollectionEditClose}
              onInputChange={this.handleCollectionNameChange}
              enableInputEdit={showCollectionEdit}
              defaultText={collection.name} >
              <span className='align-middle'>{collection.name}</span>
              <span className='align-middle ml-1'>
                {isCollaborateCollection(collection) && <ForgeIcon name="supervisor_account" />}
                </span>
            </InlineEdit>
            {isBookmarkCollection && sharedCount > 0 ? (
              <ForgeBadge
                className="new-label-chip"
                strong="false">
                  {sharedCount}
              </ForgeBadge>
            ) : null}
            {(isUserCollection && isSharedCollection) || showNewLabel || canShowNewTag(collection) ? (
              <ForgeBadge
                className="new-label-chip"
                strong="false">
                  New
              </ForgeBadge>
            ) : null}
          </div>
          </ForgeTab>
        </DragSortableCollectionItem>
      </DropTargetTab>
    );
  }
}

Collection.propTypes = {
  collectionIndex: PropTypes.number,
  onCardDrop: PropTypes.func,
  onCollectionEdit: PropTypes.func,
  onCollectionMove: PropTypes.func,
  onCollectionChange: PropTypes.func,
  isUserCollection: PropTypes.bool,
  showNewLabel: PropTypes.bool,
  collection: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  currentCollectionId:PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sharedCount: PropTypes.number,
  isBookmarkCollection: PropTypes.bool,
  isAllMetricsCollection: PropTypes.bool,
  isDroppable: PropTypes.bool,
  isActiveCollection: PropTypes.bool,
  showCollectionEdit: PropTypes.bool,
  onDrop: PropTypes.func,
  collectionTabRef: PropTypes.object,
  onUpdateCurrentIndex:PropTypes.func
};

function DropTargetTab(props) {
  const { onDrop, children, isDroppable, collection } = props;
  const isSharedAccepted = _.get(collection, 'is_shared_accepted', false);
  const [{ isHoveredOver, canDrop }, drop] = useDrop({
    accept: [DRAGGABLE.TYPES.CARD],
    drop: (item) => {
      onDrop(item);
    },
    canDrop: () => {
      return isDroppable;
    },
    collect: (monitor) => ({
      isHoveredOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const dropClassNames = classNames(
    'drop-target-tab',
    { 'is-hovered-over': isHoveredOver },
    { 'can-drop': canDrop && isSharedAccepted && !isCollectionViewAccess(collection) }
  );

  return (
    <div ref={drop} className={dropClassNames}>
      {children}
    </div>
  );
}

DropTargetTab.propTypes = {
  onDrop: PropTypes.func,
  children: PropTypes.element.isRequired,
  isDroppable: PropTypes.bool,
  collection: PropTypes.object
};

export default Collection;
