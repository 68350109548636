import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { stringOrNumberProps } from 'common/propTypes';

import { getColumnData, summaryTableApiUrl } from './pieChartHelper';
import { fetchApiData } from 'helpers/apiResponseHelper';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { connect } from 'react-redux';
import { getSummaryShapeWiseData } from 'common/api/drilldown';
import { getMapAttributes } from 'modules/Map/helpers/mapHelper';
import { getSummaryTableRows } from './mapSummaryFormatter';
import { MAP_TYPES } from 'appConstants';
import { PIE_CHART_DATA_LIMIT } from 'modules/visualization/constants';
import SummaryTableDownload from './summaryTableDownload';
import { ForgeBadge } from '@tylertech/forge-react';
class SummaryTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableApiData: [],
      isLoading: false,
      showThirdPartyWarning: false
    }
    this.abortFetchController = new AbortController();
  }

  componentDidUpdate(prevProps) {
    const { summaryTableOptions } = this.props;
    const { isPieChart } = summaryTableOptions;
    const prevSummaryTableOptionsWithoutMapOptions = _.omit(prevProps.summaryTableOptions, 'mapOptions');
    const currentSummaryTableOptionsWithoutMapOptions = _.omit(summaryTableOptions, 'mapOptions');
    const isSummaryTableOptionsChanged = !_.isEqual(summaryTableOptions, prevProps.summaryTableOptions);
    const isMapSummaryTableOptionsChanged = !_.isEqual(
      prevSummaryTableOptionsWithoutMapOptions,
      currentSummaryTableOptionsWithoutMapOptions
    );

    if ((isPieChart && isSummaryTableOptionsChanged) ||
      (this.isChoroplethView() && isMapSummaryTableOptionsChanged)
    ) {
      this.fetchTableData();
    }
  }

  componentDidMount() {
    const { summaryTableOptions } = this.props;
    const { isPieChart } = summaryTableOptions;

    if (isPieChart || this.isChoroplethView()) {
      this.fetchTableData()
    }
  }

  isChoroplethView = () => {
    const { summaryTableOptions } = this.props;
    return _.get(summaryTableOptions, 'currentMapView.type', '') === MAP_TYPES.CHOROPLETH;

  }

  fetchTableData() {
    this.abortFetchController.abort()
    this.abortFetchController = new AbortController()
    this.setState({ isLoading: true });
    const apiUrl = this.getApiDataUrl();

    fetchApiData(apiUrl, this.abortFetchController).
      then((response) => {
        this.setState({ tableApiData: response, isLoading: false });
      }).catch((error) => {
        this.setState({ tableApiData: [], isLoading: false });
        console.log("Error on fetching Table Data", error)
      })
  }

  getApiDataUrl = () => {
    const { summaryTableOptions } = this.props;
    const { isPieChart } = summaryTableOptions;

    let apiUrl;
    if (isPieChart) {
      apiUrl = summaryTableApiUrl(_.pick(summaryTableOptions,
        'commonFilters',
        'drilldown',
        'mapOptions',
        'currentDrilldownDimensionField',
        'currentDrilldownViewEntry',
        'currentBarChartSortType'), false
      );
    } else {
      const { currentPeriodMapAttributes } = getMapAttributes(summaryTableOptions);
      const { apiParams } = currentPeriodMapAttributes;
      apiUrl = getSummaryShapeWiseData(apiParams);
    }

    return apiUrl;
  }

  renderTableData() {
    let { tableData, summaryTableOptions } = this.props;
    const {
      isBarChart,
      isScatterPlot,
      isGroupChart,
      isPieChart,
      isOvertimeChart,
      isForecastingChart
    } = summaryTableOptions;
    const { tableApiData } = this.state;

    if (this.isChoroplethView()) {
      tableData = getSummaryTableRows(tableApiData, summaryTableOptions);
    }

    if (_.isEmpty(tableData)) {
      return null;
    }

    if (isPieChart) {
      return this.renderPieChartData();
    } else if (isScatterPlot || isGroupChart || isBarChart || isOvertimeChart || this.isChoroplethView()) {
      return this.renderChartTableData(tableData, isForecastingChart);
    }
  }

  renderPieChartData() {
    const { tableApiData } = this.state;
    const { drillDownTotal } = this.props;
    if (_.isEmpty(tableApiData)) {
      return null;
    }
    const piechartTotal = _.sumBy(tableApiData, (entry) => Number(_.get(entry, 'count', 0)));
    const othersCategoryValue = Math.abs(drillDownTotal - piechartTotal);
    const isMoreThanTenData = tableApiData.length >= PIE_CHART_DATA_LIMIT;
    const totalValue = (isMoreThanTenData && othersCategoryValue > 0) ?
      (piechartTotal + othersCategoryValue) :
      piechartTotal;

    return _.map(tableApiData, (datum) =>
      this.renderColumnData(datum, totalValue)
    );
  }

  renderColumnData(datum, totalValue) {
    const { tableData, summaryTableOptions } = this.props;
    const {
      currentDrilldownViewEntry,
      templateId,
      isCurrencyDimensionField
    } = summaryTableOptions;

    return getColumnData(datum, totalValue, {
      tableData,
      viewEntry: currentDrilldownViewEntry,
      templateId,
      isCurrencyType: isCurrencyDimensionField
    });
  }

  renderChartTableData(tableData, isBadge) {
    return tableData.map((entry, index) => {
      const isGroup = _.get(entry, 'isGroup', false);
      const summaryClassNames = classNames({ "group-val": isGroup });

      return (
        <tr
          className={summaryClassNames}
          key={index}
          tabIndex={0}>
          {this.renderTableRowData(entry, isBadge, index)}
        </tr>
      );
    });
  }

  renderTableRowData(entry, isBadge) {
    const { tableHeaders } = this.props;

    return tableHeaders.map((column, index) => {
      let tdValue = _.get(entry, column.columnField, '-');
      tdValue = _.isString(tdValue) ? tdValue.replaceAll('<br>', '') : tdValue;
      const tableClassNames = classNames({ 'text-right': index > 0 });
      const badgeName = _.get(entry, 'badgeName');
      let rowValue = "";

      if ( isBadge && !_.isEmpty(badgeName) && column.columnField == 'dimension') {
        const theme = badgeName == 'Incomplete' ? 'info-secondary'  : 'info-primary';
        rowValue =(
          <div className='forcasting-value'>
            {tdValue}
            {<ForgeBadge theme={theme}>{badgeName}</ForgeBadge>}
          </div>
        )
      } else {
        rowValue = tdValue;
      }

      return (
        <td className={tableClassNames} key={index}>{rowValue}</td>
      );

    });
  }

  renderLegend(heading) {

    let renderColor = !_.isEmpty(heading.color) ? (
      <span>
        <div className="legend-color"
          style={{ 'backgroundColor': heading.color }}>
        </div> {heading.name} </span>) : '';

    if (heading.isSecondary && !_.isEmpty(heading.dash) && !_.isEmpty(heading.color)) {
      renderColor = (
        <div>
          <span className="line-style dashdot" style={{ color: `${heading.color}` }}>
            &#8722; &#8729; &#8722;
          </span> {heading.name}
        </div>);
    }

    return renderColor
  }

  renderSubHeader() {
    const { subHeader } = this.props;
    if (_.isEmpty(subHeader)) {
      return null;
    }

    return (
      <p className="summary-subheader">
        <div>{subHeader}</div>
      </p>
    )
  }

  renderTableHeaders() {
    const { tableHeaders, summaryTableOptions } = this.props;
    const { isForecastingChart } = summaryTableOptions;

    if (_.isEmpty(tableHeaders)) {
      return null;
    }

    return (_.map(tableHeaders, (heading, index) => {
      const renderColor = this.renderLegend(heading);

      let renderHeaderName = heading.name;
      if (!_.isEmpty(renderColor)) {
        renderHeaderName = (<div className="legend-item">
          {this.renderLegend(heading)}
        </div>);
      }
      const badgeName = _.get(heading, 'badgeName');

      if ( isForecastingChart && !_.isEmpty(badgeName)) {
        const theme = badgeName == 'Incomplete' ? 'info-secondary'  : 'info-primary';
        return (
          <th key={index}>
            <div className='forcasting-header-value'>
              {renderHeaderName}
              {<ForgeBadge theme={theme}>{badgeName}</ForgeBadge>}
            </div>
          </th>
        )
      }else{
        return (
          <th key={index}>
            {renderHeaderName}
          </th>
        );
      }
    }));
  }

  render() {
    const { summaryTableOptions, currentDrilldownTemplateId,
      vizOptions, tableHeaders, tableData, isForecastingView } = this.props;
    const {
      isStackedChart,
      isGroupByNone,
      isGroupChart,
      isOvertimeChart,
      isForecastingChart
    } = summaryTableOptions;

    const isGroupType = (!isGroupByNone && isGroupChart);
    const tableClassNames = classNames('table', { 'table-freeze-two': isStackedChart },
      { 'group-table-indent': isGroupType },
      { 'table-freeze-one': isOvertimeChart });

    const summaryTableClassNames = classNames('summary-table', 
      { 'px-3': isForecastingView });

    const { isLoading } = this.state;
    const chartType = isForecastingChart? false : isOvertimeChart
    return (
      <>
      <div className={summaryTableClassNames}>
        <div className="summary-table-head d-flex">
        { !isForecastingView && 
          <>
            <div>
              <p className="forge-typography--title">Summary</p>
              {this.renderSubHeader()}
            </div>
            <SummaryTableDownload
              isOvertimeChart={chartType}
              isForecastingChart={isForecastingChart}
              isLoading={isLoading}
              currentDrilldownTemplateId={currentDrilldownTemplateId}
              summaryTableOptions={summaryTableOptions}
              vizOptions={vizOptions}
              tableHeaders={tableHeaders}
              tableData={tableData} />
          </>
        }
        </div>
        <div className="table-responsive">
          <LoadingSpinner isLoading={isLoading} />
          {!isLoading && <table className={tableClassNames}>
            <thead>
              <tr>
                {this.renderTableHeaders()}
              </tr>
            </thead>
            <tbody>
              {this.renderTableData()}
            </tbody>
          </table>}
        </div>
      </div>
      </>

    );
  }
}

SummaryTable.propTypes = {
  tableHeaders: PropTypes.array,
  tableData: PropTypes.array,
  summaryTableOptions: PropTypes.object,
  drillDownTotal: stringOrNumberProps,
  subHeader: PropTypes.string,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vizOptions: PropTypes.object,
  isForecastingView: PropTypes.bool
};

SummaryTable.defaultProps = {
  isForecastingView: false
};

function mapStateToProps(state) {
  return {
    viewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {})
  }
}

export default connect(mapStateToProps)(SummaryTable);
