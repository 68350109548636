// Vendor imports
import _ from 'lodash';
import moment from 'moment';

// Project Imports
import { getStackedBarChartColors, getSortedViewValues } from 'helpers/colorHelper';
import {
  COMPARE_VIEW_STACK_COLORS,
  OTHERS_VALUE
} from 'appConstants';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { isGroupByBar, isParallelOrBulletChart, isStackTypeChart } from './vizOptionsHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';

export const getLegendsItems = (chartDataEntries, vizOptions) => {
  const { groupByEntry, secondaryMetricEntry, viewEntry, templateId } = vizOptions;

  let chartData;
  const nullValueLabel = getNullValueLabel(templateId);
  let primaryMetricName = getPrimaryMetricName(viewEntry);
  if (isParallelOrBulletChart(vizOptions)) {
    if (_.get(secondaryMetricEntry, 'isComparisonEntry')) {
      primaryMetricName = `${primaryMetricName} - This Period`;
    }
    const secondaryMetricName = _.get(secondaryMetricEntry, 'name');
    const primaryMetric = {
      color: getConfiguredMetricColor(templateId, 'secondary'),
      label: primaryMetricName
    };
    const secondaryMetric = {
      color: getConfiguredMetricColor(templateId, 'primary'),
      label: secondaryMetricName
    };
    return [primaryMetric, secondaryMetric];
  }

  if (_.isEmpty(groupByEntry)) {
    const dataEntries = _.chain(chartDataEntries).values().flattenDeep().value();
    chartData = _.chain(getSortedViewValues(dataEntries, nullValueLabel)).
      reverse().
      map((dimension) => { return { view: dimension } }).
      value();
  } else {
    chartData = chartDataEntries;
  }

  const stackedBarChartColors = getStackedBarChartColors(chartData, nullValueLabel);
  let legendItems = _.chain(stackedBarChartColors).
    map((color, label) => {
      const categoryLabel = _.isNil(label) || (label === 'undefined') ? nullValueLabel : label;
      return { color, label: categoryLabel };
    }).
    take(COMPARE_VIEW_STACK_COLORS.length - 1).
    value();

  if (_.size(stackedBarChartColors) >= _.size(COMPARE_VIEW_STACK_COLORS)) {
    legendItems = legendItems.concat({
      color: _.last(COMPARE_VIEW_STACK_COLORS),
      label: OTHERS_VALUE
    });
  }

  const isGroupByNone = _.get(groupByEntry, 'name') === 'None' && !isStackTypeChart(vizOptions);
  const isNormalBarChart = _.isEmpty(groupByEntry) && !isStackTypeChart(vizOptions);
  const isGroupByBarChart = isGroupByBar(vizOptions);

  if ((isGroupByNone || isNormalBarChart || isGroupByBarChart)) {
    legendItems = getNormalLegends(vizOptions);
  }

  return legendItems;
}

export const getNormalLegends = (vizOptions) => {
  const { viewEntry, templateId } = vizOptions;
  let primaryMetricName = getPrimaryMetricName(viewEntry);

  const legendItems = [{
    color: getConfiguredMetricColor(templateId),
    label: primaryMetricName
  }]

  return legendItems;
}

export const getCompareLegendsItems = (compareRanges) => {
  
  let legendItems =[];
  _.forEach(compareRanges, (range) => {
    if(!_.isEmpty(range['period'])){
      const year = moment(range['period']).format('YYYY');
      const yearLabel = _.get(range, 'yearLabel');
      if(year !== "Invalid date"){
        legendItems.unshift({color: range['color'], label: yearLabel})
      }
    }
  })

  return legendItems;
}
