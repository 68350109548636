import React from 'react';
import PropTypes from 'prop-types';

import { getSupportLink } from 'common/config/customerConfiguration';
import { getMatchAdministrators } from 'modules/Administration/permissionHelper';

const UserSupportLink = ({ currentUser, appConfigUsers}) => {
  const { matchedAdministrators, isAdminUser } = getMatchAdministrators(currentUser, appConfigUsers);
  const helperText = isAdminUser ?
    'If you need to modify your permissions please contact:' :
    'If you need to modify your permissions or need to add or remove a user please contact:';

  const renderAdministratorLink = (administrator, index) => {
    const isLastIndex = matchedAdministrators.length - 1 == index;
    return (
      <>
        <a href={`mailto: ${administrator}`} target="_blank" rel="noreferrer">{administrator}</a>
        {isLastIndex ? '' : ', '}
      </>
    );
  }

  const renderDefaultSupportLink = () => {
    return(
      <span>
        If you need to modify your permissions please {' '}
        <a href={getSupportLink()} target="_blank" rel="noreferrer">contact Support.</a>
      </span>
    );
  }

  const renderAdministrators = () => {
    return(
      <div className=''>
        <div className='forge-typography--body1'>{helperText}</div>
        <div>{_.map(matchedAdministrators, renderAdministratorLink)}</div>
      </div>
    )
  }

  return _.isEmpty(matchedAdministrators) ?
    renderDefaultSupportLink() :
    renderAdministrators();
}

UserSupportLink.propTypes = {
  currentUser: PropTypes.object,
  appConfigUsers: PropTypes.object
}
export default UserSupportLink;
