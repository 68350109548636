import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';

import { convertAbbreviatedStringValue } from 'helpers/numberHelper';
import { getBucketColor} from '../radarHelper';
import { getCurrentViewEntry } from 'common/config/templateConfiguration';
import { ForgeTooltip } from '@tylertech/forge-react';
import { RADAR_TRACK_OTHER } from 'appConstants';
class RadarRow extends Component {
  constructor(props, context) {
    super(props, context);

  }

  renderAccordionCell(){
    const { isAccordionRow, isAccordionOpen } = this.props;
    if(!isAccordionRow){
      return null;
    }
    const iconClass = isAccordionOpen ? "icons icons-chevron-down" : "icons icons-chevron-up";
    return (<span className={iconClass}></span>);
  }

  renderMetricValuesCells(metric, tableDatum, index){
    const { bucketRanges, isTotalRow } = this.props;
    const { template_id, view_id } = metric;
    const viewEntry = getCurrentViewEntry(template_id, view_id);
    const prefix = _.get(viewEntry, 'prefix', '');
    const metricData = _.get(tableDatum, metric['id'], {});
    const buckets = _.get(bucketRanges, metric['id'], {})
    const color = getBucketColor(metricData, buckets);

    const attributes = {
      style: { backgroundColor: color },
      className: 'radar-legend-color'
    };
    let change = _.get(metricData, 'change', 0.0);
    if(change != 'N/A'){
      change = change.toFixed(2) + '%';
    }
    const changeClass = (change == 'N/A' )? '' :((metricData['change'] > 0 ?
      'icons-arrow-up' : 'icons-arrow-down1'));
    let currentValue = convertAbbreviatedStringValue(metricData['currentValue']);
    let compareValue = convertAbbreviatedStringValue(metricData['compareValue']);
    currentValue = currentValue == 'N/A' ? currentValue : prefix + currentValue;
    compareValue = compareValue == 'N/A' ? compareValue : prefix + compareValue;
    return [
      // eslint-disable-next-line react/jsx-key
      <td>
        <div className='render-row'>
          <div key={index+'compare'} className='text-center'>
            {compareValue}</div>
          <div key={index+'current'} className='text-center'>
            {currentValue}</div>
          <div key={index+'change'} className='change-value'>
            <div className='compare-change-result'>
              <div>
                {isTotalRow ? null : <div {...attributes}></div>}
              </div>
              <i className={changeClass} />
              <div className='text-left'>{change}</div>
            </div>
          </div>
        </div>
      </td>
    ];
  }

  renderOtherInfo = (trackId) => {

    if(trackId != RADAR_TRACK_OTHER){
      return null
    }

    const textInfo =
      "Includes items outside the selected shape boundaries and items without a mappable location";

    return (
      <span className="ml-1 cursor-pointer radar-other-info">
        <i className="icons-info-circle-icon"></i>
        <ForgeTooltip text={textInfo}  position="bottom" />
      </span>
    )
  }

  renderCensusRows(){
    const {
      selectedMetrics, radarRowEntry, rowKey,
      toggleAccordion, isTotalRow, isExpandRow, isLoading
    } = this.props;

    const metricData = _.get(radarRowEntry, 'metricData', []);
    const trackId = _.get(radarRowEntry, 'trackId');
    const shapeName = _.get(radarRowEntry, 'shapeName', trackId);
    const rowClass = classNames('track-row', {'shape-row': isTotalRow, 'expand-row': isExpandRow });
    return (
      <tr key={rowKey + '' +trackId} className={rowClass}>
        <td
          onClick={() => toggleAccordion(rowKey)}
          key={trackId+'-census-td'}
          className="accordion-cell">
            <div className='accordion-row'>
              {shapeName}
              {this.renderOtherInfo(trackId)}
              {this.renderAccordionCell(rowKey)}
            </div>
        </td>
        {_.map(selectedMetrics, (metric, index) => {
          return (isLoading || _.isEmpty(metricData)) ?
           (<td key={"icon-"+ rowKey + '' + trackId + index} className="loader-cell">
            <Spinner variant="primary" size="sm" animation="border" />
          </td>) : this.renderMetricValuesCells(metric, metricData[metric['metricIndex']], index)
        })}
      </tr>
    );
  }

  render() {
    return (
      this.renderCensusRows()
    );
  }
}

RadarRow.propTypes = {
  bucketRanges: PropTypes.object,
  radarRowEntry: PropTypes.array,
  isAccordionRow: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAccordionOpen: PropTypes.bool,
  isTotalRow: PropTypes.bool,
  rowKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  selectedMetrics: PropTypes.array,
  toggleAccordion: PropTypes.func,
  isExpandRow: PropTypes.bool
}

export default RadarRow;
