import _ from 'lodash';
import { RELATIVE_FILTER_OPTIONS, RELATIVE_FILTER_PERIOD_TYPE_ENTRIES } from 'appConstants';
import { getConfig, configuredDefaultDateType  } from 'common/config/customerConfiguration';

const isRelativeCustomMode = _.isEqual(_.get(getConfig(), 'relative_date_mode'), 'custom_periods');
const isRelativeFilter = (configuredDefaultDateType === 'relative');

const getCurrentRelativeFilterOption = () => {
  const fixedRelativeFilterType = _.get(RELATIVE_FILTER_OPTIONS, [0, 'type']);
  if(!isRelativeFilter) {
    return fixedRelativeFilterType;
  } else if(isRelativeCustomMode) {
    return _.get(_.last(RELATIVE_FILTER_OPTIONS), 'type');
  } else {
    return _.get(getConfig(), 'fixed_relative_filter_type', fixedRelativeFilterType);
  }
}

const getCustomRelativeFilterType = () => {
  const customRelativeFilterType = _.get(RELATIVE_FILTER_PERIOD_TYPE_ENTRIES, [0, 'type']);
  if(isRelativeFilter) {
    return _.get(getConfig(), 'relative_filter_period_type', customRelativeFilterType);
  } else {
    return customRelativeFilterType;
  }
}

const getCustomPeriodCount = () => {
  if(isRelativeFilter && isRelativeCustomMode) {
    return _.get(getConfig(), 'relative_custom_period_count', "");
  } else {
    return "";
  }
}

export const relativeDateFilterEntryDefaultState = {
  isRelativeFilter,
  currentRelativeFilterOption: getCurrentRelativeFilterOption(),
  currentRelativePeriodType: getCustomRelativeFilterType(),
  customPeriodCount: getCustomPeriodCount(),
  defaultDateType: configuredDefaultDateType
}

export const getRelativeDateFilterEntry = (relativeDateFilterEntry) => {
  if(_.get(relativeDateFilterEntry, 'isRelativeFilter')) {
    return relativeDateFilterEntry;
  } else {
    return relativeDateFilterEntryDefaultState;
  }
}
