import { buildQueryString } from 'helpers/HttpHelper';

export const getPointsTileUrl = (params) => {
  return `${window.location.protocol}//${window.location.host}`+
    '/api/visualization/map/within_tile.json?x={x}&y={y}&z={z}&'+
    buildQueryString(params)
};

export const getAdvancedSearchPointsTileUrl = (params) => {
  return `${window.location.protocol}//${window.location.host}`+
    '/api/advanced_search/within_tile.json?x={x}&y={y}&z={z}&'+
    buildQueryString(params)
};

export const getShapeOutlineTileUrl = (params) => {
  return `${window.location.protocol}//${window.location.host}`+
    '/api/shapes/outlines.json?x={x}&y={y}&z={z}&' +
    buildQueryString(params);
};

export const getShapeTileUrl = (params) => {
  return `${window.location.protocol}//${window.location.host}`+
    '/api/shapes/within_tile.json?x={x}&y={y}&z={z}&' +
    buildQueryString(params);
};
