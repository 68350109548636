import React from 'react';
import PropTypes from 'prop-types';
import { ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

const ForgeCloseButton = (props) => {

  const { onClose } = props;

  return (
    <ForgeIconButton toggle onClick={onClose} dense = {true} className="forge-close">
      <button type="button" aria-label="Toggle favorite" className="custom-forge-close-btn">
        <ForgeIcon name="close" forge-icon-button-on />
        <ForgeIcon name="close" />
      </button>
    </ForgeIconButton>
  );
}

ForgeCloseButton.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func
}

ForgeCloseButton.defaultProps = {
  className: '',
  onClose: _.noop
}

export default (ForgeCloseButton);
