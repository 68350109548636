import React from 'react';
import PropTypes from 'prop-types';
import { getTimeStampForDate, isCollaborateCollection } from "./collaboratorHelper";
import { useFetchCollectionActivities } from './hooks/useFetchCollectionActivities';

const CollaborateCollectionCreatedAt = ({ collection, className = '', isDashboard = false }) => {
  if(!isCollaborateCollection(collection)) {
    return null;
  }
  const collectionUserEmail = _.get(collection, 'currentCollectionUser.email', '');
  const manageCollaborateUpdatedAt = _.get(collection, 'manage_collaborate_updated_at');

  let displayText =
    `Created by ${collectionUserEmail} on ${getTimeStampForDate(manageCollaborateUpdatedAt)}`;
  if(isDashboard){
    const { data } = useFetchCollectionActivities(collection['id']);
    const lastUpdate = _.last(data);
    const time = getTimeStampForDate(_.get(lastUpdate, 'updated_at'));
    displayText = `This collection is managed by ${collectionUserEmail} and
    was last changed on ${time }`;
  }

  return (<div className={className}> {displayText} </div>
  );
}

CollaborateCollectionCreatedAt.propTypes = {
  collection: PropTypes.object,
  className: '',
  isDashboard: PropTypes.bool
};

export default CollaborateCollectionCreatedAt;
