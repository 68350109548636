import React, { useEffect, useState } from 'react';
import { ForgeIcon, ForgeButton } from '@tylertech/forge-react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'

import {
  getQuickFilterEntries
} from 'common/config/templateConfiguration';
import {
  updateSearchFilters,
  updateSortColumns,
  updateExpandedFilterMode,
  updateSearchResultCount,
  updatePolygonGeojson
} from 'actions/advanceSearchActions';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import OffCanvasFilter from '../OffCanvasFilter/OffCanvasFilter';
import { removeEmptyFilterConditions } from 'pages/drilldown/components/QuickFilterBar/helper';

const QuickFilter = ({isRightSideContainer}) => {
	const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
	const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));

	const [isOpen, setIsOpen] = useState(false);
  const [newFilters, setNewFilters] = useState(searchFilters);

	useEffect(() => {
    setNewFilters(searchFilters);
  }, [ searchFilters]);

  const filterFieldEntries = _.filter(getQuickFilterEntries(templateId), (entry) => {
    return !_.isEmpty(entry['column']) && _.get(entry, 'ignored_advanced_search', 'false') !== 'true';
  });
  const apiParams = {
    ignore_view_entry: true,
    currentDrilldownTemplateId: templateId,
    availableTemplateIds: [templateId],
    drilldownEntry: JSON.stringify({ quickFilters: [] })
  };

	const dispatch = useDispatch();

	const handleSingleFiltersChange = (updatedFilters) => {
    setNewFilters(updatedFilters);
  }

	const resetSelection = () => {
    dispatch(updateSortColumns([]));
    dispatch(updateSearchFilters([]));
    dispatch(updatePolygonGeojson({ featuresGeojson: {} }));
    dispatch(updateCurrentBookmark(''));
    dispatch(updateSearchResultCount(0));
		setNewFilters([]);
  }

	const handleSaveFilters = () => {
    _.each(newFilters, (filter) => {
      if(_.get(filter, "operator") == "between" && _.isEmpty(_.get(filter, "to"))) {
        filter['operator'] = '>=';
      } else if(_.get(filter, "operator") == "between" && _.isEmpty(_.get(filter, "value"))) {
        filter['operator'] = '<=';
        filter['value'] = filter['to'];
      } else if(_.get(filter, "operator") == "not between" &&
        ( _.isEmpty(_.get(filter, "to")) || _.isEmpty(_.get(filter, "value")))) {
        filter['operator'] = '<=';
        if (_.isEmpty(_.get(filter, "value"))) {
          filter['value'] = filter['to'];
        }
      }
    });


    handleCollectionFiltersChange(removeEmptyFilterConditions(newFilters));
  }

	const handleCollectionFiltersChange = (filters) => {
    dispatch(updateExpandedFilterMode(false));
    dispatch(updateSearchFilters(filters));
    setNewFilters(filters);
    if (_.isEmpty(filters)) {
      resetSelection();
    }
  }

	const onClickAddFilter = () => {
		setIsOpen(true);
	}

  const forgeButtonType = isRightSideContainer ? "raised" : "outlined";
  const className = isRightSideContainer ? "" : "text-primary";

	return (
		<>
			<ForgeButton className='quick-filter' type={forgeButtonType}>
				<button type="button" onClick={onClickAddFilter}>
					<span className={className}>
						<ForgeIcon name="plus_thick" className="tx-12 mr-1" />Add a filter
					</span>
				</button>
			</ForgeButton>
			<OffCanvasFilter
        isRightSideContainer={isRightSideContainer}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        filterFieldEntries={filterFieldEntries}
        filters={newFilters}
        searchFilters={searchFilters}
        templateId={templateId}
        apiParams={apiParams}
        onFiltersChange={handleSingleFiltersChange}
        resetSelection={resetSelection}
        onFiltersSave={handleSaveFilters}
      />
		</>
	);
}

QuickFilter.propTypes = {
  isRightSideContainer: PropTypes.bool
};

export default QuickFilter;
