import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom';

function CanvasBody({ isRightSideContainer }) {
	useEffect(() => {
    document.body.querySelector('.stacked-headers').style.zIndex = 1000;
    return () => {
      document.body.querySelector('.stacked-headers').style.zIndex = 100002;
    }
  }, []);

	if(isRightSideContainer) {
    return (
      createPortal(
        <div className="bs-canvas-overlay bg-dark position-fixed w-100 h-100">
        </div>,
        document.body
      )
    );
  } else {
    return (
        <div className="bs-canvas-overlay bg-dark position-fixed w-100 h-100">
        </div>
    );
	}
}

CanvasBody.propTypes = {
	isRightSideContainer: PropTypes.bool
}

export default CanvasBody;
