import React, { useState, useEffect } from "react";
import { LAYERS } from "../partials/PointsAndStackPartial";
import { EVENTS } from "appConstants";
import GlobalEvents from "common/components/GlobalEvents";

function PointMapAccessibility(props) {
  const { map, features } = props;
  const [ pointFeatures, setPointFeatures ] = useState(features);
  const onFeatureUpdate = (features) => {
    setPointFeatures(features);
  }

  useEffect(() => {
    GlobalEvents.on(EVENTS.UPDATE_POINT_FEATURES, onFeatureUpdate);
    return () => {
      GlobalEvents.off(EVENTS.UPDATE_POINT_FEATURES, onFeatureUpdate);
    }
  }, []);

  const onFeatureFocus = (feature) => {
    const count = _.get(feature.properties, 'count');
    const layerId = count === 1 ? LAYERS.INCIDENT_CIRCLE : LAYERS.STACKS_CIRCLE;
    const center = _.get(feature , 'geometry.coordinates', []);
    map.flyTo({ center , zoom: map.getZoom() });
    feature['layer'] = { id: layerId}
    map.fire('accessibilityFocus', { feature, layerId});
  }

  const onFeatureBlur = () => {
    map.fire('accessibilityBlur');
  }

  const renderFeatureElements = (feature) =>{
    const idKey = _.get(feature, 'properties.row_id_key');

    return(
      <div key={idKey} tabIndex={0}
        onFocus={() => onFeatureFocus(feature)}
        onBlur={() => onFeatureBlur(feature)}
        >
      </div>
    )
  }
  return _.map(pointFeatures, renderFeatureElements);
}

export default PointMapAccessibility
