import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ForgeButton } from '@tylertech/forge-react';

import { Modal } from 'react-bootstrap';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

class ThirdPartyWarningModal extends Component {
  constructor(props) {
    super(props);
    this.downloadButtonRef = React.createRef();
  }

  componentDidMount() {
    this.downloadButtonRef.current.focus();
  }

  handleKeyDownClose = (event) => {
    if (isEnterButtonPressed(event)) {
      event.preventDefault();
      this.onCloseModal();
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleKeyDownDownload = (event) => {
    const { onDownloadConfirm } = this.props;
    if (isEnterButtonPressed(event)) {
      event.preventDefault();
      onDownloadConfirm();
    }
  };

  render() {
    const { title, message, onDownloadConfirm } = this.props;

    return (
      <Modal
        size="md"
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-wrapper delete-container">
          <Modal.Header>
            <Modal.Title> {title} </Modal.Title>
            <button
              aria-label="Close"
              aria-modal="true"
              tabIndex={0}
              className="close"
              onKeyDown={(event) => this.handleKeyDownClose(event)}
              onClick={() => this.onCloseModal()}
            >
              <i className="icons-close"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-3">
                <span>{message}</span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ForgeButton type="flat">
              <button
                aria-label="Cancel"
                aria-modal="true"
                tabIndex={0}
                onClick={() => this.onCloseModal()}
                onKeyDown={(event) => this.handleKeyDownClose(event)}
              >
                Cancel
              </button>
            </ForgeButton>
            <ForgeButton type="raised">
              <button
                aria-label="Confirm"
                aria-modal="true"
                className="px-7"
                tabIndex={0}
                ref={this.downloadButtonRef}
                onClick={onDownloadConfirm}
                onKeyDown={(event) => this.handleKeyDownDownload(event)}
              >
                Download
              </button>
            </ForgeButton>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

ThirdPartyWarningModal.propTypes = {
  onClose: PropTypes.func,
  onDownloadConfirm: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default ThirdPartyWarningModal;
