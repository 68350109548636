import moment from 'moment';
import {
  formatResultsInBienniumYearWise,
  isBienniumFiscalYear
} from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import { getYearTextByRange } from 'helpers/dateHelper';
import { updateAdjustedValueForData } from '../../Helpers/overtimeHelper';
import { disableMetricTotal } from 'common/config/viewConfiguration';
import { OVERTIME_VISUALIZATION_TYPES } from 'appConstants';
import { isForecastEndDateIsBeforeToday } from 'pages/Forecasting/ForecastHelper';
import { sumApiDataForActualData } from '../../Helpers/apiDataHelper';
import { isDiscreteModeData } from 'common/config/viewConfiguration';
import { updateAdjustedValueToValue } from './dataHelper';

export class apiDataHelper {
  constructor(rawApiData, vizOptions) {
    this.rawApiData = rawApiData;
    this.vizOptions = vizOptions;
  }

  formatApiData() {
    const {
      renderTimeFrame, dateRange, isComboChart,
      compareYearRanges, dateRangeMode, renderType,
      axisGranularity
    } = this.vizOptions;

    const isAreaChart = renderType === "area";
    if (isComboChart || isAreaChart) {
      return this.rawApiData;
    }

    if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges)) {
      return this._formatYearWiseData(compareYearRanges, dateRange, dateRangeMode, axisGranularity);
    } else {
      return this.rawApiData;
    }
  }

  updateAdjustedValue() {
    const { isForecastingView } = this.vizOptions;
    const rawApiCloneData = _.cloneDeep(this.rawApiData);
    const apiTotalData = _.get(rawApiCloneData, 'total', []);

    updateAdjustedValueForData(apiTotalData, this.vizOptions, 'total');

    return isForecastingView ? rawApiCloneData : this.rawApiData;
  }

  getTotal() {
    return _.get(this.formatApiData(), 'total', []);
  }

  getTotalLastPointData() {
    const { renderType, viewEntry } = this.vizOptions;
    const isBurnup = renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
    const isDiscreteMode = isDiscreteModeData(viewEntry);
    return isBurnup && isDiscreteMode ? this.getTotal() :
      updateAdjustedValueToValue(this.formatApiData(), this.vizOptions, true);
  }

  getEntries() {
    return _.get(this.formatApiData(), 'entries', {});
  }

  getComboMetrics() {
    return _.get(this.formatApiData(), 'combo_chart_secondary_results', []);
  }

  getComparisonTotal() {
    return _.get(this.formatApiData(), 'comparison_total_result', []);
  }

  comparisonDimensionEntriesResult() {
    return _.get(this.formatApiData(), 'comparison_dimension_entries_result', {});
  }

  getBenchmarkEntries() {
    return _.get(this.formatApiData(), 'bench_mark_entries', {});
  }

  getTailingDrops(tailingDropStartDate, subtractValue) {
    const { axisGranularity, isForecastingView, dateRange } = this.vizOptions;
    if (isForecastingView && isForecastEndDateIsBeforeToday({ dateRange: dateRange }, axisGranularity)) {
      const tailDateRange = {
        startDate: moment(dateRange.endDate, "YYYY-MM-DD").startOf('month').format("YYYY-MM-DD"),
        endDate: moment(tailingDropStartDate).add(subtractValue, 'day').format("YYYY-MM-DD")
      };
      return sumApiDataForActualData(
        _.get(this.formatApiData(), 'api_data.total', this.getTotal()), this.vizOptions, tailDateRange);
    } else {
      return this.getTotal();
    }
  }

  isTotalLineEnabled() {
    const { viewEntry, renderType, isForecastingView } = this.vizOptions;
    return (this._showTotalLine() && !disableMetricTotal(viewEntry) &&
      !_.isEqual(renderType, _.get(OVERTIME_VISUALIZATION_TYPES.AREA, 'type', '')) || isForecastingView);
  }

  _showTotalLine() {
    const { isComboChart, renderType } = this.vizOptions;
    const apiData = this.formatApiData();
    const isAreaChart = renderType === "area";
    const comparisonEntries = _.get(apiData, 'comparison_dimension_entries_result', {});
    const comparisonTotal = _.get(apiData, 'comparison_total_result', []);
    const total = _.get(apiData, 'total', []);
    const entries = _.get(apiData, 'entries', {});

    if (_.isEmpty(total) || isAreaChart) {
      return false;
    } else if (_.size(entries) > 1 || isComboChart || _.size(comparisonEntries) > 1) {
      return true;
    } else {
      const totalValues = _.map(total, 'value');
      const dimensionValues = _.map(_.get(_.values(entries), '[0]', []), 'value');
      let showComparisonTotal = false;
      if (!_.isEmpty(comparisonTotal)) {
        const totals = _.map(comparisonTotal, 'value');
        const values = _.map(_.get(_.values(comparisonEntries), '[0]', []), 'value');
        showComparisonTotal = !_.isEqual(totals, values);
      }
      return !_.isEqual(totalValues, dimensionValues) || showComparisonTotal;
    }
  }

  _formatYearWiseData(compareYearRanges, dateRange, dateRangeMode, axisGranularity) {
    const isBienniumAndMoreThanTwoCompareYears = isBienniumFiscalYear(this.vizOptions) &&
      _.size(compareYearRanges) > 1;
    let yearWiseEntries = isBienniumAndMoreThanTwoCompareYears ?
      formatResultsInBienniumYearWise(this.rawApiData['total'], dateRange, dateRangeMode) :
      this._formatResultsInYearWise(this.rawApiData['total'], dateRange, dateRangeMode, axisGranularity);

    if (!_.isEmpty(compareYearRanges)) {
      _.each(compareYearRanges, (compareRange) => {
        const comparisonTotals = _.get(this.rawApiData, 'comparison_total_result', []);
        let entries = isBienniumAndMoreThanTwoCompareYears ?
          formatResultsInBienniumYearWise(comparisonTotals, compareRange, dateRangeMode) :
          this._formatResultsInYearWise(comparisonTotals, compareRange, dateRangeMode, axisGranularity);
        yearWiseEntries = _.merge({}, yearWiseEntries, entries);
      });
    }

    const formattedApiData = _.cloneDeep(this.rawApiData);
    formattedApiData['total'] = [];
    formattedApiData['previous_projection_total'] = [];
    formattedApiData['comparison_total_result'] = [];
    formattedApiData['entries'] = yearWiseEntries;
    formattedApiData['previous_projection_entries'] = this._formatResultsInYearWise(
      this.rawApiData['previous_projection_total'], dateRange, dateRangeMode, axisGranularity);
    return formattedApiData;
  }

  _formatResultsInYearWise(results, dateRange, dateRangeMode, axisGranularity) {
    let formattedResult = {};
    const { startDate, endDate } = dateRange;
    const year = getYearTextByRange(dateRange, dateRangeMode);
    const yearWiseResultEntries = _.filter(results, (entry) => {
      if (_.includes(['year', 'month'], axisGranularity)) {
        return moment(entry.period).isBetween(startDate, endDate, axisGranularity, '[]');
      } else {
        return moment(entry.period).startOf('day').isBetween(startDate, endDate, 'month', '[]');
      }
    });
    formattedResult[year] = yearWiseResultEntries;
    return formattedResult;
  }
}