import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { ForgeButton } from '@tylertech/forge-react';

import LoadingSpinner from 'common/components/LoadingSpinner';
import CollectionFilters from 'pages/dashboard/components/Collections/CollectionFilters/CollectionFilters';
import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';
import ResetButton from './ResetButton';

import { updateGlobalFiltersByConfigDefaults } from 'pages/drilldown/components/QuickFilterBar/helper';
import { getGlobalFilters, getTemplateEntries  } from 'common/config/customerConfiguration';
import ShareSection from './ShareSection';
import { shareCollection, getCollectionCards } from 'common/api/collectionApi';
import { getViewBookMarks } from 'common/api/bookmarksApi';
import {
  getCollectionCardEntries,
  getGlobalFilterConfig,
  viewEntriesUserRoles
} from 'pages/dashboard/components/Collections/collectionHelper';
import { trackEvent } from 'helpers/eventTracking';
import { collectionSharedMessage, collectionSharedErrorMessage } from 'helpers/toastMessages';
import CopyCollectionModal from 'common/components/CopyCollectionModal';
import UserFromBellerophonContext from "context/UserFromBellerophonContext";
import {
  isCollectionViewAccess,
  isRestrictedCollection
} from 'pages/dashboard/components/ManageCollection/collaboratorHelper';

class CollectionPage extends Component {
  constructor(props, context) {
    super(props, context);
    const currentCollection = _.get(props, 'currentCollection', [])
    this.state = {
      collectionName: _.get(props, 'currentCollection.name'),
      copyCollectionName: ` ${_.get(props, 'currentCollection.name')} Copy`,
      collectionFilters: updateGlobalFiltersByConfigDefaults(
          _.get(props, 'currentCollection.collectionFilters', []), currentCollection),
      isShareEnabled: false,
      isCardsLoading: false,
      isBookmarkLoading: false,
      isCollectionCopyEnabled: false,
      cardEntries: [],
      bookmarks: [],
    };
    this.abortCardsApi = new AbortController();
    this.abortBookmarkApi = new AbortController();
  }
  static contextType = UserFromBellerophonContext;

  componentDidMount() {
    this.fetchCollectionCards();
    this.fetchBookmarks();
  }

  componentDidUpdate(prevProps){
    const{ currentCollection } = this.props;
    const { name, id, collectionFilters } = currentCollection;
    const isCollectionIdChanged = !_.isEqual(id, _.get(prevProps, 'currentCollection.id'));
    const isCollectionNameChanged = !_.isEqual(name, _.get(prevProps, 'currentCollection.name'));

    if(isCollectionIdChanged || isCollectionNameChanged){
      this.setState({ collectionName: name , copyCollectionName: `${name} Copy` }, () => {
        if(isCollectionIdChanged) {
          this.fetchCollectionCards();
        }
      });
    }

    if(!_.isEqual(collectionFilters, _.get(prevProps, 'currentCollection.collectionFilters'))){
      this.setState({
        collectionFilters: updateGlobalFiltersByConfigDefaults(collectionFilters, currentCollection)
      });
    }
  }

  fetchBookmarks = () => {
    this.setState({ isBookmarkLoading: true });
    this.abortBookmarkApi.abort();
    this.abortBookmarkApi = new AbortController();

    getViewBookMarks({ signal: this.abortBookmarkApi.signal })
      .then((response) => response.json())
      .then((bookmarks) => {
        this.setState({ bookmarks, isBookmarkLoading: false });
      })
      .catch((error) => {
        this.setState({ isBookmarkLoading: false });
        console.log('Error on fetching bookmarks', error);
      });
  }

  fetchCollectionCards = () => {
    const { currentCollection } = this.props;
    this.setState({ isCardsLoading: true });

    this.abortCardsApi.abort();
    this.abortCardsApi = new AbortController();

    getCollectionCards(currentCollection.id, this.abortCardsApi)
      .then((response) => {
        this.setState({
          isCardsLoading: false,
          cardEntries: response,
        });
      });
  };

  handleCollectionNameChange = (e) => {
    const { currentCollection, onCollectionUpdate } = this.props;
    this.setState({
      collectionName: e.target.value,
    });
    onCollectionUpdate({
      ...currentCollection,
      name: e.target.value
    });
  };

  handleCollectionFiltersChange = (filters) => {
    const { currentCollection, onCollectionUpdate } = this.props;
    this.setState({ collectionFilters: filters });
    onCollectionUpdate({
      ...currentCollection,
      collectionFilters: updateGlobalFiltersByConfigDefaults(filters, currentCollection)
    });
  }

  handleDateFiltersChange = (dateFilters) => {
    const { currentCollection, onCollectionUpdate } = this.props;

    onCollectionUpdate({
      ...currentCollection,
      dateFilters
    });
  }

  renderCollectionFilters(){
    const { currentUser,  currentCollection, isManageCollection } = this.props;
    const { collectionFilters, cardEntries, bookmarks } = this.state;
    const templateEntries = getTemplateEntries();
    const newCardEntries = getCollectionCardEntries(cardEntries, templateEntries, bookmarks)
    const { globalFilterFieldConfigs } = getGlobalFilterConfig(newCardEntries);

    if(_.isEmpty(getGlobalFilters()) && _.isEmpty(globalFilterFieldConfigs)){
      return null;
    }
    return(
      <div className="subscriptions-collection-filters mt-10">
        <label className="custom-label mb-5"> Collection Filters </label>
        <CollectionFilters
          disabledFilters={isManageCollection}
          hideShowMore={true}
          currentUser={currentUser}
          cardEntries={newCardEntries}
          dateFilters={_.get(currentCollection, 'dateFilters', {})}
          onDateFiltersChange={this.handleDateFiltersChange}
          currentCollection={currentCollection}
          onCollectionFiltersChange={this.handleCollectionFiltersChange}
          collectionFilters={collectionFilters} />
      </div>
    )
  }

  handleShareCancel = () =>{
    this.setState({ isShareEnabled: false });
  }

  handleCollectionShare = (options) => {
    const { currentCollection, currentUser } = this.props;
    const shareEmails = _.get(options, 'shareEmails', []);

    const params = {
      shareEmails,
      includeSubscription: false,
      sendShareEmail: true,
      collection: {
        ...currentCollection,
        name: _.get(currentCollection, 'name')
      }
    };

    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      trackEvent('manage_confirm_collection_share');
      shareCollection(_.get(currentCollection, 'id'), params)
        .then((response) => {
          if (response.ok) {
            toast.success(collectionSharedMessage);
          } else {
            toast.error(collectionSharedErrorMessage);
          }
          this.setState({ isLoading: false, isShareEnabled: false });
        }).catch((err) => {
          console.error('collection share:', err);
          toast.error(collectionSharedErrorMessage);
          this.setState({ isLoading: false });
        });
    }
  }

  onShareClick = () => {
    trackEvent('manage_initiate_collection_share');
    this.setState({ isShareEnabled: true });
  }

  onCollectionCopyClick = () => {
    this.setState({ isCollectionCopyEnabled: true });
  }

  onClickDeleteButton = () => {
    trackEvent('manage_initiate_collection_delete');
  }

  onDeleteConfirm = () => {
    const { onCollectionDelete, currentCollection } = this.props;
    trackEvent('manage_confirm_collection_delete');
    onCollectionDelete(currentCollection);
  }

  renderShareOptions(){
    const { isShareEnabled, cardEntries } = this.state;
    const { currentUser } = this.props;
    const cards = getCollectionCardEntries(cardEntries, getTemplateEntries());
    const isLaunchpadAdmin = _.get(this.context, "userFromBellerophon.isLaunchpadAdmin", false);
    let allowedUserRoles = []
    if (!isLaunchpadAdmin){
      allowedUserRoles = viewEntriesUserRoles(cards);
    }

    if(!isShareEnabled){
      return null;
    }
    return <ShareSection
      currentUser={currentUser}
      isShareEnabled={isShareEnabled}
      onCancelShare={this.handleShareCancel}
      onSendClick={this.handleCollectionShare}
      allowedUserRoles={allowedUserRoles}
      shareType='collection'
    />;
  }

  renderCollectionActionButtons(){
    const { currentCollection, isManageCollection  } = this.props;
    const isConfiguredCollection = !_.isEmpty(_.get(currentCollection, 'bellerophon_tag_id'));
    const { isShareEnabled } = this.state;
    const isCollectionViewer = isCollectionViewAccess(currentCollection);

    if(isShareEnabled){
      return null;
    }
    return(
      <>
        {!isManageCollection &&
          <>
            <div className="dropdown">
              <ForgeButton type="outlined">
                <button
                  varient="primary"
                  onClick= {() => this.onCollectionCopyClick()}>
                  Duplicate
                </button>
              </ForgeButton>
              {this.renderCopyCollectionModal()}
            </div>
            <ForgeButton type="outlined">
              <button
                onClick={this.onShareClick}>
                Share
              </button>
            </ForgeButton>
          </>
        }
        { isConfiguredCollection || isCollectionViewer ? null :
          <DeleteConfirmModal
            title="Delete Collection"
            onClickDeleteButton={this.onClickDeleteButton}
            message="Are you sure you want to delete this collection? There is no undo."
            onDeleteConfirm={this.onDeleteConfirm}
          />}
      </>
    )
  }

  onCopyCollectionModalClose = () => {
    this.setState({ isCollectionCopyEnabled: false });
  }

  onCopyCollectionTitleChange = (e) => {
    this.setState({copyCollectionName: e.target.value});
  }

  onCopyCollectionSave = () => {
    const { copyCollectionName } = this.state;
    const { onCollectionCopy } = this.props;
    this.setState({ isCollectionCopyEnabled: false });
    onCollectionCopy(copyCollectionName);
  }

  renderCopyCollectionModal() {
    const { isCollectionCopyEnabled, copyCollectionName } = this.state;

    if(!isCollectionCopyEnabled){
      return
    }
    return <CopyCollectionModal
      name = {copyCollectionName}
      onInputChange = {this.onCopyCollectionTitleChange}
      onClose = {this.onCopyCollectionModalClose}
      onSave = {this.onCopyCollectionSave} />;
  }

  render() {
    const { collectionName, isLoading, isCardsLoading } = this.state;
    const { onResetCollectionClick, currentCollection, isManageCollection } = this.props;

    return (
      <>
        <div className="collection-head rightside-page-head">
          <h1 className="mr-3">Collection</h1>
          <LoadingSpinner isLoading={isLoading || isCardsLoading} />
          <div className="actions-btn ">
            {this.renderCollectionActionButtons()}
            {this.renderShareOptions()}
            { (isRestrictedCollection(currentCollection)) ? null :
              <ResetButton onResetClick={() => onResetCollectionClick(currentCollection)}/>
            }
          </div>
        </div>

        <div className="mt-8">
          <div className="form-group">
            <label htmlFor="collectionName" className="custom-label"> Collection Name </label>
            <input
              disabled={isManageCollection}
              value={collectionName}
              className="form-control tx-14 text-dark w-75"
              id="collectionName"
              aria-describedby="collectionName"
              onChange={(e) => this.handleCollectionNameChange(e)}
              type="text"/>
          </div>

          {this.renderCollectionFilters()}
        </div>
      </>
    );
  }
}

CollectionPage.propTypes = {
   currentCollection: PropTypes.object,
   currentUser: PropTypes.object,
   onCollectionCopy: PropTypes.func,
   dateFilters: PropTypes.object,
   onCollectionUpdate: PropTypes.func,
   onCollectionDelete: PropTypes.func,
   onResetCollectionClick: PropTypes.func,
   isManageCollection: PropTypes.bool
}

export default CollectionPage;
