import _ from 'lodash';
import React from 'react';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { getPieChartMargins } from 'modules/PlotlyTooltip/helper';
import { VIEW_MODE } from 'modules/visualization/constants';

export const getPiChartPopupConfigs = (
  chartContainer,
  data,
  isCurrencyDimensionField,
  viewMode
) => {
  const isSmallView = (VIEW_MODE.SMALL === viewMode);
  const containerRect = chartContainer.getBoundingClientRect();
  const pieChartMargins = getPieChartMargins(chartContainer);
  const hovertext = _.get(data, 'points[0].hovertext[0]', '');
  const label = formatValueToCurrency(_.get(data, 'points[0].label', ''), isCurrencyDimensionField);
  const value = _.get(data, 'points[0].value');
  const dataValues = _.compact(_.get(data, 'points[0].data.values', []));
  const sumDataValues = _.sumBy(dataValues, (dataValue) => Number(dataValue));

  let percentage = '';
  if (!_.isEmpty(dataValues)) {
    const roundOffValue = _.round(((value * 100) / sumDataValues), 2);
    percentage = `(${roundOffValue} %)`;
  }

  const htmlContent = (
    <div>
      <div className="pb-1 mb-2 border-bottom text-nowrap"><b>{label}</b></div>
      <div className="pb-1">{hovertext} {percentage}</div>
    </div>
  );
  const anchor = {
    x: data.event.x - containerRect.x,
    y: data.event.y - containerRect.y
  };
  const newAnchor = {
    x: _.min([anchor.x, pieChartMargins.x]),
    y: pieChartMargins.y
  }

  return [
    {
      className: isSmallView ? 'pie-chart-flyout': "" ,
      anchor: isSmallView ? newAnchor : pieChartMargins,
      chartContainer,
      html: htmlContent
    }
  ];
};
