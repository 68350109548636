import _ from 'lodash';
import {
  SNAPSHOT_VISUALIZATION_TYPES,
  SORT_BY_OPTIONS
} from 'appConstants';

export const sortChartData = (chartData, sortOption) => {
  const groupSortTypes = _.chain(SORT_BY_OPTIONS).map('type').take(2).value();
  const sortField = _.includes(groupSortTypes, sortOption.type) ? 'dimension' : 'count'
  const sortOrder = sortOption.sortOrder;
  const sortedDate = _.sortBy(chartData, (datum) => {
    if(sortField == 'count') {
      return Number(datum[sortField]);
    }else{
      return datum[sortField];
    }
  });
  return sortOrder == 'asc' ? sortedDate : _.reverse(sortedDate);
}

export const isScatterChart = (currentSnapshotView) => {
  return _.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type);
};
