import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import FilterIcon from 'pages/dashboard/components/TemplateCards/FilterIcon';
import { getQuickFilterEntriesFromTemplate } from 'pages/drilldown/components/QuickFilterBar/helper';
import { getTemplateFiltersOptions, getBookmarkFiltersOptions } from 'helpers/FilterIconHelper';
import { shouldShowCommonDateFilter } from 'pages/dashboard/components/Collections/collectionHelper';
import { CARD_TYPES } from 'appConstants';
import { bulkUpdateBookmarks } from 'common/api/bookmarksApi';

class BookmarkFilterBadges extends PureComponent {
  getBookmarkFilters = () => {
    const { cardEntry } = this.props;
    const isNoneDateSelected = _.get(cardEntry, 'bookmarkOptions.isNoneDateSelected', false);
    const type = isNoneDateSelected ? CARD_TYPES.TEMPLATE : CARD_TYPES.BOOKMARK;
    return getTemplateFiltersOptions(this.props, type, this.isRelativeDateSelected());
  }

  isRelativeDateSelected = () => {
    return _.get(this.props.cardEntry, 'bookmarkOptions.isRelativeDateSelected', false);
  }

  renderCollectionFilterBadges = () => {
    const {
      cardEntry, currentDrilldownViewEntry, templateEntry, currentDrilldownTemplateId,
      cardEntries, isBookmarkCardType
    } = this.props;
    const isBookmarkCard = isBookmarkCardType || _.get(cardEntry, 'isBookmarkCard', false);
    const {
      dateFilters, collectionFilters, bookmarkFitlersText, isEqualDateFilters
    } = this.getBookmarkFilters();

    const quickFilterEntries = getQuickFilterEntriesFromTemplate(
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    );
    if ((_.isEmpty(collectionFilters) &&
      (isEqualDateFilters || (_.isEmpty(dateFilters) && !shouldShowCommonDateFilter(cardEntries))))
    ) {
      return null;
    }

    return (
      <FilterIcon
        isRelativeDateSelected={this.isRelativeDateSelected()}
        key='collection-filter-badge'
        bookmarkFitlersText={bookmarkFitlersText}
        isBookmarkCard={isBookmarkCard}
        onlyRenderFilters={true}
        isHideDateFilters={isEqualDateFilters}
        isUnSelectFilters={true}
        currentDrilldownViewEntry={currentDrilldownViewEntry}
        cardEntries={cardEntries}
        dateFilters={dateFilters}
        templateEntry={templateEntry}
        hideCommonFilters={false}
        quickFilterEntries={quickFilterEntries}
        globalFilters={collectionFilters}
      />
    );
  }

  handleRemoveBookmarkDateClick = (e, filterName) => {
    e.stopPropagation();
    const { cardEntry, templateEntry, dateFilters } = this.props;
    const additionalDateFilters = _.get(templateEntry, 'additional_date_filters', []);
    const additionalFilter = _.find(additionalDateFilters, (datum) => {
      return _.get(datum, 'name') === filterName;
    });

    let bookmark = cardEntry;
    if(!_.isEmpty(additionalFilter)){
      bookmark['bookmarkOptions'].isNoneAdditionalDateFilters = true;
    } else {
      bookmark['commonFilters'].dateRange = dateFilters['dateRange'];
      bookmark['bookmarkOptions'].isNoneDateSelected = true;
      bookmark['bookmarkOptions'].isRelativeDateSelected = false;
    }

    const updateBookmarks = [{
      bookmark
    }];

    bulkUpdateBookmarks(updateBookmarks)
      .then((response) => response.json())
      .then(() => {
        this.props.reloadBookmarks();
      })
      .catch((error) => {
        toast.error(`Error while update bookmark. ${error}`);
      });
  }

  renderFilterBadges = () => {
    const {
      cardEntry, currentDrilldownViewEntry, currentDrilldownTemplateId, templateEntry, isBookmarkCardType
    } = this.props;
    const { dateFilters, quickFilters, collectionFilters } = getBookmarkFiltersOptions(this.props);
    const quickFilterEntries = getQuickFilterEntriesFromTemplate(
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    );
    const isNoneDateSelected = _.get(cardEntry, 'bookmarkOptions.isNoneDateSelected', false);
    const isNoneAdditionalDateSelected = _.get(
      cardEntry, 'bookmarkOptions.isNoneAdditionalDateFilters', false
    );
    const type = isNoneDateSelected ? CARD_TYPES.TEMPLATE : CARD_TYPES.BOOKMARK;
    const {
      collectionFilters: templateCollectionFilters, isEqualDateFilters, dateFilters: templateDateFilters
    } = getTemplateFiltersOptions(this.props, type);
    const cardEntries = [{ templateEntry, viewEntry: currentDrilldownViewEntry }];
    const isBookmarkCard = isBookmarkCardType || _.get(cardEntry, 'isBookmarkCard', false);
    const hideOverrideLabel = (
      (_.isEmpty(templateCollectionFilters) || _.isEqual(collectionFilters, templateCollectionFilters)) &&
      (isEqualDateFilters || _.isEmpty(templateDateFilters))
    );
    const { templateDateFitlersText } = this.getBookmarkFilters();
    const isRemoveSaveDate = !isEqualDateFilters && (!isNoneDateSelected || !isNoneAdditionalDateSelected);
    const filteredGeojson = _.get(cardEntry, 'visualization.mapOptions.filteredGeojson', {});
    const shapeGeoJson= _.get(cardEntry, 'visualization.mapOptions.shape', {});
    const shapeData = _.get(cardEntry, 'visualization.mapOptions.shapeData', []);
    return (
      <FilterIcon
        isRelativeDateSelected={this.isRelativeDateSelected()}
        type={type}
        bookmarkFitlersText={templateDateFitlersText}
        key='bookmark-filter-badge'
        isBookmarkCard={isBookmarkCard}
        currentDrilldownViewEntry={currentDrilldownViewEntry}
        hideCommonFilters={false}
        hideOverrideLabel={hideOverrideLabel}
        cardEntries={cardEntries}
        dateFilters={dateFilters}
        templateEntry={templateEntry}
        quickFilters={quickFilters}
        quickFilterEntries={quickFilterEntries}
        globalFilters={collectionFilters}
        isRemoveSaveDate={isRemoveSaveDate}
        filteredGeojson={filteredGeojson}
        handleRemoveBookmarkDate = {this.handleRemoveBookmarkDateClick}
        shapeGeoJson={shapeGeoJson}
        shapeData={shapeData}
      >
        {this.renderCollectionFilterBadges()}
      </FilterIcon>
    );
  }

  render() {
    return [this.renderFilterBadges(), this.renderCollectionFilterBadges()];
  }
}

BookmarkFilterBadges.propTypes = {
  cardEntry: PropTypes.object,
  currentDrilldownViewEntry: PropTypes.object,
  currentDrilldownTemplateId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  templateEntry: PropTypes.object,
  dateFilters: PropTypes.object,
  cardEntries: PropTypes.array,
  globalFilters: PropTypes.array,
  isBookmarkCardType: PropTypes.bool,
  reloadBookmarks: PropTypes.func
}

BookmarkFilterBadges.defaultProps = {
  isBookmarkCardType: false
}

export default BookmarkFilterBadges;
