import React from 'react'
import PropTypes from 'prop-types'
import MenuDropDownSelector from 'common/components/MenuDropDown/MenuDropDownSelector'
import Legends from './Legends'
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { isMobileOrTablet } from 'helpers/DomPageHelper';

function MapLegends(props) {

  const { legends, toggleClickLegendButton, isOpenLegend, showMobileView } = props;

  const onLegendButtonClick = () => {
    toggleClickLegendButton(!isOpenLegend);
  }

  const renderButtonMobileView = () => {
    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          className=""
          onKeyDown={() => onLegendButtonClick()}
          onClick={() => onLegendButtonClick()}>
          <ForgeIcon name="category" />
        </button>
      </ForgeButton>
    );
  }

  const renderLegends = () => {
    if (isMobileOrTablet() && !showMobileView) {
      return renderButtonMobileView();
    }

    return (
      <MenuDropDownSelector
        toggleClickLegendButton={toggleClickLegendButton}
        toggleButtonLabel="Legend"
        menuType="Legend"
        leadingIconName="category"
        className="map-custom-control">
        <div className="legend-wrapper">
          <Legends legends={legends} />
        </div>
      </MenuDropDownSelector>
    )
  }

  return (
    <>
      {renderLegends()}
    </>
  )
}

MapLegends.propTypes = {
  legends: PropTypes.array, 
  toggleClickLegendButton: PropTypes.func,
  isOpenLegend: PropTypes.bool,
  showMobileView: PropTypes.bool
}

export default MapLegends
