import React, { Component } from 'react';

import PlotlyView from './PlotlyView';
import Legend from './Legend';
import PercentagePoint from './PercentagePoint';
import PlotlyTooltip from 'modules/PlotlyTooltip';
import { PLOTLY_HOVER_DEBOUNCE_WAIT_TIME } from 'modules/visualization/constants';
import { getBarChartPopupConfigs } from 'common/contentFormatter/barChartContentFormatter';
import {
  getSelectedTemplateForSLAWatch,
  getSLAWatchCalculationType
} from 'common/config/customerConfiguration';
import { getFlyoutEntries, getBulletChartSlaDistance } from '../slaWatchHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { SLA_CALCULATION_TYPES } from 'appConstants';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SlaLoadingSpinner from '../SlaLoadingSpinner';

class BulletChart extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showPercentagePoint: false,
      showCategoriesNoData: true,
    };
  }

  componentDidMount() {
    if (this.popupContainer) {
      this.plotlyTooltip = new PlotlyTooltip(this.popupContainer);
    }
  }

  formatBarChartData = () => {
    const { dimensionData } = this.props;
    let formattedChartData = [];

    _.each(dimensionData, (dimensionEntry) => {
      const nullValueLabel = getNullValueLabel(getSelectedTemplateForSLAWatch());
      const dimensionName = dimensionEntry['dimension'] || nullValueLabel;
      const dimensionData = {
        isGroup: true,
        value: "",
        text: dimensionName,
        label: dimensionName,
        tickLabel: `  <span style="fill: black;font-family: Roboto;font-style: normal;font-weight: 500;
          font-size:16px;line-height:16px;">${dimensionName} &#32; &#32; </span>  `,
        range: ""
      };

      formattedChartData.push(dimensionData);
      this.getChecks(dimensionEntry, formattedChartData);
    });

    return _.reverse(formattedChartData);
  }

  getChecks = (dimensionEntry, formattedChartData) => {
    const nullValueLabel = getNullValueLabel(getSelectedTemplateForSLAWatch());
    const dimensionName = dimensionEntry['dimension'] || nullValueLabel;
    _.each(dimensionEntry['chart_data'], (check) => {
      const userAssignedLabel = check['label'] || nullValueLabel;
      let label = userAssignedLabel + ' ' + dimensionName + ' ' + check['bucket_id'];
      let tickLabel = '', flyoutEntries = [], percentagePointDistance;
      const failLabel = "<span style='font-size:16px; font-weight: bold; fill: Red;'>\u274C </span>"
      const passLabel = "<span style='font-size:23px; font-weight: bold; fill: green;'>\u2713 </span>"

      if (check['isConditionPassed']) {
        tickLabel = `<span style="fill: black;font-family: Roboto;font-style: normal;font-weight: normal;
          font-size:14px;line-height:14px;"> ${check['label']} </span>` + '&#32; ' + passLabel;
      } else {
        tickLabel = `<span style="fill: black;font-family: Roboto;font-style: normal;font-weight: normal;
          font-size:14px;line-height:14px;"> ${check['label']} </span>` + '&#32; ' + failLabel;
      }

      const customData = [[dimensionName, userAssignedLabel]];
      const flyoutData = {
        ...check,
        count: Number(dimensionEntry[check['bucket_id']]),
        dimension_count: Number(dimensionEntry['dimension_count']),
        suffix: check['suffix']
      }
      if (getSLAWatchCalculationType() == SLA_CALCULATION_TYPES.CUMULATIVE_PERCENTAGE) {
        flyoutEntries = getFlyoutEntries(flyoutData)
        percentagePointDistance = getBulletChartSlaDistance(flyoutData)
      }
      formattedChartData.push(
        {
          value: check['value'],
          label,
          tickLabel: tickLabel,
          target: check['target'],
          customData,
          flyoutEntries,
          percentagePointDistance,
          hoverText: String(check['value'])
        }
      );
    });
  }

  onPlotlyHover = _.throttle((event) => {
    this.setMouseCursor('inherit');
    const popupConfigs = getBarChartPopupConfigs({
      chartContainer: this.chartContainer,
      data: event,
      templateId: getSelectedTemplateForSLAWatch(),
      isSlaWatch: true
    });
    this.plotlyTooltip.showPopups(popupConfigs);
  }, PLOTLY_HOVER_DEBOUNCE_WAIT_TIME);

  onPlotlyUnhover = () => {
    this.setMouseCursor('inherit');
    this.plotlyTooltip.hidePopups();
  };

  setMouseCursor(cursor) {
    if (this.chartContainer.querySelector('g.draglayer')) {
      this.chartContainer.querySelector('g.draglayer').style['cursor'] = cursor;
    }
  }

  togglePercentagePoint = () => {
    this.setState({
      showPercentagePoint: !this.state.showPercentagePoint
    })
  }

  toggleCategoriesNoData = () => {
    const { onShowNoDataChange } = this.props;
    this.setState({
      showCategoriesNoData: !this.state.showCategoriesNoData
    });
    onShowNoDataChange(!this.state.showCategoriesNoData);
  }

  renderLoadingInfo = () => {
    if (!this.props.isLoading) {
      return null;
    }

    return (
      <div className='sla-bullet-chart'>
        <SlaLoadingSpinner isLoading={this.props.isLoading} />
      </div>
    )
  }

  renderBodyContent = () => {
    const { showPercentagePoint } = this.state;

    const chartData = this.formatBarChartData();
    const extraPlotlyParams = {
      onHover: this.onPlotlyHover,
      onUnhover: this.onPlotlyUnhover
    };

    const slaClassName = this.props.isLoading ? '' : 'sla-bullet-chart';

    return (
      <div className={slaClassName}
        ref={(ref) => this.chartContainer = ref}
        onMouseOut={this.onContainerMouseOut} >
        {!this.props.isLoading &&
          <PlotlyView
            data={chartData}
            extraPlotlyParams={extraPlotlyParams}
            showPercentagePoint={showPercentagePoint}
          ></PlotlyView>
        }
        <div
          id="popup-container"
          className="popup-container" ref={(ref) => this.popupContainer = ref}>
        </div>
      </div>
    )
  }

  renderBarChart() {
    const { showPercentagePoint, showCategoriesNoData } = this.state;
    const { selectedDimension, noDataCountLabel, isLoading } = this.props;

    const slaChartLegendClasses = classNames('sla-bullet-chart-legend', {
      'action-disable': isLoading
    });

    return (
      <div className="d-flex">
        {this.renderLoadingInfo()}
        {this.renderBodyContent()}
        <div className={slaChartLegendClasses}>
          <Legend></Legend>
          <PercentagePoint
            selectedDimension={selectedDimension}
            showPercentagePoint={showPercentagePoint}
            showCategoriesNoData={showCategoriesNoData}
            onPercentagePointChange={this.togglePercentagePoint}
            onCategoriesNoDataChange={this.toggleCategoriesNoData}
            noDataCountLabel={noDataCountLabel}
          >
          </PercentagePoint>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderBarChart()}
      </div>
    );
  }
}

BulletChart.propTypes = {
  dimensionData: PropTypes.array,
  selectedDimension: PropTypes.object,
  onShowNoDataChange: PropTypes.func,
  noDataCountLabel: PropTypes.string,
  isLoading: PropTypes.bool
};

export default BulletChart;
