import { buildQueryString } from 'helpers/HttpHelper';
import { fetchApiData } from 'helpers/apiResponseHelper';

export const shareViaEmail = (emailData) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(emailData),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/share/send_message.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const shareRadarViaEmail = (emailData) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(emailData),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/share/radar_send_message.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const shareBookmarkViaEmail = (emailData) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(emailData),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/share/bookmark_share_message.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const subscriptionConfirmationEmail = (emailData) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(emailData),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/share/subscription_confirmation_message.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const alertConfirmationSendEmail = (emailData) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(emailData),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/share/alert_confirmation_message.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const getGlobalFilterFieldValues = (queryParams, abortController) => {
  const apiUrl = "/api/search/global_filters_values.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
}

export const getGlobalFilterColumnValues = (queryParams) => {
  const apiUrl = "/api/search/global_filters_column_values.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`);
}

export const updateUserPreferences = (globalFilters, dateFilters) => {
  const queryParams = {globalFilters, dateFilters};
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(queryParams),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/user_preferences.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const updateCurrentCollectionId = (collectionId) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({collectionId: collectionId}),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/user_preferences/update_active_collection_id.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const updateSlaPreferences = (slaGlobalFilters, slaDateFilters) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({slaGlobalFilters, slaDateFilters}),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/user_preferences/update_sla_preferences.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const updateRadarPreferences = (radarOptions) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({ radarOptions }),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/user_preferences/update_radar_preferences.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const getNotesData = (queryParams) => {
  const apiUrl = "/api/notes.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getSearchAssigneeName = (queryParams) => {
  const apiUrl = "/api/notes/field_search.json";
  return window.fetch(`${apiUrl}?${buildQueryString(queryParams)}`);

}

export const getEntityCountsUrl = (queryParams) => {
  const apiUrl = 'api/visualization/entity_counts.json';
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const onSaveNotes = (notesEntry) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(notesEntry),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = "/api/notes.json";
  return window.fetch(apiUrl, fetchOptions);
}

export const getDatasetUpdatedAtUrl = (queryParams) => {
  const apiUrl = '/api/dataset_meta.json';
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getRadarData = (queryParams, abortController) => {
  const apiUrl = "/api/radar_area_wise_data.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
};

export const getRadarTotalRowData = (queryParams, abortController) => {
  const apiUrl = "/api/radar_total_data.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
};

export const getRadarShapeTileUrl = (params) => {
  return `${window.location.protocol}//${window.location.host}`+
    '/api/radar_with_in_title.json?x={x}&y={y}&z={z}&' +
    buildQueryString(params);
};

export const getMinDatesUrl = () => {
  const params = { includeAllTemplate: true };
  return (`/api/get_template_min_dates.json?${buildQueryString(params)}`);
};

export const getUserDetailsUrl = () => {
  return ('/user/details.json');
};

export const checkValidUrl = (params) => {
  return fetchApiData(`/validate_url.json?${buildQueryString(params)}`);
};

export const getUserLoggedDetailsUrl = () => {
  return ('/user_logged_in_detail/user_details.json');
};

export const updateUserLoggedOnboardWizardStatus = (params) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(params),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = "/user_logged_in_detail/update_onboard_status.json";
  return window.fetch(apiUrl, fetchOptions);
}
