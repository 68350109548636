export const UPDATE_RADAR_OPTIONS = 'UPDATE_RADAR_OPTIONS';
export const UPDATE_DEFAULT_RADAR_OPTIONS = 'UPDATE_DEFAULT_RADAR_OPTIONS';
export const UPDATE_CURRENT_DATA_RANGE_OPTIONS = 'UPDATE_CURRENT_DATA_RANGE_OPTIONS';
export const UPDATE_PREVIOUS_DATA_RANGE_OPTIONS = 'UPDATE_PREVIOUS_DATA_RANGE_OPTIONS';
export const UPDATE_RADAR_SHAPE_AREA_ENTRY = 'UPDATE_RADAR_SHAPE_AREA_ENTRY';
export const UPDATE_RADAR_SELECTED_TRACTS = 'UPDATE_RADAR_SELECTED_TRACTS';
export const UPDATE_RADAR_SELECTED_METRIC_ENTRIES = 'UPDATE_RADAR_SELECTED_METRIC_ENTRIES';
export const UPDATE_RADAR_SELECTED_MAP_METRIC = 'UPDATE_RADAR_SELECTED_MAP_METRIC';
export const UPDATE_RADAR_METRIC_FILTERS = 'UPDATE_RADAR_METRIC_FILTERS';
export const UPDATE_RADAR_TOGGLE_AREA = 'UPDATE_RADAR_TOGGLE_AREA';
export const UPDATE_SELECTED_AREA_NAME = 'UPDATE_SELECTED_AREA_NAME';
export const UPDATE_RADAR_METRIC_GLOBAL_FILTERS = 'UPDATE_RADAR_METRIC_GLOBAL_FILTERS';

export const updateRadarOptions = (radarOptions) => ({
  type: UPDATE_RADAR_OPTIONS,
  radarOptions
});

export const updateToDefaultRadarOptions = (radarOptions) => ({
  type: UPDATE_DEFAULT_RADAR_OPTIONS,
  radarOptions
});

export const updateCurrentDateRangeOptions = (dateRangeOptions) => ({
  type: UPDATE_CURRENT_DATA_RANGE_OPTIONS,
  dateRangeOptions
});

export const updatePreviousDateRangeOptions = (dateRangeOptions) => ({
  type: UPDATE_PREVIOUS_DATA_RANGE_OPTIONS,
  dateRangeOptions
});

export const updateRadarShapeAreaEntry = (selectedShapeAreaEntry) => ({
  type: UPDATE_RADAR_SHAPE_AREA_ENTRY,
  selectedShapeAreaEntry
});

export const updateRadarSelectedTracts = (selectedTracts, shapeAreaType) => ({
  type: UPDATE_RADAR_SELECTED_TRACTS,
  selectedTracts,
  shapeAreaType
});

export const updateRadarSelectedMetricEntries = (selectedMetricEntries) => ({
  type: UPDATE_RADAR_SELECTED_METRIC_ENTRIES,
  selectedMetricEntries
});

export const updateRadarSelectedMapMetric = (selectedMapMetric) => ({
  type: UPDATE_RADAR_SELECTED_MAP_METRIC,
  selectedMapMetric
})

export const updateRadarMetricFilters = (metricFilters) => ({
  type: UPDATE_RADAR_METRIC_FILTERS,
  metricFilters
})

export const updateRadarMetricGlobalFilters = (metricGlobalFilters) => ({
  type: UPDATE_RADAR_METRIC_GLOBAL_FILTERS,
  metricGlobalFilters
})

export const updateRadarToggleArea = (key, isChecked) => ({
  type: UPDATE_RADAR_TOGGLE_AREA,
  key,
  isChecked
});

export const updateSelectedAreaName = (selectedKey, selectedAreaName) => ({
  type: UPDATE_SELECTED_AREA_NAME,
  selectedKey,
  selectedAreaName
});
