// Vendor Imports
import classNames from 'classnames';
import { templateIdPropTypes, viewEntryPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import FilterItem from './FilterItem';

class Filters extends Component {
  constructor() {
    super();
    this._addFilterContainerRef = React.createRef();
  }
  getFilterAttributes = () => {
    return _.pick(this.props,
      'filterFieldEntries',
      'filters',
      'onFiltersChange',
      'isFilterBadge',
      'templateId',
      'type',
      'apiParams',
      'isUnSelectFilters',
      'canStopPropagation',
      'viewEntry',
      'isDisabledAddFilterButton',
      'isFilterActive',
      'placement',
      'isCollaborated',
      'disabledFilters',
      'isCustomSearch',
      'currentCollection',
      'isAnalysisPageFilter'
    );
  }

  renderFilterItem = (filter, filterKey) => {
    const { filterFieldEntries } = this.props;
    const matchedFilterEntry = _.find(filterFieldEntries, { field: filter.field });
    if(!matchedFilterEntry) {
      return null;
    }
    const key = `${filter.field}-${filterKey}`;
    const filterBadgeClass = classNames('filter-badge');

    return (
      <div className={filterBadgeClass} key={key}>
        <div className="collection-filter-badge">
          <FilterItem
            key={key}
            filter={filter}
            filterKey={filterKey}
            isEditMode={true}
            {...this.getFilterAttributes()}/>
        </div>
      </div>
    );
  }

  renderFilterItems = () => {
    const { filters, type } = this.props;
    if (_.isEmpty(filters)) {
      return null;
    }

    return _.map(filters, (filter, index) => {
      if (type === 'quickFilters' && _.get(filter, 'type') === 'text') {
        return _.map(filter.conditions, (filterCondition, conditionIndex) => {
          const newFilter = { ...filter, conditions: [filterCondition] };
          return this.renderFilterItem(newFilter, `${index}-${conditionIndex}`);
        });
      } else {
        return this.renderFilterItem(filter, index);
      }
    });
  }

  render() {
    const { isDisabledAddFilterButton, disabledFilters, filters } = this.props;
    return (
      <>
        {!_.isEmpty(filters) &&
          <div className='d-flex align-items-center flex-wrap gap-8'>
            {this.renderFilterItems()}
          </div>
        }
        {(!isDisabledAddFilterButton && !disabledFilters) &&
          <div className='add-filter-wrapper forge-popup-host' ref={this._addFilterContainerRef}>
            <FilterItem
              filter={{}} {...this.getFilterAttributes()} key="new-filter-item"
              addFilterContainerRef={this._addFilterContainerRef}
            />
          </div>
        }
      </>
    );
  }
}

Filters.propTypes = {
  isDisabledAddFilterButton: PropTypes.bool,
  isUnSelectFilters: PropTypes.bool,
  type: PropTypes.string,
  apiParams: PropTypes.object,
  filterFieldEntries: PropTypes.array,
  filters: PropTypes.array,
  templateId: templateIdPropTypes,
  onFiltersChange: PropTypes.func,
  isFilterBadge: PropTypes.bool,
  viewEntry: viewEntryPropTypes,
  isFilterActive: PropTypes.func,
  canStopPropagation: PropTypes.bool,
  disabledFilters: PropTypes.bool,
  placement: PropTypes.string,
  isCollaborated: PropTypes.bool,
  currentCollection: PropTypes.object
}

Filters.defaultProps = {
  isDisabledAddFilterButton: false,
  isUnSelectFilters: false,
  isFilterBadge: false,
  canStopPropagation: false,
  viewEntry: {},
  isFilterActive: _.noop,
  disabledFilters: false,
  isCollaborated: false,
  currentCollection: {}
}

export default Filters;
