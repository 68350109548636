import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const replaceRegex =  RegExp(";","g");

const PermissonValueSelector = ({ category, value }) => {
  return (
    <div className="field-value-picker">
      <div className="filter-chip">
        <div className="field-title filter-chip-values pr-2"><i className="icons-np-key"></i>
          <div className="field-current-value">
            <span>{category}</span> is <b>{_.replace(value, replaceRegex,' or ')}</b>
          </div>
        </div>
        <div className="fields-dropdown filter-chip-show"></div>
      </div>
    </div>
  );
}

PermissonValueSelector.propTypes = {
  category: PropTypes.string,
  value: PropTypes.string
}

export default PermissonValueSelector;
