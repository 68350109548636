import React from 'react';
import { createRoot } from 'react-dom/client';

import PointMapAccessibility from './AccessibilityAudit/PointMapAccessibility';
import ChorplethAccessibility from './AccessibilityAudit/ChorplethAccessibility';

import GlobalEvents from 'common/components/GlobalEvents';
import { isChoroplethMap, isPointMapView } from "./helpers/MapOptionsHelper";
import { SOURCES as CHOROPLETH_SOURCES } from "./partials/ChoroplethMapPartial";
import { SOURCES as POINT_MAP_SOURCES } from './partials/PointsAndStackPartial';

export default class MapAccessibility {
  constructor(map, options = {}) {
    this.map = map;
    this._options = options;
    this.container = document.getElementById("map-accessibility-element");
    if(!_.isNull(this.container)){
      this.root = createRoot(this.container);
    }
  }

  addMapFeaturesForAudit = () => {
    const { currentMapView } = this._options;

    if (isChoroplethMap(currentMapView)){
      const features = this.map.querySourceFeatures(CHOROPLETH_SOURCES.SHAPES);
      this.root.render(<ChorplethAccessibility features={features} map={this.map} />);
    }else if (isPointMapView(currentMapView)){
      const features = this.map.querySourceFeatures(POINT_MAP_SOURCES.INCIDENTS);
      this.root.render(<PointMapAccessibility features={features} map={this.map}/>);
    }else{
      this.root.render(<div tabIndex={0} aria-label='Heat Map'></div>);
    }
  }

  updateFeatureForAudit = () => {
    const { currentMapView, comparisonPeriod } = this._options;
    const layer = isChoroplethMap(currentMapView) ? CHOROPLETH_SOURCES.SHAPES :
      POINT_MAP_SOURCES.INCIDENTS
    const eventType = isChoroplethMap(currentMapView) ? 'UPDATE_SHAPE_FEATURES' :
      'UPDATE_POINT_FEATURES'

    const features = this.map.querySourceFeatures(layer);
    GlobalEvents.emit(eventType, features, comparisonPeriod);
  }

  updateMapAccessibility = () => {
    const { comparisonPeriod } = this._options;
    if(!_.isNull(this.root) && comparisonPeriod != "Last Period"){
      this.root.render(null);
      this.addMapFeaturesForAudit();
    }
  }

  updateOptions = (options) => {
    this._options = options;
  }
}
