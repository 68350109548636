import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import ConfirmationDialogue from 'modules/Administration/ConfirmationDialogue';
import { ForgeIcon } from '@tylertech/forge-react';

import {
  isCollaborateSubscription,
  isRestrictedCollection,
} from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
class CollectionBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showConfirmModal: false
    };
  }

  handleCollectionClick = () => {
    const {
      enableConfirmModal,
      onCollectionClick,
      collection
    } = this.props

    if(enableConfirmModal) {
      this.setState({ showConfirmModal: true });
    }else {
      onCollectionClick(collection)
    }
  }

  handlePressEnterButton = (e, enterButtonCallback, args) => {
    if(isEnterButtonPressed(e)) {
      enterButtonCallback(args);
    }
  }

  handleCollectionChangeConfirm = (isConfirm) => {
    const { collection, onCollectionClick } = this.props;
    this.setState({showConfirmModal: false});

    if(isConfirm){
      onCollectionClick(collection)
    }
  }

  handleSubscriptionClick = (subscription) => {
    const { onSubscriptionClick, collection } = this.props;
    onSubscriptionClick(subscription, collection)
  }

  renderCollectionConfirmModal () {
    const { showConfirmModal } = this.state;

    if(!showConfirmModal){
      return null
    }
    const message =`Are you sure you want to leave this page without saving the changes made
      to this collection?`;
    return (<ConfirmationDialogue
      message={message}
      messageTitle={'Confirm changes'}
      onConfirmationCallBack = {this.handleCollectionChangeConfirm} />)
  }

  renderSubscriptions() {
    const { collection, currentSubscription } = this.props;
    const { collection_subscriptions, user_id } = collection;
    const userId = _.get(collection, 'currentCollectionUser.currentUserId', user_id);

    if(_.isEmpty(collection_subscriptions)) {
      return null
    }
    return collection_subscriptions.map((subscription) => {
      if(!_.isEqual(_.get(subscription, 'user_id'), userId)
        && !_.get(subscription, 'is_collaborate_subscription', false)) {
        return null
      }
      const isMuted = !_.get(subscription, 'send_email', true);
      const linkClassNames = classNames('link-items w-100 align-items-start flex-column', {
        'active': _.isEqual(subscription.id, currentSubscription.id)
      });

      let iconContent = '';
      if(isCollaborateSubscription(subscription)) {
        iconContent = (<ForgeIcon name="supervisor_account" className="forge-icon" />);
      }
      return (
        <div
          className="subscription-link"
          key={subscription.id}
          tabIndex="0"
          onKeyDown={
            (e) => this.handlePressEnterButton(e, this.handleSubscriptionClick, subscription)}>
          <div className={linkClassNames}
            onClick={() => this.handleSubscriptionClick(subscription)}>
              {iconContent}
              {_.get(subscription, 'name')}
              { isMuted ? <div className="text-muted mt-1">Muted</div> : null}
          </div>
        </div>
      );
    });
  }

  handleCollectionVisibleToggle = (collection) => {
    const { onCollectionUpdate } = this.props
    const newCollection = {
      ...collection,
      is_hidden: !_.get(collection, 'is_hidden', false)
    }

    onCollectionUpdate(newCollection);
  }

  renderCollection() {
    const {
      collection,
      isSelected,
      index,
      isManageCollection
    } = this.props;
    const linkClassNames = classNames('link-items w-100 d-flex align-items-center', {
        'active': isSelected
      });
    const formCheckClassNames = classNames('check-box', {
      'disabled': (isManageCollection || isRestrictedCollection(collection))
    });
    const isChecked = !_.get(collection, 'is_hidden', false);
    let iconContent = <i className="icons icons-collections-icon"></i>;
    if(isManageCollection) {
      iconContent = (<ForgeIcon name="supervisor_account" className="forge-icon" />);
    }
    return(
      <div key={index}>
        <div
          className="collection-link d-flex align-items-center">
            {iconContent}
          <div className={linkClassNames}>
            <div
              className="w-100"
              tabIndex="0"
              onKeyDown={(e) => this.handlePressEnterButton(e, this.handleCollectionClick, collection)}
              onClick={() => this.handleCollectionClick(collection)}>
              <span className="tx-14">
                {_.get(collection, 'name')}
              </span>
            </div>
            <div className={formCheckClassNames}>
              <Form.Check
                disabled={isManageCollection || isRestrictedCollection(collection)}
                className="ml-auto ml-5 collection-switch"
                tabIndex="0"
                type="switch" id={'checkbox'+index}
                checked={isChecked}
                onKeyDown={
                  (e) => this.handlePressEnterButton(e, this.handleCollectionVisibleToggle, collection)}
                onChange={() => this.handleCollectionVisibleToggle(collection)}
                label={isChecked ? 'Visible' : 'Hidden'}/>
            </div>
          </div>
        </div>
        {this.renderSubscriptions()}
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderCollection()}
        {this.renderCollectionConfirmModal()}
      </>
    );
  }
}

CollectionBar.propTypes = {
  index: PropTypes.number,
  collection: PropTypes.object,
  isSelected: PropTypes.bool,
  enableConfirmModal: PropTypes.bool,
  onCollectionClick: PropTypes.func,
  onSubscriptionClick: PropTypes.func,
  currentSubscription: PropTypes.object,
  onCollectionUpdate: PropTypes.func,
  isManageCollection: PropTypes.bool
}

export default (CollectionBar);
