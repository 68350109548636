
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ForgeDialog, ForgeIcon } from '@tylertech/forge-react';
import { ONBOARD_DIALOG_PROPS } from '../ManageCollection/constants';
import { removeLocalAndSessionStorage } from 'helpers/localStorageHelper';
import { KEY_ON_BOARD_WIZARD } from 'appConstants';
import { updateUserLoggedOnboardWizardStatus } from 'common/api/commonApi';
import { bulkUpdateCollections } from 'common/api/collectionApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import OnboardFooterSection from './OnboardFooterSection';
import OnboardBodySection from './OnboardBodySection';
import { removeBodyAttribute } from 'helpers/DomPageHelper';

function OnboardWizardModal(props) {
  const { isOpenModal, onToggleHandleModal, collections, onReloadCollection} = props;
  const [nextStep, setNextStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowWizardAgain, setIsShowWizardAgain] = useState(false);
  const [collectionData, setCollectionData] = useState(_.cloneDeep(collections));

  const handleClickNext = (step) => {
    setIsLoading(true);
    bulkUpdateCollections(collectionData).then((response) => response.json())
    .then(() => {
      setNextStep(step);
      setIsLoading(false);
      updateStatus(true);
    });
  }

  const handleClickDone = () => {
    removeLocalAndSessionStorage(KEY_ON_BOARD_WIZARD);
    setNextStep(0);
    onToggleHandleModal();
    removeBodyAttribute("forge-dialog-open");
  }

  const updateStatus = (status = false) => {
    updateOnboardStatus(status);
    onReloadCollection(true);
  }

  const updateOnboardStatus = (status = false) => {
    if (!isShowWizardAgain && !status) {
      return;
    }

    const params = {
      show_onboard_wizard: false
    };

    updateUserLoggedOnboardWizardStatus(params).then(() => {
    }).catch((err) => {
      console.error('Error on user onboard status', err)
    });
  }

  const handleClickSkip = () => {
    updateOnboardStatus();
    removeLocalAndSessionStorage(KEY_ON_BOARD_WIZARD);
    onToggleHandleModal();
    removeBodyAttribute("forge-dialog-open");
  }

  const isChangeStatus = () => {
    let changeStatus = false;
    _.forEach(collectionData, (collection) => {
      const filterCollection = _.find(collections, { id: collection.id, is_hidden: collection.is_hidden });
      if (_.isEmpty(filterCollection)) {
        changeStatus = true;
        return
      }
    })
    return changeStatus;
  }

  return (
    <>
      <ForgeDialog open={isOpenModal} options={ONBOARD_DIALOG_PROPS} className="onboard-wizard">
        <header className="forge-dialog__header" forge-dialog-move-target="">
          <h2 className="forge-dialog__title modal-title-info">Customize dashboard
            <span className="float-right mt-3 cursor-pointer" onClick={() => handleClickSkip()}>
              <ForgeIcon name="close"></ForgeIcon>
            </span>
          </h2>
        </header>
        <div><hr className='mt-0' /></div>
        {isLoading && <LoadingSpinner isLoading={isLoading} />}
        <OnboardBodySection
          nextStep={nextStep}
          collectionData={collectionData}
          setCollectionData={setCollectionData} />
        <div><hr className='m-0' /></div>
        <OnboardFooterSection
          nextStep={nextStep}
          isShowWizardAgain={isShowWizardAgain}
          setIsShowWizardAgain={setIsShowWizardAgain}
          onHandleClickSkip={handleClickSkip}
          onHandleClickNext={handleClickNext}
          isChangeStatus={isChangeStatus}
          onHandleClickDone={handleClickDone} />
      </ForgeDialog>
    </>
  )
}

OnboardWizardModal.propTypes = {
  collections: PropTypes.array,
  isOpenModal: PropTypes.bool,
  onToggleHandleModal: PropTypes.func,
  onReloadCollection: PropTypes.func
}

export default OnboardWizardModal