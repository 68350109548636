import React from "react";
import classNames from 'classnames';
import { createRoot } from 'react-dom/client'

// Project Imports
import { getTipsyPosition, getTipsyStyle } from './helper';

class PlotlyCustomTooltip {
  constructor(container) {
    this._container = container;
    if(_.isNull(this._container)){
      container = document.getElementById('popup-container');
    }
    this.root = createRoot(container);
  }

  getPopupContent = (popupConfig, index) => {
    const {
      anchor,
      chartContainer,
      html,
      position,
      viewMode,
      isTimelineChart,
      isNeedBottomPadding,
      className,
      isHideArrow,
      customClass,
      isOverriderClass
    } = popupConfig;
    const flyoutPosition = position ? position : getTipsyPosition(anchor, chartContainer);
    const flyoutStyle = getTipsyStyle(anchor, flyoutPosition, chartContainer, viewMode, isTimelineChart);
    const defaultClassName = 'react-plotly-custom-tooltip tooltip';
    let tipsyClasses = classNames(`${defaultClassName} ${className} bs-tooltip-${flyoutPosition} show`, {
      'overtime-chart' : isTimelineChart,
      'bottom-padding': isNeedBottomPadding,
    });

    if(isOverriderClass){
      tipsyClasses = classNames(`${defaultClassName} ${className} show position-relative`, {
        'overtime-chart' : isTimelineChart,
        'bottom-padding': isNeedBottomPadding 
      });
    }

    const tooltipInnerClass = classNames("tooltip-inner", {
      [customClass] : !_.isEmpty(customClass)
    });

    return (
      <div className={tipsyClasses}
        style={flyoutStyle}
        role="tooltip"
        x-placement={flyoutPosition}
        key={index}
      >
        { !isHideArrow && <div className="arrow"></div> }
        <div className={tooltipInnerClass}>
          {html}
        </div>
      </div>
    );
  };

  showPopups = (popupConfigs) => {
    const content = popupConfigs.map(this.getPopupContent)
    this.renderPopupContent(content);
  }

  hidePopups = () => {
    this.renderPopupContent(null);
  }

  renderPopupContent = (content) => {
    if(!_.isNull(content)){
      this.root.render(content);
    }else{
      this.root.render(null);
    }
  }
}

export default PlotlyCustomTooltip;
