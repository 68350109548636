import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { getShapeIdsAndExtent } from '../helpers/mapHelper';

import { getMiniMapShapesOutlineColor } from 'common/config/templateConfiguration';

export const MOUSE_CURSOR_DEFAULT = 'auto';
export const MOUSE_CURSOR_HAND = 'pointer';
const SHAPE_FILTER_LAYER_OPACITY = 0.3;
const SHAPE_FILL_OPACITY = 0.8;

export const SOURCES = {
  SHAPES: 'filter-shapes'
};

export const LAYERS = {
  SHAPE_FILTER_LAYER: 'shape-filter-layer',
  SHAPE_FILTER_HIGHLIGHT: 'shape-filter-highlight',
  SHAPE_OUTLINE_HIGHLIGHT: 'shape-filter-outlines-highlight'
};

class ShapeFilterPartial extends Component {
  constructor(props, context) {
    super(props, context);
    this._shapeIds = props.selectedShapeIds;
    this._debouncedHandleShapeIdSelect = _.throttle(this.handleShapeIdSelect, 1000, { 'trailing': true });
  }

  state = {
    hoverOveredShapeId: '',
    enableDrawMode: false
  };

  componentDidMount() {
    this.initSourcesAndLayers();
    this.initHandlers();
  }

  componentWillUnmount() {
    const { map } = this.props;
    // Removing event listener previously added with Map.
    if(map){
      map.off('mousemove', this.handleMouseMove);
      map.off('click', this.handleMouseClick);
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.selectedShapeEntry, this.props.selectedShapeEntry)){
      this.removeSourcesAndLayers();
      this.initSourcesAndLayers();
    }
    if(this.props.isRadarDrawingEnabled && !this.state.enableDrawMode){
      this.setEnableDrawMode(true);
    }

    if (!_.isEqual(prevProps.selectedShapeIds, this.props.selectedShapeIds)){
      this._shapeIds = this.props.selectedShapeIds;
    }
  }

  setEnableDrawMode(drawingMode){
    this.setState({enableDrawMode: drawingMode});
  }

  initSourcesAndLayers(){
    const { selectedShapeEntry } = this.props;
    // Initialize sources and Layers only when shapesDatasets are configured
    if(!_.isEmpty(selectedShapeEntry)){
      this.initSources();
      this.initLayers();
    }
  }

  initSources() {
    const { selectedShapeEntry, map, currentDrilldownTemplateId, shapeTileApi } = this.props;
    const shapeGroupId = _.get(selectedShapeEntry, 'shape_dataset_id');
    const queryParams = { shapeGroupId, currentDrilldownTemplateId };

    map.addSource(SOURCES.SHAPES, {
      type: 'vector',
      'geojsonTile': true,
      'tiles': [shapeTileApi(queryParams)]
    });
  }

  initHandlers() {
    const { map } = this.props;
    if(map){
      map.on('mousemove', this.handleMouseMove);
      map.on('click', this.handleMouseClick);
    }
  }

  handleMouseMove = (event) => {
    const { map } = this.props;

    const features = map.queryRenderedFeatures(event.point, {
      layers: [LAYERS.SHAPE_FILTER_LAYER, LAYERS.SHAPE_FILTER_HIGHLIGHT]
    });

    map.getCanvas().style.cursor = _.isEmpty(features) ? MOUSE_CURSOR_DEFAULT : MOUSE_CURSOR_HAND;

    if(!_.isEmpty(features)){
      const shapeId = _.get(features, '[0].properties.shape_id');
      this.setState({ hoverOveredShapeId: shapeId });
    }else{
      this.setState({ hoverOveredShapeId: '' });
    }
  }

  handleMouseClick = (event) => {
    const { map, isRadarDrawingEnabled } = this.props;
    const { enableDrawMode, hoverOveredShapeId } = this.state;
    event.preventDefault();
    const features = map.queryRenderedFeatures(event.point, {
      layers: [LAYERS.SHAPE_FILTER_LAYER, LAYERS.SHAPE_FILTER_HIGHLIGHT]
    });
    if(!isRadarDrawingEnabled && enableDrawMode){
      this.setEnableDrawMode(false);
    }
    if (_.isEmpty(features)) {
      return;
    }
    const shapeId = _.get(features, '[0].properties.shape_id');
    this._shapeIds = this._shapeIds.includes(shapeId) ?
      _.without(this._shapeIds, shapeId) :
      this._shapeIds.concat(shapeId);

    this.setAndUpdateShapeIds(this._shapeIds, hoverOveredShapeId);

    this._debouncedHandleShapeIdSelect('');
  }

  handleShapeIdSelect(shapeId) {
    const { map } = this.props;
    const { shapeIds, shapesExtent } = getShapeIdsAndExtent(map, this._shapeIds, shapeId);

    this.props.toggleShapeIdsFilter(_.compact(shapeIds), shapesExtent);
  }

  initLayers() {
    const { map, selectedShapeIds, currentDrilldownTemplateId } = this.props;
    const { hoverOveredShapeId } = this.state;

    map.addLayer({
      'id': LAYERS.SHAPE_FILTER_LAYER,
      'type': 'fill',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'paint': {
        'fill-opacity': SHAPE_FILTER_LAYER_OPACITY,
        'fill-color':  {
          'type': 'identity',
          'property': 'color'
        },
        'fill-outline-color': getMiniMapShapesOutlineColor(currentDrilldownTemplateId)
      },
      'filter': ['all', ['!in', 'shape_id'].concat(selectedShapeIds || [])]
    });

    map.addLayer({
      'id': LAYERS.SHAPE_FILTER_HIGHLIGHT,
      'type': 'fill',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'paint': {
        'fill-opacity': SHAPE_FILL_OPACITY,
        'fill-color':  {
          'type': 'identity',
          'property': 'color'
        },
        'fill-outline-color':  {
          'type': 'identity',
          'property': 'outline_highlight_color'
        }
      },
      'filter': ['all', ['in', 'shape_id'].concat(selectedShapeIds)]
    });

    map.addLayer({
      'id': LAYERS.SHAPE_OUTLINE_HIGHLIGHT,
      'type': 'line',
      'source': SOURCES.SHAPES,
      'source-layer': '_geojsonTileLayer',
      'paint': {
        'line-color': {
          'type': 'identity',
          'property': 'outline_highlight_color'
        },
        'line-width': 2
      },
      'filter': ['==', 'shape_id', hoverOveredShapeId]
    });
  }

  removeSourcesAndLayers() {
    const { map } = this.props;

    _.each(LAYERS, (layerId) => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });
    _.each(SOURCES, (sourceId) => {
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    });
  }

  setAndUpdateShapeIds(shapeIds, hoverOveredShapeId) {
    const { map } = this.props;
    if (map.getLayer(LAYERS.SHAPE_FILTER_LAYER)) {
      map.setFilter(LAYERS.SHAPE_FILTER_LAYER, ['all', ['!in', 'shape_id'].concat(shapeIds)]);
      map.setFilter(LAYERS.SHAPE_FILTER_HIGHLIGHT, ['all', ['in', 'shape_id'].concat(shapeIds)]);
      map.setFilter(LAYERS.SHAPE_OUTLINE_HIGHLIGHT, ['==', 'shape_id', hoverOveredShapeId]);
    }
  }

  render() {
    const { hoverOveredShapeId } = this.state;
    this.setAndUpdateShapeIds(this._shapeIds, hoverOveredShapeId);
    return null;
  }
}

ShapeFilterPartial.propTypes = {
  selectedShapeEntry: PropTypes.object,
  selectedShapeIds: PropTypes.array,
  toggleShapeIdsFilter: PropTypes.func,
  shapeTileApi: PropTypes.func,
  currentDrilldownTemplateId: PropTypes.string,
  map: PropTypes.object,
  isRadarDrawingEnabled: PropTypes.bool
};

ShapeFilterPartial.defaultProps = {
  selectedShapeIds: [],
  isRadarDrawingEnabled: false
};

export default (ShapeFilterPartial);
