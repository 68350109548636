import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ForgeButton, ForgeTooltip } from '@tylertech/forge-react';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import GlobalEvents from 'common/components/GlobalEvents';

class DropDownFooter extends Component {
  constructor(props) {
    super(props);
    this.applyBtnRef = React.createRef();
  }

  componentDidUpdate(){
    const { isFocusApply } = this.props;

    if(isFocusApply){
      setTimeout(() => {
        this.applyBtnRef.current.focus();
      }, 300);
    }
  }

  componentDidMount() {
    GlobalEvents.on('ON_OUTSIDE_CLICK', this.onOutsideClick);
  }

  componentWillUnmount() {
    GlobalEvents.off('ON_OUTSIDE_CLICK', this.onOutsideClick);
  }

  onOutsideClick = () => {
    this.props.onApply();
  }

  onKeyDownApplyButton = (e) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      this.props.onApply();
    }
  }

  onKeyDownCancelButton = (e) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      this.props.onRemove();
    }
  }


  renderApplyButton = () => {
    const { onApply, isDisabledApplyButton, message } = this.props;
    if (isDisabledApplyButton) {
      return (
        <ForgeButton id='forge-apply-button' type="raised">
          <button
            disabled={true}
            type="button"
          >
            <span>Apply</span>
          </button>
          <ForgeTooltip target="#forge-apply-button" position="top">{message}</ForgeTooltip>
        </ForgeButton>
      );
    }

    return (
      <ForgeButton 
        id="apply-filter-id"
        type="raised" 
        ref={this.applyBtnRef}
      >
        <button
          tabIndex={0}
          type="button"
          onKeyDown={this.onKeyDownApplyButton}
          onClick={onApply}
        >
          <span>Apply</span>
        </button>
      </ForgeButton>
    );
  }

  render() {
    const { onRemove, onReset, hideApplyButton, hideResetButton, hideRemoveButton } = this.props;

    return (
      <div className="dropdown-filter-footer">
        { hideRemoveButton ?
          null :
          <ForgeButton type= 'flat' onClick={onRemove} 
            onKeyDown={this.onKeyDownCancelButton}>
            <button
              className="btn btn-sm btn-link px-0 float-left"
              tabIndex={0}
              aria-label="Remove"
              >
              Cancel
            </button>
          </ForgeButton>
        }

        <div className="ml-auto">
          { hideResetButton ?
            null :
            <button
              className="btn btn-sm btn-outline-dark mr-2"
              tabIndex={0}
              aria-label="Reset"
              onClick={onReset}>Reset</button>
          }
          { hideApplyButton ? null : this.renderApplyButton() }
        </div>
      </div>
    );
  }
}

DropDownFooter.propTypes = {
  isDisabledApplyButton: PropTypes.bool,
  hideApplyButton: PropTypes.bool,
  hideResetButton: PropTypes.bool,
  onApply: PropTypes.func,
  onRemove: PropTypes.func,
  onReset: PropTypes.func,
  message: PropTypes.string,
  hideRemoveButton: PropTypes.bool,
  isFocusApply: PropTypes.bool
};

DropDownFooter.defaultProps = {
  isDisabledApplyButton: false,
  hideApplyButton: false,
  hideRemoveButton: false,
  isFocusApply: false,
  onRemove: _.noop,
  onReset: _.noop,
  message: 'Please select a filter'
};

export default DropDownFooter;
