// Vendor imports.
import _ from 'lodash';

// Project Imports
import { getFormattedValue } from 'helpers/chartDataHelper';
import {
  isStackTypeChart,
  formatDimensionText,
  isBenchMarkEmpty,
  getViewLabel,
  getWrapTickText
} from '../vizOptionsHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getEmptyBucket } from './compareViewDataFormatter';
import { getStackedBarChartColors } from 'helpers/colorHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getSortByType, sortGroupByData } from '../barChartHelper';
import { getGroupByEntityField } from 'helpers/snapshotRendererHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsNumericTime } from 'helpers/visualizationHelper';

/*-------------------------------------------------------------------------------
 Input:
---------------------------------------------------------------------------------
[
  {
    "group_by_field":"CIS",
    "count":"2239",
    "min_count":"Abandoned Vehicle",
    "max_count":"Violation of Injunction",
    "view":"Closed",
    "secondary_count":"2327",
    "dimension_count":11
  },
  {
    "group_by_field":"Crime Analyst",
    "count":"13",
    "min_count":"Fleeing and Eluding",
    "max_count":"Vandalism-Criminal Mischief",
    "secondary_count":"2327",
    "view":"Open",
    "dimension_count":8
  },
]
---------------------------------------------------------------------------------
Output:
---------------------------------------------------------------------------------
[
  [
    {
      label: '100',
      secondaryLabel: '200',
      value: 'Pinellas - Education',
      text: '$100',
      secondaryText: '$200',
      customData: ['Pinellas', 'Education'],
      ticktext: 'Education',
      color: '#fff'
    },
   ....
  ],
 ....
]
*/

export function formatCombineViewData(apiDataEntries, vizOptions) {
  const { benchMarkEntries, templateId } = vizOptions;
  const stackedBarData = _.cloneDeep(apiDataEntries);
  const nullValueLabel = getNullValueLabel(templateId);
  const stackColors = getStackedBarChartColors(stackedBarData, nullValueLabel);
  const formattedData = formatApiDataEntries(apiDataEntries, {
    ...vizOptions,
    nullValueLabel,
    stackColors,
    isStackedChart: isStackTypeChart(vizOptions)
  });

  return formatDataWithBenchMark(formattedData, benchMarkEntries, vizOptions);
}

function formatApiDataEntries(apiDataEntries, options) {
  const { isStackedChart, apiParams, viewEntry, onFormattedDataLoad } = options;
  const sortByEntry = getSortByType({ apiParams, viewEntry }, true);
  const sortedApiEntries = sortedApiDataEntries(apiDataEntries, sortByEntry);
  const formattedData = _.chain(sortedApiEntries).
    map((apiDataEntry) => {
      return [getFormattedBarChartEntry(apiDataEntry, options)];
    }).
    value();

  if (isStackedChart) {
    onFormattedDataLoad(_.flatten(formattedData));
  } else {
    const summaryData = _.cloneDeep(formattedData);
    onFormattedDataLoad(_.flatten(summaryData));
  }

  return  formattedData;
}

const sortedApiDataEntries = (apiDataEntries, sortByEntry) => {
  let formattedData = _.chain(apiDataEntries).
    groupBy((entry) => getGroupByEntityField(_.get(entry, 'group_by_field'))).
    map((dimensions, category) => formatDimensionData(dimensions, category)).
    value() || [];

  formattedData = sortGroupByData(formattedData, sortByEntry);

  return _.chain(formattedData).
    map('dimensionEntries').
    flattenDeep().
    value() || [];
}

function formatDimensionData(dimensions, category) {
  return {
    category,
    count: _.sumBy(dimensions, (dimensionEntry) => Number(_.get(dimensionEntry, 'count', 0))),
    dimensionEntries: dimensions
  };
}

function formatDataWithBenchMark(formattedData, benchMarkEntries, vizOptions) {
  const { templateId } = vizOptions;
  if (!isBenchMarkEmpty(benchMarkEntries) && !_.isEmpty(formattedData)) {
    const bucketStartOption = {
      dimension: '',
      isBenchMark: true,
      templateId,
      isGroup: false,
      options: vizOptions
    }
    const benchMarkBucketStart = getEmptyBucket(bucketStartOption);

    const bucketEndOption = {
      dimension: null,
      isBenchMark: true,
      templateId,
      isGroup: false,
      options: vizOptions
    }
    const benchMarkBucketEnd = getEmptyBucket(bucketEndOption);
    formattedData = [[benchMarkBucketStart], ...formattedData, [benchMarkBucketEnd]];
  }

  return formattedData;
}

const getCombineYaxisLabel = (
  dimension,
  dimensionName,
  dimensionCount,
  isTruncateText= false,
  isCurrencyGroupByField,
  isComparisonEnabled
) => {
  const formmettedDimesion = formatValueToCurrency(dimension, isCurrencyGroupByField);
  const dimensionText = isComparisonEnabled ? `${formmettedDimesion} ${dimensionName}`
    : `${formmettedDimesion} ${dimensionName}  ${dimensionCount}`;
  return (isTruncateText ? getWrapTickText(dimensionText, "") : dimensionText);
}

const getFormattedBarChartEntry = (apiDataEntry, options) => {
  const { count, secondary_count, view, dimension_count, group_by_field, period } = apiDataEntry;
  const {
    dimensionName,
    viewEntry,
    secondaryMetricEntry,
    templateId,
    stackColors,
    isStackedChart,
    nullValueLabel,
    viewMode,
    isCurrencyGroupByField,
    isComparisonEnabled
  } = options;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const viewLabel = getViewLabel(view, nullValueLabel);
  const value = `${viewLabel} - ${getFormattedValue(count, viewEntry)}`;
  const newDimension = formatDimensionText(group_by_field, false, templateId);
  const dimension = (group_by_field || '');

  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  const dataUnit = timeDurationDataUnit(viewEntry);
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry);

  const countLabel = isSecondsFormat ? getSecondsAsNumericTime(count, dataUnit) : count;
  const secondaryCountLabel = isSecondsFormatSecondary ?
    getSecondsAsNumericTime(secondary_count, secondaryDataUnit) : secondary_count;

  let formattedBarChartEntry = {
    label: countLabel || 0,
    color: _.get(stackColors, viewLabel, 0),
    secondaryLabel: secondaryCountLabel || 0,
    actualLabel: Number(count) || 0,
    actualSecondaryLabel: Number(secondary_count) || 0,
    value: getCombineYaxisLabel(newDimension, dimensionName, dimension_count,
      false, isCurrencyGroupByField, isComparisonEnabled),
    text: isStackedChart ?
      value : getFormattedValue(count, viewEntry, false, isSmallView),
    secondaryText: getFormattedValue(secondary_count, secondaryMetricEntry,
      false, isSmallView),
    hoverText: isStackedChart ?
      value : getFormattedValue(count, viewEntry),
    secondaryHoverText: getFormattedValue(secondary_count, secondaryMetricEntry),
    customData: [dimension],
    ticktext: getCombineYaxisLabel(newDimension, dimensionName, dimension_count,
      true, isCurrencyGroupByField, isComparisonEnabled),
    groupByField: dimension,
    period: period
  }

  if (isStackedChart) {
    return {
      ...formattedBarChartEntry,
      view: viewLabel,
      formattedViewCount: getFormattedValue(count, viewEntry)
    }
  }

  return formattedBarChartEntry;
};
