import _ from 'lodash';
import moment from 'moment';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import { getDrilldownEntryForSnapshot } from 'helpers/apiParamsHelper';
import { getApiParams } from 'helpers/apiParamsHelper';
import { getComparisonPeriodDateRanges } from 'helpers/dateHelper';
import {
  SNAPSHOT_VISUALIZATION_TYPES,
  COMPARE_VIEW_DRILLDOWN_OPTIONS,
  DRILLDOWN_VIEW_TYPE,
  COMPARE_VIEW_DATA_LIMIT,
  SCATTER_PLOT_DATA_LIMIT,
  STACK_CHART_ADD_TEXT,
  DATE_COMPARISON_TYPE_OPTIONS,
  DATE_COMPARISON_TYPE
} from 'appConstants';
import {
  getBenchMarkEntries,
  getBenchMarkMetricEntries,
  getBenchmarkNames,
  getDefaultSortOption,
  getSecondaryMetricEntry,
  getSortByOption,
  isCurrencyType,
  isGroupByCurrencyType
} from 'helpers/visualizationHelper';
import { getSnapshotChartType } from 'common/config/visualizationConfiguration';
import { getDimensionName, getPrimaryMetricName } from 'helpers/displayNameHelper';
import {
  isStackTypeChart
} from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';

const itemsPerPage = 15;
export const getSnapshotOptions = (stateOptions, propsOptions) => {
  const {
    currentDrilldownDimensionField,
    currentDrilldownViewEntry,
    currentSnapshotView,
    showScatterPlotRange,
    currentDrilldownTemplateId,
    currentGroupByViewType,
    currentVisualizationType,
    currentSecondaryMetricField,
    currentBenchMarkMetricNames,
    currentGroupByChartApproach,
    currentDimensionSortType,
    currentDrilldownGroupByEntry,
    showSnapshotChartValues,
    pieChartAnnotationOptions,
    commonFilters,
    compareYearRanges,
    comparisonType,
    isComparisonEnabled
  } = propsOptions;
  const benchMarkEntries = getBenchMarkEntries(
    currentDrilldownViewEntry, currentVisualizationType, currentSnapshotView);
  const benchmarkNames = getBenchmarkNames(benchMarkEntries,
    currentBenchMarkMetricNames,
    currentDrilldownTemplateId,
    currentDrilldownDimensionField);
  const dimensionName = getDimensionName(currentDrilldownTemplateId, currentDrilldownDimensionField);
  let currentSecondaryMetricEntry = getSecondaryMetricEntry(
    currentDrilldownViewEntry, currentVisualizationType, currentSnapshotView, currentSecondaryMetricField);
  let secondaryDateRange = {}, secondaryMetricField = '';
  const comparisonFieldType = _.get(currentSecondaryMetricEntry, 'field');
  const isComparePrevious = (_.get(commonFilters, 'comparisonModeOn')) && _.isEmpty(comparisonFieldType);
  if (isComparePrevious) {
    const primaryMetricName = getPrimaryMetricName(currentDrilldownViewEntry);
    secondaryDateRange = {};
    secondaryMetricField = _.get(currentDrilldownViewEntry, 'field', null);
    currentSecondaryMetricEntry = {
      ...currentDrilldownViewEntry,
      name: getComparisonBarName(commonFilters, primaryMetricName),
      render_type: "bullet",
      field: commonFilters['comparisonType'],
      isComparisonEntry: true
    }
  } else {
    secondaryMetricField = _.get(currentSecondaryMetricEntry, 'field', null);
  }

  const currentBenchMarkEntries = getBenchMarkMetricEntries(
    currentDrilldownViewEntry, currentVisualizationType, currentSnapshotView, benchmarkNames);
  const currentDimensionSortOption = getDefaultSortOption(
    currentDrilldownDimensionField,
    currentDrilldownViewEntry,
    currentDimensionSortType,
    currentDrilldownTemplateId);
  const currentGroupByViewOption = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: currentGroupByViewType });

  let apiParams = {
    ...getQueryParams(stateOptions, propsOptions),
    secondaryMetricField: isComparePrevious ? null : secondaryMetricField,
    secondaryDateRange: JSON.stringify(secondaryDateRange),
    showScatterPlotRange 
  }

  const isRangeChartView = _.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type);
  const isPieChartView = _.isEqual(currentSnapshotView, SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type);

  if (isComparisonEnabled && !isRangeChartView) {
    apiParams = _.merge({}, apiParams, {
      compareYearRanges: JSON.stringify(commonFilters['comparisonDateRanges'])
    });
  }

  if (isComparisonEnabled && isPieChartView) {
    apiParams = _.merge({}, apiParams, {
      compareYearRanges: '[]',
      isComparisonEnabled:false
    });
  }


  const isNumberDimension = isNumericDimensionField({
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  });
  const isCurrencyDimensionField = isCurrencyType({
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  });
  const isCurrencyGroupByField = isGroupByCurrencyType(currentDrilldownGroupByEntry);

  return {
    chartType: getSnapshotChartType(currentDrilldownViewEntry),
    secondaryMetricEntry: currentSecondaryMetricEntry,
    benchMarkEntries: currentBenchMarkEntries,
    dimensionSortOption: currentDimensionSortOption,
    groupType: currentGroupByChartApproach,
    groupByViewType: currentGroupByViewType,
    groupByEntry: currentDrilldownGroupByEntry,
    currentGroupByViewOption,
    apiParams,
    isNumericDimensionField: isNumberDimension,
    isCurrencyDimensionField,
    isCurrencyGroupByField,
    currentSnapshotView,
    showScatterPlotRange,
    currentDrilldownViewEntry,
    templateId: currentDrilldownTemplateId,
    dimensionName,
    comparePrevious: isComparePrevious,
    drillDownTotal: propsOptions.drillDownTotal,
    showChartValues: showSnapshotChartValues,
    pieChartAnnotationOptions,
    isChartAndTotalLoading: propsOptions.metricTotalLoading,
    compareYearRanges,
    comparisonType,
    isComparisonEnabled,
    commonFilters
  }
}

export const getSnapshotSummaryOptions = (stateOptions, propsOptions) => {
  const {
    currentDrilldownViewEntry,
    currentSnapshotView,
    currentBarChartSortType,
    currentDrilldownDimensionField,
    currentDrilldownGroupByEntry,
    mapOptions,
    drilldown,
    commonFilters
  } = propsOptions;

  const snapshotOptions = getSnapshotOptions(stateOptions, propsOptions);
  const { secondaryMetricEntry } = snapshotOptions;

  const isScatterPlot = (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type);
  const isBarChart = (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
  const isPieChart = (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type);

  const isStackedChart = isStackTypeChart({ secondaryMetricEntry });
  const isGroupChart = (getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart');
  const isGroupByNone = (_.get(currentDrilldownGroupByEntry, 'name') === 'None');
  const primaryMetricName = getPrimaryMetricName(currentDrilldownViewEntry);
  const secondaryMericName = _.get(secondaryMetricEntry, 'name', '');

  return {
    isScatterPlot,
    isBarChart,
    isPieChart,
    isStackedChart,
    isGroupByNone,
    isGroupChart,
    primaryMetricName,
    secondaryMericName,
    currentDrilldownDimensionField,
    currentBarChartSortType,
    mapOptions,
    drilldown,
    commonFilters,
    ...snapshotOptions
  }
}

const getQueryParams = (stateOptions, propsOptions) => {
  const { currentPage, currentDataLimit } = stateOptions;
  const apiParams = getApiParams(_.pick(propsOptions, ['commonFilters', 'drilldown', 'mapOptions']), {});
  const {
    currentBarChartSortType,
    currentDimensionSortType,
    drilldown,
    currentSnapshotView,
    currentDrilldownGroupByEntry,
    currentGroupBySortType,
    currentDrilldownViewEntry,
    currentDrilldownTemplateId,
    currentDrilldownDimensionField,
    currentGroupByChartApproach
  } = propsOptions;
  const isPieChart = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type);
  const isScatterPlot = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type);
  const isGroupChart = (getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart');
  const pageDataLimit = (isGroupChart || isScatterPlot) ? currentDataLimit : itemsPerPage;
  const isNumberDimension = isNumericDimensionField({
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  });
  const isNumberCurrentDrilldownGroup = (_.get(currentDrilldownGroupByEntry, 'renderType') === 'number'
    && _.get(currentDrilldownGroupByEntry, 'renderAxis') === 'range');
  const defaultSortBy = isGroupChart ? currentDimensionSortType : currentBarChartSortType;
  const sortOption = getSortByOption(drilldown, defaultSortBy, 'sort');
  const sortByField = isNumberDimension ?
    currentDrilldownDimensionField :
    _.get(sortOption, 'sortBy');

  let queryParams = _.merge({}, apiParams, {
    page: isPieChart ? 0 : currentPage,
    limit: isPieChart ? 30 : pageDataLimit,
    sortBy: sortByField,
    sortOrder: isNumberDimension && !isPieChart ? 'asc' : _.get(sortOption, 'sortOrder'),
    drilldownEntry: getDrilldownEntryForSnapshot(apiParams.drilldownEntry),
    isGroupChart: isGroupChart && !isPieChart
  });

  if (isGroupChart && !isPieChart) {
    const groupSortOption = getSortByOption(drilldown, currentGroupBySortType, 'groupBySort');
    queryParams = {
      ...queryParams,
      override_limit: currentDataLimit,
      groupSortBy: _.get(groupSortOption, 'sortBy'),
      groupSortOrder: isNumberDimension ? 'asc' : _.get(groupSortOption, 'sortOrder'),
      groupType: currentGroupByChartApproach
    }
  }

  return {
    ...queryParams,
    ...((isNumberDimension && !isPieChart) ? { dimension_type: 'range' } : {}),
    ...(isNumberCurrentDrilldownGroup ? { group_by_type: 'range' } : {})
  };
}

export const isNumericDimensionField = (options) => {
  const { currentDrilldownTemplateId, currentDrilldownDimensionField } = options;
  const currentDrilldownDimension = getSelectedDimensionEntry(
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  );
  const dimensionRenderType = _.get(currentDrilldownDimension, 'renderType');
  return (dimensionRenderType === 'number' && _.get(currentDrilldownDimension, 'renderAxis') === 'range');
}

export const shouldHideSeeMoreLink = (stateOptions, propsOptions) => {
  const { chartDataRemainingEntries } = stateOptions;
  const {
    currentSnapshotView,
    currentDrilldownGroupByEntry,
    currentDrilldownViewEntry,
    currentGroupByViewType
  } = propsOptions;
  const isPieChart = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type);
  const chartType = getSnapshotChartType(currentDrilldownViewEntry);
  const isScatterPlot = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type);
  const isGroupByNone = (_.get(currentDrilldownGroupByEntry, 'name') === 'None');
  const isGroupChart = chartType == 'groupChart';
  const isTopTwenty = currentGroupByViewType !== 'all';

  if (isPieChart) {
    return true;
  } else if (isGroupChart && (chartDataRemainingEntries <= 0 || (isTopTwenty && isGroupByNone))) {
    return true;
  } else if (isScatterPlot && (chartDataRemainingEntries <= 0)) {
    return true;
  } else if (!isScatterPlot && !isGroupChart) {
    return true;
  } else {
    return false;
  }
}

export const isBarClickable = (event, options = {}) => {
  const { viewEntry, groupType } = options;
  if (getSnapshotChartType(viewEntry) === 'groupChart') {
    const isCombineView = groupType === DRILLDOWN_VIEW_TYPE[1].type
    if (isCombineView || _.get(event, 'data.clickmode') === false) {
      return false;
    }

    const yData = _.get(event, 'customdata') || _.get(event, 'y');
    return _.get(yData, 'isTitlePlaceholder') ? false : true;
  } else {
    if (_.get(event, 'data.clickmode') === false) {
      return false;
    }
    return true;
  }
}

export const getBarClickAttributes = (event, options) => {
  const { viewEntry, groupType } = options;
  const isBarClick = isBarClickable(event, { viewEntry, groupType });

  let yAxisValue = _.get(event, 'y');
  const isStackedChart = isStackTypeChart(options);
  if (isStackedChart) {
    yAxisValue = _.first(_.split(yAxisValue, STACK_CHART_ADD_TEXT));
  }

  const yData = _.get(event, 'customdata') || yAxisValue;
  const isSeeMoreGroupDimension = _.get(event, 'meta.isSeeMoreDimension', false);

  if (_.isArray(yData)) {
    return {
      groupByValue: yData[0],
      dimensionName: yData[1],
      isBarClickable: isBarClick,
      isSeeMoreGroupDimension
    };
  } else {
    return { dimensionName: yAxisValue, isBarClickable: isBarClick };
  }
}

export const getGroupByEntityField = (entityField) => {
  if (_.isUndefined(entityField) ||
    _.isNull(entityField) ||
    (entityField === 'undefined') ||
    (entityField === 'null')
  ) {
    return ''
  }
  return entityField;
}

export const getGroupChartDataLimit = (propsOptions) => {
  const isGroupChart = (getSnapshotChartType(propsOptions.currentDrilldownViewEntry) === 'groupChart');
  const isGroupByNone = _.get(propsOptions.currentDrilldownGroupByEntry, 'name') === 'None';
  const currentGroupByViewOption = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS,
    { name: propsOptions.currentGroupByViewType });
  const dataFetchLimit = isGroupByNone ?
    _.get(currentGroupByViewOption, 'dataLimit', SCATTER_PLOT_DATA_LIMIT) :
    COMPARE_VIEW_DATA_LIMIT;

  return (isGroupChart ? dataFetchLimit : SCATTER_PLOT_DATA_LIMIT);
}

export const getComparisonBarName = (commonFilters, primaryMetricName) => {
  const comparisonType = commonFilters['comparisonType'];
  const secondaryDateRange = getComparisonPeriodDateRanges(commonFilters)[0];
  const compareEntry = _.find(DATE_COMPARISON_TYPE_OPTIONS, (entry) => {
    return (entry['value'] == comparisonType);
  });
  const { endDate } = secondaryDateRange;
  const year = comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD ?
    moment(endDate).format('YYYY') : null;

  let name = primaryMetricName + ` - ${_.get(compareEntry, 'label')}`;
  if (year) {
    name += `, ${year}`;
  }
  return name;
}