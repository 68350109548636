import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ForgeLabelValue, ForgeCircularProgress, ForgeButton, ForgeTooltip } from '@tylertech/forge-react';
import { TOOLTIP_CONSTANTS } from '@tylertech/forge';

import { getCollectionWatchUsers } from 'common/api/collectionApi';
import { getBookmarkWatchUsers } from 'common/api/bookmarksApi';
import ForgePopupOption from 'common/components/ForgePopupOption/ForgePopupOption';
import { isManageCollections } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
import { isUserCreatedCollection } from 'pages/dashboard/components/Collections/collectionHelper';
import { getUserRole } from 'common/config/customerConfiguration';

const WatchCount = (props) => {
  const { collection, type, templateId, viewEntry, updateWatchCount} = props;
  const typeaheadRef = useRef();
  const [showFilterContent, setShowFilterContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (type == 'collection') {
      fetchCollectionCount();
    } else {
      fetchMetricCount();
    }
  }, [collection, viewEntry, updateWatchCount]);

  const fetchCollectionCount = () => {
    getCollectionWatchUsers(_.get(collection, 'id'))
      .then((response) => response.json())
      .then((response) => {
        setUsers(response);
        setIsLoading(false);
    });
  }

  const fetchMetricCount = () => {
    const params = {
      template_id: templateId,
      view_id: _.get(viewEntry, 'view_id')
    }

    getBookmarkWatchUsers(params)
      .then((response) => response.json())
      .then((response) => {
        setUsers(response);
        setIsLoading(false);
    });
  }

  const handleClickOutsideAndApply = () => {
    setShowFilterContent(false);
  };

  const renderUserEmails = () => {
    return (
      <div className="watch-email-list">
        {
          _.map(users, (user) => {
            return (
              <ForgeLabelValue>
                <span slot="value">{user}</span>
                <span slot="label">{getUserRole(user)}</span>
              </ForgeLabelValue>
            );
          })
        }
      </div>
    );
  };

  const renderAddForgePopupContent = () => {
    return (
      <div tabIndex={0}>
        <ForgePopupOption
          showPopup={showFilterContent}
          targetPopupRef={typeaheadRef}
          placement="bottom-end"
          closeFilterChip={handleClickOutsideAndApply}
          isIncludeOutsideClose={true}>
          <div className='forge-typography--subtitle2-secondary mb-3'>Already watching</div>
          {renderUserEmails()}
        </ForgePopupOption>
      </div>
    )
  }

  const countButton = () => {
    const delay = TOOLTIP_CONSTANTS.numbers.DEFAULT_DELAY;
    return (
      <ForgeButton type="outlined">
        <button
          variant="outline-primary"
          onClick={() => setShowFilterContent(!showFilterContent)}
          className="watch-count-btn">
            {isLoading ?
              renderLoading() : renderCount()
            }
        </button>
        <ForgeTooltip
          text="Select to see who is subscribed to receive an email"
          delay={delay} position="top" />
      </ForgeButton>
    )
  }

  const renderLoading = () => {
    return (<ForgeCircularProgress></ForgeCircularProgress>);
  }

  const renderCount = () => {
    return (<div className='count-badge'><div className='count-no'>{users.length}</div></div>)
  }

  const renderCountBadge = () => {
    return(
      <div ref={typeaheadRef} className='watch-count-popup forge-popup-host'>
        {countButton()}
        {renderAddForgePopupContent()}
      </div>
    )
  }

  const canShowWatchCount = () => {
    if (type == 'collection') {
      return isManageCollections() && !isUserCreatedCollection(collection);
    } else {
      return isManageCollections();
    }
  }

  return (
    canShowWatchCount() && renderCountBadge()
  );
}

WatchCount.propTypes = {
  collection: PropTypes.object,
  type: PropTypes.string,
  templateId: PropTypes.string,
  viewEntry: PropTypes.object,
}

export default WatchCount;
