import _ from 'lodash';
import moment from 'moment';
const {
  linearRegression,
  linearRegressionLine } = require("simple-statistics"); // eslint-disable-line no-undef
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
import { OVERTIME_VISUALIZATION_TYPES } from 'appConstants';
import { getFiscalYearByStartMonth } from 'helpers/fiscalPeriodUtils';
import { getTailingDropStartDate, getPeriodType } from '../vizOptionsHelper'
import { getDateRangeForYearly } from 'helpers/dateHelper';
import { isDiscreteModeData } from 'common/config/viewConfiguration';

export const getLastCurrentPeriodValue = (currentPeriods, allDataItems, vizOptions, dimensionKey) => {
  const { dateRangeMode } = vizOptions;

  const isBurnup = vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const periodType = getPeriodType(vizOptions);
  const dataItems = _.uniqBy(allDataItems, (item) => {
    return item.period;
  });
  const dimension = _.get(dataItems, '[0].dimension', dimensionKey);
  const lastPeriod = _.last(currentPeriods);
  let lastDataItem;
  if (isBurnup && isDiscreteMode) {
    let actualDataItems;
    const tailingDropDate = getTailingDropStartDate(vizOptions);
    let projectionYear = getFiscalYearByStartMonth(tailingDropDate.format('YYYY'));
    let yearRange = getDateRangeForYearly(projectionYear, dateRangeMode);
    if (!tailingDropDate.isBetween(yearRange.startDate, yearRange.endDate, undefined, '[]')) {
      projectionYear = Number(projectionYear) + 1;
      yearRange = getDateRangeForYearly(projectionYear, dateRangeMode);
    }
    // const { startDate } = yearRange;
    // const endDate = lastPeriod.startOf(periodType).format(DATE_FORMAT);
    // if(isYearOnYear({ renderTimeFrame: vizOptions.renderTimeFrame })) {
    //   actualDataItems = _.filter(dataItems, (item) => {
    //     return item.periodMmt.isBetween(startDate, endDate, undefined, '[]');
    //   });
    // }else {
    //   actualDataItems = dataItems;
    // }
    const startDate = _.first(currentPeriods).startOf(periodType).format(DATE_FORMAT);
    const endDate = lastPeriod.startOf(periodType).format(DATE_FORMAT);

    actualDataItems = _.filter(_.cloneDeep(dataItems), (item) => {
      const adjustValue = Number(item['adjustValue'] || '')
      item['value'] = item['adjustValue'] || item['value'];
      return item.periodMmt.isSame(endDate, periodType) && Math.abs(adjustValue) > 0;
    });

    if(_.isEmpty(actualDataItems)){
      actualDataItems = _.filter(dataItems, (item) => {
        return item.periodMmt.isBetween(startDate, endDate, undefined, '[]');
      });
    }

    lastDataItem = {
      value: _.sumBy(actualDataItems, function (dataItem) { return Number(dataItem['value'] || 0); }),
      secondary_total: _.sumBy(actualDataItems, function (dataItem) {
        return Number(dataItem['secondary_total'] || 0);
      })
    }
  } else {
    lastDataItem = _.find(dataItems, (dataItem) => {
      if (periodType == 'week') {
        return (lastPeriod.week() == dataItem.periodMmt.week());
      } else {
        return dataItem.periodMmt.isSame(lastPeriod, periodType);
      }
    });
  }


  if (_.isEmpty(lastDataItem)) {
    lastDataItem = {value : 0};
  }

  return {
    dimension: dimension,
    ignoreProjection: true,
    ...lastDataItem,
    period: !_.isEmpty(lastPeriod) ? lastPeriod.format(DATE_FORMAT) : lastPeriod,
    periodMmt: lastPeriod,
  };
}

export const getLinearProjection = (
  projectionPeriods, dataItems, currentPeriods, periodType, dimensionKey
)  => {
  const dimension = _.get(dataItems, '[0].dimension', dimensionKey);
  let projectionItems = [];
  let metricRegressions = [];
  let secRegressions = [];
  _.each(currentPeriods, (period, index) => {
    let availableDataItem = _.find(dataItems, (dataItem) => {
      return dataItem.periodMmt.isSame(period, periodType);
    }) || {};
    metricRegressions.push([index, Number(availableDataItem['value']) || 0]);
    secRegressions.push([index, Number(availableDataItem['secondary_total']) || 0]);
  });
  const metricLinear = linearRegressionLine(
    linearRegression(metricRegressions)
  );
  const secondaryLinear = linearRegressionLine(
    linearRegression(secRegressions)
  );
  // here we adding projection periods with currentPeriods(periods with data)
  // because currentPeriods are periods which have data
  let allPeriods = _.concat(currentPeriods, projectionPeriods);

  let periodIndex = 0;
  for (let i = currentPeriods.length; i < allPeriods.length; i++) {
    const value = metricLinear(i);
    const secondary_total = secondaryLinear(i);
    projectionItems.push({
      dimension: dimension,
      period: projectionPeriods[periodIndex].format(DATE_FORMAT),
      periodMmt: projectionPeriods[periodIndex],
      value: value,
      secondary_total: secondary_total
    });
    periodIndex++;
  }
  return projectionItems;
}

export const getHistoricProjections = (
  projectionPeriods, dataItems, extraProjectionDataItems, vizOptions,
  periodType, lastCurrentPeriodValue, dimensionKey
) => {
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  const isBurnup = vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const isRangeModeBurnUp = (isBurnup && !isDiscreteMode);
  const dimension = _.get(dataItems, '[0].dimension', dimensionKey);
  const projectionPercentage = Number(_.get(vizOptions, 'projectionPercent', 3));
  const allPeriodsItems = extraProjectionDataItems;
  const valueKey = isForecastingView ? getValueKey(lastCurrentPeriodValue) : 'value';

  const lastCurrentPeriodTotal = Number(_.get(lastCurrentPeriodValue, valueKey, 0));
  const currentPeriodLastSecondaryTotal = Number(_.get(lastCurrentPeriodValue, 'secondary_total', 0));
  const isComboChart = _.get(vizOptions, 'isComboChart', false);
  return _.map(projectionPeriods, (period, index) => {
    const previousPeriod = _.cloneDeep(period).subtract(1, 'year');
    let availableDataItem = _.find(allPeriodsItems, (dataItem) => {
      return dataItem.periodMmt.isSame(previousPeriod, periodType);
    });

    availableDataItem = isForecastingView ? getAdjustedValue(
      {availableDataItem, vizOptions, periodType, period: previousPeriod }) : availableDataItem;
    const valueKey = isForecastingView ? getValueKey(availableDataItem) : 'value';

    let value = Number(_.get(availableDataItem, valueKey, 0)) || 0;
    if (!isForecastingView) {
      value = (value + ((value * projectionPercentage) / 100));
    }

    // if Burn_up in range mode -> add prevLast value to all the projection values.
    value = isRangeModeBurnUp ? (lastCurrentPeriodTotal + value) : value;
    let secondary_total = Number(_.get(availableDataItem, 'secondary_total', 0)) || 0;
    if (!isForecastingView) {
      secondary_total = (secondary_total + ((secondary_total * projectionPercentage) / 100));
    }

    // if Burn_up in range mode -> add prevLastSecondaryTotal to all the projection values.
    secondary_total = isRangeModeBurnUp ?
      (currentPeriodLastSecondaryTotal + secondary_total) : secondary_total;

    const currentPeriodItem = {
      dimension: dimension,
      period: period.format(DATE_FORMAT),
      periodMmt: period,
      value: value,
      secondary_total: secondary_total,
      // To ignore the projection for current or completed periods.
      ignoreProjection: isComboChart && index == 0
    };
    // In some cases current period data will next period previous data
    // Ex: currentPeriod  = 2020 and its previousPeriod: 2019
    //  nextPeriod = 2021 and its previousPeriod: 2020
    allPeriodsItems.push(currentPeriodItem);
    return currentPeriodItem;
  });
}

export const getProphetProjections = (
  projectionPeriods, projectionDataItems, dataItems, periodType,
  vizOptions, lastCurrentPeriodValue, dimensionKey
) => {
  const dimension = _.get(dataItems, '[0].dimension', dimensionKey);
  const isBurnup = vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const isRangeModeBurnUp = (isBurnup && !isDiscreteMode);
  const lastCurrentPeriodTotal = Number(_.get(lastCurrentPeriodValue, 'value', 0));
  const currentPeriodLastSecondaryTotal = Number(_.get(lastCurrentPeriodValue, 'secondary_total', 0));
  const isComboChart = _.get(vizOptions, 'isComboChart', false);

  return _.map(projectionPeriods, (period, index) => {
    let availableDataItem = _.find(projectionDataItems, (dataItem) => {
      return dataItem.periodMmt.isSame(period, periodType);
    });
    let value = Number(_.get(availableDataItem, 'value', 0)) || 0;
    // if Burn_up in range mode -> add prevLast value to all the projection values.
    value = isRangeModeBurnUp ? (lastCurrentPeriodTotal + value) : value;
    let secondary_total = Number(_.get(availableDataItem, 'secondary_total', 0)) || 0;
    // if Burn_up in range mode -> add prevLastSecondaryTotal to all the projection values.
    secondary_total = isRangeModeBurnUp ?
      (currentPeriodLastSecondaryTotal + secondary_total) : secondary_total;
    return {
      dimension: dimension,
      period: period.format(DATE_FORMAT),
      periodMmt: period,
      value: value,
      secondary_total: secondary_total,
      // To ignore the projection for current or completed periods.
      ignoreProjection: isComboChart && index == 0
    };
  });
}

export const getDummyProjections = (projectionPeriods) => {
  return _.map(projectionPeriods, (period) => {
    return {
      dimension: null,
      period: period.format(DATE_FORMAT),
      periodMmt: period,
      value: null,
      secondary_total: null
    };
  });
}

const getAdjustedValue = (options) => {
  const { availableDataItem, vizOptions, periodType, period } = options;
  const { projectionAdjustedValues } = vizOptions;

  if (_.isEmpty(availableDataItem) || _.isEmpty(projectionAdjustedValues)) {
    return availableDataItem;
  }

  const prepareFormatData = _.cloneDeep(projectionAdjustedValues);
  const availablePrepareDataItem = _.find(prepareFormatData, (dataItem) => {
    const periodMmt = moment(dataItem.period).startOf('day');
    return (periodMmt.isSame(period, periodType) && dataItem['uniqueId'] === 'total');
  });

  if (_.isEmpty(availablePrepareDataItem)) {
    return availableDataItem;
  }
  return availablePrepareDataItem
}

export const getValueKey = (availableDataItem) => {
  const adjustValue = Number(_.get(availableDataItem, 'adjustValue', 0)) || 0;
  const valueKey = (adjustValue > 0 || adjustValue < 0) ? 'adjustValue' : 'value';
  return valueKey;
}