import _ from 'lodash';
import React, { PureComponent } from 'react';

import GenericVisualization from '../GenericVisualization';
import dataStore from './dataStore';
import dataFormatter from './DataFormatter';
import plotlyParamsGenerator from './plotlyParamsGenerator';
import PlotlyTooltip from 'modules/PlotlyTooltip';
import { getPopupConfigs } from './popupConfigsHelper'
import { PLOTLY_HOVER_DEBOUNCE_WAIT_TIME, VIEW_MODE } from 'modules/visualization/constants';
import { isScatterChart } from './plotlyScatterChartHelper';
import { isBarchart } from './barChartHelper';
import { SNAPSHOT_VISUALIZATION_TYPES } from 'appConstants';
import { setIframeHeight } from 'modules/visualization/SnapshotVisualization/snapshotAfterPlotHelper';
import { isMobileOrTablet } from 'helpers/DomPageHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import SummaryTableDataContext from 'context/SummaryTableDataContext';

const GenericBarChart = GenericVisualization(dataStore, dataFormatter, plotlyParamsGenerator);

const snapShotPropTypes = {
  __stubApiData: PropTypes.object,
  afterRender: PropTypes.func,
  apiParams: PropTypes.object,
  benchMarkEntries: PropTypes.array,
  dimensionName: PropTypes.string,
  groupByEntry: PropTypes.object,
  groupType: PropTypes.string,
  groupByViewType: PropTypes.string,
  isBarClickable: PropTypes.func,
  isEmbed: PropTypes.bool,
  onApiDataLoad: PropTypes.func,
  onFormattedDataLoad: PropTypes.func,
  onBarClick: PropTypes.func,
  secondaryMetricEntry: commonPropTypes.secondaryMetricEntryPropTypes,
  templateId: commonPropTypes.templateIdPropTypes,
  viewEntry: commonPropTypes.viewEntryPropTypes.isRequired,
  dimensionSortOption: PropTypes.object,
  drillDownTotal: commonPropTypes.stringOrNumberProps,
  currentGroupByChartApproach: PropTypes.string,
  isNumericDimensionField: PropTypes.bool,
  showRange: PropTypes.bool,
  currentSnapshotView: PropTypes.string,
  onPieSliceClick: PropTypes.func,
  viewMode: commonPropTypes.viewModePropTypes,
  onAfterPlot: PropTypes.func,
  isChartAndTotalLoading: PropTypes.bool,
  onDataLoading: PropTypes.func,
  showChartValues: PropTypes.bool,
  onNewApiData: PropTypes.func,
  isCurrencyDimensionField: PropTypes.bool,
  isCurrencyGroupByField: PropTypes.bool,
  cardImageId: PropTypes.string,
  compareYearRanges: PropTypes.array,
  comparisonType: PropTypes.string,
  isComparisonEnabled: PropTypes.bool,
  commonFilters:PropTypes.object
};
const defaultProps = {
  __stubApiData: null,
  afterRender: _.noop,
  apiParams: {},
  groupByEntry: {},
  groupType: '',
  groupByViewType: '',
  onApiDataLoad: _.noop,
  onFormattedDataLoad: _.noop,
  onBarClick: _.noop,
  isBarClickable: _.noop,
  onAfterPlot: _.noop,
  onNewApiData: _.noop,
  secondaryMetricEntry: {},
  templateId: '',
  viewMode: VIEW_MODE.LARGE,
  currentSnapshotView: '',
  isChartAndTotalLoading: false,
  isCurrencyDimensionField: false,
  isCurrencyGroupByField: false,
  onDataLoading: _.noop,
  currentGroupByChartApproach: 'compare_view'
};

class SnapshotVisualization extends PureComponent {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }
  static contextType = SummaryTableDataContext;

  componentDidMount() {
    if (this.popupContainer) {
      this.plotlyTooltip = new PlotlyTooltip(this.popupContainer);
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (this.chartContainer && !this.chartContainer.contains(e.target)) {
      this.plotlyTooltip.hidePopups()
    }
  }

  onContainerMouseOut = () => {
    this.setMouseCursor('inherit');
    this.plotlyTooltip.hidePopups();
  }

  onNewPlotlyParams = (formattedData) => {
    const { isEmbed, viewMode } = this.props;
    // Only we set the formattedData in Embed mode.
    // We need formattedData for display the flyout details in summary page.
    if(isEmbed && !_.isUndefined(this.context) && viewMode === VIEW_MODE.SMALL) {
      if( !_.isUndefined(this.context.onUpdateSummaryData)){
        this.context.onUpdateSummaryData(formattedData, this.getVizOptions());
      }
    }
  }

  onPlotlyClick = (event) => {
    const { onBarClick, currentSnapshotView, isEmbed } = this.props;
    if(isEmbed) {
      return;
    }

    if(isMobileOrTablet()) {
      this.onPlotlyHover(event);
    }

    if(!isMobileOrTablet()) {
      this.plotlyTooltip.hidePopups();
    }

    if (isScatterChart(currentSnapshotView)) {
      this.handleClickScatterPoint(event);
    } else if (isBarchart(currentSnapshotView)) {
      if (_.isFunction(onBarClick) && !this.isGroupBarClick(event.points[0])) {
        onBarClick(event.points[0]);
      }
    } else {
      this.handleClickPieSlice(event);
    }
  }

  isGroupBarClick = (point) => {
    if (_.get(point, 'meta.isGroup', false) && !_.get(point, 'meta.isSeeMoreDimension', false)) {
      return true;
    }
  }

  handleClickScatterPoint = (event) => {
    const { onBarClick } = this.props;

    if (!this.isScatterClickable(event)) {
      return;
    }
    const formattedDataItem = _.get(event, 'points[0].meta');
    const dimensionValue = _.get(formattedDataItem.scatterEntry,'dimension','');
    let groupByValue =  _.get(formattedDataItem.scatterEntry,'group_by_field','');
    const isSeeMoreGroupDimension = _.get(formattedDataItem,'isSeeMoreDimension',false);
    groupByValue = isSeeMoreGroupDimension ? _.get(formattedDataItem,'groupByField','') : groupByValue;

    if (_.isEmpty(groupByValue)) {
      onBarClick(dimensionValue);
    } else if(isSeeMoreGroupDimension) {
      onBarClick(undefined, groupByValue, true);
    } else {
      onBarClick(dimensionValue, groupByValue);
    }
  }

  handleClickPieSlice = (event) => {
    const { onPieSliceClick } = this.props;

    if (this.isPieSliceClickable(event)) {
      onPieSliceClick(event.points[0]);
    }
  }

  isPieSliceClickable = () => {
    const { onPieSliceClick } = this.props;
    return _.isFunction(onPieSliceClick);
  }

  isScatterClickable = (event) => {
    if(_.get(event, 'points[0].data.clickmode') === false) {
      return false;
    }
    return true;
  }

  handleAfterPlot = () => {
    setIframeHeight();
    if(!_.isUndefined(this.chartContainer)) {
      this.props.onAfterPlot(this.chartContainer, this.props.cardImageId);
    }
  }

  handleInitPieChart = (figure, graphDiv) => {
    setIframeHeight();
    const { vizOptions } = graphDiv.closest('.visualization-charts');
    const { currentSnapshotView } = vizOptions;
    if(currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type) {
      this.props.onAfterPlot(graphDiv);
    }
  }

  getVizOptions = () => {
    return _.pick(this.props,
      'afterRender',
      'apiParams',
      'benchMarkEntries',
      'dimensionName',
      'groupByEntry',
      'groupType',
      'groupByViewType',
      'onApiDataLoad',
      'onFormattedDataLoad',
      'secondaryMetricEntry',
      'secondaryMetricOption',
      'isChartAndTotalLoading',
      'isEmbed',
      'onDataLoading',
      'viewEntry',
      'viewMode',
      'currentSnapshotView',
      'drillDownTotal',
      'dimensionSortOption',
      'showRange',
      'isNumericDimensionField',
      'isCurrencyDimensionField',
      'isCurrencyGroupByField',
      'templateId',
      'currentGroupByChartApproach',
      'showChartValues',
      'pieChartAnnotationOptions',
      'ignoreCompareDateRange',
      'cardImageId',
      'compareYearRanges',
      'comparisonType',
      'isComparisonEnabled',
      'commonFilters'
    );
  }

  onPlotlyHover = _.throttle((event) => {
    const {
      isBarClickable,
      viewMode,
      viewEntry,
      secondaryMetricEntry,
      currentSnapshotView,
      isCurrencyDimensionField,
      isCurrencyGroupByField,
      templateId,
      groupByEntry,
      isEmbed,
      groupType,
      isComparisonEnabled
    } = this.props;

    if(!isEmbed) {
      this.setMouseCursor(isBarClickable(event, { viewMode, groupType }) ? 'pointer' : 'inherit');
    }

    const isSeeMoreDimension = _.get(event, 'points[0].meta.isSeeMoreDimension', false);
    if(isSeeMoreDimension) {
      return true
    }

    const popupConfigs = getPopupConfigs({
      chartContainer: this.chartContainer,
      data: event,
      viewMode,
      viewEntry,
      templateId,
      groupByEntry,
      isCurrencyDimensionField,
      isCurrencyGroupByField,
      secondaryMetricEntry,
      currentSnapshotView,
      isComparisonEnabled
    });
    this.plotlyTooltip.showPopups(popupConfigs);
  }, PLOTLY_HOVER_DEBOUNCE_WAIT_TIME);

  onPlotlyUnhover = () => {
    this.setMouseCursor('inherit', true);
    this.plotlyTooltip.hidePopups();
  };

  setMouseCursor(cursor, isUnsetCursor = false) {
    if(this.chartContainer.querySelector('g.draglayer')) {
      this.chartContainer.querySelector('g.draglayer').style['cursor'] = cursor;
    }

    // Scatter chart row hover
    if(!_.isNull(this.chartContainer.querySelector('g.gridlayer g.y path.ygrid'))) {
      _.forEach(this.chartContainer.querySelectorAll('g.gridlayer g.y path.ygrid'), (selectArea) => {
        if(!_.isNull(selectArea)){
          selectArea.style.cursor = isUnsetCursor ? cursor : 'pointer';
        }
      });
    }

    // Scatter chart point hover
    if(document.getElementsByClassName('nsewdrag')[0]){
      document.getElementsByClassName('nsewdrag')[0].style.cursor = isUnsetCursor ? cursor : 'pointer';
    }
  }

  render() {
    const { __stubApiData, onNewApiData } = this.props;
    const vizOptions = this.getVizOptions();
    const extraPlotlyParams = {
      onClick: this.onPlotlyClick,
      onHover: this.onPlotlyHover,
      onUnhover: this.onPlotlyUnhover,
      onAfterPlot: this.handleAfterPlot,
      onInitialized: this.handleInitPieChart
    };
    const debounceOptions = { leading: true };

    // Render pagination.
    // Handle mouse click and drilldown.
    // Handle tooltip and stuff.
    // Create the vizOptions from props/state.
    return (
      <div
        className="bar-chart"
        style={{position: 'relative'}}
        ref={(ref) => this.chartContainer = ref}
        onMouseOut={this.onContainerMouseOut}
      >
        <GenericBarChart
          vizOptions={vizOptions}
          onNewApiData={onNewApiData}
          extraPlotlyParams={extraPlotlyParams}
          onNewPlotlyParams={this.onNewPlotlyParams}
          __stubApiData={__stubApiData}
          debounceOptions={debounceOptions}
          plotlyTooltip={this.plotlyTooltip}
        />
        <div className="popup-container" ref={(ref) => this.popupContainer = ref}></div>
      </div>
    );
  }
}

SnapshotVisualization.propTypes = snapShotPropTypes;
SnapshotVisualization.defaultProps = defaultProps;

export default SnapshotVisualization;
