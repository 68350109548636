export const SET_CURRENT_VISUALIZATION_TYPE = 'SET_CURRENT_VISUALIZATION_TYPE';
export const UPDATE_DRILLDOWN_TEMPLATE_NAME = 'UPDATE_DRILLDOWN_TEMPLATE_NAME';
export const UPDATE_DRILLDOWN_DIMENSION_FIELD = 'UPDATE_DRILLDOWN_DIMENSION_FIELD';
export const SET_DRILLED_DOWN_DIMENSIONS = 'SET_DRILLED_DOWN_DIMENSIONS';
export const UPDATE_DRILLDOWN_VIEW_ENTRY = 'UPDATE_DRILLDOWN_VIEW_ENTRY';
export const UPDATE_DRILLDOWN_GROUP_BY_ENTRY = 'UPDATE_DRILLDOWN_GROUP_BY_ENTRY';
export const TOGGLE_INSPECT_VIEW_MODAL = 'TOGGLE_INSPECT_VIEW_MODAL';
export const UPDATE_QUICK_FILTERS = 'UPDATE_QUICK_FILTERS';
export const UPDATE_TEMPLATE_CHANGE = 'UPDATE_TEMPLATE_CHANGE';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';
export const UPDATE_PERMISSION_FILTERS = 'UPDATE_PERMISSION_FILTERS';
export const UPDATE_NEW_LABEL_CARD_ID = 'UPDATE_NEW_LABEL_CARD_ID';
export const UPDATE_TIME_OF_DAY_TOGGLE_BUTTON = 'UPDATE_TIME_OF_DAY_TOGGLE_BUTTON';
export const UPDATE_TIME_OF_DAY_FILTERS = 'UPDATE_TIME_OF_DAY_FILTERS';

export const setCurrentVisualizationType = (visualizationType) => ({
  type: SET_CURRENT_VISUALIZATION_TYPE,
  visualizationType
});

export const updateDrilldownTemplateNameAndId = (name, templateId) => ({
  type: UPDATE_DRILLDOWN_TEMPLATE_NAME,
  name,
  templateId
});

export const updateDrilldownDimensionField = (field) => ({
  type: UPDATE_DRILLDOWN_DIMENSION_FIELD,
  field
});

export const setDrilldownDimensions = (drilldownEntries) => ({
  type: SET_DRILLED_DOWN_DIMENSIONS,
  drilldownEntries
});

export const updateDrilldownGroupByEntry = (entry) => ({
  type: UPDATE_DRILLDOWN_GROUP_BY_ENTRY,
  entry
});

export const updateDrilldownViewEntry = (entry, isUpdateDrilldownDimensionField = true) => ({
  type: UPDATE_DRILLDOWN_VIEW_ENTRY,
  entry,
  isUpdateDrilldownDimensionField
});

export const toggleInspectViewModal = (toggle) => ({
  type: TOGGLE_INSPECT_VIEW_MODAL,
  toggle
});

export const updateQuickFilters = (quickFilters) => ({
  type: UPDATE_QUICK_FILTERS,
  quickFilters
});

export const updatePermissionFilters = (permissionFilters) => ({
  type: UPDATE_PERMISSION_FILTERS,
  permissionFilters
});

export const updateTemplateEntryChange = (templateEntry, viewEntry) => ({
  type: UPDATE_TEMPLATE_CHANGE,
  templateEntry,
  viewEntry
});

export const resetAllFilters = () => ({
  type: RESET_ALL_FILTERS
});

export const updateNewLabelCardId = (newLabelCardId) => ({
  type: UPDATE_NEW_LABEL_CARD_ID,
  newLabelCardId
});

export const updateTimeOfDayToggleButton = (isTimeOfDayOn) => ({
  type: UPDATE_TIME_OF_DAY_TOGGLE_BUTTON,
  isTimeOfDayOn
});

export const updateTimeOfDayFilters = (timeOfDayFilters) => ({
  type: UPDATE_TIME_OF_DAY_FILTERS,
  timeOfDayFilters
});
