import * as actionTypes from '../actionTypes/mapActionTypes';

export const setDefaultDrilldownMapOptions = (currentTemplateId, viewEntry, canUseDefaultView = false) => ({
  type: actionTypes.SET_DEFAULT_DRILL_DOWN_MAP_OPTIONS,
  currentTemplateId,
  viewEntry,
  canUseDefaultView
});

export const updateSelectedShapeOutlines = (selectedOutlines) => ({
  type: actionTypes.UPDATE_SELECTED_SHAPE_OUTLINES,
  selectedOutlines
});

export const updateMapStyleEntry = (mapStyleEntry) => ({
  type: actionTypes.UPDATE_MAP_STYLE_ENTRY,
  mapStyleEntry
});

export const toggleShapeIdsFilter = (shapeIds, shapesExtent) => ({
  type: actionTypes.TOGGLE_SHAPE_IDS_FILTER,
  shapeIds,
  shapesExtent
});

 export const updateShapeData = (shapeData) => ({
  type: actionTypes.UPDATE_SHAPE_DATA,
  shapeData
});

export const updateShapeDatasetEntry = (selectedShapeEntry) => ({
  type: actionTypes.UPDATE_SHAPE_DATASET_ENTRY,
  selectedShapeEntry
});

export const updateDrawingStateChange = (toggle) => ({
  type: actionTypes.UPDATE_DRAWING_STATE_CHANGE,
  toggle
});

export const updatePolygonFeature = ({ featuresGeojson }) => ({
  type: actionTypes.UPDATE_POLYGON_FEATURE,
  featuresGeojson
});

export const removePolygonFilter = () => ({
  type: actionTypes.REMOVE_POLYGON_FILTER
});

export const updateRadarDrawingStateChange = (toggle) => ({
  type: actionTypes.UPDATE_RADAR_DRAWING_STATE_CHANGE,
  toggle
});

export const updateRadarDrawingLineStateChange = (toggle) => ({
  type: actionTypes.UPDATE_RADAR_DRAWING_LINE_STATE_CHANGE,
  toggle
});

export const updateCenterAndZoom = (centerAndZoom) => ({
  type: actionTypes.UPDATE_CENTER_AND_ZOOM,
  centerAndZoom
});

export const updateMapView = (mapView) => ({
  type: actionTypes.UPDATE_MAP_VIEW,
  mapView
});

export const updateGeoCoder = (geoCoder) => ({
  type: actionTypes.UPDATE_GEO_CODER,
  geoCoder
});


export const updateLegends = (legends, mapType) => ({
  type: actionTypes.UPDATE_LEGENDS,
  legends,
  mapType
});

export const updateVisualizationDefaults = (options) => ({
  type: actionTypes.UPDATE_VISUALIZATION_DEFAULTS,
  options
});
