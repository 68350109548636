import * as actionTypes from '../actionTypes/snapshotActionTypes';
import { UPDATE_VISUALIZATION_REDUCER } from 'actions/visualizationActions';
import { getSnapshotState } from './helper';
import { DRILLDOWN_VIEW_TYPE } from 'appConstants';

export const getInitialState = () => {
  return {
    currentGroupByChartApproach: _.first(DRILLDOWN_VIEW_TYPE).type,
    pieChartAnnotationOptions: { label: true, percent: true, value: true }
  };
};

export default function(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.UPDATE_GROUPCHART_APPROACH:
      return {
        ...state,
        currentGroupByChartApproach: _.get(action, 'viewType')
      }

    case actionTypes.UPDATE_GROUPCHART_VIEW_TYPE:
      return {
        ...state,
        currentGroupByViewType: _.get(action, 'viewType')
      }

    case actionTypes.UPDATE_CURRENT_SNAPSHOT_VIEW:
      return {
        ...state,
        currentSnapshotView: _.get(action, 'selectedView')
      }

    case actionTypes.UPDATE_SNAPSHOT_SECONDARY_METRIC_FIELD:
      return {
        ...state,
        currentSecondaryMetricField: _.get(action, 'field')
      }

    case actionTypes.UPDATE_SNAPSHOT_BENCH_MARK_NAME:
      return {
        ...state,
        currentBenchMarkMetricNames: _.get(action, 'name')
      }

    case actionTypes.UPDATE_BAR_CHART_SORT_TYPE:
      return {
        ...state,
        currentBarChartSortType: _.get(action, 'sortType')
      }

    case actionTypes.UPDATE_GROUP_CHART_SORT_TYPE:
      return {
        ...state,
        currentGroupBySortType: _.get(action, 'sortType')
      }

    case actionTypes.UPDATE_SNAPSHOT_DIMENSION_SORT_TYPE:
      return {
        ...state,
        currentDimensionSortType: _.get(action, 'sortType')
      }

    case actionTypes.UPDATE_VISUALIZATION_DEFAULTS:
      return {
        ...state,
        ...getSnapshotState(_.get(action, 'options'))
      }

    case UPDATE_VISUALIZATION_REDUCER:
      return {
        ...state,
        ..._.get(action, 'visualization.snapshot')
      }

    case actionTypes.TOGGLE_ADD_SNAPSHOT_CHART_VALUES:
      return {
        ...state,
        showSnapshotChartValues: _.get(action, 'toggle')
      }

    case actionTypes.TOGGLE_PIE_CHART_ANNOTATION_OPTIONS:
      return {
        ...state,
        pieChartAnnotationOptions: _.get(action, 'options')
      }

    default:
      return state;
  }
}
