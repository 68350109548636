import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import ReduxDispatchAsLink from 'common/components/ReduxDispatchAsLink';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import {
  getCurrentTemplateEntry,
  getViewEntryByField } from 'common/config/templateConfiguration';
import {
  GROUP_BY_NONE_ENTRY,
  VISUALIZATION_TYPES,
  SNAPSHOT_VISUALIZATION_TYPES,
  DATE_COMPARISON_TYPE_OPTIONS,
  INSIGHT_COMPARISON_MODE_OPTIONS
} from 'appConstants';
import {
  getFilterObject,
  getNewFilters
} from 'helpers/visualizationHelper';
import { updateCurrentSnapshotView } from 'actions/snapshotActions';
import { getRelativeFilterDateRange, getLastPeriodDateRange } from 'helpers/dateHelper';
import {
  setDrilldownDimensions,
  updateDrilldownDimensionField,
  updateDrilldownViewEntry,
  updateQuickFilters,
  setCurrentVisualizationType,
  updateDrilldownGroupByEntry,
  updateTemplateEntryChange
} from 'actions/drilldownActions';
import {
  updateDateRange,
  updateDateType,
  updateRelativeDateFilterEntry,
  updateComparisonModeOn,
  updateComparisonType,
  updateComparisonDateRanges
} from 'actions/commonFiltersActions';
import { updateSnapshotSecondaryMetricField, updateGroupBychartViewType } from 'actions/snapshotActions';
import {
  getGroupDrilldownDimensionField,
  getViewEntryQuickFilters
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { getComparisonYearDateRange } from 'helpers/fiscalPeriodUtils';
import { getEntitiesValue } from './InsightsHelper';

const dispatchVariancePathLinkActions = ({ insight, result, dispatch }) => {
  const { entities: drilldownPath } = result;
  const {
    template_id: templateId,
    viewEntryField,
    comparisonRange
  } = insight;
  const templateEntry = getCurrentTemplateEntry(templateId);
  const viewEntry = getViewEntryByField(templateId, viewEntryField);
  const dimensionFields = _.keys(drilldownPath);
  const groupByViewType = _.get(viewEntry.visualization, 'snapshot.show_by_default');

  dispatch(updateTemplateEntryChange(templateEntry, 'name'));
  dispatch(updateDrilldownViewEntry(viewEntry));

  const dateRange = getRelativeFilterDateRange({ currentRelativeFilterOption: _.get(insight, 'dateRange') });
  dispatch(updateDateRange(dateRange ));
  const relativeDateFilterEntry = {
    currentRelativeFilterOption: _.get(insight, 'dateRange'),
    currentRelativePeriodType: "day",
    customPeriodCount: "0",
    defaultDateType: "relative",
    isRelativeFilter: "true"
  }
  // TODO: comparisonType Need to change according to new UI.
  // Now used old config to work for new format.
  const periodType = _.get(insight, 'dateRange');
  let comparisonType = DATE_COMPARISON_TYPE_OPTIONS[1].value;
  const selectedYear = moment(dateRange.endDate).format('YYYY') - 1;
  let comparisonDateRanges =  [getComparisonYearDateRange(dateRange, selectedYear, periodType)];
  if(comparisonRange == INSIGHT_COMPARISON_MODE_OPTIONS[1].value){
    comparisonType = DATE_COMPARISON_TYPE_OPTIONS[0].value;
    comparisonDateRanges = [getLastPeriodDateRange(dateRange, '')];
  }
  dispatch(updateComparisonType(comparisonType));
  dispatch(updateComparisonDateRanges(comparisonDateRanges));
  dispatch(updateRelativeDateFilterEntry(relativeDateFilterEntry));
  dispatch(updateDateType('relative'));
  dispatch(updateSnapshotSecondaryMetricField(false));

  dispatch(updateComparisonModeOn(true));

  dispatch(updateGroupBychartViewType(groupByViewType));
  let newDrilledDownDimensions = [];
  let newQuickFilters = getViewEntryQuickFilters(templateId, viewEntry);
  const selectedDrilldownEntries = _.map(dimensionFields, (field) => {
    return getSelectedDimensionEntry(templateId, field);
  });
  _.each(selectedDrilldownEntries, (dimensionEntry) => {
    const { field } = dimensionEntry;
    const value = drilldownPath[field] == '(No value)' ? null : drilldownPath[field];
    const dimensionByFilterObject = getFilterObject(
      false,
      dimensionEntry,
      field,
      value,
      true,
      (newDrilledDownDimensions.length)
    );
    newDrilledDownDimensions.push(dimensionByFilterObject);
    newQuickFilters = getNewFilters(newQuickFilters, dimensionByFilterObject);
  });
  _.each(newQuickFilters, (newQuickFilter) => {
    const groupDrilldownDimensionField = getGroupDrilldownDimensionField(templateId, newQuickFilter.field);
    newQuickFilter.field = groupDrilldownDimensionField;
  });
  dispatch(setDrilldownDimensions(newDrilledDownDimensions));
  dispatch(updateQuickFilters(newQuickFilters));
  dispatch(updateDrilldownGroupByEntry(GROUP_BY_NONE_ENTRY));

  const newDimensionField = _.last(selectedDrilldownEntries)['field']
  dispatch(updateDrilldownDimensionField(newDimensionField));
  dispatch(setCurrentVisualizationType(VISUALIZATION_TYPES.SNAPSHOT.type));
  dispatch(updateCurrentSnapshotView(SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type));
}

/*const getNextDimensionField = (templateId, drilldownPath) => {
  const availableDrilldownEntries = getAvailableDrilldownEntries({
    currentDrilldownTemplateId: templateId,
    drilledDownDimensions: _.keys(drilldownPath)
  });
  return _.chain(availableDrilldownEntries).first().get('field').value();
}*/

const AnalyticsLink = ({ insight, result, dispatchDrillDownEntry }) => {
  const { template_id: templateId, viewEntryField } = insight;
  const templateEntry = getCurrentTemplateEntry(templateId);
  const viewEntry = getViewEntryByField(templateId, viewEntryField);
  const segmentName = getEntitiesValue(result);
  return (
    <ReduxDispatchAsLink
      buildLinkState={(dispatch) => dispatchVariancePathLinkActions({insight, result, dispatch })}
      templateEntry={templateEntry}
      dispatchDrillDownEntry={dispatchDrillDownEntry}
      viewEntry={viewEntry}
      segmentName={segmentName}
      >
      Explore
    </ReduxDispatchAsLink>
  )
};

AnalyticsLink.propTypes = {
  insight: PropTypes.object,
  result: PropTypes.object,
  dispatchDrillDownEntry: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDrillDownEntry: (viewEntry, templateEntry) => {
      dispatch(updateDrilldownViewEntry(viewEntry));
      dispatch(updateTemplateEntryChange(templateEntry, 'name'));
    }
  }
}

function mapStateToProps(state) {
  return {
    templateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    quickFilters: _.cloneDeep(_.get(state.drilldown, 'quickFilters', [])),
    currentSecondaryMetricField: _.get(state, 'visualization.snapshot.currentSecondaryMetricField'),
    drilledDownDimensions: _.cloneDeep(_.get(state, 'drilldown.drilledDownDimensions', []))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsLink);
