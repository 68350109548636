import React from 'react';
import PropTypes from 'prop-types';

import NotificationListItem from 'common/components/Notifications/NotificationListItem';

const NotificationListContainer = (props) => {
  const { bookmarks, onSetOpen } = props;

  const renderNotificationList = () => {
    const notificationListItems = _.map(bookmarks, (bookmark) => {
      return (
        <NotificationListItem
          onSetOpen={onSetOpen}
          bookmark={bookmark}
          key={bookmark.id}
        />
      );
    });

    return (
      <ul className="list-group list-group-flush">
        {notificationListItems}
      </ul>
    );
  }

  return (
    <div className="notification-list-container">
      {renderNotificationList()}
    </div>
  );
}

NotificationListContainer.propTypes = {
  bookmarks: PropTypes.array,
  onSetOpen: PropTypes.func
}

NotificationListContainer.defaultProps = {
  bookmarks: [],
  onSetOpen: _.noop
}

export default NotificationListContainer;
