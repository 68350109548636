import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import {
  LINE_CHART_TOTAL_TRACE_COLOR,
  FORECASTING_TYPE,
  EXPONENTIAL_FORECAST_COLOR,
  PROPHET_FORECAST_COLOR,
  OVERTIME_TIME_FRAME_OPTIONS,
  HISTORICAL_FORECAST_COLOR
} from 'appConstants';
import { toLineTrace } from 'modules/visualization/LineChart/plotlyParamsGenerator/timeline';
import { generateTimeList } from 'modules/visualization/LineChart/Helpers/apiDataHelper';
import { getPlotlyLayout } from './plotlyParams';

const moment = extendMoment(Moment);
export const getPlotlyData = (props) => {
  const { forecastModelOptions, forecastAccuracyData, viewEntry,  dateRange } = props;
  const traces = [];
  const totalOptions = {
    isYearOnYear: false,
    isSecondsFormat: isTimeDurationEnabled(viewEntry),
    dataUnit: timeDurationDataUnit(viewEntry),
    dateRange,
    isTailingDrop: false,
    isProjection: false,
    isProjectionEnabled: true,
    lineColor: LINE_CHART_TOTAL_TRACE_COLOR,
    borderLineColor: LINE_CHART_TOTAL_TRACE_COLOR,
    isForecastingView: false,
    lineWidth: 2,
    periodType: 'month'
  };

  const totalMeta = {
    segmentType: 'current',
    isTotal: true,
    value: 'primary',
    dimension: 'Historical data',
    metricEntry: viewEntry,
    isForecastingView: false,
    isProjection: false
  };

  traces.push(
    toPlotlyTraces(
      totalOptions,
      totalMeta,
      formatAccuracyData(forecastAccuracyData['actual_total'], dateRange, 'period')
    )
  );

  const forecastTotal = _.get(forecastAccuracyData, 'forecast_total');

  _.each(forecastModelOptions, (option) => {
    if (option['type'] == "Historical_average") {
      const historicForecast = _.find(forecastTotal, FORECASTING_TYPE.HISTORICAL_AVG);
      const historicForecastTotal = _.get(historicForecast, FORECASTING_TYPE.HISTORICAL_AVG);
      const currentForecast = _.find(historicForecastTotal, `percent_${option.value}`);
      const currentForecastData = _.get(currentForecast,`percent_${option.value}`);
      const predictedData = _.get(currentForecastData,'predicted_data');
      const historicalData = formatAccuracyData(
          predictedData,
          dateRange,
          'date'
        );
      const options = {
        ...totalOptions,
        lineColor:  _.get(option, 'color', HISTORICAL_FORECAST_COLOR),
        borderLineColor: _.get(option, 'color', HISTORICAL_FORECAST_COLOR),
        lineDash: 'dashdot'
      }
      const meta = {
        ...totalMeta,
        dimension: option.name
      }
      traces.push(toPlotlyTraces(options, meta, historicalData));
    } else if (option['type'] == FORECASTING_TYPE.SIMPLE_EXPONENTIAL) {
      const predictedData = _.get(
        _.find(forecastTotal, FORECASTING_TYPE.SIMPLE_EXPONENTIAL),
        `${FORECASTING_TYPE.SIMPLE_EXPONENTIAL}.predicted_data`);
      const exponentialData = formatAccuracyData(
          predictedData,
          dateRange,
          'date'
        );
      const options = {
        ...totalOptions,
        lineColor: EXPONENTIAL_FORECAST_COLOR,
        borderLineColor: EXPONENTIAL_FORECAST_COLOR,
        lineDash: 'dashdot'
      }
      const meta = {
        ...totalMeta,
        dimension: option.name
      }
      traces.push(toPlotlyTraces(options, meta, exponentialData));
    } else {
      const predictedData = _.get(_.find(forecastTotal, "Prophet"), 'Prophet.predicted_data');
      const prophetData = formatAccuracyData(
        predictedData,
        dateRange,
        'date'
      );
      const options = {
        ...totalOptions,
        lineColor: PROPHET_FORECAST_COLOR,
        borderLineColor: PROPHET_FORECAST_COLOR,
        lineDash: 'dash'
      }
      const meta = {
        ...totalMeta,
        dimension: option.name
      }
      traces.push(toPlotlyTraces(options, meta, prophetData));
    }
  });
  return traces;
}

export const getPlotlyConfigs = (props, plotlyData) => {
  const { extraPlotlyParams, viewEntry,  currentChartView, dateRange, isSmallView } = props;
  const endDate = moment(_.get(dateRange, 'endDate')).format('DD/MM/YYYY');
  const startDate =  moment(_.get(dateRange, 'endDate')).subtract(1, 'year').format('DD/MM/YYYY');
  const config = {
    "displayModeBar": false,
    "scrollZoom": false,
    "editable": false,
    "showLink": false,
    "responsive": true
  };
  const style = { width: "98%", height: "100%", minHeight: "500px"};
  const  vizOptions = {
    renderType: currentChartView,
    viewEntry,
    renderTimeFrame: OVERTIME_TIME_FRAME_OPTIONS.ROLLING,
    isForecastAccuracy: true,
    dateRange
  };
  const options = {
    vizOptions : vizOptions,
    legacyChartOptions: {
      viewOptions: viewEntry
    },
    sliderRange: [startDate, endDate],
    isSmallView: isSmallView
  };
  const layout = getPlotlyLayout(plotlyData, options);

  return {
    ...extraPlotlyParams,
    data: plotlyData,
    useResizeHandler: true,
    layout,
    config,
    style
  };
}

export const formatAccuracyData = (data, dateRange, period) => {
  const endDate = moment(_.get(dateRange, 'endDate')).
    subtract(1, 'month').endOf('month');
  const startDate =  moment(_.get(dateRange, 'endDate')).
    subtract(12, 'months').set('date', 1);
  const dateList = generateTimeList(startDate, endDate, 'months', 'day');

  return _.map(dateList, (datePeriod) => {
    const filteredData = _.find(data, (item) =>{
      if(moment( _.get(item, period)).isBetween(datePeriod['start'], datePeriod['end'], null, '[]')){
        return item;
      }
    });

    return {
      period: datePeriod['start'].format('YYYY-MM-DDTHH:mm:ss.SSS'),
      value: _.get(filteredData, 'value', ''),
    }
  });
}

const toPlotlyTraces = (options, totalMeta, entries) => {
  return toLineTrace(entries, 'value', {
    meta: totalMeta,
    ...options
  })
}