import React from 'react';
import PropTypes from 'prop-types';
import { getValidFormattedData } from '../ForecastHelper';
import { getValidChartValue } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { ForgeIcon, ForgeInlineMessage } from '@tylertech/forge-react';

const InstructionMessage = (props) => {
  const { forecastAttributeOptions, forecastFormattedData } = props;

  const renderAlertIcon = () => {
    const styleInfo = {
      color: "#F6BE00"
    }

    return (
      <ForgeIcon slot="icon"  key={'issue-data'}
        style={styleInfo} name={'alert_outline'} />
    )
  }

  const renderWarningInstruction = () => {
    const validDataOptions = {
      formattedData: forecastFormattedData,
      ...forecastAttributeOptions
    }
    const formattedData = getValidFormattedData(validDataOptions);

    const isFoundNegativeValue = _.some(formattedData, (datum) => {
      const value = Number(_.get(datum, 'value', 0));
      const adjustValue = Number(_.get(datum, 'adjustValue', 0));

      if(adjustValue > 0){
        return (value < 0 && adjustValue < 0)
      } else {
        return (value < 0)
      }
    });

    const isFoundGapValue = _.some(formattedData, (datum) => {
      const value = getValidChartValue(datum);
      const adjustValue = Number(_.get(datum, 'adjustValue', 0));

      return (value == 0 && adjustValue == 0 )
    });

    if (isFoundNegativeValue && isFoundGapValue) {
      return (
        <div>
          Your dataset has gaps and negative values, which may affect the accuracy of some models.
          If gaps or negative values are not expected in the forecast,  you may adjust those
          data points here. Adjusted values will only impact the forecast, and not the source data.
        </div>
      )
    } else if (isFoundNegativeValue) {
      return (
        <div>
          Your dataset has negative values. If negative values are not
          expected in the forecast, you may adjust those data points here.
          Adjusted values will only impact the forecast, and not the source data.
        </div>
      )
    } else if (isFoundGapValue) {
      return (
        <div>
          Your dataset has gaps, which may affect the accuracy of some models.
          If gaps are not expected in the forecast, you may adjust those data points here.
          Adjusted values will only impact the forecast, and not the source data.
        </div>
      )
    } else {
      return null;
    }
  }

  const renderWarningInfo = () => {
    const warningInfo = renderWarningInstruction();
    if(_.isEmpty(warningInfo)){
      return null
    }

    return (
      <ForgeInlineMessage>
        {renderAlertIcon()}
        {warningInfo}
      </ForgeInlineMessage>
  )

  }
  return (
    <>
      <div className='forge-typography--subtitle1 mb-2'>
        Here you can fill in any gaps in your data, or modify
        values to smooth out the impact of one-time events or outliers.
      </div>
      {renderWarningInfo()}
    </>
  )
};

InstructionMessage.propTypes = {
  forecastAttributeOptions: PropTypes.object,
  forecastFormattedData: PropTypes.array
}

export default InstructionMessage;