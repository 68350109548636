// Vendor Imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import classNames from 'classnames';

// Project Imports
import LoadingSpinner from 'common/components/LoadingSpinner';
import { isEnterButtonPressed, isEscapeButtonPressed } from 'helpers/mouseEventsHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isBottomOfElementReached } from 'helpers/DomPageHelper';
import ForgeTypeHeadAutocomplete from './ForgeTypeHeadAutocomplete';
const SCROLL_DEBOUNCE_WAIT_TIME = 300;
const DROPDOWN_HEIGHT = 260;
const DROPDOWN_BUTTON_HEIGHT = 40;
const PADDING = 20;

class TypeHeadAndTopValues extends Component {
  constructor(props) {
    super(props);
    this.asyncTypeaheadRef = React.createRef();
    this.searchDropdwonRef = React.createRef();
    this.filterValueListRef = React.createRef();
    this.forgeAutocomplete = React.createRef();
    this.inputRef = React.createRef();
    this.state = {
      showDropdown: false,
      loading: false,
      columnValues: [],
      isTyping: false,
      offset: 0,
      isLoadMore: true,
      showDropdownTop: false,
      isLoading: false,
      topValues: [],
      totalCount: 0
    };
    this.searchText = '';
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    if (!this.props.isExpanded) {
      this.fetchColumnTopValues();
    } else {
      this.setState({ offset: 0 });
    }
  }

  fetchColumnTopValues = () => {
    const { apiParams, fetchTopValues, field, nullValueLabel, extraOptions, onFilterItemClick } = this.props;
    const { offset, topValues, isLoadMore } = this.state;

    if (!isLoadMore) {
      return;
    }
    const params = { field, offset, ...apiParams };
    this.setState({ isLoading: true });

    fetchTopValues(params).
      then((response) => {
        const lazyLoadedValues = _.map(response, (responseItem) => {
          const defaultValue = _.isString(responseItem) ? responseItem : '';
          return _.get(responseItem, field, defaultValue);
        });
        const newOffset = _.isEmpty(lazyLoadedValues) ? offset : offset + 1;
        let newTopValues = _.without([...topValues, ...lazyLoadedValues], '');
        if (offset === 0) {
          if (!_.includes(newTopValues, nullValueLabel) && !_.isEmpty(nullValueLabel)) {
            newTopValues.unshift(nullValueLabel);
          }
          newTopValues = extraOptions.concat(newTopValues);
        }

        this.setState({
          topValues: _.uniq(newTopValues),
          isLoading: false,
          offset: newOffset,
          isLoadMore: !_.isEmpty(lazyLoadedValues)
        }, () => { onFilterItemClick('SearchResult'); });
      }).catch((err) => {
        if (err.name !== 'AbortError') {
          console.log('Error on column top Values ', err); // eslint-disable-line no-console
          this.setState({ isLoading: false, topValues: [] });
        }
      });
  }

  fetchColumnValues = (searchText) => {
    const { apiParams, fetchSearchInputValues, field, extraOptions } = this.props;
    this.searchText = searchText;

    this.setState({ loading: true });
    const params = { field, searchText, ...apiParams };
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    fetchSearchInputValues(params, this.abortFetchController).
      then((response) => {
        if (searchText == this.searchText) {
          const newValues = extraOptions.concat(response);
          this.setState({
            columnValues: _.uniq(newValues),
            loading: false
          });
        }
      }).catch((err) => {
        if (err.name !== 'AbortError') {
          console.log('Error on column Values ', err); // eslint-disable-line no-console
          this.setState({ loading: false, columnValues: [] });
        }
      });
  }

  handleScrollToLazyLoadValues = _.debounce(() => {
    if (!this.state.isTyping && isBottomOfElementReached(this.filterValueListRef.current)) {
      this.fetchColumnTopValues();
    }
  }, SCROLL_DEBOUNCE_WAIT_TIME);

  onToggleDropdown = (event) => {
    const { showDropdown } = this.state;
    const dropdownPosition = event.pageY + DROPDOWN_HEIGHT + DROPDOWN_BUTTON_HEIGHT + PADDING;
    const showDropdownTop = dropdownPosition > (window.innerHeight + window.scrollY);
    this.setState({ showDropdown: !showDropdown, showDropdownTop, isTyping: false });
  }

  onKeyDownTopValue = (e, topValue) => {
    if (isEscapeButtonPressed(e)) {
      this.onToggleDropdown(e);
    }

    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      this.onTypeHeadChange([topValue]);
    }
  }

  onKeyDownToggleButton = (e) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      this.onToggleDropdown(e);
    }
  }

  inputKeyDown = (e) => {
    const val = e.target.value;
    if (isEnterButtonPressed(e) && val) {
      this.props.onAdd([val]);
    }
  }

  onInputChange = (inputValue) => {
    this.setState({ isTyping: !_.isEmpty(inputValue) });
  }

  onRemoveItem = (removedValue) => {
    this.props.onRemove(removedValue);
  }

  onTypeHeadChange = (selectedItems) => {
    const { selectedValues } = this.props;
    const selectedItem = _.get(selectedItems, '0');
    const isItemInSelectedValues = _.includes(selectedValues, selectedItem);
    if (isItemInSelectedValues) {
      this.props.onRemove(selectedItem);
    } else {
      this.props.onAdd(selectedItems);
    }
    this.props.onFilterItemClick('SearchResult');
    this.asyncTypeaheadRef.current.clear();
  }

  renderSearchInput() {
    const { columnValues } = this.state;
    const { isCurrencyRenderType, hideInputBox } = this.props;

    if (hideInputBox) {
      return null;
    }

    if (isCurrencyRenderType) {
      return (
        <input type="number" placeholder='Enter the value' className="form-control"
          onKeyDown={this.inputKeyDown}
          ref={ref => { this.tagInput = ref; }}
        />
      );
    }

    return (
      <AsyncTypeahead
        bsSize="small"
        id="{field}"
        placeholder='Search'
        renderMenu={() => {
          return null;
        }}
        isLoading={false}
        minLength={1}
        multiple
        onInputChange={this.onInputChange}
        onChange={this.onTypeHeadChange}
        onSearch={this.fetchColumnValues}
        options={columnValues}
        ref={this.asyncTypeaheadRef}
        useCache={false}
      />
    );
  }

  renderTopValuesContent = () => {
    const { topValues, isTyping, columnValues, isLoading, loading } = this.state;
    const { selectedValues, isCurrencyRenderType, isExpanded } = this.props;
    const newTopValues = isTyping ? columnValues : topValues;

    const topValuesContent = _.map(newTopValues, (topValue, index) => {
      const activeClass = _.includes(selectedValues, topValue) ? 'active' : '';

      return (
        <li
          tabIndex={0}
          aria-label={topValue}
          className={`list-group-item ${activeClass}`}
          key={index}
          onKeyDown={(e) => this.onKeyDownTopValue(e, topValue)}
          onClick={() => this.onTypeHeadChange([topValue])}
        >
          <div className="d-flex align-items-center">
            <div className="list-value-items">
              {formatValueToCurrency(topValue + '', isCurrencyRenderType)}
            </div>
          </div>
        </li>
      )
    });

    if (_.isEmpty(topValuesContent) && !isLoading && !loading && !isExpanded) {
      return (
        <li className="list-group-item"> No result found</li>
      );
    }

    return topValuesContent;
  }

  renderSearchInputAndTopValues = () => {
    const { loading, isLoading } = this.state;
    const { values, topValueLabel } = this.props;
    const valueListClassNames = classNames('value-list', { 'with-help-text': !_.isEmpty(topValueLabel) });

    return (
      <div
        className="show type-head-value border-0"
        aria-labelledby="dropdownMenuButton"
      >
        <div className="topvalue-search-wrapper">
          <div>
            {this.renderSearchInput()}
          </div>
          <div className={valueListClassNames}
            ref={this.filterValueListRef}
            onScroll={this.handleScrollToLazyLoadValues}>
            {!_.isEmpty(topValueLabel) &&
              <div className="help-text ">
                <em>{topValueLabel}</em>
              </div>
            }
            <LoadingSpinner size="sm" isLoading={loading || isLoading} />
            <ul className="list-group">
              {!_.isEmpty(values) && <div className="dropdown-divider mt-0"></div>}
              {this.renderTopValuesContent()}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderTypeHeadDropdown() {
    const dropupClassName = this.state.showDropdownTop ? 'dropup' : '';

    return (
      <div
        className={`dropdown w-100 ${dropupClassName}`}
        ref={this.searchDropdwonRef}
        tabIndex={0}
        aria-label="select a value"
        onKeyDown={this.onKeyDownToggleButton}
      >
        {this.renderSearchInputAndTopValues()}
      </div>
    );
  }

  renderForgeAutocomplete = () => {
    return (
      <ForgeTypeHeadAutocomplete {...this.props} />
    );
  }

  render() {
    const { isExpanded } = this.props;
    return (
      <>
        {isExpanded && this.renderForgeAutocomplete()}
        {!isExpanded && this.renderTypeHeadDropdown()}
      </>
    );
  }
}

TypeHeadAndTopValues.defaultProps = {
  hideInputBox: false,
  extraOptions: [],
  onFilterItemClick: _.noop,
  isExpanded: false,
}

TypeHeadAndTopValues.propTypes = {
  apiParams: PropTypes.object,
  fetchTopValues: PropTypes.func,
  fetchSearchInputValues: PropTypes.func,
  field: PropTypes.string,
  isCurrencyRenderType: PropTypes.bool,
  hideInputBox: PropTypes.bool,
  nullValueLabel: PropTypes.string,
  topValueLabel: PropTypes.string,
  values: PropTypes.array,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  selectedValues: PropTypes.array,
  extraOptions: PropTypes.array,
  onFilterItemClick: PropTypes.func,
  isExpanded: PropTypes.bool,
  onForgeSelect: PropTypes.func,
  filterName: PropTypes.string
};

export default TypeHeadAndTopValues;
