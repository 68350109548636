export const userAddingMessage = 'Success! User added.';
export const userRemovalMessage = 'Success! User removed.';
export const welcomeEmailMessage = 'Welcome email sent! It may take up to ten minutes to arrive.';
export const brandingSavedMessage = 'Success! Branding changes saved.';
export const ShareMetricMessage = 'Success! Email sent.';
export const userRoleSavedMessage = 'Success! User roles saved.';
export const userManagerRoleSavedMessage = 'Success! User role saved. '+
  'This user will be notified by email of their new role and permissions.';
export const collectionSharedMessage = 'Collection shared! '+
  'Your recipients will be notified by email and this collection will appear on their dashboard.';
export const subscriptionShareMessage = 'Subscription shared! ' +
  'Your recipients will receive regular email updates about this collection.';
export const subscriptionWithCollectionShareMessage = 'Collection and subscription shared!' +
  ' Your recipients will be notified by email and this collection will appear on their dashboard.'
export const subscriptionCreatedMessage = 'Success! Subscription created.';
export const viewSavedMessage = 'Success! Your view has been saved.';
export const collectionCreatedMessage = 'Success! Collection created.';
export const collectionCopiedMessage = 'Success! Collection copied.';
export const alertSharedMessage = 'Alert shared! Your recipient will be notified by email.';
export const viewSharedMessage = 'View shared!'+
  ' Your recipient will be notified by email and this view will appear in their My Views.';
export const userUploadMessage = 'Success! Users Email Uploaded Successfully.';

// Toast info messages
export const alertUndoMessage = 'Success! You are no longer watching this view.';
export const alertDeleteMessage = 'Success! Alert deleted.';
export const subscriptionUndoMessage = 'Success! You are no longer watching this collection.';
export const subscriptionDeleteMessage = 'Success! Subscription deleted.';
export const viewDeleteMessage = 'Success! Saved view deleted.';
export const collectionDeleteMessage = 'Success! Collection deleted.';
export const metricCopiedMessage = 'Success! Metric copied.';
export const metricMovedMessage = 'Success! Metric moved.';
export const cardCopiedMessage = 'Success! Card copied.';
export const cardMovedMessage = 'Success! Card moved.';
export const embedCopyMessage = 'Embed code for this view has been copied to clipboard.';

export const userPermissionChangeMessage = 'Success! User permissions saved.';
export const userRoleChangeMessage = 'Success! User roles saved.';
export const copyLinkSuccessMessage = 'Success! Link copied.';


// Error message
export const shareMetricMessageError = 'Something went wrong. Email not sent.';
export const viewSavedErrorMessage = 'Something went wrong. View not saved.';
export const subscriptionCreatedErrorMessage = 'Something went wrong. Subscription not created.';
export const collectionCreatedErrorMessage = 'Something went wrong. Collection not created.';
export const collectionCopiedErrorMessage = 'Something went wrong. Collection not copied.';
export const collectionSharedErrorMessage = 'Something went wrong. Collection not shared.';
export const subscriptionShareErrorMessage= 'Something went wrong. Subscription not shared.';
export const viewSharedErrorMessage = 'Something went wrong. View not shared.';
export const alertSharedErrorMessage = 'Something went wrong. Alert not shared.';
export const welcomeEmailErrorMessage = 'Something went wrong. Email not sent.';
export const brandingSavedErrorMessage = 'Something went wrong. Changes not saved.';
export const userRoleSavedErrorMessage = 'Something went wrong. User role not saved.';
export const userManagerRoleSavedErrorMessage = 'Something went wrong. User role not saved.';
export const userAddingErrorMessage = 'Something went wrong. User not added.';
export const userUploadErrorMessage = 'Something went wrong. Failed to import Users Emails.';
export const userRemovalErrorMessage = 'Something went wrong. User not removed.';
export const alertUndoErrorMessage = 'Something went wrong.';
export const alertDeleteErrorMessage = 'Something went wrong. Alert not deleted.';
export const subscriptionDeleteErrorMessage = 'Something went wrong. Subscription not deleted.';
export const subscriptionUndoErrorMessage = 'Something went wrong.';
export const viewDeleteErrorMessage = 'Something went wrong. View not deleted.';
export const collectionDeleteErrorMessage = 'Something went wrong. Collection not deleted.';
export const metricCopiedErrorMessage = 'Something went wrong. Metric not copied.';
export const metricMovedErrorMessage = 'Something went wrong. Metric not moved.';
export const embedCopyErrorMessage = 'Something went wrong. Embed code not copied to clipboard.';
export const subscriptionEmailErrorMessage = 'Something went wrong. Confirmation email not sent.';
export const copyLinkErrorMessage = 'Something went wrong. Link not copied.';
export const radarPageSaveMessage = 'Success! Your analysis has been saved.';
export const cardCopiedErrorMessage = 'Something went wrong. Card not copied.';
export const cardMovedErrorMessage = 'Something went wrong. Card not moved.';