import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Pagination from 'common/components/Pagination/Pagination';
import PopoverStickOnHover from 'pages/drilldown/components/PopoverStickOnHover';
import HeatChartLegend from './HeatChartLegend';
import HeatChartDescription from './HeatChartDescription';
import HeatChartLabel from './HeatChartLabel';
import HeatChartIcon from '../components/HeatChartIcon';
import { getNullValueLabel } from 'common/config/templateConfiguration';

import {
  getHeatChartConditionHeaders,
  getConditionCellEntry
} from '../slaWatchHelper';
import {
  getSelectedTemplateForSLAWatch,
  getSLAWatchFlyoutEntries,
  isErpCalculationType,
  getConditionNames
} from 'common/config/customerConfiguration';
import { EPR_CALCULATION_TYPES } from 'appConstants';

const ITEM_PER_PAGE = 30;

class HeatChart extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentPage: 1,
      currentPageDimensionData: []
    };
  }

  componentDidMount() {
    this.loadCurrentPageData();
  }
  
  componentDidUpdate(prevProps) {
    const { dimensionData, selectedDimension } = this.props;
    if(
      !_.isEqual(dimensionData, _.get(prevProps, 'dimensionData', [])) ||
      !_.isEqual(selectedDimension, _.get(prevProps, 'selectedDimension'))
    ){
      this.setState({currentPage: 1}, () => {
        this.loadCurrentPageData();
      })
    }
  }

  loadCurrentPageData = () => {
    const { dimensionData } = _.cloneDeep(this.props);
    const { currentPage } = this.state;
    let selectedIndex = (currentPage * ITEM_PER_PAGE) - ITEM_PER_PAGE;
    let currentPageDimensionData = _.slice(dimensionData, selectedIndex, currentPage * ITEM_PER_PAGE)

    this.setState({ currentPageDimensionData })
  }

  handlePageChange = (switchDirection) => {
    let { currentPage } = this.state;
    let selectedPageNo = (switchDirection === 'next') ? (currentPage + 1) : (currentPage - 1);
    this.setState({ currentPage: selectedPageNo }, () => {
      this.loadCurrentPageData(selectedPageNo);
    });
  }

  renderIcon(dimensionEntry, matchedConditionEntry, styleEntry, header){
    const nullValueLabel = getNullValueLabel(getSelectedTemplateForSLAWatch());
    const dimension = dimensionEntry['dimension'] || nullValueLabel;
    const isConditionPass = matchedConditionEntry['isConditionPassed'];
    const icon = isConditionPass ? 'icons-check' : 'icons-times';
    const viewField = matchedConditionEntry['view_field']
    const popValues = {
      actual_value: matchedConditionEntry['value'],
      total_count: _.get(dimensionEntry, `dimension_count_${viewField}`, []),
      distance: isConditionPass ? 'NA' : matchedConditionEntry['distance']
    }

    const popContents = _.compact(_.map(getSLAWatchFlyoutEntries(), (entry) => {
      if (entry['show'] == 'true') {
        return (
          <div key={entry['id']} className="entry-details">
            <div className="entry-name">{entry['name']} : </div>
            <div className="entry-values">{popValues[entry['id']]} {entry['suffix']}</div>
          </div>
        );
      }
    }));

    if(!_.isEmpty(popContents)){
        const popover = (
          <div className="popup-contents">
            <div className="popup-head">{`${dimension}: ${header['label']}`}</div>
            <div className="popup-details">
              {popContents}
            </div>
          </div>
        );
        return(
          <PopoverStickOnHover
            component={popover}
            placement="bottom"
            isSLAHeatChart={true}
            onMouseEnter={() => { }}>
            <i className={icon} style={{color: styleEntry['color']}}></i>
          </PopoverStickOnHover>
        );
      }else{
        return (<HeatChartIcon icon={icon} color={styleEntry['color']} />);
      }
  }

  renderConditionRowData(dimensionEntry) {
    const { selectedDimension, selectedListItem } = this.props;
    const conditionData = _.get(dimensionEntry, 'chart_data', []);
    const headers = isErpCalculationType() ?
      EPR_CALCULATION_TYPES :
      getHeatChartConditionHeaders(selectedDimension, selectedListItem);

    return _.map(headers, (header) => {
      let matchedConditionEntry = _.find(conditionData, (entry) => {
        return (entry['bucket_id'] == header['bucket_id']);
      }) || {};
      if (isErpCalculationType()) {
        matchedConditionEntry = _.find(conditionData, (entry) => {
          return (entry['label'] == header);
        }) || {};
      }
      const isConditionPass = matchedConditionEntry['isConditionPassed'];
      const value = matchedConditionEntry['value'];

      let styleEntry = { backgroundColor: '#fff', color: '#2E7D32' };
      if (!isConditionPass) {
        const cellEntry = getConditionCellEntry(value, header)
        styleEntry = {
          backgroundColor: cellEntry['background-color'],
          color: cellEntry['tick-color']
        };
      }
      const passedClass = classNames('condtion-column', {
        'passed': isConditionPass,
        'failed': !isConditionPass
      });
      if (_.isEmpty(matchedConditionEntry)) {
        return <td style={{ backgroundColor: '#dedede' }}></td>
      }
      return (
        <td
          style={{ backgroundColor: styleEntry['backgroundColor'] }}
          key={matchedConditionEntry['bucket_id']}
          className={passedClass}>
          {this.renderIcon(dimensionEntry, matchedConditionEntry, styleEntry, header)}
        </td>
      );
    });
  }

  renderDimensionRowLabel(index, dimension) {
    return (
      <HeatChartLabel
        labelIndex={index}
        dimensionLabel={dimension} />
    )
  }

  renderDimensiosRows(tableDimensionData) {
    const nullValueLabel = getNullValueLabel(getSelectedTemplateForSLAWatch());
    return _.map(tableDimensionData, (dimensionEntry, index) => {
      const dimension = dimensionEntry['dimension'] || nullValueLabel
      return (
        <tr key={`${index}-${dimension}`}>
          {this.renderDimensionRowLabel(index, dimension)}
          <td />
          {this.renderConditionRowData(dimensionEntry)}
        </tr>
      )
    })
  }

  renderCheckHeaders() {
    const { selectedDimension, selectedListItem } = this.props;
    const headerConditions =  isErpCalculationType() ?
      getConditionNames() :
      getHeatChartConditionHeaders(selectedDimension, selectedListItem);

    return _.map(headerConditions, (condition) => {
      const label = condition['label'] || condition;

      return(<th key={label}>{label}</th>);
    })
  }

  renderTable(tableData) {
    if (_.isEmpty(tableData)) {
      return
    }
    return (
      <table className="table heat-chart-table">
        <thead>
          <tr>
            <th>
              <div className="dimension-header" /></th>
            <th />
            {this.renderCheckHeaders()}
          </tr>
        </thead>
        <tbody>
          {this.renderDimensiosRows(tableData)}
        </tbody>
      </table>
    )
  }
  renderHeatChartTables(){
    const{ currentPageDimensionData } = this.state;
    const halfCount = ITEM_PER_PAGE / 2;
    const rightSideData = _.slice(currentPageDimensionData, 0, halfCount);
    const leftSideData = _.slice(currentPageDimensionData, halfCount, ITEM_PER_PAGE);
    return (
      <div className="sla-heat-chart-wrapper">
        <div>
          {this.renderTable(rightSideData)}
        </div>
        <div>
          {this.renderTable(leftSideData)}
        </div>
      </div>
    )
  }

  renderPagination() {
    const { dimensionData } = this.props;
    const { currentPage } = this.state;

    return (
      <Pagination
        showInfoInside={true}
        enableShowText={true}
        currentPage={currentPage}
        itemsPerPage={ITEM_PER_PAGE}
        totalRecordsCount={_.size(dimensionData)}
        switchPage={this.handlePageChange} />
    );
  }

  render() {
    const { selectedListItem, selectedDimension } = this.props;
    return (
      <div className="sla-heat-chart">
        {this.renderHeatChartTables()}
        {this.renderPagination()}
        <div className="legend-wrapper mt-auto">
          {isErpCalculationType() ? null : <HeatChartLegend /> }
          {isErpCalculationType() ? null:
            <div className='nodata align-self-baseline'>
              <span>No Data</span>
              <div className='nodata-bg'></div>
            </div>
          }
          <HeatChartDescription
            selectedListItem={selectedListItem}
            selectedDimension={selectedDimension} />
        </div>
      </div>
    )
  }
}

HeatChart.propTypes = {
  dimensionData: PropTypes.array,
  selectedDimension: PropTypes.object,
  selectedListItem: PropTypes.object
};

export default HeatChart;
