import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  getRadarPageTitle,
  getRadarPageDescription
} from 'common/config/customerConfiguration';
import { isRadarViewChanged } from '../radarHelper';
import SaveAndSaveAsButton from 'pages/dashboard/components/SaveAndSaveAsButton';

class RadarSaveButton extends Component {

  render() {
    return(
      <SaveAndSaveAsButton
        {...this.props}
        name={getRadarPageTitle()}
        description={getRadarPageDescription()}
        isViewChanged={isRadarViewChanged}
        savedTrackMessage="radar_saved_view_created"
        saveAsTrackMessage="radar_initiate_save_as" />
    )
  }
}

RadarSaveButton.propTypes = {
  currentBookmark: PropTypes.object,
  bookmarkId: PropTypes.string,
  onEditBookmarkSave: PropTypes.func,
  onBookmarkSave: PropTypes.func,
  hideSaveAsDropDown: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default RadarSaveButton;
