import _ from 'lodash';
import {
  VISUALIZATION_TYPES,
  DEFAULT_DROPDOWN_OPTION,
  DATE_FORMAT,
  NO_OF_BENCH_MARKS,
  DEFAULT_BOOKMARK_DIMENSION_OPTION,
  EMAIL_STRATEGY_TYPES,
  NEW_CREATED_RECORD_OPTIONS
} from 'appConstants';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import {
  getBenchMarkMetricEntry,
  getBenchMarkMetricEntries,
  getOvertimeChartOptions,
  getSnapshotVisualizationTypes
} from 'helpers/visualizationHelper';
import {  getAvailableMapViews } from 'modules/Map/helpers/MapOptionsHelper';
import { validateEmail } from 'helpers/HttpHelper';
import { isPrivate } from 'common/config/customerConfiguration';
import { getDimensionEntries, getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
import {
  getRelativeFilterDateRange,
  getDefaultDateMode
} from 'helpers/dateHelper';
import { getConfiguredDataEndDate } from 'common/config/customerConfiguration';

export const getConditionDisplayName = (viewEntry, currentBenchmarkName, monitorCondition) => {
  const metricName = getPrimaryMetricName(viewEntry);
  let benchmarkName = '';
  let operator = monitorCondition.operator;

  if(monitorCondition.type == 'benchmark') {
    if(_.isEqual(DEFAULT_DROPDOWN_OPTION.name, currentBenchmarkName)) {
      benchmarkName = 'benchmark';
    }else{
      benchmarkName = currentBenchmarkName;
    }
  }
  let displayNames = {
    '>': `${metricName} is above ${benchmarkName}`,
    '==': `${metricName} is equal to ${benchmarkName}`,
    '<': `${metricName} is below ${benchmarkName}`,
    '%': `${metricName} is within`
  };
  return displayNames[operator] || monitorCondition['name'];
}

export const getDefaultMetricCondition = (viewEntry) => {
  const metricName = getPrimaryMetricName(viewEntry);
  return {
    name: `${metricName} is above [ ]`,
    operator: '>',
    type: 'primary',
    enable: true,
    value: 0
  }
}
export const getMonitorConditionOptions = (viewEntry, benchMarkEntries, addRecordCondtion = true) => {
  const metricName = getPrimaryMetricName(viewEntry);
  let conditions = [
    getDefaultMetricCondition(viewEntry),
    {name: `${metricName} is equal to [ ]`, operator: '==', type: 'primary', enable: true, value: 0},
    {name: `${metricName} is below [ ]`, operator: '<', type: 'primary', enable: true, value: 0},
    {name: `${metricName}  is within [ ] percent of [ ]`, operator: '%', type: 'primary',
      enable: true, value: 0, displayName: `${metricName} is within`, percentValue: 0},
  ];

  _.forEach(benchMarkEntries, (benchMarkEntry) => {
    if(!_.isEmpty(benchMarkEntries) && !_.isEqual(DEFAULT_DROPDOWN_OPTION, benchMarkEntry)) {
      const benchmarkName = _.get(benchMarkEntry, 'name');
      let benchMarkConditions = [
        {
          name: `${metricName} is above ${benchmarkName}`,
          operator: '>',
          type: 'benchmark',
          enable: true,
          benchmarkName: benchmarkName
        },
        {
          name: `${metricName} is equal to ${benchmarkName}`,
          operator: '==',
          type: 'benchmark',
          enable: true,
          benchmarkName: benchmarkName
        },
        {
          name: `${metricName} is below ${benchmarkName}`,
          operator: '<',
          type: 'benchmark',
          enable: true,
          benchmarkName: benchmarkName
        },
        {
          name: `${metricName} is within [ ] percent of ${benchmarkName}`,
          operator: '%',
          type: 'benchmark',
          enable: true,
          benchmarkName: benchmarkName,
          value: 0,
          displayName: `${metricName} is within`
        }
      ];
     conditions = conditions.concat(benchMarkConditions);
    }
  });
  const recordsCondtions = [
    {
      name: 'records match my filters',
      type: 'records',
      strategy: EMAIL_STRATEGY_TYPES.RECORDS_MATCH_SELECTED_FILTERS,
      newlyCreatedRecordValue: ''
    },
    {
      name: 'new records match my filters',
      type: 'records',
      strategy: EMAIL_STRATEGY_TYPES.RECORDS_MATCH_SELECTED_FILTERS,
      newlyCreatedRecordValue: NEW_CREATED_RECORD_OPTIONS
    }
  ];
  if(addRecordCondtion) {
    return conditions.concat(recordsCondtions);
  }else {
    return conditions;
  }

}

export const getVizBasedChartTypes = (visType, viewEntry) => {
  if(_.isEqual(visType, VISUALIZATION_TYPES.SNAPSHOT.type)){
    return getSnapshotVisualizationTypes(viewEntry);
  }else if(_.isEqual(visType, VISUALIZATION_TYPES.MAP.type)){
    return _.values(getAvailableMapViews(viewEntry));
  }else if(_.isEqual(visType, VISUALIZATION_TYPES.OVERTIME.type)){
    return getOvertimeChartOptions(viewEntry);
  } else {
    return [];
  }
};

export const getBenchMarkName = (drilldown, visualization) => {
  const { currentVisualizationType } = drilldown;
  return _.get(visualization, currentVisualizationType + '.currentBenchMarkMetricNames');
}

export const getBenchMarkEntry = (drilldown, visualization, currentVizChartType) => {
  const bookmarkName = getBenchMarkName(drilldown, visualization);
  const { currentDrilldownViewEntry, currentVisualizationType } = drilldown;
  return getBenchMarkMetricEntry(currentDrilldownViewEntry, currentVisualizationType,
    currentVizChartType, bookmarkName);
}

export const getBenchMarkEntries = (drilldown, visualization, currentVizChartType) => {
  const bookmarkNames = getBenchMarkName(drilldown, visualization);
  const { currentDrilldownViewEntry, currentVisualizationType } = drilldown;
  return getBenchMarkMetricEntries(currentDrilldownViewEntry, currentVisualizationType,
    currentVizChartType, bookmarkNames);
}

export const validateShareEmails = (shareEmails, userEmails) => {
  let isValid = isValidEmails(shareEmails);

  if (isPrivate()) {
    return (allEmailsAreValid(shareEmails, userEmails) && isValid);
  } else {
    return isValid;
  }

}

export const isValidEmails = (shareEmails) => {
  let isValid = true;
  _.each(shareEmails, (email) => {
    if(!validateEmail(email)){
      isValid = false;
    }
  });

  return isValid;
}

export const isAnyWildCardUsers = (userEmails) =>{
  return _.some(userEmails, (user) => _.startsWith(user, '*'));
}

export const allEmailsAreValid  = (shareEmails, userEmails) => {
  if (isPrivate()) {
    const userEmailsToLowerCase = _.map(userEmails, _.toLower);

    return _.every(shareEmails, (email) => {
      return _.includes(userEmailsToLowerCase, _.toLower(email));
    });
  }

  return true;
}

export const isMonitorConditionSuccess = (
  bookmarkOptions,
  dimensionTotal,
  benchMarkEntries,
  benchMarkValues,
  viewEntry,
  dimensionWiseData
) => {
  let dimensionOption = _.get(bookmarkOptions, 'dimensionOption', DEFAULT_BOOKMARK_DIMENSION_OPTION);
  const precision = _.get(viewEntry, 'precision');
  const dimensionValue = Number(parseFloat(dimensionTotal).toFixed(precision));
  const enabledMonitorConditions = getEnabledMonitorConditions(bookmarkOptions, benchMarkEntries)

  if(_.isEmpty(enabledMonitorConditions)) {
    return false;
  }

  let evaluationResults = [];
  _.each(enabledMonitorConditions, (condition) => {
    const operator = _.get(condition, 'operator');
    if(condition.type == 'benchmark') {
      _.each(benchMarkValues, (benchMarkValue) => {
        if(operator == '%') {
          const value = Number(_.get(condition, 'value')) || 0;
          const percentValue = (value/100) * Number(benchMarkValue);
          const abovePercentValue = (Number(benchMarkValue) + percentValue);
          const belowPercentValue = (Number(benchMarkValue) - percentValue);

          evaluationResults.push(((dimensionValue > belowPercentValue) &&
            (dimensionValue < abovePercentValue)));
        }else {
          evaluationResults.push(eval(`${dimensionValue} ${operator} ${benchMarkValue}`));
        }
      });
    } else {
      if(dimensionOption === DEFAULT_BOOKMARK_DIMENSION_OPTION) {
        evaluationResults.push(getEvaluvationResult(condition, dimensionValue));
      }else{
        _.each(dimensionWiseData, (datum) => {
          const value = Number(parseFloat(datum['total']).toFixed(precision));
          evaluationResults.push(getEvaluvationResult(condition, value));
        });
      }
    }
  });

  return _.includes(_.flatten(evaluationResults), true)
}

const getEvaluvationResult = (condition, dimensionValue) => {
  let evaluationResults = []
  const operator = _.get(condition, 'operator');
  const monitorValue = Number(_.get(condition, 'value')) || 0;
  if(operator == '%') {
    const value = Number(_.get(condition, 'value')) || 0;
    const maxValue = Number(_.get(condition, 'percentValue')) || 0;
    const percentValue = (value/100) * maxValue;
    const abovePercentValue = (maxValue + percentValue);
    const belowPercentValue = (maxValue - percentValue);
    evaluationResults.push(((dimensionValue > belowPercentValue) && (dimensionValue < abovePercentValue)));
  }else {
    evaluationResults.push(eval(`${dimensionValue} ${operator} ${monitorValue}`));
  }
  return evaluationResults;
}

export const getBenchmarkAxisRangeValues = (benchMarkEntry) => {
  if(_.isEmpty(benchMarkEntry)) {
    return []
  }
  const benchMarksRange = _.range(0, NO_OF_BENCH_MARKS);
  const benchMarkValueKeys = _.map(benchMarksRange,  (benchMarkIndex) => {
    return benchMarkIndex > 0 ? `value${benchMarkIndex}`: 'value';
  });
  return _.values(_.pick(benchMarkEntry, benchMarkValueKeys));
}

const getEnabledMonitorConditions = (bookmarkOptions, benchMarkEntries) => {
  const monitorConditions = _.get(bookmarkOptions, 'monitorConditions', []);
  let enabledMonitorConditions = _.filter(monitorConditions, 'enable');
  enabledMonitorConditions = _.filter(enabledMonitorConditions, (condition) => {
    return condition['type'] != 'records';
  });
  if(_.isEmpty(benchMarkEntries)) {
    enabledMonitorConditions = _.filter(enabledMonitorConditions, (condition) => {
      return condition['type'] != 'benchmark';
    });
  }

  enabledMonitorConditions =_.compact(_.map(enabledMonitorConditions, (monitor) => {
    if(monitor.type === 'benchmark'){
       if(_.some(benchMarkEntries, { 'name': monitor.benchmarkName }) ){
          return monitor
       }
    } else {
      return monitor
    }
  }));
  return enabledMonitorConditions;
}

export const getTransformedCardEntry = (cardEntry, dimensionWiseData, benchmarkValues) => {
  if(_.isEmpty(dimensionWiseData)){
    return cardEntry;
  }
  const clonedCardEntry = _.cloneDeep(cardEntry);
  const { drilldown, bookmarkOptions } = clonedCardEntry;
  const { currentDrilldownTemplateId } = drilldown;
  const dimensionField = _.get(bookmarkOptions, 'dimensionOption');
  const quickFilters = _.get(clonedCardEntry, 'drilldown.quickFilters', []);
  const filteredDimensions = getEvaluatedDimesions(
    bookmarkOptions, dimensionWiseData, benchmarkValues
  );
  // if there is no filtered dimensions there wont be any filters
  if (_.isEmpty(filteredDimensions)){
    return cardEntry;
  }
  const templateEntry = getCurrentTemplateEntryById(
    currentDrilldownTemplateId
  );
  const dimensionFilteredEntry = getBookmarkDimesionFilterEntry(templateEntry, dimensionField);
  if (_.isEmpty(quickFilters)){
    const customQuickFilters = [{
      column: dimensionFilteredEntry['column'],
      type: dimensionFilteredEntry['renderType'],
      field: dimensionFilteredEntry['field'],
      conditions: [{ operator: '=', value: filteredDimensions }]
    }]
    clonedCardEntry['drilldown']['quickFilters'] = customQuickFilters;
  }else{
    _.each(quickFilters, (filterEntry) => {
      if(filterEntry['field'] === dimensionFilteredEntry['field']){
        const operators = _.map(filterEntry['conditions'], (condition) => {
          return condition['operator']
        });
        if (_.includes(operators, '=')){
          _.each(filterEntry['conditions'], (condition) => {
            if(_.isEqual(condition['operator'], '=')){
              condition['value'] = _.uniq(condition['value'].concat(filteredDimensions));
            }
          });
        }else{
          filterEntry['conditions'].push(
            { operator: '=', value: filteredDimensions }
          )
        }
      }
    })
    clonedCardEntry['drilldown']['quickFilters'] = quickFilters;
  }
  return clonedCardEntry;
}

const getEvaluatedDimesions = (bookmarkOptions, dimensionWiseData, benchMarkEntries) =>{
  // let dimensionOption = _.get(bookmarkOptions, 'dimensionOption', DEFAULT_BOOKMARK_DIMENSION_OPTION);
  const enabledMonitorConditions = getEnabledMonitorConditions(bookmarkOptions, benchMarkEntries)
  const dimensionField = _.get(bookmarkOptions, 'dimensionOption');
  let filteredDimensions = [];
  _.each(enabledMonitorConditions, (condition) => {
    _.each(dimensionWiseData, (datum) => {
      const value = Number(parseFloat(datum['total']));
      if (getEvaluvationResult(condition, value)[0]){
        filteredDimensions.push(datum[dimensionField]);
      }
    });
  })
  return _.uniq(filteredDimensions);
}

const getBookmarkDimesionFilterEntry = (temaplateEntry, dimensionField) => {
  const dimensionEntries = _.get(temaplateEntry, 'dimension_entries', []);
  const groupByEntries = _.get(temaplateEntry, 'group_by_entries', []);
  const dimesionEntry = _.find(dimensionEntries, (entry) => {
    return entry['field'] == dimensionField
  });
  const matchedGroupByEntry = _.find(groupByEntries, (entry) => {
    return entry['column'] == dimesionEntry['column']
  });
  return _.isEmpty(matchedGroupByEntry)? dimesionEntry : matchedGroupByEntry;
}

export const getMonitorConditionName = (monitorCondition, bookmark) => {
  const currentDrilldownViewEntry = _.get(bookmark, 'drilldown.currentDrilldownViewEntry');
  const currentDrilldownTemplateId = _.get(bookmark, 'drilldown.currentDrilldownTemplateId');
  const benchMarkName =  _.get(monitorCondition, 'benchmarkName','');
  const name = getConditionDisplayName(currentDrilldownViewEntry, benchMarkName, monitorCondition);
  const value = getMonitorConditionValue(monitorCondition);
  const dimensionName = getDimensionNameFromBookmark(currentDrilldownTemplateId, bookmark)
  const percentageName = getPercentageNameFromMonitorCondition(monitorCondition);

  return `${name} ${value} ${percentageName} for ${dimensionName}.`;
}

const getMonitorConditionValue = (monitorCondition) => {
  const { operator, type, value } = monitorCondition;

  return (operator != '%' && type == 'benchmark') || type == 'records' ? '' : value;
}

const getPercentageNameFromMonitorCondition = (monitorCondition) => {
  const { operator, benchmarkName, type, percentValue } = monitorCondition;
  if (operator != '%') {
    return '';
  }
  const percentName = (type == 'benchmark') ? benchmarkName : percentValue;

  return `percent of ${percentName}`;
}

const getDimensionNameFromBookmark = (templateId, bookmark) => {
  const dimensionOption = _.get(
    bookmark,
    'bookmarkOptions.dimensionOption',
    DEFAULT_BOOKMARK_DIMENSION_OPTION
  );
  let dimensionOptions = [
    {'type': DEFAULT_BOOKMARK_DIMENSION_OPTION, name: 'all selected data'}
  ];
  const dimensionsEntries = getDimensionEntries(templateId);
  _.each(dimensionsEntries, (entry) => {
    dimensionOptions.push({
      type: entry.field,
      name: entry.name
    })
  });
  const { name: dimensionName } = _.find(dimensionOptions, { type: dimensionOption }) || {};

  return dimensionName;
}

export const isMonitorEnabled = (bookmark) => {
  const { bookmarkOptions, drilldown, visualization, email_strategy } = bookmark;
  let monitorConditions = _.get(bookmarkOptions, 'monitorConditions', []);
  const bookmarkNames = getBenchMarkName(drilldown, visualization);

  if(!_.isEqual(EMAIL_STRATEGY_TYPES.THRESHOLD, email_strategy)){
     return false;
  }

  if (
    _.isEmpty(bookmarkNames) ||
    _.isEqual(DEFAULT_DROPDOWN_OPTION.name, bookmarkNames)
  ) {
    monitorConditions = _.filter(monitorConditions, (condition) => {
      return condition.type != 'benchmark' && condition.type != 'records';
    });
  }
  return _.some(monitorConditions, (condition) => {
    return condition.enable;
  });
};

export const isMonitorReached = ({
  currentDimensionTotal,
  benchmarkValues,
  dimensionWiseData,
  bookmark
}) => {
  const { bookmarkOptions, drilldown, visualization } = bookmark;
  const { currentDrilldownViewEntry } = drilldown;
  const currentVizChartType = _.get(bookmarkOptions, 'currentVizChartType', []);

  try {
    if (isMonitorEnabled(bookmark) && !_.isNull(currentDimensionTotal)) {
      const benchMarkEntries = getBenchMarkEntries(
        drilldown,
        visualization,
        currentVizChartType
      );
      return isMonitorConditionSuccess(
        bookmarkOptions,
        currentDimensionTotal,
        benchMarkEntries,
        benchmarkValues,
        currentDrilldownViewEntry,
        dimensionWiseData
      );
    } else {
      return false;
    }
  } catch (e) {
    console.log('bookmark monitor eval failed:', e);
    return false;
  }
}

export const getDateRangeBasedOnMonitor = (bookmark) => {
  const { bookmarkOptions, commonFilters } = _.cloneDeep(bookmark);
  let { relativeDateFilterEntry, dateRange, dateType } = commonFilters;
  const isDateRelativeFilterAdded =
    _.get(relativeDateFilterEntry, 'isRelativeFilter', 'false') == 'true';

  if (_.isEmpty(dateType)) {
    dateType = isDateRelativeFilterAdded ? 'relative' : getDefaultDateMode();
  }

  if (dateType == 'relative') {
    dateRange = getRelativeFilterDateRange(relativeDateFilterEntry);
  } else if (_.get(bookmarkOptions, 'isRelativeDateSelected', false)) {
    dateRange = {
      ...dateRange,
      endDate: getConfiguredDataEndDate().format(DATE_FORMAT),
    };
  }

  return dateRange;
}

export const formatDrilldownParams = (drilldownOptions) => {
  const { drilldown, currentDrilldownViewEntry } = drilldownOptions
  const drilldownWithoutViewEntry = drilldown;
  drilldownWithoutViewEntry['currentViewEntryField'] = _.get(
    currentDrilldownViewEntry,
    'field'
  );

  return drilldownWithoutViewEntry;
};
