import { checkImageExists } from "common/api/advanceSearchApi";
import { buildQueryString } from "helpers/HttpHelper";

export default class PropertyImageHandler {
  static async fetchPropertyPhoto(flyoutDetails) {
    try {
      const data = await checkImageExists(flyoutDetails[0]);
        return data?.result?.IsPhotoAvailable
    } catch (error) {
      console.error('Error checking external image', error);
    }

    return '';
  }

  static async getPropertyPhotoHtml(flyoutDetails) {
    const result = await this.fetchPropertyPhoto(flyoutDetails);
    return result ? this.getPropertyPhoto(_.first(flyoutDetails)) : '';
  }

  static getPropertyPhoto(flyoutDetails) {
    const countryName = _.get(flyoutDetails, 'comp_finder_country', '').replace(/County/i, "").trim();
    if (_.isEmpty(countryName) || !_.get(flyoutDetails, 'is_property_details_available', false)) {
      return '';
    }

    const queryParams = {
      country: countryName,
      quickRefId: _.get(flyoutDetails, 'comp_finder_quick_ref_id', ''),
      taxYear: _.get(flyoutDetails, 'comp_finder_tax_year', '')
    };
    const imgSrc = `/api/advanced_search/get_external_image.png?${buildQueryString(queryParams)}`;

    return (
      "<div class='external_image'>" +
        `<img src=${imgSrc}></img>` +
      "</div>"
    );
  }
}
