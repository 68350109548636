import _ from 'lodash';
import {
  VISUALIZATION_TYPES,
  LINE_CHART_COMPARE_TRACE_COLORS,
  LINE_CHART_TOTAL_TRACE_COLOR
} from 'appConstants';

import { getConfig   } from './customerConfiguration';
import { getCurrentTemplateEntry } from './templateConfiguration';

const metricColorConfigs = {
  primary: {
    app_default: 'default_primary_color',
    custom: 'custom_primary_color',
    config_option_key: 'primary_color_config_option'
  },
  secondary: {
    app_default: 'default_secondary_color',
    custom: 'custom_secondary_color',
    config_option_key: 'secondary_color_config_option'
  },
  range: {
    app_default: 'default_range_bar_color',
    custom: 'custom_range_bar_color',
    config_option_key: 'range_bar_color_config_option'
  }
}

export const shouldShowPieChart = (viewEntry) => {
  const enablePiechart = _.get(viewEntry, 'visualization.snapshot.show_pie_chart', 'false');
  return (enablePiechart == 'true');
}

export const shouldShowRangeChart = (viewEntry) => {
  return _.get(viewEntry, 'visualization.snapshot.show_range_chart', 'false') == 'true';
}

export const showAnnotationValueInBar = (viewEntry) => {
  return (_.get(viewEntry, 'visualization.snapshot.show_values', 'false') === 'true');
}

export const shouldShowAreaChart = (viewEntry) => {
  const enableChart = _.get(viewEntry, 'visualization.overtime.show_area_chart', 'false');
  return (enableChart == 'true');
}

export const shouldShowBurnUpChart = (viewEntry) => {
  const enableChart = _.get(viewEntry, 'visualization.overtime.show_burn_up_chart', 'false');
  return (enableChart == 'true');
}

export const isRateOfChangeEnable = (viewEntry, chartType) => {
  const enableRateOfChange = _.get(
    viewEntry, `visualization.overtime.${chartType}.show_change_rate`, 'false');
  return (enableRateOfChange == 'true');
}

export const getSnapshotChartType = (viewEntry) => {
  return _.get(viewEntry, 'visualization.snapshot.chart_type', 'barChart');
}

export const getDefaultVisualizationView = (viewEntry) => {
  return _.get(viewEntry, 'visualization.default_view', VISUALIZATION_TYPES.SNAPSHOT.type);
}

export const getDistributionBucketEntries = (viewEntry) => {
  return _.get(viewEntry, 'visualization.distribution.distribution_entry', {});
}

export const getDistributionBucketEntry = (viewEntry) => {
  const distributionEntries = getDistributionBucketEntries(viewEntry);
  return _.get(distributionEntries, 'buckets', [])
}

export const showDimensionsInDistribution = (viewEntry) => {
  return _.get(viewEntry, 'visualization.distribution.show_dimensions') === 'true';
}

export const getDefaultGroupByColumn = (viewEntry) => {
  return _.get(viewEntry, 'visualization.snapshot.default_group_by_entry_column', '');
}

export const getDefaultSortBy = (viewEntry) => {
  return _.get(viewEntry, 'visualization.snapshot.default_sort_display_by', '');
}

export const getDefaultGroupSortBy = (viewEntry) => {
  return _.get(viewEntry, 'visualization.snapshot.default_group_by_sort', '');
}

export const showTotalTraces = (currentViewEntry, chartType) => {
  return _.get(currentViewEntry, `visualization.overtime.${chartType}.show_total`, 'true') === 'true';
}

export const showDimensionsTraces = (currentViewEntry, chartType) => {
  return _.get(currentViewEntry, `visualization.overtime.${chartType}.show_dimensions`, 'false') === 'true';
}

export const isShowProjectionDefault = (currentViewEntry, chartType) => {
  return _.get(currentViewEntry,
    `visualization.overtime.${chartType}.default_show_projection`, 'false') === 'true';
}

export const getDefaultCompareToYear = (currentViewEntry) => {
  return _.get(currentViewEntry, `visualization.overtime.default_compare_year`, '');
}

export const getCustomCompareToYears = (currentViewEntry) => {
  return _.get(currentViewEntry, `visualization.overtime.custom_years`, []);
}

export const isComboChartEnable = (currentViewEntry, chartType) => {
  return _.get(currentViewEntry, `visualization.overtime.${chartType}.is_combo_chart`, 'false') === 'true';
}

export const isShowRangeSlider = (isComboChart) => {
  const defaultValue = isComboChart ? 'false' : 'true';
  return _.get(getConfig(), 'show_range_slider', defaultValue) == 'true'
}

export const rangeSliderType = () => {
  return _.get(getConfig(), 'range_slider_type', 'simple')
}

export const getMapTypes = (viewEntry) => {
  return _.get(viewEntry, 'visualization.map.map_types', []);
}

export const isCustomColorConfigsForMetric = (templateEntry, configType) => {
  const configKey = _.get(metricColorConfigs, [configType, 'config_option_key']);
  return (_.get(templateEntry, configKey, null) === 'custom');
}

export const getConfiguredMetricColor = (templateId = null, configType = 'primary') => {
  const defaultColor = _.get(getConfig(), _.get(metricColorConfigs, [configType, 'app_default']));
  const currentTemplateEntry = getCurrentTemplateEntry(templateId);
  const isCustomColorConfigs = isCustomColorConfigsForMetric(currentTemplateEntry, configType);

  if(_.isNil(templateId) || !isCustomColorConfigs) {
    return getValidColorFromGivenPallet(defaultColor, configType);
  }

  const customColor = _.get(currentTemplateEntry, _.get(metricColorConfigs, [configType, 'custom']));
  const metricColor = !_.isEmpty(customColor) ? customColor : defaultColor;
  return getValidColorFromGivenPallet(metricColor, configType);
};

const getValidColorFromGivenPallet = (colorCode, configType) => {
  const colorCodes = _.map(LINE_CHART_COMPARE_TRACE_COLORS, 'color');
  const isPrimaryMetric = (configType === 'primary')
  return !_.includes(colorCodes, colorCode) && isPrimaryMetric ? LINE_CHART_TOTAL_TRACE_COLOR :
           colorCode;
}
