import React, {Component} from 'react';

import BookmarkApiHelper from 'pages/SubscriptionsManager/components/Bookmark/BookmarkApiHelper';
import { getSubScriptionBookMarks } from 'common/api/bookmarksApi';
import {
  isMonitorReached,
  isMonitorEnabled,
  getTransformedCardEntry
} from 'pages/SubscriptionsManager/components/Bookmark/helper';

export default function SubscriptionBookmarkWrapper(HocComponent){
  return class extends Component {
    constructor(props) {
      super(props);
      this.abortFetchController = new AbortController();
      this.state = {
        isLoading: false,
        bookmarks: [],
        isOpen: false,
      };
    }

    componentDidMount() {
      this.fetchSubscriptionBookmarks();
    }

    componentWillUnmount() {
      this.abortFetchController.abort();
    }

    setOpen = (isOpen) => {
      this.setState({ isOpen }, () => {
        if(isOpen) {
          this.fetchSubscriptionBookmarks();
        }
      });
    }

    getMonitorConditionPromises = (bookmarks) => {
      const filteredBookmarks = _.filter(bookmarks, (bookmark) => isMonitorEnabled(bookmark));

      return _.map(filteredBookmarks, (bookmark) => {
        const bookmarkApiHelper = new BookmarkApiHelper(bookmark);
        return bookmarkApiHelper.fetchMetricTotal();
      });
    }

    getMonitorReachedBookmarks = (bookmarks) => {
      return _.chain(bookmarks).
        map((bookmarksItem) => {
          const {
            bookmark, benchmarkValues, dimensionWiseData, total: currentDimensionTotal
          } = bookmarksItem;
          const monitorReachedOptions = {
            bookmark, benchmarkValues, dimensionWiseData, currentDimensionTotal };
          const transformedCardEntry = getTransformedCardEntry(bookmark, dimensionWiseData, benchmarkValues);

          return isMonitorReached(monitorReachedOptions) ? transformedCardEntry : null;
        }).
        without(null).
        value();
    }

    setMonitorReachedBookmarks = (bookmarks) => {
      Promise.all(this.getMonitorConditionPromises(bookmarks)).then((results) => {
        const monitorReachedBookmarks = this.getMonitorReachedBookmarks(results);
        this.setState({ bookmarks: monitorReachedBookmarks, isLoading: false });
      });
    }

    fetchSubscriptionBookmarks = () => {
      this.abortFetchController.abort();
      this.abortFetchController = new AbortController();

      this.setState({ isLoading: true });

      getSubScriptionBookMarks({ signal: this.abortFetchController.signal })
        .then((response) => response.json())
        .then((bookmarks) => {
          this.setMonitorReachedBookmarks(bookmarks);
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log('Error on fetching subscription bookmarks', error);
        });
    }

    render(){
      const { bookmarks, isLoading } = this.state;

      return (
        <HocComponent
          {...this.props}
          isLoading={isLoading}
          setNotificationPanelOpen={this.setOpen}
          subscriptionBookmarks={bookmarks}
        />
      );
    }
  }
}
