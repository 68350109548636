// Vendor Imports
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import TargetEntryModal from './TargetEntryModal';
import { canUserEditAbsoluteTargetEntry } from 'common/config/viewConfiguration';
import TargetEntryContext from "context/TargetEntryContext";
import * as commonPropTypes from 'common/propTypes';
import { checkWGAColorContrast } from 'helpers/colorHelper';
import { ForgeIcon } from '@tylertech/forge-react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const TargetValueSelector = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { seeMoreLabelText, targetStatusDisable, isSmallView, viewEntry, templateId, currentUser } = props;
  const targetEntry = _.get(props, 'currentTargetEntry');
  const { isLoading } = useContext(TargetEntryContext);

  const onEditClick = () => {
    setShowModal(!showModal);
  }

  const onEditKeyDown = (e) => {
    if (isEnterButtonPressed(e)){
      setShowModal(!showModal);
    }
  }

  const onClose = () => {
    setShowModal(false);
  }

  const renderTargetEntryModal = () => {
    return (
      <TargetEntryModal
        templateId={templateId}
        viewEntry={viewEntry}
        onClose={onClose}
      />
    );
  }

  const renderLargeViewTargetContent = () => {
    if(_.isEmpty(targetEntry) || targetStatusDisable || isLoading) {
      return null;
    }

    if(showModal) {
      return renderTargetEntryModal();
    }

    let targetDescription;
    const iconClassName = `icons-${targetEntry.icon}`;

    if(_.isEmpty(targetEntry.target_entry_description)){
      targetDescription = _.isNaN(Number(targetEntry.value)) ?
        `The target for this metric is ${targetEntry.name}.`:
        `The target for this metric is ${targetEntry.name}. The target is set for ${targetEntry.value}`;
    } else {
      targetDescription =  `${targetEntry.target_entry_description}`;
    }

    const flagContentColor = checkWGAColorContrast(targetEntry.color);

    return(
      <div className="target-value-selector">
        <div className="d-flex">
          <div className="flag align-self-center" style={{background: targetEntry.color}}>
            <div className="target-entry-name" style={{color : flagContentColor}}>
              <i className={iconClassName}></i> {targetEntry.name}
            </div>
          </div>
          <div className="box align-self-center">
            {/*input element always hidden => purpose of using "see more" and "see less" show/hide text.*/}
            <input type="checkbox" disabled aria-hidden="true" id="expanded" />
            <div id="contentHeight" className="status-content">
              {targetDescription}
              {!_.isEmpty(currentUser) && canUserEditAbsoluteTargetEntry(viewEntry) &&
                <ForgeIcon name="mode_edit"
                  tabIndex={0}
                  className="edit-icon"
                  onClick={onEditClick}
                  onkeydown={onEditKeyDown}
                  />
              }
            </div>
            {seeMoreLabelText}
          </div>
        </div>
      </div>
    );
  }

  const renderSmallViewTargetContent = () => {
    if(_.isEmpty(targetEntry)) {
      return null;
    }
    const iconClassName = `icons-${targetEntry.icon}`;
    const flagContentColor = checkWGAColorContrast(targetEntry.color);

    // TODO
    return(
      <div className="flag allign-self-center" style={{background: targetEntry.color}}>
        <div className="target-entry-name" style={{color: flagContentColor}}>
          <i className={iconClassName}></i>{targetEntry.name}</div>
      </div>
    );
  }

  return isSmallView ? renderSmallViewTargetContent() : renderLargeViewTargetContent();
}

TargetValueSelector.defaultProps = {
  isSmallView: false,
  secondaryTotal: '',
  currentUser: {},
}

TargetValueSelector.propTypes = {
  isSmallView: PropTypes.bool,
  secondaryTotal: PropTypes.string,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  templateId: commonPropTypes.templateIdPropTypes,
  currentUser: commonPropTypes.userPropTypes
}

export default TargetValueSelector;
