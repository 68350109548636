import React from 'react';
import PropTypes from 'prop-types';
import { ForgeAppBarMenuButton } from '@tylertech/forge-react';

const ForgeMenuButton = ({ onClick }) => {
  return (<ForgeAppBarMenuButton slot="start" id="omnibar-menu-button" onClick={onClick} />);
}

ForgeMenuButton.propTypes = {
  onClick: PropTypes.func
}

export default ForgeMenuButton;
