import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

class Tabs extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  handleEnterButtonPressed = (e, tab) => {
    if(isEnterButtonPressed(e)) {
      this.props.onTabChange(tab);
    }
  }

  renderTabs(){
    const{ tabs, onTabChange, currentTab } = this.props;

    return tabs.map((tab, index) => {
      const linkClassNames = classNames('link', {
        'active': _.isEqual(tab, currentTab) || _.isEqual(_.get(tab, 'type'), currentTab)
      });

      return(
        <li role="tab" aria-label={_.get(tab, 'name')} className="tab-link pb-0" key={index}>
          <div
            onClick={() => onTabChange(tab)}
            onKeyDown={(e) => this.handleEnterButtonPressed(e, tab)}
            className={linkClassNames}
            tabIndex={0}>
            {_.get(tab, 'name')}
          </div>
        </li>
      );
    });
  }

  render() {
    const { tabs } = this.props;
    if(_.isEmpty(tabs)){
      return null;
    }

    return (
      <div className="d-flex justify-content-between border-bottom align-items-center">
        <div className="tabs-head">
          <ul className="tabs border-bottom-0 mb-0" role="tablist">
            {this.renderTabs()}
          </ul>
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
  })),
  onTabChange: PropTypes.func,
  currentTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    })
  ])
}

export default Tabs;
