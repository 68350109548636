import _ from 'lodash';

import { TIME_OF_WEEKS, TIME_OF_DAYS, DEFAULT_WEEK_KEY } from 'appConstants';

export const formatTimeOfDayFiltersBasedOnWeek = _.memoize((filters, weekKey) => {
  const weekWiseGroupedData = getWeekWiseGroupedData(filters, weekKey);
  return weekWiseGroupedData;
}, (filters) => `${JSON.stringify(filters)}`);


export const getHourText = (hourData) => {
  if(_.isEmpty(hourData)) {
    return null;
  }
  const firstHourDatum = _.first(hourData);
  const lastHourDatum = _.last(hourData);

  return _.size(hourData) > 1 ?
    `${_.get(firstHourDatum, 'long')} - ${_.get(lastHourDatum, 'long')}` :
    _.get(firstHourDatum, 'long');
}

export const getHoursText = (weekData) => {
  return _.compact(_.map(weekData, getHourText)).join(', ');
}

export const getTimeOfDayFiltersText = _.memoize((filters) => {
  const timeOfDayFiltersBasedOnWeek = formatTimeOfDayFiltersBasedOnWeek(filters, DEFAULT_WEEK_KEY);

  return _.map(TIME_OF_WEEKS, (weekItem, index) => {
    const matchedWeekData = _.get(timeOfDayFiltersBasedOnWeek, [weekItem[DEFAULT_WEEK_KEY]]);
    const beforeSpace = index === 0 ? '' : ' ';

    return !_.isEmpty(matchedWeekData) ?
      `${beforeSpace}${weekItem[DEFAULT_WEEK_KEY]}, ${getHoursText(matchedWeekData)}` :
      '';
  });
}, (filters) => `${JSON.stringify(filters)}`);

// Here continuous hours in same array(EX: 1,2). Different hour is new array(EX: 4).
// {
//   "Su":[
//     [
//       {"id":"01", "hour":1, "short":"1a", "long":"1am"},
//       {"id":"02", "hour":2, "short":"2a", "long":"2am"},
//     ],
//     [
//       {"id":"04", "hour":4, "short":"4a", "long":"4am"},
//     ]
//   ],
//   "Mo":[],
//   ....
// }

const getWeekWiseGroupedData = (filters, weekKey) => {
  const groupedWeekData = _.groupBy(filters, 'day');
  const groupByWeekDays = _.groupBy(TIME_OF_WEEKS, 'day');
  const weekWiseGroupedData = {};

  _.each(groupedWeekData, (groupedWeekDatum, weekDay) => {
    const hours = _.map(groupedWeekDatum, 'hour');
    const matchedWeek = _.get(groupByWeekDays, weekDay);
    const weekName = _.get(matchedWeek, [0, weekKey]);
    let index = 0;

    if(!weekWiseGroupedData[weekName]) {
      weekWiseGroupedData[weekName] = [];
    }

    _.each(TIME_OF_DAYS, (day) => {
      if(_.includes(hours, day.hour)) {
        if(!weekWiseGroupedData[weekName][index]) {
          weekWiseGroupedData[weekName][index] = [];
        }

        weekWiseGroupedData[weekName][index].push(day);
      } else {
        index = index + 1;
      }
    });
  });

  return weekWiseGroupedData;
}

export const getNewTimeOfDayFilters = (filters, selectedCells, isToggleSelectedCell) => {
  if(isToggleSelectedCell) {
    let newFilters = [...filters];
    const selectedCell = selectedCells[0];

    const isEntrySelected = _.find(filters, (selectedCellEntry) => {
      return _.isEqual(selectedCellEntry, selectedCell);
    });
    if(isEntrySelected) {
      newFilters = _.filter(filters, (selectedCellItem) => {
        return !_.isEqual(selectedCellItem, selectedCell);
      });
    } else {
      newFilters.push(selectedCell);
    }
    return newFilters;
  } else {
    const newFilters = _.filter(selectedCells, (selectedCellEntry) => {
      return _.isUndefined(_.find(filters, selectedCellEntry));
    });
    return _.isEmpty(selectedCells) ? selectedCells : [...filters, ...newFilters];
  }
}
