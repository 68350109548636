import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import GlobalEvent from 'common/components/GlobalEvents';
import SeeMoreOption from './SeeMoreOption';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { disableMetricTotal } from 'common/config/viewConfiguration';
import {
  VISUALIZATION_TYPES,
  SNAPSHOT_VISUALIZATION_TYPES,
  COMPARE_VIEW_DRILLDOWN_OPTIONS,
  ON_BAR_CLICK,
  SCATTER_PLOT_DATA_LIMIT,
  COMPARE_VIEW_ADDITIONAL_DATA_LIMIT,
  DRILLDOWN_VIEW_TYPE,
  DATE_COMPARISON_TYPE,
  NONE_DIMENSION_FIELD
} from 'appConstants';
import SnapshotOptions from 'pages/drilldown/visualizations/SnapshotOptions';
import SnapshotVisualization from 'modules/visualization/SnapshotVisualization';
import Pagination from 'common/components/Pagination/Pagination';
import { handleOnAfterPlot } from 'modules/visualization/SnapshotVisualization/snapshotAfterPlotHelper';
import { getSnapshotAttributes, getSnapshotOptionsAttributes } from 'helpers/snapshotVizAttrHelper';
import * as snapOptions from 'helpers/snapshotRendererHelper';
import { updateComparisonModeOn } from 'actions/commonFiltersActions';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  getDefaultVisualizationChartView,
  getSecondaryMetricEntry,
  updateBookMarkCardLoadingStatusForImageGenerator
} from 'helpers/visualizationHelper';
import { getSnapshotChartType } from 'common/config/visualizationConfiguration';
import * as snapshotActions from 'actions/snapshotActions';
import VisualizationDescription from 'pages/drilldown/visualizations/VisualizationDescription';
import { isStackTypeChart } from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
import SummaryTable from 'modules/visualization/SnapshotVisualization/SummaryTable';
import {
  getChartSummaryTableFormat
} from 'modules/visualization/SnapshotVisualization/SummaryTable/summaryTableHelper';

import { getDimensionName, getPrimaryMetricName } from 'helpers/displayNameHelper';
import { getGroupSummaryTotals } from 'common/api/drilldown';
import { isChangePropsOrStates } from './helper';
import * as commonPropTypes from 'common/propTypes';
import { isEmptyApiData } from 'modules/visualization/helper';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import { updateDrilldownDimensionField } from 'actions/drilldownActions';
import SnapshotLegends from './SnapshotLegends';
import { isMobileOrTablet, scrollLegendElementToTarget } from 'helpers/DomPageHelper';

const itemsPerPage = 15;
class SnapshotRenderer extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      legends: [],
      totalRowCount: 0,
      apiData: null,
      currentPage: 0,
      chartDataRemainingEntries: 0,
      currentDataLimit: snapOptions.getGroupChartDataLimit(props),
      formattedData: [],
      summaryTotalData: [],
      isOpenLegend: true
    }

    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    const {
      currentSnapshotView,
      currentDrilldownTemplateId,
      currentDrilldownDimensionField,
      currentDrilldownViewEntry,
      currentDrilldownGroupByEntry,
      commonFilters
    } = this.props;
    const snapshotView = getDefaultVisualizationChartView(currentDrilldownViewEntry,
      VISUALIZATION_TYPES.SNAPSHOT.type);
    if (_.isEmpty(currentSnapshotView)) {
      const defaultOptions = {
        currentDrilldownTemplateId,
        currentDrilldownViewEntry,
        currentDrilldownDimensionField,
        currentDrilldownGroupByEntry,
        currentSnapshotView: snapshotView,
        commonFilters
      };

      this.props.dispatchUpdateVisualizationDefaults(defaultOptions);
    }
    this.fetchSummaryTotals();
    GlobalEvent.on(ON_BAR_CLICK, this.handleBarKeyDownEvent);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentDrilldownViewEntry } = this.props;
    const isGroupByViewTypeChanged = !_.isEqual(this.props.currentGroupByViewType,
      prevProps.currentGroupByViewType);
    const isGroupByEntryChanged = !_.isEqual(this.props.currentDrilldownGroupByEntry,
      prevProps.currentDrilldownGroupByEntry);
    if (
      !_.isEqual(this.props.quickFilters, prevProps.quickFilters) ||
      !_.isEqual(currentDrilldownViewEntry, prevProps.currentDrilldownViewEntry)
    ) {
      this.setState({ currentPage: 0 });
    }
    if (!_.isEqual(this.props.currentDrilldownTemplateId, prevProps.currentDrilldownTemplateId) ||
      isGroupByViewTypeChanged || isGroupByEntryChanged) {
      this.setState({
        totalRowCount: 0,
        currentDataLimit: snapOptions.getGroupChartDataLimit(this.props)
      });
    }

    if (!_.isEqual(this.props.currentSnapshotView, prevProps.currentSnapshotView)) {
      this.setState({ formattedData: [] });
    }

    if (isChangePropsOrStates(this.props, this.state, prevProps, prevState)) {
      this.fetchSummaryTotals();
    }
  }

  componentWillUnmount() {
    this.abortFetchController.abort();
    GlobalEvent.off(ON_BAR_CLICK, this.handleBarKeyDownEvent);
  }

  fetchSummaryTotals = () => {
    if (!this.canFetchSummaryTotal()) {
      this.setState({ summaryTableData: [] });
      return;
    }
    const snapshotAttributes = snapOptions.getSnapshotOptions(this.state, this.props);
    const { apiParams } = snapshotAttributes;

    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    getGroupSummaryTotals(apiParams, this.abortFetchController).
      then((response) => {
        this.setState({ isLoading: false, summaryTotalData: response });
      }).catch((err) => {
        this.setState({ isLoading: false });
        if (err.name !== 'AbortError') {
          console.log("Error on fetching summary data ", err)
        }
      });
  }

  handleBarKeyDownEvent = (dimensionName, groupByValue, isSeeMoreGroupDimension = false) => {
    if (!_.isUndefined(dimensionName)) {
      this.props.onBarClick(dimensionName, groupByValue);
    } else if (isSeeMoreGroupDimension) {
      this.props.onBarClick(dimensionName, groupByValue, true);
    }
  }

  // After drilldown from current position, the focused not is active,
  // So this is to remove focus of chart's html node element after drilldown(render).
  handleSetFocus = (currentContainerRef) => {
    if (currentContainerRef && currentContainerRef.contains(document.activeElement)) {
      document.activeElement.blur();
    }
  }


  toggleAddChartValueFlag = () => {
    const { showSnapshotChartValues } = this.props;
    this.props.dispatchToggleAddSnapshotChartValues(!showSnapshotChartValues);
  }

  togglePieChartAnnotationOptions = (key, value) => {
    const { pieChartAnnotationOptions } = this.props;
    this.props.dispatchTogglePieChartAnnotationOptions({
      ...pieChartAnnotationOptions,
      [key]: !value
    });
  }

  handleChartRenderingComplete = (legends) => {
    this.setState({ legends: legends });
  }

  toggleClickLegendButton = (isOpenLegend) => {
    this.setState({ isOpenLegend: isOpenLegend });
    scrollLegendElementToTarget(isOpenLegend);
  }

  handleChartRemainingRowData = (rowCount, pageDataLimit, chartDataRemainingEntries) => {
    const { currentGroupByViewType, dimensionRowCount, currentDrilldownViewEntry } = this.props;
    const remainingRowCount = (rowCount - pageDataLimit);
    const totalRowCount = _.isUndefined(chartDataRemainingEntries) ?
      remainingRowCount : chartDataRemainingEntries;
    const currentGroupByViewOption = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: currentGroupByViewType });
    const isGroupChart = (getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart');
    const viewRowCount = isGroupChart ? _.get(currentGroupByViewOption, 'rowCount', 0) : itemsPerPage;
    if (dimensionRowCount > viewRowCount) {
      this.setState({
        rowCount,
        totalRowCount: (dimensionRowCount - viewRowCount),
        chartDataRemainingEntries: totalRowCount
      });
    } else {
      this.setState({ rowCount, totalRowCount, chartDataRemainingEntries: totalRowCount });
    }
  }

  handleSwitchPage = (switchDirection) => {
    let { currentPage } = this.state;
    if (switchDirection === 'next') {
      currentPage += 1
    } else {
      currentPage -= 1
    }

    this.setState({ currentPage });
  }

  handleSnapshotViewChange = (selectedView) => {
    const {
      currentDrilldownDimensionField,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      currentDrilldownGroupByEntry,
      isComparisonEnabled
    } = this.props;

    const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
    const defaultOptions = {
      currentSnapshotView: selectedView,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      currentDrilldownDimensionField: currentDrilldownDimensionField == NONE_DIMENSION_FIELD ?
        _.first(dimensionsEntries)['field'] : currentDrilldownDimensionField,
      currentDrilldownGroupByEntry
    };

    this.props.dispatchUpdateVisualizationDefaults(defaultOptions);
    if (currentDrilldownDimensionField == NONE_DIMENSION_FIELD) {
      this.props.dispatchUpdateDrilldownDimensionField(_.first(dimensionsEntries)['field']);
    }

    if (isComparisonEnabled) {
      this.props.dispatchUpdateSecondaryMetricField();
    }
  }

  handleSecondaryMetricChange = (currentSecondaryMetricEntry) => {
    this.props.dispatchUpdateSecondaryMetricField(_.get(currentSecondaryMetricEntry, 'field', ''));
    this.props.dispatchUpdateComparisonModeOn(false);
  }

  handleBarChartSortOptionChange = (option) => {
    this.props.dispatchUpdateBarChartSortType(_.get(option, 'type', ''));
  }

  handleGroupBySortOptionChange = (option) => {
    this.props.dispatchUpdateGroupChartSortType(_.get(option, 'type', ''));
  }

  handleDimensionSortOptionChange = (option) => {
    this.props.dispatchUpdateSnapshotDimensionSortType(_.get(option, 'type', ''));
  }

  handleBenchMarkChange = (currentBenchMarkEntries) => {
    const benchmarkNames = _.map(currentBenchMarkEntries, 'name');
    this.props.dispatchUpdateBenchMarkName(benchmarkNames);
  }

  onApiDataLoad = (rowCount, dataEntry, chartDataRemainingEntries) => {
    const { currentDataLimit } = this.state;
    const { currentSnapshotView } = this.props;
    const isScatterPlot = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type);
    const pageDataLimit = isScatterPlot ? currentDataLimit : itemsPerPage;

    if (rowCount || !_.isUndefined(chartDataRemainingEntries)) {
      this.handleChartRemainingRowData(rowCount, pageDataLimit, chartDataRemainingEntries);
    }

    if (dataEntry) {
      this.skipToNextLevel(dataEntry);
    }
  }

  onFormattedDataLoad = (plotlyFormattedData) => {
    this.setState({
      formattedData: plotlyFormattedData
    });
  }

  onNewApiData = (apiData) => {
    this.setState({ apiData });
  }

  onDataLoading = (isLoading) => {
    updateBookMarkCardLoadingStatusForImageGenerator(isLoading);
  };

  handleBarClick = (event) => {
    const {
      onBarClick, currentGroupByChartApproach, currentDrilldownViewEntry, currentDrilldownTemplateId
    } = this.props;
    const currentSecondaryMetricEntry = this.getCurrentSecondaryMetricEntry();
    const {
      dimensionName,
      groupByValue,
      isBarClickable,
      isSeeMoreGroupDimension } = snapOptions.getBarClickAttributes(event, {
        groupType: currentGroupByChartApproach,
        viewEntry: currentDrilldownViewEntry,
        templateId: currentDrilldownTemplateId,
        secondaryMetricEntry: currentSecondaryMetricEntry
      });

    if (_.isFunction(onBarClick) && isBarClickable) {
      onBarClick(dimensionName, groupByValue, isSeeMoreGroupDimension);

      this.setState({ currentPage: 0 });
    }
  }

  skipToNextLevel = (chartEntry) => {
    const {
      onBarClick,
      canSkipHierarchy,
      currentGroupByChartApproach,
      currentSnapshotView
    } = this.props;
    const isCompareView = (currentGroupByChartApproach === DRILLDOWN_VIEW_TYPE[0].type);
    const isBarChart = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
    const shouldSkipHierarchy = isBarChart ? (canSkipHierarchy && isCompareView) : canSkipHierarchy;
    const dimensionValue = _.get(chartEntry, 'dimension', '');

    if (shouldSkipHierarchy) {
      onBarClick(dimensionValue);
    }
  }

  handleOnAfterPlot = (currentContainerRef) => {
    const { currentSnapshotView, currentDrilldownViewEntry } = this.props;
    const currentSecondaryMetricEntry = this.getCurrentSecondaryMetricEntry();

    const isGroupChart = (getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart');
    const options = {
      currentSnapshotView,
      chartType: isGroupChart ? 'groupChart' : 'barChart',
      secondaryMetricEntry: currentSecondaryMetricEntry,
      viewEntry: currentDrilldownViewEntry
    }
    this.handleSetFocus(currentContainerRef);
    handleOnAfterPlot(currentContainerRef, options);
  }

  handleSeeMoreClick = () => {
    const { currentDataLimit } = this.state;
    const { currentDrilldownViewEntry } = this.props;
    const isGroupChart = (getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart');
    const defaultDataLimit = (isGroupChart ? COMPARE_VIEW_ADDITIONAL_DATA_LIMIT : SCATTER_PLOT_DATA_LIMIT);

    this.setState({
      currentDataLimit: currentDataLimit + defaultDataLimit,
      currentPage: 0
    })
  }

  canFetchSummaryTotal = () => {
    const { currentDrilldownViewEntry, currentDrilldownGroupByEntry } = this.props;
    const currentSecondaryMetricEntry = this.getCurrentSecondaryMetricEntry();

    const isStackedChart = isStackTypeChart({ secondaryMetricEntry: currentSecondaryMetricEntry });
    const isGroupChart = (getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart');
    const isGroupByNone = _.get(currentDrilldownGroupByEntry, 'name') === 'None'

    return ((isGroupChart && !isGroupByNone) || isStackedChart);
  }

  getCurrentSecondaryMetricEntry = () => {
    const {
      currentDrilldownViewEntry,
      currentVisualizationType,
      currentSnapshotView,
      currentSecondaryMetricField
    } = this.props;

    return getSecondaryMetricEntry(
      currentDrilldownViewEntry,
      currentVisualizationType,
      currentSnapshotView,
      currentSecondaryMetricField
    );
  }

  handlePieSliceClick = (event) => {
    const { onBarClick } = this.props;
    // Remove focus highlighting when click other slice in pie chart.
    if (event.label === "Others") {
      document.activeElement.blur();
    }

    // TODO: Others is the added bar into the list
    if (_.isFunction(onBarClick) && event.label != "Others") {
      onBarClick(event.label);
    }
  }

  handleKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleSeeMoreClick();
    }
  }

  checkLegendOption = (propsOption) => {
    const { legends, isBulletBarChart, benchMarkEntries } = propsOption;
    const isNotLegend = (_.isEmpty(legends) || isBulletBarChart);
    const isNotBenchmark = (_.isEmpty(benchMarkEntries) && !isBulletBarChart);

    return !(isNotBenchmark && isNotLegend);
  }

  getLegendOptions = (snapshotOptionsAttributes) => {
    const { isOpenLegend } = this.state;
    const {
      legends,
      dimensionName,
      isPieChart,
      isBulletBarChart,
      isBarChart,
      isStackBarChart,
      isRangeChart,
      isCurrencyDimensionField,
      benchMarkEntries,
      showLegend,
    } = snapshotOptionsAttributes

    return {
      toggleClickLegendButton: this.toggleClickLegendButton,
      legends,
      dimensionName,
      isPieChart,
      isBulletBarChart,
      isBarChart,
      isStackBarChart,
      isRangeChart,
      isCurrencyDimensionField,
      benchMarkEntries,
      isMobileView: isMobileOrTablet(),
      fromMobileView: true,
      showLegend,
      isOpenLegend
    }
  }

  getPieChatSummaryTableFormat() {
    const { legends } = this.state;
    const {
      currentDrilldownTemplateId,
      currentDrilldownDimensionField,
      currentDrilldownViewEntry
    } = this.props;

    const dimensionName = getDimensionName(currentDrilldownTemplateId, currentDrilldownDimensionField);
    const primaryMetricName = getPrimaryMetricName(currentDrilldownViewEntry);

    let tableHeaders = [
      { name: dimensionName, columnField: "dimension" },
      { name: primaryMetricName, columnField: "dimensionValue" },
      { name: "Percent", columnField: "percent" }];

    return {
      tableHeaders,
      tableData: legends
    }
  }

  renderSeeMoreLink() {
    const stateOptions = _.pick(
      this.state,
      ['rowCount', 'currentDataLimit', 'totalRowCount', 'chartDataRemainingEntries']
    );
    const propsOptions = _.pick(this.props, [
      'currentSnapshotView',
      'currentDrilldownGroupByEntry',
      'currentDrilldownViewEntry',
      'currentGroupByViewType'
    ]);
    return <SeeMoreOption
      handleKeyDown={this.handleKeyDown}
      handleClickSeeMore={this.handleSeeMoreClick}
      stateOptions={stateOptions}
      propsOptions={propsOptions} />
  }

  renderPagination() {
    const { currentPage, rowCount, apiData } = this.state;
    const { isLeafPage, currentSnapshotView, currentDrilldownViewEntry } = this.props;
    const isBarChart = (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
    const isGroupChart = (getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart');
    const snapshotAttributes = snapOptions.getSnapshotOptions(this.state, this.props);
    const { apiParams } = snapshotAttributes;
    const isEmptyData = isEmptyApiData(apiData, { apiParams });

    if (isLeafPage || !isBarChart || isGroupChart || isEmptyData) {
      return null;
    }

    return (
      <Pagination
        currentPage={currentPage + 1}
        itemsPerPage={itemsPerPage}
        totalRecordsCount={rowCount}
        switchPage={this.handleSwitchPage} />
    );
  }

  renderSummaryTable() {
    const summaryTableOptions = snapOptions.getSnapshotSummaryOptions(this.state, this.props);
    const { drillDownTotal, currentDrilldownTemplateId, isEmbed } = this.props;
    const { formattedData, summaryTotalData } = this.state;
    const { isScatterPlot, isBarChart, isPieChart } = summaryTableOptions;

    if (isEmbed) {
      return null;
    }

    let summaryTableData;
    if (isPieChart) {
      summaryTableData = this.getPieChatSummaryTableFormat();
    } else if (isScatterPlot || isBarChart) {
      summaryTableData = getChartSummaryTableFormat(
        summaryTableOptions, formattedData, summaryTotalData, drillDownTotal);
    }

    if (_.isEmpty(summaryTableData)) {
      return null;
    }

    return (
      <SummaryTable
        drillDownTotal={drillDownTotal}
        tableHeaders={summaryTableData.tableHeaders}
        tableData={summaryTableData.tableData}
        summaryTableOptions={summaryTableOptions}
        currentDrilldownTemplateId={currentDrilldownTemplateId} />
    );
  }

  renderChartView() {
    const {
      onBarClick, isEmbed, currentSnapshotView, currentDrilldownViewEntry, metricTotalLoading
    } = this.props;

    const disabledTotal = disableMetricTotal(currentDrilldownViewEntry);
    const isPieChart = currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type;

    if (metricTotalLoading && isPieChart && !disabledTotal) {
      return (<LoadingSpinner isLoading={metricTotalLoading} />)
    }

    let currentVisualizationAttributes = getSnapshotAttributes({
      handleGroupBarChartClick: this.handleGroupBarChartClick,
      onApiDataLoad: this.onApiDataLoad,
      onFormattedDataLoad: this.onFormattedDataLoad,
      afterRender: this.handleChartRenderingComplete,
      onAfterPlot: this.handleOnAfterPlot,
      onBarClick: onBarClick,
      onNewApiData: this.onNewApiData,
      onPieSliceClick: this.handlePieSliceClick,
      handleBarClick: this.handleBarClick,
      skipToNextLevel: this.skipToNextLevel,
      onDataLoading: this.onDataLoading,
      isBarClickable: snapOptions.isBarClickable,
      ...(snapOptions.getSnapshotOptions(this.state, this.props))
    });
    currentVisualizationAttributes['isEmbed'] = isEmbed;

    return (<SnapshotVisualization {...currentVisualizationAttributes} />);
  }

  renderLegendForMobileView = (snapshotOptionsAttributes) => {
    const { isOpenLegend } = this.state;

    if (isOpenLegend && !isMobileOrTablet()) {
      return null;
    }

    const legendOptions = this.getLegendOptions(snapshotOptionsAttributes);

    return (
      <SnapshotLegends legendOption={legendOptions} />
    )
  }

  render() {
    const { totalRowCount, isOpenLegend } = this.state;
    const snapshotOptionsAttributes = {
      onSnapshotViewChange: this.handleSnapshotViewChange,
      onScatterPlotSecondaryMetricChange: this.handleSecondaryMetricChange,
      onBarChartSecondaryMetricChange: this.handleSecondaryMetricChange,
      onBarChartSortOptionChange: this.handleBarChartSortOptionChange,
      onGroupBySortOptionChange: this.handleGroupBySortOptionChange,
      onDimensionSortOptionChange: this.handleDimensionSortOptionChange,
      onDrilldownViewTypeChange: this.handleDrilldownViewTypeChange,
      onBenchmarkOptionChange: this.handleBenchMarkChange,
      onAddChartValueFlagChange: this.toggleAddChartValueFlag,
      togglePieChartAnnotationOptions: this.togglePieChartAnnotationOptions,
      toggleClickLegendButton: this.toggleClickLegendButton,
      ...getSnapshotOptionsAttributes(this.state, this.props)
    }
    const { showLegend } = snapshotOptionsAttributes;
    const vizClassNames = classNames('chartContainer viz-wrapper', {
      'legend-container': showLegend && !isMobileOrTablet()
    });

    const isAddLegendClass = this.checkLegendOption(snapshotOptionsAttributes);
    const vizWrapperClass = classNames('visualization-wrapper', {
      'visualization-width': isOpenLegend && isAddLegendClass && !isMobileOrTablet()
    });

    // TODO: If chart type is bar chart and if stack, send legend details.
    return (
      <div className={vizClassNames}>
        <SnapshotOptions {...snapshotOptionsAttributes} />
        <div className={vizWrapperClass}>
          <div className="visualization-lside">
            <div className="chart-view">
              {this.renderChartView()}
              {this.renderPagination()}
              {this.renderSeeMoreLink()}
              {isMobileOrTablet() && this.renderLegendForMobileView(snapshotOptionsAttributes)}
            </div>

            <div className="visualization-footer">
              <VisualizationDescription totalRowCount={totalRowCount} />
            </div>
          </div>
        </div>
        {this.renderSummaryTable()}
      </div>
    )
  }
}

SnapshotRenderer.propTypes = {
  onBarClick: PropTypes.func,
  isLeafPage: PropTypes.bool,
  canSkipHierarchy: PropTypes.bool,
  currentSnapshotView: PropTypes.string,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentGroupByChartApproach: PropTypes.string,
  currentDrilldownGroupByEntry: commonPropTypes.currentDrilldownGroupByEntryPropTypes,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  dimensionRowCount: commonPropTypes.stringOrNumberProps,
  currentDrilldownDimensionField: PropTypes.string,
  currentGroupByViewType: PropTypes.string,
  showScatterPlotRange: PropTypes.bool,
  currentVisualizationType: PropTypes.string,
  currentSecondaryMetricField: PropTypes.string,
  dispatchUpdateSecondaryMetricField: PropTypes.func,
  dispatchUpdateBenchMarkName: PropTypes.func,
  dispatchUpdateComparisonModeOn: PropTypes.func,
  dispatchUpdateBarChartSortType: PropTypes.func,
  dispatchUpdateGroupChartSortType: PropTypes.func,
  dispatchUpdateSnapshotDimensionSortType: PropTypes.func,
  dispatchUpdateVisualizationDefaults: PropTypes.func,
  dispatchToggleAddSnapshotChartValues: PropTypes.func,
  dispatchTogglePieChartAnnotationOptions: PropTypes.func,
  showSnapshotChartValues: PropTypes.bool,
  pieChartAnnotationOptions: PropTypes.object,
  isEmbed: PropTypes.bool,
  drillDownTotal: commonPropTypes.stringOrNumberProps,
  metricTotalData: PropTypes.object,
  metricTotalLoading: PropTypes.bool,
  isSideBar: PropTypes.bool,
  compareYearRanges: PropTypes.array,
  comparisonType: PropTypes.string,
  isComparisonEnabled: PropTypes.bool,
  dispatchUpdateDrilldownDimensionField: PropTypes.func,
}

const mapDispatchToProps = {
  dispatchUpdateSecondaryMetricField: snapshotActions.updateSnapshotSecondaryMetricField,
  dispatchUpdateComparisonModeOn: updateComparisonModeOn,
  dispatchUpdateBenchMarkName: snapshotActions.updateSnapshotBenchMarkName,
  dispatchUpdateBarChartSortType: snapshotActions.updateBarChartSortType,
  dispatchUpdateGroupChartSortType: snapshotActions.updateGroupChartSortType,
  dispatchUpdateSnapshotDimensionSortType: snapshotActions.updateSnapshotDimensionSortType,
  dispatchUpdateVisualizationDefaults: snapshotActions.updateVisualizationDefaults,
  dispatchToggleAddSnapshotChartValues: snapshotActions.toggleAddSnapshotChartValues,
  dispatchTogglePieChartAnnotationOptions: snapshotActions.togglePieChartAnnotationOptions,
  dispatchUpdateDrilldownDimensionField: updateDrilldownDimensionField
}

function mapStateToProps(state) {
  // This props are used in helpers, So don't remove without check the usage in helpers too.
  return {
    drilldown: _.get(state, 'drilldown'),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry'),
    currentVisualizationType: _.get(state, 'drilldown.currentVisualizationType'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry'),
    currentGroupByChartApproach: _.get(state, 'visualization.snapshot.currentGroupByChartApproach', ''),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView'),
    currentSecondaryMetricField: _.get(state, 'visualization.snapshot.currentSecondaryMetricField'),
    currentBenchMarkMetricNames: _.get(state, 'visualization.snapshot.currentBenchMarkMetricNames'),
    currentBarChartSortType: _.get(state, 'visualization.snapshot.currentBarChartSortType'),
    currentGroupBySortType: _.get(state, 'visualization.snapshot.currentGroupBySortType'),
    currentDimensionSortType: _.get(state, 'visualization.snapshot.currentDimensionSortType'),
    showScatterPlotRange: String(_.get(state, 'visualization.snapshot.showScatterPlotRange')) === 'true',
    showSnapshotChartValues: String(
      _.get(state, 'visualization.snapshot.showSnapshotChartValues')) === 'true',
    currentGroupByViewType: _.get(state, 'visualization.snapshot.currentGroupByViewType', ''),
    pieChartAnnotationOptions: _.get(state, 'visualization.snapshot.pieChartAnnotationOptions'),
    commonFilters: _.get(state, 'commonFilters', {}),
    quickFilters: _.get(state, 'drilldown.quickFilters', []),
    mapOptions: _.get(state, 'visualization.mapOptions', {}),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false),
    drillDownTotal: _.get(state, 'metricTotal.totals.currentPeriodMetricTotals.total', ''),
    metricTotalLoading: _.get(state, 'metricTotal.isLoading', false),
    compareYearRanges: _.get(state, 'commonFilters.comparisonDateRanges', []),
    comparisonType: _.get(state, 'commonFilters.comparisonType', DATE_COMPARISON_TYPE.SAME_PERIOD),
    isComparisonEnabled: _.get(state, 'commonFilters.comparisonModeOn', false)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotRenderer);
