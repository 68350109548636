import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OnboardWizardModal from './OnboardWizardModal';
import { showOnboardWizardFromConfig } from 'common/config/customerConfiguration';
import { getLocalStorageItem } from 'helpers/localStorageHelper';
import { KEY_ON_BOARD_WIZARD } from 'appConstants';
import UseCollectionFetch from './UseCollectionFetch';
import { isMyViews } from '../CardList/cardHelper';
import { isCollaborateCollection } from '../ManageCollection/collaboratorHelper';

function OnboardWizard({ onReloadCollection }) {
  const [isOpenModal, setIsOpenModal] = useState(true);
  const { isLoading, collections } = UseCollectionFetch();

  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  }

  const checkLoginStatus = getLocalStorageItem(KEY_ON_BOARD_WIZARD);
  if (!checkLoginStatus || !showOnboardWizardFromConfig || _.isEmpty(collections) || isLoading) {
    return null;
  }

  const newCollections = _.filter(collections, (collection) => {
    if (!_.isEmpty(_.get(collection,'bellerophon_tag_id')) && !isMyViews(collection)
      && !isCollaborateCollection(collection)) {
      collection.is_hidden = true;
      return collection;
    }
  })

  return (
    <OnboardWizardModal
      collections={newCollections}
      isOpenModal={isOpenModal}
      onToggleHandleModal={handleToggleModal}
      onReloadCollection={onReloadCollection} />
  )
}

OnboardWizard.propTypes = {
  onReloadCollection: PropTypes.func
}

export default OnboardWizard
