import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetAllFilters } from 'actions/drilldownActions';
import { removePolygonFilter, toggleShapeIdsFilter } from 'actions/mapOptionsActions';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import { updateDrilldownDimensionField } from 'actions/drilldownActions';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';


class ResetFilterRenderer extends Component {
  // this.props.dispatchToggleShapeIdsFilter(newSelectedShapeIds, selectedShapesExtent);
  handleResetBtn = () => {
    const {
      currentDrilldownDimensionField,
      currentDrilldownTemplateId,
      dispatchResetAllFilters,
      dispatchUpdateDrilldownDimensionField,
      onAllFilterReset
    } = this.props;

    if(_.isEmpty(currentDrilldownDimensionField)){
      const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
      dispatchUpdateDrilldownDimensionField(_.first(dimensionsEntries)['field']);
    }
    onAllFilterReset();
    dispatchResetAllFilters();
  }

  handleKeyDown = (e) => {
    if(isEnterButtonPressed(e)) {
      this.handleResetBtn();
    }
  }

  isNoFilterApplied = () => {
    const { polygonsGeojson, selectedShapeIds, quickFilters, drilledDownDimensions} = this.props;
    return (_.isEmpty(_.get(polygonsGeojson, 'features')) &&
      _.isEmpty(selectedShapeIds) &&
      _.isEmpty(quickFilters) &&
      _.isEmpty(drilledDownDimensions)
    );
  }

  render() {
    if(this.isNoFilterApplied()){
      return null;
    }

    return (
      <span
        className="float-right reset-link"
        tabIndex={0}
        aria-label="Reset all filters"
        onClick={this.handleResetBtn}
        onKeyDown={this.handleKeyDown}
      >
        Reset all
      </span>
    );
  }
}

ResetFilterRenderer.propTypes = {
  onAllFilterReset: PropTypes.func,
  dispatchResetAllFilters: PropTypes.func,
  dispatchUpdateDrilldownDimensionField: PropTypes.func,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  polygonsGeojson: PropTypes.object,
  selectedShapeIds: PropTypes.array,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  drilledDownDimensions: PropTypes.array,
  currentDrilldownDimensionField: PropTypes.string,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes
};

ResetFilterRenderer.defaultProps = {
  onAllFilterReset: _.noop
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetAllFilters: () => {
      dispatch(resetAllFilters());
      dispatch(removePolygonFilter());
      dispatch(toggleShapeIdsFilter([], null));
    },
    dispatchUpdateDrilldownDimensionField: (field) => {
      dispatch(updateDrilldownDimensionField(field));
    }
  }
}

function mapStateToProps(state) {
  return {
    commonFilters: _.get(state, 'commonFilters', {}),
    polygonsGeojson: _.cloneDeep(_.get(state,'visualization.mapOptions.filteredGeojson',[])),
    selectedShapeIds: _.cloneDeep(_.get(state, 'visualization.mapOptions.shape.selectedShapeIds', [])),
    quickFilters: _.cloneDeep(_.get(state.drilldown, 'quickFilters', [])),
    drilledDownDimensions: _.cloneDeep(_.get(state.drilldown, 'drilledDownDimensions', [])),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetFilterRenderer);
