import PropTypes from 'prop-types';
import React from 'react';
import DistributionChart from './DistributionChart';
import LineChart from './LineChart';
import SnapshotVisualization from './SnapshotVisualization';

const DemoVisualization = (props) => {
  const { visualization, propsVarName } = props;
  const Component = getComponent(visualization);

  return (
    <Component {...window.demoProps[propsVarName]} />
  );
};

DemoVisualization.propTypes = {
  visualization: PropTypes.string,
  propsVarName: PropTypes.string
}

export default DemoVisualization;

const getComponent = (visualization) => {
  switch(visualization) {
    case 'DistributionChart':
      return DistributionChart;
    case 'LineChart':
      return LineChart;
    case 'ScatterChart':
      return SnapshotVisualization;
    case 'GroupByScatterChart':
      return SnapshotVisualization;
    case 'BarChart':
      return SnapshotVisualization;
    case 'PieChart':
      return SnapshotVisualization;
    default:
      return `Invalid visualization name #{visualization}`;
  }
}
