import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

import { getBookMarks } from 'common/api/bookmarksApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { getCurrentViewEntry } from 'common/config/templateConfiguration';
import { getBenchMarkName } from 'pages/SubscriptionsManager/components/Bookmark/helper';

import BookmarkManagePage from './BookmarkManagePage';
import BookmarkBar from './BookmarkBar';
import { deleteBookmark, deleteManagedBookmark } from 'common/api/bookmarksApi';
import { EMAIL_STRATEGY_TYPES,
  BOOKMARK_TYPES,
  SAVED_VIEWS_TAB,
  DEFAULT_DROPDOWN_OPTION
} from 'appConstants';
import { getTemplateAndViewEntryFromBookmark } from 'pages/dashboard/components/CardList/cardHelper';
import {
  alertDeleteMessage,
  alertDeleteErrorMessage,
  viewDeleteMessage,
  viewDeleteErrorMessage
} from 'helpers/toastMessages';
import { isViewChanged, isBookmarkValueChanged } from 'pages/drilldown/components/Bookmark/bookmarkHelper';
import { updateCurrentBookmark } from 'actions/bookmarkActions';

class BookmarksManager extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      bookmarks: [],
      oldBookmarks: [],
      isLoading: false,
      currentBookmark: {},
      reLoadBookmark: true,
      isErrorInRecipients: [],
    };

    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.fetchBookMarks();
  }

  componentDidUpdate(prevProps) {
    const { reLoadBookmark } = this.state;
    const { isBookmarkSaved, currentTab } = this.props;
    const isTabChanged = currentTab != prevProps.currentTab;

    if((reLoadBookmark && isBookmarkSaved) || isTabChanged){
      this.fetchBookMarks();
    }
  }

  getBookmarkType = (bookmark) => {
    let bookmarkType = BOOKMARK_TYPES.ALERT;
    const isRadar = JSON.parse(_.get(bookmark, 'is_radar', false));

    if(isRadar) {
      bookmarkType = BOOKMARK_TYPES.RADAR;
    } else if (_.get(bookmark, 'email_strategy') == EMAIL_STRATEGY_TYPES.NEVER) {
      bookmarkType = BOOKMARK_TYPES.SAVED_VIEW;
    }
    return bookmarkType;
  }

  fetchBookMarks = () => {
    this.setState({ isLoading: true, reLoadBookmark: false });
    const { currentBookmarkId, currentTab } = this.props;
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    getBookMarks({ signal: this.abortFetchController.signal }, true , currentTab)
      .then((response) => response.json())
      .then((response) => {
        const result = response;
        const acceptBookmarks = _.filter(result, (bookmark) => {
          const { viewEntry } = getTemplateAndViewEntryFromBookmark(bookmark);
          // Not Listing Hidden bookmarks
          if ((bookmark.is_shared_accepted && !bookmark.is_hidden && !_.isEmpty(viewEntry)
            && !bookmark.is_forecast) || (bookmark.is_custom_advance_search)) {
            return bookmark;
          }
        });
        let currentBookmark;
        if (!_.isEmpty(currentBookmarkId)) {
          currentBookmark = (_.find(acceptBookmarks, (bookmark) => {
            return _.isEqual(bookmark.id + '', currentBookmarkId)
          }) || _.get(acceptBookmarks, '[0]', {}));
        } else {
          currentBookmark = _.get(acceptBookmarks, '[0]', {});
        }
        const defaultErrorInRecipients = this.setDefaultErrorInRecipients(acceptBookmarks);
        this.setState({
          isLoading: false,
          bookmarks: acceptBookmarks,
          oldBookmarks: _.cloneDeep(acceptBookmarks),
          currentBookmark,
          isErrorInRecipients: defaultErrorInRecipients
        }, () => this.props.onBookmarksUpdate(acceptBookmarks));
      })
      .catch((error) => {
        console.log('Error on fetching bookmarks', error);
        this.setState({
          isLoading: false,
          bookmarks: [],
        });
      });
  };

  setDefaultErrorInRecipients = (bookmarks) => {
    return _.compact(_.map(bookmarks, (bookmark) =>{
      if (bookmark['is_collaborate_alert']) {
        return { id: bookmark['id'], isError: false }
      }
    }));
  }

  isBookmarkMonitorIcon = (bookmark) => {
    const drilldown = _.get(bookmark, 'drilldown', {});
    let monitorConditions = _.get(bookmark, 'bookmarkOptions.monitorConditions', []);
    const visualization = _.get(bookmark, 'visualization', {});
    const bookmarkNames = getBenchMarkName(drilldown, visualization);
    const emailOptions = _.get(bookmark, 'email_options', {});
    const emailStrategy = _.get(bookmark, 'email_strategy', '')
    const isEmailStrategy = emailStrategy !== 'never';
    const isSchedule = _.includes(["on_schedule"], emailStrategy);
    const isMatchRecord = _.includes(["records_match_on_selected_filters"], emailStrategy);

    if (
     _.isEmpty(bookmarkNames) ||
     _.isEqual(DEFAULT_DROPDOWN_OPTION.name, bookmarkNames)
     ) {
       monitorConditions = _.filter(monitorConditions, (condition) => {
         return condition.type != 'benchmark';
       });
     }

    const isMonitor = _.some(monitorConditions, (condition) => {
      return condition.enable;
    });

    const showAlertIcon = ((isSchedule &&
      !_.isEmpty(emailOptions.emailSendingTime)) || isMatchRecord);
    return (isEmailStrategy && (isMonitor || showAlertIcon ))
  }

  handleUpdateBookmark = (bookmarkParams) => {
    const { currentBookmark, bookmarks, oldBookmarks, isErrorInRecipients } = this.state;
    const { onBookmarksUpdate } = this.props;
    let newBookmark = currentBookmark;

    if (currentBookmark.id == bookmarkParams.bookmark.id) {
      newBookmark = bookmarkParams.bookmark;
    }

    const newBookmarks = _.map(bookmarks, (bookmarkEntry) => {
      if (bookmarkParams.bookmark.id == bookmarkEntry.id) {
        return bookmarkParams.bookmark;
      } else {
        return bookmarkEntry;
      }
    });

    let alertRecipientErrorCheck = isErrorInRecipients;
    _.each(alertRecipientErrorCheck, (alertRecipient) => {
      if (alertRecipient['id'] == currentBookmark.id) {
        alertRecipient['isError'] = bookmarkParams['isErrorInRecipients'];
      }
    });

    const isErrorFound = _.find(alertRecipientErrorCheck, (alertRecipient) => {
      return alertRecipient.isError
    });

    this.setState({
      bookmarks: newBookmarks,
      currentBookmark: newBookmark,
      reLoadBookmark: true,
      isErrorInRecipients: alertRecipientErrorCheck
    }, () => {
      const isBookmarkChanges = this.getStatus(newBookmarks, oldBookmarks) && _.isEmpty(isErrorFound);
      onBookmarksUpdate(newBookmarks, isBookmarkChanges);
    });
  }

  getStatus(newBookmarks, oldBookmarks) {
    let newSaveState = false;
    _.forEach(oldBookmarks, (oldBookmark) => {
      const options = {
        drilldown : _.get(oldBookmark, 'drilldown', {}),
        radarOptions: _.get(oldBookmark, 'radarOptions', {}),
        visualization : _.get(oldBookmark, 'visualization', {}),
        currentVizBasedChartType : _.get(oldBookmark, 'bookmarkOptions.currentVizChartType', ''),
        currentDrilldownViewEntry: _.get(oldBookmark, 'drilldown.currentDrilldownViewEntry', ''),
        commonFilters: _.get(oldBookmark, 'commonFilters', ''),
        recipients: _.get(oldBookmark, 'recipients', '')
      };

      const currentBookmark =  _.find(newBookmarks, { 'id': oldBookmark.id });
      const isChangeBookmark = isBookmarkValueChanged(currentBookmark, oldBookmark);
      if(!_.isEmpty(currentBookmark)){
        if (isViewChanged(options, currentBookmark, true) || isChangeBookmark) {
          newSaveState = true;
          return;
        }
      }
    })

    return newSaveState
  }

  handleBookmarkClick = (bookmark) => {
    this.setState({ currentBookmark: bookmark });
    this.props.dispatchUpdateCurrentBookmark(bookmark.id, bookmark.name);
  }

  handleBookmarkDelete = (bookmark) => {
    const { currentUser, onBookmarksUpdate } = this.props;
    const { bookmarks, currentBookmark } = this.state;
    const bookmarkType = this.getBookmarkType(currentBookmark);
    const isAlertType = bookmarkType === 'alert';
    const successMessage =  isAlertType ? alertDeleteMessage : viewDeleteMessage;
    const errorMessage =  isAlertType ? alertDeleteErrorMessage : viewDeleteErrorMessage;
    const isCollaborateAlert = currentBookmark['is_collaborate_alert'];

    if (!_.isEmpty(currentUser) && !isCollaborateAlert) {
      this.setState({ isLoading: true });
      deleteBookmark(_.get(bookmark, 'id'))
        .then((response) => {
          const newBookmarks = _.filter(bookmarks, (bookmarkEntry) => {
            return bookmark.id != bookmarkEntry.id
          })
          const firstBookmark = (newBookmarks[0] || {});
          this.setState({
            isLoading: false,
            bookmarks: newBookmarks,
            currentBookmark: firstBookmark
          });
          onBookmarksUpdate(newBookmarks);
          this.props.dispatchUpdateCurrentBookmark(firstBookmark.id, firstBookmark.name);

          if(response.ok) {
            toast.success(successMessage);
          } else {
            toast.error(errorMessage);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(`Error on deleting ${_.capitalize(bookmarkType)}`, error);
          toast.error(errorMessage);
        });
    } else if(!_.isEmpty(currentUser) && isCollaborateAlert){
      this.deleteManagedBookmark(_.get(bookmark, 'id'));
    }
  };

  deleteManagedBookmark = (bookmarkId) => {
    const { onBookmarksUpdate } = this.props;
    const { bookmarks } = this.state;
    deleteManagedBookmark(bookmarkId)
        .then((response) => {
          const newBookmarks = _.filter(bookmarks, (bookmarkEntry) => {
            return bookmarkId != bookmarkEntry.id
          })
          const firstBookmark = (newBookmarks[0] || {});
          this.setState({
            isLoading: false,
            bookmarks: newBookmarks,
            currentBookmark: firstBookmark
          });
          onBookmarksUpdate(newBookmarks);
          this.props.dispatchUpdateCurrentBookmark(firstBookmark.id, firstBookmark.name);

          if(response.ok) {
            toast.success(alertDeleteMessage);
          } else {
            toast.error(alertDeleteErrorMessage);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(`Error on deleting Manged Alert`, error);
          toast.error(alertDeleteErrorMessage);
        });
  };

  renderBookmarkDetails() {
    const { currentUser, isLoading, userCollectionsWithCards } = this.props;
    const { currentBookmark } = this.state;
    if (_.isEmpty(currentBookmark)) {
      return null;
    }

    const { currentDrilldownViewEntry, currentDrilldownTemplateId } = _.get(
      currentBookmark,
      'drilldown',
      {}
    );
    const viewId = _.get(currentDrilldownViewEntry, 'view_id');
    const viewEntry = getCurrentViewEntry(currentDrilldownTemplateId, viewId);

    return (
      <div className="right-side bookmarks-alerts">
        <BookmarkManagePage
          bookmarkType={this.getBookmarkType(currentBookmark)}
          currentUser={currentUser}
          viewEntry={viewEntry}
          currentBookmarkEntry={currentBookmark}
          isLoading={isLoading}
          onBookmarkUpdate={this.handleUpdateBookmark}
          onBookmarkDelete={this.handleBookmarkDelete}
          userCollectionsWithCards={userCollectionsWithCards}
        />
      </div>
    )
  }

  renderBookmark(bookmark, index) {
    const { currentBookmark } = this.state;
    const isSelected = (_.isEqual(bookmark.id, currentBookmark.id));
    const uniqKey = `bookmark-bar-${index}`
    return (
      <BookmarkBar
        key={uniqKey}
        index={index}
        bookmark={bookmark}
        isSelected={isSelected}
        onBookmarkClick={this.handleBookmarkClick}
        onBookmarkUpdate={this.handleUpdateBookmark} />
    )
  }

  renderBookmarks() {
    const { bookmarks } = this.state;

    const bookmarkContents = _.sortBy(bookmarks, 'sort_order').map((bookmark, index) => {
      return this.renderBookmark(bookmark, index);
    });
    return (
      <Accordion defaultActiveKey="0">
        {bookmarkContents}
      </Accordion>
    )
  }

  renderBookmarkList() {
    return (
      <div className="collection-filter sidebar">
        <div className="sidebar-nav">
          {this.renderBookmarks()}
        </div>
      </div>
    )
  }

  render() {
    const { bookmarks, isLoading } = this.state;
    const { currentTab } = this.props;
    const isSavedViewTab = currentTab == SAVED_VIEWS_TAB.type;

    if (_.isEmpty(bookmarks) && !isLoading) {
      return (
        <div className="content-wrapper">
          <div className="no-collection-msg">
            <i className="icons icons-bookmark-manager no-collections-icon" />
            { isSavedViewTab ?
              <h6>You do not have any saved views yet. <br />
                You can save a view by clicking “Save As” while in Analysis mode. It will then appear
                in My Views in the Overview.
              </h6> :
              <h6>You do not have any alerts yet. <br />
                You can create an alert by clicking “Watch” while in Analysis mode.
              </h6>
            }
          </div>
        </div>
      )
    }

    return (
      <div className="content-wrapper">
        <LoadingSpinner isLoading={isLoading} />
        {this.renderBookmarkList()}
        {this.renderBookmarkDetails()}
      </div>
    )
  }
}

BookmarksManager.propTypes = {
  onBookmarksUpdate: PropTypes.func,
  currentUser: commonPropTypes.userPropTypes,
  dateFilters: PropTypes.object,
  currentBookmarkId: PropTypes.string,
  isLoading: PropTypes.bool,
  isBookmarkSaved: PropTypes.bool,
  dispatchUpdateCurrentBookmark: PropTypes.func,
  currentTab: PropTypes.string,
  userCollectionsWithCards: PropTypes.array 
}
const mapDispatchToProps = {
  dispatchUpdateCurrentBookmark: updateCurrentBookmark
};

export default connect(null, mapDispatchToProps)(BookmarksManager);
