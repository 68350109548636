import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MultiSelectDropdown from 'common/components/MultiSelectDropdown/MultiSelectDropdown';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';

import {
  getMapStyleEntries,
  getShapeOverlayDatasetEntries,
  isEnableEsriBaseMap
} from 'common/config/templateConfiguration';
import { updateMapStyleEntry, updateSelectedShapeOutlines } from 'actions/mapOptionsActions';
import MenuDropDownPopupSelector from 'common/components/MenuDropDown/MenuDropDownPopupSelector';

class MapStyleSelector extends Component {
  handleMapStyleSelect = (currentMapStyleEntry) => {
    this.props.dispatchUpdateMapStyleEntry(currentMapStyleEntry);
  }

  handleShapeOverlaySelect = (selectedOutlines) => {
    this.props.dispatchUpdateSelectedShapeOutlines(selectedOutlines);
  }

  renderMapStylesOptions(){
    const { currentMapStyleEntry, currentDrilldownTemplateId } = this.props;

    if(isEnableEsriBaseMap(currentDrilldownTemplateId)) {
      return;
    }

    return (
      <div className="map-filter-list">
        <div className="options-label">Base Map</div>
         <SolutionDropDown
          size='sm'
          className='p-0'
          title={currentMapStyleEntry.name}
          options={getMapStyleEntries(currentDrilldownTemplateId)}
          onSelect={this.handleMapStyleSelect}
         />
      </div>
    );
  }

  renderShapeOverlayOptions(){
    const { selectedOutlines, currentDrilldownTemplateId } = this.props;
    const shapeOverlayOptions = getShapeOverlayDatasetEntries(currentDrilldownTemplateId);

    if(_.isEmpty(shapeOverlayOptions)) {
      return null;
    }

    return(
      <div className="map-filter-list">
        <div className="options-label">Overlay</div>
        <MultiSelectDropdown
          title="Manage overlays"
          variant="link p-0"
          size="sm"
          options={shapeOverlayOptions}
          selectedOptions={selectedOutlines}
          displayNameField="outline_name"
          onOptionChange={this.handleShapeOverlaySelect}
        />
      </div>
    );
  }

  renderMapOptions(){
    return (
      <div className="map-filters">
        <div className="d-flex flex-column gap-24">
          {this.renderMapStylesOptions()}
          {this.renderShapeOverlayOptions()}
        </div>
      </div>
    );
  }

  render() {
    return(
      <MenuDropDownPopupSelector
        className="map-custom-control"
        trailingIconName="keyboard_arrow_down">
        {this.renderMapOptions()}
      </MenuDropDownPopupSelector>
    )
  }
}

MapStyleSelector.propTypes = {
  currentMapStyleEntry: PropTypes.object,
  dispatchUpdateMapStyleEntry: PropTypes.func,
  dispatchUpdateSelectedShapeOutlines: PropTypes.func,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentMapView: PropTypes.object,
  onClick: PropTypes.func,
  selectedShapeEntry: PropTypes.object,
  selectedOutlines: PropTypes.array,
  legendItems: PropTypes.array
};

const mapDispatchToProps = {
  dispatchUpdateMapStyleEntry: updateMapStyleEntry,
  dispatchUpdateSelectedShapeOutlines: updateSelectedShapeOutlines,
};

function mapStateToProps(state) {
  const currentMapViewType = _.get(state, 'visualization.mapOptions.currentMapView.type');
  const legendItems = _.get(state, ['visualization', 'mapOptions', 'legends', currentMapViewType], []);

  return {
    currentMapView: _.get(state, 'visualization.mapOptions.currentMapView', {}),
    currentMapStyleEntry: _.get(state, 'visualization.mapOptions.currentMapStyleEntry', {}),
    selectedOutlines: _.cloneDeep(_.get(state, 'visualization.mapOptions.selectedOutlines')),
    selectedShapeEntry: _.cloneDeep(_.get(state, 'visualization.mapOptions.shape.datasetEntry')),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    legendItems
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MapStyleSelector);
