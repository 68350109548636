import React, { Component } from 'react';
import {
  forecastAccuracyTitle,
  forecastAccuracyDescription
} from 'common/config/customerConfiguration';
class Header extends Component {
  render() {
    return (
      <div>
        <div className="forge-typography--headline5 mb-3">{forecastAccuracyTitle}</div>
        <div className="forge-typography--subtitle1 mb-3">{forecastAccuracyDescription}</div>
      </div>
    );
  }
}

Header.propTypes = {
};

export default Header;