import PropTypes from 'prop-types';
import React, { Component } from 'react';

class BookmarkTitle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { name, description,
      onHandleNameChange, onHandleDescriptionChange, isDisabled
    } = this.props;

    return(
      <>
        <div className="w-50 mb-10">
          <label className="custom-label" htmlFor="title">Title</label>
          <input
            disabled={isDisabled}
            tabIndex="0"
            className="form-control"
            type="text"
            aria-label={name}
            placeholder="Title"
            value={name}
            onChange={(e) => onHandleNameChange(e)} />
        </div>

        <div className="w-75 mb-10">
          <label className="custom-label">Description</label>
          <input maxLength="200"
            disabled={isDisabled}
            className="form-control"
            tabIndex="0"
            type="text"
            placeholder="Describe what is important about this view"
            value={description}
            onChange={(e) => onHandleDescriptionChange(e)} />
        </div>
      </>
    );
  }
}

BookmarkTitle.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  onHandleNameChange: PropTypes.func,
  onHandleDescriptionChange: PropTypes.func,
  isDisabled: PropTypes.bool
}

export default BookmarkTitle;
