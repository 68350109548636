import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { buildQueryString } from 'helpers/HttpHelper';
import LoadingSpinner from 'common/components/LoadingSpinner';
const PropertyPhoto = ({ photoDetails }) => {
  const [loading, setLoading] = useState(true);

  const queryParams = {
    country: _.get(photoDetails, 'comp_finder_country', '').replace(/County/i, "").trim(),
    quickRefId: _.get(photoDetails, 'comp_finder_quick_ref_id', ''),
    taxYear: _.get(photoDetails, 'comp_finder_tax_year', '')
  };
  const imgSrc = `/api/advanced_search/get_external_image.png?${buildQueryString(queryParams)}`;

  return (
    <div>
      <LoadingSpinner isLoading={loading} />
      <img
        src={imgSrc}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
      />
    </div>

  )
};


PropertyPhoto.propTypes = {
  photoDetails: PropTypes.object.isRequired,
};


export default PropertyPhoto;
