import PropTypes from 'prop-types';
import React, { Component } from 'react';

class InputTag extends Component  {

  componentDidMount() {
    if(this.tagInput) {
      this.tagInput.focus();
    }
  }

  removeTag = (i) => {
    const newTags = [ ...this.props.tags ];
    newTags.splice(i, 1);
    if(_.isEmpty(newTags) && this.props.onInitialeEmailValueChange) {
      this.props.onInitialeEmailValueChange('');
    }
    this.props.onChange(newTags);
  }

  inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === 'Backspace' && !val) {
      this.removeTag(this.props.tags.length - 1);
    }
  }

  inputKeyUp = (e) => {
    const val = e.target.value;
    const splitValues = val.split(/[ ,;]+/);
    const inputValues = _.compact(splitValues);
    if(this.props.onInitialeEmailValueChange){
      this.props.onInitialeEmailValueChange(val)
    }
    if ((e.key === 'Enter' || _.size(splitValues) > 1)  && val) {
      inputValues.forEach((compactValue) => {
        if (this.props.tags.find(tag => tag.toLowerCase() === compactValue.toLowerCase())) {
          _.remove(inputValues, compactValue)
        }
      })
      if (_.isEmpty(inputValues)) {
        return;
      }
      const initalTagListItems =  [...this.props.tags]
      const finalTagListItems = _.uniq(_.concat(initalTagListItems, inputValues));
      this.props.onChange(finalTagListItems);
      this.tagInput.value = null;
    }
  }

  onInputBlur = (e) => {
    const val = e.target.value;
    if (val) {
      if (this.props.tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      this.props.onChange([...this.props.tags, val]);
      this.tagInput.value = null;
    }
  }

  renderInputTagListItem = (tag, i) => {
    return (
      <li key={tag}>
        {tag}
        <button 
          type="button" 
          className='tx-8' 
          onClick={() => { this.removeTag(i); }}>
            <i className='icons-close'/>
        </button>
      </li>
    );
  }

  render() {
    const { tags, className, placeholder } = this.props;
    const tagContainerClasses = `input-tag-container ${className}`;
    const inputTagListItems = _.map(tags, this.renderInputTagListItem);
    const  newPlaceholder = _.isEmpty(tags) ? placeholder : '';
    return (
      <div className={tagContainerClasses}>
        <ul className="input-tag-list-container">
          {inputTagListItems}
          <li className="input-tag-list">
            <input
              className="tag-input"
              type="text"
              placeholder={newPlaceholder}
              maxLength="200"
              onKeyDown={this.inputKeyDown}
              onKeyUp={this.inputKeyUp}
              onBlur={this.onInputBlur}
              ref={ref => { this.tagInput = ref; }}
            />
          </li>
        </ul>
      </div>
    );
  }
}

InputTag.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  tags: PropTypes.array,
  onInitialeEmailValueChange: PropTypes.func
};

InputTag.defaultProps = {
  className: '',
  placeholder: '',
  onChange: _.noop,
  onInitialeEmailValueChange: _.noop,
  tags: []
}

export default InputTag;
