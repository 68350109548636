import React from 'react'
import { Route } from 'react-router-dom';

import Dashboard from 'pages/dashboard/Dashboard';
import Drilldown from 'pages/drilldown/Drilldown';

export default function Tabs() {
  return (
    <>
      <Route
        path="/overview"
        component={Dashboard}
      />
      <Route
        path="/analysis"
        component={Drilldown}
      />
    </>
  )
}