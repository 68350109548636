import React from 'react';
import { useHistory } from "react-router-dom";

import InsightsActivitiesContainer from './InsightsActivitiesContainer';
import SlaWatchIndex from './slaWatch/slaWatchIndex';
import { getInsightTitle, getInsights } from 'common/config/customerConfiguration';

const InsightsCalculationsPage = () => {
  const history = useHistory();

  const onClickBackButton = () => {
    const hash = '/';
    history.push(hash);
  }

  const renderInsightsActivities = () => {
    const insightEntries = getInsights();
    if (_.isEmpty(insightEntries)) {
      return
    }

    return (
      <div className="insights-container">
        <h1 
          tabIndex={0} 
          role="heading" 
          aria-label="Top Movers" 
          className="main-head text-primary"
        >
          Top Movers</h1>
        <InsightsActivitiesContainer />
      </div>)
  }

  return (
    <div role="main" className="insights-calculations-wrapper pb-10">
      <div className="insights-head">
        <button
          onClick={onClickBackButton}
          aria-label="Back"
          className="
            btn
            back-arrow-btn
            back-insights
            mr-3">
          <i className="icons-arrow-left2" />
        </button>

        <span
          tabIndex={0}
          className="d-inline-block align-middle tx-15"
        >
          {getInsightTitle()}
        </span>
      </div>
      <div className="container-fluid">
        {renderInsightsActivities()}
      </div>
      <SlaWatchIndex />
    </div>
  );
}

export default InsightsCalculationsPage;
