import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { buildQueryString } from 'helpers/HttpHelper';
import { getSLAWatchTotals } from 'common/api/drilldown';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { updateSlaPreferences } from 'common/api/commonApi';
import {
  getSlaWatchTitle,
  getSelectedTemplateForSLAWatch,
  isErpCalculationType,
  getSLAWatchCalculationType
} from 'common/config/customerConfiguration';
import {
  updateSlaDateFilters,
  updateSlaGlobalFilter
} from 'actions/commonFiltersActions';
import { formatTotalResult, showSlaWatch } from './slaWatchHelper';
import SlaContainer from './slaContainer';
import TotalCountCards from './totalCountCards'
import DateFilter from './SlaOptions/DateFilter';
import GlobalFilter from './SlaOptions/GlobalFilter';
import NoDataFound from 'common/components/NoDataFound/NoDataFound';
import { SLA_CALCULATION_TYPES } from 'appConstants';
import { ForgeButton } from '@tylertech/forge-react';
import SlaLoadingSpinner from './SlaLoadingSpinner';
import classNames from 'classnames';
class SlaWatchIndex extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      allItems:[],
      totalPass: 0,
      totalFail: 0,
      isLoading: false
    };
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.fetchTotals();
  }

  componentDidUpdate(prevProps){
    const { slaDateFilters, slaGlobalFilters } = this.props;
    let dateRange = _.get(slaDateFilters, 'dateRange', {});
    const prevDateRange = _.get(prevProps, 'slaDateFilters.dateRange', {});
    const isGlobalFiltersChanged = !_.isEqual(
      slaGlobalFilters,
      _.get(prevProps, 'slaGlobalFilters', [])
    );
    if((!_.isEmpty(prevProps) && !_.isEqual(prevDateRange, dateRange)) || isGlobalFiltersChanged){
      this.setState({
        allItems: []
      });
      this.fetchTotals();
    }
  }

  fetchTotals = () => {
    const { slaDateFilters, slaGlobalFilters } = this.props;
    const apiParams = {
      commonFilters: JSON.stringify({
        ...slaDateFilters,
        globalFilters: slaGlobalFilters
      }),
      drilldownEntry: JSON.stringify({
        currentDrilldownTemplateId: getSelectedTemplateForSLAWatch(),
      }),
    }
    const apiUrl = getSLAWatchTotals(apiParams);
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    this.setState({isLoading: true});
    fetchApiData(apiUrl, this.abortFetchController)
      .then((response) => {
        const noOfPass = _.sumBy(response['results'], (entry) => { return Number(entry['pass_count']) });
        const noOfFail = _.sumBy(response['results'], (entry) => { return Number(entry['fail_count']) });

        this.setState({
          allItems: formatTotalResult(response['results']),
          totalPass: noOfPass,
          totalFail: noOfFail,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({isLoading: false});
      });
  }

  handlePreferenceChange = (globalFilters, dateFilters) => {
    this.setState({ loading: true });
    updateSlaPreferences(globalFilters, dateFilters).
      then(() => {
        this.setState({loading: false});
      }).catch((err) => {
        console.log('Error on updating global filters ', err); // eslint-disable-line no-console
        this.setState({ loading: false });
      });
  }

  renderDateFilter(){
    const { slaDateFilters } = this.props;
    return(
      <DateFilter
        slaDateFilters={slaDateFilters}
        shouldHideCustomDateRange={isErpCalculationType()}
        handleDateFilterChange={this.handleDateFilterChange}>
      </DateFilter>
    )
  }

  handleDateFilterChange = (dateFilters) => {
    this.props.dispatchDateFiltersChange(dateFilters);
    this.handlePreferenceChange(this.props.slaGlobalFilters, dateFilters);
  }

  handleFiltersChange = (filters) => {
    this.props.dispatchFiltersChange(filters);
    this.handlePreferenceChange(filters, this.props.slaDateFilters);
  }

  handleClickDownloadButton = () => {
    const csvDataUrl =  this.getDownloadCsvUrl();
    window.open(csvDataUrl, '_blank');
  }

  getDownloadCsvUrl() {
    const { slaDateFilters, slaGlobalFilters } = this.props;
    const apiParams = {
      commonFilters: JSON.stringify({
        ...slaDateFilters,
        globalFilters: slaGlobalFilters
      }),
      drilldownEntry: JSON.stringify({
        currentDrilldownTemplateId: getSelectedTemplateForSLAWatch(),
      }),
    }

    return `/api/sla_watch_download.csv?${buildQueryString(apiParams)}`;
  }

  renderDownloadBtn(){
    const {allItems} =  this.state;

    if (getSLAWatchCalculationType() == SLA_CALCULATION_TYPES.ERP || _.isEmpty(allItems)) {
      return null;
    }

    return (
      <ForgeButton type="outlined" className='ml-auto'>
        <button
          onClick={this.handleClickDownloadButton}>
            Download
        </button>
      </ForgeButton>
    )
  }

  renderGlobalFilters(){
    const { slaGlobalFilters } = this.props;
    return(
      <GlobalFilter
        slaGlobalFilters={slaGlobalFilters}
        handleFiltersChange={this.handleFiltersChange}>
      </GlobalFilter>
    )
  }

  renderSLAContainer(){
    const {allItems, isLoading} =  this.state;
    const { slaDateFilters, slaGlobalFilters} = this.props;
    let dateRange = _.get(slaDateFilters, 'dateRange', {});

    if (_.isEmpty(allItems) && isLoading) {
      return null;
    }

    if (_.isEmpty(allItems) && !isLoading) {
      return <NoDataFound />;
    }

    return(
      <SlaContainer
          globalFilters={slaGlobalFilters}
          allItems={allItems} dateRange={dateRange}/>
    )
  }

  renderFilters(){
    const { isLoading } = this.state;
    const slaFilterClasses = classNames('sub-heading', {
      'action-disable' : isLoading
    });

    return(
      <div className={slaFilterClasses}>
        {this.renderDateFilter()}
        {this.renderGlobalFilters()}
      </div>
    )
  }

  renderLoadingInfo = () => {
    const { isLoading } = this.state;

    if (!isLoading) {
      return null;
    }

    return (
      <div className='ml-10'>
        <SlaLoadingSpinner isLoading={isLoading} />
      </div>
    )
  }

  renderTotalCards = () => {
    const {isLoading, totalPass, totalFail} = this.state;

    if(isLoading){
      return null;
    }

    return (
      <TotalCountCards passCount={totalPass} failCount={totalFail}/>
    )
  }

  render() {
    if(!showSlaWatch()){
      return null;
    }

    return (
      <div className="sla-watch-container container-fluid">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="heading">
              <div className='d-flex align-items-center'>
                {getSlaWatchTitle()}
                {this.renderDownloadBtn()}
              </div>
              {this.renderFilters()}
            </div>
          </div>
        </div>

        {this.renderLoadingInfo()}
        {this.renderTotalCards()}
        {this.renderSLAContainer()}
      </div>
    );
  }
}

SlaWatchIndex.propTypes = {
  slaDateFilters: PropTypes.object,
  slaGlobalFilters: PropTypes.array,
  dispatchDateFiltersChange: PropTypes.func,
  dispatchFiltersChange: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchDateFiltersChange: (dateFilters) => {
      dispatch(updateSlaDateFilters(dateFilters));
    },
    dispatchFiltersChange: (filters) => {
      dispatch(updateSlaGlobalFilter(filters));
    }
  }
}

function mapStateToProps(state) {
  return {
    slaGlobalFilters: _.get(state, 'commonFilters.slaGlobalFilters', []),
    slaDateFilters: _.get(state, 'commonFilters.slaDateFilters', {}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlaWatchIndex);
