import React from 'react'
import PropTypes from 'prop-types'
import CompareToOptions from '../CompareToOption';
import { getSecondaryMetricEntries } from 'helpers/visualizationHelper';
import { DEFAULT_COMPARE_TO_DROPDOWN_OPTION } from 'appConstants';

function SecondaryMetricsSelection(props) {
  const {
    chartType, currentChartView,
    currentDrilldownViewEntry, currentSecondaryMetricEntry, onSecondaryMetricChange
  } = props;

  const secondaryMetrics = getSecondaryMetricEntries(
    currentDrilldownViewEntry, chartType, currentChartView);

  if (_.isEmpty(secondaryMetrics)) {
    return null;
  }
  const dropdownOptions = [DEFAULT_COMPARE_TO_DROPDOWN_OPTION].concat(secondaryMetrics);
  const key = `${currentChartView}-secondary-metrics`
  return (
    <div className='secondary-metrics-selection'>
      <CompareToOptions
        key={key}
        label="Compare to"
        dropdownOptions={dropdownOptions}
        currentCompareOption={currentSecondaryMetricEntry}
        onOptionChange={onSecondaryMetricChange}
      />
    </div>
  );
}

SecondaryMetricsSelection.propTypes = {
  currentDrilldownViewEntry: PropTypes.object,
  currentSecondaryMetricEntry: PropTypes.object,
  onSecondaryMetricChange: PropTypes.func,
  currentChartView: PropTypes.string,
  chartType: PropTypes.string
}

export default SecondaryMetricsSelection
