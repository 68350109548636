import * as actionTypes from '../actionTypes/snapshotActionTypes';

export const updateGroupBychartApproach = (viewType) => ({
  type: actionTypes.UPDATE_GROUPCHART_APPROACH,
  viewType
});

export const updateGroupBychartViewType = (viewType) => ({
  type: actionTypes.UPDATE_GROUPCHART_VIEW_TYPE,
  viewType
});

export const updateCurrentSnapshotView = (selectedView) => ({
  type: actionTypes.UPDATE_CURRENT_SNAPSHOT_VIEW,
  selectedView
});

export const updateSnapshotSecondaryMetricField = (field) => ({
  type: actionTypes.UPDATE_SNAPSHOT_SECONDARY_METRIC_FIELD,
  field
});

export const updateSnapshotBenchMarkName = (name) => ({
  type: actionTypes.UPDATE_SNAPSHOT_BENCH_MARK_NAME,
  name
});

export const updateBarChartSortType = (sortType) => ({
  type: actionTypes.UPDATE_BAR_CHART_SORT_TYPE,
  sortType
});

export const updateGroupChartSortType = (sortType) => ({
  type: actionTypes.UPDATE_GROUP_CHART_SORT_TYPE,
  sortType
});

export const updateSnapshotDimensionSortType = (sortType) => ({
  type: actionTypes.UPDATE_SNAPSHOT_DIMENSION_SORT_TYPE,
  sortType
});

export const updateVisualizationDefaults = (options) => ({
  type: actionTypes.UPDATE_VISUALIZATION_DEFAULTS,
  options
});

export const toggleAddSnapshotChartValues = (toggle) => ({
  type: actionTypes.TOGGLE_ADD_SNAPSHOT_CHART_VALUES,
  toggle
});

export const togglePieChartAnnotationOptions = (options) => ({
  type: actionTypes.TOGGLE_PIE_CHART_ANNOTATION_OPTIONS,
  options
});
