import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ForgeButton } from '@tylertech/forge-react';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import ExportModal from './Export/ExportModal';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import {
  updateSearchField,
  updateSearchPage,
  updatePolygonGeojson,
  updateSearchResultCount
} from 'actions/advanceSearchActions';
import { getAdvanceSearchDisplayName } from 'common/config/customerConfiguration';
import { getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
import { ADVANCE_SEARCH_PAGE, SEARCH_METHOD } from  'appConstants';
import CompareListButton from './CompareListButton';
import { getCompFinderDescription } from 'common/config/templateConfiguration';
import LoadingSpinner from 'common/components/LoadingSpinner';

const Header = (props) => {
  const {
    container,
    map,
    updateMapDownload,
    isCustomSearch,
    detailsEntries
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [isDownloadComplete, setIsDownloadComplete] = useState(false);

  const dispatch = useDispatch();
  const searchPage = useSelector(state => state.advanceSearch.searchPage);
  const searchField = useSelector(state => state.advanceSearch.searchField);
  const templateId = useSelector(state => state.advanceSearch.templateId);

  const searchDisplayName = _.get(searchField, 'name', getAdvanceSearchDisplayName());
  const templateEntry =  getCurrentTemplateEntryById(templateId);
  const templateName = _.get(templateEntry, 'name', '');
  const isComparisonListOpen = useSelector(state => state.advanceSearch.isComparisonListOpen);

  const onBackClick = (isEmptySearchField) => {
    let backPage = searchPage == ADVANCE_SEARCH_PAGE.REPORT_PAGE && !isEmptySearchField ?
      ADVANCE_SEARCH_PAGE.DETAIL_PAGE : ADVANCE_SEARCH_PAGE.SEARCH_PAGE;

    dispatch(updateSearchPage(backPage));
    if ( isEmptySearchField ) {
      dispatch(updateSearchField({}));
      dispatch(updateCurrentBookmark(''));
      dispatch(updateSearchResultCount(0));
      dispatch(updatePolygonGeojson({ featuresGeojson: {} }));
    }
  }

  const renderTitle = () => {
    if (searchPage == ADVANCE_SEARCH_PAGE.REPORT_PAGE) {
      return (
        <div className='forge-typography--headline5'>
          <label>{`${templateName} Comparison Report`}</label>
        </div>
      )
    }
    return (
      <div className='page-title'>
        {searchDisplayName}: {_.get(searchField, 'value')}
      </div>
    )
  }

  const renderDescription = () => {
    return (
      <div className="forge-typography--body2">
        {getCompFinderDescription(templateId, SEARCH_METHOD.SUBJECT_PROPERTY)}
      </div>
    )
  }

  const onExportClick = () => {
    setShowModal(true);
  };

  const renderExportButton = () => {
    return (
      <ForgeButton type="raised" className="mr-2">
        <button type="button" onClick={() => onExportClick(true)}>
          <span>Export</span>
        </button>
      </ForgeButton>
    )
  }

  const downloadStatusUpdate = (status) => {
    setIsDownloadComplete(status === 'start');
  };

  const renderPageHeader = () => {
    return(
      <div className='custom-search-header'>
        <LoadingSpinner isLoading={isDownloadComplete} />
        <div className='d-flex align-items-center gap-15'>
          <BackButton onClick={() => onBackClick(false)} />
          {renderTitle()}
          {renderDescription()}
        </div>

        <div className='ml-auto'>
          <ForgeButton type="outlined" className="ml-auto mr-2">
            <button type="button" onClick={() => onBackClick(true)}>
              <span>Start new search</span>
            </button>
          </ForgeButton>
          {searchPage == ADVANCE_SEARCH_PAGE.REPORT_PAGE && renderExportButton()}
          {
            (!isComparisonListOpen
              && searchPage != ADVANCE_SEARCH_PAGE.REPORT_PAGE) && (<CompareListButton/>)
          }
        </div>
        {showModal &&
          <ExportModal
            container={container}
            onClose={ (isShow) => setShowModal(isShow)}
            searchField={searchField}
            updateMapDownload= {updateMapDownload}
            isCustomSearch={isCustomSearch}
            detailsEntries={detailsEntries}
            templateId={templateId}
            downloadStatusUpdate={downloadStatusUpdate}
            map={map}>
          </ExportModal>}
      </div>
    );
  }

  return (
    <>
      {renderPageHeader()}
    </>
  );
}

Header.propTypes = {
  searchField: PropTypes.object,
  container: PropTypes.func,
  updateMapDownload: PropTypes.func,
  map: PropTypes.object,
  isCustomSearch: PropTypes.bool,
  detailsEntries: PropTypes.array
};

export default Header;
