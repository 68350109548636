import { toast } from "react-toastify";
import { updateCollaborateCollectionUser } from "common/api/collaborateApi";

export const updateCollaborateUser = async ({ collectionId, newUser, message='User role changed.' }) => {
  let resUser = {};
  try {
    resUser = await updateCollaborateCollectionUser(collectionId, newUser).then(res => res.json());
    if(message) {
      toast.success(message);
    }
  } catch (e) {
    console.log('Update collaborate collection user failed', e);
  }

  return resUser;
}
