import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';

import reducer from './reducers';
import { dashboardReducerDefaultState } from './reducers/dashboardReducer';
import { defaultRadarOptions } from './reducers/radarReducer';
import { defaultForecastingOptions } from './reducers/forecastingReducer';
import { visualizationReducerDefaultState } from './reducers/helper';
import { commonFiltersReducersDefaultState } from './reducers/commonFiltersReducer';
import {
  updateStoreToUrl, updateStoreFromUrl, updateStoreToUrlForAdvanceSearch
} from './helpers/UrlParamsHelper';
import { getCurrentPeriodDateRangeFromState, getUserPreferenceDateType } from './helpers/dateHelper';
import { updateGlobalFiltersByConfigDefaults } from 'pages/drilldown/components/QuickFilterBar/helper';
import { getSessionStorageItem, setLocalStorageItem } from 'helpers/localStorageHelper';
import { getAdvanceSearchTemplateId } from 'common/config/customerConfiguration';
import { getDefaultShapeDatasetEntry } from 'common/config/templateConfiguration';
import { ADVANCE_SEARCH_PAGE, KEY_ON_BOARD_WIZARD, KEY_USER_READ_MESSAGE } from 'appConstants';
import { userCollectionsReducerDefaultState } from 'reducers/userCollectionsReducer';

const history = createBrowserHistory();
const debounceBrowserHistoryWait = 400;

const configureStore = ({
  configurations, currentUser, userPreferences, userFromBellerophon, embedParams = {},
  userCollectionsWithCards, userLoggedInDetails
}) => {
  if (!_.isEmpty(currentUser)) {
    setLocalStorageItem(KEY_USER_READ_MESSAGE, true, false);
    const value = getSessionStorageItem(KEY_ON_BOARD_WIZARD);
    const countOnBoardWizard = getSessionStorageItem('countOnBoardWizard')
    if(countOnBoardWizard == 1){
      setLocalStorageItem(KEY_ON_BOARD_WIZARD, true);
    }
    setLocalStorageItem(KEY_ON_BOARD_WIZARD, value);
  }
  const globalFilters = _.get(userPreferences, 'globalFilters', []);
  const dateFilters = _.get(userPreferences, 'dateFilters', {});
  const lastSelectedCollectionId = _.get(userPreferences, 'last_viewed_collection_id', null);
  const slaGlobalFilters = _.get(userPreferences, 'slaGlobalFilters', []);
  const slaDateFilters = _.get(userPreferences, 'slaDateFilters', {});
  const radarOptions = _.get(userPreferences, 'radarOptions', {});
  window.radarOptions = radarOptions;
  // We don't update this key in our app side. So we set this to global same as customConfiguration.
  window.userFromBellerophon = userFromBellerophon;

  const {
    independentDateFilters,
    additionalDateFilters
  } = dateFilters;
  const newdateFilters = {
    ...defaultDateRangeFilters(dateFilters),
    independentDateFilters,
    additionalDateFilters
  };
  const searchTemplateId = getAdvanceSearchTemplateId();

  const commonFilterState = {
    globalFilters: updateGlobalFiltersByConfigDefaults(globalFilters),
    allMetricFilters: updateGlobalFiltersByConfigDefaults(globalFilters),
    slaDateFilters: defaultDateRangeFilters(slaDateFilters),
    slaGlobalFilters: updateGlobalFiltersByConfigDefaults(slaGlobalFilters),
    ...newdateFilters,
  };
  const initialState = {
    configurations: configurations,
    currentUser,
    userCollections: userCollectionsReducerDefaultState(userCollectionsWithCards),
    userLoggedInDetails,
    userFromBellerophon,
    dashboard: dashboardReducerDefaultState(lastSelectedCollectionId),
    commonFilters: commonFiltersReducersDefaultState(commonFilterState),
    visualization: visualizationReducerDefaultState(),
    embedOptions: {...embedParams, isEmbed: !_.isEmpty(embedParams) },
    bookmark: {},
    radar: defaultRadarOptions(radarOptions),
    forecasting: defaultForecastingOptions(),
    advanceSearch: {
      searchField: {},
      templateId: searchTemplateId,
      shapeDatasetEntry: getDefaultShapeDatasetEntry(searchTemplateId),
      searchPage: ADVANCE_SEARCH_PAGE.SEARCH_PAGE,
      selectedReportRowIds: [],
      reportPageData: [],
      sortColumns: [],
    }
  };
  // return createStore(reducer, initialState, applyMiddleware(logger));
  const updatedInitialState = updateInitialStateFromUrl(initialState);
  const newConfigStore = (createStore(
    reducer,
    updatedInitialState,
    applyMiddleware(logger, updateStateToUrl)
  ));
  window.configureStore = newConfigStore;
  return newConfigStore
};

const updateStateToUrl = store => next => action => {
  const result = next(action);
  // While adding in history need to add after completion of all store update.
  debounceBrowserHistory(store);
  return result;
};

function updateinBrowserHistory(store) {
  const locationHash = location.hash;
  const isAdvanceSearch = _.includes(locationHash, '!/advanced-search');
  const isAnalysisPage = _.includes(locationHash, '!/analysis');
  let initialHashString = '';
  if (isAnalysisPage) {
    initialHashString = '!/analysis';
  }else if(_.includes(locationHash, '!/app/subscriptions')){
    initialHashString = '!/app/subscriptions';
  } else if(_.includes(locationHash, '!/insights')) {
    initialHashString = '!/insights';
  } else if(_.includes(locationHash, '!/radar')) {
    initialHashString = '!/radar';
  } else if(_.includes(locationHash, '!/forecasting/concepts')) {
    initialHashString = '!/forecasting/concepts';
  }  else if(_.includes(locationHash, '!/forecasting')) {
    initialHashString = '!/forecasting';
  } else if(isAdvanceSearch) {
    initialHashString = '!/advanced-search';
  } else {
    initialHashString = '!/overview';
  }
  const state = store.getState();
  const urlHash = isAdvanceSearch ?
    updateStoreToUrlForAdvanceSearch(initialHashString, state) :
    updateStoreToUrl(initialHashString, state);

  if(isAnalysisPage){
    let lastUrls = window.lastUrls || [];
    let isAnalysisLoaded = window.isAnalysisLoaded || false;
    if(!_.isEqual(_.last(lastUrls), urlHash)){
      if(isAnalysisLoaded){
        console.log('urlHash', urlHash)
        lastUrls = lastUrls.splice(-5, 6);
        lastUrls.push(urlHash);
        window.lastUrls = lastUrls;
      }
      window.isAnalysisLoaded = true;
      window.isUndoEnabled = window.isUndoEnabled || lastUrls.length >= 2;
    }
  }else{
    window.isAnalysisLoaded = false;
    window.lastUrls = [];
    window.isUndoEnabled = false;
  }
  history.push({hash: urlHash });
}

const debounceBrowserHistory = _.debounce(updateinBrowserHistory,
  {leading: false, trailing: true, wait: debounceBrowserHistoryWait});

function updateInitialStateFromUrl(initialState) {
  const locationHash = location.hash;
  const hashString = _.split(locationHash, '?')[1];
  const isAnalysisPage = _.includes(locationHash, '!/analysis');
  const isAdminPage = _.includes(locationHash, '!/admin');
  const isAdvanceSearchPage = _.includes(locationHash, '!/advance-search');
  if (!_.isEmpty(hashString) && !isAdminPage) {
    return updateStoreFromUrl(initialState, hashString, isAnalysisPage);
  } else if (isAdvanceSearchPage) {
    return initialState;
  }
  return initialState;
}

function defaultDateRangeFilters(dateFilters){
  const {
    dateRange,
    relativeDateFilterEntry,
    dateType
  } = dateFilters;
  return {
    dateRange: getCurrentPeriodDateRangeFromState(dateFilters, dateType),
    dateType: getUserPreferenceDateType(dateType, dateRange),
    relativeDateFilterEntry
  };
}

export default configureStore;
