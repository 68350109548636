import _ from 'lodash';
import moment from 'moment';
import {
  DATE_OPTIONS_TYPE,
  EXPONENTIAL_FORECAST,
  HISTORICAL_FORECAST,
  PROPHET_FORECAST,
  DATE_FORMAT,
  FORECASTING_TYPE
} from 'appConstants';
import { YEAR_DAYS_COUNT } from 'helpers/dateHelper';
import { isEndDateOverridden } from 'common/config/viewConfiguration';
import { getConfiguredDataEndDate,
  getConfiguredDateType
} from 'common/config/customerConfiguration';

import { getPeriodType, getTailingDropStartDate, isYearOnYear } from '../vizOptionsHelper';
import {
  getLastCurrentPeriodValue,
  getLinearProjection,
  getHistoricProjections,
  getProphetProjections
} from './projectionDataGenerator';

import { isForecastEndDateIsBeforeToday } from 'pages/Forecasting/ForecastHelper';
const configuredDataEndDate = getConfiguredDataEndDate();
const isFiscalYear = (getConfiguredDateType() == DATE_OPTIONS_TYPE.YEARLY);
const numberOfYears = isFiscalYear ? 3 : 2;

export const YEAR_GROUPING_DAYS_COUNT = (YEAR_DAYS_COUNT*numberOfYears);

export const getProjectionPeriods = (vizOptions) => {
  const { viewEntry, axisGranularity, isForecastingView } = vizOptions;
  const periodType = getPeriodType(vizOptions);

  if(isForecastingView){
    return getForecastProjectionPeriods(vizOptions, periodType)
  }

  let startDateMmt;
  let endDateMmt;
  const projectionTickCount = getProjectionTickCount(viewEntry);
  const tickCount = (axisGranularity === 'quarter') ? Number(projectionTickCount) * 3 : projectionTickCount;

  let projectionStartDate = getProjectionStartDate(vizOptions);
  startDateMmt = moment(projectionStartDate).add(1, periodType);
  endDateMmt = moment(projectionStartDate).add(tickCount, periodType);
  const range = moment().range(startDateMmt, endDateMmt).snapTo(periodType);
  return Array.from(range.by(periodType));
}

const getProjectionStartDate = (vizOptions) => {
  const { axisGranularity } = vizOptions;
  const periodType = getPeriodType(vizOptions);
  const tailingStartDate = getTailingDropStartDate(vizOptions);
  return (axisGranularity === 'default') ?
    tailingStartDate.subtract(1, periodType)
  : tailingStartDate.subtract(1, axisGranularity)
}

const getForecastProjectionPeriods = (vizOptions, periodType) => {
  const { forecastingOption, dateRange, axisGranularity } = vizOptions;

  const futureYear = Number(_.get(forecastingOption, 'projectionFutureYear'));
  const futureMonth = Number(_.get(forecastingOption, 'projectionFutureMonth'));
  const futureDate = moment(`${futureYear}-${futureMonth}-01`, 'YYYY-MM-DD');
  const forecastEndDate = moment(futureDate).endOf('month').format(DATE_FORMAT);

  // To stop the actual trace data after plotting the configuredDataEndDate.
  let dateRangeEndDate = isForecastEndDateIsBeforeToday({dateRange: dateRange}, axisGranularity) ?
    moment(dateRange.endDate).startOf(axisGranularity) : getTailingDropStartDate(vizOptions);

  const range = moment().range(dateRangeEndDate, forecastEndDate).snapTo(periodType);
  return Array.from(range.by(periodType));
}

export const getProjectionDataItems = (
  dataItems, currentPeriods, vizOptions, extraProjectionDataItems, dimension
) => {
  const periodType = getPeriodType(vizOptions);
  const projectionPeriods = getProjectionPeriods(vizOptions);
  const lastCurrentPeriodValue = getLastCurrentPeriodValue(currentPeriods, dataItems, vizOptions, dimension)
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);

  if(_.isEmpty(projectionPeriods)){
    return []
  }
  let  projectionItems = [];

  if(!isForecastingView || _.get(vizOptions, 'isComboChart', false)){
    projectionItems.push(lastCurrentPeriodValue);
  }

  // projectionItems.push(lastCurrentPeriodValue);
  if(_.includes([EXPONENTIAL_FORECAST, PROPHET_FORECAST], vizOptions.projectionType)) {
    projectionItems.push(
      getProphetProjections(projectionPeriods, extraProjectionDataItems, dataItems, periodType,
        vizOptions, lastCurrentPeriodValue, dimension
      )
    );
  } else if(isForecastingView && vizOptions.projectionType == HISTORICAL_FORECAST){
    // DataItems -> new api data
    projectionItems.push(
      getProphetProjections(projectionPeriods, extraProjectionDataItems, dataItems, periodType,
        vizOptions, lastCurrentPeriodValue, dimension
      )
    );
  } else if(vizOptions.projectionType == HISTORICAL_FORECAST){
    // DataItems -> new api data
    projectionItems.push(
      getHistoricProjections(projectionPeriods, dataItems,
        extraProjectionDataItems, vizOptions, periodType, lastCurrentPeriodValue, dimension
      )
    );
  } else if(isForecastingView && vizOptions.projectionType == FORECASTING_TYPE.SIMPLE_EXPONENTIAL) {
    projectionItems.push(
      getProphetProjections(projectionPeriods, extraProjectionDataItems, dataItems, periodType,
        vizOptions, lastCurrentPeriodValue, dimension
      )
    );
  }else {
    projectionItems.push(
      getLinearProjection(projectionPeriods, dataItems, currentPeriods, periodType, dimension)
    );
  }
  return _.flatten(projectionItems);
}

export const getProjectionExtraItems = (apiData, vizOptions, type, historicKey) => {
  let itemKey = '';
  if(_.includes([EXPONENTIAL_FORECAST, PROPHET_FORECAST], vizOptions.projectionType)) {
    itemKey = 'forecasting_data.';
  }else if(vizOptions.projectionType === HISTORICAL_FORECAST) {
    itemKey = 'historic_data.';
  }else if(vizOptions.projectionType === FORECASTING_TYPE.SIMPLE_EXPONENTIAL) {
    itemKey = 'exponential_data.';
  }

  if(type == 'total') {
    return _.get(apiData, `${itemKey}total`, {});
  }else if(type == 'multiple'){
    return _.get(apiData, `${itemKey}${historicKey}`, {});
  }else{
    return _.get(apiData, `${itemKey}entries`, {});
  }
}

export const canShowProjectionMetric = (currentDrilldownViewEntry, chartType) => {
  const { visualization } = currentDrilldownViewEntry;
  const showProjectionMetric = _.get(visualization,'overtime.show_projection_metric','') === 'true';
  const secondaryMetricEntries = _.get(visualization, `overtime.${chartType}.secondary_metric_entries`, []);
  const primaryMetricRenderType = _.get(visualization, `overtime.${chartType}.render_type`);
  const isComboChart = _.get(visualization, `overtime.${chartType}.is_combo_chart`) === 'true';
  const allMetricsAreBarType = _.chain(secondaryMetricEntries).
    map('render_type').
    concat(primaryMetricRenderType).
    every((renderType) => renderType === 'bar').
    value();

  if(isComboChart) {
    return (showProjectionMetric && !allMetricsAreBarType);
  } else {
    return showProjectionMetric;
  }
}

export const getProjectionMetricTypes = (currentDrilldownViewEntry, chartType) => {
  const { visualization } = currentDrilldownViewEntry;
  const projectionTypes = _.get(visualization,'overtime.projection_types', []);
  const forecastOptions = canShowProjectionMetric(currentDrilldownViewEntry, chartType) ?
    projectionTypes :
    [];
  return forecastOptions;
}

export const getDefaultProjectionMetric = (currentDrilldownViewEntry, currentForecastOption = '') => {
  if(!_.isEmpty(currentForecastOption)) {
    return currentForecastOption;
  }
  const { visualization } = currentDrilldownViewEntry;
  const projectionMetricType = _.get(visualization,'overtime.default_enabled_projection_metric', '');

  return projectionMetricType
}

export const disableShowProjection = (
    dateRange, formattedData, timeFrame, viewEntry, axisGranularity = 'month'
  ) => {
  const options = {
    renderTimeFrame: timeFrame,
    dateRange: dateRange
  };
  let disableProjection = (_.isEmpty(_.get(formattedData, 'current.total')) &&
    _.isEmpty(_.get(formattedData, 'current.entries')));

  const endDate = _.get(dateRange, 'endDate');
  let periodType = axisGranularity;
  if (isYearOnYear({ renderTimeFrame: timeFrame }) || axisGranularity == 'default'){
    periodType = getPeriodType(options);
  }


  if(isEndDateOverridden(viewEntry)) {
    return true;
  }

  if (!isAboveConfiguredEndPeriod(endDate, periodType)) {
    return true;
  }

  return disableProjection;
}

//TODO: Reuse this function when we hide linear projection
// Linear projection need more than 2 periods data to draw
export const hasLinearDataToShowProjection = (formattedData) => {
  let isTickValue = false;
  if(!_.isEmpty(_.get(formattedData, 'current.total'))) {
    isTickValue = (_.chain(_.get(formattedData, 'current.total')).map((entry) => {
      return entry['value'];
    }).compact().value().length) >= 2;
  } else {
    isTickValue = !_.isEmpty(_.find(_.get(formattedData, 'current.entries'), (entries) => {
      const values = _.filter(entries, 'value');
      return _.compact(values).length >= 2;
    }));
  }

  let projectionCount = 0;
  if(!_.isEmpty(_.get(formattedData, 'projection.total'))) {
    _.forEach(_.get(formattedData, 'projection.total'), (entry) =>{
      const value = _.toString(entry['value']);
      if(!_.isEmpty(value) && !_.isNaN(entry['value'])) {
        projectionCount = projectionCount + 1;
      }
    })
  }

  return (isTickValue || projectionCount > 2)
}

export const isAboveConfiguredEndPeriod = (date, periodType) => {
  if(_.isEmpty(date)) {
    return false
  }

  return (
    moment(date).isAfter(configuredDataEndDate, periodType)) ||
    moment(date).isSame(configuredDataEndDate, periodType
  );
}

const getProjectionTickCount = (viewEntry) => {
  const { visualization } = viewEntry;
  return Number(_.get(visualization,'overtime.projection_tick_count','6'));
}