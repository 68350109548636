import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import abortableFetch from 'common/abortableFetch';
import { responseHandler } from 'helpers/apiResponseHelper';

import { getSecondaryMetricEntries } from 'helpers/visualizationHelper';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import DateRangeInputs from 'common/components/DateRangeInputs';
import { getEntityCountsUrl } from 'common/api/commonApi';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import { getRelativeDateFilterEntry } from 'helpers/commonFiltersHelper';
import {
  SNAPSHOT_VISUALIZATION_TYPES,
  DEFAULT_SELECT_DROPDOWN_OPTION,
  VARIANCE_MATH_VALUE_OPTIONS,
  DATE_TIME_FORMAT,
  VARIANCE_COMPARISON_OPTIONS
} from 'appConstants';
import MultiSelectDropdown from 'common/components/MultiSelectDropdown/MultiSelectDropdown';
import * as commonPropTypes from 'common/propTypes';

class VarianceFilters extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      secondaryMetricField: _.get(props, 'secondaryMetricField'),
      sampleSize: _.get(props, 'sampleSize'),
      mathValueType: _.get(props, 'mathValueType'),
      mathValueThreshold: _.get(props, 'mathValueThreshold'),
      sortOrder: _.get(props, 'sortOrder'),
      // comparatorType: _.get(props, 'comparatorType'),
      resultLimit: _.get(props, 'resultLimit', 10),
      // restrictToRoot: _.get(props, 'restrictToRoot', false),
      secondaryDateRange: _.get(props, 'secondaryDateRange', {}),
      primaryDataRange: _.get(props, 'primaryDataRange', {}),
      selectedDimensions: _.get(props, 'selectedDimensions', [])
    };
  }

   fetchEntityCounts() {
    const { currentDrilldownTemplateId, apiParams } = this.props;
    const dimensionsEntities = getDimensionEntries(currentDrilldownTemplateId);
    const dimensionsEntityFields = _.map(dimensionsEntities, 'field');

    const queryParams = {
      ...apiParams,
      entityFields: JSON.stringify(dimensionsEntityFields)
    };

    const entityCountsUrl = getEntityCountsUrl(queryParams);

    abortableFetch(entityCountsUrl, { signal: this.abortFetchController.signal })
      .then(response => response.json()).then(responseHandler).then((response) => {
        console.log(response);
        this.setState({dimensionsCounts: response});
      }).catch((error) => {
        console.log("Error on fetching Totals data", error);
    });
  }

  onDateFilterChange = (dateType, dateRange, relativeDateFilterEntry) => {
    const { onDateFilterChange } = this.props;
    this.setState({showDatePickerDropDown: false});
    onDateFilterChange({
      dateType,
      dateRange,
      relativeDateFilterEntry: getRelativeDateFilterEntry(relativeDateFilterEntry)
    });
  }

  handleSecondaryMetricChange = (entry) => {
    this.setState({ secondaryMetricField: entry['field']});
  }

  handleSampleSizeChange = (evt) => {
    if(evt.target.validity.valid) {
      this.setState({sampleSize: evt.target.value });
    }
  }

  handleThresholdChange = (e) => {
    if(e.target.validity.valid) {
      this.setState({mathValueThreshold: e.target.value });
    }
  }

  handleLimitChange = (e) => {
    if(e.target.validity.valid) {
      this.setState({resultLimit: e.target.value });
    }
  }

  /*handleRestrictRootChange = (e) => {
    const { restrictToRoot } = this.state;
    this.setState({ restrictToRoot: !restrictToRoot });
  }*/

  handleDropDownChange = (option, field) => {
    this.setState({[field]: option['value'] });
  }

  handleApplyClick = () => {
    const { onApply } = this.props;
    onApply({..._.cloneDeep(this.state)});
  }

  handleDimensionChange = (selectedDimensionEntries) => {
    const selectedDimensions = _.map(selectedDimensionEntries, 'field');
    this.setState({ selectedDimensions });
  }

  handelDateChange = (date, dateRangeType, dateType ) => {
    let dateRange = _.clone(this.state[dateRangeType])
    this.setState({
      [dateRangeType]: {
        ...dateRange,
        [dateType]: moment(date).format("YYYY-MM-DD")
      }
    })
  }

  renderDropDown(options, title, field) {
    const currentOption = _.find(options, ['value', this.state[field]]);
    const optionTitleText = _.get(currentOption,'name');

    return (
      <div>
        <label className="custom-label text-nowrap">{title}</label>
        <SolutionDropDown
          size='sm custom-placeholder'
          title={optionTitleText}
          options={options}
          onSelect={(option) => this.handleDropDownChange(option, field)} />

      </div>
    );
  }

  renderVarianceOptions() {
    const { comparisonOption, onChangeCompareOption } = this.props;
    const comparisonControls = (_.map(VARIANCE_COMPARISON_OPTIONS, (entry, index) => {
      return (
        <div className="form-check">
          <input
            key={index}
            id={_.get(entry, 'type')}
            className="form-check-input"
            type="radio"
            onChange={() => onChangeCompareOption(entry.type)}
            checked={_.get(entry, 'type') === (comparisonOption)} />
          <label className="form-check-label" htmlFor={_.get(entry, 'type')}>
            {_.get(entry, 'name')}
          </label>
        </div>
      )})
    );

    return (
      <fieldset className="form-group">
        <div className="row">
          <label className="col-form-label col-sm-2 pt-0">Compare with</label>
          <div className="col-sm-10 mt-1">{comparisonControls}</div>
        </div>
      </fieldset>
    );
  }

  renderDimensionEntries(){
    const { currentDrilldownTemplateId, drilledDownDimensions } = this.props;
    const { selectedDimensions } = this.state;
    const dimensionsEntities = getDimensionEntries(currentDrilldownTemplateId);
    const dimensionsExceptDrilldowns = _.filter(dimensionsEntities, (entry) => {
      return !_.includes(_.map(drilledDownDimensions, 'field'), entry['field']);
    })
    const selectedOptions = _.filter(dimensionsExceptDrilldowns, (entry) =>{
      return _.includes(selectedDimensions, entry['field']);
    });

    return(
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Dimensions</label>
        <div className="col-sm-10 mt-2">
          <MultiSelectDropdown
            title="Select Dimensions"
            variant="link p-0"
            options={dimensionsExceptDrilldowns}
            selectedOptions={selectedOptions}
            displayNameField="outline_name"
            onOptionChange={this.handleDimensionChange} />
          </div>
      </div>
    )
  }

  renderDimensionEntity() {
    const {
      currentDrilldownTemplateId,
      comparisonOption,
      onSelectEntity,
      validationErrors,
      selectedDimensionEntity } = this.props;
    const dimensionsEntities = getDimensionEntries(currentDrilldownTemplateId);

    if(comparisonOption !== _.get(VARIANCE_COMPARISON_OPTIONS, [2, 'type'])) {
      return null;
    }
    const isValidationFailed = !_.isEmpty(validationErrors['dimensionEntity']);
    const entityClassNames = classNames('col-sm-10 mt-2', {
      'error-highlight': isValidationFailed
    });

    return(
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Entity</label>
        <div className={entityClassNames}>
          <SolutionDropDown
            title={_.get(selectedDimensionEntity, 'name', 'Select Entity')}
            size="sm"
            options={dimensionsEntities}
            onSelect={onSelectEntity} />
            {
              isValidationFailed &&
              <label className="validation-error-text">Please select entity</label>
            }
        </div>
      </div>
    )
  }

  renderPrimaryDateRangeInputs(){
    const { primaryDataRange } = this.state;

    const startDateObj = new Date(moment(_.get(primaryDataRange, 'startDate')).format(DATE_TIME_FORMAT));
    const endDateObj =  new Date(moment(_.get(primaryDataRange, 'endDate')).format(DATE_TIME_FORMAT));
    return (
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Primary Date Range</label>
        <div className="col-sm-10 mt-1 d-flex">
          <DateRangeInputs
            dateRangeInputClassNames="datepicker-input form-control-sm"
            startDateContainerClassNames="mr-2"
            startDate={startDateObj}
            endDate={endDateObj}
            onChangeStartDate={(date) => this.handelDateChange(date, 'primaryDataRange', 'startDate')}
            onChangeEndDate={(date) => this.handelDateChange(date, 'primaryDataRange', 'endDate') }/>
        </div>
      </div>
    );
  }

  // renderPrimaryDateRangeInputs() {

  //   const { primaryDataRange } = this.state;

  //   const dateProps = {
  //     dateRange: _.get(primaryDataRange, getDefaultDateRange(relativeDateFilterEntryDefaultState)),
  //     dateType: configuredDefaultDateType,
  //     relativeDateFilterEntry: relativeDateFilterEntryDefaultState
  //   };

  //   return(
  //     <DateRangeFilterWithBadge
  //       {...dateProps}
  //       filterName=" "
  //       onDateFilterChange={this.handleDateFilterChange} />
  //   )
  // }

  renderSecondaryDateRangeInputs() {
    const { secondaryDateRange } = this.state;
    const { comparisonOption } = this.props;

    if(comparisonOption !== _.get(VARIANCE_COMPARISON_OPTIONS, [0, 'type'])) {
      return null;
    }

    const startDateObj = new Date(moment(_.get(secondaryDateRange, 'startDate')).format(DATE_TIME_FORMAT));
    const endDateObj =  new Date(moment(_.get(secondaryDateRange, 'endDate')).format(DATE_TIME_FORMAT));
    return (
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Secondary Date Range</label>
        <div className="col-sm-10 mt-1 d-flex">
          <DateRangeInputs
            dateRangeInputClassNames="datepicker-input form-control-sm"
            startDateContainerClassNames="mr-2"
            startDate={startDateObj}
            endDate={endDateObj}
            onChangeStartDate={(date) => this.handelDateChange(date, 'secondaryDateRange', 'startDate')}
            onChangeEndDate={(date) => this.handelDateChange(date, 'secondaryDateRange', 'endDate') }/>
        </div>
      </div>
    );
  }

  renderApplyButton() {
    return(
      <>
        <button
          className="btn btn-primary align-self-center mt-2"
          onClick={this.handleApplyClick}> Run Variance Analysis
        </button>
      </>
    )
  }

  /*renderRootLevelResultOption() {
    return(
      <div className="clearfix ml-10">
        <input
          type="checkbox"
          checked={this.state.restrictToRoot}
          className="custom-control-input"
          onChange={this.handleRestrictRootChange}
          id="rool-level-option" />
        <label className="custom-control-label" htmlFor="rool-level-option">
          Restrict root level
        </label>
      </div>
    )
  }*/

  renderSortOrder() {
    const options = [
      {name: 'Descending', value: 'desc'},
      {name: 'Ascending', value: 'asc'},
      {name: 'Score', value: 'score'}
    ];
    return (
      <div className="col-md-4">
        {this.renderDropDown(options, 'Sort Order', 'sortOrder')}
      </div>
    );
  }

  renderMathOptions() {
    const { comparisonOption } = this.props;
    const options = _.values(VARIANCE_MATH_VALUE_OPTIONS);

    if(comparisonOption === _.get(VARIANCE_COMPARISON_OPTIONS, [2, 'type'])) {
      return null;
    }

    return (
      <div className="col-md-4 mb-2">
        {this.renderDropDown(options, 'Math Option', 'mathValueType')}
      </div>
    );
  }

  /*renderPrimaryOptions(){
    const options = [
      {name: "Primary metric as standard and secondary metric as basis of comparison", value: "primary"},
      {name: "Secondary metric as standard and primary metric as basis of comparison", value: "secondary"},
      {name: "Primary metric as standard and basis of comparison", value: "primary-only"},
      {name: "Secondary metric as standard and basis of comparison", value: "secondary-only"}
    ];
    return this.renderDropDown(options, 'Comparator', 'comparatorType');
  }*/

  renderSecondaryMetricsDropdown() {
    const { viewEntry, comparisonOption } = this.props;
    const { secondaryMetricField } = this.state;

    const secondaryMetrics = getSecondaryMetricEntries(viewEntry, 'snapshot',
      SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type);
    const currentSecondaryMetricEntry = _.find(secondaryMetrics, ['field', secondaryMetricField]);
    const titleText = _.get(currentSecondaryMetricEntry,'name') ||
      _.get(DEFAULT_SELECT_DROPDOWN_OPTION, 'name');

    if(comparisonOption !== _.get(VARIANCE_COMPARISON_OPTIONS, [1, 'type'])) {
      return null;
    }

    if(_.isEmpty(secondaryMetrics)) {
      return (
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Secondary Metric</label>
          <div className="col-sm-10 mt-2">
            <small className="text-muted text-center d-block">N/A</small>
          </div>
        </div>
      );
    }

    return (
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Secondary Metric</label>
        <div className="col-sm-10 mt-2">
          <SolutionDropDown
            size='sm custom-placeholder'
            title={titleText}
            options={secondaryMetrics}
            onSelect={this.handleSecondaryMetricChange} />
          </div>
      </div>
    );
  }

  renderSampleSize() {
    return(
      <div className="col-md-4 mb-2">
        <label className="custom-label text-nowrap">Sample Size</label>
          <input
            className="form-control"
            type="text"
            pattern="[0-9]*"
            onChange={(e) => this.handleSampleSizeChange(e)}
            value={this.state.sampleSize} />
      </div>
    )
  }

  renderLimit() {
    return(
      <div className="col-md-4">
        <label className="custom-label text-nowrap">Result limit</label>
          <input
            className="form-control"
            type="text"
            pattern="[0-9]*"
            onChange={(e) => this.handleLimitChange(e)}
            value={this.state.resultLimit} />
      </div>
    )
  }

  renderThreshold(){
    const { comparisonOption } = this.props;
    if(comparisonOption === _.get(VARIANCE_COMPARISON_OPTIONS, [2, 'type'])) {
      return null;
    }

    return(
      <div className="col-md-4 mb-2">
        <label className="custom-label text-nowrap">Threshold</label>
        <input
          type='tel'
          className="form-control"
          value={this.state.mathValueThreshold}
          onChange={this.handleThresholdChange}
          pattern="^-?[0-9]\d*\.?\d*$" />
      </div>
    )
  }

  render() {
    return(
      <div>
        {this.renderPrimaryDateRangeInputs()}
        {this.renderVarianceOptions()}
        {this.renderDimensionEntity()}
        {this.renderSecondaryMetricsDropdown()}
        {this.renderSecondaryDateRangeInputs()}
        {this.renderDimensionEntries()}
        <div className="form-inline">
          {this.renderMathOptions()}
          {this.renderSampleSize()}
          {this.renderThreshold()}
          {this.renderLimit()}
          {this.renderSortOrder()}
        </div>
        {/* {this.renderPrimaryOptions()} */}
        {/* {this.renderRootLevelResultOption()} */}
        {this.renderApplyButton()}
      </div>
    )
  }
}

VarianceFilters.propTypes = {
  comparisonOption: PropTypes.string,
  secondaryMetricField: PropTypes.string,
  sampleSize: PropTypes.number,
  mathValueType: PropTypes.string,
  mathValueThreshold: PropTypes.number,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onChangeCompareOption: PropTypes.func,
  onSelectEntity: PropTypes.func,
  validationErrors: PropTypes.object,
  drilledDownDimensions: PropTypes.array,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  apiParams: PropTypes.object,
  onDateFilterChange: PropTypes.func,
  onApply: PropTypes.func,
  selectedDimensionEntity: PropTypes.object,
  viewEntry: commonPropTypes.viewEntryPropTypes
  // comparatorType: PropTypes.oneOf(['primary', 'secondary', 'primary-only', 'secondary-only'])
}

export default VarianceFilters;
