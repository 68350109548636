import { VIEW_MODE } from 'modules/visualization/constants';
import { PIE_CHART_ANNOTATION_OPTIONS } from 'appConstants';
import { formatValueToCurrency } from 'helpers/numberHelper';

// Input:
//  formattedData =>
//      [
//        {
//          color: 'white',
//          label: 'High',
//          value: 21.98,
//          text: '$ 21.98 Funds',
//        },
//        ...
//      ]
export const toPlotlyTraces = (vizOptions, _apiData, formattedData) => {
  const { pieChartAnnotationOptions, viewMode, isCurrencyDimensionField } = vizOptions;
  const isAnnotaionDisabled = _.every(pieChartAnnotationOptions, (value) => {
    return (value === false);
  });
  const textTemplate = getFormattedAnnotationText(
    formattedData,
    pieChartAnnotationOptions,
    isCurrencyDimensionField);
  let data = [
    {
      "labels": formattedData.map((d) => d.label),
      "values": formattedData.map((d) => d.value),
      "type":"pie",
      "automargin":true,
      "textinfo": (viewMode === VIEW_MODE.SMALL) ? "none" : "",
      "hovertext":formattedData.map((d) => d.text),
      "marker":{
        "colors": formattedData.map((d) => d.color)
      }
    }
  ];
  let layout = {
    "autosize":true,
    "margin": { "l": 10, "r": 10, "b": 20, "t": 20, "pad": 2 },
    "itemclick": false,
    "showlegend": false,
    "dragmode": false
  };

  const config = {
    "displayModeBar":false,
    "scrollZoom":false,
    "editable":false,
    "showLink":false,
    "responsive":true
  };

  if (viewMode === VIEW_MODE.LARGE) {
    layout = _.merge({}, layout, { "height": 530 });
    data = [_.merge({}, data[0], {
      "textinfo": isAnnotaionDisabled ? 'none' : '',
      "textposition": "outside",
      "texttemplate": isAnnotaionDisabled ? '' : textTemplate
    })];
  }

  return { data, layout, config };
}

const getFormattedAnnotationText = (
  formattedData,
  pieChartAnnotationOptions,
  isCurrencyDimensionField
  ) => {
  const annotationOptions = _.chain(PIE_CHART_ANNOTATION_OPTIONS).values().map('type').value();
  const totalValue = _.sumBy(formattedData, (datum) => Number(_.get(datum, 'value', 0)));

  return _.map(formattedData, (datum) => {
    let formattedStringArr = [];
    _.each(annotationOptions, (option) => {
      if(_.get(pieChartAnnotationOptions, option, false)) {
        let newOptionType = '';
        if(option === PIE_CHART_ANNOTATION_OPTIONS.PERCENTAGES.type) {
          newOptionType = `(${getPercentage(totalValue, _.get(datum, 'value'))})`;
        } else if(option === PIE_CHART_ANNOTATION_OPTIONS.VALUES.type) {
          newOptionType = _.get(datum, 'text');
        } else {
          newOptionType = `${formatValueToCurrency(_.get(datum, option), isCurrencyDimensionField)}<br>`;
        }
        formattedStringArr.push(newOptionType);
      }
    });

    return formattedStringArr.join(' ');
  });
}

// TODO: move to helper file.
const getPercentage = (totalValue, value) => {
  if(_.isUndefined(totalValue) || _.isUndefined(value)) {
    return;
  }

  return `${((value/totalValue) * 100).toFixed(2)}%`
}
