import React from 'react';
import PropTypes from 'prop-types';
import ColorWithCategorySelector from 'common/components/ColorWithCategorySelector/ColorWithCategorySelector';

const FlyoutTableHeader = ({ tableHeaders, showEmptyHead }) => {
  const tableHeaderContent = _.map(tableHeaders, (tableHeaderItem) => {
    const { name, color } = tableHeaderItem;
    return (
      <th key={name}>
        <ColorWithCategorySelector category={name} color={color} />
      </th>
    );
  });

  return(
    <thead>
      <tr>
        {showEmptyHead && <th key="default-table-head"></th>}
        {tableHeaderContent}
      </tr>
    </thead>
  );
}

FlyoutTableHeader.propTypes = {
  tableHeaders: PropTypes.array,
  showEmptyHead: PropTypes.bool
}

export default FlyoutTableHeader;
