import * as actionTypes from '../actionTypes/overtimeActionTypes';

export const updateAxisGranularity = (axisGranularity) => ({
  type: actionTypes.UPDATE_AXIS_GRANULARITY,
  axisGranularity
});

export const updateOvertimeDimensionSortOrder = (dimensionSortOrder) => ({
  type: actionTypes.UPDATE_OVERTIME_DIMENSION_SORT_ORDER,
  dimensionSortOrder
});

export const updateCurrentForecastOption = (currentForecastOption) => ({
  type: actionTypes.UPDATE_FORECAST_OPTION,
  currentForecastOption
});

export const updateOvertimeBenchMarkName = (name) => ({
  type: actionTypes.UPDATE_OVERTIME_BENCH_MARK_NAME,
  name
});

export const updateOvertimeChartView = (selectedView) => ({
  type: actionTypes.UPDATE_OVERTIME_CHART_VIEW,
  selectedView
});

export const updateDimensionConfigsByType = (dimensionConfigsByRenderType) => ({
  type: actionTypes.UPDATE_OVERTIME_LEGENDS,
  dimensionConfigsByRenderType
});

export const updateOvertimeSecondaryMetricField = (field) => ({
  type: actionTypes.UPDATE_OVERTIME_SECONDARY_METRIC,
  field
});

export const updateOvertimeShowProjection = (isShowProjection) => ({
  type: actionTypes.UPDATE_OVERTIME_SHOW_PROJECTION,
  isShowProjection
});

export const updateOvertimeTimeFrame = (timeFrame) => ({
  type: actionTypes.UPDATE_OVERTIME_TIME_FRAME,
  timeFrame
});

export const updateSliderRange = (sliderRange) => ({
  type: actionTypes.UPDATE_SLIDER_RANGE,
  sliderRange
});

export const updateVisualizationDefaults = (options) => ({
  type: actionTypes.UPDATE_VISUALIZATION_DEFAULTS,
  options
});

export const updateOvertimeShowLegendTotal = (showLegendTotalLine) => ({
  type: actionTypes.UPDATE_OVERTIME_SHOW_LEGEND_TOTAL,
  showLegendTotalLine
});

export const updateOvertimeIsEmptyLegend = (isLegendItemsEmpty) => ({
  type: actionTypes.UPDATE_OVERTIME_IS_EMPTY_LEGEND,
  isLegendItemsEmpty
});

