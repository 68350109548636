import React, { useState } from "react";
import { ForgeTabBar, ForgeTab, ForgeViewSwitcher, ForgeView } from '@tylertech/forge-react';

import CollaborateUsersTable from "./CollaborateUsersTable";
import CollaborateActivity from "./CollaborateActivity";

import { COLLABORATOR_TABS } from "./constants";

const CollaborateTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const updateViewIndex = (event) => {
    setActiveTab(event.detail.index);
  }

  return (
    <div role="tablist">
      <ForgeTabBar on-forge-tab-bar-activate={updateViewIndex} activeTab={activeTab} underline>
        {_.map(COLLABORATOR_TABS, ({name}) =>(<ForgeTab key={name}>{name}</ForgeTab>))}
      </ForgeTabBar>
      <ForgeViewSwitcher index={activeTab}>
        <ForgeView role="tabpanel">
          <CollaborateUsersTable />
        </ForgeView>
        <ForgeView role="tabpanel">
          {activeTab == 1 ? <CollaborateActivity /> : null}
        </ForgeView>
      </ForgeViewSwitcher>
    </div>
  );
}

export default CollaborateTabs;
