import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isLocationsConfigured } from '../helpers/MapOptionsHelper';
import {
  updateDrawingStateChange,
  removePolygonFilter,
  toggleShapeIdsFilter,
  updatePolygonFeature
} from 'actions/mapOptionsActions';
import { updateDrilldownDimensionField } from 'actions/drilldownActions';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import { ForgeButton } from '@tylertech/forge-react';
import CustomizedForgeButton from 'common/components/CustomizedForgeButton';
import { POLYGON_DRAWING_HELPER_TEXT } from 'modules/visualization/constants';

class PolygonFilterButton extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  handleMapPolygonFilter = () => {
    this.props.dispatchUpdateDrawingStateChange(true);
  }

  handleCompleteClick = () => {
    const {polygonFeatures, updatePolygonFeatures} = this.props;
    this.props.dispatchUpdateDrawingStateChange(false);
    this.props.dispatchUpdatePolygonFeature({ featuresGeojson: polygonFeatures });
    updatePolygonFeatures({});
  }
  
  handleClickCancelButton = () => {
    const {
      currentDrilldownDimensionField,
      currentDrilldownTemplateId,
      dispatchResetAllFilters,
      dispatchUpdateDrilldownDimensionField,
      updatePolygonFeatures
    } = this.props;

    if(_.isEmpty(currentDrilldownDimensionField)){
      const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
      dispatchUpdateDrilldownDimensionField(_.first(dimensionsEntries)['field']);
    }
    
    dispatchResetAllFilters();
    updatePolygonFeatures({});
  }

  renderDrawButton() {
    const { isDrawingEnabled, polygonsGeojson, currentDrilldownTemplateId } = this.props;

    if(!isLocationsConfigured(currentDrilldownTemplateId)){
      return null;
    }
    if(!_.isEmpty(_.get(polygonsGeojson, 'features')) || isDrawingEnabled) {
      return null;
    }

    return (
      <>
        <OverlayTrigger
          key="create-polygon-button"
          placement="left"
          overlay={
            <Tooltip id="tooltip-top">
              <div className="text-left">Click to apply a custom geo filter.</div>
            </Tooltip>
          }>
          <ForgeButton type="outlined" className="map-forge-btn">
            <button
              className="view-watch-btn"
              aria-label="polygon filter"
              onClick={this.handleMapPolygonFilter}>
              <i className="icons-tactic"></i>
              <span>Draw</span>
            </button>
          </ForgeButton>
        </OverlayTrigger>
      </>
    );
  }

  renderDrawingInProgressOverlay() {
    const { isDrawingEnabled, polygonFeatures } = this.props;

    if(!isDrawingEnabled) {
      return null;
    }

    const cancelDrawingBtnContent = <button
      className="btn btn-link cancel-btn align-self-center"
      onClick={() => this.handleClickCancelButton()}>Cancel</button>;

    return (
      <div className="map-custom-control-overlay d-flex justify-content-between">
        <div className="pl-2 pr-2 align-self-center">
          {POLYGON_DRAWING_HELPER_TEXT}
        </div>
        <div className="d-flex align-items-center gap-10">
          {!_.isEmpty(polygonFeatures) && 
            <CustomizedForgeButton text="Complete" onClick={this.handleCompleteClick}/>}
          {cancelDrawingBtnContent}
        </div>
      </div>
    );
  }

  render() {
    return(
      <div className="map-custom-control">
        {this.renderDrawButton()}
        {this.renderDrawingInProgressOverlay()}
      </div>
    );
  }
}

PolygonFilterButton.propTypes = {
  currentDrilldownDimensionField: PropTypes.string,
  dispatchUpdateDrilldownDimensionField: PropTypes.func,
  dispatchUpdateDrawingStateChange: PropTypes.func,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatchResetAllFilters: PropTypes.func,
  isDrawingEnabled: PropTypes.bool,
  polygonsGeojson: PropTypes.object,
  dispatchUpdatePolygonFeature: PropTypes.func,
  updatePolygonFeatures: PropTypes.func,
  polygonFeatures: PropTypes.object
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateDrawingStateChange: (toggle) => {
      dispatch(updateDrawingStateChange(toggle))
    },
    dispatchResetAllFilters: () => {
      dispatch(removePolygonFilter());
      dispatch(toggleShapeIdsFilter([], null));
    },
    dispatchUpdateDrilldownDimensionField: (field) => {
      dispatch(updateDrilldownDimensionField(field));
    },
    dispatchUpdatePolygonFeature: (multipolygonFeature) => {
      dispatch(updatePolygonFeature(multipolygonFeature))
    }
  }
}

function mapStateToProps(state) {
  return {
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    isDrawingEnabled: _.get(state, 'visualization.mapOptions.isDrawingEnabled', false),
    polygonsGeojson: _.cloneDeep(_.get(state,'visualization.mapOptions.filteredGeojson',[]))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PolygonFilterButton);
