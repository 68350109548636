import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getFormattedTableValue, getTableDataValue, varianceFormattingIds } from './InsightsHelper';

class TableDataRender extends PureComponent{
  constructor(props, context) {
    super(props, context);

    const { result, tableOption, scoreWeightMathValueType } = this.props;
    this.state = {
      value: getTableDataValue(result, tableOption, scoreWeightMathValueType)
    };
  }

  arrowClassNames = () => {
    const { tableOption } = this.props;
    const { value } = this.state;

    let arrowClassNames = null;
    if(_.includes(varianceFormattingIds, tableOption['id'])){
      arrowClassNames = classNames({
        'icons-arrow-up2': value > 0,
        'icons-arrow-down2': value < 0
      });
    }

    return arrowClassNames;
  }

  render(){ 
    const { value } = this.state; 
    const { tableOption, ViewEntry } = this.props;
    return (
      <div key={tableOption['id']}>
        <i className={this.arrowClassNames()} />
        {getFormattedTableValue(tableOption, ViewEntry, value)}
      </div>
    );
  }
}

TableDataRender.propTypes = {
  result: PropTypes.object,
  tableOption: PropTypes.object,
  ViewEntry: PropTypes.object,
  scoreWeightMathValueType: PropTypes.string
}

export default TableDataRender;
