import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Popover } from 'react-bootstrap';
import { DEFAULT_COLOR_PALETTE, HEADER_THEME_COLOR } from 'appConstants';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeButton, ForgeRadio } from '@tylertech/forge-react';
import ForgeInputTextField from 'common/components/ForgeInputFields/ForgeInputTextField';
import { checkValidUrl } from 'common/api/commonApi';
import LoadingSpinner from 'common/components/LoadingSpinner';

class Branding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnableSave: false,
      isLoading: false,
      isValidUrl: false,
      invalidText: '',
      isTextColorPaletteFocused: false,
      isBgColorPaletteFocused: false,
      browserTitle: '',
      brandingTitle: '',
      brandingFaviconUrl: '',
      customerLogoUrl: '',
      headerBarTheme: ''
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.getBrandings();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { configData } = this.props;
    if (!_.isEqual(configData, prevProps.configData)) {
      this.getBrandings();
    }
    this.changeSaveButtonStatus();
  }

  changeSaveButtonStatus() {
    const { configData } = this.props;
    const { brandingTitle, browserTitle, brandingFaviconUrl, headerBarTheme,
      customerLogoUrl, isEnableSave } = this.state;

    const isEqualBrandings = (_.isEqual(_.get(configData, 'browser_title', ''), browserTitle || '') &&
      _.isEqual(_.get(configData, 'title', ''), brandingTitle || '') &&
      _.isEqual(_.get(configData, 'favicon_url', ''), brandingFaviconUrl || '') &&
      _.isEqual(_.get(configData, 'customer_logo_url', ''), customerLogoUrl || '') &&
      _.isEqual(_.get(configData, 'header_bar_theme', ''), headerBarTheme || ''))

    if (!isEqualBrandings && !isEnableSave) {
      this.updateButtonStatus(true)
    } else if (isEqualBrandings && isEnableSave) {
      this.updateButtonStatus(false)
    }
  }

  updateButtonStatus = (isEnableSave) => {
    this.setState({
      isEnableSave
    });
  }

  handleClickOutside = (e) => {
    setTimeout(() => {
      if (this.headerTextcolorPaletteRef && !this.headerTextcolorPaletteRef.contains(e.target)) {
        this.setState({ isTextColorPaletteFocused: false });
      }

      if (this.headerBgColorPaletteRef && !this.headerBgColorPaletteRef.contains(e.target)) {
        this.setState({ isBgColorPaletteFocused: false });
      }
    }, 100);
  }

  getBrandings() {
    const { configData } = this.props;

    if (!_.isEmpty(configData)) {
      this.setState({
        browserTitle: configData['browser_title'],
        brandingTitle: configData['title'],
        brandingFaviconUrl: configData['favicon_url'],
        customerLogoUrl: configData['customer_logo_url'],
        headerBarTheme: configData['header_bar_theme']
      });
      this.checkImagUrl(configData['customer_logo_url']);
    }
  }

  checkImagUrl = async (imageUrl) => {
    this.setState({ isLoading: true });
    try{
      const response = await checkValidUrl({image_url: imageUrl});
      const isValidUrl = response?.is_valid_url;
      const responseCode = response?.status;
      const invalidText = _.includes([200, 403], responseCode) ? '' : 'Invalid URL';
      this.setState({ isLoading: false, isValidUrl, invalidText });
    }catch(e){
      this.setState({ isLoading: false, isValidUrl: false, invalidText: 'Invalid URL' });
    }
  }

  handleChangeBranding = (event, field) => {
    this.checkImagUrl(event.target.value)
    this.setState({ [field]: event.target.value }, () => {
      this.handleUpdatedBrandings();
    })
  }

  handleSelectColorButtonPressed = (event, colorField, color) => {
    if (isEnterButtonPressed(event)) {
      this.handleClickColor(event, colorField, color);
      this.handleClickOutside(event);
    }
  }

  handleClickColor = (e, field, color) => {
    this.setState({ [field]: color }, () => {
      this.handleUpdatedBrandings();
      if (field === 'headerTextColor') {
        this.headerTextcolorPaletteRef.focus();
      } else {
        this.headerBgColorPaletteRef.focus();
      }
    })
  }

  onHeaderBarThemeChange = (headerTheme) => {
    this.setState({ headerBarTheme: headerTheme }, () => {
      this.handleUpdatedBrandings();
    })
  }

  toggleColorPalette = (field, toggle) => {
    this.setState({ [field]: !toggle })
  }

  handleFocusColorPalette = (e, field, isFocused) => {
    if (isEnterButtonPressed(e)) {
      this.toggleColorPalette(field, isFocused)
    }
  }

  handleUpdatedBrandings() {
    const { onHandleConfigurationChange } = this.props;
    const {
      browserTitle, brandingTitle, brandingFaviconUrl,
      customerLogoUrl, headerBarTheme
    } = this.state;

    const brandingKeyValue = [
      { app_config_key: 'branding.browser_title', config_value_to_update: browserTitle },
      { app_config_key: 'branding.title', config_value_to_update: brandingTitle },
      { app_config_key: 'branding.favicon_url', config_value_to_update: brandingFaviconUrl },
      { app_config_key: 'branding.customer_logo_url', config_value_to_update: customerLogoUrl },
      { app_config_key: 'branding.header_bar_theme', config_value_to_update: headerBarTheme }
    ];
    onHandleConfigurationChange({ configKeyValues: brandingKeyValue, isBrandingChange: true });
  }

  colorPaletteFills = (colorField) => {
    return DEFAULT_COLOR_PALETTE.map((color, index) => {
      const bgColorStyle = { backgroundColor: color }
      return (
        <div key={index} style={bgColorStyle} tabIndex="0"
          onKeyDown={(e) => this.handleSelectColorButtonPressed(e, colorField, color)}
          onClick={(e) => this.handleClickColor(e, colorField, color)}
          aria-label="Pickable color" className="color-bucket"></div>
      );
    });
  }

  renderPopover = (colorField) => {
    return (<Popover id="popover-basic">
      <div>
        <div className="color-bucket-wrapper">
          <input type="text" id="branding-color-fill"
            className="form-control mb-3" autoFocus={true}
            onChange={(event) => this.handleChangeBranding(event, colorField)} />
          <div className="color-buckets">
            {this.colorPaletteFills(colorField)}
          </div>
        </div>
      </div>
    </Popover>
    )
  }

  renderSaveButton() {
    const { isEnableSave } = this.state;
    return (
      <ForgeButton type="raised">
        <button type="button" onClick={() => this.props.onSaveClick()}
          disabled={!isEnableSave}
          tabIndex={isEnableSave ? 0 : -1}> Save</button>
      </ForgeButton>
    );
  }

  renderValidationText = () => {
    const { isLoading, isValidUrl, invalidText } = this.state;
    return (
      <>
        <LoadingSpinner isLoading={isLoading}></LoadingSpinner>
        { isLoading || isValidUrl ? null :
          <div className="validation-text">
            <div className="forge-typography--error">
              {invalidText}
            </div>
          </div>
        }
      </>
    );
  }

  render() {
    const { browserTitle, brandingTitle, brandingFaviconUrl,
      customerLogoUrl, headerBarTheme
    } = this.state;

    return (
      <div className="branding">
        <Form.Group>
          <div className="branding-title d-flex align-items-center justify-content-between">
            <div className="forge-typography--title">Browser tab</div>
            {this.renderSaveButton()}
          </div>
          <div className="form-field">
            <ForgeInputTextField
              type="text"
              className="form-text-field"
              label="Browser title"
              id="browser-title"
              value={browserTitle}
              hasHelperText={true}
              ariaLabel="Browser title"
              helperText="Enter the name that appears in the browser tab."
              handleOnChange={(e) => this.handleChangeBranding(e, "browserTitle")} />

          </div>

          <div className="form-filed">
            <ForgeInputTextField
              className="form-text-max-width"
              type="text"
              label="Browser favicon"
              id="favicon-url"
              value={brandingFaviconUrl}
              hasHelperText={true}
              ariaLabel="Browser favicon"
              helperText="Enter the file path of the favicon shown in the browser tab.
              Recommended image is no more than 50x50px."
              handleOnChange={(e) => this.handleChangeBranding(e, "brandingFaviconUrl")} />
          </div>

          <div className="forge-typography--title branding-title branding-header-bar">Header bar</div>
          <div className="form-field">
            <ForgeInputTextField
              className="form-text-field"
              type="text"
              label="Title"
              id="header_title"
              value={brandingTitle}
              hasHelperText={true}
              ariaLabel="Header bar title"
              helperText="Enter the title displayed in the header bar."
              handleOnChange={(e) => this.handleChangeBranding(e, "brandingTitle")} />
          </div>

          <div className="row">
            <div className="col-md-12 branding-title">
              <ForgeInputTextField
                className="form-text-max-width"
                type="text"
                label="Site logo"
                id="header_logo"
                value={customerLogoUrl}
                hasHelperText={true}
                ariaLabel="Header site logo"
                helperText="Enter the file path of the logo shown in the header bar.
                  Recommended image height is no more than 50px."
                handleOnChange={(e) => this.handleChangeBranding(e, "customerLogoUrl")} />
                { customerLogoUrl && this.renderValidationText() }
            </div>
          </div>

          <div>
            <div className='forge-typography--subtitle2'>Header bar theme color</div>
            <div className='forge-typography--subtitle2-secondary help-text'>
              Please select the color theme for the header bar.
            </div>
            <div role="radiogroup" aria-label="Header bar theme color">
              <ForgeRadio dense={true} className="mr-4">
                <input
                  key='blueTheme'
                  className="mr-3"
                  type="radio"
                  tabIndex={0}
                  id={HEADER_THEME_COLOR.PRIMARY}
                  name="radios"
                  value={headerBarTheme}
                  aria-label="Blue"
                  checked={headerBarTheme == HEADER_THEME_COLOR.PRIMARY}
                  onChange={() => this.onHeaderBarThemeChange(HEADER_THEME_COLOR.PRIMARY)}
                />
                <label className="" htmlFor={HEADER_THEME_COLOR.PRIMARY}>Blue</label>
              </ForgeRadio>
              <ForgeRadio dense={true}>
                <input
                  key='whiteTheme'
                  type="radio"
                  tabIndex={0}
                  id={HEADER_THEME_COLOR.WHITE}
                  name="radios"
                  value={headerBarTheme}
                  aria-label="White"
                  checked={headerBarTheme == HEADER_THEME_COLOR.WHITE}
                  onChange={() => this.onHeaderBarThemeChange(HEADER_THEME_COLOR.WHITE)}
                />
                <label className="" htmlFor={HEADER_THEME_COLOR.WHITE}>White</label>
              </ForgeRadio>
            </div>
          </div>
        </Form.Group>
      </div>
    )
  }
}

Branding.propTypes = {
  configData: PropTypes.object,
  onHandleConfigurationChange: PropTypes.func,
  onSaveClick: PropTypes.func
}

function mapDispatchToProps() {
  return {}
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Branding);
