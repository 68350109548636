import _ from 'lodash';

import { OTHER_BUCKET_ID } from 'modules/visualization/constants';
import {
  getDistributionBucketEntries,
  showDimensionsInDistribution
} from 'common/config/visualizationConfiguration';

import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getlegendsItems } from 'modules/visualization/DistributionChart/plotlyParamsGenerator';
// Input:
//      apiData ===> {
//        "total": {
//          "bucket_0_nvsj_bqhd_0_0": "21.9897148200093502",
//          "bucket_1_nvsj_bqhd_0_0":"45.5743351156169710",
//          "bucket_2_nvsj_bqhd_0_0":"74.5837889996783532",
//          "bucket_3_nvsj_bqhd_0_0":"115.0628375061364752",
//          "bucket_4_nvsj_bqhd_0_0":"193.7714285714285714"
//        }
//      }
export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    return (
      !_.isEqual(_.get(vizOptions, 'viewEntry'), _.get(previousVizOptions, 'viewEntry'))
    );
  },
  formatData: (vizOptions, apiData) => {
    const bucketEntries = getDistributionBucketEntries(vizOptions.viewEntry);
    const showDimensions = showDimensionsInDistribution(_.get(vizOptions, 'viewEntry'));
    const total = _.chain(apiData).
      get('total').
      filter((value, key) => !_.startsWith(key, 'distribution_benchmark')).
      values().
      map(_.toNumber).
      sum().
      value();
    let discreteEntriesData = [],
      formattedCumulativeData = [],
      formattedCumulativeEntriesData = [],
      formattedDiscreteData = [];
    const formattedTotalData = getFormattedBucketEntry(
      vizOptions, bucketEntries, _.get(apiData, 'total'), {}, total, ''
    );
    formattedDiscreteData = formattedTotalData['formattedData'];

    if (showDimensions) {
      const drilldownEntry = JSON.parse(_.get(vizOptions, 'apiParams.drilldownEntry', '{}'));
      const nullValueLabel = getNullValueLabel(drilldownEntry['currentDrilldownTemplateId']);
      // other entry is calculated from total by subracting form entries.
      let otherEntry = _.cloneDeep(_.get(apiData, 'total', {}));
      discreteEntriesData = _.map(_.get(apiData, 'entries', []), (dimensionEntry) => {
        const entry = _.first(dimensionEntry);
        const dimension = _.get(entry, 'dimension', nullValueLabel);
        const data = getFormattedBucketEntry(vizOptions, bucketEntries, entry, otherEntry, total, dimension);
        otherEntry = data['updatedOtherEntry'];
        return data['formattedData'];
      });

      if (_.keys(_.get(apiData, 'entries', {})).length == 10) {
        const formattedOtherEntry =
          getFormattedBucketEntry(vizOptions, bucketEntries, otherEntry, {}, total, 'Others');
        discreteEntriesData.push(formattedOtherEntry['formattedData']);
      }
    }

    // for cumulative data , discrete values are made sum with each bucket.
    formattedCumulativeData = _.cloneDeep(formattedDiscreteData);
    formattedCumulativeEntriesData = _.cloneDeep(discreteEntriesData);

    _.reduce(formattedDiscreteData.map((d) => d.value), function (sum, value, index) {
      const sumOfValue = Number(sum) + Number(value);
      return formattedCumulativeData[index].value = sumOfValue;
    }, 0);

    _.reduce(formattedDiscreteData.map((d) => d.value), function (sum, value, index) {
      const percentageValue = Number(sum) + (Number(value)) / Number(total) * 100.0;
      return formattedCumulativeData[index].percentage = percentageValue;
    }, 0);

    addPlotlyDescription(formattedDiscreteData, { vizOptions }, apiData);
    addPlotlyDescription(formattedCumulativeData, { vizOptions }, apiData);
    if (showDimensions) {
      _.each(formattedCumulativeEntriesData, (data) => {
        _.reduce(data.map((d) => d.value), function (sum, value, index) {
          data[index].value = Number(sum) + Number(value),
            data[index].percentage = ((Number(sum) + Number(value)) / Number(total)) * 100.0;
          return data[index].value;
        }, 0);
        addPlotlyDescription(data, { vizOptions }, apiData);
      });

      _.each(discreteEntriesData, (formattedData) => {
        addPlotlyDescription(formattedData, { vizOptions }, apiData);
      });
    }

    let formattedData = {};
    formattedData['discreteData'] = formattedDiscreteData;
    formattedData['cumulativeData'] = formattedCumulativeData;
    formattedData['discreteEntriesData'] = discreteEntriesData;
    formattedData['cumulativeEntriesData'] = formattedCumulativeEntriesData;

    return formattedData;
  }
};

function getFormattedBucketEntry(vizOptions, bucketEntries, apiData, otherEntry, totalValue, dimension) {
  let updatedOtherEntry = _.cloneDeep(otherEntry);

  const otherValue = Number(_.get(apiData, `${OTHER_BUCKET_ID}`, 0));
  let formattedData = _.map(bucketEntries['buckets'], (bucketEntry) => {
    const bucketId = `${bucketEntry['bucket_id']}`;
    const bucketValue = Number(_.get(apiData, bucketId, 0));

    updatedOtherEntry[bucketId] = Number(updatedOtherEntry[`${bucketEntry['bucket_id']}`]) - bucketValue;
    updatedOtherEntry[`${OTHER_BUCKET_ID}`] = Number(updatedOtherEntry[bucketId]) - otherValue;

    return {
      id: bucketEntry['bucket_id'],
      tickLabel: getBucketLabel(bucketEntry),
      label: getBucketLabel(bucketEntry, true),
      value: bucketValue,
      percentage: Number(bucketValue) / Number(totalValue) * 100.0,
      buckets: apiData,
      dimension,
      bucketEntry
    };
  });

  if (_.isEqual(bucketEntries['show_others'], 'true')) {
    formattedData.push({
      id: OTHER_BUCKET_ID,
      label: 'Others',
      tickLabel: 'Others',
      value: otherValue,
      buckets: apiData,
      percentage: Number(otherValue) / Number(totalValue) * 100.0,
      dimension,
      bucketEntry: null
    });
  }
  return { formattedData, updatedOtherEntry };
}

function addPlotlyDescription(formattedData, { vizOptions }, apiData) {
  const { viewEntry } = vizOptions;
  // In distribution chart showing only counts so decimal precision is removed.
  const viewEntryClone = _.cloneDeep(viewEntry);
  const displayFormatEntry = _.omit(viewEntryClone, ['precision']);
  const groupedByLabelColors = _.groupBy(getlegendsItems(apiData, vizOptions), 'label');
  _.each(formattedData, (formattedDataItem) => {
    const { dimension } = formattedDataItem;
    const formattedNumberValue = getFormattedNumberValue(
      formattedDataItem.value,
      displayFormatEntry,
      false,
      false,
      true,
      false,
      true
    );
    const percentageValue = _.round(formattedDataItem.percentage, 2);

    formattedDataItem.plotlyDescription = `${formattedNumberValue} (${percentageValue}%)`;
    formattedDataItem.percentageValue = percentageValue;
    formattedDataItem.color = _.get(groupedByLabelColors, [dimension, 0, 'color']);
  });
}

function getBucketLabel(bucketEntry, isLabelUnique = false) {
  const toValue = _.toString(bucketEntry['to']);
  if (!_.isEmpty(bucketEntry['label']) && !isLabelUnique) {
    return bucketEntry['label'];
  } else if (_.isEmpty(toValue)) {
    return `${bucketEntry['operator'] || ''} ${bucketEntry['from']}`;
  } else {
    return `${bucketEntry['from']} - ${toValue}`;
  }
}
