import { applicationUsedFor, pendoApiKey } from 'common/config/customerConfiguration';


export const trackEvent = (event, properties) => {
  console.log('event', event);
  if (window.mixpanel && window.mixpanel.track) {

    window.mixpanel.track(event, {
      ...properties,
      currentDomain: window.location.host,
      executiveInsightsApplicationType: applicationUsedFor
    });
  }
  if(pendoApiKey) {
    if(pendo && pendo.track) { // eslint-disable-line no-undef
      pendo.track(event, { // eslint-disable-line no-undef
        ...properties,
        executiveInsightsApplicationType: applicationUsedFor
      });
    }
  }
}
