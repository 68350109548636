import React from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeList, ForgeListItem } from '@tylertech/forge-react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const CustomForgeList = (props) => {
  const {
     options, onSelect, showCount, showDescription,
    optionDisplayField, onClickToggleButton
  } = props;

  const onEnterListItem = (e, entry) => {
    const isDisabled = _.get(entry, 'disabled', false);
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      if(!isDisabled) {
        onClickToggleButton();
        onSelect(entry)
      }
    }
  }

  const renderWithDescription = (entry) => {
    if(!showDescription){
      return null;
    }

    const displayName = _.get(entry, optionDisplayField);
    const description = _.get(entry, 'description');

    return(
      <>
      <span slot="title" className="forge-list-line-clamp">{displayName}</span>
      { !_.isEmpty(description) &&
        <span slot="subtitle" className="forge-list-line-clamp mt-1">{description}</span>}
      </>
    )
  }

  const renderWithCount = (entry) =>{
    if(showDescription){
      return null;
    }
    const { count  } = entry;
    const displayName = _.get(entry, optionDisplayField);

    return(
      <div className='d-flex'>
        <span slot="title">{displayName}</span>
        {showCount && <span className="ml-auto">{count}</span>}
      </div>
    )
  }

  const renderListItem = (entry, index) => {
    const description = _.get(entry, 'description');
    const leadingIcon = _.get(entry, 'leadingIcon');
    const isDisabled = _.get(entry, 'disabled', false);

    const listItemProps = {
      twoLine: !_.isEmpty(description) && showDescription,
    };

    return (
      <ForgeListItem disabled={isDisabled} {...listItemProps}
        key={index}
        onClick={() => {
            if(!isDisabled) { onClickToggleButton(); onSelect(entry) }
          }
        }
        onkeydown={(e) => onEnterListItem(e,entry)} >
        { !_.isEmpty(leadingIcon) && <ForgeIcon slot="leading" name="plus_thick" /> }
        {renderWithDescription(entry)}
        {renderWithCount(entry)}
      </ForgeListItem>
    );
  }

  return (
    <div className='title-and-list-container forge-list-custom'>
      <ForgeList className="forgelist">
        {_.map(options, renderListItem)}
      </ForgeList>
    </div>
  );
}

CustomForgeList.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  title: PropTypes.node,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  showCount: PropTypes.bool,
  onClickToggleButton:PropTypes.func,
  optionDisplayField:PropTypes.any,
  showDescription: PropTypes.bool
};

CustomForgeList.defaultProps = {
  optionDisplayField: '',
  showDescription: false,
  onSelect: _.noop
};

export default CustomForgeList;
