import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { buildQueryString } from 'helpers/HttpHelper';
import { fetchApiData, fetchApiDataWithStatus } from 'helpers/apiResponseHelper';
import {
  PROPHET_FORECAST,
  EXPONENTIAL_FORECAST,
  HISTORICAL_FORECAST,
  DATE_FORMAT,
  OVERTIME_VISUALIZATION_TYPES
} from 'appConstants';
import abortableFetch from 'common/abortableFetch';
import {
  dimensionDefaultVisible,
  getPeriodType
} from './vizOptionsHelper';
import { getConfiguredDataEndDate } from 'common/config/customerConfiguration';
import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import { filterCompareYearRanges } from 'common/contentFormatter/helper';
import {
  lineChartSumApiData,
  getForecastData,
  getNoOfPredictions,
  getHistoricForecastData
} from './Helpers/apiDataHelper';
import {
  defaultPrepareDataAxisGranularityOption,
  isForecastEndDateIsBeforeToday } from 'pages/Forecasting/ForecastHelper';
import { VIEW_MODE } from '../constants';
const moment = extendMoment(Moment);
export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    const currentProjectionType = _.get(vizOptions, 'projectionType');
    const isProjectOptionChanged = (
      !_.isEqual(_.get(previousVizOptions, 'projectionType'), currentProjectionType) ||
      !_.isEqual(_.get(previousVizOptions, 'projectionEnabled'),
      vizOptions.projectionEnabled)) && (vizOptions.projectionEnabled &&
      _.includes(
        [ PROPHET_FORECAST, EXPONENTIAL_FORECAST, HISTORICAL_FORECAST, "Multiple"],
        currentProjectionType)
    );

    const isComboChartViewChanged = !_.isEqual(
      _.get(vizOptions,'isComboChart'), _.get(previousVizOptions, 'isComboChart')
    );
    const isRenderTypeChanged = !_.isEqual(
      _.get(vizOptions,'renderType'), _.get(previousVizOptions, 'renderType')
    );

    const isLegendItemsUpdated = !_.isEqual(
      _.get(vizOptions,'isOvertimeLegendItemsEmpty'), _.get(previousVizOptions, 'isOvertimeLegendItemsEmpty')
    );

    const isAxisGranularityChanged = !_.isEqual(
      _.get(vizOptions,'axisGranularity'), _.get(previousVizOptions, 'axisGranularity')
    );
    // const previousDimensionConfigs = _.get(previousVizOptions, 'dimensionConfigs');
    const isDimensionsChanged = (
      ( (!_.isEqual(getDimensions(previousVizOptions), getDimensions(vizOptions)) ||
          _.isEmpty(_.get(vizOptions, 'dimensionConfigs'))))
    );

    const isForecastProjectionChanged = (
      !_.isEqual(_.get(previousVizOptions, 'projectionAdjustedValues'),
      vizOptions.projectionAdjustedValues)) && (vizOptions.projectionEnabled &&
      _.get(vizOptions, 'isForecastingView', false) &&
      _.includes(
        [ PROPHET_FORECAST, "Multiple"], currentProjectionType)
    );

    const isProjectionPercentsChanged = (
      !_.isEqual(_.get(previousVizOptions, 'projectionPercent'), _.get(vizOptions, 'projectionPercent')) &&
      _.get(vizOptions, 'isForecastingView', false) &&
      _.includes(
        [ HISTORICAL_FORECAST, "Multiple"],
        currentProjectionType)
    )

    const isApiDataChanged = (_.get(vizOptions, 'isForecastingView', false) &&
    !_.isEqual(_.get(previousVizOptions, 'apiData'),
    vizOptions.apiData)
    )

    return (
      !_.isEqual(_.get(vizOptions,'apiParams'), _.get(previousVizOptions, 'apiParams')) ||
      isAxisGranularityChanged ||
      isProjectOptionChanged ||
      isComboChartViewChanged ||
      isDimensionsChanged ||
      isRenderTypeChanged ||
      isForecastProjectionChanged ||
      isProjectionPercentsChanged ||
      isApiDataChanged ||
      isLegendItemsUpdated
    );
  },
  getData: (vizOptions,
    abortableFetchController,
    previousVizOptions,
    onNewApiData = _.noop) => {
    const { apiParams, renderType, isEmbed, isBookMark, viewMode } = vizOptions;
    const previousRenderType = _.get(previousVizOptions, 'renderType');
    const isAreaChart = (renderType == OVERTIME_VISUALIZATION_TYPES.AREA.type);
    const defaultAxisGranularity = defaultPrepareDataAxisGranularityOption(vizOptions.templateId);
    const vizDimensionConfigs = _.chain(vizOptions).
      get('dimensionConfigs', []).
      filter((config) => { return !_.get(config, 'isTotalLine'); }).
      value();
    const dimensionConfigs = isAreaChart ?
      vizDimensionConfigs :
      _.get(vizOptions, 'dimensionConfigs', []);

    const selectedDimensions = getDimensions(vizOptions);
    const includeDimensions = dimensionDefaultVisible(vizOptions);
    const forecastingOption = _.get(vizOptions, 'forecastingOption', {});
    const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
    const isOvertimeLegendItemsEmpty = _.get(vizOptions, 'isOvertimeLegendItemsEmpty', false);
    let projections = [];
    if (isForecastingView){
      projections = _.get(forecastingOption, 'projectionTypes');
    } else {
      projections = [vizOptions.projectionType]
    }
    const projectionType = _.get(vizOptions, 'projectionType');
    let newParams =  _.merge({},
      getNewParams(apiParams, vizOptions),
      {
        'forecast_type': projectionType,
        is_forecast_view: isForecastingView,
        axisGranularity: isForecastingView ? defaultAxisGranularity : apiParams['axisGranularity'],
        no_of_predict: isForecastingView ? getNoOfPredictions(vizOptions, defaultAxisGranularity): 120,
        // In Forecast Page Using only least axis Granularity  for other Granularity values are Summed.
        // so its set to default.
      }
      );

    if(isOvertimeLegendItemsEmpty && viewMode !== VIEW_MODE.SMALL){
      const newSelectedDimensions =  JSON.stringify([]);
      newParams = getNewParamsWithSelectDimensions(newParams, newSelectedDimensions);
    }else{
      if(includeDimensions && isForecastingView) {
        if(!_.isEmpty(selectedDimensions) && previousRenderType === renderType) {
          const newSelectedDimensions = JSON.stringify(selectedDimensions);
          newParams = getNewParamsWithSelectDimensions(newParams, newSelectedDimensions);
        }
      } else if(!(_.isEmpty(dimensionConfigs) && includeDimensions) && !isForecastingView) {
        let newSelectedDimensions = JSON.stringify([]);
        if(!_.isEmpty(dimensionConfigs)) {
          newSelectedDimensions = JSON.stringify(selectedDimensions);
        }

        // for initial loading we are not sending selectedDimensions,
        // so route will fetch all top dimensions
        // Note: if we pass selectedDimensions as empty array it will return empty dimensions
        if ((previousRenderType != renderType) && !_.isEmpty(previousRenderType)) {
          newSelectedDimensions =  JSON.stringify([]);
        }

        if(isEmbed && !previousVizOptions && !_.isEmpty(selectedDimensions)) {
          newSelectedDimensions = JSON.stringify(selectedDimensions);
        }

        newParams = getNewParamsWithSelectDimensions(newParams, newSelectedDimensions);
      } else if(isBookMark && _.isEmpty(selectedDimensions)){
        const newSelectedDimensions =  JSON.stringify([]);
        newParams = getNewParamsWithSelectDimensions(newParams, newSelectedDimensions);
      }
    }

    const apiUrl = `/api/visualization/line_chart.json?${buildQueryString(newParams)}`;
    const chartDataPromise = fetchApiDataWithStatus(apiUrl, abortableFetchController);

    const shouldForecast = (vizOptions.projectionEnabled && _.includes(projections, PROPHET_FORECAST)) || (
      !isForecastingView && _.includes(projections, EXPONENTIAL_FORECAST)
    );

    const shouldFectchHistoricalData = (vizOptions.projectionEnabled
      && _.includes(projections, HISTORICAL_FORECAST));
    let forecastParams = _.cloneDeep(newParams),
      historicParams = _.cloneDeep(newParams),
      exponentialParams = _.cloneDeep(newParams),
      forecastVizOptions = _.cloneDeep(vizOptions),
      historicVizOptions = _.cloneDeep(vizOptions),
      exponentialVizOptions = _.cloneDeep(vizOptions);

    if (isForecastingView){
      const adjustedValues = _.get(vizOptions, 'projectionAdjustedValues', []);
      const totalAdjustedValues = _.compact(_.map(adjustedValues, (entry) => {
        if(!_.isEmpty(_.get(entry, 'adjustValue', ''))){
          return {period: entry.period, value: entry.adjustValue}
        } else {
          return null;
        }
      }));
      if(!_.isEmpty(totalAdjustedValues)){
        forecastParams['adjusted_values'] = JSON.stringify(totalAdjustedValues);
        historicParams['adjusted_values'] = JSON.stringify(totalAdjustedValues);
        exponentialParams['adjusted_values'] = JSON.stringify(totalAdjustedValues);
      }
      forecastParams['forecast_type'] = PROPHET_FORECAST;
      forecastVizOptions['projectionType'] = PROPHET_FORECAST;
      exponentialParams['forecast_type'] = EXPONENTIAL_FORECAST;
      exponentialVizOptions['projectionType'] = EXPONENTIAL_FORECAST;
    }

    // Forecasting Projection
    const forecastPromise = shouldForecast ?
      fetchForecastData(forecastParams, forecastVizOptions) :
      Promise.resolve({});

    const exponentialPromise = (isForecastingView &&
      vizOptions.projectionEnabled && _.includes(projections, EXPONENTIAL_FORECAST))?
      fetchForecastData(exponentialParams, exponentialVizOptions) :
      Promise.resolve({});

    if (isForecastingView){
      forecastParams['forecast_type'] = HISTORICAL_FORECAST;
      forecastParams['projection_percent'] = historicVizOptions['projectionPercent'];
      historicVizOptions['projectionType'] = HISTORICAL_FORECAST;
    }

    //Historical Projection
    const historicPromise = shouldFectchHistoricalData ?
    isForecastingView ? fetchForecastData(forecastParams, forecastVizOptions) :
    fetchHistoricData(historicParams, historicVizOptions) :
      Promise.resolve({});
    const isSumApiDataOnGranularityChange = isForecastingView &&
    defaultAxisGranularity !== _.get(vizOptions,'axisGranularity') &&
    !_.isEmpty(vizOptions.apiData);
     // In Forecast Page Using only least axis Granularity  for other Granularity values are Summed.
    // When API data is available this if will be used.
    if (isSumApiDataOnGranularityChange) {
      let apiData = lineChartSumApiData(vizOptions.apiData, vizOptions);

      return new Promise( (resolve) => {
        return resolve(apiData);
      });
    } else {
      return Promise.all([chartDataPromise, forecastPromise, historicPromise, exponentialPromise]).
      then( async (results) => {
        const { isComboChart } = vizOptions;
        let chartResults = results[0];
          chartResults['forecasting_data'] =  isComboChart ? results[1] : getForecastData(results[1]);
          chartResults['historic_data'] = isComboChart || !isForecastingView ?
            results[2] : getHistoricForecastData(results[2]);
          chartResults['exponential_data'] = getForecastData(results[3]);

        if (isForecastingView){
          // In Forecast page, data is set to least Granularity
          // so values are re-calculated to current Granularity.
          onNewApiData(chartResults);
          if(defaultAxisGranularity !== _.get(vizOptions,'axisGranularity')){
            return lineChartSumApiData(chartResults, vizOptions);
          } else {
            return chartResults;
          }
        } else {
          return chartResults;
        }
      });
    }
  }
};

const fetchForecastData =  async (apiParams, vizOptions) => {
  const forecastParams = getProjectionParams(apiParams, vizOptions);
  const decoder = new TextDecoder('utf-8');
  const forecastUrl = `/api/visualization/forecasting.json?${buildQueryString(forecastParams)}`;
  const fetchedResource = await abortableFetch(forecastUrl);
  const reader = await fetchedResource.body.getReader();
  let result = '';

  return reader.read().then(function processText({ done, value }) {
    if (done) {
      let decodedValues = _.compact(result.split("\\\\\\n"));
      let prophetForecastingData;
      for (var i in decodedValues) {
        let obj = JSON.parse(decodedValues[i]);
        prophetForecastingData = _.merge({}, prophetForecastingData, obj);
      }
      return prophetForecastingData;
    }
    // each chunk ends with `\\\n`, so we splitting chunk so that we can parse
    const decodedString = decoder.decode(value);
    result += decodedString;

    return reader.read().then(processText);
  }).catch((error) => {
    if(error.name !== 'AbortError'){
      console.log("Error on fetching Forecasting data", error);
    }
  })
};

const fetchHistoricData =  async (apiParams, vizOptions) => {
  const historicParams = getProjectionParams(apiParams, vizOptions);
  const apiUrl = `/api/visualization/line_chart.json?${buildQueryString(historicParams)}`;
  return fetchApiData(apiUrl);
};

const getDimensions = (vizOptions) => {
  const { dimensionConfigs, templateId, dateRange, renderTimeFrame, compareYearRanges } = vizOptions || {};
  const nullValueLabel = getNullValueLabel(templateId);

  if(shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges)) {
    return [];
  }

  return _.chain(dimensionConfigs).
    filter((config) => { return !_.get(config, 'isTotalLine'); }).
    map((entry) => {
      const traceId = _.get(entry, 'dimension');
      return traceId === nullValueLabel ? null : traceId
    }).sort().uniq().value();
}

const getNewParams = (apiParams, vizOptions) => {
  const { renderType, isComboChart } = vizOptions;
  const isAreaChart = (renderType == OVERTIME_VISUALIZATION_TYPES.AREA.type);
  const commonFilters = JSON.parse(_.get(apiParams, 'commonFilters', '{}'));
  const compareYearRanges = _.get(commonFilters, 'comparisonDateRanges', []);
  const { dateRange } = commonFilters;
  const filteredCompareYearRanges = (isAreaChart ||  isComboChart) ?
    [] :
    filterCompareYearRanges(compareYearRanges, dateRange);

  return { ...apiParams, compareYearRanges: JSON.stringify(filteredCompareYearRanges) };
}

const getProjectionParams = (apiParams, vizOptions) => {
  const periodType = getPeriodType(vizOptions);
  const commonFilters = JSON.parse(_.get(apiParams, 'commonFilters', '{}'));
  const projectionType = _.get(vizOptions, 'projectionType');
  const axisGranularity = _.get(vizOptions, 'axisGranularity');
  const isHistoricProjection = (projectionType === HISTORICAL_FORECAST);
  // For forecasting page viz options has the filter date range.
  const { dateRange } = vizOptions;
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  let projectionTypeDateRange =  dateRange;
  if (isForecastingView &&
    isForecastEndDateIsBeforeToday({dateRange: dateRange}, axisGranularity)){
    const periodType = getPeriodType(vizOptions) || vizOptions.axisGranularity;
    projectionTypeDateRange = {
      startDate: dateRange.startDate,
      endDate: moment(dateRange.endDate, DATE_FORMAT).subtract(1, periodType).format(DATE_FORMAT)
    }
  }

  if (!isForecastingView) {
    projectionTypeDateRange = isHistoricProjection ?
    getHistoricDateRange(dateRange, vizOptions) : getForecastingDateRange(dateRange, periodType);
  }
  const newCommonFilters = {
    ...commonFilters,
    dateRange: projectionTypeDateRange
  }
  // Previously we calculate Period type based on days different in backed
  // Now we are passing period type as axisGranularity only for projection
  // since we are fetching 3 years data
  let newAxisGranularity = axisGranularity === 'default' ? periodType : axisGranularity;
  if (isForecastingView){
    newAxisGranularity = apiParams.axisGranularity;
  }
  return {
    ...apiParams,
    commonFilters: JSON.stringify(newCommonFilters),
    axisGranularity: newAxisGranularity
  }
}

const getHistoricDateRange = (dateRange) => {
  // For Historical Projection we need past year's data
  // so we are fetching past year data.
  const dataEndDate = getConfiguredDataEndDate();
  return {
    startDate: dataEndDate.subtract(1, 'year').startOf('month').format(DATE_FORMAT),
    endDate: dateRange['endDate']
  };
}

const getForecastingDateRange = (dateRange, periodType) => {
  // For Forecasting Projection we need 30 months ticks
  // so we are fetching data for past 3 years.
  let dateRangeStartDate;
  if (_.includes(['month', 'year'], periodType)){
    dateRangeStartDate = moment(dateRange['endDate']).subtract(3, 'year').format(DATE_FORMAT);
  }else{
    dateRangeStartDate = moment(dateRange['endDate']).subtract(2, 'year').format(DATE_FORMAT);
  }

  return {
    startDate: dateRangeStartDate,
    endDate: dateRange['endDate']
  };
}

const getNewParamsWithSelectDimensions = (newParams, newSelectedDimensions) => {
  if( _.size(newSelectedDimensions) > 0 && _.get(newParams, 'limit') == '1'){
    return { ...newParams, selectedDimensions: newSelectedDimensions, limit: 2 }
  } else {
    return _.merge({}, newParams, { selectedDimensions: newSelectedDimensions });
  }
}
