import React, { Component } from 'react';
import ReCaptcha from 'react-recaptcha';
import PropTypes from 'prop-types';
import { recaptchaPublicKey  } from 'common/config/customerConfiguration.js';

class Recaptcha extends Component {

  constructor(props, context) {
    super(props, context);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
  }

  //This is a empty callback function for loading the google recaptcha
  recaptchaLoaded() {}

  verifyCallback(response) {
    this.props.verifyResponse(response);
  }

  expiredCallback() {
    this.props.verifyResponse('');
  }

  render() {
    return (
      <div>
        <ReCaptcha
          size="normal"
          sitekey={recaptchaPublicKey}
          type="image"
          render="explicit"
          onloadCallback={this.recaptchaLoaded}
          verifyCallback={this.verifyCallback}
          expiredCallback={this.expiredCallback}
        />
      </div>
    );
  }
}

Recaptcha.propTypes = {
  verifyResponse: PropTypes.func
};

export default Recaptcha;
