export const UPDATE_MANAGE_VIEW_COLLECTION_ID = 'UPDATE_MANAGE_VIEW_COLLECTION_ID';
export const UPDATE_MANAGE_VIEW_SUBSCRIPTION_ID = 'UPDATE_MANAGE_VIEW_SUBSCRIPTION_ID';
export const UPDATE_USER_MENU = 'UPDATE_USER_MENU';

export const updateMangeViewCollectionId = (collectionId) => ({
  type: UPDATE_MANAGE_VIEW_COLLECTION_ID,
  collectionId
});

export const updateMangeViewSubscriptionId = (collectionId, subscriptionId) => ({
  type: UPDATE_MANAGE_VIEW_SUBSCRIPTION_ID,
  subscriptionId,
  collectionId
});

export const updateUserMenu = (userMenu) => ({
  type: UPDATE_USER_MENU,
  userMenu
});
